import { useContext, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import {
  CellCallbackGenerator,
  CloudDatatableItemData,
  ICloudDatatableColumn,
  ICloudDatatablePagination,
  RefContext,
  StyleContext,
} from '../datatable.types';
import { useCloudDatatableScrollRestoration } from '../datatable.utilities';
import { Row } from './row';

interface IBody<T> {
  id: string;
  data: T[];
  columns: ICloudDatatableColumn<T>[];
  cellOnClick?: CellCallbackGenerator<T>;
  rowOnLongTouch?: (data: T) => void;
  rowHeight: (data?: T) => number;
  pagination?: ICloudDatatablePagination;
  loading?: boolean;
  overlay?: () => React.ReactNode;
  extraRow?: () => React.ReactNode;
}

export const Body = <T,>({
  id,
  data,
  columns,
  cellOnClick,
  rowOnLongTouch,
  rowHeight,
  pagination,
  loading,
  overlay,
  extraRow: extraRowProp,
}: IBody<T>) => {
  const style = useContext(StyleContext);
  const refs = useContext(RefContext);

  const className = `relative size-full`;

  const extraRow = useMemo(() => extraRowProp?.(), [extraRowProp]);

  const itemCount = useMemo(() => {
    const needsAdditionalItem = loading === true || extraRow;
    if (needsAdditionalItem) {
      return data.length + 1;
    }
    return data.length;
  }, [data.length, loading, extraRow]);

  const { onScroll, scroll } = useCloudDatatableScrollRestoration({
    id,
    datatableRef: refs?.datatableRef ?? null,
  });

  return (
    <div
      className={className}
      style={{
        minWidth: style?.minWidth,
      }}
    >
      <AutoSizer>
        {({ height = 0, width = 0 }) => (
          <VariableSizeList<CloudDatatableItemData<T>>
            ref={refs?.setDatatableRef}
            innerRef={refs?.setBodyRef}
            onScroll={onScroll}
            initialScrollOffset={scroll + 0.1}
            height={Number.isNaN(height) ? 0 : height}
            width={Number.isNaN(width) ? 0 : width}
            itemSize={(index) => rowHeight(data?.[index])}
            itemCount={itemCount}
            overscanCount={5}
            itemData={{
              data,
              columns,
              cellOnClick,
              rowOnLongTouch,
              loading,
              extraRow,
            }}
            onItemsRendered={(props) => {
              let key: keyof typeof props;
              for (key in props) {
                if (isNaN(props[key])) {
                  props[key] = 0;
                }
              }

              const shouldLoadNextPage =
                !loading &&
                props.visibleStopIndex >=
                  data.length - (pagination?.threshold ?? 1);

              if (shouldLoadNextPage) {
                pagination?.loadNextPage?.();
              }
            }}
          >
            {Row}
          </VariableSizeList>
        )}
      </AutoSizer>
      <div className="absolute inset-0 pointer-events-none">{overlay?.()}</div>
    </div>
  );
};
