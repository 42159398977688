import { useMemo } from 'react';

import { InputIdContext } from '../input-id-context';
import { InputStyle } from '../input-style';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface InputTextAreaProps {
  /**
   * The unique identifier for the textarea element.
   */
  id: string;

  /**
   * The error message to be displayed.
   */
  error?: React.ReactNode;

  /**
   * The label to be displayed above the textarea.
   */
  label?: React.ReactNode;

  /**
   * The current value of the textarea.
   */
  value: string;

  /**
   * A callback function to set the value of the textarea.
   */
  setValue: (value: string) => void;

  /**
   * Determines whether the textarea can be resized vertically.
   */
  resize?: boolean;

  /**
   * The number of rows to display in the textarea.
   */
  rows?: number;

  /**
   * Determines whether the textarea is disabled.
   */
  disabled?: boolean;

  /**
   * The validation configuration for the textarea.
   */
  validation?: InputValidation<string>;

  /**
   * Determines whether the textarea is read-only.
   */
  readOnly?: boolean;

  /**
   * The placeholder text to display in the textarea.
   */
  placeholder?: string;

  /**
   * auto focus on the input
   */
  autoFocus?: boolean;

  /**
   * onBlur event handler
   */
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;

  /**
   * onFocus event handler
   */
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;

  /**
   * style for the textarea
   */
  style?: InputStyle;

  /**
   * onKeyDown event handler
   */
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
}

const InputTextArea = ({
  id,
  label,
  error,
  value,
  setValue,
  resize,
  rows = 3,
  disabled,
  validation,
  readOnly,
  autoFocus,
  placeholder,
  onBlur,
  onFocus,
  onKeyDown,
  style = InputStyle.default,
}: InputTextAreaProps) => {
  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  const className = useMemo(() => {
    if (style === InputStyle.cloud) {
      return 'w-full rounded-lg focus:ring-primary focus:border-primary text-base border-gray-border';
    }

    return 'w-full rounded focus:ring-green focus:border-green text-sm';
  }, [style]);

  return (
    <InputIdContext.Provider value={id}>
      <div
        className={`flex flex-col ${
          disabled ? 'pointer-events-none select-none opacity-50' : ''
        }`}
      >
        {label ?? null}
        <textarea
          className={className}
          id={id}
          value={value}
          readOnly={readOnly}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          rows={rows}
          disabled={disabled}
          style={{ resize: resize ? 'vertical' : 'none' }}
          onChange={(e) => {
            const v = e.target.value;
            setValue(v.trimStart());
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
        />
        {error}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputTextArea };
