import { useCallback, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Vector3 } from 'three';

import { APIModels, APIRequests, Coordinate } from '@agerpoint/api';
import { EffectNames, SpecialCaptureObject } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../../captures-viewer';

type AllowedKeys = 'name' | 'description';

export const ThreeDAnnotationsPlugin = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();

  const { selectedCaptureJob, annotationCaptureObjects } =
    useCapturesViewerContext();

  const { captureId } = useParams();

  const captureObjectPostMutation = APIRequests.CaptureObject.Mutations.usePost(
    {
      captureId: Number(captureId) || undefined,
      captureJobId: selectedCaptureJob?.id,
    }
  );

  const captureObjectPutMutation = APIRequests.CaptureObject.Mutations.usePut();

  const captureObjectDeleteMutation =
    APIRequests.CaptureObject.Mutations.useDelete({
      captureId: Number(captureId) || undefined,
      captureJobId: selectedCaptureJob?.id,
    });

  const captureObjectCustomAttributeDeleteMutation =
    APIRequests.CaptureObjectCustomAttribute.Mutations.useDelete();

  const captureObjectCustomAttributePutMutation =
    APIRequests.CaptureObjectCustomAttribute.Mutations.usePut();

  const { subscribe } = useGlobalStore();

  const createEffectFn = useCallback(
    (captureObject: SpecialCaptureObject) => {
      if (!selectedCaptureJob) return;
      const captureId = selectedCaptureJob.captureId;
      const captureJobId = selectedCaptureJob.id;

      captureObject.captureId = captureId;
      captureObject.captureJobId = captureJobId;
      captureObjectPostMutation.mutate({
        data: captureObject,
      });
    },
    [selectedCaptureJob, captureObjectPostMutation]
  );

  const deleteEffectFn = useCallback(
    async (captureObjectId: string) => {
      if (!selectedCaptureJob) return;

      const co = annotationCaptureObjects?.find(
        (co) => co.id === Number(captureObjectId)
      );

      if ((co?.captureObjectCustomAttributes?.length ?? 0) > 0) {
        const promises: Promise<unknown>[] = [];

        co?.captureObjectCustomAttributes?.forEach((attr) => {
          if (!attr?.id) return;
          promises.push(
            captureObjectCustomAttributeDeleteMutation.mutateAsync({
              id: attr.id,
            })
          );
        });

        await Promise.allSettled(promises);
      }
      await captureObjectDeleteMutation.mutateAsync({
        id: Number(captureObjectId),
      });
    },
    [selectedCaptureJob, annotationCaptureObjects]
  );

  const updateColorEffectFn = useCallback(
    ({ id, color }: { id: number; color: string }) => {
      const co = annotationCaptureObjects?.find((co) => co.id === Number(id));
      if (!co?.captureObjectCustomAttributes) return;
      const customAttribute = co.captureObjectCustomAttributes.find(
        (attr) => attr.attributeName === 'color'
      );
      if (!customAttribute?.id) return;
      customAttribute.attributeValue = color;
      captureObjectCustomAttributePutMutation.mutate({
        data: customAttribute,
        id: customAttribute.id,
      });
    },
    [annotationCaptureObjects]
  );

  const updatePositionEffectFn = useCallback(
    ({ id, position }: { id: number; position: Vector3[] | Vector3 }) => {
      const co = annotationCaptureObjects?.find((co) => co.id === Number(id));
      if (!co?.geom2D?.coordinates || !co?.id) return;
      co.geom2D = {
        ...co.geom2D,
        coordinates: position,
      } as unknown as Coordinate;
      captureObjectPutMutation.mutate({
        data: co,
        id: co.id,
      });
    },
    [selectedCaptureJob, annotationCaptureObjects]
  );

  const updateCaptureObjectProperty = useCallback(
    ({
      id,
      value,
      property,
    }: {
      id: number;
      value: string;
      property: AllowedKeys;
    }) => {
      const co: APIModels.CaptureObject | undefined =
        annotationCaptureObjects?.find((co) => co.id === Number(id));
      if (!co || !co?.id) return;
      co[property] = value || '';
      captureObjectPutMutation.mutate({
        data: co,
        id: co.id,
      });
    },
    [annotationCaptureObjects]
  );

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_CREATE,
      createEffectFn
    );

    return unsubscribe;
  }, [selectedCaptureJob]);

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_POSITION,
      updatePositionEffectFn
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_DELETE,
      deleteEffectFn
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  // capture object custom attribute - color
  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_COLOR,
      updateColorEffectFn
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  //name
  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_NAME,
      ({ id, name }: { id: number; name: string }) => {
        updateCaptureObjectProperty({ id, value: name, property: 'name' });
      }
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  //description
  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_DESCRIPTION,
      ({ id, description }: { id: number; description: string }) => {
        updateCaptureObjectProperty({
          id,
          value: description,
          property: 'description',
        });
      }
    );
    return unsubscribe;
  }, [selectedCaptureJob, annotationCaptureObjects]);

  const setSearchCallback = useCallback(
    (id: number) => {
      if (search.get('details') === 'annotations') {
        return;
      }
      setSearch(
        (prev) => {
          prev.set('details', 'annotations');
          return prev;
        },
        {
          replace: true,
        }
      );
    },
    [setSearch]
  );

  useEffect(() => {
    const unsubscribe = subscribe(
      EffectNames.CAPTURE_OBJECT_WAS_CLICKED,
      setSearchCallback
    );
    return unsubscribe;
  }, [setSearchCallback]);

  return null;
};
