import { useQuery } from '@tanstack/react-query';
import compare from 'trivial-compare';

import { APIClient, APIUtils } from '../..';

interface IuseGetByCaptureJobId {
  captureJobId?: number;
  enabled?: boolean;
}

export const useGetAllByCaptureJobId = ({
  captureJobId,
  enabled = true,
}: IuseGetByCaptureJobId) => {
  return APIClient.useGetCaptureImagesByCaptureJobId(Number(captureJobId), {
    query: {
      queryKey: [
        APIUtils.QueryKey.captureJobs,
        { captureJobId: captureJobId },
        APIUtils.QueryKey.captureImages,
      ],
      enabled: Number.isInteger(Number(captureJobId ?? undefined)) && enabled,
      select: (data) =>
        data
          ?.filter((image) => image.x && image.y && image.z && image.id)
          ?.sort((a, b) => compare(a?.id, b?.id)),
    },
  });
};

interface IuseGetThumbnailById {
  captureImageId?: number;
  size: `${number}x${number}` | 'Original';
  enabled?: boolean;
}

export const useGetThumbnailById = ({
  captureImageId,
  size,
  enabled = true,
}: IuseGetThumbnailById) => {
  return useQuery({
    queryKey: [APIUtils.QueryKey.thumborImage, { size, captureImageId }],
    queryFn: async () => {
      const response = await APIClient.getCaptureImageThumbnailById2(
        captureImageId as number,
        size === 'Original' ? ' ' : 'fit-in',
        size === 'Original' ? ' ' : size,
        ' ',
        ' ',
        ' ',
        ' ',
        {
          responseType: 'blob',
        }
      );

      if (response.type === 'text/plain') {
        throw new Error('Failed to fetch image');
      }
      return URL.createObjectURL(response);
    },
    enabled:
      Number.isSafeInteger(Number(captureImageId ?? undefined)) && enabled,
    gcTime: APIUtils.getDuration({
      minutes: 1,
    }),
  });
};

interface IuseGetMetadataById {
  captureImageId?: number;
  downloadUrl?: string;
  enabled?: boolean;
}

export const useGetMetadataById = ({
  captureImageId,
  downloadUrl,
  enabled = true,
}: IuseGetMetadataById) => {
  return useQuery({
    queryKey: [
      APIUtils.QueryKey.captureImageMetadata,
      { captureImageId: Number(captureImageId) },
    ],
    queryFn: async () => {
      const fileName = downloadUrl?.substring(downloadUrl?.indexOf('/') + 1);

      if (!fileName || !downloadUrl) {
        throw new Error('Failed to fetch image');
      }

      const blob = await fetch(downloadUrl).then((r) => r.blob());
      const file = new File([blob], fileName);

      const elementMetadata = await new Promise<{
        resolution?: string;
      }>((resolve) => {
        const imageElement = new Image();
        imageElement.onload = () => {
          resolve({
            resolution: `${imageElement.naturalWidth}x${imageElement.naturalHeight}`,
          });
          imageElement.remove();
        };
        imageElement.onerror = () => {
          resolve({});
          imageElement.remove();
        };
        imageElement.src = downloadUrl;
      });

      const requestMetadata = await new Promise<{
        type?: string;
      }>((resolve) => {
        fetch(downloadUrl)
          .then((response) => {
            return response.blob();
          })
          .then((blob) => {
            const type = blob.type.split('/')[1].toUpperCase();
            resolve({ type });
          })
          .catch(() => {
            resolve({});
          });
      });

      return {
        fileSize: file.size,
        resolution: elementMetadata.resolution,
        type: requestMetadata.type,
      };
    },
    enabled:
      Number.isSafeInteger(Number(captureImageId ?? undefined)) && enabled,
  });
};
