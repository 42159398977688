import { ReactNode } from 'react';

export interface HeadingProps {
  level: number;
  children?: ReactNode;
  truncate?: boolean;
}

export function Heading({ level, children, truncate = false }: HeadingProps) {
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
  let className = 'font-bold leading-none';
  switch (level) {
    case 1:
      className += ' text-xl';
      break;
    default:
      throw new Error(`Unsupported heading level ${level}.`);
  }
  if (truncate) {
    className += ' truncate';
  }

  return <HeadingTag className={className}>{children}</HeadingTag>;
}
