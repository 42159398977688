
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';  // Import TWEEN

import * as Potree from "../Potree.js";

import { ClipTask, ClipMethod, CameraMode, LengthUnits, ElevationGradientRepeat } from "../defines.js";
import { Renderer } from "../PotreeRenderer.js";
import { PotreeRenderer } from "./PotreeRenderer.js";
import { EDLRenderer } from "./EDLRenderer.js";
import { HQSplatRenderer } from "./HQSplatRenderer.js";
import { Scene } from "./Scene.js";
import { ClippingTool } from "../utils/ClippingTool.js";
import { TransformationTool } from "../utils/TransformationTool.js";
import { Utils } from "../utils.js";
// import { MapView } from "./map.js";
// import { ProfileWindow, ProfileWindowController } from "./profile.js";
import { BoxVolume } from "../utils/Volume.js";
import { Features } from "../Features.js";
// import { Message } from "../utils/Message.js";
// import { Sidebar } from "./sidebar.js";

import { AnnotationTool } from "../utils/AnnotationTool.js";
import { MeasuringTool } from "../utils/MeasuringTool.js";
// import { ProfileTool } from "../utils/ProfileTool.js";
import { VolumeTool } from "../utils/VolumeTool.js";

import { InputHandler } from "../navigation/InputHandler.js";
// import { NavigationCube } from "./NavigationCube.js";
// import { Compass } from "../utils/Compass.js";
import { OrbitControls } from "../navigation/OrbitControls.js";
import { FirstPersonControls } from "../navigation/FirstPersonControls.js";
// import { EarthControls } from "../navigation/EarthControls.js";
// import { DeviceOrientationControls } from "../navigation/DeviceOrientationControls.js";
// import { VRControls } from "../navigation/VRControls.js";
import { EventDispatcher } from "../EventDispatcher.js";
import { ClassificationScheme } from "../materials/ClassificationScheme.js";
// import { VRButton } from '../../libs/three.js/extra/VRButton.js';

// import JSON5 from "../../libs/json5-2.1.3/json5.mjs";


export class Viewer extends EventDispatcher {

	constructor(domElement, args = {}) {
		super();

		this.renderArea = domElement;
		this.guiLoaded = false;
		this.guiLoadTasks = [];

		this.onVrListeners = [];

		this.messages = [];

		try {
			this.pointCloudLoadedCallback = args.onPointCloudLoaded || function () { };

			this.server = null;

			this.fov = 60;
			this.isFlipYZ = false;
			this.useDEMCollisions = false;
			this.generateDEM = false;
			this.minNodeSize = 30;
			this.edlStrength = 1.0;
			this.edlRadius = 1.4;
			this.edlOpacity = 1.0;
			this.useEDL = false;
			this.description = "";

			this.classifications = ClassificationScheme.DEFAULT;

			this.moveSpeed = 10;

			this.lengthUnit = LengthUnits.METER;
			this.lengthUnitDisplay = LengthUnits.METER;

			this.showBoundingBox = false;
			this.showAnnotations = true;
			this.freeze = false;
			this.clipTask = ClipTask.HIGHLIGHT;
			this.clipMethod = ClipMethod.INSIDE_ANY;

			this.elevationGradientRepeat = ElevationGradientRepeat.CLAMP;

			this.filterReturnNumberRange = [0, 7];
			this.filterNumberOfReturnsRange = [0, 7];
			this.filterGPSTimeRange = [-Infinity, Infinity];
			this.filterPointSourceIDRange = [0, 65535];

			this.potreeRenderer = null;
			this.edlRenderer = null;
			this.renderer = null;
			this.pRenderer = null;

			this.scene = null;
			this.sceneVR = null;
			this.overlay = null;
			this.overlayCamera = null;

			this.inputHandler = null;
			this.controls = null;

			this.clippingTool = null;
			this.transformationTool = null;
			this.navigationCube = null;
			this.compass = null;

			this.skybox = null;
			this.clock = new THREE.Clock();
			this.background = null;

			this.initThree();

			// if (args.noDragAndDrop) {

			// } else {
			// 	this.initDragAndDrop();
			// }

			if (typeof Stats !== "undefined") {
				this.stats = new Stats();
				this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
				document.body.appendChild(this.stats.dom);
			}

			{
				let canvas = this.renderer.domElement;
				canvas.addEventListener("webglcontextlost", (e) => {
					this.postMessage("WebGL context lost. \u2639");
					if (this.renderer?.getContext) {

						let gl = this.renderer?.getContext();
						let error = gl.getError();
						console.log(error);
					}
				}, false);
			}

			{
				this.overlay = new THREE.Scene();
				this.overlayCamera = new THREE.OrthographicCamera(
					0, 1,
					1, 0,
					-1000, 1000
				);
			}

			this.pRenderer = new Renderer(this.renderer);

			{
				let near = 2.5;
				let far = 10.0;
				let fov = 90;

				this.shadowTestCam = new THREE.PerspectiveCamera(90, 1, near, far);
				this.shadowTestCam.position.set(3.50, -2.80, 8.561);
				this.shadowTestCam.lookAt(new THREE.Vector3(0, 0, 4.87));
			}


			let scene = new Scene(this.renderer);
			this.setScene(scene);

			{
				this.inputHandler = new InputHandler(this);
				this.inputHandler.setScene(this.scene);

				this.clippingTool = new ClippingTool(this);
				this.transformationTool = new TransformationTool(this);
				// this.navigationCube = new NavigationCube(this);
				// this.navigationCube.visible = false;

				// this.compass = new Compass(this);

				this.createControls();

				this.clippingTool.setScene(this.scene);

				let onPointcloudAdded = (e) => {
					if (this.scene.pointclouds.length === 1) {
						let speed = e.pointcloud.boundingBox.getSize(new THREE.Vector3()).length();
						speed = speed / 5;
						this.setMoveSpeed(speed);
					}
				};

				let onVolumeRemoved = (e) => {
					this.inputHandler.deselect(e.volume);
				};

				this.addEventListener('scene_changed', (e) => {
					this.inputHandler.setScene(e.scene);
					this.clippingTool.setScene(this.scene);

					if (!e.scene.hasEventListener("pointcloud_added", onPointcloudAdded)) {
						e.scene.addEventListener("pointcloud_added", onPointcloudAdded);
					}

					if (!e.scene.hasEventListener("volume_removed", onPointcloudAdded)) {
						e.scene.addEventListener("volume_removed", onVolumeRemoved);
					}

				});

				this.scene.addEventListener("volume_removed", onVolumeRemoved);
				this.scene.addEventListener('pointcloud_added', onPointcloudAdded);
			}

			{ // set defaults
				this.setFOV(60);
				this.setEDLEnabled(false);
				this.setEDLRadius(1.4);
				this.setEDLStrength(0.4);
				this.setEDLOpacity(1.0);
				this.setClipTask(ClipTask.SHOW_INSIDE);
				this.setClipMethod(ClipMethod.INSIDE_ANY);
				this.setPointBudget(1 * 1000 * 1000);
				this.setShowBoundingBox(false);
				this.setFreeze(false);
				this.setControls(this.orbitControls);
				this.setBackground('gradient');

				this.scaleFactor = 1;

			}

			this.renderer.setAnimationLoop(this.loop.bind(this));

			this.annotationTool = new AnnotationTool(this);
			this.measuringTool = new MeasuringTool(this);
			// this.profileTool = new ProfileTool(this);
			this.volumeTool = new VolumeTool(this);

		} catch (e) {
			console.log(e)
			console.log("ERROR: Potree Viewer constructor failed!");
		}
	}

	// ------------------------------------------------------------------------------------
	// Viewer API
	// ------------------------------------------------------------------------------------

	setScene(scene) {
		if (scene === this.scene) {
			return;
		}

		let oldScene = this.scene;
		this.scene = scene;

		this.dispatchEvent({
			type: 'scene_changed',
			oldScene: oldScene,
			scene: scene
		});

		// { // Annotations
		// 	$('.annotation').detach();

		// 	// for(let annotation of this.scene.annotations){
		// 	//	this.renderArea.appendChild(annotation.domElement[0]);
		// 	// }

		// 	this.scene.annotations.traverse(annotation => {
		// 		this.renderArea.appendChild(annotation.domElement[0]);
		// 	});

		// 	if (!this.onAnnotationAdded) {
		// 		this.onAnnotationAdded = e => {
		// 			// console.log("annotation added: " + e.annotation.title);

		// 			e.annotation.traverse(node => {

		// 				$("#potree_annotation_container").append(node.domElement);
		// 				//this.renderArea.appendChild(node.domElement[0]);
		// 				node.scene = this.scene;
		// 			});
		// 		};
		// 	}

		// 	if (oldScene) {
		// 		oldScene.annotations.removeEventListener('annotation_added', this.onAnnotationAdded);
		// 	}
		// 	this.scene.annotations.addEventListener('annotation_added', this.onAnnotationAdded);
		// }
	};

	pauseControls() {
		this.stopDragging = true;
	}
	unPauseControls() {
		this.stopDragging = false;

	}

	setControls(controls) {
		if (controls !== this.controls) {
			if (this.controls) {
				this.controls.enabled = false;
				this.inputHandler.removeInputListener(this.controls);
			}

			this.controls = controls;
			this.controls.enabled = true;
			this.inputHandler.addInputListener(this.controls);
		}
	}

	getControls() {

		if (this.renderer.xr.isPresenting) {
			return this.vrControls;
		} else {
			return this.controls;
		}

	}

	getMinNodeSize() {
		return this.minNodeSize;
	};

	setMinNodeSize(value) {
		if (this.minNodeSize !== value) {
			this.minNodeSize = value;
			this.dispatchEvent({ 'type': 'minnodesize_changed', 'viewer': this });
		}
	};

	getBackground() {
		return this.background;
	}

	setBackground(bg) {
		if (this.background === bg) {
			return;
		}

		// if (bg === "skybox") {
		// 	this.skybox = Utils.loadSkybox(new URL(Potree.resourcePath + '/textures/skybox2/').href);
		// }

		this.background = bg;
		this.dispatchEvent({ 'type': 'background_changed', 'viewer': this });
	}

	setShowBoundingBox(value) {
		if (this.showBoundingBox !== value) {
			this.showBoundingBox = value;
			this.dispatchEvent({ 'type': 'show_boundingbox_changed', 'viewer': this });
		}
	};

	getShowBoundingBox() {
		return this.showBoundingBox;
	};

	setMoveSpeed(value) {
		if (this.moveSpeed !== value) {
			this.moveSpeed = value;
			this.dispatchEvent({ 'type': 'move_speed_changed', 'viewer': this, 'speed': value });
		}
	};

	getMoveSpeed() {
		return this.moveSpeed;
	};

	setWeightClassification(w) {
		for (let i = 0; i < this.scene.pointclouds.length; i++) {
			this.scene.pointclouds[i].material.weightClassification = w;
			this.dispatchEvent({ 'type': 'attribute_weights_changed' + i, 'viewer': this });
		}
	};

	setFreeze(value) {
		value = Boolean(value);
		if (this.freeze !== value) {
			this.freeze = value;
			this.dispatchEvent({ 'type': 'freeze_changed', 'viewer': this });
		}
	};

	getFreeze() {
		return this.freeze;
	};

	getClipTask() {
		return this.clipTask;
	}

	getClipMethod() {
		return this.clipMethod;
	}

	setClipTask(value) {
		if (this.clipTask !== value) {

			this.clipTask = value;

			this.dispatchEvent({
				type: "cliptask_changed",
				viewer: this
			});
		}
	}

	setClipMethod(value) {
		if (this.clipMethod !== value) {

			this.clipMethod = value;

			this.dispatchEvent({
				type: "clipmethod_changed",
				viewer: this
			});
		}
	}

	setElevationGradientRepeat(value) {
		if (this.elevationGradientRepeat !== value) {

			this.elevationGradientRepeat = value;

			this.dispatchEvent({
				type: "elevation_gradient_repeat_changed",
				viewer: this
			});
		}
	}

	setPointBudget(value) {
		if (Potree.pointBudget !== value) {
			Potree.setPointBudget(parseInt(value));
			this.dispatchEvent({ 'type': 'point_budget_changed', 'viewer': this });
		}
	};

	getPointBudget() {
		return Potree.pointBudget;
	};

	setShowAnnotations(value) {
		if (this.showAnnotations !== value) {
			this.showAnnotations = value;
			this.dispatchEvent({ 'type': 'show_annotations_changed', 'viewer': this });
		}
	}

	getShowAnnotations() {
		return this.showAnnotations;
	}

	setDEMCollisionsEnabled(value) {
		if (this.useDEMCollisions !== value) {
			this.useDEMCollisions = value;
			this.dispatchEvent({ 'type': 'use_demcollisions_changed', 'viewer': this });
		};
	};

	getDEMCollisionsEnabled() {
		return this.useDEMCollisions;
	};

	setEDLEnabled(value) {
		value = Boolean(value) && Features.SHADER_EDL.isSupported();

		if (this.useEDL !== value) {
			this.useEDL = value;
			this.dispatchEvent({ 'type': 'use_edl_changed', 'viewer': this });
		}
	};

	getEDLEnabled() {
		return this.useEDL;
	};

	setEDLRadius(value) {
		if (this.edlRadius !== value) {
			this.edlRadius = value;
			this.dispatchEvent({ 'type': 'edl_radius_changed', 'viewer': this });
		}
	};

	getEDLRadius() {
		return this.edlRadius;
	};

	setEDLStrength(value) {
		if (this.edlStrength !== value) {
			this.edlStrength = value;
			this.dispatchEvent({ 'type': 'edl_strength_changed', 'viewer': this });
		}
	};

	getEDLStrength() {
		return this.edlStrength;
	};

	setEDLOpacity(value) {
		if (this.edlOpacity !== value) {
			this.edlOpacity = value;
			this.dispatchEvent({ 'type': 'edl_opacity_changed', 'viewer': this });
		}
	};

	getEDLOpacity() {
		return this.edlOpacity;
	};

	setFOV(value) {
		if (this.fov !== value) {
			this.fov = value;
			this.dispatchEvent({ 'type': 'fov_changed', 'viewer': this });
		}
	};

	getFOV() {
		return this.fov;
	};

	disableAnnotations() {
		this.scene.annotations.traverse(annotation => {
			annotation.domElement.css('pointer-events', 'none');

			// return annotation.visible;
		});
	};

	enableAnnotations() {
		this.scene.annotations.traverse(annotation => {
			annotation.domElement.css('pointer-events', 'auto');

			// return annotation.visible;
		});
	}

	setClassifications(classifications) {
		this.classifications = classifications;

		this.dispatchEvent({ 'type': 'classifications_changed', 'viewer': this });
	}

	setClassificationVisibility(key, value) {
		if (!this.classifications[key]) {
			this.classifications[key] = { visible: value, name: 'no name' };
			this.dispatchEvent({ 'type': 'classification_visibility_changed', 'viewer': this });
		} else if (this.classifications[key].visible !== value) {
			this.classifications[key].visible = value;
			this.dispatchEvent({ 'type': 'classification_visibility_changed', 'viewer': this });
		}
	}

	toggleAllClassificationsVisibility() {

		let numVisible = 0;
		let numItems = 0;
		for (const key of Object.keys(this.classifications)) {
			if (this.classifications[key].visible) {
				numVisible++;
			}
			numItems++;
		}

		let visible = true;
		if (numVisible === numItems) {
			visible = false;
		}

		let somethingChanged = false;

		for (const key of Object.keys(this.classifications)) {
			if (this.classifications[key].visible !== visible) {
				this.classifications[key].visible = visible;
				somethingChanged = true;
			}
		}

		if (somethingChanged) {
			this.dispatchEvent({ 'type': 'classification_visibility_changed', 'viewer': this });
		}
	}

	setLengthUnit(value) {
		switch (value) {
			case 'm':
				this.lengthUnit = LengthUnits.METER;
				this.lengthUnitDisplay = LengthUnits.METER;
				break;
			case 'ft':
				this.lengthUnit = LengthUnits.FEET;
				this.lengthUnitDisplay = LengthUnits.FEET;
				break;
			case 'in':
				this.lengthUnit = LengthUnits.INCH;
				this.lengthUnitDisplay = LengthUnits.INCH;
				break;
		}

		this.dispatchEvent({ 'type': 'length_unit_changed', 'viewer': this, value: value });
	};

	setLengthUnitAndDisplayUnit(lengthUnitValue, lengthUnitDisplayValue) {
		switch (lengthUnitValue) {
			case 'm':
				this.lengthUnit = LengthUnits.METER;
				break;
			case 'ft':
				this.lengthUnit = LengthUnits.FEET;
				break;
			case 'in':
				this.lengthUnit = LengthUnits.INCH;
				break;
		}

		switch (lengthUnitDisplayValue) {
			case 'm':
				this.lengthUnitDisplay = LengthUnits.METER;
				break;
			case 'ft':
				this.lengthUnitDisplay = LengthUnits.FEET;
				break;
			case 'in':
				this.lengthUnitDisplay = LengthUnits.INCH;
				break;
		}

		this.dispatchEvent({ 'type': 'length_unit_changed', 'viewer': this, value: lengthUnitValue });
	};

	zoomTo(node, factor, animationDuration = 0) {
		let view = this.scene.view;

		let camera = this.scene.cameraP.clone();
		camera.rotation.copy(this.scene.cameraP.rotation);
		camera.rotation.order = "ZXY";
		camera.rotation.x = Math.PI / 2 + view.pitch;
		camera.rotation.z = view.yaw;
		camera.updateMatrix();
		camera.updateMatrixWorld();
		camera.zoomTo(node, factor);

		let bs;
		if (node.boundingSphere) {
			bs = node.boundingSphere;
		} else if (node.geometry && node.geometry.boundingSphere) {
			bs = node.geometry.boundingSphere;
		} else {
			bs = node.boundingBox.getBoundingSphere(new THREE.Sphere());
		}
		bs = bs.clone().applyMatrix4(node.matrixWorld);

		let startPosition = view.position.clone();
		let endPosition = camera.position.clone();
		let startTarget = view.getPivot();
		let endTarget = bs.center;
		let startRadius = view.radius;
		let endRadius = endPosition.distanceTo(endTarget);

		let easing = TWEEN.Easing.Quartic.Out;

		{ // animate camera position
			let pos = startPosition.clone();
			let tween = new TWEEN.Tween(pos).to(endPosition, animationDuration);
			tween.easing(easing);

			tween.onUpdate(() => {
				view.position.copy(pos);
			});

			tween.start();
		}

		{ // animate camera target
			let target = startTarget.clone();
			let tween = new TWEEN.Tween(target).to(endTarget, animationDuration);
			tween.easing(easing);
			tween.onUpdate(() => {
				view.lookAt(target);
			});
			tween.onComplete(() => {
				view.lookAt(target);
				this.dispatchEvent({ type: 'focusing_finished', target: this });
			});

			this.dispatchEvent({ type: 'focusing_started', target: this });
			tween.start();
		}
	};

	getBoundingBox(pointclouds) {
		return this.scene.getBoundingBox(pointclouds);
	};

	fitToScreen(factor = 1, animationDuration = 0) {
		let box = this.getBoundingBox(this.scene.pointclouds);

		let node = new THREE.Object3D();
		node.boundingBox = box;

		this.zoomTo(node, factor, animationDuration);
		this.controls.stop();
	};

	toggleNavigationCube() {
		this.navigationCube.visible = !this.navigationCube.visible;
	}

	setCameraMode(mode) {
		this.scene.cameraMode = mode;

		for (let pointcloud of this.scene.pointclouds) {
			pointcloud.material.useOrthographicCamera = mode == CameraMode.ORTHOGRAPHIC;
		}
	}
	// ------------------------------------------------------------------------------------
	// Viewer Internals
	// ------------------------------------------------------------------------------------

	createControls() {
		// { // create FIRST PERSON CONTROLS
		// 	this.fpControls = new FirstPersonControls(this);
		// 	this.fpControls.enabled = false;
		// 	// this.fpControls.addEventListener('start', this.disableAnnotations.bind(this));
		// 	// this.fpControls.addEventListener('end', this.enableAnnotations.bind(this));
		// }

		{
			// create ORBIT CONTROLS
			this.orbitControls = new OrbitControls(this);
			this.orbitControls.enabled = false;
			// this.orbitControls.addEventListener(
			// 	'start',
			// 	this.disableAnnotations.bind(this)
			// );
			// this.orbitControls.addEventListener(
			// 	'end',
			// 	this.enableAnnotations.bind(this)
			// );
		}
	};


	initThree() {

		// console.log(`initializing three.js ${THREE.REVISION}`);

		let width = this.renderArea.clientWidth;
		let height = this.renderArea.clientHeight;

		let contextAttributes = {
			alpha: true,
			depth: true,
			stencil: false,
			antialias: false,
			//premultipliedAlpha: _premultipliedAlpha,
			preserveDrawingBuffer: true,
			powerPreference: "high-performance",
		};

		// let contextAttributes = {
		// 	alpha: false,
		// 	preserveDrawingBuffer: true,
		// };

		// let contextAttributes = {
		// 	alpha: false,
		// 	preserveDrawingBuffer: true,
		// };

		let canvas = document.createElement("canvas");

		let context = canvas.getContext('webgl', contextAttributes);

		this.renderer = new THREE.WebGLRenderer({
			alpha: true,
			premultipliedAlpha: false,
			canvas: canvas,
			context: context
		});
		this.renderer.sortObjects = false;
		this.renderer.setSize(width, height);
		this.renderer.autoClear = false;
		this.renderArea.appendChild(this.renderer.domElement);
		this.renderer.domElement.tabIndex = '2222';
		this.renderer.domElement.style.position = 'absolute';
		this.renderer.domElement.addEventListener('mousedown', () => {
			this.renderer.domElement.focus();
		});
		//this.renderer.domElement.focus();

		// NOTE: If extension errors occur, pass the string into this.renderer.extensions.get(x) before enabling
		// enable frag_depth extension for the interpolation shader, if available
		let gl = this.renderer.getContext();
		gl.getExtension('EXT_frag_depth');
		gl.getExtension('WEBGL_depth_texture');
		gl.getExtension('WEBGL_color_buffer_float'); 	// Enable explicitly for more portability, EXT_color_buffer_float is the proper name in WebGL 2

		if (gl.createVertexArray == null) {
			let extVAO = gl.getExtension('OES_vertex_array_object');

			if (!extVAO) {
				throw new Error("OES_vertex_array_object extension not supported");
			}

			gl.createVertexArray = extVAO.createVertexArrayOES.bind(extVAO);
			gl.bindVertexArray = extVAO.bindVertexArrayOES.bind(extVAO);
		}

	}

	updateMaterialDefaults(pointcloud) {
		// PROBLEM STATEMENT:
		// * [min, max] of intensity, source id, etc. are computed as point clouds are loaded
		// * the point cloud material won't know the range it should use until some data is loaded
		// * users can modify the range at runtime, but sensible default ranges should be 
		//   applied even if no GUI is present
		// * display ranges shouldn't suddenly change even if the actual range changes over time.
		//   e.g. the root node has intensity range [1, 478]. One of the descendants increases range to 
		//   [0, 2047]. We should not automatically change to the new range because that would result
		//   in sudden and drastic changes of brightness. We should adjust the min/max of the sidebar slider.

		const material = pointcloud.material;

		const attIntensity = pointcloud.getAttribute("intensity");

		if (attIntensity != null && material.intensityRange[0] === Infinity) {
			material.intensityRange = [...attIntensity.range];
		}

		// const attIntensity = pointcloud.getAttribute("intensity");
		// if(attIntensity && material.intensityRange[0] === Infinity){
		// 	material.intensityRange = [...attIntensity.range];
		// }

		// let attributes = pointcloud.getAttributes();

		// for(let attribute of attributes.attributes){
		// 	if(attribute.range){
		// 		let range = [...attribute.range];
		// 		material.computedRange.set(attribute.name, range);
		// 		//material.setRange(attribute.name, range);
		// 	}
		// }


	}

	update(delta, timestamp) {

		// if (Potree.measureTimings) performance.mark("update-start");

		this.dispatchEvent({
			type: 'update_start',
			delta: delta,
			timestamp: timestamp
		});


		const scene = this.scene;
		const camera = scene.getActiveCamera();
		const visiblePointClouds = this.scene.pointclouds.filter(pc => pc.visible)

		Potree.setPointLoadLimit(Potree.pointBudget * 2);

		const lTarget = camera.position.clone().add(camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(1000));
		this.scene.directionalLight.position.copy(camera.position);
		this.scene.directionalLight.lookAt(lTarget);


		for (let pointcloud of visiblePointClouds) {

			pointcloud.showBoundingBox = this.showBoundingBox;
			pointcloud.generateDEM = this.generateDEM;
			pointcloud.minimumNodePixelSize = this.minNodeSize;

			let material = pointcloud.material;

			material.uniforms.uFilterReturnNumberRange.value = this.filterReturnNumberRange;
			material.uniforms.uFilterNumberOfReturnsRange.value = this.filterNumberOfReturnsRange;
			material.uniforms.uFilterGPSTimeClipRange.value = this.filterGPSTimeRange;
			material.uniforms.uFilterPointSourceIDClipRange.value = this.filterPointSourceIDRange;

			material.classification = this.classifications;
			material.recomputeClassification();

			this.updateMaterialDefaults(pointcloud);
		}

		{
			if (this.showBoundingBox) {
				let bbRoot = this.scene.scene.getObjectByName("potree_bounding_box_root");
				if (!bbRoot) {
					let node = new THREE.Object3D();
					node.name = "potree_bounding_box_root";
					this.scene.scene.add(node);
					bbRoot = node;
				}

				let visibleBoxes = [];
				for (let pointcloud of this.scene.pointclouds) {
					for (let node of pointcloud.visibleNodes.filter(vn => vn.boundingBoxNode !== undefined)) {
						let box = node.boundingBoxNode;
						visibleBoxes.push(box);
					}
				}

				bbRoot.children = visibleBoxes;
			}
		}

		if (!this.freeze) {
			let result = Potree.updatePointClouds(scene.pointclouds, camera, this.renderer);


			// DEBUG - ONLY DISPLAY NODES THAT INTERSECT MOUSE
			//if(false){ 

			//	let renderer = viewer.renderer;
			//	let mouse = viewer.inputHandler.mouse;

			//	let nmouse = {
			//		x: (mouse.x / renderer.domElement.clientWidth) * 2 - 1,
			//		y: -(mouse.y / renderer.domElement.clientHeight) * 2 + 1
			//	};

			//	let pickParams = {};

			//	//if(params.pickClipped){
			//	//	pickParams.pickClipped = params.pickClipped;
			//	//}

			//	pickParams.x = mouse.x;
			//	pickParams.y = renderer.domElement.clientHeight - mouse.y;

			//	let raycaster = new THREE.Raycaster();
			//	raycaster.setFromCamera(nmouse, camera);
			//	let ray = raycaster.ray;

			//	for(let pointcloud of scene.pointclouds){
			//		let nodes = pointcloud.nodesOnRay(pointcloud.visibleNodes, ray);
			//		pointcloud.visibleNodes = nodes;

			//	}
			//}

			// const tStart = performance.now();
			// const worldPos = new THREE.Vector3();
			// const camPos = viewer.scene.getActiveCamera().getWorldPosition(new THREE.Vector3());
			// let lowestDistance = Infinity;
			// let numNodes = 0;

			// viewer.scene.scene.traverse(node => {
			// 	node.getWorldPosition(worldPos);

			// 	const distance = worldPos.distanceTo(camPos);

			// 	lowestDistance = Math.min(lowestDistance, distance);

			// 	numNodes++;

			// 	if(Number.isNaN(distance)){
			// 		console.error(":(");
			// 	}
			// });
			// const duration = (performance.now() - tStart).toFixed(2);

			// Potree.debug.computeNearDuration = duration;
			// Potree.debug.numNodes = numNodes;

			//console.log(lowestDistance.toString(2), duration);

			const tStart = performance.now();
			const campos = camera.position;
			let closestImage = Infinity;
			for (const images of this.scene.orientedImages) {
				for (const image of images.images) {
					const distance = image.mesh.position.distanceTo(campos);

					closestImage = Math.min(closestImage, distance);
				}
			}
			const tEnd = performance.now();

			if (result.lowestSpacing !== Infinity) {
				let near = result.lowestSpacing * 10.0;
				let far = -this.getBoundingBox().applyMatrix4(camera.matrixWorldInverse).min.z;

				far = Math.max(far * 1.5, 10000);
				near = Math.min(100.0, Math.max(0.01, near));
				near = Math.min(near, closestImage);
				far = Math.max(far, near + 10000);

				if (near === Infinity) {
					near = 0.1;
				}

				camera.near = near;
				camera.far = far;
			} else {
				// don't change near and far in this case
			}

			if (this.scene.cameraMode == CameraMode.ORTHOGRAPHIC) {
				camera.near = -camera.far;
			}
		}

		this.scene.cameraP.fov = this.fov;

		let controls = this.getControls();
		if (controls === this.deviceControls) {
			this.controls.setScene(scene);
			this.controls.update(delta);

			this.scene.cameraP.position.copy(scene.view.position);
			this.scene.cameraO.position.copy(scene.view.position);
		} else if (controls !== null) {
			controls.setScene(scene);
			controls.update(delta);

			if (typeof debugDisabled === "undefined") {
				this.scene.cameraP.position.copy(scene.view.position);
				this.scene.cameraP.rotation.order = "ZXY";
				this.scene.cameraP.rotation.x = Math.PI / 2 + this.scene.view.pitch;
				this.scene.cameraP.rotation.z = this.scene.view.yaw;
			}

			this.scene.cameraO.position.copy(scene.view.position);
			this.scene.cameraO.rotation.order = "ZXY";
			this.scene.cameraO.rotation.x = Math.PI / 2 + this.scene.view.pitch;
			this.scene.cameraO.rotation.z = this.scene.view.yaw;
		}

		camera.updateMatrix();
		camera.updateMatrixWorld();
		camera.matrixWorldInverse.copy(camera.matrixWorld).invert();

		{
			if (this._previousCamera === undefined) {
				this._previousCamera = this.scene.getActiveCamera().clone();
				this._previousCamera.rotation.copy(this.scene.getActiveCamera().rotation);
			}

			if (!this._previousCamera.matrixWorld.equals(camera.matrixWorld)) {
				this.dispatchEvent({
					type: "camera_changed",
					previous: this._previousCamera,
					camera: camera
				});
			} else if (!this._previousCamera.projectionMatrix.equals(camera.projectionMatrix)) {
				this.dispatchEvent({
					type: "camera_changed",
					previous: this._previousCamera,
					camera: camera
				});
			}

			this._previousCamera = this.scene.getActiveCamera().clone();
			this._previousCamera.rotation.copy(this.scene.getActiveCamera().rotation);

		}

		{ // update clip boxes
			let boxes = [];

			// volumes with clipping enabled
			//boxes.push(...this.scene.volumes.filter(v => (v.clip)));
			boxes.push(...this.scene.volumes.filter(v => (v.clip && v instanceof BoxVolume)));

			// profile segments
			for (let profile of this.scene.profiles) {
				boxes.push(...profile.boxes);
			}

			// Needed for .getInverse(), pre-empt a determinant of 0, see #815 / #816
			let degenerate = (box) => box.matrixWorld.determinant() !== 0;

			let clipBoxes = boxes.filter(degenerate).map(box => {
				box.updateMatrixWorld();

				let boxInverse = box.matrixWorld.clone().invert();
				let boxPosition = box.getWorldPosition(new THREE.Vector3());

				return { box: box, inverse: boxInverse, position: boxPosition };
			});

			let clipPolygons = this.scene.polygonClipVolumes.filter(vol => vol.initialized);

			// set clip volumes in material
			for (let pointcloud of visiblePointClouds) {
				pointcloud.material.setClipBoxes(clipBoxes);
				// pointcloud.material.setClipPolygons(clipPolygons, this.clippingTool.maxPolygonVertices);
				pointcloud.material.clipTask = this.clipTask;
				pointcloud.material.clipMethod = this.clipMethod;
			}
		}

		{
			for (let pointcloud of visiblePointClouds) {
				pointcloud.material.elevationGradientRepeat = this.elevationGradientRepeat;
			}
		}

		{ // update navigation cube
			// this.navigationCube.update(camera.rotation);
		}

		// this.updateAnnotations();

		TWEEN.update(timestamp);

		this.dispatchEvent({
			type: 'update',
			delta: delta,
			timestamp: timestamp
		});

		// if (Potree.measureTimings) {
		// 	performance.mark("update-end");
		// 	performance.measure("update", "update-start", "update-end");
		// }
	}

	getPRenderer() {
		if (this.useHQ) {
			if (!this.hqRenderer) {
				this.hqRenderer = new HQSplatRenderer(this);
			}
			this.hqRenderer.useEDL = this.useEDL;

			return this.hqRenderer;
		} else {
			if (this.useEDL && Features.SHADER_EDL.isSupported()) {
				if (!this.edlRenderer) {
					this.edlRenderer = new EDLRenderer(this);
				}

				return this.edlRenderer;
			} else {
				if (!this.potreeRenderer) {
					this.potreeRenderer = new PotreeRenderer(this);
				}

				return this.potreeRenderer;
			}
		}
	}

	renderDefault() {
		let pRenderer = this.getPRenderer();

		{ // resize
			const width = this.scaleFactor * this.renderArea.clientWidth;
			const height = this.scaleFactor * this.renderArea.clientHeight;

			this.renderer.setSize(width, height);
			const pixelRatio = this.renderer.getPixelRatio();
			const aspect = width / height;

			const scene = this.scene;

			scene.cameraP.aspect = aspect;
			scene.cameraP.updateProjectionMatrix();

			let frustumScale = this.scene.view.radius;
			scene.cameraO.left = -frustumScale;
			scene.cameraO.right = frustumScale;
			scene.cameraO.top = frustumScale * 1 / aspect;
			scene.cameraO.bottom = -frustumScale * 1 / aspect;
			scene.cameraO.updateProjectionMatrix();

			scene.cameraScreenSpace.top = 1 / aspect;
			scene.cameraScreenSpace.bottom = -1 / aspect;
			scene.cameraScreenSpace.updateProjectionMatrix();
		}

		pRenderer.clear();

		pRenderer.render(this.renderer);
		this.renderer.render(this.overlay, this.overlayCamera);
	}

	render() {
		// if (Potree.measureTimings) performance.mark("render-start");

		try {

			// const vrActive = false;

			// if (vrActive) {
			// 	this.renderVR();
			// } else {
			this.renderDefault();
			// }

		} catch (e) {
			console.log(e);
		}
	}

	resolveTimings(timestamp) {
		// if (Potree.measureTimings) {
		// 	if (!this.toggle) {
		// 		this.toggle = timestamp;
		// 	}
		// 	let duration = timestamp - this.toggle;
		// 	if (duration > 1000.0) {

		// 		let measures = performance.getEntriesByType("measure");

		// 		let names = new Set();
		// 		for (let measure of measures) {
		// 			names.add(measure.name);
		// 		}

		// 		let groups = new Map();
		// 		for (let name of names) {
		// 			groups.set(name, {
		// 				measures: [],
		// 				sum: 0,
		// 				n: 0,
		// 				min: Infinity,
		// 				max: -Infinity
		// 			});
		// 		}

		// 		for (let measure of measures) {
		// 			let group = groups.get(measure.name);
		// 			group.measures.push(measure);
		// 			group.sum += measure.duration;
		// 			group.n++;
		// 			group.min = Math.min(group.min, measure.duration);
		// 			group.max = Math.max(group.max, measure.duration);
		// 		}

		// 		let glQueries = Potree.resolveQueries(this.renderer.getContext());
		// 		for (let [key, value] of glQueries) {

		// 			let group = {
		// 				measures: value.map(v => { return { duration: v } }),
		// 				sum: value.reduce((a, i) => a + i, 0),
		// 				n: value.length,
		// 				min: Math.min(...value),
		// 				max: Math.max(...value)
		// 			};

		// 			let groupname = `[tq] ${key}`;
		// 			groups.set(groupname, group);
		// 			names.add(groupname);
		// 		}

		// 		for (let [name, group] of groups) {
		// 			group.mean = group.sum / group.n;
		// 			group.measures.sort((a, b) => a.duration - b.duration);

		// 			if (group.n === 1) {
		// 				group.median = group.measures[0].duration;
		// 			} else if (group.n > 1) {
		// 				group.median = group.measures[parseInt(group.n / 2)].duration;
		// 			}

		// 		}

		// 		let cn = Array.from(names).reduce((a, i) => Math.max(a, i.length), 0) + 5;
		// 		let cmin = 10;
		// 		let cmed = 10;
		// 		let cmax = 10;
		// 		let csam = 6;

		// 		let message = ` ${"NAME".padEnd(cn)} |`
		// 			+ ` ${"MIN".padStart(cmin)} |`
		// 			+ ` ${"MEDIAN".padStart(cmed)} |`
		// 			+ ` ${"MAX".padStart(cmax)} |`
		// 			+ ` ${"SAMPLES".padStart(csam)} \n`;
		// 		message += ` ${"-".repeat(message.length)}\n`;

		// 		names = Array.from(names).sort();
		// 		for (let name of names) {
		// 			let group = groups.get(name);
		// 			let min = group.min.toFixed(3);
		// 			let median = group.median.toFixed(3);
		// 			let max = group.max.toFixed(3);
		// 			let n = group.n;

		// 			message += ` ${name.padEnd(cn)} |`
		// 				+ ` ${min.padStart(cmin)} |`
		// 				+ ` ${median.padStart(cmed)} |`
		// 				+ ` ${max.padStart(cmax)} |`
		// 				+ ` ${n.toString().padStart(csam)}\n`;
		// 		}
		// 		message += `\n`;
		// 		console.log(message);

		// 		performance.clearMarks();
		// 		performance.clearMeasures();
		// 		this.toggle = timestamp;
		// 	}
		// }
	}

	loop(timestamp) {

		if (this.stats) {
			this.stats.begin();
		}

		// if (Potree.measureTimings) {
		// 	performance.mark("loop-start");
		// }

		this.update(this.clock.getDelta(), timestamp);
		this.render();

		// let vrActive = viewer.renderer.xr.isPresenting;
		// if(vrActive){
		// 	this.update(this.clock.getDelta(), timestamp);
		// 	this.render();
		// }else{

		// 	this.update(this.clock.getDelta(), timestamp);
		// 	this.render();
		// }


		// if (Potree.measureTimings) {
		// 	performance.mark("loop-end");
		// 	performance.measure("loop", "loop-start", "loop-end");
		// }

		// this.resolveTimings(timestamp);


		Potree.setFrameNumber(Potree.framenumber + 1);
		if (this.stats) {
			this.stats.end();
		}
	}

	postError(content, params = {}) {
		return;
		let message = this.postMessage(content, params);

		message.element.addClass("potree_message_error");

		return message;
	}

	postMessage(content, params = {}) {

		// let message = new Message(content);

		// let animationDuration = 100;

		// message.element.css("display", "none");
		// message.elClose.click(() => {
		// 	message.element.slideToggle(animationDuration);

		// 	let index = this.messages.indexOf(message);
		// 	if (index >= 0) {
		// 		this.messages.splice(index, 1);
		// 	}
		// });

		// this.elMessages.prepend(message.element);

		// message.element.slideToggle(animationDuration);

		// this.messages.push(message);

		// if (params.duration !== undefined) {
		// 	let fadeDuration = 500;
		// 	let slideOutDuration = 200;
		// 	setTimeout(() => {
		// 		message.element.animate({
		// 			opacity: 0
		// 		}, fadeDuration);
		// 		message.element.slideToggle(slideOutDuration);
		// 	}, params.duration)
		// }

		// return message;
	}
};
