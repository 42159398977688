import { faFilterSlash } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { usePageTitle, useQueryState } from '@agerpoint/utilities';

import { useAdminCropsQueries } from './admin-crops-queries';

export const AdminCropsList = () => {
  const navigate = useNavigate();

  usePageTitle(() => 'Platform - Crops', []);
  const [nameAndUuidFilter, setNameAndUuidFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v,
    toUrlParam: (v) => v,
  });

  const { cropsQuery } = useAdminCropsQueries();

  const filteredCrops = useMemo(() => {
    if (cropsQuery?.data === null) {
      return undefined;
    }

    let data = cropsQuery.data ? [...cropsQuery.data] : undefined;

    const filter = nameAndUuidFilter.trim().toLowerCase();
    if (filter !== '') {
      data = data?.filter(
        (d) =>
          d.cropName?.toLowerCase().includes(filter) ||
          d.cropDisplayName?.toLowerCase().includes(filter) ||
          d.pluralCropName?.toLowerCase().includes(filter)
      );
    }

    return data?.sort((a, b) =>
      (a.cropName ?? '')
        .toLowerCase()
        .localeCompare((b.cropName ?? '').toLowerCase())
    );
  }, [nameAndUuidFilter, cropsQuery]);

  const hasFiltersApplied = useMemo(
    () => !!((nameAndUuidFilter?.length ?? 0) > 0),
    [nameAndUuidFilter]
  );

  const clearFilters = useCallback(() => {
    setNameAndUuidFilter('');
  }, []);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="flex flex-row gap-1 justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">Crops</h1>
        <Button.Primary
          id="crops-new-button"
          label="New Crop"
          icon={faPlus}
          onClick={() => {
            navigate('new', {
              state: {
                params: window.location.search,
              },
            });
          }}
        />
      </div>
      <div className="px-4 flex flex-row gap-2 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="crops-search-input"
            value={nameAndUuidFilter}
            setValue={setNameAndUuidFilter}
            placeholder="Search by Name, Display Name or Crop Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 h-full w-full">
        <Datatable
          id="admin-crps-list-datatable"
          data={filteredCrops ?? []}
          style={{ ...dataTableAgerStyle, tableMinWidth: 650 }}
          loading={cropsQuery.isLoading || filteredCrops === undefined}
          cellOnClick={() => {
            return (row) => {
              navigate(`${row.id}/details`, {
                state: {
                  params: window.location.search,
                },
              });
            };
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching crops',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No crops yet',
                  message: 'Create crop to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Crop',
                }
          }
          error={
            cropsQuery.isError
              ? {
                  title: 'There was a problem loading crops',
                  message: 'Try refreshing the page',
                  action: () => cropsQuery.refetch(),
                }
              : undefined
          }
          columns={[
            {
              label: 'Name',
              value: (row) => row.cropName,
              name: 'cropName',
            },
            {
              label: 'Display Name',
              value: (row) => row.cropDisplayName,
              name: 'cropDisplayName',
            },
            {
              label: 'Plural Name',
              value: (row) => row.pluralCropName,
              name: 'pluralCropName',
            },
          ]}
          rowHeight={50}
        />
      </div>
    </div>
  );
};
