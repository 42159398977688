import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { BreadCrumbs, Button, TabsComponent } from '@agerpoint/component';
import { TabNames, TabProps } from '@agerpoint/types';
import { useIsViteApp, usePageTitle } from '@agerpoint/utilities';

import { useAdminOrganizationsQueries } from './admin-organizations-queries';

export const AdminOrganizationsBase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  usePageTitle(() => 'Platform - Organizations', []);

  const tabs: TabProps[] = [
    {
      label: 'Details',
      link: 'details',
      type: TabNames.LINK,
      params,
    },
    {
      label: 'Analytics',
      link: isViteApp ? 'analytics' : '../analytics',
      type: TabNames.LINK,
      params,
    },
    {
      label: 'License',
      link: isViteApp ? 'license' : '../license',
      type: TabNames.LINK,
      params,
    },
  ];

  const { organizationQuery } = useAdminOrganizationsQueries();

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Organizations',
              path: isViteApp
                ? '/app/admin/platform/organizations'
                : '/admin/organizations',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="organization-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/organizations' + params);
            } else {
              navigate('/admin/organizations' + params);
            }
          }}
        />

        <h1 className="text-3xl font-bold">
          {organizationQuery.data?.customerDisplayName ??
            organizationQuery.data?.customerName}
        </h1>
      </div>
      <div className="px-4">
        <TabsComponent tabs={tabs} />
      </div>
      <Outlet />
    </div>
  );
};
