import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminMLModelsQueries } from './admin-ml-models-queries';

export const AdminMLModelsNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  usePageTitle(() => 'Platform - ML Models', []);

  const isViteApp = useIsViteApp();

  const { permissions } = useGlobalStore();

  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );

  useEffect(() => {
    if (!canManageAnalyticRequests) {
      if (isViteApp) {
        navigate('/app/admin/platform/ml-models' + params);
      } else {
        navigate('/admin/ml-models' + params);
      }
    }
  }, [canManageAnalyticRequests]);

  const { pipelinesQuery, mlModelPostMutation } = useAdminMLModelsQueries();

  const formValidation = useFormValidation();
  const [mlModel, setMlModel] = useState<APIModels.MlModel>({});
  const [selectedPipeline, setSelectedPipeline] =
    useState<APIModels.Pipeline>();

  useEffect(() => {
    setMlModel((prev) => ({ ...prev, pipelineId: selectedPipeline?.id }));
  }, [selectedPipeline]);

  const createMlModel = useCallback(async () => {
    if (mlModelPostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    mlModelPostMutation.mutate({
      data: mlModel,
    });
  }, [mlModel, formValidation, mlModelPostMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'ML Models',
              path: isViteApp
                ? '/app/admin/platform/ml-models'
                : '/admin/ml-models',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-ml-model-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/ml-models' + params);
            } else {
              navigate('/admin/ml-models' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New ML Model</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="ml-model-name-input"
          label={<Input.Label label="Name" required />}
          value={mlModel.name || ''}
          setValue={(name) => {
            setMlModel({ ...mlModel, name });
          }}
          error={
            <Input.Error error={formValidation.errors['ml-model-name-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
        <Input.Text.Single
          id="ml-model-display-name-input"
          label={<Input.Label label="Display Name" required />}
          value={mlModel.displayName || ''}
          setValue={(displayName) => {
            setMlModel({ ...mlModel, displayName });
          }}
          error={
            <Input.Error
              error={formValidation.errors['ml-model-display-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Display Name')],
          }}
        />
        <Input.Text.Single
          id="ml-model-description-input"
          label={<Input.Label label="Description" />}
          value={mlModel.description || ''}
          setValue={(description) => {
            setMlModel({ ...mlModel, description });
          }}
        />
        <Input.Text.Single
          id="ml-model-version-input"
          label={<Input.Label label="Version" />}
          value={mlModel.version || ''}
          setValue={(version) => {
            setMlModel({ ...mlModel, version });
          }}
        />
        <Input.Select.Single
          id="ml-model-pipeline-input"
          title="Pipeline"
          options={pipelinesQuery.data ?? []}
          optionBuilder={(pipeline) => pipeline?.name || ''}
          loading={pipelinesQuery.isLoading}
          label={<Input.Label label="Pipeline" />}
          value={selectedPipeline}
          setValue={setSelectedPipeline}
        />
        <div className="w-full flex flex-row justify-end items-center py-4">
          <Button.Primary
            id="create-ml-model-button"
            label={'Create'}
            onClick={createMlModel}
            loading={mlModelPostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
