import {
  IconButton,
  PrimaryButton,
  SidebarSpinner,
  ColorDefaults,
} from '@agerpoint/component';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ProjectSidebarSpinner = () => {
  return <SidebarSpinner message="Loading attributes" />;
};

export const ProjectLoading = () => {
  return <SidebarSpinner message="Loading Project" />;
};

export const ProjectIconButton = (props: { onClick: () => void }) => {
  return (
    <IconButton
      show
      onClick={props.onClick}
      icon={
        <FontAwesomeIcon
          icon={faTimes}
          className="text-2xl text-gray-800 hover:text-gray-900"
        />
      }
    />
  );
};

export const ProjectPrimaryButton = (props: {
  size: 'small' | 'medium' | 'large';
  theme: 'danger' | 'white';
  disabled: boolean;
  label: string;
  onClick: () => void;
}) => {
  return (
    <PrimaryButton
      size={props.size}
      theme={props.theme}
      disabled={props.disabled}
      label={props.label}
      onClicked={props.onClick}
    />
  );
};

export enum ProjectDefaultColors {
  grey = ColorDefaults.grey,
}
