import { IEnvironment } from '@agerpoint/types';

export const environment: IEnvironment = {
  app_version: process.env.VERSION || '0.0.0',
  production: false,
  oidc_log_level: 0,

  app_url: (process.env.NX_APP_URL as string) || 'AGERCLOUD_APP_URL',

  api_server_url:
    (process.env.NX_API_SERVER_URL as string) || 'AGERCLOUD_API_SERVER_URL',

  authentication_server_url:
    (process.env.NX_AUTHENTICATION_SERVER_URL as string) ||
    'AGERCLOUD_AUTHENTICATION_SERVER_URL',

  bing_api_key:
    (process.env.NX_BING_API_KEY as string) || 'AGERCLOUD_BING_API_KEY',

  ld_client_key:
    (process.env.NX_LD_CLIENT_KEY as string) || 'AGERCLOUD_LD_CLIENT_KEY',

  mixpanel_token:
    (process.env.NX_MIXPANEL_TOKEN as string) || 'AGERCLOUD_MIXPANEL_TOKEN',

  courier_client_id:
    (process.env.NX_COURIER_CLIENT_ID as string) ||
    'AGERCLOUD_COURIER_CLIENT_KEY',
};
