import { Gradient } from '@agerpoint/types';
import * as chromaticScales from 'd3-scale-chromatic';

export function getGradientColors(gradient: Gradient, numberOfColors = 12) {
  const interpolator = getInterpolator(gradient, numberOfColors);

  const colors = [];
  for (let i = 0; i < numberOfColors; ++i) {
    colors.push(interpolator(i));
  }

  return colors;
}

export const availableGradients = Object.keys(Gradient)
  .filter((key) => isNaN(Number(key)))
  .map((gradient) => gradient as unknown as Gradient);

/**
 * Given a color scale and a number of bins (classes),
 * returns a function that calculates a bin's color
 * from its zero-based index.
 * @param gradient gradient color scale to use
 * @param numberBins number of bins (classes)
 * @returns a function that calculates a bin's color given its index
 */
export function getInterpolator(
  gradient: Gradient,
  numberBins: number
): (x: number) => string {
  const interpolatorFns: Record<string, (x: number) => string> = {
    Magma: chromaticScales.interpolateMagma,
    RdYlGn: chromaticScales.interpolateRdYlGn,
    Viridis: chromaticScales.interpolateViridis,
    YlGnBu: chromaticScales.interpolateYlGnBu,
    Greens: chromaticScales.interpolateGreens,
    Custom: chromaticScales.interpolateRainbow,
  };

  const interpolatorFn = interpolatorFns[gradient];
  if (!interpolatorFn) throw new Error(`Unknown gradient ${gradient}.`);

  const step = 1 / numberBins;
  return (x) => interpolatorFn(x * step + step / 2);
}

interface LinearGradientProps {
  colors: Gradient;
  className: string;
  style?: React.CSSProperties;
}

export function LinearGradient({
  colors,
  className,
  style,
}: LinearGradientProps) {
  const colorsArray = getGradientColors(colors);
  return (
    <div
      className={className}
      style={{
        background: `linear-gradient(0.25turn, ${colorsArray.join()})`,
        ...style,
      }}
    />
  );
}
