import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import { useIsViteApp, useLookupTable, useToasts } from '@agerpoint/utilities';

export const useAdminOrthomosaicsQueries = (
  filter?: APIModels.ImageMosaicFilter
) => {
  const toasts = useToasts();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const orthomosaicsQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.orthomosaics,
      APIUtils.QueryKey.infinite,
      { filter },
    ],
    queryFn: async ({ pageParam }) =>
      APIClient.getFilteredPagedImageMosaics(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.ImageMosaicFilter
      ),
    enabled: filter !== undefined,
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    staleTime: APIUtils.getDuration({
      seconds: 20,
    }),
  });

  const orthomosaicsDeleteMutation = APIClient.useDeleteImageMosaic({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.orthomosaics],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityArchived('orthomosaic'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to archive orthomosaic!'));
      },
    },
  });

  const orthomosaicPostMutation = APIClient.usePostImageMosaic({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.orthomosaics],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityCreated('orthomosaic'));

        if (isViteApp) {
          navigate('/app/admin/platform/orthomosaics' + params);
        } else {
          navigate(`/admin/orthomosaics` + params);
        }
      },
      onError: (e) => {
        console.error(e);
        toasts.add(toasts.prepare.error('Failed to create orthomosaic!'));
      },
    },
  });
  return {
    organizationsLookupTable,
    orthomosaicsQuery,
    orthomosaicsDeleteMutation,
    organizationsQuery,
    orthomosaicPostMutation,
  };
};
