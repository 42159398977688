import {
  faCheck,
  faCircleNotch,
  faPenToSquare,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseGetReturn } from 'restful-react';
import { Vector3 } from 'three';

import {
  CaptureObject,
  CaptureObjectFilter,
  useChangeFilteredCaptureObjectsValidationFromCaptureExtractionJob,
  useGetCaptureObjectsByCaptureExtractionJobId,
  usePutCaptureObjectById,
} from '@agerpoint/api';
import { PrimaryButton } from '@agerpoint/component';
import { EffectNames, ViewerType } from '@agerpoint/types';
import {
  markerColorPalette,
  useGlobalStore,
  useToasts,
} from '@agerpoint/utilities';

import { IThreeDViewerControllerGroup } from '../../three-d-wrapper';
import { QAQCAttributeRenameModal } from '../qaqc-attribute-rename-modal/qaqc-attribute-rename-modal';
import { QAQCBulkValidationModal } from '../qaqc-bulk-validation-modal/qaqc-bulk-validation-modal';
import { QaqcCaptureObjectCard } from './qaqc-capture-object-card';
import { QaqcCaptureObjectFilters } from './qaqc-capture-object-filters';

export interface QAQCCaptureObject extends CaptureObject {
  attributesCount: number;
  validatedAttributesCount: number;
}

export interface QAQCValidationLoadingState {
  objectIds: number[];
  attributeIds: number[];
}

export interface QAQCChangeValidationProps {
  objectIds?: number[];
  attributeIds?: number[];
  value: boolean;
}

interface QaqcCaptureObjectsListProps {
  extId?: string;
  viewerController?: IThreeDViewerControllerGroup;
}

export const QaqcCaptureObjectsList = ({
  extId,
  viewerController,
}: QaqcCaptureObjectsListProps) => {
  const {
    data: captureObjectsData,
    loading: loadingCaptureObjectsData,
    refetch: refetchCaptureObjects,
    cancel: cancelLastGetCaptureObjectsData,
  } = useGetCaptureObjectsByCaptureExtractionJobId({
    id: extId ? +extId : NaN,
    lazy: true,
  }) as unknown as UseGetReturn<CaptureObject[], void, void, unknown>;

  const toasts = useToasts();

  const { mutate: putCaptureObjectById } = usePutCaptureObjectById({ id: NaN });

  const { twoDimensionDrawingIsActive } = useGlobalStore();

  useEffect(() => {
    if (!twoDimensionDrawingIsActive) {
      setTwoDFilter(false);
    }
  }, [twoDimensionDrawingIsActive]);

  useEffect(() => {
    if (!extId) return;
    refetchCaptureObjects();
    return () => {
      cancelLastGetCaptureObjectsData();
    };
  }, [extId]);

  const { mutate: postValidationChange } =
    useChangeFilteredCaptureObjectsValidationFromCaptureExtractionJob({
      captureExtractionJobId: extId ? +extId : NaN,
      validated: true,
    });

  const [captureObjects, setCaptureObjects] = useState<CaptureObject[]>([]);

  const [nameFilter, setNameFilter] = useState<string>('');
  const [attributeFilter, setAttributeFilter] = useState<string>('');
  const [hideValidatedFilter, setHideValidatedFilter] =
    useState<boolean>(false);
  const [twoDFilter, setTwoDFilter] = useState<boolean>(false);

  const [validationModalProps, setValidationModalProps] = useState<{
    validation: boolean;
  }>();
  const [showAttributeRenameModal, setShowAttributeRenameModal] =
    useState(false);
  const [twoDBounds, setTwoDBounds] = useState<Vector3[]>([]);

  const {
    subscribe,
    actions: { setTwoDimensionDrawing, setCaptureObjectMarkers },
    captureObjectMarkers,
  } = useGlobalStore();

  useEffect(() => {
    subscribe(EffectNames.SET_2D_POLYGON, (val: Vector3[]) => {
      setTwoDBounds(val);
    });
  }, [setTwoDBounds]);

  useEffect(() => {
    if (!twoDFilter) {
      if (viewerController?.viewerType === ViewerType.Potree) {
        setTwoDimensionDrawing(false);
        viewerController?.potreeController?.stopObjectSelection();
        viewerController?.potreeController?.resetAllObjectMarkers();
      }
    }
  }, [twoDFilter]);

  const processedObjects = useMemo<QAQCCaptureObject[]>(() => {
    let objects = cloneDeep(captureObjects) as unknown as QAQCCaptureObject[];

    objects.forEach((o) => {
      o.attributesCount = o.captureObjectCustomAttributes?.length ?? 0;
      o.validatedAttributesCount =
        o.captureObjectCustomAttributes?.reduce((prev, curr) => {
          return curr.validated ? prev + 1 : prev;
        }, 0) ?? 0;
    });

    if (nameFilter) {
      objects = objects.filter((o) => {
        return (o?.name ?? '').toLowerCase().includes(nameFilter.toLowerCase());
      });
    }
    if (attributeFilter) {
      const filter = attributeFilter
        .split(',')
        .map((f) => f.trim().toLowerCase())
        .filter((f) => f);

      objects.forEach((o) => {
        o.captureObjectCustomAttributes =
          o.captureObjectCustomAttributes?.filter((a) => {
            const name = a.attributeName?.toLowerCase();

            const idx = filter.findIndex((f) => name?.includes(f));

            return idx !== -1;
          });
      });

      objects = objects.filter((o) => {
        return (o.captureObjectCustomAttributes?.length ?? 0) > 0;
      });
    }
    if (hideValidatedFilter) {
      objects = objects.filter((o) => !o.validated);
    }
    if (twoDFilter) {
      if (viewerController?.viewerType !== ViewerType.Potree) return [];
      const highlightedObjectIds = viewerController?.potreeController
        ?.getHighlightedMarkers()
        ?.map((id) => +id);

      if (highlightedObjectIds && highlightedObjectIds.length > 0) {
        objects = objects.filter((o) => {
          if (!o.id) {
            return false;
          }

          return highlightedObjectIds.includes(o.id);
        });
      }
    }
    return objects;
  }, [
    nameFilter,
    attributeFilter,
    hideValidatedFilter,
    captureObjects,
    twoDFilter,
    twoDBounds,
    viewerController,
  ]);

  const [loadingState, setLoadingState] = useState<QAQCValidationLoadingState>({
    objectIds: [],
    attributeIds: [],
  });

  const changeDisplayName = useCallback(
    (originalName: string, newName: string) => {
      setCaptureObjects((prev) => {
        prev.forEach((o) => {
          o.captureObjectCustomAttributes?.forEach((a) => {
            if (a.attributeName === originalName) {
              a.attributeDisplayName = newName;
            }
          });
        });

        return [...prev];
      });
    },
    []
  );

  const changeValidation = useCallback(
    async ({
      objectIds = [],
      attributeIds = [],
      value,
    }: QAQCChangeValidationProps) => {
      setLoadingState((prev) => {
        prev.attributeIds = [...prev.attributeIds, ...attributeIds];
        prev.objectIds = [...prev.objectIds, ...objectIds];
        return { ...prev };
      });

      try {
        const body: CaptureObjectFilter = {
          captureObjectCustomAttributeIds: attributeIds.length
            ? attributeIds
            : undefined,
          captureObjectIds: objectIds.length ? objectIds : [],
        };

        if (!extId) {
          throw new Error('No ext id!');
        }

        await postValidationChange(body, {
          pathParams: { captureExtractionJobId: +extId, validated: value },
        });

        setCaptureObjects((prev) => {
          prev.forEach((o) => {
            if (o.id && objectIds.includes(o.id)) {
              o.validated = value;
            }
            o.captureObjectCustomAttributes?.forEach((a) => {
              if (a.id && attributeIds.includes(a.id)) {
                a.validated = value;
              }
            });
          });

          return [...prev];
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingState((prev) => {
          prev.attributeIds = prev.attributeIds.filter(
            (a) => !attributeIds.includes(a)
          );
          prev.objectIds = prev.objectIds.filter((o) => !objectIds.includes(o));
          return { ...prev };
        });
      }
    },
    [extId, postValidationChange]
  );

  const renameObject = useCallback(
    async (object: QAQCCaptureObject, value: string) => {
      try {
        const data = { ...object, name: value };
        delete data.captureObjectCustomAttributes;

        await putCaptureObjectById(data, {
          pathParams: {
            id: object.id as number,
          },
        });

        setCaptureObjectMarkers(
          captureObjectMarkers.map((o) =>
            o.id === object.id
              ? {
                  ...o,
                  name: value,
                }
              : o
          )
        );

        setCaptureObjects((prev) =>
          prev.map((o) =>
            o.id === object.id
              ? {
                  ...o,
                  name: value,
                }
              : o
          )
        );

        toasts.add(toasts.prepare.entityUpdated('capture object'));
      } catch (e) {
        console.error(e);
        toasts.add(toasts.prepare.error('Failed to rename capture object!'));
      }
    },
    [putCaptureObjectById, captureObjectMarkers]
  );

  useEffect(() => {
    if (captureObjectsData) {
      setCaptureObjects([...captureObjectsData]);
    }
  }, [captureObjectsData]);

  const getColor = useCallback((idx: number) => {
    const paletteLength = markerColorPalette.length;
    return markerColorPalette[idx % paletteLength];
  }, []);

  const filtersUsed = useMemo(() => {
    return (
      hideValidatedFilter ||
      twoDFilter ||
      nameFilter.length > 0 ||
      attributeFilter.length > 0
    );
  }, [hideValidatedFilter, nameFilter, attributeFilter, twoDFilter]);

  const loading = useMemo(() => {
    return (
      loadingState.attributeIds.length > 0 || loadingState.objectIds.length > 0
    );
  }, [loadingState]);

  const attributeNames = useMemo(() => {
    return [
      ...new Set(
        captureObjects
          .flatMap((o) => o.captureObjectCustomAttributes)
          .map((a) => a?.attributeName ?? 'Unknown')
      ),
    ];
  }, [captureObjects]);

  return (
    <div className="w-full h-full flex flex-col gap-1">
      <div className="w-full flex flex-row justify-end gap-1">
        <PrimaryButton
          disabled={captureObjects.length === 0}
          label={
            <span className="w-full">
              Rename
              <br />
              Attribute
            </span>
          }
          icon={<FontAwesomeIcon icon={faPenToSquare} />}
          size="x-small"
          className="w-1/3 py-1"
          onClicked={() => {
            setShowAttributeRenameModal(true);
          }}
        />
        <PrimaryButton
          disabled={processedObjects.length === 0}
          label={
            <span className="w-full">
              Validate
              <br />
              {filtersUsed ? 'Filtered' : 'Everything'}
            </span>
          }
          icon={
            <FontAwesomeIcon
              icon={loading ? faCircleNotch : faCheck}
              spin={loading}
            />
          }
          className="w-1/3 py-1"
          size="x-small"
          onClicked={() => {
            if (loading) {
              return;
            }
            setValidationModalProps({
              validation: true,
            });
          }}
        />
        <PrimaryButton
          disabled={processedObjects.length === 0}
          label={
            <span className="w-full">
              Invalidate
              <br />
              {filtersUsed ? 'Filtered' : 'Everything'}
            </span>
          }
          icon={
            <FontAwesomeIcon
              icon={loading ? faCircleNotch : faXmark}
              spin={loading}
            />
          }
          className="w-1/3 py-1"
          size="x-small"
          theme="red"
          onClicked={() => {
            if (loading) {
              return;
            }
            setValidationModalProps({
              validation: false,
            });
          }}
        />
      </div>
      <QaqcCaptureObjectFilters
        setAttributeFilter={setAttributeFilter}
        setNameFilter={setNameFilter}
        hideValidatedFilter={hideValidatedFilter}
        setHideValidatedFilter={setHideValidatedFilter}
        setTwoDFilter={setTwoDFilter}
        twoDFilter={twoDFilter}
      />
      <div className="w-full h-full overflow-auto bg-gray-700 rounded text-xs">
        {loadingCaptureObjectsData && (
          <div className="w-full h-full flex justify-center items-center">
            <FontAwesomeIcon icon={faCircleNotch} spin className="w-8 h-8" />
          </div>
        )}
        {!loadingCaptureObjectsData && processedObjects.length === 0 && (
          <div className="w-full h-full flex justify-center items-center">
            <div className="text-sm">No Capture Objects</div>
          </div>
        )}
        {!loadingCaptureObjectsData && processedObjects.length > 0 && (
          <div className="flex flex-col w-full h-full p-1.5 gap-1.5">
            {processedObjects.map((object, key) => {
              return (
                <QaqcCaptureObjectCard
                  key={key}
                  object={object}
                  color={getColor(
                    captureObjects.findIndex((o) => o.id === object.id)
                  )}
                  viewerController={viewerController}
                  changeValidation={changeValidation}
                  loadingState={loadingState}
                  renameObject={renameObject}
                />
              );
            })}
          </div>
        )}
      </div>
      <QAQCBulkValidationModal
        open={!!validationModalProps}
        handleCloseDialog={() => {
          setValidationModalProps(undefined);
        }}
        validationModalProps={validationModalProps}
        processedObjects={processedObjects}
        changeValidation={changeValidation}
      />
      <QAQCAttributeRenameModal
        open={showAttributeRenameModal}
        handleCloseDialog={() => {
          setShowAttributeRenameModal(false);
        }}
        attributeNames={attributeNames}
        extId={extId}
        changeDisplayName={changeDisplayName}
        captureObjects={captureObjects}
      />
    </div>
  );
};
