import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';

import { AnalyticRequestStatus } from '@agerpoint/types';
import {
  getColorByStatus,
  getIconByStatus,
  stringToTitleCase,
} from '@agerpoint/utilities';

export const AnalyticStatusIcon = ({
  status,
  spin,
}: {
  status: AnalyticRequestStatus | undefined | null;
  spin?: boolean;
}) => {
  const [titleCaseStatus, setTitleCaseStatus] = useState<string>('');
  useEffect(() => {
    const string = stringToTitleCase(status);
    setTitleCaseStatus(string);
  }, [status]);
  const statusIcon = useMemo(() => {
    const i = getIconByStatus(status as AnalyticRequestStatus);
    return i ? (
      <FontAwesomeIcon
        icon={i}
        className={`flex text-white fa-sm w-full`}
        spin={spin}
      />
    ) : null;
  }, [status]);
  return (
    <div
      className="w-5 h-5 flex items-center justify-center rounded-full"
      style={{
        backgroundColor: `${getColorByStatus(status as AnalyticRequestStatus)}`,
      }}
      title={titleCaseStatus}
    >
      {statusIcon}
    </div>
  );
};
