import { APIModels } from '@agerpoint/api';

export const sortImageTime = (
  a: APIModels.CaptureImage,
  b: APIModels.CaptureImage
) => {
  if (!a.imageTime || !b.imageTime) {
    return 0;
  }
  return a.imageTime - b.imageTime;
};
