import {
  faBroom,
  faCrop,
  faMapMarkerCheck,
  faOctagonXmark,
  faTools,
  faVectorPolygon,
} from '@fortawesome/pro-light-svg-icons';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Vector3 } from 'three';

import { PrimaryButton } from '@agerpoint/component';
import { EffectNames, MixpanelNames, ViewerType } from '@agerpoint/types';
import { useGlobalStore, useToasts } from '@agerpoint/utilities';

import { Annotation2DPolygon } from '../../annotations/2d/polygon';
import { IThreeDViewerControllerGroup } from '../../three-d-wrapper';
import { QaqcButton } from '../qaqc-bottom-bars/qaqc-bottom-bars';

export const QaqcTools = ({
  viewerController,
  size = 'default',
}: {
  viewerController: IThreeDViewerControllerGroup | undefined;
  size: 'small' | 'default';
}) => {
  const unProjectedPointRef = useRef<Vector3[]>();

  const {
    actions: { setTwoDimensionDrawing, dispatchEffect, sendEvent },
    twoDimensionDrawingIsActive,
  } = useGlobalStore();
  const [showToolMenu, setShowToolMenu] = useState(false);
  const [showCropTool, setShowCropTool] = useState(false);
  const [iconClasses, setIconClasses] = useState('');
  const [show2dPolygon, setShow2dPolygon] = useState(false);
  const toasts = useToasts();

  useEffect(() => {
    if (viewerController?.viewerType !== ViewerType.Potree) return;
    setTwoDimensionDrawing(show2dPolygon);

    if (!show2dPolygon) {
      viewerController?.potreeController?.stopObjectSelection();
      viewerController?.potreeController?.resetAllObjectMarkers();
    }
  }, [show2dPolygon, setTwoDimensionDrawing]);

  useEffect(() => {
    if (!twoDimensionDrawingIsActive) {
      setShow2dPolygon(false);
      setShowToolMenu(false);
    }
  }, [twoDimensionDrawingIsActive]);

  useEffect(() => {
    const style = `${
      size === 'small' ? 'fa-sm pr-1' : 'fa-lg px-1'
    }  block m-auto cursor-pointer`;
    setIconClasses(style);
  }, [size]);

  const toggleClippingTool = () => {
    if (
      !viewerController?.potreeController ||
      viewerController.viewerType !== ViewerType.Potree
    )
      return;
    if (showCropTool) {
      viewerController?.potreeController.stopCropBox();
    } else {
      viewerController?.potreeController.startCropBox();
      sendEvent(MixpanelNames.QAQCCropBoxUsed);
    }
    setShowCropTool(!showCropTool);
  };

  const setCoordinates = useCallback(
    (coords: number[]) => {
      if (
        !viewerController?.potreeController ||
        viewerController.viewerType !== ViewerType.Potree
      )
        return;
      const screenCoords: Vector3[] = [];
      // un project all points
      // every two numbers is a point
      for (let i = 0; i < coords.length; i += 2) {
        const unProjectedPoint = new Vector3(coords[i], coords[i + 1], 0);
        screenCoords.push(unProjectedPoint);
      }
      // duplicate the first on to the last to close the polygon
      screenCoords.push(screenCoords[0]);
      viewerController.potreeController.selectObjectsByLocation2D(screenCoords);
      if (!screenCoords) return;
      const unProjectedPoints: Vector3[] = screenCoords.map((point) => {
        return viewerController?.potreeController?.get3DPointFromXYZ(
          point.x,
          point.y
        );
      }) as Vector3[];
      unProjectedPointRef.current = unProjectedPoints ?? ([] as Vector3[]);
      dispatchEffect(EffectNames.SET_2D_POLYGON, unProjectedPointRef.current);
    },
    [viewerController]
  );

  const getCornerText = useCallback(() => {
    const getCoordinates =
      viewerController?.potreeController?.getCropBoxCoordinates();
    return getCoordinates;
  }, [viewerController]);

  return (
    <div className="relative">
      <QaqcButton
        icon={faTools}
        onClick={() => {
          setShowToolMenu(!showToolMenu);
        }}
      />
      {showToolMenu && (
        <div className="z-200 absolute -top-10 right-0 flex text-black pointer-events-none">
          <div className="z-200 relative flex flex-row justify-center h-8 mr-2 ">
            {showCropTool && (
              <PrimaryButton
                className="pointer-events-auto"
                theme="none"
                size="small"
                onClicked={() => {
                  // copy to clipboard
                  const textCorners = getCornerText() ?? '';
                  navigator.clipboard.writeText(textCorners);
                  toasts.add(
                    toasts.prepare.valueCopied('crop box coordinates')
                  );
                }}
                label="Copy Crop Box"
                icon={<FontAwesomeIcon icon={faCopy} />}
              />
            )}
            {show2dPolygon && (
              <>
                <PrimaryButton
                  className="pointer-events-auto mr-2"
                  theme="white"
                  size="small"
                  onClicked={() => {
                    setShow2dPolygon(false);
                  }}
                  label="Cancel"
                  icon={<FontAwesomeIcon icon={faOctagonXmark} />}
                />
                <PrimaryButton
                  className="pointer-events-auto"
                  theme="white"
                  size="small"
                  onClicked={() => {
                    if (!unProjectedPointRef.current) return;
                    // copy all points to clipboard
                    const points = unProjectedPointRef.current
                      .map((point) => {
                        return `${point.x},${point.y},${point.z}`;
                      })
                      .join('\n');
                    navigator.clipboard.writeText(points);
                    toasts.prepare.valueCopied('polygon coordinates');
                  }}
                  label="Copy Coordinates"
                  icon={<FontAwesomeIcon icon={faVectorPolygon} />}
                />
              </>
            )}
          </div>
          <div className="flex flex-row justify-center h-8 mr-4">
            <div
              className={`relative flex bg-white overflow-visible text-center rounded px-2 pointer-events-auto `}
            >
              <FontAwesomeIcon
                icon={!showCropTool ? faCrop : faBroom}
                className={iconClasses}
                onClick={toggleClippingTool}
                title="Crop Tool"
              />
              <FontAwesomeIcon
                icon={faMapMarkerCheck}
                className={iconClasses}
                onClick={() => {
                  viewerController?.potreeController?.startObjectSelection();
                  setShow2dPolygon(true);
                  sendEvent(MixpanelNames.QAQCKonvaSelectionUsed);
                }}
                title="Select Capture Objects"
              />
            </div>
          </div>
        </div>
      )}
      {twoDimensionDrawingIsActive && (
        <Annotation2DPolygon setCoordinates={setCoordinates} />
      )}
    </div>
  );
};
