import { useCallback } from 'react';

import { DialogModal, PrimaryButton } from '@agerpoint/component';

import {
  QAQCCaptureObject,
  QAQCChangeValidationProps,
} from '../qaqc-capture-objects-list/qaqc-capture-objects';

interface QAQCBulkValidationModalProps {
  open: boolean;
  handleCloseDialog: () => void;
  validationModalProps?: {
    validation: boolean;
  };
  processedObjects: QAQCCaptureObject[];
  changeValidation: (props: QAQCChangeValidationProps) => void;
}

export const QAQCBulkValidationModal = ({
  open,
  handleCloseDialog,
  validationModalProps,
  processedObjects,
  changeValidation,
}: QAQCBulkValidationModalProps) => {
  const validationObjects = useCallback(() => {
    if (!validationModalProps) {
      return;
    }
    const objectIds = processedObjects
      .map((o) => o.id)
      .filter((o) => o) as number[];

    changeValidation({
      objectIds,
      value: validationModalProps.validation,
    });

    handleCloseDialog();
  }, [
    changeValidation,
    handleCloseDialog,
    validationModalProps,
    processedObjects,
  ]);

  const validationAttributes = useCallback(() => {
    if (!validationModalProps) {
      return;
    }
    const attributeIds = processedObjects
      .flatMap((o) => o.captureObjectCustomAttributes)
      .map((a) => a?.id)
      .filter((a) => a) as number[];

    changeValidation({
      attributeIds,
      value: validationModalProps.validation,
    });

    handleCloseDialog();
  }, [
    changeValidation,
    handleCloseDialog,
    processedObjects,
    validationModalProps,
  ]);

  const validationObjectsAndAttributes = useCallback(() => {
    if (!validationModalProps) {
      return;
    }
    const objectIds = processedObjects
      .map((o) => o.id)
      .filter((o) => o) as number[];
    const attributeIds = processedObjects
      .flatMap((o) => o.captureObjectCustomAttributes)
      .map((a) => a?.id)
      .filter((a) => a) as number[];

    changeValidation({
      objectIds,
      attributeIds,
      value: validationModalProps.validation,
    });

    handleCloseDialog();
  }, [
    changeValidation,
    handleCloseDialog,
    processedObjects,
    validationModalProps,
  ]);

  return (
    <DialogModal
      open={open}
      handleCloseDialog={handleCloseDialog}
      size={'small'}
      title={`What do you want to bulk ${
        validationModalProps?.validation ? 'validate' : 'invalidate'
      }?`}
    >
      <div className="flex flex-col w-full p-1">
        <div className="w-full flex flex-row justify-end gap-2 pt-3">
          <PrimaryButton
            label="Objects and their Attributes"
            size="small"
            onClicked={validationObjectsAndAttributes}
          />
          <PrimaryButton
            label="Objects only"
            size="small"
            onClicked={validationObjects}
          />
          <PrimaryButton
            label="Attributes only"
            size="small"
            onClicked={validationAttributes}
          />
          <PrimaryButton
            label="Cancel"
            size="small"
            theme="secondary"
            onClicked={() => {
              handleCloseDialog();
            }}
          />
        </div>
      </div>
    </DialogModal>
  );
};
