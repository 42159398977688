import {
  faCircleCheck,
  faCircleNotch,
  faFilterSlash,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as faSolidCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIModels, formatDate } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import {
  useFormValidation,
  useIsViteApp,
  useItemSelection,
  useQueryState,
} from '@agerpoint/utilities';

import { useOpsPipelineUploadsQueries } from './pipeline-uploads-queries';

export const PipelineUploadsList = () => {
  const navigate = useNavigate();

  const isViteApp = useIsViteApp();

  const [filter, setFilter] = useState<APIModels.UploadFilter>();

  const {
    uploadsQuery,
    uploadTypesQuery,
    organizationsLookupTable,
    runPipelineForUploadsMutation,
  } = useOpsPipelineUploadsQueries({
    filter,
  });

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v.trim(),
    toUrlParam: (v) => v.trim(),
  });

  const [typeFilter, setTypeFilter] = useQueryState<
    APIModels.UploadType | undefined
  >({
    paramName: 'type',
    initialValue: undefined,
    fromUrlParam: (v) => uploadTypesQuery.data?.find((x) => x.id === Number(v)),
    toUrlParam: (v) => v?.id?.toString() ?? '',
    retryInitWhen: uploadTypesQuery.isSuccess,
  });

  const [selectedCapture, setSelectedCapture] = useState<APIModels.Capture>();

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterName: nameFilter.trim(),
      orderAscending: false,
      orderBy: 'createDatetime',
    }));
  }, []);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      uploadTypeIds: typeFilter?.id ? [typeFilter?.id] : undefined,
    }));
  }, [typeFilter]);

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        filterName: nameFilter.trim(),
      }));
    },
    500,
    [nameFilter]
  );

  const uploads = useMemo(
    () => uploadsQuery.data?.pages.flatMap((page) => page) ?? [],
    [uploadsQuery.data]
  );

  const uploadSelection = useItemSelection<number, APIModels.Upload>({
    items: uploads,
    dependencies: [uploads],
    idField: 'id',
  });

  const formValidation = useFormValidation();

  const hasFiltersApplied = useMemo(
    () =>
      !!(
        (filter?.filterName?.length ?? 0) > 0 ||
        filter?.uploadTypeIds !== undefined
      ),
    [filter]
  );

  const clearFilter = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setNameFilter('');
    setTypeFilter(undefined);

    searchParams.delete('name');
    searchParams.delete('type');
    navigate(
      {
        pathname: isViteApp
          ? '/app/admin/pipelines/uploads'
          : '/ops/pipeline/uploads',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, []);

  useEffect(() => {
    if (uploadSelection.selectionSize === 0) {
      setSelectedCapture(undefined);
      formValidation.advancedCallbacks.reset();
    }
  }, [uploadSelection.selectionSize]);

  const onlyMobileCaptureUploadSelected = useMemo(
    () =>
      uploadSelection.getSelectionArray().every((x) => x.uploadTypeId === 1),
    [uploadSelection.selectionSize]
  );

  const onlyCloudWebUploadsSelected = useMemo(
    () =>
      uploadSelection.getSelectionArray().every((x) => x.uploadTypeId === 2),
    [uploadSelection.selectionSize]
  );

  const onlyCaptureHDLazFileUploadsSelected = useMemo(
    () =>
      uploadSelection.getSelectionArray().every((x) => x.uploadTypeId === 3),
    [uploadSelection.selectionSize]
  );

  const onlyOrthomosaicCOGUploadsSelected = useMemo(
    () =>
      uploadSelection.getSelectionArray().every((x) => x.uploadTypeId === 4),
    [uploadSelection.selectionSize]
  );

  const onlyExtractionGeojsonUploadsSelected = useMemo(
    () =>
      uploadSelection.getSelectionArray().every((x) => x.uploadTypeId === 5),
    [uploadSelection.selectionSize]
  );

  const onlyGeometryCollectionUploadsSelected = useMemo(
    () =>
      uploadSelection.getSelectionArray().every((x) => x.uploadTypeId === 6),
    [uploadSelection.selectionSize]
  );

  const onlyAttachmentUploadsSelected = useMemo(
    () =>
      uploadSelection.getSelectionArray().every((x) => x.uploadTypeId === 7),
    [uploadSelection.selectionSize]
  );

  const multipleTypesSelected = useMemo(() => {
    if (uploadSelection.selectionSize === 0) {
      return false;
    }

    const types = [
      onlyCaptureHDLazFileUploadsSelected,
      onlyCloudWebUploadsSelected,
      onlyExtractionGeojsonUploadsSelected,
      onlyOrthomosaicCOGUploadsSelected,
      onlyCaptureHDLazFileUploadsSelected,
      onlyGeometryCollectionUploadsSelected,
      onlyMobileCaptureUploadSelected,
      onlyAttachmentUploadsSelected,
    ];

    if (uploadSelection.selectionSize > 0 && types.every((x) => x === false)) {
      setSelectedCapture(undefined);
      return true;
    }

    return false;
  }, [
    uploadSelection.selectionSize,
    onlyCaptureHDLazFileUploadsSelected,
    onlyCloudWebUploadsSelected,
    onlyExtractionGeojsonUploadsSelected,
    onlyOrthomosaicCOGUploadsSelected,
    onlyGeometryCollectionUploadsSelected,
    onlyMobileCaptureUploadSelected,
    onlyAttachmentUploadsSelected,
  ]);

  const runOrthomosaicPipeline = useCallback(async () => {
    const confirm = window.confirm(
      `Are you sure you want to run the orthomosaic pipeline?`
    );

    if (!confirm) {
      return;
    }

    runPipelineForUploadsMutation.mutate(
      {
        uploads: uploadSelection.getSelectionArray(),
        pipelineId: 2,
      },
      {
        onSuccess: () => {
          uploadSelection.clearSelection();
        },
      }
    );
  }, [formValidation, uploadSelection.selectionSize]);

  const runLAZPipeline = useCallback(async () => {
    if (await formValidation.hasErrors()) {
      return;
    }

    const confirm = window.confirm(
      `Are you sure you want to run the LAZ pipeline?`
    );

    if (!confirm) {
      return;
    }

    runPipelineForUploadsMutation.mutate(
      {
        uploads: uploadSelection.getSelectionArray(),
        pipelineId: 4,
        captureId: selectedCapture?.id,
      },
      {
        onSuccess: () => {
          uploadSelection.clearSelection();
        },
      }
    );
  }, [formValidation, uploadSelection.selectionSize, selectedCapture]);

  const runCOGPipeline = useCallback(async () => {
    const confirm = window.confirm(
      `Are you sure you want to run the COG pipeline?`
    );

    if (!confirm) {
      return;
    }

    runPipelineForUploadsMutation.mutate(
      {
        uploads: uploadSelection.getSelectionArray(),
        pipelineId: 4,
      },
      {
        onSuccess: () => {
          uploadSelection.clearSelection();
        },
      }
    );
  }, [formValidation, uploadSelection.selectionSize, selectedCapture]);

  const runExtractionsPipeline = useCallback(async () => {
    if (await formValidation.hasErrors()) {
      return;
    }

    const confirm = window.confirm(
      `Are you sure you want to run the extractions pipeline?`
    );

    if (!confirm) {
      return;
    }

    runPipelineForUploadsMutation.mutate(
      {
        uploads: uploadSelection.getSelectionArray(),
        pipelineId: 5,
        captureId: selectedCapture?.id,
      },
      {
        onSuccess: () => {
          uploadSelection.clearSelection();
        },
      }
    );
  }, [formValidation, uploadSelection.selectionSize, selectedCapture]);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="px-4">
        {!isViteApp && (
          <BreadCrumbs
            items={[
              {
                label: 'Operations',
                path: '/ops',
              },
              {
                label: 'Pipelines',
                path: '/ops/pipeline',
              },
            ]}
          />
        )}
      </div>
      <div className="flex flex-row items-center px-4 py-2 w-full">
        <h1 className="text-3xl font-bold">
          {isViteApp ? 'Pipelines - Uploads ' : 'Uploads'}
        </h1>
        {uploadSelection.hasSelectedItems && (
          <div className="w-full flex flex-row gap-4 text-nowrap whitespace-nowrap justify-end items-center">
            {onlyCaptureHDLazFileUploadsSelected && (
              <div className="flex flex-row gap-1 w-full justify-end">
                <div className="max-w-xs w-full">
                  <Input.Capture
                    id="capture-pipeline-input"
                    value={selectedCapture}
                    setValue={setSelectedCapture}
                    placeholder="Select Capture to run LAZ Pipeline on"
                    placeholderIcon={Input.placeholderIcons.search}
                    validation={{
                      validationState: formValidation,
                      validators: [Input.validators.required('Capture')],
                    }}
                    error={
                      <Input.Error
                        error={formValidation.errors['capture-pipeline-input']}
                      />
                    }
                  />
                </div>
                <Button.Primary
                  id="capture-laz-pipeline-button"
                  label="Run LAZ Pipeline"
                  onClick={runLAZPipeline}
                  loading={runPipelineForUploadsMutation.isPending}
                />
              </div>
            )}
            {onlyOrthomosaicCOGUploadsSelected && (
              <Button.Primary
                id="capture-cog-pipeline-button"
                label="Run COG Pipeline"
                onClick={runCOGPipeline}
                loading={runPipelineForUploadsMutation.isPending}
              />
            )}
            {onlyExtractionGeojsonUploadsSelected && (
              <div className="flex flex-row gap-1 w-full justify-end">
                <div className="max-w-sm w-full">
                  <Input.Capture
                    id="capture-pipeline-input"
                    value={selectedCapture}
                    setValue={setSelectedCapture}
                    placeholder="Select Capture to run Extractions Pipeline on"
                    placeholderIcon={Input.placeholderIcons.search}
                    validation={{
                      validationState: formValidation,
                      validators: [Input.validators.required('Capture')],
                    }}
                    error={
                      <Input.Error
                        error={formValidation.errors['capture-pipeline-input']}
                      />
                    }
                  />
                </div>
                <Button.Primary
                  id="capture-extractions-pipeline-button"
                  label="Run Extractions Pipeline"
                  onClick={runExtractionsPipeline}
                  loading={runPipelineForUploadsMutation.isPending}
                />
              </div>
            )}
            {onlyCloudWebUploadsSelected && (
              <Button.Primary
                id="orthomosaic-pipeline-button"
                label="Run Orthomosaic Pipeline"
                onClick={runOrthomosaicPipeline}
                loading={runPipelineForUploadsMutation.isPending}
              />
            )}
            {multipleTypesSelected && (
              <Input.Error error="Multiple types of uploads selected" />
            )}

            <Button.Secondary
              id="clear-all-selected-captures"
              label={`Clear Selection (${uploadSelection.selectionSize})`}
              onClick={() => {
                uploadSelection.clearSelection();
              }}
              icon={faXmark}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2 px-4 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="pipeline-uploads-name-search"
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
            value={nameFilter}
            setValue={setNameFilter}
          />
        </div>
        <Input.Select.Single
          id="pipeline-uploads-type-filter"
          placeholder="Type"
          title="Type"
          options={uploadTypesQuery.data ?? []}
          value={typeFilter}
          loading={uploadTypesQuery.isLoading}
          setValue={setTypeFilter}
          optionBuilder={(o) => o.name ?? 'Unknown'}
          search={false}
        />

        <Button.ClearFilter visible={hasFiltersApplied} onClick={clearFilter} />
      </div>
      <div className="p-4 h-full">
        <Datatable
          id="pipeline-uploads-datatable"
          data={uploads ?? []}
          columns={[
            {
              label: 'Name',
              value: (row) => {
                return <span title={row.name || ''}>{row.name}</span>;
              },
              flex: 2,
              name: 'name',
              sortKey: 'name',
            },
            {
              label: 'Organization',
              value: (row) => {
                if (!row.customerId) {
                  return;
                }

                if (!organizationsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const org = organizationsLookupTable[row.customerId];

                return (
                  org?.customerDisplayName ?? org?.customerName ?? 'Unknown'
                );
              },
            },
            {
              label: 'Uploaded On',
              value: (row) => {
                return row.createDatetime
                  ? formatDate(row.createDatetime)
                  : null;
              },
              sortKey: 'createDatetime',
              flex: 0.5,
            },
            {
              label: 'Description',
              value: (row) => {
                return (
                  <span title={row.description || ''}>{row.description}</span>
                );
              },
            },
            {
              label: 'Type',
              value: (row) => {
                return row.uploadType?.name;
              },
            },
            {
              label: 'Jobs',
              value: (row) => {
                const initialized = row?.pipelineJobs?.length;
                const completed = row?.pipelineJobs?.filter((job) => {
                  return !!job?.completedDatetime;
                })?.length;
                return initialized ? `${completed}/${initialized}` : null;
              },
              flex: 0.5,
            },
            {
              label: uploadSelection.isEverythingSelected ? (
                <Button.Small
                  id="captures-list-deselect-all"
                  label={'Deselect All'}
                  onClick={() => {
                    uploadSelection.toggleSelectionEverything();
                  }}
                  icon={faSolidCircleCheck}
                  iconColor={'text-accent'}
                />
              ) : (
                <Button.Small
                  id="captures-list-select-all"
                  label={'Select All'}
                  onClick={() => {
                    uploadSelection.toggleSelectionEverything();
                  }}
                  icon={faCircleCheck}
                />
              ),
              value: (row, index) => {
                const isSelected = row.id
                  ? uploadSelection.isSelected(row.id)
                  : false;
                return (
                  <div className="p-1">
                    <Button.Icon
                      id={`captures-list-select-one-${index}`}
                      icon={isSelected ? faSolidCircleCheck : faCircleCheck}
                      onClick={(e) => {
                        if (!row.id) {
                          return;
                        }
                        if (e.shiftKey) {
                          uploadSelection.addBulkSelectionUntilItem(
                            row.id,
                            row
                          );
                        } else {
                          uploadSelection.toggleSelection(row.id, row);
                        }
                      }}
                      iconColor={isSelected ? 'text-accent' : undefined}
                    />
                  </div>
                );
              },
              flex: 0.75,
              style: { columnWrapperStyle: 'flex justify-center' },
            },
          ]}
          loading={
            uploadsQuery.isLoading ||
            uploadsQuery.isFetchingNextPage ||
            filter === undefined
          }
          error={
            uploadsQuery.isError
              ? {
                  title: 'There was a problem loading uploads',
                  message: 'Try refreshing the page',
                  action: () => uploadsQuery.refetch(),
                }
              : undefined
          }
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching uploads found',
                  message: 'Adjust your filters and try again',
                  action: clearFilter,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No uploads yet',
                  message: 'Upload files to get started',
                }
          }
          pagination={{
            threshold: 10,
            loadNextPage: () => {
              if (
                !uploadsQuery.hasNextPage ||
                uploadsQuery.isFetchingNextPage ||
                uploadsQuery.isLoading ||
                filter === undefined
              ) {
                return;
              }

              uploadsQuery.fetchNextPage();
            },
          }}
          rowHeight={50}
          style={{
            ...dataTableAgerStyle,
            tableMinWidth: 950,
          }}
          sort={{
            key: filter?.orderBy,
            order: filter?.orderAscending ? 'asc' : 'desc',
          }}
          setSort={(options) => {
            setFilter((prev) => ({
              ...prev,
              orderBy: options.key,
              orderAscending: options.order === 'asc',
            }));
          }}
        />
      </div>
    </div>
  );
};
