import { shallow } from 'zustand/shallow';

import { MultiDNDPaginatedLibraryExpansion as MultiDNDPaginatedLibraryExpansionComponent } from '@agerpoint/feature';
import { LDFlagSet, PanelsState } from '@agerpoint/types';

import { usePanels } from '../../state/use-panels';

export function LibraryExpansion({ permissions }: { permissions: LDFlagSet }) {
  const { toggleExpansion } = usePanels(getLibraryState, shallow);

  return (
    <MultiDNDPaginatedLibraryExpansionComponent
      onCancel={() => toggleExpansion({ type: 'none' })}
    />
  );
}

function getLibraryState({
  isDraggingFromLibrary,
  toggleExpansion,
}: PanelsState) {
  return {
    isDraggingFromLibrary,
    toggleExpansion,
  };
}
