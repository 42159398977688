import { useQueryClient } from '@tanstack/react-query';

import { APIClient, APIUtils } from '../..';

export const usePut = () => {
  const queryClient = useQueryClient();
  return APIClient.usePutCaptureJobById({
    mutation: {
      onSettled: async () => {
        APIUtils.invalidateEntity({
          queryClient,
          entityKey: APIUtils.QueryKey.captureJobs,
        });

        APIUtils.invalidateEntity({
          queryClient,
          entityKey: APIUtils.QueryKey.captureJobsWithPlyInfo,
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateCachedEntity({
          queryClient,
          data: variables.data,
          accessor: 'id',
          entityKey: APIUtils.QueryKey.captureJobs,
        });

        APIUtils.updateCachedEntity({
          queryClient,
          data: variables.data,
          accessor: 'id',
          entityKey: APIUtils.QueryKey.captureJobsWithPlyInfo,
        });
      },
    },
  });
};
