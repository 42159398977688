import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { AnalyticStatusIcon } from 'libs/feature/src/captures-viewer/captures-viewer-sidebar3/subcomponents/analytic-status-icon';

import { Button, IconTypes, SvgElement } from '@agerpoint/component';
import { AnalyticRequestStatus } from '@agerpoint/types';
import { convertNameToCamelCase, getColorByStatus } from '@agerpoint/utilities';

interface AdminCapturesProcessingCardProps {
  icon: string | null | undefined;
  desc: string;
  title: string;
  additionalInfo?: string;
  buttonText?: string;
  onSelect?: () => void;
  disabled?: boolean;
  comingSoon?: boolean;
  status?: AnalyticRequestStatus;
}

export const AdminCapturesProcessingCard = (
  item: AdminCapturesProcessingCardProps
) => {
  return (
    <div
      className={`w-full sm:w-1/2 md:w-1/3 relative ${
        item.disabled ? 'pointer-event-none' : ''
      }`}
    >
      <div
        className={`relative flex flex-col h-56 m-1 max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow ${
          item.disabled ? '' : 'hover:border-green'
        } `}
      >
        <div className="absolute -top-1 -left-1">
          <AnalyticStatusIcon
            status={item.status}
            spin={item.status === AnalyticRequestStatus.PROCESSING}
          />
        </div>
        {item.disabled && (
          <DisabledCover comingSoon={item.comingSoon ?? false} />
        )}

        <div className="flex flex-row justify-end flex-none">
          {item.status !== AnalyticRequestStatus.UNKNOWN && (
            <div style={{ color: getColorByStatus(item.status) }}>
              {item.status}
            </div>
          )}
          {item.additionalInfo &&
          item.status === AnalyticRequestStatus.UNKNOWN ? (
            <span
              className={`bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-green-400`}
            >
              {item.additionalInfo}
            </span>
          ) : (
            <div style={{ height: '22px' }} />
          )}
        </div>
        <h5
          className={`mb-2 text-2xl font-semibold tracking-tight text-gray-900 truncate flex flex-row flex-none`}
        >
          <SvgElement
            className="h-6 mr-2"
            type={convertNameToCamelCase(`fa-${item.icon}`) as IconTypes}
          />
          {item.title}
        </h5>
        <div className="mb-3 font-normal text-gray-500 text-sm flex-grow flex flex-col overflow-auto">
          {item.desc}
        </div>
        <div>
          {(item.status === AnalyticRequestStatus.UNKNOWN || !item.status) && (
            <Button.Primary
              id="select-analytic-button"
              label={item.buttonText ?? 'Select'}
              icon={item.disabled ? undefined : faArrowRight}
              onClick={() => {
                item?.onSelect?.();
              }}
              expand
            />
          )}
          {item.status && item.status !== AnalyticRequestStatus.UNKNOWN && (
            <Button.Secondary
              id="view-status-button"
              label={'View Status'}
              onClick={item.onSelect}
              expand
            />
          )}
        </div>
      </div>
    </div>
  );
};

const DisabledCover = ({ comingSoon }: { comingSoon: boolean }) => {
  return (
    <div
      className={`absolute z-50 top-0 left-0 w-full h-full bg-white bg-opacity-75 rounded-lg overflow-hidden flex border border-green`}
    >
      {comingSoon && (
        <div
          className={`absolute py-1 bg-green text-white flex items-center justify-center text-center text-sm`}
          style={{
            width: '500px',
            transformOrigin: 'center',
            transform: 'rotate(-22deg) translateX(-185px) translateY(-59px)',
          }}
        >
          Coming Soon!
        </div>
      )}
    </div>
  );
};
