import { Extent, extend } from 'ol/extent';
import { SetState } from 'zustand';

import { Group, Layer, LayerActions, ProjectState } from '@agerpoint/types';
import { toStateLayer } from '@agerpoint/utilities';

import { isClientSideGroup } from '../use-project';
import { assignZIndexes, combineExtents } from './utils';

export default function createLayerActions(
  set: SetState<ProjectState>
): LayerActions {
  return {
    setVisible: (layerId, visible) =>
      set((state) => ({
        groups: state.groups.map((group) => {
          const layerIndex = group.layers.findIndex(
            (layer) => layer.id === layerId
          );
          if (layerIndex >= 0) {
            const newLayers = [...group.layers];
            newLayers[layerIndex] = {
              ...group.layers[layerIndex],
              visible,
            };
            return { ...group, layers: newLayers };
          } else {
            return group;
          }
        }),
      })),
    delete: (layerId) =>
      set((state) => {
        return {
          groups: state.groups.map((group) => {
            const newLayers = group.layers.filter(
              (layer) => layer.id !== layerId
            );

            if (newLayers.length === group.layers.length) {
              return group;
            } else {
              return {
                ...group,
                layers: assignZIndexes(newLayers),
                extent: combineExtents(
                  newLayers
                    .map((layer) =>
                      'extent' in layer ? layer.extent : undefined
                    )
                    .filter((extent) => !!extent) as Extent[]
                ),
              };
            }
          }),
        };
      }),
    addTemporary: (groupId, index) => {
      set((state) => {
        const nextGroups = [...state.groups];
        const groupIndex = nextGroups.findIndex(
          (group) => groupId === group.id
        );
        if (groupIndex < 0) {
          throw new Error(`Unknown group with id ${groupId}.`);
        }

        const nextLayers = [...nextGroups[groupIndex].layers];

        nextLayers.splice(index, 0, {
          id: -100 * (index + 1),
          type: 'Unsaved',
          typeId: 0,
          entityId: 0,
          name: '',
          visible: false,
          dimensions: 0,
          zIndex: 0,
        });

        nextGroups[groupIndex] = {
          ...nextGroups[groupIndex],
          layers: assignZIndexes(nextLayers),
        };

        return { groups: nextGroups };
      });
    },
    removeTemporary: (groupId) => {
      set((state) => {
        const nextGroups = [...state.groups];
        const groupIndex = nextGroups.findIndex(
          (group) => groupId === group.id
        );
        if (groupIndex < 0) {
          throw new Error(`Unknown group with id ${groupId}.`);
        }

        const nextLayers = [
          ...nextGroups[groupIndex].layers.filter(
            (layer) => layer.type !== 'Unsaved'
          ),
        ];

        nextGroups[groupIndex] = {
          ...nextGroups[groupIndex],
          layers: assignZIndexes(nextLayers),
        };

        return { groups: nextGroups };
      });
    },
    saveNew: (groupId, layer, index) =>
      set((state) => {
        const hasLayers = state.groups
          .filter((group) => !isClientSideGroup(group))
          .some((group) => group.layers.length > 0);
        const nextGroups = [...state.groups];
        const groupIndex = nextGroups.findIndex(
          (group) => groupId === group.id
        );
        if (groupIndex < 0) {
          throw new Error(`Unknown group with id ${groupId}.`);
        }

        const nextLayers = [
          ...nextGroups[groupIndex].layers.filter(
            (layer) => layer.type !== 'Unsaved'
          ),
        ];

        const newLayer = toStateLayer(layer);
        nextLayers.splice(index, 0, newLayer);

        const newGroup = {
          ...nextGroups[groupIndex],
          layers: assignZIndexes(nextLayers),
        };

        if ('extent' in newLayer) {
          newGroup.extent = newGroup.extent
            ? extend([...newGroup.extent], newLayer.extent)
            : newLayer.extent;
        }

        nextGroups[groupIndex] = newGroup;

        return {
          groups: nextGroups,
          desiredExtent:
            !hasLayers && 'extent' in newLayer
              ? newLayer.extent
              : state.desiredExtent,
        };
      }),
    setName: (layerId, name) =>
      set((state) => ({
        groups: state.groups.map((group) => {
          const layerIndex = group.layers.findIndex(
            (layer) => layer.id === layerId
          );
          if (layerIndex >= 0) {
            const newLayers = [...group.layers];
            newLayers[layerIndex] = {
              ...group.layers[layerIndex],
              name,
            };
            return { ...group, layers: newLayers };
          } else {
            return group;
          }
        }),
      })),
    setZIndex: (groupId, layerId, index) =>
      set((state) => {
        // Get layers for the group
        const nextGroups = [...state.groups];
        const groupIndex = nextGroups.findIndex(
          (group) => groupId === group.id
        );
        if (groupIndex < 0) {
          throw new Error(`Unknown group with id ${groupId}.`);
        }

        const nextLayers = [...nextGroups[groupIndex].layers];

        const currentLayer = nextLayers.find((layer) => layer.id === layerId);
        const currentLayerIndex = nextLayers.findIndex(
          (layer) => layer.id === layerId
        );

        if (!currentLayer || currentLayerIndex < 0) {
          throw new Error(`Unknown layer with id ${layerId}.`);
        }

        const [layer] = nextLayers.splice(currentLayerIndex, 1);
        nextLayers.splice(index, 0, layer);

        nextGroups[groupIndex] = {
          ...nextGroups[groupIndex],
          layers: assignZIndexes(nextLayers),
        };

        return {
          ...state,
          groups: nextGroups,
        };
      }),
    setStyle: (layerId, layer) =>
      set((state) => {
        return {
          groups: state.groups.map((group) => {
            const layerIndex = group.layers.findIndex(
              (layer) => layer.id === layerId
            );
            if (layerIndex >= 0) {
              const newLayers = [...group.layers];
              const currentLayer = group.layers[layerIndex];
              if (
                layer.type === 'PointCloud' &&
                currentLayer.type === 'PointCloud'
              ) {
                newLayers[layerIndex] = {
                  ...currentLayer,
                  style: layer.style,
                };
              } else if (
                layer.type === 'WmsVector' &&
                currentLayer.type === 'WmsVector'
              ) {
                newLayers[layerIndex] = {
                  ...currentLayer,
                  style: layer.style,
                };
              } else {
                console.error(
                  `Unexpected layer type mismatch: ${currentLayer.type} != ${layer.type}.`
                );
              }
              return { ...group, layers: newLayers };
            } else {
              return group;
            }
          }),
        };
      }),
    moveToGroup: (layerId, groupId, destinationIndex) =>
      set((state) => {
        let originalGroupIndex = -1;
        let originalLayer: Layer | undefined;
        for (let i = 0; i < state.groups.length; i++) {
          originalLayer = state.groups[i].layers.find(
            (layer) => layer.id === layerId
          );
          if (originalLayer) {
            originalGroupIndex = i;
            break;
          }
        }

        const destinationGroupIndex = state.groups.findIndex(
          (group) => group.id === groupId
        );

        if (
          originalGroupIndex < 0 ||
          !originalLayer ||
          destinationGroupIndex < 0
        ) {
          console.warn(
            'Attempting to move layer but could not find original group, layer or destination group.'
          );
          return { groups: state.groups };
        }

        const originalGroup = state.groups[originalGroupIndex];
        const destinationGroup = state.groups[destinationGroupIndex];
        const newLayer = { ...originalLayer, layerGroupId: groupId };
        const newGroups = [...state.groups];
        newGroups[originalGroupIndex] = {
          ...originalGroup,
          layers: assignZIndexes(
            originalGroup.layers.filter((layer) => layer.id !== layerId)
          ),
        };
        let newDestinationLayers = [...destinationGroup.layers];
        newDestinationLayers.splice(destinationIndex, 0, newLayer);
        newDestinationLayers = assignZIndexes(newDestinationLayers);
        newGroups[destinationGroupIndex] = {
          ...destinationGroup,
          layers: newDestinationLayers,
        };

        return {
          groups: newGroups,
        };
      }),
  };
}
