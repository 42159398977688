import { useCallback, useEffect, useRef } from 'react';

import { InputIdContext } from '../input-id-context';
import { InputStyle } from '../input-style';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface InputNumberDecimalProps {
  /**
   * The unique identifier for the input element.
   */
  id: string;

  /**
   * The value of the input element.
   */
  value: number | undefined;

  /**
   * A callback function to update the value of the input element.
   */
  setValue: (value: number | undefined) => void;

  /**
   * The label to be displayed for the input element.
   */
  label?: React.ReactNode;

  /**
   * The error message to be displayed for the input element.
   */
  error?: React.ReactNode;

  /**
   * The validation rules for the input element.
   */
  validation?: InputValidation<number | undefined>;

  /**
   * Specifies if only positive numbers are allowed.
   */
  onlyPositive?: boolean;

  /**
   * Specifies if the input element is read-only.
   */
  readOnly?: boolean;

  /**
   * The placeholder text to be displayed in the input element.
   */
  placeholder?: string;

  /**
   * Specifies if the input element is disabled.
   */
  disabled?: boolean;

  /**
   * The style configuration for the input element.
   */
  style?: InputStyle;

  /**
   * The maximum precision allowed for the input element.
   */
  maxPrecision?: number;
}

const styles: { [key in InputStyle]: string } = {
  [InputStyle.default]: `text-sm text-gray-900 focus:ring-green
    focus:border-green shadow-sm border-gray-500 rounded placeholder-gray`,
  [InputStyle.mini]: `text-xs bg-transparent py-0.5 px-1 focus:ring-green focus:border-green
    shadow-sm border-gray-500 rounded placeholder-gray text-gray-900`,
  [InputStyle.miniDark]: `text-xs bg-transparent py-0.5 px-1 focus:ring-gray focus:border-gray
    shadow-sm border-gray-500 rounded placeholder-gray text-white`,
  [InputStyle.cloud]: `text-sm text-gray-900 focus:ring-green
    focus:border-green shadow-sm border-gray-500 rounded-lg placeholder-gray`,
};

const InputNumberDecimal = ({
  id,
  value,
  setValue,
  validation,
  label,
  error,
  onlyPositive,
  readOnly,
  placeholder,
  disabled,
  maxPrecision = 15,
  style = InputStyle.default,
}: InputNumberDecimalProps) => {
  const valueRef = useRef(value?.toString() ?? '');

  useEffect(() => {
    valueRef.current = value?.toString() ?? '';
  }, [value]);

  const inputClassName = `${styles[style]} ${
    disabled ? 'opacity-50' : ''
  }`.trim();

  const isSupportedValue = useCallback(
    (value: string): boolean => {
      if (onlyPositive) {
        return (
          (/^\d*\.?\d*$/.test(value) || value === '') &&
          value.length <= maxPrecision &&
          value !== '.'
        );
      }
      return (
        (/^-?\d*\.?\d*$/.test(value) || value === '') &&
        value.length <= maxPrecision &&
        value !== '.'
      );
    },
    [onlyPositive, maxPrecision]
  );

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full">
        {label ?? null}
        <input
          readOnly={readOnly}
          type="text"
          id={id}
          data-test-id={id}
          disabled={disabled}
          className={`${inputClassName}`.trim()}
          placeholder={placeholder}
          style={{
            fontFamily: 'nunito, "Font Awesome 6 Pro"',
          }}
          onBlur={() => {
            if (isSupportedValue(valueRef.current)) {
              setValue(
                valueRef.current === '' || valueRef.current === '-'
                  ? undefined
                  : parseFloat(valueRef.current)
              );
            }
          }}
          value={valueRef.current}
          onChange={(e) => {
            const v = e.target.value.trimStart();
            if (isSupportedValue(v)) {
              valueRef.current = v;
              setValue(v === '' || v === '-' ? undefined : parseFloat(v));
            }
          }}
        />
        {error ?? null}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputNumberDecimal };
