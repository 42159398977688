import { ReactNode } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { parseHttpError } from '@agerpoint/api';
import { AgerDragDropContextWrapper } from '@agerpoint/feature';
import { Intermediate } from '@agerpoint/feature';
import { ProjectState } from '@agerpoint/types';

import BottomPanel from './sections/bottom-panel/bottom-panel';
import { LoadLayers } from './sections/load-layers/load-layers';
import ProjectMap from './sections/map/map';
import Sidebar from './sections/sidebar/sidebar';
import { useFetchProject, useProject } from './state/use-project';

interface ProjectProps {
  children: ReactNode;
}
function ProjectWrapper({ children }: ProjectProps) {
  return <div className="bg-gray-50 h-full">{children}</div>;
}

export function ProjectPage() {
  const { id: projectUuid } = useParams();
  const { loading, error } = useProject(getProjectState, shallow);
  const location = useLocation();

  useFetchProject(projectUuid || '');

  // if not arriving from /projects page. "validate" projectUuid
  // by doing a fetch. this prevents rendering ProjectPage if it's
  // invalid.
  if (loading && !location.state?.from?.includes('/projects')) {
    return <Intermediate message="Validating project ..." />;
  } else if (error) {
    return <Intermediate message={parseHttpError(error)} />;
  }

  return (
    <ProjectWrapper>
      <AgerDragDropContextWrapper>
        <Sidebar />
      </AgerDragDropContextWrapper>
      <BottomPanel />
      <ProjectMap />
      <LoadLayers />
    </ProjectWrapper>
  );
}

function getProjectState({ loading, error }: ProjectState) {
  return {
    loading,
    error,
  };
}
