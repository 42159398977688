import { APIClient } from '@agerpoint/api';

export interface AnalyticRequestStatusObject {
  name: AnalyticRequestStatus;
  color: string;
}

export enum AnalyticRequestStatus {
  UNKNOWN = 'UNKNOWN',
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

type StatusProperty = {
  color: string;
  shape: string;
  description: string;
};

const statusProperties: Record<AnalyticRequestStatus, StatusProperty> = {
  [AnalyticRequestStatus.REQUESTED]: {
    color: '#176754',
    shape: 'hourGlass',
    description: "We're working on it, check back soon",
  },
  [AnalyticRequestStatus.PROCESSING]: {
    color: '#339BC0',
    shape: 'square',
    description: 'Crunching the numbers...',
  },
  [AnalyticRequestStatus.COMPLETE]: {
    color: '#2E9C73',
    shape: 'triangle',
    description: 'Check the Analytics Output panel to find your results',
  },
  [AnalyticRequestStatus.CANCELLED]: {
    color: '#F99E59',
    shape: 'cross',
    description: 'Processing has been cancelled',
  },
  [AnalyticRequestStatus.FAILED]: {
    color: '#D7191C',
    shape: 'exclamation',
    description:
      'There was a problem processing your data. Get in touch for more information',
  },
  [AnalyticRequestStatus.UNKNOWN]: {
    color: '#B1B5B9',
    shape: 'question',
    description: 'Something went wrong, please try again later',
  },
};

export const analyticRequestStatusList: {
  name: AnalyticRequestStatus;
  color: string;
  shape: string;
}[] = Object.entries(statusProperties)
  .filter((a) => {
    return a[0] !== AnalyticRequestStatus.UNKNOWN;
  })
  .map(([name, { color, shape }]) => ({
    name: name as AnalyticRequestStatus,
    color,
    shape,
  }));

export const analyticRequestStatusLookup: Record<
  AnalyticRequestStatus,
  StatusProperty
> = statusProperties;

export enum JobTypeNames {
  CAPTURE_EXTRACTION = 'Capture Extraction',
}

export interface AnalyticJob {
  name: string;
  jobTypeId: number;
  statusId?: number;
  jobTypeName: JobTypeNames;
  entityId: number;
  completedDatetime?: string;
  createDatetime?: string;
  status?: string;
}

export interface AnalyticJobWithAnalyticRequest extends AnalyticJob {
  analyticRequestId: number;
}

export const availableAnalyticsIcons = [
  'tree',
  'trees',
  'apple-crate',
  'circle',
  'cubes',
  'leaf-heart',
  'magnifying-glass',
  'ruler-vertical',
  'stethoscope',
  'toolbox',
  'tree-deciduous',
  'weight-hanging',
];
