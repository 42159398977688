import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';

import { APIClient, CaptureObjectCustomAttribute } from '@agerpoint/api';
import { Input } from '@agerpoint/component';
import { getSymbol } from '@agerpoint/utilities';

import {
  QAQCChangeValidationProps,
  QAQCValidationLoadingState,
} from './qaqc-capture-objects';

interface QAQCCaptureObjectAttributeRowProps {
  attribute: CaptureObjectCustomAttribute;
  loadingState: QAQCValidationLoadingState;
  changeValidation: (props: QAQCChangeValidationProps) => Promise<void>;
}

export const QAQCCaptureObjectAttributeRow = ({
  attribute,
  loadingState,
  changeValidation,
}: QAQCCaptureObjectAttributeRowProps) => {
  const loadingAttribute = useMemo(() => {
    return attribute.id && loadingState.attributeIds.includes(attribute.id);
  }, [loadingState, attribute]);

  const validateAttribute = useCallback(async () => {
    const value = !(attribute.validated ?? false);

    if (attribute.id) {
      changeValidation({
        attributeIds: [attribute.id],
        value: value,
      });
    }
  }, [attribute, changeValidation]);

  return (
    <div className="flex flex-row gap-2 items-center py-0.5">
      <span className="w-3 h-3 flex items-center justify-center text-green-300">
        {loadingAttribute ? (
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
            className="w-3 h-3 text-white"
          />
        ) : (
          <span
            onClick={(e) => {
              // For some reason the Checkbox component sends
              // out two onClick events that are not stopped
              // from propagating and trigger all parent onclicks twice
              // So we stop the propagation here
              e.stopPropagation();
            }}
          >
            <Input.Checkbox
              id={`attribute-${attribute.id}-validation-checkbox`}
              value={attribute.validated ?? false}
              style={Input.style.miniDark}
              setValue={validateAttribute}
            />
          </span>
        )}
      </span>
      <div className="flex flex-row justify-between gap-1 items-start flex-grow">
        {attribute?.attributeDisplayName ? (
          <span className="flex flex-row gap-1">
            <span>{attribute.attributeDisplayName}</span>
          </span>
        ) : (
          <span>{attribute.attributeName}</span>
        )}
        <span className="font-bold text-right">
          <span>{attribute.attributeValue}</span>
          <span>{getSymbol(attribute)}</span>
        </span>
      </div>
    </div>
  );
};
