export interface IAgerStoreMobileSidebarsSlice {
  mobileNavSideBarOpen: boolean;
  setMobileNavSideBarOpen: (open: boolean) => void;
  mobileDetailsSideBarOpen: boolean;
  setMobileDetailsSideBarOpen: (open: boolean) => void;
}

export const initialAgerStoreMobileSidebarsSlice: IAgerStoreMobileSidebarsSlice =
  {
    mobileNavSideBarOpen: false,
    setMobileNavSideBarOpen: () => {
      throw new Error('AgerStore not initialized');
    },
    mobileDetailsSideBarOpen: false,
    setMobileDetailsSideBarOpen: () => {
      throw new Error('AgerStore not initialized');
    },
  };
