import { useState } from 'react';
import { useEffect } from 'react';

import { ErrorMessage } from '@agerpoint/api';
import { SvgElement } from '@agerpoint/component';
import { useIsViteApp } from '@agerpoint/utilities';

export interface IntermediateProps {
  message: string | ErrorMessage;

  action?: () => void;
}

export function Intermediate({ action, message }: IntermediateProps) {
  const [actionExecuted, setActionExecuted] = useState(false);

  const isErrorMessage = (
    message: string | ErrorMessage
  ): message is ErrorMessage => (message as ErrorMessage)?.title !== undefined;

  useEffect(() => {
    if (action && !actionExecuted) {
      action();
      setActionExecuted(true);
    }
  }, [action, actionExecuted]);

  const isViteApp = useIsViteApp();
  if (isViteApp) {
    return null;
  }

  return (
    <div className="flex flex-col items-center space-y-6">
      <div className="flex-grow h-80"></div>
      {!isErrorMessage(message) ? (
        <StatusMode message={message} />
      ) : (
        <ErrorMode {...message} />
      )}
    </div>
  );
}

export interface StatusMessageProps {
  message: string;
}
function StatusMode({ message }: StatusMessageProps) {
  return (
    <>
      <div className="animate-pulse filter grayscale">
        <div className="opacity-50">
          <SvgElement type="AgerpointLogo" className="w-72 h-20" />
        </div>
      </div>
      <p className="text-sm font-normal">{message}</p>
    </>
  );
}

function ErrorMode({ title, message }: ErrorMessage) {
  return (
    <>
      <div className="filter grayscale flex-none">
        <div className="opacity-50">
          <SvgElement type="AgerpointLogo" className="w-72 h-20" />
        </div>
      </div>
      <div className="flex-none w-72">
        <p className="text-xl font-bold text-center">{title}</p>
      </div>
      <div className="flex-grow">
        <p className="text-lg font-normal text-center break-words max-w-7xl my-3">
          {message}
        </p>
      </div>
      <div className="flex-none w-72 text-center">
        <span className="text-sm font-normal">
          return to:&nbsp;
          <a href="/" className="text-sm font-bold hover:underline">
            cloud.agerpoint.com
          </a>
        </span>
      </div>
    </>
  );
}
