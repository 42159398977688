import { faSort } from '@fortawesome/pro-duotone-svg-icons';
import { faCircleNotch, faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListOnScrollProps } from 'react-window';

import { PrimaryButton } from '@agerpoint/component';

import {
  DatatableItemData,
  DatatableProps,
  DatatableStyle,
  isClickableColumn,
  isSortableColumn,
} from './datatable-types';

function Datatable<T>({
  id,
  data,
  columns,
  rowHeight,
  loading,
  loadingIndicator,
  noResults,
  error,
  pagination,
  sort,
  setSort,
  cellOnClick,
  style: tableStyle,
  datatableRef: datatableRefProp,
}: DatatableProps<T>) {
  const itemData: DatatableItemData<T> = {
    data,
    loading,
    loadingIndicator,
    error,
    tableStyle,
    columns,
    cellOnClick,
  };

  const [tableDivRef, setTableDivRef] = useState<HTMLDivElement | null>(null);
  const [headerDivRef, setHeaderDivRef] = useState<HTMLDivElement | null>(null);
  const [bodyDivRef, setBodyDivRef] = useState<HTMLDivElement | null>(null);
  const [datatableRef, setDatatableRef] = useState<FixedSizeList | null>(null);

  useEffect(() => {
    if (datatableRefProp) {
      datatableRefProp.current = datatableRef;
    }
  }, [datatableRefProp, datatableRef]);

  const itemCount = useMemo(() => {
    const needsAdditionalItem =
      loading === true || (error !== undefined && data.length > 0);

    if (needsAdditionalItem) {
      return data.length + 1;
    }

    return data.length;
  }, [data.length, loading, error]);

  const { onScroll, scroll } = useDatatableScrollRestoration({
    id,
    datatableRef,
  });

  const { headerRightMarginAdjustment } =
    useDatatableHandleHeaderRightMarginAdjustment({
      headerDivRef,
      bodyDivRef,
    });

  const { showHorizontalScroll } = useDatatableHandleShowHorizontalScroll({
    tableStyle,
    tableDivRef,
  });

  return (
    <div
      className={`w-full h-full flex flex-col ${
        tableStyle?.tableWrapperStyle || ''
      }
      `}
      id="table"
      ref={setTableDivRef}
      style={{
        overflowX: showHorizontalScroll ? 'scroll' : 'hidden',
        overflowY: 'hidden',
      }}
    >
      <div
        className={`w-full flex flex-row ${
          tableStyle?.headerWrapperStyle || ''
        }`}
        ref={setHeaderDivRef}
        id="table_headers"
        style={{
          minWidth: tableStyle?.tableMinWidth
            ? `${tableStyle.tableMinWidth}px`
            : undefined,
        }}
      >
        {columns.map((c, i) => {
          if (c.visible === false) {
            return null;
          }

          return (
            <div
              onClick={() => {
                if (isSortableColumn(c)) {
                  setSort?.({
                    key: c.sortKey,
                    order:
                      sort?.key === c.sortKey && sort?.order === 'desc'
                        ? 'asc'
                        : 'desc',
                  });
                } else if (isClickableColumn(c)) {
                  c.onClick?.();
                }
              }}
              key={i}
              id={`table_header_${i}`}
              style={{ flex: c.flex ?? 1 }}
              className={`overflow-hidden ${
                (isSortableColumn(c) || isClickableColumn(c)) &&
                'cursor-pointer'
              } ${c?.style?.headerStyle || ''} ${
                c?.style?.columnWrapperStyle || ''
              } ${tableStyle?.headerStyle || ''}`}
            >
              <div className="flex flex-row truncate items-center gap-1">
                <div className="truncate">{c.label}</div>
                {isSortableColumn(c) && sort?.key === c.sortKey && (
                  <div>
                    <FontAwesomeIcon
                      icon={faSort}
                      className={`text-primary ${
                        sort?.order === 'desc' ? 'fa-swap-opacity' : ''
                      }`}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div style={{ width: `${headerRightMarginAdjustment ?? 0}px` }}></div>
      </div>
      <div
        className={`flex-grow ${tableStyle?.bodyWrapperStyle || ''} relative`}
        id="table_body"
        style={{
          minWidth: tableStyle?.tableMinWidth
            ? `${tableStyle.tableMinWidth}px`
            : undefined,
        }}
      >
        {data.length === 0 && !loading && error !== undefined && (
          <div
            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 text-center`}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              {error.title && (
                <span className="font-bold text-2xl">{error.title}</span>
              )}
              {error.message && (
                <span className="text-base text-gray-500 font-light">
                  {error.message}
                </span>
              )}
              {error.action && (
                <div className="pt-4">
                  <PrimaryButton
                    label="Refresh"
                    icon={<FontAwesomeIcon icon={faRefresh} />}
                    onClicked={error.action}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {data.length === 0 &&
          !loading &&
          noResults !== undefined &&
          error === undefined && (
            <div
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 text-center`}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                {noResults.icon && (
                  <div className="text-6xl text-gray-iconSecondary pb-8">
                    {noResults.icon}
                  </div>
                )}
                {noResults.title && (
                  <span className="font-bold text-2xl">{noResults.title}</span>
                )}
                {noResults.message && (
                  <span className="text-base text-gray-500 font-light">
                    {noResults.message}
                  </span>
                )}
                {noResults.action && (
                  <div className="pt-4">
                    <PrimaryButton
                      icon={noResults.actionIcon}
                      label={noResults.actionLabel}
                      onClicked={noResults.action}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        <AutoSizer>
          {({ height = 0, width = 0 }) => (
            <FixedSizeList<DatatableItemData<T>>
              ref={setDatatableRef}
              onScroll={onScroll}
              // Making the initial scroll slightly larger forces the table to rerender
              // which is required in our case
              initialScrollOffset={scroll + 0.1}
              innerRef={setBodyDivRef}
              height={Number.isNaN(height) ? 0 : height}
              width={Number.isNaN(width) ? 0 : width}
              onItemsRendered={(props) => {
                let key: keyof typeof props;
                for (key in props) {
                  if (isNaN(props[key])) {
                    props[key] = 0;
                  }
                }

                const shouldLoadNextPage =
                  !error &&
                  !loading &&
                  props.visibleStopIndex >=
                    data.length - (pagination?.threshold ?? 1);

                if (shouldLoadNextPage) {
                  pagination?.loadNextPage?.();
                }
              }}
              itemCount={itemCount}
              itemSize={rowHeight}
              overscanCount={5}
              itemData={itemData}
            >
              {Item}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}

function Item<T>({
  index,
  style,
  data: itemData,
}: {
  index: number;
  style: CSSProperties;
  data: DatatableItemData<T>;
}) {
  if (index === itemData.data.length && itemData.error && !itemData.loading) {
    return (
      <div
        style={style}
        className="flex flex-row w-full h-full items-center justify-center gap-2"
      >
        {itemData.error.title && (
          <span className="font-bold text-sm text-center leading-none">
            {itemData.error.title}
          </span>
        )}
        {itemData.error.action && (
          <PrimaryButton
            icon={<FontAwesomeIcon icon={faRefresh} />}
            label="Retry"
            size="x-small"
            onClicked={itemData.error.action}
            theme="white"
          />
        )}
      </div>
    );
  }

  if (index === itemData.data.length) {
    if (itemData.loadingIndicator) {
      return (
        <div style={style} className="flex flex-row w-full h-full">
          {itemData.loadingIndicator}
        </div>
      );
    }
    return <DatatableDefaultLoadingIndicator style={style} />;
  }

  return <Row index={index} style={style} itemData={itemData} />;
}

function Row<T>({
  index,
  style,
  itemData,
}: {
  index: number;
  style: CSSProperties;
  itemData: DatatableItemData<T>;
}) {
  const { data, tableStyle, columns, cellOnClick } = itemData;
  const d = data[index];
  const [isHovered, setIsHovered] = useState(false);

  const rowStyle = useMemo(() => {
    if (typeof tableStyle?.rowStyle === 'function') {
      return tableStyle.rowStyle(d) ?? '';
    }
    return tableStyle?.rowStyle ?? '';
  }, [tableStyle, d]);

  return (
    <div
      style={{ ...style }}
      onPointerEnter={() => {
        setIsHovered(true);
      }}
      onPointerLeave={() => {
        setIsHovered(false);
      }}
      className={`flex flex-row w-full ${
        tableStyle?.rowWrapperStyle || ''
      } table-row`}
    >
      <div
        className={`flex flex-row w-full h-full ${rowStyle} ${
          index % 2
            ? tableStyle?.oddRowStyle || ''
            : tableStyle?.evenRowStyle || ''
        }`}
      >
        {columns.map((c, i) => {
          if (c.visible === false) {
            return null;
          }
          const onClick = c.name ? cellOnClick?.(c.name) : undefined;

          let truncationStyle = '';
          truncationStyle +=
            c.style?.truncation?.truncate === undefined ||
            c.style?.truncation?.truncate === true
              ? ' truncate '
              : ' ';
          truncationStyle += c.style?.truncation?.lineClamp
            ? ` line-clamp-${c.style?.truncation?.lineClamp} `
            : ' ';
          truncationStyle += c.style?.truncation?.breakWords
            ? ' break-words '
            : ' ';

          return (
            <div
              key={i}
              style={{ flex: c.flex ?? 1 }}
              onClick={onClick ? (e) => onClick?.(d, e) : undefined}
              onDoubleClick={onClick ? (e) => onClick?.(d, e) : undefined}
              className={`h-full overflow-hidden ${
                onClick ? 'cursor-pointer' : ''
              } ${c?.style?.columnWrapperStyle || ''} ${
                c?.style?.bodyStyle || ''
              } ${tableStyle?.cellStyle || ''}`}
            >
              <div
                className={`${truncationStyle.trim()} ${
                  c.style?.expandBody ? 'w-full' : ''
                }`.trim()}
              >
                {c.value(d, index, isHovered)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export const DatatableDefaultLoadingIndicator = ({
  style,
}: {
  style: CSSProperties;
}) => {
  return (
    <div
      style={{ ...style }}
      className="flex flex-row w-full justify-center items-center"
    >
      <FontAwesomeIcon icon={faCircleNotch} spin className="h-1/2" />
    </div>
  );
};

export const dataTableAgerStyle: DatatableStyle<unknown> = {
  tableWrapperStyle: 'bg-white rounded-md',
  headerWrapperStyle:
    'px-8 text-xs text-gray-700 font-normal border-b border-gray-500',
  rowWrapperStyle: 'px-8 items-center text-sm hover:bg-gray-100',
  rowStyle: 'border-b border-gray-200',
  headerStyle: 'pr-1 py-3 h-full flex items-center',
  cellStyle: 'pr-1 flex items-center',
};

export const dataTableAgerSmallStyle: DatatableStyle<unknown> = {
  tableWrapperStyle: 'bg-white rounded-md',
  headerWrapperStyle:
    'px-8 text-xs text-gray-700 font-normal border-b border-gray-500',
  rowWrapperStyle: 'px-8 items-center text-xs hover:bg-gray-100',
  rowStyle: 'border-b border-gray-200',
  headerStyle: 'pr-1 py-3 h-full flex items-center',
  cellStyle: 'pr-1 flex items-center',
};

export const dataTableAgerDarkStyle: DatatableStyle<unknown> = {
  tableWrapperStyle: 'border border-gray-500 rounded-md',
  headerWrapperStyle:
    'px-8 text-xs text-gray-500 font-normal border-b border-gray-500',
  rowWrapperStyle: 'px-8 items-center text-sm hover:bg-gray-800',
  rowStyle: 'border-b border-gray-500',
  headerStyle: 'pr-1 py-3 h-full flex items-center',
  cellStyle: 'pr-1 flex items-center',
};

const scrollStorage: { [id: string]: number } = {};

const useDatatableScrollRestoration = ({
  id,
  datatableRef,
}: {
  id?: string;
  datatableRef: FixedSizeList | null;
}) => {
  const [scrollRestored, setScrollRestored] = useState(false);

  const scroll = useMemo(() => {
    if (!id) {
      return 0;
    }

    return scrollStorage[id] ?? 0;
  }, [id]);

  useEffect(() => {
    if (id && datatableRef) {
      datatableRef.scrollTo(scroll);
      setScrollRestored(true);
    }
  }, [scroll, id, datatableRef]);

  const onScroll = useCallback(
    (event: ListOnScrollProps) => {
      if (!id || event.scrollUpdateWasRequested || !scrollRestored) {
        return;
      }

      scrollStorage[id] = event.scrollOffset;
    },
    [id, scrollRestored]
  );

  return { onScroll, scroll };
};

const useDatatableHandleHeaderRightMarginAdjustment = ({
  headerDivRef,
  bodyDivRef,
}: {
  headerDivRef: HTMLDivElement | null;
  bodyDivRef: HTMLDivElement | null;
}) => {
  const [headerRightMarginAdjustment, setHeaderRightMarginAdjustment] =
    useState<number>();

  const handleRightMarginAdjustment = useCallback(() => {
    if (!headerDivRef || !bodyDivRef || bodyDivRef.clientWidth === 0) {
      return;
    }
    const diff = headerDivRef?.clientWidth - bodyDivRef?.clientWidth;
    if (diff !== headerRightMarginAdjustment) {
      if (diff > 30 || diff < 0) {
        setHeaderRightMarginAdjustment(0);
      } else {
        setHeaderRightMarginAdjustment(diff);
      }
    }
  }, [headerDivRef, bodyDivRef, headerRightMarginAdjustment]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleRightMarginAdjustment);

    if (bodyDivRef) {
      resizeObserver.observe(bodyDivRef);
      return () => {
        resizeObserver.unobserve(bodyDivRef);
        resizeObserver.disconnect();
      };
    }
    return;
  }, [handleRightMarginAdjustment]);

  return { headerRightMarginAdjustment };
};

const useDatatableHandleShowHorizontalScroll = <T,>({
  tableStyle,
  tableDivRef,
}: {
  tableStyle: DatatableStyle<T> | undefined;
  tableDivRef: HTMLDivElement | null;
}) => {
  const [showHorizontalScroll, setShowHorizontalScroll] =
    useState<boolean>(false);
  const handleShowHorizontalScroll = useCallback(() => {
    if (!tableDivRef?.clientWidth || !tableStyle?.tableMinWidth) {
      setShowHorizontalScroll(false);
      return;
    }

    if (tableDivRef.clientWidth < tableStyle.tableMinWidth) {
      setShowHorizontalScroll(true);
      return;
    }

    setShowHorizontalScroll(false);
    return;
  }, [tableStyle?.tableMinWidth, tableDivRef]);

  useEffect(() => {
    if (tableStyle?.tableMinWidth) {
      handleShowHorizontalScroll();
      window.addEventListener('resize', handleShowHorizontalScroll);
      return () => {
        window.removeEventListener('resize', handleShowHorizontalScroll);
      };
    }
    return;
  }, [handleShowHorizontalScroll, tableStyle?.tableMinWidth]);

  return { showHorizontalScroll };
};

Datatable.cloudStyle = {
  tableWrapperStyle: 'bg-white',
  headerWrapperStyle:
    'px-2 text-sm text-gray-textPrimary font-normal border-b border-gray-border',
  rowWrapperStyle: ' items-center text-sm',
  rowStyle: 'border-b border-gray-border px-2 transition-colors',
  headerStyle: 'pr-1 py-3 h-full flex items-center font-bold',
  cellStyle: 'pr-1 flex items-center',
} as DatatableStyle<unknown>;

export { Datatable };
