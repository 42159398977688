import EventEmitter from 'events';
import { uniqueId } from 'lodash';
import * as THREE from 'three';

import {
  Annotation2dPoints,
  CaptureObjectMarkerType,
  ColorsThreeD,
  EventBusNames,
  HexColor,
  IMarkerObj,
  MarkerObjOptions,
  SVG_PATH_NAMES,
  SVG_PATH_SIZE_LOOKUP,
  SVG_PATH_TOP,
} from '@agerpoint/types';
import {
  CalloutLabel,
  CustomMarker,
  _create3dCustomMarker,
  _create3dExtractionMarker,
  _createCalloutLabel,
  _createTextLabel,
  dragElement,
  eventBus,
} from '@agerpoint/utilities';

export class IconMarker extends EventEmitter implements IMarkerObj {
  options: MarkerObjOptions;
  element: HTMLElement;
  labelElement: HTMLElement;
  editable: boolean;
  position = new THREE.Vector3(0, 0, 0);
  isHighlighted: boolean = false;
  id = '';
  type: CaptureObjectMarkerType | Annotation2dPoints | undefined;
  color: ColorsThreeD | HexColor;
  marker: CustomMarker | undefined;
  label: CalloutLabel | undefined;
  private _visible: boolean;
  constructor(
    eventName: EventBusNames,
    eventId: string,
    _options: MarkerObjOptions,
    private perspectiveCamera: THREE.PerspectiveCamera,
    private canvas: HTMLCanvasElement,
    private isPotree: boolean,
    private svgPathType: SVG_PATH_NAMES
  ) {
    super();
    const options = { ..._options };
    this.options = options;
    this.editable = options?.editable ?? false;
    this.type = CaptureObjectMarkerType.IconMarker;
    this.color = options.fill ?? ColorsThreeD.Cyan;
    this.id = eventId;
    this.isPotree = isPotree;
    this._visible = options.visible ?? true;

    const size = SVG_PATH_SIZE_LOOKUP[this.svgPathType];

    if (this.options?.type === CaptureObjectMarkerType.ExtractionJob) {
      this.element = _create3dExtractionMarker(
        this.options?.fill,
        this.options?.clickable
      );
    } else {
      this.marker = new CustomMarker(
        eventId,
        svgPathType,
        this.options.fill,
        this.options.clickable
      );
      if (this.options.clickable) {
        this.marker.on('mousedown', (id: string) => {
          this.emit('mousedown', id);
        });
        this.marker.on('mouseup', (id: string) => {
          this.emit('mouseup', id);
        });
      }
      this.element = this.marker.getElement();
    }
    this.label = new CalloutLabel(
      this.options.name,
      this.options.fill,
      this.options.clickable
    );
    this.labelElement = this.label.element;
    const wheelFn = (e: WheelEvent) => {
      // gs3d
      this.canvas.dispatchEvent(new WheelEvent('wheel', e));
      // potree
      this.canvas.dispatchEvent(new WheelEvent('mousewheel', e));
    };
    wheelFn.bind(this);
    this.label.on('label-wheel', wheelFn);
    this.marker?.on('marker-wheel', wheelFn);
    if (this.options.clickable) {
      this.label.on('label-click', () => {
        this.emit('mousedown', eventId);
      });
    }
    this.updateVisibility(Boolean(this?.options?.visible));
    if (options.type === CaptureObjectMarkerType.Custom) {
      dragElement(this, this.perspectiveCamera, eventName, eventId);
    }

    if (this.options.clickable && this?.element) {
      this.element.onclick = () => {
        eventBus.dispatch(EventBusNames.CaptureObjectClicked, {
          detail: {
            id: eventId,
            position: this.position,
          },
        });
      };
    }
    if (this.isPotree) {
      const potreeRenderAreaElem = document.querySelector(
        '#potree_render_area'
      );
      if (!potreeRenderAreaElem) return;

      potreeRenderAreaElem?.appendChild(this.element);
      potreeRenderAreaElem?.appendChild(this.labelElement);
    } else {
      const rootElem = document.querySelector('.rootElement');
      if (!rootElem) return;

      rootElem?.appendChild(this.element);
      rootElem?.appendChild(this.labelElement);
    }
  }
  get isVisible() {
    return this._visible;
  }
  set isVisible(isVisible: boolean) {
    this._visible = isVisible;
  }

  updateDOM(element: HTMLElement, left?: string, top?: string): void {
    if (left && element.style.left !== left) {
      element.style.left = left;
    }
    if (top && element.style.top !== top) {
      element.style.top = top;
    }
  }

  updateEditability(value: boolean) {
    if (this.options.type !== CaptureObjectMarkerType.Custom) {
      return;
    }
    this.editable = value;
    this.element.style.pointerEvents = value ? 'auto' : 'none';
    this.element.style.cursor = value ? 'pointer' : 'default';
  }

  updatePosition() {
    const top = SVG_PATH_TOP[this.svgPathType];
    const leftAdjust = this.isHighlighted ? 24 : 12;
    const leftAdjustLabel = this.isHighlighted ? 36 : 18;
    const topAdjust = this.isHighlighted ? top * 2 : top;

    const coords2d = this.get2dCoords(this.position);
    this.updateDOM(
      this.element,
      `${coords2d.x - leftAdjust}px`,
      `${coords2d.y - topAdjust}px`
    );
    this.updateDOM(
      this.labelElement,
      `${coords2d.x + leftAdjustLabel}px`,
      `${coords2d.y - topAdjust}px`
    );
  }

  setPosition(_position: THREE.Vector3) {
    this.position = _position;
  }

  get2dCoords(position: THREE.Vector3) {
    const temp = new THREE.Vector3(position.x, position.y, position.z);
    const vector = temp.project(this.perspectiveCamera); // Project the position onto the camera
    // Calculate screen-space coordinates using the projected values
    vector.x = ((vector.x + 1) / 2) * this.canvas.width;
    vector.y = (-(vector.y - 1) / 2) * this.canvas.height;

    return new THREE.Vector2(vector.x, vector.y); // Return 2D screen coordinates
  }

  highlight() {
    this.marker?.highlight();
    this?.label?.highlight();
  }

  unHighlight() {
    this.marker?.unHighlight();
    this?.label?.unHighlight();
  }

  dispose() {
    this.element.remove();
    this.labelElement.remove();
  }

  updateType(type: Annotation2dPoints) {
    this.options.type = type;
  }

  updateColor(color: ColorsThreeD | HexColor) {
    this.color = color;
    const svgPath = this.element.querySelector<HTMLElement>('svg path');
    if (svgPath) {
      svgPath.style.fill = color;
    }
    this.labelElement.style.backgroundColor = color;
  }

  updateVisibility(visible: boolean) {
    this.changeVisibilityValues(visible);
    this.isVisible = visible;
  }

  zoomTo() {
    this.perspectiveCamera.position.set(
      this.position.x,
      this.position.y,
      this.position.z + 1
    );
  }

  isOutsideFrustum(isOutside: boolean) {
    if (isOutside) {
      this.changeVisibilityValues(false);
    } else {
      this.changeVisibilityValues(this.isVisible);
    }
  }

  private changeVisibilityValues(visible: boolean) {
    this.options.visible = visible;
    this.options.visibleLabel = visible;
    this.element.style.display = visible ? 'block' : 'none';
    this.labelElement.style.display = visible ? 'block' : 'none';
  }
}
