import { ReactNode } from 'react';
import { FixedSizeList } from 'react-window';

type RowCallback<T> = (
  row: T,
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => void;
type RowCallbackGenerator<T> = (columnName: string) => RowCallback<T> | void;

export interface DatatableOldProps<T> {
  data: T[];
  columns: DatatableOldColumnProps<T>[];
  pagination?: DatatableOldPaginationProps;
  overscanCount?: number;
  rowHeight: number;
  sortingChanged?: (options: DatatableOldColumnSortingOptions<T>) => void;
  initialSortingOptions?: DatatableOldColumnSortingOptions<T>;
  cellOnClick?: RowCallbackGenerator<T>;
  style?: DatatableOldStyle;
  datatableRef?: React.MutableRefObject<FixedSizeList<
    DatatableOldItemData<T>
  > | null>;
}

export interface DatatableOldPaginationProps {
  loadPage: (startIndex: number, endIndex: number) => void | Promise<void>;
  loadingIndicator?: React.ReactNode;
  hasNextPage: boolean;
  threshold?: number;
  maxItems?: number;
}

interface DatatableOldColumnBaseProps<T> {
  label: ReactNode;
  name?: string;
  value: (row: T, index: number) => ReactNode;
  flex?: number;
  style?: DatatableOldColumnStyle;
  visible?: boolean;
}

interface DatatableOldSortableColumnProps<T>
  extends DatatableOldColumnBaseProps<T> {
  sortKey: keyof T;
}

interface DatatableOldClickableColumnProps<T>
  extends DatatableOldColumnBaseProps<T> {
  onClick: () => void;
}

export type DatatableOldColumnProps<T> =
  | DatatableOldSortableColumnProps<T>
  | DatatableOldClickableColumnProps<T>
  | DatatableOldColumnBaseProps<T>;

export function isClickableColumn<T>(
  column: DatatableOldColumnProps<T>
): column is DatatableOldClickableColumnProps<T> {
  return 'onClick' in column;
}

export function isSortableColumn<T>(
  column: DatatableOldColumnProps<T>
): column is DatatableOldSortableColumnProps<T> {
  return 'sortKey' in column;
}

export interface DatatableOldColumnSortingOptions<T> {
  key: keyof T;
  // TODO: Create enum of sorting order
  order: 'asc' | 'desc';
}

export interface DatatableOldStyle {
  tableMinWidth?: number;
  rowWrapperStyle?: string;
  rowStyle?: string;
  oddRowStyle?: string;
  evenRowStyle?: string;
  tableWrapperStyle?: string;
  headerWrapperStyle?: string;
  headerStyle?: string;
  bodyWrapperStyle?: string;
  cellStyle?: string;
}

export interface DatatableOldColumnStyle {
  columnWrapperStyle?: string;
  headerStyle?: string;
  bodyStyle?: string;
}

export interface DatatableOldItemData<T> {
  data: T[];
  pagination?: DatatableOldPaginationProps;
  tableStyle?: DatatableOldStyle;
  columns: DatatableOldColumnProps<T>[];
  cellOnClick?: RowCallbackGenerator<T>;
}
