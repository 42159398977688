import {
  faCircleNotch,
  faPlus,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIModels, AnalyticJobType } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  useLookupTable,
} from '@agerpoint/utilities';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminAnalyticsQueries } from './admin-analytics-queries';

export const AdminAnalyticsDetailsJobTypesMgmt = () => {
  const { permissions } = useGlobalStore();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const {
    analyticQuery,
    mosaicEnginesQuery,
    jobTypesQuery,
    analyticJobTypesQuery,
    analyticJobTypesPostMutation,
    analyticJobTypesArchivePutMutation,
  } = useAdminAnalyticsQueries();

  const canEditAnalytics = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );

  const formValidation = useFormValidation();

  const mosaicEnginesLookupTable = useLookupTable(
    mosaicEnginesQuery.data,
    'id'
  );

  const availableJobTypes = useMemo(() => {
    if (!analyticJobTypesQuery.data) {
      return [];
    }

    return jobTypesQuery.data
      ?.filter(
        (a) =>
          !analyticJobTypesQuery.data?.find((ajt) => ajt.jobTypeId === a.id) &&
          a.archived === false
      )
      .sort((a, b) => compare(a.name?.toLowerCase(), b.name?.toLowerCase()));
  }, [jobTypesQuery.data, analyticJobTypesQuery.data]);

  const [selectedJobType, setSelectedJobType] = useState<
    APIModels.JobType | undefined
  >();

  const addNewJobType = useCallback(async () => {
    if (analyticJobTypesPostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    if (!selectedJobType) {
      return;
    }

    analyticJobTypesPostMutation.mutate(
      {
        data: {
          analyticId: analyticQuery.data?.id,
          jobTypeId: selectedJobType.id,
        },
      },
      {
        onSuccess: () => {
          setSelectedJobType(undefined);
        },
      }
    );
  }, [
    selectedJobType,
    formValidation,
    analyticJobTypesPostMutation,
    analyticQuery,
  ]);

  const removeAnalyticJobType = useCallback((ajt: AnalyticJobType) => {
    const confirm = window.confirm(
      `Are you sure you want to remove the job type ${ajt.jobType?.name} from the analytic?`
    );
    if (confirm) {
      analyticJobTypesArchivePutMutation.mutate({
        id: ajt.id || NaN,
        data: {
          ...ajt,
          archived: true,
        },
      });
    }
  }, []);

  if (analyticQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (analyticQuery.isError) {
    return (
      <PageErrorState
        entityName="analytic"
        pluralEntityName="analytics"
        statusCode={analyticQuery.error.response?.status ?? 500}
        tryAgainCallback={() => analyticQuery.refetch()}
        tryAgainLoading={analyticQuery.isFetching}
        navigateBackCallback={() =>
          navigate(
            isViteApp
              ? '/app/admin/platform/analytics' + params
              : '/admin/analytics' + params
          )
        }
      />
    );
  }

  return (
    <div className="px-4 flex w-full h-full flex-col overflow-auto lg:overflow-hidden">
      <div className="w-full flex flex-row font-bold pb-2">
        Review the job types that are required to process this analytic
      </div>

      <div className="w-full h-full flex flex-col">
        <div className="w-full pb-2 flex flex-row gap-2">
          <Input.Select.Single
            id="job-type-select"
            title="Job Type"
            placeholder="Select a Job Type"
            options={availableJobTypes ?? []}
            value={selectedJobType}
            setValue={(jt) => {
              setSelectedJobType(jt);
            }}
            loading={jobTypesQuery.isLoading || analyticJobTypesQuery.isLoading}
            optionBuilder={(jt) => jt?.name ?? 'Unknown'}
            label={<Input.Label label="Available Job Types" required />}
            disabled={!canEditAnalytics}
            error={
              <Input.Error error={formValidation.errors['job-type-select']} />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Job Type')],
            }}
          />
          <div className="mt-5">
            <Button.Primary
              id="add-job-type-button"
              label="Add Job Type to Analytic"
              icon={faPlus}
              onClick={addNewJobType}
              disabled={
                !canEditAnalytics ||
                jobTypesQuery.isLoading ||
                analyticJobTypesQuery.isLoading ||
                analyticJobTypesPostMutation.isPending
              }
            />
          </div>
        </div>

        <div className="w-full flex-grow pb-4">
          <Datatable
            style={{
              ...dataTableAgerStyle,
              tableWrapperStyle: `${dataTableAgerStyle.tableWrapperStyle} border border-gray-500`,
              tableMinWidth: 900,
            }}
            loading={analyticJobTypesQuery.isLoading || jobTypesQuery.isLoading}
            data={analyticJobTypesQuery.data ?? []}
            noResults={{
              title: 'No job types selected for this analytic',
              message:
                'Add job types to the analytic to enable the processing of data',
            }}
            error={
              analyticJobTypesQuery.isError
                ? {
                    title:
                      'There was a problem loading job types for this analytic',
                    message: 'Try refreshing the page',
                    action: () => analyticJobTypesQuery.refetch(),
                  }
                : undefined
            }
            columns={[
              {
                label: 'Name',
                value: (ajt) => ajt?.jobType?.name,
              },
              {
                label: 'Description',
                value: (ajt) => ajt?.jobType?.description,
              },

              {
                label: 'Category',
                value: (ajt) =>
                  ajt?.jobType?.categoryName?.split(/(?=[A-Z])/).join(' '),
              },
              {
                label: 'Req. High Res',
                value: (ajt) => (ajt?.jobType?.requiresHighRes ? 'Yes' : 'No'),
                flex: 0.5,
              },
              {
                label: 'Mosaic Engine',
                value: (ajt) => {
                  if (mosaicEnginesQuery.isLoading)
                    return <FontAwesomeIcon icon={faCircleNotch} spin />;

                  if (!ajt?.jobType?.mosaicEngineId) return null;

                  const me =
                    mosaicEnginesLookupTable?.[ajt?.jobType?.mosaicEngineId];

                  return me?.displayName ?? me?.name ?? null;
                },
                flex: 0.5,
              },
              {
                label: '',
                value: (ajt, index) => (
                  <div className="p-1">
                    <Button.Danger
                      id={`remove-job-type-button-${index}`}
                      label="Remove"
                      icon={faTrash}
                      onClick={() => {
                        removeAnalyticJobType(ajt);
                      }}
                    />
                  </div>
                ),
                style: {
                  bodyStyle: 'overflow-visible',
                },
                visible: canEditAnalytics,
                flex: 0.5,
              },
            ]}
            rowHeight={50}
          />
        </div>
      </div>
    </div>
  );
};
