import { useEffect, useMemo, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { useDebouncyEffect } from 'use-debouncy';

interface ColorPicker2RgbProps {
  initialPercentRgbColor?: number[];
  onChange?: (color: number[]) => void;
  debounceTime?: number;
}

/**
 * A color picker component that allows users to select a color in hexadecimal format.
 * It provides RGB color values in a normalized decimal format as output.
 *
 * @param {Object} props The properties for the ColorPicker2Rgb component.
 * @param {Array<number>} props.initialPercentRgbColor Initial RGB color expressed as a decimal array [R, G, B], each component ranging from 0 to 1.
 * @param {Function} props.onChange Callback function that is invoked with the new color value whenever it changes.
 * @param {number} props.debounceTime Debounce delay in milliseconds for the onChange callback, defaulting to 0ms.
 */

export const ColorPicker2Rgb = ({
  initialPercentRgbColor,
  onChange,
  debounceTime,
}: ColorPicker2RgbProps) => {
  const [hexColor, setHexColor] = useState<string>(() => {
    if (initialPercentRgbColor) {
      const [r, g, b] = initialPercentRgbColor;

      const hexR = Math.round(r * 255)
        .toString(16)
        .padStart(2, '0');
      const hexG = Math.round(g * 255)
        .toString(16)
        .padStart(2, '0');
      const hexB = Math.round(b * 255)
        .toString(16)
        .padStart(2, '0');

      const hex = `#${hexR}${hexG}${hexB}`;

      return hex;
    }
    return '#000000';
  });

  const presetsLocalStorageKey = useMemo(
    () => 'APCLOUD_COLORPICKER_RGB_PRESETS',
    []
  );

  const [presets, setPresets] = useState<string[]>(() => {
    const presetsString = localStorage.getItem(presetsLocalStorageKey);
    if (presetsString) {
      return JSON.parse(presetsString);
    }
    return [];
  });

  useEffect(() => {
    localStorage.setItem(presetsLocalStorageKey, JSON.stringify(presets));
  }, [presets, presetsLocalStorageKey]);

  useDebouncyEffect(
    () => {
      const color = hexColor
        .replace('#', '')
        .match(/.{1,2}/g)
        ?.map((x) => parseFloat((parseInt(x, 16) / 255).toFixed(2)));

      if (!color || color.length !== 3) {
        throw new Error('Invalid color format');
      }

      onChange?.(color);
    },
    debounceTime ?? 0,
    [hexColor]
  );

  useDebouncyEffect(
    () => {
      const isPreset = presets.includes(hexColor);
      if (isPreset) {
        return;
      }

      if (presets.length < 10) {
        setPresets((prev) => [...prev, hexColor]);
      } else if (presets.length === 10) {
        setPresets((prev) => [...prev.slice(1), hexColor]);
      }
    },
    debounceTime ?? 300,
    [hexColor]
  );

  return (
    <div className="flex flex-col gap-1">
      <HexColorPicker color={hexColor} onChange={setHexColor} />
      <div className="flex flex-row h-4">
        {presets.map((preset) => (
          <div
            key={preset}
            className="w-4 h-4 rounded-md border border-gray-500 cursor-pointer mx-0.5 overflow-hidden"
            onClick={() => setHexColor(preset)}
          >
            <div
              className="w-full h-full"
              style={{
                background: preset,
              }}
            />
          </div>
        ))}
      </div>
      <HexColorInput
        color={hexColor}
        type="text"
        onChange={(newColor) => {
          if (newColor.length !== 7) return;
          setHexColor(newColor);
        }}
        className="text-sm text-gray-900 focus:ring-green
        focus:border-green shadow-sm border-gray-500 rounded-md placeholder-gray"
        prefixed
      />
    </div>
  );
};
