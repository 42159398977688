import _ from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGlobalStore } from '@agerpoint/utilities';

import { routeTracking } from './route-tracking-setup.utilities';

interface RouteTrackingSetupProps {
  children: JSX.Element;
}

export const RouteTrackingSetup = ({ children }: RouteTrackingSetupProps) => {
  const { user } = useGlobalStore();

  const {
    actions: { sendEvent },
  } = useGlobalStore();
  const { pathname } = useLocation();
  const track = _.debounce((location) => {
    routeTracking(location, sendEvent);
  }, 1000);

  useEffect(() => {
    track(pathname);
  }, [user, pathname]);

  return children;
};
