import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const AgercloudMainPage = () => {
  return <ForceM2AppRoutes />;
};

const ForceM2AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/app')) {
      return;
    }

    const base = '/app';

    // regex to match /captures/:captureId...
    if (location.pathname.match(/\/captures\/\d+/)) {
      // get the id from the path
      const id = location.pathname.split('/')[2];
      const path = `${base}/capture/${id}`;

      window.location.href = path;
      return;
    }

    // regex to match /project/:projectId... where project id is a string uuid
    if (location.pathname.match(/\/project\/[a-f0-9-]+/)) {
      // get the id from the path
      const id = location.pathname.split('/')[2];
      const path = `${base}?project=${id}`;

      window.location.href = path;
      return;
    }

    // regex to match /ops/pipeline
    if (location.pathname.match(/\/ops\/pipeline/)) {
      let path = `${base}/admin/pipelines`;

      // add the rest of the path
      path += location.pathname.replace('/ops/pipeline', '') + location.search;

      // one more replace to fix the pipeline-jobs path
      path = path.replace('pipeline-jobs', 'jobs');

      window.location.href = path;
      return;
    }

    // regex to match /ops
    else if (location.pathname.match(/\/ops/)) {
      let path = `${base}/admin/operations`;

      // add the rest of the path
      path += location.pathname.replace('/ops', '') + location.search;

      // one more replace to fix the analytic-requests path
      path = path.replace('analytics', 'analytic-requests');

      window.location.href = path;
      return;
    }

    // regex to match /admin
    if (location.pathname.match(/\/admin/)) {
      let path = `${base}/admin/platform`;

      // add the rest of the path
      path += location.pathname.replace('/admin', '') + location.search;

      window.location.href = path;
      return;
    }

    window.location.href = base;
  }, [location]);

  return null;
};
