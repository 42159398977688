import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { useIsViteApp } from '@agerpoint/utilities';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminOrganizationsQueries } from './admin-organizations-queries';

export const AdminOrganizationsDetailsLicense = () => {
  const { organizationId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const {
    organizationQuery,
    licenseQuery,
    existingLicensePutMutation,
    newLicensePostMutation,
  } = useAdminOrganizationsQueries();

  const licenseExists = useRef<boolean>();

  useEffect(() => {
    licenseExists.current = !!licenseQuery.data;
    if (licenseQuery.data) {
      setLicense(licenseQuery.data);
    } else {
      setLicense({});
    }
  }, [licenseQuery.data]);

  const [license, setLicense] = useState<
    APIModels.CustomerLicense | undefined
  >();

  const saveLicense = useCallback(async () => {
    if (licenseExists.current) {
      existingLicensePutMutation.mutate({
        id: license?.id ?? 0,
        data: license as APIModels.CustomerLicense,
      });
    } else {
      newLicensePostMutation.mutate({
        data: {
          ...(license as APIModels.CustomerLicense),
          customerId: Number(organizationId),
        },
      });
    }
  }, [
    license,
    existingLicensePutMutation,
    newLicensePostMutation,
    organizationId,
  ]);

  if (organizationQuery.isLoading || licenseQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (organizationQuery.isError || licenseQuery.isError) {
    return (
      <PageErrorState
        entityName="organization"
        pluralEntityName="organizations"
        statusCode={
          licenseQuery.error?.response?.status ??
          organizationQuery.error?.response?.status ??
          500
        }
        tryAgainCallback={() => {
          organizationQuery.refetch();
          licenseQuery.refetch();
        }}
        tryAgainLoading={
          organizationQuery.isFetching || licenseQuery.isFetching
        }
        navigateBackCallback={() =>
          navigate(
            isViteApp
              ? '/app/admin/platform/organizations' + params
              : '/admin/organizations' + params
          )
        }
      />
    );
  }

  return (
    <div className="p-4 w-full flex flex-col max-w-lg gap-2">
      <Input.Text.Single
        id="sub-tier-input"
        label={<Input.Label label="Subscription Tier" />}
        value={license?.subscriptionTier ?? ''}
        setValue={(subscriptionTier) => {
          setLicense({ ...license, subscriptionTier });
        }}
      />
      <Input.Date.Single
        id="sub-start-date-input"
        label={<Input.Label label="Subscription Start Date" />}
        value={
          license?.subsciptionStartDateTime
            ? new Date(license?.subsciptionStartDateTime)
            : undefined
        }
        setValue={(subsciptionStartDateTime) => {
          setLicense({
            ...license,
            subsciptionStartDateTime: subsciptionStartDateTime?.toISOString(),
          });
        }}
      />
      <Input.Date.Single
        id="sub-end-date-input"
        label={<Input.Label label="Subscription End Date" />}
        value={
          license?.subsciptionEndDateTime
            ? new Date(license?.subsciptionEndDateTime)
            : undefined
        }
        setValue={(subsciptionEndDateTime) => {
          setLicense({
            ...license,
            subsciptionEndDateTime: subsciptionEndDateTime?.toISOString(),
          });
        }}
      />
      <Input.Number.Decimal
        id="storage-consumed-input"
        label={<Input.Label label="Storage Consumed" />}
        value={license?.storageConsumed ?? undefined}
        setValue={(storageConsumed) => {
          setLicense({
            ...license,
            storageConsumed: storageConsumed,
          });
        }}
        onlyPositive
      />
      <Input.Number.Decimal
        id="storage-allowance-input"
        label={<Input.Label label="Storage Allowance" />}
        value={license?.storageAllowance ?? undefined}
        setValue={(storageAllowance) => {
          setLicense({
            ...license,
            storageAllowance: storageAllowance,
          });
        }}
        onlyPositive
      />
      <Input.Number.Decimal
        id="credits-consumed-input"
        label={<Input.Label label="Analytic Credits Consumed" />}
        value={license?.analyticCreditsConsumed ?? undefined}
        setValue={(analyticCreditsConsumed) => {
          setLicense({
            ...license,
            analyticCreditsConsumed: analyticCreditsConsumed,
          });
        }}
        onlyPositive
      />
      <Input.Number.Decimal
        id="credits-allowance-input"
        label={<Input.Label label="Analytic Credits Allowance" />}
        value={license?.analyticCreditsAllowance ?? undefined}
        setValue={(analyticCreditsAllowance) => {
          setLicense({
            ...license,
            analyticCreditsAllowance: analyticCreditsAllowance,
          });
        }}
        onlyPositive
      />
      <Input.Number.Decimal
        id="processing-consumed-input"
        label={<Input.Label label="Processing Consumed" />}
        value={license?.processingConsumed ?? undefined}
        setValue={(processingConsumed) => {
          setLicense({
            ...license,
            processingConsumed: processingConsumed,
          });
        }}
        onlyPositive
      />
      <Input.Number.Decimal
        id="processing-allowance-input"
        label={<Input.Label label="Processing Allowance" />}
        value={license?.processingAllowance ?? undefined}
        setValue={(processingAllowance) => {
          setLicense({
            ...license,
            processingAllowance: processingAllowance,
          });
        }}
        onlyPositive
      />
      <div className="flex flex-row w-full justify-end py-4">
        <Button.Primary
          id="save-license-button"
          label="Save"
          disabled={licenseQuery.isLoading}
          loading={
            existingLicensePutMutation.isPending ||
            newLicensePostMutation.isPending
          }
          onClick={saveLicense}
        />
      </div>
    </div>
  );
};
