/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CustomerLicense } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCustomerLicenseByCustomerId = (
  customerId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CustomerLicense>(
    {
      url: `/api/CustomerLicenses/Customer/${encodeURIComponent(
        String(customerId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomerLicenseByCustomerIdQueryKey = (
  customerId: number
) => {
  return [`/api/CustomerLicenses/Customer/${customerId}`] as const;
};

export const getGetCustomerLicenseByCustomerIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerLicenseByCustomerId>>,
  TError = ErrorType<void>
>(
  customerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerLicenseByCustomerId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerLicenseByCustomerIdQueryKey(customerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerLicenseByCustomerId>>
  > = ({ signal }) =>
    getCustomerLicenseByCustomerId(customerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerLicenseByCustomerId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerLicenseByCustomerIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerLicenseByCustomerId>>
>;
export type GetCustomerLicenseByCustomerIdQueryError = ErrorType<void>;

export const useGetCustomerLicenseByCustomerId = <
  TData = Awaited<ReturnType<typeof getCustomerLicenseByCustomerId>>,
  TError = ErrorType<void>
>(
  customerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerLicenseByCustomerId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerLicenseByCustomerIdQueryOptions(
    customerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCustomerLicense = (
  customerLicense: BodyType<CustomerLicense>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CustomerLicense>(
    {
      url: `/api/CustomerLicenses`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: customerLicense,
    },
    options
  );
};

export const getPostCustomerLicenseMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerLicense>>,
    TError,
    { data: BodyType<CustomerLicense> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCustomerLicense>>,
  TError,
  { data: BodyType<CustomerLicense> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCustomerLicense>>,
    { data: BodyType<CustomerLicense> }
  > = (props) => {
    const { data } = props ?? {};

    return postCustomerLicense(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCustomerLicenseMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCustomerLicense>>
>;
export type PostCustomerLicenseMutationBody = BodyType<CustomerLicense>;
export type PostCustomerLicenseMutationError = ErrorType<void>;

export const usePostCustomerLicense = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerLicense>>,
    TError,
    { data: BodyType<CustomerLicense> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCustomerLicense>>,
  TError,
  { data: BodyType<CustomerLicense> },
  TContext
> => {
  const mutationOptions = getPostCustomerLicenseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putCustomerLicenseById = (
  id: number,
  customerLicense: BodyType<CustomerLicense>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CustomerLicense>(
    {
      url: `/api/CustomerLicenses/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: customerLicense,
    },
    options
  );
};

export const getPutCustomerLicenseByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCustomerLicenseById>>,
    TError,
    { id: number; data: BodyType<CustomerLicense> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCustomerLicenseById>>,
  TError,
  { id: number; data: BodyType<CustomerLicense> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCustomerLicenseById>>,
    { id: number; data: BodyType<CustomerLicense> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCustomerLicenseById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCustomerLicenseByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCustomerLicenseById>>
>;
export type PutCustomerLicenseByIdMutationBody = BodyType<CustomerLicense>;
export type PutCustomerLicenseByIdMutationError = ErrorType<void>;

export const usePutCustomerLicenseById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCustomerLicenseById>>,
    TError,
    { id: number; data: BodyType<CustomerLicense> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCustomerLicenseById>>,
  TError,
  { id: number; data: BodyType<CustomerLicense> },
  TContext
> => {
  const mutationOptions = getPutCustomerLicenseByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
