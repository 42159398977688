import mixpanel from 'mixpanel-browser';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useBeforeUnload } from 'react-router-dom';

import { MixpanelNames } from '@agerpoint/types';
import {
  AddTaskGroupArgs,
  BackgroundTask,
  BackgroundTaskArgs,
  BackgroundTaskGroup,
  BackgroundTaskGroupResult,
  BackgroundTaskManagerContextProps,
  BackgroundTaskMangerProps,
  BackgroundTaskResult,
  BackgroundTaskResultObject,
} from '@agerpoint/types';
import { generateUniqueID } from '@agerpoint/utilities';

const BackgroundTaskManagerContext =
  createContext<BackgroundTaskManagerContextProps>({
    addTaskGroup: () => {
      throw new Error('Background Task Manager is not mounted!');
    },
    removeTaskGroup: () => {
      throw new Error('Background Task Manager is not mounted!');
    },
    updateTaskProgress: () => {
      throw new Error('Background Task Manager is not mounted!');
    },
    cancelTaskGroup: () => {
      throw new Error('Background Task Manager is not mounted!');
    },
    createBackgroundTask: () => {
      throw new Error('Background Task Manager is not mounted!');
    },
    retryTaskGroup: () => {
      throw new Error('Background Task Manager is not mounted!');
    },
    togglePauseTaskGroup: () => {
      throw new Error('Background Task Manager is not mounted!');
    },
    taskGroups: [],
  });

export const useBackgroundTaskManager = () => {
  return useContext(BackgroundTaskManagerContext);
};

export const BackgroundTaskManager = ({
  children,
  maxConcurrency,
}: BackgroundTaskMangerProps) => {
  const [taskGroupQueue, setTaskGroupQueue] = useState<BackgroundTaskGroup[]>(
    []
  );
  const [concurrency, setConcurrency] = useState({
    current: 0,
    max: maxConcurrency,
  });

  useBeforeUnload(
    useCallback(
      (event) => {
        if (concurrency.current) {
          event.returnValue = true;
        }
      },
      [concurrency]
    )
  );

  useEffect(() => {
    if (concurrency.current >= concurrency.max) {
      return;
    }

    // Counts of currently processed tasks in the groups
    const counts = taskGroupQueue.map((g) => {
      if (g.isPaused || g.groupResult) {
        return Infinity;
      }
      return g.tasks.reduce((prev, curr) => {
        if (curr.isBeingProcessed) {
          return prev + 1;
        }
        return prev;
      }, 0);
    });

    // If everything is finished/paused or there's no tasks to work with
    if (counts.length === 0 || counts.every((c) => c === Infinity)) {
      return;
    }

    // Group index with smallest number of currently processed tasks
    const groupIndex = counts.indexOf(Math.min(...counts));

    const group = taskGroupQueue[groupIndex];

    for (const task of group.tasks) {
      if (task.result || task.isBeingProcessed) {
        continue;
      }
      const processTask = async () => {
        task.isBeingProcessed = true;
        setConcurrency((prev) => ({ ...prev, current: prev.current + 1 }));
        setTaskGroupQueue((prev) => [...prev]);

        try {
          task.result = await task.task(
            task.groupId,
            task.taskId,
            task.abortSignal
          );
        } finally {
          task.isBeingProcessed = false;

          setConcurrency((prev) => ({
            ...prev,
            current: prev.current - 1,
          }));

          setTaskGroupQueue((prev) => [...prev]);
        }
      };
      processTask();
      return;
    }
  });

  useEffect(() => {
    // Finalizing tasks, running callbacks and setting a result of a group
    taskGroupQueue.forEach((group) => {
      if (group.groupResult || group.isBeingFinalized) {
        // This group is finalized or being finalized so skip it
        return;
      }

      if (group.abortController.signal.aborted) {
        // This group is aborted, update it's result
        if (group.onCancel) {
          group.isBeingFinalized = true;
          group.onCancel(group).then(() => {
            group.groupResult = BackgroundTaskGroupResult.cancel;
            group.tasks.forEach((t) => {
              if (!t.result) {
                t.result = { type: BackgroundTaskResult.cancel };
              }
            });
            group.isBeingFinalized = false;
            setTaskGroupQueue((prev) => [...prev]);
          });
        } else {
          group.groupResult = BackgroundTaskGroupResult.cancel;
          group.tasks.forEach((t) => {
            if (!t.result) {
              t.result = { type: BackgroundTaskResult.cancel };
            }
          });
          setTaskGroupQueue((prev) => [...prev]);
        }
        return;
      }

      let allGroupTasksFinished = true;
      let groupHasErrors = false;
      // Look for tasks in a group that are finished or have errors
      for (const t of group.tasks) {
        if (t.result === undefined) {
          allGroupTasksFinished = false;
        } else if (t.result.type === BackgroundTaskResult.error) {
          groupHasErrors = true;
        }
      }

      if (allGroupTasksFinished && groupHasErrors) {
        if (group.onError) {
          group.isBeingFinalized = true;
          group.onError(group).then(() => {
            group.groupResult = BackgroundTaskGroupResult.error;
            group.isBeingFinalized = false;
            setTaskGroupQueue((prev) => [...prev]);
          });
          setTaskGroupQueue((prev) => [...prev]);
        } else {
          group.groupResult = BackgroundTaskGroupResult.error;
          setTaskGroupQueue((prev) => [...prev]);
        }

        mixpanel.track(MixpanelNames.BackgroundTaskError, {
          description: group.groupDesc,
          amountOfTasks: group.tasks.length,
        });
      } else if (allGroupTasksFinished) {
        if (group.onSuccess) {
          group.isBeingFinalized = true;
          group
            .onSuccess(group)
            .then(() => {
              group.isBeingFinalized = false;
              group.groupResult = BackgroundTaskGroupResult.success;
              setTaskGroupQueue((prev) => [...prev]);
            })
            .catch(() => {
              group.isBeingFinalized = false;
              group.groupResult = BackgroundTaskGroupResult.error;
              setTaskGroupQueue((prev) => [...prev]);
            });
          setTaskGroupQueue((prev) => [...prev]);
        } else {
          group.groupResult = BackgroundTaskGroupResult.success;
          setTaskGroupQueue((prev) => [...prev]);
        }
      }
    });
  }, [taskGroupQueue]);

  const updateTaskProgress = useCallback(
    (groupId: string, taskId: string, progress: number) => {
      setTaskGroupQueue((prev) => {
        const i = prev.findIndex((g) => g.groupId === groupId);
        if (i === -1) {
          return prev;
        }
        const group = prev[i];
        const j = group.tasks.findIndex((t) => t.taskId === taskId);
        if (j === -1) {
          return prev;
        }
        const copy = [...prev];
        copy[i].tasks[j].progress = progress;
        return copy;
      });
    },
    []
  );

  const removeTaskGroup = useCallback((groupId: string) => {
    setTaskGroupQueue((prev) => {
      const i = prev.findIndex((g) => g.groupId === groupId);
      if (i > -1) {
        if (prev[i].groupResult) {
          const copy = [...prev];
          copy.splice(i, 1);
          return copy;
        }
      }
      return prev;
    });
  }, []);

  const addTaskGroup = useCallback(
    ({
      groupDesc,
      groupTasks,
      onSuccess,
      onError,
      onCancel,
      tags,
      groupCustomPayload,
      isCancellable = false,
    }: AddTaskGroupArgs): string => {
      const tasks: BackgroundTask[] = [];
      const abortController = new AbortController();
      const groupId = generateUniqueID();

      groupTasks.forEach((t) => {
        const taskId = generateUniqueID();
        tasks.push({
          ...t,
          taskId,
          groupId,
          abortSignal: abortController.signal,
        });
      });

      mixpanel.track(MixpanelNames.BackgroundTaskStarted, {
        description: groupDesc,
        amountOfTasks: tasks.length,
      });

      const group: BackgroundTaskGroup = {
        groupId,
        groupDesc,
        onSuccess,
        onError,
        onCancel,
        abortController,
        tasks,
        retryAttempts: 0,
        isPaused: false,
        isBeingFinalized: false,
        tags: tags ?? [],
        groupCustomPayload,
        isCancellable,
      };

      setTaskGroupQueue((prev) => [...prev, group]);

      return groupId;
    },
    []
  );

  const cancelTaskGroup = useCallback((groupId: string) => {
    setTaskGroupQueue((prev) => {
      const i = prev.findIndex((g) => g.groupId === groupId);
      if (i > -1) {
        if (!prev[i].groupResult) {
          prev[i].abortController.abort();
          mixpanel.track(MixpanelNames.BackgroundTaskCanceled, {
            description: prev[i].groupDesc,
            amountOfTasks: prev[i].tasks.length,
          });
        }
      }
      return [...prev];
    });
  }, []);

  const createBackgroundTask = useCallback(
    (
      taskDesc: string,
      task: (
        resolve: (value: BackgroundTaskResultObject) => void,
        reject: (reason?: BackgroundTaskResultObject) => void,
        abortSignal: AbortSignal,
        groupId: string,
        taskId: string
      ) => Promise<void>,
      taskCustomPayload: any
    ): BackgroundTaskArgs => {
      const taskArgs: BackgroundTaskArgs = {
        taskDesc,
        taskCustomPayload,
        task: (groupId: string, taskId: string, abortSignal: AbortSignal) =>
          new Promise<BackgroundTaskResultObject>((resolve, reject) => {
            task(resolve, reject, abortSignal, groupId, taskId).catch((e) => {
              // Case of Unhandled AbortError
              if (e.name === 'AbortError') {
                resolve({ type: BackgroundTaskResult.cancel });
              } else {
                // Case of unexpected Error
                console.error('Unexpected Error in Background Task', e);
                resolve({ type: BackgroundTaskResult.error, payload: e });
              }
            });
          }).catch((e) => {
            // Catch the Abort error and pass it down as a Promise's result
            return e;
          }),
      };
      return taskArgs;
    },
    []
  );

  const retryTaskGroup = useCallback((groupId: string) => {
    setTaskGroupQueue((prev) => {
      const i = prev.findIndex((g) => g.groupId === groupId);

      if (i > -1) {
        for (const task of prev[i].tasks) {
          if (task?.result?.type === BackgroundTaskResult.error) {
            task.result = undefined;
            task.progress = undefined;
          }
        }

        prev[i].groupResult = undefined;
        prev[i].retryAttempts += 1;

        mixpanel.track(MixpanelNames.BackgroundTaskRetried, {
          description: prev[i].groupDesc,
          amountOfTasks: prev[i].tasks.length,
          attempt: prev[i].retryAttempts,
        });
      }
      return [...prev];
    });
  }, []);

  const togglePauseTaskGroup = useCallback((groupId: string) => {
    setTaskGroupQueue((prev) => {
      const i = prev.findIndex((g) => g.groupId === groupId);

      if (i > -1) {
        prev[i].isPaused = !prev[i].isPaused;

        if (prev[i].isPaused) {
          mixpanel.track(MixpanelNames.BackgroundTaskPaused, {
            description: prev[i].groupDesc,
            amountOfTasks: prev[i].tasks.length,
          });
        }
      }

      return [...prev];
    });
  }, []);

  return (
    <BackgroundTaskManagerContext.Provider
      value={{
        addTaskGroup,
        removeTaskGroup,
        updateTaskProgress,
        cancelTaskGroup,
        createBackgroundTask,
        retryTaskGroup,
        togglePauseTaskGroup,
        taskGroups: taskGroupQueue,
      }}
    >
      {children}
    </BackgroundTaskManagerContext.Provider>
  );
};
