import { Capture, CustomerAnalytic, User } from '@agerpoint/api';

import { CapturesViewerImage, OrientationOptions } from './capture-viewer';
import {
  CaptureMaterialClassifications,
  CaptureObjectMarkers,
} from './captures';
import { UndoButtonProps } from './component';
import { EffectNames } from './effect-names';
import { LDFlagSet } from './launchdarkly';
import { MaterialType, PointSizeType } from './potree-types';
import { QueueObject } from './queue';
import { NewToastProps, ToastProps } from './toast';

export enum ViewerTypeOld {
  NONE = 'none',
  POTREE = 'potree',
  THREE_PLY = 'three-ply',
}

export type PayloadType<T> = T;
export interface GlobalStore {
  actions: {
    sendEvent: (eventName: string, eventBody?: any) => void;
    setUser: (newUser: User | null) => void;
    setPermissions: (newPermissions: LDFlagSet) => void;
    setCaptures: (newCaptures: any[]) => void;
    setCapturesSidebarOpen: (newCapturesSidebarOpen: boolean) => void;
    setCaptureObjectMarkers: (
      newCaptureObjectMarkers: CaptureObjectMarkers[]
    ) => void;
    addCaptureObjectMarkersHistory: (
      marker: CaptureObjectMarkers,
      id: number
    ) => void;
    removeCaptureObjectMarkersHistory: (id: number) => void;
    setCaptureObjectWindowOpen: (newCaptureObjectWindowOpen: boolean) => void;

    addToast: (newToast: NewToastProps) => void;
    removeToast: (toastId: string) => void;
    showToastPanel: (milliseconds?: number) => void;
    hideToastPanel: () => void;
    addUndo: (newUndo: UndoButtonProps) => void;
    removeUndo: (undoId: string) => void;
    clearAllUndo: () => void;
    setSiteWideLoading: (value: boolean) => void;
    addToPendingQueue: (a: QueueObject) => void;
    updatePendingQueueById: (id: string, a: QueueObject) => void;
    dispatchEffect: <T>(
      effectName: EffectNames,
      payload?: PayloadType<T>
    ) => void;
    setTwoDimensionDrawing: (isActive: boolean) => void;
  };
  captures: Capture[];
  captureSidebarOpen: boolean;
  captureObjectMarkers: CaptureObjectMarkers[];
  captureObjectMarkersHistory: {
    [key: string]: CaptureObjectMarkers;
  };
  captureObjectWindowOpen: boolean;
  user: User | null;
  permissions: LDFlagSet;
  toasts: ToastProps[];
  showingToastPanel: boolean;
  toastPanelTimeout?: ReturnType<typeof setTimeout>;
  twoDimensionDrawingIsActive: boolean;
  undos: UndoButtonProps[];
  siteWideLoading: boolean;
  pendingQueue: QueueObject[];
  subscribe: <T>(
    effectName: string,
    listener: (p: PayloadType<T>) => void
  ) => void;
  subscriptions: subscriptions;
  standAloneViewer: {
    eptId?: string;
    captureId?: string;
    pointBudget?: number;
    pointStyle?: MaterialType;
    pointSizeType?: PointSizeType;
    pointSize?: number;
    splatQuality?: string;
    actions: {
      setEptId: (eptId?: string) => void;
      setCaptureId: (captureId?: string) => void;
      setPointBudget: (pointBudget?: number) => void;
      setPointStyle: (pointStyle: MaterialType) => void;
      setPointSizeType: (pointSize: number) => void;
      setPointSize: (pointSize: number) => void;
      setSplatQuality: (splatQuality: string) => void;
    };
  };
  bottomBar: {
    isOpen: boolean;
    actions: {
      setIsOpen: (open: boolean) => void;
    };
  };
  sidebar: {
    isOpen: boolean;
    actions: {
      setIsOpen: (open: boolean) => void;
    };
  };
  capturesViewer: {
    viewerType: ViewerTypeOld | undefined;
    pointStyle?: MaterialType;
    captureImages: CapturesViewerImage[];
    imageOrientation: OrientationOptions;
    captureMaterialClassificationSelected: CaptureMaterialClassifications;
    captureMaterialClassificationColors: Partial<
      Record<CaptureMaterialClassifications, ClassificationObject>
    >;
    actions: {
      setCaptureImages: (captureImages: CapturesViewerImage[]) => void;
      setImageOrientation: (oriatation: OrientationOptions) => void;
      setPointStyle: (pointStyle: MaterialType) => void;
      setViewerType: (viewerType: ViewerTypeOld | undefined) => void;
      setCaptureMaterialClassificationSelected: (
        material: CaptureMaterialClassifications
      ) => void;
      setCaptureMaterialClassificationColor: (
        material: CaptureMaterialClassifications,
        color: number[],
        visible: boolean
      ) => void;
      setCaptureMaterialClassificationColors: (
        newColorLookup: Partial<
          Record<CaptureMaterialClassifications, ClassificationObject>
        >
      ) => void;
    };
  };
  analytics: {
    availableAnalytics: CustomerAnalytic[];
    actions: {
      setAvailableAnalytics: (availableAnalytics: CustomerAnalytic[]) => void;
    };
  };
}

export type UserStore = Pick<GlobalStore, 'user' | 'permissions' | 'actions'>;
type subscriptions = {
  [key: string]: ((payload: any) => void)[];
};

export interface ClassificationObject {
  color: number[];
  visible: boolean;
}
