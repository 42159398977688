import { useCallback, useEffect, useState } from 'react';

interface KeysWatched {
  shiftKey?: boolean;
}

export const useKeyPress = (): KeysWatched => {
  const [keyPressed, setKeyPressed] = useState<KeysWatched>({});

  const downHandler = useCallback(
    (event: KeyboardEvent) => {
      const { shiftKey } = event;

      if (shiftKey && !keyPressed.shiftKey) {
        setKeyPressed({ ...keyPressed, shiftKey });
      }
    },
    [keyPressed]
  );

  const upHandler = useCallback(
    (event: KeyboardEvent) => {
      const { shiftKey } = event;

      if (!shiftKey && keyPressed.shiftKey) {
        setKeyPressed({ ...keyPressed, shiftKey });
      }
    },
    [keyPressed]
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);

  return keyPressed;
};
