import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Capture, CaptureExtractionJob } from '@agerpoint/api';
import { useIsViteApp } from '@agerpoint/utilities';

import { QaqcCaptureDetailsSubsection } from '../qaqc-capture-details-subsection/qaqc-capture-details-subsection';
import { QaqcCaptureJobAssignmentButton } from '../qaqc-capture-job-assignment-button/qaqc-capture-job-assignment-button';
import { QaqcCaptureJobStatusIndicator } from '../qaqc-capture-job-status-indicator/qaqc-capture-job-status-indicator';
import { QaqcJobPicker } from '../qaqc-job-picker/qaqc-job-picker';

interface QaqcTopBarsProps {
  capture: Capture | null;
  extractionJobs: CaptureExtractionJob[] | null;
  eptId?: string;
  extId?: string | undefined;
}

export const QaqcTopBars = ({
  capture,
  extractionJobs,
  eptId,
  extId,
}: QaqcTopBarsProps) => {
  const [expandedAssignmentPanel] = useState(false);
  const [expandedCaptureDetailsPanel, setExpandedCaptureDetailsPanel] =
    useState(false);

  const [currentExtractionJob, setCurrentExtractionJob] =
    useState<CaptureExtractionJob>();

  const isViteApp = useIsViteApp();

  useEffect(() => {
    if (extractionJobs && extId) {
      const extractionJob = extractionJobs.find((e) => e.id === +extId);
      if (extractionJob) {
        setCurrentExtractionJob(extractionJob);
      }
    }
  }, [extractionJobs, extId]);

  const buildRecord = (title?: React.ReactNode, value?: React.ReactNode) => {
    return (
      <div className="flex flex-col text-xs">
        <div className="leading-4 text-gray-400 whitespace-nowrap">
          {title ?? ''}
        </div>
        <div className="text-white">{value ?? ''}</div>
      </div>
    );
  };

  if (!capture || !extractionJobs) {
    return null;
  }

  return (
    <div className="absolute inset-x-0 top-0 z-50 flex flex-row items-start px-2 gap-2 pointer-events-none">
      <div
        className="flex items-center bg-gray-900 rounded-b-lg shadow p-2 pointer-events-auto"
        onPointerOver={(e) => {
          setExpandedCaptureDetailsPanel(true);
        }}
        onPointerOut={(e) => {
          setExpandedCaptureDetailsPanel(false);
        }}
        style={{ maxWidth: '250px' }}
      >
        <div className="w-full text-center">
          <Link
            to={
              isViteApp
                ? `/app/capture/${capture.id}`
                : `/captures/${capture.id}/${eptId}`
            }
            className="underline hover:text-gray-400 text-white"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              window.open(
                isViteApp
                  ? `/app/capture/${capture.id}`
                  : `/captures/${capture.id}/${eptId}`,
                '_blank',
                'noopener noreferrer'
              );
            }}
          >
            {capture.captureName}
          </Link>
          <div
            className={`text-left overflow-hidden ${
              expandedCaptureDetailsPanel ? 'max-h-full' : 'max-h-0'
            }`}
          >
            <QaqcCaptureDetailsSubsection
              capture={capture}
              extractionJob={currentExtractionJob}
              setExtractionJob={setCurrentExtractionJob}
              isExpanded={expandedCaptureDetailsPanel}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-grow items-center h-full">
        <QaqcJobPicker />
      </div>
      <div className="bg-gray-900 rounded-b-lg shadow p-2 flex flex-col flex-wrap pointer-events-auto">
        <div className="flex flex-col gap-2 items-center">
          <QaqcCaptureJobStatusIndicator
            extractionJob={currentExtractionJob}
            setExtractionJob={setCurrentExtractionJob}
          />
          <QaqcCaptureJobAssignmentButton
            extractionJob={currentExtractionJob}
            setExtractionJob={setCurrentExtractionJob}
          />
        </div>
        <div
          className={`overflow-hidden ${
            expandedAssignmentPanel ? 'max-h-full' : 'max-h-0'
          }`}
        >
          <div className="flex flex-col px-1">
            {buildRecord('Completed By:', 'none')}
            {buildRecord('Completed Date:', 'none')}
          </div>
        </div>
      </div>
    </div>
  );
};
