import { colord } from 'colord';
import { ReactNode, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import compare from 'trivial-compare';

import {
  GroupToggle,
  Input,
  SvgElement,
  getInterpolator,
  getNumberDecimals,
} from '@agerpoint/component';
import {
  CategorizedStyle,
  ClassificationPointCloudStyle,
  GraduatedStyle,
  Layer,
  SetVisibleFn,
  toggleExpansionFn,
} from '@agerpoint/types';

import { EllipsisIconButton } from '../icon-buttons/ellipsis';

interface LayerListItemProps {
  layer: Layer;
  setVisible: SetVisibleFn;
  setStyle: (layer: Layer) => void;
  updateName?: (layerId: number, name: string) => Promise<void>;
  checkboxLabel: ReactNode;
  draggableProvided?: DraggableProvided;
  selected: boolean;
  disabled?: boolean;
  onSelect: () => void;
  toggleExpansion?: toggleExpansionFn;
  currentExpansionId?: string;
  setSelectedLayerId: (layerId?: number | undefined) => void;
  onSingleSelectItems:
    | ((layer: number, groupId: number, layerTypeId: number) => void)
    | undefined;
  groupId: number;
}
export function LayerListItem({
  layer,
  setVisible,
  setStyle,
  draggableProvided,
  checkboxLabel,
  selected,
  disabled,
  currentExpansionId,
  toggleExpansion,
  onSelect,
  setSelectedLayerId,
  onSingleSelectItems,
  groupId,
}: LayerListItemProps) {
  const isCurrentExpansionContent = currentExpansionId === `layer-${layer.id}`;
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [isBinsOpen, setBinsOpen] = useState<boolean>(false);
  const fillColor =
    'style' in layer && layer.style && 'fillColor' in layer.style
      ? layer.style.fillColor
      : undefined;
  const subClasses =
    fillColor &&
    typeof fillColor !== 'string' &&
    (fillColor.type === 'graduated' || fillColor.type === 'categorized')
      ? fillColor
      : layer.type === 'PointCloud' && layer.style.type === 'classification'
      ? layer.style
      : undefined;

  return (
    <>
      <div
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        ref={draggableProvided?.innerRef}
        {...draggableProvided?.draggableProps}
        className={`h-8 flex items-center group cursor-pointer transition-all duration-200 ease-in-out ${
          selected ? 'bg-blue-100 hover:bg-blue-200' : 'hover:bg-gray-50'
        }`}
      >
        <div {...draggableProvided?.dragHandleProps}>
          <SvgElement
            type="HandleIcon"
            className="w-4 h-4 ml-2 text-gray-400 invisible group-hover:visible"
          />
        </div>
        <div className="w-4 mr-1 h-full flex-shrink-0 flex items-center justify-center">
          {subClasses ? (
            <GroupToggle
              isOpen={isBinsOpen}
              onClick={() => setBinsOpen(!isBinsOpen)}
            />
          ) : null}
        </div>
        {!(layer.type === 'File' || layer.type === 'Attachment') && (
          <span>
            <Input.Checkbox
              id={`layer-${layer.id}`}
              disabled={disabled}
              value={layer.visible}
              setValue={(checked) => setVisible(layer.id, checked)}
            />
          </span>
        )}
        <button
          className={
            layer.type === 'File' || layer.type === 'Attachment'
              ? `truncate flex-grow ml-7 h-full`
              : `truncate ml-2 flex-grow h-full`
          }
          onClick={onSelect}
        >
          {checkboxLabel}
        </button>

        <div className="flex mr-1">
          {
            <EllipsisIconButton
              className="block h-full"
              show={mouseOver || isCurrentExpansionContent}
              onClick={onToggleExpansion}
            />
          }
        </div>
      </div>
      {subClasses && isBinsOpen && (
        <div className="ml-14">
          <BinList style={subClasses} onChangeVisible={onChangeBinVisible} />
        </div>
      )}
    </>
  );

  function onToggleExpansion() {
    if (toggleExpansion) {
      onSelect();
      setSelectedLayerId(layer.id);
      onSingleSelectItems?.(layer.id, groupId, layer.typeId);
      toggleExpansion({
        type: 'layer',
        key: `layer-${layer.id}`,
      });
    }
  }

  function onChangeBinVisible(binIndex: number, visible: boolean) {
    if (!('style' in layer) || !subClasses) {
      return;
    }

    if (subClasses.type === 'graduated' || subClasses.type === 'categorized') {
      const newBins = [...subClasses.bins];
      newBins[binIndex] = { ...subClasses.bins[binIndex], visible };

      const newLayer = {
        ...layer,
        style: {
          ...layer.style,
          fillColor: { ...subClasses, bins: newBins },
        },
      };

      setStyle(newLayer as Layer);
    } else {
      const newClasses = [...subClasses.classes];
      newClasses[binIndex] = { ...subClasses.classes[binIndex], visible };

      const newLayer = {
        ...layer,
        style: {
          ...layer.style,
          classes: newClasses,
        },
      };

      setStyle(newLayer as Layer);
    }
  }
}

interface BinList {
  style: GraduatedStyle | CategorizedStyle | ClassificationPointCloudStyle;
  onChangeVisible: (binIndex: number, visible: boolean) => void;
}

function BinList({ style, onChangeVisible }: BinList) {
  if (style.type === 'graduated') {
    const interpolator = getInterpolator(style.gradient, style.bins.length);
    const bins = [...style.bins].sort((a, b) =>
      compare(a.range[0], b.range[0])
    );
    const globalMax = bins[bins.length - 1].range[1];
    const decimals = getNumberDecimals(globalMax);
    return (
      <div>
        {style.bins.map(({ visible, range: [min, max] }, i) => {
          const backgroundColor = interpolator(i);
          const textLabel = `${min.toFixed(decimals)} - ${max.toFixed(
            decimals
          )}`;
          const borderColor = colord(backgroundColor).darken().toRgbString();
          return (
            <BinLabel
              key={i}
              visible={visible}
              textLabel={textLabel}
              borderColor={borderColor}
              backgroundColor={backgroundColor}
              onChangeVisible={(checked: boolean) =>
                onChangeVisible(i, checked)
              }
            />
          );
        })}
      </div>
    );
  } else if (style.type === 'categorized') {
    const interpolator = getInterpolator(style.gradient, style.bins.length);

    return (
      <div>
        {style.bins.map(({ visible, value }, i) => {
          const backgroundColor = interpolator(i);
          const borderColor = colord(backgroundColor).darken().toRgbString();
          return (
            <BinLabel
              key={i}
              visible={visible}
              textLabel={`${value}`}
              borderColor={borderColor}
              backgroundColor={backgroundColor}
              onChangeVisible={(checked: boolean) =>
                onChangeVisible(i, checked)
              }
            />
          );
        })}
      </div>
    );
  } else if (style.type === 'classification') {
    const interpolator = getInterpolator(style.gradient, style.classes.length);
    return (
      <div>
        {style.classes.map(({ visible, name, color }, i) => {
          let backgroundColor = interpolator(i);
          if (color) {
            backgroundColor = color;
          }
          const borderColor = colord(backgroundColor).darken().toRgbString();
          return (
            <BinLabel
              key={i}
              visible={visible}
              textLabel={`${name}`}
              borderColor={borderColor}
              backgroundColor={backgroundColor}
              onChangeVisible={(checked: boolean) =>
                onChangeVisible(i, checked)
              }
            />
          );
        })}
      </div>
    );
  }
  return null;
}

interface BinLabelProps {
  visible: boolean;
  backgroundColor: string;
  textLabel: string;
  borderColor: string;
  onChangeVisible: (checked: boolean) => void;
}

function BinLabel({
  visible,
  backgroundColor,
  textLabel,
  borderColor,
  onChangeVisible,
}: BinLabelProps) {
  return (
    <div className="py-1">
      <Input.Checkbox
        id={textLabel + '-checkbox'}
        value={visible}
        setValue={onChangeVisible}
        label={
          <div className="flex items-center text-sm font-display-condensed">
            <div
              className="w-3 h-5 rounded-sm border mr-1"
              style={{
                backgroundColor,
                borderColor,
              }}
            />
            {textLabel}
          </div>
        }
      />
    </div>
  );
}
