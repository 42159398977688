import { faFilterSlash, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { formatDateAndTime } from '@agerpoint/api';
import { Button, IconTypes, Input, SvgElement } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import {
  convertNameToCamelCase,
  useIsViteApp,
  usePageTitle,
  useQueryState,
} from '@agerpoint/utilities';

import { useAdminAnalyticsQueries } from './admin-analytics-queries';

export const AdminAnalyticsList = () => {
  usePageTitle(() => 'Platform - Analytics', []);
  const navigate = useNavigate();

  const [nameFilter, setNameFilter] = useQueryState({
    paramName: 'name',
    initialValue: '',
    toUrlParam: (value) => value.trim(),
    fromUrlParam: (value) => value.trim(),
  });

  const [archivedFilter, setArchivedFilter] = useQueryState<boolean>({
    paramName: 'archived',
    initialValue: false,
    fromUrlParam: (a) => {
      if (a === 'yes') {
        return true;
      }
      return false;
    },
    toUrlParam: (a) => {
      return a ? 'yes' : '';
    },
  });

  const { analyticsQuery } = useAdminAnalyticsQueries();

  const filteredAnalytics = useMemo(() => {
    if (!analyticsQuery.data) return [];
    let result = [...analyticsQuery.data];

    if (nameFilter.trim() !== '') {
      result = result.filter((analytic) => {
        return analytic.analyticName
          ?.toLowerCase()
          .includes(nameFilter.toLowerCase().trim());
      });
    }

    result = result.filter((analytic) => analytic.archived === archivedFilter);

    result = result.sort((a, b) => compare(a.id, b.id));
    return result;
  }, [analyticsQuery, nameFilter, archivedFilter]);

  const hasFiltersApplied = useMemo(
    () => !!(nameFilter?.length ?? 0),
    [nameFilter]
  );

  const isViteApp = useIsViteApp();

  const [search] = useSearchParams();

  const clearFilters = useCallback(() => {
    setNameFilter('');

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('name');

    navigate(
      {
        pathname: isViteApp
          ? '/app/admin/platform/analytics'
          : '/admin/analytics',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, [search]);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="flex flex-row justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">Analytics</h1>
        <Button.Primary
          id="new-analytic-button"
          icon={faPlus}
          label="New Analytic"
          onClick={() => {
            navigate('new', {
              state: {
                params: window.location.search,
              },
            });
          }}
        />
      </div>
      <div className="flex flex-row px-4 gap-2 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="analytic-name-filter"
            value={nameFilter}
            setValue={setNameFilter}
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Input.Select.Inline
          id="analytic-availability-filter"
          options={[false, true]}
          optionBuilder={(o) => (o ? 'Archived' : 'Active')}
          value={archivedFilter}
          setValue={setArchivedFilter}
        />
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="h-full p-4">
        <Datatable
          id="admin-analytics-datatable"
          data={filteredAnalytics}
          columns={[
            {
              label: 'Name',
              value: (analytic) => analytic.analyticName,
              name: 'name',
            },
            {
              label: 'Description',
              value: (analytic) => analytic.analyticDescription,
              flex: 2,
              name: 'description',
            },
            {
              label: 'Icon',
              value: (analytic) => (
                <div className="flex flex-row gap-2" style={{ height: '20px' }}>
                  <SvgElement
                    className="h-full"
                    type={
                      convertNameToCamelCase(
                        `fa-${analytic?.icon}`
                      ) as IconTypes
                    }
                  />
                  <span>{analytic?.icon}</span>
                </div>
              ),
              name: 'icon',
            },
            {
              label: 'Created',
              value: (analytic) => formatDateAndTime(analytic.createDatetime),
              name: 'created',
            },
            {
              label: 'Updated',
              value: (analytic) => formatDateAndTime(analytic.updateDatetime),
              name: 'updated',
            },
          ]}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching analytics',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No analytics yet',
                  message: 'Create analytic to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Analytic',
                }
          }
          error={
            analyticsQuery.isError
              ? {
                  title: 'There was a problem loading analytics',
                  message: 'Try refreshing the page',
                  action: () => analyticsQuery.refetch(),
                }
              : undefined
          }
          cellOnClick={() => {
            return (analytic) => {
              navigate(`${analytic.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          loading={analyticsQuery.isLoading || filteredAnalytics === undefined}
          rowHeight={50}
          style={{ ...dataTableAgerStyle, tableMinWidth: 950 }}
        />
      </div>
    </div>
  );
};
