/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CloneLayerGroupCapturesRequest, LayerGroup } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getLayerGroup = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<LayerGroup[]>(
    { url: `/api/LayerGroups`, method: 'GET', signal },
    options
  );
};

export const getGetLayerGroupQueryKey = () => {
  return [`/api/LayerGroups`] as const;
};

export const getGetLayerGroupQueryOptions = <
  TData = Awaited<ReturnType<typeof getLayerGroup>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLayerGroup>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLayerGroupQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLayerGroup>>> = ({
    signal,
  }) => getLayerGroup(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLayerGroup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLayerGroupQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLayerGroup>>
>;
export type GetLayerGroupQueryError = ErrorType<void>;

export const useGetLayerGroup = <
  TData = Awaited<ReturnType<typeof getLayerGroup>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLayerGroup>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLayerGroupQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postLayerGroup = (
  layerGroup: BodyType<LayerGroup>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<LayerGroup>(
    {
      url: `/api/LayerGroups`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: layerGroup,
    },
    options
  );
};

export const getPostLayerGroupMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLayerGroup>>,
    TError,
    { data: BodyType<LayerGroup> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLayerGroup>>,
  TError,
  { data: BodyType<LayerGroup> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLayerGroup>>,
    { data: BodyType<LayerGroup> }
  > = (props) => {
    const { data } = props ?? {};

    return postLayerGroup(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLayerGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLayerGroup>>
>;
export type PostLayerGroupMutationBody = BodyType<LayerGroup>;
export type PostLayerGroupMutationError = ErrorType<void>;

export const usePostLayerGroup = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLayerGroup>>,
    TError,
    { data: BodyType<LayerGroup> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postLayerGroup>>,
  TError,
  { data: BodyType<LayerGroup> },
  TContext
> => {
  const mutationOptions = getPostLayerGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getLayerGroupById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<LayerGroup>(
    {
      url: `/api/LayerGroups/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetLayerGroupByIdQueryKey = (id: number) => {
  return [`/api/LayerGroups/${id}`] as const;
};

export const getGetLayerGroupByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getLayerGroupById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLayerGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLayerGroupByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLayerGroupById>>
  > = ({ signal }) => getLayerGroupById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLayerGroupById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLayerGroupByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLayerGroupById>>
>;
export type GetLayerGroupByIdQueryError = ErrorType<void>;

export const useGetLayerGroupById = <
  TData = Awaited<ReturnType<typeof getLayerGroupById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLayerGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLayerGroupByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putLayerGroup = (
  id: number,
  layerGroup: BodyType<LayerGroup>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<LayerGroup>(
    {
      url: `/api/LayerGroups/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: layerGroup,
    },
    options
  );
};

export const getPutLayerGroupMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putLayerGroup>>,
    TError,
    { id: number; data: BodyType<LayerGroup> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putLayerGroup>>,
  TError,
  { id: number; data: BodyType<LayerGroup> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putLayerGroup>>,
    { id: number; data: BodyType<LayerGroup> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putLayerGroup(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutLayerGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof putLayerGroup>>
>;
export type PutLayerGroupMutationBody = BodyType<LayerGroup>;
export type PutLayerGroupMutationError = ErrorType<void>;

export const usePutLayerGroup = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putLayerGroup>>,
    TError,
    { id: number; data: BodyType<LayerGroup> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putLayerGroup>>,
  TError,
  { id: number; data: BodyType<LayerGroup> },
  TContext
> => {
  const mutationOptions = getPutLayerGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteLayerGroup = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/LayerGroups/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteLayerGroupMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLayerGroup>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteLayerGroup>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteLayerGroup>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteLayerGroup(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteLayerGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteLayerGroup>>
>;

export type DeleteLayerGroupMutationError = ErrorType<void>;

export const useDeleteLayerGroup = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLayerGroup>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteLayerGroup>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteLayerGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureLocationsByLayerGroupId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<LayerGroup>(
    {
      url: `/api/LayerGroups/${encodeURIComponent(
        String(id)
      )}/CaptureLocations`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureLocationsByLayerGroupIdQueryKey = (id: number) => {
  return [`/api/LayerGroups/${id}/CaptureLocations`] as const;
};

export const getGetCaptureLocationsByLayerGroupIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureLocationsByLayerGroupId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureLocationsByLayerGroupId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureLocationsByLayerGroupIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureLocationsByLayerGroupId>>
  > = ({ signal }) =>
    getCaptureLocationsByLayerGroupId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureLocationsByLayerGroupId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureLocationsByLayerGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureLocationsByLayerGroupId>>
>;
export type GetCaptureLocationsByLayerGroupIdQueryError = ErrorType<void>;

export const useGetCaptureLocationsByLayerGroupId = <
  TData = Awaited<ReturnType<typeof getCaptureLocationsByLayerGroupId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureLocationsByLayerGroupId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureLocationsByLayerGroupIdQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProjectLayerGroups = (
  projectUuid: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<LayerGroup[]>(
    {
      url: `/api/LayerGroups/Projects/${encodeURIComponent(
        String(projectUuid)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetProjectLayerGroupsQueryKey = (projectUuid: string) => {
  return [`/api/LayerGroups/Projects/${projectUuid}`] as const;
};

export const getGetProjectLayerGroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectLayerGroups>>,
  TError = ErrorType<void>
>(
  projectUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectLayerGroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProjectLayerGroupsQueryKey(projectUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProjectLayerGroups>>
  > = ({ signal }) =>
    getProjectLayerGroups(projectUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!projectUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectLayerGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectLayerGroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectLayerGroups>>
>;
export type GetProjectLayerGroupsQueryError = ErrorType<void>;

export const useGetProjectLayerGroups = <
  TData = Awaited<ReturnType<typeof getProjectLayerGroups>>,
  TError = ErrorType<void>
>(
  projectUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectLayerGroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectLayerGroupsQueryOptions(
    projectUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const cloneLayerGroupCaptures = (
  originGroupCaptureId: number,
  destinationGroupCaptureId: number,
  cloneLayerGroupCapturesRequest: BodyType<CloneLayerGroupCapturesRequest>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/LayerGroups/${encodeURIComponent(
        String(originGroupCaptureId)
      )}/to/${encodeURIComponent(String(destinationGroupCaptureId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: cloneLayerGroupCapturesRequest,
    },
    options
  );
};

export const getCloneLayerGroupCapturesMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cloneLayerGroupCaptures>>,
    TError,
    {
      originGroupCaptureId: number;
      destinationGroupCaptureId: number;
      data: BodyType<CloneLayerGroupCapturesRequest>;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cloneLayerGroupCaptures>>,
  TError,
  {
    originGroupCaptureId: number;
    destinationGroupCaptureId: number;
    data: BodyType<CloneLayerGroupCapturesRequest>;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cloneLayerGroupCaptures>>,
    {
      originGroupCaptureId: number;
      destinationGroupCaptureId: number;
      data: BodyType<CloneLayerGroupCapturesRequest>;
    }
  > = (props) => {
    const { originGroupCaptureId, destinationGroupCaptureId, data } =
      props ?? {};

    return cloneLayerGroupCaptures(
      originGroupCaptureId,
      destinationGroupCaptureId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CloneLayerGroupCapturesMutationResult = NonNullable<
  Awaited<ReturnType<typeof cloneLayerGroupCaptures>>
>;
export type CloneLayerGroupCapturesMutationBody =
  BodyType<CloneLayerGroupCapturesRequest>;
export type CloneLayerGroupCapturesMutationError = ErrorType<void>;

export const useCloneLayerGroupCaptures = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cloneLayerGroupCaptures>>,
    TError,
    {
      originGroupCaptureId: number;
      destinationGroupCaptureId: number;
      data: BodyType<CloneLayerGroupCapturesRequest>;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof cloneLayerGroupCaptures>>,
  TError,
  {
    originGroupCaptureId: number;
    destinationGroupCaptureId: number;
    data: BodyType<CloneLayerGroupCapturesRequest>;
  },
  TContext
> => {
  const mutationOptions = getCloneLayerGroupCapturesMutationOptions(options);

  return useMutation(mutationOptions);
};
