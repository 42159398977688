import { faCheck, faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { CapturePipelineJobTable } from 'libs/feature/src/captures-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  APIClient,
  APIUtils,
  Capture,
  User,
  formatDateAndTime,
  useGetCaptureExtractionJobsByCaptureId,
  useGetCustomer,
  useGetUsersAvailibleFromCaptures,
  usePutCaptureById,
} from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import {
  EffectNames,
  ExtractionJob,
  UserClaims,
  getCaptureModeNiceName,
} from '@agerpoint/types';
import {
  hasClaims,
  useFormValidation,
  useGlobalStore,
} from '@agerpoint/utilities';

export const CaptureDetailsSectionMain = () => {
  const params = useParams();

  const id = useMemo(() => {
    return params.captureId ?? params.id;
  }, [params]);
  const [currentCaptureId, setCurrentCaptureId] = useState<number>(NaN);

  useEffect(() => {
    if (!id) {
      setCurrentCaptureId(NaN);
    } else {
      const idNum = parseInt(id);
      setCurrentCaptureId(idNum);
    }
  }, [id]);

  return currentCaptureId ? <CaptureDetailsModal /> : <div>Loading</div>;
};

const CaptureDetailsModal = () => {
  const {
    user,
    actions: { dispatchEffect },
  } = useGlobalStore();

  const params = useParams();

  const id = useMemo(() => {
    return params.captureId ?? params.id;
  }, [params]);

  const captureQuery = APIClient.useGetCaptureById(Number(id), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(id) }],
      enabled: Number.isSafeInteger(Number(id)),
    },
  });
  const [captureName, setCaptureName] = useState<string>('');

  useEffect(() => {
    if (captureQuery.data) {
      setCaptureName(captureQuery.data?.captureName ?? '');
    }
  }, [captureQuery.data]);

  const { data: userData } = useGetUsersAvailibleFromCaptures(
    {}
  ) as unknown as { data: User[] };

  const queryClient = useQueryClient();

  const isAdmin = useMemo(() => {
    return hasClaims(
      [UserClaims.AgerAdmin],
      (user?.cloudClaims || []) as UserClaims[]
    );
  }, [user]);
  const formValidation = useFormValidation();

  const { mutate: putCapture } = usePutCaptureById({ id: NaN });
  const { data: organizationData } = useGetCustomer({});
  const [loading, setLoading] = useState<boolean>(false);

  const creator = useMemo(() => {
    if (!captureQuery.data || !userData) {
      return;
    }
    const c = userData.find((u) => captureQuery.data.createdById === u.id);
    return c;
  }, [userData, captureQuery.data]);

  const organization = useMemo(() => {
    if (!captureQuery.data || !organizationData) {
      return;
    }
    const o = organizationData.find(
      (o) => captureQuery.data.customerId === o.id
    );
    return o;
  }, [organizationData, captureQuery.data]);

  const saveCaptureName = useCallback(async () => {
    if (await formValidation.hasErrors()) {
      return;
    }

    setLoading(true);

    try {
      await putCapture(
        {
          ...captureQuery.data,
          captureName,
        },
        {
          pathParams: { id: captureQuery.data?.id ?? NaN },
        }
      );
    } catch (e) {
    } finally {
      setLoading(false);
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: captureQuery.data?.id },
        ],
      });
    }
  }, [formValidation]);

  const getUsername = (user: User | undefined): string | null | undefined => {
    if (!user) {
      return;
    }
    const profile = user.userProfiles?.[0];
    if (profile) {
      let result = `${profile.firstName ?? ''} ${profile.lastName ?? ''}`;
      result = result.trim();
      return result.length === 0 ? 'Unknown User' : result;
    } else {
      return user.userName ?? 'Unknown User';
    }
  };

  const CaptureField = ({
    fieldName,
    value,
    expanded,
    truncate,
    loading,
  }: {
    fieldName: string;
    value: string;
    expanded?: boolean;
    truncate?: boolean;
    loading?: boolean;
  }) => {
    return (
      <div className={`w-full ${expanded ? '' : 'sm:w-1/2 md:w-1/3'}`}>
        <div className="pr-2 pt-2 flex flex-col">
          <p className="font-bold">{fieldName}:</p>
          <p className={`text-sm ${truncate ? 'truncate' : ''}`}>
            {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : value}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col text-sm pt-1">
      <div className="flex flex-row gap-2 w-full">
        <div className="flex flex-row w-full justify-between items-center">
          <div className="font-bold" data-test-id="capture-name-div">
            Capture Name:{' '}
          </div>
          <div className="flex flex-grow items-center px-2">
            <Input.Text.Single
              id="capture-name-input"
              value={captureName}
              setValue={setCaptureName}
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Capture Name')],
              }}
              error={
                <Input.Error
                  error={formValidation.errors['capture-name-input']}
                />
              }
            />
          </div>
          <Button.Primary
            id="save-capture-name-button"
            onClick={() => {
              saveCaptureName();
            }}
            label="Save"
            loading={loading}
          />
        </div>
      </div>
      <div className="flex flex-row flex-wrap my-2">
        <p className="w-full sm:w-1/2 text-left">
          <span className="font-bold">Created By Name: </span>
          {getUsername(creator) ?? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
          )}
        </p>
        <p className="w-full sm:w-1/2 text-left sm:text-right">
          <span className="font-bold">Created By Id: </span>
          {captureQuery.data?.createdById}
        </p>
      </div>

      <div className="flex flex-row flex-wrap">
        <CaptureField
          fieldName="UUID"
          value={captureQuery.data?.captureUuid ?? '-'}
        />
        <CaptureField
          fieldName="ID"
          value={captureQuery.data?.id?.toString() ?? '-'}
        />
        <CaptureField
          fieldName="Organization"
          value={
            organization?.customerDisplayName ??
            organization?.customerName ??
            '-'
          }
          loading={organization === undefined}
        />
        <CaptureField
          fieldName="Mode"
          value={getCaptureModeNiceName(captureQuery.data?.captureModeId)}
        />
        <CaptureField
          fieldName="Number of Images"
          value={captureQuery.data?.numberImages?.toString() ?? '-'}
        />
        <CaptureField
          fieldName="File Size (MB)"
          value={captureQuery.data?.fileSize?.toString() ?? '-'}
        />
        <CaptureField
          fieldName="Location"
          value={`${captureQuery.data?.latitude}, ${captureQuery.data?.longitude}`}
        />
        <CaptureField
          fieldName="Altitude"
          value={captureQuery.data?.altitude?.toString() ?? '-'}
        />
        {isAdmin && (
          <CaptureField
            fieldName="Container"
            value={captureQuery.data?.container ?? '-'}
          />
        )}
        <CaptureField
          fieldName="Uploaded"
          value={captureQuery.data?.isUploaded?.toString() ?? '-'}
        />
        <CaptureField
          fieldName="Created"
          value={formatDateAndTime(captureQuery.data?.createDatetime) ?? '-'}
        />
        <CaptureField
          fieldName="Updated"
          value={formatDateAndTime(captureQuery.data?.updateDatetime) ?? '-'}
        />
        <CaptureField
          fieldName="Scanned"
          value={formatDateAndTime(captureQuery.data?.scanDatetime) ?? '-'}
        />
        <CaptureField
          fieldName="Last Job Completed UUID"
          value={captureQuery.data?.completedJobs?.[0]?.uuid ?? '-'}
        />
        <CaptureField
          fieldName="Last Job Completed"
          value={captureQuery.data?.completedJobs?.[0]?.name ?? '-'}
        />
        <CaptureField
          fieldName="Last Job Completed Date"
          value={
            formatDateAndTime(
              captureQuery.data?.completedJobs?.[0]?.completedDateTime
            ) ?? '-'
          }
        />
      </div>
      <div className="flex flex-row flex-wrap my-2">
        <CaptureField
          fieldName="Notes"
          value={captureQuery.data?.description ?? '-'}
          expanded={true}
          truncate={true}
        />
      </div>
    </div>
  );
};
