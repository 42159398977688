import { m, motion } from 'framer-motion';
import { useCapturesViewerContext } from 'libs/feature/src/captures-viewer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Raycaster, Vector3 } from 'three';

import { APIClient, APIRequests } from '@agerpoint/api';
import { CloudButton } from '@agerpoint/cloud/components';
import { CaptureImagesGallery } from '@agerpoint/feature';
import { AnnotationsController } from '@agerpoint/three-d-viewer';
import {
  Annotation2dPoints,
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  ICustomLine,
  ICustomMesh,
  IGs3dViewerController,
  IPotreeViewerController,
  LdFlags,
  MixpanelNames,
} from '@agerpoint/types';
import { useAgerStore, useMixpanel, useViteToasts } from '@agerpoint/utilities';

import './anno-shared-toolbar.scss';
import useClickDragDoubleClick from './useClickDragDblClick';

export const SharedThreeDAnnotationToolbar = ({
  viewerController,
  croppingActive,
  isPotree = false,
  shareApp = false,
}: {
  viewerController: IGs3dViewerController | IPotreeViewerController;
  croppingActive: boolean;
  isPotree?: boolean;
  shareApp?: boolean;
}) => {
  if (!viewerController?.threeViewer?.camera) return null;

  const raycaster = useRef<null | Raycaster>(null);
  raycaster.current = new Raycaster();
  raycaster.current.params.Sprite = { threshold: 0.1 }; // Adjust threshold as needed
  raycaster.current.camera = viewerController?.threeViewer?.camera;

  const {
    selectedCaptureJob,
    setAnnotationCaptureObjects,
    setAnnotationCaptureObjectsLookup,
    gallery,
  } = useCapturesViewerContext();

  const captureJobPutMutation = APIRequests.CaptureJob.Mutations.usePut();

  const { captureId, capturePublicUuid } = useParams();

  const [, setSearch] = useSearchParams();

  const toasts = useViteToasts();

  const {
    isMobile,
    launchDarkly: { hasFeatureFlag },
  } = useAgerStore();

  const hasPolygonPermission = useMemo(
    () => hasFeatureFlag(LdFlags.M2Polygon3DAnnotation),
    [hasFeatureFlag]
  );

  const captureObjectsBySelectedCaptureJobQuery =
    APIRequests.CaptureObject.Queries.useGetAllByCaptureIdAndCaptureJobId({
      captureId: Number(captureId) || undefined,
      captureJobId: selectedCaptureJob?.id,
      enabled: !shareApp,
    });

  const sharedCaptureObjectsBySelectedCaptureJobQuery =
    APIRequests.CaptureObject.Queries.useGetAllByPublicCaptureUuidAndPublicCaptureJobUuid(
      {
        capturePublicUuid: capturePublicUuid,
        captureJobPublicUuid: selectedCaptureJob?.uuid ?? undefined,
        enabled: shareApp,
      }
    );

  const captureObjectsQuery = useMemo(() => {
    if (shareApp) {
      return sharedCaptureObjectsBySelectedCaptureJobQuery;
    } else {
      return captureObjectsBySelectedCaptureJobQuery;
    }
  }, [
    shareApp,
    sharedCaptureObjectsBySelectedCaptureJobQuery,
    captureObjectsBySelectedCaptureJobQuery,
  ]);

  const [point2dEditInProgress, setPoint2dEditInProgress] = useState(false);
  const [point2dCreateInProgress, setPoint2dCreateInProgress] = useState(false);
  const [line3dCreateInProgress, setLine3dCreateInProgress] = useState(false);
  const [line3dEditInProgress, setLine3dEditInProgress] = useState(false);
  const [polygon3dInProgress, setPolygon3dInProgress] = useState(false);
  const [multiPoint2dInProgress, setMultiPoint2dInProgress] = useState(false);
  const [count2dCreateInProgress, setCount2dCreateInProgress] = useState(false);
  const [count2dEditInProgress, setCount2dEditInProgress] = useState(false);
  const [currentCaptureObjects, setCurrentCaptureObjects] = useState<
    APIClient.CaptureObject[] | undefined
  >([]);
  const [selectedAnnotation, setSelectedAnnotation] = useState<
    ICustomLine | ICustomMesh | null | undefined
  >(null);
  const [targetEl, setTargetEl] = useState<HTMLCanvasElement | null>(null);

  const annoCtrl = useRef<AnnotationsController | null>(null);

  const setupCaptureObjects = useCallback(() => {
    const cObjects = captureObjectsQuery.data ?? [];
    const filteredObjects = cObjects.filter((c) => {
      if (!shareApp && c.captureJobId !== selectedCaptureJob?.id) {
        return false;
      }
      // check custom attributes for attributeName _customType
      const customType = c?.captureObjectCustomAttributes?.find(
        (attr) => attr.attributeName === '_customType'
      );
      if (!customType || !customType?.attributeValue) return false;

      const allowedTypes = [
        Annotation2dPoints.AnnotationPoint2d,
        Annotation3dPoints.AnnotationPoint,
        Annotation3dLines.AnnotationLine,
        Annotation2dPoints.AnnotationMultiPoint2d,
      ] as (Annotation3dPoints | Annotation3dLines | Annotation3dPolygons)[];

      if (hasPolygonPermission) {
        allowedTypes.push(Annotation3dPolygons.AnnotationPolygon);
      }

      return allowedTypes.includes(
        customType.attributeValue as
          | Annotation3dLines
          | Annotation3dPoints
          | Annotation3dPolygons
      );
    });
    annoCtrl?.current?.seedCaptureObjects(filteredObjects);
    setAnnotationCaptureObjects?.(filteredObjects);
    const lookup = filteredObjects.reduce((acc, obj) => {
      if (!obj.id) return acc;
      acc[obj.id] = obj;
      return acc;
    }, {} as Record<string, APIClient.CaptureObject>);
    setAnnotationCaptureObjectsLookup?.(lookup);
  }, [
    selectedCaptureJob?.id,
    selectedCaptureJob?.uuid,
    hasPolygonPermission,
    shareApp,
    captureObjectsQuery.data,
  ]);

  const redrawAnnotations = useCallback(() => {
    setupCaptureObjects();
  }, [
    selectedCaptureJob?.id,
    selectedCaptureJob?.uuid,
    hasPolygonPermission,
    shareApp,
    captureObjectsQuery.data,
  ]);
  // Use a ref to store the previous fetching state
  // const wasFetching = useRef(false);

  useEffect(() => {
    return () => {
      setAnnotationCaptureObjects?.([]);
      setAnnotationCaptureObjectsLookup?.({});
    };
  }, []);

  useEffect(() => {
    setupCaptureObjects();
  }, [captureObjectsQuery.data]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady) {
      return;
    }
    if (!viewerController?.threeViewer?.camera) return;
    if (!viewerController?.threeViewer?.scene) return;
    if (!viewerController?.threeViewer?.targetEl) return;
    if (!viewerController?.threeViewer?.controls) return;
    annoCtrl.current = new AnnotationsController(
      viewerController?.threeViewer?.camera,
      viewerController.threeViewer?.scene,
      viewerController.threeViewer?.controls,
      viewerController.threeViewer?.targetEl,
      viewerController.mousePosition,
      {
        isPotree,
        isMobile,
        isReadOnly: shareApp, // read only flag
      },
      { sendEvent }
    );
    const obs = annoCtrl.current.selectedObjectSubject().subscribe((obj) => {
      setSelectedAnnotation(obj);
    });
    setupCaptureObjects();
    return () => {
      obs.unsubscribe();
      annoCtrl.current?.destroy();
      annoCtrl.current = null;
    };
  }, [
    viewerController?.info?.viewerReady,
    isMobile,
    selectedCaptureJob?.id,
    selectedCaptureJob?.uuid,
  ]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady) {
      return;
    }
    let _targetEl;
    if (isPotree) {
      _targetEl = viewerController?.threeViewer?.targetEl?.querySelector(
        '[data-test-id="potree-render-area-canvas"]'
      ) as HTMLCanvasElement;
    } else {
      _targetEl = viewerController?.threeViewer?.targetEl?.querySelector(
        'canvas'
      ) as HTMLCanvasElement;
    }
    setTargetEl(_targetEl);
  }, [viewerController?.info?.viewerReady, isPotree]);

  // little hack, annotation controller is keeping track of what selected object the mouse is intersecting with. But here
  // we need to know what object was selected when the mouse went DOWN.
  // this is needed for how we handle click, drag, and double click events
  const mouseDownObject = useRef<
    ICustomLine | ICustomMesh | null | undefined
  >();
  useClickDragDoubleClick(targetEl, {
    onMouseDown: useCallback(() => {
      const mPos = new Vector3().copy(
        viewerController?.mousePosition.current || new Vector3()
      );
      annoCtrl.current?.clickedPosition(mPos);
      if (selectedAnnotation) {
        mouseDownObject.current = selectedAnnotation;
        if (!annoCtrl.current?.creating3dLine) {
          viewerController.pauseOrbitControls();
        }
      } else {
        mouseDownObject.current = undefined;
      }
    }, [viewerController, selectedAnnotation, mouseDownObject.current]),
    onClick: useCallback(() => {
      if (selectedAnnotation) {
        annoCtrl.current?.object3dMouseDown(selectedAnnotation);
      } else {
        annoCtrl.current?.sceneWasClicked();
      }
      annoCtrl.current?.mouseUp();
      viewerController.resumeOrbitControls();
    }, [annoCtrl, viewerController, selectedAnnotation]),
    onDragStart: useCallback(() => {
      if (selectedAnnotation || mouseDownObject.current) {
        annoCtrl.current?.object3dMouseDragStart(
          selectedAnnotation || mouseDownObject.current
        );
        // pause orbit controls if we are not creating a line
        if (!annoCtrl.current?.creating3dLine) {
          viewerController.pauseOrbitControls();
        }
      }
    }, [
      annoCtrl,
      viewerController,
      selectedAnnotation,
      mouseDownObject.current,
    ]),
    onDragEnd: useCallback(() => {
      if (selectedAnnotation) {
        annoCtrl.current?.mouseUp();
      }
      viewerController.resumeOrbitControls();
    }, [annoCtrl, viewerController, selectedAnnotation]),
    onDoubleClick: useCallback(() => {
      if (selectedAnnotation) {
        viewerController.pauseOrbitControls(true);
        annoCtrl.current?.object3dDoubleClick(selectedAnnotation);
        viewerController.resumeOrbitControls(true);
      } else {
        viewerController.pauseOrbitControls();
        annoCtrl.current?.sceneWasDoubleClicked();
        viewerController.resumeOrbitControls();
      }
    }, [annoCtrl, viewerController, selectedAnnotation]),
  });
  useEffect(() => {
    if (annoCtrl?.current?.creating2dPoint === undefined) return;
    setPoint2dCreateInProgress(annoCtrl?.current?.creating2dPoint);
  }, [annoCtrl?.current?.creating2dPoint]);

  useEffect(() => {
    if (annoCtrl?.current?.creating3dLine === undefined) return;
    setLine3dCreateInProgress(annoCtrl?.current?.creating3dLine);
  }, [annoCtrl?.current?.creating3dLine]);

  useEffect(() => {
    if (annoCtrl?.current?.creating3dMultiPoint === undefined) return;
    setCount2dCreateInProgress(annoCtrl?.current?.creating3dMultiPoint);
  }, [annoCtrl?.current?.creating3dMultiPoint]);

  useEffect(() => {
    if (annoCtrl?.current?.editing2dPoint === undefined) return;
    setPoint2dEditInProgress(annoCtrl?.current?.editing2dPoint);
  }, [annoCtrl?.current?.editing2dPoint]);

  useEffect(() => {
    if (annoCtrl?.current?.editing3dLine === undefined) return;
    setLine3dEditInProgress(annoCtrl?.current?.editing3dLine);
  }, [annoCtrl?.current?.editing3dLine]);

  useEffect(() => {
    if (annoCtrl?.current?.editing2dMultiPoint === undefined) return;
    setCount2dEditInProgress(annoCtrl?.current?.editing2dMultiPoint);
  }, [annoCtrl?.current?.editing2dMultiPoint]);

  useEffect(() => {
    if (annoCtrl?.current?.editing2dPoint) {
      setPoint2dEditInProgress(true);
    } else {
      setPoint2dEditInProgress(false);
    }
  }, [annoCtrl?.current?.editing2dPoint]);

  const cancelDrawing = useCallback(() => {
    if (multiPoint2dInProgress) {
      setMultiPoint2dInProgress(false);
      annoCtrl?.current?.cancelEditing2dMultiPoint();
    }
    if (point2dCreateInProgress) {
      setPoint2dCreateInProgress(false);
      annoCtrl?.current?.cancelCreating2dPoint();
    }
    if (line3dCreateInProgress) {
      setLine3dCreateInProgress(false);
      annoCtrl?.current?.cancelCreating3dLine();
    }
    if (count2dCreateInProgress) {
      setCount2dCreateInProgress(false);
      annoCtrl?.current?.cancelEditing2dMultiPoint();
    }
    if (line3dEditInProgress) {
      setLine3dEditInProgress(false);
      annoCtrl?.current?.cancelEditing3dLine();
    }
    // redraw the annotations
    redrawAnnotations();
  }, [
    line3dCreateInProgress,
    polygon3dInProgress,
    count2dCreateInProgress,
    point2dCreateInProgress,
    line3dEditInProgress,
  ]);

  const canDrawRequestedType = useCallback(() => {
    if (!annoCtrl?.current) return false;
    const pntInProgress = annoCtrl?.current?.creating2dPoint ?? false;
    if (pntInProgress) return false;
    if (
      line3dCreateInProgress ||
      polygon3dInProgress ||
      count2dCreateInProgress
    )
      return false;
    return true;
  }, [
    line3dCreateInProgress,
    polygon3dInProgress,
    count2dCreateInProgress,
    annoCtrl,
  ]);

  const canCancel = useMemo(() => {
    return (
      point2dCreateInProgress ||
      line3dCreateInProgress ||
      polygon3dInProgress ||
      count2dCreateInProgress ||
      line3dEditInProgress
    );
  }, [
    point2dCreateInProgress,
    line3dCreateInProgress,
    polygon3dInProgress,
    count2dCreateInProgress,
    line3dEditInProgress,
  ]);

  const canFinish = useMemo(() => {
    return (
      line3dCreateInProgress ||
      polygon3dInProgress ||
      count2dCreateInProgress ||
      line3dEditInProgress
    );
  }, [
    line3dCreateInProgress,
    polygon3dInProgress,
    count2dCreateInProgress,
    line3dEditInProgress,
  ]);

  const drawMessage = useMemo(() => {
    if (point2dCreateInProgress) {
      return 'Click to place a point marker';
    }

    if (point2dEditInProgress) {
      return 'Drag to reposition';
    }

    if (count2dCreateInProgress) {
      return 'Click an object to start counting';
    }

    if (count2dEditInProgress) {
      return 'Drag to reposition';
    }

    if (line3dCreateInProgress) {
      return 'Click at least 2 points to measure the distances between them';
    }

    if (line3dEditInProgress) {
      return 'Drag or add vertices to edit, double click to remove a vertex';
    }

    if (polygon3dInProgress) {
      return 'Click to start setting the plane';
    }

    return undefined;
  }, [
    point2dCreateInProgress,
    point2dEditInProgress,
    count2dCreateInProgress,
    count2dEditInProgress,
    line3dCreateInProgress,
    line3dEditInProgress,
    polygon3dInProgress,
  ]);

  const { sendEvent } = useMixpanel();

  const finishDrawingLine = useCallback(() => {
    sendEvent(MixpanelNames.AnnotationCreationDoneCloud, {
      type: Annotation3dLines.AnnotationLine,
    });
    setLine3dCreateInProgress(false);
    annoCtrl?.current?.finishCreating3dLine();
  }, [annoCtrl]);

  const restoreCameraSettings = useCallback(() => {
    const cameraSettings =
      viewerController?.info?.captureJobMetadata?.cameraSettings;
    if (!cameraSettings) return;
    viewerController?.setCameraSettings();
  }, [
    viewerController?.info?.captureJobMetadata?.cameraSettings,
    viewerController?.setCameraSettings,
  ]);

  const saveCameraSettings = useCallback(() => {
    if (captureJobPutMutation.isPending) return;

    const cameraSettings = viewerController?.getCameraSettings();
    if (!cameraSettings) return;
    const cameraStateJSON = JSON.stringify(cameraSettings);

    const updatedCaptureJob = {
      ...viewerController?.info?.captureJobMetadata,
      cameraSettings: cameraStateJSON,
    };

    if (
      !viewerController?.info?.captureJobMetadata?.captureId ||
      !viewerController?.info?.captureJobMetadata?.id
    ) {
      return;
    }

    captureJobPutMutation.mutate(
      {
        captureId:
          viewerController?.info?.captureJobMetadata?.captureId?.toString(),
        jobId: viewerController?.info?.captureJobMetadata.id,
        data: updatedCaptureJob,
      },
      {
        onSuccess: () => {
          toasts.add({
            type: 'success',
            title: 'Camera settings saved!',
          });
        },
        onError: () => {
          toasts.add({
            type: 'error',
            title: 'Failed to save camera settings!',
          });
        },
      }
    );
  }, [
    viewerController?.info?.captureJobMetadata,
    viewerController?.getCameraSettings,
  ]);

  return (
    <>
      {!isMobile && !shareApp && !croppingActive && (
        <>
          <motion.div
            className={`${
              isMobile ? 'fixed' : 'absolute'
            } z-30 bottom-14 left-1/2 flex flex-row bg-white shadow-lg rounded-lg p-1 gap-1`}
            initial={{ y: gallery?.visible ? -110 : 0, x: '-50%' }}
            animate={{ y: gallery?.visible ? -110 : 0, x: '-50%' }}
          >
            {!canCancel && !canFinish && (
              <>
                <CloudButton.Icon
                  disabled={
                    !viewerController?.info?.viewerReady ||
                    !viewerController?.info?.sceneLoaded ||
                    !canDrawRequestedType()
                  }
                  id="point-3d-marker"
                  leadingIcon="location-dot"
                  tooltip="Add Point"
                  tooltipPosition="top"
                  onClick={(e) => {
                    sendEvent(MixpanelNames.AnnotationCreationStartedCloud, {
                      type: Annotation2dPoints.AnnotationPoint2d,
                    });
                    e.stopPropagation();
                    const canDraw = canDrawRequestedType();

                    if (!canDraw) {
                      return;
                    }

                    if (viewerController?.info?.viewerReady) {
                      cancelDrawing();
                      setPoint2dCreateInProgress(true);
                      annoCtrl?.current?.startCreating2dPoint();
                      setSearch(
                        (prev) => {
                          prev.set('details', 'annotations');
                          return prev;
                        },
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  toggled={point2dCreateInProgress}
                />
                <CloudButton.Icon
                  disabled={
                    !viewerController?.info?.viewerReady ||
                    !viewerController?.info?.sceneLoaded ||
                    !canDrawRequestedType()
                  }
                  id="multi-point-3d-marker"
                  leadingIcon="ball-pile"
                  tooltip="Add Multi Point"
                  tooltipPosition="top"
                  onClick={(e) => {
                    sendEvent(MixpanelNames.AnnotationCreationStartedCloud, {
                      type: Annotation2dPoints.AnnotationMultiPoint2d,
                    });
                    e.stopPropagation();
                    const canDraw = canDrawRequestedType();

                    if (!canDraw) {
                      // annoCtrl?.current?.finishCreating3dPoint(true);
                      return;
                    }

                    if (viewerController?.info?.viewerReady) {
                      cancelDrawing();
                      setCount2dCreateInProgress(true);
                      annoCtrl?.current?.startCreating2dMultiPoint();
                      setSearch(
                        (prev) => {
                          prev.set('details', 'annotations');
                          return prev;
                        },
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  toggled={count2dCreateInProgress}
                />
                <CloudButton.Icon
                  disabled={
                    !viewerController?.info?.viewerReady ||
                    !viewerController?.info?.sceneLoaded ||
                    !canDrawRequestedType()
                  }
                  id="line-3d-marker"
                  leadingIcon="ruler"
                  tooltip="Add Line"
                  tooltipPosition="top"
                  onClick={(e) => {
                    sendEvent(MixpanelNames.AnnotationCreationStartedCloud, {
                      type: Annotation3dLines.AnnotationLine,
                    });
                    e.stopPropagation();
                    const canDraw = canDrawRequestedType();

                    if (!canDraw) {
                      // annoCtrl?.current?.finishCreating3dPoint(true);
                      return;
                    }

                    if (
                      viewerController?.info?.viewerReady &&
                      annoCtrl?.current
                    ) {
                      cancelDrawing();
                      setLine3dCreateInProgress(true);
                      annoCtrl?.current.startCreating3dLine();
                      setSearch(
                        (prev) => {
                          prev.set('details', 'annotations');
                          return prev;
                        },
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  toggled={line3dCreateInProgress}
                />
              </>
            )}

            {canCancel && (
              <CloudButton.Icon
                id="cancel-drawing"
                onClick={(e) => {
                  if (point2dCreateInProgress) {
                    sendEvent(MixpanelNames.AnnotationCreationCanceledCloud, {
                      type: Annotation2dPoints.AnnotationPoint2d,
                    });
                  }
                  if (count2dCreateInProgress) {
                    sendEvent(MixpanelNames.AnnotationCreationCanceledCloud, {
                      type: Annotation2dPoints.AnnotationMultiPoint2d,
                    });
                  }
                  if (line3dCreateInProgress) {
                    sendEvent(MixpanelNames.AnnotationCreationCanceledCloud, {
                      type: Annotation3dLines.AnnotationLine,
                    });
                  }

                  e.stopPropagation();
                  cancelDrawing();
                }}
                leadingIcon={'close'}
                label="Cancel"
                leadingIconColor="text-red"
              />
            )}
            {canFinish && (
              <CloudButton.Icon
                id="finish-drawing"
                onClick={(e) => {
                  e.stopPropagation();
                  if (line3dCreateInProgress) {
                    finishDrawingLine();
                  }
                  if (count2dCreateInProgress) {
                    sendEvent(MixpanelNames.AnnotationCreationDoneCloud, {
                      type: Annotation2dPoints.AnnotationMultiPoint2d,
                    });
                    setCount2dCreateInProgress(false);
                    annoCtrl?.current?.finishCreating2dMultiPoint();
                  }
                  if (line3dEditInProgress) {
                    // sendEvent(MixpanelNames.AnnotationCreationDoneCloud, {
                    //   type: Annotation3dLines.AnnotationLine,
                    // });
                    setLine3dEditInProgress(false);
                    annoCtrl?.current?.finishEditing3dLine();
                  }
                }}
                label="Done"
                leadingIcon={'check'}
                leadingIconColor="text-green"
              />
            )}
          </motion.div>
          {drawMessage && (
            <motion.div
              className="pointer-events-none absolute left-1/2 -translate-x-1/2 py-3 px-6 text-white bg-black bg-opacity-40 rounded-lg z-30 text-center"
              initial={{
                y: gallery?.visible ? -110 : 0,
                x: '-50%',
                bottom: '120px',
              }}
              animate={{
                y: gallery?.visible ? -110 : 0,
                x: '-50%',
                bottom: '120px',
              }}
            >
              {drawMessage}
            </motion.div>
          )}
        </>
      )}

      {!shareApp && (
        <CaptureImagesGallery captureJob={selectedCaptureJob ?? undefined} />
      )}

      {!shareApp && (
        <div className="absolute z-30 top-4 right-4 flex flex-col bg-white shadow-lg rounded-lg p-1 gap-1">
          <CloudButton.Icon
            id="save-camera-settings"
            leadingIcon="save"
            tooltip="Save camera settings"
            tooltipPosition="left"
            disabled={!viewerController?.info?.viewerReady}
            loading={captureJobPutMutation.isPending}
            onClick={saveCameraSettings}
          />
          <CloudButton.Icon
            id="reset-camera-settings"
            leadingIcon="arrows-to-circle"
            tooltip="Reset camera settings"
            tooltipPosition="left"
            disabled={!selectedCaptureJob?.cameraSettings}
            onClick={restoreCameraSettings}
          />
        </div>
      )}
    </>
  );
};
