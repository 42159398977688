import { useEffect } from 'react';

const BASE_TITLE = 'Agerpoint Cloud';

export const usePageTitle = (
  titleCallback: () => string | null | undefined,
  deps: React.DependencyList
) => {
  useEffect(() => {
    const title = titleCallback();

    if (title) {
      document.title = `${title} - ${BASE_TITLE}`;
    } else {
      document.title = BASE_TITLE;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    return () => {
      document.title = BASE_TITLE;
    };
  }, []);
};
