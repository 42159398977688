import { useBackgroundTaskManager } from 'libs/feature/src/background-task-manager/background-task-manager';
import mixpanel from 'mixpanel-browser';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  APIClient,
  APIUtils,
  AnalyticRequest,
  Capture,
  CustomerAnalytic,
  useGetCustomerAnalyticsByCustomerId,
  usePostRequestAnalytics,
} from '@agerpoint/api';
import { ConfirmModal, Input, QuestionInfo } from '@agerpoint/component';
import {
  AnalyticRequestStatus,
  BackgroundTaskArgs,
  BackgroundTaskResult,
  CaptureJobTypes,
} from '@agerpoint/types';
import { MixpanelNames } from '@agerpoint/types';
import { useGlobalStore, useToasts } from '@agerpoint/utilities';

import { ManageCaptureContext } from '../manage-capture-context';
import { CardOption, CardOptions } from './capture-details-section-utils';

export const CaptureDetailsSectionAnalytics = ({
  selectedCaptures = [],
  isBulk = false,
  bulkAnalyticSelected = () => null,
}: {
  selectedCaptures?: Capture[];
  isBulk?: boolean;
  bulkAnalyticSelected?: (
    selectedCaptures: Capture[],
    selected: CustomerAnalytic
  ) => void;
}) => {
  const navigate = useNavigate();
  const params = useParams<{
    id: string;
    analyticsUriName: string;
    captureId: string;
  }>();
  const { addTaskGroup, createBackgroundTask } = useBackgroundTaskManager();

  const id = useMemo(() => {
    return params.captureId ?? params.id;
  }, [params]);

  const captureQuery = APIClient.useGetCaptureById(Number(id), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(id) }],
      enabled: Number.isSafeInteger(Number(id)),
    },
  });

  const toasts = useToasts();

  const analytics = `Automatically identify features using Machine Learning algorithms on this capture.`;
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedAnalytic, setSelectedAnalytic] = useState<CustomerAnalytic>(
    {} as CustomerAnalytic
  );
  const [dbhValue, setDbhValue] = useState('1.3');
  const [customerId, setCustomerId] = useState(NaN);
  const [unArchivedAnalytics, setUnArchivedAnalytics] = useState<
    CustomerAnalytic[]
  >([]);
  const [multipleOrganizationsFound, setMultipleOrganizationsFound] =
    useState(false);
  const [noOrganizationFound, setNoOrganizationFound] = useState(false);
  const [selectedCaptureId, setSelectedCaptureId] = useState(NaN);
  const [noLowResJob, setNoLowResJob] = useState(false);

  const { mutate: sendRequestEmail } = usePostRequestAnalytics({});

  const {
    data: customerAnalytics,
    refetch: refetchCustomerAnalytics,
    loading: fetchingCustomerAnalytics,
  } = useGetCustomerAnalyticsByCustomerId({
    lazy: true,
    customerId,
  }) as unknown as {
    data: CustomerAnalytic[] | undefined;
    refetch: () => void;
    loading: boolean;
  };

  useEffect(() => {
    const unArchivedAnalytics = customerAnalytics
      ?.filter((analytic) => !analytic.archived)
      .sort((a, b) => {
        if (a?.priority === undefined || b?.priority === undefined) return 0;
        return a?.priority - b?.priority;
      });
    setUnArchivedAnalytics(unArchivedAnalytics || []);
  }, [customerAnalytics]);

  useEffect(() => {
    // this is for bulk analytics
    if (!isBulk) return;
    // we have to have at least one capture selected and they all need the same customerId
    if (!selectedCaptures?.length) return;
    const allHaveSelectedCustomerId = selectedCaptures.every(
      (customer) => customer?.customerId === selectedCaptures[0]?.customerId
    );
    const allHaveLowResJob = selectedCaptures.every((capture) => {
      const jobs = capture?.completedJobs || [];
      return jobs.some(
        (job) =>
          job.captureJobTypeId === CaptureJobTypes['Low Resolution'] &&
          !job.archived
      );
    });
    if (!allHaveSelectedCustomerId) {
      setMultipleOrganizationsFound(true);
      return;
    }
    if (!selectedCaptures[0]?.customerId) {
      setNoOrganizationFound(true);
      return;
    }
    if (!allHaveLowResJob) {
      setNoLowResJob(true);
      return;
    }

    setCustomerId(selectedCaptures[0].customerId);
  }, []);

  useEffect(() => {
    // this is for individual capture analytics see useEffect invoking refetchCapture
    if (isBulk) return;
    if (!captureQuery.data?.customerId) {
      setNoOrganizationFound(true);
      return;
    } else {
      setNoOrganizationFound(false);
    }

    const jobs = captureQuery.data?.completedJobs || [];
    const hasLowResJob = jobs.some(
      (job) => job.captureJobTypeId === CaptureJobTypes['Low Resolution']
    );
    if (!hasLowResJob) {
      setNoLowResJob(true);
      return;
    } else {
      setNoLowResJob(false);
    }

    // get the customerId off the capture
    setCustomerId(captureQuery.data.customerId);
  }, [captureQuery.data]);

  useEffect(() => {
    // individual or bulk analytic paths merge here
    if (customerId) refetchCustomerAnalytics();
  }, [customerId]);

  const analyticSelected = (id: number) => {
    if (!customerAnalytics?.length) return;
    const selected = customerAnalytics.find(
      (analytic) => analytic.analyticId === id
    );
    if (!selected || !selected?.analyticId) {
      toasts.add(toasts.prepare.entityMissing('analytic'));
      navigate('./captures');
      return;
    }
    setSelectedAnalytic(selected || ({} as CustomerAnalytic));

    if (isBulk) {
      bulkAnalyticSelected(selectedCaptures, selected);
      return;
    }

    navigate(`./${selected?.analyticId}`);
  };

  const addJobsToQueue = useCallback(
    (idList: number[]) => {
      const tasks: BackgroundTaskArgs[] = [];
      for (let i = 0; i < idList.length; i++) {
        const id = idList[i];
        const task: BackgroundTaskArgs = createBackgroundTask(
          `Run Analytic ${i}`,
          async (resolve, reject, abortSignal, groupId, taskId) => {
            try {
              mixpanel.track(MixpanelNames.AnalyticsRequest, {
                model: selectedAnalytic.analyticId,
              });
            } catch {}

            await sendRequestEmail(
              {
                captureId: id,
                mlModelId: selectedAnalytic?.analyticId || NaN,
                dbhValue,
              },
              {
                signal: abortSignal,
              }
            );
            resolve({ type: BackgroundTaskResult.success });
          }
        );
        tasks.push(task);
      }
      addTaskGroup({
        groupDesc: `Run Analytics - ${idList.length} Captures`,
        groupTasks: tasks,
        isCancellable: true,
      });
    },
    [selectedAnalytic]
  );

  const confirmFn = useCallback(() => {
    let idList = [];
    if (id) {
      idList = [parseInt(id)];
    } else if (selectedCaptures.length > 0) {
      idList = selectedCaptures.map((capture) => capture?.id as number);
    } else return;

    if (idList.length > 1) {
      mixpanel.track(MixpanelNames.AnalyticsRequestBulk, {
        numberOfCaptures: idList.length,
      });
    }
    addJobsToQueue(idList);
    setShowConfirm(false);
  }, [dbhValue, selectedAnalytic]);

  const cancelFn = () => {
    setShowConfirm(false);
  };

  return (
    <>
      <div className="flex flex-col justify-start" style={{ minHeight: 200 }}>
        <div className="pb-2 w-48 flex justify-between text-sm font-bold">
          <span data-test-id="analytics-options-span">Analytic Options</span>
          <QuestionInfo text={analytics} />
        </div>
        {fetchingCustomerAnalytics && (
          <div className="flex flex-row px-1 w-full justify-center items-center">
            <div className="relative rounded h-full cursor-pointer text-green  px-2 py1">
              Finding Analytics Available For Your Organization
            </div>
          </div>
        )}
        {noLowResJob && (
          <div className="flex flex-row px-1 w-full justify-center items-center">
            <div className="relative rounded h-full cursor-pointer text-green  px-2 py1">
              This capture(s) does not have the required assets for analytics.
            </div>
          </div>
        )}
        {!fetchingCustomerAnalytics && !noLowResJob && (
          <>
            {noOrganizationFound && !multipleOrganizationsFound && (
              <div className="flex flex-row px-1 w-full justify-center items-center">
                <div className="relative rounded h-full cursor-pointer text-green  px-2 py1">
                  No organization found for the selected capture(s). Analytics
                  can only be run for captures that are part of an organization.
                </div>
              </div>
            )}
            {isBulk && multipleOrganizationsFound && (
              <div className="flex flex-row px-1 w-full justify-center items-center">
                <div className="relative rounded h-full cursor-pointer text-green  px-2 py1">
                  We found multiple organizations for the selected capture(s).
                  Analytics can only be run for one organization at a time.
                </div>
              </div>
            )}
            {!unArchivedAnalytics?.length &&
              !noOrganizationFound &&
              !multipleOrganizationsFound && (
                <div className="flex flex-row px-1 w-full justify-center items-center">
                  <div className="relative rounded h-full cursor-pointer text-green  px-2 py1">
                    No analytics available for your organization.
                  </div>
                </div>
              )}
          </>
        )}

        <NewAnalyticsSection
          selectedCallback={useCallback(
            (id: number) => {
              analyticSelected(id);
            },
            [analyticSelected]
          )}
          availableAnalytics={unArchivedAnalytics}
        />
      </div>

      <ConfirmModal
        isOpen={showConfirm}
        canConfirm={true}
        title={`Analyze`}
        message={
          <DbhConfirmMessage
            valueChange={setDbhValue}
            captureCount={selectedCaptures.length || 1}
            credits={
              selectedAnalytic.analyticCreditCost ||
              selectedAnalytic?.analytic?.dc
            }
          />
        }
        confirm={{
          label: 'Confirm',
          callback: confirmFn,
        }}
        close={{
          label: 'Cancel',
          callback: cancelFn,
        }}
        theme="solidDarkBlue"
      />
    </>
  );
};

const DbhConfirmMessage = ({
  captureCount,
  valueChange,
  credits,
}: {
  captureCount: number;
  valueChange: (value: string) => void;
  credits?: number;
}) => {
  const [value, setValue] = useState('1.3');
  const [creditCost, setCreditCost] = useState(0);
  useEffect(() => {
    const cost = credits ? captureCount * credits : 0;
    setCreditCost(cost);
  }, [captureCount, credits]);
  return (
    <>
      <span className="font-bold">{`Number of Captures: ${captureCount}`}</span>
      <br />
      <span className="font-bold">{`${creditCost} Credits`}</span>
      <br />
      <Input.Text.Single
        id="dbh-input"
        className="w-48"
        label={
          <Input.Label label="Enter height of above ground for calculation in meters" />
        }
        value={value}
        setValue={(v) => {
          setValue(v);
          valueChange(v);
        }}
      />
    </>
  );
};

const getAnalyticRequestStatus = (
  id: string | undefined,
  lookup: { [key: string]: AnalyticRequest[] } = {}
) => {
  if (!id) {
    return AnalyticRequestStatus.UNKNOWN;
  }
  const analyticRequest = lookup[id]?.[0];

  if (!analyticRequest || !analyticRequest.status) {
    return AnalyticRequestStatus.UNKNOWN;
  }
  return analyticRequest.status as AnalyticRequestStatus;
};

const NewAnalyticsSection = ({
  selectedCallback,
  availableAnalytics = [],
}: {
  selectedCallback: (id: number) => void;
  availableAnalytics?: CustomerAnalytic[];
}) => {
  const { analyticRequests } = useContext(ManageCaptureContext);
  const [opts, setOpts] = useState<CardOption[]>([]);
  const [availableOptions, setAvailableOptions] = useState<CustomerAnalytic[]>(
    []
  );
  const [analyticRequestLookup, setAnalyticRequestLookup] = useState<{
    [key: number]: AnalyticRequest[];
  }>({});

  useEffect(() => {
    const lu = analyticRequests?.reduce((acc, ar) => {
      if (!ar.customerAnalyticId) return acc;
      // if (!ar.jobId) return acc;

      if (acc[ar.customerAnalyticId]) {
        acc[ar.customerAnalyticId].push(ar);
      } else {
        acc[ar.customerAnalyticId] = [ar];
      }
      return acc;
    }, {} as { [key: number]: AnalyticRequest[] });
    setAnalyticRequestLookup(lu || {});
  }, [analyticRequests]);

  useEffect(() => {
    const list =
      availableOptions.map((option) => {
        return {
          title: option?.analytic?.analyticName || '',
          icon: option?.analytic?.icon,
          desc: option?.analytic?.analyticDescription || '',
          subTitle: `${
            option?.available ? option?.analyticCreditCost : '-'
          } Credits`,
          disabled: option?.available === false,
          disabledMessage: 'Coming Soon!',
          selectCallback: selectedCallback,
          id: option?.analyticId?.toString() || '0',
          status: getAnalyticRequestStatus(
            option?.id?.toString(),
            analyticRequestLookup
          ),
          priority: option?.priority,
        };
      }) || [];
    setOpts(list);
  }, [availableOptions, analyticRequestLookup]);

  useEffect(() => {
    if (availableAnalytics) {
      setAvailableOptions(availableAnalytics);
    }
  }, [availableAnalytics]);

  return (
    <>
      <CardOptions cardOptions={opts} />
    </>
  );
};
