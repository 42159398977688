import { BrowserRouter } from 'react-router-dom';

import { BackgroundTaskManager } from '@agerpoint/feature';
import { AgercloudMainPage } from '@agerpoint/page';
import {
  AuthSetup,
  DatadogSetup,
  LaunchDarklySetup,
  MixpanelSetup,
  ReactQuerySetup,
  RestfulReactSetup,
  RouteTrackingSetup,
  SentrySetup,
  UserSetup,
} from '@agerpoint/shared';

export const App = () => {
  const appVersion = process.env.VERSION;

  return (
    <SentrySetup appVersion={appVersion}>
      <MixpanelSetup appVersion={appVersion}>
        <DatadogSetup appVersion={appVersion}>
          <BrowserRouter>
            <AuthSetup>
              <RestfulReactSetup>
                <ReactQuerySetup>
                  <RouteTrackingSetup>
                    <BackgroundTaskManager maxConcurrency={5}>
                      <UserSetup>
                        <LaunchDarklySetup>
                          <AgercloudMainPage />
                        </LaunchDarklySetup>
                      </UserSetup>
                    </BackgroundTaskManager>
                  </RouteTrackingSetup>
                </ReactQuerySetup>
              </RestfulReactSetup>
            </AuthSetup>
          </BrowserRouter>
        </DatadogSetup>
      </MixpanelSetup>
    </SentrySetup>
  );
};
