import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createEmpty, extend } from 'ol/extent';
import Point from 'ol/geom/Point';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import compare from 'trivial-compare';

import {
  Upload,
  UploadFile,
  useGetUploadFilesByUploadId,
  usePostPipelineJob,
} from '@agerpoint/api';
import { DialogModal, PrimaryButton } from '@agerpoint/component';
import {
  OpenLayerMapController,
  OpenMapLayer,
  UploadFileWithExif,
  selectedUploadFileDotStyle,
  unSelectedUploadFileDotStyle,
} from '@agerpoint/types';
import {
  environment,
  getCurrentEnvironment,
  useToasts,
} from '@agerpoint/utilities';

import { Datatable } from '../datatable/datatable';
import { DatatableItemData } from '../datatable/datatable-types';
import { OpenLayerMap } from '../open-layer-map/open-layer-map';

interface RunImagePipelineModalProps {
  open: boolean;
  upload?: Upload;
  handleCloseDialog: () => void;
}

export const RunImagePipelineModal = ({
  open,
  upload,
  handleCloseDialog,
}: RunImagePipelineModalProps) => {
  const { mutate: postPipelineJob, loading } = usePostPipelineJob({});

  const [mapController, setMapController] = useState<OpenLayerMapController>();
  const [highlightedFile, setHighlightedFile] =
    useState<Partial<UploadFileWithExif>>();

  const datatableRef = useRef<FixedSizeList<
    DatatableItemData<Partial<UploadFileWithExif>>
  > | null>(null);

  const {
    data: uploadFiles,
    refetch,
    loading: loadingUploadFiles,
  } = useGetUploadFilesByUploadId({
    uploadId: upload?.id ?? NaN,
    lazy: true,
  }) as unknown as {
    data: UploadFile[];
    refetch: () => void;
    loading: boolean;
  };

  const [files, setFiles] = useState<Partial<UploadFileWithExif>[]>([]);

  const toasts = useToasts();

  useEffect(() => {
    if (open) {
      setFiles([]);
      refetch();
    }
  }, [upload, open]);

  useEffect(() => {
    if (!open) {
      mapController?.refreshView?.();
    }
  }, [open]);

  useEffect(() => {
    if (!uploadFiles || !open) {
      return;
    }

    const fs: Partial<UploadFileWithExif>[] = [];

    uploadFiles.forEach((u) => {
      if (!u.latitude || !u.longitude) {
        return;
      }
      fs.push({
        name: u.name ?? undefined,
        exif: {
          gps: {
            latitude: u.latitude,
            longitude: u.longitude,
          },
        },
      });
    });

    const extent = createEmpty();
    for (const file of fs) {
      if (!file.exif) {
        continue;
      }
      const point = new Point([
        file.exif.gps.longitude,
        file.exif.gps.latitude,
      ]).transform('EPSG:4326', 'EPSG:3857');
      extend(extent, point.getExtent());
    }
    mapController?.zoomMapToExtent?.(extent);

    setFiles(fs);
  }, [uploadFiles, mapController]);

  const sortedFiles = useMemo(() => {
    const copy = [...files];
    copy.sort((a, b) => compare(a.name, b.name));
    return copy;
  }, [files]);

  const runPipeline = useCallback(
    async (upload: Upload) => {
      try {
        if (!upload.id) {
          throw new Error('Upload has no id');
        }
        const id = upload.id;
        const environment = getCurrentEnvironment();
        await postPipelineJob({
          name: upload.name ?? 'Unknown',
          uploadId: id,
          pipelineId: 2,
          // hack alert, gotta fix this later
          mosaicEngineId: 3,
        });
        toasts.add({
          title: 'Pipeline job triggered!',
          type: 'success',
        });
      } catch (e) {
        console.error(e);
        toasts.add(
          toasts.prepare.error('Failed to trigger pipeline for this upload!')
        );
      }
    },
    [postPipelineJob, toasts]
  );

  return (
    <DialogModal
      key={`${open}`}
      open={open}
      handleCloseDialog={() => {
        if (!loading) {
          handleCloseDialog();
        }
      }}
      size="large"
      title="Run Pipeline"
    >
      <div className="flex flex-col w-full p-1">
        <div className="w-full flex flex-row py-2 gap-1 h-96">
          <div className="w-1/3 h-full">
            <Datatable
              rowHeight={30}
              datatableRef={datatableRef}
              data={sortedFiles ?? []}
              cellOnClick={(colname) => {
                if (colname === 'fileName') {
                  return (row) => {
                    const lat = row.exif?.gps?.latitude;
                    const lon = row.exif?.gps?.longitude;

                    if (lat && lon) {
                      mapController?.zoomMapToLonLat?.([lon, lat], 20);
                    }
                  };
                }
                return;
              }}
              loading={loadingUploadFiles}
              style={{
                tableWrapperStyle: 'bg-white rounded-md border border-gray-500',
                headerWrapperStyle:
                  'px-2 text-xs text-gray-700 font-normal border-b border-gray-500',
                rowWrapperStyle: 'px-2 items-center text-sm hover:bg-gray-100',
                rowStyle: 'border-b border-gray-200',
                headerStyle: 'px-1 py-2 h-full flex items-center',
                cellStyle: 'px-1 flex items-center',
              }}
              columns={[
                {
                  label: 'File Name',
                  value: (row) => (
                    <div
                      className={`absolute inset-0 truncate pl-3 flex items-center ${
                        highlightedFile?.name === row.name ? 'bg-gray-100' : ''
                      }`}
                      onPointerEnter={() => {
                        setHighlightedFile(row);
                      }}
                      onPointerLeave={() => {
                        setHighlightedFile(undefined);
                      }}
                    >
                      {row.name ?? 'Unknown'}
                    </div>
                  ),
                  name: 'fileName',
                },
              ]}
            />
          </div>
          <div className="relative flex flex-col w-2/3 h-full">
            {loadingUploadFiles && (
              <div
                className={`absolute inset-0
            bg-gray-400 bg-opacity-75 flex justify-center items-center`}
                style={{ zIndex: 999 }}
              >
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="text-white w-16 h-16"
                />
              </div>
            )}
            <div className="overflow-hidden rounded w-full h-full">
              <OpenLayerMap
                id="run-image-pipeline-map"
                bingKey={environment.bing_api_key}
                controller={setMapController}
                mapLayers={{
                  used: [
                    OpenMapLayer.Hybrid,
                    OpenMapLayer.Aerial,
                    OpenMapLayer.RoadMap,
                  ],
                  initial: OpenMapLayer.Hybrid,
                }}
                callbacks={{
                  onFeatureClick: (id) => {
                    const file = sortedFiles.find((f) => f.name === id);
                    setHighlightedFile(file);

                    if (datatableRef.current) {
                      const index = sortedFiles.findIndex(
                        (f) => f.name === file?.name
                      );
                      if (index > -1) {
                        datatableRef.current.scrollToItem(index, 'center');
                      }
                    }
                  },
                }}
                featureLayer={{
                  data: sortedFiles,
                  featureGenerator: (file) => {
                    if (
                      !file?.exif?.gps?.latitude ||
                      !file?.exif?.gps?.longitude ||
                      !file.name
                    ) {
                      return null;
                    }

                    return {
                      id: file.name,
                      latitude: file.exif.gps.latitude,
                      longitude: file.exif.gps.longitude,
                      name: file.name,
                      style:
                        highlightedFile?.name === file?.name
                          ? 'selected'
                          : 'default',
                    };
                  },
                  styles: {
                    default: unSelectedUploadFileDotStyle,
                    selected: selectedUploadFileDotStyle,
                  },
                }}
                dependencies={[highlightedFile, sortedFiles]}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="w-full flex flex-row gap-2 justify-end items-center">
            {loading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            ) : (
              <PrimaryButton
                label={'Run'}
                onClicked={async () => {
                  if (upload) {
                    await runPipeline(upload);
                  }
                  handleCloseDialog();
                }}
              />
            )}
            <PrimaryButton
              label={'Cancel'}
              onClicked={handleCloseDialog}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </DialogModal>
  );
};
