import compare from 'trivial-compare';

import { APIModels } from '../..';

export const analyticRequests = (data?: APIModels.AnalyticRequest[]) => {
  if (!data) {
    return data;
  }

  const requests = [...(data ?? [])];

  const active = [...requests]?.filter((a) => a.archived === false);

  const sorted = [...active].sort((a, b) => {
    // HD Model first
    if (a.customerAnalytic?.analytic?.analyticName === 'HD Model') {
      return -1;
    }

    if (b.customerAnalytic?.analytic?.analyticName === 'HD Model') {
      return 1;
    }

    // Low Res Model second
    if (a.customerAnalytic?.analytic?.analyticName === 'Low Res Model') {
      return -1;
    }

    if (b.customerAnalytic?.analytic?.analyticName === 'Low Res Model') {
      return 1;
    }

    // Sort by createDatetime otherwise
    return compare(
      new Date(b.createDatetime ?? ''),
      new Date(a.createDatetime ?? '')
    );
  });

  return sorted;
};
