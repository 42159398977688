import { IClassification, MaterialType, PotreeViewer } from '@agerpoint/types';

export class Classification implements IClassification {
  viewer: PotreeViewer;
  constructor(viewer: PotreeViewer) {
    this.viewer = viewer;
  }

  toggleClassificationVisibilityById(id: number | string) {
    if (!this.viewer.classifications[id]) return;
    this.viewer.classifications[id].visible =
      !this.viewer.classifications[id]?.visible;
  }

  setClassificationVisibilityById(id: number | string, visible: boolean) {
    if (!this.viewer.classifications[id]) return;
    this.viewer.classifications[id].visible = visible;
  }

  toggleAllClassificationsVisible() {
    const ids = Object.keys(this.viewer.classifications);
    for (const id of ids) {
      this.viewer.classifications[id].visible = true;
    }
  }

  switchPointCloudMaterial(selectedValue: MaterialType) {
    for (const pointcloud of this.viewer.scene.pointclouds) {
      pointcloud.material.activeAttributeName = selectedValue;
    }
  }

  updateClassificationColorById(id: number | string, color: number[]) {
    this.viewer.classifications[id].color = color;
  }

  resetVisibility() {
    for (const key in this.viewer.classifications) {
      this.viewer.classifications[key].visible = true;
    }
  }
}
