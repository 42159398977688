import { Vector3 } from 'three';

import {
  GeomType,
  ILine3d,
  IMultiPoint2d,
  IMultiPoint3d,
  IPoint2d,
  IPoint3d,
} from '@agerpoint/types';

import { MultiPoint2d } from '../annotations-2d/multi-point-2d';
import { Point2d } from '../annotations-2d/point-2d';
import { Line3d, MultiPoint3d } from '../annotations-3d';
import { Point3d } from '../annotations-3d/point-3d';

export const getCoordinates = (
  type: GeomType,
  geom: IPoint3d | IPoint2d | ILine3d | IMultiPoint3d | IMultiPoint2d
) => {
  if (!geom) return;
  let coordinates: number[] | number[][] = [];
  if (type === GeomType.Point && geom instanceof Point3d) {
    coordinates = [geom.object.position]
      .map((coord: Vector3) => [coord.x, coord.y, coord.z])
      .flat();
  } else if (type === GeomType.Point && geom instanceof Point2d) {
    coordinates = [geom.point]
      .map((coord: Vector3) => [coord.x, coord.y, coord.z])
      .flat();
  } else if (type === GeomType.LineString && geom instanceof Line3d) {
    coordinates = geom
      .getPosition()
      .map((coord: Vector3) => [coord.x, coord.y, coord.z]);
  } else if (type === GeomType.MultiPoint && geom instanceof MultiPoint2d) {
    coordinates = geom.points.map((pnt) => {
      return [pnt.point.x, pnt.point.y, pnt.point.z];
    });
  } else if (type === GeomType.MultiPoint && geom instanceof MultiPoint3d) {
    coordinates = geom.points.map((point) => {
      return [
        point.object.position.x,
        point.object.position.y,
        point.object.position.z,
      ];
    });
  }
  return coordinates;
};

export const findCanvas = (
  container: HTMLElement,
  isPotree: boolean
): HTMLCanvasElement | null => {
  let canvas: HTMLCanvasElement | null;
  if (isPotree) {
    canvas = container.querySelector(
      'canvas[data-test-id="potree-render-area-canvas"]'
    );
  } else {
    canvas = container.querySelector('.rootElement > canvas');
  }
  return canvas;
};
