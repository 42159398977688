import { faCircleX, faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';

import { Button } from '@agerpoint/component';

import { InputIdContext } from '../input-id-context';
import { InputPlaceholderIcon } from '../input-placeholder-icons';
import { InputStyle } from '../input-style';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface InputTextSingleProps {
  /**
   * The unique identifier for the input element.
   */
  id: string;

  /**
   * The error message to display below the input element.
   */
  error?: React.ReactNode;

  /**
   * The label to display above the input element.
   */
  label?: React.ReactNode;

  /**
   * The style of the input element.
   * Default value: InputStyle.default
   */
  style?: InputStyle;

  /**
   * The placeholder text to display when the input is empty.
   */
  placeholder?: string;

  /**
   * The additional CSS class name for the input element.
   */
  className?: string;

  /**
   * The placeholder icon to display before the placeholder text.
   */
  placeholderIcon?: InputPlaceholderIcon;

  /**
   * The current value of the input element.
   */
  value: string;

  /**
   * The callback function to update the value of the input element.
   */
  setValue: (value: string) => void;

  /**
   * Determines whether to use the raw value without trimming leading whitespace.
   * Default value: false
   */
  useRawValue?: boolean;

  /**
   * The event handler for the blur event of the input element.
   */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;

  /**
   * The event handler for the focus event of the input element.
   */
  onFocus?: React.FocusEventHandler<HTMLInputElement>;

  /**
   * The event handler for the click event of the input element.
   */
  onClick?: React.MouseEventHandler<HTMLInputElement>;

  /**
   * The event handler for the keydown event of the input element.
   */
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;

  /**
   * The ref object or callback function to get a reference to the input element.
   */
  inputRef?: React.LegacyRef<HTMLInputElement>;

  /**
   * Determines whether the input element is disabled.
   * Default value: false
   */
  disabled?: boolean;

  /**
   * Determines whether the input element is read-only.
   * Default value: false
   */
  readOnly?: boolean;

  /**
   * The validation configuration for the input element.
   */
  validation?: InputValidation<string>;

  /**
   * Determines whether to display a clear button to clear the input value.
   */
  clearButton?: boolean;
}

const styles: { [key in InputStyle]: string } = {
  [InputStyle.default]: `text-sm text-gray-900 focus:ring-green
    focus:border-green shadow-sm border-gray-500 rounded placeholder-gray`,
  [InputStyle.mini]: `text-xs bg-transparent py-0.5 px-1 focus:ring-green focus:border-green
    shadow-sm border-gray-500 rounded placeholder-gray text-gray-900`,
  [InputStyle.miniDark]: `text-xs bg-transparent py-0.5 px-1 focus:ring-gray focus:border-gray
    shadow-sm border-gray-500 rounded placeholder-gray text-white`,
  [InputStyle.cloud]: `text-sm text-gray-textPrimary focus:ring-primary
  focus:border-primary border-gray-border rounded-lg placeholder-gray`,
};

const InputTextSingle = ({
  id,
  value,
  setValue,
  error,
  label,
  style = InputStyle.default,
  placeholder: placeholderProp,
  placeholderIcon,
  className,
  useRawValue = false,
  onBlur,
  onFocus,
  onClick,
  onKeyDown,
  disabled = false,
  inputRef,
  readOnly,
  validation,
  clearButton = false,
}: InputTextSingleProps) => {
  const inputClassName = `${styles[style]} ${disabled ? 'opacity-50' : ''} ${
    clearButton ? 'pr-8' : ''
  }  ${className || ''}`.trim();

  const placeholder = `${placeholderIcon ? String(placeholderIcon) : ''} ${
    placeholderProp ?? ''
  }`.trim();

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full relative">
        {label ?? null}
        <input
          readOnly={readOnly}
          ref={inputRef}
          type="text"
          id={id}
          data-test-id={id}
          disabled={disabled}
          className={`${inputClassName}`.trim()}
          placeholder={placeholder}
          style={{
            fontFamily: 'nunito, "Font Awesome 6 Pro"',
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={onClick}
          onKeyDown={onKeyDown}
          value={value}
          onChange={(e) => {
            let v = e.target.value;
            if (useRawValue) {
              setValue(v);
              return;
            }

            v = v.trimStart();
            setValue(v);
          }}
        />
        {error ?? null}
        {clearButton && (
          <div className="absolute right-2 top-1/2 -translate-y-1/2">
            <Button.Icon
              id="clear-input"
              icon={faCircleX}
              disabled={value.length === 0}
              onClick={() => setValue('')}
            />
          </div>
        )}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputTextSingle };
