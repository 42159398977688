import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';

import {
  CaptureObject,
  useChangeCaptureObjectsDisplayNameFromCaptureExtractionJob,
} from '@agerpoint/api';
import { DialogModal, Input, PrimaryButton } from '@agerpoint/component';
import { useFormValidation } from '@agerpoint/utilities';

interface QAQCAttributeRenameModalProps {
  open: boolean;
  handleCloseDialog: () => void;
  attributeNames?: string[];
  extId?: string;
  changeDisplayName: (originalName: string, newName: string) => void;
  captureObjects: CaptureObject[];
}

export const QAQCAttributeRenameModal = ({
  open,
  handleCloseDialog,
  attributeNames,
  extId,
  changeDisplayName,
  captureObjects,
}: QAQCAttributeRenameModalProps) => {
  const { mutate: postNewName } =
    useChangeCaptureObjectsDisplayNameFromCaptureExtractionJob({
      captureExtractionJobId: extId ? +extId : NaN,
      displayName: '',
    });

  const [attributeToRename, setAttributeToRename] = useState<string>();
  const [attributeNewName, setAttributeNewName] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const formValidation = useFormValidation();

  useEffect(() => {
    if (open) {
      formValidation.advancedCallbacks.reset();
    }
  }, [open]);

  const close = useCallback(() => {
    if (loading) {
      return;
    }
    handleCloseDialog();
    setAttributeNewName('');
    setAttributeToRename(undefined);
  }, [handleCloseDialog, loading]);

  const rename = useCallback(async () => {
    if (await formValidation.hasErrors()) {
      return;
    }

    if (!extId || !attributeNewName.trim() || !attributeToRename) {
      return;
    }
    setLoading(true);

    try {
      const attributeIds: number[] = [];

      captureObjects.forEach((o) => {
        o.captureObjectCustomAttributes?.forEach((a) => {
          if (a.attributeName === attributeToRename && a.id) {
            attributeIds.push(a.id);
          }
        });
      });

      await postNewName(
        {
          captureObjectCustomAttributeIds: attributeIds,
        },
        {
          pathParams: {
            captureExtractionJobId: +extId,
            displayName: attributeNewName.trim(),
          },
        }
      );

      changeDisplayName(attributeToRename, attributeNewName.trim());
    } catch (e) {
      console.error(e);
    } finally {
      handleCloseDialog();
      setLoading(false);
      setAttributeNewName('');
      setAttributeToRename(undefined);
    }
  }, [
    extId,
    attributeNewName,
    attributeToRename,
    postNewName,
    changeDisplayName,
    handleCloseDialog,
    captureObjects,
    formValidation,
  ]);

  return (
    <DialogModal
      open={open}
      handleCloseDialog={() => {
        close();
      }}
      size={'small'}
      title={'Rename Attribute'}
      visibleOverflow={true}
    >
      <div className="flex flex-col w-full p-1">
        <Input.Select.Single
          id="attribute-select"
          label={<Input.Label label="Select Attribute" required />}
          options={attributeNames ?? []}
          optionBuilder={(a) => a}
          loading={(attributeNames?.length ?? 0) === 0}
          value={attributeToRename}
          disabled={loading}
          setValue={setAttributeToRename}
          title="Attribute"
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Attribute')],
          }}
          error={
            <Input.Error error={formValidation.errors['attribute-select']} />
          }
        />
        <Input.Text.Single
          id="attribute-rename-input"
          value={attributeNewName}
          setValue={setAttributeNewName}
          label={<Input.Label label="Rename to" className="pt-2" required />}
          disabled={loading}
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('New Name')],
          }}
          error={
            <Input.Error
              error={formValidation.errors['attribute-rename-input']}
            />
          }
        />
        <div className="w-full flex flex-row justify-end pt-2 gap-2 items-center">
          {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
          <PrimaryButton
            disabled={loading}
            label={'Apply'}
            onClicked={rename}
          />
          <PrimaryButton
            disabled={loading}
            label={'Cancel'}
            theme="secondary"
            onClicked={() => {
              close();
            }}
          />
        </div>
      </div>
    </DialogModal>
  );
};
