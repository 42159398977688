import { GlobalStore, MaterialType, PointSizeType } from '@agerpoint/types';

// (partial: GlobalStore | Partial<GlobalStore> | ((state: GlobalStore) => GlobalStore | Partial<GlobalStore>)

enum ActionType {
  SetEptId = 'setEptId',
  SetCaptureId = 'setCaptureId',
  SetPointBudget = 'setPointBudget',
  SetPointStyle = 'setPointStyle',
  SetPointSizeType = 'setPointSizeType',
  SetPointSize = 'setPointSize',
  SetSplatQuality = 'setSplatQuality',
}

interface Action {
  type: ActionType;
}

type SetFunction = (
  updater: (state: GlobalStore) => GlobalStore | Partial<GlobalStore>,
  replace?: boolean,
  action?: Action
) => void;

export const getStandAloneSlice = (
  set: SetFunction,
  get: () => GlobalStore
) => {
  return {
    actions: {
      setEptId: (eptId?: string) => {
        set(
          (state: GlobalStore) => {
            return {
              ...state,
              standAloneViewer: {
                ...state.standAloneViewer,
                eptId,
              },
            };
          },
          false,
          { type: ActionType.SetEptId }
        );
      },
      setCaptureId: (captureId?: string) => {
        set(
          (state: GlobalStore) => {
            return {
              ...state,
              standAloneViewer: {
                ...state.standAloneViewer,
                captureId,
              },
            };
          },
          false,
          { type: ActionType.SetCaptureId }
        );
      },
      setPointBudget: (pointBudget?: number) => {
        set(
          (state: GlobalStore) => {
            return {
              ...state,
              standAloneViewer: {
                ...state.standAloneViewer,
                pointBudget,
              },
            };
          },
          false,
          { type: ActionType.SetPointBudget }
        );
      },
      setPointStyle: (pointStyle: MaterialType) => {
        set(
          (state: GlobalStore) => {
            return {
              ...state,
              standAloneViewer: {
                ...state.standAloneViewer,
                pointStyle,
              },
            };
          },
          false,
          { type: ActionType.SetPointStyle }
        );
      },
      setPointSizeType: (pointSizeType: PointSizeType) => {
        set(
          (state: GlobalStore) => {
            return {
              ...state,
              standAloneViewer: {
                ...state.standAloneViewer,
                pointSizeType,
              },
            };
          },
          false,
          { type: ActionType.SetPointSizeType }
        );
      },
      setPointSize: (pointSize: number) => {
        set(
          (state: GlobalStore) => {
            return {
              ...state,
              standAloneViewer: {
                ...state.standAloneViewer,
                pointSize,
              },
            };
          },
          false,
          { type: ActionType.SetPointSize }
        );
      },
      setSplatQuality: (splatQuality: string) => {
        set(
          (state: GlobalStore) => {
            return {
              ...state,
              standAloneViewer: {
                ...state.standAloneViewer,
                splatQuality,
              },
            };
          },
          false,
          { type: ActionType.SetSplatQuality }
        );
      },
    },
  };
};
