import { Capture } from '@agerpoint/api';
import { getCapturesUnique3DModelList } from '@agerpoint/utilities';

export const getJobInfo = (capture: Capture, appUrl: string) => {
  const captureId = capture.id;

  const jobType = getCapturesUnique3DModelList(capture)?.join(', ') || '-';
  const origin = window.location.origin;

  return { url: `${origin}/captures/${captureId}`, jobType };
};
