import { createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

type TURLStateContext = ReturnType<typeof useSearchParams>;

export const URLStateContext = createContext<TURLStateContext>([
  new URLSearchParams(),
  () => {
    throw new Error('URLStateContext not implemented');
  },
]);

export const useURLStateContext = () => useContext(URLStateContext);
