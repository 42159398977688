import { APIClient } from '@agerpoint/api';

import { getSymbol } from '../units';
import {
  formatAreaImperial,
  formatAreaMetric,
  formatLengthImperial,
  formatLengthMetric,
  formatVolumeImperial,
  formatVolumeMetric,
} from './measurements-formatter';

export const formatAttribute = (
  attr: APIClient.CaptureCustomAttribute | null | undefined
) => {
  if (!attr) {
    return '';
  }

  if (attr.customAttributeUnit?.symbol === 'm') {
    if (attr.customAttributeUnit?.exponent === null) {
      return formatLengthMetric(Number(attr.attributeValue));
    }

    if (attr.customAttributeUnit?.exponent === 2) {
      return formatAreaMetric(Number(attr.attributeValue));
    }

    if (attr.customAttributeUnit?.exponent === 3) {
      return formatVolumeMetric(Number(attr.attributeValue));
    }
  }

  if (attr.customAttributeUnit?.symbol === 'ft') {
    if (attr.customAttributeUnit?.exponent === null) {
      return formatLengthImperial(Number(attr.attributeValue));
    }

    if (attr.customAttributeUnit?.exponent === 2) {
      return formatAreaImperial(Number(attr.attributeValue));
    }

    if (attr.customAttributeUnit?.exponent === 3) {
      return formatVolumeImperial(Number(attr.attributeValue));
    }
  }

  const value = attr.attributeValue;
  if (!value) {
    return '';
  }

  if (Number.isNaN(+value)) {
    return value;
  }

  const decimalPlaces = 4;

  const userFriendlyValue = (
    Math.round(+value * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces)
  )
    .toString()
    .replace(/(\.\d*?[1-9])0+$/, '$1')
    .replace(/\.$/, '');

  if (attr.customAttributeUnit?.unitName === 'Percentage') {
    return `${userFriendlyValue}%`;
  }

  if (attr.customAttributeUnit?.unitName === 'Degree') {
    const degree = '\u00B0';
    return `${userFriendlyValue}${degree}`;
  }

  return `${userFriendlyValue} ${getSymbol(attr, true)}`.trim();
};
