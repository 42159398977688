import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ListOnScrollProps, VariableSizeList } from 'react-window';

import { StyleContext } from './datatable.types';

export const useCloudDatatableHandleHeaderRightMarginAdjustment = ({
  headersRef,
  bodyRef,
}: {
  headersRef: HTMLDivElement | null;
  bodyRef: HTMLDivElement | null;
}) => {
  const [headerRightMarginAdjustment, setHeaderRightMarginAdjustment] =
    useState<number>();

  const handleRightMarginAdjustment = useCallback(() => {
    if (!headersRef || !bodyRef || bodyRef.clientWidth === 0) {
      return;
    }
    const diff = headersRef?.clientWidth - bodyRef?.clientWidth;
    if (diff !== headerRightMarginAdjustment) {
      if (diff > 30 || diff < 0) {
        setHeaderRightMarginAdjustment(0);
      } else {
        setHeaderRightMarginAdjustment(diff);
      }
    }
  }, [headersRef, bodyRef, headerRightMarginAdjustment]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleRightMarginAdjustment);

    if (bodyRef) {
      resizeObserver.observe(bodyRef);
      return () => {
        resizeObserver.unobserve(bodyRef);
        resizeObserver.disconnect();
      };
    }
    return;
  }, [handleRightMarginAdjustment]);

  return { headerRightMarginAdjustment };
};

export const useCloudDatatableHandleShowHorizontalScroll = ({
  wrapperRef,
}: {
  wrapperRef: HTMLDivElement | null;
}) => {
  const style = useContext(StyleContext);

  const [showHorizontalScroll, setShowHorizontalScroll] =
    useState<boolean>(false);
  const handleShowHorizontalScroll = useCallback(() => {
    if (!wrapperRef?.clientWidth || !style?.minWidth) {
      setShowHorizontalScroll(false);
      return;
    }

    const minWidth = parseInt(style?.minWidth.replace(/\D/g, ''));

    if (wrapperRef.clientWidth < minWidth) {
      setShowHorizontalScroll(true);
      return;
    }

    setShowHorizontalScroll(false);
    return;
  }, [style?.minWidth, wrapperRef]);

  useEffect(() => {
    if (style?.minWidth) {
      handleShowHorizontalScroll();
      window.addEventListener('resize', handleShowHorizontalScroll);
      return () => {
        window.removeEventListener('resize', handleShowHorizontalScroll);
      };
    }
    return;
  }, [handleShowHorizontalScroll, style?.minWidth]);

  return { showHorizontalScroll };
};

export const cloudDatatableScrollStorage: { [id: string]: number } = {};

export const useCloudDatatableScrollRestoration = ({
  id,
  datatableRef,
}: {
  id: string;
  datatableRef: VariableSizeList | null;
}) => {
  const [scrollRestored, setScrollRestored] = useState(false);

  const scroll = useMemo(() => cloudDatatableScrollStorage[id] ?? 0, [id]);

  useEffect(() => {
    if (id && datatableRef && !scrollRestored) {
      datatableRef.scrollTo(scroll);
      setScrollRestored(true);
    }
  }, [scroll, id, datatableRef]);

  const onScroll = useCallback(
    (event: ListOnScrollProps) => {
      if (!id || event.scrollUpdateWasRequested || !scrollRestored) {
        return;
      }

      cloudDatatableScrollStorage[id] = event.scrollOffset;
    },
    [id, scrollRestored]
  );

  return { onScroll, scroll };
};
