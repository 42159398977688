import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';

interface ButtonSmallProps {
  id: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  label?: string;
  icon?: IconDefinition;
  iconColor?: string;
}

export const ButtonSmall = ({
  id,
  onClick,
  disabled,
  label,
  icon,
  iconColor,
}: ButtonSmallProps) => {
  const className = useMemo(() => {
    let classes = ` border-gray-light border transition-all rounded p-1 ring-gray-light
    duration-200 focus:outline-none outline-none focus:ring-2 ring-offset-1
    flex flex-row items-center justify-center text-xs `;

    if (disabled) {
      classes += ' cursor-not-allowed opacity-50 text-disabled ';
    } else {
      classes += ' text-gray-dark ';
    }

    return classes.trim();
  }, [disabled]);

  return (
    <button
      data-test-id={id}
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled}
      style={{
        height: '26px',
      }}
    >
      <div className="flex flex-row gap-1 items-center">
        {label && <span>{label}</span>}
        {icon && (
          <div className="w-4 h-4 flex items-center justify-center">
            <FontAwesomeIcon
              icon={icon}
              className={`w-full h-full ${iconColor}`.trim()}
            />
          </div>
        )}
      </div>
    </button>
  );
};
