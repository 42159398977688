/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */

export type Ordinates = (typeof Ordinates)[keyof typeof Ordinates];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Ordinates = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_16: 16,
  NUMBER_32: 32,
  NUMBER_64: 64,
  NUMBER_128: 128,
  NUMBER_256: 256,
  NUMBER_512: 512,
  NUMBER_1024: 1024,
  NUMBER_2048: 2048,
  NUMBER_4096: 4096,
  NUMBER_8192: 8192,
  NUMBER_16384: 16384,
  NUMBER_32768: 32768,
  NUMBER_65535: 65535,
  NUMBER_65536: 65536,
  NUMBER_65539: 65539,
  NUMBER_65543: 65543,
  NUMBER_131072: 131072,
  NUMBER_262144: 262144,
  NUMBER_524288: 524288,
  NUMBER_1048576: 1048576,
  NUMBER_2097152: 2097152,
  NUMBER_4194304: 4194304,
  NUMBER_8388608: 8388608,
  NUMBER_16777216: 16777216,
  NUMBER_33554432: 33554432,
  NUMBER_67108864: 67108864,
  NUMBER_134217728: 134217728,
  NUMBER_268435456: 268435456,
  NUMBER_536870912: 536870912,
  NUMBER_1073741824: 1073741824,
  NUMBER_MINUS_2147483648: -2147483648,
  NUMBER_MINUS_65536: -65536,
  NUMBER_MINUS_1: -1,
} as const;
