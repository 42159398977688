import { Range } from 'react-range';

interface InputSlider {
  values: number[];
  onChange: (values: number[]) => void;
  onFinalChange?: (values: number[]) => void;
  step: number;
  min: number;
  max: number;
  unit?: string;
  showLabels?: boolean;
}

export function InputSlider({
  values,
  onChange,
  onFinalChange,
  step,
  min,
  max,
  unit,
  showLabels,
}: InputSlider) {
  return (
    <div>
      <Range
        step={step}
        min={min}
        max={max}
        values={values}
        onChange={onChange}
        onFinalChange={onFinalChange}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="bg-gray-300 h-1 mt-3 w-full rounded"
            style={{
              ...props.style,
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{ ...props.style }}
            className="w-5 h-5 bg-green rounded-full"
          />
        )}
      />
      {showLabels && (
        <div className="flex justify-between text-sm mt-3">
          <span>{`${min} ${unit ? unit : ''}`}</span>
          <span>{`${max} ${unit ? unit : ''}`}</span>
        </div>
      )}
    </div>
  );
}
