import {
  faCircleNotch,
  faFilterSlash,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIModels, User, formatDate } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import {
  useIsViteApp,
  usePageTitle,
  useQueryState,
} from '@agerpoint/utilities';

import { useAdminProjectsQueries } from './admin-projects-queries';

export const AdminProjectsList = () => {
  usePageTitle(() => 'Platform - Projects', []);
  const navigate = useNavigate();

  const isViteApp = useIsViteApp();

  const [filter, setFilter] = useState<APIModels.ProjectFilter>();

  const {
    organizationsQuery,
    usersQuery,
    projectsInfiniteQuery,
    organizationsLookupTable,
  } = useAdminProjectsQueries(filter);

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (a) => a.trim(),
    toUrlParam: (a) => a.trim(),
  });

  const [archivedFilter, setArchivedFilter] = useQueryState<boolean>({
    paramName: 'archived',
    initialValue: false,
    fromUrlParam: (a) => {
      if (a === 'yes') {
        return true;
      }
      return false;
    },
    toUrlParam: (a) => {
      return a ? 'yes' : '';
    },
  });

  const [selectedFilterOrganizations, setSelectedFilterOrganizations] =
    useQueryState<APIModels.Customer[]>({
      paramName: 'organizations',
      initialValue: [],
      fromUrlParam: (a) => {
        const splitted = a?.split(',').map((x) => Number(x));
        const filtered = organizationsQuery.data?.filter((x) => {
          return splitted?.includes(x.id as number) ?? false;
        });
        return filtered ?? [];
      },
      toUrlParam: (a) => {
        return a.map((x) => x.id).join(',');
      },
      retryInitWhen: organizationsQuery.isSuccess,
    });

  const [selectedFilterUsers, setSelectedFilterUsers] = useQueryState<User[]>({
    paramName: 'users',
    initialValue: [],
    fromUrlParam: (a) => {
      if (!usersQuery.data) {
        return [];
      }

      return usersQuery.data.filter(
        (x) => a?.includes(x.id as string) ?? false
      );
    },
    toUrlParam: (a) => {
      return a.map((x) => x.id).join(',');
    },
    retryInitWhen: usersQuery.isSuccess,
  });

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        projectName: nameFilter.trim(),
        includeOwners: true,
      }));
    },
    500,
    [nameFilter]
  );

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      orderBy: 'createDatetime',
      orderAscending: false,
      projectName: nameFilter.trim(),
    }));
  }, []);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      archived: archivedFilter,
      customerIds: selectedFilterOrganizations.map((x) => x.id as number),
      userUuids: selectedFilterUsers.map((x) => x.id as string),
      includeOwners: true,
    }));
  }, [archivedFilter, selectedFilterOrganizations, selectedFilterUsers]);

  const hasFiltersApplied = useMemo(
    () =>
      !!(
        (filter?.customerIds?.length ?? 0) > 0 ||
        (filter?.userUuids?.length ?? 0) > 0 ||
        (filter?.projectName?.trim().length ?? 0) > 0
      ),
    [filter]
  );

  const [search] = useSearchParams();

  const clearFilters = useCallback(() => {
    setSelectedFilterOrganizations([]);
    setSelectedFilterUsers([]);
    setNameFilter('');

    const searchParams = new URLSearchParams(search);
    searchParams.delete('organizations');
    searchParams.delete('users');
    searchParams.delete('name');

    navigate(
      {
        pathname: isViteApp
          ? '/app/admin/platform/projects'
          : '/admin/projects',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, [search]);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <h1 className="text-3xl font-bold px-4 py-2">Projects</h1>
      <div className="px-4 flex flex-row gap-2 flex-wrap">
        <div className="pr-1 w-80">
          <Input.Text.Single
            id="project-name-filter"
            setValue={setNameFilter}
            value={nameFilter}
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Input.Select.Multi
          id="organization-filter-select"
          placeholder="Organizations"
          title="Organizations"
          options={organizationsQuery.data ?? []}
          value={selectedFilterOrganizations}
          loading={organizationsQuery.isLoading}
          setValue={setSelectedFilterOrganizations}
          optionBuilder={(o) =>
            o.customerDisplayName ?? o.customerName ?? 'Unknown'
          }
          maxWidth="232px"
        />
        <Input.Select.Multi
          id="user-filter-select"
          placeholder="Owners"
          title="Owners"
          options={usersQuery.data ?? []}
          value={selectedFilterUsers}
          loading={usersQuery.isLoading}
          setValue={setSelectedFilterUsers}
          optionBuilder={(o) =>
            `${o?.userProfiles?.[0]?.firstName} ${o?.userProfiles?.[0]?.lastName}`.trim()
          }
          maxWidth="232px"
        />
        <Input.Select.Inline
          id="project-status-filter"
          options={[false, true]}
          optionBuilder={(o) => (o ? 'Archived' : 'Active')}
          value={archivedFilter}
          setValue={setArchivedFilter}
        />
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 w-full h-full">
        <Datatable
          id="admin-projects-datatable"
          data={projectsInfiniteQuery.data?.pages.flatMap((p) => p) ?? []}
          style={{ ...dataTableAgerStyle, tableMinWidth: 900 }}
          rowHeight={50}
          columns={[
            {
              label: 'Name',
              value: (row) => {
                return <span title={row.name || ''}>{row.name}</span>;
              },
              flex: 2,
              name: 'name',
              sortKey: 'name',
            },
            {
              label: 'UUID',
              value: (row) => {
                return <span title={row.uuid || ''}>{row.uuid}</span>;
              },
              flex: 2,
              name: 'uuid',
            },
            {
              label: 'Owner',
              value: (row) => {
                return [
                  row.ownerProfile?.firstName ?? '',
                  row.ownerProfile?.lastName ?? '',
                ]
                  .join(' ')
                  .trim();
              },
              flex: 2,
              name: 'owner',
            },
            {
              label: 'Organization',
              value: (row) => {
                if (!row.customerId) {
                  return null;
                }

                if (!organizationsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const c = organizationsLookupTable?.[row.customerId];

                return c?.customerDisplayName ?? c?.customerName ?? null;
              },
              flex: 2,
              name: 'customer',
            },
            {
              label: 'Created On',
              value: (row) => {
                return row.createDatetime
                  ? formatDate(row.createDatetime.toString())
                  : '';
              },
              name: 'created',
              sortKey: 'createDatetime',
            },
            {
              label: '',

              value: (row, index) => {
                return (
                  <div className="p-1">
                    <Button.Icon
                      id={`actions-button-${index}`}
                      icon={faExternalLink}
                      internalPadding="p-1"
                      onClick={() => {
                        if (isViteApp) {
                          window.open(
                            `/app?project=${row.uuid}`,
                            '_blank',
                            'noopener noreferrer'
                          );
                        } else {
                          window.open(
                            `/project/${row.uuid}`,
                            '_blank',
                            'noopener noreferrer'
                          );
                        }
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          cellOnClick={() => {
            return (project, e) => {
              if (e.ctrlKey || e.metaKey) {
                if (isViteApp) {
                  window.open(
                    `/app?project=${project.uuid}`,
                    '_blank',
                    'noopener noreferrer'
                  );
                } else {
                  window.open(
                    `/project/${project.uuid}`,
                    '_blank',
                    'noopener noreferrer'
                  );
                }
                return;
              }

              return navigate(`${project.uuid}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching projects',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No projects yet',
                  message: 'Create a project to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faInfoCircle} />,
                  actionLabel: 'New Project',
                }
          }
          error={
            projectsInfiniteQuery.isError
              ? {
                  title: 'There was a problem loading projects',
                  message: 'Try refreshing the page',
                  action: () => projectsInfiniteQuery.refetch(),
                }
              : undefined
          }
          loading={
            projectsInfiniteQuery.isLoading ||
            projectsInfiniteQuery.isFetchingNextPage ||
            filter === undefined
          }
          pagination={{
            loadNextPage: () => {
              if (
                projectsInfiniteQuery.isLoading ||
                projectsInfiniteQuery.isFetchingNextPage ||
                !projectsInfiniteQuery.hasNextPage ||
                filter === undefined
              ) {
                return;
              }
              projectsInfiniteQuery.fetchNextPage();
            },
            threshold: 10,
          }}
          sort={{
            key: filter?.orderBy,
            order: filter?.orderAscending ? 'asc' : 'desc',
          }}
          setSort={(options) => {
            setFilter((prev) => ({
              ...prev,
              orderBy: options.key,
              orderAscending: options.order === 'asc',
            }));
          }}
        />
      </div>
    </div>
  );
};
