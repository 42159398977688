import {
  faCircleNotch,
  faFilterSlash,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIClient, APIModels, formatDate } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import {
  useIsViteApp,
  usePageTitle,
  useQueryState,
} from '@agerpoint/utilities';

import { useAdminGeometriesQueries } from './admin-geometries-queries';

export const AdminGeometriesList = () => {
  usePageTitle(() => 'Platform - Geometries', []);
  const navigate = useNavigate();

  const isViteApp = useIsViteApp();

  const [filter, setFilter] = useState<APIClient.ApGeometryCollectionFilter>();

  const { organizationsQuery, organizationsLookupTable, geometriesQuery } =
    useAdminGeometriesQueries(filter);

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (a) => a.trim(),
    toUrlParam: (a) => a.trim(),
  });

  const [archivedFilter, setArchivedFilter] = useQueryState<boolean>({
    paramName: 'archived',
    initialValue: false,
    fromUrlParam: (a) => {
      if (a === 'yes') {
        return true;
      }
      return false;
    },
    toUrlParam: (a) => {
      return a ? 'yes' : '';
    },
  });

  const [selectedFilterOrganizations, setSelectedFilterOrganizations] =
    useQueryState<APIModels.Customer[]>({
      paramName: 'organizations',
      initialValue: [],
      fromUrlParam: (a) => {
        const splitted = a?.split(',').map((x) => Number(x));
        const filtered = organizationsQuery.data?.filter((x) => {
          return splitted?.includes(x.id as number) ?? false;
        });
        return filtered ?? [];
      },
      toUrlParam: (a) => {
        return a.map((x) => x.id).join(',');
      },
      retryInitWhen: organizationsQuery.isSuccess,
    });

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        name: nameFilter.trim(),
      }));
    },
    500,
    [nameFilter]
  );

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      orderBy: 'createDatetime',
      orderAscending: false,
      name: nameFilter.trim(),
    }));
  }, []);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      archived: archivedFilter,
      customerId: selectedFilterOrganizations.map((x) => x.id as number),
    }));
  }, [archivedFilter, selectedFilterOrganizations]);

  const [search] = useSearchParams();

  const clearFilters = useCallback(() => {
    setNameFilter('');
    setSelectedFilterOrganizations([]);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('organizations');
    searchParams.delete('name');

    navigate(
      {
        pathname: isViteApp
          ? '/app/admin/platform/geometries'
          : '/admin/geometries',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, [search]);

  const hasFiltersApplied = useMemo(
    () =>
      !!((filter?.name?.length ?? 0) > 0) ||
      (filter?.customerId?.length ?? 0) > 0,
    [filter]
  );

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="flex flex-row justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">Geometries</h1>
        <Button.Primary
          id="geometries-new-button"
          icon={faPlus}
          label="New Geometry"
          onClick={() => {
            navigate('new', {
              state: { params: window.location.search },
            });
          }}
        />
      </div>
      <div className="px-4 flex flex-row gap-2 flex-wrap">
        <div className="pr-1 w-80">
          <Input.Text.Single
            id="geometry-name-filter"
            setValue={setNameFilter}
            value={nameFilter}
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Input.Select.Multi
          id="organization-filter-select"
          placeholder="Organizations"
          title="Organizations"
          options={organizationsQuery.data ?? []}
          value={selectedFilterOrganizations}
          loading={organizationsQuery.isLoading}
          setValue={setSelectedFilterOrganizations}
          optionBuilder={(o) =>
            o.customerDisplayName ?? o.customerName ?? 'Unknown'
          }
          maxWidth="232px"
        />

        <Input.Select.Inline
          id="geometry-status-filter"
          options={[false, true]}
          optionBuilder={(o) => (o ? 'Archived' : 'Active')}
          value={archivedFilter}
          setValue={setArchivedFilter}
        />
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 w-full h-full">
        <Datatable
          id="admin-geometries-datatable"
          data={geometriesQuery.data?.pages.flatMap((p) => p) ?? []}
          style={{ ...dataTableAgerStyle, tableMinWidth: 900 }}
          rowHeight={50}
          cellOnClick={() => {
            return (row) => {
              navigate(`${row.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching geometries',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No geometries yet',
                  message: 'Create geometry to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Geometry',
                }
          }
          error={
            geometriesQuery.isError
              ? {
                  title: 'There was a problem loading geometries',
                  message: 'Try refreshing the page',
                  action: () => geometriesQuery.refetch(),
                }
              : undefined
          }
          columns={[
            { label: 'ID', value: (row) => row.id, sortKey: 'id', name: 'id' },
            {
              label: 'Name',
              value: (row) => row.name,
              flex: 4,
              sortKey: 'name',
              name: 'name',
            },
            {
              label: 'Created',
              value: (row) =>
                row.createDatetime ? formatDate(row.createDatetime) : '',
              sortKey: 'createDatetime',
              name: 'created',
            },
            {
              label: 'Features',
              value: (row) => row.totalGeometries,
              name: 'features',
            },
            {
              label: 'Organization',
              value: (row) => {
                if (row.customerId === undefined) {
                  return;
                }

                if (!organizationsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const org = organizationsLookupTable?.[row.customerId];

                return org?.customerDisplayName ?? org?.customerName ?? null;
              },
              flex: 3,
              name: 'organization',
            },
          ]}
          loading={
            geometriesQuery.isLoading ||
            geometriesQuery.isFetchingNextPage ||
            filter === undefined
          }
          pagination={{
            loadNextPage: () => {
              if (
                geometriesQuery.isLoading ||
                geometriesQuery.isFetchingNextPage ||
                !geometriesQuery.hasNextPage ||
                filter === undefined
              ) {
                return;
              }
              geometriesQuery.fetchNextPage();
            },
            threshold: 10,
          }}
          sort={{
            key: filter?.orderBy,
            order: filter?.orderAscending ? 'asc' : 'desc',
          }}
          setSort={(options) => {
            setFilter((prev) => ({
              ...prev,
              orderBy: options.key,
              orderAscending: options.order === 'asc',
            }));
          }}
        />
      </div>
    </div>
  );
};
