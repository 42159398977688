import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, MotionStyle, motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useAgerStore } from '@agerpoint/utilities';

interface IInfo {
  fieldName: React.ReactNode;
  value: React.ReactNode;
}

interface ITooltipInfo {
  infos: IInfo[];
}

const TooltipInfo = ({ infos }: ITooltipInfo) => {
  const [show, setShow] = useState(false);
  const { isMobile } = useAgerStore();

  const iconRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<MotionStyle>({});
  const calculatePosition = useCallback(() => {
    if (!iconRef.current) {
      setStyle({});
      return undefined;
    }

    const rect = iconRef.current.getBoundingClientRect();
    // Check on which quarter of the screen the child is in
    const isLeft = rect.left < window.innerWidth / 2;
    const isTop = rect.top < window.innerHeight / 2;
    const isRight = !isLeft;
    const isBottom = !isTop;

    const calculatedStyle: MotionStyle = {};

    if (isTop) {
      calculatedStyle.top = rect.bottom + 2;
    }

    if (isBottom) {
      calculatedStyle.bottom = window.innerHeight - rect.top + 2;
    }

    const menuRect = menuRef.current?.getBoundingClientRect();
    if (menuRect && menuRect.width > window.innerWidth / 2 && isMobile) {
      calculatedStyle.transform = 'translateX(-50%)';
      calculatedStyle.left = '50%';
    } else {
      if (isRight) {
        calculatedStyle.right = window.innerWidth - rect.right + 2;
      }
      if (isLeft) {
        calculatedStyle.left = rect.right - rect.width + 2;
      }
    }

    setStyle(calculatedStyle);
  }, [isMobile]);

  const onMouseEnter = useCallback(() => {
    setShow(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setShow(false);
  }, []);

  const onClick = useCallback((e: MouseEvent) => {
    if (iconRef.current?.contains(e.target as Node)) {
      setShow((prev) => !prev);
    } else {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calculatePosition);
    if (isMobile) {
      window.addEventListener('click', onClick);
      return () => {
        window.addEventListener('resize', calculatePosition);
        window.removeEventListener('click', onClick);
      };
    }

    const c = iconRef.current;
    c?.addEventListener('mouseenter', onMouseEnter);
    c?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      window.removeEventListener('resize', calculatePosition);
      c?.removeEventListener('mouseenter', onMouseEnter);
      c?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [calculatePosition, isMobile, onMouseEnter, onMouseLeave, onClick]);

  useEffect(() => {
    if (show) {
      calculatePosition();
    }
  }, [show, calculatePosition]);

  return (
    <>
      <span
        ref={iconRef}
        style={{ width: '14px', height: '14px' }}
        className="text-gray-iconPrimary"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
      {createPortal(
        <AnimatePresence>
          {show && (
            <motion.div
              className={`absolute z-tooltip bg-white shadow-lg rounded-lg p-4 flex flex-col border border-gray-border`}
              style={style}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              ref={menuRef}
            >
              {infos.map((info, index) => (
                <div
                  key={index}
                  className="flex flex-row text-sm gap-2 w-52 break-all"
                >
                  <div className="font-bold flex-1">{info.fieldName}</div>
                  <div className="flex-1">{info.value}</div>
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>,
        document.getElementById('apc-context-menus') as HTMLElement
      )}
    </>
  );
};

export { TooltipInfo };
