import { faCircleDashed } from '@fortawesome/pro-light-svg-icons';
import {
  faBan,
  faCheck,
  faCircleNotch,
  faClock,
  faCloud,
  faExclamationTriangle,
  faPlay,
  faRotateRight,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faPause } from '@fortawesome/pro-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import {
  BackgroundTaskGroup,
  BackgroundTaskGroupResult,
} from '@agerpoint/types';

import { useBackgroundTaskManager } from './background-task-manager';

export const BackgroundTaskManagerPanel = () => {
  const {
    taskGroups,
    removeTaskGroup,
    cancelTaskGroup,
    retryTaskGroup,
    togglePauseTaskGroup,
  } = useBackgroundTaskManager();

  const hasRunningTasks = useMemo(() => {
    for (const group of taskGroups) {
      if (group.isBeingFinalized) {
        return true;
      }
      for (const task of group.tasks) {
        if (task.isBeingProcessed) {
          return true;
        }
      }
    }
    return false;
  }, [taskGroups]);

  const [groupToCancel, setGroupToCancel] = useState<{
    groupId: string;
    groupDesc: string;
  }>();

  useEffect(() => {
    if (!groupToCancel) {
      return;
    }

    const g = taskGroups.find((g) => g.groupId === groupToCancel.groupId);
    if (g === undefined || g.groupResult || g.isBeingFinalized) {
      setGroupToCancel(undefined);
    }
  }, [taskGroups]);

  const summarizeGroup = useCallback((group: BackgroundTaskGroup) => {
    const hasRunningTasks = group.tasks
      .map((t) => t.isBeingProcessed)
      .includes(true);
    // Default state is 'Pending'
    let iconProps: FontAwesomeIconProps = {
      icon: faClock,
    };
    let iconClassName = 'text-gray-900';

    if (group.groupResult === BackgroundTaskGroupResult.success) {
      iconProps = {
        icon: faCheck,
      };
      iconClassName = 'bg-green-100 text-green';
    } else if (group.groupResult === BackgroundTaskGroupResult.error) {
      iconProps = {
        icon: faExclamationTriangle,
      };
      iconClassName = 'bg-red text-white';
    } else if (group.groupResult === BackgroundTaskGroupResult.cancel) {
      iconProps = {
        icon: faBan,
      };
      iconClassName = 'bg-gray-400 text-white';
    } else if (group.isBeingFinalized) {
      iconProps = {
        icon: faCircleNotch,
        spin: true,
      };
    } else if (hasRunningTasks) {
      iconProps = {
        icon: faCircleNotch,
        spin: true,
      };
    }

    let groupProgress = group.tasks
      .map((t) => t.progress ?? (t.result ? 100 : 0))
      .reduce((p, c) => {
        return c + p;
      }, 0);
    if (group.tasks.length !== 0) {
      groupProgress /= group.tasks.length;
    }

    let progressText: string | undefined = `(${groupProgress.toFixed(1)}%)`;
    const attemptText =
      group.retryAttempts > 0
        ? `(attempt ${group.retryAttempts + 1})`
        : undefined;

    let statusText = 'Pending';
    if (group.groupResult === BackgroundTaskGroupResult.success) {
      statusText = 'Success';
      progressText = undefined;
    } else if (group.groupResult === BackgroundTaskGroupResult.error) {
      statusText = 'Error';
      progressText = undefined;
    } else if (group.groupResult === BackgroundTaskGroupResult.cancel) {
      statusText = 'Canceled';
      progressText = undefined;
    } else if (group.isBeingFinalized) {
      statusText = 'Finalizing...';
    } else if (hasRunningTasks && group.isPaused) {
      statusText = 'Pausing...';
    } else if (group.isPaused) {
      statusText = 'Paused';
      if (groupProgress <= 0) {
        progressText = undefined;
      }
    } else if (hasRunningTasks) {
      statusText = 'Processing...';
    } else {
      if (groupProgress <= 0) {
        progressText = undefined;
      }
    }

    return {
      iconProps,
      iconClassName,
      statusText,
      progressText,
      attemptText,
    };
  }, []);

  const [menuRef, setMenuRef] = useState<HTMLButtonElement | null>(null);

  const isOnLeft = useMemo(() => {
    if (!menuRef) {
      return false;
    }

    return menuRef.getBoundingClientRect().left < window.innerWidth / 2;
  }, [menuRef]);

  if (taskGroups.length === 0) {
    return null;
  }

  return (
    <Menu as="div" className="relative w-8 h-8">
      {({ open }) => (
        <>
          <Menu.Button
            ref={setMenuRef}
            data-test-id="bg-task-panel-button"
            className={`w-8 h-8 rounded transition-colors hover:bg-green-100 p-0.5 ${
              open ? 'bg-green-100' : ''
            }`}
          >
            <div className="relative w-full h-full flex items-center justify-center">
              <FontAwesomeIcon
                icon={faCloud}
                className="absolute w-2/3 h-2/3"
              />
              {hasRunningTasks && (
                <FontAwesomeIcon
                  icon={faCircleDashed}
                  className="absolute w-full h-full"
                  spin
                  style={{
                    animationDuration: '4s',
                  }}
                />
              )}
            </div>
          </Menu.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`origin-top-right absolute ${
                isOnLeft ? 'left-0' : 'right-0'
              } w-80 rounded-md
              shadow bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
              style={{ zIndex: 99999 }}
            >
              <div className="flex flex-col w-full">
                <div className="flex items-center justify-between p-2 text-md border-b border-gray-200">
                  <h5 className="leading-none text-gray-900">
                    Background Tasks
                  </h5>
                </div>
                <ul
                  className="divide-y divide-gray-200 overflow-y-auto"
                  style={{ maxHeight: '240px' }}
                >
                  {taskGroups.map((group) => {
                    const groupSummary = summarizeGroup(group);

                    return (
                      <li className="p-2" key={group.groupId}>
                        <div className="flex items-center gap-2">
                          <TaskIcon
                            iconProps={groupSummary.iconProps}
                            className={groupSummary.iconClassName}
                          />
                          <div className="flex-1 truncate">
                            <p className="text-sm text-gray-900 truncate">
                              {group.groupDesc}
                            </p>
                            <p className="text-xs text-gray-500 truncate">
                              {[
                                groupSummary.statusText,
                                groupSummary.attemptText,
                                groupSummary.progressText,
                              ]
                                .filter((s) => s)
                                .join(' ')}
                            </p>
                          </div>
                          <div className="flex flex-col justify-start items-end self-start gap-1">
                            {!group.isBeingFinalized &&
                              (group.isCancellable || group.groupResult) && (
                                <div
                                  className={`w-3 h-3 bg-gray-100 rounded-full p-0.5 cursor-pointer
                            hover:bg-gray-200 flex justify-center items-center`}
                                  onClick={() => {
                                    if (group.groupResult) {
                                      removeTaskGroup(group.groupId);
                                    } else {
                                      if (!group.isCancellable) {
                                        return;
                                      }
                                      setGroupToCancel({
                                        groupId: group.groupId,
                                        groupDesc: group.groupDesc,
                                      });
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    className="w-full h-full"
                                  />
                                </div>
                              )}
                            {group.groupResult ===
                              BackgroundTaskGroupResult.error && (
                              <div
                                className={`w-3 h-3 bg-gray-100 rounded-full p-0.5 cursor-pointer
                        hover:bg-gray-200 flex justify-center items-center`}
                                onClick={() => {
                                  retryTaskGroup(group.groupId);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faRotateRight}
                                  className="w-full h-full"
                                />
                              </div>
                            )}
                            {group.groupResult === undefined &&
                              !group.isBeingFinalized && (
                                <div
                                  className={`w-3 h-3 bg-gray-100 rounded-full p-0.5 cursor-pointer
                        hover:bg-gray-200 flex justify-center items-center`}
                                  onClick={() => {
                                    togglePauseTaskGroup(group.groupId);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={group.isPaused ? faPlay : faPause}
                                    className="w-full h-full"
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {groupToCancel && (
                <div
                  className={`absolute w-full h-10 bg-white rounded-md px-2
                ring-1 ring-black ring-opacity-5 flex items-center justify-between text-xs`}
                  style={{ bottom: '-48px' }}
                >
                  <div className="flex flex-col w-full truncate">
                    <div>Are you sure you want to cancel this task?</div>
                    <div className="truncate text-gray-500">
                      {groupToCancel.groupDesc}
                    </div>
                  </div>
                  <div className="flex flex-row gap-1">
                    <div
                      className={`rounded-full bg-gray-100 hover:bg-gray-200
                    w-5 h-5 cursor-pointer flex items-center justify-center p-1 text-gray-600`}
                      onClick={() => {
                        cancelTaskGroup(groupToCancel.groupId);
                        setGroupToCancel(undefined);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="w-full h-full"
                      />
                    </div>
                    <div
                      className={`rounded-full bg-gray-100 hover:bg-gray-200
                    w-5 h-5 cursor-pointer flex items-center justify-center p-1 text-gray-600`}
                      onClick={() => setGroupToCancel(undefined)}
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="w-full h-full"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const TaskIcon = ({
  iconProps,
  className,
}: {
  iconProps: FontAwesomeIconProps;
  className: string;
}) => {
  return (
    <div
      className={`w-6 h-6 flex justify-center items-center rounded ${
        className ?? ''
      }`}
    >
      <FontAwesomeIcon {...iconProps} />
    </div>
  );
};
