import { useEffect } from 'react';
import { UseGetReturn } from 'restful-react';
import { SetState, create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

import {
  LayerGroup,
  useGetProjectByUuid,
  useGetProjectLayerGroups,
} from '@agerpoint/api';
import { Group, ProjectInput, ProjectState } from '@agerpoint/types';
import { environment, toStateGroup } from '@agerpoint/utilities';

import createActions from './actions';
import { createInitialPanelState, usePanels } from './use-panels';

function createInitialState(set: SetState<ProjectState>): ProjectState {
  return {
    id: 0,
    tags: [],
    name: '',
    customerId: 0,
    groups: [],
    loading: false,
    error: null,
    fusionLink: null,
    ...createActions(set),
  };
}
export const useProject = create<ProjectState>()(
  devtools((set) => ({
    ...createInitialState(set),
  }))
);

function useGet(getHooks: Array<UseGetReturn<void, void, void, unknown>>) {
  const results: Array<UseGetReturn<void, void, void, unknown>> = [];
  for (let i = 0; i < getHooks.length; i++) {
    results[i] = getHooks[i];
  }

  return {
    loading: results.some((result) => result.loading),
    error: results.find((result) => result.error)?.error,
    data: results.map((result) => result.data),
  };
}

export function useFetchProject(id: string) {
  const projectGet = useGetProjectByUuid({
    uuid: id,
  }) as unknown as UseGetReturn<void, void, void, unknown>;
  const groupsGet = useGetProjectLayerGroups({ projectUuid: id });
  const {
    loading,
    error,
    data: [projectData, groupsData],
  } = useGet([
    projectGet,
    groupsGet as unknown as UseGetReturn<void, void, void, unknown>,
  ]);

  const { setProject, setLoading, setError } = useProject(getActions, shallow);

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  useEffect(() => {
    setError(error || null);
  }, [setError, error]);

  useEffect(() => {
    if (projectData) {
      setProject({
        ...(projectData as ProjectInput),
        groups: groupsData
          ? (groupsData as Array<LayerGroup>).map(toStateGroup)
          : [],
      });
    }
  }, [setProject, projectData, groupsData]);

  useEffect(() => {
    const { setState: setProjectState } = useProject;
    const { setState: setPanelState } = usePanels;

    return () => {
      useProject.setState(createInitialState(setProjectState), true);
      usePanels.setState(createInitialPanelState(setPanelState), true);
    };
  }, []);
}

function getActions({
  actions: { setProject, setLoading, setError },
}: ProjectState) {
  return { setProject, setLoading, setError };
}

export function isClientSideGroup({ id }: Group): boolean {
  return id ? id < 0 : false;
}

export const bingKey: string = environment.bing_api_key;

export function findLayer(groups: Array<Group>, layerId: number) {
  for (const group of groups) {
    for (const layer of group.layers) {
      if (layer.id === layerId) {
        return { group, layer };
      }
    }
  }
  return null;
}
