import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface useQueryStateProps<T> {
  paramName: string;
  initialValue: T;
  toUrlParam: (arg0: T) => string;
  fromUrlParam: (arg0: string) => T;
  retryInitWhen?: boolean;
}

export function useQueryState<T>({
  paramName,
  initialValue,
  toUrlParam,
  fromUrlParam,
  retryInitWhen,
}: useQueryStateProps<T>): [T, (arg0: T) => void] {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const existingValue = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(paramName);
  }, [retryInitWhen]);

  const [state, setState] = useState<T>(
    existingValue && (retryInitWhen ?? true)
      ? fromUrlParam(existingValue)
      : initialValue
  );

  useEffect(() => {
    if (!(retryInitWhen ?? false)) {
      return;
    }
    if (existingValue) {
      const parsed = fromUrlParam(existingValue);
      if (parsed !== state) {
        setState(parsed);
      }
    }
  }, [existingValue, retryInitWhen]);

  // Known Issue: When changing multiple query states in bulk, they sometimes
  // pick up each other url params and turn them back to what they were before.
  // TODO: fix the issue
  const onChange = (arg0: T) => {
    setState(arg0);
    const searchParams = new URLSearchParams(search);
    const parsed = toUrlParam(arg0);
    if (parsed.length) {
      searchParams.set(paramName, parsed);
    } else {
      searchParams.delete(paramName);
    }

    navigate({ pathname, search: searchParams.toString() });
  };

  return [state, onChange];
}
