/* eslint-disable max-len */
import { useState } from 'react';

import {
  ExpansionContentContainer,
  ExpansionContentTabContentContainer,
  ExpansionContentTabsContainer,
  IconTypes,
  SvgElement,
} from '@agerpoint/component';
import { GroupIcon } from '@agerpoint/types';

import { CustomPicker } from '../color-picker/color-picker';
import { StyleProperty } from '../layer-style/style-property';
import { ColorValue } from '../layer-style/vector-styles';

interface GroupIconSelectorProps {
  icon: GroupIcon;
  onChange: (icon: GroupIcon) => Promise<void>;
}

export function GroupIconSelector({ icon, onChange }: GroupIconSelectorProps) {
  const iconTypes: Array<IconTypes> = [
    // row 1
    'LeafIcon',
    'TreeIcon',
    'BoundaryIcon',
    'SampleIcon',

    // row 2
    'ThreeDIcon',
    'MobileIcon',
    'SatelliteIcon',
    'UavIcon',

    // row 3
    'ImageryIcon',
    'ReportIcon',
    'CloudIcon',
    'CompassIcon',

    //row 4
    'DropletIcon',
    'FlagIcon',
  ];

  const [currentName, setCurrentName] = useState<string>(icon.name);
  const [currentColor, setCurrentColor] = useState<string>(icon.color);
  const [mode, setMode] = useState<string>('name');

  const setName = (name: string) => {
    setCurrentName(name);
    onChange({ ...icon, name });
  };

  const setColor = (color: string) => {
    setCurrentColor(color);
  };

  return (
    <ExpansionContentContainer>
      <ExpansionContentTabsContainer>
        <StyleProperty
          name="Group Icon"
          value={currentName}
          selected={mode === 'name'}
          onSelect={() => setMode('name')}
        />
        <StyleProperty
          name="Group Icon Color"
          value={<ColorValue color={currentColor} />}
          selected={mode === 'color'}
          onSelect={() => setMode('color')}
        />
        {/* <StyleProperty name="Notes" value="Empty" selected={mode === 'notes'} /> */}
      </ExpansionContentTabsContainer>
      <ExpansionContentTabContentContainer>
        {mode === 'name' ? (
          <div className="grid grid-cols-4 grid-flow-row gap-1 justify-around">
            {iconTypes.map((value) => {
              const name = value.replace('Icon', '');

              return (
                <div
                  key={name}
                  className="box-border h-12 w-12
"
                >
                  <button
                    type="button"
                    aria-label={name}
                    onClick={() => setName(name)}
                    className="w-full h-full bg-white border-gray-300 rounded-md hover:bg-gray-50"
                    style={{
                      borderWidth: currentName === name ? '2px' : '1px',
                      borderColor: currentName === name ? currentColor : '',
                    }}
                  >
                    <SvgElement
                      type={value}
                      className={'m-auto max-w-3/5 max-h-3/5'}
                      style={{
                        stroke: currentColor,
                        color: currentColor,
                      }}
                    />
                  </button>
                </div>
              );
            })}
          </div>
        ) : mode === 'color' ? (
          <CustomPicker
            color={currentColor}
            debounce={200}
            disableAlpha={true}
            onChange={setColor}
            onSave={(color: string) => {
              return onChange({ ...icon, color });
            }}
          />
        ) : null}
      </ExpansionContentTabContentContainer>
    </ExpansionContentContainer>
  );
}
