import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels, formatDateAndTime } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminMLModelsQueries } from './admin-ml-models-queries';

export const AdminMLModelsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  const { permissions } = useGlobalStore();

  const isViteApp = useIsViteApp();

  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );
  usePageTitle(() => 'Platform - ML Models', []);

  const {
    mlModelQuery,
    pipelinesQuery,
    mlModelPutMutation,
    mlModelArchivePutMutation,
    mlModelUnarchivePutMutation,
  } = useAdminMLModelsQueries();

  const formValidation = useFormValidation();
  const [mlModel, setMlModel] = useState<APIModels.MlModel>({});
  const [selectedPipeline, setSelectedPipeline] =
    useState<APIModels.Pipeline>();

  useEffect(() => {
    setMlModel(mlModelQuery.data ?? {});
  }, [mlModelQuery.data]);

  useEffect(() => {
    setSelectedPipeline(
      pipelinesQuery.data?.find((p) => p.id === mlModelQuery.data?.pipelineId)
    );
  }, [pipelinesQuery.data, mlModelQuery.data]);

  useEffect(() => {
    setMlModel((prev) => ({ ...prev, pipelineId: selectedPipeline?.id }));
  }, [selectedPipeline]);

  const updateMlModel = useCallback(async () => {
    if (mlModelPutMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    mlModelPutMutation.mutate({
      data: mlModel,
    });
  }, [mlModel, formValidation, mlModelPutMutation]);

  const archiveMlModel = useCallback(async () => {
    if (mlModelArchivePutMutation.isPending) {
      return;
    }

    const confirm = window.confirm(
      'Are you sure you want to archive this ML model?'
    );
    if (!confirm) {
      return;
    }

    mlModelArchivePutMutation.mutate({
      data: { ...mlModelQuery.data, archived: true },
    });
  }, [mlModelQuery.data, mlModelArchivePutMutation]);

  const unarchiveMlModel = useCallback(async () => {
    if (mlModelUnarchivePutMutation.isPending) {
      return;
    }

    const confirm = window.confirm(
      'Are you sure you want to restore this ML model?'
    );
    if (!confirm) {
      return;
    }

    mlModelUnarchivePutMutation.mutate({
      data: { ...mlModelQuery.data, archived: false },
    });
  }, [mlModelQuery.data, mlModelUnarchivePutMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'ML Models',
              path: isViteApp
                ? '/app/admin/platform/ml-models'
                : '/admin/ml-models',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="ml-models-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/ml-models' + params);
            } else {
              navigate('/admin/ml-models' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">
          {mlModelQuery.data?.displayName ?? mlModelQuery.data?.name}
        </h1>
      </div>
      {mlModelQuery.isLoading ? (
        <PageLoadingState />
      ) : mlModelQuery.isError ? (
        <PageErrorState
          entityName="ML model"
          pluralEntityName="ML models"
          statusCode={mlModelQuery.error?.response?.status ?? 500}
          tryAgainCallback={() => {
            mlModelQuery.refetch();
          }}
          tryAgainLoading={mlModelQuery.isFetching}
          navigateBackCallback={() =>
            navigate(
              isViteApp
                ? '/app/admin/platform/ml-models' + params
                : '/admin/ml-models' + params
            )
          }
        />
      ) : (
        <div className="p-4 w-full flex flex-col max-w-lg gap-2">
          <Input.Text.Single
            id="ml-model-name-input"
            label={<Input.Label label="Name" required />}
            value={mlModel.name || ''}
            setValue={(name) => {
              setMlModel({ ...mlModel, name });
            }}
            error={
              <Input.Error
                error={formValidation.errors['ml-model-name-input']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Name')],
            }}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Text.Single
            id="ml-model-display-name-input"
            label={<Input.Label label="Display Name" required />}
            value={mlModel.displayName || ''}
            setValue={(displayName) => {
              setMlModel({ ...mlModel, displayName });
            }}
            error={
              <Input.Error
                error={formValidation.errors['ml-model-display-name-input']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Display Name')],
            }}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Text.Single
            id="ml-model-description-input"
            label={<Input.Label label="Description" />}
            value={mlModel.description || ''}
            setValue={(description) => {
              setMlModel({ ...mlModel, description });
            }}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Text.Single
            id="ml-model-version-input"
            label={<Input.Label label="Version" />}
            value={mlModel.version || ''}
            setValue={(version) => {
              setMlModel({ ...mlModel, version });
            }}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Select.Single
            id="ml-model-pipeline-input"
            title="Pipeline"
            options={pipelinesQuery.data ?? []}
            optionBuilder={(pipeline) => pipeline?.name || ''}
            loading={pipelinesQuery.isLoading}
            label={<Input.Label label="Pipeline" />}
            value={selectedPipeline}
            setValue={setSelectedPipeline}
            readOnly={!canManageAnalyticRequests}
          />
          {canManageAnalyticRequests && (
            <div className="w-full flex flex-row justify-end items-center py-4 gap-2">
              {mlModel?.archived ? (
                <Button.Secondary
                  id="ml-model-details-restore-button"
                  label={'Restore'}
                  onClick={unarchiveMlModel}
                  loading={mlModelUnarchivePutMutation.isPending}
                />
              ) : (
                <Button.Danger
                  id="ml-model-details-archive-button"
                  label={'Archive'}
                  onClick={archiveMlModel}
                  loading={mlModelArchivePutMutation.isPending}
                />
              )}
              <Button.Primary
                id="ml-model-details-save-button"
                label={'Save'}
                onClick={updateMlModel}
                loading={mlModelPutMutation.isPending}
              />
            </div>
          )}
          <EntityDetailsSection
            items={[
              { label: 'ID', value: mlModel?.id },
              { label: 'File Name', value: mlModel?.fileName },
              { label: 'Model Type', value: mlModel?.modelType },
              {
                label: 'Model Architecture',
                value: mlModel?.modelArchitecture,
              },
              { label: 'Archived', value: mlModel?.archived ? 'Yes' : 'No' },
              {
                label: 'Training Date',
                value: formatDateAndTime(mlModel?.trainingDate),
              },

              {
                label: 'Created',
                value: formatDateAndTime(mlModel?.createDatetime),
              },
              {
                label: 'Last Updated',
                value: formatDateAndTime(mlModel?.updateDatetime),
              },
              { label: 'Updated By UUID', value: mlModel?.updatedById },
            ]}
          />
        </div>
      )}
    </div>
  );
};
