import {
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import { SidebarExpansionHeader } from '@agerpoint/component';

import { SaveCheckmark } from '../icon-buttons';

interface SidebarExpansionHeaderEditProps {
  onSave?: (name: string) => Promise<void>;
  onCancel: () => void;
  name: string;
  placeholder?: string;
}

export function SidebarExpansionHeaderEdit({
  onSave,
  onCancel,
  name,
  placeholder,
}: SidebarExpansionHeaderEditProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const nameInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const [currentName, setCurrentName] = useState<string>(name);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (isEditing && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [nameInputRef, isEditing]);

  useEffect(() => {
    setCurrentName(name);
  }, [name]);

  return (
    <SidebarExpansionHeader onCancel={!isEditing ? onCancel : undefined}>
      {!isEditing || !onSave ? (
        <h2
          title={name}
          className={`font-bold w-full border-b border-transparent truncate ${
            onSave ? 'hover:cursor-pointer hover:underline' : ''
          }`}
          onClick={onSave ? () => setIsEditing(true) : undefined}
        >
          {name}
        </h2>
      ) : (
        <>
          <input
            className="w-full mr-2 font-bold focus:outline-none border-b border-blue"
            value={currentName}
            ref={nameInputRef}
            onKeyDown={keyDownHandler}
            placeholder={placeholder}
            onChange={(event) => {
              setCurrentName(event.target.value);
            }}
          />
          <SaveCheckmark
            isSaving={isSaving}
            onSave={internalSaveName}
            title="Save"
            disabled={!currentName?.trim()}
          />
        </>
      )}
    </SidebarExpansionHeader>
  );

  async function internalSaveName() {
    if (onSave) {
      setIsSaving(true);
      try {
        await onSave(currentName);
        setIsEditing(false);
      } catch (error) {
        console.error(`Failed to save name ${currentName}`, error);
      } finally {
        setIsSaving(false);
      }
    }
  }
  function keyDownHandler(e: KeyboardEvent<HTMLInputElement>) {
    if (e.code === 'Escape') {
      setIsEditing(false);
      e.stopPropagation();
    } else if (e.code === 'Enter') {
      internalSaveName();
      e.stopPropagation();
    }
  }
}
