import {
  faArrowUpRightFromSquare,
  faCircleCheck,
  faEllipsisH,
  faFilterSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle as faSolidCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInfiniteQuery } from '@tanstack/react-query';
import { TileViewLayout } from 'libs/feature/src/tile-view/tile-view-types';
import { useMemo } from 'react';

import { APIClient, APIModels, formatDate } from '@agerpoint/api';
import { Button } from '@agerpoint/component';
import {
  CaptureThumbnail,
  ContextMenu,
  ContextMenuGroupProps,
  TileView,
} from '@agerpoint/feature';
import {
  getCapturesUnique3DModelList,
  isMobileTablet,
  useGlobalStore,
  useItemSelection,
} from '@agerpoint/utilities';

import { useAdminCapturesQueries } from '../admin-captures-queries';

interface AdminCapturesListTilesProps {
  capturesQuery: ReturnType<
    typeof useInfiniteQuery<APIClient.Capture[], Error>
  >;
  hasFiltersApplied: boolean;
  clearFilters: () => void;
  captureSelection: ReturnType<
    typeof useItemSelection<number, APIModels.Capture>
  >;
  filter: APIModels.CaptureFilter | undefined;

  rowOnClick?: (
    row: APIModels.Capture,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  contextMenuItems: (item: APIModels.Capture) => ContextMenuGroupProps[];
}

export const AdminCapturesListTiles = ({
  capturesQuery,
  captureSelection,
  contextMenuItems,
  rowOnClick,
  hasFiltersApplied,
  clearFilters,
  filter,
}: AdminCapturesListTilesProps) => {
  const isMobile = useMemo(() => isMobileTablet(), []);

  const { permissions } = useGlobalStore();

  const { organizationsLookupTable } = useAdminCapturesQueries();

  return (
    <TileView
      loading={
        capturesQuery.isLoading ||
        capturesQuery.isFetchingNextPage ||
        filter === undefined
      }
      pagination={{
        loadNextPage: async () => {
          if (
            !capturesQuery.hasNextPage ||
            capturesQuery.isFetchingNextPage ||
            capturesQuery.isLoading ||
            filter === undefined
          ) {
            return;
          }

          capturesQuery.fetchNextPage();
        },
      }}
      data={capturesQuery.data?.pages.flatMap((c) => c) ?? []}
      layout={TileViewLayout.SmallGrid}
      noResults={
        hasFiltersApplied
          ? {
              title: 'No matching captures',
              message: 'Adjust your filters and try again',
              action: clearFilters,
              actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
              actionLabel: 'Clear Filters',
            }
          : {
              title: 'No captures yet',
              message: 'Upload captures with the Agerpoint Capture App',
              action: () => {
                const a = document.createElement('a');
                a.href =
                  'https://apps.apple.com/sg/app/agerpoint-capture/id1629732675';
                a.target = '_blank';
                a.click();
              },
              actionIcon: <FontAwesomeIcon icon={faArrowUpRightFromSquare} />,
              actionLabel: 'Get the Capture App',
            }
      }
      error={
        capturesQuery.isError
          ? {
              title: 'There was a problem loading captures',
              message: 'Try refreshing the page',
              action: () => capturesQuery.refetch(),
            }
          : undefined
      }
      tileGenerator={(c, _, index) => {
        const isSelected = c.id ? captureSelection.isSelected(c.id) : false;

        const org = c.customerId
          ? organizationsLookupTable?.[c.customerId]
          : null;

        return (
          <div className="p-1">
            <div
              className="hover:cursor-pointer bg-white border-gray-200 border shadow rounded-lg"
              onClick={(e) => {
                if (e.defaultPrevented) {
                  return;
                }
                rowOnClick?.(c, e);
              }}
            >
              <div>
                <div
                  className={`bg-white border-gray-200 border shadow rounded-lg ring-accent ${
                    isSelected ? 'ring-2' : ''
                  }`}
                >
                  <div className="relative">
                    <CaptureThumbnail
                      size="big"
                      capture={c}
                      ldFlags={permissions}
                    />
                    <div className="absolute top-2 right-2 bg-white rounded-full shadow-md">
                      <Button.Icon
                        id={`captures-list-select-one-${index}`}
                        icon={isSelected ? faSolidCircleCheck : faCircleCheck}
                        iconColor={isSelected ? 'text-accent' : undefined}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!c.id) {
                            return;
                          }
                          if (e.shiftKey) {
                            captureSelection.addBulkSelectionUntilItem(c.id, c);
                          } else {
                            captureSelection.toggleSelection(c.id, c);
                          }
                        }}
                      />
                    </div>

                    <div className="absolute bottom-2 right-2">
                      <ContextMenu
                        dataTestId={`captures-list-context-menu-${index}`}
                        groups={contextMenuItems(c)}
                        icon={
                          <span
                            className={`w-8 rounded-full bg-white hover:bg-gray-50
                      flex items-center justify-center h-4`}
                          >
                            <FontAwesomeIcon
                              icon={faEllipsisH}
                              className="text-gray-700"
                            />
                          </span>
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`p-2 flex flex-col justify-between ${
                      isMobile ? 'h-20' : 'h-24'
                    }`}
                  >
                    <h5
                      className="mb-1 text-sm tracking-tight text-gray-900
                      truncate cursor-pointer"
                    >
                      {c.captureName}
                    </h5>
                    <div className="font-normal text-xs text-gray-700 flex flex-col">
                      <p className="flex justify-between">
                        <span>
                          {c.scanDatetime ? formatDate(c.scanDatetime) : null}
                        </span>
                        {isMobile && c.fileSize && (
                          <span>{`${c.fileSize} MB`}</span>
                        )}
                        {!isMobile && (
                          <span>
                            {getCapturesUnique3DModelList(c)?.join(', ')}
                          </span>
                        )}
                      </p>
                      {!isMobile && (
                        <p className="flex justify-between">
                          {c.fileSize && <span>{`${c.fileSize} MB`}</span>}
                          {c.numberImages && (
                            <span>{`${c.numberImages} images`}</span>
                          )}
                        </p>
                      )}
                      <p className="flex justify-start">
                        {(org?.customerDisplayName || org?.customerName) && (
                          <span>
                            {org.customerDisplayName ?? org.customerName}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};
