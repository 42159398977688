import {
  faCircle,
  faCircleCheck,
  faEdit,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { APIClient } from '@agerpoint/api';
import { ContextMenu, ContextMenuGroupProps } from '@agerpoint/feature';
import { JobTypeCategory } from '@agerpoint/types';

import { useOpsAnalyticRequestsQueries } from '../analytic-requests-queries';

export const AnalyticRequestsDetailsJobStatus = ({
  job,
  index,
}: {
  job: APIClient.AnalyticJob;
  index: number;
}) => {
  const {
    extractionJobStatusesQuery,
    extractionJobPutMutation,
    captureJobPutMutation,
    pipelineJobPutMutation,
    operationJobPutMutation,
    extractionJobByIdQuery,
    captureJobByIdQuery,
    pipelineJobByIdQuery,
    operationJobByIdQuery,
  } = useOpsAnalyticRequestsQueries({
    extractionJobId:
      job.entityName === JobTypeCategory.CaptureExtractionJob
        ? job.entityId
        : undefined,
    captureJobId:
      job.entityName === JobTypeCategory.CaptureJob ? job.entityId : undefined,
    pipelineJobId:
      job.entityName === JobTypeCategory.PipelineJob ? job.entityId : undefined,
    operationJobId:
      job.entityName === JobTypeCategory.OperationJob
        ? job.entityId
        : undefined,
  });

  const [selectedStatus, setSelectedStatus] =
    useState<APIClient.CaptureExtractionJobStatus>();

  useEffect(() => {
    if (!extractionJobStatusesQuery?.data?.length) {
      return;
    }

    setSelectedStatus(
      extractionJobStatusesQuery.data?.find(
        (s) => s.name?.toLowerCase() === job?.status?.toLowerCase()
      )
    );
  }, [extractionJobStatusesQuery.data, job?.status]);

  const updateAnalyticJobStatus = useCallback(
    (
      job: APIClient.AnalyticJob,
      status: APIClient.CaptureExtractionJobStatus
    ) => {
      if (job.entityName === JobTypeCategory.CaptureExtractionJob) {
        const data = cloneDeep(extractionJobByIdQuery.data);
        if (!job.entityId || !data) {
          return;
        }

        delete data.captureExtractionJobStatus;
        delete data.createDatetime;
        delete data.updateDatetime;
        delete data.completedDateTime;
        delete data.submittedDateTime;
        delete data.captureExtractionJobStatus;
        delete data.updatedById;
        data.captureExtractionJobStatusId = status.id;

        extractionJobPutMutation.mutate({
          id: job.entityId,
          data,
        });
      }

      if (job.entityName === JobTypeCategory.CaptureJob) {
        const data = cloneDeep(captureJobByIdQuery.data);
        if (!job.entityId || !data || !data.captureId) {
          return;
        }

        delete data.createDatetime;
        delete data.updateDatetime;
        delete data.completedDateTime;
        delete data.submittedDateTime;
        delete data.updatedById;
        data.status = status.name;

        captureJobPutMutation.mutate({
          jobId: job.entityId,
          captureId: data.captureId.toString(),
          data,
        });
      }

      if (job.entityName === JobTypeCategory.PipelineJob) {
        const data = cloneDeep(pipelineJobByIdQuery.data);
        if (!job.entityId || !data) {
          return;
        }

        delete data.createDatetime;
        delete data.updateDatetime;
        delete data.updatedById;
        delete data.mlModel;
        delete data.pipeline;
        delete data.mosaicEngine;
        data.status = status.name;

        pipelineJobPutMutation.mutate({
          id: job.entityId,
          data,
        });
      }

      if (job.entityName === JobTypeCategory.OperationJob) {
        const data = cloneDeep(operationJobByIdQuery.data);
        if (!job.entityId || !data) {
          return;
        }

        delete data.createDatetime;
        delete data.updateDatetime;
        delete data.updatedById;
        data.status = status.name;

        operationJobPutMutation.mutate({
          id: job.entityId,
          data,
        });
      }
    },
    [
      extractionJobByIdQuery.data,
      extractionJobPutMutation,
      captureJobByIdQuery.data,
      captureJobPutMutation,
      pipelineJobByIdQuery.data,
      pipelineJobPutMutation,
      operationJobByIdQuery.data,
      operationJobPutMutation,
    ]
  );

  const contextMenuItems = useCallback(
    (job: APIClient.AnalyticJob | undefined) => {
      const editStatusGroup: ContextMenuGroupProps = {
        label: 'Set Status',
        items:
          extractionJobStatusesQuery.data?.map((status) => ({
            label: status.name || '',
            icon: (
              <FontAwesomeIcon
                icon={
                  status.id === selectedStatus?.id ? faCircleCheck : faCircle
                }
              />
            ),
            onClick: () => {
              if (!job) {
                return;
              }
              updateAnalyticJobStatus(job, status);
            },
          })) ?? [],
      };

      return [editStatusGroup];
    },
    [extractionJobStatusesQuery.data, selectedStatus, updateAnalyticJobStatus]
  );

  return (
    <div className="flex flex-row gap-2 items-center">
      {job?.status}
      <ContextMenu
        icon={<FontAwesomeIcon icon={faEdit} className="text-gray-700" />}
        dataTestId={`analytic-request-status-context-menu-${index}`}
        groups={contextMenuItems(job)}
      />
    </div>
  );
};
