import { GeoJSON } from 'ol/format';

const InputValidators = {
  required:
    <T,>(
      fieldName: string,
      useFieldNameOnly?: boolean
    ): ((value: T) => Promise<string | undefined>) =>
    async (value: T) => {
      if (value === undefined || value === null) {
        return useFieldNameOnly ? fieldName : `${fieldName} is required`;
      }

      if (typeof value === 'string' && value.trim().length === 0) {
        return useFieldNameOnly ? fieldName : `${fieldName} is required`;
      }

      if (typeof value === 'number' && isNaN(value)) {
        return useFieldNameOnly ? fieldName : `${fieldName} is required`;
      }

      if (value instanceof File && value === undefined) {
        return useFieldNameOnly ? fieldName : `${fieldName} is required`;
      }

      if (Array.isArray(value) && value.length === 0) {
        return useFieldNameOnly ? fieldName : `${fieldName} is required`;
      }

      return undefined;
    },
  validJson: async (value: string) => {
    if (value.trim().length === 0) {
      return undefined;
    }
    try {
      JSON.parse(value);
      return undefined;
    } catch (e: any) {
      return `Invalid JSON: ${e.message}`;
    }
  },
  validGeoJSONFile: async (
    value: File | undefined
  ): Promise<string | undefined> => {
    if (value === undefined) {
      return 'Geometry File is required';
    }

    const extension = value.name.split('.').pop();
    const supportedExtensions = ['geojson', 'json'];

    if (!extension || !supportedExtensions.includes(extension.toLowerCase())) {
      return 'Invalid file extension';
    }

    try {
      const text = await value.text();
      const json = JSON.parse(text);
      const geoJson = new GeoJSON({});
      const proj = geoJson.readProjection(json);
      if (proj === null) {
        return 'GeoJSON is missing coordinate system information';
      }
      geoJson.readFeatures(json, {
        featureProjection: 'EPSG:3857',
      });
    } catch (e) {
      return 'Invalid GeoJSON content';
    }

    return undefined;
  },
  validExtensions:
    (
      extensions: string[]
    ): ((value: File | undefined) => Promise<string | undefined>) =>
    async (value: File | undefined) => {
      if (value === undefined) {
        return undefined;
      }

      const extension = value.name.split('.').pop();

      if (
        !extension ||
        !extensions
          .map((e) => e.toLowerCase())
          .includes(extension.toLowerCase())
      ) {
        return `Invalid file extension. Supported extensions: ${extensions
          .map((e) => `.${e}`)
          .join(', ')}`;
      }

      return undefined;
    },
  validPassword: async (value: string) => {
    const trimmedValue = value.trim();

    const error = `Password must be at least 12 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character`;
    // minimum 12 characters
    if (trimmedValue.length < 12) {
      return error;
    }

    // at least one uppercase letter
    if (!/[A-Z]/.test(trimmedValue)) {
      return error;
    }

    // at least one lowercase letter
    if (!/[a-z]/.test(trimmedValue)) {
      return error;
    }

    // at least one number
    if (!/[0-9]/.test(trimmedValue)) {
      return error;
    }

    // at least one special character
    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(trimmedValue)) {
      return error;
    }

    return undefined;
  },

  validEmail: async (value: string) => {
    const trimmedValue = value.trim();

    const error = 'Invalid Email Address';

    const input = document.createElement('input');

    input.type = 'email';
    input.value = trimmedValue;

    if (typeof input.checkValidity === 'function' && !input.checkValidity()) {
      return error;
    } else if (
      typeof input.checkValidity !== 'function' &&
      !/\S+@\S+\.\S+/.test(value)
    ) {
      return error;
    }

    return undefined;
  },
};

export { InputValidators };
