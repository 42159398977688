// Todo; this should probably be merged with graduated state since they overlap a lot

import { CategorizedBin, Gradient } from '@agerpoint/types';
import { Action } from './graduated-state';

export enum ActionKind {
  SetAttribute = 'setAttribute',
  SetGradient = 'setGradient',
  SetOpacity = 'setOpacity',
  SetBins = 'setBins',
}

export interface State {
  attribute?: string;
  gradient: Gradient;
  opacity: number;
  bins?: CategorizedBin[];
}

export const initialState = {
  attribute: undefined,
  gradient: Gradient.RdYlGn,
  opacity: 1,
  bins: undefined,
};

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionKind.SetAttribute:
      return { ...state, attribute: action.payload };
    case ActionKind.SetGradient:
      return { ...state, gradient: action.payload };
    case ActionKind.SetOpacity:
      return { ...state, opacity: action.payload };
    case ActionKind.SetBins:
      return { ...state, bins: action.payload };
    default:
      return state;
  }
}

export const createInitialState = (state: State) => {
  return state;
};
