import { GroupIcon } from '@agerpoint/types';
import {
  Extent,
  extend as extendExtent,
  createEmpty as createEmptyExtent,
} from 'ol/extent';

interface ZIndexable {
  zIndex: number;
}

/**
 * Given an array of items that is already ordered so that the first
 * item should have the highest `zIndex`, assigns actual z-index values
 * for each item.
 * @param items the items to assign zIndex values to
 * @returns the same array that was passed to `items`
 */
export function assignZIndexes<T extends ZIndexable>(
  items: Array<T>
): Array<T> {
  return items.map((item, index) => ({
    ...item,
    zIndex: items.length - index,
  }));
}

/**
 * Default green in Hexcolor format.
 *
 * An alternative is to add `resolveConfig`, but that comes with consequences.
 * Due to the small number of hexcolor strings in source, this property is best
 * at this time.
 *
 * @see https://tailwindcss.com/docs/configuration#referencing-in-java-script
 */
export const DEFAULT_GREEN = '#176754';
export const DEFAULT_GROUP_ICON: GroupIcon = {
  name: 'Leaf',
  color: DEFAULT_GREEN,
};
export function combineExtents(extents: Extent[]): Extent {
  let result = createEmptyExtent();
  for (const extent of extents) {
    result = extendExtent(result, extent);
  }

  return result;
}
