import { LibraryLayer } from '@agerpoint/types';
import { createContext, ReactNode, useRef, useState } from 'react';
import {
  DragDropContext,
  DragStart,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';

export const multiSelectTo = (
  layers: LibraryLayer[] | undefined,
  selectedTaskIds: string[],
  layerId: string
): string[] | null => {
  if (layers === undefined) {
    return null;
  }
  // Nothing already selected
  if (!selectedTaskIds.length) {
    return [layerId];
  }

  const indexOfFirst: number = layers
    .map((l) => getLayersUniqueId(l))
    .indexOf(layerId);
  const indexOfLast: number = layers
    .map((l) => getLayersUniqueId(l))
    .indexOf(selectedTaskIds[selectedTaskIds.length - 1]);

  // multi selecting in the same column
  // need to select everything between the last index and the current index inclusive

  // nothing to do here
  if (indexOfFirst === indexOfLast) {
    return null;
  }

  const isSelectingForwards: boolean = indexOfFirst > indexOfLast;
  const start: number = isSelectingForwards ? indexOfLast : indexOfFirst;
  const end: number = isSelectingForwards ? indexOfFirst : indexOfLast;

  const inBetween: string[] = layers
    .map((l) => getLayersUniqueId(l))
    .slice(start, end + 1);

  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  const toAdd: string[] = inBetween.filter((layerId: string): boolean => {
    // if already selected: then no need to select it again
    if (selectedTaskIds.includes(layerId)) {
      return false;
    }
    return true;
  });

  const sorted: string[] = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined: string[] = [...selectedTaskIds, ...sorted];

  return combined;
};

export const AgerDragDropContext =
  createContext<AgerDragDropContextProviderProps>({});

export type AgerDragDropEventAction = 'DRAG_START' | 'DRAG_END';

export interface AgerDragDropEvent {
  action: AgerDragDropEventAction;
  provided?: ResponderProvided | undefined;
  payload: DropResult | DragStart;
}

export interface AgerDragDropContextProviderProps {
  addEvent?: ({
    droppableId,
    callback,
    greedy,
  }: {
    droppableId: string;
    callback: (event: AgerDragDropEvent) => void;
    greedy?: boolean;
  }) => void;
  removeEvent?: ({ droppableId }: { droppableId: string }) => void;
  emitCustomEvent?: ({
    droppableId,
    event,
  }: {
    droppableId: string;
    event: AgerDragDropEvent;
  }) => void;
}

export const AgerDragDropContextWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const eventsMap = useRef(
    new Map<string, (event: AgerDragDropEvent) => void>()
  );
  const greedyEventsMap = useRef(
    new Map<string, (event: AgerDragDropEvent) => void>()
  );

  const addEvent = ({
    droppableId,
    callback,
    greedy = false,
  }: {
    droppableId: string;
    callback: (event: AgerDragDropEvent) => void;
    greedy?: boolean;
  }) => {
    if (greedy) {
      greedyEventsMap.current.set(droppableId, callback);
    } else {
      eventsMap.current.set(droppableId, callback);
    }
  };

  const emitEvent = (
    droppableId: string | undefined,
    event: AgerDragDropEvent
  ) => {
    if (droppableId && eventsMap.current.has(droppableId)) {
      eventsMap.current.get(droppableId)?.(event);
    }
    greedyEventsMap.current.forEach((value, key, _) => {
      value(event);
    });
  };

  const emitCustomEvent = ({
    droppableId,
    event,
  }: {
    droppableId: string;
    event: AgerDragDropEvent;
  }) => {
    if (droppableId && eventsMap.current.has(droppableId)) {
      eventsMap.current.get(droppableId)?.(event);
    }
  };

  const removeEvent = ({ droppableId }: { droppableId: string }) => {
    eventsMap.current.delete(droppableId);
    greedyEventsMap.current.delete(droppableId);
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    emitEvent(result.destination?.droppableId, {
      action: 'DRAG_END',
      provided,
      payload: result,
    });
  };

  const handleDragStart = (initial: DragStart, provided: ResponderProvided) => {
    emitEvent(initial.source.droppableId, {
      action: 'DRAG_START',
      provided,
      payload: initial,
    });
  };

  return (
    <AgerDragDropContext.Provider
      value={{ addEvent, removeEvent, emitCustomEvent }}
    >
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        {children}
      </DragDropContext>
    </AgerDragDropContext.Provider>
  );
};

export const getLayersUniqueId = (layer: LibraryLayer): string => {
  return `${layer.entityId}-${layer.layerTypeId}`;
};
