export enum EventBusNames {
  ImageCarouselImageClicked = 'ImageCarouselImageClicked',
  CaptureCameraPositionShowAllClicked = 'CaptureCameraPositionShowAllClicked',
  CaptureCameraPositionHideAllClicked = 'CaptureCameraPositionHideAllClicked',
  CaptureObjectPositionChanged = 'CaptureObjectPositionChanged',
  PointCloudLoaded = 'PointCloudLoaded',
  CaptureObjectClicked = 'CaptureObjectClicked',
  UpdateClosestImage = 'UpdateClosestImage',
  Point3dLocationMarkerClicked = 'Point3dLocationMarkerClicked',
  Sprite3dLocationMarkerClicked = 'Sprite3dLocationMarkerClicked',
  AnnotationGeometryUpdate = 'AnnotationGeometryUpdate',
  Annotation2dPointLocation = 'Annotation2dPointLocation',
  Annotation2dGeometryUpdate = 'Annotation2dGeometryUpdate',
  Annotation2dMultiPointLocation = 'Annotation2dMultiPointLocation',
  AnnotationsSelectedObjectChanged = 'AnnotationsSelectedObjectChanged',
  SidebarAnnotationClicked = 'SidebarAnnotationClicked',
}

export interface EventBusData {
  detail: any;
}

export interface EventBus {
  lookup: { [key in EventBusNames]?: any };
  on(
    event: EventBusNames,
    callback: (e: CustomEvent) => void,
    allowMultiple?: boolean
  ): string;
  dispatch(event: EventBusNames, data: EventBusData): void;
  remove(event: string, callback: any, id?: string): void;
}
