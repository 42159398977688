/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CaptureJob } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureJobs = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureJob[]>(
    { url: `/api/CaptureJobs`, method: 'GET', signal },
    options
  );
};

export const getGetCaptureJobsQueryKey = () => {
  return [`/api/CaptureJobs`] as const;
};

export const getGetCaptureJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureJobs>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureJobs>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureJobsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCaptureJobs>>> = ({
    signal,
  }) => getCaptureJobs(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureJobs>>
>;
export type GetCaptureJobsQueryError = ErrorType<void>;

export const useGetCaptureJobs = <
  TData = Awaited<ReturnType<typeof getCaptureJobs>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureJobs>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureJobsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureJobById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureJob>(
    {
      url: `/api/CaptureJobs/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureJobByIdQueryKey = (id: number) => {
  return [`/api/CaptureJobs/${id}`] as const;
};

export const getGetCaptureJobByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureJobById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureJobById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureJobByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureJobById>>
  > = ({ signal }) => getCaptureJobById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureJobById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureJobByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureJobById>>
>;
export type GetCaptureJobByIdQueryError = ErrorType<void>;

export const useGetCaptureJobById = <
  TData = Awaited<ReturnType<typeof getCaptureJobById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureJobById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureJobByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPlyDownloadUrlByCaptureJobId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureJob>(
    {
      url: `/api/CaptureJobs/${encodeURIComponent(String(id))}/Ply`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPlyDownloadUrlByCaptureJobIdQueryKey = (id: number) => {
  return [`/api/CaptureJobs/${id}/Ply`] as const;
};

export const getGetPlyDownloadUrlByCaptureJobIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlyDownloadUrlByCaptureJobId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlyDownloadUrlByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlyDownloadUrlByCaptureJobIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlyDownloadUrlByCaptureJobId>>
  > = ({ signal }) =>
    getPlyDownloadUrlByCaptureJobId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlyDownloadUrlByCaptureJobId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlyDownloadUrlByCaptureJobIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlyDownloadUrlByCaptureJobId>>
>;
export type GetPlyDownloadUrlByCaptureJobIdQueryError = ErrorType<void>;

export const useGetPlyDownloadUrlByCaptureJobId = <
  TData = Awaited<ReturnType<typeof getPlyDownloadUrlByCaptureJobId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlyDownloadUrlByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlyDownloadUrlByCaptureJobIdQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const geVideoPreviewDownloadUrlByCaptureJobId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureJob>(
    {
      url: `/api/CaptureJobs/${encodeURIComponent(String(id))}/VideoPreview`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGeVideoPreviewDownloadUrlByCaptureJobIdQueryKey = (
  id: number
) => {
  return [`/api/CaptureJobs/${id}/VideoPreview`] as const;
};

export const getGeVideoPreviewDownloadUrlByCaptureJobIdQueryOptions = <
  TData = Awaited<ReturnType<typeof geVideoPreviewDownloadUrlByCaptureJobId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof geVideoPreviewDownloadUrlByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGeVideoPreviewDownloadUrlByCaptureJobIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof geVideoPreviewDownloadUrlByCaptureJobId>>
  > = ({ signal }) =>
    geVideoPreviewDownloadUrlByCaptureJobId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof geVideoPreviewDownloadUrlByCaptureJobId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GeVideoPreviewDownloadUrlByCaptureJobIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof geVideoPreviewDownloadUrlByCaptureJobId>>
>;
export type GeVideoPreviewDownloadUrlByCaptureJobIdQueryError = ErrorType<void>;

export const useGeVideoPreviewDownloadUrlByCaptureJobId = <
  TData = Awaited<ReturnType<typeof geVideoPreviewDownloadUrlByCaptureJobId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof geVideoPreviewDownloadUrlByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeVideoPreviewDownloadUrlByCaptureJobIdQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureJobsByCaptureId = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureJob[]>(
    {
      url: `/api/CaptureJobs/Capture/${encodeURIComponent(String(captureId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureJobsByCaptureIdQueryKey = (captureId: number) => {
  return [`/api/CaptureJobs/Capture/${captureId}`] as const;
};

export const getGetCaptureJobsByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureJobsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureJobsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureJobsByCaptureIdQueryKey(captureId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureJobsByCaptureId>>
  > = ({ signal }) =>
    getCaptureJobsByCaptureId(captureId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureJobsByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureJobsByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureJobsByCaptureId>>
>;
export type GetCaptureJobsByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureJobsByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureJobsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureJobsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureJobsByCaptureIdQueryOptions(
    captureId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureJobsByAnalyticRequestId = (
  analyticRequestId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureJob[]>(
    {
      url: `/api/CaptureJobs/AnalyticRequest/${encodeURIComponent(
        String(analyticRequestId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureJobsByAnalyticRequestIdQueryKey = (
  analyticRequestId: number
) => {
  return [`/api/CaptureJobs/AnalyticRequest/${analyticRequestId}`] as const;
};

export const getGetCaptureJobsByAnalyticRequestIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureJobsByAnalyticRequestId>>,
  TError = ErrorType<void>
>(
  analyticRequestId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureJobsByAnalyticRequestId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureJobsByAnalyticRequestIdQueryKey(analyticRequestId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureJobsByAnalyticRequestId>>
  > = ({ signal }) =>
    getCaptureJobsByAnalyticRequestId(
      analyticRequestId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!analyticRequestId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureJobsByAnalyticRequestId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureJobsByAnalyticRequestIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureJobsByAnalyticRequestId>>
>;
export type GetCaptureJobsByAnalyticRequestIdQueryError = ErrorType<void>;

export const useGetCaptureJobsByAnalyticRequestId = <
  TData = Awaited<ReturnType<typeof getCaptureJobsByAnalyticRequestId>>,
  TError = ErrorType<void>
>(
  analyticRequestId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureJobsByAnalyticRequestId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureJobsByAnalyticRequestIdQueryOptions(
    analyticRequestId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureJobById = (
  captureId: string,
  jobId: number,
  captureJob: BodyType<CaptureJob>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureJob>(
    {
      url: `/api/CaptureJobs/Capture/${encodeURIComponent(
        String(captureId)
      )}/Job/${encodeURIComponent(String(jobId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureJob,
    },
    options
  );
};

export const getPutCaptureJobByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureJobById>>,
    TError,
    { captureId: string; jobId: number; data: BodyType<CaptureJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureJobById>>,
  TError,
  { captureId: string; jobId: number; data: BodyType<CaptureJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureJobById>>,
    { captureId: string; jobId: number; data: BodyType<CaptureJob> }
  > = (props) => {
    const { captureId, jobId, data } = props ?? {};

    return putCaptureJobById(captureId, jobId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureJobByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureJobById>>
>;
export type PutCaptureJobByIdMutationBody = BodyType<CaptureJob>;
export type PutCaptureJobByIdMutationError = ErrorType<void>;

export const usePutCaptureJobById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureJobById>>,
    TError,
    { captureId: string; jobId: number; data: BodyType<CaptureJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureJobById>>,
  TError,
  { captureId: string; jobId: number; data: BodyType<CaptureJob> },
  TContext
> => {
  const mutationOptions = getPutCaptureJobByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateCaptureJobStatusByJobUuid = (
  jobUuid: string,
  captureJob: BodyType<CaptureJob>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureJob>(
    {
      url: `/api/CaptureJobs/Uuid/${encodeURIComponent(
        String(jobUuid)
      )}/Status`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureJob,
    },
    options
  );
};

export const getUpdateCaptureJobStatusByJobUuidMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCaptureJobStatusByJobUuid>>,
    TError,
    { jobUuid: string; data: BodyType<CaptureJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCaptureJobStatusByJobUuid>>,
  TError,
  { jobUuid: string; data: BodyType<CaptureJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCaptureJobStatusByJobUuid>>,
    { jobUuid: string; data: BodyType<CaptureJob> }
  > = (props) => {
    const { jobUuid, data } = props ?? {};

    return updateCaptureJobStatusByJobUuid(jobUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCaptureJobStatusByJobUuidMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCaptureJobStatusByJobUuid>>
>;
export type UpdateCaptureJobStatusByJobUuidMutationBody = BodyType<CaptureJob>;
export type UpdateCaptureJobStatusByJobUuidMutationError = ErrorType<void>;

export const useUpdateCaptureJobStatusByJobUuid = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCaptureJobStatusByJobUuid>>,
    TError,
    { jobUuid: string; data: BodyType<CaptureJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCaptureJobStatusByJobUuid>>,
  TError,
  { jobUuid: string; data: BodyType<CaptureJob> },
  TContext
> => {
  const mutationOptions =
    getUpdateCaptureJobStatusByJobUuidMutationOptions(options);

  return useMutation(mutationOptions);
};
export const reSubmitCaptureJobToPipeline = (
  captureJobId: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureJob>(
    {
      url: `/api/CaptureJobs/${encodeURIComponent(
        String(captureJobId)
      )}/Resubmit`,
      method: 'POST',
    },
    options
  );
};

export const getReSubmitCaptureJobToPipelineMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reSubmitCaptureJobToPipeline>>,
    TError,
    { captureJobId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reSubmitCaptureJobToPipeline>>,
  TError,
  { captureJobId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reSubmitCaptureJobToPipeline>>,
    { captureJobId: number }
  > = (props) => {
    const { captureJobId } = props ?? {};

    return reSubmitCaptureJobToPipeline(captureJobId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReSubmitCaptureJobToPipelineMutationResult = NonNullable<
  Awaited<ReturnType<typeof reSubmitCaptureJobToPipeline>>
>;

export type ReSubmitCaptureJobToPipelineMutationError = ErrorType<void>;

export const useReSubmitCaptureJobToPipeline = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reSubmitCaptureJobToPipeline>>,
    TError,
    { captureJobId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof reSubmitCaptureJobToPipeline>>,
  TError,
  { captureJobId: number },
  TContext
> => {
  const mutationOptions =
    getReSubmitCaptureJobToPipelineMutationOptions(options);

  return useMutation(mutationOptions);
};
export const submitCaptureJobToPipeline = (
  captureJob: BodyType<CaptureJob>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<boolean>(
    {
      url: `/api/CaptureJobs/Submit`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureJob,
    },
    options
  );
};

export const getSubmitCaptureJobToPipelineMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitCaptureJobToPipeline>>,
    TError,
    { data: BodyType<CaptureJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitCaptureJobToPipeline>>,
  TError,
  { data: BodyType<CaptureJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitCaptureJobToPipeline>>,
    { data: BodyType<CaptureJob> }
  > = (props) => {
    const { data } = props ?? {};

    return submitCaptureJobToPipeline(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitCaptureJobToPipelineMutationResult = NonNullable<
  Awaited<ReturnType<typeof submitCaptureJobToPipeline>>
>;
export type SubmitCaptureJobToPipelineMutationBody = BodyType<CaptureJob>;
export type SubmitCaptureJobToPipelineMutationError = ErrorType<void>;

export const useSubmitCaptureJobToPipeline = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitCaptureJobToPipeline>>,
    TError,
    { data: BodyType<CaptureJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof submitCaptureJobToPipeline>>,
  TError,
  { data: BodyType<CaptureJob> },
  TContext
> => {
  const mutationOptions = getSubmitCaptureJobToPipelineMutationOptions(options);

  return useMutation(mutationOptions);
};
