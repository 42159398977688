import { CloudInput } from '../input';
import { InputIdContext } from '../input-id-context';

interface IInputSelectInline<T> {
  /**
   * The unique identifier for the input element.
   */
  id: string;
  /**
   * The error message to display.
   */
  error?: string;
  /**
   * The label to display.
   */
  label?: string;
  /**
   * Determines whether the input is required.
   */
  required?: boolean;
  /**
   * The options to display in the select input.
   */
  options: T[];
  /**
   * A function that builds the display string for each option.
   */
  optionBuilder: (option: T) => React.ReactNode;
  /**
   * The currently selected value.
   */
  value: T;
  /**
   * A callback function to set the selected value.
   */
  setValue: (value: T) => void;
  /**
   * Specifies whether the component is disabled.
   */
  disabled?: boolean;
  /**
   * A custom comparison function to determine if an option is selected.
   */
  compareFn?: (a: T, b: T) => boolean;

  /**
   * Specifies whether the component is read-only.
   */
  readOnly?: boolean;
}

function InputSelectInline<T>({
  id,
  error,
  label,
  required,
  options,
  optionBuilder,
  value,
  setValue,
  disabled = false,
  compareFn,
  readOnly,
}: IInputSelectInline<T>) {
  return (
    <InputIdContext.Provider value={id}>
      <div
        className={`relative select-none w-full ${
          disabled ? 'pointer-events-none opacity-50' : ''
        }`.trim()}
      >
        <div className="flex flex-col">
          {label && <CloudInput.Label label={label} required={required} />}
          <div className="flex flex-row border border-gray-border rounded-lg overflow-hidden">
            {options.map((o, index) => {
              let isSelected = false;
              if (value !== undefined) {
                if (compareFn) {
                  isSelected = compareFn(value, o);
                } else {
                  isSelected = value === o;
                }
              }

              return (
                <div
                  key={index}
                  className={`h-10 flex items-center justify-center ${
                    isSelected
                      ? 'text-primary bg-primary bg-opacity-10 z-1'
                      : 'text-gray-iconPrimary cursor-pointer hover-overlay-5 z-0'
                  }`}
                  onClick={() => {
                    if (readOnly) {
                      return;
                    }
                    return setValue(o);
                  }}
                >
                  {optionBuilder(o)}
                </div>
              );
            })}
          </div>
          {error && <CloudInput.Error error={error} />}
        </div>
      </div>
    </InputIdContext.Provider>
  );
}

export { InputSelectInline };
