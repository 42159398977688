import { faRemove, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';

import { Button, ToastComponent } from '@agerpoint/component';
import { useGlobalStore, usePrevious } from '@agerpoint/utilities';

export const ToastsComponent = () => {
  const {
    toasts,
    showingToastPanel,
    actions: { removeToast, showToastPanel, hideToastPanel },
  } = useGlobalStore();

  const prevToastsLength = usePrevious(toasts.length);
  const tagsTimestampsEncodings = useMemo(() => {
    // map toasts to a set of tags and timestamps and json encode it
    const tagsTimestamps = toasts.reduce((acc, toast) => {
      acc[toast.tags?.join(',') ?? ''] = toast.timestamp.getTime();
      return acc;
    }, {} as Record<string, number>);

    return JSON.stringify(tagsTimestamps);
  }, [toasts]);

  useEffect(() => {
    if ((prevToastsLength ?? 0) <= toasts.length) {
      showToastPanel(5000);
    }
  }, [tagsTimestampsEncodings]);

  const sortedToasts = useMemo(
    () => toasts.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime()),
    [toasts]
  );

  return (
    <div
      className={`absolute w-80 p-1 pt-16 right-0 overflow-x-hidden overflow-y-auto pointer-events-none`}
      style={{ zIndex: 999999, maxHeight: '100vh' }}
      onPointerEnter={() => {
        showToastPanel();
      }}
      onPointerLeave={() => {
        showToastPanel(2000);
      }}
    >
      <div
        className="transition-transform transform mr-2"
        style={{
          transform: showingToastPanel
            ? 'translateX(0)'
            : `translateX(100%) translateX(+16px)`,
        }}
      >
        {toasts.length > 0 && (
          <div className="flex flex-row justify-end mb-2">
            <div className="bg-gray-50 rounded pointer-events-auto">
              <Button.Small
                id="clear-all-toasts"
                onClick={(e) => {
                  e.stopPropagation();
                  toasts.forEach((toast) => removeToast(toast.id));
                  hideToastPanel();
                }}
                label="Clear All"
                icon={faTrash}
              />
            </div>
          </div>
        )}
        {sortedToasts.map((toast) => {
          return (
            <ToastComponent
              key={toast.id}
              {...toast}
              removeToast={removeToast}
            />
          );
        })}
      </div>
    </div>
  );
};
