import { faBell } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

import { ToastProps } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const ToastsBell = () => {
  const {
    toasts: storeToasts,
    showingToastPanel,
    actions: { showToastPanel, hideToastPanel },
  } = useGlobalStore();

  const toasts = useMemo(() => {
    return storeToasts.filter((t) => t.type !== 'neutral');
  }, [storeToasts]);

  const hasErrors: boolean = useMemo(() => {
    return toasts.findIndex((t: ToastProps) => t.type === 'error') !== -1;
  }, [toasts]);

  if (toasts.length === 0) {
    return null;
  }

  return (
    <div
      className={`w-8 h-8 rounded transition-colors hover:bg-green-100 p-0.5 flex items-center justify-center 
      hover:cursor-pointer relative
      ${showingToastPanel ? 'bg-green-100' : ''}
      `}
      onClick={() => {
        if (showingToastPanel) {
          hideToastPanel();
        } else {
          showToastPanel();
        }
      }}
    >
      <div
        className={`absolute p-0.5 h-3.5 text-xs rounded-full
      text-white flex items-center justify-center
        ${hasErrors ? 'bg-red' : 'bg-green'}
      `}
        style={{
          top: '0.125rem',
          right: '0.125rem',
        }}
      >
        <span className="z-30">{toasts.length}</span>
        {hasErrors && (
          <div className="absolute w-full h-full bg-red animate-ping z-20 rounded-full"></div>
        )}
      </div>
      <FontAwesomeIcon icon={faBell} className="text-xl" aria-hidden="true" />
    </div>
  );
};
