import { createContext, useContext } from 'react';

export interface IToast {
  id?: string;
  title: React.ReactNode;
  message?: React.ReactNode;
  type: ToastType;
}

export type ToastType = 'success' | 'error' | 'info' | 'warning';

interface IToastContext {
  add: (toast: IToast) => void;
}
export const ToastContext = createContext<IToastContext>({
  add: () => {
    throw new Error('ToastContext not provided');
  },
});

export const useViteToasts = () => useContext(ToastContext);
