import { CSSProperties, useContext, useEffect, useMemo, useState } from 'react';

import { useLongTouch } from '@agerpoint/utilities';

import { APLoader } from '../../ap-loader';
import { CloudDatatableItemData, StyleContext } from '../datatable.types';
import { Cell } from './cell';

export const Row = <T,>({
  index,
  style,
  data: itemData,
}: {
  index: number;
  style: CSSProperties;
  data: CloudDatatableItemData<T>;
}) => {
  const { data, columns, cellOnClick, loading, extraRow, rowOnLongTouch } =
    itemData;
  const d = data[index];
  const [isRowHovered, setIsRowHovered] = useState(false);
  const styleContext = useContext(StyleContext);
  const [rowRef, setRowRef] = useState<HTMLDivElement | null>(null);

  const longTouchEvents = useLongTouch({
    callback: () => {
      rowOnLongTouch?.(d);
    },
  });

  useEffect(() => {
    const row = rowRef;
    if (!row) {
      return;
    }

    const onEnter = () => {
      setIsRowHovered(true);
    };

    const onLeave = () => {
      setIsRowHovered(false);
    };

    row.addEventListener('pointerenter', onEnter);
    row.addEventListener('pointerleave', onLeave);

    return () => {
      row.removeEventListener('pointerenter', onEnter);
      row.removeEventListener('pointerleave', onLeave);
    };
  }, [rowRef]);

  const rowStyle = useMemo(
    () => styleContext?.row?.(d, index),
    [styleContext, d, index]
  );

  if (index === data.length) {
    if (loading) {
      return (
        <div style={{ ...style }} className="flex-center" ref={setRowRef}>
          <APLoader.CircleNotch />
        </div>
      );
    }

    return (
      <div style={{ ...style }} className="size-full" ref={setRowRef}>
        {extraRow}
      </div>
    );
  }

  return (
    <div
      ref={setRowRef}
      style={style}
      tabIndex={-1}
      {...longTouchEvents(undefined)}
      className={`flex flex-row flex-center ${rowStyle}`}
    >
      {columns.map((c, i) => {
        return (
          <Cell
            key={i}
            data={d}
            column={c}
            index={0}
            isRowHovered={isRowHovered}
            cellOnClick={cellOnClick}
          />
        );
      })}
    </div>
  );
};
