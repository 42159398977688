import { useQueryClient } from '@tanstack/react-query';

import { APIClient, APIUtils } from '../..';

export const usePut = () => {
  const queryClient = useQueryClient();
  return APIClient.usePutCaptureObjectById({
    mutation: {
      onSettled: () => {
        APIUtils.invalidateEntity({
          queryClient,
          entityKey: APIUtils.QueryKey.captureObjects,
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateCachedEntity<APIClient.CaptureObject>({
          queryClient,
          entityKey: APIUtils.QueryKey.captureObjects,
          accessor: 'id',
          data: variables.data,
        });
      },
      onError: (error) => {
        console.error('usePutCaptureObject', error);
      },
    },
  });
};

interface IusePost {
  captureId?: number;
  captureJobId?: number;
}

export const usePost = ({ captureId, captureJobId }: IusePost) => {
  const queryClient = useQueryClient();
  return APIClient.usePostCaptureObject({
    mutation: {
      onSettled: () => {
        APIUtils.invalidateEntity({
          queryClient,
          entityKey: APIUtils.QueryKey.captureObjects,
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.addEntityToCache<APIClient.CaptureObject>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(captureJobId) },
            APIUtils.QueryKey.captureObjects,
          ],
          data: variables.data,
        });
      },
      onError: (error) => {
        console.error('usePostCaptureObject', error);
      },
    },
  });
};

interface IuseDelete {
  captureId?: number;
  captureJobId?: number;
}

export const useDelete = ({ captureId, captureJobId }: IuseDelete) => {
  const queryClient = useQueryClient();
  return APIClient.useDeleteCaptureObject({
    mutation: {
      onSettled: () => {
        APIUtils.invalidateEntity({
          queryClient,
          entityKey: APIUtils.QueryKey.captureObjects,
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.removeEntityFromCache<APIClient.CaptureObject>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(captureJobId) },
            APIUtils.QueryKey.captureObjects,
          ],
          id: variables.id,
          accessor: 'id',
        });
      },
      onError: (error) => {
        console.error('useDeleteCaptureObject', error);
      },
    },
  });
};
