import {
  IconDefinition,
  faChevronRight,
  faCircleNotch,
  faFileContract,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import { useGetReportImageById } from '@agerpoint/api';
import { ImageViewer } from '@agerpoint/component';

import { IThreeDViewerControllerGroup } from '../../three-d-wrapper';
import { QaqcTools } from '../qaqc-3d-tools/qaqc-3d-tools';
import { QaqcCaptureAttributesList } from '../qaqc-capture-attributes-list/qaqc-capture-attributes-list';
import { QaqcCaptureClassification } from '../qaqc-capture-classification/qaqc-capture-classification';
import { QaqcCaptureObjectsList } from '../qaqc-capture-objects-list/qaqc-capture-objects';
import { QaqcCaptureVideo } from '../qaqc-capture-video/qaqc-capture-video';

interface QaqcBottomBarsProps {
  extId?: string;
  viewerController?: IThreeDViewerControllerGroup;
}
type tabs = 'objects' | 'attributes' | 'video' | '' | 'classification';

export const QaqcBottomBars = ({
  extId,
  viewerController,
}: QaqcBottomBarsProps) => {
  const [expandedObjectsPanel, setExpandedObjectsPanel] = useState(false);
  const [expandedReportImage, setExpandedReportImage] = useState(false);
  const [loadingReportData, setLoadingReportData] = useState(true);
  const [imageBlob, setImageBlob] = useState<Blob>();
  const [selectedTab, setSelectedTab] = useState<tabs>('');

  const {
    data: reportData,
    refetch: refetchReport,
    cancel: cancelGetReportData,
    error: reportDataError,
  } = useGetReportImageById({
    id: extId ? +extId : NaN,
  });

  useEffect(() => {
    if (!reportData) {
      return;
    }
    const doAsync = async () => {
      try {
        const blob = await (reportData as any).blob();
        setImageBlob(blob);
        setLoadingReportData(false);
      } catch (error) {
        console.error(error);
      }
    };
    doAsync();
  }, [reportData]);

  useEffect(() => {
    setLoadingReportData(true);
    refetchReport();
    return () => {
      cancelGetReportData();
    };
  }, [extId]);

  const tabClickHandler = (tab: tabs) => {
    if (tab === selectedTab) {
      setExpandedObjectsPanel((prev) => !prev);
      setSelectedTab('');
    } else {
      setSelectedTab(tab);
      setExpandedObjectsPanel(true);
    }
  };

  return (
    <div
      className={`text-white absolute inset-x-0 bottom-0
    pl-2 pr-4 flex flex-row pointer-events-none justify-between items-end`}
      style={{
        zIndex: '100',
      }}
    >
      <div className="flex flex-col items-start">
        <div className="flex flex-row">
          <div
            className={`bg-gray-900 rounded-t-lg p-2 gap-2 flex flex-row pointer-events-auto
        items-center justify-between text-sm hover:text-gray-400 cursor-pointer ${
          selectedTab === 'objects' ? '' : 'text-gray-400 underlined'
        }`}
            onClick={() => {
              tabClickHandler('objects');
            }}
          >
            Objects
            <FontAwesomeIcon
              icon={faChevronRight}
              className={`text-xs transform
           ${
             expandedObjectsPanel && selectedTab === 'objects'
               ? 'rotate-90'
               : ''
           }`}
            />
          </div>
          <div
            className={`bg-gray-900 rounded-t-lg p-2 gap-2 flex flex-row pointer-events-auto
        items-center justify-between text-sm hover:text-gray-400 cursor-pointer ${
          selectedTab === 'attributes' ? '' : 'text-gray-400 underlined'
        }`}
            onClick={() => {
              tabClickHandler('attributes');
            }}
          >
            Attributes
            <FontAwesomeIcon
              icon={faChevronRight}
              className={`text-xs transform
           ${
             expandedObjectsPanel && selectedTab === 'attributes'
               ? 'rotate-90'
               : ''
           }`}
            />
          </div>
          <div
            className={`bg-gray-900 rounded-t-lg p-2 gap-2 flex flex-row pointer-events-auto
        items-center justify-between text-sm hover:text-gray-400 cursor-pointer ${
          selectedTab === 'video' ? '' : 'text-gray-400 underlined'
        }`}
            onClick={() => {
              tabClickHandler('video');
            }}
          >
            Video
            <FontAwesomeIcon
              icon={faChevronRight}
              className={`text-xs transform
           ${
             expandedObjectsPanel && selectedTab === 'video' ? 'rotate-90' : ''
           }`}
            />
          </div>
          <div
            className={`bg-gray-900 rounded-t-lg p-2 gap-2 flex flex-row pointer-events-auto
        items-center justify-between text-sm hover:text-gray-400 cursor-pointer ${
          selectedTab === 'classification' ? '' : 'text-gray-400 underlined'
        }`}
            onClick={() => {
              tabClickHandler('classification');
            }}
          >
            Classification
            <FontAwesomeIcon
              icon={faChevronRight}
              className={`text-xs transform
           ${
             expandedObjectsPanel && selectedTab === 'classification'
               ? 'rotate-90'
               : ''
           }`}
            />
          </div>
        </div>
        <div
          className={`pointer-events-auto overflow-hidden`}
          style={{
            height: expandedObjectsPanel ? '400px' : undefined,
            maxHeight: !expandedObjectsPanel ? '0px' : undefined,
          }}
        >
          <div className="bg-gray-900 rounded-tr-lg h-full p-1 w-96">
            <QaqcTabBody
              tab={selectedTab}
              extId={extId}
              viewerController={viewerController}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-2 pb-2">
        {!reportDataError && (
          <QaqcButton
            icon={faFileContract}
            loading={loadingReportData}
            onClick={() => {
              setExpandedReportImage(true);
            }}
          />
        )}
        <QaqcTools viewerController={viewerController} size="default" />
      </div>
      {imageBlob && (
        <ImageViewer
          open={expandedReportImage}
          handleCloseViewer={() => {
            setExpandedReportImage(false);
          }}
          downloadBlob={imageBlob}
          showDownloadButton={true}
        >
          <img src={URL.createObjectURL(imageBlob)} alt={`Chart ${extId}`} />
        </ImageViewer>
      )}
    </div>
  );
};

const QaqcTabBody = ({
  tab,
  extId,
  viewerController,
}: {
  tab: tabs;
  extId: string | undefined;
  viewerController: IThreeDViewerControllerGroup | undefined;
}) => {
  switch (tab) {
    case 'attributes':
      return <QaqcCaptureAttributesList />;
    case 'video':
      return <QaqcCaptureVideo />;
    case 'objects':
      return (
        <QaqcCaptureObjectsList
          extId={extId}
          viewerController={viewerController}
        />
      );
    case 'classification':
      return <QaqcCaptureClassification />;
    default:
      return null;
  }
};

export const QaqcButton = ({
  icon,
  onClick,
  loading = false,
}: {
  icon: IconDefinition;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  loading?: boolean;
}) => {
  return (
    <div
      className={`flex flex-row gap-2 pointer-events-auto w-8 h-8 bg-gray-900
    text-white rounded-lg justify-center items-center shadow p-1.5 border border-white ${
      loading ? '' : 'hover:text-gray-600 cursor-pointer'
    }`}
      onClick={loading ? undefined : onClick}
    >
      <FontAwesomeIcon
        icon={loading ? faCircleNotch : icon}
        className="w-full h-full"
        spin={loading}
      />
    </div>
  );
};
