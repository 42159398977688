import { faQuestion } from '@fortawesome/pro-light-svg-icons';
import {
  faCircle,
  faCircleCheck,
  faCircleNotch,
  faEdit,
  faFilterSlash,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as faSolidCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import {
  APIClient,
  APIModels,
  AnalyticRequest,
  formatDate,
  formatDateAndTime,
} from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import {
  ContextMenu,
  ContextMenuGroupProps,
  Datatable,
  dataTableAgerStyle,
} from '@agerpoint/feature';
import {
  AnalyticRequestStatus,
  AnalyticRequestStatusObject,
  analyticRequestStatusList,
} from '@agerpoint/types';
import {
  convertDateToUtcBeginningOfDay,
  convertDateToUtcEndOfDay,
  getIconByStatus,
  useIsViteApp,
  useItemSelection,
  useQueryState,
  uuidRegex,
} from '@agerpoint/utilities';

import { useOpsAnalyticRequestsQueries } from './analytic-requests-queries';

export const AnalyticRequestsList = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<APIModels.AnalyticRequestFilter>();
  const [jobStatusLookup, setJobStatusLookup] = useState<
    Record<number, string>
  >({});

  const isViteApp = useIsViteApp();

  const {
    organizationsLookupTable,
    organizationsQuery,
    analyticRequestsQuery,
    analyticsLookupTable,
    analyticRequestPutMutation,
    analyticRequestBulkStatusPutMutation,
    analyticRequestBulkAvailabilityPutMutation,
    hasAnalyticRequestManagementPermission,
    analyticsQuery,
  } = useOpsAnalyticRequestsQueries({ filter });

  const analyticRequests = useMemo(() => {
    return analyticRequestsQuery.data?.pages.flatMap((x) => x) ?? [];
  }, [analyticRequestsQuery.data]);

  const analyticRequestsSelection = useItemSelection<
    number,
    APIModels.AnalyticRequest
  >({
    dependencies: [analyticRequests.length, { filter }],
    idField: 'id',
    items: analyticRequests,
  });

  const [nameUUIDFilter, setNameUUIDFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (a) => a,
    toUrlParam: (a) => a.trim(),
  });

  const [selectedFilterOrganizations, setSelectedFilterOrganizations] =
    useQueryState<APIModels.Customer[]>({
      paramName: 'organizations',
      initialValue: [],
      fromUrlParam: (a) => {
        const splitted = a?.split(',').map((x) => Number(x));
        const filtered = organizationsQuery.data?.filter((x) => {
          return splitted?.includes(x.id as number) ?? false;
        });
        return filtered ?? [];
      },
      toUrlParam: (a) => {
        return a.map((x) => x.id).join(',');
      },
      retryInitWhen: organizationsQuery.isSuccess,
    });

  const [archivedFilter, setArchivedFilter] = useQueryState<boolean>({
    paramName: 'archived',
    initialValue: false,
    fromUrlParam: (a) => {
      return true;
    },
    toUrlParam: (a) => {
      return a ? 'yes' : '';
    },
  });

  const [selectedFilterStatus, setSelectedFilterStatus] = useQueryState<
    AnalyticRequestStatusObject | undefined
  >({
    paramName: 'status',
    initialValue: undefined,
    toUrlParam: (a) => {
      if (a?.name) {
        return a.name.toString().toLowerCase();
      }
      return '';
    },
    fromUrlParam: (a) => {
      return analyticRequestStatusList.find(
        (s) => s.name.toLowerCase() === a.toLowerCase()
      );
    },
  });

  const [selectedFilterAnalyticDate, setSelectedFilterAnalyticDate] =
    useQueryState<[Date | undefined, Date | undefined]>({
      paramName: 'analytic_date',
      initialValue: [undefined, undefined],
      toUrlParam: (v) => {
        if (v?.[0] && v?.[1]) {
          const start = formatDate(v[0].toString());
          const end = formatDate(v[1].toString());

          return `${start}-${end}`;
        }
        return '';
      },
      fromUrlParam: (v) => {
        const dates = v.split('-');
        if (dates.length !== 2) {
          return [undefined, undefined];
        }

        const [start, end] = dates;

        const startDate = new Date(start);
        const endDate = new Date(end);

        return [startDate, endDate];
      },
    });

  const [selectedFilterAnalyticId, setSelectedFilterAnalyticId] = useQueryState<
    APIModels.Analytic | undefined
  >({
    paramName: 'analytic_id',
    initialValue: undefined,
    fromUrlParam: (a) => {
      const id = Number(a);
      return analyticsQuery.data?.find((x) => x.id === id);
    },
    toUrlParam: (a) => {
      return (
        analyticsQuery.data?.find((x) => x.id === a?.id)?.id?.toString() ?? ''
      );
    },
    retryInitWhen: analyticsQuery.isSuccess,
  });

  useEffect(() => {
    const isNameAnUUID =
      nameUUIDFilter.trim().length > 0 && uuidRegex.test(nameUUIDFilter.trim());
    setFilter((prev) => ({
      ...prev,
      orderBy: 'createDatetime',
      orderAscending: false,
      captureName: isNameAnUUID ? '' : nameUUIDFilter.trim(),
      captureUuid: isNameAnUUID ? nameUUIDFilter.trim() : undefined,
    }));
  }, []);

  useEffect(() => {
    let customerIds: number[] | undefined = undefined;

    if (selectedFilterOrganizations.length > 0) {
      customerIds = selectedFilterOrganizations.map((x) => x.id as number);
    }

    const analyticId = selectedFilterAnalyticId?.id;

    setFilter((prev) => ({
      ...prev,
      customerIds: customerIds,
      status: selectedFilterStatus?.name,
      analyticId: analyticId,
      archived: archivedFilter,
    }));
  }, [
    selectedFilterStatus,
    selectedFilterOrganizations,
    archivedFilter,
    selectedFilterAnalyticId,
  ]);

  useDebouncyEffect(
    () => {
      const isNameAnUUID =
        nameUUIDFilter.trim().length > 0 &&
        uuidRegex.test(nameUUIDFilter.trim());

      setFilter((prev) => ({
        ...prev,
        captureName: isNameAnUUID ? '' : nameUUIDFilter.trim(),
        captureUuid: isNameAnUUID ? nameUUIDFilter.trim() : undefined,
      }));
    },
    500,
    [nameUUIDFilter]
  );

  useEffect(() => {
    setFilter((prev) => {
      const adjustedStartAnalyticDate = convertDateToUtcBeginningOfDay(
        selectedFilterAnalyticDate?.[0]
      );
      const adjustedEndAnalyticDate = convertDateToUtcEndOfDay(
        selectedFilterAnalyticDate?.[1]
      );
      return {
        ...prev,
        startCreatedDatetime: adjustedStartAnalyticDate?.toISOString(),
        endCreatedDatetime: adjustedEndAnalyticDate?.toISOString(),
      };
    });
  }, [selectedFilterAnalyticDate]);

  useEffect(() => {
    setFilter((prev) => {
      return {
        ...prev,
        analyticId: selectedFilterAnalyticId?.id ?? undefined,
      };
    });
  }, [selectedFilterAnalyticId]);

  const contextMenuItems = useCallback(
    (item: AnalyticRequest) => {
      const editStatusGroup: ContextMenuGroupProps = {
        label: 'Set Status',
        items: [
          {
            label: 'Requested',
            icon: (
              <FontAwesomeIcon
                icon={
                  item.status === AnalyticRequestStatus.REQUESTED
                    ? faCircleCheck
                    : faCircle
                }
              />
            ),
            onClick: () => {
              if (!item.id) {
                return;
              }

              analyticRequestPutMutation.mutate({
                id: item.id,
                data: {
                  ...item,
                  status: AnalyticRequestStatus.REQUESTED,
                },
              });
            },
          },
          {
            label: 'Processing',
            icon: (
              <FontAwesomeIcon
                icon={
                  item.status === AnalyticRequestStatus.PROCESSING
                    ? faCircleCheck
                    : faCircle
                }
              />
            ),
            onClick: () => {
              if (!item.id) {
                return;
              }

              analyticRequestPutMutation.mutate({
                id: item.id,
                data: {
                  ...item,
                  status: AnalyticRequestStatus.PROCESSING,
                },
              });
            },
          },
          {
            label: 'Complete',
            icon: (
              <FontAwesomeIcon
                icon={
                  item.status === AnalyticRequestStatus.COMPLETE
                    ? faCircleCheck
                    : faCircle
                }
              />
            ),
            onClick: () => {
              if (!item.id) {
                return;
              }

              analyticRequestPutMutation.mutate({
                id: item.id,
                data: {
                  ...item,
                  status: AnalyticRequestStatus.COMPLETE,
                },
              });
            },
          },
          {
            label: 'Cancelled',
            icon: (
              <FontAwesomeIcon
                icon={
                  item.status === AnalyticRequestStatus.CANCELLED
                    ? faCircleCheck
                    : faCircle
                }
              />
            ),
            onClick: () => {
              if (!item.id) {
                return;
              }

              analyticRequestPutMutation.mutate({
                id: item.id,
                data: {
                  ...item,
                  status: AnalyticRequestStatus.CANCELLED,
                },
              });
            },
          },
          {
            label: 'Failed',
            icon: (
              <FontAwesomeIcon
                icon={
                  item.status === AnalyticRequestStatus.FAILED
                    ? faCircleCheck
                    : faCircle
                }
              />
            ),
            onClick: () => {
              if (!item.id) {
                return;
              }

              analyticRequestPutMutation.mutate({
                id: item.id,
                data: {
                  ...item,
                  status: AnalyticRequestStatus.FAILED,
                },
              });
            },
          },
        ],
      };

      return [editStatusGroup];
    },
    [analyticRequestPutMutation]
  );

  const hasFiltersApplied = useMemo(
    () =>
      !!(
        (filter?.customerIds?.length ?? 0) > 0 ||
        filter?.status !== undefined ||
        filter?.startCreatedDatetime ||
        filter?.endCreatedDatetime ||
        filter?.analyticId ||
        filter?.captureName ||
        filter?.captureUuid
      ),
    [filter]
  );

  const clearFilters = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setSelectedFilterOrganizations([]);
    setSelectedFilterStatus(undefined);
    setSelectedFilterAnalyticDate([undefined, undefined]);
    setSelectedFilterAnalyticId(undefined);
    setNameUUIDFilter('');

    searchParams.delete('organizations');
    searchParams.delete('status');
    searchParams.delete('analytic_date');
    searchParams.delete('analytic_id');
    searchParams.delete('name');

    navigate(
      {
        pathname: isViteApp
          ? '/app/admin/operations/analytic-requests'
          : '/ops/analytics',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, []);

  const jobStatusMouseEnter = useCallback(
    async (id: number) => {
      // get all the jobs for this id
      // if they are all complete, show complete
      // else show pending

      if (!id) {
        return;
      }
      if (jobStatusLookup[id]) {
        return;
      }
      const res = await APIClient.getAnalyticRequestJobsByAnalyticRequestId(id);
      const allComplete = res.every(
        (job) => job?.status?.toLowerCase() === 'completed'
      );
      setJobStatusLookup((prev) => ({
        ...prev,
        [Number(id)]: allComplete
          ? `Complete (${res.length})`
          : `Pending (${res.length})`,
      }));
    },
    [jobStatusLookup]
  );

  const requestedIcon = useMemo(() => {
    const i = getIconByStatus(AnalyticRequestStatus.REQUESTED);
    return i ? <FontAwesomeIcon icon={i} /> : null;
  }, []);
  const processingIcon = useMemo(() => {
    const i = getIconByStatus(AnalyticRequestStatus.PROCESSING);
    return i ? <FontAwesomeIcon icon={i} spin /> : null;
  }, []);
  const completeIcon = useMemo(() => {
    const i = getIconByStatus(AnalyticRequestStatus.COMPLETE);
    return i ? <FontAwesomeIcon icon={i} /> : null;
  }, []);
  const cancelledIcon = useMemo(() => {
    const i = getIconByStatus(AnalyticRequestStatus.CANCELLED);
    return i ? <FontAwesomeIcon icon={i} /> : null;
  }, []);
  const failedIcon = useMemo(() => {
    const i = getIconByStatus(AnalyticRequestStatus.FAILED);
    return i ? <FontAwesomeIcon icon={i} /> : null;
  }, []);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="flex flex-row justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">Analytic Requests</h1>
        {hasAnalyticRequestManagementPermission &&
          analyticRequestsSelection.hasSelectedItems && (
            <div className="flex flex-row gap-2 items-center">
              <ContextMenu
                disabled={analyticRequestBulkStatusPutMutation.isPending}
                icon={
                  <Button.Primary
                    id="analytic-requests-list-change-status-button"
                    label="Change Status"
                    loading={analyticRequestBulkStatusPutMutation.isPending}
                  />
                }
                groups={[
                  {
                    items: [
                      {
                        label: 'Requested',
                        icon: requestedIcon,
                        onClick: () => {
                          analyticRequestBulkStatusPutMutation.mutate(
                            {
                              analyticRequests: cloneDeep(
                                analyticRequestsSelection.getSelectionArray()
                              ),
                              newStatus: AnalyticRequestStatus.REQUESTED,
                            },
                            {
                              onSuccess: () => {
                                analyticRequestsSelection.clearSelection();
                              },
                            }
                          );
                        },
                      },
                      {
                        label: 'Processing',
                        icon: processingIcon,
                        onClick: () => {
                          analyticRequestBulkStatusPutMutation.mutate(
                            {
                              analyticRequests: cloneDeep(
                                analyticRequestsSelection.getSelectionArray()
                              ),
                              newStatus: AnalyticRequestStatus.PROCESSING,
                            },
                            {
                              onSuccess: () => {
                                analyticRequestsSelection.clearSelection();
                              },
                            }
                          );
                        },
                      },
                      {
                        label: 'Complete',
                        icon: completeIcon,
                        onClick: () => {
                          analyticRequestBulkStatusPutMutation.mutate(
                            {
                              analyticRequests: cloneDeep(
                                analyticRequestsSelection.getSelectionArray()
                              ),
                              newStatus: AnalyticRequestStatus.COMPLETE,
                            },
                            {
                              onSuccess: () => {
                                analyticRequestsSelection.clearSelection();
                              },
                            }
                          );
                        },
                      },
                      {
                        label: 'Cancelled',
                        icon: cancelledIcon,
                        onClick: () => {
                          analyticRequestBulkStatusPutMutation.mutate(
                            {
                              analyticRequests: cloneDeep(
                                analyticRequestsSelection.getSelectionArray()
                              ),
                              newStatus: AnalyticRequestStatus.CANCELLED,
                            },
                            {
                              onSuccess: () => {
                                analyticRequestsSelection.clearSelection();
                              },
                            }
                          );
                        },
                      },
                      {
                        label: 'Failed',
                        icon: failedIcon,
                        onClick: () => {
                          analyticRequestBulkStatusPutMutation.mutate(
                            {
                              analyticRequests: cloneDeep(
                                analyticRequestsSelection.getSelectionArray()
                              ),
                              newStatus: AnalyticRequestStatus.FAILED,
                            },
                            {
                              onSuccess: () => {
                                analyticRequestsSelection.clearSelection();
                              },
                            }
                          );
                        },
                      },
                    ],
                  },
                ]}
              />
              {archivedFilter ? (
                <Button.Secondary
                  id="analytic-requests-list-restore-selected"
                  label="Restore"
                  loading={analyticRequestBulkAvailabilityPutMutation.isPending}
                  onClick={() => {
                    const confirm = window.confirm(
                      `Are you sure you want to restore selected analytic requests?`
                    );

                    if (!confirm) {
                      return;
                    }

                    analyticRequestBulkAvailabilityPutMutation.mutate(
                      {
                        analyticRequests: cloneDeep(
                          analyticRequestsSelection.getSelectionArray()
                        ),
                        archived: false,
                      },
                      {
                        onSuccess: () => {
                          analyticRequestsSelection.clearSelection();
                        },
                      }
                    );
                  }}
                />
              ) : (
                <Button.Danger
                  id="analytic-requests-list-archive-selected"
                  label="Archive"
                  loading={analyticRequestBulkAvailabilityPutMutation.isPending}
                  onClick={() => {
                    const confirm = window.confirm(
                      `Are you sure you want to archive selected analytic requests?`
                    );

                    if (!confirm) {
                      return;
                    }

                    analyticRequestBulkAvailabilityPutMutation.mutate(
                      {
                        analyticRequests: cloneDeep(
                          analyticRequestsSelection.getSelectionArray()
                        ),
                        archived: true,
                      },
                      {
                        onSuccess: () => {
                          analyticRequestsSelection.clearSelection();
                        },
                      }
                    );
                  }}
                />
              )}

              <Button.Secondary
                id="clear-selection-button"
                onClick={() => {
                  analyticRequestsSelection.clearSelection();
                }}
                label={`Clear Selection (${analyticRequestsSelection.selectionSize})`}
                icon={faXmark}
              />
            </div>
          )}
      </div>
      <div className="flex flex-row px-4 gap-2 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="analytic-requests-name-filter"
            value={nameUUIDFilter}
            setValue={setNameUUIDFilter}
            placeholder="Capture Name or UUID"
          />
        </div>
        <Input.Select.Multi
          id="analytic-requests-organization-filter"
          options={organizationsQuery.data ?? []}
          optionBuilder={(o) =>
            o.customerDisplayName ?? o.customerName ?? 'Unknown'
          }
          loading={organizationsQuery.isLoading}
          title="Organizations"
          value={selectedFilterOrganizations}
          setValue={setSelectedFilterOrganizations}
          placeholder="Organizations"
        />
        <Input.Select.Single
          id="analytic-requests-status-filter"
          options={analyticRequestStatusList}
          optionBuilder={(o) => o.name}
          title="Status"
          value={selectedFilterStatus}
          setValue={setSelectedFilterStatus}
          placeholder="Status"
        />
        <Input.Select.Single
          id="analytic-requests-analytic-id-filter"
          title="Analytic"
          value={selectedFilterAnalyticId}
          setValue={setSelectedFilterAnalyticId}
          placeholder="Analytic"
          loading={analyticsQuery.isLoading}
          options={analyticsQuery.data ?? []}
          optionBuilder={(o) => o?.analyticName ?? 'Unknown'}
        />
        <Input.Select.Inline
          id="analytic-requests-availability-filter"
          options={[false, true]}
          optionBuilder={(o) => (o ? 'Archived' : 'Active')}
          value={archivedFilter}
          setValue={setArchivedFilter}
        />
        <div className="w-48">
          <Input.Date.Range
            id="capture-date-filter"
            placeholder="Analytic Date Range"
            value={selectedFilterAnalyticDate}
            setValue={setSelectedFilterAnalyticDate}
          />
        </div>
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="h-full p-4">
        <Datatable
          id="analytic-requests-datatable"
          data={analyticRequests}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching analytic requests',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No analytic requests received',
                }
          }
          error={
            analyticRequestsQuery.isError
              ? {
                  title: 'There was a problem loading analytic requests',
                  message: 'Try refreshing the page',
                  action: () => analyticRequestsQuery.refetch(),
                }
              : undefined
          }
          columns={[
            {
              label: 'ID',
              value: (row) => row.id,
              flex: 0.5,
              name: 'id',
            },
            {
              label: 'Analytic ID',
              value: (row) => {
                if (!row.customerAnalytic?.analyticId) {
                  return null;
                }

                if (!analyticsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const analytic =
                  analyticsLookupTable?.[
                    row.customerAnalytic?.analyticId ?? ''
                  ];

                return (
                  <span title={analytic?.id?.toString()}>
                    {analytic?.id ?? null}
                  </span>
                );
              },
              flex: 0.5,
              name: 'analytic-id',
            },
            {
              label: 'Analytic',
              value: (row) => {
                if (!row.customerAnalytic?.analyticId) {
                  return null;
                }

                if (!analyticsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const analytic =
                  analyticsLookupTable?.[
                    row.customerAnalytic?.analyticId ?? ''
                  ];

                return (
                  <span title={analytic?.analyticName?.toString()}>
                    {analytic?.analyticName ?? null}
                  </span>
                );
              },
              flex: 2,
              name: 'analytic-name',
            },

            {
              label: 'Status',
              value: (row, index) => {
                return (
                  <div className="flex flex-row gap-2 items-center">
                    {row.status}
                    {hasAnalyticRequestManagementPermission && (
                      <ContextMenu
                        key={row.id}
                        icon={
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="text-gray-700"
                          />
                        }
                        dataTestId={`analytic-request-status-context-menu-${index}`}
                        groups={contextMenuItems(row)}
                      />
                    )}
                  </div>
                );
              },
              flex: 1,
            },
            {
              label: 'Jobs',
              value: (row) => {
                return row?.id && jobStatusLookup[row.id] ? (
                  jobStatusLookup[row.id]
                ) : (
                  <div
                    onMouseEnter={() => {
                      jobStatusMouseEnter(row?.id || NaN);
                    }}
                    className="p-1"
                  >
                    <Button.Icon
                      icon={faQuestion}
                      id={`job-status-${row.id}`}
                    />
                  </div>
                );
              },
            },
            {
              label: 'Capture Name',
              value: (row) => {
                return (
                  <span title={row?.captures?.[0]?.captureName || ''}>
                    {row?.captures?.[0]?.captureName}
                  </span>
                );
              },
            },

            {
              label: 'Created By',
              value: (row) => {
                if (!row.createdByProfile) {
                  return null;
                }

                return [
                  row?.createdByProfile?.firstName,
                  row?.createdByProfile?.lastName,
                ]
                  .filter((c) => !!c)
                  .join(' ')
                  .trim();
              },
              name: 'createdBy',
            },
            {
              label: 'Organization',
              value: (row) => {
                if (!row.customerAnalytic?.customerId) {
                  return null;
                }

                if (!organizationsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const org =
                  organizationsLookupTable?.[
                    row.customerAnalytic?.customerId ?? ''
                  ];

                return org?.customerDisplayName ?? org?.customerName ?? null;
              },
              name: 'organization',
            },
            // {
            //   label: 'Assigned To',
            //   value: (row) => row.assignedToId,
            //   name: 'assignedTo',
            // },
            {
              label: 'Cost',
              value: (row) => `${row.analyticCreditCost} credits`,
              name: 'cost',
            },
            {
              label: 'Created',
              value: (row) => formatDateAndTime(row.createDatetime) || '',
              name: 'created',
            },

            {
              label: (
                <div className="p-1">
                  {analyticRequestsSelection.isEverythingSelected ? (
                    <Button.Small
                      id="analytic-requests-list-deselect-all"
                      label="Deselect All"
                      onClick={() => {
                        analyticRequestsSelection.toggleSelectionEverything();
                      }}
                      icon={faSolidCircleCheck}
                      iconColor="text-accent"
                    />
                  ) : (
                    <Button.Small
                      id="analytic-requests-list-select-all"
                      label="Select All"
                      onClick={() => {
                        analyticRequestsSelection.toggleSelectionEverything();
                      }}
                      icon={faCircleCheck}
                    />
                  )}
                </div>
              ),
              value: function Select(row, index) {
                const isSelected = row.id
                  ? analyticRequestsSelection.isSelected(row.id)
                  : false;

                return (
                  <div className="p-1">
                    <Button.Icon
                      id={`select-button-${index}`}
                      icon={isSelected ? faSolidCircleCheck : faCircleCheck}
                      onClick={(e) => {
                        if (!row.id) {
                          return;
                        }
                        if (e.shiftKey) {
                          analyticRequestsSelection.addBulkSelectionUntilItem(
                            row.id,
                            row
                          );
                        } else {
                          analyticRequestsSelection.toggleSelection(
                            row.id,
                            row
                          );
                        }
                      }}
                      iconColor={isSelected ? 'text-accent' : undefined}
                    />
                  </div>
                );
              },
              style: { columnWrapperStyle: 'flex justify-center' },
            },
          ]}
          cellOnClick={() => {
            return (analyticRequest) => {
              navigate(`${analyticRequest.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          loading={
            analyticRequestsQuery.isLoading ||
            analyticRequestsQuery.isFetchingNextPage ||
            filter === undefined
          }
          rowHeight={50}
          style={{ ...dataTableAgerStyle, tableMinWidth: 1300 }}
          pagination={{
            loadNextPage: () => {
              if (
                analyticRequestsQuery.isLoading ||
                analyticRequestsQuery.isFetchingNextPage ||
                !analyticRequestsQuery.hasNextPage ||
                filter === undefined
              ) {
                return;
              }

              analyticRequestsQuery.fetchNextPage();
            },
          }}
        />
      </div>
    </div>
  );
};
