import { Group, PerspectiveCamera, Scene, Vector3 } from 'three';

import {
  Annotation3dPoints,
  ColorsThreeD,
  HexColor,
  IMultiPoint3d,
} from '@agerpoint/types';
import { AnnotationGroupName } from '@agerpoint/utilities';

import { AnnotationBase } from '../annotations.base';
import { GeometryBase } from '../geometry.base';
import { Point3d } from './point-3d';

export class MultiPoint3d extends GeometryBase implements IMultiPoint3d {
  points: Point3d[] = [];
  public type: Annotation3dPoints;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    id: string,
    type: Annotation3dPoints,
    color: HexColor | ColorsThreeD = ColorsThreeD.Cyan,
    visibility: boolean = true,
    name: string = ''
  ) {
    super(scene, perspectiveCamera, false);
    this.uniqueId = id;
    this.type = type;
    this.color = color;
    this.name = name;
    this.visibility = visibility;
  }

  get isVisible() {
    return this.visibility;
  }

  show() {
    this.updateVisibility(true);
  }

  hide() {
    this.updateVisibility(false);
  }

  updatePositions(newPoints: Vector3[]) {
    if (!newPoints?.length) return;
    const group = this.scene.getObjectByName(AnnotationGroupName) as Group;
    if (this.points?.length) {
      this.points.forEach((point) => {
        group.remove(point.object);
        point.dispose();
      });
    }
    this.points = newPoints.map((position, index) => {
      const point = new Point3d(
        this.scene,
        this.perspectiveCamera,
        `${this.uniqueId}`,
        position,
        this.type,
        false,
        this.color,
        true
      );
      return point;
    });
  }

  updateVisibility(isVisible: boolean) {
    this.visibility = isVisible;
    this.points.forEach((point) => {
      point.updateVisibility(isVisible);
    });
  }

  updateType(type: Annotation3dPoints) {
    this.type = type;
    this.points.forEach((point) => {
      point.updateType(type);
    });
  }

  updateColor(color: ColorsThreeD | HexColor) {
    this.color = color;
    this.points.forEach((point) => {
      point.updateColor(color);
    });
    this.notifyListeners();
  }

  zoomTo() {
    // TODO: Implement zoomTo method
  }

  dispose() {
    this.points.forEach((point) => {
      point.dispose();
    });
  }

  delete(): void {
    AnnotationBase.annoStore.removeMultiPoint3dById(this.uniqueId);
    this.annoMgr.deleteCapObj(this.uniqueId);
  }

  disposeAndDelete() {
    this.dispose();
    this.delete();
    this.notifyListeners();
  }

  highlight(): void {
    this.points.forEach((point) => {
      point.highlight();
    });
  }
}
