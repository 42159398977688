import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import { Input } from '@agerpoint/component';
import {
  CaptureMaterialClassifications,
  CaptureMaterialClassificationsLabels,
  CaptureMaterialColorsLookup,
  ClassificationObject,
  EffectNames,
  MaterialType,
} from '@agerpoint/types';
import { Options } from '@agerpoint/types';
import {
  cvtRgbaArrToRgbaStr,
  cvtRgbaPctToStdRgba,
  cvtRgbaStrToRgbaArr,
  cvtStdRgbaToRgbaPct,
  materialList,
  useGlobalStore,
} from '@agerpoint/utilities';

import { CustomPicker } from '../../color-picker/color-picker';

export const StandAloneViewerPointStyle = () => {
  const {
    standAloneViewer: {
      actions: { setPointStyle },
    },
  } = useGlobalStore();

  const [selectedMaterial, setSelectedMaterial] = useState<Options>({
    value: MaterialType.RGBA,
    name: 'RGBA',
  });

  const materialListChange = (option: Options) => {
    if (!option.value) return;
    if (option.value !== selectedMaterial?.value) {
      setSelectedMaterial(option);
    }
  };

  useEffect(() => {
    if (!selectedMaterial) return;
    setPointStyle(selectedMaterial.value as MaterialType);
  }, [selectedMaterial]);

  return (
    <ViewerPointStyle
      selectedMaterial={selectedMaterial}
      materialListChange={materialListChange}
    />
  );
};

export const ViewerPointStyle = ({
  selectedMaterial,
  materialListChange,
}: any) => {
  const {
    capturesViewer: { captureMaterialClassificationColors },
  } = useGlobalStore();

  return (
    <div className="pl-2 py-2">
      <div>Point Style</div>
      {materialList.map((mat) => {
        return (
          <div className="flex items-center pb-1" key={mat.value}>
            <div className="flex items-center justify-between min-h-[25px] flex-row w-full">
              <div className="mx-1">
                <input
                  key={mat.value}
                  type="radio"
                  id={mat.value}
                  checked={selectedMaterial.value === mat.value}
                  value={mat.value}
                  onChange={() => {
                    materialListChange(mat);
                  }}
                  className={`w-4 h-4 text-green bg-gray-100 border-gray-300 hover:ring-green hover:ring-2 cursor-pointer`}
                />
              </div>

              <div className={`ml-2 px-1 w-full truncate`}>
                <label
                  className="py-0 px-1 text-sm rounded border border-transparent truncate cursor-pointer"
                  htmlFor={mat.value}
                >
                  {mat.name}
                </label>
              </div>
            </div>
          </div>
        );
      })}
      <div className="pt-2">Classification</div>
      <div className="flex flex-col items-center pb-1">
        {Object.keys(
          captureMaterialClassificationColors as Partial<
            Record<CaptureMaterialClassifications, ClassificationObject>
          >
        ).map((key: unknown) => {
          const k = key as CaptureMaterialClassifications;
          return <MaterialColorList colorId={k} key={k} />;
        })}
      </div>
    </div>
  );
};

const MaterialColorList = ({
  colorId,
}: {
  colorId: CaptureMaterialClassifications;
}) => {
  const {
    capturesViewer: {
      actions: { setCaptureMaterialClassificationColor },
      captureMaterialClassificationColors,
    },
    actions: { dispatchEffect },
  } = useGlobalStore();
  const [showPicker, setShowPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('rgba(0, 0, 0, 1)');

  const [classificationObject, setClassificationObject] = useState<{
    name: string;
    id: string;
    color: number[];
    material: CaptureMaterialClassifications;
  }>({
    name: '',
    id: '',
    color: [0, 0, 0, 1],
    material: CaptureMaterialClassifications.Unclassified,
  });

  const saveColor = (color: string) => {
    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  useEffect(() => {
    const colorKey = Number(colorId) as CaptureMaterialClassifications;
    const classObj = {
      name: Object.values(CaptureMaterialClassificationsLabels)[colorKey],
      id: colorId?.toString(),
      material: colorKey,
      color: CaptureMaterialColorsLookup[colorKey],
    };
    setClassificationObject(classObj);
    const bgColor = cvtRgbaArrToRgbaStr(
      cvtRgbaPctToStdRgba(classObj?.color || [0, 0, 0, 0])
    );
    setBackgroundColor(bgColor);
  }, [colorId]);

  return (
    <div className="flex items-center justify-between w-full text-sm text-gray-800">
      <span className="mx-1">
        <Input.Checkbox
          id={`checkbox-${classificationObject.material}-${classificationObject.id}`}
          setValue={() => {
            setCaptureMaterialClassificationColor(
              colorId,
              captureMaterialClassificationColors[colorId]?.color || [
                0, 0, 0, 0,
              ],
              !captureMaterialClassificationColors[colorId]?.visible
            );
            dispatchEffect(
              EffectNames.POINT_CLOUD_MATERIAL_VISIBILITY_TOGGLED,
              {
                classification: classificationObject.material,
              }
            );
          }}
          value={captureMaterialClassificationColors[colorId]?.visible || false}
        />
      </span>
      <div
        onClick={() => {
          setShowPicker((prev) => !prev);
        }}
        className="rounded-sm border w-5 h-5 flex-shrink-0"
        style={{
          backgroundColor,
        }}
      >
        {showPicker && (
          <div className="absolute z-20 pl-1 pt-4 pr-4 pb-1 bg-white rounded border border">
            <FontAwesomeIcon
              icon={faXmark}
              className="absolute top-0 right-0 m-1 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setShowPicker(false);
              }}
            />
            <CustomPicker
              debounce={1000}
              color={backgroundColor}
              onChange={(color) => {
                const rgbaArr = cvtRgbaStrToRgbaArr(color);
                const colorAsPctArr = cvtStdRgbaToRgbaPct(rgbaArr);
                setBackgroundColor(color);
                setCaptureMaterialClassificationColor(
                  classificationObject.material,
                  colorAsPctArr,
                  true
                );
              }}
              onSave={(color) => saveColor(color)}
            />
          </div>
        )}
      </div>
      <div className="ml-2 text-md text-gray-800 truncate flex-grow flex items-center">
        {classificationObject.name}
      </div>
    </div>
  );
};
