import { useCallback, useEffect, useState } from 'react';

export const cloudTileViewScrollStorage: { [id: string]: number } = {};

export const useCloudTileViewScrollRestoration = ({
  id,
  gridRef,
}: {
  id: string;
  gridRef: HTMLDivElement | null;
}) => {
  const [scrollRestored, setScrollRestored] = useState(false);

  useEffect(() => {
    if (id && gridRef && !scrollRestored) {
      gridRef.scrollTop = cloudTileViewScrollStorage[id] ?? 0;
      setTimeout(() => {
        gridRef.scrollTop = cloudTileViewScrollStorage[id] ?? 0;
      }, 100);
      setScrollRestored(true);
    }
  }, [id, gridRef, scrollRestored]);

  const onScroll = useCallback(() => {
    if (!id || !scrollRestored) {
      return;
    }

    cloudTileViewScrollStorage[id] = gridRef?.scrollTop ?? 0;
  }, [id, scrollRestored, gridRef]);

  useEffect(() => {
    if (!scrollRestored) {
      return;
    }
    gridRef?.addEventListener('scroll', onScroll);

    return () => {
      gridRef?.removeEventListener('scroll', onScroll);
    };
  }, [gridRef, onScroll, scrollRestored]);
};
