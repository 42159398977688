import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useCallback, useMemo } from 'react';

interface IButtonTertiary {
  id: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  done?: boolean;
  loading?: boolean;
  label?: string;
  leadingIcon?: IconDefinition;
  trailingIcon?: IconDefinition;
  expand?: boolean;
  danger?: boolean;
}

export const ButtonTertiary = ({
  id,
  buttonRef,
  onClick,
  disabled,
  done,
  loading,
  leadingIcon,
  trailingIcon,
  label,
  expand,
  danger,
}: IButtonTertiary) => {
  const className = useMemo(() => {
    let classes = `font-bold transition-colors duration-200 px-3 py-2
    flex flex-row items-center justify-center relative rounded-lg`;

    if (expand) {
      classes += ' w-full ';
    }
    if (loading) {
      classes += ' cursor-progress text-gray-textSecondary';
    } else if (disabled || done) {
      classes += ' cursor-not-allowed text-gray-textSecondary';
    } else if (danger) {
      classes += ' cursor-pointer hover-overlay-5 text-status-error ';
    } else {
      classes += ' cursor-pointer hover-overlay-5 text-primary ';
    }

    return classes.trim();
  }, [disabled, loading, expand, done, danger]);

  const icon = useCallback(
    (iconDefinition?: IconDefinition) => {
      if (!iconDefinition) {
        return null;
      }

      if (loading) {
        return (
          <div className="size-6 flex-center">
            <FontAwesomeIcon
              spin
              icon={faCircleNotch}
              className="text-base text-gray-iconSecondary"
            />
          </div>
        );
      }

      if (done) {
        return (
          <div className="size-6 flex-center">
            <FontAwesomeIcon
              icon={faCheck}
              className="text-base text-status-success"
            />
          </div>
        );
      }

      if (disabled) {
        return (
          <div className="size-6 flex-center">
            <FontAwesomeIcon
              icon={iconDefinition}
              className="text-base text-gray-iconSecondary"
            />
          </div>
        );
      }

      if (danger) {
        return (
          <div className="size-6 flex-center">
            <FontAwesomeIcon
              icon={iconDefinition}
              className="text-base text-status-error"
            />
          </div>
        );
      }

      return (
        <div className="size-6 flex-center">
          <FontAwesomeIcon
            icon={iconDefinition}
            className="text-base text-primary"
          />
        </div>
      );
    },
    [loading, done, disabled, danger]
  );

  const fullTileState = !leadingIcon && !trailingIcon;

  return (
    <button
      data-test-id={id}
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled || loading || done}
      style={{
        height: '40px',
      }}
      ref={buttonRef}
    >
      {fullTileState && (done || loading) && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          {icon(done ? faCheck : faCircleNotch)}
        </div>
      )}
      <div
        className={`flex flex-row gap-1 items-center ${
          fullTileState && (done || loading) ? 'opacity-0' : ''
        }`.trim()}
      >
        {icon(leadingIcon)}
        {label && <span>{label}</span>}
        {icon(trailingIcon)}
      </div>
    </button>
  );
};
