import { MeasurementMode, MeasurementOptionsValues } from '@agerpoint/types';

export const MeasurementModeClass = {
  measuringTool: ['angle', 'area', 'distance', 'point', 'height', 'circle'],
  volumeTool: ['volume'],
  profileTool: ['heightProfile'],
};
// measuringTool: [];

export const AngleMeasurementMode: MeasurementMode = {
  closed: true,
  maxMarkers: 3,
  name: 'Angle',
  showAngles: true,
  showArea: false,
  showDistances: false,
};

export const AreaMeasurementMode: MeasurementMode = {
  closed: true,
  name: 'Area',
  showArea: true,
  showDistances: true,
};

export const CircleMeasurementMode: MeasurementMode = {
  closed: false,
  maxMarkers: 3,
  name: 'Circle',
  showArea: false,
  showCircle: true,
  showDistances: false,
  showEdges: false,
  showHeight: false,
};

export const DistanceMeasurementMode: MeasurementMode = {
  closed: false,
  name: 'Distance',
  showArea: false,
  showDistances: true,
};

export const HeightMeasurementMode: MeasurementMode = {
  showDistances: false,
  showHeight: true,
  showArea: false,
  closed: false,
  maxMarkers: 2,
  name: 'Height',
};

export const PointMeasurementMode: MeasurementMode = {
  showDistances: false,
  showAngles: false,
  showCoordinates: true,
  showArea: false,
  closed: true,
  maxMarkers: 1,
  name: 'Point',
};

export const measurementModes: {
  [key in MeasurementOptionsValues]: MeasurementMode | undefined;
} = {
  angle: AngleMeasurementMode,
  area: AreaMeasurementMode,
  circle: CircleMeasurementMode,
  distance: DistanceMeasurementMode,
  height: HeightMeasurementMode,
  point: PointMeasurementMode,
  volume: undefined,
  heightProfile: undefined,
};
