import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';

import { InputIdContext } from '../input-id-context';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';
import './hide-time-input.scss';

interface InputDateSingleProps {
  /**
   * The unique identifier for the input element.
   */
  id: string;

  /**
   * The label to be displayed above the input element.
   */
  label?: React.ReactNode;

  /**
   * The error message to be displayed below the input element.
   */
  error?: React.ReactNode;

  /**
   * The placeholder text for the input element.
   */
  placeholder?: string;

  /**
   * The value of the input element.
   */
  value: Date | undefined;

  /**
   * A callback function to set the value of the input element.
   */
  setValue: (value: Date | undefined) => void;

  /**
   * Determines whether to show the time input for the date picker.
   */
  showTime?: boolean;

  /**
   * Determines whether the input element is read-only.
   */
  readOnly?: boolean;

  /**
   * The validation configuration for the input element.
   */
  validation?: InputValidation<Date | undefined>;

  /**
   * Footer to be displayed below the input component.
   */
  footer?: React.ReactNode;
}

const InputDateSingle = ({
  id,
  error,
  label,
  placeholder,
  value,
  setValue,
  showTime,
  readOnly,
  validation,
  footer,
}: InputDateSingleProps) => {
  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  return (
    <InputIdContext.Provider value={id}>
      <div
        className={`flex flex-col w-full ${
          isCalendarOpen ? 'z-50' : ''
        }`.trim()}
      >
        {label ?? null}
        <DatePicker
          data-test-id={id}
          className="rounded text-sm w-full focus:ring-green ring-green focus:border-green"
          popperClassName="text-sm z-50"
          selected={value}
          placeholderText={placeholder}
          onCalendarOpen={() => setIsCalendarOpen(true)}
          onCalendarClose={() => setIsCalendarOpen(false)}
          onChange={(date) => {
            setValue(date ?? undefined);
          }}
          dateFormat={showTime ? 'MM/dd/yyyy hh:mm aa' : 'MM/dd/yyyy'}
          readOnly={readOnly}
          timeFormat="hh:mm aa"
          showTimeInput={showTime}
        >
          {footer}
        </DatePicker>
        {error ?? null}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputDateSingle };
