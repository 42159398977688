import OlMap from 'ol/Map';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import { transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import BingMaps from 'ol/source/BingMaps';
import { StyleLike } from 'ol/style/Style';
import proj4 from 'proj4';
import { createContext, useContext, useEffect, useState } from 'react';

import { ICloudOpenlayersBaseMap } from './openlayers-map.types';

export const cloudOpenlayersLayerCache: {
  [key: string]: TileLayer<BingMaps>;
} = {};

export const cloudOpenlayersStyleCache: {
  [key: string]: StyleLike;
} = {};

interface ICloudOpenlayersMapContext {
  map: React.MutableRefObject<OlMap | undefined>;
  mapInitialized: boolean;
}

export const CloudOpenlayersMapContext =
  createContext<ICloudOpenlayersMapContext>({
    map: { current: undefined },
    mapInitialized: false,
  });

export const useCloudOpenlayersMapContext = () =>
  useContext(CloudOpenlayersMapContext);

export const cloudOpenlayersMapViewExtentStorage: { [id: string]: Extent } = {};

export const useCloudOpenlayersMapExtentRestoration = ({
  id,
  map,
  mapInitialized,
  persistView = false,
}: {
  id: string;
  map: React.MutableRefObject<OlMap | undefined>;
  mapInitialized: boolean;
  persistView?: boolean;
}) => {
  const [extentRestored, setExtentRestored] = useState(false);

  useEffect(() => {
    if (id && mapInitialized && !extentRestored && persistView) {
      const view = map.current?.getView();
      const extent: Extent | undefined =
        cloudOpenlayersMapViewExtentStorage?.[id];
      if (extent !== undefined) {
        view?.fit(extent);
      }
      setExtentRestored(true);
    }
  }, [id, extentRestored, mapInitialized, persistView]);

  useEffect(() => {
    const m = map.current;
    if (!extentRestored || !persistView || !mapInitialized || !m) {
      return;
    }

    const onMoveEnd = () => {
      cloudOpenlayersMapViewExtentStorage[id] = m.getView().calculateExtent();
    };
    m.addEventListener('moveend', onMoveEnd);

    return () => {
      m.removeEventListener('moveend', onMoveEnd);
    };
  }, [id, extentRestored, mapInitialized, persistView]);
};

export const useCloudOpenlayersMapProjectionRegistration = () => {
  useEffect(() => {
    proj4.defs(
      'EPSG:4269',
      '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs +type=crs'
    );
    proj4.defs(
      'EPSG:32615',
      '+proj=utm +zone=15 +datum=WGS84 +units=m +no_defs +type=crs'
    );
    proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs +type=crs');
    register(proj4);
  }, []);
};

export const getBaseMapIcon = (baseMap: ICloudOpenlayersBaseMap) => {
  if (baseMap === 'Aerial') {
    return 'satellite';
  }
  if (baseMap === 'AerialWithLabelsOnDemand') {
    return 'map';
  }
  if (baseMap === 'CanvasLight') {
    return 'road';
  }
  return 'question-circle';
};

export const defaultTransform = (coords: Coordinate) =>
  transform(coords, 'EPSG:4326', 'EPSG:3857');

export const defaultReverseTransform = (coords: Coordinate) =>
  transform(coords, 'EPSG:3857', 'EPSG:4326');

export const defaultInitialView = {
  center: defaultTransform([-97, 37]),
  zoom: 4,
};

export const CloudOpenlayersMapLayerZIndex = {
  baseMap: 0,
  feature: 100,
  drawing: 1000,
  highest: 9999999999,
};
