import {
  useGetGeometriesPaginatedByGeometryCollectionId,
  useGetMorphologiesPaginatedByCollectionId,
} from '@agerpoint/api';
import {
  AttributesTable as AttributesTableFeature,
  ProjectSidebarSpinner,
} from '@agerpoint/feature';
import { WmsVectorMapLayer } from '@agerpoint/types';

interface AttributesTable {
  layer: WmsVectorMapLayer;
  selectedFeatureIds: number[];
  setSelectedFeatureIds: (featureIds: number[]) => void;
  layerTypeId: number;
}

export function AttributesTable({
  layer,
  selectedFeatureIds,
  setSelectedFeatureIds,
  layerTypeId,
}: AttributesTable) {
  const myHook =
    layerTypeId === 8
      ? useGetGeometriesPaginatedByGeometryCollectionId
      : useGetMorphologiesPaginatedByCollectionId;
  const { data, loading, error } = myHook({
    geometryCollectionId: layer.entityId,
    collectionId: layer.entityId,
    pageNumber: 1,
    pageSize: 1000,
  });

  return loading ? (
    <ProjectSidebarSpinner />
  ) : data ? (
    <AttributesTableFeature
      data={data as any}
      layerType={layer.typeId}
      selectedFeatureIds={selectedFeatureIds}
      setSelectedFeatureIds={setSelectedFeatureIds}
    />
  ) : (
    <code>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </code>
  );
}
