import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { CustomerAnalytic, User } from '@agerpoint/api';
import {
  CaptureMaterialClassifications,
  CaptureObjectMarkers,
  CapturesViewerImage,
  GlobalStore,
  LDFlagSet,
  MaterialType,
  NewToastProps,
  OrientationOptions,
  QueueObject,
  ToastProps,
  UndoButtonProps,
  ViewerTypeOld,
} from '@agerpoint/types';

import { isMobileTablet } from '../isMobileTablet/isMobileTablet';
import { getStandAloneSlice } from './stand-alone-slice';
import { trackEvent } from './store-slice-utilities/mixpanel';

export const useGlobalStore = create<GlobalStore>()(
  devtools((set, get) => ({
    twoDimensionDrawingIsActive: false,
    siteWideLoading: false,
    captures: [],
    captureSidebarOpen: false,
    captureObjectMarkers: [],
    captureObjectMarkersHistory: {},
    captureObjectWindowOpen: false,
    permissions: {},
    toasts: [],
    showingToastPanel: false,
    undos: [],
    user: null,
    pendingQueue: [],
    subscriptions: {},
    subscribe: (effectName, listener) => {
      set((state) => {
        const subscriptions = state.subscriptions[effectName] || [];
        const newSubscriptions = [...subscriptions, listener];
        return {
          subscriptions: {
            ...state.subscriptions,
            [effectName]: newSubscriptions,
          },
        };
      });

      return () => {
        set((state) => {
          const subscriptions = state.subscriptions[effectName] || [];
          const newSubscriptions = subscriptions.filter(
            (sub) => sub !== listener
          );
          return {
            subscriptions: {
              ...state.subscriptions,
              [effectName]: newSubscriptions,
            },
          };
        });
      };
    },
    actions: {
      sendEvent: (eventName: string, eventBody: any) => {
        const userId = get().user?.id;
        const userName = get().user?.userName;
        const isMobile = isMobileTablet();
        trackEvent(eventName, eventBody, userId, userName, isMobile);
      },
      setSiteWideLoading: (value: boolean) => {
        set(
          (state: GlobalStore) => ({ ...state, siteWideLoading: value }),
          false,
          'setSiteWideLoading'
        );
      },
      setUser: (newUser: User | null) => {
        set(
          (state: GlobalStore) => ({ ...state, user: newUser }),
          false,
          'setUser'
        );
      },
      setPermissions: (newPermissions: LDFlagSet) => {
        set(
          (state: GlobalStore) => ({
            ...state,
            permissions: newPermissions,
          }),
          false,
          'setPermissions'
        );
      },
      setCaptures: (newCaptures: any[]) => {
        set(
          (state: GlobalStore) => ({ ...state, captures: newCaptures }),
          false,
          'setCaptures'
        );
      },
      setCapturesSidebarOpen: (newCapturesSidebarOpen: boolean) => {
        set(
          (state: GlobalStore) => ({
            ...state,
            captureSidebarOpen: newCapturesSidebarOpen,
          }),
          false,
          'setCapturesSidebarOpen'
        );
      },
      setCaptureObjectMarkers: (
        newCaptureObjectMarkers: CaptureObjectMarkers[]
      ) => {
        set(
          (state: GlobalStore) => ({
            ...state,
            captureObjectMarkers: newCaptureObjectMarkers,
          }),
          false,
          'setCaptureObjectMarkers'
        );
      },

      setCaptureObjectWindowOpen: (newCaptureObjectWindowOpen: boolean) => {
        set(
          (state: GlobalStore) => ({
            ...state,
            captureObjectWindowOpen: newCaptureObjectWindowOpen,
          }),
          false,
          'setCaptureObjectWindowOpen'
        );
      },

      addCaptureObjectMarkersHistory: (
        marker: CaptureObjectMarkers,
        id: number
      ) => {
        set(
          (state: GlobalStore) => ({
            ...state,
            captureObjectMarkersHistory: {
              ...state.captureObjectMarkersHistory,
              [id]: marker,
            },
          }),
          false,
          'setCaptureObjectMarkersHistory'
        );
      },
      removeCaptureObjectMarkersHistory: (id: number) => {
        const { [id]: _, ...newHistory } = get().captureObjectMarkersHistory;
        set(
          (state: GlobalStore) => ({
            ...state,
            captureObjectMarkersHistory: newHistory,
          }),
          false,
          'removeCaptureObjectMarkersHistory'
        );
      },
      addToast: (newToast: NewToastProps) => {
        const toast: ToastProps = {
          ...newToast,
          id: Math.random().toString(36).substring(2, 9),
          type: newToast.type ?? 'neutral',
          timestamp: new Date(),
          tags: newToast.tags,
        };
        set(
          (state: GlobalStore) => {
            // Remove the toast from the state that has exactly the same tags list
            let oldToasts = state.toasts;

            if (toast.tags !== undefined) {
              oldToasts = oldToasts.filter(
                (t) => JSON.stringify(t.tags) !== JSON.stringify(toast.tags)
              );
            }

            return {
              ...state,
              toasts: [...oldToasts, toast],
            };
          },
          false,
          'addToast'
        );
      },
      removeToast: (toastId: string) => {
        set(
          function (state: GlobalStore) {
            const newToasts = state.toasts.filter(
              (toast) => toast.id !== toastId
            );
            const shouldHide = newToasts.length === 0;
            if (shouldHide && state.toastPanelTimeout) {
              clearTimeout(state.toastPanelTimeout);
            }

            return {
              ...state,
              toasts: newToasts,
              showingToastPanel: shouldHide ? false : state.showingToastPanel,
              toastPanelTimeout: shouldHide
                ? undefined
                : state.toastPanelTimeout,
            };
          },
          false,
          'removeToast'
        );
      },
      showToastPanel: (milliseconds?: number) => {
        set(
          (state: GlobalStore) => {
            if (state.toastPanelTimeout) {
              clearTimeout(state.toastPanelTimeout);
            }

            return {
              ...state,
              toastPanelTimeout: milliseconds
                ? setTimeout(() => {
                    set((s: GlobalStore) => ({
                      ...s,
                      showingToastPanel: false,
                      toastPanelTimeout: undefined,
                    }));
                  }, milliseconds)
                : undefined,
              showingToastPanel: true,
            };
          },
          false,
          'showToastPanel'
        );
      },
      hideToastPanel: () => {
        set(
          (state: GlobalStore) => {
            if (state.toastPanelTimeout) {
              clearTimeout(state.toastPanelTimeout);
            }

            return {
              ...state,
              toastPanelTimeout: undefined,
              showingToastPanel: false,
            };
          },
          false,
          'hideToastPanel'
        );
      },
      addUndo: (undo: UndoButtonProps) => {
        // delete duplicate undos
        const newUndos = get().undos.filter(
          (undoItem) => undoItem.id !== undo.id
        );

        set(
          (state: GlobalStore) => ({
            ...state,
            undos: [...newUndos, undo],
          }),
          false,
          'addUndo'
        );
      },
      removeUndo: (id: string) => {
        set(
          (state: GlobalStore) => ({
            ...state,
            undos: state.undos.filter((undo) => undo.id !== id),
          }),
          false,
          'removeUndo'
        );
      },
      clearAllUndo: () => {
        set(
          (state: GlobalStore) => ({
            ...state,
            undos: [],
          }),
          false,
          'clearAllUndo'
        );
      },
      addToPendingQueue: (a: QueueObject) => {
        set(
          (state: GlobalStore) => {
            const newQueueItem = {
              ...a,
              completed: false,
            };
            return {
              ...state,
              pendingQueue: [a, ...state.pendingQueue],
            };
          },
          false,
          'addToPendingQueue'
        );
      },
      updatePendingQueueById: (
        id: string,
        newPendingQueueItem: QueueObject
      ) => {
        set(
          (state: GlobalStore) => {
            const newPendingQueue = [...state.pendingQueue];
            const index = newPendingQueue.findIndex((item) => item.id === id);
            if (index === -1) return state;
            newPendingQueue[index] = newPendingQueueItem;
            return {
              ...state,
              pendingQueue: [...newPendingQueue],
            };
          },
          false,
          'updatePendingQueueById'
        );
      },
      dispatchEffect: (effectName: string, payload?: any) => {
        set(
          (state: GlobalStore) => {
            const subscriptions = state.subscriptions[effectName] || [];
            subscriptions.forEach((subscription) => {
              subscription(payload);
            });
            return state;
          },
          false,
          effectName
        );
      },
      setTwoDimensionDrawing: (isActive: boolean) => {
        set(
          (state: GlobalStore) => {
            return {
              ...state,
              twoDimensionDrawingIsActive: isActive,
            };
          },
          false,
          'setTwoDimentionDrawing'
        );
      },
    },
    standAloneViewer: getStandAloneSlice(set, get),
    bottomBar: {
      isOpen: true,
      actions: {
        setIsOpen: (open: boolean) => {
          set(
            (state: GlobalStore) => {
              return {
                ...state,
                bottomBar: {
                  ...state.bottomBar,
                  isOpen: open,
                },
              };
            },
            false,
            'setIsOpen'
          );
        },
      },
    },
    sidebar: {
      isOpen: true,
      actions: {
        setIsOpen: (open: boolean) => {
          set(
            (state: GlobalStore) => {
              return {
                ...state,
                sidebar: {
                  ...state.sidebar,
                  isOpen: open,
                },
              };
            },
            false,
            'setIsOpen'
          );
        },
      },
    },
    capturesViewer: {
      viewerType: undefined,
      pointStyle: MaterialType.RGBA,
      captureImages: [],
      imageOrientation: OrientationOptions.Original,
      captureMaterialClassificationSelected:
        CaptureMaterialClassifications.NeverClassified,
      captureMaterialClassificationColors: {},
      actions: {
        setViewerType: (viewerType: ViewerTypeOld | undefined) => {
          set(
            (state: GlobalStore) => {
              return {
                ...state,
                capturesViewer: {
                  ...state.capturesViewer,
                  viewerType,
                },
              };
            },
            false,
            'setViewerType'
          );
        },
        setCaptureImages: (captureImages: CapturesViewerImage[]) => {
          set(
            (state: GlobalStore) => {
              return {
                ...state,
                capturesViewer: {
                  ...state.capturesViewer,
                  captureImages,
                },
              };
            },
            false,
            'setCaptureImages'
          );
        },
        setImageOrientation: (orientation: OrientationOptions) => {
          set(
            (state: GlobalStore) => {
              return {
                ...state,
                capturesViewer: {
                  ...state.capturesViewer,
                  imageOrientation: orientation,
                },
              };
            },
            false,
            'setImageOrientation'
          );
        },
        setPointStyle: (pointStyle: MaterialType) => {
          set(
            (state: GlobalStore) => {
              return {
                ...state,
                capturesViewer: {
                  ...state.capturesViewer,
                  pointStyle,
                },
              };
            },
            false,
            'setPointStyle'
          );
        },
        setCaptureMaterialClassificationSelected: (
          material: CaptureMaterialClassifications
        ) => {
          set(
            (state: GlobalStore) => ({
              ...state,
              capturesViewer: {
                ...state.capturesViewer,
                captureMaterialClassificationSelected: material,
              },
            }),
            false,
            'setCaptureMaterialClassificationSelected'
          );
        },
        setCaptureMaterialClassificationColor: (
          material: CaptureMaterialClassifications,
          color: number[],
          visible: boolean
        ) => {
          set(
            (state: GlobalStore) => ({
              ...state,
              capturesViewer: {
                ...state.capturesViewer,
                captureMaterialClassificationColors: {
                  ...state.capturesViewer.captureMaterialClassificationColors,
                  [material]: {
                    color,
                    visible,
                  },
                },
              },
            }),
            false,
            'setCaptureMaterialClassificationColor'
          );
        },
        setCaptureMaterialClassificationColors: (newColorLookup) => {
          set(
            (state: GlobalStore) => ({
              ...state,
              capturesViewer: {
                ...state.capturesViewer,
                captureMaterialClassificationColors: {
                  ...newColorLookup,
                },
              },
            }),
            false,
            'setCaptureMaterialClassificationColors'
          );
        },
      },
    },
    analytics: {
      availableAnalytics: [],
      actions: {
        setAvailableAnalytics: (availableAnalytics: CustomerAnalytic[]) => {
          set(
            (state: GlobalStore) => {
              return {
                ...state,
                analytics: {
                  ...state.analytics,
                  availableAnalytics,
                },
              };
            },
            false,
            'setAvailableAnalytics'
          );
        },
      },
    },
  }))
);
