import { Capture, CaptureExtractionJob } from '@agerpoint/api';

export interface QaQcURLParams {
  id?: string | undefined;
  eptId?: string | undefined;
  extId: string | undefined;
}

export interface ExtractionJob {
  id: number;
  captureId: number;
  captureJobId: number;
  completedDateTime: Date;
  createDatetime: Date;
  createdById: string;
  name: string;
  reportImagePath: string;
  submittedDateTime: Date;
  updateDatetime: Date;
  updatedById: string;
  uuid: string;
}

export interface CaptureWithExtractionJobs {
  capture?: Capture;
  extractionJobs?: CaptureExtractionJob[];
}

export enum CaptureExtractionJobStatusType {
  Rejected = 'Rejected',
  Completed = 'Completed',
  Active = 'Active',
  New = 'New',
}

export const unassignedCaptureUUID = '00000000-0000-0000-0000-000000000000';
