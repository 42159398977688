import {
  faCheckCircle,
  faCircleNotch,
  faEllipsis,
  faExternalLink,
  faFilterSlash,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle as faSolidCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIModels, formatDate } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { ContextMenu, Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { unassignedCaptureUUID } from '@agerpoint/types';
import {
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  useItemSelection,
  usePageTitle,
  useQueryState,
  uuidRegex,
} from '@agerpoint/utilities';

import { useQAQCQueries } from './qaqc-queries';

export const QAQCList = () => {
  usePageTitle(() => 'QAQC', []);
  const [filter, setFilter] = useState<APIModels.CaptureExtractionJobFilter>();
  const navigate = useNavigate();
  const {
    capturesWithExtractionJobsQuery,
    organizationsQuery,
    organizationsLookupTable,
    qaqcUsersQuery,
    extractionJobs,
    extractionJobStatusesQuery,
    extractionJobAssignPutMutation,
    extractionJobUnassignPutMutation,
  } = useQAQCQueries({
    filter,
  });

  const isViteApp = useIsViteApp();

  const { user } = useGlobalStore();

  const capturesWithExtractionJobs = useMemo(
    () => capturesWithExtractionJobsQuery.data?.pages?.flatMap((d) => d),
    [capturesWithExtractionJobsQuery.data]
  );

  const formValidation = useFormValidation();

  const extractionJobsSelection = useItemSelection<
    number,
    APIModels.CaptureExtractionJob
  >({
    idField: 'id',
    dependencies: [extractionJobs.length],
    items: extractionJobs,
  });

  const [selectedUserToAssign, setSelectedUserToAssign] =
    useState<APIModels.User>();

  useEffect(() => {
    if (extractionJobsSelection.selectionSize === 0) {
      setSelectedUserToAssign(undefined);
    }
  }, [extractionJobsSelection.selectionSize]);

  const [nameUUIDFilter, setNameUUIDFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (a) => a,
    toUrlParam: (a) => a.trim(),
  });

  const [selectedFilterOrganizations, setSelectedFilterOrganizations] =
    useQueryState<APIModels.Customer[]>({
      paramName: 'organizations',
      initialValue: [],
      fromUrlParam: (a) => {
        const splitted = a?.split(',').map((x) => Number(x));
        const filtered =
          organizationsQuery.data?.filter((x) => {
            return splitted?.includes(x.id as number) ?? false;
          }) ?? [];
        return filtered;
      },
      toUrlParam: (a) => {
        return a.map((x) => x.id).join(',');
      },
      retryInitWhen: organizationsQuery.isSuccess,
    });

  const [statusFilter, setStatusFilter] = useQueryState<
    APIModels.CaptureExtractionJobStatus | undefined
  >({
    paramName: 'status',
    initialValue: undefined,
    toUrlParam: (a) => {
      if (!a) {
        return '';
      }

      return a.name?.toLowerCase() ?? '';
    },
    fromUrlParam: (a) => {
      return extractionJobStatusesQuery.data?.find(
        (s) => s.name?.toLowerCase() === a.toLowerCase()
      );
    },
    retryInitWhen: extractionJobStatusesQuery.isSuccess,
  });

  const [assignedToFilter, setAssignedToFilter] = useQueryState<
    APIModels.User | undefined
  >({
    paramName: 'assignedto',
    initialValue: undefined,
    fromUrlParam: (a) => {
      if (a === 'noone') {
        return { id: unassignedCaptureUUID, userName: 'Unassigned' };
      }

      if (a) {
        const user = qaqcUsersQuery.data?.find((u) => u.id === a);
        if (user) {
          return user;
        }
      }

      return undefined;
    },
    toUrlParam: (a) => {
      if (a?.id === unassignedCaptureUUID) {
        return 'noone';
      }

      return a?.id ?? '';
    },
    retryInitWhen: qaqcUsersQuery.isSuccess,
  });

  useEffect(() => {
    const isNameAnUUID =
      nameUUIDFilter.trim().length > 0 && uuidRegex.test(nameUUIDFilter.trim());
    setFilter((prev) => ({
      ...prev,
      name: isNameAnUUID ? '' : nameUUIDFilter.trim(),
      captureUuid: isNameAnUUID ? nameUUIDFilter.trim() : undefined,
    }));
  }, []);

  useDebouncyEffect(
    () => {
      const isNameAnUUID =
        nameUUIDFilter.trim().length > 0 &&
        uuidRegex.test(nameUUIDFilter.trim());

      setFilter((prev) => ({
        ...prev,
        name: isNameAnUUID ? '' : nameUUIDFilter.trim(),
        captureUuid: isNameAnUUID ? nameUUIDFilter.trim() : undefined,
      }));
    },
    500,
    [nameUUIDFilter]
  );

  useEffect(() => {
    const customerIds = selectedFilterOrganizations.map((o) => o.id as number);

    setFilter((prev) => ({
      ...prev,
      customerIds: customerIds.length > 0 ? customerIds : undefined,
      statusId: statusFilter?.id,
      assignedTo: assignedToFilter?.id,
    }));
  }, [selectedFilterOrganizations, statusFilter, assignedToFilter]);

  const hasFiltersApplied = useMemo(
    () =>
      !!(
        filter?.assignedTo !== undefined ||
        filter?.statusId !== undefined ||
        (filter?.customerIds?.length ?? 0) > 0 ||
        (filter?.name?.trim()?.length ?? 0) > 0 ||
        (filter?.captureUuid?.length ?? 0) > 0
      ),
    [filter]
  );

  const clearFilter = useCallback(() => {
    setStatusFilter(undefined);
    setSelectedFilterOrganizations([]);
    setNameUUIDFilter('');
    setAssignedToFilter(undefined);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('organizations');
    searchParams.delete('status');
    searchParams.delete('name');
    searchParams.delete('assignedto');

    navigate(
      {
        pathname: isViteApp ? '/app/admin/operations/qaqc' : '/ops/qaqc',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, []);

  const openDetailPage = useCallback(
    ({
      capExtJob,
      ext,
      event,
      forceNewWindow = false,
    }: {
      capExtJob: APIModels.CaptureExtractionJobVM;
      ext?: APIModels.CaptureExtractionJob;
      event: React.MouseEvent;
      forceNewWindow?: boolean;
    }) => {
      const latestCompletedJob = capExtJob.capture?.completedJobs?.[0];
      if (!latestCompletedJob) {
        return;
      }
      const extractionJobId = ext?.id ?? capExtJob.extractionJobs?.[0].id;
      const captureId = capExtJob.capture?.id;
      const captureJobId = latestCompletedJob.id;

      if (event.metaKey || event.ctrlKey || forceNewWindow) {
        window.open(
          isViteApp
            ? `/app/admin/operations/qaqc/${captureId}/${captureJobId}/${extractionJobId}`
            : `/ops/qaqc/${captureId}/${captureJobId}/${extractionJobId}`,
          '_blank',
          'noopener noreferrer'
        );
      } else {
        if (isViteApp) {
          navigate(
            `/app/admin/operations/qaqc/${captureId}/${captureJobId}/${extractionJobId}`
          );
        } else {
          navigate(`/ops/qaqc/${captureId}/${captureJobId}/${extractionJobId}`);
        }
      }
    },
    []
  );

  const assignSelectedJobs = useCallback(async () => {
    if (await formValidation.hasErrors()) {
      return;
    }

    extractionJobAssignPutMutation.mutate(
      {
        assigneeId: selectedUserToAssign?.id as string,
        extractionJobs: extractionJobsSelection.getSelectionArray(),
      },
      {
        onSuccess: () => {
          extractionJobsSelection.clearSelection();
          setSelectedUserToAssign(undefined);
        },
      }
    );
  }, [
    formValidation,
    selectedUserToAssign,
    extractionJobsSelection,
    extractionJobAssignPutMutation,
  ]);

  const unassignSelectedJobs = useCallback(() => {
    const confirm = window.confirm(
      `Are you sure you want to unassign users from selected extraction jobs?`
    );

    if (!confirm) {
      return;
    }

    extractionJobUnassignPutMutation.mutate(
      {
        extractionJobs: extractionJobsSelection.getSelectionArray(),
      },
      {
        onSuccess: () => {
          extractionJobsSelection.clearSelection();
        },
      }
    );
  }, [extractionJobUnassignPutMutation, extractionJobsSelection]);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="flex flex-row justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">QAQC</h1>
        {extractionJobsSelection.hasSelectedItems && (
          <div className="flex flex-row gap-1">
            <Input.Select.Single
              id="assign-selected-ext-jobs-select"
              placeholder="Select User"
              options={qaqcUsersQuery.data ?? []}
              loading={qaqcUsersQuery.isLoading}
              value={selectedUserToAssign}
              setValue={setSelectedUserToAssign}
              optionBuilder={(o) => o.userName ?? 'Unknown'}
              title="Users"
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('User')],
              }}
              error={
                <Input.Error
                  error={
                    formValidation.errors['assign-selected-ext-jobs-select']
                  }
                />
              }
            />
            <Button.Primary
              id="assign-selected-ext-jobs-button"
              label="Assign"
              onClick={assignSelectedJobs}
              loading={extractionJobAssignPutMutation.isPending}
            />
            <Button.Danger
              id="unassign-selected-ext-jobs-button"
              label="Unassign"
              onClick={unassignSelectedJobs}
              loading={extractionJobUnassignPutMutation.isPending}
            />
            <div className="pl-4">
              <Button.Secondary
                id="clear-selection-button"
                label={`Clear Selection (${extractionJobsSelection.selectionSize})`}
                onClick={extractionJobsSelection.clearSelection}
                icon={faXmark}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2 px-4 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            setValue={setNameUUIDFilter}
            value={nameUUIDFilter}
            placeholder={'Search by Name or UUID'}
            placeholderIcon={Input.placeholderIcons.search}
            id="qaqc-name-search"
          />
        </div>
        <Input.Select.Multi
          id="organization-filter-select"
          placeholder="Organizations"
          title="Organizations"
          options={organizationsQuery.data ?? []}
          loading={organizationsQuery.isLoading}
          value={selectedFilterOrganizations}
          setValue={setSelectedFilterOrganizations}
          optionBuilder={(o) =>
            o.customerDisplayName ?? o.customerName ?? 'Unknown'
          }
        />
        <Input.Select.Single
          id="status-filter-select"
          placeholder="Status"
          title="Status"
          options={extractionJobStatusesQuery.data ?? []}
          loading={extractionJobStatusesQuery.isLoading}
          value={statusFilter}
          setValue={setStatusFilter}
          optionBuilder={(o) => o.displayName ?? o.name ?? 'Unknown'}
        />
        <Input.Select.Single
          id="assignee-filter-select"
          placeholder="Assignee"
          title="Assignee"
          options={qaqcUsersQuery.data ?? []}
          loading={qaqcUsersQuery.isLoading}
          value={assignedToFilter}
          setValue={setAssignedToFilter}
          optionBuilder={(o) => o.userName ?? 'Unknown'}
          compareFn={(a, b) => a.id === b.id}
        />
        <Button.Thin
          id="assigned-to-me-button"
          label="Assigned To Me"
          underlineLabel={
            assignedToFilter !== undefined && assignedToFilter?.id === user?.id
          }
          onClick={() => {
            if (user?.id && user?.userName) {
              if (assignedToFilter?.id === user?.id) {
                setAssignedToFilter(undefined);
              } else {
                setAssignedToFilter(user);
              }
            }
          }}
        />
        <Button.Thin
          id="unassigned-button"
          label="Unassigned"
          underlineLabel={
            assignedToFilter !== undefined &&
            assignedToFilter?.id === unassignedCaptureUUID
          }
          onClick={() => {
            if (assignedToFilter?.id === unassignedCaptureUUID) {
              setAssignedToFilter(undefined);
            } else {
              setAssignedToFilter({
                userName: 'Unassigned',
                id: unassignedCaptureUUID,
              });
            }
          }}
        />
        <Button.ClearFilter visible={hasFiltersApplied} onClick={clearFilter} />
      </div>
      <div className="p-4 h-full">
        <Datatable
          id="qaqc-list"
          data={capturesWithExtractionJobs ?? []}
          cellOnClick={(columnName) => {
            const clickable = [
              'name',
              'created',
              'organization',
              'numberImages',
              'fileSize',
              'jobs',
            ];
            if (clickable.includes(columnName)) {
              return (d, e) =>
                openDetailPage({
                  capExtJob: d,
                  event: e,
                });
            }
            return undefined;
          }}
          columns={[
            {
              label: null,
              flex: 0,
              value: function ExtractionJobsRow(row, rowIndex) {
                return (
                  <div
                    className="absolute top-10 ml-1 left-0 right-0 bottom-0
                  flex flex-row overflow-auto flex-wrap"
                  >
                    {row.extractionJobs?.map((job, index) => (
                      <QAQCListExtractionJobTile
                        index={index}
                        key={`${rowIndex}-${index}`}
                        extractionJob={job}
                        captureWithExtractionJobs={row}
                        extractionJobSelection={extractionJobsSelection}
                        openDetailPage={openDetailPage}
                      />
                    ))}
                  </div>
                );
              },
            },

            {
              label: 'Name',
              flex: 2,
              value: (row) => row.capture?.captureName,
              name: 'name',
            },

            {
              label: 'Created',
              value: (row) =>
                row.capture?.createDatetime
                  ? formatDate(row.capture?.createDatetime)
                  : null,
              name: 'created',
            },
            {
              label: 'Organization',
              value: function Organization(row) {
                if (!row.capture?.customerId) {
                  return null;
                }

                if (!organizationsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const org = organizationsLookupTable?.[row.capture?.customerId];

                return org?.customerDisplayName ?? org?.customerName ?? null;
              },
              name: 'organization',
            },
            {
              label: 'No. of Images',
              flex: 0.75,
              value: (row) => row.capture?.numberImages,
              name: 'numberImages',
            },
            {
              label: 'Size (MB)',
              flex: 0.5,
              value: (row) => row.capture?.fileSize,
              name: 'fileSize',
            },
            {
              label: 'Jobs',
              value: (row) => row.extractionJobs?.length || '',
              name: 'jobs',
              flex: 0.5,
            },
            {
              label: 'Actions',
              flex: 0.5,
              value: (row, index) => {
                return (
                  <div className="p-1">
                    <ContextMenu
                      dataTestId={`captures-list-context-menu-${index}`}
                      groups={[
                        {
                          items: [
                            {
                              icon: <FontAwesomeIcon icon={faExternalLink} />,
                              label: 'New Window',
                              onClick: (e) => {
                                openDetailPage({
                                  capExtJob: row,
                                  event: e,
                                  forceNewWindow: true,
                                });
                              },
                            },
                          ],
                        },
                      ]}
                      icon={
                        <Button.Icon
                          id={`actions-button-${index}`}
                          icon={faEllipsis}
                        />
                      }
                    />
                  </div>
                );
              },
              style: {
                columnWrapperStyle: 'flex justify-center',
              },
            },
            {
              label: (
                <div className="p-1">
                  {extractionJobsSelection.isEverythingSelected ? (
                    <Button.Small
                      id="qaqc-deselect-all-button"
                      label="Deselect All"
                      icon={faSolidCheckCircle}
                      iconColor="text-accent"
                      onClick={() => {
                        extractionJobsSelection.toggleSelectionEverything();
                      }}
                    />
                  ) : (
                    <Button.Small
                      id="qaqc-select-all-button"
                      label="Select All"
                      icon={faCheckCircle}
                      onClick={() => {
                        extractionJobsSelection.toggleSelectionEverything();
                      }}
                    />
                  )}
                </div>
              ),
              value: function Select(row, index) {
                const isEveryJobFromThisCaptureSelected =
                  row.extractionJobs
                    ?.map((j) =>
                      j.id ? extractionJobsSelection.isSelected(j.id) : false
                    )
                    ?.every((x) => x) ?? false;

                return (
                  <div className="p-1">
                    <Button.Icon
                      id={`captures-list-select-one-${index}`}
                      icon={
                        isEveryJobFromThisCaptureSelected
                          ? faSolidCheckCircle
                          : faCheckCircle
                      }
                      onClick={(e) => {
                        if (!row?.extractionJobs) {
                          return;
                        }

                        const jobs = row.extractionJobs.map((j) => ({
                          key: j.id ?? NaN,
                          value: j,
                        }));

                        if (!e.shiftKey && isEveryJobFromThisCaptureSelected) {
                          extractionJobsSelection.removeSelectionList(jobs);
                          return;
                        }
                        extractionJobsSelection.addSelectionList(jobs);
                        if (e.shiftKey) {
                          const firstJob = jobs.at(0);
                          const lastJob = jobs.at(-1);
                          if (!lastJob || !firstJob) {
                            return;
                          }
                          extractionJobsSelection.addBulkSelectionUntilItemDirected(
                            {
                              downUp: {
                                key: firstJob?.key,
                                value: firstJob?.value,
                              },
                              upDown: {
                                key: lastJob?.key,
                                value: lastJob?.value,
                              },
                            }
                          );
                        }
                      }}
                      iconColor={
                        isEveryJobFromThisCaptureSelected
                          ? 'text-accent'
                          : undefined
                      }
                    />
                  </div>
                );
              },
              style: {
                columnWrapperStyle: 'flex justify-center',
              },
            },
          ]}
          loading={
            capturesWithExtractionJobsQuery.isLoading ||
            capturesWithExtractionJobsQuery.isFetchingNextPage ||
            filter === undefined
          }
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching captures to QAQC',
                  message: 'Adjust your filters and try again',
                  action: clearFilter,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No captures to QAQC yet',
                }
          }
          error={
            capturesWithExtractionJobsQuery.isError
              ? {
                  title: 'There was a problem loading captures to QAQC',
                  message: 'Try refreshing the page',
                  action: () => capturesWithExtractionJobsQuery.refetch(),
                }
              : undefined
          }
          pagination={{
            threshold: 10,
            loadNextPage: () => {
              if (
                !capturesWithExtractionJobsQuery.hasNextPage ||
                capturesWithExtractionJobsQuery.isFetchingNextPage ||
                capturesWithExtractionJobsQuery.isLoading ||
                filter === undefined
              ) {
                return;
              }

              capturesWithExtractionJobsQuery.fetchNextPage();
            },
          }}
          rowHeight={82}
          style={{
            ...dataTableAgerStyle,
            rowStyle: 'border-b border-gray-200 relative pb-10',
            tableMinWidth: 800,
          }}
        />
      </div>
    </div>
  );
};

interface QAQCListExtractionJobTileProps {
  index: number;
  captureWithExtractionJobs: APIModels.CaptureExtractionJobVM;
  extractionJob: APIModels.CaptureExtractionJob;
  extractionJobSelection: ReturnType<
    typeof useItemSelection<number, APIModels.CaptureExtractionJob>
  >;
  openDetailPage: (args: {
    capExtJob: APIModels.CaptureExtractionJobVM;
    ext?: APIModels.CaptureExtractionJob;
    event: React.MouseEvent;
    forceNewWindow?: boolean;
  }) => void;
}

const QAQCListExtractionJobTile = ({
  index,
  captureWithExtractionJobs,
  extractionJob,
  extractionJobSelection,
  openDetailPage,
}: QAQCListExtractionJobTileProps) => {
  const isThisExtractionJobSelected =
    extractionJob?.id !== undefined
      ? extractionJobSelection.isSelected(extractionJob.id)
      : false;

  const status = extractionJob?.captureExtractionJobStatus;
  const userName = extractionJob.assignedToUserName?.split('@')?.[0];

  const selectButtonOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (!extractionJob.id) {
        return;
      }

      if (e.shiftKey) {
        extractionJobSelection.addBulkSelectionUntilItem(
          extractionJob.id,
          extractionJob
        );
      } else {
        extractionJobSelection.toggleSelection(extractionJob.id, extractionJob);
      }
    },
    [extractionJob, extractionJobSelection]
  );

  return (
    <div
      className="mr-1 mb-1 flex flex-row items-center justify-center"
      style={{
        height: '37px',
        minHeight: '37px',
        maxBlockSize: '37px',
      }}
    >
      <div
        className={`border-y-2 border-l-2 rounded-l
      bg-white h-full flex items-center justify-center px-2 gap-1`}
        style={{
          borderColor: status?.color ?? 'gray',
        }}
      >
        <Button.Icon
          id="qaqc-extraction-job-open-button"
          icon={faExternalLink}
          internalPadding="p-0.5"
          onClick={(e) => {
            openDetailPage({
              capExtJob: captureWithExtractionJobs,
              forceNewWindow: true,
              ext: extractionJob,
              event: e,
            });
          }}
        />
      </div>
      <div
        className={`h-full flex flex-row divide-x divide-white
      items-center justify-center text-white cursor-pointer transition-all duration-200`}
        style={{
          background: status?.color ?? 'gray',
        }}
        onClick={(e) => {
          openDetailPage({
            capExtJob: captureWithExtractionJobs,
            ext: extractionJob,
            event: e,
          });
        }}
      >
        <span className="px-2">
          {status?.displayName ?? status?.name ?? 'Unknown'}
        </span>
        <span className="px-2">{extractionJob?.id}</span>
        {userName && <span className="px-2">{userName}</span>}
      </div>
      <div
        className={`border-y-2 border-r-2 rounded-r
      bg-white h-full flex items-center justify-center px-2 gap-1`}
        style={{
          borderColor: status?.color ?? 'gray',
        }}
      >
        {isThisExtractionJobSelected ? (
          <Button.Icon
            id={`qaqc-extraction-job-select-button-${index}`}
            icon={faSolidCheckCircle}
            iconColor="text-accent"
            onClick={selectButtonOnClick}
          />
        ) : (
          <Button.Icon
            id={`qaqc-extraction-job-select-button-${index}`}
            icon={faCheckCircle}
            onClick={selectButtonOnClick}
          />
        )}
      </div>
    </div>
  );
};
