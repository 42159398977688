import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';

interface ButtonCloudTextProps {
  id: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  label: string;
  leadingIcon?: IconDefinition;
  leadingIconColor?: string;
  trailingIcon?: IconDefinition;
  trailingIconColor?: string;
}

export const ButtonCloudText = ({
  id,
  onClick,
  disabled,
  label,
  leadingIcon,
  leadingIconColor,
  trailingIcon,
  trailingIconColor,
}: ButtonCloudTextProps) => {
  const className = useMemo(() => {
    let classes = ` transition-all focus:underline
    duration-200 focus:outline-none outline-none rounded
    flex flex-row items-center justify-center text-sm text-left `;

    if (disabled) {
      classes += 'cursor-not-allowed opacity-50 text-disabled ';
    } else {
      classes += 'text-primary hover:underline ';
    }

    return classes.trim();
  }, [disabled]);

  return (
    <button
      data-test-id={id}
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      <div className="flex flex-row items-center gap-2">
        {leadingIcon && (
          <div className="flex items-center justify-center h-3.5 w-3.5">
            <FontAwesomeIcon
              icon={leadingIcon}
              className={`w-full h-full ${leadingIconColor}`.trim()}
            />
          </div>
        )}
        <div className="leading-none">{label}</div>
        {trailingIcon && (
          <div className="flex items-center justify-center h-3.5 w-3.5">
            <FontAwesomeIcon
              icon={trailingIcon}
              className={`w-full h-full ${trailingIconColor}`.trim()}
            />
          </div>
        )}
      </div>
    </button>
  );
};
