import { Feature, Map, Tile } from 'ol';
import TileState from 'ol/TileState';
import VectorTile from 'ol/VectorTile';
import { getArea, getCenter, getHeight, getWidth } from 'ol/extent';
import MVT from 'ol/format/MVT';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import { transformExtent } from 'ol/proj';
import RenderFeature from 'ol/render/Feature';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import VectorTileSource from 'ol/source/VectorTile';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { getNumberDecimals } from '@agerpoint/component';
import {
  LabelType,
  LayerComponentProps,
  VectorStyle,
  WmsVectorMapLayer as WmsVectorMapLayerType,
} from '@agerpoint/types';
import { loadTile } from '@agerpoint/utilities';

import { createColorFn, hidden } from './layer-colors';
import { lineStyleUtil } from './style.utilities';

export function WmsVectorMapLayer({
  map,
  layer,
  zIndex,
  visible,
  selectedFeatureIds,
  setSelectedFeatureIds,
  setSelectedLayerId,
  showFeatureInfo,
  serverUrl,
  token,
  isSelectedLayer,
  layerIdUpdateCount,
  selectedOlUid,
}: LayerComponentProps) {
  const wmsLayer = layer as WmsVectorMapLayerType;

  const [tileLayer, setTileLayer] = useState<VectorTileLayer>();
  const [tileLyrSrc, setTileLyrSrc] = useState<VectorTileSource>();

  // Map of morphology_id to Feature object, used for getting
  // a feature's properties given its id
  const featureCache = useRef<Record<number, Feature<Geometry>>>({});
  // Since the same feature can occur multiple times in a VectorTileLayer
  // (a feature can be split into multiple tiles), the VectorTileLayer is
  // not suitable for labeling. Instead, we keep a separate source of Points
  // for the current features, and render labels from these points.
  const featurePointsSource = useMemo(
    () => new VectorSource<Feature<Point>>(),
    []
  );

  useEffect(() => {
    const wmsSrc = new TileWMS({
      url: wmsLayer.wmsUrl,
      params: {
        LAYERS: wmsLayer.layers,
        viewparams: wmsLayer.params,
        FORMAT: 'application/vnd.mapbox-vector-tile',
      },
      crossOrigin: 'crossOrigin',
    });

    const tileLyrSrc = new VectorTileSource({
      format: new MVT(),
      tileUrlFunction: wmsSrc.getTileUrlFunction(),
      tileGrid: wmsSrc.getTileGrid() || undefined,
      tileSize: [512, 512],
      tileLoadFunction: (tile, url) => {
        loadTile(
          token,
          featureCache,
          featurePointsSource,
          tile,
          url,
          wmsLayer.id
        );
      },
      overlaps: false,
    });
    setTileLyrSrc(tileLyrSrc);

    const tileLyr = new VectorTileLayer({
      extent:
        getWidth(wmsLayer.extent) > 0 && getHeight(wmsLayer.extent) > 0
          ? transformExtent(wmsLayer.extent, 'EPSG:4326', 'EPSG:3857')
          : undefined,
      source: tileLyrSrc,
      visible: visible,
      className: 'wms-vector-layer',
      properties: {
        wmsLayerId: wmsLayer.id,
      },
    });
    setTileLayer(tileLyr);
  }, [visible, wmsLayer.style]);

  const featureIdSet = useMemo(
    () => new Set(selectedFeatureIds),
    [selectedFeatureIds]
  );

  const updateStyleCallback = useCallback(
    (useIsSelected: any) => {
      if (!tileLayer) return;
      const fourthParam = useIsSelected ? isSelectedLayer : false;
      updateStyleFunction(wmsLayer.style, tileLayer, featureIdSet, fourthParam);
    },
    [wmsLayer.style, tileLayer, featureIdSet, isSelectedLayer]
  );

  useLabelLayer(wmsLayer.style, map, featurePointsSource, visible);

  useEffect(() => {
    updateStyleCallback(true);
  }, [visible]);

  useEffect(() => {
    updateStyleCallback(true);
  }, [layerIdUpdateCount]);

  useEffect(() => {
    if (!tileLayer) return;
    map.addLayer(tileLayer);
    updateStyleCallback(true);

    return () => {
      map.removeLayer(tileLayer);
    };
  }, [tileLayer]);

  useEffect(() => {
    if (!tileLayer) return;

    tileLayer.setZIndex(zIndex);
  }, [tileLayer, zIndex]);

  return null;
}

function updateStyleFunction(
  {
    strokeColor,
    fillColor,
    strokeWidth,
    fillOpacity,
    strokeOpacity,
    strokePattern,
  }: VectorStyle,
  tileLayer: VectorTileLayer,
  featureIdSet: Set<number>,
  isSelectedLayer?: boolean
) {
  const [fillColorFn] = createColorFn(fillColor, fillOpacity);
  const [strokeColorFn] = createColorFn(strokeColor, strokeOpacity);

  const featureStyleFn = (feature: any, radius = 9) => {
    let fillColor = fillColorFn(feature);

    // "hidden" is returned as fill if the feature's
    // bin's visibility is turned off, for this case
    // the feature should not be rendered at all, so we
    // return an undefined style.
    if (fillColor === hidden) return undefined;

    const strokeColor = strokeColorFn(feature);
    const featureId = feature.get('morphology_id');
    const geometryId = feature.get('geometry_id');
    const isSelected = featureIdSet.has(featureId || geometryId);
    const featureType = feature.getGeometry()?.getType();

    if (isSelected) {
      fillColor = [...fillColor];
      fillColor[3] *= 2;
    }

    const pointStyle = new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: fillColor,
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: strokeWidth || 1,
          lineDash:
            strokePattern === 'Dashed'
              ? [4 * strokeWidth, 4 * strokeWidth]
              : undefined,
        }),
        radius,
      }),
    });

    const lineStyle = lineStyleUtil({
      strokeColor,
      strokeWidth,
      strokePattern,
      fillColor,
    });

    if (featureType === 'Point') {
      return pointStyle;
    }
    return lineStyle;
  };

  if (isSelectedLayer && !featureIdSet.size) {
    let iter = 0;
    const animate: TimerHandler = () => {
      if (iter > 8) {
        iter = 0;
        tileLayer.setStyle((f) => featureStyleFn(f));
        clearInterval(Number(animateInterval));
        animateInterval = null;
        return;
      }
      iter++;
      tileLayer.setStyle((f) => featureStyleFn(f, 9 + iter));
    };

    let animateInterval: number | null = setInterval(animate, 50);
    return;
  }

  tileLayer.setStyle((f) => featureStyleFn(f));
}

function useLabelLayer(
  { label }: VectorStyle,
  map: Map,
  featurePointsSource: VectorSource<Feature<Point>>,
  visible: boolean
) {
  const layer = useMemo(() => {
    let textFn: (feature: RenderFeature | Feature<any>) => string;
    let textStyle: Text;
    if (label.type === LabelType.Attribute) {
      textStyle = new Text({
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 3 }),
        textAlign: 'center',
        justify: 'center',
        textBaseline: 'middle',
        offsetX: 0,
        offsetY: 1.75,
        overflow: true,
        placement: 'line',
        scale: 1.3,
      });
      const numberDecimals = label.max ? getNumberDecimals(label.max) : NaN;

      if (!isNaN(numberDecimals)) {
        textFn = (feature) => {
          const value = feature.get(label.attribute);
          return value != null ? value.toFixed(numberDecimals) : '';
        };
      } else {
        textFn = (feature) => {
          const value = feature.get(label.attribute);
          return value != null ? value.toString() : '';
        };
      }

      const style = new Style({ text: textStyle });

      const layer = new VectorLayer({
        className: 'label-layer',
        source: featurePointsSource,
        style: (feature) => {
          textStyle.setText(textFn(feature));
          return style;
        },
        declutter: false,
      });

      return layer;
    } else {
      return undefined;
    }
  }, [label, featurePointsSource]);

  useEffect(() => {
    if (layer && visible) {
      map.addLayer(layer);

      return () => {
        map.removeLayer(layer);
      };
    }

    return undefined;
  }, [map, layer, visible]);
}
