import { faDisplay } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronsUp, faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UseGetReturn } from 'restful-react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module.js';

import {
  Capture,
  CaptureJob,
  useGetCaptureById,
  useGetPlyDownloadUrlByCaptureJobId,
} from '@agerpoint/api';
import {
  BackgroundOptionsValues,
  LdFlags,
  MixpanelNames,
} from '@agerpoint/types';
import {
  GaussianSplats3D,
  hasPermission,
  useGlobalStore,
} from '@agerpoint/utilities';

import './gs-three-d.scss';

enum PlyErrors {
  NO_CAPTURE_JOB = 'NO_CAPTURE_JOB',
  NO_PLY_DOWNLOAD_URL = 'NO_PLY_DOWNLOAD_URL',
  UNKNOWN = 'UNKNOWN',
}

export const Gs3dComponent = ({
  selectedCapture,
  selectedCaptureJob,
  controlsAllowed = true,
}: {
  selectedCapture: Capture | undefined;
  selectedCaptureJob: CaptureJob | undefined;
  controlsAllowed?: boolean;
}) => {
  const {
    permissions,
    sidebar: { isOpen: sidebarOpen },
  } = useGlobalStore();
  const viewerRef = useRef<GaussianSplats3D.Viewer>();
  const divRef = useRef<HTMLDivElement>(null);
  const statsRef = useRef<HTMLDivElement>(null);
  const loadingPromiseRef = useRef<any>(null);
  const resizeAbortRef = useRef<AbortController | null>(null);
  const [downloading, setDownloading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [selectedCaptureJobId, setSelectedCaptureJobId] = useState<number>();
  const [error, setError] = useState<PlyErrors | undefined>(undefined);
  const [captureId, setCaptureId] = useState<number | undefined>(undefined);
  const [backgroundColor, setBackgroundColor] = useState<string>(
    BackgroundOptionsValues.Black
  );
  const [has3dDebugPermission, setHas3dDebugPermission] = useState(false);
  const [statsObject, setStatsObject] = useState<any>(undefined);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  const [loading, setLoading] = useState({
    step: '',
    inProgress: false,
    percentage: -1,
    finished: false,
  });

  const { data: captureJobWithPlyInfo, refetch: refetchPlyDownload } =
    useGetPlyDownloadUrlByCaptureJobId({
      id: selectedCaptureJobId ?? NaN,
      lazy: true,
    }) as unknown as UseGetReturn<CaptureJob, void, void, unknown>;

  const { data: capture, refetch: refetchCapture } = useGetCaptureById({
    id: captureId ?? NaN,
    lazy: true,
  }) as unknown as UseGetReturn<Capture, void, void, unknown>;

  const drawStats = useCallback(() => {
    if (!statsRef.current) return;
    const stats = new Stats();
    stats.dom.style.zIndex = '10000';
    stats.dom.style.position = 'absolute';
    stats.dom.style.top = '0';
    stats.dom.style.left = 'unset';
    stats.dom.style.right = '45px';

    statsRef.current.appendChild(stats.dom);
    setStatsObject(stats);
  }, []);

  const update = useCallback(() => {
    if (!viewerRef?.current) return;
    if (statsObject) {
      statsObject.update();
    }
    const frameId = requestAnimationFrame(update);
    viewerRef.current.update();
    viewerRef.current.render();
    return () => {
      cancelAnimationFrame(frameId);
    };
  }, [viewerRef, statsObject]);

  const loadedCallback = useCallback(() => {
    mixpanel.track(MixpanelNames.ThreeJsPlyViewerLoaded, {
      status: 'success',
    });
    // setLoading(false);
  }, []);

  const drawScene = useCallback(
    (scene: THREE.Scene, renderer: THREE.Renderer) => {
      if (!divRef.current || !url || !loadedCallback) return;
      divRef.current.innerHTML = '';

      const renderWidth = divRef.current.offsetWidth;
      const renderHeight = divRef.current.offsetHeight;

      const rootElement = document.createElement('div');
      rootElement.classList.add('rootElement');
      rootElement.style.width = renderWidth + 'px';
      rootElement.style.height = renderHeight + 'px';
      divRef.current.appendChild(rootElement);

      renderer.setSize(renderWidth, renderHeight);
      rootElement.appendChild(renderer.domElement);
      const camera = new THREE.PerspectiveCamera(
        65,
        renderWidth / renderHeight,
        0.1,
        500
      );
      camera.position.copy(new THREE.Vector3().fromArray([-1, -4, 6]));
      camera.lookAt(new THREE.Vector3().fromArray([0, 4, -0]));
      camera.up = new THREE.Vector3().fromArray([0, -1, 0]).normalize();

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 1, 0);
      controls.update();

      const viewer = new GaussianSplats3D.Viewer({
        scene,
        selfDrivenMode: false,
        renderer: renderer,
        camera: camera,
        useBuiltInControls: false,
        rootElement: rootElement,
        gpuAcceleratedSort: true,
      });

      if (!viewer || !viewer.loadingSpinner) return;

      setLoading({
        step: 'Starting...',
        inProgress: true,
        percentage: -1,
        finished: false,
      });

      loadingPromiseRef.current = viewer.addSplatScene(url, {
        selfDrivenMode: false,
        splatAlphaRemovalThreshold: 5,
        halfPrecisionCovariancesOnGPU: true,
        compressionLevel: 1,
        showLoadingUI: false,
        position: [0, 0, 0],
        rotation: [0, 0, 0, 1],
        scale: [1.5, 1.5, 1.5],
        streamView: true,
        format: GaussianSplats3D.SceneFormat.Ply,
        onProgress: (percent: number, _: any, stage: any) => {
          if (stage === 0) {
            setShowLoadingIndicator(true);
            percent = Math.round(percent);
            setLoading({
              step: 'Loading...',
              inProgress: true,
              percentage: percent,
              finished: false,
            });
          } else if (stage === 1) {
            setLoading({
              step: 'Processing...',
              inProgress: true,
              percentage: -1,
              finished: false,
            });
          } else if (stage >= 2) {
            setShowLoadingIndicator(false);
            setLoading({
              step: '',
              inProgress: false,
              percentage: -1,
              finished: true,
            });

            mixpanel.track(MixpanelNames.ThreeJsPlyViewerLoaded, {
              status: 'success',
            });
          }
        },
      });

      resizeAbortRef.current = new AbortController();
      const resize = () => {
        if (!divRef.current) return;
        renderer.setSize(
          divRef.current.offsetWidth,
          divRef.current.offsetHeight
        );
        camera.aspect =
          divRef.current.offsetWidth / divRef.current.offsetHeight;
        camera.updateProjectionMatrix();
      };

      window.addEventListener('resize', resize, {
        signal: resizeAbortRef.current.signal,
      });

      viewerRef.current = viewer;
      scene.add(viewerRef.current.threeScene);
    },
    [divRef, url, loadedCallback, statsObject]
  );

  useEffect(() => {
    mixpanel.time_event(MixpanelNames.ThreeJsPlyViewerLoaded);
  }, []);

  useEffect(() => {
    if (!permissions) return;
    const has3dDebugPermission = hasPermission(
      LdFlags.Debug3dFeatures,
      permissions
    );

    setHas3dDebugPermission(has3dDebugPermission);
  }, [permissions]);

  useEffect(() => {
    if (!viewerRef.current) return;
    if (has3dDebugPermission) {
      // axis helper
      // const axesHelper = new THREE.AxesHelper(5);
      // axesHelper.name = 'axisHelper';
      // viewerRef.current.threeScene.add(axesHelper);
      drawStats();
    } else {
      // remove axis helper
      const selectedObject =
        viewerRef.current.threeScene.getObjectByName('axisHelper');
      viewerRef.current.threeScene.remove(selectedObject);
      // since we wont be drawing the stats, we can kick off update cycle
      update();
    }
  }, [viewerRef.current, has3dDebugPermission, drawStats]);

  useEffect(() => {
    if (selectedCapture) {
      setCaptureId(selectedCapture.id);
    }
  }, [selectedCapture]);

  useEffect(() => {
    if (!selectedCaptureJob) {
      return;
    }
    setSelectedCaptureJobId(selectedCaptureJob.id);
  }, [selectedCaptureJob]);

  useEffect(() => {
    if (!captureJobWithPlyInfo) return;
    if (!captureJobWithPlyInfo.plyDownloadUrl) {
      setError(PlyErrors.NO_PLY_DOWNLOAD_URL);
      return;
    }
    setError(undefined);
    setUrl(captureJobWithPlyInfo?.plyDownloadUrl ?? undefined);
  }, [captureJobWithPlyInfo]);

  useEffect(() => {
    if (!selectedCaptureJobId) {
      return;
    }
    // setLoading(true);
    setDownloading(true);
    refetchPlyDownload();
  }, [selectedCaptureJobId]);

  useEffect(() => {
    if (!captureId) {
      return;
    }
    refetchCapture();
  }, [captureId]);

  useEffect(() => {
    if (!capture || !selectedCaptureJobId) {
      return;
    }

    if (
      !selectedCaptureJob ||
      !['g1', 'g2'].includes(selectedCaptureJob?.mosaicEngine ?? '')
    ) {
      setError(PlyErrors.NO_CAPTURE_JOB);
      return;
    }
    setSelectedCaptureJobId(selectedCaptureJob.id);
  }, [capture]);

  useEffect(() => {
    if (!url) return;
    const _renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });
    const _scene = new THREE.Scene();
    drawScene(_scene, _renderer);

    return () => {
      resizeAbortRef.current?.abort();
      while (_scene.children.length > 0) {
        _scene.remove(_scene.children[0]);
      }

      viewerRef.current?.stop();
      viewerRef.current = undefined as any;
      _renderer.dispose();
      loadingPromiseRef.current.abort();
    };
  }, [url]);

  useEffect(() => {
    update();
  }, [statsObject]);

  useEffect(() => {
    const loadingElement = document.querySelector(
      '.loaderContainer > .message'
    ) as HTMLElement;
    if (!loadingElement) return;
    loadingElement.style.color =
      backgroundColor === BackgroundOptionsValues.Black ? '#fff' : '#000';
  }, [backgroundColor, url]);

  return (
    <div className="relative h-full w-full">
      {loading.inProgress && (
        <>
          <div className="absolute w-full h-1 bg-black loading-gradient"></div>
          {showLoadingIndicator && (
            <div
              className={`absolute flex z-40 pointer-events-none transition-all duration-300 top-2 ${
                sidebarOpen ? 'translate-x-80' : ''
              }`}
              style={{
                left: '2rem',
              }}
            >
              <div className="loaderContainer">
                <div className="loader text-white px-4 py-1 rounded">
                  {`${loading.step} ${
                    loading.percentage >= 0 ? `${loading.percentage}%` : ''
                  }`.trim()}
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {error && (
        <div className="w-full h-full items-center justify-center">
          There was an error loading the ply file. Please try again later.
        </div>
      )}
      {controlsAllowed && (
        <div className="absolute top-0 right-0 ">
          <BackgroundToggle setBackgroundColor={setBackgroundColor} />
        </div>
      )}
      <div ref={statsRef}></div>
      <div
        className={`h-full w-full flex flex-col overflow-hidden ${
          backgroundColor === BackgroundOptionsValues.Black ? 'bg-black' : ''
        }`}
        ref={divRef}
      ></div>
    </div>
  );
};

const BackgroundToggle = ({
  setBackgroundColor,
}: {
  setBackgroundColor: (color: BackgroundOptionsValues) => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const blackStyle = {
    '--fa-primary-opacity': 1,
  } as any;
  const greyStyle = {
    '--fa-primary-opacity': 0.7,
  } as any;
  const whiteStyle = {
    '--fa-primary-opacity': 0.2,
  } as any;

  const selectBackgroundOnChange = (background: BackgroundOptionsValues) => {
    setBackgroundColor(background);
  };
  return (
    <div className="flex flex-col mt-2 pr-2 ">
      {!expanded ? (
        <div className="flex flex-row justify-center items-center gap-2 w-8 rounded z-50 bg-white py-2">
          <FontAwesomeIcon
            icon={faCog}
            className="block m-auto cursor-pointer fa-lg cursor-pointer"
            title={'Viewer Settings'}
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center gap-2 w-8 rounded z-50 bg-white  py-2">
          <FontAwesomeIcon
            icon={faDisplay}
            className="block m-auto cursor-pointer fa-lg cursor-pointer "
            style={blackStyle}
            onClick={() =>
              selectBackgroundOnChange(BackgroundOptionsValues.Black)
            }
            title="Black Background"
          />
          <FontAwesomeIcon
            icon={faDisplay}
            className="block m-auto cursor-pointer fa-lg cursor-pointer"
            style={whiteStyle}
            onClick={() =>
              selectBackgroundOnChange(BackgroundOptionsValues.White)
            }
            title="White Background"
          />
          <FontAwesomeIcon
            icon={faChevronsUp}
            className="block m-auto cursor-pointer fa-lg pt-2"
            title="Collapse Viewer Settings"
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      )}
    </div>
  );
};
