import {
  IconDefinition,
  faCamera,
  faMapPin,
  faSpinner,
  faTreeDeciduous,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Vector3 } from 'three';

import {
  CaptureObject,
  useGetCaptureObjectsByCaptureExtractionJobId,
  useGetNonExtrationCaptureObjectsByCaptureId,
} from '@agerpoint/api';
import {
  CaptureObjectMarkerType,
  CaptureObjectMarkers,
  EventBusNames,
} from '@agerpoint/types';
import { LdFlags } from '@agerpoint/types';
import {
  eventBus,
  getUpdatedCaptureMarkers,
  hasPermission,
  useGlobalStore,
} from '@agerpoint/utilities';

import { CapturesViewerContext } from '../../captures-viewer/captures-viewer-context';

export const PotreeLayerToggleBarComponent = () => {
  const { captureId } = useParams();
  const { selectedCaptureExtractionJobId, viewerController } = useContext(
    CapturesViewerContext
  );

  const [showCameraPosition, setShowCameraPosition] = useState(true);
  const [showCaptureCustomMarkers, setShowCaptureCustomMarkers] =
    useState(true);
  const [showCaptureExtractionMarkers, setShowCaptureExtractionMarkers] =
    useState(true);
  const [viewerReady, setViewerReady] = useState(false);

  const {
    data: captureExtractionObjectsApiResponse,
    loading: captureExtractionObjectsLoading,
    refetch: refetchCaptureExtractionObjects,
  } = useGetCaptureObjectsByCaptureExtractionJobId({
    id: selectedCaptureExtractionJobId || 0,
    lazy: true,
  }) as unknown as {
    data: CaptureObject[];
    loading: boolean;
    refetch: () => void;
  };

  const { data: captureObjectsApiResponse, loading: captureObjectsLoading } =
    useGetNonExtrationCaptureObjectsByCaptureId({
      id: Number(captureId),
    }) as unknown as {
      data: CaptureObject[];
      loading: boolean;
    };

  const {
    captureObjectMarkers,
    permissions,
    actions: { setCaptureObjectMarkers },
  } = useGlobalStore();

  useEffect(() => {
    if (!selectedCaptureExtractionJobId) return;
    refetchCaptureExtractionObjects();
  }, [selectedCaptureExtractionJobId]);

  useEffect(() => {
    if (
      !viewerController ||
      !viewerController?.potreeController ||
      !viewerReady
    )
      return;
    const newCaptureObjects = captureObjectMarkers
      .sort((a, b) => a.id - b.id)
      .map((co) => {
        const coId = co.id.toString();
        const show =
          co.type === CaptureObjectMarkerType.ExtractionJob
            ? showCaptureExtractionMarkers
            : showCaptureCustomMarkers;
        viewerController?.potreeController?.toggleObjectVisibility(coId, show);
        viewerController?.potreeController?.toggleTextLabelById(coId, show);
        return {
          ...co,
          showMarker: show,
          showLabel: show,
        };
      });
    setCaptureObjectMarkers(newCaptureObjects);
  }, [
    showCaptureCustomMarkers,
    showCaptureExtractionMarkers,
    viewerController,
  ]);

  useEffect(() => {
    let result: CaptureObjectMarkers[] = [];

    if (captureExtractionObjectsApiResponse) {
      const captureObjectMarkersFromApi = getUpdatedCaptureMarkers(
        captureExtractionObjectsApiResponse,
        captureObjectMarkers
      );
      result = [...result, ...captureObjectMarkersFromApi];
    }

    if (captureObjectsApiResponse) {
      const captureObjectMarkersFromApi = getUpdatedCaptureMarkers(
        captureObjectsApiResponse,
        captureObjectMarkers
      );
      result = [...result, ...captureObjectMarkersFromApi];
    }
    viewerController?.potreeController?.clearAllMarkers();
    setCaptureObjectMarkers(result);
  }, [captureExtractionObjectsApiResponse, captureObjectsApiResponse]);

  useEffect(() => {
    if (!captureObjectMarkers.length || !viewerReady) {
      return;
    }

    captureObjectMarkers?.forEach((captureObject) => {
      if (captureObject?.x && captureObject?.y && captureObject?.z) {
        const coId = captureObject.id.toString();

        viewerController?.potreeController?.addExistingObjectLocation(
          EventBusNames.CaptureObjectPositionChanged,
          coId,
          new Vector3(captureObject.x, captureObject.y, captureObject.z),
          {
            fill: captureObject.markerFill,
            name: captureObject.name || '',
            visible: captureObject.showMarker,
            visibleLabel: captureObject.showLabel,
            type: captureObject.type,
            editable: captureObject.editable,
            clickable: true,
          }
        );
      }
    });
  }, [captureObjectMarkers, viewerController?.potreeController, viewerReady]);

  useEffect(() => {
    if (showCameraPosition) {
      eventBus.dispatch(EventBusNames.CaptureCameraPositionShowAllClicked, {
        detail: undefined,
      });
    } else {
      eventBus.dispatch(EventBusNames.CaptureCameraPositionHideAllClicked, {
        detail: undefined,
      });
    }
  }, [showCameraPosition]);

  // useEffect(() => {
  //   setViewerReady(false);
  //   if (Number.isNaN(eptId)) {
  //     viewer?.current?.togglePointCloudVisibility?.(false);
  //   } else {
  //     viewer?.current?.togglePointCloudVisibility?.(true);
  //   }
  // }, []);

  const handlePointCloudLoaded = () => {
    setViewerReady(true);
  };

  useEffect(() => {
    const eventId = eventBus.on(
      EventBusNames.PointCloudLoaded,
      handlePointCloudLoaded,
      true
    );
    return () => {
      eventBus.remove(
        EventBusNames.PointCloudLoaded,
        handlePointCloudLoaded,
        eventId
      );
    };
  }, []);

  const LayerToggleButton = ({
    icon,
    loading = false,
    toggledOn = true,
    onClick,
    title,
  }: {
    icon: IconDefinition;
    loading?: boolean;
    onClick?: () => void;
    toggledOn?: boolean;
    title?: string;
  }) => {
    const toggledOffLayerStyle = 'bg-gray-150 text-gray-500';

    return (
      <div className="px-0.5 py-px">
        <div
          title={title || ''}
          className={`w-full cursor-pointer rounded hover:bg-gray-400 flex justify-center items-center
          ${!toggledOn ? toggledOffLayerStyle : ' '}
          `}
          style={{ aspectRatio: '1/1' }}
          onClick={loading ? undefined : onClick}
        >
          <FontAwesomeIcon
            icon={loading ? faSpinner : icon}
            size={'lg'}
            spin={loading}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!captureObjectMarkers.length || !viewerReady) {
      return;
    }
    captureObjectMarkers?.forEach((captureObject) => {
      if (!captureObject?.x && !captureObject?.y && !captureObject?.z) return;
      if (!captureObject?.geom2D?.coordinates?.[0]) return;

      const hasApfPermission = hasPermission(
        LdFlags.CaptureObjectApfGeometry,
        permissions
      );
      const hasConvexHullPermission = hasPermission(
        LdFlags.CaptureObjectApfGeometryConvexHull,
        permissions
      );

      if (!hasApfPermission) return;
      const coId = captureObject?.id?.toString() || '';
      viewerController?.potreeController?.drawTrunkLine(
        captureObject.geom2D.coordinates[0] as number[][],
        coId
      );

      if (!hasConvexHullPermission) return;
      viewerController?.potreeController?.drawConvexHull(
        captureObject.geom2D.coordinates[0] as number[][],
        coId
      );
    });

    return () => {
      viewerController?.potreeController?.clearAllTrunkLines();
    };
  }, [
    selectedCaptureExtractionJobId,
    permissions,
    captureObjectMarkers,
    viewerReady,
    viewerController?.potreeController?.info?.viewerReady,
  ]);

  return (
    <div className="absolute top-0 bottom-0 right-14 z-30 m-auto flex text-black pointer-events-none">
      <div className="flex flex-col justify-center w-8">
        <div className="bg-white overflow-hidden text-center rounded pt-2 pb-2 pointer-events-auto">
          <LayerToggleButton
            title="Show/Hide Camera Position"
            icon={faCamera}
            onClick={() => setShowCameraPosition(!showCameraPosition)}
            toggledOn={showCameraPosition}
          />
          <LayerToggleButton
            title="Show/Hide Capture Objects"
            icon={faTreeDeciduous}
            loading={captureObjectsLoading}
            onClick={() =>
              setShowCaptureCustomMarkers(!showCaptureCustomMarkers)
            }
            toggledOn={showCaptureCustomMarkers}
          />
          <LayerToggleButton
            title="Show/Hide Extraction Objects"
            icon={faMapPin}
            loading={captureExtractionObjectsLoading}
            onClick={() =>
              setShowCaptureExtractionMarkers(!showCaptureExtractionMarkers)
            }
            toggledOn={showCaptureExtractionMarkers}
          />
        </div>
      </div>
    </div>
  );
};
