export const getDatePickerFormattedDate = (date: Date) => {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export const getDatePickerFormattedFromUtc = (date: Date) => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const localDate = new Date(date.getTime() - timezoneOffset * 60000);
  return getDatePickerFormattedDate(localDate);
};

export const getDateAsIsoUtcString = (selectedDateStr: string) => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const localDateSelected = new Date(`${selectedDateStr}`);
  const utcDateSelected = new Date(
    localDateSelected.getTime() + timezoneOffset * 60000
  );
  return utcDateSelected.toISOString();
};

export const endOfDay = (date?: Date) => {
  if (!date) {
    return undefined;
  }
  const utcDate = new Date();
  const utcHours = utcDate.getUTCHours();
  const newDate = new Date(date);
  newDate.setHours(utcHours, 59, 59, 999);
  return newDate;
};

// we want to ask the server for times in UTC, the server will not convert for us
export function convertDateToUtcBeginningOfDay(date: Date | undefined | null) {
  if (!date) return undefined;
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
}
// we want to ask the server for times in UTC, the server will not convert for us
export function convertDateToUtcEndOfDay(date: Date | undefined | null) {
  if (!date) return undefined;
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59,
      999
    )
  );
}
export const getDateString = (date?: string | undefined | null) => {
  if (date) {
    const dateObject = new Date(date);
    return dateObject.toLocaleDateString();
  }
  return '';
};
