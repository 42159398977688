import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';

import { Options } from '@agerpoint/types';

interface MultiSelectProps {
  options: Options[];
  selectedOptions: Options[];
  onChange: (selectedOptions: Options[]) => void;
  size?: 'sm' | 'xs';
}

interface MultiSelectLookup {
  [key: string]: Options;
}

export const MultiSelect = ({
  onChange,
  options,
  selectedOptions,
  size = 'sm',
}: MultiSelectProps) => {
  const usePrevious = (value: any): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const [selected, setSelected] = useState<MultiSelectLookup>({});
  const previous = usePrevious({
    selected,
  });

  useEffect(() => {
    const newSelected: MultiSelectLookup = { ...selected };
    selectedOptions.forEach((option) => {
      newSelected[option.value] = option;
    });
    setSelected(newSelected);
  }, [selectedOptions]);

  useEffect(() => {
    if (
      previous &&
      Object.values(previous.selected).length !== Object.values(selected).length
    ) {
      onChange(Object.values(selected));
    }
  }, [selected, previous, onChange]);

  const toggleSelected = (option: Options) => {
    setSelectedOptions(option);
  };

  const setSelectedOptions = (selectedOption: Options) => {
    const newSelected = { ...selected };
    if (newSelected[selectedOption.value] !== undefined) {
      delete newSelected[selectedOption.value];
    } else {
      newSelected[selectedOption.value] = selectedOption;
    }
    setSelected(newSelected);
  };

  return (
    <div className="w-full">
      {options.length ? (
        options.map((option) => (
          <div key={option.value} className="flex justify-between">
            <span
              className={`${
                size === 'xs' ? 'text-xs' : 'text-sm'
              }  text-gray-800 truncate`}
            >
              {option.name}
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCircleCheck}
                data-test-id={`multi-select-${option.value}`}
                className={`${
                  size === 'xs' ? 'text-lg' : 'text-xl'
                } cursor-pointer ${
                  selected[option.value] !== undefined
                    ? ''
                    : 'text-gray-300 hover:text-gray-500'
                }`}
                onClick={() => {
                  toggleSelected(option);
                }}
              />
            </span>
          </div>
        ))
      ) : (
        <div>No options available</div>
      )}
    </div>
  );
};
