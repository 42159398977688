import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminCropsQueries } from './admin-crops-queries';

export const AdminCropsNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  usePageTitle(() => 'Platform - Crops', []);

  const isViteApp = useIsViteApp();

  const { cropPostMutation } = useAdminCropsQueries();

  const formValidation = useFormValidation();
  const [crop, setCrop] = useState<APIModels.Crop>({});

  const createCrop = useCallback(async () => {
    if (cropPostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    cropPostMutation.mutate({
      data: crop,
    });
  }, [crop, formValidation, cropPostMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Crops',
              path: isViteApp ? '/app/admin/platform/crops' : '/admin/crops',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="crops-new-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/crops' + params);
            } else {
              navigate('/admin/crops' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Crop</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="crop-name-input"
          label={<Input.Label label="Name" required />}
          value={crop?.cropName || ''}
          setValue={(cropName) => {
            setCrop({ ...crop, cropName });
          }}
          error={
            <Input.Error error={formValidation.errors['crop-name-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
        <Input.Text.Single
          id="crop-display-name-input"
          label={<Input.Label label="Display Name" required />}
          value={crop?.cropDisplayName || ''}
          setValue={(cropDisplayName) => {
            setCrop({ ...crop, cropDisplayName });
          }}
          error={
            <Input.Error
              error={formValidation.errors['crop-display-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Display Name')],
          }}
        />
        <Input.Text.Single
          id="crop-plural-name-input"
          label={<Input.Label label="Plural Name" required />}
          value={crop?.pluralCropName || ''}
          setValue={(pluralCropName) => {
            setCrop({ ...crop, pluralCropName });
          }}
          error={
            <Input.Error
              error={formValidation.errors['crop-plural-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Plural Name')],
          }}
        />
        <div className="w-full flex flex-row justify-end py-4">
          <Button.Primary
            id="create-crop-button"
            label={'Create'}
            onClick={createCrop}
            loading={cropPostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
