import {
  faEye,
  faEyeSlash,
  faSquareDashed,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDebouncyEffect } from 'use-debouncy';

import { Input } from '@agerpoint/component';
import { useGlobalStore } from '@agerpoint/utilities';

interface QaqcCaptureObjectFiltersProps {
  setAttributeFilter: (value: string) => void;
  setNameFilter: (value: string) => void;
  hideValidatedFilter: boolean;
  setHideValidatedFilter: (value: boolean) => void;
  twoDFilter: boolean;
  setTwoDFilter: (value: boolean) => void;
}

export const QaqcCaptureObjectFilters = ({
  setAttributeFilter,
  setNameFilter,
  hideValidatedFilter,
  setHideValidatedFilter,
  twoDFilter,
  setTwoDFilter,
}: QaqcCaptureObjectFiltersProps) => {
  const [nameFilterInputValue, setNameFilterInputValue] = useState('');
  const [attributeFilterInputValue, setAttributeFilterInputValue] =
    useState('');

  const {
    twoDimensionDrawingIsActive,
    actions: { setTwoDimensionDrawing },
  } = useGlobalStore();

  useDebouncyEffect(
    () => {
      setNameFilter(nameFilterInputValue.trim());
      setAttributeFilter(attributeFilterInputValue.trim());
    },
    500,
    [nameFilterInputValue, attributeFilterInputValue]
  );

  return (
    <div className="w-full bg-gray-700 rounded text-xs p-1 flex flex-row gap-2 items-start">
      <div className="flex flex-col gap-1 w-2/3">
        <Input.Text.Single
          id="qaqc-object-name-filter"
          value={nameFilterInputValue}
          setValue={setNameFilterInputValue}
          placeholder="Filter by Object Name"
          placeholderIcon={Input.placeholderIcons.filter}
          style={Input.style.miniDark}
        />
        <Input.Text.Single
          id="qaqc-object-name-filter"
          value={attributeFilterInputValue}
          setValue={setAttributeFilterInputValue}
          placeholder="Filter by Attributes"
          placeholderIcon={Input.placeholderIcons.filter}
          style={Input.style.miniDark}
        />
      </div>
      <div className="flex flex-col w-1/3 h-full gap-1">
        <div
          className={`text-center flex items-center cursor-pointer
        justify-center border rounded h-1/2 gap-1
        ${
          hideValidatedFilter
            ? 'border-white text-white'
            : 'border-gray text-gray hover:border-gray-300'
        }`}
          onClick={(e) => {
            e.stopPropagation();
            setHideValidatedFilter(!hideValidatedFilter);
          }}
        >
          <FontAwesomeIcon icon={hideValidatedFilter ? faEyeSlash : faEye} />
          <span>Hide Validated</span>
        </div>
        {twoDimensionDrawingIsActive ? (
          <div
            className={`text-center flex items-center cursor-pointer
        justify-center border rounded h-1/2 gap-1
        ${
          twoDFilter
            ? 'border-white text-white'
            : 'border-gray text-gray hover:border-gray-300'
        }`}
            onClick={(e) => {
              e.stopPropagation();
              setTwoDFilter(!twoDFilter);
            }}
          >
            <FontAwesomeIcon icon={faSquareDashed} />
            <span>Apply 2D Filter</span>
          </div>
        ) : (
          <div className="h-1/2" />
        )}
      </div>
    </div>
  );
};
