import { useAuth } from 'oidc-react';
import { useEffect } from 'react';
import { UseGetReturn } from 'restful-react';

import { APIClient, APIUtils, User, useGetUser } from '@agerpoint/api';
import { Intermediate } from '@agerpoint/feature';
import { useAgerStore, useGlobalStore } from '@agerpoint/utilities';

interface UserSetupProps {
  children: JSX.Element;
}

export const UserSetup = ({ children }: UserSetupProps) => {
  const { data: userData } = useGetUser({}) as unknown as UseGetReturn<
    User,
    void,
    void,
    unknown
  >;

  const {
    user,
    actions: { setUser },
  } = useGlobalStore();

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData, setUser]);

  if (!user) {
    return <Intermediate message="Getting account information ..." />;
  }

  return children;
};

export const UserCloudSetup = ({ children }: UserSetupProps) => {
  const userQuery = APIClient.useGetUser({
    query: {
      queryKey: [APIUtils.QueryKey.currentUser],
      refetchInterval: APIUtils.getDuration({
        minutes: 1,
      }),
    },
  });

  const auth = useAuth();

  const {
    user: { setUser, userInfo, auth: userData },
  } = useAgerStore();

  useEffect(() => {
    if (!userQuery.data || !auth.userData) {
      return;
    }

    setUser({
      userInfo: userQuery.data,
      auth: auth.userData,
    });
  }, [auth, userQuery.data, setUser]);

  if (!userInfo || !userData) {
    return <Intermediate message="Getting account information ..." />;
  }

  return children;
};
