import {
  faArrowUpRightFromSquare,
  faCircleCheck,
  faFilterSlash,
  faLocation,
  faLocationPinSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as faSolidCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInfiniteQuery } from '@tanstack/react-query';
import { CapturesMapPopup } from 'libs/feature/src/capture-map-popover';
import { useMemo, useState } from 'react';

import { APIClient, APIModels } from '@agerpoint/api';
import { Button } from '@agerpoint/component';
import {
  ContextMenuGroupProps,
  Datatable,
  OpenLayerMap,
} from '@agerpoint/feature';
import {
  OpenLayerMapController,
  OpenMapLayer,
  defaultCaptureMapFeatureStyle,
  selectedCaptureMapFeatureStyle,
} from '@agerpoint/types';
import { environment, useItemSelection } from '@agerpoint/utilities';

interface AdminCapturesListMapProps {
  capturesQuery: ReturnType<
    typeof useInfiniteQuery<APIClient.Capture[], Error>
  >;
  hasFiltersApplied: boolean;
  clearFilters: () => void;
  captureSelection: ReturnType<
    typeof useItemSelection<number, APIModels.Capture>
  >;
  filter: APIModels.CaptureFilter | undefined;

  rowOnClick?: (
    row: APIModels.Capture,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  contextMenuItems: (item: APIModels.Capture) => ContextMenuGroupProps[];
}

export const AdminCapturesListMap = ({
  capturesQuery,
  hasFiltersApplied,
  captureSelection,
  rowOnClick,
  clearFilters,
  filter,
}: AdminCapturesListMapProps) => {
  const [mapController, setMapController] = useState<OpenLayerMapController>();

  const captures = useMemo(
    () => capturesQuery.data?.pages.flatMap((p) => p) ?? [],
    [capturesQuery.data?.pages]
  );

  return (
    <div className="w-full h-full flex flex-row">
      <div className="w-1/3">
        <Datatable
          id="admin-captures-map-list"
          rowHeight={30}
          style={{
            tableWrapperStyle: 'bg-white rounded-l-md',
            headerWrapperStyle:
              'px-4 text-xs text-gray-700 font-normal border-b border-gray-500',
            rowWrapperStyle: 'px-4 items-center text-sm hover:bg-gray-100',
            rowStyle: 'border-b border-gray-200',
            headerStyle: 'pr-1 py-3 h-full flex items-center',
            cellStyle: 'pr-1 flex items-center',
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching captures',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No captures yet',
                  message: 'Upload captures with the Agerpoint Capture App',
                  action: () => {
                    const a = document.createElement('a');
                    a.href =
                      'https://apps.apple.com/sg/app/agerpoint-capture/id1629732675';
                    a.target = '_blank';
                    a.click();
                  },
                  actionIcon: (
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  ),
                  actionLabel: 'Get the Capture App',
                }
          }
          error={
            capturesQuery.isError
              ? {
                  title: 'There was a problem loading captures',
                  message: 'Try refreshing the page',
                  action: () => capturesQuery.refetch(),
                }
              : undefined
          }
          data={captures ?? []}
          loading={
            capturesQuery.isFetching ||
            capturesQuery.isFetchingNextPage ||
            filter === undefined
          }
          pagination={{
            threshold: 10,
            loadNextPage: () => {
              if (
                capturesQuery.isFetching ||
                capturesQuery.isFetchingNextPage ||
                !capturesQuery.hasNextPage ||
                filter === undefined
              ) {
                return;
              }

              capturesQuery.fetchNextPage();
            },
          }}
          columns={[
            {
              label: 'Name',
              value: (row) => row.captureName,
              style: {
                headerStyle: 'overflow-visible',
              },
              name: 'captureName',
              flex: 11,
            },
            {
              label: '',
              value: (row) => {
                return row.latitude && row.longitude ? (
                  <FontAwesomeIcon
                    icon={faLocation}
                    className={
                      'text-gray-300 cursor-pointer hover:text-gray-900'
                    }
                    onClick={() => {
                      if (row.latitude && row.longitude) {
                        mapController?.zoomMapToLonLat?.([
                          row.longitude,
                          row.latitude,
                        ]);
                      }
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faLocationPinSlash}
                    className={'text-gray-300'}
                  />
                );
              },
              flex: 1,
              style: {
                columnWrapperStyle: 'flex justify-center',
                headerStyle: 'overflow-visible',
              },
            },
            {
              label: captureSelection.isEverythingSelected ? (
                <div className="p-1">
                  <Button.Small
                    id="captures-list-deselect-all"
                    label={'Deselect All'}
                    onClick={() => {
                      captureSelection.toggleSelectionEverything();
                    }}
                    icon={faSolidCircleCheck}
                    iconColor={'text-accent'}
                  />
                </div>
              ) : (
                <div className="p-1">
                  <Button.Small
                    id="captures-list-select-all"
                    label={'Select All'}
                    onClick={() => {
                      captureSelection.toggleSelectionEverything();
                    }}
                    icon={faCircleCheck}
                  />
                </div>
              ),
              value: function Select(row, index) {
                const isSelected = row.id
                  ? captureSelection.isSelected(row.id)
                  : false;

                return (
                  <div className="p-1">
                    <Button.Icon
                      id={`captures-list-select-one-${index}`}
                      icon={isSelected ? faSolidCircleCheck : faCircleCheck}
                      onClick={(e) => {
                        if (!row.id) {
                          return;
                        }
                        if (e.shiftKey) {
                          captureSelection.addBulkSelectionUntilItem(
                            row.id,
                            row
                          );
                        } else {
                          captureSelection.toggleSelection(row.id, row);
                        }
                      }}
                      iconColor={isSelected ? 'text-accent' : undefined}
                    />
                  </div>
                );
              },
              style: {
                columnWrapperStyle: 'flex justify-end',
                headerStyle: 'overflow-visible',
              },
              flex: 1,
            },
          ]}
          cellOnClick={(columnName) => {
            const clickable = ['captureName'];
            if (clickable.includes(columnName)) {
              return rowOnClick;
            }
            return;
          }}
        />
      </div>
      <div className="w-2/3 rounded-r-md overflow-hidden border-white border-y-2 border-r-2">
        <OpenLayerMap
          id="admin-captures-map"
          bingKey={environment.bing_api_key}
          controller={setMapController}
          mapLayers={{
            used: [
              OpenMapLayer.Hybrid,
              OpenMapLayer.Aerial,
              OpenMapLayer.RoadMap,
            ],
            initial: OpenMapLayer.Aerial,
          }}
          featureLayer={{
            data: capturesQuery.data?.pages.flatMap((p) => p) ?? [],
            styles: {
              default: defaultCaptureMapFeatureStyle,
              selected: selectedCaptureMapFeatureStyle,
            },
            featureGenerator: (data: any) => {
              if (!data.longitude || !data.latitude || !data.id) {
                return null;
              }

              return {
                id: data.id,
                longitude: data.longitude,
                latitude: data.latitude,
                name: data.captureName ?? undefined,
                style: !captureSelection.isSelected(data.id)
                  ? 'default'
                  : 'selected',
              };
            },
            popupGenerator: (id: any) => {
              const capture = captures.find((c) => id === c.id);
              if (!capture) {
                return null;
              }

              return (
                <CapturesMapPopup
                  capture={capture}
                  selection={captureSelection}
                />
              );
            },
          }}
          callbacks={{
            onDragBoxSelect: (ids: any) => {
              const c = captures
                .filter((c) => ids.includes(c.id ?? -1))
                .map((c) => ({
                  key: c.id ?? -1,
                  value: c,
                }));
              captureSelection.addSelectionList(c);
            },
          }}
          dependencies={[captureSelection.selectionSize, captures]}
        />
      </div>
    </div>
  );
};
