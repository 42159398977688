import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';

interface ButtonPrimaryProps {
  id: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  icon?: IconDefinition;
  expand?: boolean;
  cloud?: boolean;
}

export const ButtonPrimary = ({
  id,
  buttonRef,
  onClick,
  disabled,
  loading,
  label,
  icon,
  expand,
  cloud,
}: ButtonPrimaryProps) => {
  const className = useMemo(() => {
    let classes = ` bg-primary border-transparent font-bold transition-all px-4
    duration-200 shadow-sm focus:outline-none outline-none focus:ring-2 ring-offset-2 ring-primary
    flex flex-row items-center justify-center relative`;

    if (expand) {
      classes += ' w-full ';
    }

    if (cloud) {
      classes += ' rounded-lg ';
    } else {
      classes += ' rounded ';
    }

    if (loading) {
      classes += ' opacity-50 text-disabled ';
    } else if (disabled) {
      classes += ' cursor-not-allowed opacity-50 text-disabled ';
    } else {
      classes += ' hover-overlay-20 text-white';
    }

    return classes.trim();
  }, [disabled, loading, expand, cloud]);

  return (
    <button
      data-test-id={id}
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled || loading}
      style={{
        height: '38px',
      }}
      ref={buttonRef}
    >
      {loading && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="w-4 h-4 flex items-center justify-center">
            <FontAwesomeIcon
              icon={faCircleNotch}
              spin
              className="w-full h-full"
            />
          </div>
        </div>
      )}
      <div
        className={`flex flex-row gap-2 items-center ${
          loading ? 'opacity-0' : ''
        }`.trim()}
      >
        {icon && (
          <div className="w-4 h-4 flex items-center justify-center">
            <FontAwesomeIcon icon={icon} className="w-full h-full" />
          </div>
        )}
        {label && <span>{label}</span>}
      </div>
    </button>
  );
};
