import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import { useIsViteApp, useLookupTable, useToasts } from '@agerpoint/utilities';

export const useAdminPointcloudsQueries = (
  filter?: APIModels.EptPointcloudFilter
) => {
  const toasts = useToasts();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const pointcloudPostMutation = APIClient.usePostEptPointcloud({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.pointclouds],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityCreated('pointcloud'));

        if (isViteApp) {
          navigate('/app/admin/platform/pointclouds' + params);
        } else {
          navigate(`/admin/pointclouds` + params);
        }
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create pointcloud!'));
      },
    },
  });
  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const pointcloudsQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.pointclouds,
      APIUtils.QueryKey.infinite,
      { filter },
    ],
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    enabled: filter !== undefined,
    queryFn: async ({ pageParam }) =>
      APIClient.getFilteredPagedEptPointclouds(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.EptPointcloudFilter
      ),
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const pointcloudArchiveDeleteMutation = APIClient.useDeleteEptPointcloud({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.pointclouds],
        });
      },
      onSuccess: () => {
        toasts.add(toasts.prepare.entityArchived('pointcloud'));
      },
      onError: () => {
        toasts.add(toasts.prepare.error('Failed to archive pointcloud!'));
      },
    },
  });

  return {
    pointcloudPostMutation,
    organizationsQuery,
    pointcloudsQuery,
    pointcloudArchiveDeleteMutation,
    organizationsLookupTable,
  };
};
