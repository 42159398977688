import { useBackgroundTaskManager } from 'libs/feature/src/background-task-manager/background-task-manager';
import {
  CapturePipelineJobTable,
  TriggerHighResJob,
} from 'libs/feature/src/captures-table/captures-table-utilities';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  APIClient,
  APIUtils,
  useGetMosaicEngines,
  usePostCaptureHighResWithMlModelJob,
} from '@agerpoint/api';
import { ConfirmModal, QuestionInfo } from '@agerpoint/component';
import {
  BackgroundTaskArgs,
  BackgroundTaskResult,
  LdFlags,
} from '@agerpoint/types';
import { getVariation, useGlobalStore, useToasts } from '@agerpoint/utilities';

import { CardOptions } from './capture-details-section-utils';

export const CaptureDetailsSectionModels = () => {
  const hdProcessing = `By default, captures are processed at a lower definition. Click the 'High Definition Preprocessing' button to reprocess this captureQuery.data in High Definition`;
  const { permissions } = useGlobalStore();
  const { addTaskGroup, createBackgroundTask } = useBackgroundTaskManager();

  const params = useParams();

  const id = useMemo(() => {
    return params.captureId ?? params.id;
  }, [params]);

  const captureQuery = APIClient.useGetCaptureById(Number(id), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(id) }],
      enabled: Number.isSafeInteger(Number(id)),
    },
  });

  const toasts = useToasts();

  const [captureId, setCaptureId] = useState<number>(NaN);
  const [idToTriggerHighRes, setIdToTriggerHighRes] = useState<number>(NaN);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const { data: availableMosaicEngines } = useGetMosaicEngines({});
  const { mutate: postCaptureHighResJobWithMosaicModel } =
    usePostCaptureHighResWithMlModelJob({
      id: NaN,
      engineId: NaN,
      modelId: NaN,
    });

  const addJobsToQueue = (mosaicEngineId: number) => {
    toasts.add({
      title: 'Capture processing job(s) added to queue!',
      type: 'success',
    });
    const task: BackgroundTaskArgs = createBackgroundTask(
      `Start Capture Job`,
      async (resolve, reject, abortSignal, groupId, taskId) => {
        if (!captureQuery.data) return;
        await postCaptureHighResJobWithMosaicModel(
          {},
          {
            pathParams: {
              id: captureQuery.data.id as number,
              engineId: mosaicEngineId,
              modelId: 'None' as unknown as number,
            },
            signal: abortSignal,
          }
        );
        resolve({ type: BackgroundTaskResult.success });
      }
    );

    addTaskGroup({
      groupDesc: 'Triggering Capture Processing Job(s)',
      groupTasks: [task],
      isCancellable: true,
    });
  };

  useEffect(() => {
    if (params.id) {
      setCaptureId(parseInt(params.id));
    }
  }, [params.id]);

  const confirmTriggerHighRes = () => {
    const pipelineTypeVariation: string | undefined =
      (getVariation(LdFlags.HighDefinitionJobType, permissions) as string) ||
      undefined;

    const findMosaicEngine = availableMosaicEngines?.find(
      (engine) => engine.name === pipelineTypeVariation
    );
    if (findMosaicEngine && findMosaicEngine.id) {
      addJobsToQueue(findMosaicEngine?.id);
    } else {
      setIdToTriggerHighRes(captureId);
    }

    setShowConfirmModal(false);
  };

  return (
    <>
      {/* <div className="">
        <div className="flex flex-col justify-start">
          <div className="pb-2 w-48 flex justify-between text-sm font-bold">
            <span>Processing Options</span> <QuestionInfo text={hdProcessing} />
          </div>
          <NewProcessingOptions
            hdCallback={useCallback(() => {
              setShowConfirmModal(true);
            }, [setShowConfirmModal])}
            sizeStr={`${captureQuery.data?.fileSize} MB`}
          />
        </div>

        <TriggerHighResJob id={idToTriggerHighRes} />

        <ConfirmModal
          isOpen={showConfirmModal}
          canConfirm={true}
          title="Process High Definition 3D Model"
          message={<HdConfirmMessage size={captureQuery.data?.fileSize} />}
          confirm={{
            label: 'Confirm',
            callback: confirmTriggerHighRes,
          }}
          close={{
            label: 'Cancel',
            callback: () => {
              setShowConfirmModal(false);
            },
          }}
          theme="solidDarkBlue"
        />
      </div> */}

      <div className="font-bold pb-2 text-sm" data-test-id="all-3d-models-div">
        All 3D Models
      </div>
      <CapturePipelineJobTable />
    </>
  );
};

const HdConfirmMessage = ({ size }: { size: number | null | undefined }) => {
  return (
    <>
      <span className="font-bold">{`${size} MB`}</span>
    </>
  );
};

const NewProcessingOptions = ({
  hdCallback,
  sizeStr,
}: {
  hdCallback: () => void;
  sizeStr: string;
}) => {
  const list = [
    {
      icon: 'high-definition',
      desc: 'Unlock unparalleled precision with our cutting-edge HD processing technology.',
      title: '3D Model',
      subTitle: sizeStr,
      selectCallback: hdCallback,
      buttonText: 'Process Now',
      id: 'hd',
    },
  ];
  return <CardOptions cardOptions={list} />;
};
