import { FeatureLike } from 'ol/Feature';

interface FeatureInfo {
  layerName: string;
  strokeColor: string;
  feature: FeatureLike;
}

export function FeatureInfo({ layerName, strokeColor, feature }: FeatureInfo) {
  const numberFormatter = new Intl.NumberFormat();
  const properties = feature.getProperties();
  const rows = Object.keys(properties)
    .map((key) => ({
      key,
      datatype: typeof feature.get(key),
      value: feature.get(key),
    }))
    .filter(({ datatype }) => ['string', 'number'].indexOf(datatype) >= 0);

  return (
    <div className="flex flex-row items-center  ">
      <div className="-ml-8 w-8 h-2" style={{ backgroundColor: strokeColor }} />
      <div className=" bg-green rounded pointer-events-auto  ">
        <div className="px-4 py-2 text-white">
          {layerName} / #{feature.get('tree_num')}
        </div>
        <div className="max-h-96 overflow-auto">
          <table className="table-auto w-full border-collapse ">
            <tbody>
              {rows.map(({ key, datatype, value }) => {
                return (
                  <tr key={key} className="bg-white even:bg-gray-50">
                    <td className="px-4 border-r border-gray-200 py-1">
                      {key}
                    </td>
                    <td className="px-4 py-1">
                      {datatype === 'number'
                        ? numberFormatter.format(value)
                        : value}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
