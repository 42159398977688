export const defaultInitialPageParam = { skip: 0, take: 30 };
export const defaultGetNextPageParam = <T,>(lastPage: T[], allPages: T[][]) => {
  if (lastPage.length === 0) {
    return undefined;
  }
  return {
    skip: allPages.reduce((acc, curr) => acc + curr.length, 0),
    take: 30,
  };
};

export const defaultInitialPageParamWithTake = (take: number) => ({
  skip: 0,
  take: take,
});

export const defaultGetNextPageParamWithTake =
  (take: number) =>
  <T,>(lastPage: T[], allPages: T[][]) => {
    if (lastPage.length === 0) {
      return undefined;
    }
    return {
      skip: allPages.reduce((acc, curr) => acc + curr.length, 0),
      take: take,
    };
  };
