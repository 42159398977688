export const createFilename = (name: string, extension: string) => {
  const date = new Date();
  const YYYY = date.getFullYear();
  const MM = date.getMonth() + 1;
  const dd = date.getDate();
  const hh = date.getHours();
  const mm = date.getMinutes();
  const ss = date.getSeconds();
  return `${name}-${YYYY}-${MM}-${dd}-${hh}-${mm}-${ss}.${extension}`;
};
