import { useEffect, useState } from 'react';

import {
  Capture,
  CustomerAnalytic,
  usePostAnalyticRequest,
} from '@agerpoint/api';
import { DialogModal } from '@agerpoint/component';

import {
  CaptureDetailsSectionAnalytics,
  CaptureDetailsSectionAnalyticsStepper,
} from '../../captures-shared/capture-details-modal';
import { BulkCaptureAnalyticRequestTable } from './bulk-capture-analtyic-request-table';

export const BulkCaptureAnalyticRequestModal = ({
  showAnalyticsModal,
  setShowAnalyticsModal,
  selectedCaptures,
}: {
  showAnalyticsModal: boolean;
  setShowAnalyticsModal: (show: boolean) => void;
  selectedCaptures: Capture[];
}) => {
  const [selectedAnalytic, setSelectedAnalytic] = useState<CustomerAnalytic>(
    {}
  );
  const bulkAnalyticSelected = (
    selCap: Capture[],
    selAna: CustomerAnalytic
  ) => {
    setSelectedAnalytic(selAna);
  };

  const { mutate: postAnalyticRequest } = usePostAnalyticRequest({});

  const clearState = () => {
    setSelectedAnalytic({});
  };

  return (
    <DialogModal
      open={showAnalyticsModal}
      title={`Analytics For Multiple Captures (${selectedCaptures.length})`}
      handleCloseDialog={() => {
        setShowAnalyticsModal(false);
        clearState();
      }}
      size={'large'}
      testId="bulk-capture-analytics-modal"
    >
      <div className="w-full p-1">
        {selectedAnalytic?.analyticId && selectedCaptures?.length ? (
          // <CaptureDetailsSectionAnalyticsStepper
          //   isBulk={true}
          //   selectedCaptures={selectedCaptures}
          //   selectedBulkAnalytic={selectedAnalytic}
          //   setShowAnalyticsModal={setShowAnalyticsModal}
          //   postAnalyticRequest={postAnalyticRequest}
          //   resetSelectedAnalytic={clearState}
          // />
          <BulkCaptureAnalyticRequestTable
            selectedCaptures={selectedCaptures}
            selectedAnalytic={selectedAnalytic}
            setSelectedAnalytic={setSelectedAnalytic}
            postAnalyticRequest={postAnalyticRequest}
          />
        ) : (
          <CaptureDetailsSectionAnalytics
            selectedCaptures={selectedCaptures}
            isBulk={true}
            bulkAnalyticSelected={bulkAnalyticSelected}
          />
        )}
      </div>
    </DialogModal>
  );
};
