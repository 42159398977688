import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';

interface SidebarSpinnerProps {
  message?: string;
}

export function SidebarSpinner({ message }: SidebarSpinnerProps) {
  return (
    <div className="mt-10 text-center">
      <FontAwesomeIcon
        icon={faCircleNotch}
        className="fa-spin text-4xl text-green"
      />
      {message && <p className="mt-5">{message}</p>}
    </div>
  );
}
