import { useCallback } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { BreadCrumbs, Button, TabsComponent } from '@agerpoint/component';
import {
  TabNames,
  TabThemes,
  getFirstActiveEptId,
  isCaptureReadyToViewInLegacyLayout,
  isCaptureReadyToViewInNewLayout,
} from '@agerpoint/types';
import {
  useIsViteApp,
  useNewLayout,
  usePageTitle,
  useToasts,
} from '@agerpoint/utilities';

import { useAdminCapturesQueries } from '../admin-captures-queries';

export const AdminCapturesDetailsBase = () => {
  usePageTitle(() => 'Platform - Captures', []);

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { captureId } = useParams();

  const toasts = useToasts();

  const { captureQuery, captureArchiveMutation, captureUnarchiveMutation } =
    useAdminCapturesQueries();

  const { showNewLayout } = useNewLayout();

  const archiveCapture = useCallback(() => {
    if (
      captureArchiveMutation.isPending ||
      captureUnarchiveMutation.isPending ||
      captureQuery.isLoading
    ) {
      return;
    }

    captureArchiveMutation.mutate({
      id: Number(captureId),
      data: {
        ...captureQuery.data,
        archived: true,
      },
    });
  }, [
    captureQuery.isLoading,
    captureQuery.data,
    captureId,
    captureUnarchiveMutation.isPending,
    captureArchiveMutation,
  ]);

  const unarchiveCapture = useCallback(() => {
    if (
      captureArchiveMutation.isPending ||
      captureUnarchiveMutation.isPending ||
      captureQuery.isLoading
    ) {
      return;
    }

    captureUnarchiveMutation.mutate({
      id: Number(captureId),
      data: {
        ...captureQuery.data,
        archived: false,
      },
    });
  }, [
    captureQuery.isLoading,
    captureQuery.data,
    captureId,
    captureArchiveMutation.isPending,
    captureUnarchiveMutation,
  ]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Captures',
              path: isViteApp
                ? '/app/admin/platform/captures'
                : '/admin/captures',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="capture-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/captures' + params);
            } else {
              navigate('/admin/captures' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">{captureQuery.data?.captureName}</h1>
      </div>
      <div className="px-4">
        <TabsComponent
          tabs={[
            {
              label: 'Details',
              link: 'details',
              type: TabNames.LINK,
              params,
            },
            {
              label: 'Analytics',
              link: 'analytics',
              type: TabNames.LINK,
              params,
            },
            {
              label: '3D Models',
              link: '3d-models',
              type: TabNames.LINK,
              params,
            },
            {
              label: captureQuery?.data?.archived ? 'Restore' : 'Archive',
              type: TabNames.BUTTON,
              buttonClickHandler: () => {
                if (captureQuery?.data?.archived) {
                  const confirm = window.confirm(
                    'Are you sure you want to restore this capture?'
                  );
                  if (confirm) {
                    unarchiveCapture();
                  }
                } else {
                  const confirm = window.confirm(
                    'Are you sure you want to archive this capture?'
                  );
                  if (confirm) {
                    archiveCapture();
                  }
                }
              },
              buttonTheme: captureQuery?.data?.archived
                ? undefined
                : TabThemes.RED,
            },
            {
              label: 'Open In Viewer',
              type: TabNames.BUTTON,
              buttonClickHandler: () => {
                if (isViteApp) {
                  navigate(`/app/capture/${captureQuery.data?.id}`, {
                    state: { params },
                  });
                } else {
                  const canViewCapture = showNewLayout
                    ? isCaptureReadyToViewInNewLayout(captureQuery.data)
                    : isCaptureReadyToViewInLegacyLayout(captureQuery.data);

                  if (!canViewCapture) {
                    toasts.add({
                      title: 'Capture not ready.',
                      message:
                        'This capture is not ready or is missing a complete pointcloud.',
                      type: 'info',
                    });
                    return;
                  }

                  const url = showNewLayout
                    ? `/captures/${captureQuery.data?.id}`
                    : `/captures/${captureQuery.data?.id}/${getFirstActiveEptId(
                        captureQuery.data
                      )}`;
                  navigate(url, {
                    state: { params },
                  });
                }
              },
            },
          ]}
        />
      </div>

      <Outlet />
    </div>
  );
};
