import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useProject } from 'libs/page/src/project-page/state/use-project';
import { Feature } from 'ol';
import { createEmpty, extend } from 'ol/extent';
import { LineString, Point, Polygon } from 'ol/geom';
import { useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { APIClient, APIUtils } from '@agerpoint/api';
import { ProjectState } from '@agerpoint/types';
import { Formatters, uuidRegex } from '@agerpoint/utilities';

export const useMap2DFeatureDrawingQueries = () => {
  const { id: projectUuid } = useParams();
  const queryClient = useQueryClient();

  const { saveNewGroup, saveNewLayer } = useProject(getGroupsState, shallow);

  const projectQuery = APIClient.useGetProjectByUuid(projectUuid as string, {
    query: {
      queryKey: [APIUtils.QueryKey.projects, { projectUuid }],
      enabled: !!projectUuid && uuidRegex.test(projectUuid),
    },
  });

  const projectLayerGroupsQuery = APIClient.useGetProjectLayerGroups(
    projectUuid as string,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.projects,
          { projectUuid },
          APIUtils.QueryKey.projectLayerGroups,
        ],
        enabled: !!projectUuid && uuidRegex.test(projectUuid),
      },
    }
  );

  const featureDrawingPostMutation = useMutation({
    mutationFn: async (data: { features: Feature[] }) => {
      if (!projectQuery.data || !projectLayerGroupsQuery.data) {
        throw new Error('Project or layer groups not found');
      }

      if (!projectQuery.data?.customerId) {
        throw new Error('Customer ID not found');
      }

      // create geometry collection
      const name = `Annotation - ${Formatters.DateTime.Regular(
        new Date().toISOString()
      )}`;

      const geometryCollection = await APIClient.postGeometryCollection({
        customerId: projectQuery.data.customerId,
        name,
      });

      if (!geometryCollection.id) {
        throw new Error('Geometry collection ID not found');
      }

      await APIClient.postBulkGeometries(
        data.features
          .map((feature) => {
            const geometry = feature.getGeometry();

            if (geometry instanceof Polygon) {
              return {
                geometryCollectionId: geometryCollection.id,
                geom: {
                  type: 'Polygon',
                  coordinates: geometry.getCoordinates(),
                },
              } as unknown as APIClient.ApGeometry;
            }

            if (geometry instanceof LineString) {
              return {
                geometryCollectionId: geometryCollection.id,
                geom: {
                  type: 'LineString',
                  coordinates: geometry.getCoordinates(),
                },
              } as unknown as APIClient.ApGeometry;
            }

            if (geometry instanceof Point) {
              return {
                geometryCollectionId: geometryCollection.id,
                geom: {
                  type: 'Point',
                  coordinates: geometry.getCoordinates(),
                },
              } as unknown as APIClient.ApGeometry;
            }

            return null;
          })
          .filter((x) => x) as APIClient.ApGeometry[]
      );

      // Check if the layer group exists
      let layerGroup = projectLayerGroupsQuery.data.find(
        (g) => g.name === 'Annotations'
      );

      if (!layerGroup) {
        const extent = createEmpty();
        for (const feature of data.features) {
          const geometry = feature.getGeometry();
          if (!geometry) {
            continue;
          }
          extend(extent, geometry.getExtent());
        }

        // create layer group
        layerGroup = await APIClient.postLayerGroup({
          name: 'Annotations',
          projectId: projectQuery.data.id,
          visible: true,
          icon: 'Flag',
          color: '#2E9C73',
        });

        if (!layerGroup.id) {
          throw new Error('Layer group ID not found');
        }
        saveNewGroup({
          id: layerGroup.id,
          name: layerGroup.name,
          icon: {
            name: layerGroup.icon as string,
            color: layerGroup.color as string,
          },
          zIndex: 1,
          visible: layerGroup.visible as boolean,
          extent: extent,
          layers: [],
        });
      }

      if (!layerGroup.id) {
        throw new Error('Layer group ID not found');
      }

      // create layer
      const layer = await APIClient.postLayer({
        name,
        layerTypeId: 8,
        entityId: geometryCollection?.id,
        visible: true,
        zIndex: 1,
        layerGroupId: layerGroup.id,
      });

      saveNewLayer(
        layerGroup.id,
        {
          ...layer,
        },
        1
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.projects,
          { projectUuid },
          APIUtils.QueryKey.projectLayerGroups,
        ],
      });

      queryClient.invalidateQueries({
        queryKey: [APIUtils.QueryKey.projects, { projectUuid }],
      });
    },
  });

  return {
    featureDrawingPostMutation,
  };
};

function getGroupsState({
  actions: {
    groups: { saveNew: saveNewGroup },
    layers: { saveNew: saveNewLayer },
  },
}: ProjectState) {
  return {
    saveNewGroup,
    saveNewLayer,
  };
}
