import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';

import { ButtonCloudText } from './button-cloud-text';

interface ButtonTextProps {
  id: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  label: string;
  icon?: IconDefinition;
  iconColor?: string;
}

export const ButtonText = ({
  id,
  onClick,
  disabled,
  label,
  icon,
  iconColor,
}: ButtonTextProps) => {
  const className = useMemo(() => {
    let classes = ` transition-all ring-gray-light focus:ring-1 ring-offset-1
    duration-200 focus:outline-none outline-none underline rounded
    flex flex-row items-center justify-center text-sm `;

    if (disabled) {
      classes += 'cursor-not-allowed opacity-50 text-disabled ';
    } else {
      classes += 'text-primary hover:decoration-double ';
    }

    return classes.trim();
  }, [disabled]);

  return (
    <button
      data-test-id={id}
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled}
      style={{
        height: '26px',
      }}
    >
      <div className="flex flex-row items-center">
        <div className="leading-none">{label}</div>
        {icon && (
          <div className="flex items-center justify-center h-4 w-4">
            <FontAwesomeIcon
              icon={icon}
              className={`w-full h-full ${iconColor}`.trim()}
            />
          </div>
        )}
      </div>
    </button>
  );
};

ButtonText.Cloud = ButtonCloudText;
