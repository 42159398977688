import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnalyticStatusIcon } from 'libs/feature/src/captures-viewer/captures-viewer-sidebar3/subcomponents/analytic-status-icon';

import { IconTypes, PrimaryButton, SvgElement } from '@agerpoint/component';
import { AnalyticRequestStatus } from '@agerpoint/types';
import { convertNameToCamelCase, getColorByStatus } from '@agerpoint/utilities';

export interface CardOption {
  id: string;
  icon: string | null | undefined;
  desc: string;
  title: string;
  subTitle?: string;
  buttonText?: string;
  selectCallback?: (id: number) => void;
  disabled?: boolean;
  disabledMessage?: string;
  status?: AnalyticRequestStatus;
  priority?: number;
}
export const CardOptions = ({ cardOptions }: { cardOptions: CardOption[] }) => {
  return (
    <div className="flex w-full pb-4 px-4 items-center justify-start flex-wrap">
      {cardOptions.map((item, index) => {
        return (
          <div
            className={`w-full sm:w-1/2 md:w-1/3 relative ${
              item.disabled ? 'pointer-event-none' : ''
            }`}
            key={index}
          >
            <div
              className={`relative flex flex-col h-56 m-1 max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow ${
                item.disabled ? '' : 'hover:border-green'
              } `}
            >
              <div className="absolute -top-1 -left-1">
                <AnalyticStatusIcon
                  status={item.status}
                  spin={item.status === AnalyticRequestStatus.PROCESSING}
                />
              </div>
              {item.disabled && <DisabledCover />}

              <div className="flex flex-row justify-end flex-none">
                {item.status !== AnalyticRequestStatus.UNKNOWN && (
                  <div style={{ color: getColorByStatus(item.status) }}>
                    {item.status}
                  </div>
                )}
                {item.subTitle &&
                  item.status === AnalyticRequestStatus.UNKNOWN && (
                    <span
                      className={`bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-green-400`}
                    >
                      {item.subTitle}
                    </span>
                  )}
              </div>
              <h5
                className={`mb-2 text-2xl font-semibold tracking-tight text-gray-900 truncate flex flex-row flex-none`}
              >
                <div>
                  <SvgElement
                    className="h-6 mr-2"
                    type={
                      convertNameToCamelCase(`fa-${item.icon}`) as IconTypes
                    }
                  />
                </div>
                <span className="truncate">{item.title}</span>
              </h5>
              <div className="mb-3 font-normal text-gray-500 text-sm flex-grow flex flex-col overflow-auto">
                {item.desc}
              </div>
              <div className="flex flex-none">
                {(item.status === AnalyticRequestStatus.UNKNOWN ||
                  !item.status) && (
                  <PrimaryButton
                    className="w-full bottom-0"
                    label={item.buttonText || 'Select'}
                    icon={<FontAwesomeIcon icon={faArrowRight} />}
                    onClicked={() => {
                      item?.selectCallback?.(parseInt(item.id));
                    }}
                  />
                )}
                {item.status &&
                  item.status !== AnalyticRequestStatus.UNKNOWN && (
                    <PrimaryButton
                      className="w-full bottom-0"
                      label={'View Status'}
                      onClicked={() => {
                        item?.selectCallback?.(parseInt(item.id));
                      }}
                      theme="gray"
                    />
                  )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const DisabledCover = () => {
  return (
    <div
      className={`absolute z-50 top-0 left-0 w-full h-full bg-white bg-opacity-75 rounded-lg overflow-hidden flex border border-green`}
    >
      <div
        className={`absolute py-1 bg-green text-white flex items-center justify-center text-center text-sm`}
        style={{
          width: '500px',
          transformOrigin: 'center',
          transform: 'rotate(-22deg) translateX(-185px) translateY(-59px)',
        }}
      >
        Coming Soon!
      </div>
    </div>
  );
};
