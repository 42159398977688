import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import Feature from 'ol/Feature';
import OlMap from 'ol/Map';
import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import Overlay from 'ol/Overlay';
import View, { FitOptions } from 'ol/View';
import { defaults as defaultsControls } from 'ol/control';
import { shiftKeyOnly } from 'ol/events/condition';
import { Extent, isEmpty } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import { DragBox, defaults } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import BingMaps from 'ol/source/BingMaps';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import proj4 from 'proj4';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  OpenLayerMapProps,
  OpenMapLayer,
  OpenMapLayersObject,
} from '@agerpoint/types';

import { TwoDTools } from '../two-d-tools/two-d-tools';
import {
  OpenlayerMapPopup,
  layerCache,
  openMapLayerIcons,
  useOpenLayerMapExtentRestoration,
} from './open-layer-map.utilities';
import { OpenLayerMarkerStyles } from './open-layer-marker-styles';
import './styles.css';

function OpenLayerMap<T>({
  id,
  bingKey,
  mapLayers,
  featureLayer,
  geoJsonLayer,
  vectorTileLayers,
  callbacks,
  dependencies,
  controller: setController,
  disableInteraction,
  loading,
  useTools = false,
}: OpenLayerMapProps<T>) {
  const map = useRef<OlMap>();
  const dragBox = useRef<DragBox>();
  const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null);
  const [popup, setPopup] = useState<{
    overlay?: Overlay;
    id?: number | string;
  }>();

  const [tileLayers, setTileLayers] = useState<OpenMapLayersObject>(
    {} as OpenMapLayersObject
  );

  const vectorLayerRef = useRef<VectorLayer<VectorSource<Feature<Geometry>>>>(
    new VectorLayer({
      style: featureLayer?.styles?.['default'],
      properties: { name: 'vectorLayerRef' },
    })
  );
  const vectorImageLayerRef = useRef<
    VectorImageLayer<VectorSource<Feature<Geometry>>>
  >(
    new VectorImageLayer({
      source: new VectorSource<Feature<Geometry>>({}),
      properties: { name: 'vectorImageLayerRef' },
    })
  );

  // Register projections
  useEffect(() => {
    proj4.defs(
      'EPSG:4269',
      '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs +type=crs'
    );
    proj4.defs(
      'EPSG:32615',
      '+proj=utm +zone=15 +datum=WGS84 +units=m +no_defs +type=crs'
    );
    proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs +type=crs');
    register(proj4);
  }, []);

  useEffect(() => {
    const currentMap = map.current;

    if (currentMap && popup?.overlay) {
      currentMap.addOverlay(popup.overlay);

      return () => {
        currentMap.getOverlays().clear();
      };
    }

    return;
  }, [mapContainer, popup?.overlay]);

  const [visibleLayer, setVisibleLayer] = useState<OpenMapLayer>(
    mapLayers.initial
  );

  useEffect(() => {
    const t: OpenMapLayersObject = {};
    mapLayers.used.forEach((l) => {
      if (
        layerCache.has({
          layer: l,
          id: id,
        })
      ) {
        t[l] = layerCache.get({
          layer: l,
          id: id,
        });
      } else {
        const tl = new TileLayer({
          preload: Infinity,
          visible: false,
          properties: {
            name: l,
          },
          source: new BingMaps({
            key: bingKey,
            imagerySet: l,
          }),
        });
        t[l] = tl;
        layerCache.set(
          {
            layer: l,
            id: id,
          },
          tl
        );
      }
    });

    setTileLayers(t);
  }, []);

  useEffect(() => {
    if (mapContainer) {
      const interactions = disableInteraction
        ? {
            doubleClickZoom: false,
            mouseWheelZoom: false,
            dragPan: false,
            shiftDragZoom: false,
          }
        : { shiftDragZoom: false };

      const controls = disableInteraction
        ? {
            attribution: false,
            zoom: false,
            layers: false,
          }
        : {};

      const olMap = new OlMap({
        view: new View({
          center: transform([-97, 37], 'EPSG:4326', 'EPSG:3857'),
          zoom: 4,
        }),
        target: mapContainer,
        interactions: defaults(interactions),
        controls: defaultsControls(controls),
      });

      if (!disableInteraction) {
        const interaction = new DragBox({
          onBoxEnd: mapOnDragBox,
          condition: shiftKeyOnly,
        });
        olMap.addInteraction(interaction);
        dragBox.current = interaction;
      }

      map.current = olMap;
      return () => {
        olMap.setTarget(undefined);
        map.current = undefined;
      };
    }
    return () => {
      //
    };
  }, [mapContainer, disableInteraction]);

  const isLayerAlreadyAdded = useCallback((layerName: string, map: Map) => {
    const layers = map.getLayers().getArray();
    return layers.some((layer) => {
      return layer.getProperties().name === layerName;
    });
  }, []);

  const setLayers = useCallback(() => {
    try {
      if (!map.current) {
        return;
      }
      Object.values(tileLayers).forEach((layer) => {
        if (layer && map.current) {
          const name = layer.getProperties().name;
          const needToAdd = !isLayerAlreadyAdded(name, map.current);
          if (needToAdd) {
            map?.current?.addLayer(layer);
          }
        }
      });
      if (vectorLayerRef.current) {
        // remove the layer if it already exists
        if (isLayerAlreadyAdded('vectorLayerRef', map.current)) {
          map.current.removeLayer(vectorLayerRef.current);
        }
        map.current.addLayer(vectorLayerRef.current);
      }
      if (vectorImageLayerRef.current) {
        // remove the layer if it already exists
        if (isLayerAlreadyAdded('vectorImageLayerRef', map.current)) {
          map.current.removeLayer(vectorImageLayerRef.current);
        }
        map.current.addLayer(vectorImageLayerRef.current);
      }
    } catch (error) {
      console.error('Failed to set layers sequentially:', error);
    }
  }, [map, vectorLayerRef, vectorImageLayerRef, tileLayers]);

  useEffect(() => {
    setTileLayers((prev) => {
      const copy = { ...prev };
      let k: keyof typeof copy;
      for (k in copy) {
        copy[k]?.setVisible(k === visibleLayer);
      }
      return copy;
    });
  }, [visibleLayer]);

  const mapDependencies = useMemo(() => {
    return JSON.stringify({
      featureLayerDataLength: featureLayer?.data.length,
      geoJson: geoJsonLayer?.data,
      additional: dependencies,
    });
  }, [featureLayer, geoJsonLayer, dependencies]);

  useEffect(() => {
    if (!vectorLayerRef.current || !vectorImageLayerRef.current) {
      console.error('Layer refs are not initialized');
      return;
    }
    if (featureLayer) {
      const featureLayerSource = new VectorSource({});
      const features: Feature<Geometry>[] = [];
      featureLayer.data.forEach((d) => {
        const feature = featureLayer.featureGenerator(d);
        if (!feature?.id || !feature) {
          return;
        }
        const point = new Point([
          feature.longitude,
          feature.latitude,
        ]).transform('EPSG:4326', 'EPSG:3857');
        const f = new Feature({
          geometry: point,
          name: feature.name,
          text: feature.name,
        });
        f.setId(feature.id);
        if (!featureLayer.cluster) {
          f.setStyle(featureLayer.styles?.[feature.style]);
        }
        f.setProperties({ style: feature.style });
        features.push(f);
      });
      featureLayerSource.addFeatures(features);
      if (featureLayer.cluster) {
        const clusterSource = new Cluster({
          distance: featureLayer.cluster.distance,
          minDistance: featureLayer.cluster.minDistance,
          source: featureLayerSource,
          createCluster: (cluster, features) => {
            const size = features.length;
            const clusterFeature = new Feature({
              geometry: cluster,
              name: size.toString(),
              text: size.toString(),
            });

            const style = features
              .find((f) => f.get('style') !== 'default')
              ?.get('style');
            clusterFeature.setStyle(featureLayer.styles?.[style]);
            clusterFeature.setProperties({ features: features });
            return clusterFeature;
          },
        });
        vectorLayerRef.current.setSource(clusterSource);
      } else {
        vectorLayerRef.current.setSource(featureLayerSource);
      }
    } else {
      vectorLayerRef.current?.getSource()?.clear();
    }

    if (geoJsonLayer) {
      const geoJsonSource = new VectorSource({
        features: geoJsonLayer.data,
      });
      vectorImageLayerRef.current.setSource(geoJsonSource);
    } else {
      vectorImageLayerRef.current?.getSource()?.clear();
    }
    setLayers();
  }, [mapDependencies, vectorLayerRef, vectorImageLayerRef, map, tileLayers]);

  const mapPopupOnClick = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      if (!popup?.overlay) {
        return;
      }

      const features = map.current?.getFeaturesAtPixel(e.pixel);
      if (!features?.length) {
        popup.overlay.setPosition(undefined);
        setPopup({
          overlay: popup.overlay,
          id: undefined,
        });
        return;
      }
      const feature = features[0] as Feature<Point>;
      const featureId = feature.getId();
      if (!featureId) {
        return;
      }
      popup.overlay.setPosition(feature.getGeometry()?.getCoordinates());
      setPopup({
        overlay: popup.overlay,
        id: featureId,
      });
    },
    [mapDependencies, popup]
  );

  const featureOnClick = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      const features = map.current?.getFeaturesAtPixel(e.pixel);
      if (!features?.length) {
        return;
      }
      const feature = features[0] as Feature<Point>;
      const featureId = feature.getId();
      if (!featureId) {
        return;
      }
      callbacks?.onFeatureClick?.(featureId, e);
    },
    [mapDependencies]
  );

  const clusterOnClick = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      if (!featureLayer?.cluster) {
        return;
      }
      const features = map.current?.getFeaturesAtPixel(e.pixel);
      if (!features?.length) {
        return;
      }
      const feature = features[0] as Feature<Point>;

      const clusterFeatures = feature.get('features') as Feature<Point>[];

      if ((clusterFeatures?.length ?? 0) === 0) {
        return;
      }

      const clusterFeatureIds = clusterFeatures.map(
        (f) => f.getId() as number | string
      );

      if (clusterFeatureIds.length === 1) {
        const featureId = clusterFeatureIds[0] as number | string;
        callbacks?.onFeatureClick?.(featureId, e);
        return;
      }

      callbacks?.onClusterClick?.(clusterFeatureIds, e);
    },
    [mapDependencies]
  );

  const baseMapOnClick = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      const hasFeature = map.current?.hasFeatureAtPixel(e.pixel);
      if (!hasFeature) {
        callbacks?.onBaseMapClick?.(e);
      }
    },
    [mapDependencies]
  );

  const pointerCursorOnFeatureHover = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      const hasFeature = map.current?.hasFeatureAtPixel(e.pixel);

      if (!map.current) {
        return;
      }
      if (hasFeature) {
        map.current.getTargetElement().style.cursor = 'pointer';
      } else {
        map.current.getTargetElement().style.cursor = '';
      }
    },
    [mapDependencies]
  );

  const mapOnDragBox = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      const dragBoxExtent = dragBox.current?.getGeometry().getExtent();

      if (!dragBoxExtent) {
        return;
      }

      const features = vectorLayerRef.current
        .getSource()
        ?.getFeaturesInExtent(dragBoxExtent);

      if (!features?.length) {
        return;
      }
      const ids: (string | number)[] = [];
      features.forEach((f) => {
        const id = f.getId();
        if (id) {
          ids.push(id);
        }
      });

      callbacks?.onDragBoxSelect?.(ids);
    },
    [mapDependencies]
  );

  useEffect(() => {
    map.current?.on('click', mapPopupOnClick);
    map.current?.on('click', featureOnClick);
    map.current?.on('dblclick', featureOnClick);
    map.current?.on('click', clusterOnClick);
    map.current?.on('click', baseMapOnClick);
    map.current?.on('pointermove', pointerCursorOnFeatureHover);
    return () => {
      map.current?.un('click', mapPopupOnClick);
      map.current?.un('click', featureOnClick);
      map.current?.un('click', clusterOnClick);
      map.current?.un('click', baseMapOnClick);
      map.current?.un('dblclick', featureOnClick);
      map.current?.un('pointermove', pointerCursorOnFeatureHover);
    };
  }, [
    mapPopupOnClick,
    featureOnClick,
    pointerCursorOnFeatureHover,
    baseMapOnClick,
    mapContainer,
    clusterOnClick,
  ]);

  useEffect(() => {
    if (!callbacks?.onDragBoxSelect) {
      return;
    }

    map.current?.getInteractions().forEach((interaction) => {
      if (interaction instanceof DragBox) {
        interaction.onBoxEnd = mapOnDragBox;
      }
    });

    return () => {
      map.current?.getInteractions().forEach((interaction) => {
        if (interaction instanceof DragBox) {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          interaction.onBoxEnd = () => {};
        }
      });
    };
  }, [mapContainer, mapOnDragBox]);

  const refreshView = useCallback(() => {
    const view = map?.current?.getView();

    view?.animate({
      center: transform([-97, 37], 'EPSG:4326', 'EPSG:3857'),
      duration: 0,
      zoom: 4,
    });
  }, []);

  const zoomMapToLonLat = useCallback(
    (lonlat: [number, number], zoom = 15.5) => {
      const view = map?.current?.getView();
      view?.animate({
        center: transform(lonlat, 'EPSG:4326', 'EPSG:3857'),
        duration: 0,
        zoom: zoom,
      });
    },
    []
  );

  const zoomMapToExtent = useCallback(
    (
      extent: Extent,
      options: FitOptions = {
        padding: [100, 100, 100, 100],
        maxZoom: 20.0,
      }
    ) => {
      if (isEmpty(extent)) {
        return;
      }
      const view = map?.current?.getView();
      view?.fit(extent, options);
    },
    []
  );

  useEffect(() => {
    setController?.({
      zoomMapToLonLat,
      zoomMapToExtent,
      refreshView,
    });
  }, [zoomMapToLonLat, zoomMapToExtent, setController, refreshView]);

  useEffect(() => {
    if (!vectorTileLayers?.length) return;

    vectorTileLayers.forEach((vl) => {
      if (!map?.current) return;
      map.current.addLayer(vl);
    });

    return () => {
      if (!vectorTileLayers?.length) return;
      vectorTileLayers.forEach((vl) => {
        if (!map?.current) return;
        map.current.removeLayer(vl);
      });
    };
  }, [vectorTileLayers]);

  useOpenLayerMapExtentRestoration({ id, map, mapContainer });

  return (
    <>
      {useTools && <TwoDTools map={map.current} />}
      <div
        className="w-full h-full bg-blue-200 relative"
        id={id}
        ref={(ref) => setMapContainer(ref)}
      >
        {mapLayers.used.length > 1 && (
          <div className="absolute t-0 l-0 z-10 p-2">
            {!disableInteraction && (
              <div
                className={`flex flex-row p-0.5 rounded bg-white bg-opacity-40 justify-center
            items-center text-white hover:bg-opacity-70 h-`}
                style={{ gap: '1px' }}
              >
                {mapLayers.used.map((l, i) => (
                  <div
                    key={i}
                    style={{ width: '22px', height: '22px' }}
                    className={`${
                      i === 0
                        ? 'rounded-l'
                        : i === mapLayers.used.length - 1
                        ? ' rounded-r'
                        : ''
                    } flex items-center justify-center p-1 cursor-pointer ${
                      visibleLayer === l
                        ? 'bg-opacity-50 bg-blue-900'
                        : 'bg-opacity-70 bg-blue'
                    }`}
                    onClick={() => {
                      mapLayers.onChange?.(l);
                      return setVisibleLayer(l);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={openMapLayerIcons[l]}
                      className="w-full h-full"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <AnimatePresence>
          {loading && (
            <motion.div
              className="absolute w-full top-0 h-1 bg-gray-background z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="size-full shimmer-fast" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {featureLayer?.popupGenerator && (
        <OpenlayerMapPopup
          popup={popup}
          setPopup={(overlay) => setPopup({ ...popup, overlay })}
          generator={featureLayer.popupGenerator}
        />
      )}
    </>
  );
}

OpenLayerMap.MarkerStyles = OpenLayerMarkerStyles;

export { OpenLayerMap };
