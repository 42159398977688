import { ChangeEvent, ReactNode } from 'react';

export interface SwitchProps {
  id: string;
  title: string;
  name: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  disabled?: boolean;
  checked?: boolean;
}

export function RadioButton({
  id,
  title,
  name,
  value,
  onChange,
  checked,
  disabled,
  children,
}: SwitchProps) {
  return (
    <label
      title={title}
      className={
        'flex relative items-center mb-0 text-xs truncate leading-none cursor-pointer'
      }
      htmlFor={id}
    >
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        className="absolute opacity-0 w-0 h-0"
        onChange={onChange}
        checked={!!checked}
        disabled={!!disabled}
      />
      {children}
    </label>
  );
}
