import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { UseGetReturn } from 'restful-react';
import { useDebouncyEffect } from 'use-debouncy';

import { CaptureJob, useGetCaptureJobsByCaptureId } from '@agerpoint/api';
import { Input } from '@agerpoint/component';
import { StandAloneViewerLocalStoreKeys } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const StandAloneViewerCaptureSelector = () => {
  const {
    standAloneViewer: {
      actions: { setEptId, setCaptureId },
    },
  } = useGlobalStore();

  const [localCaptureId, setLocalCaptureId] = useState(
    localStorage.getItem(StandAloneViewerLocalStoreKeys.CaptureId) ?? ''
  );
  const [localEptId, setLocalEptId] = useState(
    localStorage.getItem(StandAloneViewerLocalStoreKeys.EptId) ?? ''
  );
  const [jobs, setJobs] = useState<CaptureJob[]>([]);

  const {
    data: jobsData,
    loading: loadingJobs,
    refetch,
    cancel: cancelJobsRequest,
  } = useGetCaptureJobsByCaptureId({
    captureId: localCaptureId ? +localCaptureId : NaN,
    lazy: true,
  }) as unknown as UseGetReturn<CaptureJob[], void, void, unknown>;

  useEffect(() => {
    if (jobsData) {
      setJobs(jobsData);
    }
  }, [jobsData]);

  const getJobs = useCallback(() => {
    if (loadingJobs) {
      cancelJobsRequest();
      setJobs([]);
    }
    if (localCaptureId === undefined || localCaptureId.length <= 0) {
      return;
    }

    const id = +localCaptureId;
    if (Number.isNaN(id)) {
      return;
    }

    refetch();
  }, [loadingJobs, localCaptureId, refetch]);

  useCallback(() => {
    if (localCaptureId === undefined || localCaptureId.length <= 0) {
      return;
    }
    if (localEptId === undefined || localEptId.length <= 0) {
      return;
    }

    setEptId(localEptId);
    setCaptureId(localCaptureId);

    const id = +localCaptureId;
    if (Number.isNaN(id)) {
      return;
    }

    refetch();
  }, []);

  useDebouncyEffect(getJobs, 500, [localCaptureId]);

  return (
    <div className="p-1 flex flex-col gap-2">
      <div className="flex flex-row gap-1">
        <Input.Text.Single
          id="capture-search"
          value={localCaptureId ?? ''}
          setValue={(v) => {
            setLocalCaptureId(v);
            localStorage.setItem(StandAloneViewerLocalStoreKeys.CaptureId, v);
          }}
          placeholder="Search Capture By ID"
          placeholderIcon={Input.placeholderIcons.search}
        />
      </div>
      {loadingJobs && (
        <div className="flex flex-row justify-center items-center">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      )}
      {jobs && jobs.length > 0 && (
        <div className="flex flex-col">
          <span className="text-xs">Select the EPT Pointcloud Id</span>
          <div
            className="flex flex-col overflow-auto"
            style={{ maxHeight: '300px' }}
          >
            {jobs.map((j, i) => (
              <div key={i} className="flex flex-row gap-2 items-center px-1">
                <input
                  type="radio"
                  name="job-select"
                  id={j.eptPointcloudId?.toString()}
                  value={j.eptPointcloudId || ''}
                  checked={
                    localEptId === undefined
                      ? false
                      : j.eptPointcloudId === +localEptId
                  }
                  onChange={(e) => {
                    setLocalEptId(e.target.value);
                    setEptId(e.target.value);
                    setCaptureId(localCaptureId);
                    localStorage.setItem(
                      StandAloneViewerLocalStoreKeys.EptId,
                      e.target.value
                    );
                  }}
                />
                <label htmlFor={j.eptPointcloudId?.toString()}>
                  {j.eptPointcloudId}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
