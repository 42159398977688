const decimalsFudge = 1.7; // Higher => more decimals (but probably never set < 1)

/**
 * Returns a number of decimals that is appropriate when
 * displaying a set of values.
 * @param values numbers to get appropriate number of decimals for
 * @returns the number of decimals to use
 */
export function getNumberDecimals(...values: number[]): number {
  return Math.ceil(
    Math.min(
      6,
      Math.max(
        0,
        ...values.map((v) => -Math.log10(Math.abs(v)) + decimalsFudge)
      )
    )
  );
}
