import { IBaseViewer } from 'libs/types/src/base-viewer';
import {
  AxesHelper,
  OrthographicCamera,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import {
  Annotation3dPoints,
  FrameEvent,
  IAnnotations3d,
  IGsThreeDViewer,
  LatLngAlt,
  PotreeViewer,
  SphereColors,
} from '@agerpoint/types';

import { CroppingTool } from '../cropping';

export abstract class BaseThreeDViewer implements IBaseViewer {
  scene: Scene | undefined;
  camera: PerspectiveCamera | undefined;
  controls: OrbitControls | undefined;
  renderer: WebGLRenderer | undefined;
  annotations3d: IAnnotations3d | undefined;
  viewer: PotreeViewer | IGsThreeDViewer | undefined = undefined;

  protected isGaussian: boolean;
  public targetEl: HTMLElement;
  protected overlayCamera?: OrthographicCamera;
  protected overlayScene?: Scene;
  protected axisWidgetRenderer: WebGLRenderer | undefined;
  protected axesHelper?: AxesHelper;
  protected pointCloudCenter?: Vector3;
  protected croppingTool: CroppingTool | undefined;

  constructor(isGaussian: boolean, targetEl: HTMLElement) {
    this.targetEl = targetEl;
    this.isGaussian = isGaussian;
  }

  abstract findCenter(): Vector3;

  get croppingFrame() {
    return this.croppingTool?.frame;
  }

  setCropBoxEditingActive(active: boolean) {
    if (!this.croppingTool) return;
    this.croppingTool.setCroppingIsActive = active;
  }

  get isCropBoxEditingActive() {
    return this.croppingTool?.croppingIsActive;
  }

  addAxesHelper() {
    if (!this.targetEl) return;

    this.pointCloudCenter = this.findCenter();

    this.overlayCamera = new OrthographicCamera(
      -100, // left
      100, // right
      100, // top
      -100, // bottom
      0.001,
      1000
    );
    this.overlayCamera.position.set(150, 150, 150);
    this.overlayCamera.lookAt(new Vector3(0, 0, 0));

    if (this.isGaussian) {
      this.overlayCamera.up.set(0, -1, 0);
    } else {
      this.overlayCamera.up.set(0, 0, 1);
    }
    console.info(
      'Axis Orientation:',
      'x',
      this.overlayCamera.up.x,
      'y',
      this.overlayCamera.up.y,
      'z',
      this.overlayCamera.up.z
    );

    this.axisWidgetRenderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
    });
    this.axisWidgetRenderer.domElement.style.pointerEvents = 'none';
    this.axisWidgetRenderer.domElement.style.position = 'absolute';
    this.axisWidgetRenderer.domElement.style.bottom = '0';
    this.axisWidgetRenderer.domElement.style.right = '0';
    this.axisWidgetRenderer.setSize(200, 200);
    this.axisWidgetRenderer.autoClear = false;
    this.axisWidgetRenderer.domElement.style.background = 'transparent';
    this.axisWidgetRenderer.domElement.style.zIndex = '1000';
    this.targetEl.appendChild(this.axisWidgetRenderer.domElement);

    this.overlayScene = new Scene();

    this.axesHelper = new AxesHelper(100);
    this.axesHelper.position.set(0, 0, 0);

    this.overlayScene.add(this.axesHelper);
  }

  removeAxesHelper() {
    if (this.axisWidgetRenderer) {
      this.axisWidgetRenderer.domElement.remove();
      this.overlayCamera = undefined;
      this.overlayScene = undefined;
      this.axisWidgetRenderer = undefined;
    }
  }

  updateOverlayScene() {
    // overlay scene (debug axes helper)
    if (
      this.axisWidgetRenderer &&
      this.overlayScene &&
      this.overlayCamera &&
      this.axesHelper &&
      this.pointCloudCenter &&
      this.camera
    ) {
      const mainCamera = this.camera;

      const mainCameraPos = new Vector3();
      mainCamera.getWorldPosition(mainCameraPos);

      const relativePos = mainCameraPos.sub(this.pointCloudCenter);

      const scaledRelativePos = relativePos.normalize().multiplyScalar(500);

      this.overlayCamera.position.copy(scaledRelativePos);

      this.overlayCamera.lookAt(0, 0, 0);

      this.axisWidgetRenderer.render(this.overlayScene, this.overlayCamera);
    }
  }

  resetCropBoxEditTool(existingCropBox: [Vector3, Vector3] | undefined) {
    if (this.croppingTool) {
      this.croppingTool.resetEditTool(existingCropBox);
    }
  }

  destroyCroppingTool() {
    if (this.croppingTool) {
      this.croppingTool.destroyCroppingTool();
      this.croppingTool = undefined;
    }
  }

  hideCropBoxEditTool() {
    if (this.croppingTool) {
      this.croppingTool.hideEditTool();
    }
  }

  showCropBoxEditTool() {
    if (!this.croppingTool) return;
    this.croppingTool.showEditTool();
  }

  initWithExistingFrame(
    cropBox: [Vector3, Vector3],
    cropBoxLimits: [Vector3, Vector3]
  ) {
    if (!this.croppingTool || !cropBox || !this.camera) return;
    const origin = this.findCenter();
    const upVector = this.camera.up;
    this.croppingTool.initWithExistingFrame(
      origin,
      cropBox,
      upVector,
      cropBoxLimits
    );
  }

  initWithoutExistingFrame(
    cropBox: [Vector3, Vector3],
    cropBoxLimits: [Vector3, Vector3]
  ) {
    if (!this.croppingTool || !this.camera) return;
    const origin = this.findCenter();
    const upVector = this.camera.up;
    this.croppingTool.initWithoutExistingFrame(
      origin,
      cropBox,
      upVector,
      cropBoxLimits
    );
  }

  initializeCropBoxEditTool(
    updateCallback: (frameEvent: FrameEvent) => void,
    potreeViewer?: PotreeViewer | undefined
  ) {
    if (this.croppingTool) {
      return;
    }

    if (!this.scene || !this.camera || !this.renderer || !this.controls) {
      return;
    }

    this.croppingTool = new CroppingTool(
      this.scene,
      this.camera,
      this.renderer,
      this.controls,
      potreeViewer || null,
      !this.isGaussian
    );
    this.croppingTool.frameEventStream.subscribe((frameEvent) => {
      if (!frameEvent) return;
      updateCallback(frameEvent);
    });
  }

  addCameraLocationMarker(id: string, position: Vector3) {
    if (!this.annotations3d) return;
    const attrs = {
      id,
      color: SphereColors.Cyan,
      name: 'newPointMarker',
      description: 'newPointMarker',
      type: Annotation3dPoints.CaptureImageLocation,
    };
    this.annotations3d.add3dPoint(attrs, position, attrs.type, false);
  }

  removeAllImageMarkers() {
    this.annotations3d?.remove3dPointsByType(
      Annotation3dPoints.CaptureImageLocation
    );
  }

  hideImageMarkers() {
    this.annotations3d?.hide3dPointsByType(
      Annotation3dPoints.CaptureImageLocation
    );
  }

  showImageMarkers() {
    this.annotations3d?.show3dPointsByType(
      Annotation3dPoints.CaptureImageLocation
    );
  }
}
