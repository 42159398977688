import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

import { MixpanelNames } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const HelpButton = () => {
  const docPath = useMemo(
    () =>
      'https://agerpoint.freshdesk.com/support/solutions/folders/151000511547',
    []
  );

  const {
    actions: { sendEvent },
  } = useGlobalStore();

  return (
    <div
      className={`w-8 h-8 transition-colors hover:bg-green-100 p-0.5 flex items-center justify-center 
    hover:cursor-pointer relative rounded`}
      onClick={() => {
        sendEvent(MixpanelNames.HelpButtonUsed);
        const link = document.createElement('a');
        link.href = docPath;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
      }}
    >
      <FontAwesomeIcon
        icon={faQuestionCircle}
        className="text-xl"
        aria-hidden="true"
      />
    </div>
  );
};
