import { Tag } from '@agerpoint/types';
import { Heading } from '../heading/heading';

interface ProjectHeadingProps {
  name: string;
  tags: Array<Tag>;
}

export function ProjectHeading({ name, tags }: ProjectHeadingProps) {
  return (
    <div className="relative shadow-sm px-4 py-4 border-b border-gray-100">
      <Heading level={1}>{name}</Heading>
      {/* {tags.length > 0 && <Tags tags={tags} />} */}
    </div>
  );
}

/* interface TagProps {
  tags: Array<Tag>;
} */

/* function Tags({ tags }: TagProps) {
  return (
    <div className="mt-2 flex flex-wrap -m-1">
      {tags.map((tag) => (
        <span
          className="rounded-2xl border border-gray  text-gray  px-2 py-1 leading-none m-1"
          key={tag.id}
        >
          {tag.tagName}
        </span>
      ))}
    </div>
  );
} */
