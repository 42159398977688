import { Layer, PointCloudMapLayer } from '@agerpoint/types';
import { useState } from 'react';
import { StyleProperty } from './style-property';
import { Color } from './point-cloud-styles';
import {
  ExpansionContentContainer,
  ExpansionContentTabsContainer,
  ExpansionContentTabContentContainer,
  InputSlider,
} from '@agerpoint/component';

interface PointCloudLayerStyle {
  layer: PointCloudMapLayer;
  onChange: (layer: Layer) => Promise<void>;
}

export function PointCloudLayerStyle({
  layer,
  onChange,
}: PointCloudLayerStyle) {
  const minOffset = -10;
  const maxOffset = 10;
  const stepOffset = 0.1;
  const [mode, setMode] = useState('attribute');
  const [currentOffset, setCurrentOffset] = useState<number[]>([layer.offset]);
  return (
    <ExpansionContentContainer>
      <ExpansionContentTabsContainer>
        <StyleProperty
          name="Color"
          value={layer.style.type === 'rgb' ? 'RGB' : layer.style.attribute}
          selected={mode === 'attribute'}
          onSelect={() => setMode('attribute')}
        />
        <StyleProperty
          name="Offset"
          value={layer.offset}
          selected={mode === 'offset'}
          onSelect={() => setMode('offset')}
        />
      </ExpansionContentTabsContainer>
      <ExpansionContentTabContentContainer>
        {mode === 'attribute' ? (
          <Color layer={layer} onChange={onChange} />
        ) : null}
        {mode === 'offset' ? (
          <InputSlider
            showLabels
            min={minOffset}
            max={maxOffset}
            step={stepOffset}
            unit="m"
            values={currentOffset}
            onChange={(values) => {
              setCurrentOffset([values[0]]);
              layer.offset = values[0];
              onChange({
                ...layer,
                style: {
                  ...layer.style,
                },
              });
            }}
          />
        ) : null}
        {mode === 'offset' ? (
          <input
            min={minOffset}
            max={maxOffset}
            step={stepOffset}
            type="number"
            value={layer.offset}
            onChange={(e) => {
              let nextValue = parseFloat(e.target.value);
              if (isNaN(nextValue)) {
                e.target.value = '0';
                nextValue = 0;
              } else if (nextValue > maxOffset) {
                e.target.value = maxOffset.toString();
                nextValue = maxOffset;
              } else if (nextValue < minOffset) {
                e.target.value = minOffset.toString();
                nextValue = minOffset;
              }
              layer.offset = nextValue;
              setCurrentOffset([layer.offset]);
              onChange({
                ...layer,
                style: {
                  ...layer.style,
                },
              });
            }}
            className={`
                      my-2
                      focus:ring-green
                      focus:border-green
                      block
                      p-1
                      shadow-sm
                      sm:text-sm
                      border-gray-500
                      rounded
                      border-none
                      text-left
                    `}
          />
        ) : null}
      </ExpansionContentTabContentContainer>
    </ExpansionContentContainer>
  );
}
