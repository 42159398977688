import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import { Viewer } from '@agerpoint/three-d-viewer';
import {
  CaptureMaterialClassifications,
  CaptureMaterialColorsLookup,
  ClassificationObject,
  EffectNames,
  MaterialType,
  StandAloneViewerLocalStoreKeys,
  TogglePointCloudVisibility,
} from '@agerpoint/types';
import {
  environment,
  useActionListener,
  useGlobalStore,
} from '@agerpoint/utilities';

import { PotreeControlsFloatComponent } from '../../potree-viewer';

export const StandAloneViewer = ({ token }: { token: string }) => {
  const containerRef = useRef(null);
  const viewer = useRef<Viewer>();
  const {
    permissions,
    capturesViewer: {
      captureMaterialClassificationColors,
      actions: { setCaptureMaterialClassificationColors },
    },
    standAloneViewer: {
      captureId,
      eptId,
      pointBudget,
      pointStyle,
      pointSizeType,
      pointSize,
      splatQuality,
      actions: { setCaptureId, setEptId },
    },
    twoDimensionDrawingIsActive,
  } = useGlobalStore();

  const visibilityToggleCallback = useCallback(
    (payload: TogglePointCloudVisibility) => {
      if (!viewer?.current) return;
      viewer.current.classification.toggleClassificationVisibilityById(
        payload.classification
      );
    },
    []
  );

  useActionListener<TogglePointCloudVisibility>(
    EffectNames.POINT_CLOUD_MATERIAL_VISIBILITY_TOGGLED,
    visibilityToggleCallback
  );

  const loadEptJson = async (viewer: Viewer, token: string, eptId: string) => {
    if (!viewer || !token || !eptId) {
      return;
    }
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'cache-control': 'no-cache',
      pragma: 'no-cache',
    };
    const eptJson = await viewer.load(
      'ept.json',
      `${environment.api_server_url}/api/EptPointcloud/${eptId}/ept.json`,
      { headers, method: 'GET', mode: 'cors', cache: 'no-cache' }
    );
    const schema = eptJson?.pointcloud?.pcoGeometry?.schema;
    if (!schema) return;
    const classification = schema.find(
      (s: { name: string }) => s.name === 'Classification'
    );
    if (classification && classification?.counts) {
      const colors = classification.counts.reduce(
        (
          acc: {
            [key in CaptureMaterialClassifications]?: ClassificationObject;
          },

          c: { value: CaptureMaterialClassifications }
        ) => {
          acc[c.value] = {
            color: CaptureMaterialColorsLookup[c.value],
            visible: true,
          };
          return acc;
        },
        {} as { [key in CaptureMaterialClassifications]?: ClassificationObject }
      );
      setCaptureMaterialClassificationColors(colors);
    }
  };

  const debounceLoadEptJson = useCallback(
    debounce((viewer, token, eptId) => {
      if (!viewer || !token || !eptId) return;
      loadEptJson(viewer, token, eptId);
    }, 500),
    []
  );

  useEffect(() => {
    const doAsync = async () => {
      if (!containerRef.current) return;
      const v = new Viewer(permissions, containerRef.current as HTMLDivElement);
      await v.initialize();
      viewer.current = v;
    };
    doAsync();
  }, []);

  useEffect(() => {
    const savedCaptureId = localStorage.getItem(
      StandAloneViewerLocalStoreKeys.CaptureId
    );
    if (savedCaptureId) {
      setCaptureId(savedCaptureId);
    }
    const savedEptId = localStorage.getItem(
      StandAloneViewerLocalStoreKeys.EptId
    );
    if (savedEptId) {
      setEptId(savedEptId);
    }
  }, []);

  useEffect(() => {
    if (!viewer.current || !token) {
      return;
    }
    debounceLoadEptJson(viewer.current, token, eptId);

    return () => {
      viewer.current?.clearPointCloudScene();
    };
  }, [eptId, token, viewer.current]);

  useEffect(() => {
    if (!viewer.current || !token || !eptId) {
      return;
    }

    if (pointBudget) {
      viewer.current?.setPointBudget(pointBudget);

      debounceLoadEptJson(viewer.current, token, eptId);
    }

    return () => {
      viewer.current?.clearPointCloudScene();
    };
  }, [pointBudget]);

  useEffect(() => {
    if (!viewer.current || !token || !eptId) {
      return;
    }
    // update the color options when the material changes
    if (pointStyle === MaterialType.CLASSIFICATION) {
      Object.keys(
        captureMaterialClassificationColors as Partial<
          Record<CaptureMaterialClassifications, number[]>
        >
      ).forEach((key) => {
        const keyAsNumber = Number(key) as CaptureMaterialClassifications;
        const colorArr = captureMaterialClassificationColors[keyAsNumber];
        if (!colorArr) return;
        viewer?.current?.classification.updateClassificationColorById(
          key,
          colorArr.color
        );
      });
    }
    if (pointStyle === MaterialType.RGBA) {
      viewer?.current?.classification.toggleAllClassificationsVisible();
    }
    if (!pointStyle) return;
    viewer?.current?.classification.switchPointCloudMaterial(pointStyle);
  }, [pointStyle, captureMaterialClassificationColors]);

  useEffect(() => {
    viewer?.current?.updateMaterialProperties({
      pointSizeType,
      pointSize,
    });
  }, [pointSizeType, pointSize]);

  useEffect(() => {
    if (!splatQuality) return;
    viewer?.current?.updateSplatQuality(splatQuality);
  }, [splatQuality]);

  return (
    <>
      {!eptId && (
        <div className="absolute inset-0 flex items-center justify-center flex-col">
          <span>Nothing to draw</span>
          <span>Select the Model from Sidebar</span>
        </div>
      )}
      <div
        className={`absolute inset-0 potree_container w-full h-full bg-black outline-none ${
          eptId ? '' : 'hidden'
        }`}
      >
        <div id="potree_render_area" ref={containerRef}></div>
        {twoDimensionDrawingIsActive && (
          <div
            className="z-100 absolute top-0 left-0 pointer-events-auto"
            id="konva-root"
            style={{
              width: '100%',
              height: '100%',
            }}
          ></div>
        )}
      </div>
      {captureId && viewer && (
        <>
          <PotreeControlsFloatComponent
            viewer={viewer}
            captureId={Number(captureId)}
            size="default"
          />

          <div
            className={`text-white absolute inset-x-0 bottom-0
    px-2 mr-2 z-200 flex flex-row pointer-events-none justify-end items-end`}
          >
            <div className="flex flex-row gap-2 pb-2">
              {/* <QaqcTools viewer={viewer} size="default" /> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};
