import { ReactNode } from 'react';

interface IconButtonProps extends React.HTMLProps<HTMLButtonElement> {
  show: boolean;
  icon: ReactNode;
  onClick(): void;
}

export function IconButton({
  show,
  onClick,
  icon,
  className,
  disabled,
  ...props
}: IconButtonProps) {
  return (
    <button
      {...props}
      type="button"
      className={`mr-1 transition-all duration-300 focus:ring-0 ${
        show ? 'opacity-100' : 'opacity-0'
      }
      ${disabled ? 'cursor-not-allowed' : ''}
      ${className ? className : ''}
      `}
      onClick={
        !disabled
          ? (event) => {
              event.stopPropagation();
              onClick();
            }
          : undefined
      }
    >
      {icon}
    </button>
  );
}
