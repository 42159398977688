import {
  faCircleNotch,
  faDownload,
  faSpinner,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'oidc-react';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  APIClient,
  Capture,
  CaptureCustomAttribute,
  CaptureObject,
  CaptureObjectCustomAttribute,
  User,
  formatDateAndTime,
} from '@agerpoint/api';
import { DialogModal, Input, PrimaryButton } from '@agerpoint/component';
import {
  AnalyticRequestStatus,
  IUseItemSelectionState,
  MixpanelNames,
  getCaptureModeNiceName,
} from '@agerpoint/types';
import {
  LookupTable,
  createFilename,
  getSymbol,
  useGlobalStore,
} from '@agerpoint/utilities';

import { DatatableOld, dataTableAgerStyle } from '../datatable/datatable-old';
import { getJobInfo } from './captures-table-download.utilities';
import { getUsername } from './captures-table-utilities';

const csvHeaderWithAttributes = [
  'Capture ID',
  'Capture Name',
  'Capture UUID',
  'URL',
  'Capture Date',
  'Model',
  'User',
  'User UUID',
  'Mode',
  'Latitude',
  'Longitude',
  'Altitude',
  'Size (MB)',
  'No. of Images',
  'Notes',
  'Object Name',
  'Attribute',
  'Value',
  'Unit',
];

const csvHeaderWithoutAttributes = [
  'Capture ID',
  'Capture Name',
  'Capture UUID',
  'URL',
  'Capture Date',
  'Model',
  'User',
  'User UUID',
  'Mode',
  'Latitude',
  'Longitude',
  'Altitude',
  'Size (MB)',
  'No. of Images',
  'Notes',
];

const fetchAnalyticRequestByCapture = async (
  captureId: string,
  serverUrl: string,
  token: string
) => {
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    pragma: 'no-cache',
  };
  const response = await fetch(
    serverUrl +
      '/api/AnalyticRequests/Capture/' +
      captureId +
      '?includeArchived=true',
    { headers }
  );
  const data = await response.json();
  return data;
};

const fetchCaptureObjectsByAnalyticRequestId = async (
  arId: string,
  serverUrl: string,
  token: string
) => {
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    pragma: 'no-cache',
  };
  const response = await fetch(
    serverUrl + '/api/CaptureObjects/AnalyticRequest/' + arId,
    { headers }
  );
  const data = await response.json();
  return data;
};

const fetchCustomAttributesByAnalyticRequestId = async (
  arId: string,
  serverUrl: string,
  token: string
) => {
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    pragma: 'no-cache',
  };
  const response = await fetch(
    serverUrl + '/api/CaptureCustomAttribute/AnalyticRequest/' + arId,
    { headers }
  );
  const data = await response.json();
  return data;
};

interface CaptureCSVExportRecord {
  capture: Capture;
  captureObject?: CaptureObject;
  captureAttribute?: CaptureObjectCustomAttribute;
  jobInfo: { jobType: string; url: string };
  userName: string;
}

interface ExportObject {
  'Capture ID': number | string;
  'Capture Name': string;
  'Capture UUID': string;
  URL: string;
  'Capture Date': string;
  Model: string;
  User: string;
  'User UUID': string;
  Mode: string;
  Latitude: number | string;
  Longitude: number | string;
  Altitude: number | string;
  'Size (MB)': number | string;
  'No. of Images': number | string;
  Notes: string;
  'Object Name'?: string;
  Attribute?: string;
  Value?: string;
  Unit?: string | JSX.Element;
}

interface CaptureObjectWithAnalyticRequestId extends CaptureObject {
  analyticRequestId: number;
}

export const CSVDownloadModal = ({
  open,
  handleCloseDialog,
  selectedCaptures,
  serverUrl,
  userLookup,
}: {
  open: boolean;
  handleCloseDialog: () => void;
  selectedCaptures: IUseItemSelectionState<number, Capture>;
  serverUrl: string;
  userLookup?: LookupTable<User>;
}) => {
  const [blobUrl, setBlobUrl] = useState<string>();
  const recordsWithAttributes = useRef<CaptureCSVExportRecord[]>();
  const recordsWithoutAttributes = useRef<CaptureCSVExportRecord[]>();
  const [records, setRecords] = useState<CaptureCSVExportRecord[]>();
  const [loading, setLoading] = useState(false);
  const [includeAttributes, setIncludeAttributes] = useState(true);
  const { userData } = useAuth();
  const {
    actions: { sendEvent },
  } = useGlobalStore();

  useEffect(() => {
    if (!open) {
      return;
    }
    setRecords([]);
    recordsWithAttributes.current = undefined;
    recordsWithoutAttributes.current = undefined;
    prepareRecords();
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (includeAttributes && (recordsWithAttributes.current?.length ?? 0) > 0) {
      setRecords(recordsWithAttributes.current);
      return;
    } else if (
      !includeAttributes &&
      (recordsWithoutAttributes.current?.length ?? 0) > 0
    ) {
      setRecords(recordsWithoutAttributes.current);
      return;
    } else {
      setRecords([]);
      prepareRecords();
    }
  }, [includeAttributes]);

  useEffect(() => {
    if ((records?.length ?? 0) === 0) {
      return;
    }

    prepareDownload();
  }, [records]);

  const prepareDownload = useCallback(async () => {
    if (!records) {
      return;
    }
    try {
      const rows = records
        .map((r) => recordToObject(r))
        .map((r) => objectToString(r));

      const header =
        (includeAttributes
          ? csvHeaderWithAttributes
          : csvHeaderWithoutAttributes
        )
          .map((h) => `"${h}"`)
          .join(',') + '\n';

      const content = rows.join('\n');
      const csvString = header + content;

      const file = new File(
        [csvString],
        createFilename('capture_data', 'csv'),
        {
          type: 'text/csv;charset=utf-8',
        }
      );
      const url = URL.createObjectURL(file);
      setBlobUrl(url);
    } finally {
      setLoading(false);
    }
  }, [records, includeAttributes]);

  const recordToObject = useCallback(
    (record: CaptureCSVExportRecord) => ({
      'Capture ID': record.capture?.id || '',
      'Capture Name': record.capture?.captureName || '',
      'Capture UUID': record.capture?.captureUuid || '',
      URL: record.jobInfo?.url || '',
      'Capture Date': formatDateAndTime(record.capture?.scanDatetime) || '',
      Model: record.jobInfo.jobType || '',
      User: record.userName || '',
      'User UUID': record.capture.createdById || '',
      Mode: getCaptureModeNiceName(record?.capture?.captureModeId) || '',
      Latitude: record.capture.latitude || '',
      Longitude: record.capture.longitude || '',
      Altitude: record.capture.altitude || '',
      'Size (MB)': record.capture.fileSize || '',
      'No. of Images': record.capture.numberImages || '',
      Notes: record.capture.description || '',
      'Object Name': includeAttributes
        ? record.captureObject?.name || ''
        : undefined,
      Attribute: includeAttributes
        ? record.captureAttribute?.attributeDisplayName ??
          record.captureAttribute?.attributeName ??
          ''
        : undefined,
      Value: includeAttributes
        ? record.captureAttribute?.attributeValue ?? ''
        : undefined,
      Unit: includeAttributes
        ? record.captureAttribute
          ? getSymbol(record.captureAttribute, true) ?? ''
          : ''
        : undefined,
    }),
    [includeAttributes]
  );

  const objectToString = useCallback(
    (object: ExportObject) => {
      return Object.values(object)
        .filter((row) => row !== undefined)
        .map((row) => `${row}`.replace(/"/g, '""'))
        .map((row) => `"${row}"`)
        .join(',');
    },
    [includeAttributes]
  );

  const prepareRecords = useCallback(async () => {
    if (!userData?.access_token) {
      throw Error('No Access Token');
    }

    setLoading(true);
    try {
      const captures = selectedCaptures.getSelectionArray();

      // first check if the captures have analytic requests adn they are approved
      const completeAndActiveAnalyticRequests = (
        await Promise.all(
          captures.map((capture) => {
            if (!capture?.id) {
              return;
            }
            return fetchAnalyticRequestByCapture(
              capture.id.toString(),
              serverUrl,
              userData.access_token
            );
          })
        )
      )
        .flat()
        .filter(
          (ar) => !ar.archived && ar?.status === AnalyticRequestStatus.COMPLETE
        );

      const captureObjectsForAllAnalyticRequests = (
        await Promise.all(
          completeAndActiveAnalyticRequests.map(async (ar) => {
            if (!ar) {
              return [];
            }
            try {
              const capObjs = await fetchCaptureObjectsByAnalyticRequestId(
                ar.id.toString(),
                serverUrl,
                userData.access_token
              );
              return capObjs.map(
                (
                  capObj: CaptureObject
                ): CaptureObjectWithAnalyticRequestId => ({
                  analyticRequestId: ar.id,
                  ...capObj,
                })
              );
            } catch (error) {
              console.error('Error fetching capture objects:', error);
              return []; // Return an empty array on error to keep the structure consistent
            }
          })
        )
      ).flat();

      const completedArIds = completeAndActiveAnalyticRequests.map(
        (ar: APIClient.AnalyticRequest) => ar.id?.toString() ?? ''
      );

      const captureObjects: CaptureObjectWithAnalyticRequestId[] =
        captureObjectsForAllAnalyticRequests.filter(
          (capObj: CaptureObjectWithAnalyticRequestId) => {
            return completedArIds.includes(capObj.analyticRequestId.toString());
          }
        );

      const captureCustomAttributesForAllAnalyticRequests: CaptureCustomAttribute[] =
        (
          await Promise.all(
            completedArIds.map(async (arId) => {
              try {
                const capCustAttrs =
                  await fetchCustomAttributesByAnalyticRequestId(
                    arId,
                    serverUrl,
                    userData.access_token
                  );
                return capCustAttrs;
              } catch (error) {
                console.error(
                  'Error fetching capture custom attributes:',
                  error
                );
                return [];
              }
            })
          )
        ).flat();

      const result = (await Promise.all(
        captures.map(async (capture, i) => {
          if (!capture?.id) {
            return;
          }

          const jobInfo = getJobInfo(capture, serverUrl);
          const user = userLookup?.[capture.createdById ?? ''];
          const userName = getUsername(user);

          if (!includeAttributes) {
            return [{ capture, jobInfo, userName }];
          }

          const captureObjectsAttributes = captureObjects
            .filter((co) => co.captureId === capture.id)
            .map((captureObject) => {
              if (
                (captureObject.captureObjectCustomAttributes?.length ?? 0) > 0
              ) {
                return captureObject.captureObjectCustomAttributes
                  ?.filter((coAttr) => coAttr?.validated)
                  .map((captureAttribute) => ({
                    capture,
                    captureObject,
                    jobInfo,
                    captureAttribute,
                    userName,
                  }));
              } else {
                return {
                  capture,
                  captureObject,
                  jobInfo,
                  userName,
                };
              }
            });

          const captureCustomAttributes =
            captureCustomAttributesForAllAnalyticRequests
              .filter((cca) => cca.captureId === capture.id)
              // need to figure out which job these belong to and if it's analytic request is complete
              .filter((cca) => cca.validated)
              .map((captureAttribute) => {
                return {
                  capture,
                  captureAttribute,
                  jobInfo,
                  userName,
                };
              });

          const merged = [
            ...captureObjectsAttributes,
            ...captureCustomAttributes,
          ];

          if (merged.length > 0) {
            return merged;
          } else {
            return [{ capture, jobInfo, userName }];
          }
        })
      ).then((r) => {
        return r.flat(2);
      })) as CaptureCSVExportRecord[];

      if (includeAttributes) {
        recordsWithAttributes.current = [...result];
      } else {
        recordsWithoutAttributes.current = [...result];
      }

      setRecords(result);
    } catch {
      setLoading(false);
    }
  }, [userData, selectedCaptures.selectionSize, includeAttributes, userLookup]);

  const download = useCallback(() => {
    if (!blobUrl) {
      return;
    }

    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = createFilename('capture_list', 'csv');
    a.type = 'text/csv;charset=UTF-8';
    a.click();
    sendEvent(MixpanelNames.CaptureListCsvExport, {
      format: 'csv',
      count: selectedCaptures.selectionSize,
    });
    handleCloseDialog();
  }, [blobUrl, handleCloseDialog, selectedCaptures.selectionSize, sendEvent]);

  return (
    <DialogModal
      open={open}
      title="Export"
      size="large"
      handleCloseDialog={handleCloseDialog}
      testId="capture-export-csv-modal"
    >
      <div className="w-full flex flex-col">
        <div className="flex flex-row pt-3 pb-2 justify-between items-center">
          <span>Preview</span>

          <Input.Checkbox
            id="include-attributes-checkbox"
            label={<Input.Label label="Include Attributes" />}
            value={includeAttributes}
            setValue={(v) => {
              setIncludeAttributes(v);
            }}
          />
        </div>
        <div className="w-full h-80">
          <DatatableOld
            data={records ?? []}
            style={{
              ...dataTableAgerStyle,
              tableWrapperStyle: 'border border-gray-500 rounded-md',
              headerStyle: 'pr-1 py-1 h-full flex items-center text-xs',
              cellStyle: 'pr-1 flex items-center text-xs',
              tableMinWidth: 2500,
            }}
            pagination={{
              hasNextPage: (records?.length ?? 0) === 0,
              loadPage: () => {
                //
              },
              loadingIndicator: (
                <span className="flex flex-row gap-2 h-full px-4 items-center">
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    spin
                    className="h-3/4"
                  />
                  <span className="text-sm">Loading Preview...</span>
                </span>
              ),
            }}
            // loading={(records?.length ?? 0) === 0}
            // loadingIndicator={
            //   <span className="flex flex-row gap-2 h-full px-4 items-center">
            //     <FontAwesomeIcon icon={faCircleNotch} spin className="h-3/4" />
            //     <span className="text-sm">Loading Preview...</span>
            //   </span>
            // }
            columns={[
              {
                label: 'Capture ID',
                value: (row) => row.capture?.id,
                flex: 2,
              },
              {
                label: 'Capture Name',
                value: (row) => row.capture?.captureName,
                flex: 3,
              },
              {
                label: 'Capture UUID',
                value: (row) => row.capture?.captureUuid,
                flex: 3,
              },
              {
                label: 'URL',
                value: (row) => row.jobInfo.url,
                flex: 3,
              },
              {
                label: 'Capture Date',
                value: (row) => formatDateAndTime(row.capture?.scanDatetime),
                flex: 3,
              },
              {
                label: 'Model',
                value: (row) => row.jobInfo.jobType,
                flex: 2,
              },
              {
                label: 'User',
                value: (row) =>
                  getUsername(userLookup?.[row?.capture?.createdById ?? '']),
                flex: 2,
              },
              {
                label: 'User UUID',
                value: (row) => row?.capture?.createdById,
                flex: 3,
              },
              {
                label: 'Mode',
                value: (row) =>
                  getCaptureModeNiceName(row?.capture?.captureModeId),
                flex: 1,
              },
              {
                label: 'Latitude',
                value: (row) => row?.capture?.latitude,
                flex: 3,
              },
              {
                label: 'Longitude',
                value: (row) => row?.capture?.longitude,
                flex: 3,
              },
              {
                label: 'Altitude',
                value: (row) => row?.capture?.altitude,
                flex: 3,
              },
              {
                label: 'Size (MB)',
                value: (row) => row.capture?.fileSize,
                flex: 1,
              },
              {
                label: 'No. of Images',
                value: (row) => row.capture?.numberImages,
                flex: 2,
              },
              {
                label: 'Notes',
                value: (row) => row.capture?.description,
                flex: 3,
              },
              {
                label: 'Object Name',
                value: (row) => row.captureObject?.name,
                visible: includeAttributes,
                flex: 3,
              },
              {
                label: 'Attribute',
                value: (row) =>
                  row.captureAttribute?.attributeDisplayName ??
                  row.captureAttribute?.attributeName,
                visible: includeAttributes,
                flex: 3,
              },
              {
                label: 'Value',
                value: (row) => row.captureAttribute?.attributeValue,
                visible: includeAttributes,
                flex: 2,
              },
              {
                label: 'Unit',
                value: (row) =>
                  row.captureAttribute
                    ? getSymbol(row.captureAttribute, true)
                    : '',
                visible: includeAttributes,
                flex: 1,
              },
            ]}
            rowHeight={25}
          />
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <span>
            <span className="flex flex-col text-sm">
              <span>{selectedCaptures.selectionSize} Captures</span>
              {(records?.length ?? 0) > 0 && (
                <span>{records?.length} Rows</span>
              )}
            </span>
          </span>
          <span className="flex flex-row gap-4 p-2 items-center">
            {loading ? (
              <span className="flex flex-col">
                <span>
                  <FontAwesomeIcon spin icon={faSpinner} /> Preparing
                  Download...
                </span>
                <span className="text-xs">
                  This may take several minutes to complete.
                </span>
              </span>
            ) : (
              <PrimaryButton
                data-test-id="confirm-download"
                label="Download CSV"
                icon={<FontAwesomeIcon icon={faDownload} />}
                onClicked={download}
              />
            )}
            <PrimaryButton
              data-test-id="cancel-download"
              theme="danger"
              label="Cancel"
              onClicked={handleCloseDialog}
            />
          </span>
        </div>
      </div>
    </DialogModal>
  );
};
