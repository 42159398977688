import { useCallback, useEffect, useRef, useState } from 'react';

import { APLoader } from '../ap-loader';
import { CloudDatatable } from '../datatable';
import { CloudTileViewProps } from './tile-view.types';
import { useCloudTileViewScrollRestoration } from './tile-view.utilities';

const CloudTileView = <T,>({
  id,
  data,
  tileGenerator,
  pagination,
  loading,
  overlay,
  extraRow,
  breakpoint = 250,
}: CloudTileViewProps<T>) => {
  const lastRowRef = useRef<HTMLTableRowElement>(null);
  const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);
  const processingPage = useRef<boolean>(false);
  const [itemWidth, setItemWidth] = useState<string>();

  useCloudTileViewScrollRestoration({
    id,
    gridRef: gridRef,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        const target = entries[0];

        if (
          target.isIntersecting &&
          processingPage.current === false &&
          !loading
        ) {
          processingPage.current = true;
          await pagination?.loadNextPage();
          processingPage.current = false;
        }
      },
      {
        root: null,
        rootMargin: '1px',
        threshold: 0,
      }
    );
    if (lastRowRef.current) {
      observer.observe(lastRowRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [pagination, loading]);

  const calculateItemWidth = useCallback(() => {
    if (gridRef === null) {
      return;
    }

    const gridWidth = gridRef.clientWidth;
    if (!gridWidth) {
      setItemWidth(undefined);
    }

    const itemsInRow = Math.floor(gridWidth / breakpoint);
    if (itemsInRow === 0) {
      setItemWidth(`calc(100%)`);
    }
    const padding = 8 * itemsInRow;
    const correction = padding / itemsInRow;
    setItemWidth(`calc(100% / ${itemsInRow} - ${correction}px)`);
  }, [breakpoint, gridRef]);

  useEffect(() => {
    calculateItemWidth();
  }, [calculateItemWidth]);

  useEffect(() => {
    if (gridRef === null) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const _ of entries) {
        calculateItemWidth();
      }
    });

    resizeObserver.observe(gridRef);

    return () => {
      resizeObserver.disconnect();
    };
  }, [gridRef, calculateItemWidth]);

  return (
    <div
      className={`overflow-y-auto overflow-x-hidden flex flex-row flex-wrap
        w-full content-start relative h-full p-4 gap-2`}
      ref={setGridRef}
    >
      {data.map((d, id) => {
        return (
          <div
            key={id}
            style={{
              width: itemWidth,
            }}
          >
            {tileGenerator(d, id)}
          </div>
        );
      })}
      <div className="w-full h-1" ref={lastRowRef} />
      {loading && (
        <div className="w-full">
          <div className="py-4 flex items-center justify-center">
            <APLoader.CircleNotch />
          </div>
        </div>
      )}
      {!loading && extraRow && (
        <div className="w-full py-4 flex items-center justify-center">
          {extraRow()}
        </div>
      )}
      <div className="absolute inset-0 pointer-events-none">{overlay?.()}</div>
    </div>
  );
};

CloudTileView.BasicOverlay = CloudDatatable.BasicOverlay;
CloudTileView.BasicExtraRow = CloudDatatable.BasicExtraRow;

export { CloudTileView };
