import {
  faCircle,
  faCircleNotch,
  faSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo, useState } from 'react';

import { InputIdContext } from '../input-id-context';

interface InputCloudCheckboxProps {
  /**
   * The id of the checkbox.
   */
  id: string;

  /**
   * The value of the checkbox.
   */
  value: boolean;

  /**
   * A function to set the value of the checkbox.
   */
  setValue: (value: boolean) => void;

  /**
   * The label to display next to the checkbox.
   */
  label?: React.ReactNode;

  /**
   * The error message to display.
   */
  error?: React.ReactNode;

  /**
   * Specifies whether the checkbox is disabled.
   */
  disabled?: boolean;

  /**
   * Specifies whether the checkbox is loading.
   */
  loading?: boolean;

  /**
   * The circle version of the checkbox.
   */
  circle?: boolean;

  /**
   * Specifies whether the checkbox is white.
   */
  white?: boolean;
}

const InputCloudCheckbox = ({
  id,
  value,
  setValue,
  label,
  error,
  loading,
  disabled,
  circle,
  white,
}: InputCloudCheckboxProps) => {
  const icon = useMemo(() => {
    if (loading) {
      return (
        <FontAwesomeIcon
          key={`loading`}
          icon={faCircleNotch}
          spin
          className={white ? 'text-white' : 'text-primary'}
        />
      );
    }

    if (circle) {
      return (
        <FontAwesomeIcon
          key={`${value}`}
          icon={value ? faCheckCircle : faCircle}
          className={white ? 'text-white' : 'text-primary'}
        />
      );
    }

    return (
      <FontAwesomeIcon
        key={`${value}`}
        icon={value ? faCheckSquare : faSquare}
        className={white ? 'text-white' : 'text-primary'}
      />
    );
  }, [white, loading, circle, value]);

  const [animating, setAnimating] = useState(false);

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col">
        <div
          className={`flex-center text-lg relative ${
            disabled
              ? 'opacity-50 pointer-events-none cursor-not-allowed'
              : 'cursor-pointer'
          }`}
          onClick={() => {
            if (disabled || loading || animating) return;
            setValue(!value);
          }}
          data-test-id={id}
          tabIndex={-1}
        >
          <AnimatePresence mode="popLayout" initial={false}>
            <motion.div
              transition={{
                duration: 0.1,
              }}
              key={icon.key}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
              onAnimationStart={() => setAnimating(true)}
              onAnimationComplete={() => setAnimating(false)}
            >
              {icon}
            </motion.div>
          </AnimatePresence>
        </div>
        {error ?? null}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputCloudCheckbox };
