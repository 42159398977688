import { useMemo } from 'react';

export type LookupTable<T> = { [key: string]: T };

export const useLookupTable = <T,>(
  items: T[] | undefined | null,
  accessor: keyof T
): LookupTable<T> | undefined => {
  const lookupTableMemo = useMemo(() => {
    return items?.reduce((lookupTable, item) => {
      if (item?.[accessor])
        lookupTable[item[accessor] as unknown as string] = item;
      return lookupTable;
    }, {} as LookupTable<T>);
  }, [items, accessor]);

  return lookupTableMemo;
};
