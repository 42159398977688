import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import * as THREE from 'three';

interface Classifications {
  [key: string]: {
    name: string;
    color: number[];
    visible: boolean;
  };
}

export enum MaterialTypeLabels {
  RGBA = 'RGB',
  ELEVATION = 'Elevation',
  CLASSIFICATION = 'Classification',
}

export enum MaterialType {
  RGBA = 'rgba',
  ELEVATION = 'elevation',
  CLASSIFICATION = 'classification',
}

export enum ClipTask {
  NONE = 0,
  HIGHLIGHT = 1,
  SHOW_INSIDE = 2,
  SHOW_OUTSIDE = 3,
}

enum ClipMethod {
  INSIDE_ANY = 0,
  INSIDE_ALL = 1,
}

enum ElevationGradientRepeat {
  CLAMP = 0,
  REPEAT = 1,
  MIRRORED_REPEAT = 2,
}

enum MOUSE {
  LEFT = 0b0001,
  RIGHT = 0b0010,
  MIDDLE = 0b0100,
}

export enum PointSizeType {
  FIXED = 0,
  ATTENUATED = 1,
  ADAPTIVE = 2,
}

enum PointShape {
  SQUARE = 0,
  CIRCLE = 1,
  PARABOLOID = 2,
}

enum TreeType {
  OCTREE = 0,
  KDTREE = 1,
}

export enum PointBudget {
  ONE = 1_000_000,
  FOUR = 4_000_000,
  TEN = 10_000_000,
  TWENTY = 20_000_000,
}

interface LengthTypes {
  [key: string]: {
    code: string;
    unitspermeter: number;
  };
}

interface LengthUnits {
  METER: { code: 'm'; unitspermeter: 1 };
  FEET: { code: 'ft'; unitspermeter: 3.28084 };
  INCH: { code: '\u2033'; unitspermeter: 39.3701 };
}

export interface MeasurementTool {
  viewer: PotreeViewer;
  renderer: any;
  showLabels: boolean;
  scene: any;
  light: any;
  onSceneChange: (e: any) => void;
  startInsertion(args: any): void;
  update(): void;
  render(): void;
  onRemove: (e: any) => void;
  onAdd: (e: any) => void;
}

export declare class PotreeViewer {
  constructor(elem: HTMLElement, args: any);
  onLoad: () => void;
  server: string;
  fov: number;
  isFlipYZ: boolean;
  useDEMCollisions: boolean;
  generateDEM: boolean;
  minNodeSize: number;
  edlStrength: number;
  edlRadius: number;
  edlOpacity: number;
  useEDL: boolean;
  description: string;
  classifications: Classifications;
  moveSpeed: number;
  useHQ: boolean;

  lengthUnit: string;
  lengthUnitDisplay: string;

  showBoundingBox: boolean;
  showAnnotations: boolean;
  freeze: boolean;
  clipTask: ClipTask;
  clipMethod: ClipMethod;

  elevationGradientRepeat: ElevationGradientRepeat;

  filterReturnNumberRange: number[];
  filterNumberOfReturnsRange: number[];
  filterGPSTimeRange: number[];
  filterPointSourceIDRange: number[];

  potreeRenderer: any;
  edlRenderer: any;
  renderer: any;
  pRenderer: any;

  scene: any;
  sceneVR: any;
  overlay: any;
  overlayCamera: any;

  inputHandler: any;
  controls: any;

  clippingTool: any;
  transformationTool: any;
  navigationCube: any;
  compass: any;

  skybox: any;
  clock: any;
  background: any;
  annotationTool: any;
  measuringTool: MeasurementTool;
  profileTool: any;
  volumeTool: any;
  earthControls: any;
  fpControls: any;
  orbitControls: {
    doubleClockZoomEnabled: boolean;
  };

  onCrash: (error: Error) => void;

  setScene: (scene: any) => void;

  setControls: (controls: any) => void;
  getControls: () => any;

  getMinNodeSize: () => number;
  setMinNodeSize: (value: number) => void;

  getBackground: () => string;
  setBackground: (
    arg1: [number, number, number, number] | string,
    arg2?: [number, number, number, number]
  ) => void;

  setDescription: (value: string) => void;
  getDescription: () => string;

  setShowBoundingBox: (value: boolean) => void;

  getShowBoundingBox: () => boolean;

  setMoveSpeed: (value: number) => void;

  getMoveSpeed: () => number;

  setWeightClassification: (value: boolean) => void;

  setFreeze: (value: boolean) => void;

  getFreeze: () => boolean;

  getClipTask: () => string;

  getClipMethod: () => string;

  setClipTask: (value: ClipTask) => void;

  setClipMethod: (value: string) => void;

  setElevationGradientRepeat: (value: boolean) => void;

  setPointBudget: (value: number) => void;

  getPointBudget: () => number;

  setShowAnnotations: (value: boolean) => void;

  getShowAnnotations: () => boolean;

  setDEMCollisionsEnabled: (value: boolean) => void;

  getDEMCollisionsEnabled: () => boolean;

  setEDLEnabled: (value: boolean) => void;

  getEDLEnabled: () => boolean;

  setEDLRadius: (value: number) => void;

  getEDLRadius: () => number;

  setEDLStrength: (value: number) => void;

  getEDLStrength: () => number;

  setEDLOpacity: (value: number) => void;

  getEDLOpacity: () => number;

  setFOV: (value: number) => void;

  getFOV: () => number;

  disableAnnotations: () => void;

  enableAnnotations: () => void;

  setQuality: (value: string) => void;

  setClassifications: (value: any) => void;

  setClassificationVisibility: (key: string, value: boolean) => void;

  toggleAllClassificationsVisibility: () => void;

  setFilterReturnNumberRange: (from: number, to: number) => void;

  setFilterNumberOfReturnsRange: (from: number, to: number) => void;

  setFilterGPSTimeRange: (from: number, to: number) => void;

  setFilterPointSourceIDRange: (from: number, to: number) => void;

  setLengthUnit: (value: string) => void;

  setLengthUnitAndDisplayUnit: (value: string) => void;

  zoomTo: (node: any, factor: number) => void;

  moveToGpsTimeVicinity: (gpsTime: number) => void;

  showAbout: () => void;

  getBoundingBox: (nodes: any) => any;

  getGpsTimeExtent: (nodes: any) => any;

  fitToScreen: (factor?: number, animationDuration?: number) => void;

  toggleNavigationCube: () => void;

  setView: (position: any, target: any, up: any) => void;

  setTopView: () => void;

  setBottomView: () => void;

  setFrontView: () => void;

  setBackView: () => void;

  setLeftView: () => void;
  setRightView: () => void;

  flipYZ: () => void;

  setCameraMode: (mode: string) => void;

  getProjection: () => string;

  loadProject(url: string): Promise<void>;

  saveProject(): void;

  loadSettingsFromURL(): void;

  createControls(): void;

  toggleSidebar(): void;

  toggleMap(): void;

  onGUILoaded(callback: () => void): void;

  promiseGuiLoaded(): Promise<void>;

  setLanguage: (language: string) => void;

  setServer: (server: string) => void;

  initDragAndDrop(): void;

  initThree(): void;

  updateAnnotations(): void;

  updateMaterialDefaults(pointcloud: any): void;

  update: (delta: number, timestamp: number) => void;

  getPRenderer: () => any;

  renderVR: () => void;

  renderDefault: () => void;

  render: () => void;

  resolveTimings: (timestamp: number) => void;

  loop: (timestamp: number) => void;

  postError: (content: any, params: any) => string;

  postMessage: (content: any, params: any) => string;

  zoomToLocation: (location: { x: number; y: number; z: number }) => void;
  setCameraSettings: (settings: CameraState) => void;
  setCameraFrontView: () => void;
  pauseControls: (preventDblClickZoom?: boolean) => void;
  unPauseControls: (preventDblClickZoom?: boolean) => void;

  dispatchEvent: (event: any) => void;
}
export interface IPotree {
  Viewer: typeof PotreeViewer;
  PointSizeType: {
    FIXED: number;
    ATTENUATED: number;
    ADAPTIVE: number;
  };
  loadPointCloud: (
    url: string,
    opts: any,
    name: string,
    callback?: any
  ) => Promise<any>;
  setScriptPath: (path: string) => void;
  resourcePath: string;
  Utils: {
    getMousePointCloudIntersection: (
      mouse: THREE.Vector2,
      camera: THREE.Camera,
      viewer: PotreeViewer,
      pointclouds: any[]
    ) => any;
    mouseToRay: (
      mouse: THREE.Vector2,
      camera: THREE.Camera,
      width: number,
      height: number
    ) => THREE.Ray;
    moveTo: (
      viewer: PotreeViewer,
      position: THREE.Vector3,
      target: THREE.Vector3,
      duration: number
    ) => void;
  };
  PointCloudOctree: {
    name: string;
    material: any;
    // theres a ton of properties here
  };
  BackSide: number;
}

export interface PotreeControlsProps {
  viewer: any;
}

export enum SphereColors {
  Cyan = '#18FCFC',
  Magenta = '#FC18A6',
  Blue = '#001eff',
  Blue500 = '#4080FF',
  Green = '#37FE13',
  Yellow = '#ffe700',
  Purple = '#A117F2',
  Orange = '#FF7148',
  Red = '#f40c66',
}

export enum ColorsThreeD {
  Cyan = '#18FCFC',
  Magenta = '#FC18A6',
  Blue = '#001eff',
  Blue500 = '#4080FF',
  Green = '#37FE13',
  Yellow = '#ffe700',
  Purple = '#A117F2',
  Orange = '#FF7148',
  Red = '#f40c66',
  White = '#ffffff',
}

export enum BackgroundOptionsValues {
  Gradient = 'gradient',
  Black = 'black',
  White = 'white',
}

export enum BackgroundOptionsLabels {
  Gradient = 'Gradient',
  Black = 'Black',
  White = 'White',
}

type buttonOption<N, L> = {
  name: N;
  icon: FontAwesomeIconProps['icon'];
  label: L;
};

export type MeasurementOption = buttonOption<
  MeasurementOptionsValues,
  MeasurementOptionsLabels
>;
export type NavigationOption = buttonOption<
  NavigationOptionsValues,
  NavigationOptionsLabels
>;

export enum MeasurementOptionsValues {
  Angle = 'angle',
  Area = 'area',
  Circle = 'circle',
  Distance = 'distance',
  Height = 'height',
  Point = 'point',
  Volume = 'volume',
  HeightProfile = 'heightProfile',
}

export enum MeasurementOptionsLabels {
  Angle = 'Angle',
  Area = 'Area',
  Circle = 'Radius',
  Distance = 'Distance',
  Height = 'Height',
  Point = 'Point',
  Volume = 'Volume',
  HeightProfile = 'Height Profile',
}

export type MeasurementMode = {
  closed?: boolean;
  maxMarkers?: number;
  name?: string;
  showArea?: boolean;
  showAngles?: boolean;
  showCircle?: boolean;
  showCoordinates?: boolean;
  showDistances?: boolean;
  showEdges?: boolean;
  showHeight?: boolean;
};

export enum NavigationOptionsValues {
  Earth = 'earth',
  FirstPerson = 'firstPerson',
  Helicopter = 'helicopter',
  Orbit = 'orbit',
}

export enum NavigationOptionsLabels {
  Earth = 'Earth',
  FirstPerson = 'First Person',
  Helicopter = 'Helicopter',
  Orbit = 'Orbit',
}

export declare class IDebugPotreeViewer {
  constructor(viewer: PotreeViewer);
  getViewerState: () => any;
}

export interface CameraState {
  position: number[];
  target: number[];
}
