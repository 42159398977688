import {
  faCheck,
  faCircleInfo,
  faClose,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useRef } from 'react';

import { ToastProps } from '@agerpoint/types';
import { timeAgoString, useGlobalStore } from '@agerpoint/utilities';

interface ToastComponentProps extends ToastProps {
  removeToast: (id: string) => void;
}

export const ToastComponent = ({
  id,
  title,
  message,
  timestamp,
  type,
}: ToastComponentProps) => {
  const {
    showingToastPanel,
    actions: { removeToast },
  } = useGlobalStore();

  const autodestructTimeout = useRef<NodeJS.Timeout>();

  const shouldAutodestruct = useMemo(() => {
    if (type === 'neutral') {
      return true;
    }

    return false;
  }, [type]);

  useEffect(() => {
    if (!shouldAutodestruct) {
      return;
    }

    if (!showingToastPanel) {
      autodestructTimeout.current = setTimeout(() => {
        removeToast(id);
        autodestructTimeout.current = undefined;
      }, 1000);
    } else {
      clearTimeout(autodestructTimeout.current);
      autodestructTimeout.current = undefined;
    }
  }, [showingToastPanel]);

  return (
    <div
      className={`relative flex w-full p-3 rounded border mb-2 shadow-md pointer-events-auto
      ${type === 'neutral' ? 'border-gray-800 bg-white' : ''}
      ${type === 'info' ? 'border-blue-400 bg-blue-50' : ''}
      ${type === 'success' ? 'border-green bg-green-50' : ''}
      ${type === 'error' ? 'border-red-300 bg-red-50' : ''}`}
    >
      <FontAwesomeIcon
        icon={faClose}
        className={`absolute right-2 top-2 cursor-pointer text-gray-800
        hover:text-black rounded-full hover:bg-gray-200 h-3.5 w-3.5 p-0.5`}
        onClick={() => {
          removeToast(id);
        }}
      />
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-start gap-2">
          {type === 'error' && (
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-xl text-red-300 pt-1"
            />
          )}
          {type === 'success' && (
            <FontAwesomeIcon
              icon={faCheck}
              className="text-xl text-green pt-1"
            />
          )}
          {type === 'info' && (
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="text-xl text-blue-500 pt-1"
            />
          )}
          <div className="flex flex-col">
            {title && <div className="font-bold pr-3">{title}</div>}
            {message && <div className="text-sm pr-3">{message}</div>}
          </div>
        </div>
        {type !== 'neutral' && (
          <span className="text-xs text-gray-500 self-end leading-none">
            {timeAgoString(timestamp)}
          </span>
        )}
      </div>
    </div>
  );
};
