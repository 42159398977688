import { motion } from 'framer-motion';
import { useMemo } from 'react';

import { InputIdContext } from '../input-id-context';

interface InputToggleOnOffProps {
  /**
   * The id of the toggle input.
   */
  id: string;

  /**
   * The value of the toggle.
   */
  value: boolean;

  /**
   * A function to set the value of the toggle.
   */
  setValue: (value: boolean) => void;

  /**
   * Specifies whether the toggle is disabled.
   */
  disabled?: boolean;

  leadingLabel?: React.ReactNode;

  trailingLabel?: React.ReactNode;
}

export const InputToggleOnOff = ({
  id,
  value,
  setValue,
  disabled,
  leadingLabel,
  trailingLabel,
}: InputToggleOnOffProps) => {
  const containerClassName = useMemo(() => {
    let className =
      'w-8 h-5 flex flex-row p-1 rounded-xl items-center transition-colors duration-300';

    if (value) {
      className += ' bg-primary justify-end ';
    } else {
      className += ' bg-white border border-gray-border justify-start ';
    }

    return className;
  }, [value]);

  const handlerClassName = useMemo(() => {
    let className = 'size-3 rounded-full transition-colors';

    if (value) {
      className += ' bg-white ';
    } else {
      className += ' bg-primary ';
    }

    return className;
  }, [value]);

  return (
    <InputIdContext.Provider value={id}>
      <div
        className={`flex flex-row gap-2 items-center ${
          disabled ? 'opacity-50' : 'cursor-pointer'
        }`}
        onClick={() => {
          if (disabled) {
            return;
          }
          setValue(!value);
        }}
      >
        {leadingLabel ?? null}
        <div className={containerClassName} data-test-id={id}>
          <motion.div
            className={handlerClassName}
            layoutRoot
            layout="position"
            layoutId={`${id}-dot`}
          />
        </div>
        {trailingLabel ?? null}
      </div>
    </InputIdContext.Provider>
  );
};
