import { useGlobalStore } from '@agerpoint/utilities';

export const ThreeDViewerMessage = ({
  message,
}: {
  message: React.ReactNode;
}) => {
  const {
    sidebar: { isOpen: sidebarOpen },
  } = useGlobalStore();

  return (
    <div className="w-full h-full">
      <div
        className={`flex justify-center items-center w-full h-full text-white text-lg text-center ${
          sidebarOpen ? 'pl-sidebar' : ''
        }`}
        style={{
          background: 'radial-gradient(#1b292f, #0c1417)',
        }}
      >
        {message}
      </div>
    </div>
  );
};
