import { useQueryClient } from '@tanstack/react-query';

import { APIClient, APIUtils } from '../..';

export const usePut = () => {
  const queryClient = useQueryClient();
  return APIClient.usePutCaptureObjectCustomAttributeById({
    mutation: {
      onSettled: () => {
        APIUtils.invalidateEntity({
          queryClient,
          entityKey: APIUtils.QueryKey.captureObjects,
        });
      },
      onError: (error) => {
        console.error('usePutCaptureCustomAttribute', error);
      },
    },
  });
};

export const useDelete = () => {
  const queryClient = useQueryClient();
  return APIClient.useDeleteCaptureObjectCustomAttribute({
    mutation: {
      onSettled: () => {
        APIUtils.invalidateEntity({
          queryClient,
          entityKey: APIUtils.QueryKey.captureObjects,
        });
      },
      onError: (error) => {
        console.error('useDeleteCaptureCustomAttribute', error);
      },
    },
  });
};
