import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  DirectedSelectionUntilItem,
  IUseItemSelectionState,
  LastSelection,
  useItemSelectionProps,
} from '@agerpoint/types';

import { useKeyPress } from '../useKeyPress/useKeyPress';

export function useItemSelection<K, V>({
  items,
  idField,
  dependencies: dependenciesProp,
  clearDisappearingData = true,
}: useItemSelectionProps<K, V>): IUseItemSelectionState<K, V> {
  const [selection, setSelection] = useState<Map<K, V>>(new Map<K, V>());
  const [lastSelection, setLastSelection] = useState<LastSelection<K>>();

  const dependencies = useMemo(() => {
    return dependenciesProp.map((d) => JSON.stringify(d)).join('');
  }, [dependenciesProp]);

  const { shiftKey } = useKeyPress();

  useEffect(() => {
    if (shiftKey) {
      document.onselectstart = () => false;
    }

    return () => {
      document.onselectstart = null;
    };
  }, [shiftKey]);

  useEffect(() => {
    if (!lastSelection) {
      return;
    }

    const index = items.findIndex((v) =>
      typeof idField === 'function'
        ? idField(v)
        : v[idField] === lastSelection.key
    );

    if (index === -1) {
      setLastSelection(undefined);
    }
  }, [dependencies]);

  useEffect(() => {
    if (!hasSelectedItems || !clearDisappearingData) {
      return;
    }

    // Remove selection if it's not in the current dataset
    const ids = items.map((d) =>
      typeof idField === 'function' ? idField(d) : d[idField]
    ) as unknown as K[];
    const newSelection = new Map(selection);
    selection.forEach((v, k) => {
      if (!ids.includes(k)) {
        newSelection.delete(k);
      }
    });
    setSelection(newSelection);
  }, [dependencies]);

  const clearSelection = useCallback(() => {
    setSelection(new Map<K, V>());
    setLastSelection(undefined);
  }, []);

  const selectionSize = useMemo(() => selection.size, [selection]);

  const hasSelectedItems = useMemo(() => selection.size !== 0, [selection]);

  const isEverythingSelected = useMemo(
    () => items.length === selectionSize && hasSelectedItems,
    [dependencies, hasSelectedItems, selectionSize]
  );

  const isSelected = useCallback(
    (key: K) => {
      return selection.has(key);
    },
    [selection]
  );

  useEffect(() => {
    // Automatically keep selected items in sync with the items list
    for (const item of items) {
      if (
        isSelected(
          (typeof idField === 'function'
            ? idField(item)
            : item[idField]) as unknown as K
        )
      ) {
        setSelection((prev) => {
          prev.set(
            (typeof idField === 'function'
              ? idField(item)
              : item[idField]) as unknown as K,
            item
          );
          return prev;
        });
      }
    }
  }, [selection, items]);

  const removeSelection = useCallback(
    (key: K) => {
      const newSelection = new Map(selection);
      newSelection.delete(key);
      setLastSelection(undefined);
      setSelection(newSelection);
    },
    [selection]
  );

  const removeSelectionList = useCallback(
    (data: { key: K }[]) => {
      const newSelection = new Map(selection);
      data.forEach((d) => {
        newSelection.delete(d.key);
      });
      setLastSelection(undefined);
      setSelection(newSelection);
    },
    [selection]
  );

  const addSelection = useCallback(
    (key: K, value: V) => {
      const newSelection = new Map(selection);
      newSelection.set(key, value);
      const index = items.findIndex((v) => {
        const a = typeof idField === 'function' ? idField(v) : v[idField];
        const b =
          typeof idField === 'function' ? idField(value) : value[idField];
        return a === b;
      });
      setLastSelection(index > -1 ? { index, key } : undefined);
      setSelection(newSelection);
    },
    [selection, dependencies]
  );

  const addSelectionList = useCallback(
    (data: { key: K; value: V }[]) => {
      const newSelection = new Map(selection);
      data.forEach((d) => {
        newSelection.set(d.key, d.value);
      });

      let index = -1;
      let key: K;
      for (let i = 0; i < items.length; i++) {
        const v = items[i];
        const lastInData = data[data.length - 1];
        if (v === lastInData.value) {
          index = i;
          key = lastInData.key;
          setLastSelection(index > -1 ? { index, key } : undefined);
          break;
        }
      }
      setSelection(newSelection);
    },
    [selection, dependencies]
  );

  const toggleSelection = useCallback(
    (key: K, value: V) => {
      if (isSelected(key)) {
        removeSelection(key);
      } else {
        addSelection(key, value);
      }
    },
    [removeSelection, isSelected, addSelection]
  );

  const selectEverything = useCallback(() => {
    if (!idField) {
      return;
    }
    if (isEverythingSelected) {
      return;
    }
    const newSelection = new Map<K, V>();
    items?.forEach((v) => {
      const key = typeof idField === 'function' ? idField(v) : v[idField];
      newSelection.set(key as unknown as K, v);
    });
    setSelection(newSelection);
  }, [dependencies, isEverythingSelected, idField]);

  const toggleSelectionEverything = useCallback(() => {
    if (isEverythingSelected) {
      clearSelection();
    } else {
      selectEverything();
    }
  }, [isEverythingSelected, clearSelection, selectEverything]);

  const getSelectionArray = useCallback(() => {
    return Array.from(selection.values());
  }, [selection]);

  const getSelectionMap = useCallback(() => {
    return new Map(selection);
  }, [selection]);

  const addBulkSelectionUntilItem = useCallback(
    (key: K, value: V) => {
      if (lastSelection === undefined) {
        addSelection(key, value);
        return;
      }
      const itemIndex = items.findIndex((v) => {
        const a = typeof idField === 'function' ? idField(v) : v[idField];
        const b =
          typeof idField === 'function' ? idField(value) : value[idField];
        return a === b;
      });
      if (itemIndex === -1) {
        return;
      }

      const indexes = [itemIndex, lastSelection.index];
      indexes.sort((a, b) => a - b);
      const [from, to] = indexes;
      const toSelect = items.slice(from, to + 1);

      const newSelection = new Map(selection);
      toSelect.forEach((k) => {
        newSelection.set(
          (typeof idField === 'function'
            ? idField(k)
            : k[idField]) as unknown as K,
          k
        );
      });
      setSelection(newSelection);
      setLastSelection(undefined);
    },
    [dependencies, selection, lastSelection, addSelection, idField]
  );

  const addBulkSelectionUntilItemDirected = useCallback(
    (directions: DirectedSelectionUntilItem<K, V>) => {
      if (lastSelection === undefined) {
        return;
      }

      const upDownIndex = items.findIndex((v) => {
        const a = typeof idField === 'function' ? idField(v) : v[idField];
        const b =
          typeof idField === 'function'
            ? idField(directions.upDown.value)
            : directions.upDown.value[idField];
        return a === b;
      });

      const downUpIndex = items.findIndex((v) => {
        const a = typeof idField === 'function' ? idField(v) : v[idField];
        const b =
          typeof idField === 'function'
            ? idField(directions.downUp.value)
            : directions.downUp.value[idField];
        return a === b;
      });

      if (upDownIndex !== -1 && lastSelection.index <= upDownIndex) {
        addBulkSelectionUntilItem(
          directions.upDown.key,
          directions.upDown.value
        );
      } else if (downUpIndex !== -1 && lastSelection.index >= downUpIndex) {
        addBulkSelectionUntilItem(
          directions.downUp.key,
          directions.downUp.value
        );
      }
    },
    [addBulkSelectionUntilItem, lastSelection, dependencies]
  );

  return {
    addSelection,
    removeSelection,
    toggleSelection,
    isSelected,
    hasSelectedItems,
    selectionSize,
    selectEverything,
    isEverythingSelected,
    toggleSelectionEverything,
    addBulkSelectionUntilItemDirected,
    getSelectionArray,
    getSelectionMap,
    addBulkSelectionUntilItem,
    addSelectionList,
    removeSelectionList,
    clearSelection,
  };
}
