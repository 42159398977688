import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lottie from 'lottie-react';

import apLogoLottie from './aplogo.lottie.json';

interface IAPLoaderCircleNotch {
  className?: string;
}

const APLoaderCircleNotch = ({ className }: IAPLoaderCircleNotch) => {
  return <FontAwesomeIcon icon={faCircleNotch} spin className={className} />;
};

interface IAPLoaderLogo {
  width?: string;
  height?: string;
}

const APLoaderLogo = ({ width = '24px', height = '24px' }: IAPLoaderLogo) => {
  return (
    <Lottie
      animationData={apLogoLottie}
      loop
      autoplay
      style={{
        width: width,
        height: height,
      }}
    />
  );
};

export const APLoader = {
  CircleNotch: APLoaderCircleNotch,
  Logo: APLoaderLogo,
};
