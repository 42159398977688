import { faDownload, faExpand } from '@fortawesome/pro-light-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { APIClient, APIUtils } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { useToasts } from '@agerpoint/utilities';

import { CapturesViewerContext } from '../../captures-viewer/captures-viewer-context';

export const QaqcCaptureVideo = () => {
  const { captureId } = useParams();
  const videoRef = useRef<HTMLVideoElement>(null);
  const queryClient = useQueryClient();
  const toasts = useToasts();

  const { selectedCaptureExtractionJobId } = useContext(CapturesViewerContext);

  const captureVideoQuery = APIClient.useGetCaptureVideosByCaptureId(
    Number(captureId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.extractionJobs,
          { extractionJobId: Number(selectedCaptureExtractionJobId) },
          APIUtils.QueryKey.captureVideos,
        ],
        enabled: Number.isSafeInteger(Number(captureId)),
        select: (data) =>
          data.find(
            (video) =>
              video.captureExtractionJobId ===
              Number(selectedCaptureExtractionJobId)
          ),
      },
    }
  );

  const captureVideoPutMutation = APIClient.usePutCaptureVideoById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.extractionJobs,
            { extractionJobId: Number(selectedCaptureExtractionJobId) },
            APIUtils.QueryKey.captureVideos,
          ],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateListQueryCache({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.extractionJobs,
            { extractionJobId: Number(selectedCaptureExtractionJobId) },
            APIUtils.QueryKey.captureVideos,
          ],
          data: variables.data,
          accessor: 'captureExtractionJobId',
          id: selectedCaptureExtractionJobId,
        });

        if (variables.data.validated) {
          toasts.add(toasts.prepare.entityValidated('capture video'));
        } else {
          toasts.add(toasts.prepare.entityInvalidated('capture video'));
        }
      },
      onError: () => {
        toasts.add(toasts.prepare.error('Failed to update capture video!'));
      },
    },
  });

  const downloadVideo = useCallback(() => {
    if (!captureVideoQuery.data || !captureVideoQuery.data.downloadUrl) return;

    window.open(captureVideoQuery.data.downloadUrl, '_blank');
  }, [captureVideoQuery.data]);

  const expandVideo = useCallback(() => {
    videoRef.current?.requestFullscreen();
  }, []);

  return (
    <div className="relative h-full w-full">
      {captureVideoQuery.data?.downloadUrl ? (
        <>
          <div
            className={`absolute top-0 right-0 flex flex-row gap-2 bg-gray-500 py-1 px-2 rounded pointer-events-auto z-10 items-center`}
          >
            <Button.Icon
              icon={faDownload}
              onClick={downloadVideo}
              id="download-video"
              iconColor="text-white"
              loading={captureVideoQuery.isLoading}
            />
            <Button.Icon
              icon={faExpand}
              onClick={expandVideo}
              label=""
              id="expand-video"
              iconColor="text-white"
            />
          </div>
          <div
            className={`absolute top-0 left-0 flex flex-row gap-2 bg-gray-500 py-1 px-2 rounded pointer-events-auto z-10 items-center`}
          >
            <Input.Checkbox
              id="validate-video"
              label={<Input.Label label="Validated" />}
              value={captureVideoQuery.data?.validated ?? false}
              setValue={(value) => {
                if (!captureVideoQuery.data?.id) return;

                captureVideoPutMutation.mutate({
                  id: captureVideoQuery.data.id,
                  data: {
                    ...captureVideoQuery.data,
                    validated: value,
                  },
                });
              }}
              loading={captureVideoPutMutation.isPending}
            />
          </div>

          <div className="w-full h-full p-1">
            <video
              controls
              ref={videoRef}
              muted
              crossOrigin="anonymous"
              src={captureVideoQuery.data?.downloadUrl ?? ''}
              className="max-w-full max-h-full object-contain"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <div className="text-sm">No Capture Video</div>
        </div>
      )}
    </div>
  );
};
