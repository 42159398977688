import { EventBus, EventBusData, EventBusNames } from '@agerpoint/types';

export const eventBus: EventBus = {
  lookup: {},
  on(
    event: EventBusNames,
    callback: (e: CustomEvent) => void,
    allowMultiple = false
  ) {
    if (this.lookup[event] && !allowMultiple) {
      Object.keys(this.lookup[event]).forEach((key) => {
        document.removeEventListener(event, this.lookup[event][key] as any);
      });
      delete this.lookup[event];
    }

    const fn = (e: Event) => callback((e as CustomEvent).detail);
    const newId = 'id:' + Math.random().toString(16).slice(2);
    document.addEventListener(event, fn);
    const newListenerList = this.lookup[event] || {};
    this.lookup[event] = {
      ...newListenerList,
      [newId]: fn,
    };

    return newId;
  },
  dispatch(event: string, data: EventBusData) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: EventBusNames, callback: any, id: string) {
    if (id && this.lookup[event] && this.lookup[event][id]) {
      document.removeEventListener(event, this.lookup[event][id]);
      delete this.lookup[event][id];
      return;
    }
    const listenerList = this.lookup[event]
      ? Object.values(this.lookup[event])
      : [];
    listenerList.forEach((listenerFn: any) => {
      document.removeEventListener(event, listenerFn);
    });

    delete this.lookup[event];
  },
};
