import {
  faAngleDown,
  faCheck,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment, useCallback, useEffect, useState } from 'react';

import {
  APIClient,
  APIModels,
  APIUtils,
  CaptureExtractionJob,
  usePutCaptureExtractionJobById,
} from '@agerpoint/api';

interface QaqcCaptureJobStatusIndicatorProps {
  extractionJob: CaptureExtractionJob | undefined;
  setExtractionJob: React.Dispatch<
    React.SetStateAction<CaptureExtractionJob | undefined>
  >;
}

export const QaqcCaptureJobStatusIndicator = ({
  extractionJob,
  setExtractionJob,
}: QaqcCaptureJobStatusIndicatorProps) => {
  const queryClient = useQueryClient();

  const {
    mutate: putCaptureExtractionJob,
    loading: loadingPutExtractionJob,
    cancel: cancelPutExtractionJob,
  } = usePutCaptureExtractionJobById({
    id: NaN,
  });

  const captureExtractionJobStatusesQuery =
    APIClient.useGetCaptureExtractionJobStatus({
      query: {
        queryKey: [APIUtils.QueryKey.captureExtractionJobStatuses],
        select: (data) => APIUtils.Sort.captureExtractionJobStatuses(data),
      },
    });

  const [currentStatus, setCurrentStatus] =
    useState<APIModels.CaptureExtractionJobStatus>();

  useEffect(() => {
    if (
      !extractionJob ||
      !extractionJob?.captureExtractionJobStatusId ||
      !captureExtractionJobStatusesQuery.isSuccess
    ) {
      return;
    }

    const status = captureExtractionJobStatusesQuery.data?.find(
      (s) => s.id === extractionJob.captureExtractionJobStatusId
    );

    if (status) {
      setCurrentStatus(status);
    }
  }, [
    extractionJob,
    captureExtractionJobStatusesQuery.isSuccess,
    captureExtractionJobStatusesQuery.data,
  ]);

  const changeStatus = useCallback(
    async (status: APIModels.CaptureExtractionJobStatus) => {
      if (status.id === currentStatus?.id) {
        return;
      }

      if (!extractionJob?.id) {
        return;
      }

      if (loadingPutExtractionJob) {
        cancelPutExtractionJob();
      }

      setCurrentStatus(status);

      const body = {
        ...extractionJob,
        captureExtractionJobStatusId: status.id,
      };

      delete body.createDatetime;
      delete body.updateDatetime;
      delete body.completedDateTime;
      delete body.submittedDateTime;
      delete body.captureExtractionJobStatus;

      try {
        await putCaptureExtractionJob(body, {
          pathParams: {
            id: extractionJob.id as number,
          },
        });

        setExtractionJob((prev) => ({
          ...prev,
          captureExtractionJobStatusId: status.id,
        }));

        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.capturesWithExtractionJobs],
        });
      } catch (e) {
        console.error(e);
        setCurrentStatus(currentStatus);
      }
    },
    [
      currentStatus,
      loadingPutExtractionJob,
      extractionJob,
      putCaptureExtractionJob,
      cancelPutExtractionJob,
      setExtractionJob,
    ]
  );

  if (!extractionJob) {
    return null;
  }

  return currentStatus ? (
    <Listbox value={currentStatus} onChange={changeStatus}>
      <div className="relative w-60">
        <Listbox.Button
          className={`bg-gray-800 relative w-full cursor-pointer rounded-lg p-0.5 text-left shadow-md focus:outline-none
          focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
          focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}
        >
          {({ open }) => (
            <>
              <span className="pointer-events-none absolute inset-y-0 flex items-center right-3.5 text-white">
                <FontAwesomeIcon
                  icon={loadingPutExtractionJob ? faCircleNotch : faAngleDown}
                  spin={loadingPutExtractionJob}
                  className={`transition-transform transform ${
                    open || loadingPutExtractionJob ? '' : '-rotate-90'
                  }`}
                />
              </span>
              <span
                className="block truncate text-white rounded-lg p-0.5 text-center"
                style={{ backgroundColor: currentStatus?.color ?? 'gray' }}
              >
                {currentStatus?.name}
              </span>
            </>
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in-out duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={`bg-gray-800 absolute mt-1 max-h-60 w-full overflow-auto rounded-lg py-1 text-base shadow-md focus:outline-none sm:text-sm`}
          >
            {captureExtractionJobStatusesQuery.data?.map(
              (status, statusInx) => (
                <Listbox.Option
                  key={statusInx}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-0.5 pr-10 pl-2 text-white rounded hover:bg-gray-900 hover:bg-opacity-25`
                  }
                  value={status}
                >
                  {({ selected }) => (
                    <>
                      {selected ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-white">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      ) : null}
                      <span
                        className={`block truncate rounded p-1 text-center ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                        style={{ backgroundColor: status?.color ?? 'gray' }}
                      >
                        {status.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              )
            )}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  ) : null;
};
