import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';

export const QuestionInfo = ({ text }: { text: string }) => {
  const [showText, setShowText] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const [leftOrRight, setLeftOrRight] = useState<'left' | 'right'>('left');
  const [topOrBottom, setTopOrBottom] = useState<'top' | 'bottom'>('top');

  useEffect(() => {
    const componentRect = componentRef.current?.getBoundingClientRect();
    const width = componentRect?.width;
    const height = componentRect?.height;
    const x = componentRect?.x;
    const y = componentRect?.y;
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;

    if (!x || !y || !width || !height) return;

    if (x + width > pageWidth / 2) {
      setLeftOrRight('right');
    } else {
      setLeftOrRight('left');
    }
    if (y + height > pageHeight / 2) {
      setTopOrBottom('bottom');
    } else {
      setTopOrBottom('top');
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setShowText(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showText]);

  return (
    <div className="inline-block relative" ref={componentRef}>
      <FontAwesomeIcon
        icon={faCircleQuestion}
        // onClick={() => {
        //   setShowText((prev) => !prev);
        // }}
        onMouseOver={() => {
          setShowText(true);
        }}
        onMouseLeave={() => {
          setShowText(false);
        }}
        className="cursor-pointer mr-2"
      />
      {showText ? (
        <div
          onClick={() => {
            setShowText((prev) => !prev);
          }}
          id="question-info-div"
          className={`cursor-pointer absolute rounded border overflow-auto
           border-gray-400 bg-gray-150 px-2 py-1 z-200
          text-sm font-medium text-gray-800 w-56 ${
            leftOrRight === 'left' ? 'left-4' : 'right-6'
          } ${topOrBottom === 'top' ? 'top-5' : 'bottom-6'}`}
        >
          {text}
        </div>
      ) : null}
    </div>
  );
};
