import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, useEffect, useMemo, useRef, useState } from 'react';

import {
  GetResponse,
  useGetGeometryCategorizedBinsByGeometryCollectionId,
  useGetMorphologyCategorizedBinsByCollectionId,
} from '@agerpoint/api';
import {
  GradientSelect,
  Select,
  availableGradients,
} from '@agerpoint/component';
import { Attributes, CategorizedBin, CategorizedStyle } from '@agerpoint/types';
import { useGlobalStore, useToasts } from '@agerpoint/utilities';

import { AlphaPicker } from '../color-picker/alpha-picker';
import {
  ActionKind as CategorizedActionKind,
  State as CategorizedState,
} from './categorized-state';
import { Action } from './graduated-state';

interface CategorizedProps {
  collectionId: number;
  availableAttributes?: GetResponse<Attributes, void>;
  state: CategorizedState;
  dispatch: Dispatch<Action>;
  onChange: (style: CategorizedStyle, opacity: number) => void;
  layerTypeId: number;
}

// TODO check what can be common components with Graduated.tsx
export function Categorized({
  collectionId,
  availableAttributes,
  state,
  dispatch,
  onChange,
  layerTypeId,
}: CategorizedProps) {
  const myHook =
    layerTypeId === 8
      ? useGetGeometryCategorizedBinsByGeometryCollectionId
      : useGetMorphologyCategorizedBinsByCollectionId;
  const { data, loading, error } = myHook({
    geometryCollectionId: collectionId,
    collectionId: collectionId,
    attributeName: state.attribute || '',
    lazy: !state.attribute,
    resolve: (data) =>
      data.bins.map((bin: CategorizedBin) => {
        return { value: bin.value, visible: true };
      }),
  });

  const toasts = useToasts();

  useEffect(() => {
    if (error) {
      toasts.add(
        toasts.prepare.error('Failed to calculate attribute categories!')
      );
    }
  }, [error]);

  const [localOpacity, setLocalOpacity] = useState<number>(state.opacity);

  const attributeRef = useRef(state.attribute);
  attributeRef.current = state.attribute;

  const style = useMemo((): CategorizedStyle | null => {
    if (attributeRef.current && data) {
      return {
        attribute: attributeRef.current,
        bins: data as any,
        gradient: state.gradient,
        type: 'categorized',
      };
    }
    return null;
  }, [data, state.gradient]);

  useEffect(() => {
    if (style) {
      onChange(style, state.opacity);
    }
  }, [style, onChange, state.opacity]);

  useEffect(() => {
    if (!state.attribute && availableAttributes) {
      dispatch({
        type: CategorizedActionKind.SetAttribute,
        payload: availableAttributes?.data?.categorized[0],
      });
    }
  }, [state.attribute, availableAttributes, dispatch]);

  return (
    <div>
      {availableAttributes && (
        <div className="mb-3">
          <label
            className="text-sm text-gray-800 font-bold"
            htmlFor="attribute"
          >
            Attribute
          </label>
          {availableAttributes.loading ? (
            <FontAwesomeIcon
              className="flex text-sm text-center text-green fa-spin"
              icon={faCircleNotch}
            />
          ) : availableAttributes.data &&
            availableAttributes.data.categorized.length > 0 ? (
            <Select
              type="small"
              onChange={(event) =>
                dispatch({
                  type: CategorizedActionKind.SetAttribute,
                  payload: event.target.value,
                })
              }
              id="attribute"
              name="attribute"
              options={availableAttributes.data.categorized.map((attribute) => {
                return { name: attribute, value: attribute };
              })}
              value={state.attribute}
            />
          ) : (
            <span className="flex text-xs">No attributes available</span>
          )}
        </div>
      )}
      <GradientSelect
        onChange={(value) =>
          dispatch({ type: CategorizedActionKind.SetGradient, payload: value })
        }
        value={state.gradient}
        options={availableGradients}
      />
      <div className="mt-5">
        <AlphaPicker
          min={0}
          max={1}
          step={0.01}
          values={[localOpacity]}
          onChange={(values) => setLocalOpacity(values[0])}
          onFinalChange={(values) =>
            dispatch({
              type: CategorizedActionKind.SetOpacity,
              payload: values[0],
            })
          }
        />
      </div>
    </div>
  );
}
