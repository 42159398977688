import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { InputIdContext } from '../input-id-context';
import { InputStyle } from '../input-style';
import { InputCloudCheckbox } from './input-cloud-checkbox';

interface InputCheckboxProps {
  /**
   * The id of the checkbox.
   */
  id: string;

  /**
   * The value of the checkbox.
   */
  value: boolean;

  /**
   * A function to set the value of the checkbox.
   */
  setValue: (value: boolean) => void;

  /**
   * The label to display next to the checkbox.
   */
  label?: React.ReactNode;

  /**
   * The error message to display.
   */
  error?: React.ReactNode;

  /**
   * Specifies whether the checkbox is disabled.
   */
  disabled?: boolean;

  /**
   * Specifies whether the checkbox is loading.
   */
  loading?: boolean;

  /**
   * The style of the checkbox.
   */
  style?: InputStyle;
}

const styles: { [key in InputStyle]: string } = {
  [InputStyle.default]: `bg-white rounded-sm duration-100 ease-linear
  leading-none flex flex-shrink-0 items-center justify-center border
border-gray-500 w-5 h-5`,
  [InputStyle.mini]: `bg-white rounded-sm duration-100 ease-linear
  leading-none flex flex-shrink-0 items-center justify-center border
border-gray-500 w-4 h-4 text-sm`,
  [InputStyle.miniDark]: `bg-white rounded-sm duration-100 ease-linear
  leading-none flex flex-shrink-0 items-center justify-center border
border-gray-500 w-4 h-4 text-sm`,
  [InputStyle.cloud]: `bg-white rounded-sm duration-100 ease-linear
leading-none flex flex-shrink-0 items-center justify-center border
border-gray-500 w-5 h-5`,
};

const InputCheckbox = ({
  id,
  value,
  setValue,
  label,
  error,
  loading,
  disabled,
  style = InputStyle.default,
}: InputCheckboxProps) => {
  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col">
        <div
          className={`flex flex-row items-center gap-2 ${
            disabled ? 'opacity-50 pointer-events-none' : 'cursor-pointer'
          }`}
          onClick={() => {
            if (disabled || loading) return;
            setValue(!value);
          }}
          data-test-id={id}
        >
          <span className={styles[style]}>
            {loading && (
              <FontAwesomeIcon
                icon={faCircleNotch}
                className={`text-gray-500`}
                spin
              />
            )}
            {value && !loading && (
              <FontAwesomeIcon icon={faCheck} className={`text-green`} />
            )}
          </span>
          {label && (
            <div className="pointer-events-none select-none">{label}</div>
          )}
        </div>
        {error ?? null}
      </div>
    </InputIdContext.Provider>
  );
};

InputCheckbox.Cloud = InputCloudCheckbox;

export { InputCheckbox };
