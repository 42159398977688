import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';

export interface ContextMenuGroupProps {
  label?: string;
  items: ContextMenuItemProps[];
}

export interface ContextMenuItemProps {
  icon: ReactNode;
  label: string;
  visible?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export interface ContextMenuProps {
  groups: ContextMenuGroupProps[];
  icon?: ReactNode;
  dataTestId?: string;
  parentRef?: HTMLDivElement | null;
  customNoItemsMessage?: string;
  disabled?: boolean;
}

export const ContextMenu = ({
  dataTestId,
  groups: groupsProps,
  icon,
  parentRef,
  customNoItemsMessage,
  disabled,
}: ContextMenuProps) => {
  const [position, setPosition] = useState<{ left: boolean; top: boolean }>({
    left: false,
    top: true,
  });

  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const getLeftTop = useCallback(() => {
    const rect = ref?.getBoundingClientRect();
    const left = rect?.left || 0;
    const top = rect?.top || 0;

    if (parentRef) {
      const parentRect = parentRef.getBoundingClientRect();
      const parentLeft = parentRect.left;
      const parentTop = parentRect.top;
      const isLeft = left < parentLeft + parentRect.width / 2;
      const isTop = top < parentTop + parentRect.height / 2;
      return { left: isLeft, top: isTop };
    } else {
      const { innerWidth, innerHeight } = window;
      const rect = ref?.getBoundingClientRect();
      const left = rect?.left || 0;
      const top = rect?.top || 0;
      const isLeft = left < innerWidth / 2;
      const isTop = top < innerHeight / 2;

      return { left: isLeft, top: isTop };
    }
  }, [ref, parentRef]);

  const groups = useMemo(() => {
    return groupsProps
      .map((group) => {
        return {
          ...group,
          items: group.items.filter((item) => item.visible !== false),
        };
      })
      .filter((group) => group.items.length > 0);
  }, [groupsProps]);

  return (
    <div
      ref={setRef}
      onClick={() => {
        setPosition(getLeftTop());
      }}
    >
      <Menu as="div" onClick={(e) => e.preventDefault()}>
        {({ open, close }) => (
          <>
            <Menu.Button
              className={`flex justify-center cursor-pointer ${
                disabled ? 'pointer-events-none' : ''
              }`.trim()}
              as="div"
            >
              {icon ?? (
                <FontAwesomeIcon
                  className="text-lg text-gray"
                  icon={faEllipsisH}
                  data-test-id={dataTestId}
                />
              )}
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="absolute select-none" style={{ zIndex: 999999 }}>
                <Menu.Items
                  static
                  className={`origin-bottom-right absolute z-50 w-max rounded-md shadow-lg bg-white ring-1 ring-black
                  ring-opacity-5 focus:outline-none divide-y divide-gray-300 overflow-hidden ${
                    position.left ? 'left-0' : '-right-5'
                  } ${position.top ? 'top-0' : 'bottom-5'}`}
                >
                  {groups.length === 0 && (
                    <Menu.Item>
                      <div className="flex items-center justify-center text-gray p-4 text-sm">
                        {customNoItemsMessage ?? 'No Actions Available'}
                      </div>
                    </Menu.Item>
                  )}
                  {groups.map((group, index) => (
                    <div key={index}>
                      {group.label && (
                        <Menu.Item>
                          <div
                            className={`w-full py-1 px-2 pointer-events-none
                        text-sm flex justify-start items-center text-gray-700`}
                          >
                            {group.label}
                          </div>
                        </Menu.Item>
                      )}
                      {group.items.map((item, index) => (
                        <Menu.Item key={index}>
                          <div
                            className={`w-full py-1 px-2 hover:bg-gray-50 text-sm text-gray-700 ${
                              item.disabled
                                ? 'opacity-20 cursor-not-allowed'
                                : 'cursor-pointer'
                            }`}
                            data-test-id={item.dataTestId}
                            onClick={(e) => {
                              e.preventDefault();
                              if (item.disabled) {
                                return;
                              }
                              item.onClick?.(e);
                              close();
                            }}
                          >
                            <div className="flex flex-row gap-1 items-center">
                              <span className="w-4 h-4 flex items-center justify-center">
                                {item.icon}
                              </span>
                              <span>{item.label}</span>
                            </div>
                          </div>
                        </Menu.Item>
                      ))}
                    </div>
                  ))}
                </Menu.Items>
              </div>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
