import { useEffect, useState } from 'react';

import { InputSlider } from '@agerpoint/component';
import { LdFlags, PointBudget } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const StandAloneViewerPointBudgetSelector = () => {
  const {
    permissions,
    standAloneViewer: {
      actions: { setPointBudget },
    },
  } = useGlobalStore();
  const [localPointBudget, setLocalPointBudget] = useState<number>();

  useEffect(() => {
    if (localPointBudget || Object.keys(permissions).length === 0) {
      return;
    }
    const key =
      (permissions[LdFlags.PotreePointBudget] as keyof PointBudget) ??
      PointBudget.FOUR;
    const keyIndex = Object.values(PointBudget).indexOf(key);
    const value = parseInt(Object.keys(PointBudget)[keyIndex]);
    setLocalPointBudget(value / 1000000);
    setPointBudget(value);
  }, [permissions]);

  return (
    <div className="py-1 px-4 w-full">
      <InputSlider
        min={1}
        max={20}
        step={1}
        unit="million(s)"
        showLabels={true}
        values={[localPointBudget ?? 1]}
        onChange={(values) => {
          setLocalPointBudget(values[0]);
        }}
        onFinalChange={(values) => {
          setPointBudget(values[0] * 1000000);
        }}
      />
    </div>
  );
};
