import { ReactNode } from 'react';

export interface BottomPanelProps {
  open: boolean;
  sidebarOpen?: boolean;
  expansionOpen?: boolean;
  children?: ReactNode;
  customHeight?: string;
}

export function BottomPanel({
  sidebarOpen,
  expansionOpen,
  children,
  open = false,
  customHeight = 'h-80',
}: BottomPanelProps) {
  return (
    <div
      className={`fixed bottom-0 right-0 flex z-40 h-8 transition-all duration-300 ${
        expansionOpen
          ? 'left-sidebar-expansion-open '
          : sidebarOpen
          ? 'left-sidebar'
          : 'left-0'
      }`}
    >
      <div className="absolute flex flex-col left-0 w-full bottom-0 border-gray-300 border-t">
        <div
          className={`shadow-xl
                      transition-height
                      duration-300
                      border-r
                      border-gray-300
                      w-full
                      overflow-y-auto
                      bg-white
                      ${open ? customHeight : 'h-0'}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
