import { useCallback } from 'react';

import { useToasts } from '@agerpoint/utilities';

interface EntityDetailsSectionProps {
  title?: string;
  items: EntityDetailsSectionItem[];
}

interface EntityDetailsSectionItem {
  label: string;
  value?: React.ReactNode;
  link?: boolean;
}

export const EntityDetailsSection = ({
  title,
  items,
}: EntityDetailsSectionProps) => {
  const toasts = useToasts();

  const canCopyValue = useCallback((item: EntityDetailsSectionItem) => {
    return typeof item.value === 'string' || typeof item.value === 'number';
  }, []);

  const itemHasValue = useCallback((item: EntityDetailsSectionItem) => {
    return item.value !== null && item.value !== undefined && item.value !== '';
  }, []);

  return (
    <div className="w-full flex flex-col p-4 rounded-lg border border-gray-300 text-sm gap-2 overflow-hidden">
      {title && <div className="text-left font-bold w-full">{title}</div>}
      {items.map((item, index) => (
        <div key={index} className="w-full flex">
          <div className="w-1/2 text-left font-bold pr-1">{item.label}:</div>
          <div
            className="w-1/2 text-left pl-1"
            title={item?.link ? '' : item.value?.toString() ?? undefined}
          >
            {itemHasValue(item) ? (
              canCopyValue(item) ? (
                <span
                  className={`cursor-copy ${
                    item?.link ? '' : 'hover:underline'
                  }`}
                  onClick={() => {
                    if (itemHasValue(item) && !item?.link) {
                      navigator.clipboard.writeText(
                        item.value?.toString() ?? ''
                      );
                      toasts.add(
                        toasts.prepare.valueCopied(item.label.toLowerCase())
                      );
                    }
                  }}
                >
                  {item.value}
                </span>
              ) : (
                <span>{item.value}</span>
              )
            ) : (
              '-'
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
