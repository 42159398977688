import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { UserClaims, hasClaimsRouteGuardProps } from '@agerpoint/types';

import { AgerStore } from '../agerStore';
import { useGlobalStore } from '../store/store';

export const hasClaims = (
  claimsToCheck: UserClaims[],
  usersClaims: UserClaims[] | undefined | null
): boolean => {
  if (!usersClaims || !claimsToCheck) {
    return false;
  }
  return claimsToCheck.every((claim) => usersClaims.includes(claim));
};

export const HasClaimsRouteGuard = ({
  claims,
  children,
}: hasClaimsRouteGuardProps) => {
  const store = useGlobalStore();
  const {
    user: { hasClaim },
  } = useContext(AgerStore);
  let isAllowed = false;
  if (store.user?.cloudClaims) {
    isAllowed = hasClaims(claims, store.user?.cloudClaims as UserClaims[]);
  } else if (hasClaim) {
    isAllowed = hasClaim(claims[0]);
  }

  return isAllowed ? children : <Navigate to="/" replace={true} />;
};

export const HasClaims = ({ claims, children }: hasClaimsRouteGuardProps) => {
  const { user } = useGlobalStore();
  const isAllowed = hasClaims(claims, user?.cloudClaims as UserClaims[]);
  return isAllowed ? children : null;
};
