import {
  faBox,
  faBoxOpen,
  faChartBar,
  faClone,
  faFile,
  faFileCsv,
  faPenToSquare,
  faPlus,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { cloneCaptures } from 'libs/api/src/rq-api';
import { AddCapturesToProjectsModal } from 'libs/feature/src/add-captures-to-projects-modal/add-captures-to-projects-modal';
import { CSVDownloadModal } from 'libs/feature/src/captures-table/captures-table-download';
import { BulkCaptureAnalyticRequestModal } from 'libs/feature/src/captures-table/utilities/bulk-capture-analytic-request-modal';
import { EditCapturesOrgModal } from 'libs/feature/src/edit-captures-org-modal/edit-captures-org-modal';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { APIModels, APIUtils, Customer } from '@agerpoint/api';
import { Button } from '@agerpoint/component';
import {
  CloneDatasetModal,
  useBackgroundTaskManager,
} from '@agerpoint/feature';
import { BackgroundTaskResult, LdFlags, UserClaims } from '@agerpoint/types';
import {
  environment,
  hasPermission,
  useGlobalStore,
  useIsViteApp,
  useItemSelection,
  useToasts,
} from '@agerpoint/utilities';

import { useAdminCapturesQueries } from '../admin-captures-queries';

interface AdminCapturesListActionsProps {
  captureSelection: ReturnType<
    typeof useItemSelection<number, APIModels.Capture>
  >;
  showingArchived: boolean;
}

export const AdminCapturesListActions = ({
  captureSelection,
  showingArchived,
}: AdminCapturesListActionsProps) => {
  const { permissions, user } = useGlobalStore();

  const isViteApp = useIsViteApp();

  const toasts = useToasts();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const backgroundTaskManager = useBackgroundTaskManager();

  const hasCaptureReportPermission = useMemo(
    () => hasPermission(LdFlags.CaptureReport, permissions),
    [permissions]
  );

  const hasSelfServeAnalyticsPermissions = useMemo(
    () => hasPermission(LdFlags.SelfServeAnalytics, permissions),
    [permissions]
  );

  const [showAddCapturesToProjectsModal, setShowAddCapturesToProjectsModal] =
    useState(false);
  const [showEditCapturesOrgModal, setShowEditCapturesOrgModal] =
    useState(false);
  const [showCloneCapturesModal, setShowCloneCapturesModal] = useState(false);
  const [showCSVDownloadModal, setShowCSVDownloadModal] = useState(false);
  const [captureIdToClone, setCaptureIdToClone] = useState<number>();
  const [showAnalyticsModal, setShowAnalyticsModal] = useState<boolean>(false);

  const {
    organizationsQuery,
    usersQuery,
    usersLookupTable,
    captureBulkArchiveMutation,
    captureBulkRestoreMutation,
  } = useAdminCapturesQueries();

  if (captureSelection.selectionSize === 0) {
    return null;
  }

  return (
    <div className="flex flex-row flex-wrap justify-end items-center gap-1">
      {hasSelfServeAnalyticsPermissions && (
        <Button.Primary
          id="analytics-captures-button"
          onClick={() => {
            setShowAnalyticsModal(true);
          }}
          label="Analytics"
          icon={faChartBar}
        />
      )}
      {hasCaptureReportPermission && !isViteApp && (
        <Button.Primary
          id="captures-report-button"
          onClick={() => {
            navigate('/reports/captures', {
              state: {
                selectedCaptures: captureSelection.getSelectionArray(),
              },
            });
          }}
          label="Capture Report"
          icon={faFile}
        />
      )}
      <Button.Primary
        id="clone-captures-button"
        onClick={() => {
          setShowCloneCapturesModal(true);
        }}
        label="Clone"
        icon={faClone}
      />
      <Button.Primary
        id="add-to-project-button"
        onClick={() => {
          setShowAddCapturesToProjectsModal(true);
        }}
        label="Add To Project"
        icon={faPlus}
      />
      <Button.Primary
        id="edit-organization-button"
        onClick={() => {
          setShowEditCapturesOrgModal(true);
        }}
        label="Edit Organization"
        icon={faPenToSquare}
      />
      <Button.Primary
        id="export-captures-button"
        onClick={() => {
          setShowCSVDownloadModal(true);
        }}
        label="Export"
        icon={faFileCsv}
      />
      {!showingArchived ? (
        <Button.Danger
          id="bulk-archive-captures-button"
          onClick={() => {
            const confirm = window.confirm(
              `Are you sure you want to bulk archive ${captureSelection.selectionSize} capture(s)?`
            );

            if (!confirm) {
              return;
            }

            captureBulkArchiveMutation.mutate({
              captures: captureSelection.getSelectionArray(),
            });
          }}
          label="Archive"
          icon={faBox}
          loading={captureBulkArchiveMutation.isPending}
        />
      ) : (
        <Button.Primary
          id="bulk-restore-captures-button"
          onClick={() => {
            const confirm = window.confirm(
              `Are you sure you want to bulk restore ${captureSelection.selectionSize} capture(s)?`
            );

            if (!confirm) {
              return;
            }

            captureBulkRestoreMutation.mutate({
              captures: captureSelection.getSelectionArray(),
            });
          }}
          label="Restore"
          icon={faBoxOpen}
          loading={captureBulkRestoreMutation.isPending}
        />
      )}
      <Button.Secondary
        id="clear-selection-button"
        onClick={() => {
          captureSelection.clearSelection();
        }}
        label={`Clear Selection (${captureSelection.selectionSize})`}
        icon={faXmark}
      />

      <AddCapturesToProjectsModal
        captures={captureSelection.getSelectionArray()}
        handleCloseDialog={() => {
          setShowAddCapturesToProjectsModal(false);
        }}
        open={showAddCapturesToProjectsModal}
        customers={(organizationsQuery.data ?? []) as Customer[]}
        users={usersQuery.data ?? []}
      />

      <EditCapturesOrgModal
        captures={captureSelection.getSelectionArray()}
        handleCloseDialog={() => {
          setShowEditCapturesOrgModal(false);
        }}
        open={showEditCapturesOrgModal}
        customers={(organizationsQuery.data ?? []) as Customer[]}
        changedCaptures={(changedCaptures) => {
          queryClient.invalidateQueries({
            queryKey: [APIUtils.QueryKey.captures],
          });

          for (const capture of changedCaptures) {
            APIUtils.updateInfiniteQueryCache({
              queryClient,
              queryKey: [APIUtils.QueryKey.captures],
              accessor: 'id',
              data: capture,
              id: capture.id,
            });

            APIUtils.updateQueryCache({
              queryClient,
              queryKey: [APIUtils.QueryKey.captures, { captureId: capture.id }],
              data: capture,
            });
          }
        }}
      />

      <CSVDownloadModal
        open={showCSVDownloadModal}
        handleCloseDialog={() => {
          setShowCSVDownloadModal(false);
        }}
        selectedCaptures={captureSelection}
        serverUrl={environment.api_server_url}
        userLookup={usersLookupTable}
      />

      <CloneDatasetModal
        title="Clone Capture(s)"
        handleCloseDialog={() => {
          setCaptureIdToClone(undefined);
          setShowCloneCapturesModal(false);
        }}
        open={showCloneCapturesModal}
        cloningCount={captureIdToClone ? 1 : captureSelection.selectionSize}
        organizations={(organizationsQuery.data ?? []) as Customer[]}
        users={usersQuery.data ?? []}
        onClone={(org, user) => {
          const captureIds: number[] = captureIdToClone
            ? [captureIdToClone]
            : captureSelection
                .getSelectionArray()
                .map((c) => c.id ?? NaN)
                .filter((c) => !Number.isNaN(c));

          backgroundTaskManager.addTaskGroup({
            groupDesc: 'Cloning Capture(s)',
            groupTasks: [
              backgroundTaskManager.createBackgroundTask(
                'Cloning Capture(s)',
                async (resolve) => {
                  await cloneCaptures({
                    assignToUserUuid: user,
                    captureIds: captureIds,
                    customerId: org,
                  });

                  resolve({ type: BackgroundTaskResult.success });
                }
              ),
            ],
            onError: async () => {
              toasts.add(toasts.prepare.error('Failed to clone capture(s)!'));
            },
            onSuccess: async () => {
              toasts.add(toasts.prepare.entityCloned('capture(s)'));

              queryClient.invalidateQueries({
                queryKey: [APIUtils.QueryKey.captures],
              });
            },
          });

          toasts.add({
            title: 'Capture(s) are being cloned.',
            type: 'info',
          });
        }}
      />

      <BulkCaptureAnalyticRequestModal
        showAnalyticsModal={showAnalyticsModal}
        setShowAnalyticsModal={setShowAnalyticsModal}
        selectedCaptures={captureSelection.getSelectionArray()}
      />
    </div>
  );
};
