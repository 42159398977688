import { Feature } from 'ol';
import OlMap from 'ol/Map';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style';
import { useCallback } from 'react';

import { ICloudOpenlayersFeatureLayer } from '../openlayers-map.types';
import {
  CloudOpenlayersMapLayerZIndex,
  cloudOpenlayersStyleCache,
} from '../openlayers-map.utilities';

interface IuseCloudOpenlayersFeatureLayer {
  map: React.MutableRefObject<OlMap | undefined>;
  mapInitialized: boolean;
  layersInitialized: boolean;
}

export const useCloudOpenlayersFeatureLayer = ({
  mapInitialized,
  layersInitialized,
  map,
}: IuseCloudOpenlayersFeatureLayer) => {
  const addFeatureLayer = useCallback(
    (layer: ICloudOpenlayersFeatureLayer<unknown>) => {
      if (!mapInitialized) {
        return;
      }

      const features: Feature<Geometry>[] = [];
      layer.data.forEach((d, index) => {
        const feature = layer.featureBuilder(d, index);
        if (!feature) {
          return;
        }
        const olFeature = new Feature({
          geometry: feature.geometry,
        });
        olFeature.setId(feature.id);
        olFeature.setProperties({ layerId: layer.featureLayerId });

        const featureStyle = layer.styles?.[feature.style];

        if (typeof featureStyle === 'function') {
          const styleKey = `${layer.featureLayerId}-${feature.style}-${feature.id}`;
          if (styleKey in cloudOpenlayersStyleCache) {
            olFeature.setStyle(cloudOpenlayersStyleCache[styleKey]);
          } else {
            const style = featureStyle(olFeature, -1) as StyleLike;
            cloudOpenlayersStyleCache[styleKey] = style;
            olFeature.setStyle(style);
          }
        } else {
          const styleKey = `${layer.featureLayerId}-${feature.style}`;
          if (styleKey in cloudOpenlayersStyleCache) {
            olFeature.setStyle(cloudOpenlayersStyleCache[styleKey]);
          } else {
            cloudOpenlayersStyleCache[styleKey] = featureStyle;
            olFeature.setStyle(featureStyle);
          }
        }

        features.push(olFeature);
      });
      const olVectorSource = new VectorSource({});
      olVectorSource.addFeatures(features);
      const olVectorLayer = new VectorLayer({
        zIndex: CloudOpenlayersMapLayerZIndex.feature,
        properties: { id: layer.featureLayerId },
        maxZoom: layer.maxZoom,
        minZoom: layer.minZoom,
      });
      olVectorLayer.setSource(olVectorSource);
      map.current?.addLayer(olVectorLayer);
    },
    [mapInitialized]
  );

  const updateFeatureLayer = useCallback(
    (layer: ICloudOpenlayersFeatureLayer<unknown>) => {
      if (!layersInitialized) {
        return;
      }

      const olMapLayer = map.current
        ?.getLayers()
        .getArray()
        .find(
          (l) => l.getProperties().id === layer.featureLayerId
        ) as VectorLayer<VectorSource<Feature<Geometry>>>;

      if (!olMapLayer) {
        return;
      }

      const olSource = olMapLayer.getSource();

      if (!olSource) {
        return;
      }

      const features: Feature<Geometry>[] = [];
      layer.data.forEach((d, index) => {
        const feature = layer.featureBuilder(d, index);
        if (!feature) {
          return;
        }
        const olFeature = new Feature({
          geometry: feature.geometry,
        });
        olFeature.setId(feature.id);
        olFeature.setProperties({ layerId: layer.featureLayerId });

        const featureStyle = layer.styles?.[feature.style];

        if (typeof featureStyle === 'function') {
          const styleKey = `${layer.featureLayerId}-${feature.style}-${feature.id}`;
          if (styleKey in cloudOpenlayersStyleCache) {
            olFeature.setStyle(cloudOpenlayersStyleCache[styleKey]);
          } else {
            const style = featureStyle(olFeature, -1) as StyleLike;
            cloudOpenlayersStyleCache[styleKey] = style;
            olFeature.setStyle(style);
          }
        } else {
          const styleKey = `${layer.featureLayerId}-${feature.style}`;
          if (styleKey in cloudOpenlayersStyleCache) {
            olFeature.setStyle(cloudOpenlayersStyleCache[styleKey]);
          } else {
            cloudOpenlayersStyleCache[styleKey] = featureStyle;
            olFeature.setStyle(featureStyle);
          }
        }

        features.push(olFeature);
      });

      olSource.clear();
      olSource.addFeatures(features);
    },
    [layersInitialized]
  );

  const removeFeatureLayer = useCallback(
    (layer: ICloudOpenlayersFeatureLayer<unknown>) => {
      if (!mapInitialized) {
        return;
      }

      const mapLayer = map.current
        ?.getLayers()
        .getArray()
        .find((l) => l.getProperties().id === layer.featureLayerId);

      if (mapLayer) {
        mapLayer.dispose();
        map.current?.removeLayer(mapLayer);
      }
    },
    [mapInitialized]
  );

  return { addFeatureLayer, updateFeatureLayer, removeFeatureLayer };
};
