import { faFilterSlash } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIModels } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useGlobalStore,
  usePageTitle,
  useQueryState,
} from '@agerpoint/utilities';

import { useAdminPipelinesQueries } from './admin-pipelines-queries';

export const AdminPipelinesList = () => {
  const navigate = useNavigate();

  usePageTitle(() => 'Platform - Pipelines', []);
  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v.trim(),
    toUrlParam: (v) => v.trim(),
  });

  const { permissions } = useGlobalStore();
  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );

  const { pipelinesQuery } = useAdminPipelinesQueries();

  const filteredPipelines = useMemo(() => {
    let data: APIModels.Pipeline[] | undefined = undefined;

    if (pipelinesQuery.data) {
      data = [...pipelinesQuery.data];
    }

    if (data === undefined) {
      return undefined;
    }

    const filter = nameFilter.trim().toLowerCase();
    if (filter !== '') {
      data = data?.filter((d) => d.name?.toLowerCase().includes(filter));
    }

    return data?.sort((a, b) => compare(a.id, b.id));
  }, [nameFilter, pipelinesQuery]);

  const clearFilters = useCallback(() => {
    setNameFilter('');
  }, []);

  const hasFiltersApplied = useMemo(
    () => !!((nameFilter?.length ?? 0) > 0),
    [nameFilter]
  );

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="flex flex-row gap-1 justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">Pipelines</h1>
        {canManageAnalyticRequests && (
          <Button.Primary
            id="new-pipeline-button"
            label="New Pipeline"
            icon={faPlus}
            onClick={() => {
              navigate('new', {
                state: { params: window.location.search },
              });
            }}
          />
        )}
      </div>
      <div className="px-4 flex flex-row justify-start gap-2 flex-wrap">
        <div className="w-80">
          <Input.Text.Single
            id="pipelines-search-input"
            value={nameFilter}
            setValue={setNameFilter}
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 h-full w-full">
        <Datatable
          id="admin-pipelines-datatable"
          data={filteredPipelines ?? []}
          style={{ ...dataTableAgerStyle, tableMinWidth: 650 }}
          loading={pipelinesQuery.isLoading || filteredPipelines === undefined}
          cellOnClick={() => {
            return (row) => {
              navigate(`${row.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching pipelines',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : canManageAnalyticRequests
              ? {
                  title: 'No pipelines yet',
                  message: 'Create pipeline to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Pipeline',
                }
              : {
                  title: 'No pipelines yet',
                  message: 'Contact your administrator to create a pipeline',
                }
          }
          error={
            pipelinesQuery.isError
              ? {
                  title: 'There was a problem loading mosaic engines',
                  message: 'Try refreshing the page',
                  action: () => {
                    pipelinesQuery.refetch();
                  },
                }
              : undefined
          }
          columns={[
            { label: 'ID', value: (row) => row.id, flex: 0.25, name: 'id' },
            { label: 'Name', value: (row) => row.name, name: 'name' },
            {
              label: 'Path',
              value: (row) => row.path,
              flex: 2,
              name: 'path',
            },
            {
              label: 'Description',
              value: (row) => row.description,
              flex: 2,
              name: 'description',
            },
          ]}
          rowHeight={50}
        />
      </div>
    </div>
  );
};
