import { PrimaryButtonLink } from '@agerpoint/component';
import { MapMode, onChangeModeFn } from '@agerpoint/types';

interface ProjectPanelFooterProps {
  onChangeMode?: onChangeModeFn;
  currentMode: MapMode;
  fusionLink: string | null;
}

export function ProjectPanelFooter({
  onChangeMode,
  currentMode,
  fusionLink,
}: ProjectPanelFooterProps) {
  return (
    <div className="flex justify-between pl-5 py-3 w-full">
      {fusionLink && (
        <PrimaryButtonLink
          label={'Fusion'}
          theme="secondary"
          size="small"
          href={fusionLink}
          target="_blank"
        />
      )}
    </div>
  );
}
