import { InputCapture } from './input-capture';
import { InputCheckbox } from './input-checkbox';
import { InputDate } from './input-date';
import { InputError } from './input-error';
import { InputFile } from './input-file';
import { InputLabel } from './input-label';
import { InputSelect } from './input-select';
import { InputText } from './input-text';
import { InputToggle } from './input-toggle/input-toggle';
import { InputValidators } from './input-validators';

const CloudInput = {
  Date: InputDate,
  Text: InputText,
  File: InputFile,
  Checkbox: InputCheckbox,
  Capture: InputCapture,
  Select: InputSelect,
  Label: InputLabel,
  Error: InputError,
  Toggle: InputToggle,
  validators: InputValidators,
};

export { CloudInput };
