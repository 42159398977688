import React from 'react';

import { InputIdContext } from '../input-id-context';
import { InputRadioElement } from './input-radio-element';

interface InputRadioWrapperProps {
  id: string;
  options?: InputRadioWrapperOption[];
  value: string | undefined;
  setValue: (value?: string) => void;
  label?: React.ReactNode;
  error?: React.ReactNode;
}

interface InputRadioWrapperOption {
  label: React.ReactNode;
  value: string;
}

export const InputRadioWrapper = ({
  id,
  options,
  label,
  error,
  value,
  setValue,
}: InputRadioWrapperProps) => {
  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col items-start">
        {label ?? null}
        {options?.map((option, index) => (
          <InputRadioElement
            key={index}
            id={`${id}-${index}`}
            label={option.label}
            elementValue={option.value}
            currentValue={value}
            setValue={setValue}
          />
        ))}
        {error ?? null}
      </div>
    </InputIdContext.Provider>
  );
};
