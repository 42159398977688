import { useEffect, useState } from 'react';
import { useDebouncyEffect } from 'use-debouncy';

import {
  CaptureExtractionJob,
  usePutCaptureExtractionJobById,
} from '@agerpoint/api';
import { Input } from '@agerpoint/component';

export const QaqcCaptureNotes = ({
  extractionJob,
  setExtractionJob,
  isExpanded,
}: {
  extractionJob?: CaptureExtractionJob;
  setExtractionJob: React.Dispatch<
    React.SetStateAction<CaptureExtractionJob | undefined>
  >;
  isExpanded: boolean;
}) => {
  const [notesValue, setNotesValue] = useState<string>('');
  const { mutate: putCaptureNotes, cancel: cancelLastPutCaptureNotes } =
    usePutCaptureExtractionJobById({
      id: NaN,
    });

  useEffect(() => {
    setNotesValue(extractionJob?.notes ?? '');
  }, [extractionJob]);

  useDebouncyEffect(
    () => {
      if (!extractionJob?.id) {
        return;
      }

      const body = {
        ...extractionJob,
      };

      delete body.createDatetime;
      delete body.updateDatetime;
      delete body.completedDateTime;
      delete body.submittedDateTime;
      delete body.captureExtractionJobStatus;

      body.notes = notesValue.trimEnd();

      const doAsync = async () => {
        if (!extractionJob?.id) {
          return;
        }
        cancelLastPutCaptureNotes();

        try {
          await putCaptureNotes(body, {
            pathParams: { id: extractionJob?.id },
          });
          setExtractionJob((prev) => {
            if (!prev) {
              return prev;
            }

            prev.notes = notesValue.trimEnd();
            return prev;
          });
        } catch (e) {
          console.error(e);
        }
      };
      doAsync();
    },
    1000,
    [notesValue]
  );

  return (
    <div className="flex flex-col w-full text-gray-900">
      <div className="text-xs leading-4 w-full text-gray-400 pb-1 ">Notes:</div>
      <Input.Text.Area
        id="capture-notes"
        value={notesValue}
        setValue={setNotesValue}
        rows={3}
        disabled={!isExpanded}
      />
    </div>
  );
};
