import { ButtonAccent } from './button-accent/button-accent';
import { ButtonBack } from './button-back/button-back';
import { ButtonClearFilter } from './button-clear-filter/button-clear-filter';
import { ButtonDanger } from './button-danger/button-danger';
import { ButtonIcon } from './button-icon/button-icon';
import { ButtonPrimary } from './button-primary/button-primary';
import { ButtonSecondary } from './button-secondary/button-secondary';
import { ButtonSmall } from './button-small/button-small';
import { ButtonText } from './button-text/button-text';
import { ButtonThin } from './button-thin/button-thin';

const Button = {
  Back: ButtonBack,
  Primary: ButtonPrimary,
  Secondary: ButtonSecondary,
  Small: ButtonSmall,
  Icon: ButtonIcon,
  Text: ButtonText,
  Danger: ButtonDanger,
  ClearFilter: ButtonClearFilter,
  Thin: ButtonThin,
  Accent: ButtonAccent,
};

export { Button };
