import { Feature } from 'ol';
import OlMap from 'ol/Map';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style';
import { useCallback } from 'react';

import { ICloudOpenlayersClusterFeatureLayer } from '../openlayers-map.types';
import {
  CloudOpenlayersMapLayerZIndex,
  cloudOpenlayersStyleCache,
} from '../openlayers-map.utilities';

interface IuseCloudOpenlayersClusterFeatureLayer {
  map: React.MutableRefObject<OlMap | undefined>;
  mapInitialized: boolean;
  layersInitialized: boolean;
}

export const useCloudOpenlayersClusterFeatureLayer = ({
  mapInitialized,
  layersInitialized,
  map,
}: IuseCloudOpenlayersClusterFeatureLayer) => {
  const addClusterFeatureLayer = useCallback(
    (layer: ICloudOpenlayersClusterFeatureLayer<unknown>) => {
      if (!mapInitialized) {
        return;
      }

      const features: Feature<Geometry>[] = [];
      layer.data.forEach((d, index) => {
        const feature = layer.featureBuilder(d, index);
        if (!feature) {
          return;
        }

        const olFeature = new Feature({
          geometry: feature.geometry,
        });
        olFeature.setId(feature.id);
        olFeature.setProperties({
          layerId: layer.clusterFeatureLayerId,
          style: feature.style,
        });

        features.push(olFeature);
      });
      const olVectorSource = new VectorSource({});
      olVectorSource.addFeatures(features);
      const olVectorLayer = new VectorLayer({
        zIndex: CloudOpenlayersMapLayerZIndex.feature,
        properties: { id: layer.clusterFeatureLayerId },
        maxZoom: layer.maxZoom,
        minZoom: layer.minZoom,
      });

      const olClusterSource = new Cluster({
        distance: layer.cluterDistance,
        minDistance: layer.clusterMinDistance,
        source: olVectorSource,
        createCluster: (cluster, features) => {
          const size = features.length;
          const olClusterFeature = new Feature({
            geometry: cluster,
          });
          olClusterFeature.setProperties({
            features: features,
            layerId: layer.clusterFeatureLayerId,
          });

          const style =
            features.find((f) => f.get('style') !== 'default')?.get('style') ??
            'default';
          const featureStyle = layer.styles?.[style] ?? layer.styles?.default;

          if (typeof featureStyle === 'function') {
            const styleKey = `${layer.clusterFeatureLayerId}-${style}-${size}`;
            if (styleKey in cloudOpenlayersStyleCache) {
              olClusterFeature.setStyle(cloudOpenlayersStyleCache[styleKey]);
            } else {
              const style = featureStyle(olClusterFeature, -1) as StyleLike;
              cloudOpenlayersStyleCache[styleKey] = style;
              olClusterFeature.setStyle(style);
            }
          }

          return olClusterFeature;
        },
      });

      olVectorLayer.setSource(olClusterSource);
      map.current?.addLayer(olVectorLayer);
    },
    [mapInitialized]
  );

  const updateClusterFeatureLayer = useCallback(
    (layer: ICloudOpenlayersClusterFeatureLayer<unknown>) => {
      if (!layersInitialized) {
        return;
      }

      const olMapLayer = map.current
        ?.getLayers()
        .getArray()
        .find(
          (l) => l.getProperties().id === layer.clusterFeatureLayerId
        ) as VectorLayer<Cluster>;

      if (!olMapLayer) {
        return;
      }

      const olClusterSource = olMapLayer.getSource();

      if (!olClusterSource) {
        return;
      }

      const olSource = olClusterSource.getSource();

      if (!olSource) {
        return;
      }

      const features: Feature<Geometry>[] = [];
      layer.data.forEach((d, index) => {
        const feature = layer.featureBuilder(d, index);
        if (!feature) {
          return;
        }

        const olFeature = new Feature({
          geometry: feature.geometry,
        });
        olFeature.setId(feature.id);
        olFeature.setProperties({
          layerId: layer.clusterFeatureLayerId,
          style: feature.style,
        });

        features.push(olFeature);
      });

      olSource.clear();
      olSource.addFeatures(features);
    },
    [layersInitialized]
  );

  const removeClusterFeatureLayer = useCallback(
    (layer: ICloudOpenlayersClusterFeatureLayer<unknown>) => {
      if (!mapInitialized) {
        return;
      }

      const mapLayer = map.current
        ?.getLayers()
        .getArray()
        .find((l) => l.getProperties().id === layer.clusterFeatureLayerId);

      if (mapLayer) {
        mapLayer.dispose();
        map.current?.removeLayer(mapLayer);
      }
    },
    [mapInitialized]
  );

  return {
    addClusterFeatureLayer,
    updateClusterFeatureLayer,
    removeClusterFeatureLayer,
  };
};
