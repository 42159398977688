import {
  faCheck,
  faCircleNotch,
  faPlay,
  faTurnDownLeft,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useBackgroundTaskManager } from 'libs/feature/src/background-task-manager/background-task-manager';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MutateMethod } from 'restful-react';

import {
  APIClient,
  APIUtils,
  AnalyticRequest,
  Capture,
  CustomerAnalytic,
  useGetCustomerAnalyticsByCustomerId,
  usePostCaptureHighResWithMlModelJob,
} from '@agerpoint/api';
import { PrimaryButton, QuestionInfo } from '@agerpoint/component';
import {
  AnalyticRequestStatus,
  BackgroundTaskArgs,
  BackgroundTaskResult,
  CaptureJobTypes,
  CaptureModalNavigationProps,
  EffectNames,
  analyticRequestStatusLookup,
} from '@agerpoint/types';
import {
  getColorByStatus,
  getIconByStatus,
  useGlobalStore,
  useToasts,
} from '@agerpoint/utilities';

import { getTabNavigationRoutes } from '../../capture-modal.utils';
import { ManageCaptureContext } from '../../manage-capture-context';

export const CaptureDetailsSectionAnalyticsStepper = ({
  isBulk = false,
  selectedCaptures = [],
  selectedBulkAnalytic = {},
  setShowAnalyticsModal,
  postAnalyticRequest,
  resetSelectedAnalytic,
}: {
  isBulk?: boolean;
  selectedCaptures?: Capture[];
  selectedBulkAnalytic?: CustomerAnalytic;
  setShowAnalyticsModal?: (show: boolean) => void;
  postAnalyticRequest: MutateMethod<void, AnalyticRequest, void, void>;
  resetSelectedAnalytic?: () => void;
}) => {
  const selectedAnalyticText = `Automatically identify features using Machine Learning algorithms on this capture.`;

  const params = useParams();
  const id = useMemo(() => {
    return params.captureId ?? params.id;
  }, [params]);

  const navigate = useNavigate();
  const location = useLocation();
  const {
    actions: { dispatchEffect },
  } = useGlobalStore();

  const captureQuery = APIClient.useGetCaptureById(Number(id), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(id) }],
      enabled: Number.isSafeInteger(Number(id)),
    },
  });

  const toasts = useToasts();
  const queryClient = useQueryClient();
  const { addTaskGroup, createBackgroundTask } = useBackgroundTaskManager();
  const {
    analyticRequests,
    actions: { refetchAnalyticRequests },
  } = useContext(ManageCaptureContext);

  const [selectedAnalytic, setSelectedAnalytic] = useState<CustomerAnalytic>(
    {} as CustomerAnalytic
  );
  const [captureId, setCaptureId] = useState<number>(NaN);
  const [showLoadingIndicator, setShowLoadingIndicator] =
    useState<boolean>(true);
  const [hasHighResJobs, setHasHighResJobs] = useState<boolean>(false);
  const [jobTriggered, setJobTriggered] = useState(false);
  const [customerId, setCustomerId] = useState(NaN);
  const [currentStatus, setCurrentStatus] = useState<AnalyticRequestStatus>();
  const [tabRoutes, setTabRoutes] = useState<CaptureModalNavigationProps>({
    models: '',
    analytics: '',
    details: '',
    projects: '',
  });
  const [bulkCreditPricing, setBulkCreditPricing] = useState<number>(NaN);

  const { mutate: postCaptureHighResJobWithMosaicModel, loading } =
    usePostCaptureHighResWithMlModelJob({
      id: NaN,
      engineId: NaN,
      modelId: NaN,
    });

  const { data: customerAnalytics, refetch: refetchCustomerAnalytics } =
    useGetCustomerAnalyticsByCustomerId({
      lazy: true,
      customerId,
    }) as unknown as {
      data: CustomerAnalytic[] | undefined;
      refetch: () => void;
    };

  //shared
  useEffect(() => {
    if (customerId) {
      refetchCustomerAnalytics();
    }
  }, [customerId]);

  useEffect(() => {
    const routes = getTabNavigationRoutes(
      location.search,
      location.pathname,
      captureId,
      params?.eptId
    );
    setTabRoutes(routes);
  }, [location, captureId, params]);

  const addJobsToQueue = useCallback(
    (customersAnalytic: CustomerAnalytic, captures: Capture[]) => {
      const tasks: BackgroundTaskArgs[] = [];

      const task: BackgroundTaskArgs = createBackgroundTask(
        `Analytic processing started`,
        async (resolve, reject, abortSignal) => {
          const requestedAnalytic: AnalyticRequest = {
            customerAnalyticId: customersAnalytic.id,
            analyticCreditCost: customersAnalytic.analyticCreditCost,
            captureIds: captures.map((c) => c?.id ?? NaN),
          };
          try {
            await postAnalyticRequest(requestedAnalytic, {
              signal: abortSignal,
            });
          } catch (e) {
            resolve({ type: BackgroundTaskResult.error, payload: e });
          }
          resolve({ type: BackgroundTaskResult.success });
          refetchAnalyticRequests?.();
          dispatchEffect(EffectNames.REFETCH_ANALYTIC_REQUESTS);
        }
      );
      tasks.push(task);

      addTaskGroup({
        groupDesc: 'Triggering Capture Analytic Job(s)',
        groupTasks: tasks,
        isCancellable: true,
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: [
              APIUtils.QueryKey.captures,
              { captureId: Number(captureId) },
              APIUtils.QueryKey.analyticRequests,
            ],
          });
          toasts.add({
            title: 'Analytic processing started!',
            type: 'success',
          });
        },
      });
      setJobTriggered(true);
    },
    [
      postAnalyticRequest,
      createBackgroundTask,
      addTaskGroup,
      refetchAnalyticRequests,
      captureId,
    ]
  );

  const prepJobsForQueue = useCallback(
    (customerAnalytic: CustomerAnalytic) => {
      if (!customerAnalytics || (!captureQuery.data && !isBulk)) return;

      let caps: Capture[] = [];
      if (isBulk) {
        caps = selectedCaptures.map((c) => ({ id: c.id } as Capture));
      } else {
        caps = [captureQuery.data || ({} as Capture)];
      }

      const customersAnalytic: CustomerAnalytic | undefined =
        customerAnalytics.find((a) => {
          return a.id === customerAnalytic.id;
        });

      if (!customersAnalytic) return;

      addJobsToQueue(customerAnalytic, caps);
    },
    [
      customerAnalytics,
      selectedAnalytic,
      setJobTriggered,
      addJobsToQueue,
      toasts,
      selectedCaptures,
      captureQuery.data,
      customerAnalytics,
      captureId,
    ]
  );

  // bulk
  useEffect(() => {
    if (!isBulk) return;
    setSelectedAnalytic(selectedBulkAnalytic);
  }, [selectedBulkAnalytic]);

  useEffect(() => {
    if (!isBulk) return;
    if (!selectedCaptures?.length || !selectedCaptures[0]?.customerId) return;
    setCustomerId(selectedCaptures[0].customerId);
    setShowLoadingIndicator(false);
  }, [selectedCaptures]);

  useEffect(() => {
    if (!isBulk) return;
    if (!selectedCaptures?.length || !selectedCaptures[0]?.customerId) return;
    const analyticPrice = selectedAnalytic?.analyticCreditCost || 0;
    const totalCreditPrice = analyticPrice * selectedCaptures.length;
    setBulkCreditPricing(totalCreditPrice);
  }, [selectedAnalytic, selectedCaptures]);

  // single
  useEffect(() => {
    if (params?.analyticUriName && customerAnalytics) {
      const selected = customerAnalytics.find(
        (a) => a.analyticId == params.analyticUriName
      );
      setSelectedAnalytic(selected || ({} as CustomerAnalytic));
    }
    if (id) {
      setCaptureId(parseInt(id));
    }
  }, [params, customerAnalytics]);

  useEffect(() => {
    if (!captureQuery.data || !captureQuery.data.completedJobs?.length) return;
    const highResJobs = captureQuery.data.completedJobs.filter(
      (j) => j.captureJobTypeId === CaptureJobTypes['High Resolution']
    );
    if (highResJobs.length) {
      setHasHighResJobs(true);
    }
    setShowLoadingIndicator(false);

    if (!captureQuery.data?.customerId) return;
    setCustomerId(captureQuery.data.customerId);
  }, [captureQuery.data]);

  useEffect(() => {
    const analyticRequest = analyticRequests?.find(
      (ar) =>
        ar?.customerAnalytic?.analytic?.id === selectedAnalytic?.analyticId
    );

    if (!analyticRequest?.status) return;
    setCurrentStatus(analyticRequest.status as AnalyticRequestStatus);
  }, [selectedAnalytic, analyticRequests]);

  return (
    <>
      <div className="flex flex-col h-full p-2">
        {selectedAnalytic.analyticId && (
          <>
            <div className="pb-2 w-48 flex justify-between text-sm font-bold">
              <span>Selected Analytic</span>{' '}
              <QuestionInfo text={selectedAnalyticText} />
            </div>

            <div className="flex flex-row flex-grow p-2 justify-center border rounded my-8 mx-12 ">
              {/* left column  */}
              <div className="flex flex-col h-full justify-center w-1/2">
                <h5 className="flex justify-center items-center mb-2 text-2xl font-semibold tracking-tight text-gray-900 truncate">
                  {selectedAnalytic?.analytic?.analyticName}
                </h5>
                <p className="flex justify-center mb-3 font-normal text-gray-500 text-sm">
                  {selectedAnalytic?.analytic?.analyticDescription}
                </p>
                {currentStatus ? (
                  <HasStatusSection status={currentStatus} />
                ) : (
                  <HasNoStatusSection
                    isBulk={isBulk}
                    selectedAnalytic={selectedAnalytic}
                    selectedCaptures={selectedCaptures}
                    bulkCreditPricing={bulkCreditPricing}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-row justify-end">
              {!loading && (
                <PrimaryButton
                  className="mr-2"
                  label="Go Back"
                  icon={<FontAwesomeIcon icon={faTurnDownLeft} />}
                  onClicked={() => {
                    if (isBulk && setShowAnalyticsModal) {
                      resetSelectedAnalytic?.();
                    } else {
                      navigate('..', { relative: 'path' });
                    }
                  }}
                  theme="white"
                />
              )}
              {!loading && !jobTriggered && !currentStatus && (
                <>
                  <PrimaryButton
                    disabled={showLoadingIndicator}
                    label="Confirm"
                    icon={<FontAwesomeIcon icon={faPlay} />}
                    onClicked={() => {
                      prepJobsForQueue(selectedAnalytic);
                    }}
                  />
                </>
              )}
              {loading && (
                <div className="flex flex-row items-center justify-center">
                  <FontAwesomeIcon
                    spin
                    icon={faCircleNotch}
                    className="text-green"
                  />
                  <span className="ml-2">Processing...</span>
                </div>
              )}
              {!loading && jobTriggered && (
                <div className="flex flex-row items-center justify-center">
                  <FontAwesomeIcon icon={faCheck} className="text-green" />
                  <span className="ml-2">Analytic Started...</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const HasStatusSection = ({ status }: { status: AnalyticRequestStatus }) => {
  const color = getColorByStatus(status);
  const icon = getIconByStatus(status);
  return (
    <div className="flex flex-col justify-center">
      <div
        className="flex flex-row justify-center items-center"
        style={{ color }}
      >
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className={`fa-2x`}
            spin={status === AnalyticRequestStatus.PROCESSING}
          />
        )}
        <span className="pl-2">{status}</span>
      </div>
      <div className="flex justify-center my-3 font-normal text-gray-500 text-sm">
        {analyticRequestStatusLookup[status].description}
      </div>
    </div>
  );
};

const HasNoStatusSection = ({
  isBulk,
  selectedAnalytic,
  selectedCaptures,
  bulkCreditPricing,
}: {
  isBulk: boolean;
  selectedAnalytic: CustomerAnalytic;
  selectedCaptures: Capture[];
  bulkCreditPricing: number;
}) => {
  return isBulk ? (
    <>
      <div className="flex flex-row justify-center">
        <span
          className={`bg-green-100 text-green-800 text-xl font-medium mr-2 px-2.5 py-0.5 rounded border border-green-400`}
        >
          {`${bulkCreditPricing} Credits`}
        </span>
      </div>
      <p className="flex justify-center my-3 font-normal text-gray-500 text-sm">
        {selectedAnalytic?.analyticCreditCost} Credits per capture x{' '}
        {selectedCaptures?.length} captures
      </p>
    </>
  ) : (
    <div className="flex flex-row w-full justify-center">
      <span
        className={`bg-green-100 text-green-800 text-xl font-medium px-2.5 py-0.5 rounded border border-green-400`}
      >
        {`${selectedAnalytic?.analyticCreditCost} Credits`}
      </span>
    </div>
  );
};
