import { LabelHTMLAttributes } from 'react';

export function Label({
  className,
  children,
  ...props
}: LabelHTMLAttributes<HTMLLabelElement>) {
  return (
    <label
      className={`mt-2 mb-2 block font-normal text-gray-700 ${
        className ? className : ''
      }`}
      {...props}
    >
      {children}
    </label>
  );
}
