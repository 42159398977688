import { useEffect, useMemo, useState } from 'react';
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { UseGetReturn } from 'restful-react';

import {
  APIClient,
  APIUtils,
  AnalyticRequest,
  Capture,
  useGetAnalyticRequestsByCaptureId,
} from '@agerpoint/api';
import { ConfirmModal, DialogModal, TabsComponent } from '@agerpoint/component';
import {
  EffectNames,
  LdFlags,
  TabNames,
  TabProps,
  TabThemes,
  UserClaims,
} from '@agerpoint/types';
import { hasClaims, hasPermission, useGlobalStore } from '@agerpoint/utilities';

import {
  allCaptureModalPaths,
  getGoBackRoute,
  getTabNavigationRoutes,
} from './capture-modal.utils';
import {
  ManageCaptureContext,
  ManageCaptureContextProps,
} from './manage-capture-context';

export const ManageCaptureWrapper = ({
  archiveCapture,
  unarchiveCapture,
  refetchCaptureTable,
  refetchAnalyticRequestsForCapture,
}: {
  archiveCapture: (c: Capture) => void;
  unarchiveCapture: (c: Capture) => void;
  refetchCaptureTable?: () => void;
  refetchAnalyticRequestsForCapture?: () => void;
}) => {
  const { id, eptId, captureId: id2 } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    permissions,
    user,
    actions: { dispatchEffect },
  } = useGlobalStore();

  const [captureId, setCaptureId] = useState<number>(NaN);
  const [showArchiveConfirm, setShowArchiveConfirm] = useState(false);
  const [showUnarchiveConfirm, setShowUnarchiveConfirm] = useState(false);
  const [detailsLink, setDetailsLink] = useState('');
  const [analyticsLink, setAnalyticsLink] = useState('');
  const [projectsLink, setProjectsLink] = useState('');
  const [modelsLink, setModelsLink] = useState('');
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const [modalOpen, setModalOpen] = useState(NaN);
  const [isAdmin, setIsAdmin] = useState(false);
  const [analyticRequests, setAnalyticRequests] = useState<AnalyticRequest[]>(
    []
  );

  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)),
    },
  });

  const { data: ar, refetch: refetchAnalyticRequests } =
    useGetAnalyticRequestsByCaptureId({
      id: captureQuery.data?.id ?? NaN,
      lazy: true,
    }) as unknown as UseGetReturn<AnalyticRequest[], void, void, unknown>;

  useEffect(() => {
    if (!ar) return;
    setAnalyticRequests(ar);
  }, [ar]);

  useEffect(() => {
    if (captureQuery.data?.id) {
      refetchAnalyticRequests();
    }
  }, [captureQuery.data?.id]);

  useEffect(() => {
    if (id2) {
      setCaptureId(parseInt(id2));
    } else if (id) {
      setCaptureId(parseInt(id));
    }
  }, [id, id2]);

  useEffect(() => {
    const hasClaim = hasClaims(
      [UserClaims.AgerAdmin],
      (user?.cloudClaims || []) as UserClaims[]
    );
    setIsAdmin(hasClaim);
  }, [user]);

  useEffect(() => {
    // the capture detail modal is implemented in several places, we need unique links for each place
    const { analytics, details, models, projects } = getTabNavigationRoutes(
      location.search,
      location.pathname,
      captureId,
      eptId
    );

    setDetailsLink(details);
    setAnalyticsLink(analytics);
    setModelsLink(models);
    setProjectsLink(projects);

    if (
      !['details', 'analytics', 'models', 'projects'].some((s) =>
        location.pathname.includes(s)
      )
    ) {
      // for now this handles a user clicking the back button
      setModalOpen(NaN);
    }
  }, [location.pathname, captureId]);

  useEffect(() => {
    const t: TabProps[] = [
      {
        label: '3D Models',
        link: modelsLink,
        type: TabNames.LINK,
      },
      {
        label: 'Projects',
        link: projectsLink,
        type: TabNames.LINK,
      },
      {
        label: 'Details',
        link: detailsLink,
        type: TabNames.LINK,
      },
    ];

    if (hasPermission(LdFlags.SelfServeAnalytics, permissions)) {
      t.splice(1, 0, {
        label: 'Analytics',
        link: analyticsLink,
        type: TabNames.LINK,
      });
    }

    if (!captureQuery.data?.id) {
      setTabs(t);
      return;
    }

    if (captureQuery.data?.archived) {
      t.push({
        label: 'Restore',
        type: TabNames.BUTTON,
        buttonClickHandler: confirmUnarchiveFn,
        buttonTheme: TabThemes.RED,
      });
    } else {
      t.push({
        label: 'Archive',
        type: TabNames.BUTTON,
        buttonClickHandler: confirmArchive,
        buttonTheme: TabThemes.RED,
      });
    }
    setTabs(t);
  }, [detailsLink, analyticsLink, permissions, captureQuery.data]);

  // this is how we automatically open the modal at anytime the route is changed to a capture modal route
  useEffect(() => {
    const hasMatch = allCaptureModalPaths.some((route) => {
      return matchPath(route, location.pathname);
    });
    if (hasMatch) {
      setModalOpen(captureId);
    }
  }, [location, captureId]);

  const goBack = (replace?: boolean) => {
    const goBackPath = getGoBackRoute(
      location.search,
      location.pathname,
      captureId,
      eptId
    );
    navigate(goBackPath, { replace: replace });
  };

  const confirmArchive = () => {
    setShowArchiveConfirm(true);
  };

  const confirmArchiveFn = async () => {
    await archiveCapture({ id: captureId } as Capture);
    setShowArchiveConfirm(false);
    setModalOpen(NaN);
    if (refetchCaptureTable) {
      refetchCaptureTable();
    }
    navigate('/captures', { replace: true });
  };

  const cancelArchiveFn = () => {
    setShowArchiveConfirm(false);
  };

  const confirmUnarchiveFn = async () => {
    await unarchiveCapture({ id: captureId } as Capture);
    setShowUnarchiveConfirm(false);
    setModalOpen(NaN);
    goBack();
  };

  const cancelUnarchiveFn = () => {
    setShowUnarchiveConfirm(false);
  };

  const manageCaptureContextValue = useMemo<ManageCaptureContextProps>(() => {
    return {
      analyticRequests: analyticRequests,
      actions: {
        refetchAnalyticRequests: refetchAnalyticRequests,
      },
    };
  }, [analyticRequests]);

  return (
    <ManageCaptureContext.Provider value={manageCaptureContextValue}>
      <ConfirmModal
        isOpen={showArchiveConfirm}
        canConfirm={true}
        title="Archive Capture"
        message={`Are you certain you want to archive this capture? This action is irreversible and the capture cannot be restored.`}
        confirm={{
          label: 'Confirm',
          callback: confirmArchiveFn,
        }}
        close={{
          label: 'Cancel',
          callback: cancelArchiveFn,
        }}
      />
      <ConfirmModal
        isOpen={showUnarchiveConfirm}
        canConfirm={true}
        title="Restore Capture"
        message={`Are you certain you want to restore this capture?`}
        confirm={{
          label: 'Confirm',
          callback: confirmUnarchiveFn,
        }}
        close={{
          label: 'Cancel',
          callback: cancelUnarchiveFn,
        }}
      />
      <DialogModal
        open={!!modalOpen}
        title={'Manage Capture'}
        size="large"
        handleCloseDialog={() => {
          if (!captureQuery.data?.captureName) {
            // dont let the modal close if the capture name is not set
            return;
          }
          refetchCaptureTable?.();
          dispatchEffect(EffectNames.REFETCH_ANALYTIC_REQUESTS);
          goBack(true);
          setModalOpen(NaN);
        }}
      >
        <div className="flex h-144 flex-col w-full">
          <TabsComponent tabs={tabs} />
          <div className="flex-grow">
            <Outlet />
          </div>
        </div>
      </DialogModal>
    </ManageCaptureContext.Provider>
  );
};
