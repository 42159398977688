import { TouchEventHandler, useCallback, useRef } from 'react';

interface IuseLongTouch<T> {
  callback: (data: T) => void;
  time?: number;
}

export const useLongTouch = <T,>({
  callback,
  time = 500,
}: IuseLongTouch<T>): ((data: T) => {
  onTouchStart: TouchEventHandler;
  onTouchEnd: TouchEventHandler;
  onTouchMove: TouchEventHandler;
  onTouchCancel: TouchEventHandler;
}) => {
  const timer = useRef<NodeJS.Timeout>();

  const endTouch = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
  }, []);

  const startTouch = useCallback(
    (data: T) => {
      timer.current = setTimeout(() => {
        callback(data);
        endTouch();
      }, time);
    },
    [callback, time, endTouch]
  );

  return useCallback(
    (data: T) => {
      return {
        onTouchStart: () => startTouch(data),
        onTouchEnd: () => endTouch(),
        onTouchMove: () => endTouch(),
        onTouchCancel: () => endTouch(),
      };
    },
    [startTouch, endTouch]
  );
};
