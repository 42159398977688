import {
  BottomPanel as BottomPanelComponent,
  ProjectIconButton,
} from '@agerpoint/feature';
import {
  Group,
  Layer,
  LayerTypeName,
  PanelsState,
  ProjectState,
  WmsVectorMapLayer,
} from '@agerpoint/types';

import { AttributesTable } from './attributes-table';
import { shallow } from 'zustand/shallow';
import { usePanels } from '../../state/use-panels';
import { useProject } from '../../state/use-project';

export default function BottomPanel() {
  const { groups } = useProject(getGroups, shallow);
  const {
    currentExpansionId,
    toolMode,
    isSidebarOpen,
    setToolMode,
    selectedLayerId,
    selectedFeatureIds,
    setSelectedFeatureIds,
  } = usePanels(getBottomPanelState, shallow);
  const isBottomPanelOpen = toolMode === 'attributes';
  const selectedLayer = isBottomPanelOpen
    ? findLayer(groups, selectedLayerId)
    : undefined;
  const selectedVectorLayer =
    selectedLayer &&
    selectedLayer.type === 'WmsVector' &&
    (selectedLayer.layerTypeName === LayerTypeName.Collections ||
      selectedLayer.typeId === 8)
      ? (selectedLayer as WmsVectorMapLayer)
      : undefined;

  return (
    <BottomPanelComponent
      expansionOpen={!!currentExpansionId}
      open={isBottomPanelOpen}
      sidebarOpen={isSidebarOpen}
    >
      <div className="absolute pr-1 pt-1 right-0 top-0 z-20 bg-white">
        <ProjectIconButton onClick={() => setToolMode(undefined)} />
      </div>
      {selectedLayer && selectedVectorLayer ? (
        <AttributesTable
          layer={selectedVectorLayer}
          selectedFeatureIds={selectedFeatureIds}
          setSelectedFeatureIds={setSelectedFeatureIds}
          layerTypeId={selectedLayer.typeId}
        />
      ) : (
        <div className="m-4">
          {selectedLayer
            ? 'Attributes are only supported for collections/morphologies at the moment.'
            : 'Please select a layer to show attributes.'}
        </div>
      )}
    </BottomPanelComponent>
  );
}

function getGroups({ groups }: ProjectState) {
  return { groups };
}

function getBottomPanelState({
  currentExpansionId,
  toolMode,
  isSidebarOpen,
  setToolMode,
  selectedLayerId,
  selectedFeatureIds,
  setSelectedFeatureIds,
}: PanelsState) {
  return {
    currentExpansionId,
    toolMode,
    isSidebarOpen,
    setToolMode,
    selectedLayerId,
    selectedFeatureIds,
    setSelectedFeatureIds,
  };
}

function findLayer(groups: Group[], layerId?: number): Layer | undefined {
  for (const group of groups) {
    for (const layer of group.layers) {
      if (layer.id === layerId) {
        return layer;
      }
    }
  }

  return undefined;
}
