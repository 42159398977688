import { useGetLayersByLayerGroup } from '@agerpoint/api';
import { ProjectState } from '@agerpoint/types';
import { useEffect } from 'react';
import { isClientSideGroup, useProject } from '../../state/use-project';

export function LoadLayers() {
  const groups = useProject(getGroups);

  return (
    <>
      {groups
        .filter((group) => !isClientSideGroup(group))
        .map((group) => (
          <LoadGroupLayers key={group.id} groupId={group.id} />
        ))}
    </>
  );
}

function getGroups({ groups }: ProjectState) {
  return groups;
}

interface LoadGroupLayersProps {
  groupId: number;
}

function LoadGroupLayers({ groupId }: LoadGroupLayersProps) {
  const { data } = useGetLayersByLayerGroup({ id: groupId });
  const setGroupLayers = useProject(getSetGroupLayers);

  useEffect(() => {
    if (data) {
      setGroupLayers(groupId, data);
    }
  }, [data, setGroupLayers, groupId]);

  return null;
}

function getSetGroupLayers({
  actions: {
    groups: { setLayers },
  },
}: ProjectState) {
  return setLayers;
}
