import { faCircleNotch, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DatatableDND } from 'libs/feature/src/datatable/datatable-dnd';
import { dataTableAgerStyle } from 'libs/feature/src/datatable/datatable-old';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  APIClient,
  APIModels,
  APIUtils,
  CustomerAnalytic,
} from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePrevious,
  useToasts,
} from '@agerpoint/utilities';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminOrganizationsQueries } from './admin-organizations-queries';

export const AdminOrganizationsDetailsAnalytics = () => {
  const { permissions } = useGlobalStore();

  const { organizationId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const {
    organizationQuery,
    organizationAnalyticsQuery,
    analyticsQuery,
    organizationAnalyticPostMutation,
    organizationAnalyticsPriorityPutMutation,
  } = useAdminOrganizationsQueries();

  const availableAnalytics = useMemo(() => {
    if (!organizationAnalyticsQuery.data) {
      return [];
    }
    return analyticsQuery.data?.filter(
      (a) =>
        !organizationAnalyticsQuery.data?.find((oa) => oa.analyticId === a.id)
    );
  }, [analyticsQuery.data, organizationAnalyticsQuery.data]);

  const canEditAnalytics = useMemo(
    () => hasPermission(LdFlags.AnalyticsEditOrganizationDetails, permissions),
    [permissions]
  );

  const formValidation = useFormValidation();

  const [newAnalyticEntry, setNewAnalyticEntry] =
    useState<APIModels.CustomerAnalytic>({});
  const [selectedAnalytic, setSelectedAnalytic] = useState<
    APIModels.Analytic | undefined
  >();

  useEffect(() => {
    setNewAnalyticEntry((prev) => ({
      ...prev,
      analyticId: selectedAnalytic?.id,
      analyticCreditCost: selectedAnalytic?.dc,
    }));
  }, [selectedAnalytic]);

  const addAnalytic = useCallback(async () => {
    if (organizationAnalyticPostMutation.isPending) {
      return;
    }
    if (await formValidation.hasErrors()) {
      return;
    }

    const data: APIModels.CustomerAnalytic = {
      ...newAnalyticEntry,
      customerId: Number(organizationId),
    };

    organizationAnalyticPostMutation.mutate(
      {
        data: data,
      },
      {
        onSuccess: () => {
          setSelectedAnalytic(undefined);
        },
      }
    );
  }, [
    formValidation,
    organizationAnalyticPostMutation,
    newAnalyticEntry,
    organizationId,
  ]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (
        !result.destination ||
        organizationAnalyticsPriorityPutMutation.isPending ||
        !canEditAnalytics
      ) {
        return;
      }

      const startIndex = result.source.index;
      const endIndex = result.destination.index;

      if (startIndex === endIndex) {
        return;
      }

      const copy = cloneDeep(organizationAnalyticsQuery.data);

      if (!copy) {
        return;
      }

      // Remove the item on the start index
      const [removed] = copy.splice(startIndex, 1);

      // Add the removed item to the end index
      copy.splice(endIndex, 0, removed);

      // Update the priority of the items
      copy.forEach((item, index) => {
        item.priority = index;
      });

      organizationAnalyticsPriorityPutMutation.mutate({
        customerId: Number(organizationId),
        customerAnalytics: copy,
      });
    },
    [
      organizationAnalyticsQuery.data,
      canEditAnalytics,
      organizationAnalyticsPriorityPutMutation,
      organizationId,
    ]
  );

  if (organizationQuery.isLoading || organizationAnalyticsQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (organizationQuery.isError || organizationAnalyticsQuery.isError) {
    return (
      <PageErrorState
        entityName="organization"
        pluralEntityName="organizations"
        statusCode={
          organizationAnalyticsQuery.error?.response?.status ??
          organizationQuery.error?.response?.status ??
          500
        }
        tryAgainCallback={() => {
          organizationQuery.refetch();
          organizationAnalyticsQuery.refetch();
        }}
        tryAgainLoading={
          organizationQuery.isFetching || organizationAnalyticsQuery.isFetching
        }
        navigateBackCallback={() =>
          navigate(
            isViteApp
              ? '/app/admin/platform/organizations' + params
              : '/admin/organizations' + params
          )
        }
      />
    );
  }

  return (
    <div className="px-4 flex w-full h-full flex-col overflow-auto lg:overflow-hidden">
      <div className="w-full flex flex-row font-bold pb-2">
        Review and manage the analytics available to this organization
      </div>

      <div className="w-full h-full flex flex-col gap-2">
        {canEditAnalytics ? (
          <span className="flex items-stretch gap-2">
            <span className="flex gap-2">
              <Input.Select.Single
                id="analytic-select"
                options={availableAnalytics ?? []}
                loading={
                  organizationAnalyticsQuery.isLoading ||
                  analyticsQuery.isLoading
                }
                optionBuilder={(o) => o.analyticName ?? 'Unknown'}
                title="Analytic"
                value={selectedAnalytic}
                setValue={setSelectedAnalytic}
                placeholder="Pick An Analytic"
                error={
                  <Input.Error
                    error={formValidation.errors['analytic-select']}
                  />
                }
                validation={{
                  validationState: formValidation,
                  validators: [Input.validators.required('Analytic')],
                }}
              />
              <span className="w-44">
                <Input.Number.Integer
                  id="analytics-cost-input"
                  value={newAnalyticEntry?.analyticCreditCost ?? undefined}
                  setValue={(cost) => {
                    setNewAnalyticEntry((prev) => ({
                      ...prev,
                      analyticCreditCost: cost,
                    }));
                  }}
                  placeholder="Credit Cost"
                  onlyPositive
                  error={
                    <Input.Error
                      error={formValidation.errors['analytics-cost-input']}
                    />
                  }
                  validation={{
                    validationState: formValidation,
                    validators: [Input.validators.required('Credit Cost')],
                  }}
                />
              </span>
            </span>

            <Button.Primary
              id="add-analytic-button"
              label="Add Analytic"
              disabled={organizationAnalyticsQuery.isLoading}
              loading={organizationAnalyticPostMutation.isPending}
              onClick={addAnalytic}
            />
          </span>
        ) : (
          <span className="w-full text-sm">
            Check with product team to update an Organization&apos;s Available
            Analytics
          </span>
        )}

        <div className="w-full flex-grow pb-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <DatatableDND
              data={organizationAnalyticsQuery.data ?? []}
              droppableId="organization-analytics-dnd"
              isDropDisabled={
                !canEditAnalytics ||
                organizationAnalyticsPriorityPutMutation.isPending
              }
              isDragDisabled={
                !canEditAnalytics ||
                organizationAnalyticsPriorityPutMutation.isPending
              }
              style={{ ...dataTableAgerStyle, tableMinWidth: 1000 }}
              columns={[
                {
                  label: 'Priority',
                  value: (row) => {
                    if (organizationAnalyticsPriorityPutMutation.isPending) {
                      return <FontAwesomeIcon icon={faCircleNotch} spin />;
                    }

                    return row.priority;
                  },
                  flex: 0.2,
                },
                {
                  label: 'Name',
                  value: (row) => row.analytic?.analyticName,
                },
                {
                  label: 'Credit Cost',
                  value: (row, index) => (
                    <AdminOrganizationsDetailsAnalyticsCostInput
                      key={`${row.id}-${index}-${row.priority}-cost`}
                      customerAnalytic={row}
                      index={index}
                      canEditAnalytics={canEditAnalytics}
                    />
                  ),
                },
                {
                  label: 'Availability',
                  value: (row, index) => (
                    <AdminOrganizationsDetailsAnalyticsAvailabilityInput
                      key={`${row.id}-${index}-${row.priority}-availability`}
                      customerAnalytic={row}
                      index={index}
                      canEditAnalytics={canEditAnalytics}
                    />
                  ),
                  flex: 0.5,
                },
                {
                  label: 'Auto Complete',
                  value: (row, index) => (
                    <AdminOrganizationsDetailsAnalyticsAutoCompleteInput
                      key={`${row.id}-${index}-${row.priority}-availability`}
                      customerAnalytic={row}
                      index={index}
                      canEditAnalytics={canEditAnalytics}
                    />
                  ),
                  flex: 0.3,
                },
                {
                  visible: canEditAnalytics,
                  label: '',
                  value: (row, index) => (
                    <AdminOrganizationsDetailsAnalyticsRemoveButton
                      key={`${row.id}-${index}-${row.priority}-remove-button`}
                      customerAnalytic={row}
                      index={index}
                      canEditAnalytics={canEditAnalytics}
                    />
                  ),
                  flex: 0.4,
                  style: {
                    bodyStyle: 'flex justify-center items-center',
                  },
                },
              ]}
              rowHeight={70}
              draggableUniqueId={(row) =>
                JSON.stringify({
                  customerId: row.customerId,
                  analyticId: row.analyticId,
                })
              }
            />
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

const AdminOrganizationsDetailsAnalyticsCostInput = ({
  customerAnalytic,
  index,
  canEditAnalytics,
}: {
  customerAnalytic: APIModels.CustomerAnalytic;
  index: number;
  canEditAnalytics?: boolean;
}) => {
  const queryClient = useQueryClient();
  const toasts = useToasts();
  const [cost, setCost] = useState<number | undefined>(
    customerAnalytic?.analyticCreditCost
  );

  const formValidation = useFormValidation();

  const organizationAnalyticPutMutation = useMutation({
    mutationFn: async (data: {
      id: number;
      data: APIModels.CustomerAnalytic;
    }) => {
      const copy = cloneDeep(data.data);

      delete copy.analytic;

      return APIClient.putCustomerAnalyticById(data.id, copy);
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(variables.data.customerId) },
          APIUtils.QueryKey.analytics,
        ],
      });
    },
    onSuccess: (_, variables) => {
      APIUtils.updateListQueryCache<APIModels.CustomerAnalytic>({
        queryClient,
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(variables.data.customerId) },
          APIUtils.QueryKey.analytics,
        ],
        accessor: 'id',
        data: variables.data,
        id: variables.id,
      });

      toasts.add(
        toasts.prepare.entityUpdated('organization', 'Analytic cost updated.')
      );
    },
    onError: (e) => {
      console.error(e);

      toasts.add(toasts.prepare.error('Failed to update analytic cost!'));
    },
  });

  return (
    <div className="p-1 flex flex-row gap-1">
      <Input.Number.Integer
        onlyPositive
        id={`analytic-cost-${index}`}
        value={cost}
        readOnly={!canEditAnalytics || customerAnalytic?.archived}
        setValue={setCost}
        placeholder="Credit Cost"
        validation={{
          validationState: formValidation,
          validators: [Input.validators.required('Required', true)],
        }}
        error={
          <Input.Error
            error={formValidation.errors[`analytic-cost-${index}`]}
          />
        }
      />
      {canEditAnalytics && (
        <Button.Primary
          id={`update-analytic-cost-button-${index}`}
          label="Update"
          loading={organizationAnalyticPutMutation.isPending}
          disabled={customerAnalytic?.archived}
          onClick={async () => {
            if (organizationAnalyticPutMutation.isPending) {
              return;
            }

            if (await formValidation.hasErrors()) {
              return;
            }

            const data = { ...customerAnalytic, analyticCreditCost: cost };
            organizationAnalyticPutMutation.mutate({
              id: customerAnalytic?.id as number,
              data: data,
            });
          }}
        />
      )}
    </div>
  );
};

const AdminOrganizationsDetailsAnalyticsAvailabilityInput = ({
  customerAnalytic,
  index,
  canEditAnalytics,
}: {
  customerAnalytic: APIModels.CustomerAnalytic;
  index: number;
  canEditAnalytics?: boolean;
}) => {
  const queryClient = useQueryClient();
  const toasts = useToasts();

  const organizationAnalyticPutMutation = useMutation({
    mutationFn: async (data: {
      id: number;
      data: APIModels.CustomerAnalytic;
    }) => {
      const copy = cloneDeep(data.data);

      delete copy.analytic;

      return APIClient.putCustomerAnalyticById(data.id, copy);
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(variables.data.customerId) },
          APIUtils.QueryKey.analytics,
        ],
      });
    },
    onSuccess: (_, variables) => {
      APIUtils.updateListQueryCache<APIModels.CustomerAnalytic>({
        queryClient,
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(variables.data.customerId) },
          APIUtils.QueryKey.analytics,
        ],
        accessor: 'id',
        data: variables.data,
        id: variables.id,
      });

      toasts.add(
        toasts.prepare.entityUpdated(
          'organization',
          'Analytic availability updated.'
        )
      );
    },
    onError: (e) => {
      console.error(e);

      toasts.add(
        toasts.prepare.error('Failed to update analytic availability!')
      );
    },
  });

  return (
    <div className="p-1">
      <Input.Select.Inline
        id={`availability-select-${index}`}
        options={[true, false]}
        value={customerAnalytic?.available}
        setValue={(value) => {
          if (value === customerAnalytic?.available) {
            return;
          }
          if (organizationAnalyticPutMutation.isPending) {
            return;
          }

          const data: APIModels.CustomerAnalytic = {
            ...customerAnalytic,
            available: value,
          };
          organizationAnalyticPutMutation.mutate({
            id: customerAnalytic?.id as number,
            data: data,
          });
        }}
        optionBuilder={(o) => (o ? 'Yes' : 'Coming Soon')}
        readOnly={
          !canEditAnalytics ||
          organizationAnalyticPutMutation.isPending ||
          customerAnalytic?.archived
        }
      />
    </div>
  );
};

const AdminOrganizationsDetailsAnalyticsRemoveButton = ({
  customerAnalytic,
  index,
  canEditAnalytics,
}: {
  customerAnalytic: APIModels.CustomerAnalytic;
  index: number;
  canEditAnalytics?: boolean;
}) => {
  const queryClient = useQueryClient();
  const toasts = useToasts();

  const organizationAnalyticPutMutation = useMutation({
    mutationFn: async (data: {
      id: number;
      data: APIModels.CustomerAnalytic;
    }) => {
      const copy = cloneDeep(data.data);

      delete copy.analytic;

      return APIClient.putCustomerAnalyticById(data.id, copy);
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(variables.data.customerId) },
          APIUtils.QueryKey.analytics,
        ],
      });
    },
    onSuccess: (_, variables) => {
      APIUtils.updateListQueryCache<APIModels.CustomerAnalytic>({
        queryClient,
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(variables.data.customerId) },
          APIUtils.QueryKey.analytics,
        ],
        accessor: 'id',
        data: variables.data,
        id: variables.id,
      });

      toasts.add(
        toasts.prepare.entityUpdated('organization', 'Analytic removed.')
      );
    },
    onError: (e) => {
      console.error(e);

      toasts.add(toasts.prepare.error('Failed to remove analytic!'));
    },
  });

  return (
    <div className="p-1">
      <Button.Danger
        id={`remove-analytic-button-${index}`}
        label="Remove"
        loading={organizationAnalyticPutMutation.isPending}
        disabled={customerAnalytic?.archived || !canEditAnalytics}
        icon={faTrash}
        onClick={() => {
          if (organizationAnalyticPutMutation.isPending || !canEditAnalytics) {
            return;
          }

          const confirm = window.confirm(
            `Are you sure you want to remove this analytic?`
          );
          if (!confirm) {
            return;
          }

          const data: APIModels.CustomerAnalytic = {
            ...customerAnalytic,
            archived: true,
          };
          organizationAnalyticPutMutation.mutate({
            id: customerAnalytic?.id as number,
            data: data,
          });
        }}
      />
    </div>
  );
};

const AdminOrganizationsDetailsAnalyticsAutoCompleteInput = ({
  customerAnalytic,
  index,
  canEditAnalytics,
}: {
  customerAnalytic: APIModels.CustomerAnalytic;
  index: number;
  canEditAnalytics?: boolean;
}) => {
  const queryClient = useQueryClient();
  const toasts = useToasts();

  const organizationAnalyticPutMutation = useMutation({
    mutationFn: async (data: {
      id: number;
      data: APIModels.CustomerAnalytic;
    }) => {
      const copy = cloneDeep(data.data);

      delete copy.analytic;

      return APIClient.putCustomerAnalyticById(data.id, copy);
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(variables.data.customerId) },
          APIUtils.QueryKey.analytics,
        ],
      });
    },
    onSuccess: (_, variables) => {
      APIUtils.updateListQueryCache<APIModels.CustomerAnalytic>({
        queryClient,
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(variables.data.customerId) },
          APIUtils.QueryKey.analytics,
        ],
        accessor: 'id',
        data: variables.data,
        id: variables.id,
      });

      toasts.add(
        toasts.prepare.entityUpdated(
          'organization',
          'Analytic auto complete updated.'
        )
      );
    },
    onError: (e) => {
      console.error(e);

      toasts.add(
        toasts.prepare.error('Failed to update analytic auto complete!')
      );
    },
  });

  return (
    <div className="p-1">
      <Input.Select.Inline
        id={`availability-select-${index}`}
        options={[true, false]}
        value={customerAnalytic?.autocomplete}
        setValue={(value) => {
          if (value === customerAnalytic?.autocomplete) {
            return;
          }
          if (organizationAnalyticPutMutation.isPending) {
            return;
          }

          const data: APIModels.CustomerAnalytic = {
            ...customerAnalytic,
            autocomplete: value,
          };
          organizationAnalyticPutMutation.mutate({
            id: customerAnalytic?.id as number,
            data: data,
          });
        }}
        optionBuilder={(o) => (o ? 'Yes' : 'No')}
        readOnly={
          !canEditAnalytics ||
          organizationAnalyticPutMutation.isPending ||
          customerAnalytic?.archived
        }
      />
    </div>
  );
};
