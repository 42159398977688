export const sortByAttributeName = <T extends Record<string, any>>(
  a: T,
  b: T,
  attributeName: keyof T
) => {
  if (a[attributeName] && b[attributeName]) {
    return String(a[attributeName]).localeCompare(String(b[attributeName]));
  }
  return 0;
};
