import React, { ReactNode } from 'react';
import { theme as styleTheme } from './primary-buttons.styles';

export interface PrimaryButtonLinkProps {
  label?: string;
  icon?: ReactNode;
  theme?: string;
  size?: string;
  className?: string;
  disabled?: boolean;
  href: string;
  target?: string;
}

export function PrimaryButtonLink({
  label,
  className,
  theme,
  size,
  icon,
  disabled,
  target,
  href,
}: PrimaryButtonLinkProps) {
  return (
    <a
      href={href}
      target={target}
      className={`${styleTheme({ theme, size })} ${className}`}
    >
      {icon && <span className="-ml-1 mr-2 h-5 w-5">{icon}</span>}
      <span>{label}</span>
    </a>
  );
}
