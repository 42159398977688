import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  Histogram,
  useGetGeometryHistogramByGeometryCollectionId,
  useGetMorphologyHistogramByCollectionId,
} from '@agerpoint/api';
import { Select } from '@agerpoint/component';
import { LabelStyle, LabelType } from '@agerpoint/types';
import { useGlobalStore, useToasts } from '@agerpoint/utilities';

interface Label {
  collectionId: number;
  labelStyle: LabelStyle;
  graduatedAttributes: string[];
  categorizedAttributes: string[];
  onChange: (labelStyle: LabelStyle) => void;
  layerTypeId: number;
}

export function Label({
  labelStyle,
  graduatedAttributes,
  categorizedAttributes,
  collectionId,
  onChange,
  layerTypeId,
}: Label) {
  const [selectedAttribute, setSelectedAttribute] = useState<string>();

  const myHook =
    layerTypeId === 8
      ? useGetGeometryHistogramByGeometryCollectionId
      : useGetMorphologyHistogramByCollectionId;
  const { data, loading, error } = myHook({
    collectionId: collectionId,
    geometryCollectionId: collectionId,
    attributeName: selectedAttribute || '',
    binCount: 1,
    classificationMethod: 'equalbreak',
    lazy: !graduatedAttributes.some(
      (attribute) => attribute === selectedAttribute
    ),
    resolve: (data) =>
      ({
        min: data.bins[0].rangeMin,
        max: data.bins[data.bins.length - 1].rangeMax,
      } as Histogram),
  });

  const toasts = useToasts();

  useEffect(() => {
    if (error) {
      toasts.add(toasts.prepare.error('Failed to get attribute statistics!'));

      setSelectedAttribute(undefined);
    }
  }, [error]);

  const currentAttribute = useRef(selectedAttribute);
  currentAttribute.current = selectedAttribute;
  useEffect(() => {
    if (data && currentAttribute.current) {
      const { min, max } = data as { min: number; max: number };
      onChange({
        type: LabelType.Attribute,
        attribute: currentAttribute.current,
        min,
        max,
      });
      setSelectedAttribute(undefined);
    }
  }, [data]);

  const availableAttributes = useMemo(
    () => [...categorizedAttributes, ...graduatedAttributes].sort(),
    [categorizedAttributes, graduatedAttributes]
  );

  return (
    <>
      <Select
        type="small"
        onChange={internalOnChange}
        id="labelType"
        name="labelType"
        options={[
          { value: 0, name: 'None' },
          ...availableAttributes.map((attribute, i) => ({
            value: i + 1,
            name: attribute,
          })),
        ]}
        value={
          labelStyle.type === LabelType.None
            ? 0
            : availableAttributes.indexOf(labelStyle.attribute) + 1
        }
      />
      {selectedAttribute && (
        <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-green" />
      )}
    </>
  );

  function internalOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = Number(event.target.value);

    if (value === 0) {
      onChange({ type: LabelType.None });
    } else {
      const selected = availableAttributes[value - 1];
      const isCategorizedSelected = categorizedAttributes.some(
        (attribute) => attribute === selected
      );

      if (selected && isCategorizedSelected) {
        onChange({
          type: LabelType.Attribute,
          attribute: selected,
        });
        setSelectedAttribute(undefined);
      } else {
        // Graduated, fetch histogram before setting style
        setSelectedAttribute(availableAttributes[value - 1]);
      }
    }
  }
}
