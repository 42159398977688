import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminPipelinesQueries } from './admin-pipelines-queries';

export const AdminPipelinesNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  const { permissions } = useGlobalStore();

  const isViteApp = useIsViteApp();

  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );
  usePageTitle(() => 'Platform - Pipelines', []);

  const { pipelinePostMutation } = useAdminPipelinesQueries();

  const formValidation = useFormValidation();
  const [pipeline, setPipeline] = useState<APIModels.Pipeline>({});

  const createPipeline = useCallback(async () => {
    if (pipelinePostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    pipelinePostMutation.mutate({
      data: pipeline,
    });
  }, [pipeline, formValidation, pipelinePostMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Pipelines',
              path: isViteApp
                ? '/app/admin/platform/pipelines'
                : '/admin/pipelines',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="pipelines-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/pipelines' + params);
            } else {
              navigate('/admin/pipelines' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Pipeline</h1>
      </div>

      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="pipeline-name-input"
          label={<Input.Label label="Name" required />}
          value={pipeline.name || ''}
          setValue={(name) => {
            setPipeline({ ...pipeline, name });
          }}
          error={
            <Input.Error error={formValidation.errors['pipeline-name-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
          readOnly={!canManageAnalyticRequests}
        />
        <Input.Text.Single
          id="pipeline-path-input"
          label={<Input.Label label="Path" />}
          value={pipeline.path || ''}
          setValue={(path) => {
            setPipeline({ ...pipeline, path });
          }}
          readOnly={!canManageAnalyticRequests}
        />
        <Input.Text.Single
          id="pipeline-description-input"
          label={<Input.Label label="Description" />}
          value={pipeline.description || ''}
          setValue={(description) => {
            setPipeline({ ...pipeline, description });
          }}
          readOnly={!canManageAnalyticRequests}
        />
        {canManageAnalyticRequests && (
          <div className="w-full flex flex-row justify-end items-center py-4 gap-2">
            <Button.Primary
              id="create-pipeline-button"
              label={'Create'}
              onClick={createPipeline}
              loading={pipelinePostMutation.isPending}
            />
          </div>
        )}
      </div>
    </div>
  );
};
