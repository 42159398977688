/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type {
  CaptureExtractionJob,
  CaptureExtractionJobFilter,
  CaptureExtractionJobVM,
} from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureExtractionJobs = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureExtractionJob[]>(
    { url: `/api/CaptureExtractionJobs`, method: 'GET', signal },
    options
  );
};

export const getGetCaptureExtractionJobsQueryKey = () => {
  return [`/api/CaptureExtractionJobs`] as const;
};

export const getGetCaptureExtractionJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureExtractionJobs>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureExtractionJobs>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureExtractionJobsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureExtractionJobs>>
  > = ({ signal }) => getCaptureExtractionJobs(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureExtractionJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureExtractionJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureExtractionJobs>>
>;
export type GetCaptureExtractionJobsQueryError = ErrorType<void>;

export const useGetCaptureExtractionJobs = <
  TData = Awaited<ReturnType<typeof getCaptureExtractionJobs>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureExtractionJobs>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureExtractionJobsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureExtractionJobsById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureExtractionJob>(
    {
      url: `/api/CaptureExtractionJobs/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureExtractionJobsByIdQueryKey = (id: number) => {
  return [`/api/CaptureExtractionJobs/${id}`] as const;
};

export const getGetCaptureExtractionJobsByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureExtractionJobsById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureExtractionJobsById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureExtractionJobsByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureExtractionJobsById>>
  > = ({ signal }) => getCaptureExtractionJobsById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureExtractionJobsById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureExtractionJobsByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureExtractionJobsById>>
>;
export type GetCaptureExtractionJobsByIdQueryError = ErrorType<void>;

export const useGetCaptureExtractionJobsById = <
  TData = Awaited<ReturnType<typeof getCaptureExtractionJobsById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureExtractionJobsById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureExtractionJobsByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureExtractionJobById = (
  id: number,
  captureExtractionJob: BodyType<CaptureExtractionJob>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureExtractionJob>(
    {
      url: `/api/CaptureExtractionJobs/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureExtractionJob,
    },
    options
  );
};

export const getPutCaptureExtractionJobByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureExtractionJobById>>,
    TError,
    { id: number; data: BodyType<CaptureExtractionJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureExtractionJobById>>,
  TError,
  { id: number; data: BodyType<CaptureExtractionJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureExtractionJobById>>,
    { id: number; data: BodyType<CaptureExtractionJob> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureExtractionJobById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureExtractionJobByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureExtractionJobById>>
>;
export type PutCaptureExtractionJobByIdMutationBody =
  BodyType<CaptureExtractionJob>;
export type PutCaptureExtractionJobByIdMutationError = ErrorType<void>;

export const usePutCaptureExtractionJobById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureExtractionJobById>>,
    TError,
    { id: number; data: BodyType<CaptureExtractionJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureExtractionJobById>>,
  TError,
  { id: number; data: BodyType<CaptureExtractionJob> },
  TContext
> => {
  const mutationOptions =
    getPutCaptureExtractionJobByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPagedCaptureExtractionJobs = (
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureExtractionJob[]>(
    {
      url: `/api/CaptureExtractionJobs/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedCaptureExtractionJobsQueryKey = (
  skip: number,
  take: number
) => {
  return [`/api/CaptureExtractionJobs/skip/${skip}/take/${take}`] as const;
};

export const getGetPagedCaptureExtractionJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedCaptureExtractionJobs>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptureExtractionJobs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedCaptureExtractionJobsQueryKey(skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedCaptureExtractionJobs>>
  > = ({ signal }) =>
    getPagedCaptureExtractionJobs(skip, take, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedCaptureExtractionJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedCaptureExtractionJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedCaptureExtractionJobs>>
>;
export type GetPagedCaptureExtractionJobsQueryError = ErrorType<void>;

export const useGetPagedCaptureExtractionJobs = <
  TData = Awaited<ReturnType<typeof getPagedCaptureExtractionJobs>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptureExtractionJobs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedCaptureExtractionJobsQueryOptions(
    skip,
    take,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureExtractionJobsByCaptureId = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureExtractionJob[]>(
    {
      url: `/api/CaptureExtractionJobs/Capture/${encodeURIComponent(
        String(captureId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureExtractionJobsByCaptureIdQueryKey = (
  captureId: number
) => {
  return [`/api/CaptureExtractionJobs/Capture/${captureId}`] as const;
};

export const getGetCaptureExtractionJobsByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureExtractionJobsByCaptureIdQueryKey(captureId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureId>>
  > = ({ signal }) =>
    getCaptureExtractionJobsByCaptureId(captureId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureExtractionJobsByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureId>>
>;
export type GetCaptureExtractionJobsByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureExtractionJobsByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureExtractionJobsByCaptureIdQueryOptions(
    captureId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureExtractionJobsByCaptureJobId = (
  captureJobId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureExtractionJob[]>(
    {
      url: `/api/CaptureExtractionJobs/CaptureJob/${encodeURIComponent(
        String(captureJobId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureExtractionJobsByCaptureJobIdQueryKey = (
  captureJobId: number
) => {
  return [`/api/CaptureExtractionJobs/CaptureJob/${captureJobId}`] as const;
};

export const getGetCaptureExtractionJobsByCaptureJobIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureJobId>>,
  TError = ErrorType<void>
>(
  captureJobId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureExtractionJobsByCaptureJobIdQueryKey(captureJobId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureJobId>>
  > = ({ signal }) =>
    getCaptureExtractionJobsByCaptureJobId(
      captureJobId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!captureJobId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureJobId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureExtractionJobsByCaptureJobIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureJobId>>
>;
export type GetCaptureExtractionJobsByCaptureJobIdQueryError = ErrorType<void>;

export const useGetCaptureExtractionJobsByCaptureJobId = <
  TData = Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureJobId>>,
  TError = ErrorType<void>
>(
  captureJobId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureExtractionJobsByCaptureJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureExtractionJobsByCaptureJobIdQueryOptions(
    captureJobId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCapturesWithExtractionJobs = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureExtractionJobVM[]>(
    { url: `/api/CaptureExtractionJobs/Capture`, method: 'GET', signal },
    options
  );
};

export const getGetCapturesWithExtractionJobsQueryKey = () => {
  return [`/api/CaptureExtractionJobs/Capture`] as const;
};

export const getGetCapturesWithExtractionJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCapturesWithExtractionJobs>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCapturesWithExtractionJobs>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCapturesWithExtractionJobsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCapturesWithExtractionJobs>>
  > = ({ signal }) => getCapturesWithExtractionJobs(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCapturesWithExtractionJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCapturesWithExtractionJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCapturesWithExtractionJobs>>
>;
export type GetCapturesWithExtractionJobsQueryError = ErrorType<void>;

export const useGetCapturesWithExtractionJobs = <
  TData = Awaited<ReturnType<typeof getCapturesWithExtractionJobs>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCapturesWithExtractionJobs>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCapturesWithExtractionJobsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPagedCapturesWithExtractionJobs = (
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureExtractionJobVM[]>(
    {
      url: `/api/CaptureExtractionJobs/Capture/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedCapturesWithExtractionJobsQueryKey = (
  skip: number,
  take: number
) => {
  return [
    `/api/CaptureExtractionJobs/Capture/skip/${skip}/take/${take}`,
  ] as const;
};

export const getGetPagedCapturesWithExtractionJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedCapturesWithExtractionJobs>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCapturesWithExtractionJobs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedCapturesWithExtractionJobsQueryKey(skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedCapturesWithExtractionJobs>>
  > = ({ signal }) =>
    getPagedCapturesWithExtractionJobs(skip, take, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedCapturesWithExtractionJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedCapturesWithExtractionJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedCapturesWithExtractionJobs>>
>;
export type GetPagedCapturesWithExtractionJobsQueryError = ErrorType<void>;

export const useGetPagedCapturesWithExtractionJobs = <
  TData = Awaited<ReturnType<typeof getPagedCapturesWithExtractionJobs>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCapturesWithExtractionJobs>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedCapturesWithExtractionJobsQueryOptions(
    skip,
    take,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredPagedCapturesWithExtractionJobs = (
  skip: number,
  take: number,
  captureExtractionJobFilter: BodyType<CaptureExtractionJobFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureExtractionJobVM[]>(
    {
      url: `/api/CaptureExtractionJobs/Capture/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureExtractionJobFilter,
    },
    options
  );
};

export const getGetFilteredPagedCapturesWithExtractionJobsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedCapturesWithExtractionJobs>>,
    TError,
    { skip: number; take: number; data: BodyType<CaptureExtractionJobFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPagedCapturesWithExtractionJobs>>,
  TError,
  { skip: number; take: number; data: BodyType<CaptureExtractionJobFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPagedCapturesWithExtractionJobs>>,
    { skip: number; take: number; data: BodyType<CaptureExtractionJobFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPagedCapturesWithExtractionJobs(
      skip,
      take,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPagedCapturesWithExtractionJobsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof getFilteredPagedCapturesWithExtractionJobs>>
  >;
export type GetFilteredPagedCapturesWithExtractionJobsMutationBody =
  BodyType<CaptureExtractionJobFilter>;
export type GetFilteredPagedCapturesWithExtractionJobsMutationError =
  ErrorType<void>;

export const useGetFilteredPagedCapturesWithExtractionJobs = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedCapturesWithExtractionJobs>>,
    TError,
    { skip: number; take: number; data: BodyType<CaptureExtractionJobFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPagedCapturesWithExtractionJobs>>,
  TError,
  { skip: number; take: number; data: BodyType<CaptureExtractionJobFilter> },
  TContext
> => {
  const mutationOptions =
    getGetFilteredPagedCapturesWithExtractionJobsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const submitExtractionJobToPipeline = (
  captureUuid: string,
  lowresUuid: string,
  highresUuid: string,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<boolean>(
    {
      url: `/api/CaptureExtractionJobs/Capture/${encodeURIComponent(
        String(captureUuid)
      )}/lowres/${encodeURIComponent(
        String(lowresUuid)
      )}/highres/${encodeURIComponent(String(highresUuid))}`,
      method: 'POST',
    },
    options
  );
};

export const getSubmitExtractionJobToPipelineMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitExtractionJobToPipeline>>,
    TError,
    { captureUuid: string; lowresUuid: string; highresUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitExtractionJobToPipeline>>,
  TError,
  { captureUuid: string; lowresUuid: string; highresUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitExtractionJobToPipeline>>,
    { captureUuid: string; lowresUuid: string; highresUuid: string }
  > = (props) => {
    const { captureUuid, lowresUuid, highresUuid } = props ?? {};

    return submitExtractionJobToPipeline(
      captureUuid,
      lowresUuid,
      highresUuid,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitExtractionJobToPipelineMutationResult = NonNullable<
  Awaited<ReturnType<typeof submitExtractionJobToPipeline>>
>;

export type SubmitExtractionJobToPipelineMutationError = ErrorType<void>;

export const useSubmitExtractionJobToPipeline = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitExtractionJobToPipeline>>,
    TError,
    { captureUuid: string; lowresUuid: string; highresUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof submitExtractionJobToPipeline>>,
  TError,
  { captureUuid: string; lowresUuid: string; highresUuid: string },
  TContext
> => {
  const mutationOptions =
    getSubmitExtractionJobToPipelineMutationOptions(options);

  return useMutation(mutationOptions);
};
export const submitExtractionJobToPipelineByCaptureId = (
  captureId: number,
  lowresUuid: string,
  highresUuid: string,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<boolean>(
    {
      url: `/api/CaptureExtractionJobs/CaptureId/${encodeURIComponent(
        String(captureId)
      )}/lowres/${encodeURIComponent(
        String(lowresUuid)
      )}/highres/${encodeURIComponent(String(highresUuid))}`,
      method: 'POST',
    },
    options
  );
};

export const getSubmitExtractionJobToPipelineByCaptureIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitExtractionJobToPipelineByCaptureId>>,
    TError,
    { captureId: number; lowresUuid: string; highresUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitExtractionJobToPipelineByCaptureId>>,
  TError,
  { captureId: number; lowresUuid: string; highresUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitExtractionJobToPipelineByCaptureId>>,
    { captureId: number; lowresUuid: string; highresUuid: string }
  > = (props) => {
    const { captureId, lowresUuid, highresUuid } = props ?? {};

    return submitExtractionJobToPipelineByCaptureId(
      captureId,
      lowresUuid,
      highresUuid,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitExtractionJobToPipelineByCaptureIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof submitExtractionJobToPipelineByCaptureId>>
  >;

export type SubmitExtractionJobToPipelineByCaptureIdMutationError =
  ErrorType<void>;

export const useSubmitExtractionJobToPipelineByCaptureId = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitExtractionJobToPipelineByCaptureId>>,
    TError,
    { captureId: number; lowresUuid: string; highresUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof submitExtractionJobToPipelineByCaptureId>>,
  TError,
  { captureId: number; lowresUuid: string; highresUuid: string },
  TContext
> => {
  const mutationOptions =
    getSubmitExtractionJobToPipelineByCaptureIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getReportImageById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<string>(
    {
      url: `/api/CaptureExtractionJobs/${encodeURIComponent(
        String(id)
      )}/report/image`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetReportImageByIdQueryKey = (id: number) => {
  return [`/api/CaptureExtractionJobs/${id}/report/image`] as const;
};

export const getGetReportImageByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportImageById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReportImageById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportImageByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReportImageById>>
  > = ({ signal }) => getReportImageById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportImageById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportImageByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReportImageById>>
>;
export type GetReportImageByIdQueryError = ErrorType<void>;

export const useGetReportImageById = <
  TData = Awaited<ReturnType<typeof getReportImageById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReportImageById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportImageByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateCaptureExtractionJobStatusByJobUuid = (
  jobUuid: string,
  captureExtractionJob: BodyType<CaptureExtractionJob>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureExtractionJob>(
    {
      url: `/api/CaptureExtractionJobs/Uuid/${encodeURIComponent(
        String(jobUuid)
      )}/Status`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureExtractionJob,
    },
    options
  );
};

export const getUpdateCaptureExtractionJobStatusByJobUuidMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCaptureExtractionJobStatusByJobUuid>>,
    TError,
    { jobUuid: string; data: BodyType<CaptureExtractionJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCaptureExtractionJobStatusByJobUuid>>,
  TError,
  { jobUuid: string; data: BodyType<CaptureExtractionJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCaptureExtractionJobStatusByJobUuid>>,
    { jobUuid: string; data: BodyType<CaptureExtractionJob> }
  > = (props) => {
    const { jobUuid, data } = props ?? {};

    return updateCaptureExtractionJobStatusByJobUuid(
      jobUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCaptureExtractionJobStatusByJobUuidMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof updateCaptureExtractionJobStatusByJobUuid>>
  >;
export type UpdateCaptureExtractionJobStatusByJobUuidMutationBody =
  BodyType<CaptureExtractionJob>;
export type UpdateCaptureExtractionJobStatusByJobUuidMutationError =
  ErrorType<void>;

export const useUpdateCaptureExtractionJobStatusByJobUuid = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCaptureExtractionJobStatusByJobUuid>>,
    TError,
    { jobUuid: string; data: BodyType<CaptureExtractionJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCaptureExtractionJobStatusByJobUuid>>,
  TError,
  { jobUuid: string; data: BodyType<CaptureExtractionJob> },
  TContext
> => {
  const mutationOptions =
    getUpdateCaptureExtractionJobStatusByJobUuidMutationOptions(options);

  return useMutation(mutationOptions);
};
export const reSubmitExtractionJobToPipeline = (
  captureExtractionJobId: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureExtractionJob>(
    {
      url: `/api/CaptureExtractionJobs/${encodeURIComponent(
        String(captureExtractionJobId)
      )}/Resubmit`,
      method: 'POST',
    },
    options
  );
};

export const getReSubmitExtractionJobToPipelineMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reSubmitExtractionJobToPipeline>>,
    TError,
    { captureExtractionJobId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reSubmitExtractionJobToPipeline>>,
  TError,
  { captureExtractionJobId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reSubmitExtractionJobToPipeline>>,
    { captureExtractionJobId: number }
  > = (props) => {
    const { captureExtractionJobId } = props ?? {};

    return reSubmitExtractionJobToPipeline(
      captureExtractionJobId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ReSubmitExtractionJobToPipelineMutationResult = NonNullable<
  Awaited<ReturnType<typeof reSubmitExtractionJobToPipeline>>
>;

export type ReSubmitExtractionJobToPipelineMutationError = ErrorType<void>;

export const useReSubmitExtractionJobToPipeline = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reSubmitExtractionJobToPipeline>>,
    TError,
    { captureExtractionJobId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof reSubmitExtractionJobToPipeline>>,
  TError,
  { captureExtractionJobId: number },
  TContext
> => {
  const mutationOptions =
    getReSubmitExtractionJobToPipelineMutationOptions(options);

  return useMutation(mutationOptions);
};
export const submitNewExtractionJobToPipeline = (
  captureExtractionJob: BodyType<CaptureExtractionJob>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<boolean>(
    {
      url: `/api/CaptureExtractionJobs/Submit`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureExtractionJob,
    },
    options
  );
};

export const getSubmitNewExtractionJobToPipelineMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitNewExtractionJobToPipeline>>,
    TError,
    { data: BodyType<CaptureExtractionJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitNewExtractionJobToPipeline>>,
  TError,
  { data: BodyType<CaptureExtractionJob> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitNewExtractionJobToPipeline>>,
    { data: BodyType<CaptureExtractionJob> }
  > = (props) => {
    const { data } = props ?? {};

    return submitNewExtractionJobToPipeline(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitNewExtractionJobToPipelineMutationResult = NonNullable<
  Awaited<ReturnType<typeof submitNewExtractionJobToPipeline>>
>;
export type SubmitNewExtractionJobToPipelineMutationBody =
  BodyType<CaptureExtractionJob>;
export type SubmitNewExtractionJobToPipelineMutationError = ErrorType<void>;

export const useSubmitNewExtractionJobToPipeline = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitNewExtractionJobToPipeline>>,
    TError,
    { data: BodyType<CaptureExtractionJob> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof submitNewExtractionJobToPipeline>>,
  TError,
  { data: BodyType<CaptureExtractionJob> },
  TContext
> => {
  const mutationOptions =
    getSubmitNewExtractionJobToPipelineMutationOptions(options);

  return useMutation(mutationOptions);
};
