export const formatDateTimeRegular = (value: string | null | undefined) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  // Return date in format eg.: Mar 5 2023 12:00 PM

  const time = date.toLocaleTimeString('en-US', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });

  return `${date.toLocaleString('en-US', {
    month: 'short',
  })} ${date.getDate()} ${date.getFullYear()} ${time}`;
};

export const formatDateTimeShort = (value: string | null | undefined) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  // Return date in format eg.: 3/5/23 12:00 PM
  const time = date.toLocaleTimeString('en-US', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });

  return `${date.getMonth() + 1}/${date.getDate()}/${date
    .getFullYear()
    .toString()
    .slice(-2)} ${time}`;
};

export const formatDateTimeLong = (value: string | null | undefined) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  // Return date in format eg.: March 5, 2023 12:00 PM
  const time = date.toLocaleTimeString('en-US', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });

  return `${date.toLocaleString('en-US', {
    month: 'long',
  })} ${date.getDate()}, ${date.getFullYear()} ${time}`;
};
