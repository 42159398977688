import { CourierProvider } from '@trycourier/react-provider';
import { useMemo } from 'react';

import { LdFlags } from '@agerpoint/types';
import { environment, useAgerStore } from '@agerpoint/utilities';

interface ICourierSetup {
  children: React.ReactNode;
}

export const CourierSetup = ({ children }: ICourierSetup) => {
  const {
    user: { userInfo },
    launchDarkly: { hasFeatureFlag },
  } = useAgerStore();

  const hasCourierEnabled = useMemo(
    () => hasFeatureFlag(LdFlags.EnableCourierMessaging),
    [hasFeatureFlag]
  );

  if (!hasCourierEnabled) {
    return children;
  }

  return (
    <CourierProvider
      userId={userInfo?.id ?? undefined}
      clientKey={environment.courier_client_id}
    >
      {children}
    </CourierProvider>
  );
};
