import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';

import { InputIdContext } from '../input-id-context';
import { InputStyle } from '../input-style';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface InputFileSingleProps {
  /**
   * The unique identifier for the input element.
   */
  id: string;

  /**
   * The error message to display.
   */
  error?: React.ReactNode;

  /**
   * The label to display.
   */
  label?: React.ReactNode;

  /**
   * The placeholder text for the input element.
   */
  placeholder?: string;

  /**
   * The current value of the input element.
   */
  value: File | undefined;

  /**
   * A callback function to update the value of the input element.
   */
  setValue: (value: File | undefined) => void;

  /**
   * A reference to the input element.
   */
  inputRef?: React.RefObject<HTMLInputElement>;

  /**
   * The style configuration for the input element.
   */
  style?: InputStyle;

  /**
   * The file extensions to accept.
   */
  acceptExtensions?: string[];

  /**
   * The validation configuration for the input element.
   */
  validation?: InputValidation<File | undefined>;
}

const InputFileSingle = ({
  id,
  label,
  error,
  placeholder,
  value,
  setValue,
  inputRef: inputRefProp,
  style,
  acceptExtensions,
  validation,
}: InputFileSingleProps) => {
  const [fileName, setFileName] = useState<string>('');

  const [inputHasFocus, setInputHasFocus] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFileName(value?.name ?? '');
    if (inputRefProp || inputRef) {
      const inputElement = inputRefProp?.current ?? inputRef.current;
      if (inputElement && value) {
        inputElement.value = '';
      }
    }
  }, [value]);

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full">
        {label ?? null}
        <input
          ref={inputRefProp ?? inputRef}
          type="file"
          accept={acceptExtensions
            ?.map((a) => (a.startsWith('.') ? a : `.${a}`))
            .join(', ')}
          className="hidden"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setValue(file);
            }
          }}
        />
        <div
          className={`flex flex-row w-full shadow-sm border-gray-500 rounded border ${
            inputHasFocus ? 'ring-1 ring-green border-green' : ''
          }`}
        >
          <input
            type="text"
            id={id}
            data-test-id={id}
            className={`text-sm w-full placeholder-gray border-0 focus:ring-0 focus:border-0 focus:outline-none rounded text-gray-900`}
            placeholder={placeholder}
            style={{
              fontFamily: 'nunito, "Font Awesome 6 Pro"',
            }}
            readOnly
            onClick={() => {
              (inputRefProp ?? inputRef)?.current?.click();
            }}
            onBlur={() => {
              setInputHasFocus(false);
            }}
            onFocus={() => {
              setInputHasFocus(true);
            }}
            value={fileName}
            onChange={(e) => {
              let v = e.target.value;

              v = v.trimStart();
              setFileName(v);
            }}
          />
          {value && (
            <div
              className={`pr-2 flex items-center bg-white rounded-r text-gray-900 hover:text-gray-500 cursor-pointer`}
              onClick={() => {
                setValue(undefined);
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          )}
        </div>
        {error ?? null}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputFileSingle };
