import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { JobTypeCategory, LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
} from '@agerpoint/utilities';

import { PageLoadingState } from '../../../subcomponents/page-states';
import { useOpsAnalyticRequestsQueries } from './analytic-requests-queries';

export const AnalyticRequestsDetailsAdHocJob = () => {
  const {
    jobCategory,
    entityId: entityIdParam,
    analyticRequestId,
  } = useParams();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { permissions } = useGlobalStore();

  const entityId = useMemo(
    () => Number(entityIdParam ?? undefined),
    [entityIdParam]
  );
  const navigate = useNavigate();

  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );

  const hasAHJPermission = useMemo(
    () => hasPermission(LdFlags.CaptureAnalyticAHJSubmit, permissions),
    [permissions]
  );

  useEffect(() => {
    if (!canManageAnalyticRequests || !hasAHJPermission) {
      if (isViteApp) {
        navigate(
          `/app/admin/operations/analytic-requests/${analyticRequestId}/details`,
          {
            replace: true,
          }
        );
      } else {
        navigate(`/ops/analytics/${analyticRequestId}/details`, {
          replace: true,
        });
      }
    }
  }, [canManageAnalyticRequests, hasAHJPermission]);

  useEffect(() => {
    if (!jobCategory || !entityIdParam) {
      if (isViteApp) {
        navigate(
          `/app/admin/operations/analytic-requests/${analyticRequestId}/details`,
          {
            replace: true,
          }
        );
      } else {
        navigate(`/ops/analytics/${analyticRequestId}/details`, {
          replace: true,
        });
      }
      return;
    }

    const excludeCategories = [JobTypeCategory.OperationJob];
    if (
      !Object.values(JobTypeCategory)
        .filter((t) => !excludeCategories.includes(t))
        .map((t) => t.toLowerCase())
        .includes(jobCategory.toLowerCase())
    ) {
      if (isViteApp) {
        navigate(
          `/app/admin/operations/analytic-requests/${analyticRequestId}/details`,
          {
            replace: true,
          }
        );
      } else {
        navigate(`/ops/analytics/${analyticRequestId}/details`, {
          replace: true,
        });
      }
    }

    setCategory(
      Object.values(JobTypeCategory).find(
        (t) => t.toLowerCase() === jobCategory.toLowerCase()
      )
    );
  }, [jobCategory, entityIdParam]);

  const [category, setCategory] = useState<JobTypeCategory>();
  const [apfParameters, setApfParameters] = useState<string>();
  const [modelParameters, setModelParameters] = useState<string>();
  const [selectedMlModel, setSelectedMlModel] = useState<APIClient.MlModel>();
  const [selectedMosaicEngine, setSelectedMosaicEngine] =
    useState<APIClient.MosaicEngine>();
  const [selectedPipeline, setSelectedPipeline] =
    useState<APIClient.Pipeline>();
  const [jobName, setJobName] = useState<string>();
  const [selectedCaptureJob, setSelectedCaptureJob] =
    useState<APIClient.CaptureJob>();
  const [pipelineCaptureId, setPipelineCaptureId] = useState<number>();

  const {
    extractionJobByIdQuery,
    captureJobByIdQuery,
    pipelineJobByIdQuery,
    analyticRequestQuery,
    mlModelsQuery,
    mosaicEnginesQuery,
    pipelinesQuery,
    adHocJobPostMutation,
    captureJobsByCaptureIdQuery,
  } = useOpsAnalyticRequestsQueries({
    extractionJobId:
      category === JobTypeCategory.CaptureExtractionJob ? entityId : undefined,
    captureJobId:
      category === JobTypeCategory.CaptureJob ? entityId : undefined,
    pipelineJobId:
      category === JobTypeCategory.PipelineJob ? entityId : undefined,
    captureId: pipelineCaptureId,
  });

  const loading = useMemo(() => {
    if (category === JobTypeCategory.CaptureExtractionJob) {
      return extractionJobByIdQuery.isLoading;
    }

    if (category === JobTypeCategory.CaptureJob) {
      return captureJobByIdQuery.isLoading;
    }

    if (category === JobTypeCategory.PipelineJob) {
      return pipelineJobByIdQuery.isLoading;
    }

    return true;
  }, [
    category,
    extractionJobByIdQuery.isLoading,
    captureJobByIdQuery.isLoading,
    pipelineJobByIdQuery.isLoading,
  ]);

  useEffect(() => {
    if (
      category === JobTypeCategory.CaptureExtractionJob &&
      captureJobsByCaptureIdQuery.data
    ) {
      setSelectedCaptureJob(
        captureJobsByCaptureIdQuery.data.find(
          (job) => job.id === extractionJobByIdQuery.data?.captureJobId
        )
      );
    }
  }, [captureJobsByCaptureIdQuery.data, category, extractionJobByIdQuery.data]);

  useEffect(() => {
    // if the analytic reqeust has captureIds then we need to set the captureId
    // for the pipeline job
    if (analyticRequestQuery.data?.captureIds?.length) {
      setPipelineCaptureId(analyticRequestQuery.data.captureIds[0]);
    } else {
      setPipelineCaptureId(undefined);
    }
  }, [analyticRequestQuery.data, category]);

  useEffect(() => {
    if (
      category !== JobTypeCategory.CaptureExtractionJob ||
      !extractionJobByIdQuery.data
    ) {
      setPipelineCaptureId(undefined);
      return;
    }

    setApfParameters(extractionJobByIdQuery.data.parametersJson ?? undefined);
    setJobName(extractionJobByIdQuery.data.name ?? undefined);
  }, [extractionJobByIdQuery.data]);

  useEffect(() => {
    if (category !== JobTypeCategory.CaptureJob || !captureJobByIdQuery.data) {
      return;
    }

    setSelectedMlModel(
      mlModelsQuery.data?.find(
        (model) => model.name === captureJobByIdQuery.data?.mlModel
      )
    );

    setSelectedMosaicEngine(
      mosaicEnginesQuery.data?.find(
        (engine) => engine.name === captureJobByIdQuery.data?.mosaicEngine
      )
    );

    setModelParameters(captureJobByIdQuery.data.parametersJson ?? undefined);
    setJobName(captureJobByIdQuery.data.name ?? undefined);
  }, [captureJobByIdQuery.data, mlModelsQuery.data, mosaicEnginesQuery.data]);

  useEffect(() => {
    if (
      category !== JobTypeCategory.PipelineJob ||
      !pipelineJobByIdQuery.data
    ) {
      return;
    }

    setSelectedPipeline(
      pipelinesQuery.data?.find(
        (pipeline) => pipeline.id === pipelineJobByIdQuery.data?.pipelineId
      )
    );

    setSelectedMlModel(
      mlModelsQuery.data?.find(
        (model) => model.id === pipelineJobByIdQuery.data?.mlModelId
      )
    );

    setSelectedMosaicEngine(
      mosaicEnginesQuery.data?.find(
        (engine) => engine.id === pipelineJobByIdQuery.data?.mosaicEngineId
      )
    );

    setJobName(pipelineJobByIdQuery.data.name ?? undefined);
  }, [
    pipelineJobByIdQuery.data,
    mlModelsQuery.data,
    mosaicEnginesQuery.data,
    pipelinesQuery.data,
  ]);

  const extJobFormValidation = useFormValidation();
  const captureJobFormValidation = useFormValidation();
  const pipelineJobFormValidation = useFormValidation();

  const mosaicEngineOptions: APIClient.MosaicEngine[] = useMemo(() => {
    if (mosaicEnginesQuery.data === undefined) {
      return [];
    }

    if (category !== JobTypeCategory.PipelineJob) {
      return mosaicEnginesQuery.data;
    }

    return mosaicEnginesQuery.data.filter(
      (engine) =>
        engine.pipelineId === selectedPipeline?.id ||
        engine.pipelineId === null ||
        engine.pipelineId === undefined
    );
  }, [mosaicEnginesQuery.data, category, selectedPipeline]);

  const mlModelsOptions: APIClient.MlModel[] = useMemo(() => {
    if (mlModelsQuery.data === undefined) {
      return [];
    }

    if (category !== JobTypeCategory.PipelineJob) {
      return mlModelsQuery.data;
    }

    return mlModelsQuery.data.filter(
      (model) =>
        model.pipelineId === selectedPipeline?.id ||
        model.pipelineId === null ||
        model.pipelineId === undefined
    );
  }, [mlModelsQuery.data, category, selectedPipeline]);

  const submitAdHocJob = useCallback(async () => {
    if (adHocJobPostMutation.isPending) {
      return;
    }

    if (category === JobTypeCategory.CaptureExtractionJob) {
      if (await extJobFormValidation.hasErrors()) {
        return;
      }

      const extractionJob = cloneDeep(extractionJobByIdQuery.data);

      if (!extractionJob) {
        return;
      }

      extractionJob.parametersJson = apfParameters;
      extractionJob.name = jobName;
      extractionJob.captureJobId = selectedCaptureJob?.id;

      delete extractionJob.id;
      delete extractionJob.captureExtractionJobStatus;

      adHocJobPostMutation.mutate({
        category: JobTypeCategory.CaptureExtractionJob,
        extractionJob: extractionJob,
      });
      return;
    }

    if (category === JobTypeCategory.CaptureJob) {
      if (await captureJobFormValidation.hasErrors()) {
        return;
      }

      const captureJob = cloneDeep(captureJobByIdQuery.data);

      if (!captureJob) {
        return;
      }

      captureJob.parametersJson = modelParameters;
      captureJob.name = jobName;
      captureJob.mlModelId = selectedMlModel?.id;
      captureJob.mlModel = selectedMlModel?.name;
      captureJob.mosaicEngineId = selectedMosaicEngine?.id;
      captureJob.mosaicEngine = selectedMosaicEngine?.name;

      delete captureJob.id;

      adHocJobPostMutation.mutate({
        category: JobTypeCategory.CaptureJob,
        captureJob: captureJob,
      });
    }

    if (category === JobTypeCategory.PipelineJob) {
      if (await pipelineJobFormValidation.hasErrors()) {
        return;
      }

      const pipelineJob = cloneDeep(pipelineJobByIdQuery.data);

      if (!pipelineJob) {
        return;
      }

      pipelineJob.name = jobName;
      pipelineJob.mlModelId = selectedMlModel?.id;
      pipelineJob.mosaicEngineId = selectedMosaicEngine?.id;
      pipelineJob.pipelineId = selectedPipeline?.id;
      pipelineJob.data = modelParameters;

      delete pipelineJob.id;
      delete pipelineJob.mlModel;
      delete pipelineJob.mosaicEngine;
      delete pipelineJob.pipeline;

      adHocJobPostMutation.mutate({
        category: JobTypeCategory.PipelineJob,
        pipelineJob: pipelineJob,
      });
    }
  }, [
    category,
    apfParameters,
    modelParameters,
    selectedMlModel,
    selectedMosaicEngine,
    selectedPipeline,
    jobName,
    extJobFormValidation,
    captureJobFormValidation,
    pipelineJobFormValidation,
    adHocJobPostMutation,
    extractionJobByIdQuery.data,
    captureJobByIdQuery.data,
    pipelineJobByIdQuery.data,
  ]);

  if (!category) {
    return null;
  }

  if (loading) {
    return <PageLoadingState />;
  }

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Operations',
              path: isViteApp ? '/app/admin/operations' : '/ops',
            },
            {
              label: 'Analytic Requests',
              path: isViteApp
                ? '/app/admin/operations/analytic-requests'
                : '/ops/analytics',
              params,
            },
            {
              label:
                analyticRequestQuery.data?.customerAnalytic?.analytic
                  ?.analyticName ?? '...',
              path: isViteApp
                ? `/app/admin/operations/analytic-requests/${analyticRequestQuery.data?.id}/details`
                : `/ops/analytics/${analyticRequestQuery.data?.id}/details`,
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="submit-ad-hoc-job-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate(
                `/app/admin/operations/analytic-requests/${analyticRequestQuery.data?.id}/details` +
                  params
              );
            } else {
              navigate(
                `/ops/analytics/${analyticRequestQuery.data?.id}/details` +
                  params
              );
            }
          }}
        />
        <h1 className="text-3xl font-bold">
          Submit Ad-hoc {category.replace(/([A-Z])/g, ' $1').trim()}
        </h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        {category === JobTypeCategory.CaptureExtractionJob && (
          <>
            <Input.Text.Single
              id="ext-job-name"
              label={<Input.Label label="Job Name" required />}
              value={jobName ?? ''}
              setValue={setJobName}
              error={
                <Input.Error
                  error={extJobFormValidation.errors['ext-job-name']}
                />
              }
              validation={{
                validationState: extJobFormValidation,
                validators: [Input.validators.required('Job Name')],
              }}
            />
            <Input.Select.Single
              id="ext-job-capture-job"
              label={<Input.Label label="Capture Job" required />}
              title={'Capture Job'}
              value={selectedCaptureJob}
              setValue={setSelectedCaptureJob}
              options={captureJobsByCaptureIdQuery.data ?? []}
              optionBuilder={(captureJob) =>
                `${captureJob?.name} - ${captureJob?.id}` ?? 'Unknown'
              }
              loading={captureJobsByCaptureIdQuery.isLoading}
              error={
                <Input.Error
                  error={extJobFormValidation.errors['ext-job-capture-job']}
                />
              }
              validation={{
                validationState: extJobFormValidation,
                validators: [Input.validators.required('Capture Job')],
              }}
            />
            <Input.Text.Area
              id="ext-job-apf-parameters"
              label={<Input.Label label="APF Parameters" />}
              value={apfParameters ?? ''}
              setValue={setApfParameters}
              error={
                <Input.Error
                  error={extJobFormValidation.errors['ext-job-apf-parameters']}
                />
              }
              rows={5}
              validation={{
                validationState: extJobFormValidation,
                validators: [Input.validators.validJson],
              }}
            />
          </>
        )}
        {category === JobTypeCategory.CaptureJob && (
          <>
            <Input.Text.Single
              id="capture-job-name"
              label={<Input.Label label="Job Name" required />}
              value={jobName ?? ''}
              setValue={setJobName}
              error={
                <Input.Error
                  error={captureJobFormValidation.errors['capture-job-name']}
                />
              }
              validation={{
                validationState: captureJobFormValidation,
                validators: [Input.validators.required('Job Name')],
              }}
            />
            <Input.Select.Single
              id="capture-job-mosaic-engine"
              label={<Input.Label label="Mosaic Engine" required />}
              value={selectedMosaicEngine}
              title="Mosaic Engine"
              setValue={setSelectedMosaicEngine}
              options={mosaicEngineOptions}
              optionBuilder={(mosaicEngine) =>
                mosaicEngine?.displayName ?? mosaicEngine?.name ?? 'Unknown'
              }
              loading={
                mosaicEnginesQuery.isLoading || captureJobByIdQuery.isLoading
              }
              validation={{
                validationState: captureJobFormValidation,
                validators: [Input.validators.required('Mosaic Engine')],
              }}
              error={
                <Input.Error
                  error={
                    captureJobFormValidation.errors['capture-job-mosaic-engine']
                  }
                />
              }
            />
            <Input.Select.Single
              id="capture-job-ml-model"
              label={<Input.Label label="ML Model" />}
              value={selectedMlModel}
              title="ML Model"
              setValue={setSelectedMlModel}
              options={mlModelsOptions}
              optionBuilder={(mlModel) =>
                `${mlModel?.displayName ?? mlModel?.name ?? 'Unknown'} ${
                  mlModel.version ? `(v${mlModel.version})` : ''
                }`.trim()
              }
              loading={mlModelsQuery.isLoading || captureJobByIdQuery.isLoading}
            />

            <Input.Text.Area
              id="capture-job-model-parameters"
              label={<Input.Label label="3D Model Parameters" />}
              value={modelParameters ?? ''}
              setValue={setModelParameters ?? ''}
              error={
                <Input.Error
                  error={
                    captureJobFormValidation.errors[
                      'capture-job-model-parameters'
                    ]
                  }
                />
              }
              rows={5}
              validation={{
                validationState: captureJobFormValidation,
                validators: [Input.validators.validJson],
              }}
            />
          </>
        )}
        {category === JobTypeCategory.PipelineJob && (
          <>
            <Input.Text.Single
              id="pipeline-job-name"
              label={<Input.Label label="Job Name" required />}
              value={jobName ?? ''}
              setValue={setJobName}
              error={
                <Input.Error
                  error={pipelineJobFormValidation.errors['pipeline-job-name']}
                />
              }
              validation={{
                validationState: pipelineJobFormValidation,
                validators: [Input.validators.required('Job Name')],
              }}
            />
            <Input.Select.Single
              id="pipeline-job-pipeline"
              label={<Input.Label label="Pipeline" required />}
              value={selectedPipeline}
              title="Pipeline"
              setValue={setSelectedPipeline}
              options={pipelinesQuery.data ?? []}
              optionBuilder={(pipeline) => pipeline?.name ?? 'Unknown'}
              loading={pipelinesQuery.isLoading}
              error={
                <Input.Error
                  error={
                    pipelineJobFormValidation.errors['pipeline-job-pipeline']
                  }
                />
              }
              validation={{
                validationState: pipelineJobFormValidation,
                validators: [Input.validators.required('Pipeline')],
              }}
            />
            {selectedPipeline && (
              <>
                <Input.Select.Single
                  id="pipeline-job-mosaic-engine"
                  label={<Input.Label label="Mosaic Engine" />}
                  value={selectedMosaicEngine}
                  title="Mosaic Engine"
                  setValue={setSelectedMosaicEngine}
                  options={mosaicEngineOptions}
                  optionBuilder={(mosaicEngine) =>
                    mosaicEngine?.displayName ?? mosaicEngine?.name ?? 'Unknown'
                  }
                  loading={
                    mosaicEnginesQuery.isLoading ||
                    pipelineJobByIdQuery.isLoading
                  }
                />
                <Input.Select.Single
                  id="pipeline-job-ml-model"
                  label={<Input.Label label="ML Model" />}
                  value={selectedMlModel}
                  title="ML Model"
                  setValue={setSelectedMlModel}
                  options={mlModelsOptions}
                  optionBuilder={(mlModel) =>
                    `${mlModel?.displayName ?? mlModel?.name ?? 'Unknown'} ${
                      mlModel.version ? `(v${mlModel.version})` : ''
                    }`.trim()
                  }
                  loading={
                    mlModelsQuery.isLoading || pipelineJobByIdQuery.isLoading
                  }
                />
                <Input.Text.Area
                  id="pipeline-job-model-parameters"
                  label={<Input.Label label="3D Model Parameters" />}
                  value={modelParameters ?? ''}
                  setValue={setModelParameters ?? ''}
                  error={
                    <Input.Error
                      error={
                        pipelineJobFormValidation.errors[
                          'pipeline-job-model-parameters'
                        ]
                      }
                    />
                  }
                  rows={5}
                  validation={{
                    validationState: pipelineJobFormValidation,
                    validators: [Input.validators.validJson],
                  }}
                />
              </>
            )}
          </>
        )}
        <div className="w-full flex flex-row justify-end items-center py-4">
          <Button.Primary
            id="ad-hoc-job-submit-button"
            label="Submit"
            onClick={submitAdHocJob}
            loading={adHocJobPostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
