/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { Layer, LibraryFilter, LibraryItem } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getLayer = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Layer[]>(
    { url: `/api/Layers`, method: 'GET', signal },
    options
  );
};

export const getGetLayerQueryKey = () => {
  return [`/api/Layers`] as const;
};

export const getGetLayerQueryOptions = <
  TData = Awaited<ReturnType<typeof getLayer>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLayer>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLayerQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLayer>>> = ({
    signal,
  }) => getLayer(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLayer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLayerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLayer>>
>;
export type GetLayerQueryError = ErrorType<void>;

export const useGetLayer = <
  TData = Awaited<ReturnType<typeof getLayer>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLayer>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLayerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postLayer = (
  layer: BodyType<Layer>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Layer>(
    {
      url: `/api/Layers`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: layer,
    },
    options
  );
};

export const getPostLayerMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLayer>>,
    TError,
    { data: BodyType<Layer> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLayer>>,
  TError,
  { data: BodyType<Layer> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLayer>>,
    { data: BodyType<Layer> }
  > = (props) => {
    const { data } = props ?? {};

    return postLayer(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLayerMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLayer>>
>;
export type PostLayerMutationBody = BodyType<Layer>;
export type PostLayerMutationError = ErrorType<void>;

export const usePostLayer = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLayer>>,
    TError,
    { data: BodyType<Layer> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postLayer>>,
  TError,
  { data: BodyType<Layer> },
  TContext
> => {
  const mutationOptions = getPostLayerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getLayerById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Layer>(
    {
      url: `/api/Layers/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetLayerByIdQueryKey = (id: number) => {
  return [`/api/Layers/${id}`] as const;
};

export const getGetLayerByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getLayerById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getLayerById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLayerByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLayerById>>> = ({
    signal,
  }) => getLayerById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLayerById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLayerByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLayerById>>
>;
export type GetLayerByIdQueryError = ErrorType<void>;

export const useGetLayerById = <
  TData = Awaited<ReturnType<typeof getLayerById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getLayerById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLayerByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putLayerById = (
  id: number,
  layer: BodyType<Layer>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Layer>(
    {
      url: `/api/Layers/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: layer,
    },
    options
  );
};

export const getPutLayerByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putLayerById>>,
    TError,
    { id: number; data: BodyType<Layer> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putLayerById>>,
  TError,
  { id: number; data: BodyType<Layer> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putLayerById>>,
    { id: number; data: BodyType<Layer> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putLayerById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutLayerByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putLayerById>>
>;
export type PutLayerByIdMutationBody = BodyType<Layer>;
export type PutLayerByIdMutationError = ErrorType<void>;

export const usePutLayerById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putLayerById>>,
    TError,
    { id: number; data: BodyType<Layer> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putLayerById>>,
  TError,
  { id: number; data: BodyType<Layer> },
  TContext
> => {
  const mutationOptions = getPutLayerByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteLayerById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Layer>(
    { url: `/api/Layers/${encodeURIComponent(String(id))}`, method: 'DELETE' },
    options
  );
};

export const getDeleteLayerByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLayerById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteLayerById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteLayerById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteLayerById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteLayerByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteLayerById>>
>;

export type DeleteLayerByIdMutationError = ErrorType<void>;

export const useDeleteLayerById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLayerById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteLayerById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteLayerByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getLayersByLayerGroup = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Layer[]>(
    {
      url: `/api/Layers/GroupLayers/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetLayersByLayerGroupQueryKey = (id: number) => {
  return [`/api/Layers/GroupLayers/${id}`] as const;
};

export const getGetLayersByLayerGroupQueryOptions = <
  TData = Awaited<ReturnType<typeof getLayersByLayerGroup>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLayersByLayerGroup>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLayersByLayerGroupQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLayersByLayerGroup>>
  > = ({ signal }) => getLayersByLayerGroup(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLayersByLayerGroup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLayersByLayerGroupQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLayersByLayerGroup>>
>;
export type GetLayersByLayerGroupQueryError = ErrorType<void>;

export const useGetLayersByLayerGroup = <
  TData = Awaited<ReturnType<typeof getLayersByLayerGroup>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLayersByLayerGroup>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLayersByLayerGroupQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLibrary = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<LibraryItem[]>(
    { url: `/api/Layers/Library`, method: 'GET', signal },
    options
  );
};

export const getGetLibraryQueryKey = () => {
  return [`/api/Layers/Library`] as const;
};

export const getGetLibraryQueryOptions = <
  TData = Awaited<ReturnType<typeof getLibrary>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLibrary>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLibraryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLibrary>>> = ({
    signal,
  }) => getLibrary(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLibrary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLibraryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLibrary>>
>;
export type GetLibraryQueryError = ErrorType<void>;

export const useGetLibrary = <
  TData = Awaited<ReturnType<typeof getLibrary>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLibrary>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLibraryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLibraryFiltered = (
  skip: number,
  take: number,
  libraryFilter: BodyType<LibraryFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<LibraryItem[]>(
    {
      url: `/api/Layers/Library/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: libraryFilter,
    },
    options
  );
};

export const getGetLibraryFilteredMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getLibraryFiltered>>,
    TError,
    { skip: number; take: number; data: BodyType<LibraryFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getLibraryFiltered>>,
  TError,
  { skip: number; take: number; data: BodyType<LibraryFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getLibraryFiltered>>,
    { skip: number; take: number; data: BodyType<LibraryFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getLibraryFiltered(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetLibraryFilteredMutationResult = NonNullable<
  Awaited<ReturnType<typeof getLibraryFiltered>>
>;
export type GetLibraryFilteredMutationBody = BodyType<LibraryFilter>;
export type GetLibraryFilteredMutationError = ErrorType<void>;

export const useGetLibraryFiltered = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getLibraryFiltered>>,
    TError,
    { skip: number; take: number; data: BodyType<LibraryFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getLibraryFiltered>>,
  TError,
  { skip: number; take: number; data: BodyType<LibraryFilter> },
  TContext
> => {
  const mutationOptions = getGetLibraryFilteredMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadLayerById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/Layers/${encodeURIComponent(String(id))}/Download`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options
  );
};

export const getDownloadLayerByIdQueryKey = (id: number) => {
  return [`/api/Layers/${id}/Download`] as const;
};

export const getDownloadLayerByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadLayerById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadLayerById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDownloadLayerByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadLayerById>>
  > = ({ signal }) => downloadLayerById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadLayerById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadLayerByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadLayerById>>
>;
export type DownloadLayerByIdQueryError = ErrorType<void>;

export const useDownloadLayerById = <
  TData = Awaited<ReturnType<typeof downloadLayerById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadLayerById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadLayerByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
