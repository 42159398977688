import { SetState } from 'zustand';

import { GroupActions, GroupIcon, Layer, ProjectState } from '@agerpoint/types';
import { toStateLayer } from '@agerpoint/utilities';

import { DEFAULT_GROUP_ICON, assignZIndexes } from './utils';

export default function createGroupActions(
  set: SetState<ProjectState>
): GroupActions {
  return {
    setVisible: (groupId, visible) =>
      set((state) => ({
        groups: state.groups.map((group) =>
          group.id === groupId ? { ...group, visible } : group
        ),
      })),
    setName: (groupId, name) =>
      set((state) => ({
        groups: state.groups.map((group) =>
          group.id === groupId ? { ...group, name } : group
        ),
      })),
    setIcon: (groupId: number, icon: GroupIcon) =>
      set((state) => ({
        groups: state.groups.map((group) =>
          group.id === groupId ? { ...group, icon } : group
        ),
      })),
    setZIndex: (groupId, zIndex) =>
      set((state) => {
        const currentGroups = state.groups;
        const newGroups = [...currentGroups];
        const currentGroupIndex = currentGroups.findIndex(
          (group) => group.id === groupId
        );
        const newGroupIndex = currentGroups.findIndex((group) =>
          group.zIndex ? group.zIndex <= zIndex : false
        );
        const group = currentGroups[currentGroupIndex];
        newGroups.splice(currentGroupIndex, 1);
        newGroups.splice(newGroupIndex, 0, group);

        return {
          ...state,
          groups: assignZIndexes(newGroups),
        };
      }),
    add: () =>
      set((state) => {
        const hasNewGroup = state.groups.some((group) => group.id === 0);
        return hasNewGroup
          ? // If we already have a new group, don't do anything
            state
          : {
              ...state,
              groups: assignZIndexes([
                {
                  id: 0,
                  name: '',
                  layers: [],
                  zIndex: 0,
                  visible: true,
                  icon: DEFAULT_GROUP_ICON,
                },
                ...state.groups,
              ]),
            };
      }),
    delete: (groupId) =>
      set((state) => {
        const newGroups = assignZIndexes(
          state.groups.filter((group) => group.id !== groupId)
        );
        return { groups: newGroups };
      }),
    saveNew: (group) =>
      set((state) => {
        const newGroups = group
          ? assignZIndexes([
              { ...group, layers: [] },
              ...state.groups.filter((group) => group.id !== 0),
            ])
          : state.groups.filter((group) => group.id !== 0);
        return { ...state, groups: newGroups };
      }),
    setLayers: (groupId, layers) =>
      set((state) => {
        const nextGroups = [...state.groups];
        const groupIndex = nextGroups.findIndex(
          (group) => groupId === group.id
        );
        if (groupIndex < 0) {
          throw new Error(`Unknown group with id ${groupId}.`);
        }

        const groupLayers = layers
          .sort((a, b) => (b.zIndex || 0) - (a.zIndex || 0))
          .map((layer) => {
            try {
              return toStateLayer(layer);
            } catch (e) {
              console.error(e);
              return null;
            }
          })
          .filter((layer) => layer !== null);

        nextGroups[groupIndex] = {
          ...nextGroups[groupIndex],
          layers: groupLayers as Layer[],
        };

        return { groups: nextGroups };
      }),
  };
}
