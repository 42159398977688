import { ColorStyle } from '@agerpoint/types';
import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import RenderFeature from 'ol/render/Feature';
import { asArray } from 'ol/color';
import compare from 'trivial-compare';
import { getInterpolator } from '@agerpoint/component';

export const hidden = [0, 0, 0, 0];
const transparent = [0, 0, 0, 0];

export function createColorFn(
  colorStyle: string | ColorStyle,
  opacity: number
): [
  (feature: RenderFeature | Feature<Geometry>) => number[],
  string | undefined,
  string | number | undefined
] {
  try {
    if (typeof colorStyle === 'string') {
      const rgba = [...asArray(colorStyle).slice(0, 3), opacity];
      return [() => rgba, undefined, undefined];
    } else if (colorStyle.type === 'solid') {
      const rgba = [...asArray(colorStyle.color).slice(0, 3), opacity];
      rgba[3] = opacity;
      return [() => rgba, undefined, undefined];
    } else if (colorStyle.type === 'graduated') {
      const interpolator = getInterpolator(
        colorStyle.gradient,
        colorStyle.bins.length
      );
      const attribute = colorStyle.attribute;
      const bins = [...colorStyle.bins].sort(
        ({ range: aRange }, { range: bRange }) => compare(aRange[0], bRange[0])
      );
      const binColors = Array.from({ length: bins.length }).map((_, i) => {
        if (!bins[i].visible) return hidden;

        const color = interpolator(i);
        const rgba = [...asArray(color).slice(0, 3), opacity];
        rgba[3] = opacity;
        return rgba;
      });

      return [
        (feature) => {
          const value = feature.getProperties()[attribute];
          if (value == null) return transparent;
          const binIndex = bins.findIndex(({ range: [, max] }) => value < max);
          return binColors[binIndex > -1 ? binIndex : bins.length - 1];
        },
        attribute,
        (bins[0].range[0] + bins[bins.length - 1].range[1]) / 2,
      ];
    } else if (colorStyle.type === 'categorized') {
      const interpolator = getInterpolator(
        colorStyle.gradient,
        colorStyle.bins.length
      );
      const attribute = colorStyle.attribute;
      const bins = [...colorStyle.bins].map((bin) => ({
        ...bin,
        value: !isNaN(Number(bin.value)) ? Number(bin.value) : bin.value,
      }));

      const binColors = bins.map((bin, index) => {
        if (!bin.visible) return hidden;
        const color = interpolator(index);
        const rgba = [...asArray(color).slice(0, 3), opacity];
        rgba[3] = opacity;
        return rgba;
      });

      return [
        (feature) => {
          const value = feature.getProperties()[attribute];
          if (value == null) return transparent;
          const binIndex = bins.findIndex((bin) => bin.value === value);
          return binColors[binIndex > -1 ? binIndex : bins.length - 1];
        },
        attribute,
        bins[Math.floor(bins.length / 2)].value,
      ];
    } else {
      throw new Error(`Unknown colorStyle ${JSON.stringify(colorStyle)}.`);
    }
  } catch (exc) {
    console.error(exc);
    // We intentionally don't set opacity correctly here, to make
    // this error condition obvious.
    return [() => [255, 0, 0, 1], undefined, undefined];
  }
}
