import { useGlobalStore } from '@agerpoint/utilities';

import './sitewide-loading-bar.scss';

export const SitewideLoadingBar = () => {
  const { siteWideLoading } = useGlobalStore();

  return (
    <div
      className={`absolute left-0 right-0 -bottom-0.5 top-full overflow-hidden transition-opacity
      ${siteWideLoading ? 'opacity-100' : 'opacity-0'}
    `}
    >
      <div
        className={`absolute top-0 bottom-0 bg-gray-400 transition-all w-1/2
        rounded shadow-sm left-0 sitewide-loading-indicator opacity-50`}
      ></div>
    </div>
  );
};
