import { faDownload, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Extent } from 'ol/extent';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  GetResponse,
  Upload,
  UploadFile,
  useGetUploadById,
  useGetUploadFilesByUploadId,
} from '@agerpoint/api';
import { useDownloadLayerById, useGetFileReportById } from '@agerpoint/api';
import {
  ExpansionContainerInner,
  ExpansionContainerOuter,
  IconButton,
} from '@agerpoint/component';
import { Attributes, Layer } from '@agerpoint/types';

import {
  ExpansionDelete,
  ExpansionToolbar,
  ZoomToExtent,
} from '../expansion-toolbar/expansion-toolbar';
import { LayerStyle } from '../layer-style/layer-style';
import { SidebarExpansionHeaderEdit } from '../sidebar-expansion-header-edit/sidebar-expansion-header-edit';

interface LayerExpansionProps {
  layer: Layer;
  onCancel: () => void;
  updateLayerName?: (id: number, name: string) => Promise<void>;
  updateLayerStyle: (layer: Layer) => Promise<void>;
  deleteLayer: (id: number) => Promise<void>;
  setDesiredExtent: (extent: Extent) => void;
  availableAttributes: GetResponse<Attributes, void> | undefined;
}

export function LayerExpansion({
  layer,
  onCancel,
  updateLayerName = undefined,
  deleteLayer,
  updateLayerStyle,
  setDesiredExtent,
  availableAttributes,
}: LayerExpansionProps) {
  const [loading, setLoading] = useState<boolean>(false);

  let fileName = layer.name + '.geojson';
  const { error: errorLoadingFileName } = useGetFileReportById({
    id: layer.entityId,
    lazy: layer.type !== 'File',
    resolve: (data) => {
      fileName = data.fileName;
      return data;
    },
  });
  const { loading: loadingLayerDownload, refetch: refetchLayerDownload } =
    useDownloadLayerById({
      id: layer.id,
      lazy: true,
      resolve: (response) => {
        if (response?.ok) {
          response.blob().then((blob: Blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Remove the link html after download
            document.body.removeChild(link);
          });
        }
        return response;
      },
    });

  const downloadLayer = useCallback(async () => {
    if (layer.type === 'Attachment') {
      const downloadUrl = layer.uploadFile?.downloadUrl;

      if (downloadUrl) {
        try {
          setLoading(true);
          const response = await fetch(downloadUrl);

          if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download = layer.fileName || 'attachment.pdf';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    } else {
      refetchLayerDownload();
    }
  }, [layer, refetchLayerDownload]);

  useEffect(() => {
    if (layer.type === 'Attachment') {
      return;
    }

    setLoading(loadingLayerDownload);
  }, [loadingLayerDownload]);

  return (
    <div className="h-full relative">
      <SidebarExpansionHeaderEdit
        onSave={
          updateLayerName
            ? (layerName) => updateLayerName(layer.id, layerName)
            : undefined
        }
        onCancel={onCancel}
        name={layer.name}
      />
      <ExpansionContainerOuter>
        <LayerStyle
          layer={layer}
          onChange={updateLayerStyle}
          availableAttributes={availableAttributes}
        />
        <ExpansionContainerInner>
          <ExpansionToolbar>
            {({ showConfirm, isConfirming }) => (
              <>
                {'extent' in layer &&
                  layer.typeId !== 7 &&
                  layer.typeId !== 10 && (
                    <ZoomToExtent
                      onZoom={() => setDesiredExtent(layer.extent)}
                    />
                  )}
                {(layer.typeId === 1 ||
                  layer.typeId === 7 ||
                  layer.typeId === 10) &&
                  !errorLoadingFileName && (
                    <IconButton
                      show
                      className={` ${
                        layer.typeId === 7 || layer.typeId === 10
                          ? 'ml-12'
                          : 'mr-auto ml-3'
                      } text-gray-500 hover:text-gray-900`}
                      disabled={loading ? true : false}
                      onClick={() => {
                        downloadLayer();
                      }}
                      icon={
                        <FontAwesomeIcon
                          icon={loading ? faSpinner : faDownload}
                          className={loading ? 'text-xl fa-spin' : 'text-xl'}
                        />
                      }
                    />
                  )}
                <ExpansionDelete
                  isConfirming={isConfirming}
                  showConfirm={showConfirm}
                  onDelete={() => deleteLayer(layer.id).finally(onCancel)}
                  message="Are you sure you want to remove this layer?"
                  deleteLabel="Remove Layer"
                />
              </>
            )}
          </ExpansionToolbar>
        </ExpansionContainerInner>
      </ExpansionContainerOuter>
    </div>
  );
}
