import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colord } from 'colord';
import { useState } from 'react';
import { RgbaStringColorPicker } from 'react-colorful';

import { useGetEptDataById } from '@agerpoint/api';
import {
  GradientSelect,
  GradientSelectCustomColors,
  Select,
  availableGradients,
  getGradientColors,
} from '@agerpoint/component';
import { CustomSelect } from '@agerpoint/component';
import {
  ClassificationPointCloudStyle,
  Gradient as GradientType,
  GraduatedPointCloudStyle,
  Layer,
  PointCloudMapLayer,
  PointCloudStyle,
  TilesetMetadata,
} from '@agerpoint/types';

export const contrasts: Record<number, string> = {
  0: 'Low',
  1: 'Medium',
  2: 'High',
};

interface Color {
  layer: PointCloudMapLayer;
  onChange: (layer: Layer) => Promise<void>;
}

type Mode = 'rgb' | 'classification' | 'graduated';

const defaults: Record<
  Mode,
  (
    style: PointCloudStyle,
    tilesetMetadata: TilesetMetadata
  ) => Partial<PointCloudStyle>
> = {
  rgb: () => ({ type: 'rgb' }),
  graduated: (style, tilesetMetadata) => ({
    attribute:
      'attribute' in style
        ? style.attribute
        : tilesetMetadata.asset.options.dimensions.sort()[0],
  }),
  classification: (style, tilesetMetadata) => {
    const attributes = tilesetMetadata.asset.ept.schema;
    const classification = attributes.find(
      (attribute) => attribute.name === 'Classification'
    );

    return {
      attribute: 'Classification',
      classes:
        classification?.counts?.map((cls) => ({
          name: cls.value.toString(),
          value: cls.value,
          visible: true,
        })) || [],
    };
  },
};

export function Color({ layer, onChange }: Color) {
  const { data: tilesetMetadata } = useGetEptDataById({
    id: layer.entityId || 0,
    folderName: 'ept-tileset',
    fileName: 'tileset.json',
  });

  const hasRgb = tilesetMetadata
    ? ['Red', 'Green', 'Blue'].every((channel) =>
        (tilesetMetadata as unknown as TilesetMetadata).asset.ept.schema.some(
          (attribute) => attribute.name === channel
        )
      )
    : false;
  const dimensions = tilesetMetadata
    ? (tilesetMetadata as unknown as TilesetMetadata)?.asset.options.dimensions
    : null;

  return (
    <>
      {tilesetMetadata ? (
        <CustomSelect
          label="Type"
          id="fillColorType"
          options={[
            { name: 'RGB', value: 'rgb', disabled: !hasRgb },
            {
              name: 'Graduated',
              value: 'graduated',
              disabled: !dimensions || dimensions.length === 0,
            },
            {
              name: 'Classification',
              value: 'classification',
              disabled: !dimensions?.find((d) => d === 'Classification'),
            },
          ]}
          value={layer.style.type}
          onChange={(event) => {
            const type = event.target.value as Mode;
            onChange({
              ...layer,
              style: {
                ...layer.style,
                ...defaults[type](
                  layer.style,
                  tilesetMetadata as unknown as TilesetMetadata
                ),
                type,
              } as PointCloudStyle,
            });
          }}
        />
      ) : (
        <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-green" />
      )}
      {dimensions &&
        (layer.style.type === 'graduated' ? (
          <Graduated
            style={layer.style}
            dimensions={dimensions.filter(
              (dimension) => dimension !== 'Classification'
            )}
            onChange={(style) => onChange({ ...layer, style })}
          />
        ) : layer.style.type === 'classification' ? (
          <Classification
            style={layer.style}
            onChange={(style) => onChange({ ...layer, style })}
          />
        ) : null)}
    </>
  );
}

interface Graduated {
  style: GraduatedPointCloudStyle;
  onChange: (style: GraduatedPointCloudStyle) => Promise<void>;
  dimensions: string[];
}

function Graduated({ style, dimensions, onChange }: Graduated) {
  return (
    <div>
      <label className="text-sm text-gray-800 font-bold" htmlFor="attribute">
        Attribute
      </label>
      <Select
        type="small"
        onChange={(event) =>
          onChange({ ...style, attribute: event.target.value })
        }
        id="attribute"
        name="attribute"
        options={dimensions.sort().map((dimension) => {
          return { name: dimension, value: dimension };
        })}
        value={style.attribute}
      />
      <GradientSelect
        onChange={(gradient) => onChange({ ...style, gradient })}
        value={style.gradient || GradientType.RdYlGn}
        options={availableGradients}
      />
      <label className="text-sm text-gray-800 font-bold" htmlFor="contrast">
        Contrast
      </label>
      <Select
        type="small"
        onChange={(event) =>
          onChange({ ...style, contrast: Number(event.target.value) })
        }
        id="contrast"
        name="contrast"
        options={Object.keys(contrasts).map((contrast) => {
          return { value: contrast, name: contrasts[Number(contrast)] };
        })}
        value={style.contrast}
      />
    </div>
  );
}

interface Classification {
  style: ClassificationPointCloudStyle;
  onChange: (style: ClassificationPointCloudStyle) => Promise<void>;
}

function Classification({ style, onChange }: Classification) {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(
    new Array(style.classes.length).fill(false)
  );
  const binColors = getGradientColors(style.gradient, style.classes.length);
  return (
    <div>
      <GradientSelectCustomColors
        onChange={(gradient) => {
          style.classes.forEach((element, index) => {
            style.classes[index].color = '';
          });
          onChange({ ...style, gradient });
        }}
        value={style.gradient || GradientType.RdYlGn}
        options={availableGradients}
      />
      {style.gradient.toString() === 'Custom' && (
        <div className="w-full">
          <div className="py-2 text-sm text-gray-800 font-bold">Classes</div>
          {style.classes.map((categorizedClass, i) => {
            let backgroundColor = binColors[i];
            if (categorizedClass.color) {
              backgroundColor = categorizedClass.color;
            }
            const borderColor = colord(backgroundColor).darken().toRgbString();

            return (
              <div
                key={i}
                className="flex flex-col text-sm font-display-condensed"
              >
                <div
                  className="flex items-center py-1 text-sm cursor-pointer hover:bg-gray-50"
                  onClick={() => {
                    setIsColorPickerOpen((prevState) =>
                      prevState.map((item, idx) => (idx === i ? !item : item))
                    );
                  }}
                >
                  <div
                    className="w-3 h-5 rounded-sm border mr-1"
                    style={{
                      backgroundColor,
                      borderColor,
                    }}
                  />
                  {categorizedClass.value}
                </div>
                <input
                  type="text"
                  defaultValue={categorizedClass.name}
                  onChange={(e) => {
                    const nextValue = e.target.value;
                    categorizedClass.name = nextValue;
                    onChange({ ...style });
                  }}
                  className={`
                      my-2
                      focus:ring-green
                      focus:border-green
                      block
                      p-1
                      shadow-sm
                      sm:text-sm
                      border-gray-500
                      rounded
                      border-none
                      text-left
                    `}
                />
                {isColorPickerOpen[i] && (
                  <div className="py-2">
                    <RgbaStringColorPicker
                      color={backgroundColor}
                      onChange={(color) => {
                        categorizedClass.color = color;
                        onChange({ ...style });
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
