import { asArray, fromString, toString } from 'ol/color';

import { HexColor } from '@agerpoint/types';

// Rounds a number to two decimal places.
export const round = (value: number) => {
  return Math.round(value * 100) / 100;
};

// Converts a hexadecimal color string to an RGBA array.
// The output array includes the specified opacity as the fourth element.
export const cvtHexToRgba = (color: string, opacity: number) => {
  return [...asArray(color).slice(0, 3), opacity];
};

// Converts an RGBA string (e.g., "rgba(255,0,0,1)") to an RGBA array.
export const cvtRgbaStrToRgbaArr = (color: string) => {
  return fromString(color);
};

// Converts an RGBA array back to an RGBA string.
export const cvtRgbaArrToRgbaStr = (color: number[]) => {
  return toString(color);
};

// Converts a standard RGBA array to a hexadecimal color string.
export const cvtStdRgbaArrToHexStr = (color: number[]) => {
  const [r, g, b] = color;
  return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
};

// Converts standard RGBA values to percentage-based RGBA.
// This function is useful for CSS color values where percentages may be preferred.
export const cvtStdRgbaToRgbaPct = (color: number[]) => {
  let [r, g, b] = color;
  r = round(r / 255);
  g = round(g / 255);
  b = round(b / 255);
  const a = color[3];
  return [r, g, b, a];
};

// Converts percentage-based RGBA values back to standard RGBA values.
export const cvtRgbaPctToStdRgba = (color: number[]) => {
  let [r, g, b] = color;
  r = round(r * 255);
  g = round(g * 255);
  b = round(b * 255);
  const a = color[3];
  return [r, g, b, a];
};

// Converts a hexadecimal color string to an RGB integer array [R, G, B].
export const hexToRgbInt = (hex: string): number[] => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

// Converts a hexadecimal color string to an RGB decimal array [R, G, B].
// Each value in the array is normalized between 0 and 1.
export const hexToRgbDecimal = (hex: string): number[] => {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;
  return [round(r), round(g), round(b)];
};

/**
 * Converts an RGB array of integers into a hexadecimal color string.
 *
 * @param {number[]} rgb - An array containing the red, green, and blue components of the color.
 * @returns {HexColor} The CSS hex color code as a string.
 */
export const intRgbToCssHex = (rgb: number[]): HexColor => {
  // Helper function to convert a single number to a two-digit hexadecimal string.
  const toHex = (colorValue: number): string => {
    const hex = colorValue.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  // Destructure the RGB components from the input array.
  const [r, g, b] = rgb;

  // Convert each component to hex and concatenate them with a hash prefix.
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

/**
 * Converts an RGB color from a decimal array format to a CSS hexadecimal string.
 * Each element of the input array should be a decimal number between 0 and 1.
 *
 * @param {Array<number>} rgbDecimalArray - An array of three numbers representing the RGB values in decimal form.
 * @returns {string} - The CSS hexadecimal color string.
 */
export const decimalRgbToCssHex = (rgbDecimalArray: number[]): HexColor => {
  // Convert each RGB component from a decimal to an integer and then to a hexadecimal string
  const hex = rgbDecimalArray
    .map((component) => {
      // Convert the decimal fraction to a 0-255 range integer
      const integer = Math.round(component * 255);
      // Convert the integer to a hexadecimal string and pad with leading zeros if necessary
      return integer.toString(16).padStart(2, '0');
    })
    .join('');

  // Prepend the '#' symbol to form a valid CSS hex color code
  return `#${hex}`;
};

export const AnnotationColorOptions: { name: string; value: HexColor }[] = [
  { name: 'red', value: '#AC154C' },
  { name: 'blue', value: '#186CB7' },
  { name: 'yellow', value: '#CC9A05' },
  { name: 'green', value: '#003D33' },
  { name: 'pink', value: '#C20C64' },
  { name: 'maroon', value: '#630067' },
  { name: 'purple', value: '#5D54BA' },
  { name: 'olive', value: '#949147' },
  { name: 'bright-green', value: '#00CC00' },
  { name: 'brown', value: '#A0512D' },
];

export const LayerColorOptions: { name: string; value: HexColor }[] = [
  { name: 'red', value: '#AC154C' },
  { name: 'blue', value: '#186CB7' },
  { name: 'yellow', value: '#CC9A05' },
  { name: 'green', value: '#003D33' },
  { name: 'pink', value: '#C20C64' },
  { name: 'maroon', value: '#630067' },
  { name: 'purple', value: '#5D54BA' },
  { name: 'olive', value: '#949147' },
  { name: 'bright-green', value: '#00CC00' },
  { name: 'brown', value: '#A0512D' },
];

export const getNextAnnotationColor = (
  currentColor: string | undefined
): HexColor => {
  if (!currentColor) {
    return AnnotationColorOptions[0].value;
  }
  const currentIndex = AnnotationColorOptions.findIndex(
    (color) => color.value === currentColor
  );
  const nextIndex = (currentIndex + 1) % AnnotationColorOptions.length;
  return AnnotationColorOptions[nextIndex].value;
};
