interface PreviewMediaPlayerBaseProps {
  autoPlay?: boolean;
  controls?: boolean;
  loop?: boolean;
  className?: string;
}

interface PreviewMediaPlayerMP4Props extends PreviewMediaPlayerBaseProps {
  mp4URL: string;
}

export type PreviewMediaPlayerProps = PreviewMediaPlayerMP4Props;

export const PreviewMediaPlayer = ({
  mp4URL,
  autoPlay,
  controls,
  loop,
  className,
}: PreviewMediaPlayerProps) => {
  return (
    <video
      key={mp4URL}
      muted
      {...{ autoPlay, controls, loop }}
      crossOrigin="anonymous"
      className={`w-full h-full ${className}`}
    >
      {mp4URL && <source src={mp4URL} type="video/mp4" />}
    </video>
  );
};
