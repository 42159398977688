/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { ApGeometryCustomAttribute } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getGeometryCustomAttribute = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<ApGeometryCustomAttribute>(
    {
      url: `/api/GeometryCustomAttributes/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetGeometryCustomAttributeQueryKey = (id: number) => {
  return [`/api/GeometryCustomAttributes/${id}`] as const;
};

export const getGetGeometryCustomAttributeQueryOptions = <
  TData = Awaited<ReturnType<typeof getGeometryCustomAttribute>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryCustomAttribute>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGeometryCustomAttributeQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeometryCustomAttribute>>
  > = ({ signal }) => getGeometryCustomAttribute(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeometryCustomAttribute>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometryCustomAttributeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeometryCustomAttribute>>
>;
export type GetGeometryCustomAttributeQueryError = ErrorType<void>;

export const useGetGeometryCustomAttribute = <
  TData = Awaited<ReturnType<typeof getGeometryCustomAttribute>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryCustomAttribute>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGeometryCustomAttributeQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putGeometryCustomAttributeById = (
  id: number,
  apGeometryCustomAttribute: BodyType<ApGeometryCustomAttribute>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<ApGeometryCustomAttribute>(
    {
      url: `/api/GeometryCustomAttributes/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: apGeometryCustomAttribute,
    },
    options
  );
};

export const getPutGeometryCustomAttributeByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putGeometryCustomAttributeById>>,
    TError,
    { id: number; data: BodyType<ApGeometryCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putGeometryCustomAttributeById>>,
  TError,
  { id: number; data: BodyType<ApGeometryCustomAttribute> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putGeometryCustomAttributeById>>,
    { id: number; data: BodyType<ApGeometryCustomAttribute> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putGeometryCustomAttributeById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutGeometryCustomAttributeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putGeometryCustomAttributeById>>
>;
export type PutGeometryCustomAttributeByIdMutationBody =
  BodyType<ApGeometryCustomAttribute>;
export type PutGeometryCustomAttributeByIdMutationError = ErrorType<void>;

export const usePutGeometryCustomAttributeById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putGeometryCustomAttributeById>>,
    TError,
    { id: number; data: BodyType<ApGeometryCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putGeometryCustomAttributeById>>,
  TError,
  { id: number; data: BodyType<ApGeometryCustomAttribute> },
  TContext
> => {
  const mutationOptions =
    getPutGeometryCustomAttributeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteGeometryCustomAttributeById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<ApGeometryCustomAttribute>(
    {
      url: `/api/GeometryCustomAttributes/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteGeometryCustomAttributeByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGeometryCustomAttributeById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteGeometryCustomAttributeById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteGeometryCustomAttributeById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteGeometryCustomAttributeById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGeometryCustomAttributeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteGeometryCustomAttributeById>>
>;

export type DeleteGeometryCustomAttributeByIdMutationError = ErrorType<void>;

export const useDeleteGeometryCustomAttributeById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGeometryCustomAttributeById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteGeometryCustomAttributeById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getDeleteGeometryCustomAttributeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getGeometryCustomAttributesByGeometryId = (
  geometryId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<ApGeometryCustomAttribute>(
    {
      url: `/api/GeometryCustomAttributes/Geometry/${encodeURIComponent(
        String(geometryId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetGeometryCustomAttributesByGeometryIdQueryKey = (
  geometryId: number
) => {
  return [`/api/GeometryCustomAttributes/Geometry/${geometryId}`] as const;
};

export const getGetGeometryCustomAttributesByGeometryIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getGeometryCustomAttributesByGeometryId>>,
  TError = ErrorType<void>
>(
  geometryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryCustomAttributesByGeometryId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGeometryCustomAttributesByGeometryIdQueryKey(geometryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeometryCustomAttributesByGeometryId>>
  > = ({ signal }) =>
    getGeometryCustomAttributesByGeometryId(geometryId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!geometryId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeometryCustomAttributesByGeometryId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeometryCustomAttributesByGeometryIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeometryCustomAttributesByGeometryId>>
>;
export type GetGeometryCustomAttributesByGeometryIdQueryError = ErrorType<void>;

export const useGetGeometryCustomAttributesByGeometryId = <
  TData = Awaited<ReturnType<typeof getGeometryCustomAttributesByGeometryId>>,
  TError = ErrorType<void>
>(
  geometryId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeometryCustomAttributesByGeometryId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGeometryCustomAttributesByGeometryIdQueryOptions(
    geometryId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postGeometryCustomAttribute = (
  apGeometryCustomAttribute: BodyType<ApGeometryCustomAttribute>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<ApGeometryCustomAttribute>(
    {
      url: `/api/GeometryCustomAttributes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: apGeometryCustomAttribute,
    },
    options
  );
};

export const getPostGeometryCustomAttributeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postGeometryCustomAttribute>>,
    TError,
    { data: BodyType<ApGeometryCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postGeometryCustomAttribute>>,
  TError,
  { data: BodyType<ApGeometryCustomAttribute> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postGeometryCustomAttribute>>,
    { data: BodyType<ApGeometryCustomAttribute> }
  > = (props) => {
    const { data } = props ?? {};

    return postGeometryCustomAttribute(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostGeometryCustomAttributeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postGeometryCustomAttribute>>
>;
export type PostGeometryCustomAttributeMutationBody =
  BodyType<ApGeometryCustomAttribute>;
export type PostGeometryCustomAttributeMutationError = ErrorType<void>;

export const usePostGeometryCustomAttribute = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postGeometryCustomAttribute>>,
    TError,
    { data: BodyType<ApGeometryCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postGeometryCustomAttribute>>,
  TError,
  { data: BodyType<ApGeometryCustomAttribute> },
  TContext
> => {
  const mutationOptions =
    getPostGeometryCustomAttributeMutationOptions(options);

  return useMutation(mutationOptions);
};
