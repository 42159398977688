import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { useRef } from 'react';

import {
  GetResponse,
  useGetGeometryHistogramByGeometryCollectionId,
  useGetMorphologyHistogramByCollectionId,
} from '@agerpoint/api';
import {
  GradientSelect,
  Select,
  availableGradients,
} from '@agerpoint/component';
import { CustomSelect } from '@agerpoint/component';
import { Attributes, Bin, GraduatedStyle } from '@agerpoint/types';
import { useToasts } from '@agerpoint/utilities';

import { AlphaPicker } from '../color-picker/alpha-picker';
import { Bins as BinsComponent } from './bins';
import {
  Bins,
  Action as GraduatedAction,
  ActionKind as GraduatedActionKind,
  State as GraduatedState,
} from './graduated-state';
import { Histogram } from './histogram';

interface GraduatedProps {
  collectionId: number;
  availableAttributes?: GetResponse<Attributes, void>;
  state: GraduatedState;
  dispatch: Dispatch<GraduatedAction>;
  onChange: (style: GraduatedStyle, opacity: number) => void;
  layerTypeId: number;
}

export function Graduated({
  collectionId,
  availableAttributes,
  state,
  dispatch,
  onChange,
  layerTypeId,
}: GraduatedProps) {
  const myHook =
    layerTypeId === 8
      ? useGetGeometryHistogramByGeometryCollectionId
      : useGetMorphologyHistogramByCollectionId;

  const { data, error } = myHook({
    geometryCollectionId: collectionId,
    collectionId: collectionId,
    attributeName: encodeURIComponent(state.attribute || ''),
    binCount: state.classes,
    classificationMethod: state.breaks,
    lazy: !state.attribute,
    resolve: (data) =>
      data.bins.map((bin: Bins) => {
        return { range: [bin.rangeMin, bin.rangeMax], visible: true };
      }),
  });

  const toasts = useToasts();

  useEffect(() => {
    if (error) {
      toasts.add(
        toasts.prepare.error('Failed to calculate attribute classes!')
      );
    }
  }, [error]);

  const [localOpacity, setLocalOpacity] = useState<number>(state.opacity);

  // Only save style when bins change
  const attributeRef = useRef(state.attribute);
  attributeRef.current = state.attribute;
  const breaksRef = useRef(state.breaks);
  breaksRef.current = state.breaks;

  const graduatedStyle = useMemo((): GraduatedStyle | null => {
    return attributeRef.current && state.bins
      ? {
          attribute: attributeRef.current,
          breaksMethod: breaksRef.current,
          bins: state.bins,
          gradient: state.gradient,
          type: 'graduated',
        }
      : null;
  }, [state.bins, state.gradient]);

  useEffect(() => {
    if (data) {
      dispatch({
        type: GraduatedActionKind.SetCalculatedBins,
        payload: {
          bins: data,
          attribute: attributeRef.current,
          breakMethod: breaksRef.current,
        },
      });
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (graduatedStyle) {
      onChange(graduatedStyle, state.opacity);
    }
  }, [graduatedStyle, onChange, state.opacity]);

  useEffect(() => {
    if (!state.attribute && availableAttributes) {
      dispatch({
        type: GraduatedActionKind.SetAttribute,
        payload: availableAttributes?.data?.graduated[0],
      });
    }
  }, [state.attribute, availableAttributes, dispatch]);

  return (
    <div>
      {availableAttributes && (
        <div className="mb-3">
          <label
            className="text-sm text-gray-800 font-bold"
            htmlFor="attribute"
          >
            Attribute
          </label>
          {availableAttributes.loading ? (
            <FontAwesomeIcon
              className="flex text-sm text-center text-green fa-spin"
              icon={faCircleNotch}
            />
          ) : availableAttributes.data &&
            availableAttributes.data.graduated.length > 0 ? (
            <Select
              type="small"
              onChange={(event) =>
                dispatch({
                  type: GraduatedActionKind.SetAttribute,
                  payload: event.target.value,
                })
              }
              id="attribute"
              name="attribute"
              options={availableAttributes.data.graduated.map((attribute) => {
                return { name: attribute, value: attribute };
              })}
              value={state.attribute}
            />
          ) : (
            <span className="flex text-xs">No attributes available</span>
          )}
        </div>
      )}
      <CustomSelect
        label="Classes"
        onChange={(event) =>
          dispatch({
            type: GraduatedActionKind.SetClasses,
            payload: event.target.value,
          })
        }
        id="classes"
        options={Array.from({ length: 9 }, (v, i) => i + 2).map((num) => ({
          name: num,
          value: num,
        }))}
        value={state.classes}
      />
      <CustomSelect
        label="Break"
        onChange={(event) =>
          dispatch({
            type: GraduatedActionKind.SetBreaks,
            payload: event.target.value,
          })
        }
        id="break"
        options={[
          { name: 'Equal Interval', value: 'equalInterval' },
          { name: 'Equal Counts', value: 'equalCounts' },
          { name: 'Natural Breaks', value: 'naturalBreaks' },
        ]}
        value={state.breaks}
      />
      <GradientSelect
        onChange={(value) =>
          dispatch({ type: GraduatedActionKind.SetGradient, payload: value })
        }
        value={state.gradient}
        options={availableGradients}
      />
      <div className="mt-5">
        <AlphaPicker
          min={0}
          max={1}
          step={0.01}
          values={[localOpacity]}
          onChange={(values) => setLocalOpacity(values[0])}
          onFinalChange={(values) =>
            dispatch({
              type: GraduatedActionKind.SetOpacity,
              payload: values[0],
            })
          }
        />
      </div>
      <div className="mt-5">
        <div className="text-sm text-gray-800 font-bold">Histogram</div>
        {state.attribute && state.bins && (
          <Histogram
            collectionId={collectionId}
            attributeName={state.attribute}
            bins={state.bins as unknown as Bin[]}
            layerTypeId={layerTypeId}
            gradient={state.gradient}
            onBinsChange={(bins) =>
              dispatch({
                type: GraduatedActionKind.SetManualBins,
                payload: bins,
              })
            }
          />
        )}
      </div>
      <div className="mt-5">
        {state.attribute && state.bins && (
          <BinsComponent
            bins={state.bins as unknown as Bin[]}
            gradient={state.gradient}
            onBinsChange={(bins) =>
              dispatch({
                type: GraduatedActionKind.SetManualBins,
                payload: bins,
              })
            }
          />
        )}
      </div>
    </div>
  );
}
