import compare from 'trivial-compare';

import { APIClient, APIUtils } from '../..';
import { uuidRegex } from '../../utils/uuid';

interface IuseGetAllByAnalyticRequestId {
  analyticRequestId?: number;
  captureId?: number;
  enabled?: boolean;
}

export const useGetAllByAnalyticRequestId = ({
  analyticRequestId,
  captureId,
  enabled = true,
}: IuseGetAllByAnalyticRequestId) => {
  return APIClient.useGetCaptureObjectsByAnalyticRequestId(
    Number(analyticRequestId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.analyticRequests,
          { analyticRequestId: Number(analyticRequestId) },
          APIUtils.QueryKey.captureObjects,
        ],
        queryFn: async () => {
          // Hack: for syntetic Low Res Model Analytic Request
          if (analyticRequestId === -1) {
            return [];
          }

          return APIClient.getCaptureObjectsByAnalyticRequestId(
            Number(analyticRequestId)
          );
        },
        enabled:
          Number.isSafeInteger(Number(analyticRequestId ?? undefined)) &&
          Number.isSafeInteger(Number(captureId ?? undefined)) &&
          enabled,
        select: (data) =>
          data.filter((co) => {
            return co.captureObjectType?.id !== 4;
          }),
      },
    }
  );
};

interface IuseGetAllByCaptureIdAndCaptureJobId {
  captureId?: number;
  captureJobId?: number;
  enabled?: boolean;
}

export const useGetAllByCaptureIdAndCaptureJobId = ({
  captureId,
  captureJobId,
  enabled = true,
}: IuseGetAllByCaptureIdAndCaptureJobId) => {
  return APIClient.useGetCaptureObjectsByCaptureId(Number(captureId), {
    query: {
      queryKey: [
        APIUtils.QueryKey.captures,
        { captureId: Number(captureId) },
        APIUtils.QueryKey.captureJobs,
        { captureJobId: Number(captureJobId) },
        APIUtils.QueryKey.captureObjects,
      ],
      enabled:
        Number.isSafeInteger(Number(captureId ?? undefined)) &&
        Number.isSafeInteger(Number(captureJobId ?? undefined)) &&
        enabled,
      select: (data) =>
        data
          .filter((co) => co.captureJobId === Number(captureJobId))
          .sort((a, b) => compare(a.id, b.id)),
    },
  });
};

interface IuseGetAllByPublicCaptureUuidAndPublicCaptureJobUuid {
  capturePublicUuid?: string;
  captureJobPublicUuid?: string;
  enabled?: boolean;
}

export const useGetAllByPublicCaptureUuidAndPublicCaptureJobUuid = ({
  capturePublicUuid,
  captureJobPublicUuid,
  enabled = true,
}: IuseGetAllByPublicCaptureUuidAndPublicCaptureJobUuid) => {
  return APIClient.useGetCaptureObjectsBySharedCaptureJob(
    captureJobPublicUuid as string,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.sharedCaptures,
          { capturePublicUuid: capturePublicUuid },
          APIUtils.QueryKey.captureJobs,
          { captureJobPublicUuid: captureJobPublicUuid },
          APIUtils.QueryKey.captureObjects,
        ],
        enabled:
          !!capturePublicUuid &&
          uuidRegex.test(capturePublicUuid) &&
          !!captureJobPublicUuid &&
          uuidRegex.test(captureJobPublicUuid) &&
          enabled,
        select: (data) => data.sort((a, b) => compare(a.id, b.id)),
      },
    }
  );
};

interface IuseGetAllBySharedAnalyticRequestId {
  analyticRequestId?: number;
  capturePublicUuid?: string;
  enabled?: boolean;
}

export const useGetAllBySharedAnalyticRequestId = ({
  analyticRequestId,
  capturePublicUuid,
  enabled = true,
}: IuseGetAllBySharedAnalyticRequestId) => {
  return APIClient.useGetCaptureObjectsBySharedAnalyticRequest(
    Number(analyticRequestId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.sharedCaptures,
          { capturePublicUuid },
          APIUtils.QueryKey.analyticRequests,
          { analyticRequestId: Number(analyticRequestId) },
          APIUtils.QueryKey.captureObjects,
        ],
        queryFn: async () => {
          // Hack: for syntetic Low Res Model Analytic Request
          if (analyticRequestId === -1) {
            return [];
          }

          return APIClient.getCaptureObjectsBySharedAnalyticRequest(
            Number(analyticRequestId)
          );
        },
        enabled:
          Number.isSafeInteger(Number(analyticRequestId ?? undefined)) &&
          !!capturePublicUuid &&
          uuidRegex.test(capturePublicUuid) &&
          enabled,
        select: (data) =>
          data.filter((co) => {
            return co.captureObjectType?.id !== 4;
          }),
      },
    }
  );
};
