// import { BingMaps } from 'ol/source';
import { Feature } from 'ol';
import Geolocation from 'ol/Geolocation';
import OlMap from 'ol/Map';
import Control from 'ol/control/Control';
import { Extent, getHeight, getWidth } from 'ol/extent';
import Point from 'ol/geom/Point';
import { Layer, Vector as VectorLayer } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import { transformExtent } from 'ol/proj';
import BingMaps from 'ol/source/BingMaps';
import TileSource from 'ol/source/Tile';
import Vector from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useCallback, useEffect, useMemo } from 'react';

// TODO: this was causing a circular dependency

export const BaseMapLayer = ({ map, layer, bingKey, zIndex, visible }: any) => {
  const createSourceFn = useCallback(
    () => new BingMaps({ key: bingKey, imagerySet: layer.name }),
    [bingKey, layer.name]
  );
  useTileLayer(map, zIndex, visible, createSourceFn);
  return null;
};

export function EnableLocationService(map: OlMap, geolocation: Geolocation) {
  const accuracyFeature = new Feature();
  geolocation.on('change:accuracyGeometry', function () {
    accuracyFeature.setGeometry(geolocation.getAccuracyGeometry() || undefined);
  });
  const positionFeature = new Feature();
  positionFeature.setStyle(
    new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: '#3399CC',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2,
        }),
      }),
    })
  );
  const locate = document.createElement('div');
  locate.className = 'ol-control ol-unselectable locate';
  locate.innerHTML = '<button title="Locate me">◎</button>';
  locate.addEventListener('click', function () {
    vector.setVisible(true);
    if (geolocation.getPosition()) {
      map.getView().setCenter(geolocation.getPosition());
      const zoom = map.getView().getZoom();
      if (zoom && zoom < 18) {
        map.getView().setZoom(20);
      }
    }
  });
  map.addControl(
    new Control({
      element: locate,
    })
  );
  geolocation.on('change:position', function () {
    const coordinates = geolocation.getPosition() || [];
    positionFeature.setGeometry(new Point(coordinates));
  });
  geolocation.setTracking(true);
  const vector = new VectorLayer({
    map: map,
    source: new Vector({
      features: [accuracyFeature, positionFeature],
    }),
  });
  vector.setVisible(false);
}

export function useTileLayer(
  map: OlMap,
  zIndex: number,
  visible: boolean,
  createSourceFn: () => TileSource,
  extent: Extent | undefined = undefined
) {
  const layer = useMemo(
    () =>
      new TileLayer({
        source: createSourceFn(),
        extent:
          extent && getWidth(extent) > 0 && getHeight(extent) > 0
            ? transformExtent(extent, 'EPSG:4326', 'EPSG:3857')
            : undefined,
      }),
    [createSourceFn, extent]
  );

  useEffect(() => {
    map.addLayer(layer);
    return () => {
      map.removeLayer(layer);
    };
  }, [map, layer]);

  useEffect(() => {
    layer.setZIndex(zIndex);
  }, [layer, zIndex]);
  useEffect(() => {
    layer.setVisible(visible);
  }, [layer, visible]);
}
