import { useEffect, useState } from 'react';

import { InputSlider } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const StandAloneViewerPointSize = () => {
  const {
    permissions,
    standAloneViewer: {
      actions: { setPointSize },
    },
  } = useGlobalStore();

  const [localPointSize, setLocalPointSize] = useState<number>();

  useEffect(() => {
    if (Object.keys(permissions).length === 0) {
      return;
    }
    const key = permissions[LdFlags.PotreePointSize] as number;
    const defaultValue = Math.round(key ?? 1);
    setLocalPointSize(defaultValue);
    setPointSize(defaultValue);
  }, [permissions]);

  return (
    <div className="py-1 px-4 w-full">
      <InputSlider
        min={0}
        max={100}
        step={1}
        unit=""
        showLabels={true}
        values={[localPointSize ?? 1]}
        onChange={(values) => {
          setLocalPointSize(values[0]);
          setPointSize(values[0]);
        }}
        onFinalChange={(values) => {
          setPointSize(values[0]);
        }}
      />
    </div>
  );
};
