import Groups from './groups';
import { ProjectLoading } from '@agerpoint/feature';
import { ProjectState } from '@agerpoint/types';
import { shallow } from 'zustand/shallow';
import { useProject } from '../../state/use-project';

export default function Content() {
  const { loading, projectId } = useProject(getContentState, shallow);

  return (
    <div className="w-full bg-white flex flex-col">
      {loading ? (
        <ProjectLoading />
      ) : (
        <Groups projectId={projectId?.toString()} />
      )}
    </div>
  );
}

function getContentState({ loading, id: projectId }: ProjectState) {
  return {
    loading,
    projectId,
  };
}
