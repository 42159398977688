import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';

import { entityPredicate } from './cache-utils';

export const searchInfiniteQueriesForInitialValue = <T,>({
  queryClient,
  queryKey,
  id,
  accessor,
  stale = false,
  type = 'all',
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
  id: T[keyof T];
  accessor: keyof T;
  stale?: boolean;
  type?: 'active' | 'all' | 'inactive';
}): T | undefined => {
  const queries = queryClient.getQueriesData<InfiniteData<T[]>>({
    queryKey: queryKey,
    stale: stale,
    type: type,
  });

  for (const [, data] of queries) {
    const pages = data?.pages;
    if (!pages) continue;

    for (const p of pages) {
      const u = p.find((u) => u[accessor] === id);
      if (u) return u;
    }
  }

  return undefined;
};

export const searchQueriesForInitialValue = <T,>({
  queryClient,
  queryKey,
  id,
  accessor,
  stale = false,
  type = 'all',
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
  id: T[keyof T];
  accessor: keyof T;
  stale?: boolean;
  type?: 'active' | 'all' | 'inactive';
}): T | undefined => {
  const queries = queryClient.getQueriesData<T[]>({
    queryKey: queryKey,
    stale: stale,
    type: type,
  });
  for (const [, data] of queries) {
    if (!Array.isArray(data)) continue;
    const u = data?.find((u) => u[accessor] === id);
    if (u) return u;
  }

  return undefined;
};

interface IsearchForInitialValue<T extends object> {
  queryClient: QueryClient;
  entityKey: string;
  id: T[keyof T];
  accessor: string;
  stale?: boolean;
  type?: 'active' | 'all' | 'inactive';
}

export const searchForInitialValue = <T extends object>({
  queryClient,
  entityKey,
  id,
  accessor,
}: IsearchForInitialValue<T>): T | undefined => {
  const queries = queryClient.getQueriesData<T[] | T | InfiniteData<T[]>>({
    predicate: entityPredicate(entityKey),
  });

  for (const [, data] of queries) {
    if (data === undefined || data === null) continue;

    if (data instanceof Array) {
      return data.find(
        (d: T) => accessor in d && d[accessor as keyof T] === id
      );
    }

    if (data instanceof Object) {
      if ('pages' in data && 'pageParams' in data) {
        for (const page of data.pages) {
          return page.find(
            (d: T) => accessor in d && d[accessor as keyof T] === id
          );
        }
        return undefined;
      }

      if (accessor in data && data[accessor as keyof T] === id) {
        return data;
      }
    }
  }

  return undefined;
};
