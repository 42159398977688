import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';

import { APIClient, CaptureObjectCustomAttribute } from '@agerpoint/api';
import { Input } from '@agerpoint/component';
import { getSymbol } from '@agerpoint/utilities';

interface QAQCCaptureObjectAttributeRowProps {
  attribute: APIClient.CaptureCustomAttribute;
}

export const QAQCCaptureAttributeRow = ({
  attribute,
}: QAQCCaptureObjectAttributeRowProps) => {
  const [loadingAttribute, setLoadingAttribute] = useState(false);

  const [localAttribute, setLocalAttribute] =
    useState<CaptureObjectCustomAttribute>();
  useEffect(() => {
    setLocalAttribute(attribute);
  }, [attribute]);

  const updateCaptureCustomAttribute = APIClient.putCaptureCustomAttributeById;
  const validateAttribute = useCallback(async () => {
    if (!localAttribute?.id) return;
    setLoadingAttribute(true);
    try {
      const updatedAttribute = {
        ...localAttribute,
        validated: !localAttribute.validated,
      };
      setLocalAttribute(updatedAttribute);
      delete updatedAttribute.customAttributeUnit;
      delete updatedAttribute.createdById;
      delete updatedAttribute.updatedById;
      delete updatedAttribute.createDatetime;
      delete updatedAttribute.updateDatetime;

      await updateCaptureCustomAttribute(localAttribute.id, updatedAttribute);
    } catch (e) {
      console.error(e);
    }
    setLoadingAttribute(false);
  }, [localAttribute]);

  return (
    <div className="flex flex-row gap-2 items-center py-0.5">
      <span className="w-3 h-3 flex items-center justify-center text-green-300">
        {loadingAttribute ? (
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
            className="w-3 h-3 text-white"
          />
        ) : (
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Input.Checkbox
              id={`attribute-${localAttribute?.id}-validation-checkbox`}
              value={localAttribute?.validated ?? false}
              style={Input.style.miniDark}
              setValue={validateAttribute}
            />
          </span>
        )}
      </span>
      <div className="flex flex-row justify-between gap-1 items-start flex-grow">
        <span>{attribute.attributeName}</span>
        <span className="font-bold text-right">
          <span>{attribute.attributeValue}</span>
          <span>{getSymbol(attribute)}</span>
        </span>
      </div>
    </div>
  );
};
