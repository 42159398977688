import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { APIClient, APIRequests } from '@agerpoint/api';
import { CloudButton } from '@agerpoint/cloud/components';
import { EventBusData, EventBusNames, findLowResModel } from '@agerpoint/types';
import { eventBus, isGaussianJob, useAgerStore } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../captures-viewer';

interface ICaptureImagesGallery {
  captureJob?: APIClient.CaptureJob;
}

export const CaptureImagesGallery = ({ captureJob }: ICaptureImagesGallery) => {
  const { isMobile } = useAgerStore();
  const { captureId } = useParams();
  const { setHighlightedCaptureImage, gallery } = useCapturesViewerContext();

  const captureQuery = APIRequests.Capture.Queries.useGetById({
    captureId: Number(captureId) || undefined,
  });

  const lowResModel = useMemo(
    () => findLowResModel(captureQuery.data),
    [captureQuery.data]
  );

  const lowResCaptureJobImagesQuery =
    APIRequests.CaptureImage.Queries.useGetAllByCaptureJobId({
      captureJobId: lowResModel?.id,
    });

  const captureJobImagesQuery =
    APIRequests.CaptureImage.Queries.useGetAllByCaptureJobId({
      captureJobId: captureJob?.id,
    });

  const useLowResCaptureImages = useMemo(
    () => isGaussianJob(captureJob) && captureJobImagesQuery.data?.length === 0,
    [captureJobImagesQuery.data, captureJob]
  );

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const galleryHeight = 110;

  const handleHighlight = useCallback(
    ({ detail: { id } }: EventBusData) => {
      const image = (
        useLowResCaptureImages
          ? lowResCaptureJobImagesQuery.data
          : captureJobImagesQuery.data
      )?.find((image) => image.id == id);
      if (!image) {
        return;
      }
      setHighlightedCaptureImage?.(image);
    },
    [
      captureJobImagesQuery.data,
      lowResCaptureJobImagesQuery.data,
      setHighlightedCaptureImage,
    ]
  );

  useEffect(() => {
    const eventId = eventBus.on(
      EventBusNames.Point3dLocationMarkerClicked,
      handleHighlight,
      true
    );
    return () => {
      eventBus.remove(
        EventBusNames.Point3dLocationMarkerClicked,
        handleHighlight,
        eventId
      );
    };
  }, []);

  return (
    <AnimatePresence initial={false}>
      {!gallery?.visible && (
        <motion.div
          key={'gallery-button'}
          initial={{ y: -galleryHeight, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -galleryHeight, opacity: 0 }}
          className={`${
            isMobile ? 'fixed' : 'absolute'
          } z-overlay right-4 bottom-4 bg-white shadow-lg rounded-lg p-1`}
        >
          <CloudButton.Icon
            id="capture-images-gallery"
            tooltip="Gallery"
            tooltipPosition="top"
            leadingIcon="gallery-thumbnails"
            onClick={() => {
              gallery?.setVisible?.(true);
            }}
            loading={
              useLowResCaptureImages
                ? lowResCaptureJobImagesQuery.isLoading
                : captureJobImagesQuery.isLoading
            }
            disabled={
              (useLowResCaptureImages
                ? lowResCaptureJobImagesQuery.data?.length ?? 0
                : captureJobImagesQuery.data?.length ?? 0) === 0
            }
          />
        </motion.div>
      )}
      {gallery?.visible && (
        <motion.div
          key={'gallery'}
          initial={{ y: 0, top: '100%', height: `${galleryHeight}px` }}
          animate={{
            y: -galleryHeight,
            top: gallery.expanded ? `${galleryHeight}px` : '100%',
            height: gallery.expanded ? `100%` : `${galleryHeight}px`,
          }}
          exit={{ y: 0, top: '100%', height: `${galleryHeight}px` }}
          className={`${
            isMobile ? 'fixed' : 'absolute'
          } w-full bg-white shadow-lg flex flex-row z-overlay`}
        >
          <div
            className={`flex-grow border-r border-t border-gray-border ${
              gallery.expanded
                ? 'overflow-y-auto overflow-x-hidden'
                : 'overflow-x-auto overflow-y-hidden'
            }`}
            ref={scrollContainerRef}
          >
            <div
              className={`flex flex-row items-start gap-1 p-1 w-fit ${
                gallery.expanded ? 'flex-wrap w-full' : 'h-full'
              }`}
            >
              {(useLowResCaptureImages
                ? lowResCaptureJobImagesQuery.data
                : captureJobImagesQuery.data
              )?.map((image) => (
                <GalleryImage
                  key={image.id}
                  image={image}
                  scrollContainerRef={scrollContainerRef}
                />
              ))}
            </div>
          </div>
          <div className=" flex flex-col border-t border-gray-border p-1 gap-1">
            <CloudButton.Icon
              id="close-gallery"
              tooltip="Close"
              tooltipPosition="top"
              leadingIcon="xmark"
              onClick={() => {
                gallery?.setVisible?.(false);
              }}
              compact
            />
            <CloudButton.Icon
              id="expand-gallery"
              tooltip="Expand"
              tooltipPosition="bottom"
              leadingIcon={gallery?.expanded ? 'chevron-down' : 'chevron-up'}
              onClick={() => {
                gallery?.setExpanded?.(!gallery.expanded);
              }}
              compact
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface IGalleryImage {
  image: APIClient.CaptureImage;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
}

const GalleryImage = ({ image, scrollContainerRef }: IGalleryImage) => {
  const { highlightedCaptureImage, setHighlightedCaptureImage, gallery } =
    useCapturesViewerContext();

  const { isMobile } = useAgerStore();

  const isSelected = useMemo(() => {
    return highlightedCaptureImage?.id === image.id;
  }, [highlightedCaptureImage, image]);

  const [isVisible, setIsVisible] = useState(false);
  const [imageRef, setImageRef] = useState<HTMLDivElement | null>(null);
  const scrollBehavior = useRef<'smooth' | 'instant'>('instant');

  const observer = useMemo(() => {
    return new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (!imageRef) {
      return;
    }

    observer.observe(imageRef as Element);
    return () => {
      observer.disconnect();
    };
  }, [imageRef, observer]);

  const image200Query = APIRequests.CaptureImage.Queries.useGetThumbnailById({
    captureImageId: image?.id,
    enabled: isVisible && !gallery?.expanded,
    size: '200x200',
  });

  const image800Query = APIRequests.CaptureImage.Queries.useGetThumbnailById({
    captureImageId: image?.id,
    enabled: isVisible && gallery?.expanded,
    size: '800x800',
  });

  const imageQuery = useMemo(() => {
    if (gallery?.expanded) {
      return image800Query;
    }
    return image200Query;
  }, [gallery?.expanded, image200Query, image800Query]);

  useEffect(() => {
    if (image?.id !== highlightedCaptureImage?.id) {
      if (scrollBehavior.current === 'instant') {
        scrollBehavior.current = 'smooth';
      }
      return;
    }

    const centerImage =
      (imageRef?.offsetLeft ?? 0) + (imageRef?.offsetWidth ?? 0) / 2;
    const centerContainer = (scrollContainerRef?.current?.offsetWidth ?? 0) / 2;
    // The 16px is the half of right vertical button bar of the gallery to make sure
    // the image is in the center of the screen
    const left = centerImage - centerContainer - 16;

    scrollContainerRef?.current?.scrollTo({
      left,
      behavior: scrollBehavior.current,
    });
  }, [highlightedCaptureImage, image, imageRef, scrollContainerRef]);

  const imageOnClick = useCallback(
    (image: APIClient.CaptureImage) => {
      setHighlightedCaptureImage?.(image);
      eventBus.dispatch(EventBusNames.ImageCarouselImageClicked, {
        detail: { id: image?.id },
      });
    },
    [setHighlightedCaptureImage]
  );

  return (
    <div
      className={`rounded aspect-square relative transition-all ${
        isSelected ? 'ring ring-accent' : ' ring-1 ring-gray-border'
      } `}
      ref={setImageRef}
      style={{
        width: gallery?.expanded
          ? isMobile
            ? 'calc((100% / 3) - 3px)'
            : 'calc((100% / 6) - 3.4px)'
          : undefined,
        height: gallery?.expanded ? undefined : '100%',
      }}
    >
      {imageQuery.isLoading && (
        <div
          className="absolute inset-0 shimmer cursor-pointer"
          onClick={() => {
            imageOnClick(image);
          }}
        ></div>
      )}

      {imageQuery.isSuccess && (
        <>
          <div
            className={`absolute inset-0 z-1 rounded cursor-pointer
            bg-black hover:bg-opacity-20 bg-opacity-0 transition-colors`}
            onClick={() => {
              imageOnClick(image);
            }}
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            src={imageQuery.data}
            alt={`${image.id}`}
            className="object-cover w-full h-full rounded pointer-events-none"
          />
        </>
      )}
    </div>
  );
};
