import compare from 'trivial-compare';

import { APIModels } from '../..';

export const supplementalImages = (data?: APIModels.CaptureImageTag[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) => compare(a.fileName, b.fileName));
  return sorted;
};
