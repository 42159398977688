import { InputIdContext } from '../input-id-context';
import { InputStyle } from '../input-style';

interface InputRadioElementProps {
  id: string;
  disabled?: boolean;
  label?: React.ReactNode;
  error?: React.ReactNode;
  elementValue: string;
  currentValue: string | undefined;
  setValue: (value?: string) => void;
}

export const InputRadioElement = ({
  id,
  disabled,
  label,
  error,
  setValue,
  elementValue,
  currentValue,
}: InputRadioElementProps) => {
  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col">
        <div
          className={`flex flex-row items-center gap-2 ${
            disabled ? 'opacity-50 pointer-events-none' : 'cursor-pointer'
          }`}
          onClick={() => {
            if (disabled) return;
            setValue?.(elementValue);
          }}
        >
          <div className="rounded-full bg-gray-light w-5 h-5 flex items-center justify-center p-1">
            {currentValue === elementValue && (
              <div className="shadow w-full h-full bg-green rounded-full" />
            )}
          </div>
          {label && (
            <div className="select-none pointer-events-none">{label}</div>
          )}
        </div>
        {error ?? null}
      </div>
    </InputIdContext.Provider>
  );
};
