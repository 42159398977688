import { faBars, faGrid2, faMap } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo } from 'react';

import { Input } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  isMobileTablet,
  useGlobalStore,
} from '@agerpoint/utilities';

export type CapturesView = 'Tiles' | 'Map' | 'List';

interface CapturesListViewSelectorProps {
  layout: CapturesView;
  setLayout: (layout: CapturesView) => void;
}

export const CapturesViewSelector = ({
  layout: layoutProp,
  setLayout,
}: CapturesListViewSelectorProps) => {
  const { permissions } = useGlobalStore();

  const isMobile = useMemo(() => isMobileTablet(), []);

  const hasCaptureMapViewPermission = useMemo(
    () => hasPermission(LdFlags.CaptureListMapView, permissions),
    [permissions]
  );

  useEffect(() => {
    if (!hasCaptureMapViewPermission && layoutProp === 'Map') {
      setLayout('List');
    }
  }, [hasCaptureMapViewPermission, layoutProp]);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    const savedLayout = localStorage.getItem(
      'capturesListViewLayout'
    ) as CapturesView | null;

    if (
      savedLayout === 'Tiles' ||
      savedLayout === 'Map' ||
      savedLayout === 'List'
    ) {
      setLayout(savedLayout);
    } else {
      setLayout('List');
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (layoutProp) {
      localStorage.setItem('capturesListViewLayout', layoutProp);
    } else {
      localStorage.removeItem('capturesListViewLayout');
    }
  }, [layoutProp]);

  if (isMobile) {
    return null;
  }

  return (
    <Input.Select.Inline
      id="captures-view-selector"
      value={layoutProp}
      setValue={setLayout}
      options={['Map', 'List', 'Tiles']}
      optionBuilder={(option) => {
        if (option === 'Map') {
          return <FontAwesomeIcon icon={faMap} className="px-1" />;
        }

        if (option === 'List') {
          return <FontAwesomeIcon icon={faBars} className="px-1" />;
        }

        if (option === 'Tiles') {
          return <FontAwesomeIcon icon={faGrid2} className="px-1" />;
        }

        return option;
      }}
    />
  );
};
