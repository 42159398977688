import { Gradient } from '@agerpoint/types';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { LinearGradient } from '../gradients/gradients';

interface GradientSelectProps {
  onChange: (value: Gradient) => void;
  value: Gradient;
  options: Gradient[];
}

export function GradientSelect({
  onChange,
  value,
  options,
}: GradientSelectProps) {
  return (
    <Listbox value={value} onChange={onChange}>
      <Listbox.Label className="text-sm text-gray-800 font-bold">
        Color
      </Listbox.Label>
      <div className="mt-1 relative">
        <Listbox.Button
          className={`
          relative
          w-full
          border
          border-gray-500
          bg-white
          rounded
          shadow-sm
          pl-3
          pr-10
          py-2
          text-left
          cursor-default
          focus:outline-none
          focus:ring-1
          focus:ring-green
          focus:border-green
          leading-3
          text-sm`}
        >
          <LinearGradient colors={value} className="w-36 h-3 rounded-sm" />
          <span
            className={`absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none flex-col justify-center`}
          >
            <FontAwesomeIcon
              className="text-xs text-gray-600 -mb-1"
              icon={faChevronUp}
            />
            <FontAwesomeIcon
              className="text-xs text-gray-600"
              icon={faChevronDown}
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={`
            absolute
            z-10
            mt-1
            w-full
            bg-white
            shadow-lg
            max-h-60
            rounded-md
            py-1
            ring-1
            ring-black
            ring-opacity-5
            overflow-auto
            focus:outline-none
            text-sm
            leading-3`}
          >
            {options.map((option) => (
              <Listbox.Option
                key={option}
                className={({ active, selected }) =>
                  `${active ? 'text-white bg-gray-100' : ''}
                  ${selected ? 'text-white bg-gray-150' : ''}
                    'cursor-default select-none relative py-2 pl-3 pr-3'
                  `
                }
                value={option}
              >
                {({ selected }) => (
                  <div
                    className={`flex justify-between items-center ${
                      selected ? 'bg-gray-150' : ''
                    }`}
                  >
                    <LinearGradient
                      colors={option}
                      className="w-36 h-3 rounded-sm"
                    />
                    {selected && (
                      <FontAwesomeIcon
                        className="text-gray-800 text-lg mr-3"
                        icon={faCheck}
                      />
                    )}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
