/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */

export type OgcGeometryType =
  (typeof OgcGeometryType)[keyof typeof OgcGeometryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OgcGeometryType = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
  NUMBER_11: 11,
  NUMBER_12: 12,
  NUMBER_13: 13,
  NUMBER_14: 14,
  NUMBER_15: 15,
  NUMBER_16: 16,
} as const;
