import { faFilterSlash, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DragStart, DropResult } from 'react-beautiful-dnd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import compare from 'trivial-compare';
import { useDebouncyEffect } from 'use-debouncy';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import {
  IconButton,
  Input,
  SidebarExpansionHeader,
} from '@agerpoint/component';
import { LibraryLayer } from '@agerpoint/types';
import {
  toLibraryLayers,
  useIsViteApp,
  useItemSelection,
} from '@agerpoint/utilities';

import { DatatableDND } from '../datatable/datatable-dnd';
import { dataTableAgerStyle } from '../datatable/datatable-old';
import { LayerTypeIcon } from '../library-item/library-item';
import { AgerDragDropContext, getLayersUniqueId } from './utils';

interface LibraryExpansionProps {
  onCancel?: () => void;
}

export function MultiDNDPaginatedLibraryExpansion({
  onCancel,
}: LibraryExpansionProps) {
  const [filter, setFilter] = useState<APIModels.LibraryFilter>();

  const layerTypesQuery = APIClient.useGetLayerType({
    query: {
      queryKey: [APIUtils.QueryKey.layerTypes],
      select: (data) => data.sort((a, b) => compare(a.id, b.id)),
    },
  });

  const libraryLayersQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.libraryItems,
      APIUtils.QueryKey.infinite,
      { filter },
    ],
    queryFn: ({ pageParam }) =>
      APIClient.getLibraryFiltered(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.LibraryFilter
      ),
    enabled: filter !== undefined,
    getNextPageParam: APIUtils.defaultGetNextPageParamWithTake(60),
    initialPageParam: APIUtils.defaultInitialPageParamWithTake(60),
    select: (library) => ({
      ...library.pageParams,
      pages: library.pages.map((page) => toLibraryLayers(page)),
    }),
    staleTime: APIUtils.getDuration({
      seconds: 20,
    }),
  });

  const libraryLayers = useMemo(() => {
    return libraryLayersQuery.data?.pages.flatMap((page) => page) || [];
  }, [libraryLayersQuery.data]);

  const layerSelection = useItemSelection<string, LibraryLayer>({
    items: libraryLayers,
    dependencies: [libraryLayers.length],
    idField: 'id',
  });

  const [nameFilter, setNameFilter] = useState<string>('');
  const [selectedLayerType, setSelectedLayerType] =
    useState<APIModels.LayerType>();

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        name: nameFilter,
      }));
    },
    500,
    [nameFilter]
  );
  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      name: nameFilter,
      orderBy: 'name',
      orderAscending: true,
    }));
  }, []);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      layerTypeIds: selectedLayerType?.id ? [selectedLayerType.id] : undefined,
    }));
  }, [selectedLayerType]);

  const onWindowKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return;
      }

      if (event.key === 'Escape') {
        layerSelection.clearSelection();
      }
    },
    [layerSelection.selectionSize]
  );

  const onWindowClick = useCallback(
    (event: MouseEvent) => {
      if (event.defaultPrevented) {
        return;
      }
      layerSelection.clearSelection();
    },
    [layerSelection.selectionSize]
  );

  const onWindowTouchEnd = useCallback(
    (event: TouchEvent) => {
      if (event.defaultPrevented) {
        return;
      }

      layerSelection.clearSelection();
    },
    [layerSelection.selectionSize]
  );

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  }, [onWindowClick, onWindowKeyDown, onWindowTouchEnd]);

  const { addEvent, removeEvent, emitCustomEvent } =
    useContext(AgerDragDropContext);

  const onDragStart = useCallback(
    (start: DragStart) => {
      if (start.source.droppableId !== 'library') {
        return;
      }
      const layer: LibraryLayer = JSON.parse(start.draggableId) as LibraryLayer;

      const isSelected = layerSelection.isSelected(layer.id);

      if (!isSelected) {
        layerSelection.clearSelection();
      }
    },
    [layerSelection.selectionSize]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (
        !result.destination?.droppableId?.startsWith('groupLayer') &&
        !result.destination?.droppableId?.startsWith('groupHeader')
      ) {
        return;
      }

      const draggedLayer = JSON.parse(result.draggableId) as LibraryLayer;
      const restOfTheSelection = layerSelection
        .getSelectionArray()
        .filter((l) => l.id !== draggedLayer.id);

      let index = result?.destination?.index;
      restOfTheSelection?.forEach((l) => {
        index = index + 1;
        emitCustomEvent?.({
          droppableId: result.destination?.droppableId as string,
          event: {
            action: 'DRAG_END',
            payload: {
              source: {
                droppableId: 'library',
                index: libraryLayers.indexOf(l),
              },
              destination: {
                droppableId: result?.destination?.droppableId as string,
                index: index,
              },
              draggableId: JSON.stringify({
                id: getLayersUniqueId(l),
                entityId: l.entityId,
                layerTypeId: l.layerTypeId,
                name: l.name,
              }),
            } as DropResult,
          },
        });
      });

      layerSelection.clearSelection();
    },
    [libraryLayers, emitCustomEvent, layerSelection.selectionSize]
  );

  useEffect(() => {
    addEvent?.({
      droppableId: 'library',
      callback: (event) => {
        if (event.action === 'DRAG_START') {
          onDragStart(event.payload as DragStart);
        } else if (event.action === 'DRAG_END') {
          onDragEnd(event.payload as DropResult);
        }
      },
      greedy: true,
    });

    return () => {
      removeEvent?.({ droppableId: 'library' });
    };
  }, [onDragStart, onDragEnd, addEvent, removeEvent]);

  const hasFiltersApplied = useMemo(
    () => !!(filter?.name !== '' || filter?.layerTypeIds !== undefined),
    [filter]
  );

  const clearFilters = useCallback(() => {
    setNameFilter('');
    setSelectedLayerType(undefined);
  }, []);

  const isViteApp = useIsViteApp();
  const [, setSearch] = useSearchParams();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full">
      <SidebarExpansionHeader size="small" onCancel={onCancel}>
        <div className="flex flex-row gap-5 w-full items-center">
          <span className="font-bold w-full">Library</span>
          <IconButton
            show
            onClick={() => {
              if (isViteApp) {
                setSearch(
                  (prev) => {
                    prev.set('upload', 'capture');
                    return prev;
                  },
                  {
                    replace: true,
                  }
                );
              } else {
                navigate('/uploads/new');
              }
            }}
            className={`w-8 h-4`}
            icon={
              <FontAwesomeIcon
                icon={faUpload}
                className="text-lg text-gray-600 hover:text-gray-900 flex items-center justify-center"
              />
            }
          />
        </div>
      </SidebarExpansionHeader>
      <div className="p-1 flex flex-col gap-1">
        <Input.Text.Single
          id="library-name-filter"
          placeholder="Search by Name"
          placeholderIcon={Input.placeholderIcons.search}
          value={nameFilter}
          setValue={setNameFilter}
        />
        <Input.Select.Single
          id="layer-type-filter"
          title="Layer Type"
          placeholder="Search by Layer Type"
          loading={layerTypesQuery.isLoading}
          options={layerTypesQuery.data ?? []}
          optionBuilder={(lt) => ({
            element: (
              <div className="flex flex-row gap-2 items-center">
                <LayerTypeIcon layerId={lt.id} />
                <span>{lt.displayName ?? lt.name ?? 'Unknown'}</span>
              </div>
            ),
            searchString: lt.displayName ?? lt.name ?? 'Unknown',
          })}
          value={selectedLayerType}
          setValue={setSelectedLayerType}
        />
      </div>
      <div className="flex-grow">
        <DatatableDND
          data={libraryLayers}
          cellOnClick={() => {
            return (row, e) => {
              if (e.shiftKey) {
                e.preventDefault();
                layerSelection.addBulkSelectionUntilItem(row.id, row);
              } else if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
                layerSelection.toggleSelection(row.id, row);
              }
            };
          }}
          style={{
            ...dataTableAgerStyle,
            headerWrapperStyle:
              'px-2 text-xs text-gray-700 font-normal border-b border-gray-500',
            rowWrapperStyle: 'px-2 items-center text-sm hover:bg-gray-100',
          }}
          rowHeight={30}
          columns={[
            {
              label: '',
              value: (row) => <LayerTypeIcon layerId={row.layerTypeId} />,
              flex: 0.07,
            },
            {
              label: 'Name',
              value: (row) => {
                const isSelected = layerSelection.isSelected(row.id);

                return (
                  <div
                    className={isSelected ? 'underline decoration-double' : ''}
                  >
                    {row.name}
                  </div>
                );
              },
              name: 'name',
            },
          ]}
          loading={
            libraryLayersQuery.isLoading ||
            libraryLayersQuery.isFetchingNextPage ||
            filter === undefined
          }
          error={
            libraryLayersQuery.isError
              ? {
                  title: 'There was a problem loading library',
                  message: 'Try refreshing the page',
                  action: () => libraryLayersQuery.refetch(),
                }
              : undefined
          }
          draggableUniqueId={(row) =>
            JSON.stringify({
              id: getLayersUniqueId(row),
              entityId: row.entityId,
              layerTypeId: row.layerTypeId,
              name: row.name,
            })
          }
          droppableId="library"
          multiSelectCount={layerSelection.selectionSize}
          isDropDisabled={true}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching library items',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No library items yet',
                  message: 'Upload data to get started',
                }
          }
          pagination={{
            threshold: 10,
            loadNextPage: () => {
              if (
                !libraryLayersQuery.hasNextPage ||
                libraryLayersQuery.isFetchingNextPage ||
                libraryLayersQuery.isLoading ||
                filter === undefined
              ) {
                return;
              }
              libraryLayersQuery.fetchNextPage();
            },
          }}
        />
      </div>
    </div>
  );
}
