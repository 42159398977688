import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { EnvironmentTypes } from '@agerpoint/types';
import { getCurrentEnvironment } from '@agerpoint/utilities';

interface SentrySetupProps {
  children: JSX.Element;
  appVersion?: string;
}

export const SentrySetup = ({ children, appVersion }: SentrySetupProps) => {
  useEffect(() => {
    const env = getCurrentEnvironment();
    if ([EnvironmentTypes.Localhost].includes(env)) {
      return;
    }
    Sentry.init({
      dsn: 'https://d257a3b726bd49cf860ba59c212a111b@o4504255577653248.ingest.sentry.io/4504288150683648',
      integrations: [
        new Sentry.BrowserTracing({
          // routingInstrumentation: Sentry.reactRouterV6Instrumentation(),

          tracePropagationTargets: [
            'localhost',
            /^https:\/\/cloud-dev\.agerpoint\.com/,
            /^https:\/\/cloud-stage\.agerpoint\.com/,
            /^https:\/\/cloud\.agerpoint\.com/,
          ],
        }),
      ],
      release: appVersion,
      tracesSampleRate: 0.2,
      enableTracing: true,
      environment: env,
    });
  }, []);

  return children;
};
