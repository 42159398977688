import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { IconButton } from '@agerpoint/component';

interface EllipsisIconButton {
  show: boolean;
  onClick: () => void;
  className: string;
}

export function EllipsisIconButton({
  show,
  onClick,
  className,
}: EllipsisIconButton) {
  return (
    <IconButton
      className={className}
      show={show}
      onClick={onClick}
      icon={<FontAwesomeIcon icon={faEllipsisH} className="text-gray" />}
    />
  );
}
