import {
  InfiniteData,
  Query,
  QueryClient,
  QueryKey,
} from '@tanstack/react-query';

import { APIUtils } from '..';

export const entityPredicate =
  (entityKey: string) => (query: Query<unknown, Error, unknown, QueryKey>) => {
    const queryKey = query.queryKey;

    // check if it's a simple query (the last element in array is entity key)
    if (queryKey[queryKey.length - 1] === entityKey) {
      return true;
    }

    // check if it's a filtered query (the second last element in array is entity key and the last element is filter)
    if (queryKey.length >= 2 && queryKey[queryKey.length - 2] === entityKey) {
      return true;
    }

    // check if it's a infinite query (the third last element in array is entity key and the second to last item is infinite keyword)
    if (
      queryKey.length >= 3 &&
      queryKey[queryKey.length - 2] === APIUtils.QueryKey.infinite &&
      queryKey[queryKey.length - 3] === entityKey
    ) {
      return true;
    }

    return false;
  };

interface IinvalidateEntity {
  queryClient: QueryClient;
  entityKey: string;
}

export const invalidateEntity = ({
  queryClient,
  entityKey,
}: IinvalidateEntity) => {
  queryClient.invalidateQueries({
    predicate: entityPredicate(entityKey),
  });
};

interface IupdateCachedEntity<T> {
  queryClient: QueryClient;
  entityKey: string;
  accessor: keyof T;
  data: T;
}

type CachedEntityQuery<T> = T | T[] | InfiniteData<T[]>;

export const updateCachedEntity = <T,>({
  queryClient,
  entityKey,
  accessor,
  data,
}: IupdateCachedEntity<T>) => {
  queryClient.setQueriesData<CachedEntityQuery<T>>(
    { predicate: entityPredicate(entityKey) },
    (oldData) => {
      if (oldData == null || oldData === undefined) {
        return oldData;
      }

      if (oldData instanceof Array) {
        return oldData.map((d) => (d[accessor] === data[accessor] ? data : d));
      }

      if (oldData instanceof Object) {
        if ('pages' in oldData && 'pageParams' in oldData) {
          return {
            ...oldData,
            pages: oldData.pages.map((page) =>
              page.map((d) => (d[accessor] === data[accessor] ? data : d))
            ),
          };
        }

        if (oldData[accessor] === data[accessor]) {
          return data;
        }
      }

      return oldData;
    }
  );
};

interface IaddEntityToCache<T> {
  queryClient: QueryClient;
  queryKey: QueryKey;
  data: T;
  exactQueryKey?: boolean;
}

export const addEntityToCache = <T,>({
  queryClient,
  queryKey,
  data,
  exactQueryKey = true,
}: IaddEntityToCache<T>) => {
  queryClient.setQueriesData<CachedEntityQuery<T>>(
    { queryKey, exact: exactQueryKey },
    (oldData) => {
      if (oldData == null || oldData === undefined) {
        return data;
      }

      if (oldData instanceof Array) {
        return [...oldData, data];
      }

      return oldData;
    }
  );
};

interface IremoveEntityFromCache<T> {
  queryClient: QueryClient;
  queryKey: QueryKey;
  accessor: keyof T;
  id: T[keyof T];
  exactQueryKey?: boolean;
}

export const removeEntityFromCache = <T,>({
  queryClient,
  queryKey,
  accessor,
  id,
  exactQueryKey = true,
}: IremoveEntityFromCache<T>) => {
  queryClient.setQueriesData<CachedEntityQuery<T>>(
    { queryKey, exact: exactQueryKey },
    (oldData) => {
      if (oldData == null || oldData === undefined) {
        return oldData;
      }

      if (oldData instanceof Array) {
        return oldData.filter((d) => d[accessor] !== id);
      }

      return oldData;
    }
  );
};
