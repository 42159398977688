/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { JobType } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getJobType = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<JobType[]>(
    { url: `/api/JobTypes`, method: 'GET', signal },
    options
  );
};

export const getGetJobTypeQueryKey = () => {
  return [`/api/JobTypes`] as const;
};

export const getGetJobTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobType>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getJobType>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetJobTypeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobType>>> = ({
    signal,
  }) => getJobType(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getJobType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetJobTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getJobType>>
>;
export type GetJobTypeQueryError = ErrorType<void>;

export const useGetJobType = <
  TData = Awaited<ReturnType<typeof getJobType>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getJobType>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetJobTypeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postJobType = (
  jobType: BodyType<JobType>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<JobType>(
    {
      url: `/api/JobTypes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: jobType,
    },
    options
  );
};

export const getPostJobTypeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postJobType>>,
    TError,
    { data: BodyType<JobType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postJobType>>,
  TError,
  { data: BodyType<JobType> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postJobType>>,
    { data: BodyType<JobType> }
  > = (props) => {
    const { data } = props ?? {};

    return postJobType(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostJobTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postJobType>>
>;
export type PostJobTypeMutationBody = BodyType<JobType>;
export type PostJobTypeMutationError = ErrorType<void>;

export const usePostJobType = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postJobType>>,
    TError,
    { data: BodyType<JobType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postJobType>>,
  TError,
  { data: BodyType<JobType> },
  TContext
> => {
  const mutationOptions = getPostJobTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getJobTypeById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<JobType>(
    {
      url: `/api/JobTypes/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetJobTypeByIdQueryKey = (id: number) => {
  return [`/api/JobTypes/${id}`] as const;
};

export const getGetJobTypeByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobTypeById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getJobTypeById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetJobTypeByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobTypeById>>> = ({
    signal,
  }) => getJobTypeById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getJobTypeById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetJobTypeByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getJobTypeById>>
>;
export type GetJobTypeByIdQueryError = ErrorType<void>;

export const useGetJobTypeById = <
  TData = Awaited<ReturnType<typeof getJobTypeById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getJobTypeById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetJobTypeByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putJobTypeById = (
  id: number,
  jobType: BodyType<JobType>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<JobType>(
    {
      url: `/api/JobTypes/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: jobType,
    },
    options
  );
};

export const getPutJobTypeByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putJobTypeById>>,
    TError,
    { id: number; data: BodyType<JobType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putJobTypeById>>,
  TError,
  { id: number; data: BodyType<JobType> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putJobTypeById>>,
    { id: number; data: BodyType<JobType> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putJobTypeById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutJobTypeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putJobTypeById>>
>;
export type PutJobTypeByIdMutationBody = BodyType<JobType>;
export type PutJobTypeByIdMutationError = ErrorType<void>;

export const usePutJobTypeById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putJobTypeById>>,
    TError,
    { id: number; data: BodyType<JobType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putJobTypeById>>,
  TError,
  { id: number; data: BodyType<JobType> },
  TContext
> => {
  const mutationOptions = getPutJobTypeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
