import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';

import { Intermediate } from '@agerpoint/feature';
import { MixpanelNames } from '@agerpoint/types';
import {
  environment,
  useAgerStore,
  useGlobalStore,
} from '@agerpoint/utilities';

interface MixpanelSetupProps {
  children: JSX.Element;
  appVersion?: string;
}

export const MixpanelSetup = ({ children, appVersion }: MixpanelSetupProps) => {
  const {
    user,
    actions: { sendEvent },
  } = useGlobalStore();
  const [mixPanelInitialized, setMixPanelInitialized] = useState(false);
  useEffect(() => {
    // mixpanel.init(environment.mixpanel_token);
    mixpanel.init('66d58006cc5ce52452d5dfad261b64d2');
    if (window.location.hostname === 'localhost') {
      mixpanel.disable();
    }
  }, []);

  useEffect(() => {
    if (user && user?.id && !mixPanelInitialized) {
      sendEvent(MixpanelNames.AppInitialized, {
        app: 'Legacy',
      });
      mixpanel.reset();
      mixpanel.identify(user.id);
      mixpanel.people.set({ Email: user.email });
      mixpanel.people.set({ 'App Version': appVersion });
      mixpanel.people.set({
        'First Name': user?.userProfiles?.[0].firstName || '',
      });
      mixpanel.people.set({
        'Last Name': user?.userProfiles?.[0].lastName || '',
      });

      setMixPanelInitialized(true);
    }
  }, [user, mixPanelInitialized, sendEvent]);

  return children;
};

export const MixpanelCloudSetup = ({ children }: MixpanelSetupProps) => {
  const {
    user: { userInfo },
  } = useAgerStore();

  const [mixPanelInitialized, setMixPanelInitialized] = useState(false);
  useEffect(() => {
    mixpanel.init('66d58006cc5ce52452d5dfad261b64d2');
    if (window.location.hostname === 'localhost') {
      mixpanel.disable();
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo?.id && !mixPanelInitialized) {
      mixpanel.reset();
      mixpanel.identify(userInfo.id);
      mixpanel.people.set({ Email: userInfo.email });
      mixpanel.people.set({ 'App Version': environment.app_version });
      mixpanel.people.set({
        'First Name': userInfo?.userProfiles?.[0].firstName || '',
      });
      mixpanel.people.set({
        'Last Name': userInfo?.userProfiles?.[0].lastName || '',
      });
      setMixPanelInitialized(true);
    }
  }, [userInfo, mixPanelInitialized]);

  if (!mixPanelInitialized) {
    return <Intermediate message="Initializing event tracker ..." />;
  }

  return children;
};

export const MixpanelShareSetup = ({ children }: MixpanelSetupProps) => {
  const [mixPanelInitialized, setMixPanelInitialized] = useState(false);

  useEffect(() => {
    const key = process.env.NX_MIXPANEL_TOKEN;
    mixpanel.init(key || '');
    if (window.location.hostname === 'localhost') {
      mixpanel.disable();
    }
  }, []);

  useEffect(() => {
    if (!mixPanelInitialized) {
      mixpanel.reset();
      mixpanel.people.set({ 'App Version': environment.app_version });
      setMixPanelInitialized(true);
    }
  }, [mixPanelInitialized]);

  if (!mixPanelInitialized) {
    return <Intermediate message="Initializing event tracker ..." />;
  }

  return children;
};
