import {
  faCircleNotch,
  faFilterSlash,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { usePageTitle, useQueryState } from '@agerpoint/utilities';

import { useAdminOrganizationsQueries } from './admin-organizations-queries';

export const AdminOrganizationsList = () => {
  const navigate = useNavigate();

  const [nameAndUuidFilter, setNameAndUuidFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v,
    toUrlParam: (v) => v,
  });

  usePageTitle(() => 'Platform - Organizations', []);

  const { organizationsQuery, regionsLookupTable } =
    useAdminOrganizationsQueries();

  const filteredOrganizations = useMemo(() => {
    let data = organizationsQuery.data;
    if (data === null) {
      return;
    }

    const filter = nameAndUuidFilter.trim().toLowerCase();
    if (filter !== '') {
      data = data
        ?.filter(
          (d) =>
            d.customerName?.toLowerCase().includes(filter) ||
            d.customerUuid?.toLowerCase().includes(filter)
        )
        .sort((a, b) => {
          if (!a?.customerName || !b?.customerName) return 0;
          return a.customerName?.localeCompare(b.customerName);
        });
    }

    return data;
  }, [nameAndUuidFilter, organizationsQuery]);

  const clearFilters = useCallback(() => {
    setNameAndUuidFilter('');
  }, []);

  const hasFiltersApplied = useMemo(
    () => !!((nameAndUuidFilter?.length ?? 0) > 0),
    [nameAndUuidFilter]
  );

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="px-4 py-2 flex flex-row gap-2 justify-between items-center">
        <h1 className="text-3xl font-bold">Organizations</h1>
        <Button.Primary
          id="new-organization-button"
          label="New Organization"
          icon={faPlus}
          onClick={() => {
            navigate('new', {
              state: {
                params: window.location.search,
              },
            });
          }}
        />
      </div>
      <div className="px-4 flex flex-row gap-2 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="org-name-filter"
            setValue={setNameAndUuidFilter}
            value={nameAndUuidFilter}
            placeholder="Search by Name or ID"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 h-full w-full">
        <Datatable
          id="admin-organizations-datatable"
          data={filteredOrganizations || []}
          rowHeight={50}
          loading={organizationsQuery.isLoading}
          style={{ ...dataTableAgerStyle, tableMinWidth: 850 }}
          cellOnClick={() => {
            return (row) => {
              navigate(`${row.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching organizations',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No organizations yet',
                  message: 'Create organization to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Organization',
                }
          }
          error={
            organizationsQuery.isError
              ? {
                  title: 'There was a problem loading organizations',
                  message: 'Try refreshing the page',
                  action: () => organizationsQuery.refetch(),
                }
              : undefined
          }
          columns={[
            { label: 'Name', value: (row) => row.customerName, name: 'name' },
            {
              label: 'Display Name',
              value: (row) => row.customerDisplayName,
              name: 'displayName',
            },
            {
              label: 'Region',
              value: (row) => {
                if (!row.regionId) {
                  return null;
                }

                if (!regionsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const region = regionsLookupTable[row.regionId];

                return region?.regionName ?? null;
              },
              name: 'region',
            },
            {
              label: 'UUID',
              value: (row) => row.customerUuid,
              name: 'uuid',
            },
          ]}
        />
      </div>
    </div>
  );
};
