import { PerspectiveCamera, Scene, Vector3 } from 'three';

import {
  Annotation2dPoints,
  CaptureObjectGenericAttributes,
  EventBusNames,
  GeomType,
  HexColor,
  IAnnotations2d,
  IMultiPoint2d,
  IPoint2d,
  MarkerObjOptions,
} from '@agerpoint/types';
import { getNextAnnotationColor } from '@agerpoint/utilities';

import { AnnotationsDispatcher } from '../annotations-3d/annotations-dispatcher';
import { AnnotationBase } from '../annotations.base';
import { getCoordinates } from '../utility/annotations-utility';
import { MultiPoint2d } from './multi-point-2d';
import { Point2d } from './point-2d';

export class Annotations2d extends AnnotationBase implements IAnnotations2d {
  public annoMgr: AnnotationsDispatcher;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    isPotree: boolean,
    isReadOnly = false,
    canvas: HTMLCanvasElement
  ) {
    super(scene, perspectiveCamera, isPotree, isReadOnly, canvas);
    this.annoMgr = new AnnotationsDispatcher();
  }

  public add2dPoint(
    eventName: EventBusNames,
    pointId: string,
    position: Vector3,
    options: MarkerObjOptions
  ) {
    const existingPoint = AnnotationBase.annoStore.getPoint2dById(pointId);
    if (existingPoint) {
      AnnotationBase.annoStore.removePoint2dById(pointId);
    }

    if (this.canvas) {
      const pnt = new Point2d(
        eventName,
        pointId,
        position,
        options,
        this.scene,
        this.perspectiveCamera,
        this.canvas,
        this.isPotree
      );
      pnt.on('click', (id) => {
        this.emit('click', id);
      });
      pnt.on('mousedown', (id) => {
        this.emit('mousedown', id);
      });
      pnt.on('mouseup', (id) => {
        this.emit('mouseup', id);
      });
      AnnotationBase.annoStore.addPoint2d(pnt);
    }
    this.notifyListeners();
  }

  public add2dMultiPoint(
    id: string,
    points: Vector3[],
    coAttrs: CaptureObjectGenericAttributes
  ) {
    if (!id) return;
    const existingMultiPoint = AnnotationBase.annoStore.getMultiPoint2dById(id);
    if (existingMultiPoint) {
      AnnotationBase.annoStore.removeMultiPoint2dById(id);
    }

    if (!this.canvas) return;
    const multiPoint = new MultiPoint2d(
      EventBusNames.Annotation2dMultiPointLocation,
      id,
      this.scene,
      this.perspectiveCamera,
      this.canvas,
      this.isPotree,
      this.isReadOnly,
      coAttrs
    );
    multiPoint.addPoints(points);
    multiPoint.show();
    multiPoint.updateVisibility(true);

    multiPoint.on('click', (id) => {
      this.emit('click', id);
    });
    multiPoint.on('mousedown', (id) => {
      this.emit('mousedown', id);
    });
    multiPoint.on('mouseup', (id) => {
      this.emit('mouseup', id);
    });
    AnnotationBase.annoStore.addMultiPoint2d(multiPoint);
    this.notifyListeners();
  }

  public getNew2dPointId() {
    return AnnotationBase.annoStore.getNewPoint2dId();
  }
  public getNew2dMultiPointId() {
    return AnnotationBase.annoStore.getNewMultiPoint2dId();
  }
  public get2dPoints() {
    return AnnotationBase.annoStore.getPoints2d();
  }
  public get2dMultiPoints() {
    return AnnotationBase.annoStore.getMultiPoints2d();
  }
  public get2dPointById(id: string): IPoint2d | undefined {
    return AnnotationBase.annoStore.getPoint2dById(id);
  }
  public get2dMultiPointById(id: string): IMultiPoint2d | undefined {
    return AnnotationBase.annoStore.getMultiPoint2dById(id);
  }
  public get2dMultiPointByChildId(id: string): IMultiPoint2d | undefined {
    return AnnotationBase.annoStore.getMultiPoint2dByChildId(id);
  }
  public getNext2dPointColor(): HexColor {
    const lastPnt = AnnotationBase.annoStore.getLastPoint2d();
    return getNextAnnotationColor(lastPnt?.color);
  }
  public getNext2dMultiPointColor(): HexColor {
    const lastPnt = AnnotationBase.annoStore.getLastMultiPoint2d();
    return getNextAnnotationColor(lastPnt?.color);
  }

  public remove2dPoints() {
    AnnotationBase.annoStore.removeAllPoints2d();
    this.notifyListeners();
  }
  public remove2dPointById(id: string) {
    AnnotationBase.annoStore.removePoint2dById(id);
    this.notifyListeners();
  }
  public remove2dMultiPoints() {
    AnnotationBase.annoStore.removeAllMultiPoints2d();
    this.notifyListeners();
  }
  public remove2dMultiPointById(id: string) {
    AnnotationBase.annoStore.removeMultiPoint2dById(id);
    this.notifyListeners();
  }

  public removeLastPointFrom2dMultiPoint(id: string) {
    const obj = AnnotationBase.annoStore.getMultiPoint2dById(id);
    if (obj) {
      obj.removeLastPoint();
    }
  }

  render() {
    AnnotationBase.annoStore.render2dPoints();
    AnnotationBase.annoStore.render2dMultiPoints();
  }

  destroy() {
    this.remove2dPoints();
    this.remove2dMultiPoints();
    super.destroy();
  }

  /**
   * Highlight a point annotation.
   * @param {string} id - The ID of the point.
   */
  public highlight2dPointById(id: string) {
    this.unHighlightAll();
    AnnotationBase.annoStore.highlightPoint2dById(id);
    this.setSelectedObject(id, Annotation2dPoints.AnnotationPoint2d);
    this.notifyListeners();
  }

  /**
   * Save a point annotation.
   * @param {string} id - The ID of the point.
   */
  public savePoint(id: string) {
    const obj = AnnotationBase.annoStore.getPoint2dById(id);
    if (!obj) return;
    obj.updateType(Annotation2dPoints.AnnotationPoint2d);
    this.annoMgr.createCapObj(obj, GeomType.Point);
    this.notifyListeners();
  }

  public saveMultiPoint(id: string) {
    const obj = AnnotationBase.annoStore.getMultiPoint2dById(id);
    if (!obj) return;
    obj.updateType(Annotation2dPoints.AnnotationMultiPoint2d);
    this.annoMgr.createCapObj(obj, GeomType.MultiPoint);
    this.notifyListeners();
  }

  /**
   * Update existing point geometry
   */
  public update2dPointPosition(id: string, position: Vector3) {
    const obj = AnnotationBase.annoStore.getPoint2dById(id);
    if (!obj) return;
    obj.updatePosition(position);
  }

  public updateMultiPointChildPosition(id: string, position: Vector3) {
    // child's id
    const parent = AnnotationBase.annoStore.getMultiPoint2dByChildId(id);
    if (!parent) return;
    const child = parent?.getChildById(id);
    if (!child) return;
    child.updatePosition(position);
  }

  public updatePending2dMultiPointPositions(id: string, positions: Vector3[]) {
    const obj = AnnotationBase.annoStore.getMultiPoint2dById(id);
    if (!obj) return;
    obj.updatePositionByIndex(
      positions.length - 1,
      positions[positions.length - 1]
    );
  }

  public save2dPointPosition(id: string) {
    const obj = AnnotationBase.annoStore.getPoint2dById(id);
    if (!obj) return;
    obj.updateType(Annotation2dPoints.AnnotationPoint2d);
    this.saveUpdatedPosition(obj);
  }

  public save2dMultiPointPosition(childId: string) {
    const obj = AnnotationBase.annoStore.getMultiPoint2dByChildId(childId);
    if (!obj) return;
    obj.updateType(Annotation2dPoints.AnnotationMultiPoint2d);
    this.saveUpdatedPosition(obj);
  }

  public highlight2dMultiPointById(id: string) {
    this.unHighlightAll();
    AnnotationBase.annoStore.highlightMultiPoint2dById(id);
    this.setSelectedObject(id, Annotation2dPoints.AnnotationMultiPoint2d);
    this.notifyListeners();
  }

  public destroyGeometry() {
    this.remove2dPoints();
    this.remove2dMultiPoints();
  }

  private saveUpdatedPosition(obj: IPoint2d | IMultiPoint2d) {
    if (!obj) return;
    let coordinates;
    if (obj.type === Annotation2dPoints.AnnotationPoint2d) {
      coordinates = getCoordinates(GeomType.Point, obj);
    }
    if (obj.type === Annotation2dPoints.AnnotationMultiPoint2d) {
      coordinates = getCoordinates(GeomType.MultiPoint, obj);
    }
    if (!coordinates) return;
    this.annoMgr.updateCapObjPosition(obj.uniqueId, coordinates);
  }
}
