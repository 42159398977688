import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Vector2 } from 'three';
import compare from 'trivial-compare';

import { APIClient, APIUtils } from '@agerpoint/api';
import { CloudButton, CloudInput } from '@agerpoint/cloud/components';
import {
  CaptureJobTypes,
  IGs3dViewerController,
  IGsCloudToolState,
  ILine3d,
  IMultiPoint2d,
  IMultiPoint3d,
  IPoint2d,
  IPoint3d,
} from '@agerpoint/types';
import { useAgerStore } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../captures-viewer';

interface IGS3DCloudTools {
  viewerController?: IGs3dViewerController;
  shared?: boolean;
}

export const Gs3DCloudTools = ({
  viewerController,
  shared,
}: IGS3DCloudTools) => {
  const [, setSearch] = useSearchParams();

  const {
    gsCloudToolState,
    setGsCloudToolState,
    annotations3dGeometry,
    annotations2dGeometry,
    selectedCaptureJob,
  } = useCapturesViewerContext();
  const [toggleAll3dAnnotations, setToggleAll3dAnnotations] = useState(true);
  const { isMobile } = useAgerStore();

  const [annotations, setAnnotations] = useState<
    (IPoint3d | ILine3d | IMultiPoint3d | IPoint2d | IMultiPoint2d)[]
  >([]);

  useEffect(() => {
    const combinedAnnotations = [
      ...(annotations3dGeometry?.points ?? []),
      ...(annotations3dGeometry?.lines ?? []),
      ...(annotations3dGeometry?.polygons ?? []),
      ...(annotations3dGeometry?.multiPoints ?? []),
      ...(annotations2dGeometry?.points ?? []),
      ...(annotations2dGeometry?.multiPoints ?? []),
    ];

    setAnnotations(combinedAnnotations);
  }, [annotations3dGeometry, annotations2dGeometry]);

  useEffect(() => {
    const allNotVisible = annotations.every(
      (annotation) => !annotation.isVisible
    );
    if (allNotVisible) {
      setToggleAll3dAnnotations(!allNotVisible);
    } else {
      setToggleAll3dAnnotations(true);
    }
  }, [annotations]);

  const { captureId } = useParams();

  // Hack: For now, we're trying to get Low Res Camera Poses for Gaussian Splat Visualization
  const captureQuery = APIClient.useGetCaptureById(Number(captureId), {
    query: {
      enabled: Number.isSafeInteger(Number(captureId)),
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
    },
  });

  const lowResModel = useMemo(
    () =>
      captureQuery.data?.completedJobs?.find(
        (j) =>
          j.captureJobTypeId === CaptureJobTypes['Low Resolution'] &&
          j.archived === false &&
          j.eptPointcloudId !== null &&
          (j.status === 'Completed' || j.status === '' || j.status === null)
      ),
    [captureQuery.data]
  );

  const lowResCaptureJobImagesQuery =
    APIClient.useGetCaptureImagesByCaptureJobId(Number(lowResModel?.id), {
      query: {
        enabled:
          Number.isSafeInteger(Number(lowResModel?.id ?? undefined)) && !shared,
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(lowResModel?.id),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data
            ?.filter((image) => image.x && image.y && image.z && image.id)
            ?.sort((a, b) => compare(a?.id, b?.id)),
      },
    });

  const captureJobImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(selectedCaptureJob?.id),
    {
      query: {
        enabled:
          Number.isSafeInteger(Number(selectedCaptureJob?.id ?? undefined)) &&
          !shared,
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(selectedCaptureJob?.id),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data
            ?.filter((image) => image.x && image.y && image.z && image.id)
            ?.sort((a, b) => compare(a?.id, b?.id)),
      },
    }
  );

  const hasImages = useMemo(() => {
    if ((captureJobImagesQuery.data?.length ?? 0) > 0) {
      return true;
    }

    if ((lowResCaptureJobImagesQuery.data?.length ?? 0) > 0) {
      return true;
    }

    return false;
  }, [lowResCaptureJobImagesQuery.data, captureJobImagesQuery.data]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady || !captureQuery.data) {
      return;
    }

    viewerController?.setCaptureMetadata(captureQuery.data);
  }, [captureQuery.data]);

  const loadCameraPositions = useCallback(() => {
    let captureJobImages: APIClient.CaptureImage[] | undefined = undefined;
    if (captureJobImagesQuery.data === undefined) {
      return;
    } else if (captureJobImagesQuery.data.length > 0) {
      captureJobImages = captureJobImagesQuery.data;
    } else if (lowResCaptureJobImagesQuery.data === undefined) {
      return;
    } else if (lowResCaptureJobImagesQuery.data.length === 0) {
      return;
    } else {
      captureJobImages = lowResCaptureJobImagesQuery.data;
    }
    viewerController?.loadCameraPositions(captureJobImages);
  }, [captureJobImagesQuery.data, lowResCaptureJobImagesQuery.data]);

  useEffect(() => {
    if (
      !viewerController?.info?.viewerReady ||
      !viewerController?.info?.captureMetadata
    ) {
      return;
    }
    loadCameraPositions();
  }, [
    lowResCaptureJobImagesQuery.data,
    captureJobImagesQuery.data,
    viewerController?.info?.viewerReady,
    viewerController?.info?.captureMetadata,
  ]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady) {
      return;
    }
    if (viewerController?.info?.cameraPositionsVisible) {
      viewerController?.threeViewer?.showImageMarkers();
    } else {
      viewerController?.threeViewer?.hideImageMarkers();
    }
  }, [loadCameraPositions]);

  const updateToolState = useCallback(
    (key: keyof IGsCloudToolState, value: boolean | Vector2) => {
      if (!viewerController?.info?.viewerReady) return;
      const newState = {
        ...gsCloudToolState,
        [key]: value,
      };
      setGsCloudToolState?.(newState);
    },
    [setGsCloudToolState, gsCloudToolState, viewerController]
  );

  const floatingButtonClassName =
    'bg-white shadow-lg rounded-lg flex flex-row p-1 gap-1 items-center';

  return (
    <div
      className="absolute top-4 left-4 flex flex-row gap-2"
      style={{
        zIndex: 2,
      }}
    >
      {!shared && (
        <div className={floatingButtonClassName}>
          <CloudInput.Toggle
            mobileViewMode={isMobile}
            disabled={!hasImages}
            id="camera-positions-toggle"
            leadingIcon="camera"
            tooltip="Toggle Camera Positions"
            tooltipPosition="bottom"
            value={
              hasImages &&
              (viewerController?.info.cameraPositionsVisible ?? false)
            }
            setValue={() => {
              if (!hasImages) {
                return;
              }
              viewerController?.setCameraPositionsVisible?.(
                !viewerController.info.cameraPositionsVisible
              );
            }}
          />
        </div>
      )}
      {viewerController?.info.viewerReady && (annotations?.length ?? 0) > 0 && (
        <div className={floatingButtonClassName}>
          <CloudInput.Toggle
            mobileViewMode={isMobile}
            disabled={!annotations?.length}
            id="annotations-toggle"
            leadingIcon="location-dot"
            tooltip="Show Annotations"
            tooltipPosition="bottom"
            value={toggleAll3dAnnotations}
            setValue={() => {
              setToggleAll3dAnnotations(!toggleAll3dAnnotations);
              viewerController?.toggleAll3dAnnotations?.(
                !toggleAll3dAnnotations
              );
              if (!toggleAll3dAnnotations && !shared) {
                setSearch(
                  (prev) => {
                    prev.set('details', 'annotations');
                    return prev;
                  },
                  {
                    replace: true,
                  }
                );
              }
            }}
          />
        </div>
      )}

      {!shared && (
        <div className={floatingButtonClassName}>
          <CloudButton.Icon
            id="model-material-toggle-gaussian-splats"
            toggled={gsCloudToolState?.showSplats}
            onClick={() => {
              updateToolState('showSplats', true);
            }}
            leadingIcon="cubes"
            {...(isMobile
              ? {
                  tooltip: 'Gaussian',
                  tooltipPosition: 'bottom',
                }
              : { label: 'Gaussian' })}
          />
          <CloudButton.Icon
            id="model-material-toggle-gaussian-points"
            toggled={!gsCloudToolState?.showSplats}
            onClick={() => {
              updateToolState('showSplats', false);
            }}
            leadingIcon="chart-scatter-3d"
            {...(isMobile
              ? {
                  tooltip: 'Point Cloud',
                  tooltipPosition: 'bottom',
                }
              : { label: 'Point Cloud' })}
          />
        </div>
      )}

      {!(
        isMobile ||
        shared ||
        viewerController?.info?.cropBoxEditingActive
      ) && (
        <div className={floatingButtonClassName}>
          <CloudButton.Icon
            id="model-crop-button"
            onClick={() => {
              viewerController?.toggleCropEditingTool();
            }}
            disabled={!viewerController?.info?.sceneLoaded}
            leadingIcon="crop"
            tooltip="Crop"
            tooltipPosition="bottom"
          />
        </div>
      )}
    </div>
  );
};
