import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminBlocksQueries } from './admin-blocks-queries';

export const AdminBlocksDetails = () => {
  usePageTitle(() => 'Platform - Blocks', []);

  const isViteApp = useIsViteApp();

  const formValidation = useFormValidation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const [selectedFarm, setSelectedFarm] = useState<
    APIModels.Farm | undefined
  >();

  const [block, setBlock] = useState<Partial<APIModels.Block> | undefined>();

  const {
    blockQuery,
    farmsQuery,
    regionsQuery,
    blockPutMutation,
    organizationQuery,
    farmsLookupTable,
    regionsLookupTable,
  } = useAdminBlocksQueries();

  useEffect(() => {
    setSelectedFarm(
      farmsQuery.data?.find((f) => f.id === blockQuery.data?.farmId)
    );
  }, [blockQuery.data, farmsQuery.data]);

  useEffect(() => {
    setBlock((prev) => ({
      ...prev,
      farmId: selectedFarm?.id,
    }));
  }, [selectedFarm]);

  useEffect(() => {
    setBlock(blockQuery.data);
  }, [blockQuery.data]);

  const updateBlock = useCallback(async () => {
    if (blockPutMutation.isPending || !block?.id || blockQuery.isLoading) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    blockPutMutation.mutate({
      id: block.id,
      data: block as APIModels.Block,
    });
  }, [formValidation, blockPutMutation, block, blockQuery.isLoading]);

  const farm = blockQuery.data?.farmId
    ? farmsLookupTable?.[blockQuery.data?.farmId]
    : undefined;
  const region = farm?.regionId
    ? regionsLookupTable?.[farm.regionId]
    : undefined;

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Blocks',
              path: isViteApp ? '/app/admin/platform/blocks' : '/admin/blocks',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="blocks-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/blocks' + params);
            } else {
              navigate('/admin/blocks' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">
          {blockQuery?.data?.blockDisplayName ?? blockQuery?.data?.blockName}
        </h1>
      </div>
      {blockQuery.isLoading ||
      farmsQuery.isLoading ||
      regionsQuery.isLoading ? (
        <PageLoadingState />
      ) : blockQuery.isError ? (
        <PageErrorState
          entityName="block"
          pluralEntityName="blocks"
          statusCode={blockQuery.error?.response?.status ?? 500}
          tryAgainCallback={() => {
            blockQuery.refetch();
            farmsQuery.refetch();
            regionsQuery.refetch();
          }}
          tryAgainLoading={
            blockQuery.isFetching ||
            farmsQuery.isFetching ||
            regionsQuery.isFetching
          }
          navigateBackCallback={() =>
            isViteApp
              ? navigate('/app/admin/platform/blocks' + params)
              : navigate('/admin/blocks' + params)
          }
        />
      ) : (
        <div className="p-4 w-full flex flex-col max-w-lg gap-2">
          <Input.Text.Single
            id="block-name-input"
            value={block?.blockName || ''}
            setValue={(blockName) => {
              setBlock({ ...block, blockName });
            }}
            label={<Input.Label label="Name" required />}
            error={
              <Input.Error error={formValidation.errors['block-name-input']} />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Name')],
            }}
          />
          <Input.Text.Single
            id="block-display-name-input"
            value={block?.blockDisplayName || ''}
            setValue={(blockDisplayName) => {
              setBlock({ ...block, blockDisplayName });
            }}
            label={<Input.Label label="Display Name" required />}
            error={
              <Input.Error
                error={formValidation.errors['block-display-name-input']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Display Name')],
            }}
          />
          <Input.Select.Single
            id="block-farm-select"
            options={farmsQuery.data ?? []}
            loading={farmsQuery.isLoading}
            optionBuilder={(f) => f.farmDisplayName ?? f.farmName ?? 'Unknown'}
            value={selectedFarm}
            setValue={setSelectedFarm}
            title="Farm"
            label={<Input.Label label="Farm" required />}
            error={
              <Input.Error error={formValidation.errors['block-farm-select']} />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Farm')],
            }}
          />
          <div className="flex flex-row justify-end py-4">
            <Button.Primary
              id="save-block-button"
              label="Save"
              onClick={updateBlock}
              loading={blockPutMutation.isPending}
            />
          </div>
          <EntityDetailsSection
            items={[
              {
                label: 'ID',
                value: block?.id,
              },
              {
                label: 'Total Block Acres',
                value: block?.totalAcres,
              },
            ]}
          />
          <EntityDetailsSection
            title="Organization"
            items={[
              { label: 'ID', value: organizationQuery.data?.id },
              {
                label: 'Name',
                value: organizationQuery.data?.customerName,
              },
              {
                label: 'Display Name',
                value: organizationQuery.data?.customerDisplayName,
              },
              {
                label: 'Region',
                value: organizationQuery.data?.region?.regionName,
              },
              {
                label: 'UUID',
                value: organizationQuery.data?.customerUuid,
              },
            ]}
          />
          <EntityDetailsSection
            title="Farm"
            items={[
              { label: 'ID', value: farm?.id },
              {
                label: 'Name',
                value: farm?.farmName,
              },
              {
                label: 'Display Name',
                value: farm?.farmDisplayName,
              },
              {
                label: 'Address',
                value: farm?.address,
              },
              {
                label: 'City',
                value: farm?.city,
              },
              {
                label: 'Region',
                value: region?.regionName,
              },
              {
                label: 'UUID',
                value: farm?.uuid,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
