import OlMap from 'ol/Map';
import { Extent } from 'ol/extent';

import { APIClient } from '@agerpoint/api';

import { Gradient } from './project';

type BaseLayer = {
  id: number;
  entityId: number;
  type: string;
  typeId: number;
  visible: boolean;
  name: string;
  zIndex: number;
  dimensions: number;
};

export interface BaseMapLayer extends BaseLayer {
  id: number;
  type: 'BaseMap';
  name: string;
  displayName: string;
  dimensions: 2;
}

export interface WmsVectorMapLayer extends BaseLayer {
  entityId: number;
  layerTypeName: string;
  type: 'WmsVector';
  name: string;
  wmsUrl: string;
  style: VectorStyle;
  layers: string;
  params: string;
  dimensions: 2;
  extent: Extent;
}

export interface WmsRasterMapLayer extends BaseLayer {
  type: 'WmsRaster';
  name: string;
  wmsUrl: string;
  layers: string;
  params: string;
  dimensions: 2;
  extent: Extent;
}

export interface PointCloudMapLayer extends BaseLayer {
  entityId: number;
  type: 'PointCloud';
  name: string;
  tilesetUrl: string;
  dimensions: 3;
  style: PointCloudStyle;
  offset: number;
}

export interface FileMapLayer extends BaseLayer {
  entityId: number;
  type: 'File';
  name: string;
  fileName: string;
  reportName: string;
  fileDate: string;
  description: string;
  dimensions: 0;
}

export interface AttachmentMapLayer extends BaseLayer {
  entityId: number;
  type: 'Attachment';
  name: string;
  fileName: string;
  fileDate: string;
  dimensions: 0;
  upload?: APIClient.Upload;
  uploadFile?: APIClient.UploadFile;
}

export interface UnsavedLayer extends BaseLayer {
  type: 'Unsaved';
  dimensions: 0;
}

export interface RgbPointCloudStyle {
  type: 'rgb';
}

interface BasePointCloudStyle {
  attribute: string;
  gradient: Gradient;
}

export interface GraduatedPointCloudStyle extends BasePointCloudStyle {
  type: 'graduated';
  contrast: number;
}

export interface ClassificationPointCloudStyle extends BasePointCloudStyle {
  type: 'classification';
  classes: PointCloudClassification[];
}

export type PointCloudClassification = {
  name: string;
  value: number;
  visible: boolean;
  color: string;
};

export type PointCloudStyle =
  | RgbPointCloudStyle
  | GraduatedPointCloudStyle
  | ClassificationPointCloudStyle;

export type Layer =
  | BaseMapLayer /* | SomeOtherLayer | SomeMoreTypeOfLayer | ...*/
  | WmsVectorMapLayer
  | WmsRasterMapLayer
  | PointCloudMapLayer
  | FileMapLayer
  | AttachmentMapLayer
  | UnsavedLayer;

export type StrokePattern = 'Solid' | 'Dashed';

export enum LabelType {
  None = 'None',
  Attribute = 'Attribute',
}

export type LabelStyle =
  | {
      type: LabelType.None;
    }
  | {
      type: LabelType.Attribute;
      attribute: string;
      min?: number;
      max?: number;
    };

export interface VectorStyle {
  strokeColor: string | ColorStyle;
  strokeWidth: number;
  fillColor: string | ColorStyle;
  strokePattern: StrokePattern;
  fillOpacity: number;
  strokeOpacity: number;
  label: LabelStyle;
}

export type ColorStyle = SolidStyle | GraduatedStyle | CategorizedStyle;

export interface SolidStyle {
  type: 'solid';
  color: string;
}

interface GradientStyle {
  attribute: string;
  gradient: Gradient;
}

export type BreaksMethod = 'equalInterval' | 'equalCounts' | 'naturalBreaks';

export interface GraduatedStyle extends GradientStyle {
  type: 'graduated';
  breaksMethod: BreaksMethod;
  bins: Bin[];
}

export interface CategorizedStyle extends GradientStyle {
  type: 'categorized';
  bins: CategorizedBin[];
}

export interface Bin {
  range: [number, number];
  visible: boolean;
}

export interface CategorizedBin {
  value: number | string;
  visible: boolean;
}

export interface LayerComponentProps {
  map: OlMap;
  layer: Layer;
  bingKey: string;
  zIndex: number;
  visible: boolean;
  selectedFeatureIds?: number[];
  showFeatureInfo: boolean;
  setSelectedFeatureIds?: (featureIds: number[]) => void;
  serverUrl?: string;
  token?: string;
  isSelectedLayer?: boolean;
  setSelectedLayerId?: (layerId: number, count: boolean) => void;
  layerIdUpdateCount?: number;
  selectedOlUid?: string;
  setSelectedOlUid?: (olUid: string) => void;
}

export enum LayerTypeName {
  Collections = 'collections',
  Farms = 'farms',
  Blocks = 'blocks',
  EptPointClouds = 'eptpointclouds',
  ImageMosaics = 'imagemosaics',
  File = 'file',
  Attachment = 'upload',
  GeometryCollection = 'geometrycollection',
  Capture = 'capture',
}
