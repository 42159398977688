/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { ProjectTag } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getProjectTagById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<ProjectTag>(
    {
      url: `/api/ProjectTags/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetProjectTagByIdQueryKey = (id: number) => {
  return [`/api/ProjectTags/${id}`] as const;
};

export const getGetProjectTagByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectTagById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectTagById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectTagByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProjectTagById>>
  > = ({ signal }) => getProjectTagById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectTagById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectTagByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectTagById>>
>;
export type GetProjectTagByIdQueryError = ErrorType<void>;

export const useGetProjectTagById = <
  TData = Awaited<ReturnType<typeof getProjectTagById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectTagById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectTagByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteProjectTag = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<ProjectTag>(
    {
      url: `/api/ProjectTags/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteProjectTagMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectTag>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProjectTag>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProjectTag>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteProjectTag(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteProjectTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProjectTag>>
>;

export type DeleteProjectTagMutationError = ErrorType<void>;

export const useDeleteProjectTag = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectTag>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteProjectTag>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteProjectTagMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getProjectTagByName = (
  tagName: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<ProjectTag[]>(
    {
      url: `/api/ProjectTags/Search/${encodeURIComponent(String(tagName))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetProjectTagByNameQueryKey = (tagName: string) => {
  return [`/api/ProjectTags/Search/${tagName}`] as const;
};

export const getGetProjectTagByNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectTagByName>>,
  TError = ErrorType<void>
>(
  tagName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectTagByName>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProjectTagByNameQueryKey(tagName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProjectTagByName>>
  > = ({ signal }) => getProjectTagByName(tagName, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tagName,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectTagByName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectTagByNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectTagByName>>
>;
export type GetProjectTagByNameQueryError = ErrorType<void>;

export const useGetProjectTagByName = <
  TData = Awaited<ReturnType<typeof getProjectTagByName>>,
  TError = ErrorType<void>
>(
  tagName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectTagByName>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectTagByNameQueryOptions(tagName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getProjectTagsByProjectId = (
  projectId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<ProjectTag[]>(
    {
      url: `/api/ProjectTags/Projects/${encodeURIComponent(String(projectId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetProjectTagsByProjectIdQueryKey = (projectId: number) => {
  return [`/api/ProjectTags/Projects/${projectId}`] as const;
};

export const getGetProjectTagsByProjectIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectTagsByProjectId>>,
  TError = ErrorType<void>
>(
  projectId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectTagsByProjectId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProjectTagsByProjectIdQueryKey(projectId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProjectTagsByProjectId>>
  > = ({ signal }) =>
    getProjectTagsByProjectId(projectId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!projectId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectTagsByProjectId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectTagsByProjectIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectTagsByProjectId>>
>;
export type GetProjectTagsByProjectIdQueryError = ErrorType<void>;

export const useGetProjectTagsByProjectId = <
  TData = Awaited<ReturnType<typeof getProjectTagsByProjectId>>,
  TError = ErrorType<void>
>(
  projectId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectTagsByProjectId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectTagsByProjectIdQueryOptions(
    projectId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putProjectTag = (
  projectTagId: number,
  projectTag: BodyType<ProjectTag>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<ProjectTag>(
    {
      url: `/api/ProjectTags/${encodeURIComponent(String(projectTagId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: projectTag,
    },
    options
  );
};

export const getPutProjectTagMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectTag>>,
    TError,
    { projectTagId: number; data: BodyType<ProjectTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putProjectTag>>,
  TError,
  { projectTagId: number; data: BodyType<ProjectTag> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProjectTag>>,
    { projectTagId: number; data: BodyType<ProjectTag> }
  > = (props) => {
    const { projectTagId, data } = props ?? {};

    return putProjectTag(projectTagId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutProjectTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof putProjectTag>>
>;
export type PutProjectTagMutationBody = BodyType<ProjectTag>;
export type PutProjectTagMutationError = ErrorType<void>;

export const usePutProjectTag = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectTag>>,
    TError,
    { projectTagId: number; data: BodyType<ProjectTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putProjectTag>>,
  TError,
  { projectTagId: number; data: BodyType<ProjectTag> },
  TContext
> => {
  const mutationOptions = getPutProjectTagMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postProjectTag = (
  projectTag: BodyType<ProjectTag>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<ProjectTag>(
    {
      url: `/api/ProjectTags`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: projectTag,
    },
    options
  );
};

export const getPostProjectTagMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectTag>>,
    TError,
    { data: BodyType<ProjectTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectTag>>,
  TError,
  { data: BodyType<ProjectTag> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectTag>>,
    { data: BodyType<ProjectTag> }
  > = (props) => {
    const { data } = props ?? {};

    return postProjectTag(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectTag>>
>;
export type PostProjectTagMutationBody = BodyType<ProjectTag>;
export type PostProjectTagMutationError = ErrorType<void>;

export const usePostProjectTag = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectTag>>,
    TError,
    { data: BodyType<ProjectTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectTag>>,
  TError,
  { data: BodyType<ProjectTag> },
  TContext
> => {
  const mutationOptions = getPostProjectTagMutationOptions(options);

  return useMutation(mutationOptions);
};
