import {
  faChevronDown,
  faChevronUp,
  faSearch,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import { formatDateAndTime } from '@agerpoint/api';
import { CloudButton } from '@agerpoint/cloud/components';
import { OpenLayerMapController, UploadFileWithExif } from '@agerpoint/types';
import { useIsViteApp } from '@agerpoint/utilities';

interface FileExifOverlayProps {
  file?: UploadFileWithExif;
  mapController?: OpenLayerMapController;
  closeOverlay: () => void;
}

export const FileExifOverlay = ({
  file,
  closeOverlay,
  mapController,
}: FileExifOverlayProps) => {
  const [highlightedFileInfoExpanded, setHighlightedFileInfoExpanded] =
    useState(false);

  const isViteApp = useIsViteApp();

  return (
    <div className="w-full h-full">
      <div className="w-full flex justify-between items-center py-1 px-2">
        <div>{file?.name}</div>
        <div className="flex flex-row gap-1">
          {mapController && (
            <div
              className={`p-0.5 flex w-4 h-4 cursor-pointer justify-center items-center hover:bg-gray-300 rounded-full`}
              onClick={() => {
                const long = file?.exif?.gps.longitude;
                const lat = file?.exif?.gps.latitude;

                if (long && lat) {
                  mapController.zoomMapToLonLat?.([long, lat], 20);
                }
              }}
            >
              <FontAwesomeIcon icon={faSearch} className="w-full h-full" />
            </div>
          )}
          {isViteApp ? (
            <CloudButton.Icon
              id="close-image-info"
              onClick={() => {
                closeOverlay();
              }}
              tooltip="Close"
              leadingIcon="xmark"
            />
          ) : (
            <div
              className={`p-0.5 flex w-4 h-4 cursor-pointer justify-center items-center hover:bg-gray-300 rounded-full`}
              onClick={() => {
                closeOverlay();
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="w-full h-full" />
            </div>
          )}
        </div>
      </div>
      <div className="text-xs w-full h-full overflow-x-hidden overflow-y-auto px-2">
        <FileExifOverlayRecord
          label="Latitude"
          value={file?.exif.gps.latitude}
        />
        <FileExifOverlayRecord
          label="Longitude"
          value={file?.exif.gps.longitude}
        />
        <FileExifOverlayRecord label="Altitude" value={file?.exif.altitude} />
        <FileExifOverlayRecord
          label="Create Date"
          value={formatDateAndTime(file?.exif.createDate)}
        />
        <FileExifOverlayRecord
          label="Modify Date"
          value={formatDateAndTime(file?.exif.modifyDate)}
        />
        <FileExifOverlayRecord label="Width (px)" value={file?.exif.width} />
        <FileExifOverlayRecord label="Height (px)" value={file?.exif.height} />
        <FileExifOverlayRecord
          label="File Size (B)"
          value={file?.exif.fileSize}
        />
        <FileExifOverlayRecord label="Camera" value={file?.exif.cameraName} />
        <FileExifOverlayRecord
          label="Description"
          value={file?.exif.description}
        />
        <div className="flex flex-row justify-start gap-1 w-full items-center">
          <span
            className="underline hover:text-gray-700 cursor-pointer"
            onClick={() => {
              setHighlightedFileInfoExpanded((prev) => !prev);
            }}
          >
            {highlightedFileInfoExpanded ? 'Show Less' : 'Show More'}
          </span>
          <FontAwesomeIcon
            icon={highlightedFileInfoExpanded ? faChevronUp : faChevronDown}
          />
        </div>
        {highlightedFileInfoExpanded &&
          file?.exif.other &&
          Object.keys(file.exif.other).map((key, index) => {
            const o = file.exif.other;
            if (!o) {
              return null;
            }
            const value = o[key as keyof typeof o];

            return (
              <FileExifOverlayRecord key={index} label={key} value={value} />
            );
          })}
      </div>
    </div>
  );
};

const FileExifOverlayRecord = ({
  label,
  value,
}: {
  label: string;
  value?: string | number;
}) => {
  if (!value) {
    return null;
  }
  return (
    <div className="flex flex-row justify-start gap-1 w-full">
      <span>{label}:</span>
      <span className="truncate">{`${value}`}</span>
    </div>
  );
};
