/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';
import type { CaptureObjectType } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureObjectTypes = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObjectType[]>(
    { url: `/api/CaptureObjectTypes`, method: 'GET', signal },
    options
  );
};

export const getGetCaptureObjectTypesQueryKey = () => {
  return [`/api/CaptureObjectTypes`] as const;
};

export const getGetCaptureObjectTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectTypes>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureObjectTypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureObjectTypesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectTypes>>
  > = ({ signal }) => getCaptureObjectTypes(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectTypes>>
>;
export type GetCaptureObjectTypesQueryError = ErrorType<void>;

export const useGetCaptureObjectTypes = <
  TData = Awaited<ReturnType<typeof getCaptureObjectTypes>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureObjectTypes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectTypesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
