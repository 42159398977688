import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminOrganizationsQueries } from './admin-organizations-queries';

export const AdminOrganizationsNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  usePageTitle(() => 'Platform - Organizations', []);

  const formValidation = useFormValidation();
  const [organization, setOrganization] = useState<APIModels.Customer>({});
  const [selectedCountry, setSelectedCountry] = useState<APIModels.Country>();
  const [selectedRegion, setSelectedRegion] = useState<APIModels.Region>();
  const [selectedUser, setSelectedUser] = useState<APIModels.User>();

  const {
    organizationPostMutation,
    usersQuery,
    countriesQuery,
    countryRegionsQuery,
  } = useAdminOrganizationsQueries(selectedCountry);

  useEffect(() => {
    setSelectedRegion(undefined);
  }, [selectedCountry]);

  useEffect(() => {
    setOrganization((prev) => ({ ...prev, regionId: selectedRegion?.id }));
  }, [selectedRegion]);

  const createOrganization = useCallback(async () => {
    if (organizationPostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    organizationPostMutation.mutate({
      data: organization,
    });
  }, [organization, formValidation, organizationPostMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Organizations',
              path: isViteApp
                ? '/app/admin/platform/organizations'
                : '/admin/organizations',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-organization-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/organizations' + params);
            } else {
              navigate('/admin/organizations' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Organization</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="org-name-input"
          label={<Input.Label label="Name" required />}
          value={organization?.customerName || ''}
          setValue={(customerName) => {
            setOrganization({ ...organization, customerName });
          }}
          error={
            <Input.Error error={formValidation.errors['org-name-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
        <Input.Text.Single
          id="org-display-name-input"
          label={<Input.Label label="Display Name" required />}
          value={organization?.customerDisplayName || ''}
          setValue={(customerDisplayName) => {
            setOrganization({ ...organization, customerDisplayName });
          }}
          error={
            <Input.Error
              error={formValidation.errors['org-display-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Display Name')],
          }}
        />
        <Input.Select.Single
          id="organization-country-select"
          options={countriesQuery.data ?? []}
          optionBuilder={(o) => o?.countryName ?? 'Unknown'}
          value={selectedCountry}
          setValue={setSelectedCountry}
          loading={countriesQuery.isLoading}
          title="Country"
          label={<Input.Label label="Country" required />}
          error={
            <Input.Error
              error={formValidation.errors['organization-country-select']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Country')],
          }}
        />
        <Input.Select.Single
          id="organization-region-select"
          options={countryRegionsQuery.data ?? []}
          optionBuilder={(o) => o?.regionName ?? 'Unknown'}
          value={selectedRegion}
          setValue={setSelectedRegion}
          disabled={selectedCountry === undefined}
          title="Region"
          loading={countryRegionsQuery.isLoading}
          label={<Input.Label label="Region" required />}
          error={
            <Input.Error
              error={formValidation.errors['organization-region-select']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Region')],
          }}
        />
        <Input.Text.Single
          id="org-status-input"
          label={<Input.Label label="Status" />}
          value={organization?.status || ''}
          setValue={(status) => {
            setOrganization({ ...organization, status });
          }}
        />
        <Input.Select.Single
          id="organization-user-select"
          options={usersQuery.data ?? []}
          optionBuilder={(o) => o?.email ?? 'Unknown'}
          value={selectedUser}
          setValue={setSelectedUser}
          loading={usersQuery.isLoading}
          title="User"
          label={<Input.Label label="Primary User" />}
        />
        <div className="w-full flex flex-row justify-end items-center py-4">
          <Button.Primary
            id="new-organization-create-button"
            label={'Create'}
            onClick={createOrganization}
            loading={organizationPostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
