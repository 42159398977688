import { Capture, LayerGroup } from '@agerpoint/api';
import { LabelType } from '@agerpoint/types';

enum LayerGroupNames {
  MobileCaptures = 'Mobile Captures',
}

const createMobileCaptureLayerGroup = async (
  projectId: number,
  serverUrl: string,
  token: string
) => {
  const url = `${serverUrl}/api/LayerGroups`;
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    pragma: 'no-cache',
  };
  const body = {
    name: LayerGroupNames.MobileCaptures,
    projectId,
    icon: `Mobile`,
    visible: true,
  };
  const res = await fetch(`${url}`, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
  });
  return res.json();
};

const addCaptureToLayerGroup = async (
  layerGroup: LayerGroup,
  serverUrl: string,
  token: string,
  captureData: Capture
) => {
  const url = `${serverUrl}/api/Layers`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
    'cache-control': 'no-cache',
    pragma: 'no-cache',
  };
  const res = await fetch(`${url}`, {
    body: JSON.stringify({
      entityid: captureData.id,
      layerGroupId: layerGroup.id,
      layerTypeId: 9,
      name: captureData.captureName,
      visible: true,
      style: JSON.stringify({
        strokeColor: '#000000',
        strokeWidth: 1,
        fillColor: { type: 'solid', color: '#339bc0' },
        fillOpacity: 1,
        strokeOpacity: 1,
        strokePattern: 'Solid',
        label: { type: LabelType.None },
        type: 'solid',
      }),
    }),

    headers,
    method: 'POST',
  });
  const postRes = await res.json();
};

const getProjectLayerGroups = async (
  projectUuids: string[],
  serverUrl: string,
  token: string
) => {
  const url = `${serverUrl}/api/LayerGroups/Projects`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
    'cache-control': 'no-cache',
    pragma: 'no-cache',
  };
  return await Promise.all(
    projectUuids.map(async (projectUuid) => {
      const res = await fetch(`${url}/${projectUuid}`, {
        headers: headers,
        method: 'GET',
      });
      return res.json();
    })
  );
};

const getAllMobileCaptureGroups = async (
  projectsLayerGroups: LayerGroup[][],
  projectIds: number[],
  serverUrl: string,
  token: string
) => {
  return await Promise.all(
    projectsLayerGroups.map(async (projectLayerGroup: LayerGroup[], i) => {
      const mobileCaptureLayerGroup = projectLayerGroup.find((obj) => {
        return obj.name === LayerGroupNames.MobileCaptures;
      });
      if (!mobileCaptureLayerGroup) {
        const projectId = projectIds[i];
        return await createMobileCaptureLayerGroup(projectId, serverUrl, token);
      }
      return Promise.resolve(mobileCaptureLayerGroup);
    })
  );
};

export const fetchLayerGroups = async (
  projectIds: number[],
  projectUuids: string[],
  serverUrl: string,
  token: string,
  captureData: Capture
) => {
  const projectsLayerGroups = await getProjectLayerGroups(
    projectUuids,
    serverUrl,
    token
  );

  const allMobileCaptureGroups = await getAllMobileCaptureGroups(
    projectsLayerGroups,
    projectIds,
    serverUrl,
    token
  );

  const addToAllProjects = await Promise.all(
    allMobileCaptureGroups.map(async (layerGroup: LayerGroup) => {
      return await addCaptureToLayerGroup(
        layerGroup,
        serverUrl,
        token,
        captureData
      );
    })
  );

  return await addToAllProjects;
};

export const deleteLayerFromGroup = async (
  projectIds: number[],
  projectUuids: string[],
  serverUrl: string,
  token: string,
  captureData: Capture
) => {
  const projectsLayerGroups = await getProjectLayerGroups(
    projectUuids,
    serverUrl,
    token
  );

  const allMobileCaptureGroups = await getAllMobileCaptureGroups(
    projectsLayerGroups,
    projectIds,
    serverUrl,
    token
  );

  const findLayers = await Promise.all(
    allMobileCaptureGroups.map(async (layerGroup: LayerGroup) => {
      const url = `${serverUrl}/api/Layers/GroupLayers`;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'cache-control': 'no-cache',
        pragma: 'no-cache',
      };
      const res = await fetch(`${url}/${layerGroup.id}`, {
        headers: headers,
        method: 'GET',
      });
      return res.json();
    })
  );

  const layersToDelete = findLayers.flat().filter((layer) => {
    return layer.entityId === captureData.id;
  });

  const deleteLayer = await Promise.all(
    layersToDelete.map(async (layer) => {
      const url = `${serverUrl}/api/Layers/${layer.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'cache-control': 'no-cache',
        pragma: 'no-cache',
      };
      return await fetch(`${url}`, {
        headers: headers,
        method: 'DELETE',
      });
    })
  );
  return await deleteLayer;
};
