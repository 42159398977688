import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DragStart,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { useDebouncyEffect } from 'use-debouncy';

import { useGetLibrary } from '@agerpoint/api';
import {
  IconButton,
  Input,
  SidebarExpansionHeader,
  SidebarSpinner,
} from '@agerpoint/component';
import { LibraryLayer } from '@agerpoint/types';
import { toLibraryLayers, useIsViteApp } from '@agerpoint/utilities';

import { LibraryItem } from '../library-item/library-item';
import {
  AgerDragDropContext,
  AgerDragDropEvent,
  getLayersUniqueId,
  multiSelectTo as multiSelect,
} from './utils';

interface LibraryExpansionProps {
  isDragging?: boolean;
  onCancel?: () => void;
}

export function MultiDNDLibraryExpansion({
  isDragging,
  onCancel,
}: LibraryExpansionProps) {
  const { loading, error, data: library } = useGetLibrary({});
  const isViteApp = useIsViteApp();
  const [, setSearch] = useSearchParams();
  const navigate = useNavigate();

  const layers = useMemo(
    () => (library ? toLibraryLayers(library) : undefined),
    [library]
  );

  const [nameFilter, setNameFilter] = useState<string>('');
  const [selectedLayerIds, setSelectedLayersIds] = useState<string[]>([]);
  const [draggingLayerId, setDraggingLayerId] = useState<string>();
  const [filteredLayers, setFilteredLayers] = useState<
    LibraryLayer[] | undefined
  >();

  useEffect(() => {
    setSelectedLayersIds([]);
    setDraggingLayerId(undefined);
    let data = layers;

    if (data === undefined) {
      return;
    }

    if (filteredLayers === undefined) {
      setFilteredLayers(data);
    }

    const filter = nameFilter.trim().toLowerCase();
    if (filter !== '') {
      data = data.filter((d) => d.name?.toLowerCase().includes(filter));
    }

    setFilteredLayers(data);
  }, [layers, nameFilter]);

  const unselectAll = () => {
    setSelectedLayersIds([]);
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  }, []);

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      unselectAll();
    }
  };

  const onWindowClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const onWindowTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    unselectAll();
  };

  const toggleSelection = useCallback(
    (layerId: string) => {
      const wasSelected: boolean = selectedLayerIds.includes(layerId);
      const newLayerIds: string[] = (() => {
        // Task was not previously selected
        // now will be the only selected item
        if (!wasSelected) {
          return [layerId];
        }

        // Task was part of a selected group
        // will now become the only selected item
        if (selectedLayerIds.length > 1) {
          return [layerId];
        }

        // task was previously selected but not in a group
        // we will now clear the selection
        return [];
      })();
      setSelectedLayersIds(newLayerIds);
    },
    [selectedLayerIds]
  );

  const toggleSelectionInGroup = useCallback(
    (layerId: string) => {
      const index: number = selectedLayerIds.indexOf(layerId);

      // if not selected - add it to the selected items
      if (index === -1) {
        setSelectedLayersIds([...selectedLayerIds, layerId]);
        return;
      }

      // it was previously selected and now needs to be removed from the group
      const shallow: string[] = [...selectedLayerIds];
      shallow.splice(index, 1);
      setSelectedLayersIds(shallow);
    },
    [selectedLayerIds]
  );

  // This behaviour matches the MacOSX finder selection
  const multiSelectTo = useCallback(
    (layerId: string) => {
      const updated: string[] | null = multiSelect(
        filteredLayers,
        selectedLayerIds,
        layerId
      );
      if (updated == null) {
        return;
      }
      setSelectedLayersIds(updated);
    },
    [filteredLayers, selectedLayerIds]
  );

  const onDragStart = useCallback(
    (start: DragStart) => {
      if (start.source.droppableId !== 'library') {
        return;
      }
      const layer: LibraryLayer = JSON.parse(start.draggableId) as LibraryLayer;

      const selected: string | undefined = selectedLayerIds.find(
        (layerId: string): boolean => layerId === getLayersUniqueId(layer)
      );

      // if dragging an item that is not selected - unselect all items
      if (!selected && selectedLayerIds.length !== 0) {
        setSelectedLayersIds([]);
      }

      if (draggingLayerId !== getLayersUniqueId(layer)) {
        setDraggingLayerId(getLayersUniqueId(layer));
      }
    },
    [selectedLayerIds, draggingLayerId]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (
        result.destination?.droppableId?.startsWith('groupLayer') ||
        result.destination?.droppableId?.startsWith('groupHeader')
      ) {
        const draggedLayer = JSON.parse(result.draggableId) as LibraryLayer;

        const layersToAdd = layers?.filter((l) => {
          return (
            selectedLayerIds.includes(getLayersUniqueId(l)) &&
            getLayersUniqueId(l) !== getLayersUniqueId(draggedLayer)
          );
        });
        let index = result?.destination?.index;
        layersToAdd?.forEach((l) => {
          index = index + 1;
          const event = {
            action: 'DRAG_END',
            payload: {
              source: { droppableId: 'library' },
              destination: {
                droppableId: result?.destination?.droppableId,
                index: index,
              },
              draggableId: JSON.stringify({
                id: getLayersUniqueId(l),
                entityId: l.entityId,
                layerTypeId: l.layerTypeId,
                name: l.name,
              }),
            } as DropResult,
          } as AgerDragDropEvent;

          emitCustomEvent?.({
            droppableId: result.destination?.droppableId as string,
            event: event,
          });
        });
      }

      setDraggingLayerId(undefined);
    },
    [selectedLayerIds, layers]
  );

  const { addEvent, removeEvent, emitCustomEvent } =
    useContext(AgerDragDropContext);

  useEffect(() => {
    addEvent?.({
      droppableId: 'library',
      callback: (event) => {
        if (event.action === 'DRAG_START') {
          onDragStart(event.payload as DragStart);
        } else if (event.action === 'DRAG_END') {
          onDragEnd(event.payload as DropResult);
        }
      },
      greedy: true,
    });

    return () => {
      removeEvent?.({ droppableId: 'library' });
    };
  }, [onDragStart, onDragEnd, addEvent, removeEvent]);

  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    const layer = filteredLayers?.[index];

    if (!layer) {
      return <div style={style}></div>;
    }

    const isSelected = selectedLayerIds.includes(getLayersUniqueId(layer));
    const isGhosting =
      isSelected &&
      Boolean(draggingLayerId) &&
      draggingLayerId !== getLayersUniqueId(layer);

    return (
      <div style={style}>
        <Draggable
          isDragDisabled={isDragging}
          key={getLayersUniqueId(layer)}
          draggableId={JSON.stringify({
            id: getLayersUniqueId(layer),
            entityId: layer.entityId,
            layerTypeId: layer.layerTypeId,
            name: layer.name,
          })}
          index={index}
        >
          {(provided, snapshot) => (
            <LibraryItem
              toggleSelection={toggleSelection}
              toggleSelectionInGroup={toggleSelectionInGroup}
              multiSelectTo={multiSelectTo}
              provided={provided}
              snapshot={snapshot}
              layer={layer}
              index={index}
              innerRef={provided.innerRef}
              draggableProps={provided.draggableProps}
              dragHandleProps={provided.dragHandleProps as any}
              isDragging={snapshot.isDragging}
              isSelected={isSelected}
              isGhosting={isGhosting}
              hasMultiDNDPermission={true}
            />
          )}
        </Draggable>
      </div>
    );
  };

  return (
    <div className="relative h-full">
      <SidebarExpansionHeader size="large" onCancel={onCancel}>
        <div className="flex flex-row gap-5 w-full items-center">
          <LibrarySearchInput callback={setNameFilter} />
          <IconButton
            show
            onClick={() => {
              if (isViteApp) {
                setSearch(
                  (prev) => {
                    prev.set('upload', 'capture');
                    return prev;
                  },
                  {
                    replace: true,
                  }
                );
              } else {
                navigate('/uploads/new');
              }
            }}
            className={`w-8 h-4`}
            icon={
              <FontAwesomeIcon
                icon={faUpload}
                className="text-lg text-gray-600 hover:text-gray-900 flex items-center justify-center"
              />
            }
          />
        </div>
      </SidebarExpansionHeader>
      {loading ? (
        <SidebarSpinner message="Loading Library" />
      ) : error ? (
        <div>
          An error occurred:
          <code>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </code>
        </div>
      ) : (
        filteredLayers && (
          <Droppable
            droppableId="library"
            isDropDisabled
            mode={'virtual'}
            renderClone={(
              provided: DraggableProvided,
              snapshot: DraggableStateSnapshot,
              rubric: DraggableRubric
            ) => (
              <LibraryItem
                toggleSelection={toggleSelection}
                toggleSelectionInGroup={toggleSelectionInGroup}
                multiSelectTo={multiSelectTo}
                provided={provided}
                snapshot={snapshot}
                layer={filteredLayers[rubric.source.index]}
                index={rubric.source.index}
                innerRef={provided.innerRef}
                draggableProps={provided.draggableProps}
                dragHandleProps={provided.dragHandleProps as any}
                isDragging={snapshot.isDragging}
                hasMultiDNDPermission={true}
                isDragged={true}
                selectedItemsQuantity={selectedLayerIds.length - 1}
              />
            )}
          >
            {({ droppableProps, innerRef, placeholder }) => (
              <div
                ref={innerRef}
                {...droppableProps}
                className="absolute inset-0 overflow-auto"
                style={{
                  top: '4rem',
                }}
              >
                <AutoSizer className="h-full overflow-y-scroll">
                  {({ height = 0, width = 0 }) => (
                    <List
                      height={height}
                      width={width}
                      itemCount={filteredLayers.length}
                      itemSize={30}
                      overscanCount={5}
                    >
                      {Item}
                    </List>
                  )}
                </AutoSizer>
              </div>
            )}
          </Droppable>
        )
      )}
    </div>
  );
}

// Exported LibrarySearchInput to component to improve performance
const LibrarySearchInput = ({
  callback,
}: {
  callback: (value: string) => void;
}) => {
  const [nameFilter, setNameFilter] = useState<string>('');

  useDebouncyEffect(
    () => {
      callback(nameFilter);
    },
    300,
    [nameFilter]
  );

  return (
    <Input.Text.Single
      id="library-name-filter"
      placeholder="Search by Name"
      placeholderIcon={Input.placeholderIcons.search}
      value={nameFilter}
      setValue={setNameFilter}
    />
  );
};
