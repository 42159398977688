import { useContext } from 'react';

import { InputIdContext } from './input-id-context';

interface IInputLabel {
  label?: string;
  required?: boolean;
}

export function InputLabel({ label, required = false }: IInputLabel) {
  const id = useContext(InputIdContext);

  return (
    <label
      data-test-id={id ? `${id}-label` : undefined}
      className="text-xs text-gray-textSecondary pl-2 pb-1"
    >
      {label ?? null}
      {required ? '*' : null}
    </label>
  );
}
