import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import { useIsViteApp, useToasts } from '@agerpoint/utilities';

export const useAdminCropsQueries = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const toasts = useToasts();

  const isViteApp = useIsViteApp();

  const { cropId } = useParams();

  const cropQuery = APIClient.useGetCropById(Number(cropId), {
    query: {
      queryKey: [APIUtils.QueryKey.crops, { cropId: Number(cropId) }],
      enabled: Number.isSafeInteger(Number(cropId)),
      initialData: () =>
        APIUtils.searchQueriesForInitialValue<APIModels.Crop>({
          queryClient,
          queryKey: [APIUtils.QueryKey.crops],
          id: Number(cropId),
          accessor: 'id',
        }),
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      retry: 0,
    },
  });

  const cropPutMutation = APIClient.usePutCropById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [APIUtils.QueryKey.crops] });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateListQueryCache<APIModels.Crop>({
          queryClient,
          queryKey: [APIUtils.QueryKey.crops],
          accessor: 'id',
          data: variables.data,
          id: variables.id,
        });

        APIUtils.updateQueryCache<APIModels.Crop>({
          queryClient,
          queryKey: [APIUtils.QueryKey.crops, { cropId: variables.id }],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityUpdated('crop'));
      },
      onError: (e) => {
        console.error(e);
        toasts.add(toasts.prepare.error('Failed to update crop!'));
      },
    },
  });

  useEffect(() => {
    if (cropId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(cropId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/crops' + params);
      } else {
        navigate('/admin/crops' + params);
      }
      queryClient.removeQueries({
        queryKey: [APIUtils.QueryKey.crops, { cropId: Number(cropId) }],
      });
    }
  }, [cropId]);

  const cropsQuery = APIClient.useGetCrop({
    query: {
      queryKey: [APIUtils.QueryKey.crops],
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
    },
  });

  const cropPostMutation = APIClient.usePostCrop({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [APIUtils.QueryKey.crops] });
      },
      onSuccess: (data) => {
        APIUtils.updateQueryCache<APIModels.Crop>({
          queryClient,
          queryKey: [APIUtils.QueryKey.crops, { cropId: data.id }],
          data: data,
        });

        toasts.add(toasts.prepare.entityCreated('crop'));

        if (isViteApp) {
          navigate(`/app/admin/platform/crops/${data.id}/details`, {
            state: {
              params,
            },
          });
        } else {
          navigate(`/admin/crops/${data.id}/details`, {
            state: {
              params,
            },
          });
        }
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create crop!'));
      },
    },
  });

  return {
    cropQuery,
    cropPutMutation,
    cropsQuery,
    cropPostMutation,
  };
};
