import compare from 'trivial-compare';

import { APIModels } from '../..';

export const organizations = (data?: APIModels.Customer[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) => {
    const aName = a.customerDisplayName ?? a.customerName ?? 'Unknown';
    const bName = b.customerDisplayName ?? b.customerName ?? 'Unknown';
    return compare(aName.toLowerCase(), bName.toLowerCase());
  });
  return sorted;
};
