import { ReactNode } from 'react';
import { FixedSizeList } from 'react-window';

type RowCallback<T> = (
  row: T,
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => void;
type RowCallbackGenerator<T> = (columnName: string) => RowCallback<T> | void;

export interface DatatableProps<T> {
  id?: string;
  data: T[];
  columns: DatatableColumnProps<T>[];
  loading?: boolean;
  loadingIndicator?: React.ReactNode;
  noResults?: DatatableNoResultsProps;
  error?: DatatableErrorProps;
  pagination?: DatatablePaginationProps;
  rowHeight: number;
  sort?: DatatableColumnSortingOptions;
  setSort?: (options: DatatableColumnSortingOptions) => void;
  cellOnClick?: RowCallbackGenerator<T>;
  style?: DatatableStyle<T>;
  datatableRef?: React.MutableRefObject<FixedSizeList<
    DatatableItemData<T>
  > | null>;
}

interface DatatableErrorProps {
  title?: string;
  message?: string;
  action?: () => void;
}

interface DatatableNoResultsProps {
  icon?: React.ReactNode;
  title?: string;
  message?: string;
  action?: () => void;
  actionLabel?: string;
  actionIcon?: React.ReactNode;
}

export interface DatatablePaginationProps {
  loadNextPage?: () => void;
  threshold?: number;
}

interface DatatableColumnBaseProps<T> {
  label: ReactNode;
  name?: string;
  value: (row: T, index: number, isHovered: boolean) => ReactNode;
  flex?: number;
  style?: DatatableColumnStyle;
  visible?: boolean;
}

interface DatatableSortableColumnProps<T> extends DatatableColumnBaseProps<T> {
  sortKey: string;
}

interface DatatableClickableColumnProps<T> extends DatatableColumnBaseProps<T> {
  onClick: () => void;
}

export type DatatableColumnProps<T> =
  | DatatableSortableColumnProps<T>
  | DatatableClickableColumnProps<T>
  | DatatableColumnBaseProps<T>;

export function isClickableColumn<T>(
  column: DatatableColumnProps<T>
): column is DatatableClickableColumnProps<T> {
  return 'onClick' in column;
}

export function isSortableColumn<T>(
  column: DatatableColumnProps<T>
): column is DatatableSortableColumnProps<T> {
  return 'sortKey' in column;
}

export interface DatatableColumnSortingOptions {
  key: string | null | undefined;
  order: 'asc' | 'desc';
}

export interface DatatableStyle<T> {
  tableMinWidth?: number;
  rowWrapperStyle?: string;
  rowStyle?: string | ((data: T) => string);
  oddRowStyle?: string;
  evenRowStyle?: string;
  tableWrapperStyle?: string;
  headerWrapperStyle?: string;
  headerStyle?: string;
  bodyWrapperStyle?: string;
  cellStyle?: string;
}

export interface DatatableColumnStyle {
  columnWrapperStyle?: string;
  headerStyle?: string;
  bodyStyle?: string;
  expandBody?: boolean;
  truncation?: DatatableColumnTruncationStyle;
}

export interface DatatableColumnTruncationStyle {
  truncate?: boolean;
  lineClamp?: number;
  breakWords?: boolean;
}

export interface DatatableItemData<T> {
  data: T[];
  loading?: boolean;
  loadingIndicator?: React.ReactNode;
  error?: DatatableErrorProps;
  tableStyle?: DatatableStyle<T>;
  columns: DatatableColumnProps<T>[];
  cellOnClick?: RowCallbackGenerator<T>;
}
