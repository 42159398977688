import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import { useIsViteApp, useLookupTable, useToasts } from '@agerpoint/utilities';

export const useAdminFarmsQueries = (selectedCountry?: APIModels.Country) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { farmId } = useParams();

  const toasts = useToasts();

  const farmQuery = APIClient.useGetFarmById(Number(farmId), {
    query: {
      queryKey: [APIUtils.QueryKey.farms, { farmId: Number(farmId) }],
      enabled: Number.isSafeInteger(Number(farmId)),
      initialData: () =>
        APIUtils.searchQueriesForInitialValue<APIModels.Farm>({
          queryClient,
          queryKey: [APIUtils.QueryKey.farms],
          id: Number(farmId),
          accessor: 'id',
        }),
      retry: 0,
    },
  });

  const farmPutMutation = APIClient.usePutFarm({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [APIUtils.QueryKey.farms] });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.Farm>({
          queryClient,
          queryKey: [APIUtils.QueryKey.farms, { farmId: variables.id }],
          data: variables.data,
        });

        APIUtils.updateListQueryCache<APIModels.Farm>({
          queryClient,
          queryKey: [APIUtils.QueryKey.farms],
          data: variables.data,
          accessor: 'id',
          id: variables.id,
        });

        toasts.add(toasts.prepare.entityUpdated('farm'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update farm!'));
      },
    },
  });

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const countriesQuery = APIClient.useGetCountries({
    query: {
      queryKey: [APIUtils.QueryKey.countries],
      select: (data) => APIUtils.Sort.countries(data),
    },
  });

  useEffect(() => {
    if (farmId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(farmId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/farms' + params);
      } else {
        navigate('/admin/farms' + params);
      }
      queryClient.removeQueries({
        queryKey: [APIUtils.QueryKey.farms, { farmId: Number(farmId) }],
      });
    }
  }, [farmId]);

  const regionCountryQuery = APIClient.useGetCountryByRegionId(
    farmQuery.data?.regionId as number,
    {
      query: {
        enabled: farmQuery.data?.regionId !== undefined,
        queryKey: [
          APIUtils.QueryKey.regions,
          { regionId: farmQuery.data?.regionId },
          APIUtils.QueryKey.countries,
        ],
      },
    }
  );

  const countryRegionsQuery = APIClient.useGetRegionsByCountryId(
    selectedCountry?.id as number,
    {
      query: {
        enabled: selectedCountry !== undefined,
        queryKey: [
          APIUtils.QueryKey.countries,
          { countryId: selectedCountry?.id },
          APIUtils.QueryKey.regions,
        ],
        select: (data) => APIUtils.Sort.regions(data),
      },
    }
  );

  const farmsQuery = APIClient.useGetFarm({
    query: {
      queryKey: [APIUtils.QueryKey.farms],
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
    },
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const regionsQuery = APIClient.useGetRegions({
    query: {
      queryKey: [APIUtils.QueryKey.regions],
    },
  });

  const regionsLookupTable = useLookupTable(regionsQuery.data, 'id');

  const farmPostMutation = APIClient.usePostFarm({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [APIUtils.QueryKey.farms] });
      },
      onSuccess: (data) => {
        APIUtils.updateQueryCache<APIModels.Farm>({
          queryClient,
          queryKey: [APIUtils.QueryKey.farms, { farmId: data.id }],
          data: data,
        });

        toasts.add(toasts.prepare.entityCreated('farm'));

        if (isViteApp) {
          navigate(`/app/admin/platform/farms/${data.id}/details`, {
            state: { params },
          });
        } else {
          navigate(`/admin/farms/${data.id}/details`, {
            state: { params },
          });
        }
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create farm!'));
      },
    },
  });
  return {
    farmQuery,
    farmPutMutation,
    organizationsQuery,
    countriesQuery,
    regionCountryQuery,
    countryRegionsQuery,
    farmsQuery,
    organizationsLookupTable,
    regionsLookupTable,
    regionsQuery,
    farmPostMutation,
  };
};
