import { faClockRotateLeft, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { timeAgoString, useRecentAccess } from '@agerpoint/utilities';

export const RecentAccessPanel = () => {
  const recentAccess = useRecentAccess();

  const navigate = useNavigate();

  const recent = useMemo(
    () =>
      recentAccess.data?.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      ) ?? [],
    [recentAccess.data]
  );

  const [menuRef, setMenuRef] = useState<HTMLButtonElement | null>(null);

  const isOnLeft = useMemo(() => {
    if (!menuRef) {
      return false;
    }

    return menuRef.getBoundingClientRect().left < window.innerWidth / 2;
  }, [menuRef]);

  if (recent.length === 0) {
    return null;
  }

  return (
    <Menu as="div" className="relative w-8 h-8 select-none">
      {({ open, close }) => (
        <>
          <Menu.Button
            ref={setMenuRef}
            data-test-id="bg-task-panel-button"
            className={`w-8 h-8 rounded transition-colors hover:bg-green-100 p-0.5 ${
              open ? 'bg-green-100' : ''
            }`}
          >
            <div className="relative w-full h-full flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                className="absolute w-2/3 h-2/3"
              />
            </div>
          </Menu.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`origin-top-right absolute ${
                isOnLeft ? 'left-0' : 'right-0'
              } w-80 rounded-md overflow-hidden
                shadow bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
              style={{ zIndex: 99999 }}
            >
              <div className="flex flex-col w-full">
                <div className="flex items-center justify-between p-2 text-md border-b border-gray-200">
                  <h5 className="leading-none text-gray-900">Recent</h5>
                </div>
                <div className="divide-y divide-y-gray">
                  {recent.map((entry) => (
                    <div
                      key={entry.id}
                      className={`flex flex-row gap-2 items-center justify-between
                      px-2 py-2 hover:bg-gray-50 cursor-pointer`}
                      onClick={() => {
                        navigate(entry.path);
                        close();
                        window.location.reload();
                      }}
                    >
                      <div className="flex flex-col w-full truncate">
                        <div className="flex flex-row gap-1">
                          <div className="flex-grow truncate leading-none">
                            {entry.name}
                          </div>
                          <div>
                            <div
                              className={`w-4 h-4 bg-gray-100 rounded-full p-0.5 cursor-pointer
                            hover:bg-gray-200 flex justify-center items-center`}
                              onClick={(e) => {
                                e.stopPropagation();
                                recentAccess.remove({ id: entry.id });
                              }}
                            >
                              <span className="fa-stack w-full h-full flex items-center justify-center">
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  className="absolute w-3 h-3"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row justify-between text-xs text-gray-700 leading-none">
                          <span>
                            {entry.dataType.charAt(0).toUpperCase() +
                              entry.dataType.slice(1)}
                          </span>
                          <span>
                            {timeAgoString(new Date(entry.createdAt))}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
