import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

interface DatadogSetupProps {
  children: JSX.Element;
  appVersion?: string;
}

export const DatadogSetup = ({ children, appVersion }: DatadogSetupProps) => {
  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      return;
    }
    const subdomain = window.location.hostname.split('.')[0];
    const dev = subdomain === 'cloud-dev';

    datadogRum.init({
      applicationId: '8b545e43-9ce9-496f-96ba-1953efb5ed29',
      clientToken: 'pub5a63a4f29bd27f6207636e56966f0626',
      site: 'us5.datadoghq.com',
      service: 'apcloud',
      env: dev ? 'dev' : 'prod',
      version: appVersion,
      sessionSampleRate: 20,
      premiumSampleRate: 20,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }, []);

  return children;
};
