/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { MlModel } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getMlModels = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<MlModel[]>(
    { url: `/api/mlmodels`, method: 'GET', signal },
    options
  );
};

export const getGetMlModelsQueryKey = () => {
  return [`/api/mlmodels`] as const;
};

export const getGetMlModelsQueryOptions = <
  TData = Awaited<ReturnType<typeof getMlModels>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMlModels>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMlModelsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMlModels>>> = ({
    signal,
  }) => getMlModels(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMlModels>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMlModelsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMlModels>>
>;
export type GetMlModelsQueryError = ErrorType<void>;

export const useGetMlModels = <
  TData = Awaited<ReturnType<typeof getMlModels>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMlModels>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMlModelsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putMlModelById = (
  mlModel: BodyType<MlModel>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<MlModel>(
    {
      url: `/api/mlmodels`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: mlModel,
    },
    options
  );
};

export const getPutMlModelByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putMlModelById>>,
    TError,
    { data: BodyType<MlModel> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putMlModelById>>,
  TError,
  { data: BodyType<MlModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putMlModelById>>,
    { data: BodyType<MlModel> }
  > = (props) => {
    const { data } = props ?? {};

    return putMlModelById(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutMlModelByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putMlModelById>>
>;
export type PutMlModelByIdMutationBody = BodyType<MlModel>;
export type PutMlModelByIdMutationError = ErrorType<void>;

export const usePutMlModelById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putMlModelById>>,
    TError,
    { data: BodyType<MlModel> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putMlModelById>>,
  TError,
  { data: BodyType<MlModel> },
  TContext
> => {
  const mutationOptions = getPutMlModelByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postMlModel = (
  mlModel: BodyType<MlModel>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<MlModel>(
    {
      url: `/api/mlmodels`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: mlModel,
    },
    options
  );
};

export const getPostMlModelMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMlModel>>,
    TError,
    { data: BodyType<MlModel> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postMlModel>>,
  TError,
  { data: BodyType<MlModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postMlModel>>,
    { data: BodyType<MlModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postMlModel(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostMlModelMutationResult = NonNullable<
  Awaited<ReturnType<typeof postMlModel>>
>;
export type PostMlModelMutationBody = BodyType<MlModel>;
export type PostMlModelMutationError = ErrorType<void>;

export const usePostMlModel = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMlModel>>,
    TError,
    { data: BodyType<MlModel> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postMlModel>>,
  TError,
  { data: BodyType<MlModel> },
  TContext
> => {
  const mutationOptions = getPostMlModelMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getMlModelById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<MlModel>(
    {
      url: `/api/mlmodels/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetMlModelByIdQueryKey = (id: number) => {
  return [`/api/mlmodels/${id}`] as const;
};

export const getGetMlModelByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMlModelById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMlModelById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMlModelByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMlModelById>>> = ({
    signal,
  }) => getMlModelById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMlModelById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMlModelByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMlModelById>>
>;
export type GetMlModelByIdQueryError = ErrorType<void>;

export const useGetMlModelById = <
  TData = Awaited<ReturnType<typeof getMlModelById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMlModelById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMlModelByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
