import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { APLoader } from '../../ap-loader';
import { CloudDatatable } from '../../datatable';
import { CloudInput } from '../input';
import { InputIdContext } from '../input-id-context';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface IInputSelectSingleInlineList<T> {
  /**
   * The unique identifier for the input component.
   */
  id: string;

  /**
   * The error message to display below the input component.
   */
  error?: string;

  /**
   * The label to display above the input component.
   */
  label?: string;

  /**
   * Determines whether the input component is required.
   */
  required?: boolean;

  /**
   * The options to display in the dropdown list.
   */
  options: T[];

  /**
   * Loading state of the input component.
   */
  loading?: boolean;

  /**
   * The function to build the display text for each option.
   */
  optionBuilder: (option: T | undefined) => string;

  /**
   * The function to build the leading icon for each option.
   */
  optionIconBuilder?: (option: T | undefined) => IconName | undefined;

  /**
   * Determines whether to override the default option icon.
   */
  overrideOptionIcon?: boolean;

  /**
   * The currently selected value.
   */
  value: T | undefined;

  /**
   * The function to set the selected value.
   */
  setValue: (value: T | undefined) => void;

  /**
   * The function to compare two values for equality.
   */
  compareFn?: (a: T, b: T) => boolean;

  /**
   * The validation configuration for the input component.
   */
  validation?: InputValidation<T | undefined>;

  /**
   * The children to render inside the input component.
   */
  children?: ReactNode;

  /**
   * Pagination configuration for the input component.
   */
  pagination?: {
    loadNextPage: () => Promise<void>;
    threshold?: number;
  };

  /**
   * The component to display on top of the list.
   */
  overlay?: () => ReactNode;
}

function InputSelectSingleInlineList<T>({
  id,
  error,
  label,
  options,
  optionBuilder,
  optionIconBuilder,
  overrideOptionIcon,
  value,
  setValue,
  loading = false,
  compareFn,
  validation,
  required,
  children,
  pagination,
  overlay,
}: IInputSelectSingleInlineList<T>) {
  const wrapperClassName = useMemo(() => {
    let className = 'flex flex-col rounded-lg ring-1 overflow-hidden';

    if (error) {
      className += ' ring-status-error';
    } else {
      className += ' ring-gray-border';
    }

    return className;
  }, [error]);

  const listClassName = useMemo(() => {
    const className =
      'flex flex-col h-52 overflow-x-hidden overflow-y-auto rounded-b-lg relative py-2';

    return className;
  }, []);

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  const [paginationThresholdDiv, setPaginationThresholdDiv] =
    useState<HTMLDivElement | null>(null);
  const processingPage = useRef(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        const target = entries[0];

        if (
          target.isIntersecting &&
          processingPage.current === false &&
          !loading
        ) {
          processingPage.current = true;
          await pagination?.loadNextPage();
          processingPage.current = false;
        }
      },
      {
        root: null,
        rootMargin: '1px',
        threshold: 0,
      }
    );
    if (paginationThresholdDiv) {
      observer.observe(paginationThresholdDiv);
    }

    return () => {
      observer.disconnect();
    };
  }, [pagination, loading, paginationThresholdDiv]);

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full">
        {label && <CloudInput.Label label={label} required={required} />}
        <div className={wrapperClassName}>
          {children ?? null}

          <div className={listClassName}>
            {options.map((option, i) => {
              let isSelected = false;
              if (value !== undefined) {
                if (compareFn) {
                  isSelected = compareFn(value, option);
                } else {
                  isSelected = value === option;
                }
              }

              const icon = optionIconBuilder?.(option);

              return (
                <div
                  key={i}
                  ref={
                    i === options.length - (pagination?.threshold ?? 5)
                      ? setPaginationThresholdDiv
                      : null
                  }
                  className={`flex flex-row gap-2 px-4 py-2 transition-colors
                    shrink-0 cursor-pointer hover-overlay-5 truncate  ${
                      isSelected ? 'bg-primary bg-opacity-10' : ''
                    }`}
                  onClick={() => {
                    if (isSelected) {
                      setValue(undefined);
                    } else {
                      setValue(option);
                    }
                  }}
                >
                  {isSelected && !overrideOptionIcon ? (
                    <FontAwesomeIcon
                      icon={['far', 'check']}
                      className="shrink-0 size-4 py-1 text-primary"
                    />
                  ) : icon ? (
                    <FontAwesomeIcon
                      icon={['far', icon]}
                      className="shrink-0 size-4 py-1 text-gray-iconPrimary"
                    />
                  ) : (
                    <div className="size-4 shrink-0" />
                  )}
                  <div className="truncate">{optionBuilder(option)}</div>
                </div>
              );
            })}
            {loading && (
              <div className="h-10 w-full flex-center shrink-0">
                <APLoader.CircleNotch />
              </div>
            )}
            <div className="absolute inset-0 pointer-events-none">
              {overlay?.() ?? null}
            </div>
          </div>
        </div>
        {error && <CloudInput.Error error={error} />}
      </div>
    </InputIdContext.Provider>
  );
}

InputSelectSingleInlineList.BasicOverlay = CloudDatatable.BasicOverlay;

export { InputSelectSingleInlineList };
