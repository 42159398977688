import { User } from '@agerpoint/api';
import { AppBarProps, NavigationLink, UserClaims } from '@agerpoint/types';

import { hasClaims } from '../claims';

export function usersFirstInitial(user: User | undefined | null) {
  const userProfile = user?.userProfiles?.[0];
  return userProfile?.firstName?.[0] || '';
}

export const getAppBarProps = (user: User): AppBarProps => {
  return {
    navigation: getNavItems(user?.cloudClaims as UserClaims[]),
    firstLetterFirstName: usersFirstInitial(user),
  };
};

export const getNavItems = (
  cloudClaims: UserClaims[] = []
): NavigationLink[] => {
  const capturesItem = {
    label: 'Captures',
    link: '/captures',
    disabled: false,
  };
  const uploadsItem = {
    label: 'Uploads',
    link: '/uploads',
    disabled: false,
  };

  const defaultItems = [
    { label: 'Projects', link: '/projects', disabled: false },
  ];

  if (hasClaims([UserClaims.Cloud], cloudClaims)) {
    // Order of the array is important.
    defaultItems.unshift(capturesItem);
  }

  defaultItems.push(uploadsItem);

  return [...defaultItems];
};
