import { EventEmitter } from 'events';

import { ColorsThreeD, HexColor } from '@agerpoint/types';

export class CalloutLabel extends EventEmitter {
  private div: HTMLDivElement;
  private span: HTMLSpanElement;

  constructor(text = '', color: ColorsThreeD | HexColor, clickable = false) {
    super();

    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.top = '-1000';
    div.style.left = '-1000';
    // div.style.pointerEvents = 'none';
    div.style.cursor = clickable ? 'pointer' : 'default';
    div.style.color = 'white';
    div.style.fontSize = '14px';
    div.style.userSelect = 'none';
    div.style.height = '20px';
    div.style.backgroundColor = color;
    div.style.borderRadius = '2px';

    div.style.display = 'flex';
    div.style.justifyContent = 'center';
    div.style.alignItems = 'center';
    this.div = div;

    const span = document.createElement('span');
    span.innerText = text;
    span.style.display = 'block';
    span.style.padding = '0 5px';
    span.style.textWrap = 'nowrap';
    span.style.pointerEvents = 'none'; // Prevent pointer events on the span
    this.span = span;
    this.div.appendChild(span);
    let clickTimeout: NodeJS.Timeout;

    this.div.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    this.div.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      // Clear any existing timeout
      clearTimeout(clickTimeout);

      // Set a new timeout to emit the click event
      clickTimeout = setTimeout(() => {
        this.emit('label-click');
      }, 250); // 250ms delay to distinguish between click and double-click
    });

    this.div.addEventListener('mousedown', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    this.div.addEventListener('mouseup', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    this.div.addEventListener('wheel', (e) => {
      this.emit('label-wheel', e);
    });

    this.div.addEventListener('dblclick', (e) => {
      e.preventDefault();
      e.stopPropagation();

      // If double-click happens, cancel the click event
      clearTimeout(clickTimeout);
      this.emit('label-dblclick');
    });
  }

  get element(): HTMLDivElement {
    return this.div;
  }

  setText(text: string) {
    this.span.innerText = text;
  }

  setColor(color: ColorsThreeD | HexColor) {
    this.div.style.backgroundColor = color;
  }

  setVisibility(visible: boolean) {
    this.div.style.display = visible ? 'flex' : 'none';
  }

  highlight() {
    this.div.style.border = '2px solid white';
    this.div.style.height = '24px';
  }
  unHighlight() {
    this.div.style.border = 'none';
    this.div.style.height = '20px';
  }
  hide() {
    this.div.style.display = 'none';
  }
  show() {
    this.div.style.display = 'flex';
  }
}

export default CalloutLabel;
