import { colord } from 'colord';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { GetResponse } from '@agerpoint/api';
import {
  ExpansionContentContainer,
  ExpansionContentTabContentContainer,
  ExpansionContentTabsContainer,
  InputSlider,
  useCallbackRef,
} from '@agerpoint/component';
import { CustomSelect } from '@agerpoint/component';
import {
  Attributes,
  ColorStyle,
  LabelStyle,
  LabelType,
  Layer,
  LayerTypeName,
  SolidStyle,
  StrokePattern as StrokePatternType,
  WmsVectorMapLayer,
} from '@agerpoint/types';

import { CustomPicker } from '../color-picker/color-picker';
import { useColorPickerState } from '../color-picker/use-color-picker-state';
import { Categorized } from './categorized';
import {
  State as CategorizedState,
  createInitialState as categorizeCreateInitialState,
  initialState as categorizedInitialState,
  reducer as categorizedReducer,
} from './categorized-state';
import { Graduated } from './graduated';
import {
  State as GraduatedState,
  createInitialState,
  initialState as graduatedInitialState,
  reducer as graduatedReducer,
} from './graduated-state';
import { Label } from './label';
import { StyleProperty } from './style-property';
import { ColorValue, GradientColorValue, StrokePattern } from './vector-styles';

type FillColorType = 'solid' | 'graduated' | 'categorized' | 'transparent';

interface WmsVectorLayerStyleProps {
  layer: WmsVectorMapLayer;
  onChange: (layer: Layer) => Promise<void>;
  availableAttributes?: GetResponse<Attributes, void>;
}

export function WmsVectorLayerStyle({
  layer,
  onChange,
  availableAttributes,
}: WmsVectorLayerStyleProps) {
  const { currentColor: fillColor, setCurrentColor: setFillColor } =
    useColorPickerState(
      typeof layer.style.fillColor === 'string'
        ? layer.style.fillColor
        : layer.style.fillColor.type === 'solid'
        ? layer.style.fillColor.color
        : // Dummy fallback
          'white',
      layer.style.fillOpacity
    );

  const {
    currentColor: strokeColor,
    setCurrentColor: setStrokeColor,
    colorWithAlpha: strokeColorWithAlpha,
  } = useColorPickerState(
    typeof layer.style.strokeColor === 'string'
      ? layer.style.strokeColor
      : layer.style.strokeColor.type === 'solid'
      ? layer.style.strokeColor.color
      : // Dummy fallback
        'white',
    layer.style.strokeOpacity
  );

  const [currentStrokeWidth, setCurrentStrokeWidth] = useState<number[]>([
    layer.style.strokeWidth,
  ]);

  const [currentStrokePattern, setCurrentStrokePattern] =
    useState<StrokePatternType>('Solid');

  const [currentLabel, setCurrentLabel] = useState<LabelStyle>({
    type: LabelType.None,
  });

  const [mode, setMode] = useState<string>('fillColor');
  const [fillColorType, setFillColorType] = useState<FillColorType>('solid');

  const graduatedFillColor = useMemo((): GraduatedState => {
    if (
      typeof layer.style.fillColor !== 'string' &&
      layer.style.fillColor.type === 'graduated'
    ) {
      const { attribute, bins, breaksMethod, gradient } = layer.style.fillColor;

      return {
        attribute,
        bins,
        gradient,
        classes: bins.length,
        breaks: breaksMethod,
        opacity: layer.style.fillOpacity,
      };
    }
    return graduatedInitialState;
  }, [layer.style.fillColor, layer.style.fillOpacity]);

  const categorizedFillColor = useMemo((): CategorizedState => {
    if (
      typeof layer.style.fillColor !== 'string' &&
      layer.style.fillColor.type === 'categorized'
    ) {
      const { attribute, bins, gradient } = layer.style.fillColor;

      return {
        attribute,
        bins,
        gradient,
        opacity: layer.style.fillOpacity,
      };
    }
    return categorizedInitialState;
  }, [layer.style.fillColor, layer.style.fillOpacity]);

  const [graduatedState, graduatedDispatch] = useReducer(
    graduatedReducer,
    graduatedInitialState,
    () => createInitialState(graduatedFillColor)
  );

  const [categorizedState, categorizedDispatch] = useReducer(
    categorizedReducer,
    categorizedInitialState,
    () => categorizeCreateInitialState(categorizedFillColor)
  );

  useEffect(() => {
    if (
      typeof layer.style.fillColor === 'string' ||
      layer.style.fillColor.type === 'solid'
    ) {
      setFillColorType('solid');
    } else {
      setFillColorType(layer.style.fillColor.type);
    }
  }, [layer.style.fillColor]);

  useEffect(() => {
    setCurrentStrokeWidth([layer.style.strokeWidth]);
  }, [layer.style.strokeWidth]);

  useEffect(() => {
    setCurrentStrokePattern(layer.style.strokePattern || 'Solid');
  }, [layer.style.strokePattern]);

  useEffect(() => {
    setCurrentLabel(layer.style.label || LabelType.None);
  }, [layer.style.label]);

  const onChangeRef = useCallbackRef(onChange);
  const layerRef = useRef(layer);
  layerRef.current = layer;

  const onStyleChange = useCallback(
    (style: ColorStyle, opacity: number): Promise<void> => {
      const layer = layerRef.current;
      return onChangeRef.current({
        ...layer,
        style: {
          ...layer.style,
          type: fillColorType,
          fillColor: style,
          fillOpacity: opacity,
        },
      });
    },
    [onChangeRef, fillColorType]
  );

  const isCollection =
    layer.layerTypeName === LayerTypeName.Collections ||
    layer.layerTypeName === LayerTypeName.GeometryCollection;

  return (
    <ExpansionContentContainer>
      <ExpansionContentTabsContainer>
        <StyleProperty
          name="Fill Color"
          value={
            fillColorType === 'solid' ? (
              <ColorValue color={fillColor} />
            ) : fillColorType === 'graduated' ||
              fillColorType === 'categorized' ? (
              <GradientColorValue
                color={
                  fillColorType === 'graduated'
                    ? graduatedState.gradient
                    : categorizedState.gradient
                }
              />
            ) : null
          }
          selected={mode === 'fillColor'}
          onSelect={() => setMode('fillColor')}
        />
        <StyleProperty
          name="Outline Color"
          value={<ColorValue color={strokeColor} />}
          selected={mode === 'outlineColor'}
          onSelect={() => setMode('outlineColor')}
        />
        <StyleProperty
          name="Thickness"
          value={`${currentStrokeWidth} px`}
          selected={mode === 'thickness'}
          onSelect={() => setMode('thickness')}
        />
        <StyleProperty
          name="Outline Pattern"
          value={currentStrokePattern}
          selected={mode === 'strokePattern'}
          onSelect={() => setMode('strokePattern')}
        />
        <StyleProperty
          name="Label"
          value={
            currentLabel.type === LabelType.None
              ? 'None'
              : currentLabel.attribute
          }
          selected={mode === 'label'}
          onSelect={() => setMode('label')}
        />
      </ExpansionContentTabsContainer>
      <ExpansionContentTabContentContainer>
        {mode === 'fillColor' ? (
          <div>
            <CustomSelect
              label="Type"
              id="fillColorType"
              options={[
                { name: 'Solid', value: 'solid', disabled: false },
                {
                  name: 'Graduated',
                  value: 'graduated',
                  disabled: !isCollection,
                },
                {
                  name: 'Categorized',
                  value: 'categorized',
                  disabled: !isCollection,
                },
                { name: 'Transparent', value: 'transparent', disabled: true },
              ]}
              value={fillColorType}
              onChange={(event) => {
                setFillColorType(event.target.value as FillColorType);
              }}
            />
            {fillColorType === 'solid' && (
              <CustomPicker
                debounce={1000}
                color={fillColor}
                onChange={setFillColor}
                onSave={(color) =>
                  onStyleChange(
                    {
                      type: 'solid',
                      color: colord(color).alpha(1).toHex(),
                    } as SolidStyle,
                    colord(color).alpha()
                  )
                }
              />
            )}
            {fillColorType === 'graduated' && (
              <Graduated
                collectionId={layer.entityId}
                availableAttributes={availableAttributes}
                state={graduatedState}
                layerTypeId={layer.typeId}
                dispatch={graduatedDispatch}
                onChange={onStyleChange}
              />
            )}
            {fillColorType === 'categorized' && (
              <Categorized
                collectionId={layer.entityId}
                availableAttributes={availableAttributes}
                state={categorizedState}
                layerTypeId={layer.typeId}
                dispatch={categorizedDispatch}
                onChange={onStyleChange}
              />
            )}
          </div>
        ) : mode === 'outlineColor' ? (
          <CustomPicker
            debounce={1000}
            color={strokeColor}
            onChange={setStrokeColor}
            onClear={
              strokeColorWithAlpha !== strokeColor
                ? () => setStrokeColor(strokeColorWithAlpha)
                : undefined
            }
            onSave={(color) =>
              onChange({
                ...layer,
                style: {
                  ...layer.style,
                  strokeColor: colord(color).alpha(1).toHex(),
                  strokeOpacity: colord(color).alpha(),
                },
              })
            }
          />
        ) : mode === 'thickness' ? (
          <InputSlider
            showLabels
            min={1}
            max={5}
            step={0.2}
            unit="px"
            values={currentStrokeWidth}
            onChange={setCurrentStrokeWidth}
            onFinalChange={(values) =>
              onChange({
                ...layer,
                style: {
                  ...layer.style,
                  strokeWidth: values?.[0] || layer.style.strokeWidth,
                },
              })
            }
          />
        ) : mode === 'strokePattern' ? (
          <StrokePattern
            onChange={(value) => {
              setCurrentStrokePattern(value);
              onChange({
                ...layer,
                style: {
                  ...layer.style,
                  strokePattern: value,
                },
              });
            }}
            strokePattern={currentStrokePattern}
          />
        ) : mode === 'label' ? (
          <Label
            collectionId={layer.entityId}
            labelStyle={currentLabel}
            layerTypeId={layer.typeId}
            graduatedAttributes={
              availableAttributes ? availableAttributes.data.graduated : []
            }
            categorizedAttributes={
              availableAttributes
                ? // Attributes can sometimes show as both graduated and categorized, filter out duplicates
                  availableAttributes.data.categorized.filter(
                    (categorizedAttribute) =>
                      !availableAttributes.data.graduated.some(
                        (graduatedAttribute) =>
                          graduatedAttribute === categorizedAttribute
                      )
                  )
                : []
            }
            onChange={(value) => {
              setCurrentLabel(value);
              onChange({
                ...layer,
                style: {
                  ...layer.style,
                  label: value,
                },
              });
            }}
          />
        ) : null}
      </ExpansionContentTabContentContainer>
    </ExpansionContentContainer>
  );
}
