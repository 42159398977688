const tagStore: Map<string, string[]> = new Map();

export function generateTags(tagName: string): Array<string> {
  let tags = [];
  if (tagStore.has(tagName) === true) {
    tags = tagStore.get(tagName) as Array<string>;
  } else {
    tags = formatTags(randomTags());
    tagStore.set(tagName, tags);
  }

  return tags;
}

function formatTags(tags: Set<string>): Array<string> {
  const MAX_TAGS_LENGTH = 24;
  const displayTags = [];
  let length = 0;
  let tagCount = 0;
  const tagArray = Array.from(tags);
  for (const tag of tagArray) {
    if (length + tag.length + 1 < MAX_TAGS_LENGTH) {
      displayTags.push(tag);
      // TODO: all characters are not the same width, so this is just
      // an estimation.
      length += tag.length + 1;
      tagCount++;
    } else {
      displayTags.push(`+${tagArray.length - tagCount}`);
      return displayTags;
    }
  }

  return displayTags;
}

/**
 * Generates random unique keys (tags).
 *
 * This was added to assit in development as tags are not available yet in API
 *
 * @example
 * ```
 * randomTags().forEach((value, key) => console.log(key));
 * ```
 *
 * @returns a Set of keys
 */
const randomTags = (): Set<string> => {
  const results = new Set<string>();
  const tags = [
    'Orange',
    'Trial 203',
    'Trial 240',
    'Almond',
    'CA',
    'Research',
    'Cacao',
    'Yearly',
    'Insurance',
    'Wind Damage',
    'Estimation',
  ];

  const count = Math.floor(Math.random() * 12);
  for (let i = 0; i < count; i++) {
    const tagPointer = Math.floor(Math.random() * tags.length);
    results.add(tags[tagPointer]);
  }

  return results;
};
