/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { Role, RoleDto, RoleIdsPayload } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a new role with the provided details.
 * @summary Creates a new role
 */
export const create = (
  role: BodyType<Role>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Role>(
    {
      url: `/api/Roles`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: role,
    },
    options
  );
};

export const getCreateMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof create>>,
    TError,
    { data: BodyType<Role> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof create>>,
  TError,
  { data: BodyType<Role> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof create>>,
    { data: BodyType<Role> }
  > = (props) => {
    const { data } = props ?? {};

    return create(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof create>>
>;
export type CreateMutationBody = BodyType<Role>;
export type CreateMutationError = ErrorType<void>;

/**
 * @summary Creates a new role
 */
export const useCreate = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof create>>,
    TError,
    { data: BodyType<Role> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof create>>,
  TError,
  { data: BodyType<Role> },
  TContext
> => {
  const mutationOptions = getCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates the role with the specified ID.
 * @summary Updates a role by ID
 */
export const update = (
  id: number,
  role: BodyType<Role>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Role>(
    {
      url: `/api/Roles/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: role,
    },
    options
  );
};

export const getUpdateMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof update>>,
    TError,
    { id: number; data: BodyType<Role> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof update>>,
  TError,
  { id: number; data: BodyType<Role> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof update>>,
    { id: number; data: BodyType<Role> }
  > = (props) => {
    const { id, data } = props ?? {};

    return update(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof update>>
>;
export type UpdateMutationBody = BodyType<Role>;
export type UpdateMutationError = ErrorType<void>;

/**
 * @summary Updates a role by ID
 */
export const useUpdate = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof update>>,
    TError,
    { id: number; data: BodyType<Role> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof update>>,
  TError,
  { id: number; data: BodyType<Role> },
  TContext
> => {
  const mutationOptions = getUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all roles in the system.
 * @summary Gets all roles
 */
export const getRoles = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Role[]>(
    { url: `/api/Roles`, method: 'GET', signal },
    options
  );
};

export const getGetRolesQueryKey = () => {
  return [`/api/Roles`] as const;
};

export const getGetRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof getRoles>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getRoles>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRolesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoles>>> = ({
    signal,
  }) => getRoles(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRoles>>
>;
export type GetRolesQueryError = ErrorType<void>;

/**
 * @summary Gets all roles
 */
export const useGetRoles = <
  TData = Awaited<ReturnType<typeof getRoles>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getRoles>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRolesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the role with the specified ID.
 * @summary Gets a role by ID
 */
export const getRole = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<RoleDto>(
    {
      url: `/api/Roles/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetRoleQueryKey = (id: number) => {
  return [`/api/Roles/${id}`] as const;
};

export const getGetRoleQueryOptions = <
  TData = Awaited<ReturnType<typeof getRole>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getRole>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRoleQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRole>>> = ({
    signal,
  }) => getRole(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getRole>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetRoleQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRole>>
>;
export type GetRoleQueryError = ErrorType<void>;

/**
 * @summary Gets a role by ID
 */
export const useGetRole = <
  TData = Awaited<ReturnType<typeof getRole>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getRole>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRoleQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Sets the archive status of a role to true or false.
 * @summary Archives or unarchives a role by ID
 */
export const archiveRole = (
  id: number,
  archive: boolean,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Roles/${encodeURIComponent(
        String(id)
      )}/archive/${encodeURIComponent(String(archive))}`,
      method: 'PATCH',
    },
    options
  );
};

export const getArchiveRoleMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof archiveRole>>,
    TError,
    { id: number; archive: boolean },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof archiveRole>>,
  TError,
  { id: number; archive: boolean },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof archiveRole>>,
    { id: number; archive: boolean }
  > = (props) => {
    const { id, archive } = props ?? {};

    return archiveRole(id, archive, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof archiveRole>>
>;

export type ArchiveRoleMutationError = ErrorType<void>;

/**
 * @summary Archives or unarchives a role by ID
 */
export const useArchiveRole = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof archiveRole>>,
    TError,
    { id: number; archive: boolean },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof archiveRole>>,
  TError,
  { id: number; archive: boolean },
  TContext
> => {
  const mutationOptions = getArchiveRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all roles associated with the specified user ID.
 * @summary Gets roles for a user
 */
export const getUserRoles = (
  userId: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Role[]>(
    {
      url: `/api/Roles/user/${encodeURIComponent(String(userId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetUserRolesQueryKey = (userId: string) => {
  return [`/api/Roles/user/${userId}`] as const;
};

export const getGetUserRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserRoles>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserRoles>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserRolesQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRoles>>> = ({
    signal,
  }) => getUserRoles(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserRoles>>
>;
export type GetUserRolesQueryError = ErrorType<void>;

/**
 * @summary Gets roles for a user
 */
export const useGetUserRoles = <
  TData = Awaited<ReturnType<typeof getUserRoles>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserRoles>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserRolesQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Adds the specified roles to the user with the given user ID.
 * @summary Adds roles to a user
 */
export const addRolesToUser = (
  userId: string,
  roleIdsPayload: BodyType<RoleIdsPayload>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Role>(
    {
      url: `/api/Roles/user/${encodeURIComponent(String(userId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: roleIdsPayload,
    },
    options
  );
};

export const getAddRolesToUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addRolesToUser>>,
    TError,
    { userId: string; data: BodyType<RoleIdsPayload> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addRolesToUser>>,
  TError,
  { userId: string; data: BodyType<RoleIdsPayload> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addRolesToUser>>,
    { userId: string; data: BodyType<RoleIdsPayload> }
  > = (props) => {
    const { userId, data } = props ?? {};

    return addRolesToUser(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddRolesToUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof addRolesToUser>>
>;
export type AddRolesToUserMutationBody = BodyType<RoleIdsPayload>;
export type AddRolesToUserMutationError = ErrorType<void>;

/**
 * @summary Adds roles to a user
 */
export const useAddRolesToUser = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addRolesToUser>>,
    TError,
    { userId: string; data: BodyType<RoleIdsPayload> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addRolesToUser>>,
  TError,
  { userId: string; data: BodyType<RoleIdsPayload> },
  TContext
> => {
  const mutationOptions = getAddRolesToUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Removes the specified roles from the user with the given user ID.
 * @summary Removes roles from a user
 */
export const removeRolesFromUser = (
  userId: string,
  removeRolesFromUserBody: BodyType<number[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Role>(
    {
      url: `/api/Roles/user/${encodeURIComponent(String(userId))}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: removeRolesFromUserBody,
    },
    options
  );
};

export const getRemoveRolesFromUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeRolesFromUser>>,
    TError,
    { userId: string; data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeRolesFromUser>>,
  TError,
  { userId: string; data: BodyType<number[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeRolesFromUser>>,
    { userId: string; data: BodyType<number[]> }
  > = (props) => {
    const { userId, data } = props ?? {};

    return removeRolesFromUser(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveRolesFromUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeRolesFromUser>>
>;
export type RemoveRolesFromUserMutationBody = BodyType<number[]>;
export type RemoveRolesFromUserMutationError = ErrorType<void>;

/**
 * @summary Removes roles from a user
 */
export const useRemoveRolesFromUser = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeRolesFromUser>>,
    TError,
    { userId: string; data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeRolesFromUser>>,
  TError,
  { userId: string; data: BodyType<number[]> },
  TContext
> => {
  const mutationOptions = getRemoveRolesFromUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Adds the specified claims to the role with the given role ID.
 * @summary Adds claims to a role
 */
export const addClaimsToRoles = (
  roleId: number,
  addClaimsToRolesBody: BodyType<number[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Role>(
    {
      url: `/api/Roles/${encodeURIComponent(String(roleId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: addClaimsToRolesBody,
    },
    options
  );
};

export const getAddClaimsToRolesMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addClaimsToRoles>>,
    TError,
    { roleId: number; data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addClaimsToRoles>>,
  TError,
  { roleId: number; data: BodyType<number[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addClaimsToRoles>>,
    { roleId: number; data: BodyType<number[]> }
  > = (props) => {
    const { roleId, data } = props ?? {};

    return addClaimsToRoles(roleId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddClaimsToRolesMutationResult = NonNullable<
  Awaited<ReturnType<typeof addClaimsToRoles>>
>;
export type AddClaimsToRolesMutationBody = BodyType<number[]>;
export type AddClaimsToRolesMutationError = ErrorType<void>;

/**
 * @summary Adds claims to a role
 */
export const useAddClaimsToRoles = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addClaimsToRoles>>,
    TError,
    { roleId: number; data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addClaimsToRoles>>,
  TError,
  { roleId: number; data: BodyType<number[]> },
  TContext
> => {
  const mutationOptions = getAddClaimsToRolesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes the role with the specified role ID.
 * @summary Deletes a role
 */
export const removeClaimsFromUser = (
  roleId: number,
  removeClaimsFromUserBody: BodyType<number[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Role>(
    {
      url: `/api/Roles/${encodeURIComponent(String(roleId))}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: removeClaimsFromUserBody,
    },
    options
  );
};

export const getRemoveClaimsFromUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeClaimsFromUser>>,
    TError,
    { roleId: number; data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeClaimsFromUser>>,
  TError,
  { roleId: number; data: BodyType<number[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeClaimsFromUser>>,
    { roleId: number; data: BodyType<number[]> }
  > = (props) => {
    const { roleId, data } = props ?? {};

    return removeClaimsFromUser(roleId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveClaimsFromUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeClaimsFromUser>>
>;
export type RemoveClaimsFromUserMutationBody = BodyType<number[]>;
export type RemoveClaimsFromUserMutationError = ErrorType<void>;

/**
 * @summary Deletes a role
 */
export const useRemoveClaimsFromUser = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeClaimsFromUser>>,
    TError,
    { roleId: number; data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeClaimsFromUser>>,
  TError,
  { roleId: number; data: BodyType<number[]> },
  TContext
> => {
  const mutationOptions = getRemoveClaimsFromUserMutationOptions(options);

  return useMutation(mutationOptions);
};
