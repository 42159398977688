import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';

export const updateInfiniteQueryCache = <T,>({
  queryClient,
  queryKey,
  id,
  accessor,
  data,
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
  id: T[keyof T];
  accessor: keyof T;
  data: T;
}) => {
  queryClient.setQueriesData<InfiniteData<T[]>>(
    { queryKey: queryKey },
    (oldData) => {
      if (oldData?.pages) {
        return {
          ...oldData,
          pages: oldData.pages.map((page) =>
            page.map((u) => (u[accessor] === id ? data : u))
          ),
        };
      }
      return oldData;
    }
  );
};

export const updateQueryCache = <T,>({
  queryClient,
  queryKey,
  data,
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
  data: T;
}) => {
  queryClient.setQueryData<T>(queryKey, () => data);
};

export const updateListQueryCache = <T,>({
  queryClient,
  queryKey,
  id,
  accessor,
  data,
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
  id: T[keyof T];
  accessor: keyof T;
  data: T;
}) => {
  queryClient.setQueryData<T[]>(queryKey, (old) =>
    old?.map((d) => (d[accessor] === id ? data : d))
  );
};
