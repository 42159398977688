export enum LdFlags {
  AgerpointDemos = 'agerpoint-demos',
  CapturePointCloudClassification = 'capture-point-cloud-classification',
  CaptureObjectApfGeometry = 'capture-object-apf-geometry',
  CaptureObjectApfGeometryConvexHull = 'capture-object-apf-geometry-convex-hull',
  CropTool3DViewer = 'crop-tool-3-d-viewer',
  OrganizationManagement = 'organization-management',
  PotreePointSize = 'potree-point-size',
  PotreePointSizeType = 'potree-point-size-type',
  PotreePointBudget = 'potree-point-budget',
  PotreeUseHighQuality = 'potree-use-high-quality',
  PotreeImagePrism = 'potree-image-prism',
  PotreeAutoScrollGallery = 'potree-auto-scroll-gallery',
  ReportingPage = 'reporting-page',
  CaptureListMapView = 'capture-list-map-view',
  SelfServeAnalytics = 'self-serve-analytics',
  CaptureViewerSidebarSummer2023 = 'capture-viewer-sidebar-summer-2023',
  CaptureAnalyticsSidebar = 'capture-analytics-sidebar',
  CaptureAnalyticsSidebarSelfToggle = 'capture-analytics-sidebar-self-toggle',
  AnalyticsEditOrganizationDetails = 'analytics-edit-organization-details',
  AnalyticRequestManagement = 'analytic-request-management',
  CaptureReport = 'capture-report',
  HighDefinitionJobType = 'high-definition-job-type',
  ThreeDModelPreview = 'three-d-model-preview',
  ExtractionsUploadButton = 'extractions-upload-button',
  Debug3dFeatures = 'debug-3-d-features',
  ReactQueryDevTools = 'react-query-dev-tools',
  PaginatedLibrary = 'paginated-library',
  CaptureAnalyticAHJSubmit = 'capture-analytic-ahj-submit',
  Measurements3D = 'measurements-3-d',
  Measurements2D = 'measurements-2-d',
  ModelRatingWidget = 'model-rating-widget',
  M2App = 'm2-app',
  M2AppDirectProjectPageNavigation = 'vite-app-project-page-direct-navigation',
  M2Polygon3DAnnotation = 'm2-polygon-3d-annotation',
  ForceM2App = 'force-m-2-app',
  MaxCapturesForBulkActions = 'max-captures-for-bulk-actions',
  EnableCourierMessaging = 'enable-courier-messaging',
}
