import { useCallback } from 'react';

import { usePutLayerGroup } from '@agerpoint/api';
import { toApiGroup } from '@agerpoint/utilities';

import { isClientSideGroup, useProject } from '../state/use-project';

export function useSaveAndPersistGroupZIndex(
  setGroupZIndex: (groupId: number, zIndex: number) => void
) {
  const { mutate: putLayerGroup } = usePutLayerGroup({ id: NaN });

  const saveAndPersistGroupZIndex = useCallback(persistGroupZIndex, [
    putLayerGroup,
    setGroupZIndex,
  ]);

  async function persistGroupZIndex(id: number, zIndex: number) {
    setGroupZIndex(id, zIndex);
    const { groups } = useProject.getState();
    await Promise.all(
      groups
        .filter((group) => !isClientSideGroup(group))
        .map((group) =>
          putLayerGroup(toApiGroup(group), { pathParams: { id: group.id } })
        )
    );
  }

  return saveAndPersistGroupZIndex;
}
