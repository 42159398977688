import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import { useIsViteApp, useLookupTable, useToasts } from '@agerpoint/utilities';

export const useAdminMosaicEnginesQueries = (archivedFilter?: boolean) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const toasts = useToasts();

  const isViteApp = useIsViteApp();

  const { mosaicEngineId } = useParams();

  const mosaicEngineQuery = APIClient.useGetMosaicEngineById(
    Number(mosaicEngineId),
    {
      query: {
        enabled: Number.isSafeInteger(Number(mosaicEngineId)),
        queryKey: [
          APIUtils.QueryKey.mosaicEngines,
          { mosaicEngineId: Number(mosaicEngineId) },
        ],
        initialData: () =>
          APIUtils.searchQueriesForInitialValue<APIModels.MosaicEngine>({
            queryClient,
            queryKey: [APIUtils.QueryKey.mosaicEngines],
            id: Number(mosaicEngineId),
            accessor: 'id',
          }),
        staleTime: APIUtils.getDuration({
          seconds: 20,
        }),
        retry: 0,
      },
    }
  );

  const pipelinesQuery = APIClient.useGetPipelines({
    query: {
      queryKey: [APIUtils.QueryKey.pipelines],
      select: (data) => APIUtils.Sort.pipelines(data),
    },
  });

  useEffect(() => {
    if (mosaicEngineId === undefined) {
      return;
    }

    if (!Number.isSafeInteger(Number(mosaicEngineId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/mosaic-engines' + params);
      } else {
        navigate('/admin/mosaic-engines' + params);
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.mosaicEngines,
          { mosaicEngineId: Number(mosaicEngineId) },
        ],
      });
    }
  }, [mosaicEngineId]);

  const mosaicEnginePutMutation = APIClient.usePutMosaicEngineById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.mosaicEngines],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.MosaicEngine>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.mosaicEngines,
            { mosaicEngineId: variables.data.id },
          ],
          data: variables.data,
        });

        APIUtils.updateListQueryCache<APIModels.MosaicEngine>({
          queryClient,
          queryKey: [APIUtils.QueryKey.mosaicEngines, { archived: true }],
          data: variables.data,
          accessor: 'id',
          id: variables.data.id,
        });

        APIUtils.updateListQueryCache<APIModels.MosaicEngine>({
          queryClient,
          queryKey: [APIUtils.QueryKey.mosaicEngines, { archived: false }],
          data: variables.data,
          accessor: 'id',
          id: variables.data.id,
        });

        toasts.add(toasts.prepare.entityUpdated('mosaic engine'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update mosaic engine!'));
      },
    },
  });

  const mosaicEngineArchivePutMutation = APIClient.usePutMosaicEngineById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.mosaicEngines],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.MosaicEngine>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.mosaicEngines,
            { mosaicEngineId: variables.data.id },
          ],
          data: variables.data,
        });
        toasts.add(toasts.prepare.entityArchived('mosaic engine'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to archive mosaic engine!'));
      },
    },
  });

  const mosaicEngineUnarchivePutMutation = APIClient.usePutMosaicEngineById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.mosaicEngines],
        });
      },
      onSuccess: (_, variables) => {
        APIUtils.updateQueryCache<APIModels.MosaicEngine>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.mosaicEngines,
            { mosaidEngineId: variables.data.id },
          ],
          data: variables.data,
        });

        toasts.add(toasts.prepare.entityRestored('mosaic engine'));
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to restore mosaic engine!'));
      },
    },
  });

  const mosaicEnginesQuery = APIClient.useGetMosaicEngines({
    query: {
      queryKey: [APIUtils.QueryKey.mosaicEngines, { archived: archivedFilter }],
      staleTime: APIUtils.getDuration({
        seconds: 20,
      }),
      select: (data) => data.filter((d) => d.archived === archivedFilter),
      enabled: archivedFilter !== undefined,
    },
  });

  const pipelinesLookupTable = useLookupTable(pipelinesQuery.data, 'id');

  const mosaicEnginePostMutation = APIClient.usePostMosaicEngine({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.mosaicEngines],
        });
      },
      onSuccess: (data) => {
        APIUtils.updateQueryCache<APIModels.MosaicEngine>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.mosaicEngines,
            { mosaidEngineId: data.id },
          ],
          data: data,
        });
        toasts.add(toasts.prepare.entityCreated('mosaic engine'));

        if (isViteApp) {
          navigate(`/app/admin/platform/mosaic-engines/${data.id}/details`, {
            state: { params },
          });
        } else {
          navigate(`/admin/mosaic-engines/${data.id}/details`, {
            state: { params },
          });
        }
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create mosaic engine!'));
      },
    },
  });

  return {
    mosaicEngineQuery,
    pipelinesQuery,
    mosaicEnginePutMutation,
    mosaicEngineArchivePutMutation,
    mosaicEngineUnarchivePutMutation,
    mosaicEnginesQuery,
    pipelinesLookupTable,
    mosaicEnginePostMutation,
  };
};
