import { ReactElement, useContext } from 'react';

import { RefContext, StyleContext } from '../datatable.types';
import { useCloudDatatableHandleShowHorizontalScroll } from '../datatable.utilities';

interface IWrapper {
  children: ReactElement;
}

export const Wrapper = ({ children }: IWrapper) => {
  const style = useContext(StyleContext);
  const refs = useContext(RefContext);

  const { showHorizontalScroll } = useCloudDatatableHandleShowHorizontalScroll({
    wrapperRef: refs?.wrapperRef ?? null,
  });

  const className = `${style?.wrapper ?? ''} flex flex-col`;

  return (
    <div
      className={className}
      ref={refs?.setWrapperRef}
      style={{
        overflowX: showHorizontalScroll ? 'scroll' : 'hidden',
        overflowY: 'hidden',
      }}
    >
      {children}
    </div>
  );
};
