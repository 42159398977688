import {
  CaptureModalNavigationProps,
  CaptureModalTabs,
} from '@agerpoint/types';

const analyticModalPaths = [
  '/captures/:id/analytics/:analyticUriName',
  '/captures/:id/:eptId/analytics/:analyticUriName',
  '/admin/captures/:id/analytics/:analyticUriName',
];

const mainCaptureTableModalPathsLookup: CaptureModalNavigationProps = {
  analytics: '/captures/manage/:id/analytics',
  details: '/captures/manage/:id/details',
  models: '/captures/manage/:id/models',
  projects: '/captures/manage/:id/projects',
};

const adminCaptureTableModalPathsLookup: CaptureModalNavigationProps = {
  analytics: '/admin/captures/:id/analytics',
  details: '/admin/captures/:id/details',
  models: '/admin/captures/:id/models',
  projects: '/admin/captures/:id/projects',
};

const captureViewer2ModalPathsLookup: CaptureModalNavigationProps = {
  analytics: '/captures/:id/analytics',
  details: '/captures/:id/details',
  models: '/captures/:id/models',
  projects: '/captures/:id/projects',
};

const captureViewerModalPathsLookup: CaptureModalNavigationProps = {
  analytics: '/captures/:id/:eptId/analytics',
  details: '/captures/:id/:eptId/details',
  models: '/captures/:id/:eptId/models',
  projects: '/captures/:id/:eptId/projects',
};

const capturePipelineModalPathsLookup: CaptureModalNavigationProps = {
  analytics: '/ops/pipeline/captures/:id/analytics',
  details: '/ops/pipeline/captures/:id/details',
  models: '/ops/pipeline/captures/:id/models',
  projects: '/ops/pipeline/captures/:id/projects',
};

const mainCaptureTablePaths = [
  mainCaptureTableModalPathsLookup.analytics,
  mainCaptureTableModalPathsLookup.details,
  mainCaptureTableModalPathsLookup.models,
  mainCaptureTableModalPathsLookup.projects,
];

const adminCaptureTablePaths = [
  adminCaptureTableModalPathsLookup.analytics,
  adminCaptureTableModalPathsLookup.details,
  adminCaptureTableModalPathsLookup.models,
  adminCaptureTableModalPathsLookup.projects,
];

const captureViewer2ModalPaths = [
  captureViewer2ModalPathsLookup.analytics,
  captureViewer2ModalPathsLookup.details,
  captureViewer2ModalPathsLookup.models,
  captureViewer2ModalPathsLookup.projects,
];

const captureViewerModalPaths = [
  captureViewerModalPathsLookup.analytics,
  captureViewerModalPathsLookup.details,
  captureViewerModalPathsLookup.models,
  captureViewerModalPathsLookup.projects,
];

const capturePipelineModalPaths = [
  capturePipelineModalPathsLookup.analytics,
  capturePipelineModalPathsLookup.details,
  capturePipelineModalPathsLookup.models,
  capturePipelineModalPathsLookup.projects,
];

const buildNavigationTabs = (
  search: string,
  lookup: CaptureModalNavigationProps,
  id: number,
  eptId?: number
): CaptureModalNavigationProps => {
  const analytics = `${lookup.analytics
    .replace(':id', `${id}`)
    .replace(':eptId', `${eptId}`)}${search}`;
  const details = `${lookup.details
    .replace(':id', `${id}`)
    .replace(':eptId', `${eptId}`)}${search}`;
  const models = `${lookup.models
    .replace(':id', `${id}`)
    .replace(':eptId', `${eptId}`)}${search}`;
  const projects = `${lookup.projects
    .replace(':id', `${id}`)
    .replace(':eptId', `${eptId}`)}${search}`;

  return {
    analytics,
    details,
    models,
    projects,
  };
};

export const allCaptureModalPaths = [
  ...captureViewer2ModalPaths,
  ...captureViewerModalPaths,
  ...mainCaptureTablePaths,
  ...adminCaptureTablePaths,
  ...analyticModalPaths,
  ...capturePipelineModalPaths,
];

export const getTabNavigationRoutes = (
  search: string,
  currentPath: string,
  captureId: number,
  eptId?: string
): CaptureModalNavigationProps => {
  if (currentPath.includes('manage')) {
    return buildNavigationTabs(
      search,
      mainCaptureTableModalPathsLookup,
      captureId
    );
  } else if (captureId && eptId) {
    return buildNavigationTabs(
      search,
      captureViewerModalPathsLookup,
      captureId,
      parseInt(eptId)
    );
  } else if (captureId) {
    return buildNavigationTabs(
      search,
      captureViewer2ModalPathsLookup,
      captureId,
      eptId ? parseInt(eptId) : NaN
    );
  } else {
    return buildNavigationTabs(search, mainCaptureTableModalPathsLookup, NaN);
  }
};

export const getGoBackRoute = (
  search: string,
  currentPath: string,
  captureId: number,
  eptId?: string
) => {
  if (currentPath.includes('manage')) {
    return `/captures${search}`;
  } else if (captureId && eptId) {
    return `/captures/${captureId}/${eptId}${search}`;
  } else if (captureId) {
    return `/captures/${captureId}${search}`;
  } else {
    return `/`;
  }
};

export const getContextMenuLink = (
  tabName: CaptureModalTabs,
  isAdmin: boolean | undefined,
  captureId: number,
  search: string
) => {
  if (isAdmin) {
    return `/admin/captures/${captureId}/${tabName}${search}`;
  } else {
    return `/captures/manage/${captureId}/${tabName}${search}`;
  }
};
