interface getDurationProps {
  seconds?: number;
  minutes?: number;
  hours?: number;
}

/**
 * Calculates the duration in milliseconds based on the provided seconds, minutes, and hours.
 * @param seconds The number of seconds.
 * @param minutes The number of minutes.
 * @param hours The number of hours.
 * @returns The duration in milliseconds.
 */
export const getDuration = ({ seconds, minutes, hours }: getDurationProps) => {
  const duration =
    (seconds || 0) + (minutes || 0) * 60 + (hours || 0) * 60 * 60;

  return duration * 1000;
};
