import { IconButton, PrimaryButton } from '@agerpoint/component';
import { ReactNode, useState } from 'react';
import { faCircleNotch, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation } from '@fortawesome/pro-light-svg-icons';

type ConfirmProps = {
  message: ReactNode;
  confirmLabel: string;
  onConfirm: () => Promise<void>;
};

type ShowConfirmFn = (props: ConfirmProps) => void;

interface ExpansionToolbarChildProps {
  isConfirming: boolean;
  showConfirm: ShowConfirmFn;
}

interface ExpansionToolbar {
  children: (props: ExpansionToolbarChildProps) => ReactNode;
}

export function ExpansionToolbar({ children }: ExpansionToolbar) {
  const [confirm, setConfirm] = useState<ConfirmProps>();
  const [isConfirming, setIsConfirming] = useState(false);

  const showConfirm: ShowConfirmFn = (props) => {
    setConfirm(props);
  };

  return (
    <div className="mt-auto">
      {confirm ? (
        <div className="flex flex-col space-y-2 mb-5">
          <h4 className="font-bold text-sm">Are you sure?</h4>
          <p className="text-xs">{confirm.message}</p>
          <PrimaryButton
            size={'small'}
            theme={'danger'}
            disabled={isConfirming}
            label={confirm.confirmLabel}
            onClicked={onConfirm}
          />
          <PrimaryButton
            size={'small'}
            disabled={isConfirming}
            label="Cancel"
            theme="white"
            onClicked={() => setConfirm(undefined)}
          />
        </div>
      ) : null}
      <div className="flex">{children({ showConfirm, isConfirming })}</div>
    </div>
  );

  function onConfirm() {
    setIsConfirming(true);
    confirm?.onConfirm().finally(() => setIsConfirming(false));
  }
}

interface ExpansionDeleteProps {
  message: string;
  deleteLabel: string;
  isConfirming: boolean;
  onDelete: () => Promise<void>;
  showConfirm: ShowConfirmFn;
}

export function ExpansionDelete({
  onDelete,
  message,
  deleteLabel,
  isConfirming,
  showConfirm,
}: ExpansionDeleteProps) {
  return (
    <IconButton
      className={`ml-auto ${
        !isConfirming ? 'text-red-300 hover:text-red' : 'text-red-300'
      }`}
      show
      disabled={isConfirming}
      onClick={() =>
        showConfirm({ message, confirmLabel: deleteLabel, onConfirm: onDelete })
      }
      icon={
        <FontAwesomeIcon
          icon={!isConfirming ? faTrashAlt : faCircleNotch}
          className={`text-xl ${isConfirming ? 'fa-spin' : ''}`}
        />
      }
    />
  );
}

interface ZoomToExtent {
  onZoom: () => void;
}

export function ZoomToExtent({ onZoom }: ZoomToExtent) {
  return (
    <IconButton
      show
      className="mr-auto text-gray-500 hover:text-gray-900"
      onClick={onZoom}
      icon={<FontAwesomeIcon icon={faSearchLocation} className="text-xl" />}
    />
  );
}
