import { GetDataError } from 'restful-react';

/*
 * A file of pure functions
 */

/**
 *
 * @see https://stackoverflow.com/a/50431109/648789
 * @param value dotnet Date string format
 * @returns '7.29.2021'
 */

export function formatDate(value: string) {
  const date = new Date(value);
  return date.getMonth() + 1 + '.' + date.getDate() + '.' + date.getFullYear();
}

export const formatDateAndTime = (
  date: string | undefined | null
): string | undefined => {
  if (!date) {
    return '';
  }
  const d = new Date(date);

  let month: string | number = d.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;

  let day: string | number = d.getDate();
  day = day < 10 ? `0${day}` : day;

  const year = d.getFullYear();

  let hours = d.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  let minutes: string | number = d.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
};

export interface ErrorMessage {
  title: string;
  message: string;
}

export const isHttpError = (
  error: unknown | GetDataError<Response>
): error is Required<GetDataError<Response>> =>
  typeof (error as GetDataError<Response>)?.status === 'number';

/**
 * Creates a `ErrorMessage` object from a api Http error.
 */
export const parseHttpError = (
  error: unknown | GetDataError<Response>
): ErrorMessage => {
  const statusToMessage = (status: number): string => {
    switch (status) {
      case 401:
        return "You're not authenticated. Please check that you're signed in.";
      case 403:
        return 'You attempted to access a resource with insufficient rights.';
      case 404:
        return "You attempted to access a resource that doesn't exist.";
      default:
        return 'An unknown error just occurred.';
    }
  };

  const result = DefaultErrorMessage;

  if (isHttpError(error)) {
    result.title = 'Your request failed.';
    result.message = statusToMessage(error.status);
  }

  return result;
};

export const DefaultErrorMessage: ErrorMessage = {
  title:
    'We apologize for the inconvenience - something unexpected has occurred.',
  message: 'Please refresh your browser, try again.',
};
