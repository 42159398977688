import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import { useIsViteApp, useLookupTable, useToasts } from '@agerpoint/utilities';

export const useAdminOrganizationsQueries = (
  selectedCountry?: APIModels.Country
) => {
  const { organizationId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const toasts = useToasts();

  const organizationQuery = APIClient.useGetCustomerById(
    Number(organizationId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(organizationId) },
        ],
        staleTime: APIUtils.getDuration({ seconds: 20 }),
        enabled: Number.isSafeInteger(Number(organizationId)),
        retry: 0,
        initialData: () =>
          APIUtils.searchQueriesForInitialValue({
            queryClient,
            queryKey: [APIUtils.QueryKey.organizations],
            accessor: 'id',
            id: Number(organizationId),
          }),
      },
    }
  );

  const organizationAnalyticsQuery =
    APIClient.useGetCustomerAnalyticsByCustomerId(Number(organizationId), {
      query: {
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(organizationId) },
          APIUtils.QueryKey.analytics,
        ],
        select: (data) =>
          data
            ?.filter((d) => d.archived === false)
            ?.sort((a, b) => compare(a.priority, b.priority)),
      },
    });

  const analyticsQuery = APIClient.useGetAnalytic({
    query: {
      queryKey: [APIUtils.QueryKey.analytics],
      select: (data) =>
        APIUtils.Sort.analytics(data.filter((d) => d.archived === false)),
    },
  });

  const organizationAnalyticPostMutation = APIClient.usePostCustomerAnalytic({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.organizations,
            { organizationId: Number(organizationId) },
            APIUtils.QueryKey.analytics,
          ],
        });
      },
      onSuccess: () => {
        toasts.add(
          toasts.prepare.entityUpdated('organization', 'Analytic added.')
        );
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to add analytic!'));
      },
    },
  });

  const licenseQuery = APIClient.useGetCustomerLicenseByCustomerId(
    Number(organizationId),
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.organizationLicense,
          { organizationId: Number(organizationId) },
        ],
        staleTime: APIUtils.getDuration({ seconds: 20 }),
      },
    }
  );

  const newLicensePostMutation = APIClient.usePostCustomerLicense({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.organizationLicense,
            { organizationId: Number(organizationId) },
          ],
        });
      },
      onSuccess: () => {
        toasts.add(
          toasts.prepare.entityUpdated('organization', 'License updated.')
        );
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update license!'));
      },
    },
  });

  const existingLicensePutMutation = APIClient.usePutCustomerLicenseById({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.organizationLicense,
            { organizationId: Number(organizationId) },
          ],
        });
      },
      onSuccess: () => {
        toasts.add(
          toasts.prepare.entityUpdated('organization', 'License updated.')
        );
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to update license!'));
      },
    },
  });

  const regionsQuery = APIClient.useGetRegions({
    query: {
      queryKey: [APIUtils.QueryKey.regions],
    },
  });

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      staleTime: APIUtils.getDuration({ seconds: 20 }),
    },
  });

  useEffect(() => {
    if (organizationId === undefined) {
      return;
    }
    if (!Number.isSafeInteger(Number(organizationId))) {
      if (isViteApp) {
        navigate('/app/admin/platform/organizations');
      } else {
        navigate('/admin/organizations');
      }
      queryClient.removeQueries({
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(organizationId) },
        ],
      });
    }
  }, [organizationId]);

  const regionsLookupTable = useLookupTable(regionsQuery.data, 'id');

  const countriesQuery = APIClient.useGetCountries({
    query: {
      queryKey: [APIUtils.QueryKey.countries],
      select: (data) => APIUtils.Sort.countries(data),
    },
  });

  const usersQuery = APIClient.useGetUsersAvailibleFromCaptures({
    query: {
      queryKey: [APIUtils.QueryKey.usersCaptures],
      select: (data) => APIUtils.Sort.users(data),
    },
  });

  const organizationPostMutation = APIClient.usePostCustomer({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [APIUtils.QueryKey.organizations],
        });
      },
      onSuccess: (data) => {
        APIUtils.updateQueryCache<APIModels.Customer>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.organizations,
            { organizationId: data.id },
          ],
          data: data,
        });

        toasts.add(toasts.prepare.entityCreated('organization'));

        if (isViteApp) {
          navigate(`/app/admin/platform/organizations/${data.id}/details`, {
            state: { params },
          });
          return;
        } else {
          navigate(`/admin/organizations/${data.id}/details`, {
            state: { params },
          });
        }
      },
      onError: (e) => {
        console.error(e);

        toasts.add(toasts.prepare.error('Failed to create organization!'));
      },
    },
  });

  const countryRegionsQuery = APIClient.useGetRegionsByCountryId(
    selectedCountry?.id as number,
    {
      query: {
        queryKey: [
          APIUtils.QueryKey.countries,
          { countryId: selectedCountry?.id },
          APIUtils.QueryKey.regions,
        ],
        enabled: selectedCountry !== undefined,
        select: (data) => APIUtils.Sort.regions(data),
      },
    }
  );

  const organizationAnalyticsPriorityPutMutation = useMutation({
    mutationFn: async (data: {
      customerId: number;
      customerAnalytics: APIModels.CustomerAnalytic[];
    }) => {
      // optimistic update
      for (const item of data.customerAnalytics) {
        APIUtils.updateListQueryCache<APIModels.CustomerAnalytic>({
          queryClient,
          queryKey: [
            APIUtils.QueryKey.organizations,
            { organizationId: Number(data.customerId) },
            APIUtils.QueryKey.analytics,
          ],
          accessor: 'id',
          data: item,
          id: item.id,
        });
      }

      const copy = cloneDeep(data.customerAnalytics);

      copy.forEach((item) => {
        delete item.analytic;
      });

      const promises = [];

      for (const item of copy) {
        promises.push(
          APIClient.putCustomerAnalyticById(item.id as number, item)
        );
      }

      return Promise.all(promises);
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          APIUtils.QueryKey.organizations,
          { organizationId: Number(variables.customerId) },
          APIUtils.QueryKey.analytics,
        ],
      });
    },
    onSuccess: (_, variables) => {
      toasts.add(
        toasts.prepare.entityUpdated('organization', 'Analytics reprioritized.')
      );
    },
    onError: (e) => {
      console.error(e);

      toasts.add(toasts.prepare.error('Failed to reprioritize analytics!'));
    },
  });
  return {
    organizationQuery,
    organizationAnalyticsQuery,
    analyticsQuery,
    organizationAnalyticPostMutation,
    licenseQuery,
    newLicensePostMutation,
    existingLicensePutMutation,
    regionsQuery,
    organizationsQuery,
    regionsLookupTable,
    usersQuery,
    countriesQuery,
    organizationPostMutation,
    countryRegionsQuery,
    organizationAnalyticsPriorityPutMutation,
  };
};
