import { SwitchProps, RadioButton } from '@agerpoint/component';
import { ReactNode } from 'react';

interface RadioCardProps extends SwitchProps {
  children?: ReactNode;
}

export function RadioCard({ ...props }: RadioCardProps) {
  const { checked, children } = props;
  return (
    <RadioButton {...props}>
      <div
        className={`rounded w-full p-4  text-sm font-bold ${
          checked ? 'border-2 border-gray-900' : 'border m-px border-gray-300'
        }`}
      >
        {children}
      </div>
    </RadioButton>
  );
}
