import { APIModels } from '@agerpoint/api';

export const sortCustomerAnalytics = (
  a: APIModels.CustomerAnalytic,
  b: APIModels.CustomerAnalytic,
  customerField?: keyof APIModels.CustomerAnalytic,
  analyticField?: keyof APIModels.Analytic
): number => {
  // Fallback value when fields are missing
  const fallback = 0;

  let aValue: any, bValue: any;

  // Determine the field and value to sort by
  if (customerField) {
    aValue = a[customerField] ?? fallback;
    bValue = b[customerField] ?? fallback;
  } else if (analyticField && a.analytic && b.analytic) {
    aValue = a.analytic[analyticField] ?? fallback;
    bValue = b.analytic[analyticField] ?? fallback;
  } else {
    return 0; // Return equal if neither sort field is provided or if analytic is not present
  }

  // Handle comparison for different types of values
  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return aValue.localeCompare(bValue);
  } else if (typeof aValue === 'number' && typeof bValue === 'number') {
    return aValue - bValue;
  } else if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
    return aValue === bValue ? 0 : aValue ? -1 : 1;
  }

  return 0; // Default fallback if types are inconsistent or unhandled
};
