import { faCheck, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import {
  faCircleNotch,
  faFilterSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { InputIdContext } from '../input-id-context';
import { InputPlaceholderIcon } from '../input-placeholder-icons';
import { InputStyle } from '../input-style';
import { InputTextSingle } from '../input-text/input-text-single';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface InputSelectMultiProps<T> {
  /**
   * The unique identifier for the component.
   */
  id: string;

  /**
   * The error message to display.
   */
  error?: React.ReactNode;

  /**
   * The label to display.
   */
  label?: React.ReactNode;

  /**
   * The placeholder text to display when no value is selected.
   */
  placeholder?: string;

  /**
   * The placeholder icon to display when no value is selected.
   */
  placeholderIcon?: InputPlaceholderIcon;

  /**
   * The title of the select dropdown.
   */
  title: string;

  /**
   * Additional CSS classes for the component.
   */
  className?: string;

  /**
   * The options to display in the select dropdown.
   */
  options: T[];

  /**
   * The function to build the display text for each option.
   */
  optionBuilder: (
    option: T
  ) => string | { element: React.ReactNode; searchString: string };

  /**
   * Loading state of the component.
   */
  loading?: boolean;

  /**
   * The currently selected values.
   */
  value: T[];

  /**
   * Callback function to update the selected values.
   */
  setValue: (value: T[]) => void;

  /**
   * The maximum width of the component.
   */
  maxWidth?: string;

  /**
   * The maximum height of the select dropdown.
   */
  maxHeight?: string;

  /**
   * Whether to display a search input field.
   */
  search?: boolean;

  /**
   * Whether the component is disabled.
   */
  disabled?: boolean;

  /**
   * The style of the component.
   */
  style?: InputStyle;

  /**
   * Whether the component should stretch to fill its container.
   */
  stretch?: boolean;

  /**
   * Whether to display a "Clear All" button when there are selected values.
   */
  clearAllButton?: boolean;

  /**
   * A custom comparison function to determine if two values are equal.
   */
  compareFn?: (a: T, b: T) => boolean;

  /**
   * Validation configuration for the component.
   */
  validation?: InputValidation<T[]>;

  /**
   * Whether the component is read-only.
   */
  readOnly?: boolean;
}

const styles: { [key in InputStyle]: string } = {
  [InputStyle.default]: `flex flex-row gap-2 items-center text-sm text-gray-900 bg-white p-2
  shadow-sm rounded border border-gray-500 justify-between`,
  [InputStyle.mini]: `flex flex-row gap-1 items-center text-xs text-gray-900 bg-white p-1
  shadow-sm rounded border border-gray-500 justify-between`,
  [InputStyle.miniDark]: `flex flex-row gap-1 items-center text-xs text-gray-900 bg-white p-1
  shadow-sm rounded border border-gray-500 justify-between`,
  [InputStyle.cloud]: `flex flex-row gap-2 items-center text-sm text-gray-textPrimary bg-white p-2
  rounded-lg border border-gray-border justify-between`,
};

function InputSelectMulti<T>({
  id,
  error,
  label,
  options,
  optionBuilder,
  value,
  setValue,
  placeholder: placeholderProp,
  placeholderIcon,
  title,
  className,
  loading = false,
  maxWidth,
  maxHeight = '200px',
  search = true,
  disabled = false,
  style = InputStyle.default,
  stretch = false,
  clearAllButton = true,
  compareFn,
  validation,
  readOnly,
}: InputSelectMultiProps<T>) {
  const [expanded, setExpanded] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const placeholder = `${placeholderIcon ? String(placeholderIcon) : ''} ${
    placeholderProp ?? ''
  }`.trim();

  useEffect(() => {
    if (expanded) {
      setSearchInputValue('');
      if (search) {
        searchInputRef.current?.focus();
      }
    }
  }, [expanded]);

  useEffect(() => {
    if (disabled || readOnly) {
      setExpanded(false);
    }
  }, [disabled, readOnly]);

  const inputClassName = `${styles[style]} ${
    expanded ? 'ring-1 ring-green border-green' : ''
  } ${disabled ? 'opacity-50' : readOnly ? '' : 'cursor-pointer'} ${
    className || ''
  }`.trim();

  const filteredOptions = useMemo(() => {
    if (searchInputValue.trim().length === 0) {
      return options;
    }

    return options.filter((o) => {
      const builtOption = optionBuilder(o);
      const searchString =
        typeof builtOption === 'object'
          ? builtOption.searchString
          : builtOption;

      return searchString
        .toLowerCase()
        .includes(searchInputValue.trim().toLowerCase());
    });
  }, [options, searchInputValue, optionBuilder]);

  const buildOption = useCallback(
    (value: T) => {
      const builtOption = optionBuilder(value);
      return typeof builtOption === 'object'
        ? builtOption.element
        : builtOption;
    },
    [optionBuilder]
  );

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  return (
    <InputIdContext.Provider value={id}>
      <div
        className={`relative select-none ${stretch ? 'w-full' : ''} ${
          expanded ? 'z-50' : ''
        }`.trim()}
        onBlur={(e) => {
          if (
            search &&
            searchInputRef.current?.contains(e.relatedTarget as Node)
          ) {
            return;
          }

          if (inputRef.current?.contains(e.relatedTarget as Node)) {
            return;
          }

          setExpanded(false);
        }}
        tabIndex={0}
        ref={inputRef}
      >
        <div className={`flex flex-col`}>
          {label ?? null}
          <div
            className={inputClassName}
            style={{
              height:
                style === InputStyle.default || style === InputStyle.cloud
                  ? '38px'
                  : '22px',
              maxWidth,
            }}
            onClick={() => {
              if (loading || disabled || readOnly) {
                return;
              }
              setExpanded((prev) => !prev);
            }}
            data-test-id={id}
          >
            {value.length === 0 && (
              <span
                className="text-gray truncate"
                style={{
                  fontFamily: 'nunito, "Font Awesome 6 Pro"',
                }}
              >
                {placeholder}
              </span>
            )}
            {value.length === 1 && (
              <span className="text-gray-900 truncate">
                {buildOption(value[0])}
              </span>
            )}
            {value.length > 1 && (
              <span className="flex flex-row gap-2 truncate">
                <span className="text-gray-900 truncate">{`${optionBuilder(
                  value[0]
                )}`}</span>
                <span className="text-gray">{`+${value.length - 1}`}</span>
              </span>
            )}
            {loading ? (
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin
                className="text-gray"
              />
            ) : readOnly ? null : (
              <FontAwesomeIcon
                icon={faChevronDown}
                className={`transition-transform ${
                  expanded ? '-rotate-180' : ''
                }`}
                data-test-id={`${id + '-chevron-down'}`}
              />
            )}
          </div>
          {error ?? null}
        </div>
        {expanded && (
          <div
            className={`absolute mx-auto mt-1 bg-white w-full rounded
            shadow-md border border-gray-500 flex flex-col`}
            style={{ minWidth: '232px' }}
            onClick={(e) => e.stopPropagation()}
            data-test-id={`${id}-list-container`}
            ref={listRef}
          >
            <div className="flex flex-row w-full border-b border-gray-500 p-1 justify-between">
              <span data-test-id={`${id}-list-title`}>{title}</span>
              {value.length > 0 && clearAllButton && (
                <div
                  className="text-gray hover:text-black cursor-pointer"
                  onClick={(e) => {
                    setValue([]);
                    e.stopPropagation();
                  }}
                  data-test-id={`${id}-clear-all-button`}
                >
                  <FontAwesomeIcon icon={faFilterSlash} />
                </div>
              )}
            </div>
            <div
              className="overflow-auto text-xs"
              style={{ maxHeight }}
              data-test-id={`${id}-list`}
            >
              {filteredOptions.map((o, i) => {
                let index = -1;

                if (compareFn) {
                  index = value.findIndex((v) => compareFn(v, o));
                } else {
                  index = value.indexOf(o);
                }
                const isSelected = index >= 0;

                return (
                  <div
                    key={i}
                    className={`p-1 pr-4 truncate cursor-pointer flex flex-row gap-1 items-center transition-colors ${
                      isSelected ? 'bg-green-100' : 'hover:bg-gray-200'
                    }`.trim()}
                    onClick={(e) => {
                      if (isSelected) {
                        value.splice(index, 1);
                      } else {
                        value.push(o);
                      }
                      setValue([...value]);

                      e.stopPropagation();
                    }}
                    data-test-id={`${id}-option-${i}`}
                  >
                    <span className="w-3 h-3 flex items-center justify-center">
                      {isSelected && (
                        <FontAwesomeIcon icon={faCheck} className="w-full" />
                      )}
                    </span>
                    <span className="w-full truncate">{buildOption(o)}</span>
                  </div>
                );
              })}
            </div>
            {search && (
              <div className="absolute top-full w-full">
                <div className="mt-1 bg-white w-full rounded border border-gray-500 shadow-md">
                  <InputTextSingle
                    id={id + '-search-input'}
                    value={searchInputValue}
                    setValue={setSearchInputValue}
                    placeholder={'Search ' + title}
                    placeholderIcon={InputPlaceholderIcon.search}
                    className="border-0"
                    inputRef={searchInputRef}
                    onBlur={(e) => {
                      if (inputRef.current?.contains(e.relatedTarget as Node)) {
                        e.stopPropagation();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && filteredOptions.length === 1) {
                        const index = value.indexOf(filteredOptions[0]);
                        if (index === -1) {
                          value.push(filteredOptions[0]);
                          setValue([...value]);
                        }
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </InputIdContext.Provider>
  );
}

export { InputSelectMulti };
