export type OptionalClaimLookup = { [key in UserClaims]?: boolean };
export interface hasClaimsRouteGuardProps {
  claims: UserClaims[];
  children: JSX.Element;
}

export enum UserClaims {
  Cloud = 'Cloud',
  Uploads = 'Uploads',
  Projects = 'Projects',
  AgerAdmin = 'AgerAdmin',
  QAQC = 'QAQC',
}
