import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';

/* eslint-disable max-len */
import React, { Fragment, ReactNode } from 'react';

import { IconButton } from '@agerpoint/component';

/**
 * "Headless UI's Dialog component actually uses a Portal under-the-hood."
 *
 * @see https://reactjs.org/docs/portals.html
 * @see https://headlessui.dev/react/dialog#rendering-to-a-portal
 * @see https://headlessui.dev/react/dialog
 * @see https://tailwindcomponents.blogspot.com/2021/03/how-to-create-premium-dialog-component-using-tailwind-css-and-react-js.html
 * @returns
 */

type DialogSize = 'small' | 'large';

interface DialogModalProps {
  children: ReactNode;
  open: boolean;
  handleCloseDialog: () => void;
  size: DialogSize;
  title: string;
  backgroundColor?: string;
  visibleOverflow?: boolean;
  testId?: string;
}

export function DialogModal(props: DialogModalProps) {
  const {
    children,
    handleCloseDialog,
    size = 'large',
    title,
    open,
    visibleOverflow = false,
    testId,
  } = props;

  const CloseIcon = () => {
    return (
      <IconButton
        tabIndex={-1}
        data-test-id={`close-dialog${testId ? `-${testId}` : ''}`}
        show
        onClick={handleCloseDialog}
        className="w-8 h-8 self-start md:-mt-4 md:-mr-6 focus:outline-none focus:ring-0"
        icon={
          <FontAwesomeIcon
            icon={faTimes}
            className="text-xl text-gray-600 hover:text-gray-900"
          />
        }
      />
    );
  };

  const getMaxWidth = (size: DialogSize) => {
    switch (size) {
      case 'small':
        return 'max-w-xl';
      case 'large':
      default:
        return 'max-w-5xl';
    }
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 overflow-y-auto`}
        style={{ zIndex: 999999 }}
        open={open}
        onClose={handleCloseDialog}
        onClick={(e) => {
          // prevent clicks beyond the dialog
          e.stopPropagation();
        }}
      >
        <div className="flex items-center justify-center mx-10 min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-25 z-40" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`flex inline-block z-50 w-full px-6 py-4 md:px-10 md:py-8 my-8 ${
                visibleOverflow ? 'overflow-visible' : 'overflow-hidden'
              } text-left align-middle transition-all transform  ${
                props.backgroundColor ? props.backgroundColor : 'bg-white'
              } shadow-xl rounded ${getMaxWidth(size)}`}
              style={{ maxHeight: '90vh' }}
            >
              <div className="flex flex-col w-full">
                <div className="w-full flex justify-between items-center">
                  <Dialog.Title>
                    <div
                      className="text-3xl font-bold"
                      data-test-id={`dialog-title${testId ? `-${testId}` : ''}`}
                    >
                      {title}
                    </div>
                  </Dialog.Title>
                  {CloseIcon()}
                </div>
                <div
                  className={`flex flex-grow ${
                    visibleOverflow ? 'overflow-y-visible' : 'overflow-y-auto'
                  }`}
                  id="dialog-modal-children"
                >
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
