import { useInfiniteQuery } from '@tanstack/react-query';

import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import { useLookupTable } from '@agerpoint/utilities';

export const useOpsPipelineJobsQueries = ({
  filter,
}: {
  filter?: APIModels.PipelineJobFilter;
}) => {
  const pipelineJobsQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.pipelineJobs,
      APIUtils.QueryKey.infinite,
      { filter: filter },
    ],
    queryFn: async ({ pageParam }) =>
      APIClient.getFilteredPagedPipelineJobs(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.PipelineJobFilter
      ),

    enabled: filter !== undefined,
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    staleTime: APIUtils.getDuration({
      seconds: 20,
    }),
  });

  const pipelinesQuery = APIClient.useGetPipelines({
    query: {
      queryKey: [APIUtils.QueryKey.pipelines],
      select: (data) => APIUtils.Sort.pipelines(data),
    },
  });

  const pipelinesLookupTable = useLookupTable(pipelinesQuery.data, 'id');

  return {
    pipelineJobsQuery,
    pipelinesLookupTable,
  };
};
