/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';
import type { Claim } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getClaims = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Claim[]>(
    { url: `/api/Claims`, method: 'GET', signal },
    options
  );
};

export const getGetClaimsQueryKey = () => {
  return [`/api/Claims`] as const;
};

export const getGetClaimsQueryOptions = <
  TData = Awaited<ReturnType<typeof getClaims>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getClaims>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClaimsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClaims>>> = ({
    signal,
  }) => getClaims(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getClaims>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetClaimsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClaims>>
>;
export type GetClaimsQueryError = ErrorType<void>;

export const useGetClaims = <
  TData = Awaited<ReturnType<typeof getClaims>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getClaims>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetClaimsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getClaim = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Claim>(
    {
      url: `/api/Claims/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetClaimQueryKey = (id: number) => {
  return [`/api/Claims/${id}`] as const;
};

export const getGetClaimQueryOptions = <
  TData = Awaited<ReturnType<typeof getClaim>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getClaim>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClaimQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClaim>>> = ({
    signal,
  }) => getClaim(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getClaim>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetClaimQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClaim>>
>;
export type GetClaimQueryError = ErrorType<void>;

export const useGetClaim = <
  TData = Awaited<ReturnType<typeof getClaim>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getClaim>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetClaimQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
