import { CaptureImage } from './captures';

export interface ImageSelection {
  mode?: 'ADD' | 'EDIT';
  editImageId?: number;
}

export interface CaptureGalleryImageProps {
  image: CapturesViewerImage;
  index: number;
  setExpandedImage: (image: CapturesViewerImage) => void;
  imageSelection?: ImageSelection;
  imageSelected: (image: CapturesViewerImage) => void;
  imageNote?: string;
  isImageHighlighted?: boolean;
  isImageSelectedInQAQC?: boolean;
}

export interface CaptureViewerBottomPanelImageGalleryProps {
  captureImages: CapturesViewerImage[];
  selectedCaptureImages: CapturesViewerImage[];
  imageSelectionMode?: ImageSelection;
  imageSelectedCallback: (image: CapturesViewerImage) => void;
  isBottomPanelExpanded: boolean;
  setExpandedImage: (image: CapturesViewerImage) => void;
  isQAQCModeEnabled: boolean;
  qaqcModeShowSelectedOnly: boolean;
  highlightedImage: number | undefined;
}

export enum OrientationOptions {
  Original,
  Landscape,
  Portrait,
}

export interface CapturesViewerImage extends CaptureImage {
  localIndex: number;
}

export interface TogglePointCloudVisibility {
  classification: string;
}
