import compare from 'trivial-compare';

import { APIModels } from '../..';

export const projects = (data?: APIModels.Project[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) => compare(a.name.toLowerCase(), b.name.toLowerCase()));
  return sorted;
};
