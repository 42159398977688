import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useCallback, useMemo, useRef } from 'react';

import { CloudButton } from '../..';
import { Tooltip } from '../../tooltip';
import { InputIdContext } from '../input-id-context';

interface InputToggleOnOffProps {
  /**
   * The id of the toggle input.
   */
  id: string;

  /**
   * The value of the toggle.
   */
  value: boolean;

  /**
   * A function to set the value of the toggle.
   */
  setValue: (value: boolean) => void;

  /**
   * Specifies whether the toggle is disabled.
   */
  disabled?: boolean;

  /**
   * The label to display before the toggle.
   */
  leadingIcon?: IconName;

  /**
   * The label to display after the toggle.
   */
  trailingIcon?: IconName;

  /**
   * Specifies whether the toggle is highlighted.
   */
  highlighted?: boolean;

  /**
   * The tooltip to display when hovering over the toggle.
   */
  tooltip: string;

  /**
   * The position of the tooltip.
   */
  tooltipPosition: 'top' | 'bottom' | 'left' | 'right';

  /**
   * If this is true, it will render the CloudButton.Icon instead that fits the smaller screens better.
   */
  mobileViewMode?: boolean;
}

export const InputToggle = ({
  id,
  value,
  setValue,
  disabled,
  leadingIcon,
  trailingIcon,
  highlighted,
  tooltip,
  tooltipPosition,
  mobileViewMode,
}: InputToggleOnOffProps) => {
  const containerClassName = useMemo(() => {
    let className =
      'w-8 h-5 flex flex-row p-1 rounded-xl items-center transition-colors duration-300 border relative';

    if (disabled && value) {
      className += ' bg-gray-border border-gray-border ';
    } else if (disabled && !value) {
      className += ' bg-gray-background border-gray-border ';
    } else if (value) {
      className += ' bg-primary border-primary';
    } else {
      className += ' bg-white border-gray-border ';
    }

    return className;
  }, [value, disabled]);

  const handlerClassName = useMemo(() => {
    let className = 'size-3 rounded-full transition-colors absolute ';

    if (disabled && value) {
      className += ' bg-gray-textSecondary ';
    } else if (disabled && !value) {
      className += ' bg-gray-textSecondary ';
    } else if (value) {
      className += ' bg-white ';
    } else {
      className += ' bg-gray-iconSecondary ';
    }

    return className;
  }, [value, disabled]);

  const wrapperClassName = useMemo(() => {
    let className = 'flex flex-row gap-1 p-1 items-center rounded-lg ';

    if (disabled) {
      className += 'cursor-not-allowed';
    } else {
      className += 'cursor-pointer hover-overlay-5';
    }

    if (highlighted) {
      className += ' bg-primary bg-opacity-10 ';
    }

    return className;
  }, [disabled, highlighted]);

  const buildIcon = useCallback(
    (icon?: IconName) => {
      if (!icon) return null;

      let color = 'text-gray-iconPrimary';
      let prefix: IconPrefix = 'far';

      if (value) {
        color = 'text-primary';
        prefix = 'fas';
      }

      if (disabled) {
        color = 'text-gray-iconSecondary';
      }

      return (
        <div className="size-6 flex-center">
          <FontAwesomeIcon icon={[prefix, icon]} className={color} />
        </div>
      );
    },
    [value, disabled]
  );

  const buttonRef = useRef<HTMLDivElement>(null);

  if (mobileViewMode) {
    return (
      <CloudButton.Icon
        id={id}
        disabled={disabled}
        onClick={() => setValue(!value)}
        leadingIcon={leadingIcon}
        tooltip={tooltip}
        tooltipPosition={tooltipPosition}
        toggled={value}
      />
    );
  }

  return (
    <InputIdContext.Provider value={id}>
      <div
        className={wrapperClassName}
        onClick={() => {
          if (disabled) {
            return;
          }
          setValue(!value);
        }}
        ref={buttonRef}
      >
        {buildIcon(leadingIcon)}

        <div className={containerClassName} data-test-id={id}>
          <motion.div
            className={handlerClassName}
            initial={{
              left: value ? '15px' : '3px',
            }}
            animate={{
              left: value ? '15px' : '3px',
            }}
          />
        </div>
        {buildIcon(trailingIcon)}
        {tooltip && tooltipPosition && buttonRef && (
          <Tooltip childRef={buttonRef} position={tooltipPosition}>
            {tooltip}
          </Tooltip>
        )}
      </div>
    </InputIdContext.Provider>
  );
};
