import { IBaseOverlay, IOverlay2d } from 'libs/types/src/overlay-2d';
import * as ReactDOM from 'react-dom/client';
import { Vector3 } from 'three';

import { PotreeViewer } from '@agerpoint/types';

/**
 * Represents a 2D overlay manager for displaying feature information on a PotreeViewer canvas.
 */
export class Overlay2d implements IOverlay2d {
  private currentOverlay: FeatureInfoOverlay | null;
  private frustumPlaneOverlay: any;
  private viewer: PotreeViewer;
  private canvas: HTMLCanvasElement;

  /**
   * Creates a new Overlay2d instance.
   * @param viewer - The PotreeViewer to which the overlay manager is attached.
   * @param canvas - The HTML canvas element on which overlays are rendered.
   */
  constructor(viewer: PotreeViewer, canvas: HTMLCanvasElement) {
    this.currentOverlay = null;
    this.viewer = viewer;
    this.canvas = canvas;
    this.handleResize = this.handleResize.bind(this);

    // Attach event listeners
    window.addEventListener('resize', this.handleResize);
  }

  /**
   * Creates a feature information overlay at the specified geographic position.
   * @param position - The 3D geographic position to display the overlay at.
   */
  createFeatureInfoOverlay(position: Vector3) {
    if (this.currentOverlay) {
      this.currentOverlay.destroy();
    }

    this.currentOverlay = new FeatureInfoOverlay(
      this.viewer,
      this.canvas,
      position
    );

    const potreeRenderAreaElem = document.querySelector('#potree_render_area');
    if (potreeRenderAreaElem) {
      const element = this.currentOverlay.getElement();
      potreeRenderAreaElem.appendChild(element);
      this.currentOverlay.update2dPosition();
    } else {
      console.error('Potree render area element not found.');
    }
  }

  createFrustumPlaneOverlay() {
    if (this.frustumPlaneOverlay) {
      this.frustumPlaneOverlay.destroy();
    }
    this.frustumPlaneOverlay = new FrustumPlaneOverlay(
      this.viewer,
      this.canvas
    );
    const potreeRenderAreaElem = document.querySelector('#potree_render_area');
    if (potreeRenderAreaElem) {
      const element = this.frustumPlaneOverlay.getElement();
      potreeRenderAreaElem.appendChild(element);
      this.frustumPlaneOverlay.update2dPosition();
    } else {
      console.error('Potree render area element not found.');
    }
  }

  getFrustumPlaneGeometry(): number[][] | null {
    if (!this.frustumPlaneOverlay) return null;
    const svgBounds = this.frustumPlaneOverlay
      .getElement()
      .getBoundingClientRect();
    //
    return [
      [svgBounds.left, svgBounds.top],
      [svgBounds.right, svgBounds.top],
      [svgBounds.right, svgBounds.bottom],
      [svgBounds.left, svgBounds.bottom],
      [svgBounds.left, svgBounds.top],
    ];
  }

  /**
   * Sets the children of the current feature information overlay using a React component.
   * @param child - The React component representing the content to be displayed in the overlay.
   */
  setFeatureInfoChildren(child: React.ReactElement<any, any>) {
    if (this.currentOverlay) {
      this.currentOverlay.setChildren(child);
    }
  }

  /**
   * Updates the position of the current feature information overlay on the canvas.
   * This should be called when the camera or overlay position changes.
   */
  render() {
    if (this.currentOverlay) {
      this.currentOverlay.update2dPosition();
    }
  }

  /**
   * Handle window resize events.
   * Call this method when the window is resized to update the overlay position.
   */
  private handleResize() {
    this.render();
  }

  /**
   * Cleanup method to remove event listeners and destroy the overlay when it's no longer needed.
   */
  destroy() {
    window.removeEventListener('resize', this.handleResize);
    if (this.currentOverlay) {
      this.currentOverlay.destroy();
    }
  }
}

class BaseOverlay implements IBaseOverlay {
  protected geographicPosition: Vector3;
  element: HTMLElement | SVGElement = document.createElement('div');
  private viewer: PotreeViewer;
  private canvas: HTMLCanvasElement;

  /**
   * Converts a 3D geographic position to a screen position on the canvas.
   * @param position - The 3D geographic position to convert.
   * @returns The screen position as a Vector3.
   * @private
   */
  constructor(
    viewer: PotreeViewer,
    canvas: HTMLCanvasElement,
    position: Vector3
  ) {
    this.viewer = viewer;
    this.canvas = canvas;
    this.geographicPosition = position;
  }

  update2dPosition() {
    const screenPos = this._getScreenPosition(this.geographicPosition);
    this.element.style.left = screenPos.x + 'px';
    this.element.style.top = screenPos.y + 'px';
  }

  protected _getScreenPosition(position: Vector3) {
    const temp = new Vector3(position.x, position.y, position.z);
    const vector = temp.project(this.viewer.scene.cameraP);
    vector.x = ((temp.x + 1) / 2) * this.canvas.width;
    vector.y = (-(temp.y - 1) / 2) * this.canvas.height;
    return vector;
  }

  /**
   * Sets the children content of the overlay.
   * @param children - The content to be set as children of the overlay.
   */
  setChildren(children: React.ReactElement<any, any> | string) {
    // Clear existing content
    while (this.element.firstChild) {
      this.element.removeChild(this.element.firstChild);
    }

    // Add new content
    const root = ReactDOM.createRoot(this.element!);
    root.render(children);
  }

  /**
   * Gets the HTML element associated with the overlay.
   * @returns The overlay's HTML element.
   */
  getElement() {
    return this.element;
  }
  /**
   * Removes the overlay's HTML element from the DOM.
   */
  destroy() {
    if (this.element.parentElement) {
      this.element.parentElement.removeChild(this.element);
    }
  }
}

/**
 * Represents an overlay for displaying feature information on a PotreeViewer.
 */
export class FeatureInfoOverlay extends BaseOverlay {
  private id: string;

  /**
   * Creates a new FeatureInfoOverlay instance.
   * @param viewer - The PotreeViewer to which the overlay is attached.
   * @param canvas - The HTML canvas element used for rendering.
   */
  constructor(
    viewer: PotreeViewer,
    canvas: HTMLCanvasElement,
    position: Vector3
  ) {
    super(viewer, canvas, position);
    this.id = 'feature-info-overlay';
    this.element = this._setupElement();
    this.update2dPosition();
  }

  /**
   * Sets up the HTML element for the overlay.
   * @private
   * @returns The created HTML element.
   */
  private _setupElement() {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.top = '-1000px';
    div.style.left = '-1000px';
    div.style.display = 'block';
    div.style.maxWidth = '320px';
    div.style.maxHeight = '350px';
    div.style.borderRadius = '5px';
    div.style.zIndex = '1000';
    div.style.overflow = 'visible';
    div.id = this.id;
    return div;
  }
}
export class FrustumPlaneOverlay extends BaseOverlay {
  private id: string;
  element: SVGElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  );

  constructor(viewer: PotreeViewer, canvas: HTMLCanvasElement) {
    super(viewer, canvas, new Vector3(0, 0, 0));
    this.id = 'frustum-plane-overlay';
    this.element = this._setupElement();

    this.update2dPosition();
  }

  private _setupElement() {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.style.position = 'absolute';
    svg.style.top = '0';
    svg.style.left = '0';
    svg.style.display = 'block';
    svg.style.maxWidth = '100%';
    svg.style.width = '100%';
    svg.style.maxHeight = '100%';
    svg.style.height = '100%';
    // svg.style.stroke = 'yellow';
    svg.style.strokeWidth = '2px';
    svg.style.zIndex = '1000';
    svg.style.overflow = 'hidden';
    svg.style.pointerEvents = 'none';
    svg.id = this.id;

    svg.style.fill = 'none';

    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    rect.setAttribute('x', '0');
    rect.setAttribute('y', '0');
    rect.setAttribute('width', '100%');
    rect.setAttribute('height', '100%');

    svg.appendChild(rect);
    return svg;
  }
}
