import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminMosaicEnginesQueries } from './admin-mosaic-engines-queries';

export const AdminMosaicEnginesNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  usePageTitle(() => 'Platform - Mosaic Engines', []);

  const { permissions } = useGlobalStore();

  const isViteApp = useIsViteApp();

  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );

  useEffect(() => {
    if (!canManageAnalyticRequests) {
      if (isViteApp) {
        navigate('/app/admin/platform/mosaic-engines' + params);
      } else {
        navigate('/admin/mosaic-engines' + params);
      }
    }
  }, [canManageAnalyticRequests]);

  const { mosaicEnginePostMutation, pipelinesQuery } =
    useAdminMosaicEnginesQueries();

  const formValidation = useFormValidation();
  const [mosaicEngine, setMosaicEngine] = useState<APIModels.MosaicEngine>({});
  const [selectedPipeline, setSelectedPipeline] =
    useState<APIModels.Pipeline>();

  useEffect(() => {
    setMosaicEngine((prev) => ({ ...prev, pipelineId: selectedPipeline?.id }));
  }, [selectedPipeline]);

  const createMosaicEngine = useCallback(async () => {
    if (mosaicEnginePostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    mosaicEnginePostMutation.mutate({
      data: mosaicEngine,
    });
  }, [mosaicEngine, formValidation, mosaicEnginePostMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Mosaic Engines',
              path: isViteApp
                ? '/app/admin/platform/mosaic-engines'
                : '/admin/mosaic-engines',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-mosaic-engine-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/mosaic-engines' + params);
            } else {
              navigate('/admin/mosaic-engines' + params);
            }
          }}
        />

        <h1 className="text-3xl font-bold">New Mosaic Engine</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="mosaic-engine-name-input"
          label={<Input.Label label="Name" required />}
          value={mosaicEngine.name || ''}
          setValue={(name) => {
            setMosaicEngine({ ...mosaicEngine, name });
          }}
          error={
            <Input.Error
              error={formValidation.errors['mosaic-engine-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
        <Input.Text.Single
          id="mosaic-engine-display-name-input"
          label={<Input.Label label="Display Name" required />}
          value={mosaicEngine.displayName || ''}
          setValue={(displayName) => {
            setMosaicEngine({ ...mosaicEngine, displayName });
          }}
          error={
            <Input.Error
              error={formValidation.errors['mosaic-engine-display-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Display Name')],
          }}
        />
        <Input.Text.Single
          id="mosaic-engine-description-input"
          label={<Input.Label label="Description" />}
          value={mosaicEngine.description || ''}
          setValue={(description) => {
            setMosaicEngine({ ...mosaicEngine, description });
          }}
        />
        <Input.Select.Single
          id="mosaic-engine-pipeline-input"
          title="Pipeline"
          options={pipelinesQuery.data ?? []}
          optionBuilder={(pipeline) => pipeline?.name || ''}
          loading={pipelinesQuery.isLoading}
          label={<Input.Label label="Pipeline" />}
          value={selectedPipeline}
          setValue={setSelectedPipeline}
        />
        <div className="w-full flex flex-row justify-end items-center py-4">
          <Button.Primary
            id="new-mosaic-engine-create-button"
            label={'Create'}
            onClick={createMosaicEngine}
            loading={mosaicEnginePostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
