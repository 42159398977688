import { useContext } from 'react';

import { InputIdContext } from './input-id-context';

interface IInputError {
  error?: string;
  className?: string;
}

export function InputError({ error, className }: IInputError) {
  const id = useContext(InputIdContext);

  if (!error) {
    return null;
  }

  return (
    <span
      id={id ? `${id}-error` : undefined}
      data-test-id={id ? `${id}-error` : undefined}
      className="text-xs text-status-error pl-2 pt-1"
    >
      {error}
    </span>
  );
}
