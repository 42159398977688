import { useEffect, useMemo, useState } from 'react';

import { Customer, User } from '@agerpoint/api';
import { DialogModal, Input, PrimaryButton } from '@agerpoint/component';
import { Sort, useFormValidation, useGlobalStore } from '@agerpoint/utilities';

interface CloneDatasetModalProps {
  open: boolean;
  handleCloseDialog: () => void;
  title: string;
  cloningCount: number;
  organizations?: Customer[];
  users?: User[];
  onClone: (organizationId: number, userId: string) => void;
}

export const CloneDatasetModal = ({
  open,
  handleCloseDialog,
  title,
  cloningCount,
  organizations,
  users,
  onClone,
}: CloneDatasetModalProps) => {
  const { user } = useGlobalStore();

  const formValidaton = useFormValidation();

  const [selectedOrganization, setSelectedOrganization] = useState<Customer>();

  const [selectedUser, setSelectedUser] = useState<User>();

  const sortedOrganizations = useMemo(
    () => Sort.organizations(organizations ?? undefined),
    [organizations]
  );

  const sortedUsers = useMemo(() => Sort.users(users), [users]);

  useEffect(() => {
    if (open) {
      setSelectedOrganization(undefined);
      const u = users?.find((u) => u.id === user?.id);
      setSelectedUser(u);
    }
  }, [open, organizations, users, user?.id]);

  useEffect(() => {
    if (open) {
      formValidaton.advancedCallbacks.reset();
    }
  }, [open]);

  return (
    <DialogModal
      open={open}
      handleCloseDialog={handleCloseDialog}
      size="small"
      title={title}
      visibleOverflow={true}
    >
      <div className="w-full p-1 flex flex-col">
        <div className="text-sm">
          Select the organization and user to which the cloned items are to be
          assigned.
        </div>
        <div className="py-2 text-sm">Cloning {cloningCount} item(s).</div>
        <Input.Select.Single
          id="organization-select-clone-modal"
          value={selectedOrganization}
          setValue={setSelectedOrganization}
          options={sortedOrganizations ?? []}
          loading={(organizations?.length ?? 0) === 0}
          optionBuilder={(o) =>
            o.customerDisplayName ?? o.customerName ?? 'Unknown'
          }
          title="Organization"
          label={<Input.Label label="Organization" required />}
          validation={{
            validationState: formValidaton,
            validators: [Input.validators.required('Organization')],
          }}
          error={
            <Input.Error
              error={formValidaton.errors['organization-select-clone-modal']}
            />
          }
        />
        <div className="pt-2">
          <Input.Select.Single
            id="user-select-clone-modal"
            value={selectedUser}
            setValue={setSelectedUser}
            loading={(sortedUsers?.length ?? 0) === 0}
            options={sortedUsers ?? []}
            optionBuilder={(o) =>
              `${o.userProfiles?.[0].firstName} ${o.userProfiles?.[0].lastName}`.trim()
            }
            title="User"
            label={<Input.Label label="User" required />}
            validation={{
              validationState: formValidaton,
              validators: [Input.validators.required('User')],
            }}
            error={
              <Input.Error
                error={formValidaton.errors['user-select-clone-modal']}
              />
            }
          />
        </div>
        <div className="flex flex-row gap-1 justify-end pt-2 z-30">
          <PrimaryButton
            label={'Clone'}
            onClicked={async () => {
              if (await formValidaton.hasErrors()) {
                return;
              }

              onClone(
                selectedOrganization?.id as number,
                selectedUser?.id as string
              );
              handleCloseDialog();
            }}
          />
          <PrimaryButton
            label={'Cancel'}
            onClicked={() => {
              handleCloseDialog();
            }}
          />
        </div>
      </div>
    </DialogModal>
  );
};
