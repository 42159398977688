/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */

export type Dimension = (typeof Dimension)[keyof typeof Dimension];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Dimension = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_MINUS_3: -3,
  NUMBER_MINUS_2: -2,
  NUMBER_MINUS_1: -1,
} as const;
