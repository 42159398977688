import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import {
  LayerGroup,
  usePostLayerGroup,
  usePutLayerById,
  usePutLayerGroup,
} from '@agerpoint/api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Groups as GroupsComponent } from '@agerpoint/feature';
import { Layer, PanelsState, ProjectState } from '@agerpoint/types';
import { toApiGroup, toApiLayer, toStateGroup } from '@agerpoint/utilities';

import useMultiSelection from '../../state/use-multiselection';
import { usePanels } from '../../state/use-panels';
import {
  bingKey,
  findLayer,
  isClientSideGroup,
  useProject,
} from '../../state/use-project';

interface GroupsParams {
  projectId?: string;
}

export default function Groups({ projectId }: GroupsParams) {
  const {
    groups,
    setGroupVisible,
    setLayerVisible,
    setLayerStyle,
    saveNewGroup,
  } = useProject(getGroupsState, shallow);
  const {
    mapMode,
    currentExpansionId,
    selectedLayerId,
    setSelectedLayerId,
    toggleExpansion,
  } = usePanels(getPanelsState, shallow);

  const { mutate: postLayerGroup } = usePostLayerGroup({});
  const { mutate: putLayerGroup } = usePutLayerGroup({ id: NaN });
  const { mutate: putLayer } = usePutLayerById({ id: NaN });

  const multiSelection = useMultiSelection();

  const internalSaveNewGroup = useCallback(persistNewGroup, [
    projectId,
    saveNewGroup,
    postLayerGroup,
    groups,
  ]);

  const internalSetGroupVisible = useCallback(persistGroupVisible, [
    groups,
    putLayerGroup,
    setGroupVisible,
  ]);

  const internalSetLayerVisible = useCallback(persistLayerVisible, [
    groups,
    putLayer,
    setLayerVisible,
  ]);

  const internalSetLayerStyle = useCallback(persistLayerStyle, [
    groups,
    putLayer,
    setLayerStyle,
  ]);

  return (
    <GroupsComponent
      mode={mapMode}
      groups={groups}
      selectedLayerId={selectedLayerId}
      setGroupVisible={internalSetGroupVisible}
      setLayerVisible={internalSetLayerVisible}
      setLayerStyle={internalSetLayerStyle}
      setSelectedLayerId={setSelectedLayerId}
      saveNewGroup={internalSaveNewGroup}
      currentExpansionId={currentExpansionId}
      toggleExpansion={toggleExpansion}
      bingKey={bingKey}
      isClientSideGroup={isClientSideGroup}
      setMultiSelectedLayers={multiSelection.setMultiSelection}
      multiSelectGroupId={multiSelection.groupId}
      setMultiSelectGroupId={multiSelection.setMultiSelectGroupId}
      clearMultiSelection={multiSelection.clearMultiSelectGroupId}
      multiSelectedItems={multiSelection.layerIds}
      setMultiSelectLayerTypeId={multiSelection.setMultiSelectLayerTypeId}
      layerTypeId={multiSelection.layerTypeId}
    />
  );

  async function persistNewGroup(name?: string) {
    const newGroup = groups.find((group) => group.id === 0);
    if (newGroup === undefined) {
      console.warn(
        'saveNewGroup was called but no new group was present in the state.'
      );
      return;
    }

    if (!name) {
      saveNewGroup(undefined);
      return;
    }

    const result = (await postLayerGroup({
      name,
      projectId: Number(projectId),
      visible: newGroup.visible,
    })) as unknown as LayerGroup;
    saveNewGroup(toStateGroup(result));
  }

  async function persistGroupVisible(id: number, visible: boolean) {
    setGroupVisible(id, visible);
    const group = groups.find((group) => group.id === id);
    if (group && group.id !== 0 && !isClientSideGroup(group)) {
      await putLayerGroup(
        {
          ...toApiGroup(group),
          visible,
        } as LayerGroup,
        { pathParams: { id } }
      );
    }
  }

  async function persistLayerVisible(id: number, visible: boolean) {
    setLayerVisible(id, visible);

    const groupLayer = findLayer(groups, id);
    if (groupLayer) {
      const { group, layer } = groupLayer;
      if (!isClientSideGroup(group)) {
        await putLayer(
          toApiLayer({
            ...layer,
            visible,
          }),
          { pathParams: { id } }
        );
      }
    } else {
      throw new Error(`Could not find layer with id ${id}.`);
    }
  }

  async function persistLayerStyle(id: number, layer: Layer) {
    setLayerStyle(id, layer);

    const groupLayer = findLayer(groups, id);
    if (groupLayer) {
      const { group } = groupLayer;
      if (!isClientSideGroup(group)) {
        await putLayer(toApiLayer(layer), { pathParams: { id } });
      }
    } else {
      throw new Error(`Could not find layer with id ${id}.`);
    }
  }
}

function getGroupsState({
  groups,
  actions: {
    groups: {
      setVisible: setGroupVisible,
      add: addGroup,
      saveNew: saveNewGroup,
    },
    layers: {
      setVisible: setLayerVisible,
      setName: setLayerName,
      setStyle: setLayerStyle,
    },
  },
}: ProjectState) {
  return {
    groups,
    setGroupVisible,
    setLayerVisible,
    setLayerStyle,
    saveNewGroup,
    addGroup,
    setLayerName,
  };
}

function getPanelsState({
  mapMode,
  currentExpansionId,
  selectedLayerId,
  setSelectedLayerId,
  toggleExpansion,
}: PanelsState) {
  return {
    mapMode,
    currentExpansionId,
    selectedLayerId,
    setSelectedLayerId,
    toggleExpansion,
  };
}
