import { APIClient, Capture } from '@agerpoint/api';
import { Options } from '@agerpoint/types';

import {
  deleteLayerFromGroup,
  fetchLayerGroups,
} from './captures-project-http';

export const updateProjectsForCapture = ({
  previous,
  selectedProjects,
  projects,
  serverUrl,
  accessToken,
  captures,
}: {
  previous: { selectedProjects: Options[] } | undefined;
  selectedProjects: Options[];
  projects: APIClient.Project[];
  serverUrl: string;
  accessToken: string | undefined;
  captures: Capture[];
}) => {
  const removed =
    previous?.selectedProjects?.filter((prevOpt) => {
      return !selectedProjects.some((curOpt) => {
        return curOpt.value === prevOpt.value;
      });
    }) || [];
  const added =
    selectedProjects.filter((curOpt) => {
      return !previous?.selectedProjects?.some((prevOpt) => {
        return curOpt.value === prevOpt.value;
      });
    }) || [];

  const projectIds = [...removed, ...added].map((opt) => opt.value) as number[];
  const projectUuids = projects.reduce((acc, proj) => {
    if (
      projectIds.includes(proj.id as number) &&
      proj?.uuid &&
      !acc.includes(proj.uuid)
    ) {
      acc.push(proj.uuid);
    }
    return acc;
  }, [] as string[]);

  const fetchLayer = async () => {
    for (const c of captures) {
      await fetchLayerGroups(
        projectIds,
        projectUuids,
        serverUrl,
        accessToken || '',
        c
      );
    }
  };
  const deleteLayer = async () => {
    const capturesToUpdate = Promise.all(
      captures.map(
        async (c) =>
          await deleteLayerFromGroup(
            projectIds,
            projectUuids,
            serverUrl,
            accessToken || '',
            c
          )
      )
    );
    await capturesToUpdate;
  };

  const res = [];

  if (added.length > 0) {
    res.push(fetchLayer);
  }

  if (removed.length > 0) {
    res.push(deleteLayer);
  }
  return Promise.all(res.map((r) => r()));
};
