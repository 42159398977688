import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { QuestionInfo } from '@agerpoint/component';
import { AnalyticRequestStatus } from '@agerpoint/types';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../../subcomponents/page-states';
import { useAdminCapturesQueries } from '../admin-captures-queries';
import { AdminCapturesProcessingCard } from './admin-captures-processing-card';

export const AdminCapturesDetailsAnalytics = () => {
  const analytics = `Automatically identify features using Machine Learning algorithms on this capture.`;

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const {
    captureQuery,
    customerAnalyticsQuery,
    captureAnalyticsLookupTable,
    captureAnalyticsQuery,
  } = useAdminCapturesQueries();

  const getCaptureAnalyticStatus = useCallback(
    (id?: number) => {
      if (!id) {
        return AnalyticRequestStatus.UNKNOWN;
      }

      const analyticRequest = captureAnalyticsLookupTable?.[id];

      if (!analyticRequest || !analyticRequest.status) {
        return AnalyticRequestStatus.UNKNOWN;
      }

      return analyticRequest.status as AnalyticRequestStatus;
    },
    [captureAnalyticsLookupTable]
  );

  if (captureQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (captureQuery.isError) {
    return (
      <PageErrorState
        entityName="capture"
        pluralEntityName="captures"
        statusCode={captureQuery.error.response?.status ?? 500}
        tryAgainCallback={() => {
          captureQuery.refetch();
        }}
        tryAgainLoading={captureQuery.isFetching}
        navigateBackCallback={() => navigate('/admin/captures' + params)}
      />
    );
  }

  return (
    <div className="px-4 pb-4 flex flex-col w-full h-full">
      <div className="pb-2 w-48 flex justify-between text-sm font-bold">
        <span>Analytic Options</span> <QuestionInfo text={analytics} />
      </div>

      {customerAnalyticsQuery.isLoading || captureAnalyticsQuery.isLoading ? (
        <div className="flex flex-row px-1 w-full h-full justify-center items-center gap-2">
          <FontAwesomeIcon icon={faCircleNotch} spin className="text-lg" />
          <span>Finding Analytics Available For Your Organization</span>
        </div>
      ) : captureQuery.isSuccess && !captureQuery.data?.customerId ? (
        <div className="flex flex-row px-1 w-full h-full justify-center items-center gap-2">
          <span>No organization found for the selected capture</span>
        </div>
      ) : captureQuery.isSuccess &&
        captureQuery.data?.customerId &&
        customerAnalyticsQuery.isSuccess &&
        customerAnalyticsQuery.data.length === 0 ? (
        <div className="flex flex-row px-1 w-full h-full justify-center items-center gap-2">
          <span> No analytics available for your organization.</span>
        </div>
      ) : customerAnalyticsQuery.isSuccess &&
        captureAnalyticsQuery.isSuccess ? (
        <div className="flex flex-row flex-wrap">
          {customerAnalyticsQuery.data
            ?.sort((a, b) => {
              if (a?.priority === undefined || b?.priority === undefined)
                return 0;
              return a?.priority - b?.priority;
            })
            .map((customerAnalytic, index) => {
              const { analytic } = customerAnalytic;
              return (
                <AdminCapturesProcessingCard
                  key={index}
                  disabled={customerAnalytic?.available === false}
                  comingSoon={customerAnalytic?.available === false}
                  additionalInfo={
                    customerAnalytic?.available === true
                      ? `${customerAnalytic?.analyticCreditCost} Credit(s)`
                      : undefined
                  }
                  icon={analytic?.icon}
                  desc={analytic?.analyticDescription ?? ''}
                  title={analytic?.analyticName ?? ''}
                  status={getCaptureAnalyticStatus(customerAnalytic?.id)}
                  onSelect={() => {
                    if (customerAnalytic?.analyticId) {
                      navigate(`${customerAnalytic?.analyticId}`, {
                        state: { params },
                      });
                    }
                  }}
                />
              );
            })}
        </div>
      ) : null}
    </div>
  );
};
