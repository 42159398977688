import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { URLStateContext } from '@agerpoint/utilities';

interface IURLStateSetup {
  children: ReactNode;
}

export const URLStateSetup = ({ children }: IURLStateSetup) => {
  const searchParams = useSearchParams();

  return (
    <URLStateContext.Provider value={searchParams}>
      {children}
    </URLStateContext.Provider>
  );
};
