/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CaptureImageTag } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureImageTags = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureImageTag[]>(
    { url: `/api/CaptureImageTags`, method: 'GET', signal },
    options
  );
};

export const getGetCaptureImageTagsQueryKey = () => {
  return [`/api/CaptureImageTags`] as const;
};

export const getGetCaptureImageTagsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImageTags>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureImageTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureImageTagsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImageTags>>
  > = ({ signal }) => getCaptureImageTags(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImageTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImageTagsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageTags>>
>;
export type GetCaptureImageTagsQueryError = ErrorType<void>;

export const useGetCaptureImageTags = <
  TData = Awaited<ReturnType<typeof getCaptureImageTags>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureImageTags>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImageTagsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureImageTagById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureImageTag>(
    {
      url: `/api/CaptureImageTags/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureImageTagByIdQueryKey = (id: number) => {
  return [`/api/CaptureImageTags/${id}`] as const;
};

export const getGetCaptureImageTagByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImageTagById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageTagById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureImageTagByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImageTagById>>
  > = ({ signal }) => getCaptureImageTagById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImageTagById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImageTagByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageTagById>>
>;
export type GetCaptureImageTagByIdQueryError = ErrorType<void>;

export const useGetCaptureImageTagById = <
  TData = Awaited<ReturnType<typeof getCaptureImageTagById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageTagById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImageTagByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureImageTag = (
  id: number,
  captureImageTag: BodyType<CaptureImageTag>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureImageTag>(
    {
      url: `/api/CaptureImageTags/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureImageTag,
    },
    options
  );
};

export const getPutCaptureImageTagMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureImageTag>>,
    TError,
    { id: number; data: BodyType<CaptureImageTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureImageTag>>,
  TError,
  { id: number; data: BodyType<CaptureImageTag> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureImageTag>>,
    { id: number; data: BodyType<CaptureImageTag> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureImageTag(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureImageTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureImageTag>>
>;
export type PutCaptureImageTagMutationBody = BodyType<CaptureImageTag>;
export type PutCaptureImageTagMutationError = ErrorType<void>;

export const usePutCaptureImageTag = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureImageTag>>,
    TError,
    { id: number; data: BodyType<CaptureImageTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureImageTag>>,
  TError,
  { id: number; data: BodyType<CaptureImageTag> },
  TContext
> => {
  const mutationOptions = getPutCaptureImageTagMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureImageTagThumbnailById2 = (
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/CaptureImageTags/${encodeURIComponent(
        String(id)
      )}/Thumbnail/${encodeURIComponent(String(fit))}/${encodeURIComponent(
        String(size)
      )}/${encodeURIComponent(String(horizontalAlign))}/${encodeURIComponent(
        String(verticalAlign)
      )}/${encodeURIComponent(String(crop))}/${encodeURIComponent(
        String(filters)
      )}`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options
  );
};

export const getGetCaptureImageTagThumbnailById2QueryKey = (
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string
) => {
  return [
    `/api/CaptureImageTags/${id}/Thumbnail/${fit}/${size}/${horizontalAlign}/${verticalAlign}/${crop}/${filters}`,
  ] as const;
};

export const getGetCaptureImageTagThumbnailById2QueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImageTagThumbnailById2>>,
  TError = ErrorType<void>
>(
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageTagThumbnailById2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureImageTagThumbnailById2QueryKey(
      id,
      fit,
      size,
      horizontalAlign,
      verticalAlign,
      crop,
      filters
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImageTagThumbnailById2>>
  > = ({ signal }) =>
    getCaptureImageTagThumbnailById2(
      id,
      fit,
      size,
      horizontalAlign,
      verticalAlign,
      crop,
      filters,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(
      id &&
      fit &&
      size &&
      horizontalAlign &&
      verticalAlign &&
      crop &&
      filters
    ),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImageTagThumbnailById2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImageTagThumbnailById2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageTagThumbnailById2>>
>;
export type GetCaptureImageTagThumbnailById2QueryError = ErrorType<void>;

export const useGetCaptureImageTagThumbnailById2 = <
  TData = Awaited<ReturnType<typeof getCaptureImageTagThumbnailById2>>,
  TError = ErrorType<void>
>(
  id: number,
  fit: string,
  size: string,
  horizontalAlign: string,
  verticalAlign: string,
  crop: string,
  filters: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageTagThumbnailById2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImageTagThumbnailById2QueryOptions(
    id,
    fit,
    size,
    horizontalAlign,
    verticalAlign,
    crop,
    filters,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureImageTagThumbnailById = (
  id: number,
  getCaptureImageTagThumbnailByIdBody: BodyType<string[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/CaptureImageTags/${encodeURIComponent(String(id))}/Thumbnail`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getCaptureImageTagThumbnailByIdBody,
      responseType: 'blob',
    },
    options
  );
};

export const getGetCaptureImageTagThumbnailByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCaptureImageTagThumbnailById>>,
    TError,
    { id: number; data: BodyType<string[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getCaptureImageTagThumbnailById>>,
  TError,
  { id: number; data: BodyType<string[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCaptureImageTagThumbnailById>>,
    { id: number; data: BodyType<string[]> }
  > = (props) => {
    const { id, data } = props ?? {};

    return getCaptureImageTagThumbnailById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetCaptureImageTagThumbnailByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageTagThumbnailById>>
>;
export type GetCaptureImageTagThumbnailByIdMutationBody = BodyType<string[]>;
export type GetCaptureImageTagThumbnailByIdMutationError = ErrorType<void>;

export const useGetCaptureImageTagThumbnailById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCaptureImageTagThumbnailById>>,
    TError,
    { id: number; data: BodyType<string[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getCaptureImageTagThumbnailById>>,
  TError,
  { id: number; data: BodyType<string[]> },
  TContext
> => {
  const mutationOptions =
    getGetCaptureImageTagThumbnailByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureImageTagsByCaptureId = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureImageTag[]>(
    {
      url: `/api/CaptureImageTags/CaptureId/${encodeURIComponent(
        String(captureId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureImageTagsByCaptureIdQueryKey = (
  captureId: number
) => {
  return [`/api/CaptureImageTags/CaptureId/${captureId}`] as const;
};

export const getGetCaptureImageTagsByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureImageTagsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageTagsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureImageTagsByCaptureIdQueryKey(captureId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureImageTagsByCaptureId>>
  > = ({ signal }) =>
    getCaptureImageTagsByCaptureId(captureId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureImageTagsByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureImageTagsByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureImageTagsByCaptureId>>
>;
export type GetCaptureImageTagsByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureImageTagsByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureImageTagsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureImageTagsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureImageTagsByCaptureIdQueryOptions(
    captureId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPagedCaptureImageTagsByCaptureId = (
  captureId: number,
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureImageTag[]>(
    {
      url: `/api/CaptureImageTags/CaptureId/${encodeURIComponent(
        String(captureId)
      )}/${encodeURIComponent(String(skip))}/${encodeURIComponent(
        String(take)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedCaptureImageTagsByCaptureIdQueryKey = (
  captureId: number,
  skip: number,
  take: number
) => {
  return [
    `/api/CaptureImageTags/CaptureId/${captureId}/${skip}/${take}`,
  ] as const;
};

export const getGetPagedCaptureImageTagsByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedCaptureImageTagsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptureImageTagsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedCaptureImageTagsByCaptureIdQueryKey(captureId, skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedCaptureImageTagsByCaptureId>>
  > = ({ signal }) =>
    getPagedCaptureImageTagsByCaptureId(
      captureId,
      skip,
      take,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(captureId && skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedCaptureImageTagsByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedCaptureImageTagsByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedCaptureImageTagsByCaptureId>>
>;
export type GetPagedCaptureImageTagsByCaptureIdQueryError = ErrorType<void>;

export const useGetPagedCaptureImageTagsByCaptureId = <
  TData = Awaited<ReturnType<typeof getPagedCaptureImageTagsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptureImageTagsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedCaptureImageTagsByCaptureIdQueryOptions(
    captureId,
    skip,
    take,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
