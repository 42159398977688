import { useLayoutEffect, useRef } from 'react';

export function useCallbackRef(callback: any) {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  return callbackRef;
}
