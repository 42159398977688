import { Fill, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Style from 'ol/style/Style';

export interface ILineStyle {
  color: string;
  zIndex?: number;
  radius?: number;
}

const regular = ({ color, zIndex, radius = 4 }: ILineStyle) => {
  return new Style({
    fill: new Fill({
      color: color,
    }),
    stroke: new Stroke({
      color: color,
      width: 2,
    }),
    image: new CircleStyle({
      radius: radius,
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 1,
      }),
    }),
    zIndex: zIndex,
  });
};

const CloudOpenlayersLineStyles = {
  regular,
};

export { CloudOpenlayersLineStyles };
