import { sortAnalytics } from './sort_analytics';
import { sortByAttributeName } from './sort_by_attributeName';
import { sortCountries } from './sort_countries';
import { sortCustomerAnalytics } from './sort_customerAnalytics';
import { sortFarms } from './sort_farms';
import { sortImageTime } from './sort_imageTime';
import { sortOrganizations } from './sort_organizations';
import { sortProjects } from './sort_projects';
import { sortRegions } from './sort_regions';
import { sortUsers } from './sort_users';

const Sort = {
  organizations: sortOrganizations,
  users: sortUsers,
  projects: sortProjects,
  countries: sortCountries,
  regions: sortRegions,
  analytics: sortAnalytics,
  farms: sortFarms,
  sortByAtt: sortByAttributeName,
  customerAnalytics: sortCustomerAnalytics,
  sortByImageTime: sortImageTime,
};

export { Sort };
