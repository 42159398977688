/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type {
  User,
  UserBaseDto,
  UserDto,
  UserFilter,
  UserFullDto,
} from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getUser = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<User>(
    { url: `/api/Users`, method: 'GET', signal },
    options
  );
};

export const getGetUserQueryKey = () => {
  return [`/api/Users`] as const;
};

export const getGetUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({
    signal,
  }) => getUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUser>>
>;
export type GetUserQueryError = ErrorType<void>;

export const useGetUser = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUsersAvailibleToAssignByUser = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<User[]>(
    { url: `/api/Users/Customers`, method: 'GET', signal },
    options
  );
};

export const getGetUsersAvailibleToAssignByUserQueryKey = () => {
  return [`/api/Users/Customers`] as const;
};

export const getGetUsersAvailibleToAssignByUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUsersAvailibleToAssignByUserQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>
  > = ({ signal }) => getUsersAvailibleToAssignByUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersAvailibleToAssignByUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>
>;
export type GetUsersAvailibleToAssignByUserQueryError = ErrorType<void>;

export const useGetUsersAvailibleToAssignByUser = <
  TData = Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUsersAvailibleToAssignByUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersAvailibleToAssignByUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUsersAvailibleFromCaptures = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<User[]>(
    { url: `/api/Users/Captures`, method: 'GET', signal },
    options
  );
};

export const getGetUsersAvailibleFromCapturesQueryKey = () => {
  return [`/api/Users/Captures`] as const;
};

export const getGetUsersAvailibleFromCapturesQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUsersAvailibleFromCapturesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>
  > = ({ signal }) => getUsersAvailibleFromCaptures(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersAvailibleFromCapturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>
>;
export type GetUsersAvailibleFromCapturesQueryError = ErrorType<void>;

export const useGetUsersAvailibleFromCaptures = <
  TData = Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUsersAvailibleFromCaptures>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersAvailibleFromCapturesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getQAQCUsers = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<User[]>(
    { url: `/api/Users/QAQC`, method: 'GET', signal },
    options
  );
};

export const getGetQAQCUsersQueryKey = () => {
  return [`/api/Users/QAQC`] as const;
};

export const getGetQAQCUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getQAQCUsers>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQAQCUsers>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQAQCUsersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getQAQCUsers>>> = ({
    signal,
  }) => getQAQCUsers(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQAQCUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQAQCUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQAQCUsers>>
>;
export type GetQAQCUsersQueryError = ErrorType<void>;

export const useGetQAQCUsers = <
  TData = Awaited<ReturnType<typeof getQAQCUsers>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQAQCUsers>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetQAQCUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredPagedUsers = (
  skip: number,
  take: number,
  userFilter: BodyType<UserFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<UserBaseDto[]>(
    {
      url: `/api/Users/Admin/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userFilter,
    },
    options
  );
};

export const getGetFilteredPagedUsersMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedUsers>>,
    TError,
    { skip: number; take: number; data: BodyType<UserFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPagedUsers>>,
  TError,
  { skip: number; take: number; data: BodyType<UserFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPagedUsers>>,
    { skip: number; take: number; data: BodyType<UserFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPagedUsers(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPagedUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredPagedUsers>>
>;
export type GetFilteredPagedUsersMutationBody = BodyType<UserFilter>;
export type GetFilteredPagedUsersMutationError = ErrorType<void>;

export const useGetFilteredPagedUsers = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedUsers>>,
    TError,
    { skip: number; take: number; data: BodyType<UserFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPagedUsers>>,
  TError,
  { skip: number; take: number; data: BodyType<UserFilter> },
  TContext
> => {
  const mutationOptions = getGetFilteredPagedUsersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getById = (
  userId: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<UserFullDto>(
    {
      url: `/api/Users/Admin/User/${encodeURIComponent(String(userId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetByIdQueryKey = (userId: string) => {
  return [`/api/Users/Admin/User/${userId}`] as const;
};

export const getGetByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getById>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetByIdQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getById>>> = ({
    signal,
  }) => getById(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getById>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getById>>
>;
export type GetByIdQueryError = ErrorType<void>;

export const useGetById = <
  TData = Awaited<ReturnType<typeof getById>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetByIdQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteById = (
  userId: string,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<UserFullDto>(
    {
      url: `/api/Users/Admin/User/${encodeURIComponent(String(userId))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteById>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteById>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteById>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {};

    return deleteById(userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteById>>
>;

export type DeleteByIdMutationError = ErrorType<void>;

export const useDeleteById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteById>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteById>>,
  TError,
  { userId: string },
  TContext
> => {
  const mutationOptions = getDeleteByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getByEmail = (
  email: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<UserFullDto>(
    {
      url: `/api/Users/Admin/User/Email/${encodeURIComponent(String(email))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetByEmailQueryKey = (email: string) => {
  return [`/api/Users/Admin/User/Email/${email}`] as const;
};

export const getGetByEmailQueryOptions = <
  TData = Awaited<ReturnType<typeof getByEmail>>,
  TError = ErrorType<void>
>(
  email: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getByEmail>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetByEmailQueryKey(email);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getByEmail>>> = ({
    signal,
  }) => getByEmail(email, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!email,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getByEmail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetByEmailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getByEmail>>
>;
export type GetByEmailQueryError = ErrorType<void>;

export const useGetByEmail = <
  TData = Awaited<ReturnType<typeof getByEmail>>,
  TError = ErrorType<void>
>(
  email: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getByEmail>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetByEmailQueryOptions(email, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateUserInfo = (
  userDto: BodyType<UserDto>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<UserFullDto>(
    {
      url: `/api/Users/Admin/User`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: userDto,
    },
    options
  );
};

export const getUpdateUserInfoMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserInfo>>,
    TError,
    { data: BodyType<UserDto> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserInfo>>,
  TError,
  { data: BodyType<UserDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserInfo>>,
    { data: BodyType<UserDto> }
  > = (props) => {
    const { data } = props ?? {};

    return updateUserInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserInfo>>
>;
export type UpdateUserInfoMutationBody = BodyType<UserDto>;
export type UpdateUserInfoMutationError = ErrorType<void>;

export const useUpdateUserInfo = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserInfo>>,
    TError,
    { data: BodyType<UserDto> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserInfo>>,
  TError,
  { data: BodyType<UserDto> },
  TContext
> => {
  const mutationOptions = getUpdateUserInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createUserInfo = (
  userDto: BodyType<UserDto>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<UserFullDto>(
    {
      url: `/api/Users/Admin/User`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userDto,
    },
    options
  );
};

export const getCreateUserInfoMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserInfo>>,
    TError,
    { data: BodyType<UserDto> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUserInfo>>,
  TError,
  { data: BodyType<UserDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUserInfo>>,
    { data: BodyType<UserDto> }
  > = (props) => {
    const { data } = props ?? {};

    return createUserInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUserInfo>>
>;
export type CreateUserInfoMutationBody = BodyType<UserDto>;
export type CreateUserInfoMutationError = ErrorType<void>;

export const useCreateUserInfo = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserInfo>>,
    TError,
    { data: BodyType<UserDto> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUserInfo>>,
  TError,
  { data: BodyType<UserDto> },
  TContext
> => {
  const mutationOptions = getCreateUserInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const disableUserById = (
  userId: string,
  disable: boolean,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<UserFullDto>(
    {
      url: `/api/Users/Admin/User/${encodeURIComponent(
        String(userId)
      )}/disable/${encodeURIComponent(String(disable))}`,
      method: 'PATCH',
    },
    options
  );
};

export const getDisableUserByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disableUserById>>,
    TError,
    { userId: string; disable: boolean },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof disableUserById>>,
  TError,
  { userId: string; disable: boolean },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof disableUserById>>,
    { userId: string; disable: boolean }
  > = (props) => {
    const { userId, disable } = props ?? {};

    return disableUserById(userId, disable, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DisableUserByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof disableUserById>>
>;

export type DisableUserByIdMutationError = ErrorType<void>;

export const useDisableUserById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disableUserById>>,
    TError,
    { userId: string; disable: boolean },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof disableUserById>>,
  TError,
  { userId: string; disable: boolean },
  TContext
> => {
  const mutationOptions = getDisableUserByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const triggerChangePassword = (
  userId: string,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Users/Admin/User/${encodeURIComponent(
        String(userId)
      )}/ChangePassword`,
      method: 'POST',
    },
    options
  );
};

export const getTriggerChangePasswordMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof triggerChangePassword>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof triggerChangePassword>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof triggerChangePassword>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {};

    return triggerChangePassword(userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TriggerChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof triggerChangePassword>>
>;

export type TriggerChangePasswordMutationError = ErrorType<void>;

export const useTriggerChangePassword = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof triggerChangePassword>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof triggerChangePassword>>,
  TError,
  { userId: string },
  TContext
> => {
  const mutationOptions = getTriggerChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const triggerVerifyEmail = (
  userId: string,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Users/Admin/User/${encodeURIComponent(
        String(userId)
      )}/VerifyEmail`,
      method: 'POST',
    },
    options
  );
};

export const getTriggerVerifyEmailMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof triggerVerifyEmail>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof triggerVerifyEmail>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof triggerVerifyEmail>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {};

    return triggerVerifyEmail(userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TriggerVerifyEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof triggerVerifyEmail>>
>;

export type TriggerVerifyEmailMutationError = ErrorType<void>;

export const useTriggerVerifyEmail = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof triggerVerifyEmail>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof triggerVerifyEmail>>,
  TError,
  { userId: string },
  TContext
> => {
  const mutationOptions = getTriggerVerifyEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
