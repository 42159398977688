export interface BackgroundTaskManagerContextProps {
  addTaskGroup: (args: AddTaskGroupArgs) => string;
  removeTaskGroup: (groupId: string) => void;
  updateTaskProgress: (
    groupId: string,
    taskId: string,
    process: number
  ) => void;
  cancelTaskGroup: (groupId: string) => void;
  togglePauseTaskGroup: (groupId: string) => void;
  createBackgroundTask: (
    taskDesc: string,
    task: (
      resolve: (value: BackgroundTaskResultObject) => void,
      reject: (reason?: BackgroundTaskResultObject) => void,
      abortSignal: AbortSignal,
      groupId: string,
      taskId: string
    ) => Promise<void>,
    taskCustomPayload?: any
  ) => BackgroundTaskArgs;
  retryTaskGroup: (groupId: string) => void;
  taskGroups: BackgroundTaskGroup[];
}

export interface BackgroundTaskMangerProps {
  children: JSX.Element;
  maxConcurrency: number;
}

export interface AddTaskGroupArgs {
  groupDesc: string;
  groupTasks: BackgroundTaskArgs[];
  onSuccess?: (group: BackgroundTaskGroup) => Promise<void>;
  onError?: (group: BackgroundTaskGroup) => Promise<void>;
  onCancel?: (group: BackgroundTaskGroup) => Promise<void>;
  tags?: string[];
  groupCustomPayload?: any;
  isCancellable?: boolean;
}

export enum BackgroundTaskResult {
  success = 'success',
  error = 'error',
  cancel = 'cancel',
}

export enum BackgroundTaskGroupResult {
  success = 'success',
  error = 'error',
  cancel = 'cancel',
}

export interface BackgroundTaskResultObject {
  type: BackgroundTaskResult;
  payload?: any;
}

export type BackgroundTaskArgs = {
  taskDesc: string;
  task: (
    groupId: string,
    taskId: string,
    abortSignal: AbortSignal
  ) => Promise<BackgroundTaskResultObject>;
  taskCustomPayload?: any;
};

export type BackgroundTask = {
  taskId: string;
  taskDesc: string;
  groupId: string;
  task: (
    groupId: string,
    taskId: string,
    abortSignal: AbortSignal
  ) => Promise<BackgroundTaskResultObject>;
  abortSignal: AbortSignal;
  progress?: number;
  result?: BackgroundTaskResultObject;
  isBeingProcessed?: boolean;
  taskCustomPayload?: any;
};

export interface BackgroundTaskGroup {
  groupId: string;
  groupDesc: string;
  tasks: BackgroundTask[];
  onSuccess?: (group: BackgroundTaskGroup) => Promise<void>;
  onError?: (group: BackgroundTaskGroup) => Promise<void>;
  onCancel?: (group: BackgroundTaskGroup) => Promise<void>;
  abortController: AbortController;
  groupResult?: BackgroundTaskGroupResult;
  retryAttempts: number;
  isPaused: boolean;
  isBeingFinalized: boolean;
  tags: string[];
  groupCustomPayload?: any;
  isCancellable: boolean;
}
