import { ReactNode } from 'react';

interface StyleProperty {
  name: string;
  value: ReactNode;
  selected: boolean;

  /**
   * If not provided, implies that this component is disabled.
   */
  onSelect?: () => void;
}

export function StyleProperty({
  name,
  value,
  selected,
  onSelect,
}: StyleProperty) {
  return (
    <div
      className={`px-4 py-3 text-gray-800 text-sm ${
        selected ? 'border-b' : 'border-b border-r'
      } ${!onSelect && 'cursor-not-allowed'} border-gray-300 cursor-pointer`}
      onClick={onSelect}
    >
      <h3 className="font-bold mb-2 leading-3">{name}</h3>
      <div
        className={`truncate ${
          selected ? 'text-system-gray-600' : 'text-system-gray-400'
        }`}
      >
        {value}
      </div>
    </div>
  );
}
