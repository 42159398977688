import {
  IconDefinition,
  faMap,
  faRoad,
  faSatellite,
} from '@fortawesome/pro-regular-svg-icons';
import { MapBrowserEvent } from 'ol';
import Feature from 'ol/Feature';
import OlMap from 'ol/Map';
import Overlay from 'ol/Overlay';
import { Extent } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import { StyleLike } from 'ol/style/Style';
import React, { useEffect, useMemo, useState } from 'react';

import { OpenMapLayer } from '@agerpoint/types';

export const layerCache = new Map<
  {
    layer: OpenMapLayer;
    id?: string;
  },
  TileLayer<BingMaps>
>();

export const openMapLayerIcons = {
  [OpenMapLayer.RoadMap]: faRoad,
  [OpenMapLayer.Aerial]: faSatellite,
  [OpenMapLayer.Hybrid]: faMap,
};

export interface OpenLayerGeneratedFeature {
  id: string | number;
  latitude: number;
  longitude: number;
  name?: string;
  style: string;
}

export interface IPinMarkerStyle {
  icon: IconDefinition;
  color: string;
  scale?: number;
  zIndex?: number;
}

export interface IDotMarkerStyle {
  color: string;
  zIndex?: number;
  radius?: number;
  text?: string;
}

export interface OpenLayerMapProps<T> {
  id: string;
  controller?: (controller: OpenLayerMapController) => void;
  bingKey: string;
  mapLayers: {
    used: OpenMapLayer[];
    initial: OpenMapLayer;
  };
  featureLayer?: {
    data: T[];
    styles?: { [key: string]: StyleLike };
    featureGenerator: (data: T) => OpenLayerGeneratedFeature | null;
    popupGenerator?: (id: number | string) => React.ReactNode;
  };
  geoJsonLayer?: {
    data: Feature<Geometry>[];
  };
  callbacks?: {
    onDragBoxSelect?: (ids: (number | string)[]) => void;
    onFeatureClick?: (
      id: number | string,
      event: MapBrowserEvent<PointerEvent>
    ) => void;
  };
  dependencies?: React.DependencyList;
}

export interface OpenLayerMapController {
  zoomMapToLonLat?: (lonlat: [number, number], zoom?: number) => void;
  zoomMapToExtent?: (extent: Extent) => void;
  refreshView?: () => void;
}

interface OpenLayerMapPopupProps {
  popup?: { overlay?: Overlay | undefined; id?: string | number | undefined };
  setPopup: (overlay?: Overlay) => void;
  generator: (id: number | string) => React.ReactNode;
}

export const OpenlayerMapPopup = ({
  popup,
  setPopup,
  generator,
}: OpenLayerMapPopupProps) => {
  const [popupRef, setPopupRef] = useState<HTMLElement>();

  useEffect(() => {
    const overlay = popupRef
      ? new Overlay({
          element: popupRef,
          positioning: 'center-left',
          offset: [8, 0],
        })
      : undefined;

    setPopup(overlay);
  }, [popupRef]);

  const children = useMemo(() => {
    if (!popup?.id) {
      return null;
    }

    return generator(popup.id);
  }, [generator, popup?.id]);

  useEffect(() => {
    if (popup?.overlay?.getPosition()) {
      popup.overlay.panIntoView({ animation: { duration: 250 }, margin: 50 });
    }
  }, [children]);

  return (
    <div ref={(ref) => setPopupRef(ref ?? undefined)}>
      {children && (
        <div className="flex flex-row relative">
          <div className="flex justify-center items-center z-10 relative">
            <div
              className="absolute m-y-auto w-4 h-4 rotate-45 rounded-bl-sm bg-gray-400"
              style={{ left: '-7px' }}
            ></div>
          </div>
          <div className=" bg-white rounded-lg overflow-hidden z-20 border border-gray-400 shadow">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export const openLayerMapViewExtentStorage: { [id: string]: Extent } = {};

export const useOpenLayerMapExtentRestoration = ({
  id,
  map,
  mapContainer,
}: {
  id: string;
  map: React.MutableRefObject<OlMap | undefined>;
  mapContainer: HTMLDivElement | null;
}) => {
  const [scrollRestored, setScrollRestored] = useState(false);

  useEffect(() => {
    if (id && map.current && !scrollRestored) {
      const view = map.current.getView();
      const extent: Extent | undefined = openLayerMapViewExtentStorage?.[id];
      if (extent !== undefined) {
        view.fit(extent);
      }
      setScrollRestored(true);
    }
  }, [id, scrollRestored, map, mapContainer]);

  useEffect(() => {
    if (!scrollRestored) {
      return;
    }
    const m = map?.current;

    if (!m) {
      return;
    }

    const onMoveEnd = () => {
      openLayerMapViewExtentStorage[id] = m.getView().calculateExtent();
    };
    m.addEventListener('moveend', onMoveEnd);

    return () => {
      m.removeEventListener('moveend', onMoveEnd);
    };
  }, [id, map, scrollRestored, mapContainer]);
};
