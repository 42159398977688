import { faLocationPin } from '@fortawesome/pro-solid-svg-icons';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import { IDotMarkerStyle, IPinMarkerStyle } from './open-layer-map.utilities';

const pin = ({ icon, color, scale = 1, zIndex }: IPinMarkerStyle): Style => {
  // Viewport width - icon width / 4 = translated to center
  const translateIconX = (512 - icon.icon[0]) / 4;

  const svg = `
    <svg width="37.5" height="57" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" cursor="pointer">
        <g transform="translate(64 0) scale(1 1)">
          <path d="${faLocationPin.icon[4]}" fill="${color.replace(
    '#',
    '%23'
  )}" stroke="white" stroke-width="15"/>
        </g>
        <g transform-origin="top center" transform="translate(${translateIconX} 64) scale(0.5 0.5)">
          <path d="${icon.icon[4]}" fill="white"/>
        </g>
    </svg>`;

  return new Style({
    image: new Icon({
      opacity: 1,
      src: 'data:image/svg+xml;utf8,' + svg,
      scale: scale,
      anchor: [0.5, 1],
    }),
    zIndex: zIndex,
  });
};

const dot = ({ color, zIndex, radius = 8, text }: IDotMarkerStyle) => {
  const characters = text?.length;
  const singleton = text === '1';
  const showText = characters && !singleton;

  let r = radius;
  if (showText) {
    r = radius + (characters ?? 0) * 3;
  }
  return new Style({
    image: new CircleStyle({
      radius: r,
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 1,
      }),
    }),
    zIndex: zIndex,
    text: showText
      ? new Text({
          text: text,
          fill: new Fill({
            color: '#fff',
          }),
          font: '12px sans-serif',
        })
      : undefined,
  });
};

const OpenLayerMarkerStyles = {
  pin,
  dot,
};

export { OpenLayerMarkerStyles };
