import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels, formatDateAndTime } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminMosaicEnginesQueries } from './admin-mosaic-engines-queries';

export const AdminMosaicEnginesDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  usePageTitle(() => 'Platform - Mosaic Engines', []);

  const { permissions } = useGlobalStore();
  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );

  const {
    mosaicEngineQuery,
    pipelinesQuery,
    mosaicEnginePutMutation,
    mosaicEngineArchivePutMutation,
    mosaicEngineUnarchivePutMutation,
  } = useAdminMosaicEnginesQueries();

  const formValidation = useFormValidation();
  const [mosaicEngine, setMosaicEngine] = useState<APIModels.MosaicEngine>({});
  const [selectedPipeline, setSelectedPipeline] =
    useState<APIModels.Pipeline>();

  useEffect(() => {
    setMosaicEngine(mosaicEngineQuery.data ?? {});
  }, [mosaicEngineQuery.data]);

  useEffect(() => {
    setSelectedPipeline(
      pipelinesQuery.data?.find(
        (p) => p.id === mosaicEngineQuery.data?.pipelineId
      )
    );
  }, [mosaicEngineQuery.data, pipelinesQuery.data]);

  useEffect(() => {
    setMosaicEngine((prev) => ({
      ...prev,
      pipelineId: selectedPipeline?.id,
    }));
  }, [selectedPipeline]);

  const updateMosaicEngine = useCallback(async () => {
    if (mosaicEnginePutMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    mosaicEnginePutMutation.mutate({
      data: mosaicEngine,
    });
  }, [mosaicEngine, formValidation, mosaicEnginePutMutation]);

  const archiveMosaicEngine = useCallback(async () => {
    if (mosaicEngineArchivePutMutation.isPending) {
      return;
    }

    const confirm = window.confirm(
      'Are you sure you want to archive this mosaic engine?'
    );
    if (!confirm) {
      return;
    }

    mosaicEngineArchivePutMutation.mutate({
      data: { ...mosaicEngineQuery.data, archived: true },
    });
  }, [mosaicEngineQuery.data, mosaicEngineArchivePutMutation]);

  const unarchiveMosaicEngine = useCallback(async () => {
    if (mosaicEngineUnarchivePutMutation.isPending) {
      return;
    }

    const confirm = window.confirm(
      'Are you sure you want to restore this mosaic engine?'
    );
    if (!confirm) {
      return;
    }

    mosaicEngineUnarchivePutMutation.mutate({
      data: { ...mosaicEngineQuery.data, archived: false },
    });
  }, [mosaicEngineQuery.data, mosaicEngineUnarchivePutMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Mosaic Engines',
              path: isViteApp
                ? '/app/admin/platform/mosaic-engines'
                : '/admin/mosaic-engines',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="mosaic-engine-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/mosaic-engines' + params);
            } else {
              navigate('/admin/mosaic-engines' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">
          {mosaicEngineQuery.data?.displayName ?? mosaicEngineQuery.data?.name}
        </h1>
      </div>
      {mosaicEngineQuery.isLoading ? (
        <PageLoadingState />
      ) : mosaicEngineQuery.isError ? (
        <PageErrorState
          entityName="mosaic engine"
          pluralEntityName="mosaic engines"
          statusCode={mosaicEngineQuery.error?.response?.status ?? 500}
          tryAgainCallback={() => {
            mosaicEngineQuery.refetch();
          }}
          tryAgainLoading={mosaicEngineQuery.isFetching}
          navigateBackCallback={() =>
            navigate(
              isViteApp
                ? '/app/admin/platform/mosaic-engines' + params
                : '/admin/mosaic-engines' + params
            )
          }
        />
      ) : (
        <div className="p-4 w-full flex flex-col max-w-lg gap-2">
          <Input.Text.Single
            id="mosaic-engine-name-input"
            label={<Input.Label label="Name" required />}
            value={mosaicEngine.name || ''}
            setValue={(name) => {
              setMosaicEngine({ ...mosaicEngine, name });
            }}
            error={
              <Input.Error
                error={formValidation.errors['mosaic-engine-name-input']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Name')],
            }}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Text.Single
            id="mosaic-engine-display-name-input"
            label={<Input.Label label="Display Name" required />}
            value={mosaicEngine.displayName || ''}
            setValue={(displayName) => {
              setMosaicEngine({ ...mosaicEngine, displayName });
            }}
            error={
              <Input.Error
                error={
                  formValidation.errors['mosaic-engine-display-name-input']
                }
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Display Name')],
            }}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Text.Single
            id="mosaic-engine-description-input"
            label={<Input.Label label="Description" />}
            value={mosaicEngine.description || ''}
            setValue={(description) => {
              setMosaicEngine({ ...mosaicEngine, description });
            }}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Select.Single
            id="mosaic-engine-pipeline-input"
            title="Pipeline"
            options={pipelinesQuery.data ?? []}
            optionBuilder={(pipeline) => pipeline?.name || ''}
            loading={pipelinesQuery.isLoading}
            label={<Input.Label label="Pipeline" />}
            value={selectedPipeline}
            setValue={setSelectedPipeline}
            readOnly={!canManageAnalyticRequests}
          />
          {canManageAnalyticRequests && (
            <div className="w-full flex flex-row justify-end items-center py-4 gap-2">
              {mosaicEngine?.archived ? (
                <Button.Secondary
                  id="mosaic-engine-details-restore-button"
                  label={'Restore'}
                  onClick={unarchiveMosaicEngine}
                  loading={mosaicEngineUnarchivePutMutation.isPending}
                />
              ) : (
                <Button.Danger
                  id="mosaic-engine-details-archive-button"
                  label={'Archive'}
                  onClick={archiveMosaicEngine}
                  loading={mosaicEngineArchivePutMutation.isPending}
                />
              )}
              <Button.Primary
                id="mosaic-engine-details-save-button"
                label={'Save'}
                onClick={updateMosaicEngine}
                loading={mosaicEnginePutMutation.isPending}
              />
            </div>
          )}
          <EntityDetailsSection
            items={[
              {
                label: 'ID',
                value: mosaicEngine?.id,
              },
              {
                label: 'Archived',
                value: mosaicEngine?.archived ? 'Yes' : 'No',
              },
              {
                label: 'Created',
                value: formatDateAndTime(mosaicEngine?.createDatetime) || '-',
              },
              {
                label: 'Last Updated',
                value: formatDateAndTime(mosaicEngine?.updateDatetime) || '-',
              },
              {
                label: 'Updated By UUID',
                value: mosaicEngine?.updatedById || '-',
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
