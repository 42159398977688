import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseMutateReturn } from 'restful-react';

import {
  Customer,
  Project,
  User,
  usePostLayerGroup,
  usePostProject,
} from '@agerpoint/api';
import {
  DialogModal,
  Input,
  PrimaryButton,
  SvgElement,
} from '@agerpoint/component';
import { UserClaims } from '@agerpoint/types';
import {
  Sort,
  hasClaims,
  useFormValidation,
  useGlobalStore,
  useToasts,
} from '@agerpoint/utilities';

interface CreateProjectModalProps {
  organizations?: Customer[];
  users?: User[];
  open: boolean;
  handleCloseDialog: () => void;
  onProjectCreated?: (project: Project) => void;
}

export const CreateProjectModal = ({
  organizations,
  open,
  users: usersProp,
  handleCloseDialog,
  onProjectCreated,
}: CreateProjectModalProps) => {
  const { mutate: postProject } = usePostProject(
    {}
  ) as unknown as UseMutateReturn<Project, void, Project, void, void>;
  const { mutate: postLayerGroup } = usePostLayerGroup({});

  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useGlobalStore();

  const toasts = useToasts();

  const { TreeSvg, BaseMapsSvg } = useMemo(
    () => ({
      TreeSvg: <SvgElement type="TreeIcon" className="w-5 h-5" />,
      BaseMapsSvg: <SvgElement type="BaseMapsIcon" />,
    }),
    []
  );

  const layerGroups = useMemo(
    () => ({
      Default: [
        { name: 'Polygon', icon: TreeSvg },
        { name: 'Imagery', icon: TreeSvg },
        { name: 'Base Map', icon: BaseMapsSvg },
      ],
      Empty: [
        {
          name: 'Group 1',
          icon: TreeSvg,
        },
        { name: 'Base Map', icon: BaseMapsSvg },
      ],
      Locational: [
        { name: 'Site 1', icon: TreeSvg },
        { name: 'Site 2', icon: TreeSvg },
        { name: 'Site 3', icon: TreeSvg },
        { name: 'Base Map', icon: BaseMapsSvg },
      ],
      'Plant Morphologies': [
        { name: 'Morphologies', icon: TreeSvg },
        { name: 'Blocks', icon: TreeSvg },
        { name: 'Base Map', icon: BaseMapsSvg },
      ],
      'Ag Insurance': [
        { name: 'Ground Samples', icon: TreeSvg },
        { name: 'Training Data', icon: TreeSvg },
        { name: 'Damage Estimates', icon: TreeSvg },
        { name: 'Field Boundaries', icon: TreeSvg },
        { name: 'Imagery', icon: TreeSvg },
        { name: 'Base Map', icon: BaseMapsSvg },
      ],
      Climate: [
        { name: 'Biomass', icon: TreeSvg },
        { name: 'Block Boundaries', icon: TreeSvg },
        { name: 'Base Map', icon: BaseMapsSvg },
      ],
      'Ag R&D': [
        { name: 'Plot Boundaries', icon: TreeSvg },
        { name: 'Imagery', icon: TreeSvg },
        { name: 'Base Map', icon: BaseMapsSvg },
      ],
    }),
    [BaseMapsSvg, TreeSvg]
  );

  useEffect(() => {
    if (open) {
      setProjectName('');
      setProjectOrganization(undefined);
      setProjectOwner(user ?? undefined);
      setProjectConfiguration('Default');
      setProjectInviteSearchMock('');
      setProjectPublic(true);
      formValidation.advancedCallbacks.reset();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setProjectOwner(user ?? undefined);
    }
  }, [user]);

  const users = useMemo(() => {
    if (!usersProp || !user) return undefined;

    // If the user is not in the list of users, add them to the list from the global store
    if (usersProp.findIndex((u) => u.id === user.id) === -1) {
      return [...usersProp, user];
    }

    return usersProp;
  }, [usersProp, user]);

  const isAdmin = useMemo(() => {
    return hasClaims(
      [UserClaims.AgerAdmin],
      (user?.cloudClaims || []) as UserClaims[]
    );
  }, [user]);

  const [projectName, setProjectName] = useState<string>('');
  const [projectOrganization, setProjectOrganization] = useState<Customer>();
  const [projectOwner, setProjectOwner] = useState<User>();
  const [projectConfiguration, setProjectConfiguration] = useState<
    keyof typeof layerGroups | undefined
  >('Default');
  const [projectInviteSearchMock, setProjectInviteSearchMock] =
    useState<string>('');
  const [projectPublic, setProjectPublic] = useState<boolean>(true);

  const sortedOrganizations = useMemo(
    () => Sort.organizations(organizations),
    [organizations]
  );

  const sortedUsers = useMemo(() => Sort.users(users), [users]);

  const formValidation = useFormValidation();

  const handleCreateProject = useCallback(async () => {
    if (await formValidation.hasErrors()) {
      return;
    }

    setLoading(true);

    try {
      const project = await postProject({
        name: projectName.trim(),
        customerId: projectOrganization?.id as number,
        createdBy: projectOwner?.id ?? undefined,
      });

      if (!project) return;

      await Promise.allSettled(
        layerGroups[projectConfiguration as keyof typeof layerGroups]
          .filter((group) => group.name !== 'Base Map')
          .map((group) =>
            postLayerGroup({
              name: group.name,
              projectId: Number(project.id),
              visible: true,
            })
          )
      );

      toasts.add(toasts.prepare.entityCreated('project'));

      if (onProjectCreated) {
        onProjectCreated(project);
      }
    } catch (error) {
      console.error(error);

      toasts.add(toasts.prepare.error('Failed to create project!'));
    } finally {
      setLoading(false);
    }
  }, [
    projectName,
    projectOrganization,
    projectOwner,
    postProject,
    layerGroups,
    postLayerGroup,
    projectConfiguration,
    toasts,
    onProjectCreated,
    formValidation,
  ]);

  return (
    <DialogModal
      open={open}
      handleCloseDialog={handleCloseDialog}
      size={'large'}
      title={'New Project'}
      visibleOverflow={true}
    >
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-row w-full gap-2 pt-2">
          <div className="flex flex-col flex-1 gap-2">
            <Input.Text.Single
              id="project-name"
              label={<Input.Label label="Project Name" required />}
              value={projectName}
              setValue={setProjectName}
              error={
                <Input.Error error={formValidation.errors['project-name']} />
              }
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Name')],
              }}
            />
            <Input.Select.Single
              id="project-organization"
              options={sortedOrganizations ?? []}
              loading={sortedOrganizations === undefined}
              optionBuilder={(customer) =>
                customer.customerDisplayName ??
                customer.customerName ??
                'Unknown'
              }
              label={<Input.Label label="Organization" required />}
              title="Organization"
              value={projectOrganization}
              setValue={setProjectOrganization}
              error={
                <Input.Error
                  error={formValidation.errors['project-organization']}
                />
              }
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Organization')],
              }}
            />
            {isAdmin && (
              <Input.Select.Single
                id="project-owner"
                options={sortedUsers ?? []}
                loading={sortedUsers === undefined}
                optionBuilder={(o) =>
                  `${o?.userProfiles?.[0]?.firstName} ${o?.userProfiles?.[0]?.lastName}`.trim()
                }
                label={<Input.Label label="Owner" />}
                title="Owner"
                value={projectOwner}
                setValue={setProjectOwner}
                compareFn={(a, b) => a?.id === b?.id}
              />
            )}
          </div>
          <div className="flex flex-col flex-1 gap-2">
            <Input.Select.Single
              id="project-configuration"
              options={
                (Object.keys(layerGroups) ?? []) as (keyof typeof layerGroups)[]
              }
              optionBuilder={(o) => o}
              label={<Input.Label label="Project Configuration" required />}
              title="Project Configuration"
              value={projectConfiguration}
              setValue={setProjectConfiguration}
              search={false}
              error={
                <Input.Error
                  error={formValidation.errors['project-configuration']}
                />
              }
              validation={{
                validationState: formValidation,
                validators: [
                  Input.validators.required('Project Configuration'),
                ],
              }}
            />
            {projectConfiguration && (
              <div
                className={`divide-y divide-gray-500 border border-gray-500 rounded overflow-y-auto overflow-x-hidden`}
                style={{ maxHeight: '124px' }}
              >
                {layerGroups[projectConfiguration].map(({ name, icon }) => (
                  <div
                    key={name}
                    className="flex justify-start p-2 items-center"
                  >
                    <div className="flex justify-center w-7 pr-2">{icon}</div>
                    <div className="text-sm text-gray-700">{name}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col flex-1 gap-2">
            <Input.Select.Inline
              id="project-visibility"
              options={[true, false]}
              optionBuilder={(o) => (o ? 'Public' : 'Private')}
              value={projectPublic}
              setValue={setProjectPublic}
              label={<Input.Label label="Project Visibility" />}
            />
            <Input.Text.Single
              id="project-team-members"
              label={<Input.Label label="Invite" />}
              value={projectInviteSearchMock}
              setValue={setProjectInviteSearchMock}
              placeholder={'Search Team Members'}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <PrimaryButton
            label="Create Project"
            onClicked={() => {
              if (loading) return;

              handleCreateProject();
            }}
            disabled={loading}
          />
        </div>
      </div>
    </DialogModal>
  );
};
