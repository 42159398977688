import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import { Capture, Customer, usePutCaptureById } from '@agerpoint/api';
import { DialogModal, Input, PrimaryButton } from '@agerpoint/component';
import { useGlobalStore, useToasts } from '@agerpoint/utilities';

interface EditCapturesOrgModalProps {
  open: boolean;
  handleCloseDialog: () => void;
  captures: Capture[];
  customers: Customer[];
  changedCaptures?: (captures: Capture[]) => void;
}

export const EditCapturesOrgModal = ({
  open,
  captures,
  handleCloseDialog,
  customers,
  changedCaptures,
}: EditCapturesOrgModalProps) => {
  const toasts = useToasts();

  const { mutate: putCapture } = usePutCaptureById({
    id: NaN,
  });

  const [selectedOrganization, setSelectedOrganization] = useState<Customer>();

  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!captures.length) {
      return;
    }

    const selectedOrg = customers.find((c) => c.id === captures[0].customerId);

    for (let i = 1; i < captures.length; i++) {
      const c = captures[i];
      if (c.customerId !== selectedOrg?.id) {
        setSelectedOrganization(undefined);
        return;
      }
    }
    setSelectedOrganization(selectedOrg);
  }, [captures]);

  if (!captures.length) {
    return null;
  }

  return (
    <DialogModal
      open={open}
      handleCloseDialog={handleCloseDialog}
      size="small"
      title="Edit Organization"
      visibleOverflow={true}
    >
      <div className="relative w-full p-1">
        {loading && (
          <div
            className={`absolute top-0 bottom-0 left-0 right-0 bg-white
        opacity-75 flex flex-col gap-2 items-center justify-center z-50`}
          >
            <FontAwesomeIcon icon={faCircleNotch} className="w-8 h-8" spin />
            <p>{` Loading ${loadingMessage}`}</p>
          </div>
        )}
        <div className="py-2">
          <Input.Select.Single
            id="organization-select"
            value={selectedOrganization}
            setValue={setSelectedOrganization}
            options={customers}
            optionBuilder={(o) => o.customerDisplayName ?? 'Unknown'}
            loading={loading}
            title="Organization"
          />
        </div>
        <div className="flex flex-row justify-end">
          <PrimaryButton
            data-test-id="save-button"
            label="Save"
            onClicked={async () => {
              if (loading) {
                return;
              }
              setLoading(true);
              let allGood = true;
              let iterator = 1;
              const changed: Capture[] = [];
              for (let i = 0; i < captures.length; i++) {
                const c = captures[i];

                if (!c.id) {
                  continue;
                }
                const copy = { ...c };
                copy.customerId = selectedOrganization?.id;
                try {
                  setLoadingMessage(`(${iterator}/${captures.length})`);
                  await putCapture(copy, { pathParams: { id: c.id } });
                  iterator += 1;
                  changed.push({ ...copy });
                } catch (e) {
                  allGood = false;
                  console.error(e);
                }
              }

              if (allGood) {
                toasts.add(toasts.prepare.entityUpdated('capture(s)'));
              } else {
                toasts.add(
                  toasts.prepare.error('Failed to update every capture!')
                );
              }

              setLoading(false);
              changedCaptures?.(changed);
              handleCloseDialog();
            }}
            disabled={loading}
          />
        </div>
      </div>
    </DialogModal>
  );
};
