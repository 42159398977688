import { GroupLayersProps } from '@agerpoint/types';

export function BaseMapsGroupLayers({
  group: { layers },
  setVisible,
  bingKey,
}: GroupLayersProps) {
  return (
    <div className="justify-items-center w-3/4 mx-auto grid grid-cols-2 gap-5">
      {layers.map((layer) => (
        <div key={layer.name}>
          <button onClick={() => setVisibleInternal(layer.id)}>
            <img
              crossOrigin=""
              alt={layer.name}
              src={`https://dev.virtualearth.net/REST/v1/Imagery/Map/${layer.name}/57.73,11.94/12?mapSize=124,92&key=${bingKey}`}
              className={`border-2  ${
                layer.visible ? 'border-gray-600' : 'border-transparent'
              } rounded w-32 h-24`}
            />
          </button>
        </div>
      ))}
    </div>
  );

  function setVisibleInternal(layerId: number) {
    for (const layer of layers) {
      setVisible(layer.id, layer.id === layerId);
    }
  }
}
