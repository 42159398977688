import { useQuery } from '@tanstack/react-query';

import { APIClient, APIUtils } from '@agerpoint/api';
import { AnalyticRequestStatus } from '@agerpoint/types';
import { useLookupTable } from '@agerpoint/utilities';

interface AnalyticRequestWithCaptureObjects extends APIClient.AnalyticRequest {
  captureObjects?: APIClient.CaptureObject[];
}

export const useCaptureMapPopoverQueries = ({
  captureId,
}: {
  captureId?: number;
}) => {
  const usersQuery = APIClient.useGetUsersAvailibleFromCaptures({
    query: {
      queryKey: [APIUtils.QueryKey.usersCaptures],
      select: (data) => APIUtils.Sort.users(data),
    },
  });

  const usersLookupTable = useLookupTable(usersQuery.data, 'id');

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const captureObjectsQuery = APIClient.useGetCaptureObjectsByCaptureId(
    captureId as number,
    {
      query: {
        enabled: Number.isSafeInteger(Number(captureId ?? undefined)),
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: captureId },
          APIUtils.QueryKey.captureObjects,
        ],
      },
    }
  );

  const analyticRequestsQuery = APIClient.useGetAnalyticRequestsByCaptureId(
    captureId as number,
    {},
    {
      query: {
        enabled: Number.isSafeInteger(Number(captureId ?? undefined)),
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: captureId },
          APIUtils.QueryKey.analyticRequests,
        ],
      },
    }
  );

  const captureCustomAttributesQuery =
    APIClient.useGetCaptureCustomAttributesByCaptureId(captureId as number, {
      query: {
        enabled: Number.isSafeInteger(Number(captureId ?? undefined)),
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: captureId },
          APIUtils.QueryKey.captureCustomAttributes,
        ],
        select: (data) => data.filter((item) => item.validated === true),
      },
    });

  const captureCustomAttributesByCompleteAnalyticRequestsQuery = useQuery({
    queryKey: [
      APIUtils.QueryKey.captures,
      { captureId: captureId },
      APIUtils.QueryKey.captureCustomAttributesByCompleteAnalyticRequests,
    ],
    queryFn: async () => {
      const ars = await APIClient.getAnalyticRequestsByCaptureId(
        Number(captureId)
      );

      const completeAndActiveAnalyticRequests = ars.filter(
        (ar) => !ar.archived && ar?.status === AnalyticRequestStatus.COMPLETE
      );

      // Get capture custom attributes for each ar
      const promises = [];
      for (const ar of completeAndActiveAnalyticRequests) {
        if (!ar.id || ar.archived) {
          continue;
        }
        promises.push(
          APIClient.getCaptureCustomAttributesByAnalyticRequestId(ar.id)
        );
      }
      const res = await Promise.all(promises);

      return res.flat().filter((d) => d?.validated);
    },
    enabled:
      Number.isSafeInteger(Number(captureId ?? undefined)) &&
      analyticRequestsQuery.isSuccess,
    // select: (data) =>
    //   data
    //     .map((d) => d.captureCustomAttributes)
    //     .flat()
    //     .filter((d) => d?.validated),
  });

  const captureObjectsByCompleteAnalyticRequestsQuery = useQuery({
    queryKey: [
      APIUtils.QueryKey.captures,
      { captureId: captureId },
      APIUtils.QueryKey.captureObjectsByCompleteAnalyticRequests,
    ],
    queryFn: async () => {
      const ars = (await APIClient.getAnalyticRequestsByCaptureId(
        Number(captureId)
        // { includeArchived: true }
      )) as AnalyticRequestWithCaptureObjects[];

      const completeAndActiveAnalyticRequests = ars.filter(
        (ar) => !ar.archived && ar?.status === AnalyticRequestStatus.COMPLETE
      );

      // Get capture objects and capture jobs for each ar
      const promises = [];
      for (const ar of completeAndActiveAnalyticRequests) {
        if (!ar.id || ar.archived) {
          continue;
        }
        promises.push(APIClient.getCaptureObjectsByAnalyticRequestId(ar.id));
      }
      await Promise.all(promises);
      for (let i = 0; i < ars.length; i++) {
        const result = await promises[i];
        ars[i].captureObjects = result;
      }
      return ars;
    },
    enabled:
      Number.isSafeInteger(Number(captureId ?? undefined)) &&
      analyticRequestsQuery.isSuccess,
    select: (data) =>
      data
        .map((d) => d.captureObjects)
        .flat()
        .filter((d) => d?.validated),
  });
  return {
    usersQuery,
    usersLookupTable,
    organizationsQuery,
    organizationsLookupTable,
    captureObjectsQuery,
    analyticRequestsQuery,
    captureCustomAttributesQuery,
    captureObjectsByCompleteAnalyticRequestsQuery,
    captureCustomAttributesByCompleteAnalyticRequestsQuery,
  };
};
