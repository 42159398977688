import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';

import { Capture, UploadFile } from '@agerpoint/api';

export interface NewUploadProps {
  bingKey: string;
}

export type NewUploadState = {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: File[];
};

export enum FileUploadResult {
  success = 'success',
  fail = 'fail',
  markedToRetry = 'retry',
}

export interface UploadFileWithExif {
  uploadFile?: UploadFile;
  name: string;
  data: File;
  exif: {
    createDate?: string;
    modifyDate?: string;
    width?: number;
    height?: number;
    altitude?: number;
    fileSize?: number;
    cameraName?: string;
    description?: string;
    gps: {
      latitude: number;
      longitude: number;
    };
    other?: { [key: string]: string };
  };
  uploadProgress?: number;
  hash: string;
  uploadResult?: FileUploadResult;
  reuploadAttempt: number;
}

export interface CogFile {
  uploadFile?: UploadFile;
  data: File;
  uploadProgress?: number;
  uploadResult?: FileUploadResult;
  reuploadAttempt: number;
  autorunPipeline?: boolean;
}

export interface LazFile {
  uploadFile?: UploadFile;
  capture?: Capture;
  data: File;
  uploadProgress?: number;
  uploadResult?: FileUploadResult;
  reuploadAttempt: number;
  autorunPipeline?: boolean;
}

export interface ExtractionsFile {
  uploadFile?: UploadFile;
  capture?: Capture;
  data: File;
  geoJson: JSON;
  uploadProgress?: number;
  uploadResult?: FileUploadResult;
  reuploadAttempt: number;
  autorunPipeline?: boolean;
}

export interface GeoJSONFile {
  uploadFile?: UploadFile;
  data: File;
  geoJson: JSON;
  features: Feature<Geometry>[];
  uploadProgress?: number;
  uploadResult?: FileUploadResult;
  reuploadAttempt: number;
  autorunPipeline?: boolean;
}

export interface PDFFile {
  uploadFile?: UploadFile;
  data: File;
  uploadProgress?: number;
  uploadResult?: FileUploadResult;
  reuploadAttempt: number;
  autorunPipeline?: boolean;
}
