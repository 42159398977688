import {
  faCircleNotch,
  faFilterSlash,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIClient, formatDate } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { usePageTitle, useQueryState } from '@agerpoint/utilities';

import { useAdminOrthomosaicsQueries } from './admin-orthomosaic-queries';

export const AdminOrthomosaicsList = () => {
  usePageTitle(() => 'Platform - Orthomosaics', []);

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v.trim(),
    toUrlParam: (v) => v.trim(),
  });

  const [filter, setFilter] = useState<
    APIClient.ImageMosaicFilter | undefined
  >();

  const {
    organizationsLookupTable,
    orthomosaicsQuery,
    orthomosaicsDeleteMutation,
    organizationsQuery,
  } = useAdminOrthomosaicsQueries(filter);

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        imageMosaicName: nameFilter.trim(),
      }));
    },
    500,
    [nameFilter]
  );

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      imageMosaicName: nameFilter.trim(),
    }));
  }, []);

  const navigate = useNavigate();

  const clearFilters = () => {
    setNameFilter('');
  };

  const hasFiltersApplied = useMemo(
    () => !!((filter?.imageMosaicName?.trim().length ?? 0) > 0),
    [filter]
  );

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="px-4 py-2 flex flex-row gap-2 justify-between items-center">
        <h1 className="text-3xl font-bold">Orthomosaics</h1>
        <Button.Primary
          id="new-orthomosaic-button"
          label="New Orthomosaic"
          icon={faPlus}
          onClick={() => {
            navigate('new', {
              state: { params: window.location.search },
            });
          }}
        />
      </div>
      <div className="px-4 flex flex-row gap-2 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="orthomosaic-name-filter"
            setValue={setNameFilter}
            value={nameFilter}
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 h-full w-full">
        <Datatable
          id="admin-orthomosaics-datatable"
          style={{ ...dataTableAgerStyle, tableMinWidth: 650 }}
          data={orthomosaicsQuery.data?.pages.flatMap((p) => p) ?? []}
          columns={[
            { label: 'ID', value: (row) => row.id, flex: 0.2 },
            { label: 'Name', value: (row) => row.name },
            { label: 'Layer', value: (row) => row.tifLayerName },
            {
              flex: 0.5,
              label: 'Organization',
              value: (row) => {
                if (row.customerId === undefined) return '';
                if (organizationsQuery.isLoading)
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;

                const org = organizationsLookupTable?.[row.customerId];
                return org?.customerDisplayName ?? org?.customerName ?? '';
              },
            },
            {
              flex: 0.5,
              label: 'Scan Date',
              value: (row) =>
                row.scanDateTime ? formatDate(row.scanDateTime) : '',
            },
            {
              flex: 0.5,
              label: '',
              value: (row, index) => {
                if (!row.id) return null;
                return (
                  <Button.Danger
                    id={`archive-orthomosaic-button-${index}`}
                    label="Archive"
                    loading={orthomosaicsDeleteMutation.isPending}
                    onClick={() => {
                      if (!row.id) return;
                      const confirmed = window.confirm(
                        'Are you sure you want to delete this orthomosaic?'
                      );
                      if (confirmed) {
                        orthomosaicsDeleteMutation.mutate({ id: row.id });
                      }
                    }}
                  />
                );
              },
              style: { bodyStyle: 'overflow-visible' },
            },
          ]}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching orthomosaics',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No orthomosaics yet',
                  message: 'Create a orthomosaic to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Orthomosaic',
                }
          }
          error={
            orthomosaicsQuery.isError
              ? {
                  title: 'There was a problem loading orthomosaics',
                  message: 'Try refreshing the page',
                  action: () => orthomosaicsQuery.refetch(),
                }
              : undefined
          }
          rowHeight={50}
          loading={
            orthomosaicsQuery.isLoading ||
            orthomosaicsQuery.isFetchingNextPage ||
            filter === undefined
          }
          pagination={{
            threshold: 10,
            loadNextPage: () => {
              if (
                orthomosaicsQuery.isLoading ||
                orthomosaicsQuery.isFetchingNextPage ||
                !orthomosaicsQuery.hasNextPage ||
                filter === undefined
              ) {
                return;
              }
              orthomosaicsQuery.fetchNextPage();
            },
          }}
        />
      </div>
    </div>
  );
};
