/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CaptureObject, CaptureObjectFilter } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureObjects = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObject[]>(
    { url: `/api/CaptureObjects`, method: 'GET', signal },
    options
  );
};

export const getGetCaptureObjectsQueryKey = () => {
  return [`/api/CaptureObjects`] as const;
};

export const getGetCaptureObjectsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjects>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureObjects>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureObjectsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjects>>
  > = ({ signal }) => getCaptureObjects(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjects>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjects>>
>;
export type GetCaptureObjectsQueryError = ErrorType<void>;

export const useGetCaptureObjects = <
  TData = Awaited<ReturnType<typeof getCaptureObjects>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureObjects>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCaptureObject = (
  captureObject: BodyType<CaptureObject>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObject>(
    {
      url: `/api/CaptureObjects`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureObject,
    },
    options
  );
};

export const getPostCaptureObjectMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureObject>>,
    TError,
    { data: BodyType<CaptureObject> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureObject>>,
  TError,
  { data: BodyType<CaptureObject> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureObject>>,
    { data: BodyType<CaptureObject> }
  > = (props) => {
    const { data } = props ?? {};

    return postCaptureObject(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureObjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureObject>>
>;
export type PostCaptureObjectMutationBody = BodyType<CaptureObject>;
export type PostCaptureObjectMutationError = ErrorType<void>;

export const usePostCaptureObject = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureObject>>,
    TError,
    { data: BodyType<CaptureObject> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureObject>>,
  TError,
  { data: BodyType<CaptureObject> },
  TContext
> => {
  const mutationOptions = getPostCaptureObjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureObjectById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObject>(
    {
      url: `/api/CaptureObjects/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureObjectByIdQueryKey = (id: number) => {
  return [`/api/CaptureObjects/${id}`] as const;
};

export const getGetCaptureObjectByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureObjectByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectById>>
  > = ({ signal }) => getCaptureObjectById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectById>>
>;
export type GetCaptureObjectByIdQueryError = ErrorType<void>;

export const useGetCaptureObjectById = <
  TData = Awaited<ReturnType<typeof getCaptureObjectById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureObjectById = (
  id: number,
  captureObject: BodyType<CaptureObject>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObject>(
    {
      url: `/api/CaptureObjects/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureObject,
    },
    options
  );
};

export const getPutCaptureObjectByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureObjectById>>,
    TError,
    { id: number; data: BodyType<CaptureObject> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureObjectById>>,
  TError,
  { id: number; data: BodyType<CaptureObject> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureObjectById>>,
    { id: number; data: BodyType<CaptureObject> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureObjectById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureObjectByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureObjectById>>
>;
export type PutCaptureObjectByIdMutationBody = BodyType<CaptureObject>;
export type PutCaptureObjectByIdMutationError = ErrorType<void>;

export const usePutCaptureObjectById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureObjectById>>,
    TError,
    { id: number; data: BodyType<CaptureObject> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureObjectById>>,
  TError,
  { id: number; data: BodyType<CaptureObject> },
  TContext
> => {
  const mutationOptions = getPutCaptureObjectByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCaptureObject = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObject>(
    {
      url: `/api/CaptureObjects/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCaptureObjectMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureObject>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCaptureObject>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCaptureObject>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCaptureObject(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCaptureObjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCaptureObject>>
>;

export type DeleteCaptureObjectMutationError = ErrorType<void>;

export const useDeleteCaptureObject = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureObject>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCaptureObject>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteCaptureObjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureObjectsByCaptureId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObject[]>(
    {
      url: `/api/CaptureObjects/Capture/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureObjectsByCaptureIdQueryKey = (id: number) => {
  return [`/api/CaptureObjects/Capture/${id}`] as const;
};

export const getGetCaptureObjectsByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsByCaptureId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureObjectsByCaptureIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectsByCaptureId>>
  > = ({ signal }) => getCaptureObjectsByCaptureId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectsByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectsByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectsByCaptureId>>
>;
export type GetCaptureObjectsByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureObjectsByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsByCaptureId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectsByCaptureIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureObjectsBySharedCapture = (
  captureUuid: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObject[]>(
    {
      url: `/api/CaptureObjects/Shared/CaptureUuid/${encodeURIComponent(
        String(captureUuid)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureObjectsBySharedCaptureQueryKey = (
  captureUuid: string
) => {
  return [`/api/CaptureObjects/Shared/CaptureUuid/${captureUuid}`] as const;
};

export const getGetCaptureObjectsBySharedCaptureQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsBySharedCapture>>,
  TError = ErrorType<void>
>(
  captureUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsBySharedCapture>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureObjectsBySharedCaptureQueryKey(captureUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectsBySharedCapture>>
  > = ({ signal }) =>
    getCaptureObjectsBySharedCapture(captureUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectsBySharedCapture>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectsBySharedCaptureQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectsBySharedCapture>>
>;
export type GetCaptureObjectsBySharedCaptureQueryError = ErrorType<void>;

export const useGetCaptureObjectsBySharedCapture = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsBySharedCapture>>,
  TError = ErrorType<void>
>(
  captureUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsBySharedCapture>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectsBySharedCaptureQueryOptions(
    captureUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureObjectsBySharedCaptureJob = (
  captureJobUuid: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObject[]>(
    {
      url: `/api/CaptureObjects/Shared/CaptureJob/${encodeURIComponent(
        String(captureJobUuid)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureObjectsBySharedCaptureJobQueryKey = (
  captureJobUuid: string
) => {
  return [`/api/CaptureObjects/Shared/CaptureJob/${captureJobUuid}`] as const;
};

export const getGetCaptureObjectsBySharedCaptureJobQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsBySharedCaptureJob>>,
  TError = ErrorType<void>
>(
  captureJobUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsBySharedCaptureJob>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureObjectsBySharedCaptureJobQueryKey(captureJobUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectsBySharedCaptureJob>>
  > = ({ signal }) =>
    getCaptureObjectsBySharedCaptureJob(captureJobUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureJobUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectsBySharedCaptureJob>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectsBySharedCaptureJobQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectsBySharedCaptureJob>>
>;
export type GetCaptureObjectsBySharedCaptureJobQueryError = ErrorType<void>;

export const useGetCaptureObjectsBySharedCaptureJob = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsBySharedCaptureJob>>,
  TError = ErrorType<void>
>(
  captureJobUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsBySharedCaptureJob>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectsBySharedCaptureJobQueryOptions(
    captureJobUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureObjectsBySharedAnalyticRequest = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObject[]>(
    {
      url: `/api/CaptureObjects/Shared/AnalyticRequest/${encodeURIComponent(
        String(id)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureObjectsBySharedAnalyticRequestQueryKey = (
  id: number
) => {
  return [`/api/CaptureObjects/Shared/AnalyticRequest/${id}`] as const;
};

export const getGetCaptureObjectsBySharedAnalyticRequestQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsBySharedAnalyticRequest>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsBySharedAnalyticRequest>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureObjectsBySharedAnalyticRequestQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectsBySharedAnalyticRequest>>
  > = ({ signal }) =>
    getCaptureObjectsBySharedAnalyticRequest(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectsBySharedAnalyticRequest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectsBySharedAnalyticRequestQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectsBySharedAnalyticRequest>>
>;
export type GetCaptureObjectsBySharedAnalyticRequestQueryError =
  ErrorType<void>;

export const useGetCaptureObjectsBySharedAnalyticRequest = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsBySharedAnalyticRequest>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsBySharedAnalyticRequest>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectsBySharedAnalyticRequestQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNonExtrationCaptureObjectsByCaptureId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObject[]>(
    {
      url: `/api/CaptureObjects/Capture/${encodeURIComponent(
        String(id)
      )}/NonExtraction`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetNonExtrationCaptureObjectsByCaptureIdQueryKey = (
  id: number
) => {
  return [`/api/CaptureObjects/Capture/${id}/NonExtraction`] as const;
};

export const getGetNonExtrationCaptureObjectsByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getNonExtrationCaptureObjectsByCaptureId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNonExtrationCaptureObjectsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetNonExtrationCaptureObjectsByCaptureIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNonExtrationCaptureObjectsByCaptureId>>
  > = ({ signal }) =>
    getNonExtrationCaptureObjectsByCaptureId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getNonExtrationCaptureObjectsByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNonExtrationCaptureObjectsByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNonExtrationCaptureObjectsByCaptureId>>
>;
export type GetNonExtrationCaptureObjectsByCaptureIdQueryError =
  ErrorType<void>;

export const useGetNonExtrationCaptureObjectsByCaptureId = <
  TData = Awaited<ReturnType<typeof getNonExtrationCaptureObjectsByCaptureId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNonExtrationCaptureObjectsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNonExtrationCaptureObjectsByCaptureIdQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureObjectsByCaptureExtractionJobId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObject[]>(
    {
      url: `/api/CaptureObjects/CaptureExtractionJob/${encodeURIComponent(
        String(id)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureObjectsByCaptureExtractionJobIdQueryKey = (
  id: number
) => {
  return [`/api/CaptureObjects/CaptureExtractionJob/${id}`] as const;
};

export const getGetCaptureObjectsByCaptureExtractionJobIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsByCaptureExtractionJobId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsByCaptureExtractionJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureObjectsByCaptureExtractionJobIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectsByCaptureExtractionJobId>>
  > = ({ signal }) =>
    getCaptureObjectsByCaptureExtractionJobId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectsByCaptureExtractionJobId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectsByCaptureExtractionJobIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectsByCaptureExtractionJobId>>
>;
export type GetCaptureObjectsByCaptureExtractionJobIdQueryError =
  ErrorType<void>;

export const useGetCaptureObjectsByCaptureExtractionJobId = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsByCaptureExtractionJobId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsByCaptureExtractionJobId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectsByCaptureExtractionJobIdQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureObjectsByAnalyticRequestId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObject[]>(
    {
      url: `/api/CaptureObjects/AnalyticRequest/${encodeURIComponent(
        String(id)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureObjectsByAnalyticRequestIdQueryKey = (id: number) => {
  return [`/api/CaptureObjects/AnalyticRequest/${id}`] as const;
};

export const getGetCaptureObjectsByAnalyticRequestIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsByAnalyticRequestId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsByAnalyticRequestId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureObjectsByAnalyticRequestIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectsByAnalyticRequestId>>
  > = ({ signal }) =>
    getCaptureObjectsByAnalyticRequestId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectsByAnalyticRequestId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectsByAnalyticRequestIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectsByAnalyticRequestId>>
>;
export type GetCaptureObjectsByAnalyticRequestIdQueryError = ErrorType<void>;

export const useGetCaptureObjectsByAnalyticRequestId = <
  TData = Awaited<ReturnType<typeof getCaptureObjectsByAnalyticRequestId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectsByAnalyticRequestId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectsByAnalyticRequestIdQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const changeCaptureObjectsValidationFromCaptureExtractionJob = (
  captureExtractionJobId: number,
  validated: boolean,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/CaptureObjects/CaptureExtractionJob/${encodeURIComponent(
        String(captureExtractionJobId)
      )}/Validated/${encodeURIComponent(String(validated))}`,
      method: 'POST',
    },
    options
  );
};

export const getChangeCaptureObjectsValidationFromCaptureExtractionJobMutationOptions =
  <TError = ErrorType<void>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof changeCaptureObjectsValidationFromCaptureExtractionJob
        >
      >,
      TError,
      { captureExtractionJobId: number; validated: boolean },
      TContext
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof changeCaptureObjectsValidationFromCaptureExtractionJob>
    >,
    TError,
    { captureExtractionJobId: number; validated: boolean },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof changeCaptureObjectsValidationFromCaptureExtractionJob
        >
      >,
      { captureExtractionJobId: number; validated: boolean }
    > = (props) => {
      const { captureExtractionJobId, validated } = props ?? {};

      return changeCaptureObjectsValidationFromCaptureExtractionJob(
        captureExtractionJobId,
        validated,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type ChangeCaptureObjectsValidationFromCaptureExtractionJobMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof changeCaptureObjectsValidationFromCaptureExtractionJob>
    >
  >;

export type ChangeCaptureObjectsValidationFromCaptureExtractionJobMutationError =
  ErrorType<void>;

export const useChangeCaptureObjectsValidationFromCaptureExtractionJob = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof changeCaptureObjectsValidationFromCaptureExtractionJob>
    >,
    TError,
    { captureExtractionJobId: number; validated: boolean },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof changeCaptureObjectsValidationFromCaptureExtractionJob>
  >,
  TError,
  { captureExtractionJobId: number; validated: boolean },
  TContext
> => {
  const mutationOptions =
    getChangeCaptureObjectsValidationFromCaptureExtractionJobMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
export const changeFilteredCaptureObjectsValidationFromCaptureExtractionJob = (
  captureExtractionJobId: number,
  validated: boolean,
  captureObjectFilter: BodyType<CaptureObjectFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/CaptureObjects/CaptureExtractionJob/${encodeURIComponent(
        String(captureExtractionJobId)
      )}/FilterValidate/${encodeURIComponent(String(validated))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureObjectFilter,
    },
    options
  );
};

export const getChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobMutationOptions =
  <TError = ErrorType<void>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof changeFilteredCaptureObjectsValidationFromCaptureExtractionJob
        >
      >,
      TError,
      {
        captureExtractionJobId: number;
        validated: boolean;
        data: BodyType<CaptureObjectFilter>;
      },
      TContext
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof changeFilteredCaptureObjectsValidationFromCaptureExtractionJob
      >
    >,
    TError,
    {
      captureExtractionJobId: number;
      validated: boolean;
      data: BodyType<CaptureObjectFilter>;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof changeFilteredCaptureObjectsValidationFromCaptureExtractionJob
        >
      >,
      {
        captureExtractionJobId: number;
        validated: boolean;
        data: BodyType<CaptureObjectFilter>;
      }
    > = (props) => {
      const { captureExtractionJobId, validated, data } = props ?? {};

      return changeFilteredCaptureObjectsValidationFromCaptureExtractionJob(
        captureExtractionJobId,
        validated,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof changeFilteredCaptureObjectsValidationFromCaptureExtractionJob
      >
    >
  >;
export type ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobMutationBody =
  BodyType<CaptureObjectFilter>;
export type ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobMutationError =
  ErrorType<void>;

export const useChangeFilteredCaptureObjectsValidationFromCaptureExtractionJob =
  <TError = ErrorType<void>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof changeFilteredCaptureObjectsValidationFromCaptureExtractionJob
        >
      >,
      TError,
      {
        captureExtractionJobId: number;
        validated: boolean;
        data: BodyType<CaptureObjectFilter>;
      },
      TContext
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof changeFilteredCaptureObjectsValidationFromCaptureExtractionJob
      >
    >,
    TError,
    {
      captureExtractionJobId: number;
      validated: boolean;
      data: BodyType<CaptureObjectFilter>;
    },
    TContext
  > => {
    const mutationOptions =
      getChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };
export const changeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob =
  (
    captureExtractionJobId: number,
    validated: boolean,
    polygon: string,
    options?: SecondParameter<typeof AxiosInstance>
  ) => {
    return AxiosInstance<void>(
      {
        url: `/api/CaptureObjects/CaptureExtractionJob/${encodeURIComponent(
          String(captureExtractionJobId)
        )}/Validated/${encodeURIComponent(
          String(validated)
        )}/polygon/${encodeURIComponent(String(polygon))}`,
        method: 'POST',
      },
      options
    );
  };

export const getChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobMutationOptions =
  <TError = ErrorType<void>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof changeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob
        >
      >,
      TError,
      { captureExtractionJobId: number; validated: boolean; polygon: string },
      TContext
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof changeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob
      >
    >,
    TError,
    { captureExtractionJobId: number; validated: boolean; polygon: string },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof changeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob
        >
      >,
      { captureExtractionJobId: number; validated: boolean; polygon: string }
    > = (props) => {
      const { captureExtractionJobId, validated, polygon } = props ?? {};

      return changeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob(
        captureExtractionJobId,
        validated,
        polygon,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof changeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob
      >
    >
  >;

export type ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobMutationError =
  ErrorType<void>;

export const useChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob =
  <TError = ErrorType<void>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof changeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob
        >
      >,
      TError,
      { captureExtractionJobId: number; validated: boolean; polygon: string },
      TContext
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof changeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob
      >
    >,
    TError,
    { captureExtractionJobId: number; validated: boolean; polygon: string },
    TContext
  > => {
    const mutationOptions =
      getChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };
export const changeCaptureObjectsDisplayNameFromCaptureExtractionJob = (
  captureExtractionJobId: number,
  displayName: string,
  captureObjectFilter: BodyType<CaptureObjectFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/CaptureObjects/CaptureExtractionJob/${encodeURIComponent(
        String(captureExtractionJobId)
      )}/DisplayName/${encodeURIComponent(String(displayName))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureObjectFilter,
    },
    options
  );
};

export const getChangeCaptureObjectsDisplayNameFromCaptureExtractionJobMutationOptions =
  <TError = ErrorType<void>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof changeCaptureObjectsDisplayNameFromCaptureExtractionJob
        >
      >,
      TError,
      {
        captureExtractionJobId: number;
        displayName: string;
        data: BodyType<CaptureObjectFilter>;
      },
      TContext
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof changeCaptureObjectsDisplayNameFromCaptureExtractionJob>
    >,
    TError,
    {
      captureExtractionJobId: number;
      displayName: string;
      data: BodyType<CaptureObjectFilter>;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof changeCaptureObjectsDisplayNameFromCaptureExtractionJob
        >
      >,
      {
        captureExtractionJobId: number;
        displayName: string;
        data: BodyType<CaptureObjectFilter>;
      }
    > = (props) => {
      const { captureExtractionJobId, displayName, data } = props ?? {};

      return changeCaptureObjectsDisplayNameFromCaptureExtractionJob(
        captureExtractionJobId,
        displayName,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof changeCaptureObjectsDisplayNameFromCaptureExtractionJob>
    >
  >;
export type ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobMutationBody =
  BodyType<CaptureObjectFilter>;
export type ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobMutationError =
  ErrorType<void>;

export const useChangeCaptureObjectsDisplayNameFromCaptureExtractionJob = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof changeCaptureObjectsDisplayNameFromCaptureExtractionJob>
    >,
    TError,
    {
      captureExtractionJobId: number;
      displayName: string;
      data: BodyType<CaptureObjectFilter>;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof changeCaptureObjectsDisplayNameFromCaptureExtractionJob>
  >,
  TError,
  {
    captureExtractionJobId: number;
    displayName: string;
    data: BodyType<CaptureObjectFilter>;
  },
  TContext
> => {
  const mutationOptions =
    getChangeCaptureObjectsDisplayNameFromCaptureExtractionJobMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
