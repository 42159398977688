import { Bin, BreaksMethod, Gradient } from '@agerpoint/types';
export interface Bins {
  rangeMin: number;
  rangeMax: number;
  itemCount: number;
}

export enum ActionKind {
  SetAttribute = 'setAttribute',
  SetClasses = 'setClasses',
  SetBreaks = 'setBreaks',
  SetGradient = 'setGradient',
  SetOpacity = 'setOpacity',
  SetCalculatedBins = 'setCalculatedBins',
  SetManualBins = 'setManualBins',
  SetAll = 'setAll',
}

export interface Action {
  type: string;
  payload: any;
}

export interface State {
  attribute?: string;
  breaks: BreaksMethod;
  classes: number;
  gradient: Gradient;
  opacity: number;
  bins?: Bin[];
  binContext?: { attribute: string; breaks: BreaksMethod };
}

export const initialState = {
  attribute: undefined,
  breaks: 'equalInterval' as BreaksMethod,
  classes: 5,
  gradient: Gradient.RdYlGn,
  opacity: 1,
  bins: undefined,
};

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionKind.SetAttribute:
      return { ...state, attribute: action.payload };
    case ActionKind.SetClasses:
      return { ...state, classes: action.payload };
    case ActionKind.SetBreaks:
      return { ...state, breaks: action.payload };
    case ActionKind.SetGradient:
      return { ...state, gradient: action.payload };
    case ActionKind.SetOpacity:
      return { ...state, opacity: action.payload };
    case ActionKind.SetCalculatedBins: {
      const { payload } = action;
      // To prevent overwriting manual changes, we
      // only update them if the attribute, number of bins
      // or the breakMethod also changed (which forces
      // recalculation of bins).
      if (
        !state.bins ||
        !state.binContext ||
        payload.bins.length !== state.bins.length ||
        payload.attribute !== state.binContext.attribute ||
        payload.breakMethod !== state.binContext.breaks
      ) {
        return {
          ...state,
          bins: payload.bins,
          binContext: {
            attribute: payload.attribute,
            breakMethod: payload.breakMethod,
          },
        };
      } else {
        return state;
      }
    }
    case ActionKind.SetManualBins:
      return { ...state, bins: action.payload };
    case ActionKind.SetAll:
      return { ...action.payload };
    default:
      return state;
  }
}

export const createInitialState = (state: State) => {
  return {
    ...state,
    binContext: { attribute: state.attribute, breaks: state.breaks },
  };
};
