interface style {
  text: string;
  bg: string;
  border: string;
  bgHover: string;
  colorFocus: string;
  padding: string;
  weight: string;
}

export const theme = ({
  theme = 'primary',
  size = 'normal',
  disabled = false,
}) => {
  const style: style = {
    text: 'text-white',
    bg: '',
    border: 'border-transparent',
    bgHover: '',
    colorFocus: '',
    padding: 'px-4 py-4',
    weight: 'font-bold',
  };

  switch (theme) {
    case 'primary':
      style.text = 'text-white';
      style.bg = `bg-green ${!disabled ? 'opacity-100' : 'opacity-50'}`;
      style.colorFocus = 'focus:ring-green-300';
      style.bgHover = `${!disabled ? 'hover:bg-green-600' : ''}`;
      break;
    case 'white':
      style.text = `${!disabled ? 'text-gray' : 'text-gray-300'}`;
      style.border = 'border border-gray-300';
      style.colorFocus = 'focus:ring-gray-50';
      style.bgHover = `${!disabled ? 'hover:bg-gray-50' : ''}`;
      break;
    case 'secondary':
      style.text = 'text-white';
      style.bg = `bg-orange ${!disabled ? 'opacity-100' : 'opacity-50'}`;
      style.colorFocus = 'ring-orange-500';
      style.bgHover = 'hover:bg-orange-600';
      break;
    case 'danger':
      style.bg = `bg-red-50 ${!disabled ? 'opacity-100' : 'opacity-50'}`;
      style.text = `text-red-300 ${!disabled ? 'hover:text-white ' : ''}`;
      style.border = 'border border-red-300';
      style.colorFocus = 'focus:ring-red';
      style.bgHover = `${!disabled ? 'hover:bg-red-300' : ''}`;
      break;
    case 'red':
      style.text = 'text-white';
      style.bg = `bg-red ${!disabled ? 'opacity-100' : 'opacity-50'}`;
      style.colorFocus = 'ring-red-300';
      style.bgHover = 'hover:bg-red-600';
      break;
    case 'link':
      style.text = 'text-blue-400';
      style.bg = 'bg-transparent';
      style.border = 'border-transparent';
      style.colorFocus = 'focus:ring-blue-300';
      style.bgHover = 'hover:bg-blue-50';
      break;
    case 'link-underlined':
      style.text = 'text-blue-400 underline';
      style.bg = 'bg-transparent';
      style.border = 'border-transparent';
      style.colorFocus = 'focus:ring-blue-300';
      style.bgHover = 'hover:bg-blue-50';
      break;
    case 'darkBlue':
      style.text = 'text-darkBlue';
      style.bg = 'bg-transparent';
      style.border = 'border border-darkBlue';
      style.colorFocus = 'focus:ring-blue-300';
      style.bgHover = 'hover:bg-blue-50';
      break;
    case 'solidDarkBlue':
      style.text = 'text-white';
      style.bg = `bg-darkBlue ${!disabled ? 'opacity-100' : 'opacity-50'}`;
      style.border = 'border border-darkBlue';
      style.colorFocus = 'focus:ring-blue-300';
      style.bgHover = 'hover:bg-blue-50';
      break;
    case 'gray':
      style.text = 'text-gray-400';
      style.bg = 'bg-transparent';
      style.border = 'border border-gray-300';
      style.colorFocus = 'focus:ring-gray-300';
      style.bgHover = 'hover:bg-gray-50';
      break;
  }

  switch (size) {
    case 'small':
      style.padding = 'px-2 py-1 text-xs leading-none';
      break;
    case 'x-small':
      style.padding = 'px-2 text-xs leading-none';
      style.weight = 'font-normal';
      break;
    case 'normal':
    default:
      style.padding = 'px-4 py-2';
      break;
  }

  return `inline-flex
    transition-all
    duration-200
    items-center
    justify-center
    ${style.padding}
    ${style.border}
    shadow-sm
    ${style.weight}
    ${style.text}
    ${style.bg}
    ${style.bgHover}
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    ${style.colorFocus}
    rounded`;
};
