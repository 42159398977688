/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CaptureAudio } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureAudios = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureAudio[]>(
    { url: `/api/CaptureAudios`, method: 'GET', signal },
    options
  );
};

export const getGetCaptureAudiosQueryKey = () => {
  return [`/api/CaptureAudios`] as const;
};

export const getGetCaptureAudiosQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureAudios>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureAudios>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureAudiosQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureAudios>>
  > = ({ signal }) => getCaptureAudios(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureAudios>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureAudiosQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureAudios>>
>;
export type GetCaptureAudiosQueryError = ErrorType<void>;

export const useGetCaptureAudios = <
  TData = Awaited<ReturnType<typeof getCaptureAudios>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureAudios>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureAudiosQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureAudiosByCaptureId = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureAudio[]>(
    {
      url: `/api/CaptureAudios/Capture/${encodeURIComponent(
        String(captureId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureAudiosByCaptureIdQueryKey = (captureId: number) => {
  return [`/api/CaptureAudios/Capture/${captureId}`] as const;
};

export const getGetCaptureAudiosByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureAudiosByCaptureIdQueryKey(captureId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>
  > = ({ signal }) =>
    getCaptureAudiosByCaptureId(captureId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureAudiosByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>
>;
export type GetCaptureAudiosByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureAudiosByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureAudiosByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureAudiosByCaptureIdQueryOptions(
    captureId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureAudioById = (
  id: number,
  captureAudio: BodyType<CaptureAudio>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureAudio>(
    {
      url: `/api/CaptureAudios/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureAudio,
    },
    options
  );
};

export const getPutCaptureAudioByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureAudioById>>,
    TError,
    { id: number; data: BodyType<CaptureAudio> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureAudioById>>,
  TError,
  { id: number; data: BodyType<CaptureAudio> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureAudioById>>,
    { id: number; data: BodyType<CaptureAudio> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureAudioById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureAudioByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureAudioById>>
>;
export type PutCaptureAudioByIdMutationBody = BodyType<CaptureAudio>;
export type PutCaptureAudioByIdMutationError = ErrorType<void>;

export const usePutCaptureAudioById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureAudioById>>,
    TError,
    { id: number; data: BodyType<CaptureAudio> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureAudioById>>,
  TError,
  { id: number; data: BodyType<CaptureAudio> },
  TContext
> => {
  const mutationOptions = getPutCaptureAudioByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
