export enum TabNames {
  LINK = 'link',
  BUTTON = 'button',
}

export enum TabThemes {
  RED = 'red',
  GREEN = 'green',
}

export interface TabProps {
  label: string;
  link?: string;
  type: TabNames;
  buttonClickHandler?: () => void;
  buttonTheme?: TabThemes | null;
  params?: string;
}
