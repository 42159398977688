import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IAudioViewer, IAudioViewerController } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const AudioViewer = ({ controller: setController }: IAudioViewer) => {
  const [url, setUrl] = useState<string>();

  const [viewerReady, setViewerReady] = useState<boolean>(false);

  const loadAudio = useCallback((url: string) => {
    setUrl(url);
  }, []);

  const {
    sidebar: { isOpen: sidebarOpen },
  } = useGlobalStore();

  const controller: IAudioViewerController = useMemo(
    () => ({
      info: {
        viewerReady,
      },
      loadAudio,
    }),
    [loadAudio, viewerReady]
  );

  useEffect(() => {
    setController?.(controller);
  }, [controller, setController]);

  useEffect(() => {
    setViewerReady(true);
    return () => {
      setViewerReady(false);
    };
  }, []);

  return (
    <div className="w-full h-full">
      <div
        className={`flex justify-center items-center w-full h-full text-white text-lg text-center ${
          sidebarOpen ? 'pl-sidebar' : ''
        }`}
        style={{
          background: 'radial-gradient(#1b292f, #0c1417)',
        }}
      >
        {url === undefined ? (
          <div className="flex flex-row gap-2">
            <span>Loading Audio...</span>
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          <div className="p-10 size-full flex-center">
            <div className="flex-center rounded-lg overflow-hidden shadow-lg w-2/3 flex-center">
              <audio
                controls
                crossOrigin="anonymous"
                src={url}
                className="w-full"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
