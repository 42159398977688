import { axisBottom } from 'd3-axis';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useMemo } from 'react';
import { Range } from 'react-range';

import {
  useGetGeometryHistogramByGeometryCollectionId,
  useGetMorphologyHistogramByCollectionId,
} from '@agerpoint/api';
import { getInterpolator, getNumberDecimals } from '@agerpoint/component';
import { Bin, Gradient } from '@agerpoint/types';

import { Bins } from './graduated-state';

const numberHistogramBins = 40;

interface Histogram {
  collectionId: number;
  attributeName: string;
  bins: Bin[];
  gradient: Gradient;
  onBinsChange: (bins: Bin[]) => void;
  layerTypeId: number;
}

export function Histogram({
  collectionId,
  attributeName,
  bins,
  gradient,
  onBinsChange,
  layerTypeId,
}: Histogram) {
  const [canDrawChart, setCanDrawChart] = useState(false);
  const myHook =
    layerTypeId === 8
      ? useGetGeometryHistogramByGeometryCollectionId
      : useGetMorphologyHistogramByCollectionId;
  const { data, loading, error } = myHook({
    collectionId: collectionId,
    geometryCollectionId: collectionId,
    attributeName: encodeURIComponent(attributeName || ''),
    binCount: numberHistogramBins,
    classificationMethod: 'equalInterval',
    resolve: (data) => data.bins.map(toHistogramBin),
  });

  const wrapper: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const container: MutableRefObject<SVGSVGElement | null> = useRef(null);
  const axisContainer: MutableRefObject<SVGSVGElement | null> = useRef(null);
  const [dimensions, setDimensions] = useState<number[] | undefined>();

  useLayoutEffect(() => {
    if (wrapper.current) {
      setDimensions([
        wrapper.current.offsetWidth,
        wrapper.current.offsetHeight,
      ]);
    }
  }, [canDrawChart]);

  const [pips, setPips] = useState<number[] | undefined>();
  const histogramBins = data ? (data as HistogramBin[]) : data;
  const step = histogramBins
    ? (histogramBins[histogramBins.length - 1].range[1] -
        histogramBins[0].range[0]) /
      numberHistogramBins
    : 1;

  const xDomain = useMemo(
    () =>
      histogramBins && bins
        ? [
            Math.min(histogramBins[0].range[0], bins[0].range[0]),
            Math.max(
              histogramBins[histogramBins.length - 1].range[1],
              bins[bins.length - 1].range[1]
            ),
          ]
        : [0, 0],
    [histogramBins, bins]
  );

  const [min, max] = xDomain;

  useEffect(() => {
    if (bins && histogramBins) {
      setPips(
        bins
          .slice(1)
          .map(({ range: [rangeMin] }) =>
            clamp(
              histogramBins.find((histBin) => histBin.range[1] > rangeMin)
                ?.range[0] || rangeMin,
              min,
              max
            )
          )
      );
    }
  }, [min, max, bins, step, histogramBins]);

  useEffect(() => {
    if (!canDrawChart) return;
    if (!loading && container.current && dimensions && histogramBins && bins) {
      const [width, height] = dimensions;
      container.current.setAttribute('width', width.toString());
      container.current.setAttribute('height', height.toString());

      const yDomain = [
        0,
        Math.max(...histogramBins.map((bin) => bin.itemCount)),
      ];
      const xScale = scaleLinear(xDomain, [0, width]);
      const yScale = scaleLinear(yDomain, [height, 0]);
      const interpolator = getInterpolator(gradient, bins.length);

      const svg = select(container.current);
      svg
        .selectAll('rect')
        .data(histogramBins)
        .join('rect')
        .attr('x', (d: HistogramBin) => xScale(d.range[0]) + 0.25)
        .attr('width', (d: HistogramBin) =>
          Math.max(0, xScale(d.range[1]) - xScale(d.range[0]) - 0.25)
        )
        .attr('y', (d: HistogramBin) => yScale(d.itemCount))
        .attr('height', (d: HistogramBin) => yScale(0) - yScale(d.itemCount))
        .attr('fill', (d) => interpolator(findBinIndex(d)));
    }

    function findBinIndex({ range: [min, max] }: HistogramBin): number {
      const value = (min + max) / 2;
      const pipIndex = pips ? pips.findIndex((max) => value < max) : -1;
      return pipIndex < 0 ? bins.length - 1 : pipIndex;
    }
  }, [
    wrapper,
    loading,
    histogramBins,
    dimensions,
    bins,
    gradient,
    pips,
    xDomain,
    canDrawChart,
  ]);

  useEffect(() => {
    if (axisContainer.current && dimensions && histogramBins && bins) {
      const [width] = dimensions;

      axisContainer.current.setAttribute('width', width.toString());
      axisContainer.current.setAttribute('height', '24');

      const xScale = scaleLinear(xDomain, [0, width]);
      const xAxis = axisBottom(xScale)
        .ticks(width / 80, `,.${getNumberDecimals(xDomain[1] - xDomain[0])}f`)
        .tickSizeOuter(0);
      const svg = select(axisContainer.current);
      svg.call(xAxis as any).call((g) => g.select('.domain').remove());
    }
  }, [bins, dimensions, histogramBins, xDomain]);

  useEffect(() => {
    if (step <= 0 || min >= max) {
      setCanDrawChart(false);
    } else {
      setCanDrawChart(true);
    }
  }, [step, min, max, bins, pips]);

  return (
    <div className="w-full">
      <div
        ref={wrapper}
        className={`relative w-full ${canDrawChart ? 'h-32' : 'h-0'}`}
      >
        {canDrawChart && pips?.length && (
          <>
            <svg ref={container} className="w-full h-full" />

            <div className="absolute bottom-0 left-0 w-full">
              {chart({
                min,
                max,
                bins,
                step,
                pips,
                setPips,
                onBinsChange,
              })}
            </div>
          </>
        )}
      </div>
      {canDrawChart && (
        <svg
          ref={axisContainer}
          className="w-full text-gray-800 text-sm font-display-condensed"
        />
      )}
      {!canDrawChart && (
        <div className="text-sm">This data cannot be charted</div>
      )}
    </div>
  );
}

interface HistogramBin {
  range: number[];
  itemCount: number;
}

function toHistogramBin(bin: Bins): HistogramBin {
  return {
    range: [bin.rangeMin, bin.rangeMax],
    itemCount: bin.itemCount,
  };
}

function clamp(x: number, min: number, max: number): number {
  return Math.min(Math.max(x, min), max);
}

const chart = ({
  min,
  max,
  bins,
  step,
  pips,
  setPips,
  onBinsChange,
}: {
  min: number;
  max: number;
  bins: Bin[];
  step: number;
  pips: number[] | undefined;
  setPips: (pips: number[]) => void;
  onBinsChange: (bins: Bin[]) => void;
}) => {
  return (
    <Range
      // react-range does not seem to like changing number of values
      // (runtime error when dragging sliders), so we remount the component
      // when number bins change
      key={bins.length}
      min={min}
      max={max}
      step={step}
      values={pips || []}
      renderTrack={({ props, children }) => (
        <div {...props} className="absolute inset-0 w-full">
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div {...props} style={{ ...props.style }} className="w-2 h-full">
          <div className="mx-auto w-px h-full bg-gray-900" />
        </div>
      )}
      onChange={(values) => setPips(values)}
      onFinalChange={(values) => {
        onBinsChange(
          bins.map((bin, i) => ({
            ...bin,
            range:
              i === 0
                ? [bin.range[0], values[0]]
                : [
                    values[i - 1],
                    i < values.length ? values[i] : bins[i].range[1],
                  ],
          }))
        );
      }}
    />
  );
};
