import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminBlocksQueries } from './admin-blocks-queries';

export const AdminBlocksNew = () => {
  usePageTitle(() => 'Platform - Blocks', []);

  const isViteApp = useIsViteApp();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const { farmsQuery, blockPostMutation } = useAdminBlocksQueries();

  const formValidation = useFormValidation();
  const [selectedFarm, setSelectedFarm] = useState<
    APIModels.Farm | undefined
  >();

  useEffect(() => {
    setBlock((prev) => ({
      ...prev,
      farmId: selectedFarm?.id,
    }));
  }, [selectedFarm]);

  const [block, setBlock] = useState<Partial<APIModels.Block>>({});

  const createBlock = useCallback(async () => {
    if (blockPostMutation.isPending) {
      return;
    }
    if (await formValidation.hasErrors()) {
      return;
    }

    blockPostMutation.mutate({
      data: block as APIModels.Block,
    });
  }, [block, formValidation, blockPostMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Blocks',
              path: isViteApp ? '/app/admin/platform/blocks' : '/admin/blocks',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-block-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/blocks' + params);
            } else {
              navigate('/admin/blocks' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Block</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="block-name-input"
          value={block?.blockName || ''}
          setValue={(blockName) => {
            setBlock({ ...block, blockName });
          }}
          label={<Input.Label label="Name" required />}
          error={
            <Input.Error error={formValidation.errors['block-name-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
        <Input.Text.Single
          id="block-display-name-input"
          value={block?.blockDisplayName || ''}
          setValue={(blockDisplayName) => {
            setBlock({ ...block, blockDisplayName });
          }}
          label={<Input.Label label="Display Name" required />}
          error={
            <Input.Error
              error={formValidation.errors['block-display-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Display Name')],
          }}
        />
        <Input.Select.Single
          id="block-farm-select"
          options={farmsQuery.data ?? []}
          optionBuilder={(o) => o.farmDisplayName ?? o.farmName ?? 'Unknown'}
          value={selectedFarm}
          setValue={setSelectedFarm}
          title="Farm"
          loading={farmsQuery.isLoading}
          label={<Input.Label label="Farm" required />}
          error={
            <Input.Error error={formValidation.errors['block-farm-select']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Farm')],
          }}
        />
        <Input.Text.Single
          id="block-boundary-input"
          value={block?.blockGeom?.boundary?.toString() || ''}
          setValue={(boundary) => {
            setBlock({
              ...block,
              blockGeom: { ...block.blockGeom, boundary },
            });
          }}
          label={<Input.Label label="Boundary" />}
        />
        <div className="flex flex-row justify-end py-4">
          <Button.Primary
            id="create-block-button"
            label="Create"
            onClick={createBlock}
            loading={blockPostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
