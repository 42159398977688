import { useEffect, useRef } from 'react';

type Options = {
  onClick?: (e: MouseEvent) => void; // Callback for single click
  onDragStart?: (e: MouseEvent, startPos: { x: number; y: number }) => void; // Callback when drag starts
  onDragEnd?: (e: MouseEvent, endPos: { x: number; y: number }) => void; // Callback when drag ends
  onDoubleClick?: (e: MouseEvent) => void; // Callback for double click
  onMouseDown?: (e: MouseEvent) => void; // Callback for mouse down
  dragThreshold?: number; // Minimum distance in pixels to consider a movement as drag
  clickTimeout?: number; // Maximum time in ms to differentiate between click and double-click
};

const useClickDragDoubleClick = (
  targetEl: HTMLCanvasElement | null, // The canvas element to attach the event listeners to
  {
    onClick,
    onDragStart,
    onDragEnd,
    onDoubleClick,
    onMouseDown,
    dragThreshold = 5, // Default drag threshold (3px movement)
    clickTimeout = 200, // Default click timeout (250ms)
  }: Options
) => {
  const isDraggingRef = useRef(false); // Keeps track of whether a drag is happening
  const isMouseDownRef = useRef(false); // Keeps track of whether the left mouse button is held down
  // Holds the ID of the setTimeout to differentiate click and double-click
  const clickTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const clickCountRef = useRef(0); // Counts how many clicks have occurred (for double-click detection)
  const startPosRef = useRef({ x: 0, y: 0 }); // Records the initial mouse position when the button is pressed
  const startTimeRef = useRef<number>(0); // Records the time when the mouse button was pressed down

  useEffect(() => {
    if (!targetEl) return; // Do nothing if there is no target element (canvas)

    // Mouse down event handler
    const handleMouseDown = (e: MouseEvent) => {
      if (e.button !== 0) return; // Only proceed if the left mouse button (button 0) is pressed

      isMouseDownRef.current = true; // Indicate that the left mouse button is being held down
      isDraggingRef.current = false; // Reset drag status, as we're starting a new interaction

      // Record the initial mouse position and time when the button is pressed
      startPosRef.current = { x: e.clientX, y: e.clientY };
      startTimeRef.current = Date.now(); // Record the start time for timing the click/drag
      onMouseDown && onMouseDown(e); // Call the onMouseDown callback
    };

    // Mouse move event handler
    const handleMouseMove = (e: MouseEvent) => {
      // Only process mouse move events if the mouse is held down and dragging hasn't started yet
      if (!isMouseDownRef.current || isDraggingRef.current) return;

      const distanceX = Math.abs(e.clientX - startPosRef.current.x); // Calculate movement in the X axis
      const distanceY = Math.abs(e.clientY - startPosRef.current.y); // Calculate movement in the Y axis
      // Calculate how long the mouse has been held down
      const elapsedTime = Date.now() - startTimeRef.current;

      // Determine if the movement qualifies as a drag:
      // If either the distance moved exceeds the drag threshold, AND the time held down exceeds clickTimeout
      if (
        (distanceX > dragThreshold || distanceY > dragThreshold) &&
        elapsedTime > clickTimeout
      ) {
        isDraggingRef.current = true; // Mark the interaction as a drag
        // Call the onDragStart callback ONCE with the initial mouse position (start of drag)
        onDragStart && onDragStart(e, startPosRef.current);

        // Clear any pending single-click or double-click timeout, since it's now a drag
        if (clickTimeoutIdRef.current) {
          clearTimeout(clickTimeoutIdRef.current);
          clickTimeoutIdRef.current = null;
        }

        clickCountRef.current = 0; // Reset click count, since dragging cancels clicking
      }
    };

    // Mouse up event handler
    const handleMouseUp = (e: MouseEvent) => {
      if (e.button !== 0) return; // Only process left mouse button releases

      isMouseDownRef.current = false; // Reset the mouse down state

      const clickDuration = Date.now() - startTimeRef.current; // Calculate how long the mouse was held down

      if (isDraggingRef.current) {
        // If a drag was detected, call onDragEnd
        isDraggingRef.current = false; // Reset dragging status for the next interaction
        // Call the onDragEnd callback with the final position
        onDragEnd && onDragEnd(e, { x: e.clientX, y: e.clientY });
        return;
      }

      // Handle double-click: if there was already one click within the timeout, this is the second click
      if (clickCountRef.current === 1) {
        clickCountRef.current = 0; // Reset click count
        // Clear the single-click timeout
        if (clickTimeoutIdRef.current) clearTimeout(clickTimeoutIdRef.current);
        onDoubleClick && onDoubleClick(e); // Call the double-click callback
        return; // Return early to prevent further click handling
      }

      // Handle single click: if the duration was short enough, consider this a click
      if (clickDuration <= clickTimeout) {
        clickCountRef.current += 1; // Increment click count for double-click detection
        clickTimeoutIdRef.current = setTimeout(() => {
          clickCountRef.current = 0; // Reset click count after the timeout period
          onClick && onClick(e); // Call the single-click callback
        }, clickTimeout); // Wait for the timeout to see if a second click will happen
      } else {
        // If the click is too long, we still need to reset the state
        clickCountRef.current = 0;
      }
    };

    const handleDoubleClick = (e: MouseEvent) => {
      e.stopImmediatePropagation();
    };

    // Register event listeners to the canvas element
    targetEl.addEventListener('mousedown', handleMouseDown);
    targetEl.addEventListener('mousemove', handleMouseMove);
    targetEl.addEventListener('mouseup', handleMouseUp);
    targetEl.addEventListener('dblclick', handleDoubleClick);

    // Cleanup function to remove event listeners when the component unmounts or the target element changes
    return () => {
      targetEl.removeEventListener('mousedown', handleMouseDown);
      targetEl.removeEventListener('mousemove', handleMouseMove);
      targetEl.removeEventListener('mouseup', handleMouseUp);
      targetEl.removeEventListener('dblclick', handleDoubleClick);
    };
    // Re-run this effect if any of the dependencies change
  }, [
    targetEl,
    onClick,
    onDragStart,
    onDragEnd,
    onDoubleClick,
    onMouseDown,
    dragThreshold,
    clickTimeout,
  ]);
};

export default useClickDragDoubleClick;
