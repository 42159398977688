import {
  faCheck,
  faChevronDown,
  faCircleNotch,
  faEdit,
  faMagnifyingGlass,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useState } from 'react';

import { Input } from '@agerpoint/component';
import { ViewerType } from '@agerpoint/types';

import { IThreeDViewerControllerGroup } from '../../three-d-wrapper';
import { QAQCObjectRenameModal } from '../qaqc-object-rename-modal/qaqc-object-rename-modal';
import { QAQCCaptureObjectAttributeRow } from './qaqc-capture-object-attribute-row';
import {
  QAQCCaptureObject,
  QAQCChangeValidationProps,
  QAQCValidationLoadingState,
} from './qaqc-capture-objects';

interface QaqcCaptureObjectCardProps {
  object: QAQCCaptureObject;
  color: string;
  viewerController?: IThreeDViewerControllerGroup;
  loadingState: QAQCValidationLoadingState;
  changeValidation: (props: QAQCChangeValidationProps) => Promise<void>;
  renameObject: (object: QAQCCaptureObject, newName: string) => Promise<void>;
}

export const QaqcCaptureObjectCard = ({
  object,
  color,
  viewerController,
  loadingState,
  changeValidation,
  renameObject,
}: QaqcCaptureObjectCardProps) => {
  const [expanded, setExpanded] = useState(true);

  const [showObjectRenameModal, setShowObjectRenameModal] = useState(false);

  const loadingObject = useMemo(() => {
    return object.id && loadingState?.objectIds.includes(object.id);
  }, [loadingState, object]);

  const loadingAttributeInThisObject = useMemo(() => {
    return (
      (object.captureObjectCustomAttributes?.findIndex(
        (a) => a.id && loadingState.attributeIds.includes(a.id)
      ) ?? -1) !== -1
    );
  }, [loadingState, object]);

  const validateObject = useCallback(async () => {
    const value = !(object.validated ?? false);

    if (object.id) {
      changeValidation({
        objectIds: [object.id],
        value: value,
      });
    }
  }, [object, changeValidation]);

  const zoomToObject = useCallback(() => {
    if (!object.centroidX || !object.centroidY || !object.centroidZ) return;
    if (viewerController?.viewerType !== ViewerType.Potree) return;
    viewerController?.potreeController?.zoomToLocation({
      loc: {
        x: object.centroidX,
        y: object.centroidY,
        z: object.centroidZ,
      },
    });
  }, [viewerController, object]);

  return (
    <div className="w-full rounded bg-gray-800">
      <QAQCObjectRenameModal
        handleCloseDialog={() => {
          setShowObjectRenameModal(false);
        }}
        open={showObjectRenameModal}
        renameObject={renameObject}
        object={object}
      />
      <div>
        <div
          className={`flex flex-row justify-between w-full bg-white bg-opacity-0 hover:bg-opacity-25 rounded cursor-pointer`}
          onClick={(e) => {
            setExpanded((prev) => !prev);
          }}
        >
          <div
            className={`flex flex-row gap-1 items-center justify-between w-full px-2 py-1.5 border-b rounded-b ${
              expanded ? 'border-gray-500' : 'border-transparent'
            }`}
          >
            <div className="flex flex-row gap-1 items-center">
              <div className="text-green-300">
                {loadingObject ? (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    spin
                    className="w-4 h-4 text-white"
                  />
                ) : (
                  <span
                    onClick={(e) => {
                      // For some reason the Checkbox component sends
                      // out two onClick events that are not stopped
                      // from propagating and trigger all parent onclicks twice
                      // So we stop the propagation here
                      e.stopPropagation();
                    }}
                  >
                    <Input.Checkbox
                      id={`object-${object.id}-validation-checkbox`}
                      value={object.validated ?? false}
                      style={Input.style.miniDark}
                      setValue={validateObject}
                    />
                  </span>
                )}
              </div>
              <span className="border-r pr-1">
                ({object.validatedAttributesCount}/{object.attributesCount})
              </span>
              <span>
                <span>{object.name}</span>
                <span
                  className="pl-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowObjectRenameModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="text-gray-400 hover:text-white"
                  />
                </span>
                <span className="pl-1 text-gray-500">({object.id})</span>
              </span>
            </div>
            <div className="flex flex-row gap-1 items-center">
              <div
                className={`text-xs border border-gray-400 text-gray-400
                  px-1 rounded hover:border-white hover:text-white ${
                    loadingObject || loadingAttributeInThisObject
                      ? 'border-white text-white'
                      : ''
                  }`}
                onClick={async (e) => {
                  e.stopPropagation();
                  if (loadingObject || loadingAttributeInThisObject) {
                    return;
                  }

                  if (object.id) {
                    changeValidation({
                      objectIds: [object.id],
                      attributeIds: object.captureObjectCustomAttributes
                        ?.map((a) => a.id)
                        .filter((a) => a) as number[],
                      value: true,
                    });
                  }
                }}
              >
                <span className="flex flex-row gap-0.5 items-center">
                  <FontAwesomeIcon
                    icon={
                      loadingObject || loadingAttributeInThisObject
                        ? faCircleNotch
                        : faCheck
                    }
                    spin={loadingObject || loadingAttributeInThisObject}
                  />
                  <span>All</span>
                </span>
              </div>
              <div
                className={`text-xs border border-gray-400 text-gray-400
                  px-1 rounded hover:border-white hover:text-white ${
                    loadingObject || loadingAttributeInThisObject
                      ? 'border-white text-white'
                      : ''
                  }`}
                onClick={async (e) => {
                  e.stopPropagation();
                  if (loadingObject || loadingAttributeInThisObject) {
                    return;
                  }

                  if (object.id) {
                    changeValidation({
                      objectIds: [object.id],
                      attributeIds: object.captureObjectCustomAttributes
                        ?.map((a) => a.id)
                        .filter((a) => a) as number[],
                      value: false,
                    });
                  }
                }}
              >
                <span className="flex flex-row gap-0.5 items-center">
                  <FontAwesomeIcon
                    icon={
                      loadingObject || loadingAttributeInThisObject
                        ? faCircleNotch
                        : faXmark
                    }
                    spin={loadingObject || loadingAttributeInThisObject}
                  />
                  <span>All</span>
                </span>
              </div>
              <div
                className={`w-4 h-4 rounded-full cursor-pointer flex items-center justify-center shadow hover:ring-1 ring-white`}
                style={{ backgroundColor: color }}
                onClick={(e) => {
                  e.stopPropagation();
                  zoomToObject();
                }}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="text-white w-2.5 h-2.5"
                />
              </div>
              <div>
                <FontAwesomeIcon
                  className={`cursor-pointer ${
                    expanded ? '' : 'transform -rotate-90'
                  }`}
                  icon={faChevronDown}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col divide-y divide-gray-700 overflow-hidden px-1 pl-2 ${
            expanded ? 'max-h-full' : 'max-h-0'
          }`}
        >
          {object.captureObjectCustomAttributes?.map((a, i) => {
            return (
              <QAQCCaptureObjectAttributeRow
                key={i}
                attribute={a}
                changeValidation={changeValidation}
                loadingState={loadingState}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
