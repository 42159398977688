import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';

import {
  useDeleteLayerById,
  useGetCollectionSummary,
  useGetGeometryCollectionDetails,
  usePutLayerById,
} from '@agerpoint/api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LayerExpansion as LayerExpansionComponent } from '@agerpoint/feature';
import {
  Attributes,
  Layer,
  LayerExpansion as LayerExpansionType,
  LayerTypeName,
  PanelsState,
  ProjectState,
} from '@agerpoint/types';
import { Layer as StateLayer, WmsVectorMapLayer } from '@agerpoint/types';
import { toApiLayer, useGlobalStore, useToasts } from '@agerpoint/utilities';

import { usePanels } from '../../state/use-panels';
import {
  findLayer,
  isClientSideGroup,
  useProject,
} from '../../state/use-project';

interface LayerExpansionProps {
  expansionInfo: LayerExpansionType;
  useMultiSelect: any;
}

export function LayerExpansion({
  expansionInfo,
  useMultiSelect,
}: LayerExpansionProps) {
  const {
    groups,
    updateLayerName,
    updateLayerStyle,
    removeLayer,
    setDesiredExtent,
  } = useProject(getLayerState, shallow);
  const { toggleExpansion, selectedLayerId } = usePanels(getPanelsState);

  const [currentLayer, setCurrentLayer] = useState<StateLayer>();
  const currentVectorLayerEntityId = useMemo(() => {
    return isWmsVectorMapLayer(currentLayer) &&
      (currentLayer.layerTypeName === LayerTypeName.Collections ||
        currentLayer.typeId === 8)
      ? currentLayer.entityId
      : -1;
  }, [currentLayer]);
  const layerTypeId = useMultiSelect.layerTypeId;
  const { mutate: putLayer } = usePutLayerById({ id: NaN });
  const { mutate: deleteLayer } = useDeleteLayerById({});
  const myHook =
    layerTypeId === 8
      ? useGetGeometryCollectionDetails
      : useGetCollectionSummary;
  const { data, loading, error } = myHook({
    id: currentVectorLayerEntityId,
    geometryCollectionId: currentVectorLayerEntityId,
    lazy: currentVectorLayerEntityId < 0,
    resolve: (data) => {
      if (layerTypeId === 8) {
        return {
          graduated: [...data.availibleCustomAttributes], // wrong spelling in API

          categorized: [...data.availibleCategorizedCustomAttributes], // wrong spelling in API
        } as any;
      } else {
        return {
          graduated: [
            ...data.availibleAttributes.map(toLower),
            ...data.availibleCustomAttributes,
          ], // wrong spelling in API

          categorized: [
            ...data.availibleCategorizedAttributes.map(toLower),
            ...data.availibleCategorizedCustomAttributes,
          ], // wrong spelling in API
        } as any;
      }
    },
  });

  const toasts = useToasts();
  useEffect(() => {
    if (error) {
      toasts.add(toasts.prepare.error('Failed to load layer attributes!'));
    }
  }, [error]);

  const internalUpdateLayerName = useCallback(persistLayerName, [
    groups,
    putLayer,
    updateLayerName,
  ]);

  const internalUpdateLayerStyle = useCallback(persistLayerStyle, [
    groups,
    putLayer,
    updateLayerStyle,
  ]);

  const internalDeleteLayer = useCallback(persistDeleteLayer, [
    groups,
    removeLayer,
    deleteLayer,
  ]);

  useEffect(() => {
    if (selectedLayerId) {
      const groupLayer = findLayer(groups, selectedLayerId);
      if (groupLayer) {
        const { layer } = groupLayer;
        setCurrentLayer(layer);
      }
    }
  }, [selectedLayerId, expansionInfo.type, groups]);

  return currentLayer ? (
    <LayerExpansionComponent
      onCancel={() => toggleExpansion({ type: 'none' })}
      layer={currentLayer}
      updateLayerName={internalUpdateLayerName}
      deleteLayer={internalDeleteLayer}
      updateLayerStyle={internalUpdateLayerStyle}
      setDesiredExtent={setDesiredExtent}
      availableAttributes={
        currentVectorLayerEntityId > 0
          ? {
              data: (data || { graduated: [], categorized: [] }) as Attributes,
              loading: loading,
              error: error,
            }
          : undefined
      }
    />
  ) : null;

  async function persistLayerName(layerId: number, name: string) {
    const groupLayer = findLayer(groups, layerId);

    if (groupLayer) {
      const { group, layer } = groupLayer;
      if (!isClientSideGroup(group)) {
        await putLayer(toApiLayer({ ...layer, name }), {
          pathParams: { id: layer.id },
        });
      }
    }
    updateLayerName(layerId, name);
  }
  async function persistLayerStyle(layer: Layer) {
    const groupLayer = findLayer(groups, layer.id);

    updateLayerStyle(layer.id, layer);
    if (groupLayer) {
      const { group } = groupLayer;
      if (!isClientSideGroup(group)) {
        await putLayer(toApiLayer(layer), {
          pathParams: { id: layer.id },
        });
      }
    }
  }

  async function persistDeleteLayer(layerId: number) {
    const groupLayer = findLayer(groups, layerId);

    if (groupLayer) {
      const { group, layer } = groupLayer;
      if (!isClientSideGroup(group)) {
        await deleteLayer(layer.id);
      }
    }

    removeLayer(layerId);
  }
}

function getLayerState({
  groups,
  actions: {
    setDesiredExtent,
    layers: {
      setName: updateLayerName,
      setStyle: updateLayerStyle,
      delete: removeLayer,
    },
  },
}: ProjectState) {
  return {
    updateLayerName,
    updateLayerStyle,
    removeLayer,
    groups,
    setDesiredExtent,
  };
}

function getPanelsState({ toggleExpansion, selectedLayerId }: PanelsState) {
  return { toggleExpansion, selectedLayerId };
}

function isWmsVectorMapLayer(
  layer: StateLayer | undefined
): layer is WmsVectorMapLayer {
  return layer?.type === 'WmsVector';
}

function toLower(attribute: string) {
  return attribute.toLowerCase();
}
