import { Box3, Object3D, PerspectiveCamera, Scene, Vector3 } from 'three';

import { ColorsThreeD, HexColor, IGeometryBase } from '@agerpoint/types';

import { AnnotationsDispatcher } from './annotations-3d/annotations-dispatcher';
import { AnnotationBase } from './annotations.base';

export class GeometryBase extends AnnotationBase implements IGeometryBase {
  annoMgr: AnnotationsDispatcher;
  userData = {} as {
    originalColor: ColorsThreeD | HexColor;
    originalScale?: Vector3;
    originalLineWidth?: number;
  };
  highlightColor: ColorsThreeD | HexColor = ColorsThreeD.White;
  useHighlightColor: boolean = false;
  name: string = '';
  uniqueId: string = '';
  visibility: boolean = false;
  isHighlighted: boolean = false;

  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    isPotree: boolean = false,
    isReadOnly: boolean = false,
    canvas?: HTMLCanvasElement
  ) {
    super(scene, perspectiveCamera, isPotree, isReadOnly, canvas);
    this.annoMgr = new AnnotationsDispatcher();
    this.highlightColor = ColorsThreeD.White;
  }

  get isVisible() {
    return this.visibility;
  }

  doZoom(object: Object3D) {
    const boundingBox = new Box3().setFromObject(object);
    const center = boundingBox.getCenter(new Vector3());
    // Calculate a new camera position
    const size = boundingBox.getSize(new Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = this.perspectiveCamera.fov * (Math.PI / 180);
    // let cameraZ = Math.abs((maxDim / 2) * Math.tan(fov * 2));

    // Consider any current camera position offset
    let scaleFactor = 3; // Adjust this factor to bring the camera closer (less than 1) or farther (more than 1)
    let cameraZ = Math.abs((maxDim / 2) * Math.tan(fov * 2)) * scaleFactor;

    // Create a vector for the new camera position
    const newCameraPos = new Vector3();
    newCameraPos.copy(center);
    newCameraPos.z += cameraZ;

    // Alternative without tweening:
    this.perspectiveCamera.position.set(
      newCameraPos.x,
      newCameraPos.y,
      newCameraPos.z
    );
    this.perspectiveCamera.lookAt(center);
  }

  updateDescription(id: string, description: string): void {
    this.annoMgr.updateCapObjDescription(id, description);
  }

  updateName(name: string): void {
    this.annoMgr.updateCapObjName(this.uniqueId, name);
  }

  // Implement in child class
  dispose() {}
  delete() {}
  disposeAndDelete() {}
  remove3dLineById(id: string) {}
  highlight(color?: ColorsThreeD | HexColor): void {}
  unHighlight(): void {}
  updateColor(color: ColorsThreeD | HexColor, persist?: boolean): void {}
  updateVisibility(isVisible: boolean): void {}
  cameraChanged(): void {}
}
