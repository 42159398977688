import {
  faChevronDown,
  faChevronUp,
  faRefresh,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { PrimaryButton } from '@agerpoint/component';

import { DatatableDefaultLoadingIndicator } from './datatable';
import { DatatableDNDItemData, DatatableDNDProps } from './datatable-dnd-types';
import { isClickableColumn, isSortableColumn } from './datatable-types';

export function DatatableDND<T>({
  data,
  columns,
  rowHeight,
  loading,
  loadingIndicator,
  noResults,
  error,
  pagination,
  sort,
  setSort,
  cellOnClick,
  style: tableStyle,
  datatableRef,
  draggableUniqueId,
  isDropDisabled,
  multiSelectCount,
  droppableId,
  isDragDisabled,
}: DatatableDNDProps<T>) {
  const portal = useRef(document.createElement('div'));

  useEffect(() => {
    const p = portal.current;
    document.body.appendChild(p);
    return () => {
      document.body.removeChild(p);
    };
  }, []);

  const itemData: DatatableDNDItemData<T> = {
    data,
    loading,
    loadingIndicator,
    error,
    tableStyle,
    columns,
    cellOnClick,
    portal: portal.current,
    draggableUniqueId,
    multiSelectCount,
    isDragDisabled,
  };

  const [tableDivRef, setTableDivRef] = useState<HTMLDivElement | null>(null);
  const [headerDivRef, setHeaderDivRef] = useState<HTMLDivElement | null>(null);
  const [bodyDivRef, setBodyDivRef] = useState<HTMLDivElement | null>(null);

  const [headerRightMarginAdjustment, setHeaderRightMarginAdjustment] =
    useState<number>();

  const handleRightMarginAdjustment = useCallback(() => {
    if (!headerDivRef || !bodyDivRef || bodyDivRef.clientWidth === 0) {
      return;
    }
    const diff = headerDivRef?.clientWidth - bodyDivRef?.clientWidth;
    if (diff !== headerRightMarginAdjustment) {
      if (diff > 30 || diff < 0) {
        setHeaderRightMarginAdjustment(0);
      } else {
        setHeaderRightMarginAdjustment(diff);
      }
    }
  }, [headerDivRef, bodyDivRef, headerRightMarginAdjustment]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleRightMarginAdjustment);

    if (bodyDivRef) {
      resizeObserver.observe(bodyDivRef);
      return () => {
        resizeObserver.unobserve(bodyDivRef);
        resizeObserver.disconnect();
      };
    }
    return;
  }, [handleRightMarginAdjustment]);

  const [tableXScroll, setTableXScroll] = useState<boolean>(false);
  const handleShowScroll = useCallback(() => {
    if (!tableDivRef?.clientWidth || !tableStyle?.tableMinWidth) {
      setTableXScroll(false);
      return;
    }

    if (tableDivRef.clientWidth < tableStyle.tableMinWidth) {
      setTableXScroll(true);
      return;
    }

    setTableXScroll(false);
    return;
  }, [tableStyle?.tableMinWidth, tableDivRef]);

  useEffect(() => {
    if (tableStyle?.tableMinWidth) {
      handleShowScroll();
      window.addEventListener('resize', handleShowScroll);
      return () => {
        window.removeEventListener('resize', handleShowScroll);
      };
    }
    return;
  }, [handleShowScroll, tableStyle?.tableMinWidth]);

  const itemCount = useMemo(() => {
    const needsAdditionalItem =
      loading === true || (error !== undefined && data.length > 0);

    if (needsAdditionalItem) {
      return data.length + 1;
    }

    return data.length;
  }, [data.length, loading, error]);

  return (
    <div
      className={`w-full h-full flex flex-col ${
        tableStyle?.tableWrapperStyle || ''
      }
      `}
      id="table"
      ref={setTableDivRef}
      style={{
        overflowX: tableXScroll ? 'scroll' : 'hidden',
        overflowY: 'hidden',
      }}
    >
      <div
        className={`w-full flex flex-row ${
          tableStyle?.headerWrapperStyle || ''
        }`}
        ref={setHeaderDivRef}
        id="table_headers"
        style={{
          minWidth: tableStyle?.tableMinWidth
            ? `${tableStyle.tableMinWidth}px`
            : undefined,
        }}
      >
        {columns.map((c, i) => {
          if (c.visible === false) {
            return null;
          }

          return (
            <div
              onClick={() => {
                if (isSortableColumn(c)) {
                  setSort?.({
                    key: c.sortKey,
                    order:
                      sort?.key === c.sortKey && sort?.order === 'desc'
                        ? 'asc'
                        : 'desc',
                  });
                } else if (isClickableColumn(c)) {
                  c.onClick?.();
                }
              }}
              key={i}
              id={`table_header_${i}`}
              style={{ flex: c.flex ?? 1 }}
              className={`overflow-hidden ${
                (isSortableColumn(c) || isClickableColumn(c)) &&
                'cursor-pointer'
              } ${c?.style?.headerStyle || ''} ${
                c?.style?.columnWrapperStyle || ''
              } ${tableStyle?.headerStyle || ''}`}
            >
              <div className="flex flex-row truncate items-center gap-1">
                <div className="truncate">{c.label}</div>
                {isSortableColumn(c) && sort?.key === c.sortKey && (
                  <div>
                    <FontAwesomeIcon
                      icon={
                        sort?.order === 'desc' ? faChevronDown : faChevronUp
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div style={{ width: `${headerRightMarginAdjustment ?? 0}px` }}></div>
      </div>
      <div
        className={`flex-grow ${tableStyle?.bodyWrapperStyle || ''} relative`}
        id="table_body"
        style={{
          minWidth: tableStyle?.tableMinWidth
            ? `${tableStyle.tableMinWidth}px`
            : undefined,
        }}
      >
        {data.length === 0 && !loading && error !== undefined && (
          <div
            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 text-center`}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              {error.title && (
                <span className="font-bold text-lg">{error.title}</span>
              )}
              {error.message && (
                <span className="text-sm text-gray-500 font-light">
                  {error.message}
                </span>
              )}
              {error.action && (
                <div className="pt-4">
                  <PrimaryButton
                    label="Refresh"
                    icon={<FontAwesomeIcon icon={faRefresh} />}
                    onClicked={error.action}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {data.length === 0 &&
          !loading &&
          noResults !== undefined &&
          error === undefined && (
            <div
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 text-center`}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                {noResults.title && (
                  <span className="font-bold text-lg">{noResults.title}</span>
                )}
                {noResults.message && (
                  <span className="text-sm text-gray-500 font-light">
                    {noResults.message}
                  </span>
                )}
                {noResults.action && (
                  <div className="pt-4">
                    <PrimaryButton
                      icon={noResults.actionIcon}
                      label={noResults.actionLabel}
                      onClicked={noResults.action}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        <Droppable
          key={droppableId}
          droppableId={droppableId}
          mode={'virtual'}
          isDropDisabled={isDropDisabled}
          renderClone={(provided, _, rubric) => (
            <Row
              index={rubric.source.index}
              style={{}}
              itemData={{ ...itemData, provided, isDragged: true }}
            />
          )}
        >
          {({ droppableProps, innerRef }) => (
            <div className="h-full" {...droppableProps} ref={innerRef}>
              <AutoSizer>
                {({ height = 0, width = 0 }) => (
                  <FixedSizeList<DatatableDNDItemData<T>>
                    ref={(r) => {
                      if (datatableRef) {
                        datatableRef.current = r;
                      }
                    }}
                    innerRef={(r) => {
                      setBodyDivRef(r);
                      innerRef(r);
                    }}
                    height={Number.isNaN(height) ? 0 : height}
                    width={Number.isNaN(width) ? 0 : width}
                    onItemsRendered={(props) => {
                      let key: keyof typeof props;
                      for (key in props) {
                        if (isNaN(props[key])) {
                          props[key] = 0;
                        }
                      }

                      const shouldLoadNextPage =
                        !error &&
                        !loading &&
                        props.visibleStopIndex >=
                          data.length - (pagination?.threshold ?? 1);

                      if (shouldLoadNextPage) {
                        pagination?.loadNextPage?.();
                      }
                    }}
                    itemCount={itemCount}
                    itemSize={rowHeight}
                    overscanCount={5}
                    itemData={itemData}
                  >
                    {Item}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
}

function Item<T>({
  index,
  style,
  data: itemData,
}: {
  index: number;
  style: CSSProperties;
  data: DatatableDNDItemData<T>;
}) {
  if (index === itemData.data.length && itemData.error && !itemData.loading) {
    return (
      <div
        style={style}
        className="flex flex-row w-full h-full items-center justify-center gap-2"
      >
        {itemData.error.title && (
          <span className="font-bold text-sm text-center leading-none">
            {itemData.error.title}
          </span>
        )}
        {itemData.error.action && (
          <PrimaryButton
            icon={<FontAwesomeIcon icon={faRefresh} />}
            label="Retry"
            size="x-small"
            onClicked={itemData.error.action}
            theme="white"
          />
        )}
      </div>
    );
  }

  if (index === itemData.data.length) {
    if (itemData.loadingIndicator) {
      return (
        <div style={style} className="flex flex-row w-full h-full">
          {itemData.loadingIndicator}
        </div>
      );
    }
    return <DatatableDefaultLoadingIndicator style={style} />;
  }

  return <DraggableRow index={index} style={style} itemData={itemData} />;
}

function DraggableRow<T>({
  index,
  style,
  itemData,
}: {
  index: number;
  style: CSSProperties;
  itemData: DatatableDNDItemData<T>;
}) {
  const { data } = itemData;
  const d = data[index];

  return (
    <Draggable
      key={itemData.draggableUniqueId(d, index)}
      draggableId={itemData.draggableUniqueId(d, index)}
      index={index}
      isDragDisabled={itemData.isDragDisabled}
    >
      {(provided, snapshot) => {
        const component = (
          <Row
            key={itemData.draggableUniqueId(d, index) + '_row'}
            index={index}
            style={style}
            itemData={{
              ...itemData,
              provided,
            }}
          />
        );

        if (snapshot.isDragging) {
          return ReactDOM.createPortal(
            component,
            itemData.portal,
            itemData.draggableUniqueId(d, index) + '_portal'
          );
        }

        return component;
      }}
    </Draggable>
  );
}

function Row<T>({
  index,
  style,
  itemData,
}: {
  index: number;
  style: CSSProperties;
  itemData: DatatableDNDItemData<T>;
}) {
  const { data, tableStyle, columns, cellOnClick } = itemData;
  const d = data[index];
  return (
    <div
      ref={itemData.provided?.innerRef}
      {...itemData.provided?.draggableProps}
      {...itemData.provided?.dragHandleProps}
      className={`flex flex-row w-full ${
        tableStyle?.rowWrapperStyle || ''
      } table-row ${itemData.isDragged ? 'bg-white shadow relative' : ''}`}
      style={{
        ...style,
        ...itemData.provided?.draggableProps.style,
      }}
    >
      {itemData.isDragged &&
        itemData.multiSelectCount !== undefined &&
        itemData.multiSelectCount > 1 && (
          <div className="absolute -top-2 -right-2">
            <div className="w-6 h-6 rounded-full bg-gray-100 shadow flex justify-center items-center p-1">
              {`+${itemData.multiSelectCount - 1}`}
            </div>
          </div>
        )}
      <div
        className={`flex flex-row w-full h-full ${tableStyle?.rowStyle || ''} ${
          index % 2
            ? tableStyle?.oddRowStyle || ''
            : tableStyle?.evenRowStyle || ''
        }`}
        style={{
          border: itemData.isDragged ? 0 : undefined,
        }}
      >
        {columns.map((c, i) => {
          if (c.visible === false) {
            return null;
          }
          const onClick = c.name ? cellOnClick?.(c.name) : undefined;

          return (
            <div
              key={i}
              style={{ flex: c.flex ?? 1 }}
              onClick={onClick ? (e) => onClick?.(d, e) : undefined}
              className={`h-full overflow-hidden ${
                onClick ? 'cursor-pointer' : ''
              } ${c?.style?.columnWrapperStyle || ''} ${
                c?.style?.bodyStyle || ''
              } ${tableStyle?.cellStyle || ''}`}
            >
              <div
                className={`truncate ${
                  c.style?.expandBody ? 'w-full' : ''
                }`.trim()}
              >
                {c.value(d, index)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
