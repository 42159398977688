import { CSSProperties } from 'react';

import * as types from '.';

export { ReactComponent as LeafIcon } from './leaf.svg';
export { ReactComponent as ArrowIcon } from './arrow-right.svg';
export { ReactComponent as BaseMapsIcon } from './base-maps.svg';
export { ReactComponent as TreeIcon } from './tree.svg';
export { ReactComponent as HandleIcon } from './handle.svg';
export { ReactComponent as RasterIcon } from './raster.svg';
export { ReactComponent as PointCloudIcon } from './cube.svg';
export { ReactComponent as VectorIcon } from './vector.svg';
export { ReactComponent as DefaultLayerIcon } from './default-layer.svg';
export { ReactComponent as CloudIcon } from './cloud.svg';
export { ReactComponent as DropletIcon } from './droplet.svg';
export { ReactComponent as FlagIcon } from './flag.svg';
export { ReactComponent as CompassIcon } from './compass.svg';
export { ReactComponent as AgerpointLogo } from './ag-logo.svg';
export { ReactComponent as FileDownloadIcon } from './attachment.svg';
export { ReactComponent as BoundaryIcon } from './draw-polygon-light.svg';
export { ReactComponent as SampleIcon } from './map-location-dot-light.svg';
export { ReactComponent as ThreeDIcon } from './cube-light.svg';
export { ReactComponent as MobileIcon } from './mobile-notch-light.svg';
export { ReactComponent as SatelliteIcon } from './satellite-light.svg';
export { ReactComponent as UavIcon } from './drone-front-light.svg';
export { ReactComponent as ImageryIcon } from './camera-light.svg';
export { ReactComponent as ReportIcon } from './file-lines-light.svg';
export { ReactComponent as GeometryCollection } from './geometrycollection.svg';
export { ReactComponent as Capture } from './capture.svg';
export { ReactComponent as faAppleCrate } from './faAppleCrate.svg';
export { ReactComponent as faCircle } from './faCircle.svg';
export { ReactComponent as faCubes } from './faCubes.svg';
export { ReactComponent as faLeafHeart } from './faLeafHeart.svg';
export { ReactComponent as faMagnifyingGlass } from './faMagnifyingGlass.svg';
export { ReactComponent as faRulerVertical } from './faRulerVertical.svg';
export { ReactComponent as faStethoscope } from './faStethoscope.svg';
export { ReactComponent as faTree } from './faTree.svg';
export { ReactComponent as faToolbox } from './faToolbox.svg';
export { ReactComponent as faTreeDeciduous } from './faTreeDeciduous.svg';
export { ReactComponent as faTrees } from './faTrees.svg';
export { ReactComponent as faWeightHanging } from './faWeightHanging.svg';
export { ReactComponent as faHighDefinition } from './faHighDefinition.svg';

export type OtherTypes = 'AgerpointLogo';

/**
 * These type-values need to end with 'Icon'.
 */
export type IconTypes =
  | 'LeafIcon'
  | 'ArrowIcon'
  | 'BaseMapsIcon'
  | 'TreeIcon'
  | 'HandleIcon'
  | 'RasterIcon'
  | 'PointCloudIcon'
  | 'VectorIcon'
  | 'DefaultLayerIcon'
  | 'CloudIcon'
  | 'DropletIcon'
  | 'FlagIcon'
  | 'CompassIcon'
  | 'FileDownloadIcon'
  | 'BoundaryIcon'
  | 'SampleIcon'
  | 'ThreeDIcon'
  | 'MobileIcon'
  | 'SatelliteIcon'
  | 'UavIcon'
  | 'ImageryIcon'
  | 'ReportIcon'
  | 'GeometryCollection'
  | 'Capture'
  | 'faAppleCrate'
  | 'faCircle'
  | 'faCubes'
  | 'faLeafHeart'
  | 'faMagnifyingGlass'
  | 'faRulerVertical'
  | 'faStethoscope'
  | 'faTree'
  | 'faToolbox'
  | 'faTreeDeciduous'
  | 'faTrees'
  | 'faWeightHanging'
  | 'faHighDefinition';

const isIconType = (type: IconTypes | OtherTypes): boolean =>
  (type as string).endsWith('Icon');

export enum ColorDefaults {
  blue = '#339BC0',
  grey = '#B1B5B9',
  green = '#176754',
}

export interface SvgElementProps {
  type: IconTypes | OtherTypes;
  className?: string;
  style?: CSSProperties;

  /**
   * If specificed, will set `IconTypes` stroke to value.
   *
   * Defaults to `ColorDefaults.blue`
   */
  color?: ColorDefaults;
}

/**
 * Custom Svg importer.
 *
 * Most, if not all of `IconTypes` (Svgs) are from Figma documents, that are located in
 * the following project path:
 * ```
 * libs/component/src/icons
 * ```
 *
 * @param param0
 * @returns a JSX.Element
 */
export function SvgElement({
  type,
  className,
  style,
  color,
}: SvgElementProps): JSX.Element {
  const SvgType = types[type];
  // fail nicely
  if (!SvgType) return <span></span>;

  if (isIconType(type) && (!style?.stroke || color)) {
    style = {
      stroke: color || ColorDefaults.blue,
      color: color || ColorDefaults.blue,
    };
  }

  return <SvgType className={className} style={style} />;
}
