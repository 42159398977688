import compare from 'trivial-compare';

import { Analytic } from '@agerpoint/api';

export const sortAnalytics = (data?: Analytic[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) =>
    compare(a.analyticName?.toLowerCase(), b.analyticName?.toLowerCase())
  );
  return sorted;
};
