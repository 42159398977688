import React, { ReactNode, SyntheticEvent } from 'react';

import { theme as styleTheme } from './primary-buttons.styles';

/**
 * @see https://reactjs.org/docs/react-api.html#createelement
 * @param icon
 * @returns
 */

export interface PrimaryButtonProps {
  label?: ReactNode;
  icon?: ReactNode;
  theme?: string;
  size?: string;
  className?: string;
  testId?: string;

  /**
   * When the button is clicked.
   *
   * @example
   * ```
   * onClicked: () =>
   * ```
   */
  onClicked?: () => void;
  disabled?: boolean;
}

export function PrimaryButton(props: PrimaryButtonProps) {
  const { label, className, theme, icon, disabled, onClicked, size, testId } =
    props;
  function handleClick(e: SyntheticEvent) {
    if (onClicked) {
      onClicked();
    }
  }

  return (
    <button
      data-test-id={testId}
      type="button"
      onClick={handleClick}
      className={`${styleTheme({ theme, disabled, size })} ${className} ${
        disabled ? 'cursor-not-allowed' : ''
      }`}
      disabled={disabled}
    >
      {icon && (
        <span
          className={`flex -ml-1 ${
            size && ['small', 'x-small'].includes(size) ? 'mr-0' : 'mr-2'
          } h-5 w-5 items-center justify-center`}
        >
          {icon}
        </span>
      )}
      {label}
    </button>
  );
}

export default PrimaryButton;
