import { useAuth } from 'oidc-react';
import { RestfulProvider } from 'restful-react';

import { isHttpError } from '@agerpoint/api';
import { environment } from '@agerpoint/utilities';

interface RestfulReactSetupProps {
  children: JSX.Element | null;
}

const RestfulProviderProxy = RestfulProvider as any;

export const RestfulReactSetup = ({ children }: RestfulReactSetupProps) => {
  const { userData, signOut, signIn } = useAuth();

  return (
    <RestfulProviderProxy
      base={environment.api_server_url}
      requestOptions={{
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      }}
      onError={async (err: any) => {
        if (isHttpError(err) && err.status === 401) {
          signOut();
          signIn();
        }
      }}
    >
      {children}
    </RestfulProviderProxy>
  );
};
