import { create } from 'zustand';

interface MultiSelectionState {
  layerIds: number[];
  groupId: number | null;
  layerTypeId: number | null;
  setMultiSelection: (addedSelection: number[]) => void;
  clearMultiSelection: () => void;
  setMultiSelectGroupId: (groupId: number | null) => void;
  clearMultiSelectGroupId: () => void;
  setMultiSelectLayerTypeId: (layerTypeId: number | null) => void;
}

const useMultiSelection = create<MultiSelectionState>((set) => ({
  layerIds: [],
  groupId: null,
  layerTypeId: null,

  setMultiSelection: (addedSelection: number[]) =>
    set({
      layerIds: addedSelection,
    }),

  clearMultiSelection: () =>
    set({
      layerIds: [],
      layerTypeId: null,
      groupId: null,
    }),

  setMultiSelectGroupId: (groupId: number | null) =>
    set({
      groupId: groupId,
    }),

  clearMultiSelectGroupId: () =>
    set({
      groupId: null,
    }),
  setMultiSelectLayerTypeId: (layerTypeId: number | null) =>
    set({
      layerTypeId: layerTypeId,
    }),
}));

export default useMultiSelection;
