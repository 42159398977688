import { faSortDown, faSortUp } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useMemo, useState } from 'react';
import compare from 'trivial-compare';

import { Input } from '@agerpoint/component';

type DataValue = string | number;

interface CustomAttributeValue {
  attributeName: string;
  attributeValue: string;
}

interface DataRow extends Record<string, any> {
  id: number;
  morphologyCustomAttributes: CustomAttributeValue[];
  geometryCustomAttributes: CustomAttributeValue[];
}
type Data = DataRow[];

type Header = {
  name: string;
  datatype: string;
};

interface AttributesTableProps {
  data: Data;
  selectedFeatureIds: number[];
  setSelectedFeatureIds: (featureIds: number[]) => void;
  layerType: number;
}

type Sort = {
  attribute: string;
  order: 'asc' | 'desc';
};

export function AttributesTable({
  data,
  selectedFeatureIds,
  setSelectedFeatureIds,
  layerType,
}: AttributesTableProps) {
  const { headers, rows } = prepareData(data, layerType);
  const numberFormatter = new Intl.NumberFormat();
  const [sort, setSort] = useState<Sort>({ attribute: 'id', order: 'asc' });
  const sortedRows = useMemo(
    () =>
      rows.sort(
        (a, b) =>
          compare(a[sort.attribute], b[sort.attribute]) *
          (sort.order === 'asc' ? 1 : -1)
      ),
    [sort, rows]
  );

  return (
    <div className="w-full h-full">
      <table className="table-auto text-sm">
        <colgroup>
          {[{}, {}, ...headers].map((_, i) => (
            <col key={i} className="border-r border-gray-200 text-gray-300" />
          ))}
        </colgroup>
        <thead className="sticky top-0 right-0 bg-white border-b-2 border-gray-200 z-10">
          <tr className="text-gray-700">
            <TableHeader
              order={sort.attribute === 'id' ? sort.order : undefined}
              onClick={() => updateSort('id')}
            >
              id
            </TableHeader>
            <TableHeader />
            {headers.map(({ name, datatype }) => (
              <TableHeader
                key={name}
                className={datatype === 'number' ? 'text-right' : 'text-left'}
                order={sort.attribute === name ? sort.order : undefined}
                onClick={() => updateSort(name)}
              >
                {name
                  .split('_')
                  .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                  .join(' ')}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedRows.map((row) => (
            <tr key={row.id} className="even:bg-gray-50">
              <TableCell className="text-gray-400">{row.id}</TableCell>
              <TableCell>
                <Input.Checkbox
                  id={`selected-features-{row.id}`}
                  value={selectedFeatureIds.indexOf(row.id as number) >= 0}
                  setValue={(value) =>
                    setSelectedFeatureIds(
                      value
                        ? [...selectedFeatureIds, row.id as number]
                        : selectedFeatureIds.filter((id) => id !== row.id)
                    )
                  }
                />
              </TableCell>
              {headers.map(({ name, datatype }) => (
                <TableCell
                  key={name}
                  className={datatype === 'number' ? 'text-right' : 'text-left'}
                >
                  {row[name] != null
                    ? datatype === 'number'
                      ? numberFormatter.format(row[name] as unknown as number)
                      : row[name]
                    : ''}
                </TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  function updateSort(attribute: string) {
    setSort({
      attribute,
      order:
        sort.attribute !== attribute
          ? 'asc'
          : sort.order === 'asc'
          ? 'desc'
          : 'asc',
    });
  }
}

function prepareData(
  data: Data,
  layerType: number
): { headers: Header[]; rows: Data } {
  const standardAttributes = Object.keys(data[0]).map((headerName) => ({
    name: headerName,
    datatype: typeof data[0][headerName],
  }));
  const customAttributes =
    layerType === 8
      ? data[0].geometryCustomAttributes?.map((attribute) => ({
          name: attribute.attributeName,
          datatype: !isNaN(Number(attribute.attributeValue))
            ? 'number'
            : 'string',
        }))
      : data[0].morphologyCustomAttributes?.map((attribute) => ({
          name: attribute.attributeName,
          datatype: !isNaN(Number(attribute.attributeValue))
            ? 'number'
            : 'string',
        }));

  const headers = customAttributes
    ? [...standardAttributes, ...customAttributes]
        .filter(
          ({ name, datatype }) =>
            name !== 'id' && ['number', 'string'].indexOf(datatype) >= 0
        )
        .sort(({ name: aName }, { name: bName }) => compare(aName, bName))
    : [...standardAttributes]
        .filter(
          ({ name, datatype }) =>
            name !== 'id' && ['number', 'string'].indexOf(datatype) >= 0
        )
        .sort(({ name: aName }, { name: bName }) => compare(aName, bName));

  const rows = data.map((row) => {
    const result = {
      ...row,
    };

    if (customAttributes)
      for (let i = 0; i < customAttributes.length; i++) {
        const customAttribute = customAttributes[i];
        const value =
          layerType === 8
            ? row.geometryCustomAttributes?.find(
                (attrib) => attrib.attributeName === customAttribute.name
              )?.attributeValue
            : row.morphologyCustomAttributes?.find(
                (attrib) => attrib.attributeName === customAttribute.name
              )?.attributeValue;
        result[customAttribute.name] =
          value != null && customAttribute.datatype === 'number'
            ? Number(value)
            : value;
      }

    return result;
  });
  return { headers, rows };
}

interface TableCellProps {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}

interface TableHeader extends TableCellProps {
  order?: 'asc' | 'desc';
}

function TableCell({ children, className, onClick }: TableCellProps) {
  return (
    <td className={`px-2 py-0 ${className || ''}`} onClick={onClick}>
      {children}
    </td>
  );
}

function TableHeader({ children, className, order, onClick }: TableHeader) {
  return (
    <th
      className={`font-normal px-2 py-2 cursor-pointer whitespace-nowrap ${
        className || ''
      }`}
      onClick={onClick}
    >
      {children}
      {order ? (
        <span className={`ml-2 inline-block text-gray `}>
          <FontAwesomeIcon icon={order === 'asc' ? faSortUp : faSortDown} />
        </span>
      ) : (
        <span className="inline-block w-4" />
      )}
    </th>
  );
}
