import { Gradient, StrokePattern as StrokePatternType } from '@agerpoint/types';
import { RadioCard } from '../radio-card/radio-card';
import { LinearGradient } from '@agerpoint/component';

interface ColorValueProps {
  color: string;
}

export function ColorValue({ color }: ColorValueProps) {
  return (
    <div
      className="w-14 h-5 rounded-sm border border-black border-opacity-20"
      style={{ background: color }}
    />
  );
}

interface GradientColorValueProps {
  color?: Gradient;
}

export function GradientColorValue({ color }: GradientColorValueProps) {
  return color ? (
    <LinearGradient colors={color} className="h-5 w-14 rounded-sm" />
  ) : (
    <ColorValue color={'transparent'} />
  );
}

interface StrokePatternProps {
  strokePattern: StrokePatternType;
  onChange: (strokePattern: StrokePatternType) => void;
}

export function StrokePattern({ strokePattern, onChange }: StrokePatternProps) {
  return (
    <div className="space-y-2">
      {['Solid', 'Dashed'].map((value) => {
        return (
          <RadioCard
            key={value}
            checked={strokePattern === value}
            title={value}
            id={value}
            name={value}
            value={value}
            onChange={(event) =>
              onChange
                ? onChange(
                    (event.target as HTMLInputElement)
                      .value as StrokePatternType
                  )
                : null
            }
          >
            <span>{value}</span>
          </RadioCard>
        );
      })}
    </div>
  );
}
