/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */

export type CaptureType = (typeof CaptureType)[keyof typeof CaptureType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaptureType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;
