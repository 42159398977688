import compare from 'trivial-compare';

import { APIModels } from '../..';

export const layers = (data?: APIModels.Layer[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) => {
    return compare(a.createDatetime, b.createDatetime);
  });
  return sorted;
};
