export interface TileViewProps<T> {
  data: T[];
  tileGenerator: (
    item: T,
    layout: TileViewLayout,
    index: number
  ) => React.ReactNode;
  layout: TileViewLayout;
  pagination?: TileViewPaginationProps;
  noResults?: TileViewNoDataProps;
  error?: TileViewErrorProps;
  loading?: boolean;
}

interface TileViewErrorProps {
  title?: string;
  message?: string;
  action?: () => void;
}

interface TileViewNoDataProps {
  icon?: React.ReactNode;
  title?: string;
  message?: string;
  action?: () => void;
  actionLabel?: string;
  actionIcon?: React.ReactNode;
}

export enum TileViewLayout {
  LargeGrid = 'LargeGrid',
  SmallGrid = 'SmallGrid',
  List = 'List',
  Cloud = 'Cloud',
}

export interface TileViewPaginationProps {
  loadNextPage: () => Promise<void>;
}
