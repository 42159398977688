import { useEffect } from 'react';

import { LdFlags } from '@agerpoint/types';
import { hasPermission, useGlobalStore } from '@agerpoint/utilities';

enum SplatQualityNames {
  Low = 'Low',
  High = 'High',
}
export const StandAloneViewerSplats = () => {
  const {
    permissions,
    standAloneViewer: {
      actions: { setSplatQuality },
      splatQuality,
    },
  } = useGlobalStore();

  useEffect(() => {
    const hasSplatsPermission = hasPermission(
      LdFlags.PotreeUseHighQuality,
      permissions
    );
    if (hasSplatsPermission) {
      setSplatQuality(SplatQualityNames.High);
    } else {
      setSplatQuality(SplatQualityNames.Low);
    }
  }, [permissions]);

  return (
    <>
      <div className="flex items-center pb-1">
        <div className="flex items-center justify-between min-h-[25px] flex-row w-full">
          <div className="mx-1">
            <input
              type="radio"
              id={SplatQualityNames.Low}
              checked={splatQuality === SplatQualityNames.Low}
              value={SplatQualityNames.Low}
              onChange={() => {
                setSplatQuality(SplatQualityNames.Low);
              }}
              className={`w-4 h-4 text-green bg-gray-100 border-gray-300 hover:ring-green hover:ring-2 cursor-pointer`}
            />
          </div>

          <div className={`ml-2 px-1 w-full truncate`}>
            <label
              className="py-0 px-1 text-sm rounded border border-transparent truncate cursor-pointer"
              htmlFor={SplatQualityNames.Low}
            >
              {SplatQualityNames.Low}
            </label>
          </div>
        </div>
      </div>
      <div className="flex items-center pb-1">
        <div className="flex items-center justify-between min-h-[25px] flex-row w-full">
          <div className="mx-1">
            <input
              type="radio"
              id={SplatQualityNames.High}
              checked={splatQuality === SplatQualityNames.High}
              value={SplatQualityNames.High}
              onChange={() => {
                setSplatQuality(SplatQualityNames.High);
              }}
              className={`w-4 h-4 text-green bg-gray-100 border-gray-300 hover:ring-green hover:ring-2 cursor-pointer`}
            />
          </div>

          <div className={`ml-2 px-1 w-full truncate`}>
            <label
              className="py-0 px-1 text-sm rounded border border-transparent truncate cursor-pointer"
              htmlFor={SplatQualityNames.High}
            >
              {SplatQualityNames.High}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
