import { Feature, Tile } from 'ol';
import TileState from 'ol/TileState';
import VectorTile from 'ol/VectorTile';
import { getArea, getCenter } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import { MutableRefObject } from 'react';

export function loadTile(
  accessToken: string | undefined,
  featureCache: MutableRefObject<Record<string, Feature<any>>>,
  featurePointsSource: VectorSource<Feature<Geometry>>,
  tile: Tile,
  url: string,
  wmsId: number
) {
  const vectorTile = tile as VectorTile;
  vectorTile.setLoader(function (tileExtent, resolution, projection) {
    fetch(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(function (response) {
      if (response.ok) {
        response.arrayBuffer().then(function (data) {
          const format = vectorTile.getFormat(); // ol/format/MVT configured as source format
          try {
            const features = format.readFeatures(data, {
              extent: tileExtent,
              featureProjection: projection,
            }) as Feature<Geometry>[];
            hydrateCustomAttributes(features);

            vectorTile.setFeatures(features);
            for (const feature of features) {
              const id =
                feature.get('geometry_id') ||
                feature.get('morphology_id') ||
                feature.get('capture_id');

              if (!id) continue;
              featureCache.current[id] = feature;

              // Update the loaded feature's point feature.
              // Since a feature can be split across multiple
              // vector tiles, features with the same id can occur
              // multiple times. To figure out which is the most
              // representative part of the feature, we use the
              // extent's area as heuristic, and use the largest
              // area: this avoids
              // putting the point (label) in a corner of the
              // feature.
              // This _not_ perfect though, and we might have to
              // think about a better way.
              const extent = feature?.getGeometry()?.getExtent();
              if (extent === undefined) continue;
              const point = new Point(getCenter(extent));
              const extentArea = getArea(extent);

              const pointFeature = featurePointsSource.getFeatureById(
                id
              ) as Feature<Geometry>;
              const featureType = (feature as any)['type_'];
              if (
                (pointFeature &&
                  extentArea > pointFeature.get('_extentArea')) ||
                (pointFeature && featureType === 'Point')
              ) {
                pointFeature.setGeometry(point);
              } else {
                const newFeature = new Feature({
                  geometry: point,
                  _extentArea: extentArea,
                  ...feature.getProperties(),
                  wmsLayerId: wmsId,
                });
                newFeature.setId(id);
                featurePointsSource.addFeature(newFeature);
              }
            }
          } catch (e) {
            console.error(e);
            tile.setState(TileState.ERROR);
          }
        });
      } else {
        console.warn(
          `Failed to load tile from ${url}: ${response.status} ${response.statusText}`
        );
        tile.setState(TileState.ERROR);
      }
    });
  });
}

export function hydrateCustomAttributes(features: Feature<Geometry>[]) {
  for (const feature of features) {
    const properties = feature.getProperties();
    if (properties.morphology_custom_attribute_names) {
      const customAttributeNames =
        properties.morphology_custom_attribute_names.split(',');
      const customAttributeValues =
        properties.morphology_custom_attribute_values.split(',');
      for (let i = 0; i < customAttributeNames.length; i++) {
        const value = customAttributeValues[i];
        const numericValue = Number(value);
        properties[customAttributeNames[i]] = !isNaN(numericValue)
          ? numericValue
          : value;
      }

      delete properties.morphology_custom_attribute_names;
      delete properties.morphology_custom_attribute_values;
    }
    if (properties.geometry_custom_attribute_names) {
      const customAttributeNames =
        properties.geometry_custom_attribute_names.split(',');
      const customAttributeValues =
        properties.geometry_custom_attribute_values.split(',');
      for (let i = 0; i < customAttributeNames.length; i++) {
        const value = customAttributeValues[i];
        const numericValue = Number(value);
        properties[customAttributeNames[i]] = !isNaN(numericValue)
          ? numericValue
          : value;
      }
      delete properties.geometry_custom_attribute_names;
      delete properties.geometry_custom_attribute_values;
    }
  }
}
