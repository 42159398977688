import { LDFlagSet, LdFlags } from '@agerpoint/types';

export interface IAgerStoreLDSlice {
  featureFlags: LDFlagSet;
  setFeatureFlags?: (flags: LDFlagSet) => void;
  hasFeatureFlag: (flag: LdFlags) => boolean;
  getFeatureFlagValue: (flag: LdFlags) => boolean | number | string | undefined;
}

export const initialAgerStoreLDSlice: IAgerStoreLDSlice = {
  featureFlags: {},
  hasFeatureFlag: (_) => false,
  getFeatureFlagValue: (_) => undefined,
};
