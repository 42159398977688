import { createContext, useContext } from 'react';

import { IAgerStore } from './agerStore';
import { initialAgerStoreLDSlice } from './slices/ld-slice';
import { initialAgerStoreMobileSidebarsSlice } from './slices/sidebars-slice';
import { initialAgerStoreUserSlice } from './slices/user-slice';

export const AgerStore = createContext<IAgerStore>({
  launchDarkly: initialAgerStoreLDSlice,
  user: initialAgerStoreUserSlice,
  isMobile: false,
  mobileSidebars: initialAgerStoreMobileSidebarsSlice,
});

export const useAgerStore = () => useContext(AgerStore);
