import { NavLink } from 'react-router-dom';

import { TabProps } from '@agerpoint/types';

import PrimaryButton from '../primary-button/primary-button';

export const TabsComponent = ({ tabs }: { tabs: Array<TabProps> }) => {
  return (
    <div className="border-b border-gray-200 mb-4 mx-1 flex flex-row">
      <ul className="flex flex-wrap  flex-grow -mb-px text-sm font-medium text-center text-gray-500">
        {tabs.map((tab) => {
          if (tab.type === 'link') {
            return (
              <li className="mr-2" key={tab.label}>
                <NavLink
                  style={{ outline: 'none' }}
                  to={tab.link || '/'}
                  state={{
                    params: tab.params,
                  }}
                  className={({ isActive, isPending }) =>
                    `${
                      isActive ? 'text-green font-bold border-green' : ''
                    } inline-flex p-4 border-b-2 rounded-t-lg group`
                  }
                >
                  {tab.label}
                </NavLink>
              </li>
            );
          }
          return null;
        })}
      </ul>
      <ul
        className={`flex flex-wrap justify-end -mb-px text-sm font-medium text-center text-gray-500`}
      >
        {tabs.map((tab) => {
          if (tab.type === 'button') {
            return (
              <li className="mr-2 pt-2" key={tab.label}>
                <PrimaryButton
                  label={tab.label}
                  onClicked={() => {
                    tab?.buttonClickHandler?.();
                  }}
                  theme={tab?.buttonTheme || 'primary'}
                />
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};
