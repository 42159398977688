import OlMap from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import { useEffect } from 'react';

import { environment } from '@agerpoint/utilities';

import { ICloudOpenlayersBaseMap } from '../openlayers-map.types';
import {
  CloudOpenlayersMapLayerZIndex,
  cloudOpenlayersLayerCache,
} from '../openlayers-map.utilities';

interface IuseCloudOpenlayersBaseMapLayer {
  id: string;
  baseMap: ICloudOpenlayersBaseMap;
  map: React.MutableRefObject<OlMap | undefined>;
  mapInitialized: boolean;
}

export const useCloudOpenlayersBaseMapLayer = ({
  id,
  baseMap,
  map,
  mapInitialized,
}: IuseCloudOpenlayersBaseMapLayer) => {
  useEffect(() => {
    if (!mapInitialized) {
      return;
    }

    const key = `${id}-${baseMap}`;
    const cachedLayer = cloudOpenlayersLayerCache[key];
    const mapLayer = map.current
      ?.getLayers()
      .getArray()
      .find((l) => l.getProperties().name === key);

    if (mapLayer) {
      mapLayer.setVisible(true);
    } else if (cachedLayer) {
      cachedLayer.setVisible(true);
      map.current?.addLayer(cachedLayer);
    } else {
      const newLayer = new TileLayer({
        preload: Infinity,
        visible: true,
        source: new BingMaps({
          key: environment.bing_api_key,
          imagerySet: baseMap,
        }),
        properties: { name: key },
        zIndex: CloudOpenlayersMapLayerZIndex.baseMap,
      });

      map.current?.addLayer(newLayer);
      cloudOpenlayersLayerCache[key] = newLayer;
    }

    return () => {
      cloudOpenlayersLayerCache?.[key]?.setVisible(false);
    };
  }, [id, mapInitialized, baseMap]);
};
