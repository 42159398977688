import { faDownload, faSunPlantWilt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIModels, formatDateAndTime } from '@agerpoint/api';
import { Button, Input, QuestionInfo } from '@agerpoint/component';
import { ContextMenu, Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { CaptureJobTypes, LdFlags } from '@agerpoint/types';
import {
  createFilename,
  getVariation,
  useFormValidation,
  useGlobalStore,
  useToasts,
} from '@agerpoint/utilities';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../../subcomponents/page-states';
import { useAdminCapturesQueries } from '../admin-captures-queries';
import { AdminCapturesProcessingCard } from './admin-captures-processing-card';

export const AdminCapturesDetails3dModels = () => {
  const hdProcessing = `By default, captures are processed at a lower definition. Click the 'High Definition Preprocessing' button to reprocess this capture in High Definition`;
  const { captureId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const { permissions } = useGlobalStore();

  const {
    captureQuery,
    captureJobPostMutation,
    captureJobsQuery,
    availableMosaicEngines,
  } = useAdminCapturesQueries();

  const mosaicEngine = useMemo(() => {
    const pipelineTypeVariation = getVariation(
      LdFlags.HighDefinitionJobType,
      permissions
    );

    return availableMosaicEngines?.find(
      (engine) => engine.name === pipelineTypeVariation
    );
  }, [availableMosaicEngines, permissions]);

  const confirmTriggerHighRes = useCallback(() => {
    if (!mosaicEngine?.id) {
      return;
    }

    const confirm = window.confirm(
      `Are you sure you want to process this capture in high definition (${captureQuery.data?.fileSize} MB)?`
    );
    if (!confirm) {
      return;
    }

    captureJobPostMutation.mutate({
      id: Number(captureId),
      engineId: mosaicEngine.id,
      modelId: 'None' as unknown as number,
      data: {},
    });
  }, [mosaicEngine, captureQuery.data]);

  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);

  if (captureQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (captureQuery.isError) {
    return (
      <PageErrorState
        entityName="capture"
        pluralEntityName="captures"
        statusCode={captureQuery.error.response?.status ?? 500}
        tryAgainCallback={() => {
          captureQuery.refetch();
        }}
        tryAgainLoading={captureQuery.isFetching}
        navigateBackCallback={() => navigate('/admin/captures' + params)}
      />
    );
  }

  return (
    <div className="px-4 pb-4 flex flex-col w-full h-full">
      <div className="pb-2 w-48 flex justify-between text-sm font-bold">
        <span>Processing Options</span> <QuestionInfo text={hdProcessing} />
      </div>

      <div className="flex w-full pb-4 items-center justify-start flex-wrap">
        <AdminCapturesProcessingCard
          icon="high-definition"
          desc="Unlock unparalleled precision with our cutting-edge HD processing technology."
          title="3D Model"
          onSelect={confirmTriggerHighRes}
          buttonText={
            mosaicEngine ? 'Process Now' : 'No HD processing pipeline available'
          }
          disabled={!mosaicEngine}
        />
      </div>

      <div className="font-bold pb-2 text-sm" data-test-id="all-3d-models-div">
        All 3D Models
      </div>
      <div className="flex-grow" ref={setWrapperRef}>
        <Datatable
          data={captureJobsQuery.data ?? []}
          loading={captureJobsQuery.isLoading}
          style={{ ...dataTableAgerStyle, tableMinWidth: 1180 }}
          rowHeight={65}
          columns={[
            {
              label: 'Name',
              value: (row, index) => (
                <CaptureJobNameInput captureJob={row} index={index} />
              ),
              flex: 1.5,
              style: {
                expandBody: true,
              },
            },
            {
              label: 'Mosaic Engine',
              value: (row) => row.mosaicEngine,
              flex: 0.5,
            },
            {
              label: 'ML Model',
              value: (row) => row.mlModel,
              flex: 0.5,
            },
            {
              label: 'Created',
              value: (row) =>
                row.createDatetime
                  ? formatDateAndTime(row.createDatetime)
                  : null,
            },
            {
              label: 'Completed',
              value: (row) =>
                row.completedDateTime
                  ? formatDateAndTime(row.completedDateTime)
                  : null,
            },
            {
              label: 'Type',
              value: (row) => CaptureJobTypes[row?.captureJobTypeId ?? 0],
            },
            {
              label: 'Archived',
              value: (row, index) => (
                <CaptureJobArchiveInput captureJob={row} index={index} />
              ),
            },
            {
              label: null,
              flex: 0.25,
              value: (row) => (
                <CaptureJobActions captureJob={row} parentRef={wrapperRef} />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

interface CaptureJobNameInputProps {
  captureJob: APIModels.CaptureJob;
  index: number;
}

const CaptureJobNameInput = ({
  captureJob,
  index,
}: CaptureJobNameInputProps) => {
  const [name, setName] = useState(captureJob?.name ?? '');

  const { captureJobPutMutation } = useAdminCapturesQueries();

  const formValidation = useFormValidation();

  return (
    <div className="flex flex-row p-1 gap-1 items-start">
      <Input.Text.Single
        id={`capture-job-name-${index}`}
        value={name}
        setValue={setName}
        error={
          <Input.Error
            error={formValidation.errors[`capture-job-name-${index}`]}
          />
        }
        validation={{
          validationState: formValidation,
          validators: [Input.validators.required('Name')],
        }}
      />

      <Button.Primary
        id={`save-capture-job-name-button-${index}`}
        onClick={async () => {
          if (await formValidation.hasErrors()) {
            return;
          }

          
          captureJobPutMutation.mutate({
            captureId: captureJob.captureId as unknown as string,
            jobId: captureJob.id as number,
            data: {
              ...captureJob,
              name,
            },
          });
        }}
        label="Save"
        loading={captureJobPutMutation.isPending}
      />
    </div>
  );
};

interface CaptureJobActionsProps {
  captureJob: APIModels.CaptureJob;
  parentRef: HTMLDivElement | null;
}

const CaptureJobActions = ({
  captureJob,
  parentRef,
}: CaptureJobActionsProps) => {
  const toasts = useToasts();

  const GSPLAT_VIEWER_URL = 'https://gsplat.agerpoint.com/?load=';

  const { captureJobPlyUrlQuery, eptJsonQuery } = useAdminCapturesQueries(
    undefined,
    captureJob
  );

  const plyUrl = useMemo(
    () => captureJobPlyUrlQuery.data ?? undefined,
    [captureJobPlyUrlQuery.data]
  );

  const plyViewerUrl = useMemo(() => {
    if (!plyUrl) {
      return undefined;
    }

    return GSPLAT_VIEWER_URL + encodeURIComponent(encodeURIComponent(plyUrl));
  }, [plyUrl]);

  const downloadPly = useCallback(() => {
    if (!plyUrl) {
      return;
    }

    toasts.add({
      title: 'PLY File download started.',
      type: 'info',
    });
    const link = document.createElement('a');
    link.href = plyUrl;
    link.click();
  }, [plyUrl]);

  const openPlyViewer = useCallback(() => {
    if (!plyViewerUrl) {
      return;
    }

    const link = document.createElement('a');
    link.href = plyViewerUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  }, [plyViewerUrl]);

  const downloadEpt = useCallback(async () => {
    if (!eptJsonQuery.data) return;
    toasts.add({
      title: 'EPT File download started.',
      type: 'info',
    });
    const data =
      'text/json;charset=utf-8,' +
      encodeURIComponent(await eptJsonQuery.data.text());

    const a = document.createElement('a');
    a.href = 'data:' + data;
    a.download = createFilename('ept', 'json');
    a.click();
  }, [eptJsonQuery.data]);

  return (
    <ContextMenu
      parentRef={parentRef}
      customNoItemsMessage={
        captureJob?.archived
          ? 'No Actions Available for archived capture jobs'
          : undefined
      }
      groups={[
        {
          label: 'Download',
          items: [
            {
              label: 'EPT',
              icon: <FontAwesomeIcon icon={faDownload} />,
              visible:
                !(eptJsonQuery.data === undefined) && !captureJob?.archived,
              onClick: () => {
                downloadEpt();
              },
            },
            {
              label: 'PLY',
              icon: <FontAwesomeIcon icon={faDownload} />,
              visible: !(plyUrl === undefined) && !captureJob?.archived,
              onClick: () => {
                downloadPly();
              },
            },
          ],
        },
        {
          label: 'View',
          items: [
            {
              label: 'PLY',
              icon: <FontAwesomeIcon icon={faSunPlantWilt} />,
              visible: !(plyUrl === undefined) && !captureJob?.archived,
              onClick: () => {
                openPlyViewer();
              },
            },
          ],
        },
      ]}
    />
  );
};

interface CaptureJobArchiveInputProps {
  captureJob: APIModels.CaptureJob;
  index: number;
}

const CaptureJobArchiveInput = ({
  captureJob,
  index,
}: CaptureJobArchiveInputProps) => {
  const { captureJobPutMutation } = useAdminCapturesQueries();

  return (
    <div className="p-1">
      <Input.Select.Inline
        id={`capture-job-archive-${index}`}
        options={[true, false]}
        optionBuilder={(option) => (option ? 'Yes' : 'No')}
        value={captureJob?.archived ?? false}
        setValue={(archived) => {
          if (captureJobPutMutation.isPending) {
            return;
          }

          captureJobPutMutation.mutate({
            captureId: captureJob.captureId as unknown as string,
            jobId: captureJob.id as number,
            data: {
              ...captureJob,
              archived,
            },
          });
        }}
      />
    </div>
  );
};
