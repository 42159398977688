import { CaptureObject } from '@agerpoint/api';
import {
  EffectNames,
  GeomType,
  GlobalStore,
  IAnnotationsDispatcher,
  ILine3d,
  IMultiPoint2d,
  IMultiPoint3d,
  IPoint2d,
  IPoint3d,
} from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

import { getCoordinates } from '../utility/annotations-utility';

export class AnnotationsDispatcher implements IAnnotationsDispatcher {
  private store: GlobalStore;
  constructor() {
    this.store = useGlobalStore.getState();
  }

  createCapObj(
    geom: IPoint3d | ILine3d | IMultiPoint3d | IPoint2d | IMultiPoint2d,
    type: GeomType
  ) {
    const coordinates = getCoordinates(type, geom);
    const obj: CaptureObject = {
      name: geom.name,
      description: '',
      captureObjectTypeId: 4,
      geom2D: {
        type,
        coordinates,
      } as any,
      validated: true,
      captureId: 11667,
      captureObjectCustomAttributes: [
        {
          attributeName: 'color',
          attributeDisplayName: 'Color',
          attributeValue: geom.color,
          customAttributeUnitId: 0,
          validated: true,
        },
        {
          // _customType is used to differentiate between 3d annotations
          attributeName: '_customType',
          attributeDisplayName: 'Custom Type',
          attributeValue: geom.type,
          customAttributeUnitId: 0,
          validated: true,
        },
      ],
    };

    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECTS_LISTENER_CREATE,
      obj
    );
  }

  updateCapObjPosition(id: string, position: number[] | number[][]) {
    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_POSITION,
      { id, position }
    );
  }

  updateCapObjColor(id: string, color: string) {
    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_COLOR,
      { id, color }
    );
  }

  updateCapObjName(id: string, name: string) {
    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_NAME,
      { id, name }
    );
  }

  updateCapObjDescription(id: string, description: string) {
    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECTS_LISTENER_UPDATE_DESCRIPTION,
      { id, description }
    );
  }

  deleteCapObj(id: string) {
    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECTS_LISTENER_DELETE,
      id
    );
  }

  captureObjectWasClickedIn3D(id: string) {
    this.store.actions.dispatchEffect(
      EffectNames.CAPTURE_OBJECT_WAS_CLICKED,
      id
    );
  }
}
