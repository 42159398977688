import { IconName } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useRef, useState } from 'react';

import { useViteToasts } from '@agerpoint/utilities';

import { CloudInput } from '../input';
import { InputIdContext } from '../input-id-context';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface IInputFileSingle {
  /**
   * The unique identifier for the input element.
   */
  id: string;

  /**
   * The error message to display.
   */
  error?: string;

  /**
   * The label to display.
   */
  label?: string;

  /**
   * Whether the input element is required.
   */
  required?: boolean;

  /**
   * The placeholder text for the input element.
   */
  placeholder?: string;

  /**
   * The placeholder text to display when a file is dragged over the input element.
   */
  draggedOverPlaceholder?: string;

  /**
   * The leading icon for the input element.
   */
  leadingIcon?: IconName;

  /**
   * Determines whether the input element is highlighted.
   */
  highlighted?: boolean;

  /**
   * The current value of the input element.
   */
  value: File | undefined;

  /**
   * A callback function to update the value of the input element.
   */
  setValue: (value: File | undefined) => void;

  /**
   * A reference to the input element.
   */
  inputRef?: React.RefObject<HTMLInputElement>;

  /**
   * The file extensions to accept.
   */
  acceptExtensions?: string[];

  /**
   * The validation configuration for the input element.
   */
  validation?: InputValidation<File | undefined>;
}

const InputFileSingle = ({
  id,
  label,
  error,
  required,
  placeholder,
  draggedOverPlaceholder,
  value,
  setValue,
  acceptExtensions,
  validation,
  leadingIcon,
  highlighted = false,
}: IInputFileSingle) => {
  const [fileName, setFileName] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);

  const [draggedOver, setDraggedOver] = useState(false);

  const toasts = useViteToasts();

  useEffect(() => {
    setFileName(value?.name?.trim() ?? '');
    if (inputRef) {
      if (inputRef.current && value) {
        inputRef.current.value = '';
      }
    }
  }, [value]);

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full">
        <input
          ref={inputRef}
          type="file"
          accept={acceptExtensions
            ?.map((a) => (a.startsWith('.') ? a : `.${a}`))
            .join(', ')}
          className="hidden"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setValue(file);
            }
          }}
        />
        <div
          onDragEnter={(e) => {
            e.preventDefault();
            setDraggedOver(true);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setDraggedOver(false);
          }}
          onDrop={(e) => {
            e.preventDefault();
            setDraggedOver(false);

            const file = e.dataTransfer.files?.[0];

            if (!file) {
              return;
            }

            const extension = file.name.split('.').pop();

            if (acceptExtensions) {
              if (
                !extension ||
                !acceptExtensions?.includes(extension.toLowerCase())
              ) {
                toasts.add({
                  type: 'error',
                  title: 'File extension not supported!',
                  message: (
                    <div>
                      Supported extensions:
                      <ol className="list-disc pl-4">
                        {acceptExtensions.map((e) => (
                          <li key={e}>{`.${e}`}</li>
                        ))}
                      </ol>
                    </div>
                  ),
                });
                return;
              }
            }

            setValue(file);
          }}
        >
          <CloudInput.Text.Single
            id={id + '-filename-input'}
            readOnly
            value={fileName}
            error={error}
            label={label}
            highlighted={highlighted}
            required={required}
            placeholder={
              draggedOver ? draggedOverPlaceholder ?? placeholder : placeholder
            }
            setValue={(value) => {
              if (value === '') {
                setValue(undefined);
              }
            }}
            clearButton
            leadingIcon={leadingIcon}
            trailingIcon={draggedOver ? 'folder-arrow-down' : 'folder'}
            onClick={() => {
              inputRef.current?.click();
            }}
          />
        </div>
      </div>
    </InputIdContext.Provider>
  );
};

export { InputFileSingle };
