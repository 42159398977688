import { DependencyList, useEffect } from 'react';

import { useFormValidationState } from '@agerpoint/utilities';

export interface InputValidation<T> {
  validationState: useFormValidationState;
  validators: ((value: T) => Promise<string | undefined>)[];
  externalDependencies?: DependencyList;
}

interface IuseInputValidationIntegration<T> {
  id: string;
  value: T;
  validation?: InputValidation<T>;
}

export const useInputValidationIntegration = <T,>({
  id,
  value,
  validation,
}: IuseInputValidationIntegration<T>) => {
  useEffect(() => {
    if (validation) {
      validation.validationState.advancedCallbacks.subscribe({
        key: id,
        value,
        validators: validation.validators,
      });

      return () => {
        validation.validationState.advancedCallbacks.unsubscribe(id);
      };
    }

    return () => {
      //
    };
  }, [
    validation?.validators?.length,
    ...(validation?.externalDependencies ?? []),
  ]);

  useEffect(() => {
    validation?.validationState.advancedCallbacks.update(id, value);
  }, [value, ...(validation?.externalDependencies ?? [])]);
};
