import {
  faChevronLeft,
  faRightFromBracket,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mixpanel from 'mixpanel-browser';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  StandAloneViewerSidebarSection,
  SvgElement,
  ToggleableSidebar,
  ToggleableSidebarBottomItem,
} from '@agerpoint/component';
import { LdFlags, PointSizeType } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

import { StandAloneViewerCaptureSelector } from '../stand-alone-viewer-capture-selector/stand-alone-viewer-capture-selector';
import { StandAloneViewerPointBudgetSelector } from '../stand-alone-viewer-point-budget-selector/stand-alone-viewer-point-budget-selector';
import { StandAloneViewerPointSizeType } from '../stand-alone-viewer-point-size-type/stand-alone-viewer-point-size-type';
import { StandAloneViewerPointSize } from '../stand-alone-viewer-point-size/stand-alone-viewer-point-size';
import { StandAloneViewerPointStyle } from '../stand-alone-viewer-point-style/stand-alone-viewer-point-style';
import { StandAloneViewerSplats } from '../stand-alone-viewer-splat-quality/stand-alone-viewer-splat-quality';

export const StandAloneViewerSidebar = () => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const {
    standAloneViewer: {
      eptId,
      captureId,
      pointBudget,
      pointStyle,
      pointSizeType,
      pointSize,
      splatQuality,
      actions: { setPointSizeType },
    },
    permissions,
  } = useGlobalStore();
  const { userData, signOutRedirect } = useAuth();

  useEffect(() => {
    const key = permissions[LdFlags.PotreePointSizeType] as keyof PointSizeType;
    const keyIndex = Object.values(PointSizeType).indexOf(key);
    const ldPointSizeType = parseInt(Object.keys(PointSizeType)[keyIndex]);
    setPointSizeType(ldPointSizeType);
  }, [permissions]);

  return (
    <>
      <div
        className={`absolute top-0 mt-2 bg-white hover:bg-gray-200 border-t border-r border-b border-gray-200
cursor-pointer rounded-r-full pl-1 pr-2 py-1 transition-all ${
          sidebarExpanded ? 'ml-64' : 'ml-0'
        }`}
        style={{ zIndex: 100 }}
        onClick={() => {
          setSidebarExpanded((prev) => !prev);
        }}
      >
        <FontAwesomeIcon
          className={`transform transition-transform  ${
            sidebarExpanded ? '' : 'rotate-180'
          }`}
          icon={faChevronLeft}
        />
      </div>
      <ToggleableSidebar
        expanded={sidebarExpanded}
        items={
          <>
            <div className="pb-2 flex">
              <NavLink to="/admin" className="justify-end">
                <SvgElement
                  type="AgerpointLogo"
                  className="pl-4"
                  style={{ width: '10rem' }}
                />
              </NavLink>
            </div>
            <div className="flex flex-col gap-1">
              <StandAloneViewerSidebarSection
                title="Model"
                value={eptId && captureId ? `${captureId}/${eptId}` : undefined}
              >
                <StandAloneViewerCaptureSelector />
              </StandAloneViewerSidebarSection>
              <StandAloneViewerSidebarSection
                title="Point Budget"
                value={
                  pointBudget
                    ? `${pointBudget / 1000000} million(s)`
                    : undefined
                }
              >
                <StandAloneViewerPointBudgetSelector />
              </StandAloneViewerSidebarSection>

              <StandAloneViewerSidebarSection
                title="Classification"
                value={pointStyle}
              >
                <StandAloneViewerPointStyle />
              </StandAloneViewerSidebarSection>
              <StandAloneViewerSidebarSection
                title="Point Size Type"
                value={
                  pointSizeType !== undefined
                    ? PointSizeType[pointSizeType]
                    : ''
                }
              >
                <StandAloneViewerPointSizeType />
              </StandAloneViewerSidebarSection>
              <StandAloneViewerSidebarSection
                title="Point Size"
                value={pointSize}
              >
                <StandAloneViewerPointSize />
              </StandAloneViewerSidebarSection>
              <StandAloneViewerSidebarSection
                title="Splat Quality"
                value={splatQuality}
              >
                <StandAloneViewerSplats />
              </StandAloneViewerSidebarSection>
            </div>
          </>
        }
        bottomItems={
          <>
            <ToggleableSidebarBottomItem
              title="Sign Out"
              icon={faRightFromBracket}
              action={() => {
                mixpanel.reset();

                signOutRedirect({ id_token_hint: userData?.id_token });
              }}
            />
            <div className="text-xs text-gray-500 text-center flex flex-grow items-end justify-end">
              Version - {process.env.VERSION}
            </div>
          </>
        }
      />
    </>
  );
};
