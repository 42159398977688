import {
  faCircleNotch,
  faFilterSlash,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { usePageTitle, useQueryState } from '@agerpoint/utilities';

import { useAdminFarmsQueries } from './admin-farms-queries';

export const AdminFarmsList = () => {
  usePageTitle(() => 'Platform - Farms', []);

  const navigate = useNavigate();

  const [textFilter, setTextFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v,
    toUrlParam: (v) => v,
  });

  const { farmsQuery, organizationsLookupTable, regionsLookupTable } =
    useAdminFarmsQueries();

  const filteredFarms = useMemo(() => {
    let data = farmsQuery.data;
    if (data === null) {
      return;
    }

    const filter = textFilter.trim().toLowerCase();
    if (filter !== '') {
      data = data?.filter(
        (d) =>
          d.farmName?.toLowerCase().includes(filter) ||
          d.farmDisplayName?.toLowerCase().includes(filter)
      );
    }

    return data;
  }, [textFilter, farmsQuery]);

  const hasFiltersApplied = useMemo(
    () => !!((textFilter?.length ?? 0) > 0),
    [textFilter]
  );

  const clearFilters = useCallback(() => {
    setTextFilter('');
  }, []);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="px-4 py-2 flex flex-row gap-1 justify-between">
        <h1 className="text-3xl font-bold">Farms</h1>
        <Button.Primary
          id="farms-new-button"
          label="New Farm"
          icon={faPlus}
          onClick={() => {
            navigate('new', {
              state: {
                params: window.location.search,
              },
            });
          }}
        />
      </div>
      <div className="px-4 flex flex-row gap-2 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="farms-search-filter"
            setValue={setTextFilter}
            value={textFilter}
            placeholder="Search by Farm or Display Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 w-full h-full">
        <Datatable
          id="admin-farms-list-datatable"
          data={filteredFarms ?? []}
          style={{ ...dataTableAgerStyle, tableMinWidth: 650 }}
          loading={farmsQuery.isLoading || filteredFarms === undefined}
          cellOnClick={() => {
            return (row) => {
              navigate(`${row.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching farms',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No farms yet',
                  message: 'Create farm to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Farm',
                }
          }
          error={
            farmsQuery.isError
              ? {
                  title: 'There was a problem loading farms',
                  message: 'Try refreshing the page',
                  action: () => farmsQuery.refetch(),
                }
              : undefined
          }
          columns={[
            {
              label: 'Name',
              value: (row) => row.farmName,
              name: 'farmName',
            },
            {
              label: 'Display Name',
              value: (row) => row.farmDisplayName,
              name: 'farmDisplayName',
            },
            {
              label: 'Region',
              name: 'region',
              value: (row) => {
                if (!row.regionId) {
                  return null;
                }

                if (!regionsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const r = regionsLookupTable[row.regionId];

                return r.regionName ?? null;
              },
            },
            {
              label: 'Organization',
              name: 'organization',
              value: (row) => {
                if (!row.customerId) {
                  return null;
                }

                if (!organizationsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const o = organizationsLookupTable[row.customerId];

                return o?.customerDisplayName ?? o?.customerName ?? null;
              },
            },
          ]}
          rowHeight={50}
        />
      </div>
    </div>
  );
};
