import { colord } from 'colord';
import { useState, useEffect } from 'react';

/**
 * Converts a Hexadecimal formatted string to RGB.
 *
 * Returned object having `currentColor` and `colorWithAlpha` properties as rgb and
 * rgba formatted string, respectively.
 *
 * @param color hexadecimal string.
 * @param opacity numeric value between 0-1. 1 being opaque.
 * @returns `{ currentColor, setCurrentColor, colorWithAlpha }`
 */
export function useColorPickerState(color: string, opacity = 1) {
  // rgbValue is a rgb/rgba formatted string; ex: "rgb(162, 166, 199)" or "rgba(162, 166, 199, 0.2)"
  const rgbValue = colord(color).alpha(opacity).toRgbString();
  const [currentColor, setCurrentColor] = useState<string>(rgbValue);

  useEffect(() => {
    setCurrentColor(rgbValue);
  }, [rgbValue]);

  return { currentColor, setCurrentColor, colorWithAlpha: rgbValue };
}
