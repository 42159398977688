import { colord } from 'colord';
import {
  KeyboardEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import {
  Input,
  getGradientColors,
  getNumberDecimals,
} from '@agerpoint/component';
import { Bin, Gradient } from '@agerpoint/types';

import { SaveCheckmark } from '..';

interface Bins {
  bins: Bin[];
  gradient: Gradient;
  onBinsChange: (bins: Bin[]) => void;
}

export function Bins({ bins, gradient, onBinsChange }: Bins) {
  const [rowEdited, setRowEdited] = useState<number>();
  const binColors = getGradientColors(gradient, bins.length);
  const globalMax = bins[bins.length - 1].range[1];
  const decimals = getNumberDecimals(globalMax);

  return (
    <div className="w-full">
      {bins.map((bin, i) => {
        const backgroundColor = binColors[i];
        const borderColor = colord(backgroundColor).darken().toRgbString();

        return (
          <div
            key={i}
            className="flex items-center text-sm font-display-condensed"
          >
            <Input.Checkbox
              id={'bin-' + i + '-checkbox'}
              value={bin.visible}
              setValue={() => {
                const nextBins = [...bins];
                nextBins[i] = { ...bins[i], visible: !bin.visible };
                onBinsChange(nextBins);
              }}
              label={
                <div
                  className="w-4 h-4 rounded-sm border mr-1"
                  style={{
                    backgroundColor,
                    borderColor,
                  }}
                />
              }
            />
            <EditRange
              range={bin.range}
              decimals={decimals}
              disabled={rowEdited != null && rowEdited !== i}
              onEdit={() => setRowEdited(i)}
              onChange={([min, max]) => {
                const nextBins = adjustMax(i, adjustMin(i, bins, min), max);
                onBinsChange(nextBins);
                setRowEdited(undefined);
              }}
              onCancelEdit={() => setRowEdited(undefined)}
            />
          </div>
        );
      })}
    </div>
  );
}

interface EditRange {
  range: [number, number];
  decimals: number;
  disabled?: boolean;
  onEdit: () => void;
  onChange: (range: [number, number]) => void;
  onCancelEdit: () => void;
}

function EditRange({
  range,
  decimals,
  disabled,
  onEdit,
  onChange,
  onCancelEdit,
}: EditRange) {
  const [[min, max], setRange] = useState(range);
  const [isUnsaved, setUnsaved] = useState(false);
  useEffect(() => {
    setRange(range);
    setUnsaved(false);
  }, [range]);

  useEffect(() => {
    if (isUnsaved) {
      onEdit();
    }
  }, [onEdit, isUnsaved]);

  return (
    <>
      <NumberInput
        value={min}
        decimals={decimals}
        disabled={disabled}
        onChange={(v) => {
          setRange([v, max]);
          setUnsaved(true);
        }}
        onKeyDown={onKeyDown}
      />
      <div className="mx-1 text-center">-</div>
      <NumberInput
        value={max}
        decimals={decimals}
        disabled={disabled}
        onChange={(v) => {
          setRange([min, v]);
          setUnsaved(true);
        }}
        onKeyDown={onKeyDown}
      />
      {isUnsaved && (
        <div className="flex-grow justify-self-right">
          <SaveCheckmark isSaving={false} title="Save" onSave={onSave} />
        </div>
      )}
    </>
  );

  function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Escape') {
      onCancel();
    } else if (e.key === 'Enter') {
      onSave();
    }
  }

  function onSave() {
    const correctedMin = max < min && min === range[0] ? max : min;
    const correctedMax = max < min && max === range[1] ? min : max;

    onChange([
      Math.min(correctedMin, correctedMax),
      Math.max(correctedMin, correctedMax),
    ]);
    setUnsaved(false);
  }

  function onCancel() {
    setRange([...range]);
    setUnsaved(false);
    onCancelEdit();
  }
}

interface NumberInput {
  value: number;
  decimals: number;
  disabled?: boolean;
  onChange: (value: number) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

function NumberInput({
  value,
  decimals,
  disabled,
  onChange,
  onKeyDown,
}: NumberInput) {
  const [currentValue, setCurrentValue] = useState(value.toFixed(decimals));
  const isValid = isValidValue(currentValue);
  const lastValue = useRef(value);
  useLayoutEffect(() => {
    if (value !== lastValue.current && value !== Number(currentValue)) {
      setCurrentValue(value.toFixed(decimals));
    }
  }, [currentValue, value, decimals]);
  useEffect(() => {
    lastValue.current = value;
  }, [value]);

  return (
    <input
      type="text"
      value={currentValue}
      disabled={disabled}
      onChange={(e) => {
        const nextValue = e.target.value;
        setCurrentValue(nextValue);
        if (isValidValue(nextValue)) {
          onChange(Number(nextValue));
        }
      }}
      onKeyDown={onKeyDown}
      className={`
        m-1
        focus:ring-green
        focus:border-green
        block
        p-1
        w-10
        shadow-sm
        sm:text-sm
        border-gray-500
        rounded
        border-none
        text-right
        placeholder-gray ${
          !isValid
            ? 'border-red-300 focus:ring-red focus:border-red'
            : 'focus:ring-green focus:border-green focus:border'
        } ${disabled ? 'text-gray-400' : ''}
      `}
    />
  );

  function isValidValue(x: string): boolean {
    return !!x && !isNaN(Number(x));
  }
}

function adjustMin(index: number, bins: Bin[], value: number): Bin[] {
  const nextBins = [...bins];
  nextBins[index] = {
    ...nextBins[index],
    range: [value, nextBins[index].range[1]],
  };
  for (let i = index - 1; i >= 0; i--) {
    const newMax = nextBins[i + 1].range[0];
    if (newMax !== nextBins[i].range[1]) {
      const newMin = Math.min(newMax, nextBins[i].range[0]);
      nextBins[i] = { ...nextBins[i], range: [newMin, newMax] };
    } else {
      break;
    }
  }

  return nextBins;
}

function adjustMax(index: number, bins: Bin[], value: number): Bin[] {
  const nextBins = [...bins];
  nextBins[index] = {
    ...nextBins[index],
    range: [nextBins[index].range[0], value],
  };
  for (let i = index + 1; i < bins.length; i++) {
    const newMin = nextBins[i - 1].range[1];
    if (newMin !== nextBins[i].range[0]) {
      const newMax = Math.max(newMin, nextBins[i].range[1]);
      nextBins[i] = { ...nextBins[i], range: [newMin, newMax] };
    } else {
      break;
    }
  }

  return nextBins;
}
