import compare from 'trivial-compare';

import { Country } from '@agerpoint/api';

export const sortCountries = (data?: Country[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) =>
    compare(a.countryName?.toLowerCase(), b.countryName?.toLowerCase())
  );
  return sorted;
};
