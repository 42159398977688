import * as THREE from 'three';

import {
  CameraGeometry,
  ColorsThreeD,
  EventBusNames,
  GlobalStore,
  HexColor,
  IMarkerObj,
  LdFlags,
  MarkerClassNames2d,
  PointBudget,
  PointCloudSettings,
  PointSizeType,
} from '@agerpoint/types';

import { eventBus } from '../event-bus';
import { Potree } from '../potree-slim';

export const dragElement = (
  markerObj: IMarkerObj,
  camera: THREE.Camera,
  eventName: EventBusNames,
  eventId: string
) => {
  const elem = markerObj.element;

  elem.onmousedown = elemMouseDown;

  function elemMouseDown(e: MouseEvent) {
    if (!markerObj.editable) {
      return;
    }
    document.onmousemove = elemDrag;
    document.onmouseup = docMouseUp;
  }

  function docMouseUp(e: MouseEvent) {
    eventBus.dispatch(eventName, {
      detail: { pos: markerObj.position, id: eventId },
    });
    document.onmousemove = null;
    document.onmouseup = null;
  }

  function elemDrag(e: MouseEvent) {
    e.preventDefault();
    const pnt = pointFromEvent(e, camera);
    if (pnt?.point?.position) {
      markerObj.position.copy(pnt.point.position);
      markerObj.updatePosition();
    }
  }
};

export const pointFromEvent = (e: MouseEvent, camera: THREE.Camera) => {
  if (window.apv && window.apv.scene.pointclouds) {
    return Potree.Utils.getMousePointCloudIntersection(
      new THREE.Vector2(e.clientX, e.clientY),
      camera,
      window.apv,
      window.apv.scene.pointclouds
    );
  } else {
    console.warn('APV or pointclouds are not defined');
    return null; // or handle this case appropriately
  }
};

const _getCanvasRelativePosition = (
  event: MouseEvent,
  canvas: HTMLCanvasElement
) => {
  if (!canvas) return;
  const rect = canvas?.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
};

export const _setPickPosition = (
  event: MouseEvent,
  canvas: HTMLCanvasElement
) => {
  const pos = _getCanvasRelativePosition(event, canvas);
  if (!pos || !canvas) return;
  const pickPosition = { x: 0, y: 0 };
  pickPosition.x = (pos.x / canvas.clientWidth) * 2 - 1;
  pickPosition.y = (pos.y / canvas.clientHeight) * -2 + 1; // note we flip Y
  return pickPosition;
};

/**
 *
 * @deprecated use CustomMarker instead
 */
export const _create3dCustomMarker = (
  eventId: string,
  fill?: string,
  clickable?: boolean
) => {
  const size = 32;
  const xlmns = 'http://www.w3.org/2000/svg';
  const svg = document.createElementNS(xlmns, 'svg');
  svg.style.height = `${size}px`;
  svg.style.overflow = 'visible';
  svg.setAttributeNS(null, 'viewBox', '0 0 384 512');

  const path = document.createElementNS(xlmns, 'path');
  path.setAttribute(
    'd',
    `M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z`
  );
  path.style.fill = fill || '#339BC0';
  path.setAttribute('class', `${MarkerClassNames2d.CustomMarkerIcon} fill`);
  path.setAttribute('stroke', '#fff');
  path.setAttribute('stroke-width', '20');
  path.setAttribute('stroke-linejoin', 'arcs');

  svg.appendChild(path);
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.top = '-1000';
  div.style.left = '-1000';
  div.style.pointerEvents = clickable ? 'auto' : 'none';
  div.style.cursor = clickable ? 'pointer' : 'default';

  if (clickable) {
    div.addEventListener('mouseover', () => {
      div.style.cursor = 'pointer';
    });
    div.addEventListener('mouseout', () => {
      div.style.cursor = 'default';
    });
  }
  div.appendChild(svg);
  return div;
};

export const _create3dExtractionMarker = (
  fill?: string,
  clickable?: boolean
) => {
  const size = 32;
  const xlmns = 'http://www.w3.org/2000/svg';
  const svg = document.createElementNS(xlmns, 'svg');
  svg.style.height = `${size}px`;
  svg.style.overflow = 'visible';
  svg.setAttributeNS(null, 'viewBox', '0 0 384 512');

  const path = document.createElementNS(xlmns, 'path');
  path.setAttribute(
    'd',
    `M 32 144 a 144 144 0 1 1 288 0 A 144 144 0 1 1 32 144 z z M 144 480 V 317.1 c 10.4 1.9 21.1 2.9 32 2.9 s 21.6 -1 32 -2.9 V 480 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 z`
  );
  path.style.fill = fill || '#339BC0';
  path.setAttribute('class', 'fill');
  path.setAttribute('stroke', '#fff');
  path.setAttribute('stroke-width', '20');
  path.setAttribute('stroke-linejoin', 'arcs');

  svg.appendChild(path);
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.top = '-1000';
  div.style.left = '-1000';
  div.style.pointerEvents = 'none';
  div.style.pointerEvents = clickable ? 'auto' : 'none';
  div.style.cursor = clickable ? 'pointer' : 'default';

  div.appendChild(svg);
  return div;
};

export const _createTextLabel = (text = '') => {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.top = '-1000';
  div.style.left = '-1000';
  div.innerText = text;
  div.style.pointerEvents = 'none';
  div.style.color = 'white';
  div.style.fontSize = '16px';
  div.style.textShadow = 'black 0px 0px 2px';
  div.style.userSelect = 'none';
  return div;
};

export const _createCalloutLabel = (
  text = '',
  color: ColorsThreeD | HexColor,
  clickable = false
) => {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.top = '-1000';
  div.style.left = '-1000';
  // div.style.pointerEvents = 'none';
  div.style.color = 'white';
  div.style.fontSize = '14px';
  div.style.userSelect = 'none';
  div.style.height = '20px';
  div.style.backgroundColor = color;
  div.style.borderRadius = '2px';

  div.style.display = 'flex';
  div.style.justifyContent = 'center';
  div.style.alignItems = 'center';

  const span = document.createElement('span');
  span.innerText = text;
  span.style.display = 'block';
  span.style.padding = '0 5px';
  span.style.textWrap = 'nowrap';
  div.appendChild(span);

  return div;
};

export const getViewerSettings = (
  permissions: GlobalStore['permissions']
): PointCloudSettings => {
  const defaultSettings = {
    pointSize: 2.5,
    pointSizeType: PointSizeType.FIXED,
    pointBudget: PointBudget.FOUR,
    useHQ: false,
    imageGalleryAutoScroll: false,
    showDebugTools: false,
  };
  const overrideSettings: {
    pointSize?: number;
    pointSizeType?: number;
    pointBudget?: number;
    useHQ?: boolean;
    imageGalleryAutoScroll?: boolean;
    showDebugTools?: boolean;
  } = {};

  if (permissions[LdFlags.PotreePointSize]) {
    overrideSettings['pointSize'] = permissions[
      LdFlags.PotreePointSize
    ] as number;
  }

  if (permissions[LdFlags.PotreePointSizeType]) {
    const key = permissions[LdFlags.PotreePointSizeType] as keyof PointSizeType;
    const keyIndex = Object.values(PointSizeType).indexOf(key);
    overrideSettings['pointSizeType'] = parseInt(
      Object.keys(PointSizeType)[keyIndex]
    );
  }

  if (permissions[LdFlags.PotreePointBudget]) {
    const key = permissions[LdFlags.PotreePointBudget] as keyof PointBudget;
    const keyIndex = Object.values(PointBudget).indexOf(key);
    overrideSettings['pointBudget'] = parseInt(
      Object.keys(PointBudget)[keyIndex]
    );
  }

  if (permissions[LdFlags.PotreeUseHighQuality]) {
    overrideSettings['useHQ'] = permissions[
      LdFlags.PotreeUseHighQuality
    ] as boolean;
  }

  if (permissions[LdFlags.PotreeAutoScrollGallery]) {
    overrideSettings['imageGalleryAutoScroll'] = permissions[
      LdFlags.PotreeAutoScrollGallery
    ] as boolean;
  }

  if (permissions[LdFlags.Debug3dFeatures]) {
    overrideSettings['showDebugTools'] = permissions[
      LdFlags.Debug3dFeatures
    ] as boolean;
  }

  return {
    ...defaultSettings,
    ...overrideSettings,
  };
};

export const threeDMidpoint = (
  vector1: THREE.Vector3,
  vector2: THREE.Vector3
) => {
  const midpoint = new THREE.Vector3();

  midpoint.addVectors(vector1, vector2).divideScalar(2);
  return midpoint;
};

export const pointInPolygon = function (polygon: number[][], point: number[]) {
  //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
  let odd = false;
  //For each edge (In this case for each point of the polygon and the previous one)
  for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
    //If a line from the point into infinity crosses this edge
    if (
      polygon[i][1] > point[1] !== polygon[j][1] > point[1] &&
      // One point needs to be above, one below our y coordinate
      // and the edge doesn't cross our Y corrdinate before our x coordinate
      // (but between our x coordinate and infinity)
      point[0] <
        ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) /
          (polygon[j][1] - polygon[i][1]) +
          polygon[i][0]
    ) {
      // Invert odd
      odd = !odd;
    }
    j = i;
  }
  //If the number of crossings was odd, the point is in the polygon
  return odd;
};

// worldToLocalCoordinates(object: any, worldPoints: any) {
//   var matrixWorldInverse = new THREE.Matrix4();
//   matrixWorldInverse.getInverse(object.matrixWorld);

//   var localPoints = [];
//   for (var i = 0; i < worldPoints.length; i++) {
//     var worldPosition = new THREE.Vector3().fromArray(worldPoints[i]);
//     var localPosition = worldPosition.applyMatrix4(matrixWorldInverse);
//     localPoints.push(localPosition);
//   }

//   return localPoints;
// }

export const getPointsInPlane = (
  poly: number[][],
  points: CameraGeometry[],
  cameraP: THREE.PerspectiveCamera
) => {
  if (!poly) return;
  return points.filter((point) => {
    const pos = new THREE.Vector3(point.x, point.y, point.z);
    const vector = pos.project(cameraP);
    vector.x = ((pos.x + 1) / 2) * window.innerWidth;
    vector.y = (-(pos.y - 1) / 2) * window.innerHeight;
    return pointInPolygon(poly, [vector.x, vector.y]);
  });
};

export function isOutsideFrustum(position: THREE.Vector3, frustumPlanes: any) {
  for (let i = 0; i < 6; i++) {
    if (frustumPlanes[i].distanceToPoint(position) < 0) {
      return true; // The position is outside the frustum
    }
  }
  return false; // The position is inside the frustum
}
