import OlMap from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { useCallback } from 'react';

import { ICloudOpenlayersWMSLayer } from '../openlayers-map.types';
import { CloudOpenlayersMapLayerZIndex } from '../openlayers-map.utilities';

interface IuseCloudOpenlayerWMSLayer {
  map: React.MutableRefObject<OlMap | undefined>;
  mapInitialized: boolean;
  layersInitialized: boolean;
}

export const useCloudOpenlayerWMSLayer = ({
  map,
  mapInitialized,
  layersInitialized,
}: IuseCloudOpenlayerWMSLayer) => {
  const addWMSLayer = useCallback(
    (layer: ICloudOpenlayersWMSLayer) => {
      if (!mapInitialized) {
        return;
      }

      const olTileWMS = new TileWMS(layer);

      const olTileLayer = new TileLayer({
        extent: layer.extent,
        source: olTileWMS,
        properties: { id: layer.wmsLayerId },
        zIndex: CloudOpenlayersMapLayerZIndex.feature,
      });

      map.current?.addLayer(olTileLayer);
    },
    [mapInitialized]
  );

  const updateWMSLayer = useCallback(
    (layer: ICloudOpenlayersWMSLayer) => {
      if (!layersInitialized) {
        return;
      }
      const olMapLayer = map.current
        ?.getLayers()
        .getArray()
        .find(
          (l) => l.getProperties().id === layer.wmsLayerId
        ) as TileLayer<TileWMS>;

      if (!olMapLayer) {
        return;
      }

      const olSource = olMapLayer.getSource();

      olSource?.setUrl(layer.url ?? '');
    },
    [layersInitialized]
  );

  const removeWMSLayer = useCallback(
    (layer: ICloudOpenlayersWMSLayer) => {
      if (!mapInitialized) {
        return;
      }

      const mapLayer = map.current
        ?.getLayers()
        .getArray()
        .find((l) => l.getProperties().id === layer.wmsLayerId);

      if (mapLayer) {
        mapLayer.dispose();
        map.current?.removeLayer(mapLayer);
      }
    },
    [mapInitialized]
  );

  return { addWMSLayer, updateWMSLayer, removeWMSLayer };
};
