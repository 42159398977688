import { useCallback } from 'react';

import { NewToastProps } from '@agerpoint/types';

import { useGlobalStore } from '../store/store';
import { useIsViteApp } from '../useIsViteApp';
import { ToastType, useViteToasts } from './useViteToasts';

export const useToasts = () => {
  const {
    actions: { addToast },
  } = useGlobalStore();

  const isViteApp = useIsViteApp();

  const viteToasts = useViteToasts();

  const viteToastConnector = useCallback(
    (newToast: NewToastProps) => {
      viteToasts.add({
        title: newToast.title,
        message: newToast.message,
        type: newToast.type as ToastType,
      });
    },
    [viteToasts]
  );

  const prepareEntityCreatedToast = useCallback(
    (entityName: string): NewToastProps => ({
      title: `Your ${entityName}${
        entityName.endsWith('s') ? ' have' : ' has'
      } been created!`,
      type: 'success',
    }),
    []
  );

  const prepareEntityUpdatedToast = useCallback(
    (entityName: string, message?: string): NewToastProps => ({
      title: `Successfully updated your ${entityName}!`,
      type: 'success',
      message,
    }),
    []
  );

  const prepareErrorToast = useCallback(
    (message: string): NewToastProps => ({
      title: `Error`,
      type: 'error',
      message,
    }),
    []
  );

  const prepareEntityMissingToast = useCallback(
    (entityName: string): NewToastProps => ({
      title: `Your ${entityName}${
        entityName.endsWith('s') ? ' are' : ' is'
      } missing!`,
      type: 'error',
    }),
    []
  );

  const prepareEntityArchivedToast = useCallback(
    (entityName: string): NewToastProps => ({
      title: `Your ${entityName}${
        entityName.endsWith('s') ? ' have' : ' has'
      } been archived!`,
      type: 'success',
    }),
    []
  );

  const prepareEntityRestoredToast = useCallback(
    (entityName: string): NewToastProps => ({
      title: `Your ${entityName}${
        entityName.endsWith('s') ? ' have' : ' has'
      } been restored!`,
      type: 'success',
    }),
    []
  );

  const prepareEntityClonedToast = useCallback(
    (entityName: string): NewToastProps => ({
      title: `Your ${entityName}${
        entityName.endsWith('s') ? ' have' : ' has'
      } been cloned!`,
      type: 'success',
    }),
    []
  );

  const prepareValueCopiedToast = useCallback(
    (label: string): NewToastProps => ({
      title: 'Copied',
      message: (
        <>
          Copied <b>{label}</b> to clipboard
        </>
      ),
      type: 'info',
      tags: ['copy'],
    }),
    []
  );

  const prepareEntityValidatedToast = useCallback(
    (entityName: string): NewToastProps => ({
      title: `Your ${entityName}${
        entityName.endsWith('s') ? ' have' : ' has'
      } been validated!`,
      type: 'success',
    }),
    []
  );

  const prepareEntityInvalidatedToast = useCallback(
    (entityName: string): NewToastProps => ({
      title: `Your ${entityName}${
        entityName.endsWith('s') ? ' have' : ' has'
      } been invalidated!`,
      type: 'success',
    }),
    []
  );

  return {
    add: isViteApp ? viteToastConnector : addToast,
    prepare: {
      entityCreated: prepareEntityCreatedToast,
      entityUpdated: prepareEntityUpdatedToast,
      entityMissing: prepareEntityMissingToast,
      entityArchived: prepareEntityArchivedToast,
      entityRestored: prepareEntityRestoredToast,
      entityCloned: prepareEntityClonedToast,
      entityValidated: prepareEntityValidatedToast,
      entityInvalidated: prepareEntityInvalidatedToast,
      valueCopied: prepareValueCopiedToast,
      error: prepareErrorToast,
    },
  };
};
