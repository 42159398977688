import { Group, GroupIcon } from '@agerpoint/types';
import { SidebarExpansionHeaderEdit } from '../sidebar-expansion-header-edit/sidebar-expansion-header-edit';
import {
  ExpansionDelete,
  ExpansionToolbar,
  ZoomToExtent,
} from '../expansion-toolbar/expansion-toolbar';
import {
  ExpansionContainerOuter,
  ExpansionContainerInner,
} from '@agerpoint/component';
import { Extent } from 'ol/extent';
import { GroupIconSelector } from '.';

interface GroupExpansionProps {
  onCancel: () => void;
  group: Group;
  updateGroupName?: (id: number, name: string) => Promise<void>;
  updateGroupIcon: (id: number, icon: GroupIcon) => Promise<void>;
  deleteGroup?: (id: number) => Promise<void>;
  setDesiredExtent: (extent: Extent) => void;
}

export function GroupExpansion({
  onCancel,
  group,
  updateGroupName,
  updateGroupIcon,
  deleteGroup,
  setDesiredExtent,
}: GroupExpansionProps) {
  const updateGroup = async (groupName: string) => {
    if (updateGroupName && group.name !== groupName) {
      await updateGroupName(group.id, groupName);
    }
  };

  const updateGroupIconObject = async (icon: GroupIcon) => {
    if (!shallowEqual(group.icon, icon)) {
      await updateGroupIcon(group.id, icon);
    }
  };

  return (
    <div className="h-full relative">
      <SidebarExpansionHeaderEdit
        onSave={updateGroup}
        onCancel={onCancel}
        name={group.name}
      />
      <ExpansionContainerOuter>
        <GroupIconSelector
          icon={group.icon}
          onChange={(icon) => updateGroupIconObject(icon)}
        />
        <ExpansionContainerInner>
          <ExpansionToolbar>
            {({ showConfirm, isConfirming }) => (
              <>
                {group.extent && (
                  <ZoomToExtent
                    onZoom={() =>
                      group.extent && setDesiredExtent(group.extent)
                    }
                  />
                )}
                {deleteGroup && (
                  <ExpansionDelete
                    showConfirm={showConfirm}
                    onDelete={() => deleteGroup(group.id).finally(onCancel)}
                    message="Are you sure you want to delete this group & all associated layers?"
                    deleteLabel="Delete group"
                    isConfirming={isConfirming}
                  />
                )}
              </>
            )}
          </ExpansionToolbar>
        </ExpansionContainerInner>
      </ExpansionContainerOuter>
    </div>
  );

  function shallowEqual(object1: GroupIcon, object2: GroupIcon): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key as keyof GroupIcon] !== object2[key as keyof GroupIcon]) {
        return false;
      }
    }
    return true;
  }
}
