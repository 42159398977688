/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CaptureObjectTag } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureObjectTag = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObjectTag[]>(
    { url: `/api/CaptureObjectTags`, method: 'GET', signal },
    options
  );
};

export const getGetCaptureObjectTagQueryKey = () => {
  return [`/api/CaptureObjectTags`] as const;
};

export const getGetCaptureObjectTagQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectTag>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureObjectTag>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureObjectTagQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectTag>>
  > = ({ signal }) => getCaptureObjectTag(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectTag>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectTagQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectTag>>
>;
export type GetCaptureObjectTagQueryError = ErrorType<void>;

export const useGetCaptureObjectTag = <
  TData = Awaited<ReturnType<typeof getCaptureObjectTag>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCaptureObjectTag>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectTagQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCaptureObjectTag = (
  captureObjectTag: BodyType<CaptureObjectTag>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObjectTag>(
    {
      url: `/api/CaptureObjectTags`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureObjectTag,
    },
    options
  );
};

export const getPostCaptureObjectTagMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureObjectTag>>,
    TError,
    { data: BodyType<CaptureObjectTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureObjectTag>>,
  TError,
  { data: BodyType<CaptureObjectTag> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureObjectTag>>,
    { data: BodyType<CaptureObjectTag> }
  > = (props) => {
    const { data } = props ?? {};

    return postCaptureObjectTag(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureObjectTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureObjectTag>>
>;
export type PostCaptureObjectTagMutationBody = BodyType<CaptureObjectTag>;
export type PostCaptureObjectTagMutationError = ErrorType<void>;

export const usePostCaptureObjectTag = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureObjectTag>>,
    TError,
    { data: BodyType<CaptureObjectTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureObjectTag>>,
  TError,
  { data: BodyType<CaptureObjectTag> },
  TContext
> => {
  const mutationOptions = getPostCaptureObjectTagMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureObjectTagById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureObjectTag>(
    {
      url: `/api/CaptureObjectTags/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureObjectTagByIdQueryKey = (id: number) => {
  return [`/api/CaptureObjectTags/${id}`] as const;
};

export const getGetCaptureObjectTagByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureObjectTagById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectTagById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureObjectTagByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureObjectTagById>>
  > = ({ signal }) => getCaptureObjectTagById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureObjectTagById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureObjectTagByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureObjectTagById>>
>;
export type GetCaptureObjectTagByIdQueryError = ErrorType<void>;

export const useGetCaptureObjectTagById = <
  TData = Awaited<ReturnType<typeof getCaptureObjectTagById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureObjectTagById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureObjectTagByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureObjectTagById = (
  id: number,
  captureObjectTag: BodyType<CaptureObjectTag>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObjectTag>(
    {
      url: `/api/CaptureObjectTags/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureObjectTag,
    },
    options
  );
};

export const getPutCaptureObjectTagByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureObjectTagById>>,
    TError,
    { id: number; data: BodyType<CaptureObjectTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureObjectTagById>>,
  TError,
  { id: number; data: BodyType<CaptureObjectTag> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureObjectTagById>>,
    { id: number; data: BodyType<CaptureObjectTag> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureObjectTagById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureObjectTagByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureObjectTagById>>
>;
export type PutCaptureObjectTagByIdMutationBody = BodyType<CaptureObjectTag>;
export type PutCaptureObjectTagByIdMutationError = ErrorType<void>;

export const usePutCaptureObjectTagById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureObjectTagById>>,
    TError,
    { id: number; data: BodyType<CaptureObjectTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureObjectTagById>>,
  TError,
  { id: number; data: BodyType<CaptureObjectTag> },
  TContext
> => {
  const mutationOptions = getPutCaptureObjectTagByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCaptureObjectTagById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObjectTag>(
    {
      url: `/api/CaptureObjectTags/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCaptureObjectTagByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureObjectTagById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCaptureObjectTagById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCaptureObjectTagById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCaptureObjectTagById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCaptureObjectTagByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCaptureObjectTagById>>
>;

export type DeleteCaptureObjectTagByIdMutationError = ErrorType<void>;

export const useDeleteCaptureObjectTagById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureObjectTagById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCaptureObjectTagById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteCaptureObjectTagByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
