import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels, formatDateAndTime } from '@agerpoint/api';
import { Button, IconTypes, Input, SvgElement } from '@agerpoint/component';
import { availableAnalyticsIcons } from '@agerpoint/types';
import {
  convertNameToCamelCase,
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminAnalyticsQueries } from './admin-analytics-queries';

export const AdminAnalyticsDetails = () => {
  usePageTitle(() => 'Platform - Analytics', []);

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const formValidation = useFormValidation();

  const {
    analyticPutMutation,
    analyticQuery,
    analyticArchivePutMutation,
    analyticUnarchivePutMutation,
  } = useAdminAnalyticsQueries();

  const [analytic, setAnalytic] = useState<APIModels.Analytic>();

  useEffect(() => {
    setAnalytic(analyticQuery.data);
  }, [analyticQuery.data]);

  const updateAnalytic = useCallback(async () => {
    if (analyticPutMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    analyticPutMutation.mutate({
      id: analytic?.id as number,
      data: analytic as APIModels.Analytic,
    });
  }, [formValidation, analytic, analyticPutMutation]);

  const archiveAnalytic = useCallback(async () => {
    if (analyticArchivePutMutation.isPending) {
      return;
    }

    const confirm = window.confirm(
      'Are you sure you want to archive this analytic?'
    );
    if (!confirm) {
      return;
    }

    analyticArchivePutMutation.mutate({
      id: analyticQuery.data?.id as number,
      data: { ...analyticQuery.data, archived: true },
    });
  }, [analyticQuery.data, analyticArchivePutMutation]);

  const unarchiveAnalytic = useCallback(async () => {
    if (analyticUnarchivePutMutation.isPending) {
      return;
    }

    const confirm = window.confirm(
      'Are you sure you want to restore this analytic?'
    );
    if (!confirm) {
      return;
    }

    analyticUnarchivePutMutation.mutate({
      id: analyticQuery.data?.id as number,
      data: { ...analyticQuery.data, archived: false },
    });
  }, [analyticQuery.data, analyticUnarchivePutMutation]);

  if (analyticQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (analyticQuery.isError) {
    return (
      <PageErrorState
        entityName="analytic"
        pluralEntityName="analytics"
        statusCode={analyticQuery.error.response?.status ?? 500}
        tryAgainCallback={() => analyticQuery.refetch()}
        tryAgainLoading={analyticQuery.isFetching}
        navigateBackCallback={() =>
          navigate(
            isViteApp
              ? '/app/admin/platform/analytics' + params
              : '/admin/analytics' + params
          )
        }
      />
    );
  }

  return (
    <div className="flex flex-col h-full w-full">
      <div className="px-4 pb-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="analytic-name-input"
          value={analytic?.analyticName || ''}
          setValue={(analyticName) => {
            setAnalytic({ ...analytic, analyticName });
          }}
          label={<Input.Label label="Name" required />}
          error={
            <Input.Error error={formValidation.errors['analytic-name-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
        <Input.Text.Single
          id="analytic-desc-input"
          value={analytic?.analyticDescription || ''}
          setValue={(analyticDescription) => {
            setAnalytic({ ...analytic, analyticDescription });
          }}
          label={<Input.Label label="Description" />}
        />
        <Input.Number.Integer
          id="analytic-cost-input"
          value={analytic?.dc}
          setValue={(dc) => {
            setAnalytic({
              ...analytic,
              dc,
            });
          }}
          label={<Input.Label label="Default Cost" required />}
          error={
            <Input.Error error={formValidation.errors['analytic-cost-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Default Cost')],
          }}
          onlyPositive
        />
        <Input.Select.Single
          id="analytic-icon-input"
          options={availableAnalyticsIcons}
          title="Icon"
          optionBuilder={(i) => ({
            searchString: i ?? '',
            element: (
              <div
                className="flex flex-row gap-2 items-center"
                style={{
                  height: '20px',
                }}
              >
                <SvgElement
                  className="h-full"
                  type={convertNameToCamelCase(`fa-${i}`) as IconTypes}
                />
                <span>{i}</span>
              </div>
            ),
          })}
          value={analytic?.icon}
          setValue={(icon) => {
            setAnalytic({
              ...analytic,
              icon,
            });
          }}
          label={<Input.Label label="Icon" />}
        />
        <div className="w-full flex justify-end py-4 gap-2">
          {analytic?.archived ? (
            <Button.Secondary
              id="ml-model-details-restore-button"
              label={'Restore'}
              onClick={unarchiveAnalytic}
              loading={analyticUnarchivePutMutation.isPending}
            />
          ) : (
            <Button.Danger
              id="ml-model-details-archive-button"
              label={'Archive'}
              onClick={archiveAnalytic}
              loading={analyticArchivePutMutation.isPending}
            />
          )}
          <Button.Primary
            id="save-analytic-button"
            label="Save"
            onClick={updateAnalytic}
            disabled={analyticPutMutation.isPending || analyticQuery.isLoading}
          />
        </div>
        <EntityDetailsSection
          items={[
            {
              label: 'ID',
              value: analytic?.id,
            },
            {
              label: 'Created',
              value: formatDateAndTime(analytic?.createDatetime),
            },
            {
              label: 'Last Updated',
              value: formatDateAndTime(analytic?.updateDatetime),
            },
            {
              label: 'Updated By UUID',
              value: analytic?.updatedById,
            },
          ]}
        />
      </div>
    </div>
  );
};
