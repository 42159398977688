import { IconDefinition, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export interface ToggleableSidebarProps {
  expanded: boolean;
  items: ReactNode;
  bottomItems?: ReactNode;
  paddingTop?: number;
}

export const ToggleableSidebar = ({
  expanded,
  items,
  bottomItems,
  paddingTop,
}: ToggleableSidebarProps) => {
  return (
    <aside
      id="logo-sidebar"
      className={`fixed top-0 left-0 w-64 h-screen transition-transform transform bg-white border-r border-gray-200 print:hidden ${
        expanded ? '' : '-translate-x-full'
      }`}
      aria-label="Sidebar"
      style={{ zIndex: 99, paddingTop: `${paddingTop}px` }}
    >
      <div className="h-full overflow-y-hidden bg-white flex flex-col justify-between">
        <div className="overflow-y-auto p-3">{items}</div>
        {bottomItems && (
          <div className="border-t border-gray-200 w-full p-3 flex flex-row flex-wrap">
            {bottomItems}
          </div>
        )}
      </div>
    </aside>
  );
};

export interface ToggleableSidebarContentProps {
  expanded: boolean;
  children?: ReactNode;
}

export const ToggleableSidebarContent = ({
  expanded,
  children,
}: ToggleableSidebarContentProps) => {
  return (
    <div
      className={`${
        expanded ? 'pl-64' : ''
      } relative h-full w-full flex flex-col overflow-hidden pt-12 print:pl-0`}
      style={{
        transitionProperty: 'padding',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '150ms',
      }}
    >
      {children}
    </div>
  );
};

export interface ToggleableSidebarGroupProps {
  name: string | React.ReactElement;
  children: React.ReactElement<ToggleableSidebarGroupItemProps>[];
  initiallyExpanded?: boolean;
  route?: string;
  cascadeLevel?: number;
}

export const ToggleableSidebarGroup = ({
  name,
  children,
  initiallyExpanded,
  route,
  cascadeLevel,
}: ToggleableSidebarGroupProps) => {
  const location = useLocation();
  const subroutes = useMemo(
    () => children.map((child) => child.props.route),
    [children]
  );
  const [expanded, setExpanded] = useState(
    subroutes.includes(location.pathname) || initiallyExpanded
  );
  const highlighted =
    subroutes.some((subroute) => location.pathname.startsWith(subroute)) ||
    (route && location.pathname.startsWith(route));
  return (
    <>
      <div
        onClick={() => setExpanded(!expanded)}
        className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 justify-between cursor-pointer ${
          highlighted ? 'bg-gray-100' : ''
        }`}
        style={{
          paddingLeft: cascadeLevel ? `${cascadeLevel * 24}px` : undefined,
        }}
      >
        <span>{name}</span>
        <div className="w-4 flex flex-row justify-center items-center">
          <FontAwesomeIcon
            icon={faCaretDown}
            className={`text-xl text-gray-500 transform transition-transform ${
              !expanded ? '' : 'rotate-180'
            }`}
          />
        </div>
      </div>
      <div
        className={`overflow-hidden transition-all`}
        style={{ maxHeight: expanded ? '1000px' : '0px' }}
      >
        {children}
      </div>
    </>
  );
};

export interface ToggleableSidebarGroupItemProps {
  name: string;
  route: string;
  cascadeLevel?: number;
}

export const ToggleableSidebarGroupItem = ({
  name,
  route,
  cascadeLevel,
}: ToggleableSidebarGroupItemProps) => {
  const location = useLocation();
  const highlighted = location.pathname.startsWith(route);
  return (
    <NavLink
      end
      to={route}
      className={({ isActive }) =>
        `flex items-center p-2 px-4 pl-6 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 justify-between ${
          highlighted ? 'bg-gray-100' : ''
        }` + (isActive ? ' pointer-events-none' : '')
      }
      style={{
        paddingLeft: cascadeLevel ? `${cascadeLevel * 20}px` : undefined,
      }}
    >
      <span>{name}</span>
    </NavLink>
  );
};

export interface ToggleableSidebarItemProps {
  name: string;
  route: string;
  icon?: IconDefinition;
}

export const ToggleableSidebarItem = ({
  name,
  route,
  icon,
}: ToggleableSidebarItemProps) => {
  const location = useLocation();
  const highlighted = location.pathname.startsWith(route);
  return (
    <NavLink
      end
      to={route}
      className={({ isActive }) =>
        `flex items-center p-2 px-4 pl-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 justify-between ${
          highlighted ? 'bg-gray-100' : ''
        }` + (isActive ? ' pointer-events-none' : '')
      }
    >
      <span>{name}</span>
      {icon && (
        <div className="w-4 flex flex-row justify-center items-center">
          <FontAwesomeIcon
            icon={icon}
            className={`text-gray-500 transform transition-transform`}
          />
        </div>
      )}
    </NavLink>
  );
};

interface ToggleableSidebarBottomItemBaseProps {
  icon: IconDefinition;
  title: string;
}

interface ToggleableSidebarBottomItemNavProps
  extends ToggleableSidebarBottomItemBaseProps {
  route: string;
}

interface ToggleableSidebarBottomItemActionProps
  extends ToggleableSidebarBottomItemBaseProps {
  action: () => void;
}

export type ToggleableSidebarBottomItemProps =
  | ToggleableSidebarBottomItemNavProps
  | ToggleableSidebarBottomItemActionProps;

function isNavBottomItem(
  item: ToggleableSidebarBottomItemProps
): item is ToggleableSidebarBottomItemNavProps {
  return 'route' in item;
}

function isActionBottomItem(
  item: ToggleableSidebarBottomItemProps
): item is ToggleableSidebarBottomItemActionProps {
  return 'action' in item;
}

export const ToggleableSidebarBottomItem = (
  props: ToggleableSidebarBottomItemProps
) => {
  const location = useLocation();
  const highlighted = isNavBottomItem(props)
    ? location.pathname.startsWith(props.route)
    : false;
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className="w-1/5 cursor-pointer"
      style={{ aspectRatio: '1 / 1' }}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
    >
      <div className="h-full w-full">
        <div className="p-0.5 h-full w-full relative">
          <div
            className={`w-full h-full transform transition-transform ${
              hovered ? '-translate-y-2' : ''
            }`}
          >
            <ButtonItem
              highlighted={highlighted}
              hovered={hovered}
              props={props}
            >
              <div className="flex flex-row justify-center items-center">
                <FontAwesomeIcon icon={props.icon} className="text-gray-500" />
              </div>
            </ButtonItem>
          </div>
          <div
            className={`absolute bottom-0 z-30 px-1 h-4 shadow transition-opacity left-1/2 transform -translate-x-1/2
          text-xs flex items-center justify-center bg-white rounded-lg text-gray-500 truncate pointer-events-none ${
            hovered ? 'opacity-100' : 'opacity-0'
          }`}
          >
            {props.title}
          </div>
        </div>
      </div>
    </div>
  );
};

const ButtonItem = ({
  children,
  highlighted,
  hovered,
  props,
}: {
  children: ReactNode;
  highlighted: boolean;
  hovered: boolean;
  props: ToggleableSidebarBottomItemProps;
}) => {
  if (isNavBottomItem(props)) {
    return (
      <NavLink
        end
        to={props.route}
        className={() => `transition-colors flex items-center w-full h-full border border-gray-500 text-xl justify-center rounded-full ${
          highlighted ? 'bg-gray-200' : ''
        }
        ${hovered ? 'bg-gray-100' : 'bg-transparent'}
         `}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <button
      type="button"
      onClick={props.action}
      className={`transition-colors flex items-center w-full h-full border border-gray-500 text-xl justify-center rounded-full ${
        highlighted ? 'bg-gray-200' : ''
      }
      ${hovered ? 'bg-gray-100' : 'bg-transparent'}`}
    >
      {children}
    </button>
  );
};
