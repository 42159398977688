import {
  faCircle,
  faList,
  faLocationDot,
  faShareNodes,
  faSquare,
  faVectorPolygon,
} from '@fortawesome/pro-light-svg-icons';
import { Feature } from 'ol';
import OlMap from 'ol/Map';
import { Coordinate } from 'ol/coordinate';
import { GeoJSON } from 'ol/format';
import { Circle, Polygon, SimpleGeometry } from 'ol/geom';
import Geometry, { Type } from 'ol/geom/Geometry';
import { fromCircle } from 'ol/geom/Polygon';
import { Draw } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { getArea, getLength } from 'ol/sphere';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import { hasPermission, useGlobalStore } from '@agerpoint/utilities';

const MEASUREMENT_LAYER_NAME = 'Measurements';

export const TwoDTools = ({ map }: { map: OlMap | undefined }) => {
  const vectorLayerMeasurementsRef = useRef<
    VectorLayer<VectorSource<Feature<Geometry>>>
  >(
    new VectorLayer({
      source: new VectorSource<Feature<Geometry>>(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      }),
      properties: {
        name: MEASUREMENT_LAYER_NAME,
        layer: MEASUREMENT_LAYER_NAME,
      },
    })
  );
  const pendingFeature = useRef<Feature<Geometry> | null>(null);
  const { permissions } = useGlobalStore();

  const [hasMeasurement2dPermission, setHasMeasurement2dPermission] =
    useState(false);
  const [interactionInprogress, setInteractionInProgress] = useState(false);
  const [cancelableInteraction, setCancelableInteraction] = useState(false);
  const [interactionType, setInteractionType] = useState<
    Type | 'Square' | null
  >();

  useEffect(() => {
    const hasPerm = hasPermission(LdFlags.Measurements2D, permissions);
    setHasMeasurement2dPermission(hasPerm);
  }, [permissions]);

  useEffect(() => {
    if (!map) return;
    map.addLayer(vectorLayerMeasurementsRef.current);
    map.on('singleclick', (e) => {
      map.forEachFeatureAtPixel(e.pixel, function (feature) {
        const name = feature.get('name');
        if (name === MEASUREMENT_LAYER_NAME) {
          console.log('This feature was drawn by a user.');
        } else {
          console.log('This feature was added from another source.');
        }
      });
    });
    return () => {
      if (!map) return;
      map.removeLayer(vectorLayerMeasurementsRef.current);
      map.getInteractions().forEach((interaction) => {
        if (interaction instanceof Draw) {
          map.removeInteraction(interaction);
        }
      });
    };
  }, [map]);

  const startInteraction = useCallback(
    (type: Type | 'Square') => {
      if (!map || interactionInprogress) {
        return;
      }
      setInteractionInProgress(true);
      setInteractionType(type);
      setCancelableInteraction(true);
      const layers = map.getLayers().getArray();
      const vectorLayer = layers.find((layer) => {
        return layer.getProperties().name === MEASUREMENT_LAYER_NAME;
      }) as VectorLayer<VectorSource<Feature<Geometry>>>;
      if (!vectorLayer) {
        return;
      }
      const src = vectorLayer.getSource();
      if (!src) {
        return;
      }
      let drawInteraction;
      if (type === 'Square') {
        drawInteraction = new Draw({
          source: src,
          type: 'Circle',
          // @ts-expect-error - Overriding the geometry function to draw a square instead of a circle
          geometryFunction: (
            coordinates: Coordinate[],
            geometry?: SimpleGeometry
          ): Polygon => {
            if (!geometry) {
              geometry = new Polygon([]); // Initialize with an empty array if no geometry is provided
            }
            const center = coordinates[0];
            const end = coordinates[1];
            const dx = end[0] - center[0];
            const dy = end[1] - center[1];
            const radius = Math.sqrt(dx * dx + dy * dy);
            const squareCoordinates = [
              [center[0] - radius, center[1] - radius],
              [center[0] + radius, center[1] - radius],
              [center[0] + radius, center[1] + radius],
              [center[0] - radius, center[1] + radius],
              [center[0] - radius, center[1] - radius], // Close the linear ring
            ];
            geometry.setCoordinates([squareCoordinates]);
            return geometry as Polygon;
          },
        });
      } else {
        drawInteraction = new Draw({
          source: src,
          type,
        });
      }

      drawInteraction.on('drawstart', (e) => {
        const feature = e.feature;
        pendingFeature.current = feature;
      });

      drawInteraction.on('drawend', (e) => {
        const geojsonFormat = new GeoJSON();

        const feature = e.feature;
        feature.set('name', MEASUREMENT_LAYER_NAME);
        let geometry = feature.getGeometry();
        const type = geometry?.getType();
        if (!type || !geometry) return;

        if (geometry.getType() === 'Circle') {
          // Convert CircleGeometry to Polygon
          geometry = fromCircle(geometry as Circle); // Correct cast
        }

        const geometryGeoJSON = geojsonFormat.writeGeometry(geometry);

        // Calculating measurements based on geometry type
        if (geometry.getType() === 'LineString') {
          const length = getLength(geometry); // returns length in meters
          console.log('Length of LineString:', length, 'meters');
        } else if (geometry.getType() === 'Polygon') {
          const area = getArea(geometry); // returns area in square meters
          console.log('Area of Polygon:', area, 'square meters');
        }

        console.log('Geometry in GeoJSON:', geometryGeoJSON, 'Type:', type);
        // send to db
        // refetch
        if (['Point', 'Circle', 'Polygon'].includes(type)) {
          setInteractionInProgress(false);
          drawInteraction.finishDrawing();
          map.removeInteraction(drawInteraction);
        }
      });

      map.addInteraction(drawInteraction);
    },
    [map, interactionInprogress]
  );

  const finishDrawing = (e: any) => {
    if (!map || !interactionInprogress) {
      return;
    }

    console.log('Finish Drawing', e);

    map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Draw) {
        interaction.finishDrawing();
        map.removeInteraction(interaction);
      }
    });
    setInteractionInProgress(false);
    setInteractionType(null);
    setCancelableInteraction(false);
  };

  const cancelDrawing = () => {
    if (!map || !interactionInprogress) {
      return;
    }
    if (pendingFeature.current) {
      const layers = map.getLayers().getArray();
      const vectorLayer = layers.find((layer) => {
        return layer.getProperties().name === MEASUREMENT_LAYER_NAME;
      }) as VectorLayer<VectorSource<Feature<Geometry>>>;
      if (!vectorLayer) {
        return;
      }
      const src = vectorLayer.getSource();

      if (src) {
        src.removeFeature(pendingFeature.current);
      }
      pendingFeature.current = null;
    }

    map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Draw) {
        map.removeInteraction(interaction);
      }
    });
    setInteractionInProgress(false);
    setInteractionType(null);
    setCancelableInteraction(false);
  };

  return hasMeasurement2dPermission ? (
    <div className="absolute top-0 bottom-0 right-0 z-30 m-0 flex text-black pointer-events-none">
      <div className="flex flex-col justify-center w-8 mr-4">
        <div className="bg-white flex flex-col items-center rounded py-2 pointer-events-auto relative gap-2">
          <div className="relative" onMouseDown={(e) => e.stopPropagation()}>
            <Button.Icon
              id="point-2d-marker"
              icon={faLocationDot}
              onClick={(e) => {
                e.stopPropagation();
                startInteraction('Point');
              }}
              title="Point"
              disabled={interactionInprogress}
            />
            <div className="h-1 w-full"></div>

            <Button.Icon
              id="line-3d-marker"
              icon={faShareNodes}
              onClick={(e) => {
                e.stopPropagation();
                startInteraction('LineString');
              }}
              title="Line"
              disabled={interactionInprogress}
            />
            <div className="h-1 w-full"></div>

            <Button.Icon
              id="polygon-3d-marker"
              icon={faVectorPolygon}
              onClick={() => {
                startInteraction('Polygon');
              }}
              title="Polygon"
              disabled={interactionInprogress}
            />
            <div className="h-1 w-full"></div>

            {/* Circle */}
            <Button.Icon
              id="circle-3d-marker"
              icon={faCircle}
              onClick={() => {
                startInteraction('Circle');
              }}
              title="Circle"
              disabled={interactionInprogress}
            />
            <div className="h-1 w-full"></div>

            {/* Square */}
            <Button.Icon
              id="square-3d-marker"
              icon={faSquare}
              onClick={() => {
                startInteraction('Square');
              }}
              title="Square"
              disabled={interactionInprogress}
            />
            <div className="h-1 w-full"></div>

            {/* Annotation List */}

            <Button.Icon
              id="annotation-list"
              icon={faList}
              onClick={() => {
                console.log('Annotation List');
              }}
              title="Annotations List"
              disabled={interactionInprogress}
            />
            {['LineString', 'Polygon'].includes(interactionType || '') && (
              <div className="absolute -bottom-36 right-2 ">
                <button
                  type="button"
                  data-dial-toggle="speed-dial-menu-default"
                  aria-controls="speed-dial-menu-default"
                  aria-expanded="false"
                  className={`flex items-center justify-center text-white bg-green rounded-full w-14 h-14 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 focus:outline-none`}
                  onClick={finishDrawing}
                >
                  <svg
                    className="w-5 h-5 transition-transform group-hover:rotate-45"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2 9 l6 6 l10 -10"
                    />
                  </svg>
                  <span className="sr-only">Save new geometry</span>
                </button>
              </div>
            )}
            {interactionInprogress && cancelableInteraction && (
              <div className="absolute -bottom-20 right-2 ">
                <button
                  type="button"
                  data-dial-toggle="speed-dial-menu-default"
                  aria-controls="speed-dial-menu-default"
                  aria-expanded="false"
                  className={`flex items-center justify-center text-white bg-red rounded-full w-14 h-14 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 focus:outline-none`}
                  onClick={cancelDrawing}
                >
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2 2 l14 14 M2 16 L16 2"
                    />
                  </svg>
                  <span className="sr-only">Cancel new geometry</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
