import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';

import { APIClient, APIUtils } from '@agerpoint/api';
import { Sort } from '@agerpoint/utilities';

import { CapturesViewerContext } from '../../captures-viewer/captures-viewer-context';
import { QAQCCaptureAttributeRow } from './qaqc-capture-attribute-row';

export const QaqcCaptureAttributesList = () => {
  const { selectedCaptureExtractionJobId } = useContext(CapturesViewerContext);

  const captureCustomAttributeQuery =
    APIClient.useGetCaptureCustomAttributesByCaptureExtractionJobId(
      Number(selectedCaptureExtractionJobId),
      {
        query: {
          queryKey: [
            APIUtils.QueryKey.extractionJobs,
            { extractionJobId: Number(selectedCaptureExtractionJobId) },
            APIUtils.QueryKey.customAttributes,
          ],
          enabled: Number.isSafeInteger(Number(selectedCaptureExtractionJobId)),
          staleTime: 0,
          retry: 0,
          select: (data) =>
            data.sort((a, b) => Sort.sortByAtt(a, b, 'attributeName')),
        },
      }
    );

  return (
    <div className="w-full h-full overflow-auto bg-gray-700 rounded text-xs">
      {captureCustomAttributeQuery.isLoading && (
        <div className="w-full h-full flex justify-center items-center">
          <FontAwesomeIcon icon={faCircleNotch} spin className="w-8 h-8" />
        </div>
      )}
      {!captureCustomAttributeQuery.isLoading &&
        (captureCustomAttributeQuery.data?.length ?? 0) === 0 && (
          <div className="w-full h-full flex justify-center items-center">
            <div className="text-sm">No Capture Attributes</div>
          </div>
        )}
      {!captureCustomAttributeQuery.isLoading &&
      (captureCustomAttributeQuery.data?.length ?? 0) ? (
        <div className="flex flex-col w-full h-full p-1.5 gap-1.5">
          {captureCustomAttributeQuery.data?.map((object, key) => (
            <QAQCCaptureAttributeRow key={object.id} attribute={object} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
