import { ArrowIcon } from '../icons';

interface GroupToggleProps {
  isOpen: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export function GroupToggle({ isOpen, onClick }: GroupToggleProps) {
  return (
    <div
      className={`p-2 text-gray transform ${
        isOpen ? '-rotate-90' : 'rotate-90'
      }`}
      onClick={onClick}
    >
      <ArrowIcon />
    </div>
  );
}
