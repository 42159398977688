import Konva from 'konva';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const Annotation2DPolygon = ({
  setCoordinates,
}: {
  setCoordinates: (coords: number[]) => void;
}) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return ready ? (
    <Annotation2DPolygonComponent setCoordinates={setCoordinates} />
  ) : null;
};

export const Annotation2DPolygonComponent = ({
  setCoordinates,
}: {
  setCoordinates: (coords: number[]) => void;
}) => {
  const debounceRef = useRef<any>();
  const callSetCoords = useCallback(
    (debounceRef.current = debounce((val) => {
      setCoordinates(val);
    }, 400)),
    [setCoordinates, debounceRef]
  );

  const stage = useMemo(
    () =>
      new Konva.Stage({
        container: 'konva-root',
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    []
  );

  const layer = new Konva.Layer();

  const circles = useMemo(
    () => [
      new Konva.Circle({
        x: window.innerWidth / 2 - 50,
        y: window.innerHeight / 2 - 50,
        radius: 3,
        stroke: '#ff0000',
        strokeWidth: 1,
        draggable: true,
      }),
      new Konva.Circle({
        x: window.innerWidth / 2 + 50,
        y: window.innerHeight / 2 - 50,
        radius: 3,
        stroke: '#ff0000',
        strokeWidth: 1,
        draggable: true,
      }),
      new Konva.Circle({
        x: window.innerWidth / 2 + 50,
        y: window.innerHeight / 2 + 50,
        radius: 3,
        stroke: '#ff0000',
        strokeWidth: 1,
        draggable: true,
      }),
      new Konva.Circle({
        x: window.innerWidth / 2 - 50,
        y: window.innerHeight / 2 + 50,
        radius: 3,
        stroke: '#ff0000',
        strokeWidth: 1,
        draggable: true,
      }),
    ],
    []
  );

  const circlesToPoints = useCallback((circles: Konva.Circle[]): number[] => {
    return circles
      .map((circle) => [circle.attrs.x, circle.attrs.y])
      .reduce((prev, current) => prev.concat(current));
  }, []);

  const polygon = useMemo(
    () =>
      new Konva.Line({
        points: circlesToPoints(circles),
        fill: '#ff000088',
        stroke: '#ff0000',
        strokeWidth: 1,
        draggable: false,
        closed: true,
        dash: [],
      }),
    [circles]
  );

  useEffect(() => {
    setCoordinates(circlesToPoints(circles));

    return () => {
      debounceRef.current.cancel();

      stage.destroy();
    };
  }, [debounceRef]);

  useEffect(() => {
    const addEventToCircle = (circle: Konva.Circle) => {
      circle.on('dragmove', (e) => {
        const pnts = circlesToPoints(circles);
        callSetCoords(pnts);
        polygon.points(pnts);
        layer.draw();
      });
      circle.on('mouseover', (e) => {
        circle.radius(10);
        circle.fill('#ffffff');
        layer.draw();
      });
      circle.on('mouseout', () => {
        circle.radius(5);
        circle.fill('#ff000088');
        layer.draw();
      });
    };

    circles.map((circle) => addEventToCircle(circle));

    polygon.on('click', (e) => {
      const mousePos = stage.getPointerPosition();
      if (!mousePos) return;
      const x = mousePos.x;
      const y = mousePos.y;
      const points = polygon.attrs.points;
      for (let i = 0; i < points.length / 2; i++) {
        const s_x = points[i * 2];
        const s_y = points[i * 2 + 1];
        const e_x = points[(i * 2 + 2) % points.length];
        const e_y = points[(i * 2 + 3) % points.length];

        // adding new vertex
        if (
          ((s_x <= x && x <= e_x) || (e_x <= x && x <= s_x)) &&
          ((s_y <= y && y <= e_y) || (e_y <= y && y <= s_y))
        ) {
          const point = new Konva.Circle({
            x: x,
            y: y,
            radius: 3,
            stroke: '#ff0000',
            strokeWidth: 1,
            draggable: true,
          });
          addEventToCircle(point);
          circles.splice(i + 1, 0, point);
          polygon.points(circlesToPoints(circles));
          layer.add(point);
          layer.draw();
          break;
        }
      }
    });
    layer.add(...circles, polygon);
    stage.add(layer);
    layer.draw();
  }, []);

  return null;
};
