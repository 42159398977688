import { Navigate } from 'react-router-dom';

import { LDFlagSet, LDFlagValue, LdFlags } from '@agerpoint/types';

import { useGlobalStore } from '../store/store';

export const hasPermission = (
  permissionToCheck: LdFlags,
  ldPermissions: LDFlagSet
): boolean => {
  if (!ldPermissions) return false;
  return ldPermissions[permissionToCheck] === true;
};

export const HasPermissionRouteGuard = ({
  permissionToCheck,
  children,
}: {
  permissionToCheck: LdFlags;
  children: JSX.Element;
}) => {
  const { permissions } = useGlobalStore();
  const isAllowed = hasPermission(permissionToCheck, permissions);

  return isAllowed ? children : <Navigate to="/" replace={true} />;
};

export const getVariation = (
  flag: string,
  ldPermissions: LDFlagSet
): LDFlagValue => {
  if (!ldPermissions || !flag) return undefined;
  return ldPermissions[flag];
};
