import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';

interface ButtonThinProps {
  id: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  label?: string;
  icon?: IconDefinition;
  disabled?: boolean;
  iconColor?: string;
  underlineLabel?: boolean;
}

export const ButtonThin = ({
  id,
  onClick,
  underlineLabel,
  label,
  icon,
  disabled,
  iconColor,
}: ButtonThinProps) => {
  const className = useMemo(() => {
    let classes = `border-gray-500 border transition-all rounded p-1 ring-gray-500 duration-200 text-gray-900 bg-white
      focus:outline-none outline-none focus:ring-2 ring-offset-1 flex flex-row items-center justify-center text-sm
      ${underlineLabel ? 'underline' : ''}`;

    if (disabled) {
      classes += ' cursor-not-allowed opacity-50 text-disabled ';
    } else {
      classes += ' text-gray-900 hover:border-green ';
    }

    return classes.trim();
  }, [disabled, underlineLabel]);

  return (
    <button
      data-test-id={id}
      type="button"
      onClick={onClick}
      disabled={disabled}
      style={{
        height: '38px',
        minHeight: '38px',
      }}
      className={className}
    >
      <div className="flex flex-row gap-1 items-center px-2">
        {label && <span>{label}</span>}
        {icon && (
          <div className="w-4 h-4 flex items-center justify-center">
            <FontAwesomeIcon
              icon={icon}
              className={`w-full h-full ${iconColor}`.trim()}
            />
          </div>
        )}
      </div>
    </button>
  );
};
