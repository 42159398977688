import {
  faBan,
  faCheck,
  faExclamation,
  faHourglassStart,
  faQuestion,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';

import {
  AnalyticRequestStatus,
  analyticRequestStatusLookup,
} from '@agerpoint/types';

export const getColorByStatus = (
  status: AnalyticRequestStatus | undefined | null
) => {
  switch (status) {
    case AnalyticRequestStatus.COMPLETE:
      return analyticRequestStatusLookup[AnalyticRequestStatus.COMPLETE].color;
    case AnalyticRequestStatus.FAILED:
      return analyticRequestStatusLookup[AnalyticRequestStatus.FAILED].color;
    case AnalyticRequestStatus.PROCESSING:
      return analyticRequestStatusLookup[AnalyticRequestStatus.PROCESSING]
        .color;
    case AnalyticRequestStatus.CANCELLED:
      return analyticRequestStatusLookup[AnalyticRequestStatus.CANCELLED].color;
    case AnalyticRequestStatus.REQUESTED:
      return analyticRequestStatusLookup[AnalyticRequestStatus.REQUESTED].color;
    default:
      return '';
  }
};

export const getIconByStatus = (
  status: AnalyticRequestStatus | undefined | null
) => {
  switch (status) {
    case AnalyticRequestStatus.COMPLETE:
      return faCheck;
    case AnalyticRequestStatus.FAILED:
      return faExclamation;
    case AnalyticRequestStatus.PROCESSING:
      return faSpinnerThird;
    case AnalyticRequestStatus.CANCELLED:
      return faBan;
    case AnalyticRequestStatus.REQUESTED:
      return faHourglassStart;
    default:
      return;
  }
};
