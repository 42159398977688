import { AnimatePresence, MotionStyle, motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useAgerStore } from '@agerpoint/utilities';

import { TooltipInfo } from './tooltip-info';

interface ITooltip {
  childRef: React.RefObject<HTMLElement>;
  children?: React.ReactNode;
  position: 'top' | 'bottom' | 'left' | 'right';
  maxWidth?: string;
  breakAll?: boolean;
}

const Tooltip = ({
  childRef,
  children,
  position,
  maxWidth = 'max-w-28',
  breakAll = false,
}: ITooltip) => {
  const openTimeout = useRef<NodeJS.Timeout>();

  const [show, setShow] = useState(false);
  const { isMobile } = useAgerStore();

  const [style, setStyle] = useState<MotionStyle>({});
  const calculatePosition = useCallback(() => {
    if (!childRef.current) {
      setStyle({});
      return undefined;
    }

    const rect = childRef.current.getBoundingClientRect();
    if (position === 'top') {
      setStyle({
        bottom: window.innerHeight - rect.top + 6,
        left: rect.left + rect.width / 2,
        translateX: '-50%',
      });
      return;
    } else if (position === 'bottom') {
      setStyle({
        top: rect.bottom + 6,
        left: rect.left + rect.width / 2,
        translateX: '-50%',
      });
      return;
    } else if (position === 'left') {
      setStyle({
        right: window.innerWidth - rect.left + 6,
        top: rect.top + rect.height / 2,
        translateY: '-50%',
      });
      return;
    } else if (position === 'right') {
      setStyle({
        left: rect.right + 4,
        top: rect.top + rect.height / 6,
        translateY: '-50%',
      });
      return;
    }
    setStyle({});
  }, [childRef, position]);

  const onMouseEnter = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    openTimeout.current = setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  const onMouseLeave = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    clearTimeout(openTimeout.current);
    setShow(false);
  }, []);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    window.addEventListener('resize', calculatePosition);

    const c = childRef.current;
    c?.addEventListener('mouseenter', onMouseEnter);
    c?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      window.removeEventListener('resize', calculatePosition);
      c?.removeEventListener('mouseenter', onMouseEnter);
      c?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [calculatePosition, childRef, isMobile, onMouseEnter, onMouseLeave]);

  useEffect(() => {
    if (show) {
      calculatePosition();
    }
  }, [show, calculatePosition]);

  return createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          className={`absolute z-tooltip text-white bg-black bg-opacity-60
            text-xs px-1 py-0.5 rounded text-center ${maxWidth} ${
            breakAll ? 'break-all' : ''
          }`}
          style={style}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById('apc-context-menus') as HTMLElement
  );
};

Tooltip.Info = TooltipInfo;

export { Tooltip };
