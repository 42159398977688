import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIModels, formatDateAndTime } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { getUsername } from '@agerpoint/feature';
import { getCaptureModeNiceName } from '@agerpoint/types';
import { useFormValidation } from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../../subcomponents/page-states';
import { useAdminCapturesQueries } from '../admin-captures-queries';

export const AdminCapturesDetails = () => {
  const formValidation = useFormValidation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const { captureId } = useParams();

  const {
    captureQuery,
    captureProjectsQuery,
    organizationsQuery,
    usersLookupTable,
    capturePutMutation,
    usersQuery,
    projectsQuery,
  } = useAdminCapturesQueries();

  const [capture, setCapture] = useState<APIModels.Capture>();
  const [selectedOrganization, setSelectedOrganization] =
    useState<APIModels.Customer>();
  const [selectedProjects, setSelectedProjects] = useState<APIModels.Project[]>(
    []
  );

  useEffect(() => {
    setCapture(captureQuery.data);
    setSelectedOrganization(
      organizationsQuery.data?.find(
        (o) => o.id === captureQuery.data?.customerId
      )
    );
    setSelectedProjects([...(captureProjectsQuery.data ?? [])]);
  }, [captureQuery.data, organizationsQuery.data, captureProjectsQuery.data]);

  useEffect(() => {
    setCapture((prev) => ({ ...prev, customerId: selectedOrganization?.id }));
  }, [selectedOrganization]);

  const createdByValue = useMemo(() => {
    if (!captureQuery.data?.createdById) {
      return '-';
    }
    if (!usersLookupTable) {
      return '-';
    }

    const user = usersLookupTable[captureQuery.data.createdById];

    return getUsername(user);
  }, [captureQuery.data, usersLookupTable]);

  const lastJobCompleted = useMemo(() => {
    if (!captureQuery.data?.completedJobs?.[0]) {
      return undefined;
    }

    return captureQuery.data.completedJobs[0];
  }, [captureQuery.data]);

  const saveCapture = useCallback(async () => {
    if (capturePutMutation.isPending || captureQuery.isLoading) {
      return;
    }
    if (await formValidation.hasErrors()) {
      return;
    }

    

    const toAssign = selectedProjects.filter((p) => {
      return !captureProjectsQuery.data?.find((cp) => cp.id === p.id);
    });

    // List of projects that is present in captureProjectsQuery.data but isn't in selectedProjects
    const toUnassign =
      captureProjectsQuery.data?.filter((cp) => {
        return !selectedProjects.find((p) => p.id === cp.id);
      }) ?? [];

    capturePutMutation.mutate({
      id: Number(captureId),
      data: capture as APIModels.Capture,
      projectsToAssign: toAssign,
      projectsToUnassign: toUnassign,
    });
  }, [
    capture,
    capturePutMutation,
    captureQuery.isLoading,
    formValidation,
    captureId,
    captureProjectsQuery.data,
    selectedProjects,
  ]);

  if (captureQuery.isLoading || usersQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (captureQuery.isError) {
    return (
      <PageErrorState
        entityName="capture"
        pluralEntityName="captures"
        statusCode={captureQuery.error.response?.status ?? 500}
        tryAgainCallback={() => {
          captureQuery.refetch();
          usersQuery.refetch();
          projectsQuery.refetch();
          organizationsQuery.refetch();
        }}
        tryAgainLoading={
          captureQuery.isFetching ||
          usersQuery.isFetching ||
          projectsQuery.isFetching ||
          organizationsQuery.isFetching
        }
        navigateBackCallback={() => navigate('/admin/captures' + params)}
      />
    );
  }

  return (
    <div className="px-4 pb-4 w-full flex flex-col max-w-lg gap-2">
      <Input.Text.Single
        id="capture-name-input"
        label={<Input.Label label="Name" required />}
        value={capture?.captureName ?? ''}
        setValue={(captureName) => {
          setCapture((prev) => ({ ...prev, captureName }));
        }}
        error={
          <Input.Error error={formValidation.errors['capture-name-input']} />
        }
        validation={{
          validationState: formValidation,
          validators: [Input.validators.required('Name')],
        }}
      />
      <Input.Select.Single
        id="capture-organization-input"
        label={<Input.Label label="Organization" />}
        options={organizationsQuery.data ?? []}
        optionBuilder={(o) =>
          o.customerDisplayName ?? o.customerName ?? 'Unknown'
        }
        title="Organization"
        loading={organizationsQuery.isLoading}
        value={selectedOrganization}
        setValue={setSelectedOrganization}
      />
      <Input.Select.Multi
        id="capture-projects-input"
        label={<Input.Label label="Assigned to Projects" />}
        options={projectsQuery.data ?? []}
        optionBuilder={(p) => p.name ?? 'Unknown'}
        title="Project"
        loading={projectsQuery.isLoading}
        value={selectedProjects}
        setValue={setSelectedProjects}
        compareFn={(a, b) => a.id === b.id}
      />
      <div className="w-full flex flex-row justify-end py-4">
        <Button.Primary
          id="save-capture-button"
          onClick={saveCapture}
          label="Save"
          loading={capturePutMutation.isPending}
        />
      </div>
      <EntityDetailsSection
        items={[
          {
            label: 'ID',
            value: captureQuery?.data?.id,
          },
          {
            label: 'UUID',
            value: captureQuery?.data?.captureUuid,
          },
          {
            label: 'Created By Name',
            value: createdByValue,
          },
          {
            label: 'Created By UUID',
            value: captureQuery?.data?.createdById,
          },
          {
            label: 'Mode',
            value: getCaptureModeNiceName(capture?.captureModeId),
          },
          {
            label: 'Number of Images',
            value: captureQuery?.data?.numberImages,
          },
          {
            label: 'File Size (MB)',
            value: captureQuery?.data?.fileSize,
          },
          {
            label: 'Longitude',
            value: captureQuery?.data?.longitude,
          },
          {
            label: 'Latitude',
            value: captureQuery?.data?.latitude,
          },
          {
            label: 'Altitude',
            value: captureQuery?.data?.altitude,
          },
          {
            label: 'Container',
            value: captureQuery?.data?.container,
          },
          {
            label: 'Uploaded',
            value: captureQuery?.data?.isUploaded ? 'Yes' : 'No',
          },
          {
            label: 'Created',
            value: formatDateAndTime(captureQuery?.data?.createDatetime),
          },
          {
            label: 'Updated',
            value: formatDateAndTime(captureQuery?.data?.updateDatetime),
          },
          {
            label: 'Scanned',
            value: formatDateAndTime(captureQuery?.data?.scanDatetime),
          },
          {
            label: 'Last Job Completed UUID',
            value: lastJobCompleted?.uuid,
          },
          {
            label: 'Last Job Completed',
            value: lastJobCompleted?.name,
          },
          {
            label: 'Last Job Completed Date',
            value: formatDateAndTime(lastJobCompleted?.completedDateTime),
          },
          {
            label: 'Notes',
            value: captureQuery.data?.description,
          },
        ]}
      />
    </div>
  );
};
