import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { JobTypeCategory, LdFlags } from '@agerpoint/types';
import {
  Sort,
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminJobTypesQueries } from './admin-job-types-queries';

export const AdminJobTypesDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { permissions } = useGlobalStore();
  usePageTitle(() => 'Platform - Job Types', []);

  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );
  const {
    jobTypeQuery,
    mlModelsQuery,
    mosaicEnginesQuery,
    jobTypePutArchivedMutation,
    jobTypePutMutation,
    pipelinesQuery,
  } = useAdminJobTypesQueries();

  const [jobType, setJobType] = useState<APIModels.JobType | undefined>();

  const formValidation = useFormValidation();

  useEffect(() => {
    setJobType(jobTypeQuery.data);
  }, [jobTypeQuery.data]);

  useEffect(() => {
    const mlModel = mlModelsQuery.data?.find(
      (m) => m.id === jobTypeQuery.data?.mlModelId
    );
    setSelectedMlModel(mlModel);
  }, [mlModelsQuery.data, jobTypeQuery.data?.mlModelId]);

  useEffect(() => {
    setSelectedMosaicEngine(
      mosaicEnginesQuery.data?.find(
        (m) => m.id === jobTypeQuery.data?.mosaicEngineId
      )
    );
  }, [mosaicEnginesQuery.data, jobTypeQuery.data?.mosaicEngineId]);

  useEffect(() => {
    setSelectedCategory(jobTypeQuery.data?.categoryName ?? undefined);
  }, [jobTypeQuery.data?.categoryName]);

  useEffect(() => {
    setSelectedPipeline(
      pipelinesQuery.data?.find((p) => p.id === jobTypeQuery.data?.pipelineId)
    );
  }, [pipelinesQuery.data, jobTypeQuery.data?.pipelineId]);

  const [selectedCategory, setSelectedCategory] = useState<string>();

  const [selectedMosaicEngine, setSelectedMosaicEngine] =
    useState<APIModels.MosaicEngine>();

  useEffect(() => {
    setJobType((prev) => ({
      ...prev,
      mosaicEngineId: selectedMosaicEngine?.id,
    }));
  }, [selectedMosaicEngine]);

  const [selectedMlModel, setSelectedMlModel] = useState<APIModels.MlModel>();

  useEffect(() => {
    setJobType((prev) => ({
      ...prev,
      mlModelId: selectedMlModel?.id,
    }));
  }, [selectedMlModel]);

  const [selectedPipeline, setSelectedPipeline] =
    useState<APIModels.Pipeline>();

  useEffect(() => {
    setJobType((prev) => ({
      ...prev,
      pipelineId: selectedPipeline?.id,
    }));
  }, [selectedPipeline]);

  const categories = useMemo(
    () => [
      JobTypeCategory.OperationJob,
      JobTypeCategory.CaptureJob,
      JobTypeCategory.CaptureExtractionJob,
      JobTypeCategory.PipelineJob,
    ],
    []
  );

  const saveJobType = useCallback(async () => {
    if (
      jobTypePutMutation.isPending ||
      !jobType?.id ||
      jobTypeQuery.isLoading
    ) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    jobTypePutMutation.mutate({
      id: jobType.id,
      data: jobType,
    });
  }, [formValidation, jobTypePutMutation, jobType, jobTypeQuery.isLoading]);

  const updateArchived = useCallback(
    async (to: boolean) => {
      if (
        jobTypePutArchivedMutation.isPending ||
        !jobTypeQuery.data?.id ||
        jobTypeQuery.isLoading
      ) {
        return;
      }
      const confirmed = window.confirm(
        `Are you sure you want to ${to ? 'archive' : 'restore'} this job type?`
      );

      if (!confirmed) {
        return;
      }

      jobTypePutArchivedMutation.mutate({
        id: jobTypeQuery.data.id,
        data: { ...jobTypeQuery.data, archived: to },
      });
    },
    [jobTypeQuery.data, jobTypePutArchivedMutation, jobTypeQuery.isLoading]
  );

  const mosaicEngineOptions: APIClient.MosaicEngine[] = useMemo(() => {
    if (mosaicEnginesQuery.data === undefined) {
      return [];
    }

    if (selectedCategory !== JobTypeCategory.PipelineJob) {
      return mosaicEnginesQuery.data;
    }

    return mosaicEnginesQuery.data.filter(
      (engine) =>
        engine.pipelineId === selectedPipeline?.id ||
        engine.pipelineId === null ||
        engine.pipelineId === undefined
    );
  }, [mosaicEnginesQuery.data, selectedCategory, selectedPipeline]);

  const mlModelsOptions: APIModels.MlModel[] = useMemo(() => {
    if (mlModelsQuery.data === undefined) {
      return [];
    }

    if (selectedCategory !== JobTypeCategory.PipelineJob) {
      return mlModelsQuery.data.sort((a, b) =>
        Sort.sortByAtt(a, b, 'displayName')
      );
    }

    return mlModelsQuery.data
      .filter(
        (model) =>
          model.pipelineId === selectedPipeline?.id ||
          model.pipelineId === null ||
          model.pipelineId === undefined
      )
      .sort((a, b) => Sort.sortByAtt(a, b, 'displayName'));
  }, [mlModelsQuery.data, selectedCategory, selectedPipeline]);

  return (
    <div className="flex w-full h-full flex-col pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Job Types',
              path: isViteApp
                ? '/app/admin/platform/job-types'
                : '/admin/job-types',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="job-types-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/job-types' + params);
            } else {
              navigate('/admin/job-types' + params);
            }
          }}
        />

        <h1 className="text-3xl font-bold">{jobTypeQuery.data?.name}</h1>
      </div>
      {jobTypeQuery.isLoading ? (
        <PageLoadingState />
      ) : jobTypeQuery.isError ? (
        <PageErrorState
          entityName="job type"
          pluralEntityName="job types"
          statusCode={
            (jobTypeQuery.error as unknown as AxiosError<void>)?.response
              ?.status ?? 500
          }
          tryAgainCallback={() => {
            jobTypeQuery.refetch();
          }}
          tryAgainLoading={jobTypeQuery.isFetching}
          navigateBackCallback={() =>
            navigate(
              isViteApp
                ? '/app/admin/platform/job-types' + params
                : '/admin/job-types' + params
            )
          }
        />
      ) : (
        <div className="p-4 w-full flex flex-col max-w-lg gap-2">
          <Input.Text.Single
            id="job-type-name"
            label={<Input.Label label="Name" required />}
            value={jobType?.name ?? ''}
            setValue={(name) => setJobType({ ...jobType, name })}
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Name')],
            }}
            error={
              <Input.Error error={formValidation.errors['job-type-name']} />
            }
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Text.Single
            id="job-type-description"
            label={<Input.Label label="Description" />}
            value={jobType?.description ?? ''}
            setValue={(description) => setJobType({ ...jobType, description })}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Select.Single
            id="job-type-category"
            label={<Input.Label label="Category" required />}
            value={selectedCategory}
            title="Category"
            disabled={true}
            setValue={setSelectedCategory}
            options={categories}
            optionBuilder={(category) => category.split(/(?=[A-Z])/).join(' ')}
            search={false}
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Category')],
            }}
            error={
              <Input.Error error={formValidation.errors['job-type-category']} />
            }
            readOnly={!canManageAnalyticRequests}
          />
          {selectedCategory === JobTypeCategory.PipelineJob && (
            <Input.Select.Single
              id="job-type-pipeline"
              label={<Input.Label label="Pipeline" required />}
              value={selectedPipeline}
              title="Pipeline"
              disabled={true}
              setValue={setSelectedPipeline}
              options={pipelinesQuery.data ?? []}
              optionBuilder={(pipeline) => pipeline?.name ?? 'Unknown'}
              loading={pipelinesQuery.isLoading}
              error={
                <Input.Error
                  error={formValidation.errors['job-type-pipeline']}
                />
              }
              validation={{
                validationState: formValidation,
                validators: [Input.validators.required('Pipeline')],
              }}
            />
          )}
          {(selectedCategory === JobTypeCategory.CaptureJob ||
            selectedCategory === JobTypeCategory.CaptureExtractionJob) && (
            <div className="w-full">
              <Input.Checkbox
                id="job-type-requires-high-res"
                disabled={true}
                label={<Input.Label label="Requires High Res" />}
                value={jobType?.requiresHighRes ?? false}
                setValue={(requiresHighRes) =>
                  setJobType({ ...jobType, requiresHighRes })
                }
              />
            </div>
          )}
          {(selectedCategory === JobTypeCategory.CaptureJob ||
            selectedCategory === JobTypeCategory.CaptureExtractionJob ||
            (selectedCategory === JobTypeCategory.PipelineJob &&
              selectedPipeline !== undefined)) && (
            <Input.Select.Single
              id="job-type-mosaic-engine"
              label={
                <Input.Label
                  label="Mosaic Engine"
                  required={jobType?.requiresHighRes ?? false}
                />
              }
              disabled={true}
              value={selectedMosaicEngine}
              title="Mosaic Engine"
              setValue={(mosaicEngine) => setSelectedMosaicEngine(mosaicEngine)}
              options={mosaicEngineOptions}
              optionBuilder={(mosaicEngine) =>
                mosaicEngine?.displayName ?? mosaicEngine?.name ?? 'Unknown'
              }
              loading={mosaicEnginesQuery.isLoading}
              error={
                <Input.Error
                  error={formValidation.errors['job-type-mosaic-engine']}
                />
              }
              validation={{
                validationState: formValidation,
                validators: jobType?.requiresHighRes
                  ? [Input.validators.required('Mosaic Engine')]
                  : [],
              }}
              readOnly={!canManageAnalyticRequests}
            />
          )}
          {(selectedCategory === JobTypeCategory.CaptureJob ||
            selectedCategory === JobTypeCategory.CaptureExtractionJob ||
            (selectedCategory === JobTypeCategory.PipelineJob &&
              selectedPipeline !== undefined)) && (
            <Input.Select.Single
              id="job-type-ml-model"
              disabled={true}
              label={<Input.Label label="ML Model" />}
              value={selectedMlModel}
              title="ML Model"
              setValue={(mlModel) => setSelectedMlModel(mlModel)}
              options={mlModelsOptions}
              optionBuilder={(mlModel) =>
                `${mlModel?.displayName ?? mlModel?.name ?? 'Unknown'} ${
                  mlModel.version ? `(v${mlModel.version})` : ''
                }`.trim()
              }
              loading={mlModelsQuery.isLoading}
              readOnly={!canManageAnalyticRequests}
            />
          )}
          {(selectedCategory === JobTypeCategory.CaptureJob ||
            selectedCategory === JobTypeCategory.CaptureExtractionJob ||
            (selectedCategory === JobTypeCategory.PipelineJob &&
              selectedPipeline !== undefined)) && (
            <Input.Text.Area
              id="job-type-3d-model-parameters"
              label={<Input.Label label="3D Model Parameters" />}
              value={jobType?.nerfConfig ?? ''}
              setValue={(nerfConfig) => setJobType({ ...jobType, nerfConfig })}
              error={
                <Input.Error
                  error={formValidation.errors['job-type-3d-model-parameters']}
                />
              }
              rows={5}
              validation={{
                validationState: formValidation,
                validators: [Input.validators.validJson],
              }}
              readOnly={!canManageAnalyticRequests}
            />
          )}
          {selectedCategory === JobTypeCategory.CaptureExtractionJob && (
            <Input.Text.Area
              id="job-type-apf-parameters"
              label={<Input.Label label="APF Parameters" />}
              value={jobType?.jobParameters ?? ''}
              setValue={(jobParameters) =>
                setJobType({ ...jobType, jobParameters })
              }
              error={
                <Input.Error
                  error={formValidation.errors['job-type-apf-parameters']}
                />
              }
              rows={5}
              validation={{
                validationState: formValidation,
                validators: [Input.validators.validJson],
              }}
              readOnly={!canManageAnalyticRequests}
            />
          )}
          {canManageAnalyticRequests && (
            <div className="w-full flex flex-row justify-end items-center gap-2 py-4">
              {jobType?.archived ? (
                <Button.Secondary
                  id="restore-job-type-button"
                  label="Restore"
                  onClick={() => updateArchived(false)}
                  loading={jobTypePutArchivedMutation.isPending}
                />
              ) : (
                <Button.Danger
                  id="archive-job-type-button"
                  label="Archive"
                  onClick={() => updateArchived(true)}
                  loading={jobTypePutArchivedMutation.isPending}
                />
              )}

              <Button.Primary
                id="save-job-type-button"
                label="Save"
                onClick={saveJobType}
                loading={jobTypePutMutation.isPending}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
