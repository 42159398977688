export const formatDateRegular = (value: string | null | undefined) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);

  // Return date in format eg.: Mar 5 2023

  return `${date.toLocaleString('en-US', {
    month: 'short',
  })} ${date.getDate()} ${date.getFullYear()}`;
};

export const formatDateShort = (value: string | null | undefined) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);

  // Return date in format eg.: 3/5/23
  return `${date.getMonth() + 1}/${date.getDate()}/${date
    .getFullYear()
    .toString()
    .slice(-2)}`;
};

export const formatDateLong = (value: string | null | undefined) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);

  // Return date in format eg.: March 5, 2023
  return `${date.toLocaleString('en-US', {
    month: 'long',
  })} ${date.getDate()}, ${date.getFullYear()}`;
};
