import { PointSizeType, PotreeViewer } from '@agerpoint/types';

class DebugPotreeViewer {
  private viewer: PotreeViewer;
  constructor(viewer: PotreeViewer) {
    this.viewer = viewer;
  }

  public getViewerState() {
    const pointCount = this.getPointCount();
    return {
      edlEnabled: this.viewer.getEDLEnabled(),
      edlRadius: this.viewer.getEDLRadius(),
      edlStrength: this.viewer.getEDLStrength(),
      pointBudget: this.viewer.getPointBudget(),
      fov: this.viewer.getFOV(),
      useHQ: this.viewer.useHQ,
      pointCount,
    };
  }

  protected getPointCount() {
    const pointClouds: {
      [key: string]: {
        count: number;
        materialPointSizeType: string;
        materialSize: number;
      };
    } = {};

    this.viewer.scene.pointclouds.forEach((pointCloud: any, i: number) => {
      const count = pointCloud.pcoGeometry?.info?.points;
      const sizeType = PointSizeType[pointCloud.material._pointSizeType];
      pointClouds[i] = {
        count,
        materialPointSizeType: sizeType,
        materialSize: pointCloud.material.size,
      };
    });

    return pointClouds;
  }
}

export default DebugPotreeViewer;
