import { useEffect, useMemo, useState } from 'react';
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful';
import { useDebouncyEffect } from 'use-debouncy';

interface ColorPicker2RgbaProps {
  initialPercentRgbaColor?: number[];
  onChange?: (color: number[]) => void;
  debounceTime?: number;
}

export const ColorPicker2Rgba = ({
  initialPercentRgbaColor,
  onChange,
  debounceTime,
}: ColorPicker2RgbaProps) => {
  const [hexAlphaColor, setHexAlphaColor] = useState<string>(() => {
    if (initialPercentRgbaColor) {
      const [r, g, b, a] = initialPercentRgbaColor;

      const hexR = Math.round(r * 255)
        .toString(16)
        .padStart(2, '0');
      const hexG = Math.round(g * 255)
        .toString(16)
        .padStart(2, '0');
      const hexB = Math.round(b * 255)
        .toString(16)
        .padStart(2, '0');
      const hexA = Math.round(a * 255)
        .toString(16)
        .padStart(2, '0');

      const hex = `#${hexR}${hexG}${hexB}${hexA}`;

      return hex;
    }
    return '#000000ff';
  });

  const presetsLocalStorageKey = useMemo(
    () => 'APCLOUD_COLORPICKER_RGBA_PRESETS',
    []
  );

  const [presets, setPresets] = useState<string[]>(() => {
    const presetsString = localStorage.getItem(presetsLocalStorageKey);
    if (presetsString) {
      return JSON.parse(presetsString);
    }
    return [];
  });

  useEffect(() => {
    localStorage.setItem(presetsLocalStorageKey, JSON.stringify(presets));
  }, [presets, presetsLocalStorageKey]);

  useDebouncyEffect(
    () => {
      const color = hexAlphaColor
        .replace('#', '')
        .match(/.{1,2}/g)
        ?.map((x) => parseFloat((parseInt(x, 16) / 255).toFixed(2)));

      if (color && color.length === 3) {
        color.push(1); // Add alpha value of 1 if it doesn't exist
      }

      if (!color || color.length !== 4) {
        throw new Error('Invalid color format');
      }

      onChange?.(color);
    },
    debounceTime ?? 0,
    [hexAlphaColor]
  );

  useDebouncyEffect(
    () => {
      const isPreset = presets.includes(hexAlphaColor);
      if (isPreset) {
        return;
      }

      if (presets.length < 10) {
        setPresets((prev) => [...prev, hexAlphaColor]);
      } else if (presets.length === 10) {
        setPresets((prev) => [...prev.slice(1), hexAlphaColor]);
      }
    },
    debounceTime ?? 300,
    [hexAlphaColor]
  );

  return (
    <div className="flex flex-col gap-1">
      <HexAlphaColorPicker color={hexAlphaColor} onChange={setHexAlphaColor} />
      <div className="flex flex-row h-4">
        {presets.map((preset) => (
          <div
            key={preset}
            className="w-4 h-4 rounded-md border border-gray-500 cursor-pointer mx-0.5 overflow-hidden"
            onClick={() => setHexAlphaColor(preset)}
            style={{
              background: `repeating-conic-gradient(#d3d3d3 0% 25%, transparent 0% 50%) 50% / 20px 20px`,
            }}
          >
            <div
              className="w-full h-full"
              style={{
                background: preset,
              }}
            />
          </div>
        ))}
      </div>
      <HexColorInput
        color={hexAlphaColor}
        type="text"
        onChange={(newColor) => {
          if (newColor.length !== 9) return;
          setHexAlphaColor(newColor);
        }}
        className="text-sm text-gray-900 focus:ring-green
        focus:border-green shadow-sm border-gray-500 rounded-md placeholder-gray"
        prefixed
        alpha
      />
    </div>
  );
};
