import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create({
  baseURL:
    (process.env.NX_API_SERVER_URL as string) || 'AGERCLOUD_API_SERVER_URL',
});

export const AxiosInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const response = await AXIOS_INSTANCE({
    ...config,
    ...options,
  });

  return response?.data ?? null;
};

export type BodyType<BodyData> = BodyData;
export type ErrorType<Error> = AxiosError<Error>;
