import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminOrthomosaicsQueries } from './admin-orthomosaic-queries';

export const AdminOrthomosaicsNew = () => {
  usePageTitle(() => 'Platform - Orthomosaics', []);

  const navigate = useNavigate();
  const location = useLocation();
  const { params } = location.state;

  const [orthomosaic, setOrthomosaic] = useState<
    Partial<APIModels.ImageMosaic>
  >({});

  const formValidation = useFormValidation();

  const { orthomosaicPostMutation, organizationsQuery } =
    useAdminOrthomosaicsQueries();

  const isViteApp = useIsViteApp();

  const [selectedOrg, setSelectedOrg] = useState<
    APIModels.Customer | undefined
  >();

  const createOrthomosaic = useCallback(async () => {
    if (orthomosaicPostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    orthomosaicPostMutation.mutate({
      data: orthomosaic,
    });
  }, [formValidation, orthomosaicPostMutation, orthomosaic]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Orthomosaics',
              path: isViteApp
                ? '/app/admin/platform/orthomosaics'
                : '/admin/orthomosaics',
              params,
            },
          ]}
        />
      </div>
      <div className="px-4 py-2 flex flex-row gap-2 justify-start items-center">
        <Button.Back
          id="new-orthomosaic-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/orthomosaics' + params);
            } else {
              navigate('/admin/orthomosaics' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Orthomosaic</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="ortho-name-input"
          label={<Input.Label label="Name" required />}
          value={orthomosaic?.name ?? ''}
          setValue={(name) => {
            setOrthomosaic({ ...orthomosaic, name });
          }}
          error={
            <Input.Error error={formValidation.errors['ortho-name-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
        <Input.Text.Single
          id="ortho-layer-name-input"
          label={<Input.Label label="Layer Name" required />}
          value={orthomosaic?.tifLayerName ?? ''}
          setValue={(tifLayerName) => {
            setOrthomosaic({ ...orthomosaic, tifLayerName });
          }}
          error={
            <Input.Error
              error={formValidation.errors['ortho-layer-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Layer Name')],
          }}
        />
        <Input.Select.Single
          id="organization-select"
          title="Organization"
          label={<Input.Label label="Organization" required />}
          error={
            <Input.Error error={formValidation.errors['organization-select']} />
          }
          value={selectedOrg}
          setValue={(o) => {
            setSelectedOrg(o);
            setOrthomosaic({ ...orthomosaic, customerId: o?.id });
          }}
          options={organizationsQuery.data ?? []}
          optionBuilder={(o) =>
            o.customerDisplayName ?? o.customerName ?? 'Unknown'
          }
          loading={organizationsQuery.isLoading}
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Organization')],
          }}
        />
        <Input.Number.Decimal
          id="ortho-min-x-input"
          label={<Input.Label label="Min X" required />}
          value={orthomosaic?.minX ?? undefined}
          setValue={(minX) => {
            setOrthomosaic((prev) => ({ ...prev, minX }));
          }}
          error={
            <Input.Error error={formValidation.errors['ortho-min-x-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Min X')],
          }}
        />
        <Input.Number.Decimal
          id="ortho-min-y-input"
          label={<Input.Label label="Min Y" required />}
          value={orthomosaic?.minY ?? undefined}
          setValue={(minY) => {
            setOrthomosaic((prev) => ({ ...prev, minY }));
          }}
          error={
            <Input.Error error={formValidation.errors['ortho-min-y-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Min Y')],
          }}
        />
        <Input.Number.Decimal
          id="ortho-max-x-input"
          label={<Input.Label label="Max X" required />}
          value={orthomosaic?.maxX ?? undefined}
          setValue={(maxX) => {
            setOrthomosaic((prev) => ({ ...prev, maxX }));
          }}
          error={
            <Input.Error error={formValidation.errors['ortho-max-x-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Max X')],
          }}
        />
        <Input.Number.Decimal
          id="ortho-max-y-input"
          label={<Input.Label label="Max Y" required />}
          value={orthomosaic?.maxY ?? undefined}
          setValue={(maxY) => {
            setOrthomosaic((prev) => ({ ...prev, maxY }));
          }}
          error={
            <Input.Error error={formValidation.errors['ortho-max-y-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Max Y')],
          }}
        />
        <Input.Date.Single
          id="ortho-scan-date"
          label={<Input.Label label={'Scan Date'} />}
          value={
            orthomosaic?.scanDateTime
              ? new Date(orthomosaic?.scanDateTime)
              : undefined
          }
          setValue={(scanDateTime) => {
            setOrthomosaic({
              ...orthomosaic,
              scanDateTime: scanDateTime?.toISOString(),
            });
          }}
        />
        <div className="flex flex-row justify-end items-center py-4">
          <Button.Primary
            id="new-orthomosaic-create-button"
            label={'Create'}
            onClick={createOrthomosaic}
            loading={orthomosaicPostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
