import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ToolButton {
  icon: IconDefinition;
  active?: boolean;
  disabled?: boolean;
  grow?: boolean;
  className?: string;
  onSelect?: () => void;
  title?: string;
}

export function ToolButton({
  active,
  className,
  disabled,
  grow,
  icon,
  onSelect,
  title,
}: ToolButton) {
  return (
    <div
      className={`flex items-center ${grow ? 'flex-grow' : 'justify-center'} ${
        active ? 'bg-gray-600 text-white border-gray-600' : ''
      } ${className ? className : ''}
`}
    >
      <button
        title={title}
        disabled={disabled}
        className={`p-2 w-10 h-10 border-l transition-colors duration-200 border-gray-100 flex justify-center items-center ${
          !disabled
            ? 'hover:bg-gray-600 hover:text-white hover:border-gray-600'
            : 'cursor-not-allowed'
        }`}
        onClick={onSelect}
      >
        {icon && (
          <FontAwesomeIcon
            className={`text-lg ${disabled ? 'opacity-20' : ''}`}
            icon={icon}
          />
        )}
      </button>
    </div>
  );
}
