import { ChangeEvent } from 'react';

import { Options } from '@agerpoint/types';

import { Select } from '../select/select';

interface CustomSelectProps {
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  value: string | number;
  options: Options[];
  id: string;
  label: string;
  disabled?: boolean;
  className?: string;
}

/**
 * @deprecated Use Input.Select.* instead
 */
export function CustomSelect({
  onChange,
  value,
  options,
  id,
  label,
  disabled = false,
  className,
}: CustomSelectProps) {
  return (
    <div className={className !== undefined ? className : 'mb-3'}>
      <label className="text-sm text-gray-800 font-bold" htmlFor={id}>
        {label}
      </label>
      <Select
        value={value}
        type="small"
        onChange={onChange}
        options={options}
        name={id}
        id={id}
        disabled={disabled}
      />
    </div>
  );
}
