/**
 *
 * @param value length in meters
 * @returns string formatted length in readable format
 */
export const formatLengthMetric = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  let unit = 'm';
  let length = value;
  if (value < 0.01) {
    unit = 'mm';
    length = value * 1000;
  } else if (value < 1) {
    unit = 'cm';
    length = value * 100;
  } else if (value >= 1000) {
    unit = 'km';
    length = value / 1000;
  }

  return `${length.toFixed(2)} ${unit}`;
};

/**
 *
 * @param value length in feet
 * @returns string formatted length in readable format
 */
export const formatLengthImperial = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  let unit = 'ft';
  let length = value;
  if (value < 1) {
    unit = 'in';
    length = value * 12;
  } else if (value >= 5280) {
    unit = 'mi';
    length = value / 5280;
  }

  return `${length.toFixed(2)} ${unit}`;
};

/**
 *
 * @param value area in square meters
 * @returns string formatted area in readable format
 */
export const formatAreaMetric = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  const squared = '\u00B2';
  let unit = 'm';
  let area = value;

  if (value < 0.0001) {
    unit = 'mm';
    area = value * 1000000;
  } else if (value < 1) {
    unit = 'cm';
    area = value * 10000;
  } else if (value >= 1000000) {
    unit = 'km';
    area = value / 1000000;
  }

  return `${area.toFixed(2)} ${unit}${squared}`;
};

/**
 *
 * @param value area in square feet
 * @returns string formatted area in readable format
 */
export const formatAreaImperial = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  const squared = '\u00B2';
  let unit = 'ft';
  let area = value;

  if (value < 1) {
    unit = 'in';
    area = value * 144;
  } else if (value >= 27878400) {
    unit = 'mi';
    area = value / 27878400;
  }

  return `${area.toFixed(2)} ${unit}${squared}`;
};

/**
 *
 * @param value volume in cubic meters
 * @returns string formatted volume in readable format
 */
export const formatVolumeMetric = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  const cubed = '\u00B3';
  let unit = 'm';
  let volume = value;

  if (value < 0.000001) {
    unit = 'mm';
    volume = value * 1000000000;
  } else if (value < 1) {
    unit = 'cm';
    volume = value * 1000000;
  } else if (value >= 1000000000) {
    unit = 'km';
    volume = value / 1000000000;
  }

  return `${volume.toFixed(2)} ${unit}${cubed}`;
};

/**
 *
 * @param value volume in cubic feet
 * @returns string formatted volume in readable format
 */
export const formatVolumeImperial = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  const cubed = '\u00B3';
  let unit = 'ft';
  let volume = value;

  if (value < 1) {
    unit = 'in';
    volume = value * 1728;
  } else if (value >= 147197952000) {
    unit = 'mi';
    volume = value / 147197952000;
  }

  return `${volume.toFixed(2)} ${unit}${cubed}`;
};
