export interface IEnvironment {
  app_version: string;
  /**
   * If `true` build is configured for production
   */
  production: boolean;

  /**
   * Url of app. No trailing forward slash please!
   *
   * @example 'https://localhost:4200'
   */
  app_url: string;

  /**
   * Oidc server. No trailing forward slash please!
   *
   * @example 'https://ageridentity-dev.azurewebsites.net'
   */
  authentication_server_url: string;

  /**
   * Api server. No trailing forward slash please!
   *
   * @example 'https://localhost:5001'
   */
  api_server_url: string;

  /**
   * Possible values:
   * - NONE = 0
   * - ERROR = 1
   * - WARN = 2
   * - INFO = 3
   * - DEBUG = 4
   *
   * @see https://github.com/IdentityModel/oidc-client-js/wiki#logging
   */
  oidc_log_level: 0 | 1 | 2 | 3 | 4;

  /**
   * Bing Map key (typically 64 chars contains: alpha, numeric and -)
   */
  bing_api_key: string;

  ld_client_key: string;
  mixpanel_token: string;
  courier_client_id: string;
}

export enum EnvironmentTypes {
  Production = 'prod',
  Development = 'dev',
  Staging = 'stage',
  PreProduction = 'preprod',
  Localhost = 'local',
  Unknown = 'unknown',
}
