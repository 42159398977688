import { CaptureCustomAttribute } from '@agerpoint/api';

export const getSymbol = (item: CaptureCustomAttribute, asString = false) => {
  let res = '';

  if (item?.customAttributeUnit?.symbol) {
    res = item?.customAttributeUnit?.symbol;
  }

  if (
    item?.customAttributeUnit?.exponent &&
    item?.customAttributeUnit?.exponent !== null
  ) {
    if (asString) return `${res}^${item?.customAttributeUnit?.exponent}`;

    return (
      <>
        {res}
        <sup>{item?.customAttributeUnit?.exponent}</sup>
      </>
    );
  }

  return res;
};
