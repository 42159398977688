import {
  faDrawPolygon,
  faLocationDot,
  faRuler,
  faSave,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { Feature } from 'ol';
import OlMap from 'ol/Map';
import { LineString, Point, Polygon } from 'ol/geom';
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useEffect, useMemo, useState } from 'react';

import { Button } from '@agerpoint/component';
import { useToasts } from '@agerpoint/utilities';

import { useMap2DFeatureDrawingQueries } from './map-2d-feature-drawing-queries';

interface IMap2dFeatureDrawing {
  map?: OlMap;
  onLayerCreated?: () => void;
}

type ToolTypes = 'point' | 'line' | 'polygon';

export const Map2dFeatureDrawing = ({ map }: IMap2dFeatureDrawing) => {
  const mapInitialized = useMemo(() => map !== undefined, [map]);

  const toasts = useToasts();

  const [tool, setTool] = useState<ToolTypes>();

  const [features, setFeatures] = useState<Feature[]>([]);

  const { featureDrawingPostMutation } = useMap2DFeatureDrawingQueries();

  useEffect(() => {
    if (!mapInitialized || !map) {
      return;
    }

    const olSource = new VectorSource({ wrapX: false, features });
    const olVectorLayer = new VectorLayer({
      source: olSource,
      zIndex: 99999,
      properties: {
        id: 'geojson-drawing-layer',
      },
    });

    map.addLayer(olVectorLayer);

    return () => {
      olSource.dispose();
      olVectorLayer.dispose();
      map.removeLayer(olVectorLayer);
    };
  }, [features]);

  useEffect(() => {
    if (tool !== 'point' || !mapInitialized || !map) {
      return;
    }
    // Prepare source, layer and draw objects for point placement
    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
      zIndex: 99999,
      properties: {
        id: 'geojson-drawing-layer',
      },
    });
    const olDraw = new Draw({
      source: olSource,
      type: 'Point',
    });
    map.addInteraction(olDraw);
    map.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setTool(undefined);
      const olFeature: Feature<Point> = e.feature as Feature<Point>;
      setFeatures([...features, olFeature]);
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      map.removeInteraction(olDraw);
      map.removeLayer(olVectorLayer);
    };
  }, [tool, mapInitialized]);

  useEffect(() => {
    if (tool !== 'line' || !mapInitialized || !map) {
      return;
    }

    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
      zIndex: 99999,
      properties: {
        id: 'geojson-drawing-layer',
      },
    });
    const olDraw = new Draw({
      source: olSource,
      type: 'LineString',
    });
    map.addInteraction(olDraw);
    map.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setTool(undefined);
      const olFeature: Feature<LineString> = e.feature as Feature<LineString>;
      setFeatures([...features, olFeature]);
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      map.removeInteraction(olDraw);
      map.removeLayer(olVectorLayer);
    };
  }, [tool, mapInitialized]);

  useEffect(() => {
    if (tool !== 'polygon' || !mapInitialized || !map) {
      return;
    }

    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
      zIndex: 99999,
      properties: {
        id: 'geojson-drawing-layer',
      },
    });
    const olDraw = new Draw({
      source: olSource,
      type: 'Polygon',
    });
    map.addInteraction(olDraw);
    map.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setTool(undefined);
      const olFeature: Feature<Polygon> = e.feature as Feature<Polygon>;
      setFeatures([...features, olFeature]);
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      map.removeInteraction(olDraw);
      map.removeLayer(olVectorLayer);
    };
  }, [tool, mapInitialized]);

  if (!mapInitialized) {
    return null;
  }

  return (
    <div className="absolute inset-0 left-sidebar pointer-events-none">
      {tool === 'point' && (
        <div
          className={`absolute left-1/2 -translate-x-1/2 z-1 bottom-28
          text-white bg-black bg-opacity-60 py-3 px-6 rounded-lg`}
        >
          Click to place a marker
        </div>
      )}
      {tool === 'line' && (
        <div
          className={`absolute left-1/2 -translate-x-1/2 z-1 bottom-28
          text-white bg-black bg-opacity-60 py-3 px-6 rounded-lg`}
        >
          Draw a line by clicking on the map
        </div>
      )}
      {tool === 'polygon' && (
        <div
          className={`absolute left-1/2 -translate-x-1/2 z-1 bottom-28
          text-white bg-black bg-opacity-60 py-3 px-6 rounded-lg`}
        >
          Draw a polygon by clicking on the map
        </div>
      )}
      <div
        className={`absolute left-1/2 bottom-14 -translate-x-1/2 z-1 flex flex-row gap-2`}
      >
        {(features.length > 0 || tool !== undefined) && (
          <div className="bg-white shadow-lg rounded-lg p-1 pointer-events-auto">
            <div className="p-1">
              <Button.Icon
                id={`save-geojson-button`}
                internalPadding="p-px"
                icon={faSave}
                disabled={features.length === 0}
                loading={featureDrawingPostMutation.isPending}
                onClick={() => {
                  if (featureDrawingPostMutation.isPending) {
                    return;
                  }

                  featureDrawingPostMutation.mutate(
                    {
                      features,
                    },
                    {
                      onSuccess: () => {
                        setFeatures([]);

                        toasts.add(toasts.prepare.entityCreated('GeoJSON'));
                      },
                    }
                  );
                }}
                title="Save as Annotation Layer"
              />
            </div>
          </div>
        )}
        <div className="bg-white shadow-lg rounded-lg p-1 flex flex-row gap-1 pointer-events-auto">
          <div
            className={`p-1 rounded-lg ${
              tool === 'point' ? 'bg-black bg-opacity-10' : ''
            }`}
          >
            <Button.Icon
              id={`2d-point-button`}
              icon={faLocationDot}
              internalPadding="p-px"
              disabled={featureDrawingPostMutation.isPending}
              onClick={() => {
                if (mapInitialized) {
                  if (tool === 'point') {
                    setTool(undefined);
                  } else {
                    setTool('point');
                  }
                }
              }}
              title="Add Point"
            />
          </div>
          <div
            className={`p-1 rounded-lg ${
              tool === 'line' ? 'bg-black bg-opacity-10' : ''
            }`}
          >
            <Button.Icon
              id={`2d-line-button`}
              icon={faRuler}
              internalPadding="p-px"
              disabled={featureDrawingPostMutation.isPending}
              onClick={() => {
                if (mapInitialized) {
                  if (tool === 'line') {
                    setTool(undefined);
                  } else {
                    setTool('line');
                  }
                }
              }}
              title="Add Line"
            />
          </div>
          <div
            className={`p-1 rounded-lg ${
              tool === 'polygon' ? 'bg-black bg-opacity-10' : ''
            }`}
          >
            <Button.Icon
              id={`2d-polygon-button`}
              icon={faDrawPolygon}
              internalPadding="p-px"
              disabled={featureDrawingPostMutation.isPending}
              onClick={() => {
                if (mapInitialized) {
                  if (tool === 'polygon') {
                    setTool(undefined);
                  } else {
                    setTool('polygon');
                  }
                }
              }}
              title="Add Polygon"
            />
          </div>
        </div>
        {(features.length > 0 || tool !== undefined) && (
          <div className="bg-white shadow-lg rounded-lg p-1 pointer-events-auto">
            <div className="p-1">
              <Button.Icon
                id={`cancel-geojson-button`}
                icon={faXmark}
                internalPadding="p-px"
                disabled={featureDrawingPostMutation.isPending}
                onClick={() => {
                  setTool(undefined);
                  setFeatures([]);
                }}
                title="Cancel"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
