import { useContext } from 'react';

import { InputCapture } from './input-capture';
import { InputCheckbox } from './input-checkbox';
import { InputDate } from './input-date/input-date';
import { InputFile } from './input-file';
import { InputIdContext } from './input-id-context';
import { InputNumber } from './input-number';
import { InputPlaceholderIcon } from './input-placeholder-icons';
import { InputRadio } from './input-radio';
import { InputSelect } from './input-select/input-select';
import { InputStyle } from './input-style';
import { InputText } from './input-text';
import { InputToggle } from './input-toggle';
import { InputValidators } from './input-validators';

interface InputErrorProps {
  error?: string;
  className?: string;
}

function InputError({ error, className }: InputErrorProps) {
  const id = useContext(InputIdContext);

  return (
    <span
      id={id ? `${id}-error` : undefined}
      data-test-id={id ? `${id}-error` : undefined}
      className={`text-sm text-red ${className || ''}`.trim()}
    >
      {error ?? null}
    </span>
  );
}

interface InputLabelProps {
  label?: string;
  required?: boolean;
  className?: string;
}

function InputLabel({ label, required = false, className }: InputLabelProps) {
  const id = useContext(InputIdContext);

  return (
    <label
      htmlFor={id}
      id={id ? `${id}-label` : undefined}
      data-test-id={id ? `${id}-label` : undefined}
      className={`text-sm ${className || ''}`.trim()}
    >
      {label ?? null}
      {required ? '*' : null}
    </label>
  );
}

const Input = {
  Text: InputText,
  Radio: InputRadio,
  Label: InputLabel,
  Error: InputError,
  Select: InputSelect,
  Number: InputNumber,
  Checkbox: InputCheckbox,
  Date: InputDate,
  File: InputFile,
  Capture: InputCapture,
  Toggle: InputToggle,
  style: InputStyle,
  placeholderIcons: InputPlaceholderIcon,
  validators: InputValidators,
};

export { Input };
