import {
  faCircle,
  faCircleNotch,
  faSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';

import { InputIdContext } from '../input-id-context';

interface IInputCheckbox {
  /**
   * The id of the checkbox.
   */
  id: string;

  /**
   * The value of the checkbox.
   */
  value: boolean;

  /**
   * A function to set the value of the checkbox.
   */
  setValue: (value: boolean, event: React.MouseEvent) => void;

  /**
   * The label to display before the checkbox.
   */
  leadingLabel?: string;

  /**
   * The label to display after the checkbox.
   */
  trailingLabel?: string;

  /**
   * Determines whether the checkbox is required.
   */
  required?: boolean;

  /**
   * The error message to display.
   */
  error?: React.ReactNode;

  /**
   * Specifies whether the checkbox is disabled.
   */
  disabled?: boolean;

  /**
   * Specifies whether the checkbox is loading.
   */
  loading?: boolean;

  /**
   * The circle version of the checkbox.
   */
  circle?: boolean;

  /**
   * The color of the checkbox.
   */
  checkedColor?: string;

  /**
   * The color of the checkbox when disabled.
   */
  uncheckedColor?: string;
}

const InputCheckbox = ({
  id,
  value,
  setValue,
  leadingLabel,
  trailingLabel,
  error,
  loading,
  disabled,
  circle,
  required,
  checkedColor,
  uncheckedColor,
}: IInputCheckbox) => {
  const icon = useMemo(() => {
    if (loading) {
      return (
        <FontAwesomeIcon
          key={`loading`}
          icon={faCircleNotch}
          spin
          className={`${uncheckedColor ?? 'text-gray-iconPrimary'} size-4`}
        />
      );
    }

    if (circle) {
      return (
        <FontAwesomeIcon
          key={`${value}`}
          icon={value ? faCheckCircle : faCircle}
          className={
            value
              ? checkedColor ?? 'text-primary'
              : uncheckedColor ?? 'text-gray-iconPrimary'
          }
        />
      );
    }

    return (
      <FontAwesomeIcon
        key={`${value}`}
        icon={value ? faCheckSquare : faSquare}
        className={
          value
            ? checkedColor ?? 'text-primary'
            : uncheckedColor ?? 'text-gray-iconPrimary'
        }
      />
    );
  }, [checkedColor, uncheckedColor, loading, circle, value]);

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-1">
          {leadingLabel && (
            <label
              htmlFor={id}
              onClick={(e) => {
                if (disabled || loading) return;
                setValue(!value, e);
              }}
              className="cursor-pointer select-none"
            >
              {leadingLabel + (required ? '*' : '')}
            </label>
          )}
          <div
            className={`flex-center text-lg relative p-2 rounded-full z-1 ${
              disabled
                ? 'opacity-50 pointer-events-none cursor-not-allowed'
                : 'cursor-pointer hover-overlay-5'
            }`}
            onClick={(e) => {
              if (disabled || loading) return;
              setValue(!value, e);
            }}
            id={id}
            data-test-id={id}
            tabIndex={-1}
          >
            <motion.div
              transition={{
                duration: 0.1,
              }}
              key={icon.key}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              className="size-4 flex-center"
            >
              {icon}
            </motion.div>
          </div>
          {trailingLabel && (
            <label
              className="cursor-pointer select-none"
              htmlFor={id}
              onClick={(e) => {
                if (disabled || loading) return;
                setValue(!value, e);
              }}
            >
              {trailingLabel + (required ? '*' : '')}
            </label>
          )}
        </div>
        {error ?? null}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputCheckbox };
