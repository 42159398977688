import { useEffect, useRef } from 'react';

export function useIsMounted() {
  const componentIsMounted = useRef<boolean>(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return componentIsMounted;
}
