import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';

interface ButtonSecondaryProps {
  id: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  icon?: IconDefinition;
  trailingIcon?: IconDefinition;
  expand?: boolean;
  cloud?: boolean;
}

export const ButtonSecondary = ({
  id,
  onClick,
  disabled,
  label,
  loading,
  icon,
  trailingIcon,
  expand,
  cloud = false,
}: ButtonSecondaryProps) => {
  const className = useMemo(() => {
    let classes = ` border-primary border font-bold transition-all px-4
    duration-200 focus:outline-none outline-none focus:ring-2 ring-offset-2 ring-primary
    flex flex-row items-center justify-center relative`;

    if (expand) {
      classes += ' w-full ';
    }

    if (cloud) {
      classes += ' rounded-lg ';
    } else {
      classes += ' rounded ';
    }

    if (loading) {
      classes += ' opacity-50 text-disabled ';
    } else if (disabled) {
      classes += ' cursor-not-allowed opacity-50 text-disabled ';
    } else {
      classes += ' text-primary hover-overlay-5';
    }

    return classes.trim();
  }, [disabled, loading, expand, cloud]);

  return (
    <button
      data-test-id={id}
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled}
      style={{
        height: '38px',
      }}
    >
      {loading && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="w-4 h-4 flex items-center justify-center rounded">
            <FontAwesomeIcon
              icon={faCircleNotch}
              className="w-full h-full"
              spin
            />
          </div>
        </div>
      )}
      <div
        className={`flex flex-row gap-2 items-center ${
          loading ? 'opacity-0' : ''
        }`.trim()}
      >
        {icon && (
          <div
            className={` ${
              cloud ? 'w-5 h-5' : 'w-4 h-4'
            } flex items-center justify-center`}
          >
            <FontAwesomeIcon icon={icon} className="w-full h-full" />
          </div>
        )}
        {label && <span>{label}</span>}
        {trailingIcon && (
          <div
            className={` ${
              cloud ? 'w-5 h-5' : 'w-4 h-4'
            } flex items-center justify-center`}
          >
            <FontAwesomeIcon icon={trailingIcon} className="w-full h-full" />
          </div>
        )}
      </div>
    </button>
  );
};
