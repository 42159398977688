import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { APIClient, APIUtils } from '@agerpoint/api';
import { CloudButton, CloudInput } from '@agerpoint/cloud/components';
import {
  ILine3d,
  IMultiPoint2d,
  IMultiPoint3d,
  IPoint2d,
  IPoint3d,
  IPotreeViewerController,
  MaterialType,
} from '@agerpoint/types';
import { useAgerStore } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../captures-viewer';

interface IPotreeCloudTools {
  viewerController?: IPotreeViewerController;
  shared?: boolean;
}

export const PotreeCloudTools = ({
  viewerController,
  shared,
}: IPotreeCloudTools) => {
  const { annotations3dGeometry, annotations2dGeometry } =
    useCapturesViewerContext();
  const { isMobile } = useAgerStore();
  const [toggleAll3dAnnotations, setToggleAll3dAnnotations] = useState(true);

  const [, setSearch] = useSearchParams();

  const [annotations, setAnnotations] = useState<
    (IPoint3d | ILine3d | IMultiPoint3d | IPoint2d | IMultiPoint2d)[]
  >([]);

  useEffect(() => {
    const combinedAnnotations = [
      ...(annotations3dGeometry?.points ?? []),
      ...(annotations3dGeometry?.lines ?? []),
      ...(annotations3dGeometry?.polygons ?? []),
      ...(annotations3dGeometry?.multiPoints ?? []),
      ...(annotations2dGeometry?.points ?? []),
      ...(annotations2dGeometry?.multiPoints ?? []),
    ];

    setAnnotations(combinedAnnotations);
  }, [annotations3dGeometry, annotations2dGeometry]);

  const hasClassification = useMemo(
    () => (viewerController?.info?.classificationClasses?.length ?? 0) > 1,
    [viewerController?.info?.classificationClasses]
  );

  const isRGBA = useMemo(
    () =>
      viewerController?.info?.pointStyle === MaterialType.RGBA &&
      hasClassification,
    [viewerController?.info?.pointStyle, hasClassification]
  );

  const isClassified = useMemo(
    () =>
      viewerController?.info?.pointStyle === MaterialType.CLASSIFICATION &&
      hasClassification,
    [viewerController?.info?.pointStyle, hasClassification]
  );

  const captureJobImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(viewerController?.info?.captureJobMetadata?.id),
    {
      query: {
        enabled:
          Number.isSafeInteger(
            Number(viewerController?.info?.captureJobMetadata?.id ?? undefined)
          ) && !shared,
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(
              viewerController?.info?.captureJobMetadata?.id
            ),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data?.filter((image) => image.x && image.y && image.z && image.id),
      },
    }
  );

  const hasImages = useMemo(
    () => (captureJobImagesQuery.data?.length ?? 0) > 0,
    [captureJobImagesQuery.data]
  );

  useEffect(() => {
    if (
      !viewerController?.info?.sceneLoaded ||
      !viewerController?.info?.viewerReady
    ) {
      return;
    }

    viewerController?.removeCameraPositions();
    viewerController?.setCameraPositionsVisible?.(false);
    if (captureJobImagesQuery.data?.length) {
      viewerController?.loadCameraPositions?.(captureJobImagesQuery.data);
    }
  }, [
    viewerController?.info?.sceneLoaded,
    viewerController?.info?.viewerReady,
    captureJobImagesQuery.data,
  ]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady) {
      return;
    }

    if (viewerController?.info?.cameraPositionsVisible) {
      viewerController?.threeViewer?.showImageMarkers();
    } else {
      viewerController?.threeViewer?.hideImageMarkers();
    }
  }, [viewerController?.info?.cameraPositionsVisible]);

  useEffect(() => {
    // go through all annotation3d and see if they are all not visible
    const allNotVisible = annotations.every(
      (annotation) => !annotation.isVisible
    );
    if (allNotVisible) {
      setToggleAll3dAnnotations(!allNotVisible);
    } else {
      setToggleAll3dAnnotations(true);
    }
  }, [annotations, annotations3dGeometry, annotations2dGeometry]);

  const floatingButtonClassName =
    'bg-white shadow-lg rounded-lg flex flex-row p-1 gap-1 items-center';

  return (
    <div
      className="absolute top-4 left-4 flex flex-row gap-2"
      style={{
        zIndex: 2,
      }}
    >
      {!shared && (
        <div className={floatingButtonClassName}>
          <CloudInput.Toggle
            mobileViewMode={isMobile}
            disabled={!hasImages || !viewerController?.info?.sceneLoaded}
            id="camera-positions-toggle"
            leadingIcon="camera"
            tooltip="Toggle Camera Positions"
            tooltipPosition="bottom"
            value={
              hasImages &&
              (viewerController?.info.cameraPositionsVisible ?? false)
            }
            setValue={() => {
              if (!hasImages && !viewerController?.info?.sceneLoaded) {
                return;
              }
              viewerController?.setCameraPositionsVisible?.(
                !viewerController.info.cameraPositionsVisible
              );
            }}
          />
        </div>
      )}
      {viewerController?.info.sceneLoaded && (annotations?.length ?? 0) > 0 && (
        <div className={floatingButtonClassName}>
          <CloudInput.Toggle
            mobileViewMode={isMobile}
            disabled={!viewerController?.info?.sceneLoaded}
            id="annotations-toggle"
            leadingIcon="location-dot"
            tooltip="Show Annotations"
            tooltipPosition="bottom"
            value={toggleAll3dAnnotations}
            setValue={() => {
              setToggleAll3dAnnotations(!toggleAll3dAnnotations);
              viewerController?.toggleAll3dAnnotations?.(
                !toggleAll3dAnnotations
              );
              if (!toggleAll3dAnnotations && !shared) {
                setSearch(
                  (prev) => {
                    prev.set('details', 'annotations');
                    return prev;
                  },
                  {
                    replace: true,
                  }
                );
              }
            }}
          />
        </div>
      )}

      {hasClassification && (
        <div className={floatingButtonClassName}>
          <CloudButton.Icon
            id="model-material-toggle-gaussian-splats"
            toggled={isRGBA}
            onClick={() => {
              if (hasClassification) {
                viewerController?.setPointStyle(MaterialType.RGBA);
              }
            }}
            leadingIcon="image"
            {...(isMobile
              ? {
                  tooltip: 'Photorealistic',
                  tooltipPosition: 'bottom',
                }
              : { label: 'Photorealistic' })}
          />
          <CloudButton.Icon
            id="model-material-toggle-gaussian-points"
            toggled={isClassified}
            onClick={() => {
              if (hasClassification) {
                viewerController?.setPointStyle(MaterialType.CLASSIFICATION);
              }
            }}
            leadingIcon="swatchbook"
            {...(isMobile
              ? {
                  tooltip: 'Classified',
                  tooltipPosition: 'bottom',
                }
              : { label: 'Classified' })}
          />
        </div>
      )}

      {!(
        isMobile ||
        shared ||
        viewerController?.info?.cropBoxEditingActive
      ) && (
        <div className={floatingButtonClassName}>
          <CloudButton.Icon
            id="model-crop-button"
            onClick={async () => {
              await viewerController?.toggleCropEditingTool();
            }}
            leadingIcon="crop"
            tooltip="Crop"
          />
        </div>
      )}
    </div>
  );
};
