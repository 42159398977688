import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIClient, APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { JobTypeCategory, LdFlags } from '@agerpoint/types';
import {
  Sort,
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminJobTypesQueries } from './admin-job-types-queries';

export const AdminJobTypesNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  usePageTitle(() => 'Platform - Job Types', []);

  const isViteApp = useIsViteApp();

  const formValidation = useFormValidation();
  const { permissions } = useGlobalStore();

  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );

  useEffect(() => {
    if (!canManageAnalyticRequests) {
      if (isViteApp) {
        navigate('/app/admin/platform/job-types' + params);
      } else {
        navigate('/admin/job-types' + params);
      }
    }
  }, [canManageAnalyticRequests]);

  const [jobType, setJobType] = useState<Partial<APIModels.JobType>>({
    requiresHighRes: false,
  });

  const [selectedCategory, setSelectedCategory] = useState<string>();

  const {
    jobTypePostMutation,
    mosaicEnginesQuery,
    mlModelsQuery,
    pipelinesQuery,
  } = useAdminJobTypesQueries();

  useEffect(() => {
    setJobType((prev) => ({
      ...prev,
      categoryName: selectedCategory,
      pipelineId:
        selectedCategory === JobTypeCategory.PipelineJob
          ? prev.pipelineId
          : undefined,
      jobParameters:
        selectedCategory === JobTypeCategory.OperationJob ||
        selectedCategory === JobTypeCategory.CaptureJob ||
        selectedCategory === JobTypeCategory.PipelineJob
          ? undefined
          : prev.jobParameters,
      nerfConfig:
        selectedCategory === JobTypeCategory.OperationJob
          ? undefined
          : prev.nerfConfig,
      requiresHighRes:
        selectedCategory === JobTypeCategory.CaptureJob ? true : false,
    }));

    if (selectedCategory === JobTypeCategory.OperationJob) {
      setSelectedMlModel(undefined);
      setSelectedMosaicEngine(undefined);
      setSelectedPipeline(undefined);
    }

    if (selectedCategory === JobTypeCategory.CaptureExtractionJob) {
      setSelectedPipeline(undefined);
    }

    if (selectedCategory === JobTypeCategory.CaptureJob) {
      setSelectedPipeline(undefined);
    }
  }, [selectedCategory]);

  const [selectedMosaicEngine, setSelectedMosaicEngine] =
    useState<APIModels.MosaicEngine>();

  useEffect(() => {
    setJobType((prev) => ({
      ...prev,
      mosaicEngineId: selectedMosaicEngine?.id,
    }));
  }, [selectedMosaicEngine]);

  const [selectedMlModel, setSelectedMlModel] = useState<APIModels.MlModel>();

  useEffect(() => {
    setJobType((prev) => ({
      ...prev,
      mlModelId: selectedMlModel?.id,
    }));
  }, [selectedMlModel]);

  const [selectedPipeline, setSelectedPipeline] =
    useState<APIModels.Pipeline>();

  useEffect(() => {
    setJobType((prev) => ({
      ...prev,
      nerfConfig: undefined,
      pipelineId: selectedPipeline?.id,
    }));

    setSelectedMlModel(undefined);
    setSelectedMosaicEngine(undefined);
  }, [selectedPipeline]);

  const categories = useMemo(
    () => [
      JobTypeCategory.OperationJob,
      JobTypeCategory.CaptureJob,
      JobTypeCategory.CaptureExtractionJob,
      JobTypeCategory.PipelineJob,
    ],
    []
  );

  const createJobType = useCallback(async () => {
    if (jobTypePostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    jobTypePostMutation.mutate({
      data: jobType,
    });
  }, [formValidation, jobType, jobTypePostMutation]);

  const mosaicEngineOptions: APIClient.MosaicEngine[] = useMemo(() => {
    if (mosaicEnginesQuery.data === undefined) {
      return [];
    }

    if (selectedCategory !== JobTypeCategory.PipelineJob) {
      return mosaicEnginesQuery.data;
    }

    return mosaicEnginesQuery.data.filter(
      (engine) =>
        engine.pipelineId === selectedPipeline?.id ||
        engine.pipelineId === null ||
        engine.pipelineId === undefined
    );
  }, [mosaicEnginesQuery.data, selectedCategory, selectedPipeline]);

  const mlModelsOptions: APIModels.MlModel[] = useMemo(() => {
    if (mlModelsQuery.data === undefined) {
      return [];
    }

    if (selectedCategory !== JobTypeCategory.PipelineJob) {
      return mlModelsQuery.data.sort((a, b) =>
        Sort.sortByAtt(a, b, 'displayName')
      );
    }

    return mlModelsQuery.data
      .filter(
        (model) =>
          model.pipelineId === selectedPipeline?.id ||
          model.pipelineId === null ||
          model.pipelineId === undefined
      )
      .sort((a, b) => Sort.sortByAtt(a, b, 'displayName'));
  }, [mlModelsQuery.data, selectedCategory, selectedPipeline]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Job Types',
              path: isViteApp
                ? '/app/admin/platform/job-types'
                : '/admin/job-types',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-job-type-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/job-types' + params);
            } else {
              navigate('/admin/job-types' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Job Type</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="job-type-name"
          label={<Input.Label label="Name" required />}
          value={jobType.name ?? ''}
          setValue={(name) => setJobType({ ...jobType, name })}
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
          error={<Input.Error error={formValidation.errors['job-type-name']} />}
        />
        <Input.Text.Single
          id="job-type-description"
          label={<Input.Label label="Description" />}
          value={jobType.description ?? ''}
          setValue={(description) => setJobType({ ...jobType, description })}
        />
        <Input.Select.Single
          id="job-type-category"
          label={<Input.Label label="Category" required />}
          value={selectedCategory}
          title="Category"
          setValue={setSelectedCategory}
          options={categories}
          optionBuilder={(category) => category.split(/(?=[A-Z])/).join(' ')}
          search={false}
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Category')],
          }}
          error={
            <Input.Error error={formValidation.errors['job-type-category']} />
          }
        />
        {selectedCategory === JobTypeCategory.PipelineJob && (
          <Input.Select.Single
            id="job-type-pipeline"
            label={<Input.Label label="Pipeline" required />}
            value={selectedPipeline}
            title="Pipeline"
            setValue={setSelectedPipeline}
            options={pipelinesQuery.data ?? []}
            optionBuilder={(pipeline) => pipeline?.name ?? 'Unknown'}
            loading={pipelinesQuery.isLoading}
            error={
              <Input.Error error={formValidation.errors['job-type-pipeline']} />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Pipeline')],
            }}
          />
        )}
        {selectedCategory === JobTypeCategory.CaptureExtractionJob && (
          <div className="w-full">
            <Input.Checkbox
              id="job-type-requires-high-res"
              label={<Input.Label label="Requires High Res" />}
              value={jobType.requiresHighRes ?? false}
              setValue={(requiresHighRes) =>
                setJobType({ ...jobType, requiresHighRes })
              }
            />
          </div>
        )}
        {(selectedCategory === JobTypeCategory.CaptureJob ||
          selectedCategory === JobTypeCategory.CaptureExtractionJob ||
          (selectedCategory === JobTypeCategory.PipelineJob &&
            selectedPipeline !== undefined)) && (
          <Input.Select.Single
            id="job-type-mosaic-engine"
            label={
              <Input.Label
                label="Mosaic Engine"
                required={jobType?.requiresHighRes ?? false}
              />
            }
            value={selectedMosaicEngine}
            title="Mosaic Engine"
            setValue={(mosaicEngine) => setSelectedMosaicEngine(mosaicEngine)}
            options={mosaicEngineOptions}
            optionBuilder={(mosaicEngine) =>
              mosaicEngine?.displayName ?? mosaicEngine?.name ?? 'Unknown'
            }
            loading={mosaicEnginesQuery.isLoading}
            error={
              <Input.Error
                error={formValidation.errors['job-type-mosaic-engine']}
              />
            }
            validation={{
              validationState: formValidation,
              validators:
                jobType?.requiresHighRes ?? false
                  ? [Input.validators.required('Mosaic Engine')]
                  : [],
            }}
          />
        )}
        {(selectedCategory === JobTypeCategory.CaptureJob ||
          selectedCategory === JobTypeCategory.CaptureExtractionJob ||
          (selectedCategory === JobTypeCategory.PipelineJob &&
            selectedPipeline !== undefined)) && (
          <Input.Select.Single
            id="job-type-ml-model"
            label={<Input.Label label="ML Model" />}
            value={selectedMlModel}
            title="ML Model"
            setValue={(mlModel) => setSelectedMlModel(mlModel)}
            options={mlModelsOptions}
            optionBuilder={(mlModel) =>
              `${mlModel?.displayName ?? mlModel?.name ?? 'Unknown'} ${
                mlModel.version ? `(v${mlModel.version})` : ''
              }`.trim()
            }
            loading={mlModelsQuery.isLoading}
          />
        )}
        {(selectedCategory === JobTypeCategory.CaptureJob ||
          selectedCategory === JobTypeCategory.CaptureExtractionJob ||
          (selectedCategory === JobTypeCategory.PipelineJob &&
            selectedPipeline !== undefined)) && (
          <Input.Text.Area
            id="job-type-3d-model-parameters"
            label={<Input.Label label="3D Model Parameters" />}
            value={jobType.nerfConfig ?? ''}
            setValue={(nerfConfig) => setJobType({ ...jobType, nerfConfig })}
            error={
              <Input.Error
                error={formValidation.errors['job-type-3d-model-parameters']}
              />
            }
            rows={5}
            validation={{
              validationState: formValidation,
              validators: [Input.validators.validJson],
            }}
          />
        )}
        {selectedCategory === JobTypeCategory.CaptureExtractionJob && (
          <Input.Text.Area
            id="job-type-apf-parameters"
            label={<Input.Label label="APF Parameters" />}
            value={jobType?.jobParameters ?? ''}
            setValue={(jobParameters) =>
              setJobType({ ...jobType, jobParameters })
            }
            error={
              <Input.Error
                error={formValidation.errors['job-type-apf-parameters']}
              />
            }
            rows={5}
            validation={{
              validationState: formValidation,
              validators: [Input.validators.validJson],
            }}
            readOnly={!canManageAnalyticRequests}
          />
        )}
        <div className="w-full flex flex-row justify-end items-center py-4">
          <Button.Primary
            id="job-types-create-button"
            label="Create"
            onClick={createJobType}
            loading={jobTypePostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
