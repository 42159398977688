import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { useFormValidation, useIsViteApp } from '@agerpoint/utilities';

import { useOpsPipelineCapturesQueries } from './pipeline-captures-queries';

export const PipelineCapturesHDMLPipeline = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  const queries = location.search;

  const isViteApp = useIsViteApp();

  const formValidation = useFormValidation();

  const captureIds = useMemo(() => {
    const searchParams = new URLSearchParams(queries);
    const captureIds = searchParams.get('captures');
    if (captureIds === null) {
      return undefined;
    }

    const captureIdsArray = captureIds.split(',').map(Number);

    if (
      captureIds.length === 0 ||
      captureIdsArray.some((id) => !Number.isSafeInteger(id))
    ) {
      return undefined;
    }

    return captureIdsArray;
  }, [queries]);

  useEffect(() => {
    if (captureIds === undefined) {
      if (isViteApp) {
        navigate('/app/admin/pipelines/captures' + params);
      } else {
        navigate('/ops/pipeline/captures' + params);
      }
    }
  }, [captureIds]);

  const [selectedMlModel, setSelectedMlModel] = useState<APIModels.MlModel>();
  const [selectedMosaicEngine, setSelectedMosaicEngine] =
    useState<APIModels.MosaicEngine>();
  const [json, setJson] = useState<string>('');

  const {
    capturesByIdsQueries,
    mosaicEnginesQuery,
    mlModelsQuery,
    runPipelineForCapturesMutation,
  } = useOpsPipelineCapturesQueries({
    captureIds,
  });

  const isGaussianMosaicEngine = useCallback(
    (engine?: APIModels.MosaicEngine) => {
      if (engine === undefined) {
        return false;
      }
      return engine.name === 'g1' || engine.name === 'g2';
    },
    []
  );

  const captures = useMemo(() => {
    if (capturesByIdsQueries.some((query) => query.isLoading)) {
      return undefined;
    }

    return capturesByIdsQueries
      .filter((query) => !query.isError)
      .map((query) => query.data) as APIModels.Capture[];
  }, [capturesByIdsQueries]);

  const excludeCapture = useCallback(
    (capture: APIModels.Capture) => {
      const confirm = window.confirm(
        `Are you sure you want to exclude this capture?`
      );
      if (!confirm) {
        return;
      }

      let newCaptureIds = captureIds
        ?.filter((id) => id !== capture.id)
        .map((id) => id.toString())
        .filter((x) => x.length > 0)
        .join(',');

      if (newCaptureIds === undefined || newCaptureIds.length === 0) {
        if (isViteApp) {
          navigate('/app/admin/pipelines/captures' + params);
        } else {
          navigate('/ops/pipeline/captures' + params);
        }
        return;
      }

      newCaptureIds = encodeURIComponent(newCaptureIds);

      navigate(
        isViteApp
          ? `/app/admin/pipelines/captures/hd-ml-pipeline?captures=${newCaptureIds}`
          : `/ops/pipeline/captures/hd-ml-pipeline?captures=${newCaptureIds}`,
        {
          state: {
            params,
          },
        }
      );
    },
    [captureIds, params, navigate]
  );

  const initiateJob = useCallback(async () => {
    if (runPipelineForCapturesMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    if (captures === undefined || captures.length === 0) {
      return;
    }

    runPipelineForCapturesMutation.mutate({
      captures: captures,
      mosaicEngine: selectedMosaicEngine as APIModels.MosaicEngine,
      mlModel: selectedMlModel as APIModels.MlModel,
      modelParameters: json,
    });
  }, [
    formValidation,
    captures,
    runPipelineForCapturesMutation,
    json,
    selectedMlModel,
    selectedMosaicEngine,
  ]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-y-auto">
      <div className="px-4">
        {isViteApp ? (
          <BreadCrumbs
            items={[
              {
                label: 'Pipelines',
                path: '/app/admin/pipelines',
              },
              {
                label: 'Captures',
                path: '/app/admin/pipelines/captures',
                params,
                additionalState: {
                  preselectedCaptureIds: captureIds,
                },
              },
            ]}
          />
        ) : (
          <BreadCrumbs
            items={[
              {
                label: 'Operations',
                path: '/ops',
              },
              {
                label: 'Pipelines',
                path: '/ops/pipeline',
              },
              {
                label: 'Captures',
                path: '/ops/pipeline/captures',
                params,
                additionalState: {
                  preselectedCaptureIds: captureIds,
                },
              },
            ]}
          />
        )}
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="capture-hd-jobs-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/pipelines/captures' + params, {
                state: {
                  preselectedCaptureIds: captureIds,
                },
              });
            } else {
              navigate('/ops/pipeline/captures' + params, {
                state: {
                  preselectedCaptureIds: captureIds,
                },
              });
            }
          }}
        />
        <h1 className="text-3xl font-bold">HD and ML Pipeline</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <div className="w-full flex flex-row font-bold pb-2">
          Review the selected captures based on which the pipeline will be
          triggered.
        </div>
        <div className="h-48 rounded-md border border-gray-500">
          <Datatable
            rowHeight={30}
            data={captures ?? []}
            loading={capturesByIdsQueries.some((query) => query.isLoading)}
            columns={[
              {
                label: 'ID',
                value: (row) => row?.id,
                flex: 1,
              },
              {
                label: 'Name',
                value: (row) => row?.captureName,
                flex: 4,
              },
              {
                label: '',
                style: {
                  bodyStyle: 'flex justify-end',
                },
                value: (row, index) => (
                  <div className="p-1">
                    <Button.Icon
                      id={`remove-capture-button-${index}`}
                      icon={faXmark}
                      onClick={() => {
                        excludeCapture(row);
                      }}
                    />
                  </div>
                ),
              },
            ]}
            style={{
              ...dataTableAgerStyle,
            }}
            error={
              capturesByIdsQueries.every((query) => query.isError)
                ? {
                    title: 'There was a problem loading captures',
                    message: 'Try refreshing the page',
                    action: () =>
                      capturesByIdsQueries.forEach((query) => query.refetch()),
                  }
                : undefined
            }
          />
        </div>
        <Input.Select.Single
          id="mosaic-engine-select"
          title="Mosaic Engine"
          options={mosaicEnginesQuery.data ?? []}
          optionBuilder={(o) => o.displayName ?? o.name ?? 'Unknown'}
          value={selectedMosaicEngine}
          setValue={(value) => {
            if (isGaussianMosaicEngine(value)) {
              setSelectedMlModel(undefined);
            }

            setSelectedMosaicEngine(value);
          }}
          label={<Input.Label label="Mosaic Engine" required />}
          loading={mosaicEnginesQuery.isLoading}
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Mosaic Engine')],
          }}
          error={
            <Input.Error
              error={formValidation.errors['mosaic-engine-select']}
            />
          }
        />
        <Input.Select.Single
          id="ml-model-select"
          title="ML Model"
          options={mlModelsQuery.data ?? []}
          optionBuilder={(mlModel) =>
            `${mlModel?.displayName ?? mlModel?.name ?? 'Unknown'} ${
              mlModel.version ? `(v${mlModel.version})` : ''
            }`.trim()
          }
          value={selectedMlModel}
          setValue={setSelectedMlModel}
          label={
            <Input.Label
              label={
                isGaussianMosaicEngine(selectedMosaicEngine)
                  ? 'ML Model (disabled for this Mosaic Engine)'
                  : 'ML Model'
              }
            />
          }
          loading={mlModelsQuery.isLoading}
          disabled={isGaussianMosaicEngine(selectedMosaicEngine)}
        />
        <Input.Text.Area
          label={<Input.Label label="3D Model Parameters" />}
          id="3d-model-parameters"
          value={json}
          setValue={setJson}
          error={
            <Input.Error
              error={formValidation.errors['capture-job-3d-model-parameters']}
            />
          }
          rows={6}
          validation={{
            validationState: formValidation,
            validators: [Input.validators.validJson],
          }}
        />
        <div className="flex flex-row justify-end py-4">
          <Button.Primary
            id="initiate-job-button"
            label="Initiate Job"
            onClick={initiateJob}
            loading={runPipelineForCapturesMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
