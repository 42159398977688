import {
  faCircleNotch,
  faDownload,
  faSunPlantWilt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels, formatDateAndTime } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  ContextMenu,
  Datatable,
  dataTableAgerStyle,
  getUsername,
} from '@agerpoint/feature';
import { CaptureJobTypes } from '@agerpoint/types';
import {
  createFilename,
  useFormValidation,
  useIsViteApp,
  useToasts,
} from '@agerpoint/utilities';

import { useOpsPipelineCapturesQueries } from './pipeline-captures-queries';

export const PipelineCapturesHDJobsList = () => {
  const { captureQuery, captureJobsQuery, usersLookupTable } =
    useOpsPipelineCapturesQueries({});

  const toasts = useToasts();

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);

  const isViteApp = useIsViteApp();

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="px-4">
        {isViteApp ? (
          <BreadCrumbs
            items={[
              {
                label: 'Pipelines',
                path: '/app/admin/pipelines',
              },
              {
                label: 'Captures',
                path: '/app/admin/pipelines/captures',
                params,
              },
            ]}
          />
        ) : (
          <BreadCrumbs
            items={[
              {
                label: 'Operations',
                path: '/ops',
              },
              {
                label: 'Pipelines',
                path: '/ops/pipeline',
              },
              {
                label: 'Captures',
                path: '/ops/pipeline/captures',
                params,
              },
            ]}
          />
        )}
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="capture-hd-jobs-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/pipelines/captures' + params);
            } else {
              navigate('/ops/pipeline/captures' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">{captureQuery.data?.captureName}</h1>
      </div>
      <div className="p-4 h-full" ref={setWrapperRef}>
        <Datatable
          id="pipeline-captures-hd-jobs-datatable"
          data={captureJobsQuery.data ?? []}
          loading={captureJobsQuery.isLoading}
          columns={[
            {
              label: 'Name',
              value: (row, index) => (
                <CaptureJobNameInput captureJob={row} index={index} />
              ),
              flex: 2,
              style: {
                expandBody: true,
              },
            },
            {
              label: 'Job UUID',
              value: (row) => (
                <span
                  title={row.uuid ?? undefined}
                  className="cursor-copy hover:underline"
                  onClick={() => {
                    navigator.clipboard.writeText(row.uuid ?? '');

                    toasts.add(toasts.prepare.valueCopied('UUID'));
                  }}
                >
                  {row.uuid}
                </span>
              ),
              flex: 1,
            },
            {
              label: 'Type',
              value: (row) => CaptureJobTypes[row?.captureJobTypeId ?? 0],
            },
            {
              label: 'Created By',
              value: (row) => {
                if (!row.createdById) {
                  return;
                }

                if (!usersLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const user = usersLookupTable[row.createdById];
                return getUsername(user);
              },
            },
            {
              label: 'Mosaic Engine',
              value: (row) => row.mosaicEngine,
              flex: 0.5,
            },
            {
              label: 'ML Model',
              value: (row) => row.mlModel,
              flex: 0.5,
            },
            {
              label: 'Created',
              value: (row) => formatDateAndTime(row.createDatetime),
            },
            {
              label: 'Completed',
              value: (row) => formatDateAndTime(row.completedDateTime),
            },
            {
              label: 'Archived',
              value: (row, index) => (
                <CaptureJobArchiveInput captureJob={row} index={index} />
              ),
            },
            {
              label: null,
              flex: 0.25,
              value: (row) => (
                <CaptureJobActions captureJob={row} parentRef={wrapperRef} />
              ),
            },
          ]}
          style={{ ...dataTableAgerStyle, tableMinWidth: 1300 }}
          rowHeight={65}
          noResults={{
            title: 'No jobs found for this capture',
          }}
        />
      </div>
    </div>
  );
};

interface CaptureJobNameInputProps {
  captureJob: APIModels.CaptureJob;
  index: number;
}

const CaptureJobNameInput = ({
  captureJob,
  index,
}: CaptureJobNameInputProps) => {
  const [name, setName] = useState(captureJob?.name ?? '');

  const { captureJobPutMutation } = useOpsPipelineCapturesQueries({});

  const formValidation = useFormValidation();

  return (
    <div className="flex flex-row p-1 gap-1 items-start">
      <Input.Text.Single
        id={`capture-job-name-${index}`}
        value={name}
        setValue={setName}
        error={
          <Input.Error
            error={formValidation.errors[`capture-job-name-${index}`]}
          />
        }
        validation={{
          validationState: formValidation,
          validators: [Input.validators.required('Name')],
        }}
      />

      <Button.Primary
        id={`save-capture-job-name-button-${index}`}
        onClick={async () => {
          if (await formValidation.hasErrors()) {
            return;
          }

          captureJobPutMutation.mutate({
            captureId: captureJob.captureId as unknown as string,
            jobId: captureJob.id as number,
            data: {
              ...captureJob,
              name,
            },
          });
        }}
        label="Save"
        loading={captureJobPutMutation.isPending}
      />
    </div>
  );
};

interface CaptureJobActionsProps {
  captureJob: APIModels.CaptureJob;
  parentRef: HTMLDivElement | null;
}

const CaptureJobActions = ({
  captureJob,
  parentRef,
}: CaptureJobActionsProps) => {
  const toasts = useToasts();

  const GSPLAT_VIEWER_URL = 'https://gsplat.agerpoint.com/?load=';

  const { captureJobPlyUrlQuery, eptJsonQuery } = useOpsPipelineCapturesQueries(
    {
      captureJob,
    }
  );

  const plyUrl = useMemo(
    () => captureJobPlyUrlQuery.data ?? undefined,
    [captureJobPlyUrlQuery.data]
  );

  const plyViewerUrl = useMemo(() => {
    if (!plyUrl) {
      return undefined;
    }

    return GSPLAT_VIEWER_URL + encodeURIComponent(encodeURIComponent(plyUrl));
  }, [plyUrl]);

  const downloadPly = useCallback(() => {
    if (!plyUrl) {
      return;
    }

    toasts.add({
      title: 'PLY File download started.',
      type: 'info',
    });
    const link = document.createElement('a');
    link.href = plyUrl;
    link.click();
  }, [plyUrl]);

  const openPlyViewer = useCallback(() => {
    if (!plyViewerUrl) {
      return;
    }

    const link = document.createElement('a');
    link.href = plyViewerUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  }, [plyViewerUrl]);

  const downloadEpt = useCallback(async () => {
    if (!eptJsonQuery.data) return;
    toasts.add({
      title: 'EPT File download started.',
      type: 'info',
    });
    const data =
      'text/json;charset=utf-8,' +
      encodeURIComponent(await eptJsonQuery.data.text());

    const a = document.createElement('a');
    a.href = 'data:' + data;
    a.download = createFilename('ept', 'json');
    a.click();
  }, [eptJsonQuery.data]);

  return (
    <ContextMenu
      parentRef={parentRef}
      customNoItemsMessage={
        captureJob?.archived
          ? 'No Actions Available for archived capture jobs'
          : undefined
      }
      groups={[
        {
          label: 'Download',
          items: [
            {
              label: 'EPT',
              icon: <FontAwesomeIcon icon={faDownload} />,
              visible:
                !(eptJsonQuery.data === undefined) && !captureJob?.archived,
              onClick: () => {
                downloadEpt();
              },
            },
            {
              label: 'PLY',
              icon: <FontAwesomeIcon icon={faDownload} />,
              visible: !(plyUrl === undefined) && !captureJob?.archived,
              onClick: () => {
                downloadPly();
              },
            },
          ],
        },
        {
          label: 'View',
          items: [
            {
              label: 'PLY',
              icon: <FontAwesomeIcon icon={faSunPlantWilt} />,
              visible: !(plyUrl === undefined) && !captureJob?.archived,
              onClick: () => {
                openPlyViewer();
              },
            },
          ],
        },
      ]}
    />
  );
};

interface CaptureJobArchiveInputProps {
  captureJob: APIModels.CaptureJob;
  index: number;
}

const CaptureJobArchiveInput = ({
  captureJob,
  index,
}: CaptureJobArchiveInputProps) => {
  const { captureJobPutMutation } = useOpsPipelineCapturesQueries({});

  return (
    <div className="p-1">
      <Input.Select.Inline
        id={`capture-job-archive-${index}`}
        options={[true, false]}
        optionBuilder={(option) => (option ? 'Yes' : 'No')}
        value={captureJob?.archived ?? false}
        setValue={(archived) => {
          if (captureJobPutMutation.isPending) {
            return;
          }

          captureJobPutMutation.mutate({
            captureId: captureJob.captureId as unknown as string,
            jobId: captureJob.id as number,
            data: {
              ...captureJob,
              archived,
            },
          });
        }}
      />
    </div>
  );
};
