/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { ImageMosaic, ImageMosaicFilter } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getImageMosaics = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<ImageMosaic[]>(
    { url: `/api/ImageMosaics`, method: 'GET', signal },
    options
  );
};

export const getGetImageMosaicsQueryKey = () => {
  return [`/api/ImageMosaics`] as const;
};

export const getGetImageMosaicsQueryOptions = <
  TData = Awaited<ReturnType<typeof getImageMosaics>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getImageMosaics>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetImageMosaicsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getImageMosaics>>> = ({
    signal,
  }) => getImageMosaics(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getImageMosaics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetImageMosaicsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getImageMosaics>>
>;
export type GetImageMosaicsQueryError = ErrorType<void>;

export const useGetImageMosaics = <
  TData = Awaited<ReturnType<typeof getImageMosaics>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getImageMosaics>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetImageMosaicsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postImageMosaic = (
  imageMosaic: BodyType<ImageMosaic>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<ImageMosaic>(
    {
      url: `/api/ImageMosaics`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: imageMosaic,
    },
    options
  );
};

export const getPostImageMosaicMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postImageMosaic>>,
    TError,
    { data: BodyType<ImageMosaic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postImageMosaic>>,
  TError,
  { data: BodyType<ImageMosaic> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postImageMosaic>>,
    { data: BodyType<ImageMosaic> }
  > = (props) => {
    const { data } = props ?? {};

    return postImageMosaic(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostImageMosaicMutationResult = NonNullable<
  Awaited<ReturnType<typeof postImageMosaic>>
>;
export type PostImageMosaicMutationBody = BodyType<ImageMosaic>;
export type PostImageMosaicMutationError = ErrorType<void>;

export const usePostImageMosaic = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postImageMosaic>>,
    TError,
    { data: BodyType<ImageMosaic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postImageMosaic>>,
  TError,
  { data: BodyType<ImageMosaic> },
  TContext
> => {
  const mutationOptions = getPostImageMosaicMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getImageMosaicsById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<ImageMosaic>(
    {
      url: `/api/ImageMosaics/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetImageMosaicsByIdQueryKey = (id: number) => {
  return [`/api/ImageMosaics/${id}`] as const;
};

export const getGetImageMosaicsByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getImageMosaicsById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getImageMosaicsById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetImageMosaicsByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getImageMosaicsById>>
  > = ({ signal }) => getImageMosaicsById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getImageMosaicsById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetImageMosaicsByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getImageMosaicsById>>
>;
export type GetImageMosaicsByIdQueryError = ErrorType<void>;

export const useGetImageMosaicsById = <
  TData = Awaited<ReturnType<typeof getImageMosaicsById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getImageMosaicsById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetImageMosaicsByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteImageMosaic = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/ImageMosaics/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteImageMosaicMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteImageMosaic>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteImageMosaic>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteImageMosaic>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteImageMosaic(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteImageMosaicMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteImageMosaic>>
>;

export type DeleteImageMosaicMutationError = ErrorType<void>;

export const useDeleteImageMosaic = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteImageMosaic>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteImageMosaic>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteImageMosaicMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putImageMosaic = (
  id: number,
  imageMosaic: BodyType<ImageMosaic>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/ImageMosaics/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: imageMosaic,
    },
    options
  );
};

export const getPutImageMosaicMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putImageMosaic>>,
    TError,
    { id: number; data: BodyType<ImageMosaic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putImageMosaic>>,
  TError,
  { id: number; data: BodyType<ImageMosaic> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putImageMosaic>>,
    { id: number; data: BodyType<ImageMosaic> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putImageMosaic(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutImageMosaicMutationResult = NonNullable<
  Awaited<ReturnType<typeof putImageMosaic>>
>;
export type PutImageMosaicMutationBody = BodyType<ImageMosaic>;
export type PutImageMosaicMutationError = ErrorType<void>;

export const usePutImageMosaic = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putImageMosaic>>,
    TError,
    { id: number; data: BodyType<ImageMosaic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putImageMosaic>>,
  TError,
  { id: number; data: BodyType<ImageMosaic> },
  TContext
> => {
  const mutationOptions = getPutImageMosaicMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPagedImageMosaics = (
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<ImageMosaic[]>(
    {
      url: `/api/ImageMosaics/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedImageMosaicsQueryKey = (skip: number, take: number) => {
  return [`/api/ImageMosaics/skip/${skip}/take/${take}`] as const;
};

export const getGetPagedImageMosaicsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedImageMosaics>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedImageMosaics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPagedImageMosaicsQueryKey(skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedImageMosaics>>
  > = ({ signal }) => getPagedImageMosaics(skip, take, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedImageMosaics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedImageMosaicsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedImageMosaics>>
>;
export type GetPagedImageMosaicsQueryError = ErrorType<void>;

export const useGetPagedImageMosaics = <
  TData = Awaited<ReturnType<typeof getPagedImageMosaics>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedImageMosaics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedImageMosaicsQueryOptions(skip, take, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredPagedImageMosaics = (
  skip: number,
  take: number,
  imageMosaicFilter: BodyType<ImageMosaicFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<ImageMosaic[]>(
    {
      url: `/api/ImageMosaics/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: imageMosaicFilter,
    },
    options
  );
};

export const getGetFilteredPagedImageMosaicsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedImageMosaics>>,
    TError,
    { skip: number; take: number; data: BodyType<ImageMosaicFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPagedImageMosaics>>,
  TError,
  { skip: number; take: number; data: BodyType<ImageMosaicFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPagedImageMosaics>>,
    { skip: number; take: number; data: BodyType<ImageMosaicFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPagedImageMosaics(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPagedImageMosaicsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredPagedImageMosaics>>
>;
export type GetFilteredPagedImageMosaicsMutationBody =
  BodyType<ImageMosaicFilter>;
export type GetFilteredPagedImageMosaicsMutationError = ErrorType<void>;

export const useGetFilteredPagedImageMosaics = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedImageMosaics>>,
    TError,
    { skip: number; take: number; data: BodyType<ImageMosaicFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPagedImageMosaics>>,
  TError,
  { skip: number; take: number; data: BodyType<ImageMosaicFilter> },
  TContext
> => {
  const mutationOptions =
    getGetFilteredPagedImageMosaicsMutationOptions(options);

  return useMutation(mutationOptions);
};
