import { LatLngAlt } from '@agerpoint/types';

const degreesToMeters = (degrees: number) => degrees * 111139;

export const llaToEnu = (point: LatLngAlt, origin: LatLngAlt): LatLngAlt => {
  // Convert latitude and longitude differences from degrees to meters
  const east = degreesToMeters(point.lng - origin.lng);
  const north = degreesToMeters(point.lat - origin.lat);
  // Assuming altitude is already in meters and represents the 'up' component
  const up = point.alt - origin.alt;

  return { lat: north, lng: east, alt: up };
};
