import { faSort } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, useContext } from 'react';

import {
  ICloudDatatableColumn,
  ICloudDatatableColumnSorting,
  RefContext,
  StyleContext,
  isClickableColumn,
  isSortableColumn,
} from '../datatable.types';
import { useCloudDatatableHandleHeaderRightMarginAdjustment } from '../datatable.utilities';

interface IHeaders<T> {
  columns: ICloudDatatableColumn<T>[];
  sort?: ICloudDatatableColumnSorting;
  setSort?: (sort: ICloudDatatableColumnSorting) => void;
}

export const Headers = <T,>({ columns, sort, setSort }: IHeaders<T>) => {
  const style = useContext(StyleContext);
  const refs = useContext(RefContext);

  const { headerRightMarginAdjustment } =
    useCloudDatatableHandleHeaderRightMarginAdjustment({
      bodyRef: refs?.bodyRef ?? null,
      headersRef: refs?.headersRef ?? null,
    });

  return (
    <div
      ref={refs?.setHeadersRef}
      className={`w-full flex flex-row ${style?.headers ?? ''}`}
      style={{
        minWidth: style?.minWidth,
      }}
    >
      {columns.map((c, i) => {
        if (c.visible === false) {
          return null;
        }
        const onClick =
          isSortableColumn(c) && setSort
            ? () => {
                setSort({
                  key: c.sortKey,
                  order:
                    sort?.key === c.sortKey && sort?.order === 'desc'
                      ? 'asc'
                      : 'desc',
                });
              }
            : isClickableColumn(c)
            ? c.onClick
            : undefined;

        const className = `flex flex-row gap-1 items-center ${
          onClick ? 'cursor-pointer hover-overlay-5' : ''
        } ${c.style?.header ?? ''} ${style?.header ?? ''}`.trim();

        let wrapperStyle: CSSProperties = {};
        if (c.fixed) {
          wrapperStyle = {
            width: c.fixed,
            minWidth: c.fixed,
            maxWidth: c.fixed,
          };
        } else {
          wrapperStyle = {
            flex: c.flex ?? 1,
            minWidth: c.flexMinWidth,
          };
        }

        return (
          <div
            key={i}
            onClick={onClick}
            style={wrapperStyle}
            className={className}
          >
            {c.header}
            {isSortableColumn(c) && sort?.key === c.sortKey && (
              <FontAwesomeIcon
                icon={faSort}
                className={`text-primary ${
                  sort?.order === 'desc' ? 'fa-swap-opacity' : ''
                }`}
              />
            )}
          </div>
        );
      })}
      <div style={{ width: `${headerRightMarginAdjustment ?? 0}px` }}></div>
    </div>
  );
};
