import { useMemo } from 'react';

import { InputIdContext } from '../input-id-context';
import { InputStyle } from '../input-style';

interface InputSelectInlineProps<T> {
  /**
   * The unique identifier for the input element.
   */
  id: string;
  /**
   * The error message to display.
   */
  error?: React.ReactNode;
  /**
   * The label to display.
   */
  label?: React.ReactNode;
  /**
   * The CSS class name for the component.
   */
  className?: string;
  /**
   * The options to display in the select input.
   */
  options: T[];
  /**
   * A function that builds the display string for each option.
   */
  optionBuilder: (option: T) => React.ReactNode;
  /**
   * The currently selected value.
   */
  value: T;
  /**
   * A callback function to set the selected value.
   */
  setValue: (value: T) => void;
  /**
   * The maximum width of the component.
   */
  maxWidth?: string;
  /**
   * Specifies whether the component is disabled.
   */
  disabled?: boolean;
  /**
   * The style of the component.
   */
  style?: InputStyle;
  /**
   * Specifies whether the component should stretch to fill the available width.
   */
  stretch?: boolean;
  /**
   * A custom comparison function to determine if an option is selected.
   */
  compareFn?: (a: T, b: T) => boolean;

  /**
   * Specifies whether the component is read-only.
   */
  readOnly?: boolean;
}

function InputSelectInline<T>({
  id,
  error,
  label,
  options,
  optionBuilder,
  value,
  setValue,
  className,
  maxWidth,
  disabled = false,
  style = InputStyle.default,
  stretch = false,
  compareFn,
  readOnly,
}: InputSelectInlineProps<T>) {
  const wrapperClassName = useMemo(() => {
    if (style === InputStyle.cloud) {
      return `flex flex-row border border-gray-border rounded-lg overflow-hidden`;
    }

    return `flex flex-row`;
  }, [style]);

  const optionClassName = useMemo(() => {
    const c = `${className}`;

    if (style === InputStyle.default) {
      return `text-sm flex-auto transition-colors px-2 first:rounded-l last:rounded-r
      flex items-center justify-center ${c ?? ''}`.trim();
    }

    if (style === InputStyle.cloud) {
      return `flex items-center justify-center ${c ?? ''}`.trim();
    }

    return `text-xs flex-auto transition-colors px-2 first:rounded-l last:rounded-r
    flex items-center justify-center ${c ?? ''}`.trim();
  }, [className, style]);

  const selectedClassName = useMemo(() => {
    if (style === InputStyle.cloud) {
      return 'text-primary bg-primary bg-opacity-10';
    }

    return 'text-gray-900 bg-green-100 ring-1 ring-green';
  }, [style]);

  const notSelectedClassname = useMemo(() => {
    if (style === InputStyle.cloud) {
      return 'text-gray-iconPrimary cursor-pointer  hover-overlay-5';
    }

    return `text-gray-500 ${
      readOnly ? '' : 'cursor-pointer hover:bg-gray-50'
    } bg-white ring-1 ring-gray-500`;
  }, [style, readOnly]);

  return (
    <InputIdContext.Provider value={id}>
      <div
        className={`relative select-none  ${stretch ? 'w-full' : ''} ${
          disabled ? 'pointer-events-none opacity-50' : ''
        }`.trim()}
        style={{ maxWidth }}
      >
        <div className="flex flex-col">
          {label ?? null}
          <div className={wrapperClassName}>
            {options.map((o, index) => {
              let isSelected = false;
              if (value !== undefined) {
                if (compareFn) {
                  isSelected = compareFn(value, o);
                } else {
                  isSelected = value === o;
                }
              }

              return (
                <div
                  key={index}
                  className={`${optionClassName} ${
                    isSelected ? selectedClassName : notSelectedClassname
                  }`}
                  style={{
                    height:
                      style === InputStyle.default
                        ? '38px'
                        : style === InputStyle.cloud
                        ? '40px'
                        : '22px',
                    zIndex: isSelected ? 1 : 0,
                  }}
                  onClick={() => {
                    if (readOnly) {
                      return;
                    }
                    return setValue(o);
                  }}
                >
                  {optionBuilder(o)}
                </div>
              );
            })}
          </div>
          {error ?? null}
        </div>
      </div>
    </InputIdContext.Provider>
  );
}

export { InputSelectInline };
