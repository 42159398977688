import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminFarmsQueries } from './admin-farms-queries';

export const AdminFarmsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  usePageTitle(() => 'Platform - Farms', []);

  const isViteApp = useIsViteApp();

  const [farm, setFarm] = useState<Partial<APIModels.Farm>>();
  const [selectedOrganization, setSelectedOrganization] =
    useState<APIModels.Customer>();
  const [selectedCountry, setSelectedCountry] = useState<APIModels.Country>();
  const [selectedRegion, setSelectedRegion] = useState<APIModels.Region>();

  const formValidation = useFormValidation();

  const {
    farmQuery,
    farmPutMutation,
    regionCountryQuery,
    countriesQuery,
    countryRegionsQuery,
    organizationsQuery,
  } = useAdminFarmsQueries(selectedCountry);

  useEffect(() => {
    setFarm(farmQuery.data);
  }, [farmQuery.data]);

  useEffect(() => {
    setSelectedOrganization(
      organizationsQuery.data?.find((c) => c.id === farmQuery.data?.customerId)
    );
  }, [farmQuery.data, organizationsQuery.data]);

  useEffect(() => {
    setSelectedRegion(
      countryRegionsQuery.data?.find((c) => c.id === farmQuery.data?.regionId)
    );
  }, [farmQuery.data, countryRegionsQuery.data]);

  useEffect(() => {
    setSelectedCountry(
      countriesQuery.data?.find((c) => c.id === regionCountryQuery.data?.id)
    );
  }, [countriesQuery.data, regionCountryQuery.data]);

  useEffect(() => {
    setFarm((prev) => ({ ...prev, regionId: selectedRegion?.id }));
  }, [selectedRegion]);

  useEffect(() => {
    setFarm((prev) => ({ ...prev, customerId: selectedOrganization?.id }));
  }, [selectedOrganization]);

  const saveFarm = useCallback(async () => {
    if (farmPutMutation.isPending || farmQuery.isLoading) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    farmPutMutation.mutate({
      id: farm?.id as number,
      data: farm as APIModels.Farm,
    });
  }, [farm, formValidation, farmPutMutation, farmQuery.isLoading]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Farms',
              path: isViteApp ? '/app/admin/platform/farms' : '/admin/farms',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="farms-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/farms' + params);
            } else {
              navigate('/admin/farms' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">
          {farmQuery.data?.farmDisplayName ?? farmQuery.data?.farmName}
        </h1>
      </div>
      {farmQuery.isLoading ? (
        <PageLoadingState />
      ) : farmQuery.isError ? (
        <PageErrorState
          entityName="farm"
          pluralEntityName="farms"
          statusCode={farmQuery.error?.response?.status ?? 500}
          tryAgainCallback={() => {
            farmQuery.refetch();
            regionCountryQuery.refetch();
            countriesQuery.refetch();
            countryRegionsQuery.refetch();
          }}
          tryAgainLoading={farmQuery.isFetching}
          navigateBackCallback={() =>
            navigate(
              isViteApp
                ? '/app/admin/platform/farms' + params
                : '/admin/farms' + params
            )
          }
        />
      ) : (
        <div className="p-4 w-full flex flex-col max-w-lg gap-2">
          <Input.Text.Single
            id="farm-name-input"
            value={farm?.farmName || ''}
            setValue={(farmName) => {
              setFarm({ ...farm, farmName });
            }}
            label={<Input.Label label="Name" required />}
            error={
              <Input.Error error={formValidation.errors['farm-name-input']} />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Name')],
            }}
          />
          <Input.Text.Single
            id="farm-display-name-input"
            value={farm?.farmDisplayName || ''}
            setValue={(farmDisplayName) => {
              setFarm({ ...farm, farmDisplayName });
            }}
            label={<Input.Label label="Display Name" required />}
            error={
              <Input.Error
                error={formValidation.errors['farm-display-name-input']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Display Name')],
            }}
          />
          <Input.Select.Single
            id="farm-organization-select"
            title="Organization"
            options={organizationsQuery.data ?? []}
            optionBuilder={(o) => o?.customerDisplayName ?? 'Unknown'}
            loading={organizationsQuery.isLoading}
            value={selectedOrganization}
            setValue={setSelectedOrganization}
            label={<Input.Label label="Organization" required />}
            error={
              <Input.Error
                error={formValidation.errors['farm-organization-select']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Organization')],
            }}
          />
          <Input.Text.Single
            id="farm-address-input"
            value={farm?.address || ''}
            setValue={(address) => {
              setFarm({ ...farm, address });
            }}
            label={<Input.Label label="Address" />}
          />
          <Input.Text.Single
            id="farm-city-input"
            value={farm?.city || ''}
            setValue={(city) => {
              setFarm({ ...farm, city });
            }}
            label={<Input.Label label="City" />}
          />
          <Input.Select.Single
            id="farm-country-select"
            title="Country"
            options={countriesQuery.data ?? []}
            optionBuilder={(o) => o?.countryName ?? 'Unknown'}
            loading={regionCountryQuery.isLoading || countriesQuery.isLoading}
            value={selectedCountry}
            setValue={(c) => {
              setSelectedRegion(undefined);
              setSelectedCountry(c);
            }}
            label={<Input.Label label="Country" required />}
            error={
              <Input.Error
                error={formValidation.errors['farm-country-select']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Country')],
            }}
            compareFn={(a, b) => a?.id === b?.id}
          />
          <Input.Select.Single
            id="farm-region-select"
            title="Region"
            options={countryRegionsQuery.data ?? []}
            optionBuilder={(o) => o?.regionName ?? 'Unknown'}
            loading={
              countryRegionsQuery.isLoading || regionCountryQuery.isLoading
            }
            value={selectedRegion}
            setValue={setSelectedRegion}
            disabled={
              countryRegionsQuery.isLoading || selectedCountry === undefined
            }
            label={<Input.Label label="Region" required />}
            error={
              <Input.Error
                error={formValidation.errors['farm-region-select']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Region')],
            }}
            compareFn={(a, b) => a?.id === b?.id}
          />
          <Input.Number.Decimal
            id="farm-acres-input"
            onlyPositive
            value={farm?.totalAcres ?? undefined}
            setValue={(totalAcres) => {
              setFarm({ ...farm, totalAcres });
            }}
            label={<Input.Label label="Total Acres" />}
          />
          <div className="w-full flex flex-row justify-end py-4">
            <Button.Primary
              id="save-farm-button"
              label={'Save'}
              onClick={saveFarm}
              loading={farmPutMutation.isPending}
            />
          </div>
        </div>
      )}
    </div>
  );
};
