import { faCheck, faRocket } from '@fortawesome/pro-light-svg-icons';
import { faTurnDownLeft } from '@fortawesome/pro-regular-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { MutateMethod, UseGetReturn } from 'restful-react';

import {
  AnalyticRequest,
  Capture,
  CustomerAnalytic,
  useGetAnalyticRequestsByCaptureId,
} from '@agerpoint/api';
import { PrimaryButton } from '@agerpoint/component';
import {
  BackgroundTaskArgs,
  BackgroundTaskResult,
  CaptureJobTypes,
  EffectNames,
} from '@agerpoint/types';
import { useGlobalStore, useToasts } from '@agerpoint/utilities';

import { useBackgroundTaskManager } from '../../background-task-manager/background-task-manager';
import {
  DatatableOld,
  dataTableAgerStyle,
} from '../../datatable/datatable-old';

interface BulkCaptureAnalyticRequestTableRow {
  captureId: number;
  captureName: string;
  analyticRequestCount: number;
  matchedAnalyticRequest: AnalyticRequest | undefined;
  canRequestThisAnalytic: boolean;
  captureJobCount: number;
}

export const BulkCaptureAnalyticRequestTable = ({
  selectedCaptures,
  selectedAnalytic,
  postAnalyticRequest,
  setSelectedAnalytic,
}: {
  selectedCaptures: Capture[];
  selectedAnalytic: CustomerAnalytic;
  postAnalyticRequest: MutateMethod<void, AnalyticRequest, void, void>;
  setSelectedAnalytic: (analytic: CustomerAnalytic) => void;
}) => {
  const {
    actions: { dispatchEffect },
  } = useGlobalStore();
  const { addTaskGroup, createBackgroundTask } = useBackgroundTaskManager();
  const toasts = useToasts();

  const [analyticRequestLoading, setAnalyticRequestLoading] =
    useState<boolean>(true);
  const [analyticRequestLookup, setAnalyticRequestLookup] = useState<
    Record<number, AnalyticRequest[]>
  >({});

  const [tableRows, setTableRows] = useState<
    BulkCaptureAnalyticRequestTableRow[]
  >([]);
  const [allowedToRequest, setAllowedToRequest] = useState<
    BulkCaptureAnalyticRequestTableRow[]
  >([]);
  const [jobTriggered, setJobTriggered] = useState(false);

  const [totalCost, setTotalCost] = useState<number>(0);

  const { refetch: refetchAnalyticRequests } =
    useGetAnalyticRequestsByCaptureId({
      id: NaN,
      lazy: true,
    }) as unknown as UseGetReturn<
      AnalyticRequest[],
      void,
      void,
      { id: number }
    >;

  const fetchAnalytics = async (id: number): Promise<AnalyticRequest[]> => {
    if (!id) return [];
    try {
      const response = await refetchAnalyticRequests({
        pathParams: {
          id: id,
        },
      });
      if (!response?.length) {
        return [];
      }
      return response;
    } catch (error) {
      console.error('Error fetching analytic requests', error);
      return [];
    }
  };

  const prepSelectedCapturesForFetch = async () => {
    const arPromises = selectedCaptures
      .map((capture) => {
        return capture?.id ?? NaN;
      })
      .filter((id) => !isNaN(id))
      .map(fetchAnalytics);
    const analyticRequests = await Promise.all(arPromises);
    setAnalyticRequestLoading(false);
    const lookup = analyticRequests.reduce((acc, ar, i) => {
      const captureId = selectedCaptures[i]?.id ?? NaN;
      if (isNaN(captureId)) {
        return acc;
      }
      if (!acc[captureId]) {
        acc[captureId] = ar;
      }
      return acc;
    }, {} as Record<number, AnalyticRequest[]>);

    setAnalyticRequestLookup(lookup);
  };

  useEffect(() => {
    if (!selectedCaptures?.length) return;
    setAnalyticRequestLoading(true);
    prepSelectedCapturesForFetch();
  }, [JSON.stringify(selectedCaptures)]);

  useEffect(() => {
    if (!Object.keys(analyticRequestLookup).length) {
      return;
    }
    const rows = selectedCaptures.map(
      (capture): BulkCaptureAnalyticRequestTableRow => {
        const captureId = capture?.id ?? NaN;
        const captureName = capture?.captureName ?? '';
        const analyticRequestCount =
          analyticRequestLookup[captureId]?.length ?? 0;
        const matchedAnalyticRequest = analyticRequestLookup[captureId].find(
          (req) => {
            return req.customerAnalyticId === selectedAnalytic?.id;
          }
        );
        const nonArchivedLowResCaptureJobs = capture?.completedJobs?.filter(
          (job) =>
            !job.archived &&
            job.captureJobTypeId === CaptureJobTypes['Low Resolution']
        );
        const canRequestThisAnalytic =
          !analyticRequestLookup[captureId]?.some((req) => {
            return req.customerAnalyticId === selectedAnalytic?.id;
          }) && nonArchivedLowResCaptureJobs
            ? nonArchivedLowResCaptureJobs?.length > 0
            : false;
        return {
          captureId,
          captureName,
          captureJobCount: nonArchivedLowResCaptureJobs?.length ?? 0,
          analyticRequestCount,
          matchedAnalyticRequest,
          canRequestThisAnalytic,
        };
      }
    );
    const allowedToRequest = rows.filter((row) => row.canRequestThisAnalytic);
    setTableRows(rows);
    setAllowedToRequest(allowedToRequest);
    setTotalCost(
      allowedToRequest.length * (selectedAnalytic?.analyticCreditCost ?? 0)
    );
  }, [analyticRequestLookup, selectedCaptures]);

  const addJobsToQueue = useCallback(
    (customersAnalytic: CustomerAnalytic, captures: Capture[]) => {
      const tasks: BackgroundTaskArgs[] = [];

      captures.forEach((capture) => {
        const task: BackgroundTaskArgs = createBackgroundTask(
          `Analytic processing started`,
          async (resolve, reject, abortSignal) => {
            const requestedAnalytic: AnalyticRequest = {
              customerAnalyticId: customersAnalytic.id,
              analyticCreditCost: customersAnalytic.analyticCreditCost,
              captureIds: [capture?.id ?? NaN],
            };
            try {
              await postAnalyticRequest(requestedAnalytic, {
                signal: abortSignal,
              });
            } catch (e) {
              resolve({ type: BackgroundTaskResult.error, payload: e });
            }
            resolve({ type: BackgroundTaskResult.success });
          }
        );
        tasks.push(task);
      });
      addTaskGroup({
        groupDesc: 'Triggering Capture Analytic Job(s)',
        groupTasks: tasks,
        isCancellable: true,
        onSuccess: async () => {
          setAnalyticRequestLoading(true);
          prepSelectedCapturesForFetch();
          dispatchEffect(EffectNames.REFETCH_ANALYTIC_REQUESTS);
        },
      });
      setJobTriggered(true);
    },
    [
      postAnalyticRequest,
      createBackgroundTask,
      addTaskGroup,
      refetchAnalyticRequests,
      prepSelectedCapturesForFetch,
    ]
  );

  const prepJobsForQueue = useCallback(() => {
    if (!selectedAnalytic) {
      return;
    }
    const allowedToRequestCaptureIds = allowedToRequest.map((r) => r.captureId);
    const allowedToRequestCaptures = selectedCaptures.filter((c) => {
      if (!c.id) return false;
      return allowedToRequestCaptureIds.includes(c.id);
    });

    toasts.add({
      title: 'Analytic processing started!',
      type: 'success',
    });
    addJobsToQueue(selectedAnalytic, allowedToRequestCaptures);
  }, [selectedAnalytic, selectedCaptures, allowedToRequest, addJobsToQueue]);

  return (
    <div>
      <div className="flex w-full justify-between pb-2">
        <div className="flex flex-grow">
          Request Summary for - {selectedAnalytic?.analytic?.analyticName} -{' '}
          {selectedAnalytic?.analyticCreditCost} Credits
        </div>
        {analyticRequestLoading && (
          <div className="flex justify-end">
            <div>
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin
                className="text-green"
              />
            </div>
          </div>
        )}
      </div>

      <div className="w-full h-80">
        <DatatableOld
          data={tableRows ?? []}
          style={{
            ...dataTableAgerStyle,
            tableWrapperStyle: 'border border-gray-500 rounded-md',
            headerStyle: 'pr-1 py-1 h-full flex items-center text-xs',
            cellStyle: 'pr-1 flex items-center text-xs',
          }}
          columns={[
            {
              label: 'Capture ID',
              value: (row) => row.captureId,
            },
            {
              label: 'Capture Name',
              value: (row) => row.captureName,
              flex: 4,
            },
            {
              label: '3D Model',
              value: (row) => {
                return row.captureJobCount > 0 ? 'x' : '';
              },
              flex: 2,
            },
            {
              label: 'All Previous Requests',
              value: (row) =>
                row.captureJobCount > 0 ? row.analyticRequestCount : '',
              flex: 2,
            },
            {
              label: 'Already Requested',
              value: (row) =>
                row.captureJobCount > 0 && row.matchedAnalyticRequest
                  ? 'x'
                  : '',
              flex: 2,
            },
            {
              label: 'Can Request',
              value: (row) =>
                row.captureJobCount > 0 && row.canRequestThisAnalytic
                  ? 'x'
                  : '',
              flex: 2,
            },
          ]}
          rowHeight={25}
        />
      </div>

      <div>
        <div className="w-full flex justify-between pt-1">
          <div className="flex flex-grow flex-col">
            <div>
              <span>Selected Analytic: </span>
              <span className="font-bold">
                {selectedAnalytic?.analytic?.analyticName}
              </span>
            </div>
            <div>
              <span>Number of Requests: </span>
              <span className="font-bold">{allowedToRequest.length}</span>
              <span className="pl-2">Total Cost: </span>
              <span className="font-bold">{totalCost}</span>
            </div>
          </div>
          <div className="flex">
            <PrimaryButton
              className="mr-2"
              label="Go Back"
              icon={<FontAwesomeIcon icon={faTurnDownLeft} />}
              onClicked={() => {
                setSelectedAnalytic({});
              }}
              theme="white"
            />
            {jobTriggered && (
              <div className="flex flex-row items-center justify-center">
                <FontAwesomeIcon icon={faCheck} className="text-green" />
                <span className="ml-2">Analytic Started...</span>
              </div>
            )}
            {!jobTriggered && (
              <PrimaryButton
                onClicked={() => {
                  prepJobsForQueue();
                }}
                className=""
                icon={<FontAwesomeIcon icon={faRocket} />}
                label={<>Submit Request</>}
                disabled={allowedToRequest.length === 0}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
