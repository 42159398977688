import { formatAttribute } from './attributes-formatter';
import {
  formatDateLong,
  formatDateRegular,
  formatDateShort,
} from './date-formatter';
import {
  formatDateTimeLong,
  formatDateTimeRegular,
  formatDateTimeShort,
} from './dateTime-formatter';
import { formatFileSize } from './file-formatter';
import {
  formatAreaImperial,
  formatAreaMetric,
  formatLengthImperial,
  formatLengthMetric,
  formatVolumeImperial,
  formatVolumeMetric,
} from './measurements-formatter';

const Formatters = {
  Date: {
    Regular: formatDateRegular,
    Long: formatDateLong,
    Short: formatDateShort,
  },
  DateTime: {
    Regular: formatDateTimeRegular,
    Long: formatDateTimeLong,
    Short: formatDateTimeShort,
  },
  Measurement: {
    Metric: {
      Length: formatLengthMetric,
      Area: formatAreaMetric,
      Volume: formatVolumeMetric,
    },
    Imperial: {
      Length: formatLengthImperial,
      Area: formatAreaImperial,
      Volume: formatVolumeImperial,
    },
    Attribute: formatAttribute,
  },
  File: {
    Size: formatFileSize,
  },
};

export { Formatters };
