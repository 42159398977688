import {
  faChevronDown,
  faChevronUp,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { DatatableDefaultLoadingIndicator } from './datatable';
import {
  DatatableOldColumnSortingOptions,
  DatatableOldItemData,
  DatatableOldProps,
  DatatableOldStyle,
  isClickableColumn,
  isSortableColumn,
} from './datatable-old-types';

export function DatatableOld<T>({
  data,
  columns,
  rowHeight,
  overscanCount = 5,
  pagination,
  sortingChanged,
  initialSortingOptions,
  cellOnClick,
  style: tableStyle,
  datatableRef,
}: DatatableOldProps<T>) {
  const sort = useRef<DatatableOldColumnSortingOptions<T> | undefined>(
    initialSortingOptions
  );

  const itemData: DatatableOldItemData<T> = {
    data: data,
    pagination: pagination,
    tableStyle: tableStyle,
    columns: columns,
    cellOnClick: cellOnClick,
  };

  const [pageLoading, setPageLoading] = useState(
    pagination?.hasNextPage ?? false
  );

  // We delay turning off the pageLoading indicator so the FixedSizedList has time
  // to notice the change in the indices and call loadMoreItems in InfiniteLoader.
  // Otherwise the loading indicator stays there until the next change in data which is a bug.
  useEffect(() => {
    const value = pagination?.hasNextPage ?? false;

    if (pageLoading) {
      setTimeout(() => {
        setPageLoading(value);
      }, 20);
    } else {
      setPageLoading(value);
    }
  }, [pagination?.hasNextPage]);

  const [tableDivRef, setTableDivRef] = useState<HTMLDivElement | null>(null);
  const [headerDivRef, setHeaderDivRef] = useState<HTMLDivElement | null>(null);
  const [bodyDivRef, setBodyDivRef] = useState<HTMLDivElement | null>(null);

  const [headerRightMarginAdjustment, setHeaderRightMarginAdjustment] =
    useState<number>();

  const handleRightMarginAdjustment = useCallback(() => {
    if (!headerDivRef || !bodyDivRef || bodyDivRef.clientWidth === 0) {
      return;
    }
    const diff = headerDivRef?.clientWidth - bodyDivRef?.clientWidth;
    if (diff !== headerRightMarginAdjustment) {
      if (diff > 30 || diff < 0) {
        setHeaderRightMarginAdjustment(0);
      } else {
        setHeaderRightMarginAdjustment(diff);
      }
    }
  }, [headerDivRef, bodyDivRef, headerRightMarginAdjustment]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleRightMarginAdjustment);

    if (bodyDivRef) {
      resizeObserver.observe(bodyDivRef);
      return () => {
        resizeObserver.unobserve(bodyDivRef);
        resizeObserver.disconnect();
      };
    }
    return;
  }, [handleRightMarginAdjustment]);

  const [tableXScroll, setTableXScroll] = useState<boolean>(false);
  const handleShowScroll = useCallback(() => {
    if (!tableDivRef?.clientWidth || !tableStyle?.tableMinWidth) {
      setTableXScroll(false);
      return;
    }

    if (tableDivRef.clientWidth < tableStyle.tableMinWidth) {
      setTableXScroll(true);
      return;
    }

    setTableXScroll(false);
    return;
  }, [tableStyle?.tableMinWidth, tableDivRef]);

  useEffect(() => {
    if (tableStyle?.tableMinWidth) {
      handleShowScroll();
      window.addEventListener('resize', handleShowScroll);
      return () => {
        window.removeEventListener('resize', handleShowScroll);
      };
    }
    return;
  }, [handleShowScroll, tableStyle?.tableMinWidth]);

  return (
    <div
      className={`w-full h-full flex flex-col ${
        tableStyle?.tableWrapperStyle || ''
      }
      `}
      id="table"
      ref={setTableDivRef}
      style={{
        overflowX: tableXScroll ? 'scroll' : 'hidden',
        overflowY: 'hidden',
      }}
    >
      <div
        className={`w-full flex flex-row ${
          tableStyle?.headerWrapperStyle || ''
        }`}
        ref={setHeaderDivRef}
        id="table_headers"
        style={{
          minWidth: tableStyle?.tableMinWidth
            ? `${tableStyle.tableMinWidth}px`
            : undefined,
        }}
      >
        {columns.map((c, i) => {
          if (c.visible === false) {
            return null;
          }

          return (
            <div
              onClick={() => {
                if (isSortableColumn(c)) {
                  let s: DatatableOldColumnSortingOptions<T> | undefined;
                  if (sort.current && sort.current.key === c.sortKey) {
                    s = {
                      ...sort.current,
                      order: sort.current.order === 'asc' ? 'desc' : 'asc',
                    };
                  } else {
                    s = {
                      key: c.sortKey,
                      order: 'desc',
                    };
                  }
                  sort.current = s;
                  sortingChanged?.(s);
                } else if (isClickableColumn(c)) {
                  c.onClick?.();
                }
              }}
              key={i}
              id={`table_header_${i}`}
              style={{ flex: c.flex ?? 1 }}
              className={`overflow-hidden ${
                (isSortableColumn(c) || isClickableColumn(c)) &&
                'cursor-pointer'
              } ${c?.style?.headerStyle || ''} ${
                c?.style?.columnWrapperStyle || ''
              } ${tableStyle?.headerStyle || ''}`}
            >
              <div className="flex flex-row truncate items-center gap-1">
                <div className="truncate">{c.label}</div>
                {isSortableColumn(c) && sort.current?.key === c.sortKey && (
                  <div>
                    <FontAwesomeIcon
                      icon={
                        sort.current.order === 'desc'
                          ? faChevronDown
                          : faChevronUp
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div style={{ width: `${headerRightMarginAdjustment ?? 0}px` }}></div>
      </div>
      <div
        className={`flex-grow ${tableStyle?.bodyWrapperStyle || ''}`}
        id="table_body"
        style={{
          minWidth: tableStyle?.tableMinWidth
            ? `${tableStyle.tableMinWidth}px`
            : undefined,
        }}
      >
        <AutoSizer>
          {({ height = 0, width = 0 }) => (
            <InfiniteLoader
              isItemLoaded={
                pagination
                  ? (index) => !pageLoading || index < data.length
                  : () => true
              }
              threshold={pagination ? pagination?.threshold ?? 1 : 1}
              itemCount={pagination ? pagination?.maxItems ?? 1_000_000 : 0}
              loadMoreItems={
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                pagination?.loadPage ? pagination.loadPage : () => {}
              }
            >
              {({ onItemsRendered, ref: internalRef }) => (
                <FixedSizeList<DatatableOldItemData<T>>
                  ref={(r) => {
                    if (datatableRef) {
                      datatableRef.current = r;
                    }
                    internalRef(r);
                  }}
                  innerRef={setBodyDivRef}
                  height={Number.isNaN(height) ? 0 : height}
                  width={Number.isNaN(width) ? 0 : width}
                  onItemsRendered={(props) => {
                    let key: keyof typeof props;
                    for (key in props) {
                      if (isNaN(props[key])) {
                        props[key] = 0;
                      }
                    }

                    return onItemsRendered(props);
                  }}
                  itemCount={data.length + (pageLoading ? 1 : 0)}
                  itemSize={rowHeight}
                  overscanCount={overscanCount}
                  itemData={itemData}
                >
                  {Item}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}

export const dataTableAgerStyle: DatatableOldStyle = {
  tableWrapperStyle: 'bg-white rounded-md',
  headerWrapperStyle:
    'px-8 text-xs text-gray-700 font-normal border-b border-gray-500',
  rowWrapperStyle: 'px-8 items-center text-sm hover:bg-gray-100',
  rowStyle: 'border-b border-gray-200',
  headerStyle: 'pr-1 py-3 h-full flex items-center',
  cellStyle: 'pr-1 flex items-center',
};

export const dataTableAgerSmallStyle: DatatableOldStyle = {
  tableWrapperStyle: 'bg-white rounded-md',
  headerWrapperStyle:
    'px-8 text-xs text-gray-700 font-normal border-b border-gray-500',
  rowWrapperStyle: 'px-8 items-center text-xs hover:bg-gray-100',
  rowStyle: 'border-b border-gray-200',
  headerStyle: 'pr-1 py-3 h-full flex items-center',
  cellStyle: 'pr-1 flex items-center',
};

export const dataTableAgerDarkStyle: DatatableOldStyle = {
  tableWrapperStyle: 'border border-gray-500 rounded-md',
  headerWrapperStyle:
    'px-8 text-xs text-gray-500 font-normal border-b border-gray-500',
  rowWrapperStyle: 'px-8 items-center text-sm hover:bg-gray-800',
  rowStyle: 'border-b border-gray-500',
  headerStyle: 'pr-1 py-3 h-full flex items-center',
  cellStyle: 'pr-1 flex items-center',
};

function Item<T>({
  index,
  style,
  data: itemData,
}: {
  index: number;
  style: CSSProperties;
  data: DatatableOldItemData<T>;
}) {
  if (index === itemData.data.length) {
    if (itemData.pagination?.loadingIndicator) {
      return (
        <div style={style} className="flex flex-row w-full h-full">
          {itemData.pagination?.loadingIndicator}
        </div>
      );
    }
    return <DatatableDefaultLoadingIndicator style={style} />;
  }

  return <Row index={index} style={style} itemData={itemData} />;
}

function Row<T>({
  index,
  style,
  itemData,
}: {
  index: number;
  style: CSSProperties;
  itemData: DatatableOldItemData<T>;
}) {
  const { data, tableStyle, columns, cellOnClick } = itemData;
  const d = data[index];
  return (
    <div
      style={{ ...style }}
      className={`flex flex-row w-full ${
        tableStyle?.rowWrapperStyle || ''
      } table-row`}
    >
      <div
        className={`flex flex-row w-full h-full ${tableStyle?.rowStyle || ''} ${
          index % 2
            ? tableStyle?.oddRowStyle || ''
            : tableStyle?.evenRowStyle || ''
        }`}
      >
        {columns.map((c, i) => {
          if (c.visible === false) {
            return null;
          }
          const onClick = c.name ? cellOnClick?.(c.name) : undefined;

          return (
            <div
              key={i}
              style={{ flex: c.flex ?? 1 }}
              onClick={onClick ? (e) => onClick?.(d, e) : undefined}
              className={`h-full overflow-hidden ${
                onClick ? 'cursor-pointer' : ''
              } ${c?.style?.columnWrapperStyle || ''} ${
                c?.style?.bodyStyle || ''
              } ${tableStyle?.cellStyle || ''}`}
            >
              <div className="truncate">{c.value(d, index)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
