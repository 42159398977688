import { faArchive } from '@fortawesome/pro-regular-svg-icons';
import {
  IconDefinition,
  faColumns,
  faListUl,
  faMousePointer,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';

import { ToolButton } from '@agerpoint/component';
import { ToolMode } from '@agerpoint/types';

interface ProjectPanelToolbarProps {
  addGroup: () => void;
  toggleLibrary: () => void;
  setToolMode: (toolMode: ToolMode) => void;
  isLibraryOpen?: boolean;
  toolMode: ToolMode;
}
export function ProjectPanelToolbar({
  addGroup,
  toggleLibrary,
  setToolMode,
  isLibraryOpen,
  toolMode,
}: ProjectPanelToolbarProps) {
  return (
    <div className="bg-white flex flex-row text-gray-600 w-full border-b border-gray-100">
      <ToolButton icon={faPlus} onSelect={addGroup} title="Add Group" />
      {modes.map(({ mode, icon, title }) => (
        <ToolButton
          key={mode}
          icon={icon}
          title={title}
          active={toolMode === mode}
          onSelect={() => setToolMode(toolMode === mode ? undefined : mode)}
        />
      ))}

      <ToolButton icon={faColumns} disabled grow />
      <ToolButton
        icon={faArchive}
        onSelect={toggleLibrary}
        active={isLibraryOpen}
        title="Library"
      />
    </div>
  );
}

const modes: Array<{ mode: ToolMode; icon: IconDefinition; title?: string }> = [
  { mode: 'select', icon: faMousePointer, title: 'Selection Tool' },
  { mode: 'attributes', icon: faListUl, title: 'Attributes' },
];
