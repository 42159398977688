import { createContext } from 'react';

import { AnalyticRequest } from '@agerpoint/api';

export interface ManageCaptureContextProps {
  analyticRequests: AnalyticRequest[] | null;
  actions: {
    refetchAnalyticRequests?: () => void;
  };
}

export const ManageCaptureContext = createContext<ManageCaptureContextProps>({
  analyticRequests: null,
  actions: {
    refetchAnalyticRequests: undefined,
  },
});
