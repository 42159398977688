import { InputSelectInline } from './input-select-inline';
import { InputSelectMulti } from './input-select-multi';
import { InputSelectSingle } from './input-select-single';

const InputSelect = {
  Multi: InputSelectMulti,
  Single: InputSelectSingle,
  Inline: InputSelectInline,
};

export { InputSelect };
