import {
  IconName,
  faCheck,
  faChevronDown,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useAgerStore } from '@agerpoint/utilities';

import { CloudInput } from '../input';
import { InputIdContext } from '../input-id-context';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';
import { InputSelectSingleInlineList } from './input-select-single-inline-list';

interface IInputSelectSingle<T> {
  /**
   * The unique identifier for the input component.
   */
  id: string;

  /**
   * The error message to display below the input component.
   */
  error?: string;

  /**
   * The label to display above the input component.
   */
  label?: string;

  /**
   * Determines whether the input component is required.
   */
  required?: boolean;

  /**
   * The placeholder text to display when no value is selected.
   */
  placeholder?: string;

  /**
   * The icon to display next to the placeholder text.
   */
  leadingIcon?: IconName;

  /**
   * Determines whether the input component is highlighted.
   */
  highlighted?: boolean;

  /**
   * The options to display in the dropdown list.
   */
  options: T[];

  /**
   * Loading state of the input component.
   */
  loading?: boolean;

  /**
   * The function to build the display text for each option.
   */
  optionBuilder: (option: T | undefined) => string;

  /**
   * The function to build the display text for each duplicate option.
   */
  duplicateOptionInfoBuilder?: (option: T | undefined) => string;

  /**
   * The currently selected value.
   */
  value: T | undefined;

  /**
   * The function to set the selected value.
   */
  setValue: (value: T | undefined) => void;

  /**
   * Determines whether the input component is disabled.
   */
  disabled?: boolean;

  /**
   * The function to compare two values for equality.
   */
  compareFn?: (a: T, b: T) => boolean;

  /**
   * The validation configuration for the input component.
   */
  validation?: InputValidation<T | undefined>;

  /**
   * The name of the entity for which the input component is being used.
   */
  entityName?: string;
}

interface Position {
  top: number;
  left: number;
  width: number;
}

function InputSelectSingle<T>({
  id,
  error,
  label,
  options,
  optionBuilder,
  duplicateOptionInfoBuilder,
  value,
  setValue,
  placeholder,
  entityName,
  loading = false,
  leadingIcon,
  disabled = false,
  compareFn,
  validation,
  required,
  highlighted = false,
}: IInputSelectSingle<T>) {
  const [expanded, setExpanded] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useAgerStore();

  const inputClassName = useMemo(() => {
    let className =
      'flex flex-row text-base rounded-lg border-0 h-10 w-full bg-white';
    if (loading) {
      className += ` text-gray-textSecondary placeholder-gray-border
       ring-1 ring-gray-border cursor-progress`;
    } else if (disabled) {
      className += ` text-gray-textSecondary placeholder-gray-border
       ring-1 ring-gray-border cursor-not-allowed`;
    } else if (error) {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary transition-colors cursor-pointer
         ring-1 ring-status-error focus:ring-status-error focus:ring-2 hover-overlay-5`;
    } else if (highlighted) {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary transition-colors cursor-pointer
         ring-1 ring-gray-border focus:ring-primary focus:ring-2 hover-overlay-5 bg-primary bg-opacity-10`;
    } else {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary transition-colors cursor-pointer
         ring-1 ring-gray-border focus:ring-primary focus:ring-2 hover-overlay-5`;
    }
    return className;
  }, [disabled, error, highlighted, loading]);

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  const leadingIconComponent = useMemo(() => {
    if (!leadingIcon) {
      return undefined;
    }

    return (
      <div className="size-6 flex-center">
        <FontAwesomeIcon
          icon={[highlighted ? 'fas' : 'far', leadingIcon]}
          className={
            disabled
              ? 'text-gray-border'
              : highlighted
              ? 'text-primary'
              : 'text-gray-iconSecondary'
          }
        />
      </div>
    );
  }, [leadingIcon, disabled, highlighted]);

  const trailingIconComponent = useMemo(() => {
    if (loading) {
      return (
        <div className="size-6 flex-center">
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
            className={
              disabled ? 'text-gray-border' : 'text-gray-iconSecondary'
            }
          />
        </div>
      );
    }

    return (
      <div
        className={`size-6 flex-center transition-transform ${
          expanded ? 'rotate-180' : ''
        }`}
      >
        <FontAwesomeIcon
          icon={faChevronDown}
          className={disabled ? 'text-gray-border' : 'text-gray-iconSecondary'}
        />
      </div>
    );
  }, [disabled, loading, expanded]);

  const filteredOptions = useMemo(() => {
    if (!searchInputValue.trim()) {
      return options;
    }

    return options.filter((option) =>
      optionBuilder(option)
        .trim()
        .toLowerCase()
        .includes(searchInputValue.trim().toLowerCase())
    );
  }, [options, searchInputValue, optionBuilder]);

  const duplicates = useMemo(() => {
    if (!duplicateOptionInfoBuilder) {
      return [];
    }

    const seen: Set<string> = new Set();
    const duplicates: string[] = [];

    options.forEach((option) => {
      const optionText = optionBuilder(option);
      if (seen.has(optionText)) {
        duplicates.push(optionText);
      } else {
        seen.add(optionText);
      }
    });

    return duplicates;
  }, [options, optionBuilder, duplicateOptionInfoBuilder]);

  const calculatePosition = useCallback(() => {
    const input = inputRef.current?.getBoundingClientRect();

    if (!input) {
      return undefined;
    }

    setPosition({
      top: input.top - 9,
      left: input.left - 9,
      width: input.width + 18,
    });
  }, [inputRef]);

  const [position, setPosition] = useState<Position | undefined>(undefined);

  const windowOnClick = useCallback((e: MouseEvent) => {
    if (
      !dropdownRef.current?.contains(e.target as Node) &&
      !inputRef.current?.contains(e.target as Node)
    ) {
      setExpanded(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calculatePosition);

    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, [calculatePosition]);

  useEffect(() => {
    if (expanded) {
      searchInputRef.current?.focus();
    }
  }, [expanded, windowOnClick]);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    window.addEventListener('click', windowOnClick);

    return () => {
      window.removeEventListener('click', windowOnClick);
    };
  }, [windowOnClick, expanded]);

  useEffect(() => {
    if (disabled) {
      setExpanded(false);
    }
  }, [disabled]);

  const dropdown = (
    <div
      className={`absolute bg-white shadow-lg border border-gray-border rounded-lg overflow-hidden z-dropdown`}
      ref={dropdownRef}
      style={{
        ...position,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="p-2">
        <CloudInput.Text.Single
          id={id + '-search'}
          value={searchInputValue}
          setValue={setSearchInputValue}
          leadingIcon={'search'}
          inputRef={searchInputRef}
          clearButton
          placeholder={`Search ${entityName ?? ''}`.trim()}
          onKeyDown={(event) => {
            if (event.key === 'Escape') {
              setExpanded(false);
            }

            if (event.key === 'Enter') {
              if (filteredOptions.length === 1) {
                setValue(filteredOptions[0]);
                setExpanded(false);
              }
            }
          }}
        />
      </div>
      <div className="flex flex-col max-h-72 overflow-auto">
        {filteredOptions.map((option, index) => {
          let isSelected = false;
          if (value !== undefined) {
            if (compareFn) {
              isSelected = compareFn(value, option);
            } else {
              isSelected = value === option;
            }
          }

          const builtOption = optionBuilder(option);
          const isDuplicate = duplicates.includes(builtOption);

          return (
            <div key={index}>
              <div
                className={`px-4 py-2 cursor-pointer hover-overlay-5 flex flex-row gap-2 transition-colors ${
                  isSelected ? 'bg-primary bg-opacity-10' : ''
                }`}
                onClick={(e) => {
                  if (isSelected) {
                    setValue(undefined);
                  } else {
                    setValue(option);
                  }

                  setExpanded(false);
                }}
              >
                <div className="size-6 flex-center shrink-0">
                  {isSelected && (
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  )}
                </div>
                <div className="flex flex-col">
                  <span>{builtOption}</span>
                  {isDuplicate && duplicateOptionInfoBuilder && (
                    <span className="text-xs text-gray-textSecondary leading-none">
                      {duplicateOptionInfoBuilder(option)}
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full">
        {label && <CloudInput.Label label={label} required={required} />}
        <div
          data-test-id={id}
          className={inputClassName}
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
          ref={inputRef}
          onClick={() => {
            if (disabled || loading) {
              return;
            }
            calculatePosition();
            setExpanded(true);
            setSearchInputValue('');
          }}
        >
          <div className="flex flex-row items-center justify-between truncate gap-1 w-full">
            {leadingIconComponent ?? null}
            <div className="size-full flex flex-row items-center justify-between gap-2 truncate">
              {value === undefined ? (
                <span className="text-gray-textSecondary truncate">
                  {placeholder}
                </span>
              ) : (
                <span className="truncate">{optionBuilder(value)}</span>
              )}
            </div>
            {trailingIconComponent}
          </div>
        </div>
        {error && <CloudInput.Error error={error} />}
      </div>
      {expanded &&
        (isMobile
          ? createPortal(
              <div
                className="absolute inset-0 bg-black bg-opacity-20 z-dropdown overflow-hidden"
                onClick={(e) => {
                  if (
                    dropdownRef.current?.contains(e.target as Node) ||
                    e.defaultPrevented
                  ) {
                    return;
                  }
                  e.stopPropagation();
                  setExpanded(false);
                }}
              >
                {dropdown}
              </div>,
              document.getElementById('apc-dropdowns') as HTMLElement
            )
          : createPortal(
              dropdown,
              document.getElementById('apc-dropdowns') as HTMLElement
            ))}
    </InputIdContext.Provider>
  );
}

InputSelectSingle.InlineList = InputSelectSingleInlineList;

export { InputSelectSingle };
