import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels, formatDateAndTime } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useFormValidation,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminPipelinesQueries } from './admin-pipelines-queries';

export const AdminPipelinesDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  const { permissions } = useGlobalStore();

  const isViteApp = useIsViteApp();

  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );
  usePageTitle(() => 'Platform - Pipelines', []);

  const { pipelineQuery, pipelinePutMutation } = useAdminPipelinesQueries();

  const formValidation = useFormValidation();
  const [pipeline, setPipeline] = useState<APIModels.Pipeline>({});
  useEffect(() => {
    setPipeline(pipelineQuery.data ?? {});
  }, [pipelineQuery.data]);

  const updatePipeline = useCallback(async () => {
    if (pipelinePutMutation.isPending || !pipeline.id) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    pipelinePutMutation.mutate({
      id: pipeline.id,
      data: pipeline,
    });
  }, [pipeline, formValidation, pipelinePutMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Pipelines',
              path: isViteApp
                ? '/app/admin/platform/pipelines'
                : '/admin/pipelines',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="pipelines-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/pipelines' + params);
            } else {
              navigate('/admin/pipelines' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">{pipelineQuery.data?.name}</h1>
      </div>
      {pipelineQuery.isLoading ? (
        <PageLoadingState />
      ) : pipelineQuery.isError ? (
        <PageErrorState
          entityName="pipeline"
          pluralEntityName="pipelines"
          statusCode={pipelineQuery.error?.response?.status ?? 500}
          tryAgainCallback={() => {
            pipelineQuery.refetch();
          }}
          tryAgainLoading={pipelineQuery.isFetching}
          navigateBackCallback={() =>
            navigate(
              isViteApp
                ? '/app/admin/platform/pipelines' + params
                : '/admin/pipelines' + params
            )
          }
        />
      ) : (
        <div className="p-4 w-full flex flex-col max-w-lg gap-2">
          <Input.Text.Single
            id="pipeline-name-input"
            label={<Input.Label label="Name" required />}
            value={pipeline.name || ''}
            setValue={(name) => {
              setPipeline({ ...pipeline, name });
            }}
            error={
              <Input.Error
                error={formValidation.errors['pipeline-name-input']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Name')],
            }}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Text.Single
            id="pipeline-path-input"
            label={<Input.Label label="Path" />}
            value={pipeline.path || ''}
            setValue={(path) => {
              setPipeline({ ...pipeline, path });
            }}
            readOnly={!canManageAnalyticRequests}
          />
          <Input.Text.Single
            id="pipeline-description-input"
            label={<Input.Label label="Description" />}
            value={pipeline.description || ''}
            setValue={(description) => {
              setPipeline({ ...pipeline, description });
            }}
            readOnly={!canManageAnalyticRequests}
          />
          {canManageAnalyticRequests && (
            <div className="w-full flex flex-row justify-end items-center py-4 gap-2">
              <Button.Primary
                id="pipeline-details-save-button"
                label={'Save'}
                onClick={updatePipeline}
                loading={pipelinePutMutation.isPending}
              />
            </div>
          )}
          <EntityDetailsSection
            items={[
              { label: 'ID', value: pipeline?.id },
              {
                label: 'Created',
                value: formatDateAndTime(pipeline?.createDatetime),
              },
              {
                label: 'Last Updated',
                value: formatDateAndTime(pipeline?.updateDatetime),
              },
              { label: 'Updated By UUID', value: pipeline?.updatedById },
            ]}
          />
        </div>
      )}
    </div>
  );
};
