import { Fill, Stroke, Style } from 'ol/style';

interface LineStyleOptions {
  strokeColor: number[] | string;
  strokeWidth: number;
  strokePattern: string;
  fillColor: number[] | string;
}

export const lineStyleUtil = ({
  strokeColor,
  strokeWidth,
  strokePattern,
  fillColor,
}: LineStyleOptions) =>
  new Style({
    stroke: new Stroke({
      color: strokeColor,
      width: strokeWidth || 1,
      lineDash:
        strokePattern === 'Dashed'
          ? [4 * strokeWidth, 4 * strokeWidth]
          : undefined,
    }),
    fill: new Fill({ color: fillColor }),
  });
