import { faArrowLeft, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { NavLink, useLocation } from 'react-router-dom';
import { MutateMethod } from 'restful-react';
import { shallow } from 'zustand/shallow';

import {
  Layer,
  PutLayerByIdPathParams,
  useDeleteLayerById,
  usePutLayerById,
} from '@agerpoint/api';
import { Button, SvgElement } from '@agerpoint/component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  Sidebar as LeftPanelSidebar,
  ProjectPanelFooter,
  ProjectPanelHeader,
  ProjectPrimaryButton,
  SidebarExpansion,
} from '@agerpoint/feature';
import { AgerDragDropContext } from '@agerpoint/feature';
import {
  Expansion,
  LDFlagSet,
  PanelsState,
  ProjectState,
} from '@agerpoint/types';
import {
  useCloudNavigate,
  useGlobalStore,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAddAndPersistLayer } from '../../hooks/use-add-and-persist-layer';
import { useSaveAndPersistGroupZIndex } from '../../hooks/use-save-and-persist-group-z-index';
import {
  persistGroupLayers,
  useSaveAndPersistLayerZIndex,
} from '../../hooks/use-save-and-persist-layer-z-index';
import useMultiSelection from '../../state/use-multiselection';
import { usePanels } from '../../state/use-panels';
import {
  findLayer,
  isClientSideGroup,
  useProject,
} from '../../state/use-project';
import {
  GroupExpansion,
  LayerExpansion,
  LibraryExpansion,
} from '../expansion-panels';
import Content from './content';
import Navigation from './navigation';

type ConfirmProps = {
  message: ReactNode;
  confirmLabel: string;
  onConfirm: () => Promise<void>;
};

type ShowConfirmFn = (props: ConfirmProps) => void;
export default function Sidebar() {
  const {
    groups,
    setGroupZIndex,
    setLayerZIndex,
    saveNewLayer,
    addTemporaryLayer,
    removeTemporaryLayer,
    fusionLink,
    name,
    tags,
    addGroup,
    moveLayerToGroup,
    removeLayer,
  } = useProject(getSidebarState, shallow);

  const { user, permissions } = useGlobalStore();

  const {
    currentExpansionId,
    expansionInfo,
    isSidebarOpen,
    mapMode,
    toolMode,
    toggleSidebar,
    toggleExpansion,
    setMapMode,
    setToolMode,
    setIsDraggingFromLibrary,
  } = usePanels(getPanelsState, shallow);

  const { mutate: deleteLayer } = useDeleteLayerById({});
  const internalSetGroupZIndex = useSaveAndPersistGroupZIndex(setGroupZIndex);
  const internalSetLayerZIndex = useSaveAndPersistLayerZIndex(setLayerZIndex);
  const internalMoveLayerToGroup = useMoveLayerToGroup(moveLayerToGroup);
  const internalAddLayer = useAddAndPersistLayer(
    saveNewLayer,
    addTemporaryLayer,
    removeTemporaryLayer,
    setIsDraggingFromLibrary
  );
  const multiSelection = useMultiSelection((state) => state.layerIds);
  const clearMultiSelection = useMultiSelection().clearMultiSelection;
  const useMultiSelect = useMultiSelection();
  const [confirm, setConfirm] = useState<ConfirmProps>();
  const [isConfirming, setIsConfirming] = useState(false);
  const { addEvent, removeEvent } = useContext(AgerDragDropContext);

  const onDragEnd = useCallback(
    (result: any) => {
      const { destination, source, draggableId } = result;

      // Dropping an item on itself
      if (
        !destination ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return;
      }

      const isDroppingInsideGroup =
        destination.droppableId.startsWith('groupLayer');
      const isFromLibrary = source.droppableId === 'library';
      const isDroppingOnGroup =
        destination.droppableId.startsWith('groupHeader');

      // Re-arranging groups
      if (
        destination.droppableId === 'groups' &&
        source.droppableId === 'groups'
      ) {
        const groupId = draggableId.split('-')[1];

        internalSetGroupZIndex(
          Number(groupId),
          groups[destination.index].zIndex
        );
      }

      // Dropping (a layer) inside a group
      if (isDroppingInsideGroup) {
        const groupId = Number(destination.droppableId.split('-')[1]);
        const layerId = Number(draggableId.split('-')[1]);

        if (isFromLibrary) {
          // Dropping a dataset from the library inside a group
          const groupId = Number(destination.droppableId.split('-')[1]);
          const libraryLayerData = JSON.parse(draggableId);
          const group = groups.find((group) => group.id === groupId);
          if (group) {
            internalAddLayer(groupId, libraryLayerData, destination.index);
          }
        } else if (source.droppableId === destination.droppableId) {
          // Re-arranging layers within a group
          internalSetLayerZIndex(groupId, layerId, destination.index);
        } else {
          // Dropping a layer inside another group
          internalMoveLayerToGroup(layerId, groupId, destination.index);
        }
      } else if (isDroppingOnGroup) {
        // Dropping a layer on a group header
        const groupId = Number(destination.droppableId.split('-')[1]);

        if (isFromLibrary) {
          const libraryLayerData = JSON.parse(draggableId);
          const group = groups.find((group) => group.id === groupId);
          if (group) {
            internalAddLayer(groupId, libraryLayerData, destination.index);
          }
        } else {
          const layerId = Number(draggableId.split('-')[1]);
          internalMoveLayerToGroup(layerId, groupId, destination.index);
        }
      }
    },
    [
      groups,
      internalAddLayer,
      internalSetGroupZIndex,
      internalSetLayerZIndex,
      internalMoveLayerToGroup,
    ]
  );

  useEffect(() => {
    groups.forEach((g) => {
      addEvent?.({
        droppableId: `groupLayer-${g.id}`,
        callback: (event) => {
          if (event.action === 'DRAG_END') {
            onDragEnd(event.payload as DropResult);
          }
        },
      });
      addEvent?.({
        droppableId: `groupHeader-${g.id}`,
        callback: (event) => {
          if (event.action === 'DRAG_END') {
            onDragEnd(event.payload as DropResult);
          }
        },
      });
    });
    addEvent?.({
      droppableId: `groups`,
      callback: (event) => {
        if (event.action === 'DRAG_END') {
          onDragEnd(event.payload as DropResult);
        }
      },
    });
    return () => {
      groups.forEach((g) => {
        removeEvent?.({ droppableId: `groupLayer-${g.id}` });
        removeEvent?.({ droppableId: `groupHeader-${g.id}` });
      });
      removeEvent?.({ droppableId: 'groups' });
    };
  }, [groups, addEvent, removeEvent, onDragEnd]);

  useEffect(() => {
    setConfirm(undefined);
  }, [multiSelection]);

  usePageTitle(() => {
    return name ? name : undefined;
  }, [name]);

  const internalDeleteLayer = useCallback(persistDeleteLayer, [
    groups,
    removeLayer,
    deleteLayer,
  ]);
  const showConfirm: ShowConfirmFn = (props) => {
    setConfirm(props);
  };
  function deleteSelectedLayers() {
    for (let i = 0; i < multiSelection.length; i++) {
      internalDeleteLayer(multiSelection[i]);
    }
    clearMultiSelection();
    setConfirm(undefined);
  }

  const isViteApp = useIsViteApp();
  const navigate = useCloudNavigate();
  const location = useLocation();

  return (
    <LeftPanelSidebar
      expansionOpen={!!currentExpansionId}
      toggleSidebar={toggleSidebar}
      isSidebarOpen={isSidebarOpen}
    >
      <div className="h-full max-h-full flex w-full flex-col relative z-20 bg-white overflow-hidden">
        <div className="flex-shrink">
          {isViteApp ? (
            <div className="flex flex-row p-4 border-b items-center gap-4">
              <Button.Icon
                id="navigate-back"
                icon={faArrowLeft}
                onClick={(e) => {
                  const pathname = location.state?.fromPathname;
                  const search = location.state?.fromSearch;

                  if (pathname) {
                    navigate(
                      { pathname: pathname, search: search || undefined },
                      {
                        clearSearch: true,
                        mouseEvent: e,
                      }
                    );
                  } else {
                    navigate(
                      { pathname: '/app' },
                      {
                        mouseEvent: e,
                      }
                    );
                  }
                }}
              />
              <div className="flex-shrink-0">
                <NavLink to="/app">
                  <SvgElement type="AgerpointLogo" style={{ width: '10rem' }} />
                </NavLink>
              </div>
            </div>
          ) : (
            <Navigation user={user} permissions={permissions} />
          )}
          <ProjectPanelHeader
            name={name}
            tags={tags}
            isLibraryOpen={currentExpansionId === 'library'}
            toolMode={toolMode}
            addGroup={addGroup}
            toggleLibrary={() =>
              toggleExpansion({ type: 'library', key: 'library' })
            }
            setToolMode={setToolMode}
          />
        </div>
        <div className="flex-grow overflow-y-auto h-full">
          <Content />
        </div>
        {confirm ? (
          <div className="w-full flex justify-end items-center bg-transparent">
            <div
              className="flex flex-col w-full space-y-2 mb-2 mx-2
              border bg-white border-gray-100 shadow-md p-2 rounded-md"
            >
              <h4 className="font-bold text-sm">Are you sure?</h4>
              <p className="text-xs">{confirm.message}</p>
              <ProjectPrimaryButton
                size={'small'}
                theme={'danger'}
                disabled={isConfirming}
                label={confirm.confirmLabel}
                onClick={onConfirm}
              />
              <ProjectPrimaryButton
                size={'small'}
                disabled={isConfirming}
                label="Cancel"
                theme="white"
                onClick={() => setConfirm(undefined)}
              />
            </div>
          </div>
        ) : null}
        <div className="flex flex-row flex-shrink justify-between items-end">
          <ProjectPanelFooter
            onChangeMode={setMapMode}
            currentMode={mapMode}
            fusionLink={fusionLink}
          />

          <div className="flex flex-col w-12 h-10 justify-center items-center">
            {multiSelection.length > 1 && (
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() =>
                  showConfirm({
                    message:
                      'Are you sure you want to remove the selected layers?',
                    confirmLabel: 'Remove Layers',
                    onConfirm: async () => deleteSelectedLayers(),
                  })
                }
                className="pb-3 px-2 w-5 h-5 text-xl cursor-pointer
                bg-transparent text-red-300 hover:text-red"
              />
            )}
          </div>
        </div>
      </div>
      <SidebarExpansion open={!!currentExpansionId} sidebarOpen={isSidebarOpen}>
        <SidebarExpansionContent
          useMultiSelect={useMultiSelect}
          expansionInfo={expansionInfo}
          permissions={permissions}
        />
      </SidebarExpansion>
    </LeftPanelSidebar>
  );

  function onConfirm() {
    setIsConfirming(true);
    confirm?.onConfirm().finally(() => setIsConfirming(false));
  }

  async function persistDeleteLayer(layerId: number) {
    const groupLayer = findLayer(groups, layerId);

    if (groupLayer) {
      const { group, layer } = groupLayer;
      if (!isClientSideGroup(group)) {
        await deleteLayer(layer.id);
      }
    }

    removeLayer(layerId);
  }
}

interface SidebarExpansionContentProps {
  expansionInfo: Expansion;
  useMultiSelect: any;
  permissions: LDFlagSet;
}

function SidebarExpansionContent({
  expansionInfo,
  useMultiSelect,
  permissions,
}: SidebarExpansionContentProps) {
  switch (expansionInfo.type) {
    case 'group':
      return <GroupExpansion expansionInfo={expansionInfo} />;
    case 'layer':
      return (
        <LayerExpansion
          useMultiSelect={useMultiSelect}
          expansionInfo={expansionInfo}
        />
      );
    case 'library':
      return <LibraryExpansion permissions={permissions} />;
    default:
      return null;
  }
}

function getSidebarState({
  name,
  tags,
  fusionLink,
  actions: {
    groups: { setZIndex: setGroupZIndex, add: addGroup },
    layers: {
      saveNew: saveNewLayer,
      setZIndex: setLayerZIndex,
      addTemporary: addTemporaryLayer,
      removeTemporary: removeTemporaryLayer,
      moveToGroup: moveLayerToGroup,
      delete: removeLayer,
    },
  },
  groups,
}: ProjectState) {
  return {
    name,
    tags,
    groups,
    addGroup,
    setGroupZIndex,
    setLayerZIndex,
    saveNewLayer,
    addTemporaryLayer,
    removeTemporaryLayer,
    moveLayerToGroup,
    removeLayer,
    fusionLink,
  };
}

function getPanelsState({
  currentExpansionId,
  expansionInfo,
  isSidebarOpen,
  mapMode,
  toolMode,
  toggleSidebar,
  toggleExpansion,
  setToolMode,
  setMapMode,
  setIsDraggingFromLibrary,
}: PanelsState) {
  return {
    currentExpansionId,
    expansionInfo,
    isSidebarOpen,
    mapMode,
    toolMode,
    toggleSidebar,
    toggleExpansion,
    setToolMode,
    setMapMode,
    setIsDraggingFromLibrary,
  };
}

function useMoveLayerToGroup(
  moveLayerToGroup: (
    layerId: number,
    groupId: number,
    destinationIndex: number
  ) => void
) {
  const { mutate: putLayerById } = usePutLayerById({ id: NaN });
  const saveAndPersistLayerMove = useCallback(persistLayersMove, [
    putLayerById,
    moveLayerToGroup,
  ]);

  return saveAndPersistLayerMove;

  async function persistLayersMove(
    layerId: number,
    groupId: number,
    destinationIndex: number
  ) {
    const { groups: originalGroups } = useProject.getState();
    const foundLayer = findLayer(originalGroups, layerId);
    if (!foundLayer) return;

    const {
      group: { id: originalGroupId },
    } = foundLayer;

    if (originalGroupId === groupId) return;

    moveLayerToGroup(layerId, groupId, destinationIndex);

    const { groups } = useProject.getState();
    await persistGroupLayers(
      groups,
      originalGroupId,
      putLayerById as unknown as MutateMethod<
        void,
        Layer,
        void,
        PutLayerByIdPathParams
      >
    );
    await persistGroupLayers(
      groups,
      groupId,
      putLayerById as unknown as MutateMethod<
        void,
        Layer,
        void,
        PutLayerByIdPathParams
      >
    );
  }
}
