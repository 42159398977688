import { ChangeEvent, InputHTMLAttributes } from 'react';

import { Options } from '@agerpoint/types';

type SelectType = 'small' | 'large';

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  options: Options[];
  id: string;
  name: string;
  type?: SelectType;
}

/**
 * @deprecated Use Input.Select.* instead
 */
export function Select({
  onChange,
  options,
  id,
  name,
  type = 'large',
  ...props
}: SelectProps): JSX.Element {
  return (
    <select
      onChange={onChange}
      id={id}
      name={name}
      className={`
      mt-1 
      block 
      w-full 
      py-2 
      pl-3
      pr-5
      border 
      border-gray-500 
      bg-white 
      rounded
      shadow-sm 
      focus:outline-none 
      focus:ring-green
      focus:border-green 
      text-sm
      truncate
      ${type === 'small' ? 'leading-3' : ''}`}
      {...props}
    >
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {option.name}
        </option>
      ))}
    </select>
  );
}
