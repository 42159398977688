import {
  faCheck,
  faCircleNotch,
  faFilterSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIModels, formatDate } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { useIsViteApp, useQueryState } from '@agerpoint/utilities';

import { useOpsPipelineJobsQueries } from './pipeline-jobs-queries';

export const PipelineJobsList = () => {
  const navigate = useNavigate();

  const isViteApp = useIsViteApp();

  const [filter, setFilter] = useState<APIModels.PipelineJobFilter>();

  const { pipelineJobsQuery, pipelinesLookupTable } = useOpsPipelineJobsQueries(
    { filter }
  );

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v.trim(),
    toUrlParam: (v) => v.trim(),
  });

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      name: nameFilter.trim(),
    }));
  }, []);

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        name: nameFilter.trim(),
      }));
    },
    500,
    [nameFilter]
  );

  const pipelineJobs = useMemo(
    () => pipelineJobsQuery.data?.pages.flatMap((page) => page) ?? [],
    [pipelineJobsQuery.data]
  );

  const hasFiltersApplied = useMemo(
    () => !!((filter?.name?.length ?? 0) > 0),
    [filter]
  );

  const clearFilter = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setNameFilter('');
    searchParams.delete('name');
    navigate(
      {
        pathname: isViteApp
          ? '/app/admin/pipelines/jobs'
          : '/ops/pipeline/pipeline-jobs',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, []);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="px-4">
        {!isViteApp && (
          <BreadCrumbs
            items={[
              {
                label: 'Operations',
                path: '/ops',
              },
              {
                label: 'Pipelines',
                path: '/ops/pipeline',
              },
            ]}
          />
        )}
      </div>
      <div className="flex flex-row justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">
          {isViteApp ? 'Pipelines - All Jobs' : 'All Jobs'}
        </h1>
      </div>
      <div className="flex flex-row gap-2 px-4 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="pipeline-capture-job-name-search"
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
            value={nameFilter}
            setValue={setNameFilter}
          />
        </div>
        <Button.ClearFilter visible={hasFiltersApplied} onClick={clearFilter} />
      </div>
      <div className="p-4 h-full">
        <Datatable
          id="pipeline-jobs-datatable"
          data={pipelineJobs ?? []}
          columns={[
            {
              label: '',
              value: (row) => {
                const isComplete = row?.completedDatetime;
                const isCreated = row?.createDatetime;

                if (isComplete) {
                  return <FontAwesomeIcon icon={faCheck} />;
                }
                if (isCreated) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }
                return '-';
              },
              flex: 0.25,
              style: {
                bodyStyle: 'flex justify-center',
              },
            },
            {
              label: 'Name',
              value: (row) => row.name,
              flex: 2,
            },
            {
              label: 'Pipeline',
              value: (row) => {
                if (!row.pipelineId) {
                  return null;
                }

                if (!pipelinesLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const pipeline = pipelinesLookupTable[row.pipelineId];

                return pipeline?.name ?? 'Unknown';
              },
              flex: 2,
            },
            {
              label: 'Triggered On',
              value: (row) =>
                row?.createDatetime ? formatDate(row.createDatetime) : '',
              flex: 1,
            },
          ]}
          loading={
            pipelineJobsQuery.isLoading ||
            pipelineJobsQuery.isFetchingNextPage ||
            filter === undefined
          }
          error={
            pipelineJobsQuery.isError
              ? {
                  title: 'There was a problem loading pipeline jobs',
                  message: 'Try refreshing the page',
                  action: () => pipelineJobsQuery.refetch(),
                }
              : undefined
          }
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching pipeline jobs found',
                  message: 'Adjust your filters and try again',
                  action: clearFilter,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No pipeline jobs found',
                  message: 'Trigger a pipeline to see jobs',
                }
          }
          pagination={{
            threshold: 10,
            loadNextPage: () => {
              if (
                !pipelineJobsQuery.hasNextPage ||
                pipelineJobsQuery.isFetchingNextPage ||
                pipelineJobsQuery.isLoading ||
                filter === undefined
              ) {
                return;
              }

              pipelineJobsQuery.fetchNextPage();
            },
          }}
          rowHeight={50}
          style={{
            ...dataTableAgerStyle,
            tableMinWidth: 950,
          }}
        />
      </div>
    </div>
  );
};
