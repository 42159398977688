import mixpanel from 'mixpanel-browser';

import { getCurrentEnvironment } from '../../environments/environment.utilities';

export const trackEvent = (
  eventName: string,
  eventBody: any,
  userId: string | null | undefined,
  userName: string | null | undefined,
  isMobile: boolean
) => {
  // const {user} = useGlobalStore();
  if (!mixpanel || !mixpanel?.track) return;
  const uName = userName || 'anonymous';
  const uId = userId || 'anonymous';
  mixpanel.track(eventName, {
    ...eventBody,
    env: getCurrentEnvironment(),
    userId: uId,
    userName: uName,
    isMobile,
  });
};
