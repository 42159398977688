import {
  faArrowLeft,
  faCircleNotch,
  faRefresh,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

import { PrimaryButton } from '@agerpoint/component';

export const PageLoadingState = () => {
  return (
    <div className="flex flex-row items-center justify-center h-full w-full">
      <div
        style={{ height: '24px', width: '24px' }}
        className="flex justify-center items-center"
      >
        <FontAwesomeIcon icon={faCircleNotch} spin className="w-full h-full" />
      </div>
    </div>
  );
};

interface PageErrorStateProps {
  entityName: string;
  pluralEntityName: string;
  statusCode: number;
  tryAgainCallback: () => void;
  tryAgainLoading: boolean;
  navigateBackCallback: () => void;
}

export const PageErrorState = ({
  entityName,
  pluralEntityName,
  statusCode,
  tryAgainCallback,
  tryAgainLoading,
  navigateBackCallback,
}: PageErrorStateProps) => {
  const message = useMemo(() => {
    if (statusCode === 404) {
      return `${
        entityName.charAt(0).toUpperCase() + entityName.slice(1)
      } not found`;
    }

    return `There was a problem loading this ${entityName}`;
  }, [entityName, statusCode]);

  const suggestion = useMemo(() => {
    if (statusCode === 404) {
      return `Go back and choose another ${entityName}`;
    }

    return 'Try refreshing the page';
  }, [entityName, statusCode]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full text-center">
      <div className="flex flex-col items-center justify-center gap-2">
        <span className="font-bold text-lg">{message}</span>
        <span className="text-sm text-gray-500 font-light">{suggestion}</span>
        <div className="pt-4">
          {statusCode === 404 ? (
            <PrimaryButton
              label={`Back to ${
                pluralEntityName.charAt(0).toUpperCase() +
                pluralEntityName.slice(1)
              }`}
              onClicked={navigateBackCallback}
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
            />
          ) : (
            <PrimaryButton
              label="Refresh"
              icon={<FontAwesomeIcon icon={faRefresh} spin={tryAgainLoading} />}
              onClicked={tryAgainCallback}
              disabled={tryAgainLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};
