import {
  faCircleNotch,
  faFilterSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIModels } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useGlobalStore,
  usePageTitle,
  useQueryState,
} from '@agerpoint/utilities';

import { useAdminMosaicEnginesQueries } from './admin-mosaic-engines-queries';

export const AdminMosaicEnginesList = () => {
  const navigate = useNavigate();

  usePageTitle(() => 'Platform - Mosaic Engines', []);
  const { permissions } = useGlobalStore();
  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v.trim(),
    toUrlParam: (v) => v.trim(),
  });

  const [archivedFilter, setArchivedFilter] = useQueryState<boolean>({
    paramName: 'archived',
    initialValue: false,
    fromUrlParam: (a) => {
      if (a === 'yes') {
        return true;
      }
      return false;
    },
    toUrlParam: (a) => {
      return a ? 'yes' : '';
    },
  });

  const { pipelinesLookupTable, mosaicEnginesQuery } =
    useAdminMosaicEnginesQueries(archivedFilter);

  const filteredMosaicEngines = useMemo(() => {
    let data: APIModels.MosaicEngine[] | undefined = undefined;

    if (mosaicEnginesQuery.data) {
      data = [...mosaicEnginesQuery.data];
    }

    if (data === undefined) {
      return undefined;
    }

    const filter = nameFilter.trim().toLowerCase();
    if (filter !== '') {
      data = data?.filter(
        (d) =>
          d.name?.toLowerCase().includes(filter) ||
          d.displayName?.toLowerCase().includes(filter)
      );
    }

    return data?.sort((a, b) => compare(a.id, b.id));
  }, [nameFilter, mosaicEnginesQuery]);

  const clearFilters = useCallback(() => {
    setNameFilter('');
  }, []);

  const hasFiltersApplied = useMemo(
    () => !!((nameFilter?.length ?? 0) > 0),
    [nameFilter]
  );

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="flex flex-row gap-1 justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">Mosaic Engines</h1>
        {canManageAnalyticRequests && (
          <Button.Primary
            id="mosaic-engines-new-button"
            label="New Mosaic Engine"
            icon={faPlus}
            onClick={() => {
              navigate('new', {
                state: { params: window.location.search },
              });
            }}
          />
        )}
      </div>
      <div className="px-4 flex flex-row justify-start gap-2 flex-wrap">
        <div className="w-80">
          <Input.Text.Single
            id="mosaic-engines-search-input"
            value={nameFilter}
            setValue={setNameFilter}
            placeholder="Search by Name or Display Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Input.Select.Inline
          id="mosaic-engines-status-filter"
          options={[false, true]}
          optionBuilder={(o) => (o ? 'Archived' : 'Active')}
          value={archivedFilter}
          setValue={setArchivedFilter}
        />
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 h-full w-full">
        <Datatable
          id="admin-mosaic-engines-datatable"
          data={filteredMosaicEngines ?? []}
          style={{ ...dataTableAgerStyle, tableMinWidth: 650 }}
          loading={
            mosaicEnginesQuery.isLoading || filteredMosaicEngines === undefined
          }
          cellOnClick={() => {
            return (row) => {
              navigate(`${row.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching mosaic engines',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : canManageAnalyticRequests
              ? {
                  title: 'No mosaic engines yet',
                  message: 'Create mosaic engine to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Mosaic Engine',
                }
              : {
                  title: 'No mosaic engines yet',
                  message:
                    'Contact your administrator to create a mosaic engine',
                }
          }
          error={
            mosaicEnginesQuery.isError
              ? {
                  title: 'There was a problem loading mosaic engines',
                  message: 'Try refreshing the page',
                  action: () => {
                    mosaicEnginesQuery.refetch();
                  },
                }
              : undefined
          }
          columns={[
            { label: 'ID', value: (row) => row.id, flex: 0.25, name: 'id' },
            {
              label: 'Name',
              value: (row) => row.name,
              name: 'name',
            },
            {
              label: 'Display Name',
              value: (row) => row.displayName,
              name: 'displayName',
            },
            {
              label: 'Description',
              name: 'description',
              value: (row) => row.description,
            },
            {
              label: 'Pipeline',
              name: 'pipeline',
              value: (row) => {
                if (!row.pipelineId) {
                  return null;
                }

                if (!pipelinesLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const pipeline = pipelinesLookupTable[row.pipelineId];

                return pipeline?.name ?? null;
              },
            },
          ]}
          rowHeight={50}
        />
      </div>
    </div>
  );
};
