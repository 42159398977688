import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable max-len */
import { useLocation } from 'react-router-dom';

import { SitewideLoadingBar, SvgElement } from '@agerpoint/component';
import { AppBarProps, MenuItemLink, ProfileAction } from '@agerpoint/types';
import { useIsViteApp, useProfile } from '@agerpoint/utilities';

import { BackgroundTaskManagerPanel } from '../background-task-manager/background-task-manager-panel';
import { HelpButton } from '../help-button';
import { RecentAccessPanel } from '../recent-access-panel/recent-access-panel';
import { ToastsBell } from '../toasts/toasts-bell';
import './app-bar.module.css';

/**
 * NavLink below is where AppBar component original came from:
 * @see https://tailwindui.com/components/application-ui/navigation/navbars
 * @param props
 * @returns
 */
export function AppBar(props: AppBarProps) {
  const profile = useProfile();
  const { compact, navigation } = props;
  const mdShow = !compact ? 'md:block' : '';
  const mdHide = !compact ? 'md:hidden' : '';
  const letter = props.firstLetterFirstName || '?';

  return (
    <Disclosure
      as="nav"
      className="bg-white border-b border-gray-200 h-16 relative"
      style={{ zIndex: 1000 }}
    >
      {({ open }) => (
        <>
          <SitewideLoadingBar />
          <div
            className={`${
              compact ? 'h-full w-full flex items-center' : 'px-4'
            }`}
          >
            <div className={`max-w-7xl ${compact ? '' : 'mx-auto'}`}>
              <div
                className={`flex items-center justify-between ${
                  !compact ? 'h-16' : ''
                }`}
              >
                <div
                  className={`h-full flex items-center justify-between  md:flex-row md:w-auto ${
                    compact ? 'flex-row' : 'flex-row-reverse w-full'
                  }`}
                >
                  <MenuButton open={open} className={mdHide} />
                  <div className="flex-shrink-0">
                    <NavLink to="/">
                      <SvgElement
                        type="AgerpointLogo"
                        style={{ width: '10rem' }}
                      />
                    </NavLink>
                  </div>
                  <div className={`hidden ${mdShow} h-full`}>
                    <div className="ml-10 flex items-baseline space-x-4 h-full">
                      {/* these links for navigation are shown in dashboard mode */}
                      {navigation.map((item) => (
                        <NavigationItem item={item} key={item.label} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className={`hidden ${mdShow}`}>
                  <div className="ml-4 flex items-center md:ml-6">
                    <div className="flex flex-row gap-2">
                      <RecentAccessPanel />
                      <BackgroundTaskManagerPanel />
                      <ToastsBell />
                      <HelpButton />
                    </div>
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      {({ open }) => (
                        <>
                          <Menu.Button
                            data-test-id="profile-button"
                            className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                          >
                            <span className="sr-only">Open user menu</span>
                            <ProfileImage letter={letter} />
                          </Menu.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              style={{ zIndex: 99999 }}
                            >
                              {/* these items/links for profile are shown in dashboard mode */}
                              {profile.map((item) => (
                                <Menu.Item key={item.label}>
                                  <div>
                                    <ProfileItem item={item} />
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel
            className={`fixed z-40 bg-white w-80 border-b border-x ${
              compact ? '' : 'right-4'
            } rounded-b-md border-gray-100 ${mdHide}`}
          >
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => (
                <NavigationItem
                  key={item.label}
                  item={item}
                  compact={compact}
                />
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-300">
              <div className="flex items-center px-5 gap-2">
                <ProfileImage letter={letter} />
                <RecentAccessPanel />
                <BackgroundTaskManagerPanel />
                <ToastsBell />
                <HelpButton />
              </div>
              <div className="mt-3 px-2 space-y-1">
                {/* these items/links for profile are shown in compact/project mode */}
                {profile.map((item) => (
                  <ProfileItem key={item.label} item={item} compact />
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

interface ProfileImageProps {
  letter: string;
}
function ProfileImage({ letter }: ProfileImageProps) {
  return (
    <div className="h-10 w-10 rounded-full bg-blue ring-2 text-center">
      <span className="h-10 w-10 font-bold text-white text-xl leading-loose select-none">
        {letter}
      </span>
    </div>
  );
}

interface NavigationItemProps {
  item: MenuItemLink;
  compact?: boolean;
}

function NavigationItem({ item, compact }: NavigationItemProps) {
  const location = useLocation();
  const baseRoute = location.pathname.split('/')[1];
  const sameParentRoute = item.link.includes(baseRoute);
  const active = sameParentRoute || location.pathname === item.link;

  const activeClassname = `border-gray-200 py-2 px-3 text-gray-900 w-full rounded-md bg-green text-white ${
    !compact
      ? 'md:rounded-none md:text-gray-900 md:bg-transparent md:font-bold md:border-b md:flex md:items-center'
      : ''
  }`;

  const inactiveClassname = `border-gray-200 py-2 px-3 hover:bg-gray-50 text-gray-900 w-full rounded-md ${
    !compact
      ? 'md:rounded-none md:font-bold md:border-b md:flex md:items-center'
      : ''
  }`;

  return !item.disabled ? (
    <div className="h-full flex items-stretch relative select-none">
      <NavLink
        to={item.link}
        className={active ? activeClassname : inactiveClassname}
      >
        {item.label}
      </NavLink>
      {active && !compact && (
        <div
          className="absolute w-full md:border-2 md:border-green md:rounded-full md:bg-green"
          style={{ bottom: '-1.5px' }}
        />
      )}
    </div>
  ) : (
    <span
      className={`cursor-not-allowed block px-3 py-2 rounded-md text-base font-normal text-gray-400  ${
        !compact ? 'md:text-gray-300 md:py-5 md:font-bold' : ''
      }`}
    >
      {item.label}
    </span>
  );
}

interface ProfileItemProps {
  item: ProfileAction;
  compact?: boolean;
}

function ProfileItem({ item, compact }: ProfileItemProps) {
  return !item.disabled ? (
    'link' in item ? (
      <NavLink
        to={item.link}
        className={`block px-3 py-2 rounded-md text-base font-normal hover:bg-gray-50 ${
          !compact ? 'md:hover:bg-gray-50 md:rounded-none' : ''
        }`}
      >
        {item.label}
      </NavLink>
    ) : (
      <button
        className={`block px-3 py-2 rounded-md text-base font-normal hover:bg-gray-50 w-full text-left ${
          !compact
            ? 'md:hover:bg-gray-50 md:text-gray-900 md:hover:text-gray-900 md:rounded-none'
            : ''
        }`}
        onClick={item.action}
        disabled={item.disabled}
      >
        {item.label}
      </button>
    )
  ) : (
    <span
      className={
        'cursor-not-allowed block px-3 py-2 rounded-md text-base font-normal text-gray-400'
      }
    >
      {item.label}
    </span>
  );
}
interface MenuButtonProps {
  open: boolean;
  className?: string;
}

/**
 * UI component to be clicked to reveal (context) menu.
 *
 * @param open `true` to reveal, `false` to hide
 * @returns
 */
function MenuButton({ open, className }: MenuButtonProps) {
  return (
    <Disclosure.Button
      className={`${className} text-lg bg-white inline-flex items-center justify-center p-4 w-11 h-4 mx-1 rounded-md text-gray-800 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
    >
      <span className="sr-only">Open main menu</span>
      {open ? (
        <FontAwesomeIcon
          icon={faTimes}
          className="tw-text-xl block"
          aria-hidden="true"
        />
      ) : (
        <FontAwesomeIcon
          icon={faBars}
          className="tw-text-xl block"
          aria-hidden="true"
        />
      )}
    </Disclosure.Button>
  );
}
