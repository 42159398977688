/**
 *
 * @deprecated use useDebounce from @react-hook/debounce instead
 */
export const debounce = (callback: any, wait: any) => {
  let timeoutId: any = null;
  return (...args: any) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      // eslint-disable-next-line
      callback.apply(null, args);
    }, wait);
  };
};
