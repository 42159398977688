/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CustomerAnalytic } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCustomerAnalytic = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CustomerAnalytic[]>(
    { url: `/api/CustomerAnalytics`, method: 'GET', signal },
    options
  );
};

export const getGetCustomerAnalyticQueryKey = () => {
  return [`/api/CustomerAnalytics`] as const;
};

export const getGetCustomerAnalyticQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerAnalytic>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerAnalytic>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerAnalyticQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerAnalytic>>
  > = ({ signal }) => getCustomerAnalytic(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerAnalytic>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerAnalyticQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerAnalytic>>
>;
export type GetCustomerAnalyticQueryError = ErrorType<void>;

export const useGetCustomerAnalytic = <
  TData = Awaited<ReturnType<typeof getCustomerAnalytic>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerAnalytic>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerAnalyticQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCustomerAnalytic = (
  customerAnalytic: BodyType<CustomerAnalytic>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CustomerAnalytic>(
    {
      url: `/api/CustomerAnalytics`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: customerAnalytic,
    },
    options
  );
};

export const getPostCustomerAnalyticMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerAnalytic>>,
    TError,
    { data: BodyType<CustomerAnalytic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCustomerAnalytic>>,
  TError,
  { data: BodyType<CustomerAnalytic> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCustomerAnalytic>>,
    { data: BodyType<CustomerAnalytic> }
  > = (props) => {
    const { data } = props ?? {};

    return postCustomerAnalytic(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCustomerAnalyticMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCustomerAnalytic>>
>;
export type PostCustomerAnalyticMutationBody = BodyType<CustomerAnalytic>;
export type PostCustomerAnalyticMutationError = ErrorType<void>;

export const usePostCustomerAnalytic = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerAnalytic>>,
    TError,
    { data: BodyType<CustomerAnalytic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCustomerAnalytic>>,
  TError,
  { data: BodyType<CustomerAnalytic> },
  TContext
> => {
  const mutationOptions = getPostCustomerAnalyticMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCustomerAnalyticById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CustomerAnalytic>(
    {
      url: `/api/CustomerAnalytics/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomerAnalyticByIdQueryKey = (id: number) => {
  return [`/api/CustomerAnalytics/${id}`] as const;
};

export const getGetCustomerAnalyticByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerAnalyticById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerAnalyticById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCustomerAnalyticByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerAnalyticById>>
  > = ({ signal }) => getCustomerAnalyticById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerAnalyticById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerAnalyticByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerAnalyticById>>
>;
export type GetCustomerAnalyticByIdQueryError = ErrorType<void>;

export const useGetCustomerAnalyticById = <
  TData = Awaited<ReturnType<typeof getCustomerAnalyticById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerAnalyticById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerAnalyticByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCustomerAnalyticById = (
  id: number,
  customerAnalytic: BodyType<CustomerAnalytic>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CustomerAnalytic>(
    {
      url: `/api/CustomerAnalytics/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: customerAnalytic,
    },
    options
  );
};

export const getPutCustomerAnalyticByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCustomerAnalyticById>>,
    TError,
    { id: number; data: BodyType<CustomerAnalytic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCustomerAnalyticById>>,
  TError,
  { id: number; data: BodyType<CustomerAnalytic> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCustomerAnalyticById>>,
    { id: number; data: BodyType<CustomerAnalytic> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCustomerAnalyticById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCustomerAnalyticByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCustomerAnalyticById>>
>;
export type PutCustomerAnalyticByIdMutationBody = BodyType<CustomerAnalytic>;
export type PutCustomerAnalyticByIdMutationError = ErrorType<void>;

export const usePutCustomerAnalyticById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCustomerAnalyticById>>,
    TError,
    { id: number; data: BodyType<CustomerAnalytic> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCustomerAnalyticById>>,
  TError,
  { id: number; data: BodyType<CustomerAnalytic> },
  TContext
> => {
  const mutationOptions = getPutCustomerAnalyticByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCustomerAnalyticsByCustomerId = (
  customerId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CustomerAnalytic[]>(
    {
      url: `/api/CustomerAnalytics/Customer/${encodeURIComponent(
        String(customerId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomerAnalyticsByCustomerIdQueryKey = (
  customerId: number
) => {
  return [`/api/CustomerAnalytics/Customer/${customerId}`] as const;
};

export const getGetCustomerAnalyticsByCustomerIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerAnalyticsByCustomerId>>,
  TError = ErrorType<void>
>(
  customerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerAnalyticsByCustomerId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerAnalyticsByCustomerIdQueryKey(customerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerAnalyticsByCustomerId>>
  > = ({ signal }) =>
    getCustomerAnalyticsByCustomerId(customerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerAnalyticsByCustomerId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerAnalyticsByCustomerIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerAnalyticsByCustomerId>>
>;
export type GetCustomerAnalyticsByCustomerIdQueryError = ErrorType<void>;

export const useGetCustomerAnalyticsByCustomerId = <
  TData = Awaited<ReturnType<typeof getCustomerAnalyticsByCustomerId>>,
  TError = ErrorType<void>
>(
  customerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerAnalyticsByCustomerId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerAnalyticsByCustomerIdQueryOptions(
    customerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomerAnalyticsByAnalyticId = (
  analyticId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CustomerAnalytic[]>(
    {
      url: `/api/CustomerAnalytics/Analytic/${encodeURIComponent(
        String(analyticId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomerAnalyticsByAnalyticIdQueryKey = (
  analyticId: number
) => {
  return [`/api/CustomerAnalytics/Analytic/${analyticId}`] as const;
};

export const getGetCustomerAnalyticsByAnalyticIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerAnalyticsByAnalyticId>>,
  TError = ErrorType<void>
>(
  analyticId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerAnalyticsByAnalyticId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerAnalyticsByAnalyticIdQueryKey(analyticId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerAnalyticsByAnalyticId>>
  > = ({ signal }) =>
    getCustomerAnalyticsByAnalyticId(analyticId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!analyticId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerAnalyticsByAnalyticId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerAnalyticsByAnalyticIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerAnalyticsByAnalyticId>>
>;
export type GetCustomerAnalyticsByAnalyticIdQueryError = ErrorType<void>;

export const useGetCustomerAnalyticsByAnalyticId = <
  TData = Awaited<ReturnType<typeof getCustomerAnalyticsByAnalyticId>>,
  TError = ErrorType<void>
>(
  analyticId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerAnalyticsByAnalyticId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerAnalyticsByAnalyticIdQueryOptions(
    analyticId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
