import { faCheck, faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDebouncyEffect } from 'use-debouncy';

import { APIClient, useGetFilteredPageCaptures } from '@agerpoint/api';

import { InputIdContext } from '../input-id-context';
import { InputPlaceholderIcon } from '../input-placeholder-icons';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface InputCaptureProps {
  /**
   * The unique identifier for the input element.
   */
  id: string;

  /**
   * The error message to display.
   */
  error?: React.ReactNode;

  /**
   * The label to display.
   */
  label?: React.ReactNode;

  /**
   * The placeholder text for the input element.
   */
  placeholder?: string;

  /**
   * The placeholder icon for the input element.
   */
  placeholderIcon?: InputPlaceholderIcon;

  /**
   * The value of the input element.
   */
  value: APIClient.Capture | undefined;

  /**
   * A callback function to set the value of the input element.
   */
  setValue: (value: APIClient.Capture | undefined) => void;

  /**
   * Determines whether to show UUIDs.
   */
  showUUIDs?: boolean;

  /**
   * The maximum height of the dropdown list.
   */
  maxHeight?: string;

  /**
   * The validation configuration for the input element.
   */
  validation?: InputValidation<APIClient.Capture | undefined>;
}

const InputCapture = ({
  id,
  error,
  label,
  placeholder: placeholderProp,
  placeholderIcon,
  value,
  setValue,
  showUUIDs,
  maxHeight = '200px',
  validation,
}: InputCaptureProps) => {
  const [nameFilter, setNameFilter] = useState<string>('');

  const [capturesList, setCapturesList] = useState<APIClient.Capture[]>([]);

  const [inputHasFocus, setInputHasFocus] = useState(false);

  const {
    mutate: getCaptures,
    cancel: cancelPreviousGetCapturesCall,
    loading: loadingCaptures,
  } = useGetFilteredPageCaptures({ skip: 0, take: 20 });

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  useEffect(() => {
    if (value === undefined) {
      setNameFilter('');
    }
  }, [value]);

  useDebouncyEffect(
    () => {
      if (value?.captureName?.trim() === nameFilter.trim()) {
        return;
      }
      cancelPreviousGetCapturesCall();

      if (nameFilter.trim().length === 0) {
        return;
      }
      setCapturesList([]);

      const doAsync = async () => {
        try {
          const response = (await getCaptures({
            captureName: nameFilter.trim(),
          })) as unknown as APIClient.Capture[];

          if (!response) {
            return;
          }

          setCapturesList([...response]);
        } catch (e: any) {
          if (e.message.includes('aborted')) {
            return;
          }
        }
      };

      doAsync();
    },
    500,
    [nameFilter]
  );

  const expanded = !loadingCaptures && inputHasFocus && capturesList.length > 0;

  const placeholder = `${placeholderIcon ? String(placeholderIcon) : ''} ${
    placeholderProp ?? ''
  }`.trim();

  return (
    <InputIdContext.Provider value={id}>
      {label ?? null}
      <div className={`relative w-full ${expanded ? 'z-50' : ''}`}>
        <div
          className={`flex flex-row w-full shadow-sm border-gray-500 rounded border ${
            inputHasFocus ? 'ring-1 ring-green border-green' : ''
          }`}
        >
          <input
            type="text"
            id={id}
            data-test-id={id}
            className={`text-sm w-full placeholder-gray border-0 focus:ring-0 focus:border-0 focus:outline-none rounded
            ${value ? 'text-gray-900' : 'text-gray-500'}`}
            placeholder={placeholder}
            style={{
              fontFamily: 'nunito, "Font Awesome 6 Pro"',
            }}
            onBlur={() => {
              setInputHasFocus(false);
            }}
            onFocus={() => {
              setInputHasFocus(true);
            }}
            value={nameFilter}
            onChange={(e) => {
              let v = e.target.value;

              v = v.trimStart();
              setNameFilter(v);
              setValue(undefined);
            }}
          />

          {loadingCaptures && (
            <div className={`px-2 flex items-center bg-white rounded-r`}>
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin
                className="text-gray-900"
              />
            </div>
          )}
          {value && (
            <div className={`pr-2 flex items-center bg-white rounded-r`}>
              <FontAwesomeIcon icon={faCheck} className="text-gray-900" />
            </div>
          )}
        </div>
        {expanded && (
          <div
            className={`absolute mx-auto mt-1 bg-white w-full rounded
            shadow-md border border-gray-500 flex flex-col`}
            style={{ minWidth: '232px' }}
            data-test-id={`${id}-list-container`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="overflow-auto text-xs" style={{ maxHeight }}>
              {capturesList.map((c, i) => {
                return (
                  <div
                    key={i}
                    className={`truncate cursor-pointer flex flex-row gap-1 items-center`.trim()}
                    onMouseDown={(e) => {
                      setValue(c);
                      setNameFilter(c?.captureName ?? '');
                      setInputHasFocus(false);

                      e.stopPropagation();
                    }}
                  >
                    <span
                      className={`w-full truncate flex flex-row
                      justify-between hover:bg-gray-200 p-1 gap-2`}
                    >
                      <span className="truncate">{c.captureName ?? ''}</span>
                      {showUUIDs && (
                        <span
                          className="truncate text-xs text-gray-400"
                          title={c.captureUuid ?? undefined}
                        >
                          {c.captureUuid?.split('-')?.[0]}...
                        </span>
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {error ?? null}
    </InputIdContext.Provider>
  );
};

export { InputCapture };
