import { useInfiniteQuery, useMutation } from '@tanstack/react-query';

// @ts-ignore
import { APIClient, APIModels, APIUtils } from '@agerpoint/api';
import { useLookupTable, useToasts } from '@agerpoint/utilities';

export const useOpsPipelineUploadsQueries = ({
  filter,
}: {
  filter?: APIModels.UploadFilter;
}) => {
  const toasts = useToasts();

  const uploadsQuery = useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.uploads,
      APIUtils.QueryKey.infinite,
      { filter: filter },
    ],
    queryFn: async ({ pageParam }) =>
      APIClient.getFilteredPagedUploads(
        pageParam.skip,
        pageParam.take,
        filter as APIModels.UploadFilter
      ),
    enabled: filter !== undefined,
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    staleTime: APIUtils.getDuration({
      seconds: 20,
    }),
  });

  const uploadTypesQuery = APIClient.useGetUploadType({
    query: {
      queryKey: [APIUtils.QueryKey.uploadTypes],
      select: (data) => APIUtils.Sort.uploadTypes(data),
    },
  });

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const organizationsLookupTable = useLookupTable(
    organizationsQuery.data,
    'id'
  );

  const runPipelineForUploadsMutation = useMutation({
    mutationFn: async (data: {
      uploads: APIModels.Upload[];
      pipelineId: number;
      captureId?: number;
    }) => {
      const promises = [];

      for (const upload of data.uploads) {
        const job: APIModels.PipelineJob = {
          pipelineId: data.pipelineId,
          mosaicEngineId: data.pipelineId === 2 ? 3 : undefined,
          uploadId: upload.id as number,
          name: upload.name,
          captureId: data.captureId,
        };

        promises.push(APIClient.postPipelineJob(job));
      }

      await Promise.all(promises);
    },
    onSuccess: () => {
      toasts.add({
        title: 'Pipeline for your uploads started!',
        type: 'success',
      });
    },
    onError: () => {
      toasts.add(
        toasts.prepare.error('Failed to start pipeline for your uploads!')
      );
    },
  });

  return {
    uploadsQuery,
    uploadTypesQuery,
    organizationsQuery,
    organizationsLookupTable,
    runPipelineForUploadsMutation,
  };
};
