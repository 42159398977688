import {
  faCheck,
  faCircleNotch,
  faPlay,
  faTurnDownLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { Button, QuestionInfo } from '@agerpoint/component';
import {
  AnalyticRequestStatus,
  analyticRequestStatusLookup,
} from '@agerpoint/types';
import { getColorByStatus, getIconByStatus } from '@agerpoint/utilities';

import {
  PageErrorState,
  PageLoadingState,
} from '../../../../subcomponents/page-states';
import { useAdminCapturesQueries } from '../admin-captures-queries';

export const AdminCapturesDetailsAnalyticsStepper = () => {
  const analytics = `Automatically identify features using Machine Learning algorithms on this capture.`;
  const location = useLocation();
  const params = location.state?.params ?? '';

  const navigate = useNavigate();
  const { analyticId } = useParams();

  const {
    captureQuery,
    customerAnalyticsQuery,
    captureAnalyticsQuery,
    analyticRequestPostMutation,
  } = useAdminCapturesQueries();

  const customerAnalytic = useMemo(
    () =>
      customerAnalyticsQuery.data?.find(
        (ca) => ca.analytic?.id === Number(analyticId)
      ),
    [customerAnalyticsQuery.data, analyticId]
  );

  const analytic = useMemo(
    () => customerAnalytic?.analytic,
    [customerAnalytic]
  );

  const analyticRequest = useMemo(
    () =>
      captureAnalyticsQuery.data?.find(
        (ca) => ca.customerAnalytic?.analytic?.id === Number(analyticId)
      ),
    [captureAnalyticsQuery.data]
  );

  const [jobTriggered, setJobTriggered] = useState(false);

  const startAnalytic = useCallback(() => {
    if (
      customerAnalytic?.id === undefined ||
      customerAnalytic?.analyticCreditCost === undefined ||
      captureQuery?.data?.id === undefined
    ) {
      return;
    }

    const confirm = window.confirm(
      `Are you sure you want to start this analytic?`
    );
    if (!confirm) {
      return;
    }

    analyticRequestPostMutation.mutate(
      {
        data: {
          customerAnalyticId: customerAnalytic?.id,
          analyticCreditCost: customerAnalytic?.analyticCreditCost,
          captureIds: [captureQuery?.data?.id],
        },
      },
      {
        onSuccess: () => {
          setJobTriggered(true);
        },
      }
    );
  }, []);

  if (captureQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (captureQuery.isError) {
    return (
      <PageErrorState
        entityName="capture"
        pluralEntityName="captures"
        statusCode={captureQuery.error.response?.status ?? 500}
        tryAgainCallback={() => {
          captureQuery.refetch();
        }}
        tryAgainLoading={captureQuery.isFetching}
        navigateBackCallback={() => navigate('/admin/captures' + params)}
      />
    );
  }

  return (
    <div className="px-4 pb-4 flex flex-col w-full h-full">
      <div className="pb-2 w-48 flex justify-between text-sm font-bold">
        <span>Selected Analytic</span> <QuestionInfo text={analytics} />
      </div>

      <div className="flex flex-row flex-grow p-2 justify-center border rounded m-8">
        {customerAnalytic && analytic ? (
          <div className="flex flex-col h-full justify-center w-1/2">
            <h5
              className={`flex justify-center items-center mb-2 text-2xl
            font-semibold tracking-tight text-gray-900 truncate`}
            >
              {analytic?.analyticName}
            </h5>
            <p className="flex justify-center mb-3 font-normal text-gray-500 text-sm">
              {analytic?.analyticDescription}
            </p>
            {analyticRequest?.status ? (
              <HasStatusSection
                status={analyticRequest?.status as AnalyticRequestStatus}
              />
            ) : (
              <HasNoStatusSection selectedAnalytic={customerAnalytic} />
            )}
          </div>
        ) : (
          <div className="flex flex-row w-full justify-center items-center gap-2 h-full">
            <FontAwesomeIcon icon={faCircleNotch} spin />
            <span>Finding Analytic</span>
          </div>
        )}
      </div>

      <div className="flex flex-row justify-end px-8 gap-2">
        {!analyticRequestPostMutation.isPending && (
          <Button.Secondary
            id="go-back-button"
            label="Go Back"
            icon={faTurnDownLeft}
            onClick={() => {
              navigate('..', { relative: 'path', state: { params } });
            }}
          />
        )}
        {!analyticRequestPostMutation.isPending &&
          !jobTriggered &&
          !analyticRequest?.status && (
            <Button.Primary
              id="start-analytic-button"
              label="Confirm"
              icon={faPlay}
              onClick={startAnalytic}
            />
          )}
        {analyticRequestPostMutation.isPending && (
          <div className="flex flex-row items-center justify-center">
            <FontAwesomeIcon spin icon={faCircleNotch} className="text-green" />
            <span className="ml-2">Processing...</span>
          </div>
        )}
        {!analyticRequestPostMutation.isPending && jobTriggered && (
          <div className="flex flex-row items-center justify-center">
            <FontAwesomeIcon icon={faCheck} className="text-green" />
            <span className="ml-2">Analytic Started...</span>
          </div>
        )}
      </div>
    </div>
  );
};

const HasStatusSection = ({ status }: { status: AnalyticRequestStatus }) => {
  const color = getColorByStatus(status);
  const i = getIconByStatus(status);
  const statusIcon = i ? (
    <FontAwesomeIcon
      icon={i}
      className={`flex text-white fa-sm w-full`}
      spin={status === AnalyticRequestStatus.PROCESSING}
    />
  ) : null;
  return (
    <div className="flex flex-col justify-center">
      <div
        className="flex flex-row justify-center items-center"
        style={{ color }}
      >
        {statusIcon}
        <span className="pl-2">{status}</span>
      </div>
      <div className="flex justify-center my-3 font-normal text-gray-500 text-sm">
        {analyticRequestStatusLookup[status].description}
      </div>
    </div>
  );
};

const HasNoStatusSection = ({
  selectedAnalytic,
}: {
  selectedAnalytic?: APIModels.CustomerAnalytic;
}) => {
  return (
    <div className="flex flex-row w-full justify-center">
      <span
        className={`bg-green-100 text-green-800 text-xl font-medium px-2.5 py-0.5 rounded border border-green-400`}
      >
        {`${selectedAnalytic?.analyticCreditCost} Credits`}
      </span>
    </div>
  );
};
