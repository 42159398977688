import { CaptureObject } from '@agerpoint/api';

export type Point = [number, number, number];
export type Line = Point[];
export type Polygon = Line[];

export type LatLngAlt = {
  lat: number;
  lng: number;
  alt: number;
};

export enum GeomType {
  Point = 'Point',
  LineString = 'LineString',
  Polygon = 'Polygon',
  MultiPoint = 'MultiPoint',
}

interface SpecialGeom2D {
  geom2D: {
    type: GeomType;
    coordinates: Point | Line | Polygon;
  };
}

export type SpecialCaptureObject = CaptureObject & SpecialGeom2D;
