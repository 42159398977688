import {
  Extent,
  createEmpty as createEmptyExtent,
  extend as extendExtent,
  isEmpty,
} from 'ol/extent';
import { SetState } from 'zustand';

import {
  Group,
  ProjectActions,
  ProjectInput,
  ProjectState,
} from '@agerpoint/types';

import createGroupActions from './groups';
import createLayerActions from './layers';

export default function createActions(
  set: SetState<ProjectState>
): ProjectActions {
  return {
    actions: {
      setProject: (data: ProjectInput) =>
        set((state) => {
          const nextState = addBaseMaps({ ...state, ...data });
          const groupsExtent = combineExtents(
            data.groups
              .filter(
                (group) =>
                  group.extent &&
                  group.extent[2] - group.extent[0] > 0 &&
                  group.extent[3] - group.extent[1] > 0
              )
              .map((group) => group.extent as Extent)
          );

          return {
            ...nextState,
            tags:
              data.projectTags?.map((projectTag) => ({
                id: projectTag.id || 0,
                tagName: projectTag.tagName || '',
              })) || [],
            groups: nextState.groups.sort((a, b) => b.zIndex - a.zIndex),
            desiredExtent: !isEmpty(groupsExtent)
              ? groupsExtent
              : [-180, -90, 180, 90],
          };
        }),
      setLoading: (loading) => set((state) => ({ ...state, loading })),
      setError: (error) => set((state) => ({ ...state, error })),
      setDesiredExtent: (desiredExtent) => set(() => ({ desiredExtent })),
      groups: createGroupActions(set),
      layers: createLayerActions(set),
    },
  };
}

/**
 * Ensures base maps are part of project state's layer groups.
 * Since base maps are a special group and not persisted in the database,
 * it should always be the last group of the project state.
 *
 * Note that this function modifies the input state directly instead
 * of creating a new state.
 *
 * @param state the current project state
 * @returns the updated project state
 */
function addBaseMaps(state: ProjectState): ProjectState {
  if (!state?.groups?.find((group) => group.id === BASE_MAPS_GROUP_ID)) {
    state.groups = [...state.groups, baseMapsGroup];
  }

  return state;
}

export const BASE_MAPS_GROUP_ID = -1;
const SATELLITE_BASE_MAP_ID = -1;
const STREET_MAPS_BASE_MAP_ID = -2;
const HYBRID_BASE_MAP_ID = -3;

const baseMapsGroup: Group = {
  id: BASE_MAPS_GROUP_ID,
  name: 'Base maps',
  icon: { name: 'BaseMaps', color: '#B1B5B9' },
  layers: [
    {
      id: SATELLITE_BASE_MAP_ID,
      type: 'BaseMap',
      typeId: 0,
      entityId: 0,
      displayName: 'Satellite',
      name: 'Aerial',
      visible: false,
      dimensions: 2,
      zIndex: 0,
    },
    {
      id: STREET_MAPS_BASE_MAP_ID,
      type: 'BaseMap',
      typeId: 0,
      entityId: 0,
      displayName: 'Street Maps',
      name: 'RoadOnDemand',
      visible: false,
      dimensions: 2,
      zIndex: 0,
    },
    {
      id: HYBRID_BASE_MAP_ID,
      type: 'BaseMap',
      typeId: 0,
      entityId: 0,
      displayName: 'Hybrid',
      name: 'AerialWithLabelsOnDemand',
      visible: true,
      dimensions: 2,
      zIndex: 0,
    },
  ],
  zIndex: 0,
  visible: true,
};

function combineExtents(extents: Extent[]): Extent {
  let result = createEmptyExtent();
  for (const extent of extents) {
    result = extendExtent(result, extent);
  }

  return result;
}
