import { EnvironmentTypes } from '@agerpoint/types';

export const getCurrentEnvironment = () => {
  const subdomain = window.location.hostname.split('.')[0];
  let env;
  switch (subdomain) {
    case 'cloud-dev':
      env = EnvironmentTypes.Development;
      break;
    case 'cloud-staging':
      env = EnvironmentTypes.Staging;
      break;
    case 'cloud-preproduction':
      env = EnvironmentTypes.PreProduction;
      break;
    case 'cloud':
      env = EnvironmentTypes.Production;
      break;
    case 'localhost':
      env = EnvironmentTypes.Localhost;
      break;
    default:
      env = EnvironmentTypes.Unknown;
      break;
  }
  return env;
};
