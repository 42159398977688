import { useEffect } from 'react';

import { PayloadType } from '@agerpoint/types';

import { useGlobalStore } from '../store/store';

export const useActionListener = <T>(
  actionName: string,
  effectFn: (payload: PayloadType<T>) => void
) => {
  const { subscribe } = useGlobalStore();

  useEffect(() => {
    const unsubscribe = subscribe(actionName, (p: PayloadType<T>) =>
      effectFn(p)
    );

    return unsubscribe;
  }, [actionName, effectFn, subscribe]);
};
