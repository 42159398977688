import { faCircleNotch, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CloudButton } from '@agerpoint/cloud/components';
import { IVideoViewer, IVideoViewerController } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const VideoViewer = ({ controller: setController }: IVideoViewer) => {
  const [url, setUrl] = useState<string>();

  const [viewerReady, setViewerReady] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const loadVideo = useCallback((url: string) => {
    setHasError(false);
    setUrl(url);
  }, []);

  const {
    sidebar: { isOpen: sidebarOpen },
  } = useGlobalStore();

  const controller: IVideoViewerController = useMemo(
    () => ({
      info: {
        viewerReady,
      },
      loadVideo,
    }),
    [loadVideo, viewerReady]
  );

  useEffect(() => {
    setViewerReady(true);
    return () => {
      setViewerReady(false);
    };
  }, []);

  useEffect(() => {
    setController?.(controller);
  }, [controller, setController]);

  if (hasError) {
    return (
      <div className="size-full">
        <div
          className={`flex justify-center items-center w-full h-full text-white text-lg text-center ${
            sidebarOpen ? 'pl-sidebar' : ''
          }`}
          style={{
            background: 'radial-gradient(#1b292f, #0c1417)',
          }}
        >
          <div
            className={`flex flex-col font-normal items-center bg-white
        rounded-4xl p-8 text-gray-textPrimary shadow-lg`}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-6xl text-status-error"
            />
            <div className="font-bold text-2xl pt-8">Video Not Available</div>
            <div className="pt-2 pb-8 text-base text-gray-textSecondary">
              Try refreshing the page or downloading the video
            </div>
            <CloudButton.Primary
              id="trigger-high-res-job"
              onClick={() => {
                window.open(url, '_blank');
              }}
              leadingIcon={faDownload}
              label={'Download Video'}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="size-full">
      <div
        className={`flex justify-center items-center w-full h-full text-white text-lg text-center ${
          sidebarOpen ? 'pl-sidebar' : ''
        }`}
        style={{
          background: 'radial-gradient(#1b292f, #0c1417)',
        }}
      >
        {url === undefined ? (
          <div className="flex flex-row gap-2">
            <span>Loading Video...</span>
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          <div className="p-10 size-full flex-center rounded-lg overflow-hidden shadow-lg">
            <video
              controls
              crossOrigin="anonymous"
              src={url}
              className="size-full rounded-lg"
              onError={(e) => {
                console.error(e);
                setHasError(true);
              }}
              onLoadedMetadata={(e) => {
                const videoElement = e.currentTarget as HTMLVideoElement;
                if (
                  videoElement.videoWidth === 0 ||
                  videoElement.videoHeight === 0
                ) {
                  console.error('Video format not supported');
                  setHasError(true);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
