import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';

interface ButtonBackProps {
  id: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
}

export const ButtonBack = ({ id, onClick, disabled }: ButtonBackProps) => {
  const className = useMemo(() => {
    let classes = ` border-gray-dark border transition-all rounded-full px-4
    duration-200 focus:outline-none outline-none focus:ring-2 ring-offset-1 ring-gray-dark
    flex flex-row items-center justify-center `;

    if (disabled) {
      classes += ' cursor-not-allowed opacity-50 text-disabled ';
    } else {
      classes += ' text-primary cursor-pointer ';
    }

    return classes.trim();
  }, [disabled]);

  return (
    <div
      style={{
        width: '36px',
        height: '36px',
      }}
      className={className}
      data-test-id={id}
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
      }}
      tabIndex={0}
    >
      <FontAwesomeIcon
        icon={faArrowLeft}
        style={{
          width: '20px',
          height: '20px',
        }}
      />
    </div>
  );
};
