import { Range } from 'react-range';

interface AlphaPickerProps {
  values: number[];
  onChange: (values: number[]) => void;
  onFinalChange?: (values: number[]) => void;
  step: number;
  min: number;
  max: number;
  unit?: string;
  showLabels?: boolean;
}

export function AlphaPicker({
  values,
  onChange,
  onFinalChange,
  step,
  min,
  max,
  unit,
}: AlphaPickerProps) {
  return (
    <div className="flex items-center justify-between">
      <Range
        step={step}
        min={min}
        max={max}
        values={values}
        onChange={onChange}
        onFinalChange={onFinalChange}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="h-3 w-full rounded-lg border border-gray-150"
            style={{
              ...props.style,
              background: `linear-gradient(0.25turn, #ffffff, #000000)`,
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{ ...props.style }}
            className="w-4 h-4 bg-green rounded-full border border-gray-50"
          />
        )}
      />

      <span className="ml-5 w-10 text-sm flex-shrink-0 flex justify-end">
        {(values[0] * 100).toFixed()}%
      </span>
    </div>
  );
}
