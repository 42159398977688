import { CSSProperties, useRef } from 'react';

import { Tooltip } from '../../tooltip';
import {
  CellCallbackGenerator,
  ICloudDatatableColumn,
} from '../datatable.types';

interface ICell<T> {
  data: T;
  column: ICloudDatatableColumn<T>;
  index: number;
  isRowHovered: boolean;
  cellOnClick?: CellCallbackGenerator<T>;
}

export const Cell = <T,>({
  data,
  column,
  index,
  isRowHovered,
  cellOnClick,
}: ICell<T>) => {
  const cellRef = useRef<HTMLDivElement | null>(null);

  if (column.visible === false) {
    return null;
  }
  const onClick = column.onClickId
    ? cellOnClick?.(column.onClickId)
    : undefined;

  const cell = column.cell(data, index, isRowHovered);

  let wrapperStyle: CSSProperties = {};
  if (column.fixed) {
    wrapperStyle = {
      width: column.fixed,
      minWidth: column.fixed,
      maxWidth: column.fixed,
    };
  } else {
    wrapperStyle = {
      flex: column.flex ?? 1,
      minWidth: column.flexMinWidth,
    };
  }

  return (
    <div
      style={{
        ...wrapperStyle,
        height: '100%',
      }}
      ref={cellRef}
      onClick={onClick ? (e) => onClick?.(data, e) : undefined}
      onDoubleClick={onClick ? (e) => onClick?.(data, e) : undefined}
      className={`
        flex flex-col justify-center
        ${onClick ? 'cursor-pointer' : ''}
        ${column.style?.cell ?? ''}
        ${column.style?.overflowStyle ?? ''}
        `}
    >
      <div className={`${column.style?.overflowStyle ?? ''}`}>
        {cell}
        {typeof cell === 'string' && cell && (
          <Tooltip
            position="bottom"
            childRef={cellRef}
            maxWidth="max-w-80"
            breakAll
          >
            {cell}
          </Tooltip>
        )}
      </div>
    </div>
  );
};
