import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { BreadCrumbs, Button, TabsComponent } from '@agerpoint/component';
import { TabNames, TabProps } from '@agerpoint/types';
import { useIsViteApp } from '@agerpoint/utilities';

import { useAdminAnalyticsQueries } from './admin-analytics-queries';

export const AdminAnalyticsBase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const { analyticQuery } = useAdminAnalyticsQueries();

  const tabs: TabProps[] = [
    {
      label: 'Details',
      link: 'details',
      type: TabNames.LINK,
      params,
    },
    {
      label: 'Job Types',
      link: 'job-types',
      type: TabNames.LINK,
      params,
    },
    {
      label: 'Organizations',
      link: 'organizations',
      type: TabNames.LINK,
      params,
    },
  ];

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Analytics',
              path: isViteApp
                ? '/app/admin/platform/analytics'
                : '/admin/analytics',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="analytics-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/analytics' + params);
            } else {
              navigate('/admin/analytics' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">
          {analyticQuery?.data?.analyticName}
        </h1>
      </div>
      <div className="px-4">
        <TabsComponent tabs={tabs} />
      </div>
      <Outlet />
    </div>
  );
};
