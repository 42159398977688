import { Vector2, Vector3 } from 'three';
import * as THREE from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import {
  Annotation3dPoints,
  ColorsThreeD,
  EventBusNames,
  HexColor,
  ICustomLine,
  SphereColors,
} from '@agerpoint/types';

import { eventBus } from '../event-bus';

declare module 'three' {
  export interface Sprite {
    uniqueId?: string;
    callback?: (id: string) => void;
    customType?: Annotation3dPoints;
  }
}

export const createPlane = () => {
  // Define screen coordinates
  const screenCoordinates = [
    { x: -200, y: 200, z: 0 },
    { x: 200, y: 200, z: 0 },
    { x: 200, y: -200, z: 0 },
    { x: -200, y: -200, z: 0 },
  ];

  // Create a custom geometry
  const geometry = new THREE.BufferGeometry();
  const vertices = screenCoordinates.map(
    (coord) => new THREE.Vector3(coord.x, coord.y, coord.z)
  );
  geometry.setFromPoints(vertices);
  geometry.setIndex([0, 1, 2, 0, 2, 3]);

  // Create a material
  const material = new THREE.MeshLambertMaterial({
    color: 0xcccccc,
    side: THREE.DoubleSide,
  });

  // Create a mesh
  const plane = new THREE.Mesh(geometry, material);
  return plane;
};

export class CustomMesh extends THREE.Mesh {
  uniqueId = '';
  callback: (id: string) => void;
  updatePosition: (pos: Vector3) => void;
  customType?: Annotation3dPoints;

  constructor(
    geometry: THREE.BufferGeometry,
    material: THREE.Material | THREE.Material[]
  ) {
    super(geometry, material);
    // Initialize custom methods or properties
    this.updatePosition = (pos: Vector3) => {
      this.position.set(pos.x, pos.y, pos.z);
    };
    this.callback = (id: string) => {
      // do nothing
    };
  }

  disposeAndRemove() {
    this.geometry.dispose();
    if (Array.isArray(this.material)) {
      this.material.forEach((material) => material.dispose());
    } else {
      this.material.dispose();
    }
    this.parent?.remove(this);
  }
}

export const createSphereObject = (
  id: string,
  point: Vector3,
  eventName: EventBusNames | null,
  type: Annotation3dPoints,
  isPotree: boolean,
  color?: SphereColors | ColorsThreeD | HexColor,
  size?: number
) => {
  let radius;
  if (size) {
    radius = size;
  } else {
    radius = isPotree ? 0.017 : 0.03;
  }

  const geometry = new THREE.SphereGeometry(radius, 20, 20);

  const material = new THREE.MeshBasicMaterial({
    color: color || SphereColors.Cyan,
  });
  material.needsUpdate = true;
  const sphere = new CustomMesh(geometry, material);
  sphere.position.set(point.x, point.y, point.z);
  if (eventName && eventName !== null) {
    sphere.callback = (id: string) => {
      eventBus.dispatch(eventName, {
        detail: { id },
      });
    };
  }

  sphere.uniqueId = id;
  sphere.customType = type;
  sphere.updatePosition = (pos: Vector3) => {
    sphere.position.set(pos.x, pos.y, pos.z);
  };
  return sphere;
};

export const createRing = (point: Vector3, color: SphereColors) => {
  const geometry = new THREE.TorusGeometry(0.5, 0.01, 2, 100);
  const material = new THREE.MeshBasicMaterial({
    color: color || SphereColors.Magenta,
  });
  const torus = new THREE.Mesh(geometry, material);
  torus.position.set(point.x, point.y, point.z);
  return torus;
};

export const createLine = (id: string, color?: SphereColors, linewidth = 6) => {
  const lineGeometry = new LineGeometry();

  const lineMaterial = new LineMaterial({
    color: color || 0x00ff00,
    dashSize: 5,
    gapSize: 2,
    linewidth,
    resolution: new Vector2(1000, 1000),
  });

  const line = new Line2(lineGeometry, lineMaterial);
  //@ts-expect-error: we can add anything we want to Line2
  line.uniqueId = id;

  return line;
};

export const getLinearPositions = (linePath: Vector3[], count: number) => {
  const first = linePath[0];
  const linearPositions = [];

  for (let i = 0; i < linePath.length - 1; i++) {
    const start = linePath[i];
    const end = linePath[i + 1];
    for (let k = 0; k <= count; k++) {
      const t = k / count;
      const position = start.clone().lerp(end, t).sub(first);
      linearPositions.push(position.x, position.y, position.z);
    }
  }
  return linearPositions;
};

export const addGeometryToLine = (
  line: Line2 | ICustomLine,
  linePath: Vector3[],
  count = 100
) => {
  const linearPositions = getLinearPositions(linePath, count);
  line.geometry.setPositions(linearPositions);

  line.geometry.computeBoundingSphere();
  line.position.copy(linePath[0]);
  line.computeLineDistances();
  return line;
};

export const createPyramid = (
  x: number,
  y: number,
  z: number,
  roll: number,
  pitch: number,
  yaw: number
) => {
  // Create a custom pyramid wireframe
  const pyramidGeometry = new THREE.BufferGeometry();

  // Define the vertices of the pyramid
  const height = 0.1; // Adjust the height as needed
  const halfBaseWidth = 0.05; // Half of the pyramid's base width
  const vertices = new Float32Array([
    0,
    0,
    0,
    -halfBaseWidth,
    -halfBaseWidth,
    height,
    halfBaseWidth,
    -halfBaseWidth,
    height,
    halfBaseWidth,
    halfBaseWidth,
    height,
    -halfBaseWidth,
    halfBaseWidth,
    height,
    0,
    0,
    height,
  ]);

  pyramidGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(vertices, 3)
  );

  // Define the edges of the pyramid
  const indices = new Uint16Array([
    0, 1, 0, 2, 0, 3, 0, 4, 1, 2, 2, 3, 3, 4, 4, 1, 1, 5, 2, 5, 3, 5, 4, 5,
  ]);

  pyramidGeometry.setIndex(new THREE.BufferAttribute(indices, 1));

  const pyramidMaterial = new THREE.LineBasicMaterial({
    color: 0xffffff, // Wireframe color
  });

  const pyramid = new THREE.LineSegments(pyramidGeometry, pyramidMaterial);
  pyramid.position.set(x, y, z);

  // Adjust the yaw angle to face the opposite direction
  // yaw += Math.PI;

  pyramid.rotation.set(pitch, yaw, roll);

  return pyramid;
};

export function createCameraPose(
  x: number,
  y: number,
  z: number,
  roll: number,
  pitch: number,
  yaw: number
): THREE.Matrix4 {
  // Create a translation vector
  const translation = new THREE.Vector3(x, y, z);

  // Create rotation matrices for roll, pitch, and yaw
  const rotationRoll = new THREE.Matrix4().makeRotationAxis(
    new THREE.Vector3(0, 0, 1),
    roll
  );
  const rotationPitch = new THREE.Matrix4().makeRotationAxis(
    new THREE.Vector3(0, 1, 0),
    pitch
  );
  const rotationYaw = new THREE.Matrix4().makeRotationAxis(
    new THREE.Vector3(1, 0, 0),
    yaw
  );

  // Combine the rotation matrices
  const rotationMatrix = new THREE.Matrix4().multiplyMatrices(
    rotationYaw,
    new THREE.Matrix4().multiplyMatrices(rotationPitch, rotationRoll)
  );

  // Create the transformation matrix
  const transformationMatrix = new THREE.Matrix4();
  transformationMatrix.compose(
    translation,
    new THREE.Quaternion(),
    new THREE.Vector3(1, 1, 1)
  );

  // Apply the rotation to the transformation matrix
  transformationMatrix.multiply(rotationMatrix);

  return transformationMatrix;
}

// export function calculateViewFrustum(
//   cameraPose: THREE.Matrix4,
//   camera: THREE.PerspectiveCamera
// ): THREE.Frustum {
//   const viewMatrix = new THREE.Matrix4();
//   viewMatrix.getInverse(cameraPose); // Calculate the view matrix from the camera pose

//   // Calculate the combined projection-view matrix
//   const projectionMatrix = camera.projectionMatrix;
//   const projectionViewMatrix = new THREE.Matrix4().multiplyMatrices(
//     projectionMatrix,
//     viewMatrix
//   );

//   // Create a frustum from the projection-view matrix
//   const viewFrustum = new THREE.Frustum();
//   viewFrustum.setFromMatrix(projectionViewMatrix);

//   return viewFrustum;
// }

// export const createSpriteObject = (
//   id: string,
//   point: Vector3,
//   eventName: EventBusNames | null,
//   type: CaptureViewerGeometryTypes | Annotation3dPoints,
//   isPotree: boolean
// ) => {
//   const textureLoader = new TextureLoader();
//   const texture = textureLoader.load('assets/location-dot-sharp-regular.png');

//   const material = new SpriteMaterial({
//     map: texture,
//     color: 0xffffff,
//     fog: true,
//     alphaTest: 0.5,
//     // @ts-expect-error this property is not in the type definition
//     sizeAttenuation: false,
//   });

//   const sprite = new Sprite(material) as CustomSprite;
//   const spriteHeight = isPotree ? 0.034 : 0.05; // Adjust size based on isPotree
//   const spriteWidth = spriteHeight * 0.75;
//   sprite.scale.set(spriteWidth, spriteHeight, 1);
//   sprite.position.set(point.x, point.y + 0.5, point.z);

//   sprite.uniqueId = id;
//   sprite.type = type;
//   sprite.updatePosition = (pos: Vector3) => {
//     sprite.position.set(pos.x, pos.y + 0.5, pos.z);
//   };
//   return sprite;
// };
