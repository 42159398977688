import * as THREE from 'three';
import { Vector3 } from 'three';

import {
  Annotation2dPoints,
  IAnnotations2d,
  IAnnotations3d,
  SVG_PATH_NAMES,
} from './annotations';
import { IBaseViewer } from './base-viewer';
import { CaptureObjectMarkerType } from './captures';
import { IClassification } from './classifications';
import { HexColor } from './colors';
import { EventBusNames } from './event-bus';
import { IOverlay2d } from './overlay-2d';
import {
  CameraState,
  ColorsThreeD,
  MeasurementOptionsValues,
  PointSizeType,
} from './potree-types';

export enum MarkerClassNames2d {
  'CustomMarkerIcon' = 'custom-marker-icon',
}

export interface IMarkerObj {
  position: THREE.Vector3;
  element: HTMLElement;
  labelElement: HTMLElement;
  options: MarkerObjOptions;
  type: CaptureObjectMarkerType | Annotation2dPoints | undefined;
  editable: boolean;
  updateEditability: (value: boolean) => void;
  id: string;
  isHighlighted: boolean;
  updatePosition: () => void;
  get2dCoords: (position: THREE.Vector3) => THREE.Vector2;
  highlight: () => void;
  unHighlight: () => void;
  color: ColorsThreeD | HexColor;
}

export interface MarkerObjOptions {
  fill: ColorsThreeD | HexColor;
  name?: string;
  visible?: boolean;
  visibleLabel?: boolean;
  type?: CaptureObjectMarkerType | Annotation2dPoints;
  clickable: boolean;
  editable: boolean;
  pathType?: SVG_PATH_NAMES;
}

export interface PointCloudSettings {
  pointSize: number;
  pointSizeType: number;
  pointBudget: number;
  useHQ: boolean;
  imageGalleryAutoScroll: boolean;
  showDebugTools: boolean;
}

export interface CameraGeometry {
  x: number;
  y: number;
  z: number;
  roll: number;
  pitch: number;
  yaw: number;
  id: number;
}

export interface IViewer extends IBaseViewer {
  pointCloudReady: boolean;
  classification: IClassification;
  overlay2d: IOverlay2d;
  annotations2d: IAnnotations2d;
  annotations3d: IAnnotations3d | undefined;
  targetEl: HTMLElement;

  initialize(): Promise<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  load(fileName: string, baseUrl: string, opts?: any): Promise<any>;
  render(): void;
  destroy(): void;
  togglePointCloudVisibility(showHide: boolean): void;
  setPointBudget(pointBudget: number): void;
  clearPointCloudScene(): void;
  setBackground(
    arg1: [number, number, number, number] | string,
    arg2?: [number, number, number, number]
  ): void;
  add2dPointMarker(
    eventName: EventBusNames,
    eventId: string,
    position: Vector3,
    options: MarkerObjOptions
  ): void;
  clear2dPointMarkers(): void;
  setTool(tool: MeasurementOptionsValues): void;
  removeAllMeasurements(): void;
  showHideAllMeasurements(show: boolean): void;
  resetView(): void;
  updateMaterialProperties(opts: {
    pointSizeType?: PointSizeType;
    pointSize?: number;
  }): void;
  updateSplatQuality(splatQuality: string): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addPrism(prism: any): void;
  clearAllPrisms(): void;
  indexImageLocation(
    id: number,
    x: number,
    y: number,
    z: number,
    roll: number,
    pitch: number,
    yaw: number
  ): void;
  startCropBox(): void;
  stopCropBox(): void;
  getCropBoxBoundingBox(): { min: THREE.Vector3; max: THREE.Vector3 };
  getCropBoxCoordinates(): number[];
  toggleCursor(nextCursor?: string): void;
  locateObject(id: string, highlighted: boolean): void;
  toggleObjectVisibility(id: string, show: boolean): void;
  toggleTextLabelById(id: string, show: boolean): void;
  updateObjectEditability(id: string, editable: boolean): void;
  addExistingObjectLocation(
    eventName: EventBusNames,
    eventId: string,
    position: Vector3,
    options: MarkerObjOptions
  ): void;
  addNewObjectLocation(
    eventName: EventBusNames,
    eventId: string,
    options: MarkerObjOptions
  ): Promise<Vector3>;
  clearMarkerById(id: string): void;
  clearAllMarkers(): void;
  drawTrunkLine(path: number[][], id: string): void;
  toggleTrunkLineVisibility(id: string, show: boolean): void;
  clearAllTrunkLines(): void;
  drawConvexHull(path: number[][], id: string): void;
  clearConvexHull(id: string): void;
  clearAllConvexHulls(): void;
  removeAllMeshes(): void;
  startObjectSelection(): void;
  stopObjectSelection(): void;
  get3DPointFromXYZ(x: number, y: number, z?: number): Vector3;
  resetAllObjectMarkers(): void;
  selectObjectsByLocation2D(coords: Vector3[]): void;
  getHighlightedMarkers(): string[];
  addFeatureInfoModal(
    position: Vector3,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: React.ReactElement<any, any>
  ): void;
  removeFeatureInfoModal(): void;
  drawFindCameraPlane(): void;
  zoomToLocation(
    loc: { x: number; y: number; z: number },
    distance?: number
  ): void;
  getCameraSettings(): CameraState;
  setCameraSettings(cameraState: CameraState): void;
  setCameraFrontView(): void;
  findCenter(): Vector3 | undefined;
}
