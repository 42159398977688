import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';

import { InputIdContext } from '../input-id-context';
import { InputPlaceholderIcon } from '../input-placeholder-icons';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface InputDateRangeProps {
  /**
   * The unique identifier for the input component.
   */
  id: string;

  /**
   * The label to be displayed above the input component.
   */
  label?: React.ReactNode;

  /**
   * The error message to be displayed below the input component.
   */
  error?: React.ReactNode;

  /**
   * The placeholder text for the input component.
   */
  placeholder?: string;

  /**
   * The placeholder icon to display before the placeholder text.
   */
  placeholderIcon?: InputPlaceholderIcon;

  /**
   * The value of the date range.
   */
  value: [Date | undefined, Date | undefined];

  /**
   * The function to set the value of the date range.
   */
  setValue: (value: [Date | undefined, Date | undefined]) => void;

  /**
   * The validation configuration for the input component.
   */
  validation?: InputValidation<[Date | undefined, Date | undefined]>;

  /**
   * New version used in cloud app
   */
  cloud?: boolean;

  /**
   * Footer to be displayed below the input component.
   */
  footer?: React.ReactNode;
}

const InputDateRange = ({
  id,
  error,
  label,
  placeholder: placeholderProp,
  placeholderIcon,
  value,
  setValue,
  validation,
  cloud = false,
  footer,
}: InputDateRangeProps) => {
  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const placeholder = `${placeholderIcon ? String(placeholderIcon) : ''} ${
    placeholderProp ?? ''
  }`.trim();

  const className = cloud
    ? 'rounded-lg text-sm w-full focus:ring-primary ring-primary focus:border-primary border-gray-border'
    : 'rounded text-sm w-full focus:ring-green ring-green focus:border-green';

  return (
    <InputIdContext.Provider value={id}>
      <div
        className={`flex flex-col w-full ${
          isCalendarOpen ? 'z-50' : ''
        }`.trim()}
        style={{
          fontFamily: 'nunito, "Font Awesome 6 Pro"',
        }}
      >
        {label ?? null}
        <DatePicker
          data-test-id={id}
          className={className}
          popperClassName="text-sm z-50"
          startDate={value[0]}
          endDate={value[1]}
          onCalendarOpen={() => setIsCalendarOpen(true)}
          onCalendarClose={() => setIsCalendarOpen(false)}
          placeholderText={placeholder}
          onChange={(date) => {
            setValue(date as [Date | undefined, Date | undefined]);
          }}
          selectsRange
          dateFormat={cloud ? 'MMM dd yyy' : 'MM/dd/yyyy'}
        >
          {footer}
        </DatePicker>
        {error ?? null}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputDateRange };
