import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CloudButton } from '@agerpoint/cloud/components';
import { IImageViewer, IImageViewerController } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const ImageViewer = ({ controller: setController }: IImageViewer) => {
  const [url, setUrl] = useState<string>();

  const [viewerReady, setViewerReady] = useState<boolean>(false);

  const loadImage = useCallback((url: string) => {
    setUrl(url);
  }, []);

  const clearImage = useCallback(() => {
    setUrl(undefined);
  }, []);

  const {
    sidebar: { isOpen: sidebarOpen },
  } = useGlobalStore();

  const controller: IImageViewerController = useMemo(
    () => ({
      info: {
        viewerReady,
      },
      loadImage,
      clearImage,
    }),
    [loadImage, clearImage, viewerReady]
  );

  useEffect(() => {
    setViewerReady(true);
    return () => {
      setViewerReady(false);
    };
  }, []);

  useEffect(() => {
    setController?.(controller);
  }, [controller, setController]);

  const [clicked, setClicked] = useState(false);
  const [zoom, setZoom] = useState<{
    value: number;
    duration: number;
  }>({
    value: 0.5,
    duration: 0.0,
  });
  const [rotate, setRotate] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const onWheel = useCallback((e: WheelEvent) => {
    e.preventDefault();
    setZoom((prev) => {
      const newZoom = prev.value - e.deltaY / 100;
      return {
        value: Math.min(3, Math.max(0.5, newZoom)),
        duration: 0.0,
      };
    });
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    container.addEventListener('wheel', onWheel, {
      passive: false,
    });

    return () => {
      container.removeEventListener('wheel', onWheel);
    };
  }, [onWheel]);

  return (
    <div className="w-full h-full relative" ref={containerRef}>
      <div
        className={`flex justify-center items-center w-full h-full text-white text-lg text-center ${
          sidebarOpen ? 'pl-sidebar' : ''
        }`}
        style={{
          background: 'radial-gradient(#1b292f, #0c1417)',
        }}
      >
        {url === undefined ? (
          <div className="flex flex-row gap-2">
            <span>Loading Image...</span>
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          <motion.div
            drag
            dragMomentum={false}
            initial={{
              scale: zoom.value,
              rotate: rotate,
            }}
            animate={{
              scale: zoom.value,
              rotate: rotate,
              transition: {
                rotate: {
                  type: 'spring',
                  damping: 30,
                  stiffness: 500,
                  duration: 0.3,
                },
                scale: {
                  duration: zoom.duration,
                },
              },
            }}
            className={`flex-center rounded-lg overflow-hidden shadow-lg ${
              clicked ? 'cursor-grabbing' : 'cursor-grab'
            }`}
            onPointerDown={() => setClicked(true)}
            onPointerUp={() => setClicked(false)}
          >
            <img
              src={url}
              className="size-full pointer-events-none"
              crossOrigin="anonymous"
              alt="Capture Viewer"
            />
          </motion.div>
        )}
      </div>
      <div className="absolute top-4 right-4 z-1 flex flex-col gap-2">
        <div className="flex flex-col bg-white rounded-lg shadow-lg p-1 gap-1">
          <CloudButton.Icon
            id="modal-zoom-in"
            leadingIcon="plus"
            tooltip="Zoom In"
            tooltipPosition="left"
            onClick={() => {
              setZoom((prev) => ({
                value: Math.min(3, prev.value + 0.5),
                duration: 0.3,
              }));
            }}
          />
          <CloudButton.Icon
            id="modal-zoom-out"
            leadingIcon="minus"
            tooltip="Zoom Out"
            tooltipPosition="left"
            onClick={() => {
              setZoom((prev) => ({
                value: Math.max(0.5, prev.value - 0.5),
                duration: 0.3,
              }));
            }}
          />
        </div>
        <div className="bg-white rounded-lg shadow-lg p-1">
          <CloudButton.Icon
            id="modal-rotate"
            leadingIcon="rotate-right"
            tooltip="Rotate"
            tooltipPosition="left"
            onClick={() => {
              setRotate((prev) => prev + 90);
            }}
          />
        </div>
      </div>
    </div>
  );
};
