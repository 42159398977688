import { useCallback, useEffect, useState } from 'react';
import * as THREE from 'three';

function useThreeSceneSetup(containerRef: HTMLDivElement | null) {
  const [threeObjects, setThreeObjects] = useState(
    {} as {
      camera: THREE.PerspectiveCamera;
      scene: THREE.Scene;
      renderer: THREE.WebGLRenderer;
      rootElement: HTMLDivElement;
    }
  );

  const sceneSetup = useCallback(() => {
    if (!containerRef) return;

    // Scene setup
    const scene = new THREE.Scene();
    console.info(
      'Scene Orientation:',
      'x:',
      scene.up.x,
      'y:',
      scene.up.y,
      'z:',
      scene.up.z
    );
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
      // precision: 'lowp',
    });

    // DOM setup
    const renderWidth = containerRef.offsetWidth;
    const renderHeight = containerRef.offsetHeight;
    renderer.setSize(renderWidth, renderHeight);
    renderer.setClearAlpha(0);

    const rootElement = document.createElement('div');
    rootElement.classList.add('rootElement');
    rootElement.style.width = `${renderWidth}px`;
    rootElement.style.height = `${renderHeight}px`;
    rootElement.appendChild(renderer.domElement);
    containerRef.appendChild(rootElement);

    const camera = new THREE.PerspectiveCamera(
      75,
      renderWidth / renderHeight, // Aspect ratio
      0.0001,
      1000
    );
    camera.position.set(0, -10, 15);
    camera.lookAt(new THREE.Vector3(0, 0, 0));
    camera.up = new THREE.Vector3().fromArray([0, -1, 0]).normalize();

    console.log(
      "Camera's Position:",
      'x:',
      camera.position.x,
      'y:',
      camera.position.y,
      'z:',
      camera.position.z
    );

    console.log(
      'Camera Orientation:',
      'x:',
      camera.up.x,
      'y:',
      camera.up.y,
      'z:',
      camera.up.z
    );

    setThreeObjects({ camera, scene, renderer, rootElement });
  }, [containerRef]);

  useEffect(() => {
    sceneSetup();
  }, [containerRef]);

  return threeObjects;
}

export default useThreeSceneSetup;
