import compare from 'trivial-compare';

import { APIModels } from '../..';

export const captureExtractionJobStatuses = (
  data?: APIModels.CaptureExtractionJobStatus[]
) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) => compare(a.name, b.name));
  return sorted;
};
