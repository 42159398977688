import { ReactNode } from 'react';

interface ExpansionContentContainerProps {
  children: ReactNode;
}

export function ExpansionContentContainer({
  children,
}: ExpansionContentContainerProps) {
  return <div className="flex flex-row w-full h-full">{children}</div>;
}

export function ExpansionContentTabsContainer({
  children,
}: ExpansionContentContainerProps) {
  return (
    <div className="w-40 flex flex-col flex-shrink-0">
      {children} <div className="h-full border-r border-gray-300" />
    </div>
  );
}

export function ExpansionContentTabContentContainer({
  children,
}: ExpansionContentContainerProps) {
  return <div className="w-full py-1 mx-3">{children}</div>;
}
