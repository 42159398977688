import { useState } from 'react';
import { VariableSizeList } from 'react-window';

import { ICloudDatatable, RefContext, StyleContext } from './datatable.types';
import { Body } from './elements/body';
import { BasicExtraRow } from './elements/extra-rows';
import { Headers } from './elements/headers';
import { BasicOverlay } from './elements/overlays';
import { Wrapper } from './elements/wrapper';

const CloudDatatable = <T,>({
  id,
  data,
  style,
  columns,
  setSort,
  sort,
  cellOnClick,
  rowOnLongTouch,
  rowHeight,
  pagination,
  loading,
  overlay,
  extraRow,
}: ICloudDatatable<T>) => {
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const [headersRef, setHeadersRef] = useState<HTMLDivElement | null>(null);
  const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);
  const [datatableRef, setDatatableRef] = useState<VariableSizeList | null>(
    null
  );

  return (
    <StyleContext.Provider value={style}>
      <RefContext.Provider
        value={{
          setWrapperRef,
          setHeadersRef,
          setBodyRef,
          setDatatableRef,
          wrapperRef,
          headersRef,
          bodyRef,
          datatableRef,
        }}
      >
        <Wrapper>
          <>
            <Headers columns={columns} setSort={setSort} sort={sort} />
            <Body
              columns={columns}
              data={data}
              id={id}
              cellOnClick={cellOnClick}
              rowOnLongTouch={rowOnLongTouch}
              rowHeight={rowHeight}
              pagination={pagination}
              loading={loading}
              overlay={overlay}
              extraRow={extraRow}
            />
          </>
        </Wrapper>
      </RefContext.Provider>
    </StyleContext.Provider>
  );
};

CloudDatatable.BasicOverlay = BasicOverlay;
CloudDatatable.BasicExtraRow = BasicExtraRow;

export { CloudDatatable };
