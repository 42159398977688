import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Group,
  Layer,
  MapMode,
  SetVisibleFn,
  toggleExpansionFn,
} from '@agerpoint/types';

import { GroupListItem } from '../group-list-item/group-list-item';

interface GroupsParams {
  groups: Array<Group>;
  mode: MapMode;
  selectedLayerId?: number;
  setGroupVisible: SetVisibleFn;
  setLayerVisible: SetVisibleFn;
  setLayerStyle: (layerId: number, layer: Layer) => void;
  setSelectedLayerId: (layerId?: number) => void;
  saveNewGroup: (name?: string) => Promise<void>;
  bingKey: string;
  isClientSideGroup: (id: Group) => boolean;
  currentExpansionId?: string;
  toggleExpansion?: toggleExpansionFn;
  setMultiSelectedLayers?: (addedSelection: number[]) => void;
  multiSelectGroupId?: number | null;
  setMultiSelectGroupId?: (groupId: number | null) => void;
  clearMultiSelection?: () => void | undefined;
  setMultiSelectLayerTypeId?: (layerTypeId: number | null) => void;
  multiSelectedItems?: number[];
  layerTypeId: number | null;
}

export function Groups({
  groups,
  mode,
  selectedLayerId,
  setGroupVisible,
  setLayerVisible,
  setLayerStyle,
  setSelectedLayerId,
  saveNewGroup,
  currentExpansionId,
  toggleExpansion,
  isClientSideGroup,
  bingKey,
  setMultiSelectedLayers,
  multiSelectGroupId,
  setMultiSelectGroupId,
  clearMultiSelection,
  setMultiSelectLayerTypeId,
  layerTypeId,
  multiSelectedItems,
}: GroupsParams) {
  const draggableGroups = groups.filter((group) => !isClientSideGroup(group));
  const nonDraggableGroups = groups.filter((group) => isClientSideGroup(group));

  return (
    <div className={'relative flex-grow'}>
      <Droppable droppableId="groups" type="group">
        {({ droppableProps, innerRef, placeholder }) => (
          <div ref={innerRef} {...droppableProps}>
            {draggableGroups.map((group, index) =>
              group.name ? (
                <Draggable
                  draggableId={`group-${group.id}`}
                  index={index}
                  key={group.id}
                >
                  {({ draggableProps, dragHandleProps, innerRef }) => (
                    <GroupListItem
                      group={group}
                      mode={mode}
                      selectedLayerId={selectedLayerId}
                      setGroupVisible={setGroupVisible}
                      setLayerVisible={setLayerVisible}
                      setLayerStyle={setLayerStyle}
                      setSelectedLayerId={setSelectedLayerId}
                      dragHandleProps={dragHandleProps as any}
                      draggableProps={draggableProps}
                      innerRef={innerRef}
                      bingKey={bingKey}
                      toggleExpansion={toggleExpansion}
                      currentExpansionId={currentExpansionId}
                      isNewGroup={false}
                      setMultiSelectedLayers={setMultiSelectedLayers}
                      multiSelectGroupId={multiSelectGroupId}
                      setMultiSelectGroupId={setMultiSelectGroupId}
                      clearMultiSelection={clearMultiSelection}
                      multiSelectedItems={multiSelectedItems}
                      setMultiSelectLayerTypeId={setMultiSelectLayerTypeId}
                      layerTypeId={layerTypeId}
                    />
                  )}
                </Draggable>
              ) : (
                <GroupListItem
                  key={group.id}
                  group={group}
                  mode={mode}
                  selectedLayerId={selectedLayerId}
                  setGroupVisible={setGroupVisible}
                  setLayerVisible={setLayerVisible}
                  setLayerStyle={setLayerStyle}
                  setSelectedLayerId={setSelectedLayerId}
                  saveNewGroup={saveNewGroup}
                  bingKey={bingKey}
                  isNewGroup={true}
                  layerTypeId={layerTypeId}
                />
              )
            )}
            {placeholder}
          </div>
        )}
      </Droppable>

      {nonDraggableGroups.map((group) => (
        <GroupListItem
          key={group.id}
          group={group}
          mode={mode}
          selectedLayerId={selectedLayerId}
          setGroupVisible={setGroupVisible}
          setLayerVisible={setLayerVisible}
          setLayerStyle={setLayerStyle}
          setSelectedLayerId={setSelectedLayerId}
          bingKey={bingKey}
          isNewGroup={false}
          layerTypeId={layerTypeId}
        />
      ))}
    </div>
  );
}
