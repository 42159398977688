import { User } from '@agerpoint/api';
import { AppBar } from '@agerpoint/feature';
import { AppBarProps, LDFlagSet, UserClaims } from '@agerpoint/types';
import { getAppBarProps, getNavItems } from '@agerpoint/utilities';

interface NavigationProps {
  user: User | undefined | null;
  permissions: LDFlagSet;
}

export default function Navigation({ user, permissions }: NavigationProps) {
  const navigation = getNavItems(user?.cloudClaims as UserClaims[]);
  const appBarProps: AppBarProps = getAppBarProps(user || ({} as User));

  return (
    <div className="relative">
      <AppBar {...appBarProps} compact={true} />
    </div>
  );
}
