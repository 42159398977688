import {
  faArrowUpRightFromSquare,
  faCircleCheck,
  faCircleNotch,
  faEllipsis,
  faFilterSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as faSolidCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInfiniteQuery } from '@tanstack/react-query';

import { APIClient, APIModels, formatDate } from '@agerpoint/api';
import { Button } from '@agerpoint/component';
import {
  CaptureThumbnail,
  ContextMenu,
  ContextMenuGroupProps,
  Datatable,
  dataTableAgerStyle,
} from '@agerpoint/feature';
import { getCaptureModeNiceName } from '@agerpoint/types';
import {
  getCapturesUnique3DModelList,
  useGlobalStore,
  useItemSelection,
} from '@agerpoint/utilities';

import { useAdminCapturesQueries } from '../admin-captures-queries';

interface AdminCapturesListDatatableProps {
  capturesQuery: ReturnType<
    typeof useInfiniteQuery<APIClient.Capture[], Error>
  >;
  hasFiltersApplied: boolean;
  clearFilters: () => void;
  captureSelection: ReturnType<
    typeof useItemSelection<number, APIModels.Capture>
  >;
  rowOnClick?: (
    row: APIModels.Capture,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  contextMenuItems: (item: APIModels.Capture) => ContextMenuGroupProps[];
  filter: APIModels.CaptureFilter | undefined;
  setFilter: React.Dispatch<
    React.SetStateAction<APIModels.CaptureFilter | undefined>
  >;
}

export const AdminCapturesListDatatable = ({
  capturesQuery,
  hasFiltersApplied,
  clearFilters,
  captureSelection,
  rowOnClick,
  filter,
  setFilter,
  contextMenuItems,
}: AdminCapturesListDatatableProps) => {
  const { permissions } = useGlobalStore();

  const { organizationsLookupTable, usersLookupTable } =
    useAdminCapturesQueries();

  return (
    <Datatable
      id="admin-captures-datatable-list"
      data={capturesQuery.data?.pages.flatMap((x) => x) ?? []}
      style={{ ...dataTableAgerStyle, tableMinWidth: 1280 }}
      rowHeight={60}
      noResults={
        hasFiltersApplied
          ? {
              title: 'No matching captures',
              message: 'Adjust your filters and try again',
              action: clearFilters,
              actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
              actionLabel: 'Clear Filters',
            }
          : {
              title: 'No captures yet',
              message: 'Upload captures with the Agerpoint Capture App',
              action: () => {
                const a = document.createElement('a');
                a.href =
                  'https://apps.apple.com/sg/app/agerpoint-capture/id1629732675';
                a.target = '_blank';
                a.click();
              },
              actionIcon: <FontAwesomeIcon icon={faArrowUpRightFromSquare} />,
              actionLabel: 'Get the Capture App',
            }
      }
      error={
        capturesQuery.isError
          ? {
              title: 'There was a problem loading captures',
              message: 'Try refreshing the page',
              action: () => capturesQuery.refetch(),
            }
          : undefined
      }
      columns={[
        {
          label: '',
          value: function Thumbnail(row) {
            return (
              <CaptureThumbnail
                capture={row}
                ldFlags={permissions}
                size="small"
              />
            );
          },
          name: 'thumbnail',
        },
        {
          label: 'Name',
          value: (row) => {
            return <span title={row.captureName || ''}>{row.captureName}</span>;
          },
          flex: 2,
          name: 'captureName',
          sortKey: 'captureName',
        },
        {
          label: 'Capture Date',
          value: (row) =>
            row.scanDatetime ? formatDate(row.scanDatetime) : null,
          name: 'captureScanDate',
          sortKey: 'scanDatetime',
        },
        {
          label: 'Create Date',
          value: (row) =>
            row.createDatetime ? formatDate(row.createDatetime) : null,
          name: 'captureCreateDate',
          sortKey: 'createDatetime',
        },
        {
          label: 'Mode',
          value: (row) => {
            if (!row.captureModeId) return '-';
            return getCaptureModeNiceName(row.captureModeId);
          },
          name: 'mode',
        },
        {
          label: '3D Model',
          value: (row) => {
            return getCapturesUnique3DModelList(row)?.join(', ');
          },

          name: 'resolution',
        },
        {
          label: 'Size (MB)',
          value: (row) => row.fileSize,
          name: 'fileSize',
          sortKey: 'fileSize',
        },
        {
          label: 'User',
          name: 'userName',
          value: (row) => {
            if (!row.createdById) {
              return null;
            }

            if (!usersLookupTable) {
              return <FontAwesomeIcon icon={faCircleNotch} spin />;
            }

            const u = usersLookupTable?.[row.createdById];

            if (!u) {
              return 'Unknown User';
            }

            return `${u?.userProfiles?.[0].firstName} ${u?.userProfiles?.[0].lastName}`.trim();
          },
        },
        {
          label: 'Organization',
          value: (row) => {
            if (!row.customerId) {
              return null;
            }

            if (!organizationsLookupTable) {
              return <FontAwesomeIcon icon={faCircleNotch} spin />;
            }

            const org = organizationsLookupTable?.[row.customerId];

            return org?.customerDisplayName ?? org?.customerName ?? null;
          },
          name: 'organization',
        },
        {
          label: 'Actions',
          value: function Action(row, index) {
            if (row.id) {
              return (
                <div className="p-1">
                  <ContextMenu
                    dataTestId={`captures-list-context-menu-${index}`}
                    groups={contextMenuItems(row)}
                    icon={
                      <Button.Icon
                        id={`actions-button-${index}`}
                        icon={faEllipsis}
                      />
                    }
                  />
                </div>
              );
            }
            return null;
          },
          style: { columnWrapperStyle: 'flex justify-center' },
        },
        {
          label: (
            <div className="p-1">
              {captureSelection.isEverythingSelected ? (
                <Button.Small
                  id="captures-list-deselect-all"
                  label={'Deselect All'}
                  onClick={() => {
                    captureSelection.toggleSelectionEverything();
                  }}
                  icon={faSolidCircleCheck}
                  iconColor={'text-accent'}
                />
              ) : (
                <Button.Small
                  id="captures-list-select-all"
                  label={'Select All'}
                  onClick={() => {
                    captureSelection.toggleSelectionEverything();
                  }}
                  icon={faCircleCheck}
                  iconColor={undefined}
                />
              )}
            </div>
          ),
          value: function Select(row, index) {
            const isSelected = row.id
              ? captureSelection.isSelected(row.id)
              : false;

            return (
              <div className="p-1">
                <Button.Icon
                  id={`captures-list-select-one-${index}`}
                  icon={isSelected ? faSolidCircleCheck : faCircleCheck}
                  onClick={(e) => {
                    if (!row.id) {
                      return;
                    }
                    if (e.shiftKey) {
                      captureSelection.addBulkSelectionUntilItem(row.id, row);
                    } else {
                      captureSelection.toggleSelection(row.id, row);
                    }
                  }}
                  iconColor={isSelected ? 'text-accent' : undefined}
                />
              </div>
            );
          },
          style: {
            columnWrapperStyle: 'flex justify-center',
          },
        },
      ]}
      cellOnClick={(columnName) => {
        const clickable = [
          'thumbnail',
          'captureName',
          'captureScanDate',
          'captureCreateDate',
          'resolution',
          'fileSize',
          'numberImages',
          'organization',
          'userName',
          'mode',
        ];
        if (clickable.includes(columnName)) {
          return rowOnClick;
        }
        return;
      }}
      loading={
        capturesQuery.isFetching ||
        capturesQuery.isFetchingNextPage ||
        filter === undefined
      }
      pagination={{
        threshold: 10,
        loadNextPage: () => {
          if (
            capturesQuery.isFetching ||
            capturesQuery.isFetchingNextPage ||
            !capturesQuery.hasNextPage ||
            filter === undefined
          ) {
            return;
          }

          capturesQuery.fetchNextPage();
        },
      }}
      sort={{
        key: filter?.orderBy,
        order: filter?.orderAscending ? 'asc' : 'desc',
      }}
      setSort={(options) => {
        setFilter((prev) => ({
          ...prev,
          orderBy: options.key,
          orderAscending: options.order === 'asc',
        }));
      }}
    />
  );
};
