import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import {
  APIClient,
  APIModels,
  CaptureObject,
  CaptureObjectCustomAttribute,
} from '@agerpoint/api';
import {
  AnalyticRequestStatus,
  CaptureJobTypes,
  CaptureModes,
  CaptureObjectMarkerType,
  CaptureObjectMarkers,
  HexColor,
  LdFlags,
  MaterialType,
  MixpanelNames,
  captureModesNiceNames,
} from '@agerpoint/types';

import { hasPermission } from '../hasPermission';
import { useGlobalStore } from '../store/store';
import { getSymbol } from '../units';
import { useToasts } from '../useToasts/useToasts';

export const materialList = [
  {
    value: MaterialType.RGBA,
    name: 'RGB',
  },
  {
    value: MaterialType.CLASSIFICATION,
    name: 'Classification',
  },
];

export const markerColorPalette: HexColor[] = [
  '#ac154c',
  '#186cb7',
  '#cc9a05',
  '#003d33',
  '#2d64b0',
  '#630067',
  '#c20c64',
  '#542844',
  '#5d54ba',
  '#382a84',
];

export const getMarkerColorByIndex = (index: number) => {
  return markerColorPalette[index % markerColorPalette.length];
};

export const getUpdatedCaptureMarkers = (
  captureObjectsData: CaptureObject[],
  captureObjectMarkers: CaptureObjectMarkers[]
) => {
  const paletteLength = markerColorPalette.length;
  const captureObjectMarkersFromStateLookup = captureObjectMarkers.reduce(
    (acc, co) => {
      acc[co.id] = co;
      return acc;
    },
    {} as { [key: number]: CaptureObjectMarkers }
  );
  return captureObjectsData.map((co, i) => {
    const id = co.id || 0;
    const newObj: CaptureObjectMarkers = {
      ...co,
      id,
      type: co.captureExtractionJobId
        ? CaptureObjectMarkerType.ExtractionJob
        : CaptureObjectMarkerType.Custom,
      showMarker: true,
      showLabel: true,
      markerFill: markerColorPalette[i % paletteLength],
      highlighted: false,
      editable: false,
    };
    if (captureObjectMarkersFromStateLookup[id]) {
      return {
        ...captureObjectMarkersFromStateLookup[id],
        ...newObj,
        showMarker: captureObjectMarkersFromStateLookup[id].showMarker,
        showLabel: captureObjectMarkersFromStateLookup[id].showLabel,
      };
    }
    return newObj;
  });
};

export const captureRoutes = {
  '/captures/:id/analytics': 'Capture Modal - Analytics',
  '/captures/:id/details': 'Capture Modal - Details',
  '/captures/:id/projects': 'Capture Modal - Projects',
  '/captures/:id/models': 'Capture Modal - Models',
  '/captures/:id/analytics/:analyticUriName': 'Capture Analytics Stepper',
  '/admin/captures/:id/analytics': 'Admin Capture Modal - Analytics',
  '/admin/captures/:id/details': 'Admin Capture Modal - Details',
  '/admin/captures/:id/projects': 'Admin Capture Modal - Projects',
  '/admin/captures/:id/models': 'Admin Capture Modal - Models',
  '/admin/captures/:id/analytics/:analyticUriName':
    'Admin Capture Analytics Stepper',
  '/captures/:id/:eptId': 'Captures Viewer',
  '/captures/:id/:eptId/analytics': 'Captures Viewer - Analytics',
  '/captures/:id/:eptId/models': 'Captures Viewer - Models',
  '/captures/:id/:eptId/projects': 'Captures Viewer - Projects',
  '/captures/:id/:eptId/details': 'Captures Viewer - Details',
  '/captures/:id/:eptId/analytics/:analyticUriName':
    'Captures Viewer - Analytics Stepper',
  '/captures/:id/:eptId/3d-model': 'Captures Viewer - 3D Model',
  '/captures/:id/:eptId/3d-model/select': 'Captures Viewer - 3D Model Select',
  '/captures/:id/:eptId/3d-model/style': 'Captures Viewer - 3D Model Style',
  '/captures/:id/:eptId/supplemental-images':
    'Captures Viewer - Supplemental Images',
  '/captures/:id/:eptId/analytics-outputs':
    'Captures Viewer - Analytic Outputs',
  '/captures/:id/:eptId/ai-results': 'Captures Viewer - AI Results',
  '/captures/:id/:eptId/note': 'Captures Viewer - Note',
  '/ops/qaqc/:id/:eptId/:extId': 'QAQC 3D Viewer',
};

export const convertNameToCamelCase = (name: string) => {
  const newName = name
    .split('-')
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');
  return newName;
};

export const convertAttributeValueToUIFriendly = (
  attribute?: CaptureObjectCustomAttribute
): React.ReactNode | null => {
  if (!attribute) {
    return null;
  }

  const value = attribute.attributeValue;

  if (!value) {
    return null;
  }

  if (Number.isNaN(+value)) {
    return value;
  }

  const decimalPlaces = 4;

  const userFriendlyValue = (
    Math.round(+value * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces)
  )
    .toString()
    .replace(/(\.\d*?[1-9])0+$/, '$1')
    .replace(/\.$/, '');

  return (
    <span>
      {userFriendlyValue}
      {getSymbol(attribute)}
    </span>
  );
};

export const isGaussianJob = (
  captureJob: APIModels.CaptureJob | undefined | null
) => {
  if (!captureJob) {
    return false;
  }
  return ['g1', 'g2'].includes(captureJob.mosaicEngine?.toLowerCase() ?? '');
};

export const isNonLidarJob = (
  capture?: APIModels.Capture,
  captureJob?: APIModels.CaptureJob
) => {
  if (!capture || !captureJob) {
    return false;
  }

  return (
    [3, 7].includes(capture.captureModeId ?? NaN) &&
    captureJob.captureJobTypeId === CaptureJobTypes['Low Resolution'] &&
    !captureJob.eptPointcloudId
  );
};

const unique3DModelListMapping: { [key: string]: string } = {
  g1: 'HD',
  g2: 'HD',
  p1: 'AI',
  p2: 'AI',
  n1: 'AI',
};

export const getCapturesUnique3DModelList = (
  capture: APIClient.Capture
): string[] | undefined => {
  if (!capture.completedJobs) {
    return undefined;
  }

  const notArchivedJobs = capture.completedJobs.filter((j) => !j.archived);

  if (notArchivedJobs.length === 0) {
    return undefined;
  }

  const result: string[] = [];

  for (const job of notArchivedJobs) {
    if (job.mosaicEngine) {
      const mapping = unique3DModelListMapping[job.mosaicEngine];
      if (mapping) {
        result.includes(mapping) || result.push(mapping);
      }
    }

    if (job.captureJobTypeId === 1 && !isNonLidarJob(capture, job)) {
      result.includes('LiDAR') || result.push('LiDAR');
    }
  }

  // AR Video
  if (capture.captureModeId === CaptureModes.ar_video) {
    result.push(captureModesNiceNames[CaptureModes.ar_video]);
  }

  result.sort((a, b) => a.localeCompare(b));

  return result;
};

const checkForAssociatedAnalytic = (
  analyticRequests: APIClient.AnalyticRequest[] | undefined | null,
  captureJob: APIClient.CaptureJob
) => {
  const analyticRequest = analyticRequests?.find(
    (ar) => ar.id === captureJob?.analyticRequestId
  );

  if (analyticRequest?.status !== AnalyticRequestStatus.COMPLETE) {
    return false;
  }
  return true;
};

export const getBestCaptureJob = async ({
  includeArchived = false,
  analyticRequests,
  specificAnalyticRequestId,
}: {
  includeArchived?: boolean;
  analyticRequests: APIClient.AnalyticRequest[] | undefined | null;
  specificAnalyticRequestId?: number;
}): Promise<APIClient.CaptureJob | undefined> => {
  if (!analyticRequests?.length) return;
  // get capture jobs for each analytic request
  const _allJobs = await Promise.all(
    analyticRequests
      .filter((ar) => ar.status === AnalyticRequestStatus.COMPLETE)
      .map((ar) => {
        if (!ar?.id) return Promise.resolve([]);
        return APIClient.getCaptureJobsByAnalyticRequestId(ar.id, {});
      })
  );
  const allJobs = _allJobs.flat();
  if (!allJobs || allJobs.length === 0) {
    return undefined;
  }

  let jobs = cloneDeep(allJobs);

  if (!includeArchived) {
    jobs = jobs.filter((j) => !j.archived);
  }

  jobs = jobs
    .filter((j) => j.status === 'Completed' || j.status === '')
    .sort((a, b) => compare(b.createDatetime, a.createDatetime));

  if (specificAnalyticRequestId) {
    const specificJob = jobs.find(
      (j) => j.analyticRequestId === specificAnalyticRequestId
    );
    if (specificJob) {
      return specificJob;
    }
  }

  const latestGaussianJob = jobs?.find((j) => isGaussianJob(j));

  if (
    latestGaussianJob &&
    checkForAssociatedAnalytic(analyticRequests, latestGaussianJob)
  ) {
    return latestGaussianJob;
  }

  const latestHighResJob = jobs?.find(
    (j) =>
      !isGaussianJob(j) &&
      !(j.captureJobTypeId === CaptureJobTypes['Low Resolution'])
  );

  if (
    latestHighResJob &&
    checkForAssociatedAnalytic(analyticRequests, latestHighResJob)
  ) {
    return latestHighResJob;
  }

  const lowResJob = jobs?.find(
    (j) => j.captureJobTypeId === CaptureJobTypes['Low Resolution']
  );

  if (lowResJob) {
    return lowResJob;
  }

  return;
};

export const useNewLayout = () => {
  const {
    permissions,
    actions: { sendEvent },
  } = useGlobalStore();

  const [showNewLayout, setShowNewLayout] = useState<boolean>();

  const { id, captureId: captureIdParam } = useParams();

  const captureId = useMemo(() => captureIdParam ?? id, [id, captureIdParam]);
  const navigate = useNavigate();

  const toasts = useToasts();

  const localStorageKey = useMemo(() => 'APCLOUD_USE_NEW_LAYOUT', []);

  const hasCaptureAnalyticsSidebarPermission = useMemo(
    () => hasPermission(LdFlags.CaptureAnalyticsSidebar, permissions),
    [permissions]
  );

  const hasCaptureAnalyticsSidebarSelfTogglePermission = useMemo(
    () => hasPermission(LdFlags.CaptureAnalyticsSidebarSelfToggle, permissions),
    [permissions]
  );

  useEffect(() => {
    if (!hasCaptureAnalyticsSidebarSelfTogglePermission) {
      // Remove the localStorage key if the user does not have the permission to toggle
      window.localStorage.removeItem(localStorageKey);
    }
  }, [hasCaptureAnalyticsSidebarSelfTogglePermission, localStorageKey]);

  useEffect(() => {
    if (!hasCaptureAnalyticsSidebarSelfTogglePermission) {
      // If the user does not have the permission to toggle,
      // always show the layout based on the general permission
      setShowNewLayout(hasCaptureAnalyticsSidebarPermission);
      return;
    }

    // If the user has the permission to toggle, check the localStorage
    const newLayout = window.localStorage.getItem(localStorageKey);

    if (newLayout === 'true') {
      // If the user has set the layout to new, show the new layout
      setShowNewLayout(true);
    } else if (newLayout === 'false') {
      // If the user has set the layout to legacy, show the legacy layout
      setShowNewLayout(false);
    } else {
      // If the user has not set the layout, use the general permission
      setShowNewLayout(hasCaptureAnalyticsSidebarPermission);
    }
  }, [
    hasCaptureAnalyticsSidebarPermission,
    hasCaptureAnalyticsSidebarSelfTogglePermission,
    localStorageKey,
  ]);

  const toggleNewLayout = useCallback(() => {
    if (!hasCaptureAnalyticsSidebarSelfTogglePermission) {
      return;
    }

    setShowNewLayout(true);
    window.localStorage.setItem(localStorageKey, 'true');
    sendEvent(MixpanelNames.CaptureViewerLayoutChanged, {
      layout: 'new',
    });
    if (!captureId) {
      navigate(`/captures`, {
        replace: true,
      });
    } else {
      navigate(`/captures/${captureId}`, {
        replace: true,
      });
    }
    window.location.reload();
  }, [
    localStorageKey,
    hasCaptureAnalyticsSidebarSelfTogglePermission,
    captureId,
    navigate,
    sendEvent,
  ]);

  const toggleLegacyLayout = useCallback(
    (eptId?: number) => {
      if (!hasCaptureAnalyticsSidebarSelfTogglePermission) {
        return;
      }

      window.localStorage.setItem(localStorageKey, 'false');
      setShowNewLayout(false);
      sendEvent(MixpanelNames.CaptureViewerLayoutChanged, {
        layout: 'legacy',
      });

      if (!eptId) {
        navigate(`/captures`, {
          replace: true,
        });

        window.location.reload();
        return;
      } else {
        navigate(`/captures/${captureId}/${eptId}`, {
          replace: true,
        });
        window.location.reload();
      }
    },
    [
      sendEvent,
      hasCaptureAnalyticsSidebarSelfTogglePermission,
      localStorageKey,
      captureId,
      navigate,
      toasts,
    ]
  );

  return {
    showNewLayout,
    showNewLayoutToggle: hasCaptureAnalyticsSidebarSelfTogglePermission,
    toggleNewLayout,
    toggleLegacyLayout,
  };
};
