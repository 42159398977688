import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CaptureExtractionJob,
  User,
  useGetQAQCUsers,
  usePutCaptureExtractionJobById,
} from '@agerpoint/api';
import { DialogModal, Input, PrimaryButton } from '@agerpoint/component';
import { Sort, useToasts } from '@agerpoint/utilities';

type Item = {
  id: string;
  name: string;
};

interface AssignToUserModalProps {
  extractionJobs: CaptureExtractionJob[];
  isOpen: boolean;
  handleCloseDialog: () => void;
  updatedCallback?: (user: Item) => void;
}

export const AssignToUserModal = ({
  extractionJobs,
  isOpen,
  handleCloseDialog,
  updatedCallback,
}: AssignToUserModalProps) => {
  const { data: users } = useGetQAQCUsers({}) as unknown as {
    data: User[] | undefined;
  };
  const { mutate: assignToUser } = usePutCaptureExtractionJobById({ id: NaN });
  const [loading, setLoading] = useState(false);
  const [currentSelection, setCurrentSelection] = useState<User | undefined>();

  const toasts = useToasts();

  const [loadingMessage, setLoadingMessage] = useState<string>('');

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (extractionJobs.length <= 0) {
      return;
    }
    const ids = extractionJobs.map((j) => j.assignedToId);
    const allEqual = ids.every((i) => i === ids[0]);
    const id = ids[0];
    if (allEqual && id) {
      const selection = users?.find((u) => u.id === id);
      setCurrentSelection(selection);
    } else {
      setCurrentSelection(undefined);
    }
  }, [isOpen]);

  const sortedUsers = useMemo(() => Sort.users(users), [users]);

  const saveData = useCallback(async () => {
    // allow no assignee
    // if (!currentSelection) {
    //   return;
    // }

    let allGood = true;
    setLoading(true);
    for (let i = 0; i < extractionJobs.length; i++) {
      const extractionJob = extractionJobs[i];
      setLoadingMessage(`(${i}/${extractionJobs.length})`);
      try {
        const partialJob: any = {
          ...extractionJob,
        };

        delete partialJob.createDatetime;
        delete partialJob.updateDatetime;
        delete partialJob.completedDateTime;
        delete partialJob.submittedDateTime;
        delete partialJob.captureExtractionJobStatus;
        delete partialJob.updatedById;

        const body: Partial<CaptureExtractionJob> = {
          ...partialJob,
          assignedToId: currentSelection?.id,
        };
        if (extractionJob.id) {
          await assignToUser(body, {
            pathParams: {
              id: extractionJob.id,
            },
          });
        } else {
          throw new Error('Extraction Job missing id');
        }
      } catch (e) {
        allGood = false;
        console.error(e);
      }
    }

    handleCloseDialog();

    if (allGood) {
      toasts.add(
        toasts.prepare.entityUpdated(
          'extraction job(s)',
          'Successfully assigned user to extraction job(s).'
        )
      );

      updatedCallback?.({
        id: currentSelection?.id ?? '',
        name: currentSelection?.userName ?? '',
      });
    } else {
      toasts.add(
        toasts.prepare.error('Failed to assign user to extraction job(s)!')
      );
    }
    setLoading(false);
    setLoadingMessage('');
  }, [
    toasts,
    assignToUser,
    currentSelection,
    extractionJobs,
    handleCloseDialog,
    updatedCallback,
  ]);

  return (
    <DialogModal
      open={isOpen}
      handleCloseDialog={() => {
        if (loading) {
          return;
        }
        handleCloseDialog();
      }}
      size="small"
      title={`Assign to User - ${extractionJobs.length} Extraction(s)`}
      backgroundColor="bg-gray-300"
      visibleOverflow={true}
    >
      <div className="w-full p-1">
        {loading && (
          <div
            className={`absolute top-0 bottom-0 left-0 right-0 bg-white
          opacity-75 flex flex-col gap-2 items-center justify-center z-50`}
          >
            <FontAwesomeIcon icon={faCircleNotch} className="w-8 h-8" spin />
            <p>{` Loading ${loadingMessage}`}</p>
          </div>
        )}
        <Input.Select.Single
          id="user-dropdown"
          title="User"
          options={sortedUsers ?? []}
          loading={sortedUsers === undefined}
          value={currentSelection}
          setValue={setCurrentSelection}
          optionBuilder={(user) =>
            `${user?.userProfiles?.[0]?.firstName ?? ''} ${
              user?.userProfiles?.[0]?.lastName ?? ''
            } - ${user?.userName}` ?? 'Unknown User'
          }
          stretch={true}
        />
        <div className="w-full flex flex-row justify-end pt-2">
          <PrimaryButton
            disabled={loading}
            size="default"
            onClicked={saveData}
            label="Save"
          />
        </div>
      </div>
    </DialogModal>
  );
};
