import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';

import { Upload, usePostPipelineJob } from '@agerpoint/api';
import { DialogModal, PrimaryButton } from '@agerpoint/component';
import { useGlobalStore, useToasts } from '@agerpoint/utilities';

interface RunCOGPipelineModalProps {
  open: boolean;
  upload?: Upload;
  handleCloseDialog: () => void;
}

export const RunCOGPipelineModal = ({
  open,
  upload,
  handleCloseDialog,
}: RunCOGPipelineModalProps) => {
  const { mutate: postPipelineJob, loading } = usePostPipelineJob({});

  const toasts = useToasts();

  const runPipeline = useCallback(
    async (upload: Upload) => {
      try {
        if (!upload.id) {
          throw new Error('Upload has no id');
        }
        const id = upload.id;
        await postPipelineJob({
          name: upload.name ?? 'Unknown',
          uploadId: id,
          pipelineId: 4,
        });
        toasts.add({
          title: 'Pipeline job triggered!',
          type: 'success',
        });
      } catch (e) {
        console.error(e);
        toasts.add(
          toasts.prepare.error('Failed to trigger pipeline for this upload!')
        );
      }
    },
    [postPipelineJob, toasts]
  );

  return (
    <DialogModal
      key={`${open}`}
      open={open}
      handleCloseDialog={() => {
        if (!loading) {
          handleCloseDialog();
        }
      }}
      size="small"
      title="Run COG Pipeline"
    >
      <div className="flex flex-col w-full p-1">
        <div className="flex flex-col">
          <div className="w-full flex flex-row gap-2 justify-end items-center">
            {loading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            ) : (
              <PrimaryButton
                label={'Run'}
                onClicked={async () => {
                  if (upload) {
                    await runPipeline(upload);
                  }
                  handleCloseDialog();
                }}
              />
            )}
            <PrimaryButton
              label={'Cancel'}
              onClicked={handleCloseDialog}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </DialogModal>
  );
};
