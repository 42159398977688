import { ReactNode, useState } from 'react';

export const StandAloneViewerSidebarSection = ({
  title,
  children,
  value,
}: {
  title: string;
  children: ReactNode;
  value?: string | number;
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <div
        className={`w-full cursor-pointer hover:bg-gray-200 rounded px-2 border flex flex-row justify-between ${
          expanded ? 'border-gray-200' : ' border-transparent'
        }`}
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <div>{title}</div>
        <div className="text-gray-500">{value}</div>
      </div>
      <div className={`mx-1 shadow rounded-b ${expanded ? '' : 'hidden'}`}>
        <div className="border-b border-x rounded-b">{children}</div>
      </div>
    </div>
  );
};
