import { ReactNode } from 'react';

import { SidebarToggleButton } from '@agerpoint/component';

export interface SidebarProps {
  children?: ReactNode;
  expansionOpen?: boolean;
  toggleSidebar: () => void;
  isSidebarOpen: boolean;
}

export function Sidebar({
  children,
  expansionOpen,
  toggleSidebar,
  isSidebarOpen,
}: SidebarProps) {
  return (
    <div className="fixed flex top-0 h-full left-0 z-50">
      <div
        className={`shadow-xl
                      transition-width
                      duration-300
                      border-r
                      border-gray-300
                      h-full
                      overflow-y-auto
                      bg-white
                      ${isSidebarOpen ? 'w-80' : 'w-0'}`}
      >
        {isSidebarOpen && children}
      </div>
      <SidebarToggleButton
        show={!expansionOpen}
        open={isSidebarOpen}
        onClick={toggleSidebar}
      />
    </div>
  );
}
