import { PathMatch, matchPath } from 'react-router-dom';

import { MixpanelNames } from '@agerpoint/types';
import { captureRoutes } from '@agerpoint/utilities';

export const pageMap: { [key: string]: string } = {
  // order matters, more specific routes first
  '/ops/qaqc/:id/:eptId/:jobId': 'Capture QAQC Details',
  '/ops/qaqc': 'Capture QAQC List',
  '/ops/pipeline': 'Capture Pipeline',
  '/ops/analytics': 'Analytics Request Report',
  '/ops': 'Ops',

  '/project/:id': 'Project',
  '/projects': 'Projects List',
  '/dashboard': 'Projects List Navigate',

  ...captureRoutes,

  '/captures?': 'Captures List with Filters',
  '/captures': 'Captures List',

  '/uploads/new': 'Uploads New',
  '/uploads': 'Uploads',
  '/profile': 'Profile',

  '/reports/fusion-dashboard': 'Fusion Dashboard',
  '/reports': 'Reports',

  '/admin/analytics': 'Admin Analytics',
  '/admin/blocks': 'Admin Blocks',
  '/admin/captures': 'Admin Captures',
  '/admin/crops': 'Admin Crops',
  '/admin/farms': 'Admin Farms',
  '/admin/geometries': 'Admin Geometries',
  '/admin/organizations': 'Admin Organizations',
  '/admin/orthomosaics': 'Admin Orthomosaics',
  '/admin/pipelines': 'Admin Pipelines',
  '/admin/pointclouds': 'Admin Pointclouds',
  '/admin/projects': 'Admin Projects',
  '/admin/uploads': 'Admin Uploads',
  '/admin': 'Admin',

  '/viewer': 'Standalone Viewer',
};

export function routeTracking(
  location: any,
  sendEvent: (eventName: string, eventBody: any) => void
) {
  try {
    const match = Object.keys(pageMap)
      .map((key): PathMatch => {
        return matchPath(key, location) as PathMatch;
      })
      .filter((m) => m as PathMatch);

    if (!match || !match.length) return;
    const pathMatch = match[0] as PathMatch;
    const m = pathMatch?.pattern?.path;

    const page = m ? pageMap[m] : 'Unknown';
    const params = pathMatch.params;
    sendEvent(MixpanelNames.PageViewed, {
      page,
      params,
    });
  } catch (e) {
    console.error(e);
  }
}
