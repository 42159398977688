import { useCallback, useEffect, useState } from 'react';

type SpecialKeys =
  | 'Space'
  | 'ShiftLeft'
  | 'ShiftRight'
  | 'ControlLeft'
  | 'ControlRight'
  | 'MetaLeft'
  | 'MetaRight'
  | 'AltLeft'
  | 'AltRight'
  | 'Enter'
  | 'Escape'
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight';

type Keys = SpecialKeys | `Key${string}`;

interface IuseKeyboardShortcutBase {
  combination: Keys[];
  callback: () => void;
  enabled?: boolean;
}

interface IuseKeyboardShortcutRef extends IuseKeyboardShortcutBase {
  ref: HTMLElement | null;
  refUsageMode: 'focus' | 'click';
}

interface IuseKeyboardShortcutRefless extends IuseKeyboardShortcutBase {
  ref?: never;
  refUsageMode?: never;
}

type IuseKeyboardShortcut =
  | IuseKeyboardShortcutRef
  | IuseKeyboardShortcutRefless;

export const useKeyboardShortcut = ({
  ref,
  refUsageMode,
  combination,
  callback,
  enabled = true,
}: IuseKeyboardShortcut) => {
  const [keys, setKeys] = useState<Keys[]>([]);
  const [isRefFocused, setIsRefFocused] = useState<boolean>(false);

  const [recentlyTriggered, setRecentlyTriggered] = useState<boolean>(false);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const isCombinationPressed = combination.every((key) => keys.includes(key));

    if (isCombinationPressed && !recentlyTriggered) {
      if (refUsageMode === undefined || isRefFocused) {
        callback();
        setRecentlyTriggered(true);
      }
    } else if (!isCombinationPressed && recentlyTriggered) {
      setRecentlyTriggered(false);
    }
  }, [
    keys,
    enabled,
    combination,
    callback,
    recentlyTriggered,
    refUsageMode,
    isRefFocused,
  ]);

  const keyDown = useCallback((e: KeyboardEvent) => {
    setKeys((prev) => {
      if (prev.includes(e.code as Keys)) {
        return prev;
      }

      return [...prev, e.code as Keys];
    });
  }, []);

  const keyUp = useCallback((e: KeyboardEvent) => {
    setKeys((prev) => prev.filter((key) => key !== e.code));
  }, []);

  const refOnClick = useCallback(
    (e: MouseEvent) => {
      if (ref?.contains(e.target as Node)) {
        setIsRefFocused(true);
      } else {
        setIsRefFocused(false);
      }
    },
    [ref]
  );

  useEffect(() => {
    const element = ref ?? undefined;

    if (refUsageMode === 'focus' && element) {
      element.addEventListener('focus', () => {
        return setIsRefFocused(true);
      });
      element.addEventListener('blur', () => {
        return setIsRefFocused(false);
      });
      window.addEventListener('keydown', keyDown);
      window.addEventListener('keyup', keyUp);

      return () => {
        element.removeEventListener('focus', () => setIsRefFocused(true));
        element.removeEventListener('blur', () => setIsRefFocused(false));
        window.removeEventListener('keydown', keyDown);
        window.removeEventListener('keyup', keyUp);
      };
    }

    if (refUsageMode === 'click' && element) {
      window.addEventListener('click', refOnClick);
      window.addEventListener('keydown', keyDown);
      window.addEventListener('keyup', keyUp);

      return () => {
        window.removeEventListener('click', refOnClick);
        window.removeEventListener('keydown', keyDown);
        window.removeEventListener('keyup', keyUp);
      };
    }

    window.addEventListener('keydown', keyDown);
    window.addEventListener('keyup', keyUp);

    return () => {
      window.removeEventListener('keydown', keyDown);
      window.removeEventListener('keyup', keyUp);
    };
  }, [ref, refUsageMode, keyDown, keyUp, refOnClick]);
};
