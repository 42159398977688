interface IBasicExtraRow {
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  actions?: React.ReactNode;
}

export const BasicExtraRow = ({
  icon,
  title,
  subtitle,
  actions,
}: IBasicExtraRow) => {
  return (
    <div className="size-full flex-center">
      <div className="pointer-events-auto flex flex-row items-center">
        {icon && (
          <div className="text-gray-iconSecondary pr-4 text-xl">{icon}</div>
        )}
        {(title || subtitle) && (
          <div className="flex flex-col gap-1">
            {title && <div className="font-bold leading-none">{title}</div>}
            {subtitle && (
              <div className="text-gray-textSecondary leading-none text-sm">
                {subtitle}
              </div>
            )}
          </div>
        )}
        {actions && <div className="flex flex-row pl-2">{actions}</div>}
      </div>
    </div>
  );
};
