/**
 *
 * @param value size i nbytes
 * @returns string formatted size in readable format
 */
export const formatFileSize = (value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  let unit = 'B';
  let size = value;
  if (value >= 1024) {
    unit = 'KB';
    size = value / 1024;
  }
  if (value >= 1024 * 1024) {
    unit = 'MB';
    size = value / 1024 / 1024;
  }
  if (value >= 1024 * 1024 * 1024) {
    unit = 'GB';
    size = value / 1024 / 1024 / 1024;
  }

  return `${size.toFixed(2)} ${unit}`;
};
