import {
  faCircleNotch,
  faFilterSlash,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIModels, formatDate } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { usePageTitle, useQueryState } from '@agerpoint/utilities';

import { useAdminPointcloudsQueries } from './admin-pointclouds-queries';

export const AdminPointcloudsList = () => {
  usePageTitle(() => 'Platform - Pointclouds', []);

  const navigate = useNavigate();

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v.trim(),
    toUrlParam: (v) => v.trim(),
  });

  const [filter, setFilter] = useState<
    APIModels.EptPointcloudFilter | undefined
  >();

  const {
    organizationsLookupTable,
    pointcloudsQuery,
    pointcloudArchiveDeleteMutation,
  } = useAdminPointcloudsQueries(filter);

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        name: nameFilter.trim(),
      }));
    },
    500,
    [nameFilter]
  );

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      name: nameFilter.trim(),
    }));
  }, []);

  const hasFiltersApplied = useMemo(
    () => !!((filter?.name?.trim().length ?? 0) > 0),
    [filter]
  );

  const clearFilters = useCallback(() => {
    setNameFilter('');
  }, []);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="px-4 py-2 flex flex-row gap-1 justify-between">
        <h1 className="text-3xl font-bold">Pointclouds</h1>
        <Button.Primary
          id="new-pointcloud-button"
          label="New Pointcloud"
          icon={faPlus}
          onClick={() => {
            navigate('new', {
              state: {
                params: window.location.search,
              },
            });
          }}
        />
      </div>
      <div className="px-4 max-w-sm flex gap-2">
        <Input.Text.Single
          id="pointcloud-name-filter"
          setValue={setNameFilter}
          value={nameFilter}
          placeholder="Search by Name"
          placeholderIcon={Input.placeholderIcons.search}
        />
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 h-full">
        <Datatable
          id="admin-pointclouds-list-datatable"
          data={pointcloudsQuery?.data?.pages?.flatMap((p) => p) || []}
          rowHeight={50}
          style={{ ...dataTableAgerStyle, tableMinWidth: 850 }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching pointclouds',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No pointclouds yet',
                  message: 'Create a pointcloud to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Pointcloud',
                }
          }
          error={
            pointcloudsQuery.isError
              ? {
                  title: 'There was a problem loading pointclouds',
                  message: 'Try refreshing the page',
                  action: () => pointcloudsQuery.refetch(),
                }
              : undefined
          }
          columns={[
            { label: 'ID', value: (row) => row.id },
            { label: 'Name', value: (row) => row.name, flex: 3 },
            { label: 'File', value: (row) => row.uuid, flex: 4 },
            {
              label: 'Organization',
              value: (row) => {
                if (!row.customerId) {
                  return null;
                }

                if (!organizationsLookupTable) {
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;
                }

                const c = organizationsLookupTable?.[row.customerId];

                return c?.customerDisplayName ?? c?.customerName ?? null;
              },
            },
            {
              label: 'Create Date',
              value: (row) =>
                row.createDatetime ? formatDate(row.createDatetime) : null,
            },
            {
              label: '',
              value: function ArchiveButton(row) {
                return (
                  <Button.Danger
                    id="archive-pointcloud-button"
                    label="Archive"
                    loading={pointcloudArchiveDeleteMutation.isPending}
                    onClick={() => {
                      if (!row.id) return;
                      const confirmed = window.confirm(
                        'Are you sure you want to archive this pointcloud?'
                      );
                      if (confirmed) {
                        pointcloudArchiveDeleteMutation.mutate({
                          id: row.id,
                        });
                      }
                    }}
                  />
                );
              },
              flex: 2,
              style: {
                bodyStyle: 'flex justify-center',
              },
            },
          ]}
          loading={
            pointcloudsQuery.isLoading ||
            pointcloudsQuery.isFetchingNextPage ||
            filter === undefined
          }
          pagination={{
            loadNextPage: () => {
              if (
                pointcloudsQuery.isLoading ||
                pointcloudsQuery.isFetchingNextPage ||
                !pointcloudsQuery.hasNextPage ||
                filter === undefined
              ) {
                return;
              }
              pointcloudsQuery.fetchNextPage();
            },
          }}
        />
      </div>
    </div>
  );
};
