import React, { useEffect, useState } from 'react';

interface IuseLocalStorageState<T> {
  fromString: (arg0: string) => T;
  toString: (arg0: T) => string;
  defaultValue: T;
  key: string;
}

export const useLocalStorageState = <T,>({
  defaultValue,
  key,
  fromString,
  toString,
}: IuseLocalStorageState<T>): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    const item = localStorage.getItem(key);
    return item !== null ? fromString(item) : defaultValue;
  });

  useEffect(() => {
    if (state === defaultValue) {
      localStorage.removeItem(key);
      return;
    } else {
      localStorage.setItem(key, toString(state));
    }
  }, [state]);

  return [state, setState];
};
