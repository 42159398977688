import { Tag, ToolMode } from '@agerpoint/types';
import { ProjectPanelToolbar } from '..';
import { ProjectHeading } from './project-heading';

interface ProjectPanelHeader {
  name: string;
  tags: Tag[];
  addGroup: () => void;
  toggleLibrary: () => void;
  setToolMode: (toolMode: ToolMode) => void;
  isLibraryOpen: boolean;
  toolMode: ToolMode;
}

export function ProjectPanelHeader({
  name,
  tags,
  addGroup,
  toolMode,
  isLibraryOpen,
  setToolMode,
  toggleLibrary,
}: ProjectPanelHeader) {
  return (
    <>
      <ProjectHeading name={name} tags={tags} />
      <ProjectPanelToolbar
        addGroup={addGroup}
        toggleLibrary={toggleLibrary}
        toolMode={toolMode}
        isLibraryOpen={isLibraryOpen}
        setToolMode={setToolMode}
      />
    </>
  );
}
