import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';

import { DialogModal, Input, PrimaryButton } from '@agerpoint/component';
import { useFormValidation } from '@agerpoint/utilities';

import { QAQCCaptureObject } from '../qaqc-capture-objects-list/qaqc-capture-objects';

interface QAQCObjectRenameModalProps {
  open: boolean;
  handleCloseDialog: () => void;
  renameObject: (id: QAQCCaptureObject, newName: string) => Promise<void>;
  object: QAQCCaptureObject;
}

export const QAQCObjectRenameModal = ({
  open,
  handleCloseDialog,
  object,
  renameObject,
}: QAQCObjectRenameModalProps) => {
  const [name, setName] = useState<string>(object.name ?? '');

  const [loading, setLoading] = useState(false);

  const formValidation = useFormValidation();

  useEffect(() => {
    if (open) {
      formValidation.advancedCallbacks.reset();
      setName(object.name ?? '');
    }
  }, [open]);

  const close = useCallback(() => {
    if (loading) {
      return;
    }
    handleCloseDialog();
    setName('');
  }, [handleCloseDialog, loading]);

  const rename = useCallback(async () => {
    if (await formValidation.hasErrors()) {
      return;
    }

    setLoading(true);

    try {
      await renameObject(object, name);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      handleCloseDialog();
    }
  }, [formValidation]);

  return (
    <DialogModal
      open={open}
      handleCloseDialog={() => {
        close();
      }}
      size={'small'}
      title={'Rename Object'}
      visibleOverflow={true}
    >
      <div className="flex flex-col w-full p-1">
        <Input.Text.Single
          id="object-rename-input"
          value={name}
          setValue={setName}
          label={<Input.Label label="New Name" className="pt-2" required />}
          disabled={loading}
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('New Name')],
          }}
          error={
            <Input.Error error={formValidation.errors['object-rename-input']} />
          }
        />
        <div className="w-full flex flex-row justify-end pt-2 gap-2 items-center">
          {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
          <PrimaryButton
            disabled={loading}
            label={'Apply'}
            onClicked={rename}
          />
          <PrimaryButton
            disabled={loading}
            label={'Cancel'}
            theme="secondary"
            onClicked={() => {
              close();
            }}
          />
        </div>
      </div>
    </DialogModal>
  );
};
