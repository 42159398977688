/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from "restful-react";
export const SPEC_VERSION = "v1"; 
export interface Analytic {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  analyticName?: string | null;
  analyticDescription?: string | null;
  createdById?: string | null;
  updatedById?: string | null;
  dc?: number;
  icon?: string | null;
  archived?: boolean;
}

export interface AnalyticJob {
  name?: string | null;
  status?: string | null;
  jobTypeName?: string | null;
  entityId?: number;
  completedDatetime?: string | null;
  createDatetime?: string | null;
  entityName?: string | null;
}

export interface AnalyticJobType {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  analyticId?: number;
  jobTypeId?: number;
  analytic?: Analytic;
  jobType?: JobType;
  archived?: boolean;
}

export interface AnalyticJobTypeFilter {
  analyticId?: number | null;
  jobTypeId?: number | null;
  archived?: boolean;
}

export interface AnalyticRequest {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  customerAnalyticId?: number;
  customerAnalytic?: CustomerAnalytic;
  analyticCreditCost?: number;
  createdById?: string | null;
  createdByProfile?: UserProfile;
  assignedToId?: string | null;
  status?: string | null;
  completedDatetime?: string;
  analyticRequestCaptures?: AnalyticRequestCapture[] | null;
  captureIds?: number[] | null;
  captures?: Capture[] | null;
  archived?: boolean;
}

export interface AnalyticRequestCapture {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  analyticRequestId?: number;
  captureId?: number;
  analyticRequest?: AnalyticRequest;
  capture?: Capture;
}

export interface AnalyticRequestFilter {
  customerIds?: number[] | null;
  startCreatedDatetime?: string | null;
  endCreatedDatetime?: string | null;
  assignedToId?: string | null;
  createdById?: string | null;
  captureName?: string | null;
  captureUuid?: string | null;
  analyticId?: number | null;
  status?: string | null;
  orderBy?: string | null;
  orderAscending?: boolean | null;
  archived?: boolean;
}

export interface ApGeometry {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  geom?: Geometry;
  geometryCollectionId?: number;
  geometryCustomAttributes?: ApGeometryCustomAttribute[] | null;
}

export interface ApGeometryCollection {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  customerId?: number;
  farmId?: number | null;
  blockId?: number | null;
  owner?: string | null;
  uuid?: string;
  totalGeometries?: number | null;
  archived?: boolean;
  uploadFileId?: number | null;
  extent?: Extent;
}

export interface ApGeometryCollectionDetails {
  availibleCustomAttributes?: string[] | null;
  availibleCategorizedCustomAttributes?: string[] | null;
}

export interface ApGeometryCollectionFilter {
  name?: string | null;
  customerId?: number[] | null;
  archived?: boolean;
  orderBy?: string | null;
  orderAscending?: boolean | null;
}

export interface ApGeometryCustomAttribute {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  apGeometryId?: number;
  displayName?: string | null;
  attributeName?: string | null;
  attributeValue?: string | null;
  customAttributeUnitId?: number | null;
}

export interface BaseMap {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  displayName?: string | null;
}

export interface Block {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  blockName: string;
  blockDisplayName: string;
  farmId: number;
  uuid?: string;
  blockGeom?: Geometry;
  centroid?: string | null;
  blockCollections?: BlockCollection[] | null;
  serviceOrders?: ServiceOrder[] | null;
  farm?: Farm;
  totalAcres?: number | null;
  plants?: Plant[] | null;
  rawYield?: RawYield[] | null;
  wellKnownText?: string | null;
  hasMorphologies?: boolean | null;
  hasYield?: boolean | null;
  hasVitality?: boolean | null;
}

export interface BlockCollection {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  blockId?: number;
  collectionId?: number;
  maps?: Map[] | null;
  morphologies?: Morphology[] | null;
  pointclouds?: Pointcloud[] | null;
  yields?: Yield[] | null;
  block?: Block;
  collection?: Collection;
}

export interface BlockCollectionCount {
  blockId?: number;
  serviceId?: number;
  serviceName?: string | null;
  collectionCount?: number;
}

export interface BlockFilter {
  blockName?: string | null;
}

export interface BlockRanking {
  blockName?: string | null;
  blockId?: number;
  plantDensityRanking?: Ranking;
  plantHeightRanking?: Ranking;
  canopyVolumeRanking?: Ranking;
  canopyDiameterRanking?: Ranking;
  trunkDiameterRanking?: Ranking;
  yieldVolumeRanking?: Ranking;
  yieldWeightRanking?: Ranking;
}

export interface BlockSummaryPage {
  total?: number;
  pageNumber?: number;
  pageSize?: number;
}

export interface Capture {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  captureName?: string | null;
  description?: string | null;
  customerId?: number | null;
  farmId?: number | null;
  blockId?: number | null;
  captureUuid?: string | null;
  container?: string | null;
  isUploaded?: boolean;
  scanDatetime?: string | null;
  uploadDatetime?: string | null;
  createdById?: string | null;
  scannedById?: string | null;
  captureStatusId?: number;
  uploadUrl?: string | null;
  gUploadUrl?: string | null;
  uploadUrlQuery?: string | null;
  uploadUrlPath?: string | null;
  numberImages?: number | null;
  fileSize?: number | null;
  completedJobs?: CaptureJob[] | null;
  longitude?: number | null;
  latitude?: number | null;
  altitude?: number | null;
  archived?: boolean;
  captureSizeBytes?: number | null;
  appVersion?: string | null;
  firstCaptureImageId?: number | null;
  captureModeId?: number | null;
  captureMode?: CaptureMode;
}

export interface CaptureCloneRequest {
  captureIds?: number[] | null;
  customerId?: number | null;
  assignToUserUuid?: string;
}

export interface CaptureCustomAttribute {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  captureId?: number;
  displayName?: string | null;
  attributeName?: string | null;
  attributeValue?: string | null;
  customAttributeUnitId?: number | null;
  customAttributeUnit?: CustomAttributeUnit;
  createdById?: string | null;
  captureExtractionJobId?: number | null;
  validated?: boolean;
}

export interface CaptureExtractionJob {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  uuid?: string | null;
  captureJobId?: number;
  captureId?: number;
  createdById?: string | null;
  submittedDateTime?: string | null;
  completedDateTime?: string | null;
  reportImagePath?: string | null;
  captureExtractionJobStatusId?: number;
  captureExtractionJobStatus?: CaptureExtractionJobStatus;
  assignedToId?: string | null;
  notes?: string | null;
  analyticRequestId?: number | null;
  assignedToUserName?: string | null;
  parametersJson?: string | null;
}

export interface CaptureExtractionJobFilter {
  name?: string | null;
  statusId?: number | null;
  assignedTo?: string | null;
  customerIds?: number[] | null;
  captureUuid?: string | null;
}

export interface CaptureExtractionJobStatus {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  displayName?: string | null;
  createdById?: string | null;
  color?: string | null;
}

export interface CaptureExtractionJobVM {
  capture?: Capture;
  extractionJobs?: CaptureExtractionJob[] | null;
}

export interface CaptureFilter {
  captureName?: string | null;
  description?: string | null;
  customerId?: number[] | null;
  startScanDatetime?: string | null;
  endScanDatetime?: string | null;
  startUploadDatetime?: string | null;
  endUploadDatetime?: string | null;
  startCreatedDatetime?: string | null;
  endCreatedDatetime?: string | null;
  projectUuids?: string[] | null;
  userUuids?: string[] | null;
  captureUuid?: string | null;
  archived?: boolean;
  orderBy?: string | null;
  orderAscending?: boolean | null;
}

export interface CaptureImage {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  container?: string | null;
  imagePath?: string | null;
  thumbnailPath?: string | null;
  x?: number | null;
  y?: number | null;
  z?: number | null;
  captureId?: number | null;
  captureJobId?: number | null;
  yaw?: number | null;
  pitch?: number | null;
  roll?: number | null;
  imageTime?: number | null;
  isFavorite?: boolean | null;
  note?: string | null;
  exifOrientation?: number;
  rotation?: number;
  videoFrameId?: number | null;
  captureVideoId?: number | null;
  epsg?: number | null;
  longitude?: number | null;
  latitude?: number | null;
  altitude?: number | null;
}

export interface CaptureImageTag {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  fileName?: string | null;
  imagePath?: string | null;
  x?: number | null;
  y?: number | null;
  z?: number | null;
  captureId?: number;
  note?: string | null;
  exifOrientation?: number;
  rotation?: number;
  archived?: boolean;
}

export interface CaptureJob {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  uuid?: string | null;
  captureId?: number;
  createdById?: string | null;
  submittedDateTime?: string | null;
  completedDateTime?: string | null;
  captureJobTypeId?: number;
  eptPointcloudId?: number | null;
  mosaicEngine?: string | null;
  mosaicEngineId?: number | null;
  mlModel?: string | null;
  mlModelId?: number | null;
  pointCloudType?: string | null;
  archived?: boolean;
  parametersJson?: string | null;
  plyPath?: string | null;
  videoPreviewPath?: string | null;
  analyticRequestId?: number | null;
  plyDownloadUrl?: string | null;
  videoPreviewDownloadUrl?: string | null;
  captureExtractionJobIdToRun?: number | null;
  status?: string | null;
  cameraSettings?: string | null;
  captureJobRating?: CaptureJobRating;
}

export interface CaptureJobRating {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  rating?: string | null;
  feedback?: string | null;
  scale?: number;
  captureJobId?: number;
  createdById?: string | null;
}

export interface CaptureMode {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  displayName?: string | null;
}

export interface CaptureObject {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  description?: string | null;
  captureId?: number;
  number?: number;
  createdById?: string | null;
  captureObjectTypeId?: number;
  captureObjectType?: CaptureObjectType;
  captureObjectCustomAttributes?: CaptureObjectCustomAttribute[] | null;
  x?: number | null;
  y?: number | null;
  z?: number | null;
  radius?: number | null;
  geom?: Geometry;
  geom2D?: Geometry;
  centroidX?: number | null;
  centroidY?: number | null;
  centroidZ?: number | null;
  diameter?: number | null;
  height?: number | null;
  volume?: number | null;
  confidence?: number | null;
  captureExtractionJobId?: number | null;
  validated?: boolean;
  epsg?: number | null;
  captureJobId?: number | null;
}

export interface CaptureObjectCustomAttribute {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  captureObjectId?: number;
  attributeName?: string | null;
  attributeDisplayName?: string | null;
  attributeValue?: string | null;
  customAttributeUnitId?: number | null;
  customAttributeUnit?: CustomAttributeUnit;
  createdById?: string | null;
  validated?: boolean;
}

export interface CaptureObjectFilter {
  captureObjectIds?: number[] | null;
  captureObjectCustomAttributeIds?: number[] | null;
  objectName?: string | null;
  objectCustomAttributeName?: string | null;
}

export interface CaptureObjectType {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
  createdById?: string | null;
}

export interface CaptureVideo {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  container?: string | null;
  videoPath?: string | null;
  captureId?: number;
  captureJobId?: number | null;
  captureExtractionJobId?: number | null;
  archived?: boolean;
  validated?: boolean;
  orientation?: number | null;
  downloadUrl?: string | null;
}

export interface CloneLayerGroupCapturesRequest {
  userUuid?: string;
  customerId?: number | null;
}

export interface Collection {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  startDate?: string | null;
  endDate?: string;
  serviceTypeId?: number | null;
  uuid: string;
  blockCollections?: BlockCollection[] | null;
  serviceType?: ServiceType;
  rawYield?: RawYield[] | null;
  collectionCropVariety?: CollectionCropVariety[] | null;
  plants?: Plant[] | null;
  totalTrees?: number;
  plantHeightAverage?: number;
  canopyDiameterAverage?: number;
  trunkDiameterAverage?: number;
  plantDensityAverage?: number;
  trunkVolumeAverage?: number;
  canopyVolumeAverage?: number;
  totalFruit?: number;
  hasMorphologies?: boolean;
  hasYield?: boolean;
  hasHarvest?: boolean;
  hasTreatments?: boolean;
  hasNotes?: boolean;
  hasVitality?: boolean;
  hasPointclouds?: boolean;
  pageNumber?: number;
  totalItems?: number;
  isCollapsed?: boolean;
  weightUnitType?: string | null;
  distanceUnitType?: string | null;
  volumeExponent?: number;
  volumeUnitType?: string | null;
}

export interface CollectionCropVariety {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  collectionId?: number;
  cropVarietyId?: number;
  collection?: Collection;
  cropVariety?: CropVariety;
}

export interface CollectionSummary {
  totalTrees?: number;
  plantHeightAverage?: number;
  canopyDiameterAverage?: number;
  trunkDiameterAverage?: number;
  plantDensityAverage?: number;
  trunkVolumeAverage?: number;
  canopyVolumeAverage?: number;
  yieldVolumeAverage?: number;
  yieldWeightAverage?: number;
  chlorophyllAverage?: number;
  vegetationAverage?: number;
  leafAreaAverage?: number;
  photosynthesisAverage?: number;
  demzAverage?: number;
  totalFruit?: number;
  hasMorphologies?: boolean;
  hasYield?: boolean;
  hasHarvest?: boolean;
  hasVitality?: boolean;
  hasPointclouds?: boolean;
  weightUnitType?: string | null;
  distanceUnitType?: string | null;
  volumeExponent?: number;
  volumeUnitType?: string | null;
  availibleAttributes?: string[] | null;
  availibleCategorizedAttributes?: string[] | null;
  availibleCustomAttributes?: string[] | null;
  availibleCategorizedCustomAttributes?: string[] | null;
}

export interface Coordinate {
  x?: number;
  y?: number;
  z?: number;
  m?: number;
  coordinateValue?: Coordinate;
  isValid?: boolean;
}

export interface CoordinateEqualityComparer {[key: string]: any}

export interface CoordinateSequence {
  dimension?: number;
  measures?: number;
  spatial?: number;
  ordinates?: Ordinates;
  hasZ?: boolean;
  hasM?: boolean;
  zOrdinateIndex?: number;
  mOrdinateIndex?: number;
  first?: Coordinate;
  last?: Coordinate;
  count?: number;
}

export interface CoordinateSequenceFactory {
  ordinates?: Ordinates;
}

export interface Country {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  countryCode?: string | null;
  iso3?: string | null;
  fips?: string | null;
  countryName?: string | null;
  countryGeom?: Geometry;
  regions?: Region[] | null;
}

export interface Crop {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  cropName?: string | null;
  cropDisplayName?: string | null;
  imagePath?: string | null;
  pluralCropName?: string | null;
  uuid?: string;
  plantTypeId?: number;
  cropVarieties?: CropVariety[] | null;
  serviceOrders?: ServiceOrder[] | null;
  plantType?: PlantType;
}

export interface CropVariety {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  cropVarietyName?: string | null;
  cropVarietyDisplayName?: string | null;
  cropId?: number | null;
  plants?: Plant[] | null;
  crop?: Crop;
  collectionCropVariety?: CollectionCropVariety[] | null;
}

export interface CustomAttributeUnit {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  unitName?: string | null;
  unitDisplayName?: string | null;
  symbol?: string | null;
  isMetric?: boolean;
  createdById?: string | null;
  exponent?: number | null;
}

export interface Customer {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  customerUuid?: string | null;
  customerName?: string | null;
  customerDisplayName?: string | null;
  regionId?: number | null;
  status?: string | null;
  primaryUserId?: string | null;
  isDemo?: boolean;
  region?: Region;
  farms?: Farm[] | null;
}

export interface CustomerAnalytic {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  customerId?: number;
  analyticId?: number;
  analytic?: Analytic;
  analyticCreditCost?: number;
  createdById?: string | null;
  archived?: boolean;
  available?: boolean;
  priority?: number;
  autocomplete?: boolean;
}

export interface CustomerLicense {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  subscriptionTier?: string | null;
  subsciptionStartDateTime?: string | null;
  subsciptionEndDateTime?: string | null;
  storageAllowance?: number | null;
  storageConsumed?: number | null;
  processingAllowance?: number | null;
  processingConsumed?: number | null;
  analyticCreditsAllowance?: number | null;
  analyticCreditsConsumed?: number | null;
  customerId?: number;
  createdById?: string | null;
}

export type Dimension = 0 | 1 | 2 | 3 | -3 | -2 | -1;

export interface Download {
  downloadUrl?: string | null;
}

export interface Envelope {
  isNull?: boolean;
  width?: number;
  height?: number;
  diameter?: number;
  minX?: number;
  maxX?: number;
  minY?: number;
  maxY?: number;
  area?: number;
  minExtent?: number;
  maxExtent?: number;
  centre?: Coordinate;
}

export interface EptPointcloud {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  uuid?: string | null;
  name?: string | null;
  customerId?: number | null;
  farmId?: number | null;
  collectionId?: number | null;
  rowId?: number | null;
  totalPoints?: number | null;
  createdById?: string | null;
}

export interface EptPointcloudFilter {
  name?: string | null;
}

export interface Extent {
  minX?: number;
  minY?: number;
  maxX?: number;
  maxY?: number;
}

export interface Farm {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  farmName: string;
  farmDisplayName: string;
  regionId: number;
  customerId: number;
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
  uuid?: string;
  totalAcres?: number | null;
  farmLocation?: Point;
  blocks?: Block[] | null;
  farmReports?: FarmReport[] | null;
  customer?: Customer;
  region?: Region;
  farmUser?: FarmUser[] | null;
  hasMorphologies?: boolean | null;
  hasYield?: boolean | null;
  hasVitality?: boolean | null;
}

export interface FarmPin {
  farmName?: string | null;
  farmDisplayName?: string | null;
  regionId?: number;
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
}

export interface FarmReport {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  reportName?: string | null;
  fileName?: string | null;
  description?: string | null;
  contentType?: string | null;
  farm?: Farm;
  farmId?: number;
  fileData?: string | null;
}

export interface FarmSummary {
  id?: number;
  farmDisplayName?: string | null;
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
  blockCnt?: number;
  plantCnt?: number;
  crops?: {
  [key: string]: Crop;
} | null;
}

export interface FarmSummaryPage {
  total?: number;
  pageNumber?: number;
  pageSize?: number;
}

export interface FarmUser {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  farmId?: number;
  userId?: string;
  farm?: Farm;
}

export interface Geometry {
  factory?: GeometryFactory;
  userData?: Record<string, unknown> | null;
  srid?: number;
  geometryType?: string | null;
  ogcGeometryType?: OgcGeometryType;
  precisionModel?: PrecisionModel;
  coordinate?: Coordinate;
  coordinates?: Coordinate[] | null;
  numPoints?: number;
  numGeometries?: number;
  isSimple?: boolean;
  isValid?: boolean;
  isEmpty?: boolean;
  area?: number;
  length?: number;
  centroid?: Point;
  interiorPoint?: Point;
  pointOnSurface?: Point;
  dimension?: Dimension;
  boundary?: Geometry;
  boundaryDimension?: Dimension;
  envelope?: Geometry;
  envelopeInternal?: Envelope;
  isRectangle?: boolean;
}

export interface GeometryCollectionCloneRequest {
  geometryCollectionIds?: number[] | null;
  customerId?: number;
  assignToUserUuid?: string;
}

export interface GeometryFactory {
  precisionModel?: PrecisionModel;
  coordinateSequenceFactory?: CoordinateSequenceFactory;
  srid?: number;
  geometryServices?: NtsGeometryServices;
}

export interface GeometryOverlay {[key: string]: any}

export interface GpsFix {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  fixValue?: number;
  fixType?: string | null;
  rawYield?: RawYield[] | null;
}

export interface Histogram {
  binCount?: number;
  bins?: HistogramBin[] | null;
  minValue?: number;
  maxValue?: number;
  binWidth?: number;
  xAxisName?: string | null;
  yAxisName?: string | null;
}

export interface HistogramBin {
  rangeMin?: number;
  rangeMax?: number;
  itemCount?: number;
  roundingPrecision?: number;
  range?: string | null;
}

export interface HistogramCategorized {
  binCount?: number;
  bins?: HistogramCategorizedBin[] | null;
  xAxisName?: string | null;
  yAxisName?: string | null;
}

export interface HistogramCategorizedBin {
  itemCount?: number;
  value?: string | null;
}

export interface HistogramParameters {
  attributeName?: string | null;
  binCount?: number;
  classificationMethod?: string | null;
}

export interface ImageMosaic {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  imageMosaicGranuleId?: number | null;
  customerId?: number;
  farmId?: number | null;
  collectionId?: number | null;
  scanDateTime?: string | null;
  imageMosaicGranule?: ImageMosaicGranule;
  tifLayerName?: string | null;
  minX?: number | null;
  minY?: number | null;
  maxX?: number | null;
  maxY?: number | null;
  uploadUuid?: string | null;
  mosaicJobUuid?: string | null;
}

export interface ImageMosaicFilter {
  imageMosaicName?: string | null;
}

export interface ImageMosaicGranule {
  id?: number;
  location?: string | null;
  fileName?: string | null;
}

export interface JobType {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  description?: string | null;
  categoryName?: string | null;
  pipelineId?: number | null;
  requiresHighRes?: boolean;
  mosaicEngineId?: number | null;
  mlModelId?: number | null;
  jobParameters?: string | null;
  archived?: boolean;
  nerfConfig?: string | null;
}

export interface JobTypeCategory {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
  archived?: boolean;
}

export interface Layer {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  layerGroupId?: number;
  layerType?: LayerType;
  layerTypeId?: number;
  visible?: boolean;
  zIndex?: number;
  entityId?: number;
  style?: string | null;
  filter?: string | null;
  name?: string | null;
  extent?: Extent;
}

export interface LayerFormatType {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  displayName?: string | null;
}

export interface LayerGroup {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name: string;
  zIndex?: number;
  projectId?: number;
  visible?: boolean;
  notes?: string | null;
  icon?: string | null;
  extent?: Extent;
  color?: string | null;
}

export interface LayerType {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  displayName?: string | null;
  mapLayer?: string | null;
  layerFormatType?: LayerFormatType;
  layerFormatTypeId?: number;
}

export interface LibraryFilter {
  name?: string | null;
  layerTypeId?: number;
  customerId?: number | null;
  orderBy?: string | null;
  orderAscending?: boolean | null;
  startCreatedDatetime?: string | null;
  endCreatedDatetime?: string | null;
}

export interface LibraryItem {
  layerTypeId?: number;
  layerType?: LayerType;
  entityId?: number;
  name?: string | null;
  date?: string | null;
}

export interface Map {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  blockCollectionId?: number;
  url?: string | null;
  blockCollection?: BlockCollection;
}

export interface MapPin {
  id?: number;
  blockName?: string | null;
  blockDisplayName?: string | null;
  farmId?: number;
  customerDisplayName?: string | null;
  farm?: FarmPin;
  crop?: Crop;
}

export interface MlModel {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  description?: string | null;
  displayName?: string | null;
  pipelineId?: number | null;
  createdById?: string | null;
  version?: string | null;
  archived?: boolean;
  fileName?: string | null;
  modelType?: string | null;
  modelArchitecture?: string | null;
  trainingDate?: string | null;
}

export interface Morphology {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  rowId?: number | null;
  treeNum?: number | null;
  canopyDiameter?: number | null;
  canopyDensity?: number | null;
  canopyNumpt?: number | null;
  demz?: number | null;
  treeHeight?: number | null;
  canopyReflectance?: number | null;
  canopyIntensity?: number | null;
  canopyVolume?: number | null;
  trunkDiameter?: number | null;
  trunkNumpt?: number | null;
  trunkReflectance?: number | null;
  trunkIntensity?: number | null;
  trunkVolume?: number | null;
  z?: number | null;
  blockCollectionId?: number;
  morphologyCustomAttributes?: MorphologyCustomAttribute[] | null;
  plantId?: number;
  plant?: Plant;
  isCompare?: boolean;
  pageNumber?: number;
  totalItems?: number;
  isCollapsed?: boolean;
  weightUnitType?: string | null;
  distanceUnitType?: string | null;
  volumeExponent?: number;
  volumeUnitType?: string | null;
}

export interface MorphologyCustomAttribute {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  morphologyId?: number;
  displayName?: string | null;
  attributeName?: string | null;
  attributeValue?: string | null;
  scanDate?: string;
}

export interface MosaicEngine {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  description?: string | null;
  displayName?: string | null;
  pipelineId?: number | null;
  createdById?: string | null;
  archived?: boolean;
  modelName?: string | null;
}

export interface Note {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  text?: string | null;
  plantId?: number;
}

export interface Notification {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  createdById?: string | null;
  notificationId?: string | null;
  notificationType?: string | null;
  title?: string | null;
  body?: string | null;
}

export interface NtsGeometryServices {
  geometryOverlay?: GeometryOverlay;
  coordinateEqualityComparer?: CoordinateEqualityComparer;
  defaultSRID?: number;
  defaultCoordinateSequenceFactory?: CoordinateSequenceFactory;
  defaultPrecisionModel?: PrecisionModel;
}

export type OgcGeometryType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;

export interface OperationJob {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  description?: string | null;
  analyticRequestId?: number | null;
  requiredCaptureJobId?: number | null;
  requiredCaptureExtractionJobId?: number | null;
  createdById?: string | null;
  assignedToId?: string | null;
  status?: string | null;
  completedDatetime?: string | null;
}

export type Ordinates = 0 | 1 | 2 | 3 | 4 | 7 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024 | 2048 | 4096 | 8192 | 16384 | 32768 | 65535 | 65536 | 65539 | 65543 | 131072 | 262144 | 524288 | 1048576 | 2097152 | 4194304 | 8388608 | 16777216 | 33554432 | 67108864 | 134217728 | 268435456 | 536870912 | 1073741824 | -2147483648 | -65536 | -1;

export interface ParametersString {
  parameters?: string | null;
}

export interface Pipeline {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  description?: string | null;
  path?: string | null;
  createdById?: string | null;
}

export interface PipelineJob {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  jobUuid?: string | null;
  captureId?: number | null;
  uploadId?: number | null;
  stage?: string | null;
  pipeline?: Pipeline;
  pipelineId?: number;
  data?: string | null;
  createdById?: string | null;
  submittedDatetime?: string | null;
  completedDatetime?: string | null;
  mosaicEngineId?: number | null;
  mosaicEngine?: MosaicEngine;
  mlModelId?: number | null;
  mlModel?: MlModel;
  excludeFiles?: string | null;
  status?: string | null;
  analyticRequestId?: number | null;
}

export interface PipelineJobFilter {
  name?: string | null;
  startCreatedDatetime?: string | null;
  endCreatedDatetime?: string | null;
  startCompletedDatetime?: string | null;
  endCompletedDatetime?: string | null;
  pipelineId?: number | null;
}

export interface Plant {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  blockId?: number;
  originCollectionId?: number;
  varietyId?: number | null;
  uuid?: string | null;
  morphologies?: Morphology[] | null;
  plantTreatments?: PlantTreatment[] | null;
  yields?: Yield[] | null;
  variety?: CropVariety;
  block?: Block;
  originCollection?: Collection;
  rawYield?: RawYield[] | null;
  notes?: Note[] | null;
}

export interface PlantTreatment {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  treatmentId?: number | null;
  treatmentDatetime?: string | null;
  plantId?: number | null;
  plant?: Plant;
  treatment?: Treatment;
}

export interface PlantType {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  plantTypeName?: string | null;
  newMorphologyValidationConfig?: string | null;
  editMorphologyValidationConfig?: string | null;
  editMorphologyNoTrunksValidationConfig?: string | null;
  finalMorphologyValidationConfig?: string | null;
  crops?: Crop[] | null;
}

export interface Point {
  factory?: GeometryFactory;
  userData?: Record<string, unknown> | null;
  srid?: number;
  precisionModel?: PrecisionModel;
  numGeometries?: number;
  isSimple?: boolean;
  isValid?: boolean;
  area?: number;
  length?: number;
  centroid?: Point;
  interiorPoint?: Point;
  pointOnSurface?: Point;
  envelope?: Geometry;
  envelopeInternal?: Envelope;
  isRectangle?: boolean;
  coordinateSequence?: CoordinateSequence;
  coordinates?: Coordinate[] | null;
  numPoints?: number;
  isEmpty?: boolean;
  dimension?: Dimension;
  boundaryDimension?: Dimension;
  x?: number;
  y?: number;
  coordinate?: Coordinate;
  geometryType?: string | null;
  ogcGeometryType?: OgcGeometryType;
  boundary?: Geometry;
  z?: number;
  m?: number;
}

export interface Pointcloud {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  blockCollectionId?: number;
  rowNumber?: number;
  fileLocation?: string | null;
  blockCollection?: BlockCollection;
}

export interface PrecisionModel {
  isFloating?: boolean;
  maximumSignificantDigits?: number;
  scale?: number;
  gridSize?: number;
  precisionModelType?: PrecisionModels;
}

export type PrecisionModels = 0 | 1 | 2;

export interface Product {
  name?: string | null;
  basePath?: string | null;
  isEnabled?: boolean;
  isActive?: boolean;
  requiredClaim?: string | null;
}

export interface Project {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name: string;
  uuid?: string;
  archived?: boolean;
  createdBy?: string;
  baseMapId?: number | null;
  customerId: number;
  owner?: string | null;
  ownerProfile?: UserProfile;
  extent?: Extent;
  customer?: Customer;
  projectTags?: ProjectTag[] | null;
  fusionLink?: string | null;
}

export interface ProjectFilter {
  projectName?: string | null;
  archived?: boolean;
  customerIds?: number[] | null;
  userUuids?: string[] | null;
  orderBy?: string | null;
  orderAscending?: boolean | null;
}

export interface ProjectTag {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  tagName?: string | null;
  projectId?: number;
}

export interface Ranking {
  position?: number;
  total?: number;
  pecentage?: number;
  mean?: number;
}

export interface RawYield {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  timeStamp?: number;
  numSattelites?: number | null;
  gpsFixId?: number;
  gpsTime?: number;
  velocityX?: number | null;
  velcocityY?: number | null;
  velocityZ?: number | null;
  velocityTotal?: number | null;
  latitude?: number | null;
  longitude?: number | null;
  altitude?: number | null;
  yaw?: number | null;
  pitch?: number | null;
  roll?: number | null;
  velocityUncertainty?: number | null;
  latitudeUncertainty?: number | null;
  longitudeUncertainty?: number | null;
  altitudeUncertainty?: number | null;
  yawUncertainty?: number | null;
  pitchUncertainty?: number | null;
  rollUncertainty?: number | null;
  insStatus?: string | null;
  yieldVolume?: number | null;
  yieldWeight?: number | null;
  yieldCount?: number | null;
  rawWeightOne?: number | null;
  rawWeightTwo?: number | null;
  blockId?: number | null;
  collectionId?: number | null;
  plantId?: number | null;
  isProcessed?: boolean;
  batchJobUuid?: string | null;
  originFilePath?: string | null;
  originFileName?: string | null;
  uploadUserId?: string | null;
  rawYieldDetection?: RawYieldDetection[] | null;
  yieldTrackerVersion?: YieldTrackerVersion;
  yieldTrackerUnit?: YieldTrackerUnit;
  block?: Block;
  collection?: Collection;
  gpsFix?: GpsFix;
  plant?: Plant;
  isBinOneLifted?: boolean;
  isBinTwoLifted?: boolean;
  yieldTrackerVersionId?: number;
  yieldTrackerUnitId?: number;
  sensorHeight?: number;
  rawYieldDetectionJson?: string | null;
  sensorCalibrationOffset?: number;
  isVecnavFlipped?: boolean;
}

export interface RawYieldDetection {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  rawYieldId?: number;
  amplitude?: number | null;
  distance?: number | null;
  rawYield?: RawYield;
}

export interface Region {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  regionCode?: string | null;
  regionName?: string | null;
  regionGeom?: Geometry;
  countryId?: number;
  farms?: Farm[] | null;
  country?: Country;
}

export interface RequestAnalytics {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  captureId?: number;
  mlModelId?: number;
  dbhValue?: string | null;
}

export interface Service {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  serviceName?: string | null;
  serviceOrders?: ServiceOrder[] | null;
  serviceTypes?: ServiceType[] | null;
}

export interface ServiceOrder {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  serviceId?: number | null;
  userId?: string | null;
  blockId?: number | null;
  cropId?: number | null;
  block?: Block;
  service?: Service;
  crop?: Crop;
}

export interface ServiceType {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  serviceTypeName?: string | null;
  serviceId?: number | null;
  productKey?: string | null;
  collections?: Collection[] | null;
  service?: Service;
}

export interface ServicesHealthCheck {
  dbConnectionAvailable?: boolean;
  dbConnectionError?: string | null;
  noSQLConnectionAvailable?: boolean;
  noSQLConnectionError?: string | null;
  imageServiceConnectionAvailable?: boolean;
  imageServiceConnectionError?: string | null;
  videoServiceConnectionAvailable?: boolean;
  videoServiceConnectionError?: string | null;
}

export interface Treatment {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  treatmentName?: string | null;
  plantTreatments?: PlantTreatment[] | null;
}

export interface Upload {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  description?: string | null;
  customerId?: number | null;
  farmId?: number | null;
  blockId?: number | null;
  isUploaded?: boolean;
  uploadUrl?: string | null;
  uploadUrlQuery?: string | null;
  uploadUrlPath?: string | null;
  uuid?: string | null;
  container?: string | null;
  uploadTypeId?: number;
  scanDatetime?: string | null;
  createdById?: string | null;
  uploadType?: UploadType;
  pipelineJobs?: PipelineJob[] | null;
}

export interface UploadFile {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  uploadId?: number;
  name?: string | null;
  originalName?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  fileCreateDatetime?: string | null;
  isUploaded?: boolean;
}

export interface UploadFilter {
  filterName?: string | null;
  uploadTypeId?: number | null;
  orderBy?: string | null;
  orderAscending?: boolean | null;
}

export interface UploadType {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  name?: string | null;
  available?: boolean;
}

export interface User {
  id?: string | null;
  userName?: string | null;
  normalizedUserName?: string | null;
  email?: string | null;
  normalizedEmail?: string | null;
  emailConfirmed?: boolean;
  passwordHash?: string | null;
  securityStamp?: string | null;
  concurrencyStamp?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  lockoutEnd?: string | null;
  lockoutEnabled?: boolean;
  accessFailedCount?: number;
  lastLogin?: string;
  isActive?: boolean;
  userProfiles?: UserProfile[] | null;
  products?: Product[] | null;
  defaultState?: string | null;
  cloudClaims?: string[] | null;
}

export interface UserProfile {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  isMetric?: boolean;
  perferredLanguage?: string | null;
  dateCreated?: string | null;
  user?: User;
}

export interface VideoMetadata {
  format?: string | null;
  content_type?: string | null;
  orientation?: string | null;
  duration?: string | null;
  width?: string | null;
  height?: string | null;
  fps?: string | null;
  has_video?: string | null;
  has_audio?: string | null;
}

export interface Yield {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  volume?: number | null;
  weight?: number | null;
  count?: number | null;
  percentage?: number | null;
  blockCollectionId?: number;
  groveTrackerBlockCollectionId?: number | null;
  plantId?: number | null;
  blockCollection?: BlockCollection;
  plant?: Plant;
  morphology?: Morphology;
  pageNumber?: number;
  totalItems?: number;
  isCollapsed?: boolean;
  weightUnitType?: string | null;
  distanceUnitType?: string | null;
  volumeExponent?: number;
  volumeUnitType?: string | null;
}

export interface YieldTrackerUnit {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  boxId?: string | null;
  boxNumber?: string | null;
  wifiName?: string | null;
  raspiSerialNumber?: string | null;
  vectorNavSerialNumber?: string | null;
  vectorNavUsbSerialNumber?: string | null;
  lidarSerialNumber?: string | null;
  lidarModbusAddress?: string | null;
  usbSerialNumber?: string | null;
  sensorHeight?: number;
  sensorCalibrationOffset?: number;
  isVecnavFlipped?: boolean;
  processData?: boolean;
  rawYields?: RawYield[] | null;
}

export interface YieldTrackerVersion {
  id?: number;
  createDatetime?: string | null;
  updateDatetime?: string | null;
  updatedById?: string | null;
  versionKey?: string | null;
  description?: string | null;
  isWeight?: boolean;
  isVolume?: boolean;
  rawYields?: RawYield[] | null;
}

export type GetAnalyticJobTypeProps = Omit<GetProps<AnalyticJobType[], void, void, void>, "path">;

export const GetAnalyticJobType = (props: GetAnalyticJobTypeProps) => (
  <Get<AnalyticJobType[], void, void, void>
    path={`/api/AnalyticJobTypes`}
    
    {...props}
  />
);

export type UseGetAnalyticJobTypeProps = Omit<UseGetProps<AnalyticJobType[], void, void, void>, "path">;

export const useGetAnalyticJobType = (props: UseGetAnalyticJobTypeProps) => useGet<AnalyticJobType[], void, void, void>(`/api/AnalyticJobTypes`, props);


export type PostAnalyticJobTypeProps = Omit<MutateProps<AnalyticJobType, void, void, AnalyticJobType, void>, "path" | "verb">;

export const PostAnalyticJobType = (props: PostAnalyticJobTypeProps) => (
  <Mutate<AnalyticJobType, void, void, AnalyticJobType, void>
    verb="POST"
    path={`/api/AnalyticJobTypes`}
    
    {...props}
  />
);

export type UsePostAnalyticJobTypeProps = Omit<UseMutateProps<AnalyticJobType, void, void, AnalyticJobType, void>, "path" | "verb">;

export const usePostAnalyticJobType = (props: UsePostAnalyticJobTypeProps) => useMutate<AnalyticJobType, void, void, AnalyticJobType, void>("POST", `/api/AnalyticJobTypes`, props);


export interface GetAnalyticJobTypeByIdPathParams {
  id: number
}

export type GetAnalyticJobTypeByIdProps = Omit<GetProps<AnalyticJobType, void, void, GetAnalyticJobTypeByIdPathParams>, "path"> & GetAnalyticJobTypeByIdPathParams;

export const GetAnalyticJobTypeById = ({id, ...props}: GetAnalyticJobTypeByIdProps) => (
  <Get<AnalyticJobType, void, void, GetAnalyticJobTypeByIdPathParams>
    path={`/api/AnalyticJobTypes/${id}`}
    
    {...props}
  />
);

export type UseGetAnalyticJobTypeByIdProps = Omit<UseGetProps<AnalyticJobType, void, void, GetAnalyticJobTypeByIdPathParams>, "path"> & GetAnalyticJobTypeByIdPathParams;

export const useGetAnalyticJobTypeById = ({id, ...props}: UseGetAnalyticJobTypeByIdProps) => useGet<AnalyticJobType, void, void, GetAnalyticJobTypeByIdPathParams>((paramsInPath: GetAnalyticJobTypeByIdPathParams) => `/api/AnalyticJobTypes/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutAnalyticJobTypeByIdPathParams {
  id: number
}

export type PutAnalyticJobTypeByIdProps = Omit<MutateProps<AnalyticJobType, void, void, AnalyticJobType, PutAnalyticJobTypeByIdPathParams>, "path" | "verb"> & PutAnalyticJobTypeByIdPathParams;

export const PutAnalyticJobTypeById = ({id, ...props}: PutAnalyticJobTypeByIdProps) => (
  <Mutate<AnalyticJobType, void, void, AnalyticJobType, PutAnalyticJobTypeByIdPathParams>
    verb="PUT"
    path={`/api/AnalyticJobTypes/${id}`}
    
    {...props}
  />
);

export type UsePutAnalyticJobTypeByIdProps = Omit<UseMutateProps<AnalyticJobType, void, void, AnalyticJobType, PutAnalyticJobTypeByIdPathParams>, "path" | "verb"> & PutAnalyticJobTypeByIdPathParams;

export const usePutAnalyticJobTypeById = ({id, ...props}: UsePutAnalyticJobTypeByIdProps) => useMutate<AnalyticJobType, void, void, AnalyticJobType, PutAnalyticJobTypeByIdPathParams>("PUT", (paramsInPath: PutAnalyticJobTypeByIdPathParams) => `/api/AnalyticJobTypes/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteAnalyticJobTypeByIdProps = Omit<MutateProps<AnalyticJobType, void, void, number, void>, "path" | "verb">;

export const DeleteAnalyticJobTypeById = (props: DeleteAnalyticJobTypeByIdProps) => (
  <Mutate<AnalyticJobType, void, void, number, void>
    verb="DELETE"
    path={`/api/AnalyticJobTypes`}
    
    {...props}
  />
);

export type UseDeleteAnalyticJobTypeByIdProps = Omit<UseMutateProps<AnalyticJobType, void, void, number, void>, "path" | "verb">;

export const useDeleteAnalyticJobTypeById = (props: UseDeleteAnalyticJobTypeByIdProps) => useMutate<AnalyticJobType, void, void, number, void>("DELETE", `/api/AnalyticJobTypes`, {   ...props });


export interface GetAnalyticJobTypeByAnalyticIdPathParams {
  id: number
}

export type GetAnalyticJobTypeByAnalyticIdProps = Omit<GetProps<AnalyticJobType[], void, void, GetAnalyticJobTypeByAnalyticIdPathParams>, "path"> & GetAnalyticJobTypeByAnalyticIdPathParams;

export const GetAnalyticJobTypeByAnalyticId = ({id, ...props}: GetAnalyticJobTypeByAnalyticIdProps) => (
  <Get<AnalyticJobType[], void, void, GetAnalyticJobTypeByAnalyticIdPathParams>
    path={`/api/AnalyticJobTypes/Analytic/${id}`}
    
    {...props}
  />
);

export type UseGetAnalyticJobTypeByAnalyticIdProps = Omit<UseGetProps<AnalyticJobType[], void, void, GetAnalyticJobTypeByAnalyticIdPathParams>, "path"> & GetAnalyticJobTypeByAnalyticIdPathParams;

export const useGetAnalyticJobTypeByAnalyticId = ({id, ...props}: UseGetAnalyticJobTypeByAnalyticIdProps) => useGet<AnalyticJobType[], void, void, GetAnalyticJobTypeByAnalyticIdPathParams>((paramsInPath: GetAnalyticJobTypeByAnalyticIdPathParams) => `/api/AnalyticJobTypes/Analytic/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetAnalyticJobTypeByJobTypeIdPathParams {
  id: number
}

export type GetAnalyticJobTypeByJobTypeIdProps = Omit<GetProps<AnalyticJobType, void, void, GetAnalyticJobTypeByJobTypeIdPathParams>, "path"> & GetAnalyticJobTypeByJobTypeIdPathParams;

export const GetAnalyticJobTypeByJobTypeId = ({id, ...props}: GetAnalyticJobTypeByJobTypeIdProps) => (
  <Get<AnalyticJobType, void, void, GetAnalyticJobTypeByJobTypeIdPathParams>
    path={`/api/AnalyticJobTypes/JobType/${id}`}
    
    {...props}
  />
);

export type UseGetAnalyticJobTypeByJobTypeIdProps = Omit<UseGetProps<AnalyticJobType, void, void, GetAnalyticJobTypeByJobTypeIdPathParams>, "path"> & GetAnalyticJobTypeByJobTypeIdPathParams;

export const useGetAnalyticJobTypeByJobTypeId = ({id, ...props}: UseGetAnalyticJobTypeByJobTypeIdProps) => useGet<AnalyticJobType, void, void, GetAnalyticJobTypeByJobTypeIdPathParams>((paramsInPath: GetAnalyticJobTypeByJobTypeIdPathParams) => `/api/AnalyticJobTypes/JobType/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetFilteredAnalyticJobTypesPathParams {
  skip: number;
  take: number
}

export type GetFilteredAnalyticJobTypesProps = Omit<MutateProps<AnalyticJobType[], void, void, AnalyticJobTypeFilter, GetFilteredAnalyticJobTypesPathParams>, "path" | "verb"> & GetFilteredAnalyticJobTypesPathParams;

export const GetFilteredAnalyticJobTypes = ({skip, take, ...props}: GetFilteredAnalyticJobTypesProps) => (
  <Mutate<AnalyticJobType[], void, void, AnalyticJobTypeFilter, GetFilteredAnalyticJobTypesPathParams>
    verb="POST"
    path={`/api/AnalyticJobTypes/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredAnalyticJobTypesProps = Omit<UseMutateProps<AnalyticJobType[], void, void, AnalyticJobTypeFilter, GetFilteredAnalyticJobTypesPathParams>, "path" | "verb"> & GetFilteredAnalyticJobTypesPathParams;

export const useGetFilteredAnalyticJobTypes = ({skip, take, ...props}: UseGetFilteredAnalyticJobTypesProps) => useMutate<AnalyticJobType[], void, void, AnalyticJobTypeFilter, GetFilteredAnalyticJobTypesPathParams>("POST", (paramsInPath: GetFilteredAnalyticJobTypesPathParams) => `/api/AnalyticJobTypes/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export type GetAnalyticRequestProps = Omit<GetProps<AnalyticRequest[], void, void, void>, "path">;

export const GetAnalyticRequest = (props: GetAnalyticRequestProps) => (
  <Get<AnalyticRequest[], void, void, void>
    path={`/api/AnalyticRequests`}
    
    {...props}
  />
);

export type UseGetAnalyticRequestProps = Omit<UseGetProps<AnalyticRequest[], void, void, void>, "path">;

export const useGetAnalyticRequest = (props: UseGetAnalyticRequestProps) => useGet<AnalyticRequest[], void, void, void>(`/api/AnalyticRequests`, props);


export type PostAnalyticRequestProps = Omit<MutateProps<void, void, void, AnalyticRequest, void>, "path" | "verb">;

export const PostAnalyticRequest = (props: PostAnalyticRequestProps) => (
  <Mutate<void, void, void, AnalyticRequest, void>
    verb="POST"
    path={`/api/AnalyticRequests`}
    
    {...props}
  />
);

export type UsePostAnalyticRequestProps = Omit<UseMutateProps<void, void, void, AnalyticRequest, void>, "path" | "verb">;

export const usePostAnalyticRequest = (props: UsePostAnalyticRequestProps) => useMutate<void, void, void, AnalyticRequest, void>("POST", `/api/AnalyticRequests`, props);


export interface GetFilteredAnalyticRequestsPathParams {
  skip: number;
  take: number
}

export type GetFilteredAnalyticRequestsProps = Omit<MutateProps<AnalyticRequest[], void, void, AnalyticRequestFilter, GetFilteredAnalyticRequestsPathParams>, "path" | "verb"> & GetFilteredAnalyticRequestsPathParams;

export const GetFilteredAnalyticRequests = ({skip, take, ...props}: GetFilteredAnalyticRequestsProps) => (
  <Mutate<AnalyticRequest[], void, void, AnalyticRequestFilter, GetFilteredAnalyticRequestsPathParams>
    verb="POST"
    path={`/api/AnalyticRequests/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredAnalyticRequestsProps = Omit<UseMutateProps<AnalyticRequest[], void, void, AnalyticRequestFilter, GetFilteredAnalyticRequestsPathParams>, "path" | "verb"> & GetFilteredAnalyticRequestsPathParams;

export const useGetFilteredAnalyticRequests = ({skip, take, ...props}: UseGetFilteredAnalyticRequestsProps) => useMutate<AnalyticRequest[], void, void, AnalyticRequestFilter, GetFilteredAnalyticRequestsPathParams>("POST", (paramsInPath: GetFilteredAnalyticRequestsPathParams) => `/api/AnalyticRequests/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetAnalyticRequestByIdPathParams {
  id: number
}

export type GetAnalyticRequestByIdProps = Omit<GetProps<AnalyticRequest, void, void, GetAnalyticRequestByIdPathParams>, "path"> & GetAnalyticRequestByIdPathParams;

export const GetAnalyticRequestById = ({id, ...props}: GetAnalyticRequestByIdProps) => (
  <Get<AnalyticRequest, void, void, GetAnalyticRequestByIdPathParams>
    path={`/api/AnalyticRequests/${id}`}
    
    {...props}
  />
);

export type UseGetAnalyticRequestByIdProps = Omit<UseGetProps<AnalyticRequest, void, void, GetAnalyticRequestByIdPathParams>, "path"> & GetAnalyticRequestByIdPathParams;

export const useGetAnalyticRequestById = ({id, ...props}: UseGetAnalyticRequestByIdProps) => useGet<AnalyticRequest, void, void, GetAnalyticRequestByIdPathParams>((paramsInPath: GetAnalyticRequestByIdPathParams) => `/api/AnalyticRequests/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutAnalyticRequestByIdPathParams {
  id: number
}

export type PutAnalyticRequestByIdProps = Omit<MutateProps<AnalyticRequest, void, void, AnalyticRequest, PutAnalyticRequestByIdPathParams>, "path" | "verb"> & PutAnalyticRequestByIdPathParams;

export const PutAnalyticRequestById = ({id, ...props}: PutAnalyticRequestByIdProps) => (
  <Mutate<AnalyticRequest, void, void, AnalyticRequest, PutAnalyticRequestByIdPathParams>
    verb="PUT"
    path={`/api/AnalyticRequests/${id}`}
    
    {...props}
  />
);

export type UsePutAnalyticRequestByIdProps = Omit<UseMutateProps<AnalyticRequest, void, void, AnalyticRequest, PutAnalyticRequestByIdPathParams>, "path" | "verb"> & PutAnalyticRequestByIdPathParams;

export const usePutAnalyticRequestById = ({id, ...props}: UsePutAnalyticRequestByIdProps) => useMutate<AnalyticRequest, void, void, AnalyticRequest, PutAnalyticRequestByIdPathParams>("PUT", (paramsInPath: PutAnalyticRequestByIdPathParams) => `/api/AnalyticRequests/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetAnalyticRequestsByCaptureIdPathParams {
  id: number
}

export type GetAnalyticRequestsByCaptureIdProps = Omit<GetProps<AnalyticRequest[], void, void, GetAnalyticRequestsByCaptureIdPathParams>, "path"> & GetAnalyticRequestsByCaptureIdPathParams;

export const GetAnalyticRequestsByCaptureId = ({id, ...props}: GetAnalyticRequestsByCaptureIdProps) => (
  <Get<AnalyticRequest[], void, void, GetAnalyticRequestsByCaptureIdPathParams>
    path={`/api/AnalyticRequests/Capture/${id}`}
    
    {...props}
  />
);

export type UseGetAnalyticRequestsByCaptureIdProps = Omit<UseGetProps<AnalyticRequest[], void, void, GetAnalyticRequestsByCaptureIdPathParams>, "path"> & GetAnalyticRequestsByCaptureIdPathParams;

export const useGetAnalyticRequestsByCaptureId = ({id, ...props}: UseGetAnalyticRequestsByCaptureIdProps) => useGet<AnalyticRequest[], void, void, GetAnalyticRequestsByCaptureIdPathParams>((paramsInPath: GetAnalyticRequestsByCaptureIdPathParams) => `/api/AnalyticRequests/Capture/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetAnalyticRequestJobsByAnalyticRequestIdPathParams {
  id: number
}

export type GetAnalyticRequestJobsByAnalyticRequestIdProps = Omit<GetProps<AnalyticJob[], void, void, GetAnalyticRequestJobsByAnalyticRequestIdPathParams>, "path"> & GetAnalyticRequestJobsByAnalyticRequestIdPathParams;

export const GetAnalyticRequestJobsByAnalyticRequestId = ({id, ...props}: GetAnalyticRequestJobsByAnalyticRequestIdProps) => (
  <Get<AnalyticJob[], void, void, GetAnalyticRequestJobsByAnalyticRequestIdPathParams>
    path={`/api/AnalyticRequests/${id}/AnalyticJobs`}
    
    {...props}
  />
);

export type UseGetAnalyticRequestJobsByAnalyticRequestIdProps = Omit<UseGetProps<AnalyticJob[], void, void, GetAnalyticRequestJobsByAnalyticRequestIdPathParams>, "path"> & GetAnalyticRequestJobsByAnalyticRequestIdPathParams;

export const useGetAnalyticRequestJobsByAnalyticRequestId = ({id, ...props}: UseGetAnalyticRequestJobsByAnalyticRequestIdProps) => useGet<AnalyticJob[], void, void, GetAnalyticRequestJobsByAnalyticRequestIdPathParams>((paramsInPath: GetAnalyticRequestJobsByAnalyticRequestIdPathParams) => `/api/AnalyticRequests/${paramsInPath.id}/AnalyticJobs`, {  pathParams: { id }, ...props });


export type GetAnalyticProps = Omit<GetProps<Analytic[], void, void, void>, "path">;

export const GetAnalytic = (props: GetAnalyticProps) => (
  <Get<Analytic[], void, void, void>
    path={`/api/Analytics`}
    
    {...props}
  />
);

export type UseGetAnalyticProps = Omit<UseGetProps<Analytic[], void, void, void>, "path">;

export const useGetAnalytic = (props: UseGetAnalyticProps) => useGet<Analytic[], void, void, void>(`/api/Analytics`, props);


export type PostAnalyticProps = Omit<MutateProps<Analytic, void, void, Analytic, void>, "path" | "verb">;

export const PostAnalytic = (props: PostAnalyticProps) => (
  <Mutate<Analytic, void, void, Analytic, void>
    verb="POST"
    path={`/api/Analytics`}
    
    {...props}
  />
);

export type UsePostAnalyticProps = Omit<UseMutateProps<Analytic, void, void, Analytic, void>, "path" | "verb">;

export const usePostAnalytic = (props: UsePostAnalyticProps) => useMutate<Analytic, void, void, Analytic, void>("POST", `/api/Analytics`, props);


export interface GetAnalyticByIdPathParams {
  id: number
}

export type GetAnalyticByIdProps = Omit<GetProps<Analytic, void, void, GetAnalyticByIdPathParams>, "path"> & GetAnalyticByIdPathParams;

export const GetAnalyticById = ({id, ...props}: GetAnalyticByIdProps) => (
  <Get<Analytic, void, void, GetAnalyticByIdPathParams>
    path={`/api/Analytics/${id}`}
    
    {...props}
  />
);

export type UseGetAnalyticByIdProps = Omit<UseGetProps<Analytic, void, void, GetAnalyticByIdPathParams>, "path"> & GetAnalyticByIdPathParams;

export const useGetAnalyticById = ({id, ...props}: UseGetAnalyticByIdProps) => useGet<Analytic, void, void, GetAnalyticByIdPathParams>((paramsInPath: GetAnalyticByIdPathParams) => `/api/Analytics/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutAnalyticByIdPathParams {
  id: number
}

export type PutAnalyticByIdProps = Omit<MutateProps<Analytic, void, void, Analytic, PutAnalyticByIdPathParams>, "path" | "verb"> & PutAnalyticByIdPathParams;

export const PutAnalyticById = ({id, ...props}: PutAnalyticByIdProps) => (
  <Mutate<Analytic, void, void, Analytic, PutAnalyticByIdPathParams>
    verb="PUT"
    path={`/api/Analytics/${id}`}
    
    {...props}
  />
);

export type UsePutAnalyticByIdProps = Omit<UseMutateProps<Analytic, void, void, Analytic, PutAnalyticByIdPathParams>, "path" | "verb"> & PutAnalyticByIdPathParams;

export const usePutAnalyticById = ({id, ...props}: UsePutAnalyticByIdProps) => useMutate<Analytic, void, void, Analytic, PutAnalyticByIdPathParams>("PUT", (paramsInPath: PutAnalyticByIdPathParams) => `/api/Analytics/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetBaseMapProps = Omit<GetProps<BaseMap[], void, void, void>, "path">;

export const GetBaseMap = (props: GetBaseMapProps) => (
  <Get<BaseMap[], void, void, void>
    path={`/api/BaseMaps`}
    
    {...props}
  />
);

export type UseGetBaseMapProps = Omit<UseGetProps<BaseMap[], void, void, void>, "path">;

export const useGetBaseMap = (props: UseGetBaseMapProps) => useGet<BaseMap[], void, void, void>(`/api/BaseMaps`, props);


export type PostBaseMapProps = Omit<MutateProps<BaseMap, void, void, BaseMap, void>, "path" | "verb">;

export const PostBaseMap = (props: PostBaseMapProps) => (
  <Mutate<BaseMap, void, void, BaseMap, void>
    verb="POST"
    path={`/api/BaseMaps`}
    
    {...props}
  />
);

export type UsePostBaseMapProps = Omit<UseMutateProps<BaseMap, void, void, BaseMap, void>, "path" | "verb">;

export const usePostBaseMap = (props: UsePostBaseMapProps) => useMutate<BaseMap, void, void, BaseMap, void>("POST", `/api/BaseMaps`, props);


export interface GetBaseMapByIdPathParams {
  id: number
}

export type GetBaseMapByIdProps = Omit<GetProps<BaseMap, void, void, GetBaseMapByIdPathParams>, "path"> & GetBaseMapByIdPathParams;

export const GetBaseMapById = ({id, ...props}: GetBaseMapByIdProps) => (
  <Get<BaseMap, void, void, GetBaseMapByIdPathParams>
    path={`/api/BaseMaps/${id}`}
    
    {...props}
  />
);

export type UseGetBaseMapByIdProps = Omit<UseGetProps<BaseMap, void, void, GetBaseMapByIdPathParams>, "path"> & GetBaseMapByIdPathParams;

export const useGetBaseMapById = ({id, ...props}: UseGetBaseMapByIdProps) => useGet<BaseMap, void, void, GetBaseMapByIdPathParams>((paramsInPath: GetBaseMapByIdPathParams) => `/api/BaseMaps/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutBaseMapByIdPathParams {
  id: number
}

export type PutBaseMapByIdProps = Omit<MutateProps<BaseMap, void, void, BaseMap, PutBaseMapByIdPathParams>, "path" | "verb"> & PutBaseMapByIdPathParams;

export const PutBaseMapById = ({id, ...props}: PutBaseMapByIdProps) => (
  <Mutate<BaseMap, void, void, BaseMap, PutBaseMapByIdPathParams>
    verb="PUT"
    path={`/api/BaseMaps/${id}`}
    
    {...props}
  />
);

export type UsePutBaseMapByIdProps = Omit<UseMutateProps<BaseMap, void, void, BaseMap, PutBaseMapByIdPathParams>, "path" | "verb"> & PutBaseMapByIdPathParams;

export const usePutBaseMapById = ({id, ...props}: UsePutBaseMapByIdProps) => useMutate<BaseMap, void, void, BaseMap, PutBaseMapByIdPathParams>("PUT", (paramsInPath: PutBaseMapByIdPathParams) => `/api/BaseMaps/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteBaseMapByIdProps = Omit<MutateProps<BaseMap, void, void, number, void>, "path" | "verb">;

export const DeleteBaseMapById = (props: DeleteBaseMapByIdProps) => (
  <Mutate<BaseMap, void, void, number, void>
    verb="DELETE"
    path={`/api/BaseMaps`}
    
    {...props}
  />
);

export type UseDeleteBaseMapByIdProps = Omit<UseMutateProps<BaseMap, void, void, number, void>, "path" | "verb">;

export const useDeleteBaseMapById = (props: UseDeleteBaseMapByIdProps) => useMutate<BaseMap, void, void, number, void>("DELETE", `/api/BaseMaps`, {   ...props });


export type GetBlockProps = Omit<GetProps<Block[], void, void, void>, "path">;

export const GetBlock = (props: GetBlockProps) => (
  <Get<Block[], void, void, void>
    path={`/api/Blocks`}
    
    {...props}
  />
);

export type UseGetBlockProps = Omit<UseGetProps<Block[], void, void, void>, "path">;

export const useGetBlock = (props: UseGetBlockProps) => useGet<Block[], void, void, void>(`/api/Blocks`, props);


export type PostBlockProps = Omit<MutateProps<Block, void, void, Block, void>, "path" | "verb">;

export const PostBlock = (props: PostBlockProps) => (
  <Mutate<Block, void, void, Block, void>
    verb="POST"
    path={`/api/Blocks`}
    
    {...props}
  />
);

export type UsePostBlockProps = Omit<UseMutateProps<Block, void, void, Block, void>, "path" | "verb">;

export const usePostBlock = (props: UsePostBlockProps) => useMutate<Block, void, void, Block, void>("POST", `/api/Blocks`, props);


export interface GetPagedBlocksPathParams {
  skip: number;
  take: number
}

export type GetPagedBlocksProps = Omit<GetProps<Block[], void, void, GetPagedBlocksPathParams>, "path"> & GetPagedBlocksPathParams;

export const GetPagedBlocks = ({skip, take, ...props}: GetPagedBlocksProps) => (
  <Get<Block[], void, void, GetPagedBlocksPathParams>
    path={`/api/Blocks/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetPagedBlocksProps = Omit<UseGetProps<Block[], void, void, GetPagedBlocksPathParams>, "path"> & GetPagedBlocksPathParams;

export const useGetPagedBlocks = ({skip, take, ...props}: UseGetPagedBlocksProps) => useGet<Block[], void, void, GetPagedBlocksPathParams>((paramsInPath: GetPagedBlocksPathParams) => `/api/Blocks/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetFilteredPagedBlocksPathParams {
  skip: number;
  take: number
}

export type GetFilteredPagedBlocksProps = Omit<MutateProps<Block[], void, void, BlockFilter, GetFilteredPagedBlocksPathParams>, "path" | "verb"> & GetFilteredPagedBlocksPathParams;

export const GetFilteredPagedBlocks = ({skip, take, ...props}: GetFilteredPagedBlocksProps) => (
  <Mutate<Block[], void, void, BlockFilter, GetFilteredPagedBlocksPathParams>
    verb="POST"
    path={`/api/Blocks/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredPagedBlocksProps = Omit<UseMutateProps<Block[], void, void, BlockFilter, GetFilteredPagedBlocksPathParams>, "path" | "verb"> & GetFilteredPagedBlocksPathParams;

export const useGetFilteredPagedBlocks = ({skip, take, ...props}: UseGetFilteredPagedBlocksProps) => useMutate<Block[], void, void, BlockFilter, GetFilteredPagedBlocksPathParams>("POST", (paramsInPath: GetFilteredPagedBlocksPathParams) => `/api/Blocks/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetBlockByIdPathParams {
  id: number
}

export type GetBlockByIdProps = Omit<GetProps<Block, void, void, GetBlockByIdPathParams>, "path"> & GetBlockByIdPathParams;

export const GetBlockById = ({id, ...props}: GetBlockByIdProps) => (
  <Get<Block, void, void, GetBlockByIdPathParams>
    path={`/api/Blocks/${id}`}
    
    {...props}
  />
);

export type UseGetBlockByIdProps = Omit<UseGetProps<Block, void, void, GetBlockByIdPathParams>, "path"> & GetBlockByIdPathParams;

export const useGetBlockById = ({id, ...props}: UseGetBlockByIdProps) => useGet<Block, void, void, GetBlockByIdPathParams>((paramsInPath: GetBlockByIdPathParams) => `/api/Blocks/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutBlockPathParams {
  id: number
}

export type PutBlockProps = Omit<MutateProps<Block, void, void, Block, PutBlockPathParams>, "path" | "verb"> & PutBlockPathParams;

export const PutBlock = ({id, ...props}: PutBlockProps) => (
  <Mutate<Block, void, void, Block, PutBlockPathParams>
    verb="PUT"
    path={`/api/Blocks/${id}`}
    
    {...props}
  />
);

export type UsePutBlockProps = Omit<UseMutateProps<Block, void, void, Block, PutBlockPathParams>, "path" | "verb"> & PutBlockPathParams;

export const usePutBlock = ({id, ...props}: UsePutBlockProps) => useMutate<Block, void, void, Block, PutBlockPathParams>("PUT", (paramsInPath: PutBlockPathParams) => `/api/Blocks/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteBlockProps = Omit<MutateProps<Block, void, void, number, void>, "path" | "verb">;

export const DeleteBlock = (props: DeleteBlockProps) => (
  <Mutate<Block, void, void, number, void>
    verb="DELETE"
    path={`/api/Blocks`}
    
    {...props}
  />
);

export type UseDeleteBlockProps = Omit<UseMutateProps<Block, void, void, number, void>, "path" | "verb">;

export const useDeleteBlock = (props: UseDeleteBlockProps) => useMutate<Block, void, void, number, void>("DELETE", `/api/Blocks`, {   ...props });


export interface GetBlockCropsResponse {
  [key: string]: Crop;
}

export type GetBlockCropsProps = Omit<MutateProps<GetBlockCropsResponse, void, void, number[], void>, "path" | "verb">;

export const GetBlockCrops = (props: GetBlockCropsProps) => (
  <Mutate<GetBlockCropsResponse, void, void, number[], void>
    verb="POST"
    path={`/api/Blocks/GetBlockCrops`}
    
    {...props}
  />
);

export type UseGetBlockCropsProps = Omit<UseMutateProps<GetBlockCropsResponse, void, void, number[], void>, "path" | "verb">;

export const useGetBlockCrops = (props: UseGetBlockCropsProps) => useMutate<GetBlockCropsResponse, void, void, number[], void>("POST", `/api/Blocks/GetBlockCrops`, props);


export interface GetBlockCollectionCountPerServicePathParams {
  farmId: number
}

export type GetBlockCollectionCountPerServiceProps = Omit<GetProps<BlockCollectionCount[], void, void, GetBlockCollectionCountPerServicePathParams>, "path"> & GetBlockCollectionCountPerServicePathParams;

export const GetBlockCollectionCountPerService = ({farmId, ...props}: GetBlockCollectionCountPerServiceProps) => (
  <Get<BlockCollectionCount[], void, void, GetBlockCollectionCountPerServicePathParams>
    path={`/api/Blocks/GetBlockCollectionCountPerService/${farmId}`}
    
    {...props}
  />
);

export type UseGetBlockCollectionCountPerServiceProps = Omit<UseGetProps<BlockCollectionCount[], void, void, GetBlockCollectionCountPerServicePathParams>, "path"> & GetBlockCollectionCountPerServicePathParams;

export const useGetBlockCollectionCountPerService = ({farmId, ...props}: UseGetBlockCollectionCountPerServiceProps) => useGet<BlockCollectionCount[], void, void, GetBlockCollectionCountPerServicePathParams>((paramsInPath: GetBlockCollectionCountPerServicePathParams) => `/api/Blocks/GetBlockCollectionCountPerService/${paramsInPath.farmId}`, {  pathParams: { farmId }, ...props });


export interface GetFarmBlocksPathParams {
  farmId: number
}

export type GetFarmBlocksProps = Omit<GetProps<Block[], void, void, GetFarmBlocksPathParams>, "path"> & GetFarmBlocksPathParams;

export const GetFarmBlocks = ({farmId, ...props}: GetFarmBlocksProps) => (
  <Get<Block[], void, void, GetFarmBlocksPathParams>
    path={`/api/Blocks/GetFarmBlocks/${farmId}`}
    
    {...props}
  />
);

export type UseGetFarmBlocksProps = Omit<UseGetProps<Block[], void, void, GetFarmBlocksPathParams>, "path"> & GetFarmBlocksPathParams;

export const useGetFarmBlocks = ({farmId, ...props}: UseGetFarmBlocksProps) => useGet<Block[], void, void, GetFarmBlocksPathParams>((paramsInPath: GetFarmBlocksPathParams) => `/api/Blocks/GetFarmBlocks/${paramsInPath.farmId}`, {  pathParams: { farmId }, ...props });


export interface GetFarmBlocksWithYieldPathParams {
  farmId: number
}

export type GetFarmBlocksWithYieldProps = Omit<GetProps<Block[], void, void, GetFarmBlocksWithYieldPathParams>, "path"> & GetFarmBlocksWithYieldPathParams;

export const GetFarmBlocksWithYield = ({farmId, ...props}: GetFarmBlocksWithYieldProps) => (
  <Get<Block[], void, void, GetFarmBlocksWithYieldPathParams>
    path={`/api/Blocks/GetFarmBlocksWithYield/${farmId}`}
    
    {...props}
  />
);

export type UseGetFarmBlocksWithYieldProps = Omit<UseGetProps<Block[], void, void, GetFarmBlocksWithYieldPathParams>, "path"> & GetFarmBlocksWithYieldPathParams;

export const useGetFarmBlocksWithYield = ({farmId, ...props}: UseGetFarmBlocksWithYieldProps) => useGet<Block[], void, void, GetFarmBlocksWithYieldPathParams>((paramsInPath: GetFarmBlocksWithYieldPathParams) => `/api/Blocks/GetFarmBlocksWithYield/${paramsInPath.farmId}`, {  pathParams: { farmId }, ...props });


export interface GetFarmBlocksSummaryPathParams {
  farmId: number;
  pageNumber: number;
  pageSize: number
}

export type GetFarmBlocksSummaryProps = Omit<GetProps<BlockSummaryPage[], void, void, GetFarmBlocksSummaryPathParams>, "path"> & GetFarmBlocksSummaryPathParams;

export const GetFarmBlocksSummary = ({farmId, pageNumber, pageSize, ...props}: GetFarmBlocksSummaryProps) => (
  <Get<BlockSummaryPage[], void, void, GetFarmBlocksSummaryPathParams>
    path={`/api/Blocks/GetPagedFarmBlocksSummary/${farmId}/${pageNumber}/${pageSize}`}
    
    {...props}
  />
);

export type UseGetFarmBlocksSummaryProps = Omit<UseGetProps<BlockSummaryPage[], void, void, GetFarmBlocksSummaryPathParams>, "path"> & GetFarmBlocksSummaryPathParams;

export const useGetFarmBlocksSummary = ({farmId, pageNumber, pageSize, ...props}: UseGetFarmBlocksSummaryProps) => useGet<BlockSummaryPage[], void, void, GetFarmBlocksSummaryPathParams>((paramsInPath: GetFarmBlocksSummaryPathParams) => `/api/Blocks/GetPagedFarmBlocksSummary/${paramsInPath.farmId}/${paramsInPath.pageNumber}/${paramsInPath.pageSize}`, {  pathParams: { farmId, pageNumber, pageSize }, ...props });


export interface GetPagedBlocksSummaryWithCountsPathParams {
  farmId: number;
  pageNumber: number;
  pageSize: number
}

export type GetPagedBlocksSummaryWithCountsProps = Omit<GetProps<BlockSummaryPage, void, void, GetPagedBlocksSummaryWithCountsPathParams>, "path"> & GetPagedBlocksSummaryWithCountsPathParams;

export const GetPagedBlocksSummaryWithCounts = ({farmId, pageNumber, pageSize, ...props}: GetPagedBlocksSummaryWithCountsProps) => (
  <Get<BlockSummaryPage, void, void, GetPagedBlocksSummaryWithCountsPathParams>
    path={`/api/Blocks/GetPagedFarmBlocksSummaryWithCounts/${farmId}/${pageNumber}/${pageSize}`}
    
    {...props}
  />
);

export type UseGetPagedBlocksSummaryWithCountsProps = Omit<UseGetProps<BlockSummaryPage, void, void, GetPagedBlocksSummaryWithCountsPathParams>, "path"> & GetPagedBlocksSummaryWithCountsPathParams;

export const useGetPagedBlocksSummaryWithCounts = ({farmId, pageNumber, pageSize, ...props}: UseGetPagedBlocksSummaryWithCountsProps) => useGet<BlockSummaryPage, void, void, GetPagedBlocksSummaryWithCountsPathParams>((paramsInPath: GetPagedBlocksSummaryWithCountsPathParams) => `/api/Blocks/GetPagedFarmBlocksSummaryWithCounts/${paramsInPath.farmId}/${paramsInPath.pageNumber}/${paramsInPath.pageSize}`, {  pathParams: { farmId, pageNumber, pageSize }, ...props });


export interface GetPagedFarmBlocksSummaryByMorphologyPathParams {
  farmId: number;
  blockId: number;
  pageSize: number
}

export type GetPagedFarmBlocksSummaryByMorphologyProps = Omit<GetProps<BlockSummaryPage, void, void, GetPagedFarmBlocksSummaryByMorphologyPathParams>, "path"> & GetPagedFarmBlocksSummaryByMorphologyPathParams;

export const GetPagedFarmBlocksSummaryByMorphology = ({farmId, blockId, pageSize, ...props}: GetPagedFarmBlocksSummaryByMorphologyProps) => (
  <Get<BlockSummaryPage, void, void, GetPagedFarmBlocksSummaryByMorphologyPathParams>
    path={`/api/Blocks/GetPagedFarmBlocksSummary/${farmId}/Block/${blockId}/${pageSize}`}
    
    {...props}
  />
);

export type UseGetPagedFarmBlocksSummaryByMorphologyProps = Omit<UseGetProps<BlockSummaryPage, void, void, GetPagedFarmBlocksSummaryByMorphologyPathParams>, "path"> & GetPagedFarmBlocksSummaryByMorphologyPathParams;

export const useGetPagedFarmBlocksSummaryByMorphology = ({farmId, blockId, pageSize, ...props}: UseGetPagedFarmBlocksSummaryByMorphologyProps) => useGet<BlockSummaryPage, void, void, GetPagedFarmBlocksSummaryByMorphologyPathParams>((paramsInPath: GetPagedFarmBlocksSummaryByMorphologyPathParams) => `/api/Blocks/GetPagedFarmBlocksSummary/${paramsInPath.farmId}/Block/${paramsInPath.blockId}/${paramsInPath.pageSize}`, {  pathParams: { farmId, blockId, pageSize }, ...props });


export interface GetBlockGeoJsonPathParams {
  collectionUuid: string
}

export type GetBlockGeoJsonProps = Omit<GetProps<string, void, void, GetBlockGeoJsonPathParams>, "path"> & GetBlockGeoJsonPathParams;

export const GetBlockGeoJson = ({collectionUuid, ...props}: GetBlockGeoJsonProps) => (
  <Get<string, void, void, GetBlockGeoJsonPathParams>
    path={`/api/Blocks/GetBlockGeoJson/${collectionUuid}`}
    
    {...props}
  />
);

export type UseGetBlockGeoJsonProps = Omit<UseGetProps<string, void, void, GetBlockGeoJsonPathParams>, "path"> & GetBlockGeoJsonPathParams;

export const useGetBlockGeoJson = ({collectionUuid, ...props}: UseGetBlockGeoJsonProps) => useGet<string, void, void, GetBlockGeoJsonPathParams>((paramsInPath: GetBlockGeoJsonPathParams) => `/api/Blocks/GetBlockGeoJson/${paramsInPath.collectionUuid}`, {  pathParams: { collectionUuid }, ...props });


export interface GetBlockFarmPinInfoPathParams {
  id: number
}

export type GetBlockFarmPinInfoProps = Omit<GetProps<MapPin, void, void, GetBlockFarmPinInfoPathParams>, "path"> & GetBlockFarmPinInfoPathParams;

export const GetBlockFarmPinInfo = ({id, ...props}: GetBlockFarmPinInfoProps) => (
  <Get<MapPin, void, void, GetBlockFarmPinInfoPathParams>
    path={`/api/Blocks/GetBlockFarmPinInfo/${id}`}
    
    {...props}
  />
);

export type UseGetBlockFarmPinInfoProps = Omit<UseGetProps<MapPin, void, void, GetBlockFarmPinInfoPathParams>, "path"> & GetBlockFarmPinInfoPathParams;

export const useGetBlockFarmPinInfo = ({id, ...props}: UseGetBlockFarmPinInfoProps) => useGet<MapPin, void, void, GetBlockFarmPinInfoPathParams>((paramsInPath: GetBlockFarmPinInfoPathParams) => `/api/Blocks/GetBlockFarmPinInfo/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetBlockRankingsPathParams {
  id: number
}

export type GetBlockRankingsProps = Omit<GetProps<BlockRanking, void, void, GetBlockRankingsPathParams>, "path"> & GetBlockRankingsPathParams;

export const GetBlockRankings = ({id, ...props}: GetBlockRankingsProps) => (
  <Get<BlockRanking, void, void, GetBlockRankingsPathParams>
    path={`/api/Blocks/GetBlockRankings/${id}`}
    
    {...props}
  />
);

export type UseGetBlockRankingsProps = Omit<UseGetProps<BlockRanking, void, void, GetBlockRankingsPathParams>, "path"> & GetBlockRankingsPathParams;

export const useGetBlockRankings = ({id, ...props}: UseGetBlockRankingsProps) => useGet<BlockRanking, void, void, GetBlockRankingsPathParams>((paramsInPath: GetBlockRankingsPathParams) => `/api/Blocks/GetBlockRankings/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetCapturesProps = Omit<GetProps<Capture[], void, void, void>, "path">;

export const GetCaptures = (props: GetCapturesProps) => (
  <Get<Capture[], void, void, void>
    path={`/api/Capture`}
    
    {...props}
  />
);

export type UseGetCapturesProps = Omit<UseGetProps<Capture[], void, void, void>, "path">;

export const useGetCaptures = (props: UseGetCapturesProps) => useGet<Capture[], void, void, void>(`/api/Capture`, props);


export type PostCaptureProps = Omit<MutateProps<Capture, void, void, Capture, void>, "path" | "verb">;

export const PostCapture = (props: PostCaptureProps) => (
  <Mutate<Capture, void, void, Capture, void>
    verb="POST"
    path={`/api/Capture`}
    
    {...props}
  />
);

export type UsePostCaptureProps = Omit<UseMutateProps<Capture, void, void, Capture, void>, "path" | "verb">;

export const usePostCapture = (props: UsePostCaptureProps) => useMutate<Capture, void, void, Capture, void>("POST", `/api/Capture`, props);


export type BulkDeleteCaptureProps = Omit<MutateProps<void, void, void, number[], void>, "path" | "verb">;

export const BulkDeleteCapture = (props: BulkDeleteCaptureProps) => (
  <Mutate<void, void, void, number[], void>
    verb="DELETE"
    path={`/api/Capture`}
    
    {...props}
  />
);

export type UseBulkDeleteCaptureProps = Omit<UseMutateProps<void, void, void, number[], void>, "path" | "verb">;

export const useBulkDeleteCapture = (props: UseBulkDeleteCaptureProps) => useMutate<void, void, void, number[], void>("DELETE", `/api/Capture`, {   ...props });


export interface GetPagedCapturesPathParams {
  skip: number;
  take: number
}

export type GetPagedCapturesProps = Omit<GetProps<Capture[], void, void, GetPagedCapturesPathParams>, "path"> & GetPagedCapturesPathParams;

export const GetPagedCaptures = ({skip, take, ...props}: GetPagedCapturesProps) => (
  <Get<Capture[], void, void, GetPagedCapturesPathParams>
    path={`/api/Capture/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetPagedCapturesProps = Omit<UseGetProps<Capture[], void, void, GetPagedCapturesPathParams>, "path"> & GetPagedCapturesPathParams;

export const useGetPagedCaptures = ({skip, take, ...props}: UseGetPagedCapturesProps) => useGet<Capture[], void, void, GetPagedCapturesPathParams>((paramsInPath: GetPagedCapturesPathParams) => `/api/Capture/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetFilteredPageCapturesPathParams {
  skip: number;
  take: number
}

export type GetFilteredPageCapturesProps = Omit<MutateProps<Capture[], void, void, CaptureFilter, GetFilteredPageCapturesPathParams>, "path" | "verb"> & GetFilteredPageCapturesPathParams;

export const GetFilteredPageCaptures = ({skip, take, ...props}: GetFilteredPageCapturesProps) => (
  <Mutate<Capture[], void, void, CaptureFilter, GetFilteredPageCapturesPathParams>
    verb="POST"
    path={`/api/Capture/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredPageCapturesProps = Omit<UseMutateProps<Capture[], void, void, CaptureFilter, GetFilteredPageCapturesPathParams>, "path" | "verb"> & GetFilteredPageCapturesPathParams;

export const useGetFilteredPageCaptures = ({skip, take, ...props}: UseGetFilteredPageCapturesProps) => useMutate<Capture[], void, void, CaptureFilter, GetFilteredPageCapturesPathParams>("POST", (paramsInPath: GetFilteredPageCapturesPathParams) => `/api/Capture/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetCaptureByIdPathParams {
  id: number
}

export type GetCaptureByIdProps = Omit<GetProps<Capture, void, void, GetCaptureByIdPathParams>, "path"> & GetCaptureByIdPathParams;

export const GetCaptureById = ({id, ...props}: GetCaptureByIdProps) => (
  <Get<Capture, void, void, GetCaptureByIdPathParams>
    path={`/api/Capture/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureByIdProps = Omit<UseGetProps<Capture, void, void, GetCaptureByIdPathParams>, "path"> & GetCaptureByIdPathParams;

export const useGetCaptureById = ({id, ...props}: UseGetCaptureByIdProps) => useGet<Capture, void, void, GetCaptureByIdPathParams>((paramsInPath: GetCaptureByIdPathParams) => `/api/Capture/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCaptureByIdPathParams {
  id: number
}

export type PutCaptureByIdProps = Omit<MutateProps<Capture, void, void, Capture, PutCaptureByIdPathParams>, "path" | "verb"> & PutCaptureByIdPathParams;

export const PutCaptureById = ({id, ...props}: PutCaptureByIdProps) => (
  <Mutate<Capture, void, void, Capture, PutCaptureByIdPathParams>
    verb="PUT"
    path={`/api/Capture/${id}`}
    
    {...props}
  />
);

export type UsePutCaptureByIdProps = Omit<UseMutateProps<Capture, void, void, Capture, PutCaptureByIdPathParams>, "path" | "verb"> & PutCaptureByIdPathParams;

export const usePutCaptureById = ({id, ...props}: UsePutCaptureByIdProps) => useMutate<Capture, void, void, Capture, PutCaptureByIdPathParams>("PUT", (paramsInPath: PutCaptureByIdPathParams) => `/api/Capture/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetCaptureByUuidPathParams {
  uuid: string
}

export type GetCaptureByUuidProps = Omit<GetProps<Capture[], void, void, GetCaptureByUuidPathParams>, "path"> & GetCaptureByUuidPathParams;

export const GetCaptureByUuid = ({uuid, ...props}: GetCaptureByUuidProps) => (
  <Get<Capture[], void, void, GetCaptureByUuidPathParams>
    path={`/api/Capture/Uuid/${uuid}`}
    
    {...props}
  />
);

export type UseGetCaptureByUuidProps = Omit<UseGetProps<Capture[], void, void, GetCaptureByUuidPathParams>, "path"> & GetCaptureByUuidPathParams;

export const useGetCaptureByUuid = ({uuid, ...props}: UseGetCaptureByUuidProps) => useGet<Capture[], void, void, GetCaptureByUuidPathParams>((paramsInPath: GetCaptureByUuidPathParams) => `/api/Capture/Uuid/${paramsInPath.uuid}`, {  pathParams: { uuid }, ...props });


export interface CaptureUploadedPathParams {
  captureId: number
}

export type CaptureUploadedProps = Omit<MutateProps<Capture, void, void, void, CaptureUploadedPathParams>, "path" | "verb"> & CaptureUploadedPathParams;

export const CaptureUploaded = ({captureId, ...props}: CaptureUploadedProps) => (
  <Mutate<Capture, void, void, void, CaptureUploadedPathParams>
    verb="POST"
    path={`/api/Capture/${captureId}/Uploaded`}
    
    {...props}
  />
);

export type UseCaptureUploadedProps = Omit<UseMutateProps<Capture, void, void, void, CaptureUploadedPathParams>, "path" | "verb"> & CaptureUploadedPathParams;

export const useCaptureUploaded = ({captureId, ...props}: UseCaptureUploadedProps) => useMutate<Capture, void, void, void, CaptureUploadedPathParams>("POST", (paramsInPath: CaptureUploadedPathParams) => `/api/Capture/${paramsInPath.captureId}/Uploaded`, {  pathParams: { captureId }, ...props });


export type PostCapturesProps = Omit<MutateProps<Capture[], void, void, Capture[], void>, "path" | "verb">;

export const PostCaptures = (props: PostCapturesProps) => (
  <Mutate<Capture[], void, void, Capture[], void>
    verb="POST"
    path={`/api/Capture/Bulk`}
    
    {...props}
  />
);

export type UsePostCapturesProps = Omit<UseMutateProps<Capture[], void, void, Capture[], void>, "path" | "verb">;

export const usePostCaptures = (props: UsePostCapturesProps) => useMutate<Capture[], void, void, Capture[], void>("POST", `/api/Capture/Bulk`, props);


export interface PostCaptureLowResJobPathParams {
  id: number
}

export type PostCaptureLowResJobProps = Omit<MutateProps<void, void, void, void, PostCaptureLowResJobPathParams>, "path" | "verb"> & PostCaptureLowResJobPathParams;

export const PostCaptureLowResJob = ({id, ...props}: PostCaptureLowResJobProps) => (
  <Mutate<void, void, void, void, PostCaptureLowResJobPathParams>
    verb="POST"
    path={`/api/Capture/${id}/LowRes`}
    
    {...props}
  />
);

export type UsePostCaptureLowResJobProps = Omit<UseMutateProps<void, void, void, void, PostCaptureLowResJobPathParams>, "path" | "verb"> & PostCaptureLowResJobPathParams;

export const usePostCaptureLowResJob = ({id, ...props}: UsePostCaptureLowResJobProps) => useMutate<void, void, void, void, PostCaptureLowResJobPathParams>("POST", (paramsInPath: PostCaptureLowResJobPathParams) => `/api/Capture/${paramsInPath.id}/LowRes`, {  pathParams: { id }, ...props });


export interface PostCaptureHighResJobPathParams {
  id: number
}

export type PostCaptureHighResJobProps = Omit<MutateProps<void, void, void, void, PostCaptureHighResJobPathParams>, "path" | "verb"> & PostCaptureHighResJobPathParams;

export const PostCaptureHighResJob = ({id, ...props}: PostCaptureHighResJobProps) => (
  <Mutate<void, void, void, void, PostCaptureHighResJobPathParams>
    verb="POST"
    path={`/api/Capture/${id}/HighRes`}
    
    {...props}
  />
);

export type UsePostCaptureHighResJobProps = Omit<UseMutateProps<void, void, void, void, PostCaptureHighResJobPathParams>, "path" | "verb"> & PostCaptureHighResJobPathParams;

export const usePostCaptureHighResJob = ({id, ...props}: UsePostCaptureHighResJobProps) => useMutate<void, void, void, void, PostCaptureHighResJobPathParams>("POST", (paramsInPath: PostCaptureHighResJobPathParams) => `/api/Capture/${paramsInPath.id}/HighRes`, {  pathParams: { id }, ...props });


export interface PostCaptureHighResWithMlModelJobPathParams {
  id: number;
  engineId: number;
  modelId: number
}

export type PostCaptureHighResWithMlModelJobProps = Omit<MutateProps<void, void, void, ParametersString, PostCaptureHighResWithMlModelJobPathParams>, "path" | "verb"> & PostCaptureHighResWithMlModelJobPathParams;

export const PostCaptureHighResWithMlModelJob = ({id, engineId, modelId, ...props}: PostCaptureHighResWithMlModelJobProps) => (
  <Mutate<void, void, void, ParametersString, PostCaptureHighResWithMlModelJobPathParams>
    verb="POST"
    path={`/api/Capture/${id}/HighRes/Engine/${engineId}/Model/${modelId}`}
    
    {...props}
  />
);

export type UsePostCaptureHighResWithMlModelJobProps = Omit<UseMutateProps<void, void, void, ParametersString, PostCaptureHighResWithMlModelJobPathParams>, "path" | "verb"> & PostCaptureHighResWithMlModelJobPathParams;

export const usePostCaptureHighResWithMlModelJob = ({id, engineId, modelId, ...props}: UsePostCaptureHighResWithMlModelJobProps) => useMutate<void, void, void, ParametersString, PostCaptureHighResWithMlModelJobPathParams>("POST", (paramsInPath: PostCaptureHighResWithMlModelJobPathParams) => `/api/Capture/${paramsInPath.id}/HighRes/Engine/${paramsInPath.engineId}/Model/${paramsInPath.modelId}`, {  pathParams: { id, engineId, modelId }, ...props });


export interface PostCaptureHighResWithNoModelPathParams {
  id: number;
  engineId: number
}

export type PostCaptureHighResWithNoModelProps = Omit<MutateProps<void, void, void, ParametersString, PostCaptureHighResWithNoModelPathParams>, "path" | "verb"> & PostCaptureHighResWithNoModelPathParams;

export const PostCaptureHighResWithNoModel = ({id, engineId, ...props}: PostCaptureHighResWithNoModelProps) => (
  <Mutate<void, void, void, ParametersString, PostCaptureHighResWithNoModelPathParams>
    verb="POST"
    path={`/api/Capture/${id}/HighRes/Engine/${engineId}`}
    
    {...props}
  />
);

export type UsePostCaptureHighResWithNoModelProps = Omit<UseMutateProps<void, void, void, ParametersString, PostCaptureHighResWithNoModelPathParams>, "path" | "verb"> & PostCaptureHighResWithNoModelPathParams;

export const usePostCaptureHighResWithNoModel = ({id, engineId, ...props}: UsePostCaptureHighResWithNoModelProps) => useMutate<void, void, void, ParametersString, PostCaptureHighResWithNoModelPathParams>("POST", (paramsInPath: PostCaptureHighResWithNoModelPathParams) => `/api/Capture/${paramsInPath.id}/HighRes/Engine/${paramsInPath.engineId}`, {  pathParams: { id, engineId }, ...props });


export type DeleteCaptureProps = Omit<MutateProps<Capture, void, void, number, void>, "path" | "verb">;

export const DeleteCapture = (props: DeleteCaptureProps) => (
  <Mutate<Capture, void, void, number, void>
    verb="DELETE"
    path={`/api/Capture`}
    
    {...props}
  />
);

export type UseDeleteCaptureProps = Omit<UseMutateProps<Capture, void, void, number, void>, "path" | "verb">;

export const useDeleteCapture = (props: UseDeleteCaptureProps) => useMutate<Capture, void, void, number, void>("DELETE", `/api/Capture`, {   ...props });


export type BulkRecoverCaptureProps = Omit<MutateProps<void, void, void, number[], void>, "path" | "verb">;

export const BulkRecoverCapture = (props: BulkRecoverCaptureProps) => (
  <Mutate<void, void, void, number[], void>
    verb="POST"
    path={`/api/Capture/Recover`}
    
    {...props}
  />
);

export type UseBulkRecoverCaptureProps = Omit<UseMutateProps<void, void, void, number[], void>, "path" | "verb">;

export const useBulkRecoverCapture = (props: UseBulkRecoverCaptureProps) => useMutate<void, void, void, number[], void>("POST", `/api/Capture/Recover`, props);


export type CloneCapturesProps = Omit<MutateProps<void, void, void, CaptureCloneRequest, void>, "path" | "verb">;

export const CloneCaptures = (props: CloneCapturesProps) => (
  <Mutate<void, void, void, CaptureCloneRequest, void>
    verb="POST"
    path={`/api/Capture/Clone`}
    
    {...props}
  />
);

export type UseCloneCapturesProps = Omit<UseMutateProps<void, void, void, CaptureCloneRequest, void>, "path" | "verb">;

export const useCloneCaptures = (props: UseCloneCapturesProps) => useMutate<void, void, void, CaptureCloneRequest, void>("POST", `/api/Capture/Clone`, props);


export type GetCaptureCustomAttributesProps = Omit<GetProps<CaptureCustomAttribute[], void, void, void>, "path">;

export const GetCaptureCustomAttributes = (props: GetCaptureCustomAttributesProps) => (
  <Get<CaptureCustomAttribute[], void, void, void>
    path={`/api/CaptureCustomAttribute`}
    
    {...props}
  />
);

export type UseGetCaptureCustomAttributesProps = Omit<UseGetProps<CaptureCustomAttribute[], void, void, void>, "path">;

export const useGetCaptureCustomAttributes = (props: UseGetCaptureCustomAttributesProps) => useGet<CaptureCustomAttribute[], void, void, void>(`/api/CaptureCustomAttribute`, props);


export type PostCaptureCustomAttributeProps = Omit<MutateProps<CaptureCustomAttribute, void, void, CaptureCustomAttribute, void>, "path" | "verb">;

export const PostCaptureCustomAttribute = (props: PostCaptureCustomAttributeProps) => (
  <Mutate<CaptureCustomAttribute, void, void, CaptureCustomAttribute, void>
    verb="POST"
    path={`/api/CaptureCustomAttribute`}
    
    {...props}
  />
);

export type UsePostCaptureCustomAttributeProps = Omit<UseMutateProps<CaptureCustomAttribute, void, void, CaptureCustomAttribute, void>, "path" | "verb">;

export const usePostCaptureCustomAttribute = (props: UsePostCaptureCustomAttributeProps) => useMutate<CaptureCustomAttribute, void, void, CaptureCustomAttribute, void>("POST", `/api/CaptureCustomAttribute`, props);


export interface GetCaptureCustomAttributeByIdPathParams {
  id: number
}

export type GetCaptureCustomAttributeByIdProps = Omit<GetProps<CaptureCustomAttribute, void, void, GetCaptureCustomAttributeByIdPathParams>, "path"> & GetCaptureCustomAttributeByIdPathParams;

export const GetCaptureCustomAttributeById = ({id, ...props}: GetCaptureCustomAttributeByIdProps) => (
  <Get<CaptureCustomAttribute, void, void, GetCaptureCustomAttributeByIdPathParams>
    path={`/api/CaptureCustomAttribute/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureCustomAttributeByIdProps = Omit<UseGetProps<CaptureCustomAttribute, void, void, GetCaptureCustomAttributeByIdPathParams>, "path"> & GetCaptureCustomAttributeByIdPathParams;

export const useGetCaptureCustomAttributeById = ({id, ...props}: UseGetCaptureCustomAttributeByIdProps) => useGet<CaptureCustomAttribute, void, void, GetCaptureCustomAttributeByIdPathParams>((paramsInPath: GetCaptureCustomAttributeByIdPathParams) => `/api/CaptureCustomAttribute/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCaptureCustomAttributeByIdPathParams {
  id: number
}

export type PutCaptureCustomAttributeByIdProps = Omit<MutateProps<CaptureCustomAttribute, void, void, CaptureCustomAttribute, PutCaptureCustomAttributeByIdPathParams>, "path" | "verb"> & PutCaptureCustomAttributeByIdPathParams;

export const PutCaptureCustomAttributeById = ({id, ...props}: PutCaptureCustomAttributeByIdProps) => (
  <Mutate<CaptureCustomAttribute, void, void, CaptureCustomAttribute, PutCaptureCustomAttributeByIdPathParams>
    verb="PUT"
    path={`/api/CaptureCustomAttribute/${id}`}
    
    {...props}
  />
);

export type UsePutCaptureCustomAttributeByIdProps = Omit<UseMutateProps<CaptureCustomAttribute, void, void, CaptureCustomAttribute, PutCaptureCustomAttributeByIdPathParams>, "path" | "verb"> & PutCaptureCustomAttributeByIdPathParams;

export const usePutCaptureCustomAttributeById = ({id, ...props}: UsePutCaptureCustomAttributeByIdProps) => useMutate<CaptureCustomAttribute, void, void, CaptureCustomAttribute, PutCaptureCustomAttributeByIdPathParams>("PUT", (paramsInPath: PutCaptureCustomAttributeByIdPathParams) => `/api/CaptureCustomAttribute/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteCaptureCustomAttributeProps = Omit<MutateProps<CaptureCustomAttribute, void, void, number, void>, "path" | "verb">;

export const DeleteCaptureCustomAttribute = (props: DeleteCaptureCustomAttributeProps) => (
  <Mutate<CaptureCustomAttribute, void, void, number, void>
    verb="DELETE"
    path={`/api/CaptureCustomAttribute`}
    
    {...props}
  />
);

export type UseDeleteCaptureCustomAttributeProps = Omit<UseMutateProps<CaptureCustomAttribute, void, void, number, void>, "path" | "verb">;

export const useDeleteCaptureCustomAttribute = (props: UseDeleteCaptureCustomAttributeProps) => useMutate<CaptureCustomAttribute, void, void, number, void>("DELETE", `/api/CaptureCustomAttribute`, {   ...props });


export interface GetCaptureCustomAttributesByCaptureIdPathParams {
  id: number
}

export type GetCaptureCustomAttributesByCaptureIdProps = Omit<GetProps<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByCaptureIdPathParams>, "path"> & GetCaptureCustomAttributesByCaptureIdPathParams;

export const GetCaptureCustomAttributesByCaptureId = ({id, ...props}: GetCaptureCustomAttributesByCaptureIdProps) => (
  <Get<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByCaptureIdPathParams>
    path={`/api/CaptureCustomAttribute/Capture/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureCustomAttributesByCaptureIdProps = Omit<UseGetProps<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByCaptureIdPathParams>, "path"> & GetCaptureCustomAttributesByCaptureIdPathParams;

export const useGetCaptureCustomAttributesByCaptureId = ({id, ...props}: UseGetCaptureCustomAttributesByCaptureIdProps) => useGet<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByCaptureIdPathParams>((paramsInPath: GetCaptureCustomAttributesByCaptureIdPathParams) => `/api/CaptureCustomAttribute/Capture/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetCaptureCustomAttributesByCaptureExtractionJobIdPathParams {
  id: number
}

export type GetCaptureCustomAttributesByCaptureExtractionJobIdProps = Omit<GetProps<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByCaptureExtractionJobIdPathParams>, "path"> & GetCaptureCustomAttributesByCaptureExtractionJobIdPathParams;

export const GetCaptureCustomAttributesByCaptureExtractionJobId = ({id, ...props}: GetCaptureCustomAttributesByCaptureExtractionJobIdProps) => (
  <Get<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByCaptureExtractionJobIdPathParams>
    path={`/api/CaptureCustomAttribute/CaptureExtractionJob/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureCustomAttributesByCaptureExtractionJobIdProps = Omit<UseGetProps<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByCaptureExtractionJobIdPathParams>, "path"> & GetCaptureCustomAttributesByCaptureExtractionJobIdPathParams;

export const useGetCaptureCustomAttributesByCaptureExtractionJobId = ({id, ...props}: UseGetCaptureCustomAttributesByCaptureExtractionJobIdProps) => useGet<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByCaptureExtractionJobIdPathParams>((paramsInPath: GetCaptureCustomAttributesByCaptureExtractionJobIdPathParams) => `/api/CaptureCustomAttribute/CaptureExtractionJob/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetCaptureCustomAttributesByAnalyticRequestIdPathParams {
  id: number
}

export type GetCaptureCustomAttributesByAnalyticRequestIdProps = Omit<GetProps<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByAnalyticRequestIdPathParams>, "path"> & GetCaptureCustomAttributesByAnalyticRequestIdPathParams;

export const GetCaptureCustomAttributesByAnalyticRequestId = ({id, ...props}: GetCaptureCustomAttributesByAnalyticRequestIdProps) => (
  <Get<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByAnalyticRequestIdPathParams>
    path={`/api/CaptureCustomAttribute/AnalyticRequest/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureCustomAttributesByAnalyticRequestIdProps = Omit<UseGetProps<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByAnalyticRequestIdPathParams>, "path"> & GetCaptureCustomAttributesByAnalyticRequestIdPathParams;

export const useGetCaptureCustomAttributesByAnalyticRequestId = ({id, ...props}: UseGetCaptureCustomAttributesByAnalyticRequestIdProps) => useGet<CaptureCustomAttribute[], void, void, GetCaptureCustomAttributesByAnalyticRequestIdPathParams>((paramsInPath: GetCaptureCustomAttributesByAnalyticRequestIdPathParams) => `/api/CaptureCustomAttribute/AnalyticRequest/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetCaptureExtractionJobsProps = Omit<GetProps<CaptureExtractionJob[], void, void, void>, "path">;

export const GetCaptureExtractionJobs = (props: GetCaptureExtractionJobsProps) => (
  <Get<CaptureExtractionJob[], void, void, void>
    path={`/api/CaptureExtractionJobs`}
    
    {...props}
  />
);

export type UseGetCaptureExtractionJobsProps = Omit<UseGetProps<CaptureExtractionJob[], void, void, void>, "path">;

export const useGetCaptureExtractionJobs = (props: UseGetCaptureExtractionJobsProps) => useGet<CaptureExtractionJob[], void, void, void>(`/api/CaptureExtractionJobs`, props);


export interface GetCaptureExtractionJobsByIdPathParams {
  id: number
}

export type GetCaptureExtractionJobsByIdProps = Omit<GetProps<CaptureExtractionJob, void, void, GetCaptureExtractionJobsByIdPathParams>, "path"> & GetCaptureExtractionJobsByIdPathParams;

export const GetCaptureExtractionJobsById = ({id, ...props}: GetCaptureExtractionJobsByIdProps) => (
  <Get<CaptureExtractionJob, void, void, GetCaptureExtractionJobsByIdPathParams>
    path={`/api/CaptureExtractionJobs/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureExtractionJobsByIdProps = Omit<UseGetProps<CaptureExtractionJob, void, void, GetCaptureExtractionJobsByIdPathParams>, "path"> & GetCaptureExtractionJobsByIdPathParams;

export const useGetCaptureExtractionJobsById = ({id, ...props}: UseGetCaptureExtractionJobsByIdProps) => useGet<CaptureExtractionJob, void, void, GetCaptureExtractionJobsByIdPathParams>((paramsInPath: GetCaptureExtractionJobsByIdPathParams) => `/api/CaptureExtractionJobs/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCaptureExtractionJobByIdPathParams {
  id: number
}

export type PutCaptureExtractionJobByIdProps = Omit<MutateProps<CaptureExtractionJob, void, void, CaptureExtractionJob, PutCaptureExtractionJobByIdPathParams>, "path" | "verb"> & PutCaptureExtractionJobByIdPathParams;

export const PutCaptureExtractionJobById = ({id, ...props}: PutCaptureExtractionJobByIdProps) => (
  <Mutate<CaptureExtractionJob, void, void, CaptureExtractionJob, PutCaptureExtractionJobByIdPathParams>
    verb="PUT"
    path={`/api/CaptureExtractionJobs/${id}`}
    
    {...props}
  />
);

export type UsePutCaptureExtractionJobByIdProps = Omit<UseMutateProps<CaptureExtractionJob, void, void, CaptureExtractionJob, PutCaptureExtractionJobByIdPathParams>, "path" | "verb"> & PutCaptureExtractionJobByIdPathParams;

export const usePutCaptureExtractionJobById = ({id, ...props}: UsePutCaptureExtractionJobByIdProps) => useMutate<CaptureExtractionJob, void, void, CaptureExtractionJob, PutCaptureExtractionJobByIdPathParams>("PUT", (paramsInPath: PutCaptureExtractionJobByIdPathParams) => `/api/CaptureExtractionJobs/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetPagedCaptureExtractionJobsPathParams {
  skip: number;
  take: number
}

export type GetPagedCaptureExtractionJobsProps = Omit<GetProps<CaptureExtractionJob[], void, void, GetPagedCaptureExtractionJobsPathParams>, "path"> & GetPagedCaptureExtractionJobsPathParams;

export const GetPagedCaptureExtractionJobs = ({skip, take, ...props}: GetPagedCaptureExtractionJobsProps) => (
  <Get<CaptureExtractionJob[], void, void, GetPagedCaptureExtractionJobsPathParams>
    path={`/api/CaptureExtractionJobs/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetPagedCaptureExtractionJobsProps = Omit<UseGetProps<CaptureExtractionJob[], void, void, GetPagedCaptureExtractionJobsPathParams>, "path"> & GetPagedCaptureExtractionJobsPathParams;

export const useGetPagedCaptureExtractionJobs = ({skip, take, ...props}: UseGetPagedCaptureExtractionJobsProps) => useGet<CaptureExtractionJob[], void, void, GetPagedCaptureExtractionJobsPathParams>((paramsInPath: GetPagedCaptureExtractionJobsPathParams) => `/api/CaptureExtractionJobs/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetCaptureExtractionJobsByCaptureIdPathParams {
  captureId: number
}

export type GetCaptureExtractionJobsByCaptureIdProps = Omit<GetProps<CaptureExtractionJob[], void, void, GetCaptureExtractionJobsByCaptureIdPathParams>, "path"> & GetCaptureExtractionJobsByCaptureIdPathParams;

export const GetCaptureExtractionJobsByCaptureId = ({captureId, ...props}: GetCaptureExtractionJobsByCaptureIdProps) => (
  <Get<CaptureExtractionJob[], void, void, GetCaptureExtractionJobsByCaptureIdPathParams>
    path={`/api/CaptureExtractionJobs/Capture/${captureId}`}
    
    {...props}
  />
);

export type UseGetCaptureExtractionJobsByCaptureIdProps = Omit<UseGetProps<CaptureExtractionJob[], void, void, GetCaptureExtractionJobsByCaptureIdPathParams>, "path"> & GetCaptureExtractionJobsByCaptureIdPathParams;

export const useGetCaptureExtractionJobsByCaptureId = ({captureId, ...props}: UseGetCaptureExtractionJobsByCaptureIdProps) => useGet<CaptureExtractionJob[], void, void, GetCaptureExtractionJobsByCaptureIdPathParams>((paramsInPath: GetCaptureExtractionJobsByCaptureIdPathParams) => `/api/CaptureExtractionJobs/Capture/${paramsInPath.captureId}`, {  pathParams: { captureId }, ...props });


export interface GetCaptureExtractionJobsByCaptureJobIdPathParams {
  captureJobId: number
}

export type GetCaptureExtractionJobsByCaptureJobIdProps = Omit<GetProps<CaptureExtractionJob[], void, void, GetCaptureExtractionJobsByCaptureJobIdPathParams>, "path"> & GetCaptureExtractionJobsByCaptureJobIdPathParams;

export const GetCaptureExtractionJobsByCaptureJobId = ({captureJobId, ...props}: GetCaptureExtractionJobsByCaptureJobIdProps) => (
  <Get<CaptureExtractionJob[], void, void, GetCaptureExtractionJobsByCaptureJobIdPathParams>
    path={`/api/CaptureExtractionJobs/CaptureJob/${captureJobId}`}
    
    {...props}
  />
);

export type UseGetCaptureExtractionJobsByCaptureJobIdProps = Omit<UseGetProps<CaptureExtractionJob[], void, void, GetCaptureExtractionJobsByCaptureJobIdPathParams>, "path"> & GetCaptureExtractionJobsByCaptureJobIdPathParams;

export const useGetCaptureExtractionJobsByCaptureJobId = ({captureJobId, ...props}: UseGetCaptureExtractionJobsByCaptureJobIdProps) => useGet<CaptureExtractionJob[], void, void, GetCaptureExtractionJobsByCaptureJobIdPathParams>((paramsInPath: GetCaptureExtractionJobsByCaptureJobIdPathParams) => `/api/CaptureExtractionJobs/CaptureJob/${paramsInPath.captureJobId}`, {  pathParams: { captureJobId }, ...props });


export type GetCapturesWithExtractionJobsProps = Omit<GetProps<CaptureExtractionJobVM[], void, void, void>, "path">;

export const GetCapturesWithExtractionJobs = (props: GetCapturesWithExtractionJobsProps) => (
  <Get<CaptureExtractionJobVM[], void, void, void>
    path={`/api/CaptureExtractionJobs/Capture`}
    
    {...props}
  />
);

export type UseGetCapturesWithExtractionJobsProps = Omit<UseGetProps<CaptureExtractionJobVM[], void, void, void>, "path">;

export const useGetCapturesWithExtractionJobs = (props: UseGetCapturesWithExtractionJobsProps) => useGet<CaptureExtractionJobVM[], void, void, void>(`/api/CaptureExtractionJobs/Capture`, props);


export interface GetPagedCapturesWithExtractionJobsPathParams {
  skip: number;
  take: number
}

export type GetPagedCapturesWithExtractionJobsProps = Omit<GetProps<CaptureExtractionJobVM[], void, void, GetPagedCapturesWithExtractionJobsPathParams>, "path"> & GetPagedCapturesWithExtractionJobsPathParams;

export const GetPagedCapturesWithExtractionJobs = ({skip, take, ...props}: GetPagedCapturesWithExtractionJobsProps) => (
  <Get<CaptureExtractionJobVM[], void, void, GetPagedCapturesWithExtractionJobsPathParams>
    path={`/api/CaptureExtractionJobs/Capture/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetPagedCapturesWithExtractionJobsProps = Omit<UseGetProps<CaptureExtractionJobVM[], void, void, GetPagedCapturesWithExtractionJobsPathParams>, "path"> & GetPagedCapturesWithExtractionJobsPathParams;

export const useGetPagedCapturesWithExtractionJobs = ({skip, take, ...props}: UseGetPagedCapturesWithExtractionJobsProps) => useGet<CaptureExtractionJobVM[], void, void, GetPagedCapturesWithExtractionJobsPathParams>((paramsInPath: GetPagedCapturesWithExtractionJobsPathParams) => `/api/CaptureExtractionJobs/Capture/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetFilteredPagedCapturesWithExtractionJobsPathParams {
  skip: number;
  take: number
}

export type GetFilteredPagedCapturesWithExtractionJobsProps = Omit<MutateProps<CaptureExtractionJobVM[], void, void, CaptureExtractionJobFilter, GetFilteredPagedCapturesWithExtractionJobsPathParams>, "path" | "verb"> & GetFilteredPagedCapturesWithExtractionJobsPathParams;

export const GetFilteredPagedCapturesWithExtractionJobs = ({skip, take, ...props}: GetFilteredPagedCapturesWithExtractionJobsProps) => (
  <Mutate<CaptureExtractionJobVM[], void, void, CaptureExtractionJobFilter, GetFilteredPagedCapturesWithExtractionJobsPathParams>
    verb="POST"
    path={`/api/CaptureExtractionJobs/Capture/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredPagedCapturesWithExtractionJobsProps = Omit<UseMutateProps<CaptureExtractionJobVM[], void, void, CaptureExtractionJobFilter, GetFilteredPagedCapturesWithExtractionJobsPathParams>, "path" | "verb"> & GetFilteredPagedCapturesWithExtractionJobsPathParams;

export const useGetFilteredPagedCapturesWithExtractionJobs = ({skip, take, ...props}: UseGetFilteredPagedCapturesWithExtractionJobsProps) => useMutate<CaptureExtractionJobVM[], void, void, CaptureExtractionJobFilter, GetFilteredPagedCapturesWithExtractionJobsPathParams>("POST", (paramsInPath: GetFilteredPagedCapturesWithExtractionJobsPathParams) => `/api/CaptureExtractionJobs/Capture/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface SubmitExtractionJobToPipelinePathParams {
  captureUuid: string;
  lowresUuid: string;
  highresUuid: string
}

export type SubmitExtractionJobToPipelineProps = Omit<MutateProps<boolean, void, void, void, SubmitExtractionJobToPipelinePathParams>, "path" | "verb"> & SubmitExtractionJobToPipelinePathParams;

export const SubmitExtractionJobToPipeline = ({captureUuid, lowresUuid, highresUuid, ...props}: SubmitExtractionJobToPipelineProps) => (
  <Mutate<boolean, void, void, void, SubmitExtractionJobToPipelinePathParams>
    verb="POST"
    path={`/api/CaptureExtractionJobs/Capture/${captureUuid}/lowres/${lowresUuid}/highres/${highresUuid}`}
    
    {...props}
  />
);

export type UseSubmitExtractionJobToPipelineProps = Omit<UseMutateProps<boolean, void, void, void, SubmitExtractionJobToPipelinePathParams>, "path" | "verb"> & SubmitExtractionJobToPipelinePathParams;

export const useSubmitExtractionJobToPipeline = ({captureUuid, lowresUuid, highresUuid, ...props}: UseSubmitExtractionJobToPipelineProps) => useMutate<boolean, void, void, void, SubmitExtractionJobToPipelinePathParams>("POST", (paramsInPath: SubmitExtractionJobToPipelinePathParams) => `/api/CaptureExtractionJobs/Capture/${paramsInPath.captureUuid}/lowres/${paramsInPath.lowresUuid}/highres/${paramsInPath.highresUuid}`, {  pathParams: { captureUuid, lowresUuid, highresUuid }, ...props });


export interface SubmitExtractionJobToPipelineByCaptureIdPathParams {
  captureId: number;
  lowresUuid: string;
  highresUuid: string
}

export type SubmitExtractionJobToPipelineByCaptureIdProps = Omit<MutateProps<boolean, void, void, void, SubmitExtractionJobToPipelineByCaptureIdPathParams>, "path" | "verb"> & SubmitExtractionJobToPipelineByCaptureIdPathParams;

export const SubmitExtractionJobToPipelineByCaptureId = ({captureId, lowresUuid, highresUuid, ...props}: SubmitExtractionJobToPipelineByCaptureIdProps) => (
  <Mutate<boolean, void, void, void, SubmitExtractionJobToPipelineByCaptureIdPathParams>
    verb="POST"
    path={`/api/CaptureExtractionJobs/CaptureId/${captureId}/lowres/${lowresUuid}/highres/${highresUuid}`}
    
    {...props}
  />
);

export type UseSubmitExtractionJobToPipelineByCaptureIdProps = Omit<UseMutateProps<boolean, void, void, void, SubmitExtractionJobToPipelineByCaptureIdPathParams>, "path" | "verb"> & SubmitExtractionJobToPipelineByCaptureIdPathParams;

export const useSubmitExtractionJobToPipelineByCaptureId = ({captureId, lowresUuid, highresUuid, ...props}: UseSubmitExtractionJobToPipelineByCaptureIdProps) => useMutate<boolean, void, void, void, SubmitExtractionJobToPipelineByCaptureIdPathParams>("POST", (paramsInPath: SubmitExtractionJobToPipelineByCaptureIdPathParams) => `/api/CaptureExtractionJobs/CaptureId/${paramsInPath.captureId}/lowres/${paramsInPath.lowresUuid}/highres/${paramsInPath.highresUuid}`, {  pathParams: { captureId, lowresUuid, highresUuid }, ...props });


export interface GetReportImageByIdPathParams {
  id: number
}

export type GetReportImageByIdProps = Omit<GetProps<string, void, void, GetReportImageByIdPathParams>, "path"> & GetReportImageByIdPathParams;

export const GetReportImageById = ({id, ...props}: GetReportImageByIdProps) => (
  <Get<string, void, void, GetReportImageByIdPathParams>
    path={`/api/CaptureExtractionJobs/${id}/report/image`}
    
    {...props}
  />
);

export type UseGetReportImageByIdProps = Omit<UseGetProps<string, void, void, GetReportImageByIdPathParams>, "path"> & GetReportImageByIdPathParams;

export const useGetReportImageById = ({id, ...props}: UseGetReportImageByIdProps) => useGet<string, void, void, GetReportImageByIdPathParams>((paramsInPath: GetReportImageByIdPathParams) => `/api/CaptureExtractionJobs/${paramsInPath.id}/report/image`, {  pathParams: { id }, ...props });


export interface UpdateCaptureExtractionJobStatusByJobUuidPathParams {
  jobUuid: string
}

export type UpdateCaptureExtractionJobStatusByJobUuidProps = Omit<MutateProps<CaptureExtractionJob, void, void, CaptureExtractionJob, UpdateCaptureExtractionJobStatusByJobUuidPathParams>, "path" | "verb"> & UpdateCaptureExtractionJobStatusByJobUuidPathParams;

export const UpdateCaptureExtractionJobStatusByJobUuid = ({jobUuid, ...props}: UpdateCaptureExtractionJobStatusByJobUuidProps) => (
  <Mutate<CaptureExtractionJob, void, void, CaptureExtractionJob, UpdateCaptureExtractionJobStatusByJobUuidPathParams>
    verb="PUT"
    path={`/api/CaptureExtractionJobs/Uuid/${jobUuid}/Status`}
    
    {...props}
  />
);

export type UseUpdateCaptureExtractionJobStatusByJobUuidProps = Omit<UseMutateProps<CaptureExtractionJob, void, void, CaptureExtractionJob, UpdateCaptureExtractionJobStatusByJobUuidPathParams>, "path" | "verb"> & UpdateCaptureExtractionJobStatusByJobUuidPathParams;

export const useUpdateCaptureExtractionJobStatusByJobUuid = ({jobUuid, ...props}: UseUpdateCaptureExtractionJobStatusByJobUuidProps) => useMutate<CaptureExtractionJob, void, void, CaptureExtractionJob, UpdateCaptureExtractionJobStatusByJobUuidPathParams>("PUT", (paramsInPath: UpdateCaptureExtractionJobStatusByJobUuidPathParams) => `/api/CaptureExtractionJobs/Uuid/${paramsInPath.jobUuid}/Status`, {  pathParams: { jobUuid }, ...props });


export interface ReSubmitExtractionJobToPipelinePathParams {
  captureExtractionJobId: number
}

export type ReSubmitExtractionJobToPipelineProps = Omit<MutateProps<CaptureExtractionJob, void, void, void, ReSubmitExtractionJobToPipelinePathParams>, "path" | "verb"> & ReSubmitExtractionJobToPipelinePathParams;

export const ReSubmitExtractionJobToPipeline = ({captureExtractionJobId, ...props}: ReSubmitExtractionJobToPipelineProps) => (
  <Mutate<CaptureExtractionJob, void, void, void, ReSubmitExtractionJobToPipelinePathParams>
    verb="POST"
    path={`/api/CaptureExtractionJobs/${captureExtractionJobId}/Resubmit`}
    
    {...props}
  />
);

export type UseReSubmitExtractionJobToPipelineProps = Omit<UseMutateProps<CaptureExtractionJob, void, void, void, ReSubmitExtractionJobToPipelinePathParams>, "path" | "verb"> & ReSubmitExtractionJobToPipelinePathParams;

export const useReSubmitExtractionJobToPipeline = ({captureExtractionJobId, ...props}: UseReSubmitExtractionJobToPipelineProps) => useMutate<CaptureExtractionJob, void, void, void, ReSubmitExtractionJobToPipelinePathParams>("POST", (paramsInPath: ReSubmitExtractionJobToPipelinePathParams) => `/api/CaptureExtractionJobs/${paramsInPath.captureExtractionJobId}/Resubmit`, {  pathParams: { captureExtractionJobId }, ...props });


export type SubmitNewExtractionJobToPipelineProps = Omit<MutateProps<boolean, void, void, CaptureExtractionJob, void>, "path" | "verb">;

export const SubmitNewExtractionJobToPipeline = (props: SubmitNewExtractionJobToPipelineProps) => (
  <Mutate<boolean, void, void, CaptureExtractionJob, void>
    verb="POST"
    path={`/api/CaptureExtractionJobs/Submit`}
    
    {...props}
  />
);

export type UseSubmitNewExtractionJobToPipelineProps = Omit<UseMutateProps<boolean, void, void, CaptureExtractionJob, void>, "path" | "verb">;

export const useSubmitNewExtractionJobToPipeline = (props: UseSubmitNewExtractionJobToPipelineProps) => useMutate<boolean, void, void, CaptureExtractionJob, void>("POST", `/api/CaptureExtractionJobs/Submit`, props);


export type GetCaptureExtractionJobStatusProps = Omit<GetProps<CaptureExtractionJobStatus[], void, void, void>, "path">;

export const GetCaptureExtractionJobStatus = (props: GetCaptureExtractionJobStatusProps) => (
  <Get<CaptureExtractionJobStatus[], void, void, void>
    path={`/api/CaptureExtractionJobStatus`}
    
    {...props}
  />
);

export type UseGetCaptureExtractionJobStatusProps = Omit<UseGetProps<CaptureExtractionJobStatus[], void, void, void>, "path">;

export const useGetCaptureExtractionJobStatus = (props: UseGetCaptureExtractionJobStatusProps) => useGet<CaptureExtractionJobStatus[], void, void, void>(`/api/CaptureExtractionJobStatus`, props);


export type PostCaptureExtractionJobStatusProps = Omit<MutateProps<CaptureExtractionJobStatus, void, void, CaptureExtractionJobStatus, void>, "path" | "verb">;

export const PostCaptureExtractionJobStatus = (props: PostCaptureExtractionJobStatusProps) => (
  <Mutate<CaptureExtractionJobStatus, void, void, CaptureExtractionJobStatus, void>
    verb="POST"
    path={`/api/CaptureExtractionJobStatus`}
    
    {...props}
  />
);

export type UsePostCaptureExtractionJobStatusProps = Omit<UseMutateProps<CaptureExtractionJobStatus, void, void, CaptureExtractionJobStatus, void>, "path" | "verb">;

export const usePostCaptureExtractionJobStatus = (props: UsePostCaptureExtractionJobStatusProps) => useMutate<CaptureExtractionJobStatus, void, void, CaptureExtractionJobStatus, void>("POST", `/api/CaptureExtractionJobStatus`, props);


export interface GetCaptureExtractionJobStatusByIdPathParams {
  id: number
}

export type GetCaptureExtractionJobStatusByIdProps = Omit<GetProps<CaptureExtractionJobStatus, void, void, GetCaptureExtractionJobStatusByIdPathParams>, "path"> & GetCaptureExtractionJobStatusByIdPathParams;

export const GetCaptureExtractionJobStatusById = ({id, ...props}: GetCaptureExtractionJobStatusByIdProps) => (
  <Get<CaptureExtractionJobStatus, void, void, GetCaptureExtractionJobStatusByIdPathParams>
    path={`/api/CaptureExtractionJobStatus/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureExtractionJobStatusByIdProps = Omit<UseGetProps<CaptureExtractionJobStatus, void, void, GetCaptureExtractionJobStatusByIdPathParams>, "path"> & GetCaptureExtractionJobStatusByIdPathParams;

export const useGetCaptureExtractionJobStatusById = ({id, ...props}: UseGetCaptureExtractionJobStatusByIdProps) => useGet<CaptureExtractionJobStatus, void, void, GetCaptureExtractionJobStatusByIdPathParams>((paramsInPath: GetCaptureExtractionJobStatusByIdPathParams) => `/api/CaptureExtractionJobStatus/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCaptureExtractionJobStatusByIdPathParams {
  id: number
}

export type PutCaptureExtractionJobStatusByIdProps = Omit<MutateProps<CaptureExtractionJobStatus, void, void, CaptureExtractionJobStatus, PutCaptureExtractionJobStatusByIdPathParams>, "path" | "verb"> & PutCaptureExtractionJobStatusByIdPathParams;

export const PutCaptureExtractionJobStatusById = ({id, ...props}: PutCaptureExtractionJobStatusByIdProps) => (
  <Mutate<CaptureExtractionJobStatus, void, void, CaptureExtractionJobStatus, PutCaptureExtractionJobStatusByIdPathParams>
    verb="PUT"
    path={`/api/CaptureExtractionJobStatus/${id}`}
    
    {...props}
  />
);

export type UsePutCaptureExtractionJobStatusByIdProps = Omit<UseMutateProps<CaptureExtractionJobStatus, void, void, CaptureExtractionJobStatus, PutCaptureExtractionJobStatusByIdPathParams>, "path" | "verb"> & PutCaptureExtractionJobStatusByIdPathParams;

export const usePutCaptureExtractionJobStatusById = ({id, ...props}: UsePutCaptureExtractionJobStatusByIdProps) => useMutate<CaptureExtractionJobStatus, void, void, CaptureExtractionJobStatus, PutCaptureExtractionJobStatusByIdPathParams>("PUT", (paramsInPath: PutCaptureExtractionJobStatusByIdPathParams) => `/api/CaptureExtractionJobStatus/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteCaptureExtractionJobStatusByIdProps = Omit<MutateProps<CaptureExtractionJobStatus, void, void, number, void>, "path" | "verb">;

export const DeleteCaptureExtractionJobStatusById = (props: DeleteCaptureExtractionJobStatusByIdProps) => (
  <Mutate<CaptureExtractionJobStatus, void, void, number, void>
    verb="DELETE"
    path={`/api/CaptureExtractionJobStatus`}
    
    {...props}
  />
);

export type UseDeleteCaptureExtractionJobStatusByIdProps = Omit<UseMutateProps<CaptureExtractionJobStatus, void, void, number, void>, "path" | "verb">;

export const useDeleteCaptureExtractionJobStatusById = (props: UseDeleteCaptureExtractionJobStatusByIdProps) => useMutate<CaptureExtractionJobStatus, void, void, number, void>("DELETE", `/api/CaptureExtractionJobStatus`, {   ...props });


export type GetCaptureImagesProps = Omit<GetProps<CaptureImage[], void, void, void>, "path">;

export const GetCaptureImages = (props: GetCaptureImagesProps) => (
  <Get<CaptureImage[], void, void, void>
    path={`/api/CaptureImages`}
    
    {...props}
  />
);

export type UseGetCaptureImagesProps = Omit<UseGetProps<CaptureImage[], void, void, void>, "path">;

export const useGetCaptureImages = (props: UseGetCaptureImagesProps) => useGet<CaptureImage[], void, void, void>(`/api/CaptureImages`, props);


export interface GetCaptureImageByIdPathParams {
  id: number
}

export type GetCaptureImageByIdProps = Omit<GetProps<CaptureImage, void, void, GetCaptureImageByIdPathParams>, "path"> & GetCaptureImageByIdPathParams;

export const GetCaptureImageById = ({id, ...props}: GetCaptureImageByIdProps) => (
  <Get<CaptureImage, void, void, GetCaptureImageByIdPathParams>
    path={`/api/CaptureImages/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureImageByIdProps = Omit<UseGetProps<CaptureImage, void, void, GetCaptureImageByIdPathParams>, "path"> & GetCaptureImageByIdPathParams;

export const useGetCaptureImageById = ({id, ...props}: UseGetCaptureImageByIdProps) => useGet<CaptureImage, void, void, GetCaptureImageByIdPathParams>((paramsInPath: GetCaptureImageByIdPathParams) => `/api/CaptureImages/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCaptureImageByIdPathParams {
  id: number
}

export type PutCaptureImageByIdProps = Omit<MutateProps<CaptureImage, void, void, CaptureImage, PutCaptureImageByIdPathParams>, "path" | "verb"> & PutCaptureImageByIdPathParams;

export const PutCaptureImageById = ({id, ...props}: PutCaptureImageByIdProps) => (
  <Mutate<CaptureImage, void, void, CaptureImage, PutCaptureImageByIdPathParams>
    verb="PUT"
    path={`/api/CaptureImages/${id}`}
    
    {...props}
  />
);

export type UsePutCaptureImageByIdProps = Omit<UseMutateProps<CaptureImage, void, void, CaptureImage, PutCaptureImageByIdPathParams>, "path" | "verb"> & PutCaptureImageByIdPathParams;

export const usePutCaptureImageById = ({id, ...props}: UsePutCaptureImageByIdProps) => useMutate<CaptureImage, void, void, CaptureImage, PutCaptureImageByIdPathParams>("PUT", (paramsInPath: PutCaptureImageByIdPathParams) => `/api/CaptureImages/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetCaptureImageFileByIdPathParams {
  id: number
}

export type GetCaptureImageFileByIdProps = Omit<GetProps<string, void, void, GetCaptureImageFileByIdPathParams>, "path"> & GetCaptureImageFileByIdPathParams;

export const GetCaptureImageFileById = ({id, ...props}: GetCaptureImageFileByIdProps) => (
  <Get<string, void, void, GetCaptureImageFileByIdPathParams>
    path={`/api/CaptureImages/${id}/File`}
    
    {...props}
  />
);

export type UseGetCaptureImageFileByIdProps = Omit<UseGetProps<string, void, void, GetCaptureImageFileByIdPathParams>, "path"> & GetCaptureImageFileByIdPathParams;

export const useGetCaptureImageFileById = ({id, ...props}: UseGetCaptureImageFileByIdProps) => useGet<string, void, void, GetCaptureImageFileByIdPathParams>((paramsInPath: GetCaptureImageFileByIdPathParams) => `/api/CaptureImages/${paramsInPath.id}/File`, {  pathParams: { id }, ...props });


export interface GetCaptureImageVerticalThumbnailByIdPathParams {
  id: number;
  fit: string;
  size: string;
  horizontalAlign: string;
  verticalAlign: string;
  crop: string;
  filters: string
}

export type GetCaptureImageVerticalThumbnailByIdProps = Omit<GetProps<string, void, void, GetCaptureImageVerticalThumbnailByIdPathParams>, "path"> & GetCaptureImageVerticalThumbnailByIdPathParams;

export const GetCaptureImageVerticalThumbnailById = ({id, fit, size, horizontalAlign, verticalAlign, crop, filters, ...props}: GetCaptureImageVerticalThumbnailByIdProps) => (
  <Get<string, void, void, GetCaptureImageVerticalThumbnailByIdPathParams>
    path={`/api/CaptureImages/${id}/VerticalThumbnail/${fit}/${size}/${horizontalAlign}/${verticalAlign}/${crop}/${filters}`}
    
    {...props}
  />
);

export type UseGetCaptureImageVerticalThumbnailByIdProps = Omit<UseGetProps<string, void, void, GetCaptureImageVerticalThumbnailByIdPathParams>, "path"> & GetCaptureImageVerticalThumbnailByIdPathParams;

export const useGetCaptureImageVerticalThumbnailById = ({id, fit, size, horizontalAlign, verticalAlign, crop, filters, ...props}: UseGetCaptureImageVerticalThumbnailByIdProps) => useGet<string, void, void, GetCaptureImageVerticalThumbnailByIdPathParams>((paramsInPath: GetCaptureImageVerticalThumbnailByIdPathParams) => `/api/CaptureImages/${paramsInPath.id}/VerticalThumbnail/${paramsInPath.fit}/${paramsInPath.size}/${paramsInPath.horizontalAlign}/${paramsInPath.verticalAlign}/${paramsInPath.crop}/${paramsInPath.filters}`, {  pathParams: { id, fit, size, horizontalAlign, verticalAlign, crop, filters }, ...props });


export interface GetCaptureImageHorizontalThumbnailByIdPathParams {
  id: number;
  fit: string;
  size: string;
  horizontalAlign: string;
  verticalAlign: string;
  crop: string;
  filters: string
}

export type GetCaptureImageHorizontalThumbnailByIdProps = Omit<GetProps<string, void, void, GetCaptureImageHorizontalThumbnailByIdPathParams>, "path"> & GetCaptureImageHorizontalThumbnailByIdPathParams;

export const GetCaptureImageHorizontalThumbnailById = ({id, fit, size, horizontalAlign, verticalAlign, crop, filters, ...props}: GetCaptureImageHorizontalThumbnailByIdProps) => (
  <Get<string, void, void, GetCaptureImageHorizontalThumbnailByIdPathParams>
    path={`/api/CaptureImages/${id}/HorizontalThumbnail/${fit}/${size}/${horizontalAlign}/${verticalAlign}/${crop}/${filters}`}
    
    {...props}
  />
);

export type UseGetCaptureImageHorizontalThumbnailByIdProps = Omit<UseGetProps<string, void, void, GetCaptureImageHorizontalThumbnailByIdPathParams>, "path"> & GetCaptureImageHorizontalThumbnailByIdPathParams;

export const useGetCaptureImageHorizontalThumbnailById = ({id, fit, size, horizontalAlign, verticalAlign, crop, filters, ...props}: UseGetCaptureImageHorizontalThumbnailByIdProps) => useGet<string, void, void, GetCaptureImageHorizontalThumbnailByIdPathParams>((paramsInPath: GetCaptureImageHorizontalThumbnailByIdPathParams) => `/api/CaptureImages/${paramsInPath.id}/HorizontalThumbnail/${paramsInPath.fit}/${paramsInPath.size}/${paramsInPath.horizontalAlign}/${paramsInPath.verticalAlign}/${paramsInPath.crop}/${paramsInPath.filters}`, {  pathParams: { id, fit, size, horizontalAlign, verticalAlign, crop, filters }, ...props });


export interface GetCaptureImageThumbnailById2PathParams {
  id: number;
  fit: string;
  size: string;
  horizontalAlign: string;
  verticalAlign: string;
  crop: string;
  filters: string
}

export type GetCaptureImageThumbnailById2Props = Omit<GetProps<string, void, void, GetCaptureImageThumbnailById2PathParams>, "path"> & GetCaptureImageThumbnailById2PathParams;

export const GetCaptureImageThumbnailById2 = ({id, fit, size, horizontalAlign, verticalAlign, crop, filters, ...props}: GetCaptureImageThumbnailById2Props) => (
  <Get<string, void, void, GetCaptureImageThumbnailById2PathParams>
    path={`/api/CaptureImages/${id}/Thumbnail/${fit}/${size}/${horizontalAlign}/${verticalAlign}/${crop}/${filters}`}
    
    {...props}
  />
);

export type UseGetCaptureImageThumbnailById2Props = Omit<UseGetProps<string, void, void, GetCaptureImageThumbnailById2PathParams>, "path"> & GetCaptureImageThumbnailById2PathParams;

export const useGetCaptureImageThumbnailById2 = ({id, fit, size, horizontalAlign, verticalAlign, crop, filters, ...props}: UseGetCaptureImageThumbnailById2Props) => useGet<string, void, void, GetCaptureImageThumbnailById2PathParams>((paramsInPath: GetCaptureImageThumbnailById2PathParams) => `/api/CaptureImages/${paramsInPath.id}/Thumbnail/${paramsInPath.fit}/${paramsInPath.size}/${paramsInPath.horizontalAlign}/${paramsInPath.verticalAlign}/${paramsInPath.crop}/${paramsInPath.filters}`, {  pathParams: { id, fit, size, horizontalAlign, verticalAlign, crop, filters }, ...props });


export interface GetCaptureImageThumbnailByIdPathParams {
  id: number
}

export type GetCaptureImageThumbnailByIdProps = Omit<MutateProps<string, void, void, string[], GetCaptureImageThumbnailByIdPathParams>, "path" | "verb"> & GetCaptureImageThumbnailByIdPathParams;

export const GetCaptureImageThumbnailById = ({id, ...props}: GetCaptureImageThumbnailByIdProps) => (
  <Mutate<string, void, void, string[], GetCaptureImageThumbnailByIdPathParams>
    verb="POST"
    path={`/api/CaptureImages/${id}/Thumbnail`}
    
    {...props}
  />
);

export type UseGetCaptureImageThumbnailByIdProps = Omit<UseMutateProps<string, void, void, string[], GetCaptureImageThumbnailByIdPathParams>, "path" | "verb"> & GetCaptureImageThumbnailByIdPathParams;

export const useGetCaptureImageThumbnailById = ({id, ...props}: UseGetCaptureImageThumbnailByIdProps) => useMutate<string, void, void, string[], GetCaptureImageThumbnailByIdPathParams>("POST", (paramsInPath: GetCaptureImageThumbnailByIdPathParams) => `/api/CaptureImages/${paramsInPath.id}/Thumbnail`, {  pathParams: { id }, ...props });


export interface GetCaptureImagesByCaptureIdPathParams {
  captureId: number
}

export type GetCaptureImagesByCaptureIdProps = Omit<GetProps<CaptureImage[], void, void, GetCaptureImagesByCaptureIdPathParams>, "path"> & GetCaptureImagesByCaptureIdPathParams;

export const GetCaptureImagesByCaptureId = ({captureId, ...props}: GetCaptureImagesByCaptureIdProps) => (
  <Get<CaptureImage[], void, void, GetCaptureImagesByCaptureIdPathParams>
    path={`/api/CaptureImages/CaptureId/${captureId}`}
    
    {...props}
  />
);

export type UseGetCaptureImagesByCaptureIdProps = Omit<UseGetProps<CaptureImage[], void, void, GetCaptureImagesByCaptureIdPathParams>, "path"> & GetCaptureImagesByCaptureIdPathParams;

export const useGetCaptureImagesByCaptureId = ({captureId, ...props}: UseGetCaptureImagesByCaptureIdProps) => useGet<CaptureImage[], void, void, GetCaptureImagesByCaptureIdPathParams>((paramsInPath: GetCaptureImagesByCaptureIdPathParams) => `/api/CaptureImages/CaptureId/${paramsInPath.captureId}`, {  pathParams: { captureId }, ...props });


export interface GetCaptureImagesByCaptureJobIdPathParams {
  captureJobId: number
}

export type GetCaptureImagesByCaptureJobIdProps = Omit<GetProps<CaptureImage[], void, void, GetCaptureImagesByCaptureJobIdPathParams>, "path"> & GetCaptureImagesByCaptureJobIdPathParams;

export const GetCaptureImagesByCaptureJobId = ({captureJobId, ...props}: GetCaptureImagesByCaptureJobIdProps) => (
  <Get<CaptureImage[], void, void, GetCaptureImagesByCaptureJobIdPathParams>
    path={`/api/CaptureImages/CaptureJobId/${captureJobId}`}
    
    {...props}
  />
);

export type UseGetCaptureImagesByCaptureJobIdProps = Omit<UseGetProps<CaptureImage[], void, void, GetCaptureImagesByCaptureJobIdPathParams>, "path"> & GetCaptureImagesByCaptureJobIdPathParams;

export const useGetCaptureImagesByCaptureJobId = ({captureJobId, ...props}: UseGetCaptureImagesByCaptureJobIdProps) => useGet<CaptureImage[], void, void, GetCaptureImagesByCaptureJobIdPathParams>((paramsInPath: GetCaptureImagesByCaptureJobIdPathParams) => `/api/CaptureImages/CaptureJobId/${paramsInPath.captureJobId}`, {  pathParams: { captureJobId }, ...props });


export interface GetPagedCaptureImagesByCaptureJobIdPathParams {
  captureJobId: number;
  skip: number;
  take: number
}

export type GetPagedCaptureImagesByCaptureJobIdProps = Omit<GetProps<CaptureImage[], void, void, GetPagedCaptureImagesByCaptureJobIdPathParams>, "path"> & GetPagedCaptureImagesByCaptureJobIdPathParams;

export const GetPagedCaptureImagesByCaptureJobId = ({captureJobId, skip, take, ...props}: GetPagedCaptureImagesByCaptureJobIdProps) => (
  <Get<CaptureImage[], void, void, GetPagedCaptureImagesByCaptureJobIdPathParams>
    path={`/api/CaptureImages/CaptureJobId/${captureJobId}/${skip}/${take}`}
    
    {...props}
  />
);

export type UseGetPagedCaptureImagesByCaptureJobIdProps = Omit<UseGetProps<CaptureImage[], void, void, GetPagedCaptureImagesByCaptureJobIdPathParams>, "path"> & GetPagedCaptureImagesByCaptureJobIdPathParams;

export const useGetPagedCaptureImagesByCaptureJobId = ({captureJobId, skip, take, ...props}: UseGetPagedCaptureImagesByCaptureJobIdProps) => useGet<CaptureImage[], void, void, GetPagedCaptureImagesByCaptureJobIdPathParams>((paramsInPath: GetPagedCaptureImagesByCaptureJobIdPathParams) => `/api/CaptureImages/CaptureJobId/${paramsInPath.captureJobId}/${paramsInPath.skip}/${paramsInPath.take}`, {  pathParams: { captureJobId, skip, take }, ...props });


export interface GetPagedFavoriteCaptureImagesByCaptureJobIdPathParams {
  captureJobId: number;
  skip: number;
  take: number
}

export type GetPagedFavoriteCaptureImagesByCaptureJobIdProps = Omit<GetProps<CaptureImage[], void, void, GetPagedFavoriteCaptureImagesByCaptureJobIdPathParams>, "path"> & GetPagedFavoriteCaptureImagesByCaptureJobIdPathParams;

export const GetPagedFavoriteCaptureImagesByCaptureJobId = ({captureJobId, skip, take, ...props}: GetPagedFavoriteCaptureImagesByCaptureJobIdProps) => (
  <Get<CaptureImage[], void, void, GetPagedFavoriteCaptureImagesByCaptureJobIdPathParams>
    path={`/api/CaptureImages/CaptureJobId/Favorite/${captureJobId}/${skip}/${take}`}
    
    {...props}
  />
);

export type UseGetPagedFavoriteCaptureImagesByCaptureJobIdProps = Omit<UseGetProps<CaptureImage[], void, void, GetPagedFavoriteCaptureImagesByCaptureJobIdPathParams>, "path"> & GetPagedFavoriteCaptureImagesByCaptureJobIdPathParams;

export const useGetPagedFavoriteCaptureImagesByCaptureJobId = ({captureJobId, skip, take, ...props}: UseGetPagedFavoriteCaptureImagesByCaptureJobIdProps) => useGet<CaptureImage[], void, void, GetPagedFavoriteCaptureImagesByCaptureJobIdPathParams>((paramsInPath: GetPagedFavoriteCaptureImagesByCaptureJobIdPathParams) => `/api/CaptureImages/CaptureJobId/Favorite/${paramsInPath.captureJobId}/${paramsInPath.skip}/${paramsInPath.take}`, {  pathParams: { captureJobId, skip, take }, ...props });


export interface GetPagedCaptureImagesByCaptureIdPathParams {
  captureId: number;
  skip: number;
  take: number
}

export type GetPagedCaptureImagesByCaptureIdProps = Omit<GetProps<CaptureImage[], void, void, GetPagedCaptureImagesByCaptureIdPathParams>, "path"> & GetPagedCaptureImagesByCaptureIdPathParams;

export const GetPagedCaptureImagesByCaptureId = ({captureId, skip, take, ...props}: GetPagedCaptureImagesByCaptureIdProps) => (
  <Get<CaptureImage[], void, void, GetPagedCaptureImagesByCaptureIdPathParams>
    path={`/api/CaptureImages/CaptureId/${captureId}/${skip}/${take}`}
    
    {...props}
  />
);

export type UseGetPagedCaptureImagesByCaptureIdProps = Omit<UseGetProps<CaptureImage[], void, void, GetPagedCaptureImagesByCaptureIdPathParams>, "path"> & GetPagedCaptureImagesByCaptureIdPathParams;

export const useGetPagedCaptureImagesByCaptureId = ({captureId, skip, take, ...props}: UseGetPagedCaptureImagesByCaptureIdProps) => useGet<CaptureImage[], void, void, GetPagedCaptureImagesByCaptureIdPathParams>((paramsInPath: GetPagedCaptureImagesByCaptureIdPathParams) => `/api/CaptureImages/CaptureId/${paramsInPath.captureId}/${paramsInPath.skip}/${paramsInPath.take}`, {  pathParams: { captureId, skip, take }, ...props });


export interface GetPagedFavoriteCaptureImagesByCaptureIdPathParams {
  captureId: number;
  skip: number;
  take: number
}

export type GetPagedFavoriteCaptureImagesByCaptureIdProps = Omit<GetProps<CaptureImage[], void, void, GetPagedFavoriteCaptureImagesByCaptureIdPathParams>, "path"> & GetPagedFavoriteCaptureImagesByCaptureIdPathParams;

export const GetPagedFavoriteCaptureImagesByCaptureId = ({captureId, skip, take, ...props}: GetPagedFavoriteCaptureImagesByCaptureIdProps) => (
  <Get<CaptureImage[], void, void, GetPagedFavoriteCaptureImagesByCaptureIdPathParams>
    path={`/api/CaptureImages/CaptureId/Favorite/${captureId}/${skip}/${take}`}
    
    {...props}
  />
);

export type UseGetPagedFavoriteCaptureImagesByCaptureIdProps = Omit<UseGetProps<CaptureImage[], void, void, GetPagedFavoriteCaptureImagesByCaptureIdPathParams>, "path"> & GetPagedFavoriteCaptureImagesByCaptureIdPathParams;

export const useGetPagedFavoriteCaptureImagesByCaptureId = ({captureId, skip, take, ...props}: UseGetPagedFavoriteCaptureImagesByCaptureIdProps) => useGet<CaptureImage[], void, void, GetPagedFavoriteCaptureImagesByCaptureIdPathParams>((paramsInPath: GetPagedFavoriteCaptureImagesByCaptureIdPathParams) => `/api/CaptureImages/CaptureId/Favorite/${paramsInPath.captureId}/${paramsInPath.skip}/${paramsInPath.take}`, {  pathParams: { captureId, skip, take }, ...props });


export type GetCaptureImageTagsProps = Omit<GetProps<CaptureImageTag[], void, void, void>, "path">;

export const GetCaptureImageTags = (props: GetCaptureImageTagsProps) => (
  <Get<CaptureImageTag[], void, void, void>
    path={`/api/CaptureImageTags`}
    
    {...props}
  />
);

export type UseGetCaptureImageTagsProps = Omit<UseGetProps<CaptureImageTag[], void, void, void>, "path">;

export const useGetCaptureImageTags = (props: UseGetCaptureImageTagsProps) => useGet<CaptureImageTag[], void, void, void>(`/api/CaptureImageTags`, props);


export interface GetCaptureImageTagByIdPathParams {
  id: number
}

export type GetCaptureImageTagByIdProps = Omit<GetProps<CaptureImageTag, void, void, GetCaptureImageTagByIdPathParams>, "path"> & GetCaptureImageTagByIdPathParams;

export const GetCaptureImageTagById = ({id, ...props}: GetCaptureImageTagByIdProps) => (
  <Get<CaptureImageTag, void, void, GetCaptureImageTagByIdPathParams>
    path={`/api/CaptureImageTags/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureImageTagByIdProps = Omit<UseGetProps<CaptureImageTag, void, void, GetCaptureImageTagByIdPathParams>, "path"> & GetCaptureImageTagByIdPathParams;

export const useGetCaptureImageTagById = ({id, ...props}: UseGetCaptureImageTagByIdProps) => useGet<CaptureImageTag, void, void, GetCaptureImageTagByIdPathParams>((paramsInPath: GetCaptureImageTagByIdPathParams) => `/api/CaptureImageTags/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCaptureImageTagPathParams {
  id: number
}

export type PutCaptureImageTagProps = Omit<MutateProps<CaptureImageTag, void, void, CaptureImageTag, PutCaptureImageTagPathParams>, "path" | "verb"> & PutCaptureImageTagPathParams;

export const PutCaptureImageTag = ({id, ...props}: PutCaptureImageTagProps) => (
  <Mutate<CaptureImageTag, void, void, CaptureImageTag, PutCaptureImageTagPathParams>
    verb="PUT"
    path={`/api/CaptureImageTags/${id}`}
    
    {...props}
  />
);

export type UsePutCaptureImageTagProps = Omit<UseMutateProps<CaptureImageTag, void, void, CaptureImageTag, PutCaptureImageTagPathParams>, "path" | "verb"> & PutCaptureImageTagPathParams;

export const usePutCaptureImageTag = ({id, ...props}: UsePutCaptureImageTagProps) => useMutate<CaptureImageTag, void, void, CaptureImageTag, PutCaptureImageTagPathParams>("PUT", (paramsInPath: PutCaptureImageTagPathParams) => `/api/CaptureImageTags/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetCaptureImageTagThumbnailById2PathParams {
  id: number;
  fit: string;
  size: string;
  horizontalAlign: string;
  verticalAlign: string;
  crop: string;
  filters: string
}

export type GetCaptureImageTagThumbnailById2Props = Omit<GetProps<string, void, void, GetCaptureImageTagThumbnailById2PathParams>, "path"> & GetCaptureImageTagThumbnailById2PathParams;

export const GetCaptureImageTagThumbnailById2 = ({id, fit, size, horizontalAlign, verticalAlign, crop, filters, ...props}: GetCaptureImageTagThumbnailById2Props) => (
  <Get<string, void, void, GetCaptureImageTagThumbnailById2PathParams>
    path={`/api/CaptureImageTags/${id}/Thumbnail/${fit}/${size}/${horizontalAlign}/${verticalAlign}/${crop}/${filters}`}
    
    {...props}
  />
);

export type UseGetCaptureImageTagThumbnailById2Props = Omit<UseGetProps<string, void, void, GetCaptureImageTagThumbnailById2PathParams>, "path"> & GetCaptureImageTagThumbnailById2PathParams;

export const useGetCaptureImageTagThumbnailById2 = ({id, fit, size, horizontalAlign, verticalAlign, crop, filters, ...props}: UseGetCaptureImageTagThumbnailById2Props) => useGet<string, void, void, GetCaptureImageTagThumbnailById2PathParams>((paramsInPath: GetCaptureImageTagThumbnailById2PathParams) => `/api/CaptureImageTags/${paramsInPath.id}/Thumbnail/${paramsInPath.fit}/${paramsInPath.size}/${paramsInPath.horizontalAlign}/${paramsInPath.verticalAlign}/${paramsInPath.crop}/${paramsInPath.filters}`, {  pathParams: { id, fit, size, horizontalAlign, verticalAlign, crop, filters }, ...props });


export interface GetCaptureImageTagThumbnailByIdPathParams {
  id: number
}

export type GetCaptureImageTagThumbnailByIdProps = Omit<MutateProps<string, void, void, string[], GetCaptureImageTagThumbnailByIdPathParams>, "path" | "verb"> & GetCaptureImageTagThumbnailByIdPathParams;

export const GetCaptureImageTagThumbnailById = ({id, ...props}: GetCaptureImageTagThumbnailByIdProps) => (
  <Mutate<string, void, void, string[], GetCaptureImageTagThumbnailByIdPathParams>
    verb="POST"
    path={`/api/CaptureImageTags/${id}/Thumbnail`}
    
    {...props}
  />
);

export type UseGetCaptureImageTagThumbnailByIdProps = Omit<UseMutateProps<string, void, void, string[], GetCaptureImageTagThumbnailByIdPathParams>, "path" | "verb"> & GetCaptureImageTagThumbnailByIdPathParams;

export const useGetCaptureImageTagThumbnailById = ({id, ...props}: UseGetCaptureImageTagThumbnailByIdProps) => useMutate<string, void, void, string[], GetCaptureImageTagThumbnailByIdPathParams>("POST", (paramsInPath: GetCaptureImageTagThumbnailByIdPathParams) => `/api/CaptureImageTags/${paramsInPath.id}/Thumbnail`, {  pathParams: { id }, ...props });


export interface GetCaptureImageTagsByCaptureIdPathParams {
  captureId: number
}

export type GetCaptureImageTagsByCaptureIdProps = Omit<GetProps<CaptureImageTag[], void, void, GetCaptureImageTagsByCaptureIdPathParams>, "path"> & GetCaptureImageTagsByCaptureIdPathParams;

export const GetCaptureImageTagsByCaptureId = ({captureId, ...props}: GetCaptureImageTagsByCaptureIdProps) => (
  <Get<CaptureImageTag[], void, void, GetCaptureImageTagsByCaptureIdPathParams>
    path={`/api/CaptureImageTags/CaptureId/${captureId}`}
    
    {...props}
  />
);

export type UseGetCaptureImageTagsByCaptureIdProps = Omit<UseGetProps<CaptureImageTag[], void, void, GetCaptureImageTagsByCaptureIdPathParams>, "path"> & GetCaptureImageTagsByCaptureIdPathParams;

export const useGetCaptureImageTagsByCaptureId = ({captureId, ...props}: UseGetCaptureImageTagsByCaptureIdProps) => useGet<CaptureImageTag[], void, void, GetCaptureImageTagsByCaptureIdPathParams>((paramsInPath: GetCaptureImageTagsByCaptureIdPathParams) => `/api/CaptureImageTags/CaptureId/${paramsInPath.captureId}`, {  pathParams: { captureId }, ...props });


export interface GetPagedCaptureImageTagsByCaptureIdPathParams {
  captureId: number;
  skip: number;
  take: number
}

export type GetPagedCaptureImageTagsByCaptureIdProps = Omit<GetProps<CaptureImageTag[], void, void, GetPagedCaptureImageTagsByCaptureIdPathParams>, "path"> & GetPagedCaptureImageTagsByCaptureIdPathParams;

export const GetPagedCaptureImageTagsByCaptureId = ({captureId, skip, take, ...props}: GetPagedCaptureImageTagsByCaptureIdProps) => (
  <Get<CaptureImageTag[], void, void, GetPagedCaptureImageTagsByCaptureIdPathParams>
    path={`/api/CaptureImageTags/CaptureId/${captureId}/${skip}/${take}`}
    
    {...props}
  />
);

export type UseGetPagedCaptureImageTagsByCaptureIdProps = Omit<UseGetProps<CaptureImageTag[], void, void, GetPagedCaptureImageTagsByCaptureIdPathParams>, "path"> & GetPagedCaptureImageTagsByCaptureIdPathParams;

export const useGetPagedCaptureImageTagsByCaptureId = ({captureId, skip, take, ...props}: UseGetPagedCaptureImageTagsByCaptureIdProps) => useGet<CaptureImageTag[], void, void, GetPagedCaptureImageTagsByCaptureIdPathParams>((paramsInPath: GetPagedCaptureImageTagsByCaptureIdPathParams) => `/api/CaptureImageTags/CaptureId/${paramsInPath.captureId}/${paramsInPath.skip}/${paramsInPath.take}`, {  pathParams: { captureId, skip, take }, ...props });


export type CreateCaptureJobRatingProps = Omit<MutateProps<string, string | void, void, CaptureJobRating, void>, "path" | "verb">;

export const CreateCaptureJobRating = (props: CreateCaptureJobRatingProps) => (
  <Mutate<string, string | void, void, CaptureJobRating, void>
    verb="POST"
    path={`/api/CaptureJobRating`}
    
    {...props}
  />
);

export type UseCreateCaptureJobRatingProps = Omit<UseMutateProps<string, string | void, void, CaptureJobRating, void>, "path" | "verb">;

export const useCreateCaptureJobRating = (props: UseCreateCaptureJobRatingProps) => useMutate<string, string | void, void, CaptureJobRating, void>("POST", `/api/CaptureJobRating`, props);


export type UpdateCaptureJobRatingProps = Omit<MutateProps<string, string | void, void, CaptureJobRating, void>, "path" | "verb">;

export const UpdateCaptureJobRating = (props: UpdateCaptureJobRatingProps) => (
  <Mutate<string, string | void, void, CaptureJobRating, void>
    verb="PUT"
    path={`/api/CaptureJobRating`}
    
    {...props}
  />
);

export type UseUpdateCaptureJobRatingProps = Omit<UseMutateProps<string, string | void, void, CaptureJobRating, void>, "path" | "verb">;

export const useUpdateCaptureJobRating = (props: UseUpdateCaptureJobRatingProps) => useMutate<string, string | void, void, CaptureJobRating, void>("PUT", `/api/CaptureJobRating`, props);


export type GetCaptureJobsProps = Omit<GetProps<CaptureJob[], void, void, void>, "path">;

export const GetCaptureJobs = (props: GetCaptureJobsProps) => (
  <Get<CaptureJob[], void, void, void>
    path={`/api/CaptureJobs`}
    
    {...props}
  />
);

export type UseGetCaptureJobsProps = Omit<UseGetProps<CaptureJob[], void, void, void>, "path">;

export const useGetCaptureJobs = (props: UseGetCaptureJobsProps) => useGet<CaptureJob[], void, void, void>(`/api/CaptureJobs`, props);


export interface GetCaptureJobByIdPathParams {
  id: number
}

export type GetCaptureJobByIdProps = Omit<GetProps<CaptureJob, void, void, GetCaptureJobByIdPathParams>, "path"> & GetCaptureJobByIdPathParams;

export const GetCaptureJobById = ({id, ...props}: GetCaptureJobByIdProps) => (
  <Get<CaptureJob, void, void, GetCaptureJobByIdPathParams>
    path={`/api/CaptureJobs/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureJobByIdProps = Omit<UseGetProps<CaptureJob, void, void, GetCaptureJobByIdPathParams>, "path"> & GetCaptureJobByIdPathParams;

export const useGetCaptureJobById = ({id, ...props}: UseGetCaptureJobByIdProps) => useGet<CaptureJob, void, void, GetCaptureJobByIdPathParams>((paramsInPath: GetCaptureJobByIdPathParams) => `/api/CaptureJobs/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetPlyDownloadUrlByCaptureJobIdPathParams {
  id: number
}

export type GetPlyDownloadUrlByCaptureJobIdProps = Omit<GetProps<CaptureJob, void, void, GetPlyDownloadUrlByCaptureJobIdPathParams>, "path"> & GetPlyDownloadUrlByCaptureJobIdPathParams;

export const GetPlyDownloadUrlByCaptureJobId = ({id, ...props}: GetPlyDownloadUrlByCaptureJobIdProps) => (
  <Get<CaptureJob, void, void, GetPlyDownloadUrlByCaptureJobIdPathParams>
    path={`/api/CaptureJobs/${id}/Ply`}
    
    {...props}
  />
);

export type UseGetPlyDownloadUrlByCaptureJobIdProps = Omit<UseGetProps<CaptureJob, void, void, GetPlyDownloadUrlByCaptureJobIdPathParams>, "path"> & GetPlyDownloadUrlByCaptureJobIdPathParams;

export const useGetPlyDownloadUrlByCaptureJobId = ({id, ...props}: UseGetPlyDownloadUrlByCaptureJobIdProps) => useGet<CaptureJob, void, void, GetPlyDownloadUrlByCaptureJobIdPathParams>((paramsInPath: GetPlyDownloadUrlByCaptureJobIdPathParams) => `/api/CaptureJobs/${paramsInPath.id}/Ply`, {  pathParams: { id }, ...props });


export interface GeVideoPreviewDownloadUrlByCaptureJobIdPathParams {
  id: number
}

export type GeVideoPreviewDownloadUrlByCaptureJobIdProps = Omit<GetProps<CaptureJob, void, void, GeVideoPreviewDownloadUrlByCaptureJobIdPathParams>, "path"> & GeVideoPreviewDownloadUrlByCaptureJobIdPathParams;

export const GeVideoPreviewDownloadUrlByCaptureJobId = ({id, ...props}: GeVideoPreviewDownloadUrlByCaptureJobIdProps) => (
  <Get<CaptureJob, void, void, GeVideoPreviewDownloadUrlByCaptureJobIdPathParams>
    path={`/api/CaptureJobs/${id}/VideoPreview`}
    
    {...props}
  />
);

export type UseGeVideoPreviewDownloadUrlByCaptureJobIdProps = Omit<UseGetProps<CaptureJob, void, void, GeVideoPreviewDownloadUrlByCaptureJobIdPathParams>, "path"> & GeVideoPreviewDownloadUrlByCaptureJobIdPathParams;

export const useGeVideoPreviewDownloadUrlByCaptureJobId = ({id, ...props}: UseGeVideoPreviewDownloadUrlByCaptureJobIdProps) => useGet<CaptureJob, void, void, GeVideoPreviewDownloadUrlByCaptureJobIdPathParams>((paramsInPath: GeVideoPreviewDownloadUrlByCaptureJobIdPathParams) => `/api/CaptureJobs/${paramsInPath.id}/VideoPreview`, {  pathParams: { id }, ...props });


export interface GetCaptureJobsByCaptureIdPathParams {
  captureId: number
}

export type GetCaptureJobsByCaptureIdProps = Omit<GetProps<CaptureJob[], void, void, GetCaptureJobsByCaptureIdPathParams>, "path"> & GetCaptureJobsByCaptureIdPathParams;

export const GetCaptureJobsByCaptureId = ({captureId, ...props}: GetCaptureJobsByCaptureIdProps) => (
  <Get<CaptureJob[], void, void, GetCaptureJobsByCaptureIdPathParams>
    path={`/api/CaptureJobs/Capture/${captureId}`}
    
    {...props}
  />
);

export type UseGetCaptureJobsByCaptureIdProps = Omit<UseGetProps<CaptureJob[], void, void, GetCaptureJobsByCaptureIdPathParams>, "path"> & GetCaptureJobsByCaptureIdPathParams;

export const useGetCaptureJobsByCaptureId = ({captureId, ...props}: UseGetCaptureJobsByCaptureIdProps) => useGet<CaptureJob[], void, void, GetCaptureJobsByCaptureIdPathParams>((paramsInPath: GetCaptureJobsByCaptureIdPathParams) => `/api/CaptureJobs/Capture/${paramsInPath.captureId}`, {  pathParams: { captureId }, ...props });


export interface GetCaptureJobsByAnalyticRequestIdPathParams {
  analyticRequestId: number
}

export type GetCaptureJobsByAnalyticRequestIdProps = Omit<GetProps<CaptureJob[], void, void, GetCaptureJobsByAnalyticRequestIdPathParams>, "path"> & GetCaptureJobsByAnalyticRequestIdPathParams;

export const GetCaptureJobsByAnalyticRequestId = ({analyticRequestId, ...props}: GetCaptureJobsByAnalyticRequestIdProps) => (
  <Get<CaptureJob[], void, void, GetCaptureJobsByAnalyticRequestIdPathParams>
    path={`/api/CaptureJobs/AnalyticRequest/${analyticRequestId}`}
    
    {...props}
  />
);

export type UseGetCaptureJobsByAnalyticRequestIdProps = Omit<UseGetProps<CaptureJob[], void, void, GetCaptureJobsByAnalyticRequestIdPathParams>, "path"> & GetCaptureJobsByAnalyticRequestIdPathParams;

export const useGetCaptureJobsByAnalyticRequestId = ({analyticRequestId, ...props}: UseGetCaptureJobsByAnalyticRequestIdProps) => useGet<CaptureJob[], void, void, GetCaptureJobsByAnalyticRequestIdPathParams>((paramsInPath: GetCaptureJobsByAnalyticRequestIdPathParams) => `/api/CaptureJobs/AnalyticRequest/${paramsInPath.analyticRequestId}`, {  pathParams: { analyticRequestId }, ...props });


export interface PutCaptureJobByIdPathParams {
  captureId: string;
  jobId: number
}

export type PutCaptureJobByIdProps = Omit<MutateProps<CaptureJob, void, void, CaptureJob, PutCaptureJobByIdPathParams>, "path" | "verb"> & PutCaptureJobByIdPathParams;

export const PutCaptureJobById = ({captureId, jobId, ...props}: PutCaptureJobByIdProps) => (
  <Mutate<CaptureJob, void, void, CaptureJob, PutCaptureJobByIdPathParams>
    verb="PUT"
    path={`/api/CaptureJobs/Capture/${captureId}/Job/${jobId}`}
    
    {...props}
  />
);

export type UsePutCaptureJobByIdProps = Omit<UseMutateProps<CaptureJob, void, void, CaptureJob, PutCaptureJobByIdPathParams>, "path" | "verb"> & PutCaptureJobByIdPathParams;

export const usePutCaptureJobById = ({captureId, jobId, ...props}: UsePutCaptureJobByIdProps) => useMutate<CaptureJob, void, void, CaptureJob, PutCaptureJobByIdPathParams>("PUT", (paramsInPath: PutCaptureJobByIdPathParams) => `/api/CaptureJobs/Capture/${paramsInPath.captureId}/Job/${paramsInPath.jobId}`, {  pathParams: { captureId, jobId }, ...props });


export interface UpdateCaptureJobStatusByJobUuidPathParams {
  jobUuid: string
}

export type UpdateCaptureJobStatusByJobUuidProps = Omit<MutateProps<CaptureJob, void, void, CaptureJob, UpdateCaptureJobStatusByJobUuidPathParams>, "path" | "verb"> & UpdateCaptureJobStatusByJobUuidPathParams;

export const UpdateCaptureJobStatusByJobUuid = ({jobUuid, ...props}: UpdateCaptureJobStatusByJobUuidProps) => (
  <Mutate<CaptureJob, void, void, CaptureJob, UpdateCaptureJobStatusByJobUuidPathParams>
    verb="PUT"
    path={`/api/CaptureJobs/Uuid/${jobUuid}/Status`}
    
    {...props}
  />
);

export type UseUpdateCaptureJobStatusByJobUuidProps = Omit<UseMutateProps<CaptureJob, void, void, CaptureJob, UpdateCaptureJobStatusByJobUuidPathParams>, "path" | "verb"> & UpdateCaptureJobStatusByJobUuidPathParams;

export const useUpdateCaptureJobStatusByJobUuid = ({jobUuid, ...props}: UseUpdateCaptureJobStatusByJobUuidProps) => useMutate<CaptureJob, void, void, CaptureJob, UpdateCaptureJobStatusByJobUuidPathParams>("PUT", (paramsInPath: UpdateCaptureJobStatusByJobUuidPathParams) => `/api/CaptureJobs/Uuid/${paramsInPath.jobUuid}/Status`, {  pathParams: { jobUuid }, ...props });


export interface ReSubmitCaptureJobToPipelinePathParams {
  captureJobId: number
}

export type ReSubmitCaptureJobToPipelineProps = Omit<MutateProps<CaptureJob, void, void, void, ReSubmitCaptureJobToPipelinePathParams>, "path" | "verb"> & ReSubmitCaptureJobToPipelinePathParams;

export const ReSubmitCaptureJobToPipeline = ({captureJobId, ...props}: ReSubmitCaptureJobToPipelineProps) => (
  <Mutate<CaptureJob, void, void, void, ReSubmitCaptureJobToPipelinePathParams>
    verb="POST"
    path={`/api/CaptureJobs/${captureJobId}/Resubmit`}
    
    {...props}
  />
);

export type UseReSubmitCaptureJobToPipelineProps = Omit<UseMutateProps<CaptureJob, void, void, void, ReSubmitCaptureJobToPipelinePathParams>, "path" | "verb"> & ReSubmitCaptureJobToPipelinePathParams;

export const useReSubmitCaptureJobToPipeline = ({captureJobId, ...props}: UseReSubmitCaptureJobToPipelineProps) => useMutate<CaptureJob, void, void, void, ReSubmitCaptureJobToPipelinePathParams>("POST", (paramsInPath: ReSubmitCaptureJobToPipelinePathParams) => `/api/CaptureJobs/${paramsInPath.captureJobId}/Resubmit`, {  pathParams: { captureJobId }, ...props });


export type SubmitCaptureJobToPipelineProps = Omit<MutateProps<boolean, void, void, CaptureJob, void>, "path" | "verb">;

export const SubmitCaptureJobToPipeline = (props: SubmitCaptureJobToPipelineProps) => (
  <Mutate<boolean, void, void, CaptureJob, void>
    verb="POST"
    path={`/api/CaptureJobs/Submit`}
    
    {...props}
  />
);

export type UseSubmitCaptureJobToPipelineProps = Omit<UseMutateProps<boolean, void, void, CaptureJob, void>, "path" | "verb">;

export const useSubmitCaptureJobToPipeline = (props: UseSubmitCaptureJobToPipelineProps) => useMutate<boolean, void, void, CaptureJob, void>("POST", `/api/CaptureJobs/Submit`, props);


export type PostCaptureObjectCustomAttributeProps = Omit<MutateProps<CaptureObjectCustomAttribute, void, void, CaptureObjectCustomAttribute, void>, "path" | "verb">;

export const PostCaptureObjectCustomAttribute = (props: PostCaptureObjectCustomAttributeProps) => (
  <Mutate<CaptureObjectCustomAttribute, void, void, CaptureObjectCustomAttribute, void>
    verb="POST"
    path={`/api/CaptureObjectCustomAttributes`}
    
    {...props}
  />
);

export type UsePostCaptureObjectCustomAttributeProps = Omit<UseMutateProps<CaptureObjectCustomAttribute, void, void, CaptureObjectCustomAttribute, void>, "path" | "verb">;

export const usePostCaptureObjectCustomAttribute = (props: UsePostCaptureObjectCustomAttributeProps) => useMutate<CaptureObjectCustomAttribute, void, void, CaptureObjectCustomAttribute, void>("POST", `/api/CaptureObjectCustomAttributes`, props);


export interface PutCaptureObjectCustomAttributeByIdPathParams {
  id: number
}

export type PutCaptureObjectCustomAttributeByIdProps = Omit<MutateProps<CaptureObjectCustomAttribute, void, void, CaptureObjectCustomAttribute, PutCaptureObjectCustomAttributeByIdPathParams>, "path" | "verb"> & PutCaptureObjectCustomAttributeByIdPathParams;

export const PutCaptureObjectCustomAttributeById = ({id, ...props}: PutCaptureObjectCustomAttributeByIdProps) => (
  <Mutate<CaptureObjectCustomAttribute, void, void, CaptureObjectCustomAttribute, PutCaptureObjectCustomAttributeByIdPathParams>
    verb="PUT"
    path={`/api/CaptureObjectCustomAttributes/${id}`}
    
    {...props}
  />
);

export type UsePutCaptureObjectCustomAttributeByIdProps = Omit<UseMutateProps<CaptureObjectCustomAttribute, void, void, CaptureObjectCustomAttribute, PutCaptureObjectCustomAttributeByIdPathParams>, "path" | "verb"> & PutCaptureObjectCustomAttributeByIdPathParams;

export const usePutCaptureObjectCustomAttributeById = ({id, ...props}: UsePutCaptureObjectCustomAttributeByIdProps) => useMutate<CaptureObjectCustomAttribute, void, void, CaptureObjectCustomAttribute, PutCaptureObjectCustomAttributeByIdPathParams>("PUT", (paramsInPath: PutCaptureObjectCustomAttributeByIdPathParams) => `/api/CaptureObjectCustomAttributes/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteCaptureObjectCustomAttributeProps = Omit<MutateProps<CaptureObjectCustomAttribute, void, void, number, void>, "path" | "verb">;

export const DeleteCaptureObjectCustomAttribute = (props: DeleteCaptureObjectCustomAttributeProps) => (
  <Mutate<CaptureObjectCustomAttribute, void, void, number, void>
    verb="DELETE"
    path={`/api/CaptureObjectCustomAttributes`}
    
    {...props}
  />
);

export type UseDeleteCaptureObjectCustomAttributeProps = Omit<UseMutateProps<CaptureObjectCustomAttribute, void, void, number, void>, "path" | "verb">;

export const useDeleteCaptureObjectCustomAttribute = (props: UseDeleteCaptureObjectCustomAttributeProps) => useMutate<CaptureObjectCustomAttribute, void, void, number, void>("DELETE", `/api/CaptureObjectCustomAttributes`, {   ...props });


export type GetCaptureObjectsProps = Omit<GetProps<CaptureObject[], void, void, void>, "path">;

export const GetCaptureObjects = (props: GetCaptureObjectsProps) => (
  <Get<CaptureObject[], void, void, void>
    path={`/api/CaptureObjects`}
    
    {...props}
  />
);

export type UseGetCaptureObjectsProps = Omit<UseGetProps<CaptureObject[], void, void, void>, "path">;

export const useGetCaptureObjects = (props: UseGetCaptureObjectsProps) => useGet<CaptureObject[], void, void, void>(`/api/CaptureObjects`, props);


export type PostCaptureObjectProps = Omit<MutateProps<CaptureObject, void, void, CaptureObject, void>, "path" | "verb">;

export const PostCaptureObject = (props: PostCaptureObjectProps) => (
  <Mutate<CaptureObject, void, void, CaptureObject, void>
    verb="POST"
    path={`/api/CaptureObjects`}
    
    {...props}
  />
);

export type UsePostCaptureObjectProps = Omit<UseMutateProps<CaptureObject, void, void, CaptureObject, void>, "path" | "verb">;

export const usePostCaptureObject = (props: UsePostCaptureObjectProps) => useMutate<CaptureObject, void, void, CaptureObject, void>("POST", `/api/CaptureObjects`, props);


export interface GetCaptureObjectByIdPathParams {
  id: number
}

export type GetCaptureObjectByIdProps = Omit<GetProps<CaptureObject, void, void, GetCaptureObjectByIdPathParams>, "path"> & GetCaptureObjectByIdPathParams;

export const GetCaptureObjectById = ({id, ...props}: GetCaptureObjectByIdProps) => (
  <Get<CaptureObject, void, void, GetCaptureObjectByIdPathParams>
    path={`/api/CaptureObjects/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureObjectByIdProps = Omit<UseGetProps<CaptureObject, void, void, GetCaptureObjectByIdPathParams>, "path"> & GetCaptureObjectByIdPathParams;

export const useGetCaptureObjectById = ({id, ...props}: UseGetCaptureObjectByIdProps) => useGet<CaptureObject, void, void, GetCaptureObjectByIdPathParams>((paramsInPath: GetCaptureObjectByIdPathParams) => `/api/CaptureObjects/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCaptureObjectByIdPathParams {
  id: number
}

export type PutCaptureObjectByIdProps = Omit<MutateProps<CaptureObject, void, void, CaptureObject, PutCaptureObjectByIdPathParams>, "path" | "verb"> & PutCaptureObjectByIdPathParams;

export const PutCaptureObjectById = ({id, ...props}: PutCaptureObjectByIdProps) => (
  <Mutate<CaptureObject, void, void, CaptureObject, PutCaptureObjectByIdPathParams>
    verb="PUT"
    path={`/api/CaptureObjects/${id}`}
    
    {...props}
  />
);

export type UsePutCaptureObjectByIdProps = Omit<UseMutateProps<CaptureObject, void, void, CaptureObject, PutCaptureObjectByIdPathParams>, "path" | "verb"> & PutCaptureObjectByIdPathParams;

export const usePutCaptureObjectById = ({id, ...props}: UsePutCaptureObjectByIdProps) => useMutate<CaptureObject, void, void, CaptureObject, PutCaptureObjectByIdPathParams>("PUT", (paramsInPath: PutCaptureObjectByIdPathParams) => `/api/CaptureObjects/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteCaptureObjectProps = Omit<MutateProps<CaptureObject, void, void, number, void>, "path" | "verb">;

export const DeleteCaptureObject = (props: DeleteCaptureObjectProps) => (
  <Mutate<CaptureObject, void, void, number, void>
    verb="DELETE"
    path={`/api/CaptureObjects`}
    
    {...props}
  />
);

export type UseDeleteCaptureObjectProps = Omit<UseMutateProps<CaptureObject, void, void, number, void>, "path" | "verb">;

export const useDeleteCaptureObject = (props: UseDeleteCaptureObjectProps) => useMutate<CaptureObject, void, void, number, void>("DELETE", `/api/CaptureObjects`, {   ...props });


export interface GetCaptureObjectsByCaptureIdPathParams {
  id: number
}

export type GetCaptureObjectsByCaptureIdProps = Omit<GetProps<CaptureObject[], void, void, GetCaptureObjectsByCaptureIdPathParams>, "path"> & GetCaptureObjectsByCaptureIdPathParams;

export const GetCaptureObjectsByCaptureId = ({id, ...props}: GetCaptureObjectsByCaptureIdProps) => (
  <Get<CaptureObject[], void, void, GetCaptureObjectsByCaptureIdPathParams>
    path={`/api/CaptureObjects/Capture/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureObjectsByCaptureIdProps = Omit<UseGetProps<CaptureObject[], void, void, GetCaptureObjectsByCaptureIdPathParams>, "path"> & GetCaptureObjectsByCaptureIdPathParams;

export const useGetCaptureObjectsByCaptureId = ({id, ...props}: UseGetCaptureObjectsByCaptureIdProps) => useGet<CaptureObject[], void, void, GetCaptureObjectsByCaptureIdPathParams>((paramsInPath: GetCaptureObjectsByCaptureIdPathParams) => `/api/CaptureObjects/Capture/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetNonExtrationCaptureObjectsByCaptureIdPathParams {
  id: number
}

export type GetNonExtrationCaptureObjectsByCaptureIdProps = Omit<GetProps<CaptureObject[], void, void, GetNonExtrationCaptureObjectsByCaptureIdPathParams>, "path"> & GetNonExtrationCaptureObjectsByCaptureIdPathParams;

export const GetNonExtrationCaptureObjectsByCaptureId = ({id, ...props}: GetNonExtrationCaptureObjectsByCaptureIdProps) => (
  <Get<CaptureObject[], void, void, GetNonExtrationCaptureObjectsByCaptureIdPathParams>
    path={`/api/CaptureObjects/Capture/${id}/NonExtraction`}
    
    {...props}
  />
);

export type UseGetNonExtrationCaptureObjectsByCaptureIdProps = Omit<UseGetProps<CaptureObject[], void, void, GetNonExtrationCaptureObjectsByCaptureIdPathParams>, "path"> & GetNonExtrationCaptureObjectsByCaptureIdPathParams;

export const useGetNonExtrationCaptureObjectsByCaptureId = ({id, ...props}: UseGetNonExtrationCaptureObjectsByCaptureIdProps) => useGet<CaptureObject[], void, void, GetNonExtrationCaptureObjectsByCaptureIdPathParams>((paramsInPath: GetNonExtrationCaptureObjectsByCaptureIdPathParams) => `/api/CaptureObjects/Capture/${paramsInPath.id}/NonExtraction`, {  pathParams: { id }, ...props });


export interface GetCaptureObjectsByCaptureExtractionJobIdPathParams {
  id: number
}

export type GetCaptureObjectsByCaptureExtractionJobIdProps = Omit<GetProps<CaptureObject[], void, void, GetCaptureObjectsByCaptureExtractionJobIdPathParams>, "path"> & GetCaptureObjectsByCaptureExtractionJobIdPathParams;

export const GetCaptureObjectsByCaptureExtractionJobId = ({id, ...props}: GetCaptureObjectsByCaptureExtractionJobIdProps) => (
  <Get<CaptureObject[], void, void, GetCaptureObjectsByCaptureExtractionJobIdPathParams>
    path={`/api/CaptureObjects/CaptureExtractionJob/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureObjectsByCaptureExtractionJobIdProps = Omit<UseGetProps<CaptureObject[], void, void, GetCaptureObjectsByCaptureExtractionJobIdPathParams>, "path"> & GetCaptureObjectsByCaptureExtractionJobIdPathParams;

export const useGetCaptureObjectsByCaptureExtractionJobId = ({id, ...props}: UseGetCaptureObjectsByCaptureExtractionJobIdProps) => useGet<CaptureObject[], void, void, GetCaptureObjectsByCaptureExtractionJobIdPathParams>((paramsInPath: GetCaptureObjectsByCaptureExtractionJobIdPathParams) => `/api/CaptureObjects/CaptureExtractionJob/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetCaptureObjectsByAnalyticRequestIdPathParams {
  id: number
}

export type GetCaptureObjectsByAnalyticRequestIdProps = Omit<GetProps<CaptureObject[], void, void, GetCaptureObjectsByAnalyticRequestIdPathParams>, "path"> & GetCaptureObjectsByAnalyticRequestIdPathParams;

export const GetCaptureObjectsByAnalyticRequestId = ({id, ...props}: GetCaptureObjectsByAnalyticRequestIdProps) => (
  <Get<CaptureObject[], void, void, GetCaptureObjectsByAnalyticRequestIdPathParams>
    path={`/api/CaptureObjects/AnalyticRequest/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureObjectsByAnalyticRequestIdProps = Omit<UseGetProps<CaptureObject[], void, void, GetCaptureObjectsByAnalyticRequestIdPathParams>, "path"> & GetCaptureObjectsByAnalyticRequestIdPathParams;

export const useGetCaptureObjectsByAnalyticRequestId = ({id, ...props}: UseGetCaptureObjectsByAnalyticRequestIdProps) => useGet<CaptureObject[], void, void, GetCaptureObjectsByAnalyticRequestIdPathParams>((paramsInPath: GetCaptureObjectsByAnalyticRequestIdPathParams) => `/api/CaptureObjects/AnalyticRequest/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface ChangeCaptureObjectsValidationFromCaptureExtractionJobPathParams {
  captureExtractionJobId: number;
  validated: boolean
}

export type ChangeCaptureObjectsValidationFromCaptureExtractionJobProps = Omit<MutateProps<void, void, void, void, ChangeCaptureObjectsValidationFromCaptureExtractionJobPathParams>, "path" | "verb"> & ChangeCaptureObjectsValidationFromCaptureExtractionJobPathParams;

export const ChangeCaptureObjectsValidationFromCaptureExtractionJob = ({captureExtractionJobId, validated, ...props}: ChangeCaptureObjectsValidationFromCaptureExtractionJobProps) => (
  <Mutate<void, void, void, void, ChangeCaptureObjectsValidationFromCaptureExtractionJobPathParams>
    verb="POST"
    path={`/api/CaptureObjects/CaptureExtractionJob/${captureExtractionJobId}/Validated/${validated}`}
    
    {...props}
  />
);

export type UseChangeCaptureObjectsValidationFromCaptureExtractionJobProps = Omit<UseMutateProps<void, void, void, void, ChangeCaptureObjectsValidationFromCaptureExtractionJobPathParams>, "path" | "verb"> & ChangeCaptureObjectsValidationFromCaptureExtractionJobPathParams;

export const useChangeCaptureObjectsValidationFromCaptureExtractionJob = ({captureExtractionJobId, validated, ...props}: UseChangeCaptureObjectsValidationFromCaptureExtractionJobProps) => useMutate<void, void, void, void, ChangeCaptureObjectsValidationFromCaptureExtractionJobPathParams>("POST", (paramsInPath: ChangeCaptureObjectsValidationFromCaptureExtractionJobPathParams) => `/api/CaptureObjects/CaptureExtractionJob/${paramsInPath.captureExtractionJobId}/Validated/${paramsInPath.validated}`, {  pathParams: { captureExtractionJobId, validated }, ...props });


export interface ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobPathParams {
  captureExtractionJobId: number;
  validated: boolean
}

export type ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobProps = Omit<MutateProps<void, void, void, CaptureObjectFilter, ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobPathParams>, "path" | "verb"> & ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobPathParams;

export const ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJob = ({captureExtractionJobId, validated, ...props}: ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobProps) => (
  <Mutate<void, void, void, CaptureObjectFilter, ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobPathParams>
    verb="POST"
    path={`/api/CaptureObjects/CaptureExtractionJob/${captureExtractionJobId}/FilterValidate/${validated}`}
    
    {...props}
  />
);

export type UseChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobProps = Omit<UseMutateProps<void, void, void, CaptureObjectFilter, ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobPathParams>, "path" | "verb"> & ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobPathParams;

export const useChangeFilteredCaptureObjectsValidationFromCaptureExtractionJob = ({captureExtractionJobId, validated, ...props}: UseChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobProps) => useMutate<void, void, void, CaptureObjectFilter, ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobPathParams>("POST", (paramsInPath: ChangeFilteredCaptureObjectsValidationFromCaptureExtractionJobPathParams) => `/api/CaptureObjects/CaptureExtractionJob/${paramsInPath.captureExtractionJobId}/FilterValidate/${paramsInPath.validated}`, {  pathParams: { captureExtractionJobId, validated }, ...props });


export interface ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobPathParams {
  captureExtractionJobId: number;
  validated: boolean;
  polygon: string
}

export type ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobProps = Omit<MutateProps<void, void, void, void, ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobPathParams>, "path" | "verb"> & ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobPathParams;

export const ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob = ({captureExtractionJobId, validated, polygon, ...props}: ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobProps) => (
  <Mutate<void, void, void, void, ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobPathParams>
    verb="POST"
    path={`/api/CaptureObjects/CaptureExtractionJob/${captureExtractionJobId}/Validated/${validated}/polygon/${polygon}`}
    
    {...props}
  />
);

export type UseChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobProps = Omit<UseMutateProps<void, void, void, void, ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobPathParams>, "path" | "verb"> & ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobPathParams;

export const useChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJob = ({captureExtractionJobId, validated, polygon, ...props}: UseChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobProps) => useMutate<void, void, void, void, ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobPathParams>("POST", (paramsInPath: ChangeCaptureObjectsValidationGeometryByPolygonFromCaptureExtractionJobPathParams) => `/api/CaptureObjects/CaptureExtractionJob/${paramsInPath.captureExtractionJobId}/Validated/${paramsInPath.validated}/polygon/${paramsInPath.polygon}`, {  pathParams: { captureExtractionJobId, validated, polygon }, ...props });


export interface ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobPathParams {
  captureExtractionJobId: number;
  displayName: string
}

export type ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobProps = Omit<MutateProps<void, void, void, CaptureObjectFilter, ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobPathParams>, "path" | "verb"> & ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobPathParams;

export const ChangeCaptureObjectsDisplayNameFromCaptureExtractionJob = ({captureExtractionJobId, displayName, ...props}: ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobProps) => (
  <Mutate<void, void, void, CaptureObjectFilter, ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobPathParams>
    verb="POST"
    path={`/api/CaptureObjects/CaptureExtractionJob/${captureExtractionJobId}/DisplayName/${displayName}`}
    
    {...props}
  />
);

export type UseChangeCaptureObjectsDisplayNameFromCaptureExtractionJobProps = Omit<UseMutateProps<void, void, void, CaptureObjectFilter, ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobPathParams>, "path" | "verb"> & ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobPathParams;

export const useChangeCaptureObjectsDisplayNameFromCaptureExtractionJob = ({captureExtractionJobId, displayName, ...props}: UseChangeCaptureObjectsDisplayNameFromCaptureExtractionJobProps) => useMutate<void, void, void, CaptureObjectFilter, ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobPathParams>("POST", (paramsInPath: ChangeCaptureObjectsDisplayNameFromCaptureExtractionJobPathParams) => `/api/CaptureObjects/CaptureExtractionJob/${paramsInPath.captureExtractionJobId}/DisplayName/${paramsInPath.displayName}`, {  pathParams: { captureExtractionJobId, displayName }, ...props });


export type GetCaptureObjectTypesProps = Omit<GetProps<CaptureObjectType[], void, void, void>, "path">;

export const GetCaptureObjectTypes = (props: GetCaptureObjectTypesProps) => (
  <Get<CaptureObjectType[], void, void, void>
    path={`/api/CaptureObjectTypes`}
    
    {...props}
  />
);

export type UseGetCaptureObjectTypesProps = Omit<UseGetProps<CaptureObjectType[], void, void, void>, "path">;

export const useGetCaptureObjectTypes = (props: UseGetCaptureObjectTypesProps) => useGet<CaptureObjectType[], void, void, void>(`/api/CaptureObjectTypes`, props);


export type GetCaptureVideosProps = Omit<GetProps<CaptureVideo[], void, void, void>, "path">;

export const GetCaptureVideos = (props: GetCaptureVideosProps) => (
  <Get<CaptureVideo[], void, void, void>
    path={`/api/CaptureVideos`}
    
    {...props}
  />
);

export type UseGetCaptureVideosProps = Omit<UseGetProps<CaptureVideo[], void, void, void>, "path">;

export const useGetCaptureVideos = (props: UseGetCaptureVideosProps) => useGet<CaptureVideo[], void, void, void>(`/api/CaptureVideos`, props);


export interface GetCaptureVideoByIdPathParams {
  id: number
}

export type GetCaptureVideoByIdProps = Omit<GetProps<CaptureVideo, void, void, GetCaptureVideoByIdPathParams>, "path"> & GetCaptureVideoByIdPathParams;

export const GetCaptureVideoById = ({id, ...props}: GetCaptureVideoByIdProps) => (
  <Get<CaptureVideo, void, void, GetCaptureVideoByIdPathParams>
    path={`/api/CaptureVideos/${id}`}
    
    {...props}
  />
);

export type UseGetCaptureVideoByIdProps = Omit<UseGetProps<CaptureVideo, void, void, GetCaptureVideoByIdPathParams>, "path"> & GetCaptureVideoByIdPathParams;

export const useGetCaptureVideoById = ({id, ...props}: UseGetCaptureVideoByIdProps) => useGet<CaptureVideo, void, void, GetCaptureVideoByIdPathParams>((paramsInPath: GetCaptureVideoByIdPathParams) => `/api/CaptureVideos/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCaptureVideoByIdPathParams {
  id: number
}

export type PutCaptureVideoByIdProps = Omit<MutateProps<CaptureVideo, void, void, CaptureVideo, PutCaptureVideoByIdPathParams>, "path" | "verb"> & PutCaptureVideoByIdPathParams;

export const PutCaptureVideoById = ({id, ...props}: PutCaptureVideoByIdProps) => (
  <Mutate<CaptureVideo, void, void, CaptureVideo, PutCaptureVideoByIdPathParams>
    verb="PUT"
    path={`/api/CaptureVideos/${id}`}
    
    {...props}
  />
);

export type UsePutCaptureVideoByIdProps = Omit<UseMutateProps<CaptureVideo, void, void, CaptureVideo, PutCaptureVideoByIdPathParams>, "path" | "verb"> & PutCaptureVideoByIdPathParams;

export const usePutCaptureVideoById = ({id, ...props}: UsePutCaptureVideoByIdProps) => useMutate<CaptureVideo, void, void, CaptureVideo, PutCaptureVideoByIdPathParams>("PUT", (paramsInPath: PutCaptureVideoByIdPathParams) => `/api/CaptureVideos/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetCaptureVideoMetaByIdPathParams {
  id: number
}

export type GetCaptureVideoMetaByIdProps = Omit<GetProps<VideoMetadata, void, void, GetCaptureVideoMetaByIdPathParams>, "path"> & GetCaptureVideoMetaByIdPathParams;

export const GetCaptureVideoMetaById = ({id, ...props}: GetCaptureVideoMetaByIdProps) => (
  <Get<VideoMetadata, void, void, GetCaptureVideoMetaByIdPathParams>
    path={`/api/CaptureVideos/${id}/Meta`}
    
    {...props}
  />
);

export type UseGetCaptureVideoMetaByIdProps = Omit<UseGetProps<VideoMetadata, void, void, GetCaptureVideoMetaByIdPathParams>, "path"> & GetCaptureVideoMetaByIdPathParams;

export const useGetCaptureVideoMetaById = ({id, ...props}: UseGetCaptureVideoMetaByIdProps) => useGet<VideoMetadata, void, void, GetCaptureVideoMetaByIdPathParams>((paramsInPath: GetCaptureVideoMetaByIdPathParams) => `/api/CaptureVideos/${paramsInPath.id}/Meta`, {  pathParams: { id }, ...props });


export interface GetCaptureVideosByCaptureIdPathParams {
  captureId: number
}

export type GetCaptureVideosByCaptureIdProps = Omit<GetProps<CaptureVideo[], void, void, GetCaptureVideosByCaptureIdPathParams>, "path"> & GetCaptureVideosByCaptureIdPathParams;

export const GetCaptureVideosByCaptureId = ({captureId, ...props}: GetCaptureVideosByCaptureIdProps) => (
  <Get<CaptureVideo[], void, void, GetCaptureVideosByCaptureIdPathParams>
    path={`/api/CaptureVideos/Capture/${captureId}`}
    
    {...props}
  />
);

export type UseGetCaptureVideosByCaptureIdProps = Omit<UseGetProps<CaptureVideo[], void, void, GetCaptureVideosByCaptureIdPathParams>, "path"> & GetCaptureVideosByCaptureIdPathParams;

export const useGetCaptureVideosByCaptureId = ({captureId, ...props}: UseGetCaptureVideosByCaptureIdProps) => useGet<CaptureVideo[], void, void, GetCaptureVideosByCaptureIdPathParams>((paramsInPath: GetCaptureVideosByCaptureIdPathParams) => `/api/CaptureVideos/Capture/${paramsInPath.captureId}`, {  pathParams: { captureId }, ...props });


export interface GetCaptureVideosByCaptureExtractionJobIdPathParams {
  captureExtractionJobId: number
}

export type GetCaptureVideosByCaptureExtractionJobIdProps = Omit<GetProps<CaptureVideo[], void, void, GetCaptureVideosByCaptureExtractionJobIdPathParams>, "path"> & GetCaptureVideosByCaptureExtractionJobIdPathParams;

export const GetCaptureVideosByCaptureExtractionJobId = ({captureExtractionJobId, ...props}: GetCaptureVideosByCaptureExtractionJobIdProps) => (
  <Get<CaptureVideo[], void, void, GetCaptureVideosByCaptureExtractionJobIdPathParams>
    path={`/api/CaptureVideos/CaptureExtractionJob/${captureExtractionJobId}`}
    
    {...props}
  />
);

export type UseGetCaptureVideosByCaptureExtractionJobIdProps = Omit<UseGetProps<CaptureVideo[], void, void, GetCaptureVideosByCaptureExtractionJobIdPathParams>, "path"> & GetCaptureVideosByCaptureExtractionJobIdPathParams;

export const useGetCaptureVideosByCaptureExtractionJobId = ({captureExtractionJobId, ...props}: UseGetCaptureVideosByCaptureExtractionJobIdProps) => useGet<CaptureVideo[], void, void, GetCaptureVideosByCaptureExtractionJobIdPathParams>((paramsInPath: GetCaptureVideosByCaptureExtractionJobIdPathParams) => `/api/CaptureVideos/CaptureExtractionJob/${paramsInPath.captureExtractionJobId}`, {  pathParams: { captureExtractionJobId }, ...props });


export interface GetCaptureVideosByAnalyticRequestIdPathParams {
  analyticRequestId: number
}

export type GetCaptureVideosByAnalyticRequestIdProps = Omit<GetProps<CaptureVideo[], void, void, GetCaptureVideosByAnalyticRequestIdPathParams>, "path"> & GetCaptureVideosByAnalyticRequestIdPathParams;

export const GetCaptureVideosByAnalyticRequestId = ({analyticRequestId, ...props}: GetCaptureVideosByAnalyticRequestIdProps) => (
  <Get<CaptureVideo[], void, void, GetCaptureVideosByAnalyticRequestIdPathParams>
    path={`/api/CaptureVideos/AnalyticRequest/${analyticRequestId}`}
    
    {...props}
  />
);

export type UseGetCaptureVideosByAnalyticRequestIdProps = Omit<UseGetProps<CaptureVideo[], void, void, GetCaptureVideosByAnalyticRequestIdPathParams>, "path"> & GetCaptureVideosByAnalyticRequestIdPathParams;

export const useGetCaptureVideosByAnalyticRequestId = ({analyticRequestId, ...props}: UseGetCaptureVideosByAnalyticRequestIdProps) => useGet<CaptureVideo[], void, void, GetCaptureVideosByAnalyticRequestIdPathParams>((paramsInPath: GetCaptureVideosByAnalyticRequestIdPathParams) => `/api/CaptureVideos/AnalyticRequest/${paramsInPath.analyticRequestId}`, {  pathParams: { analyticRequestId }, ...props });


export interface GetCaptureVideoImageByIdPathParams {
  id: number;
  size: string;
  filters: string
}

export type GetCaptureVideoImageByIdProps = Omit<GetProps<string, void, void, GetCaptureVideoImageByIdPathParams>, "path"> & GetCaptureVideoImageByIdPathParams;

export const GetCaptureVideoImageById = ({id, size, filters, ...props}: GetCaptureVideoImageByIdProps) => (
  <Get<string, void, void, GetCaptureVideoImageByIdPathParams>
    path={`/api/CaptureVideos/${id}/Image/${size}/${filters}`}
    
    {...props}
  />
);

export type UseGetCaptureVideoImageByIdProps = Omit<UseGetProps<string, void, void, GetCaptureVideoImageByIdPathParams>, "path"> & GetCaptureVideoImageByIdPathParams;

export const useGetCaptureVideoImageById = ({id, size, filters, ...props}: UseGetCaptureVideoImageByIdProps) => useGet<string, void, void, GetCaptureVideoImageByIdPathParams>((paramsInPath: GetCaptureVideoImageByIdPathParams) => `/api/CaptureVideos/${paramsInPath.id}/Image/${paramsInPath.size}/${paramsInPath.filters}`, {  pathParams: { id, size, filters }, ...props });


export interface GetCollectionPathParams {
  id: number
}

export type GetCollectionProps = Omit<GetProps<Collection, void, void, GetCollectionPathParams>, "path"> & GetCollectionPathParams;

export const GetCollection = ({id, ...props}: GetCollectionProps) => (
  <Get<Collection, void, void, GetCollectionPathParams>
    path={`/api/Collections/${id}`}
    
    {...props}
  />
);

export type UseGetCollectionProps = Omit<UseGetProps<Collection, void, void, GetCollectionPathParams>, "path"> & GetCollectionPathParams;

export const useGetCollection = ({id, ...props}: UseGetCollectionProps) => useGet<Collection, void, void, GetCollectionPathParams>((paramsInPath: GetCollectionPathParams) => `/api/Collections/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteCollectionProps = Omit<MutateProps<Collection, void, void, number, void>, "path" | "verb">;

export const DeleteCollection = (props: DeleteCollectionProps) => (
  <Mutate<Collection, void, void, number, void>
    verb="DELETE"
    path={`/api/Collections`}
    
    {...props}
  />
);

export type UseDeleteCollectionProps = Omit<UseMutateProps<Collection, void, void, number, void>, "path" | "verb">;

export const useDeleteCollection = (props: UseDeleteCollectionProps) => useMutate<Collection, void, void, number, void>("DELETE", `/api/Collections`, {   ...props });


export interface GetCollectionSummaryPathParams {
  id: number
}

export type GetCollectionSummaryProps = Omit<GetProps<CollectionSummary, void, void, GetCollectionSummaryPathParams>, "path"> & GetCollectionSummaryPathParams;

export const GetCollectionSummary = ({id, ...props}: GetCollectionSummaryProps) => (
  <Get<CollectionSummary, void, void, GetCollectionSummaryPathParams>
    path={`/api/Collections/${id}/Summary`}
    
    {...props}
  />
);

export type UseGetCollectionSummaryProps = Omit<UseGetProps<CollectionSummary, void, void, GetCollectionSummaryPathParams>, "path"> & GetCollectionSummaryPathParams;

export const useGetCollectionSummary = ({id, ...props}: UseGetCollectionSummaryProps) => useGet<CollectionSummary, void, void, GetCollectionSummaryPathParams>((paramsInPath: GetCollectionSummaryPathParams) => `/api/Collections/${paramsInPath.id}/Summary`, {  pathParams: { id }, ...props });


export interface GetMorphologiesByCollectionIdPathParams {
  collectionId: number
}

export type GetMorphologiesByCollectionIdProps = Omit<GetProps<Morphology[], void, void, GetMorphologiesByCollectionIdPathParams>, "path"> & GetMorphologiesByCollectionIdPathParams;

export const GetMorphologiesByCollectionId = ({collectionId, ...props}: GetMorphologiesByCollectionIdProps) => (
  <Get<Morphology[], void, void, GetMorphologiesByCollectionIdPathParams>
    path={`/api/Collections/${collectionId}/Morphologies`}
    
    {...props}
  />
);

export type UseGetMorphologiesByCollectionIdProps = Omit<UseGetProps<Morphology[], void, void, GetMorphologiesByCollectionIdPathParams>, "path"> & GetMorphologiesByCollectionIdPathParams;

export const useGetMorphologiesByCollectionId = ({collectionId, ...props}: UseGetMorphologiesByCollectionIdProps) => useGet<Morphology[], void, void, GetMorphologiesByCollectionIdPathParams>((paramsInPath: GetMorphologiesByCollectionIdPathParams) => `/api/Collections/${paramsInPath.collectionId}/Morphologies`, {  pathParams: { collectionId }, ...props });


export interface GetMorphologiesPaginatedByCollectionIdPathParams {
  collectionId: number;
  pageNumber: number;
  pageSize: number
}

export type GetMorphologiesPaginatedByCollectionIdProps = Omit<GetProps<Morphology[], void, void, GetMorphologiesPaginatedByCollectionIdPathParams>, "path"> & GetMorphologiesPaginatedByCollectionIdPathParams;

export const GetMorphologiesPaginatedByCollectionId = ({collectionId, pageNumber, pageSize, ...props}: GetMorphologiesPaginatedByCollectionIdProps) => (
  <Get<Morphology[], void, void, GetMorphologiesPaginatedByCollectionIdPathParams>
    path={`/api/Collections/${collectionId}/Morphologies/${pageNumber}/${pageSize}`}
    
    {...props}
  />
);

export type UseGetMorphologiesPaginatedByCollectionIdProps = Omit<UseGetProps<Morphology[], void, void, GetMorphologiesPaginatedByCollectionIdPathParams>, "path"> & GetMorphologiesPaginatedByCollectionIdPathParams;

export const useGetMorphologiesPaginatedByCollectionId = ({collectionId, pageNumber, pageSize, ...props}: UseGetMorphologiesPaginatedByCollectionIdProps) => useGet<Morphology[], void, void, GetMorphologiesPaginatedByCollectionIdPathParams>((paramsInPath: GetMorphologiesPaginatedByCollectionIdPathParams) => `/api/Collections/${paramsInPath.collectionId}/Morphologies/${paramsInPath.pageNumber}/${paramsInPath.pageSize}`, {  pathParams: { collectionId, pageNumber, pageSize }, ...props });


export interface GetOrderedMorphologiesPaginatedByCollectionIdPathParams {
  collectionId: number;
  pageNumber: number;
  pageSize: number;
  attributeName: string
}

export type GetOrderedMorphologiesPaginatedByCollectionIdProps = Omit<GetProps<Morphology[], void, void, GetOrderedMorphologiesPaginatedByCollectionIdPathParams>, "path"> & GetOrderedMorphologiesPaginatedByCollectionIdPathParams;

export const GetOrderedMorphologiesPaginatedByCollectionId = ({collectionId, pageNumber, pageSize, attributeName, ...props}: GetOrderedMorphologiesPaginatedByCollectionIdProps) => (
  <Get<Morphology[], void, void, GetOrderedMorphologiesPaginatedByCollectionIdPathParams>
    path={`/api/Collections/${collectionId}/Morphologies/${pageNumber}/${pageSize}/OrderBy/${attributeName}`}
    
    {...props}
  />
);

export type UseGetOrderedMorphologiesPaginatedByCollectionIdProps = Omit<UseGetProps<Morphology[], void, void, GetOrderedMorphologiesPaginatedByCollectionIdPathParams>, "path"> & GetOrderedMorphologiesPaginatedByCollectionIdPathParams;

export const useGetOrderedMorphologiesPaginatedByCollectionId = ({collectionId, pageNumber, pageSize, attributeName, ...props}: UseGetOrderedMorphologiesPaginatedByCollectionIdProps) => useGet<Morphology[], void, void, GetOrderedMorphologiesPaginatedByCollectionIdPathParams>((paramsInPath: GetOrderedMorphologiesPaginatedByCollectionIdPathParams) => `/api/Collections/${paramsInPath.collectionId}/Morphologies/${paramsInPath.pageNumber}/${paramsInPath.pageSize}/OrderBy/${paramsInPath.attributeName}`, {  pathParams: { collectionId, pageNumber, pageSize, attributeName }, ...props });


export interface GetOrderedDescendingMorphologiesPaginatedByCollectionIdPathParams {
  collectionId: number;
  pageNumber: number;
  pageSize: number;
  attributeName: string
}

export type GetOrderedDescendingMorphologiesPaginatedByCollectionIdProps = Omit<GetProps<Morphology[], void, void, GetOrderedDescendingMorphologiesPaginatedByCollectionIdPathParams>, "path"> & GetOrderedDescendingMorphologiesPaginatedByCollectionIdPathParams;

export const GetOrderedDescendingMorphologiesPaginatedByCollectionId = ({collectionId, pageNumber, pageSize, attributeName, ...props}: GetOrderedDescendingMorphologiesPaginatedByCollectionIdProps) => (
  <Get<Morphology[], void, void, GetOrderedDescendingMorphologiesPaginatedByCollectionIdPathParams>
    path={`/api/Collections/${collectionId}/Morphologies/${pageNumber}/${pageSize}/OrderByDescending/${attributeName}`}
    
    {...props}
  />
);

export type UseGetOrderedDescendingMorphologiesPaginatedByCollectionIdProps = Omit<UseGetProps<Morphology[], void, void, GetOrderedDescendingMorphologiesPaginatedByCollectionIdPathParams>, "path"> & GetOrderedDescendingMorphologiesPaginatedByCollectionIdPathParams;

export const useGetOrderedDescendingMorphologiesPaginatedByCollectionId = ({collectionId, pageNumber, pageSize, attributeName, ...props}: UseGetOrderedDescendingMorphologiesPaginatedByCollectionIdProps) => useGet<Morphology[], void, void, GetOrderedDescendingMorphologiesPaginatedByCollectionIdPathParams>((paramsInPath: GetOrderedDescendingMorphologiesPaginatedByCollectionIdPathParams) => `/api/Collections/${paramsInPath.collectionId}/Morphologies/${paramsInPath.pageNumber}/${paramsInPath.pageSize}/OrderByDescending/${paramsInPath.attributeName}`, {  pathParams: { collectionId, pageNumber, pageSize, attributeName }, ...props });


export interface GetMorphologyHistogramByCollectionIdPathParams {
  collectionId: number;
  attributeName: string;
  binCount: number;
  classificationMethod: string
}

export type GetMorphologyHistogramByCollectionIdProps = Omit<GetProps<Histogram, void, void, GetMorphologyHistogramByCollectionIdPathParams>, "path"> & GetMorphologyHistogramByCollectionIdPathParams;

export const GetMorphologyHistogramByCollectionId = ({collectionId, attributeName, binCount, classificationMethod, ...props}: GetMorphologyHistogramByCollectionIdProps) => (
  <Get<Histogram, void, void, GetMorphologyHistogramByCollectionIdPathParams>
    path={`/api/Collections/${collectionId}/Morphologies/Histogram/${attributeName}/${binCount}/${classificationMethod}`}
    
    {...props}
  />
);

export type UseGetMorphologyHistogramByCollectionIdProps = Omit<UseGetProps<Histogram, void, void, GetMorphologyHistogramByCollectionIdPathParams>, "path"> & GetMorphologyHistogramByCollectionIdPathParams;

export const useGetMorphologyHistogramByCollectionId = ({collectionId, attributeName, binCount, classificationMethod, ...props}: UseGetMorphologyHistogramByCollectionIdProps) => useGet<Histogram, void, void, GetMorphologyHistogramByCollectionIdPathParams>((paramsInPath: GetMorphologyHistogramByCollectionIdPathParams) => `/api/Collections/${paramsInPath.collectionId}/Morphologies/Histogram/${paramsInPath.attributeName}/${paramsInPath.binCount}/${paramsInPath.classificationMethod}`, {  pathParams: { collectionId, attributeName, binCount, classificationMethod }, ...props });


export interface GetMorphologyCategorizedBinsByCollectionIdPathParams {
  collectionId: number;
  attributeName: string
}

export type GetMorphologyCategorizedBinsByCollectionIdProps = Omit<GetProps<HistogramCategorized, void, void, GetMorphologyCategorizedBinsByCollectionIdPathParams>, "path"> & GetMorphologyCategorizedBinsByCollectionIdPathParams;

export const GetMorphologyCategorizedBinsByCollectionId = ({collectionId, attributeName, ...props}: GetMorphologyCategorizedBinsByCollectionIdProps) => (
  <Get<HistogramCategorized, void, void, GetMorphologyCategorizedBinsByCollectionIdPathParams>
    path={`/api/Collections/${collectionId}/Morphologies/CategorizedHistogram/${attributeName}`}
    
    {...props}
  />
);

export type UseGetMorphologyCategorizedBinsByCollectionIdProps = Omit<UseGetProps<HistogramCategorized, void, void, GetMorphologyCategorizedBinsByCollectionIdPathParams>, "path"> & GetMorphologyCategorizedBinsByCollectionIdPathParams;

export const useGetMorphologyCategorizedBinsByCollectionId = ({collectionId, attributeName, ...props}: UseGetMorphologyCategorizedBinsByCollectionIdProps) => useGet<HistogramCategorized, void, void, GetMorphologyCategorizedBinsByCollectionIdPathParams>((paramsInPath: GetMorphologyCategorizedBinsByCollectionIdPathParams) => `/api/Collections/${paramsInPath.collectionId}/Morphologies/CategorizedHistogram/${paramsInPath.attributeName}`, {  pathParams: { collectionId, attributeName }, ...props });


export interface UploadGeojsonToCollectionPathParams {
  id: number
}

export type UploadGeojsonToCollectionProps = Omit<MutateProps<Collection, void, void, void, UploadGeojsonToCollectionPathParams>, "path" | "verb"> & UploadGeojsonToCollectionPathParams;

export const UploadGeojsonToCollection = ({id, ...props}: UploadGeojsonToCollectionProps) => (
  <Mutate<Collection, void, void, void, UploadGeojsonToCollectionPathParams>
    verb="POST"
    path={`/api/Collections/${id}/Upload`}
    
    {...props}
  />
);

export type UseUploadGeojsonToCollectionProps = Omit<UseMutateProps<Collection, void, void, void, UploadGeojsonToCollectionPathParams>, "path" | "verb"> & UploadGeojsonToCollectionPathParams;

export const useUploadGeojsonToCollection = ({id, ...props}: UseUploadGeojsonToCollectionProps) => useMutate<Collection, void, void, void, UploadGeojsonToCollectionPathParams>("POST", (paramsInPath: UploadGeojsonToCollectionPathParams) => `/api/Collections/${paramsInPath.id}/Upload`, {  pathParams: { id }, ...props });


export type PostCollectionProps = Omit<MutateProps<Collection, void, void, Collection, void>, "path" | "verb">;

export const PostCollection = (props: PostCollectionProps) => (
  <Mutate<Collection, void, void, Collection, void>
    verb="POST"
    path={`/api/Collections`}
    
    {...props}
  />
);

export type UsePostCollectionProps = Omit<UseMutateProps<Collection, void, void, Collection, void>, "path" | "verb">;

export const usePostCollection = (props: UsePostCollectionProps) => useMutate<Collection, void, void, Collection, void>("POST", `/api/Collections`, props);


export type GetCountriesProps = Omit<GetProps<Country[], void, void, void>, "path">;

export const GetCountries = (props: GetCountriesProps) => (
  <Get<Country[], void, void, void>
    path={`/api/Countries`}
    
    {...props}
  />
);

export type UseGetCountriesProps = Omit<UseGetProps<Country[], void, void, void>, "path">;

export const useGetCountries = (props: UseGetCountriesProps) => useGet<Country[], void, void, void>(`/api/Countries`, props);


export type PostCountryProps = Omit<MutateProps<Country, void, void, Country, void>, "path" | "verb">;

export const PostCountry = (props: PostCountryProps) => (
  <Mutate<Country, void, void, Country, void>
    verb="POST"
    path={`/api/Countries`}
    
    {...props}
  />
);

export type UsePostCountryProps = Omit<UseMutateProps<Country, void, void, Country, void>, "path" | "verb">;

export const usePostCountry = (props: UsePostCountryProps) => useMutate<Country, void, void, Country, void>("POST", `/api/Countries`, props);


export interface GetCountryPathParams {
  id: number
}

export type GetCountryProps = Omit<GetProps<Country, void, void, GetCountryPathParams>, "path"> & GetCountryPathParams;

export const GetCountry = ({id, ...props}: GetCountryProps) => (
  <Get<Country, void, void, GetCountryPathParams>
    path={`/api/Countries/${id}`}
    
    {...props}
  />
);

export type UseGetCountryProps = Omit<UseGetProps<Country, void, void, GetCountryPathParams>, "path"> & GetCountryPathParams;

export const useGetCountry = ({id, ...props}: UseGetCountryProps) => useGet<Country, void, void, GetCountryPathParams>((paramsInPath: GetCountryPathParams) => `/api/Countries/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCountryPathParams {
  id: number
}

export type PutCountryProps = Omit<MutateProps<Country, void, void, Country, PutCountryPathParams>, "path" | "verb"> & PutCountryPathParams;

export const PutCountry = ({id, ...props}: PutCountryProps) => (
  <Mutate<Country, void, void, Country, PutCountryPathParams>
    verb="PUT"
    path={`/api/Countries/${id}`}
    
    {...props}
  />
);

export type UsePutCountryProps = Omit<UseMutateProps<Country, void, void, Country, PutCountryPathParams>, "path" | "verb"> & PutCountryPathParams;

export const usePutCountry = ({id, ...props}: UsePutCountryProps) => useMutate<Country, void, void, Country, PutCountryPathParams>("PUT", (paramsInPath: PutCountryPathParams) => `/api/Countries/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteCountryProps = Omit<MutateProps<Country, void, void, number, void>, "path" | "verb">;

export const DeleteCountry = (props: DeleteCountryProps) => (
  <Mutate<Country, void, void, number, void>
    verb="DELETE"
    path={`/api/Countries`}
    
    {...props}
  />
);

export type UseDeleteCountryProps = Omit<UseMutateProps<Country, void, void, number, void>, "path" | "verb">;

export const useDeleteCountry = (props: UseDeleteCountryProps) => useMutate<Country, void, void, number, void>("DELETE", `/api/Countries`, {   ...props });


export interface GetCountryByRegionIdPathParams {
  id: number
}

export type GetCountryByRegionIdProps = Omit<GetProps<Country, void, void, GetCountryByRegionIdPathParams>, "path"> & GetCountryByRegionIdPathParams;

export const GetCountryByRegionId = ({id, ...props}: GetCountryByRegionIdProps) => (
  <Get<Country, void, void, GetCountryByRegionIdPathParams>
    path={`/api/Countries/Region/${id}`}
    
    {...props}
  />
);

export type UseGetCountryByRegionIdProps = Omit<UseGetProps<Country, void, void, GetCountryByRegionIdPathParams>, "path"> & GetCountryByRegionIdPathParams;

export const useGetCountryByRegionId = ({id, ...props}: UseGetCountryByRegionIdProps) => useGet<Country, void, void, GetCountryByRegionIdPathParams>((paramsInPath: GetCountryByRegionIdPathParams) => `/api/Countries/Region/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type SendMessageProps = Omit<MutateProps<string, string | void, void, Notification, void>, "path" | "verb">;

export const SendMessage = (props: SendMessageProps) => (
  <Mutate<string, string | void, void, Notification, void>
    verb="POST"
    path={`/api/notification`}
    
    {...props}
  />
);

export type UseSendMessageProps = Omit<UseMutateProps<string, string | void, void, Notification, void>, "path" | "verb">;

export const useSendMessage = (props: UseSendMessageProps) => useMutate<string, string | void, void, Notification, void>("POST", `/api/notification`, props);


export type GetCropProps = Omit<GetProps<Crop[], void, void, void>, "path">;

export const GetCrop = (props: GetCropProps) => (
  <Get<Crop[], void, void, void>
    path={`/api/Crops`}
    
    {...props}
  />
);

export type UseGetCropProps = Omit<UseGetProps<Crop[], void, void, void>, "path">;

export const useGetCrop = (props: UseGetCropProps) => useGet<Crop[], void, void, void>(`/api/Crops`, props);


export type PostCropProps = Omit<MutateProps<Crop, void, void, Crop, void>, "path" | "verb">;

export const PostCrop = (props: PostCropProps) => (
  <Mutate<Crop, void, void, Crop, void>
    verb="POST"
    path={`/api/Crops`}
    
    {...props}
  />
);

export type UsePostCropProps = Omit<UseMutateProps<Crop, void, void, Crop, void>, "path" | "verb">;

export const usePostCrop = (props: UsePostCropProps) => useMutate<Crop, void, void, Crop, void>("POST", `/api/Crops`, props);


export interface GetCropByIdPathParams {
  id: number
}

export type GetCropByIdProps = Omit<GetProps<Crop, void, void, GetCropByIdPathParams>, "path"> & GetCropByIdPathParams;

export const GetCropById = ({id, ...props}: GetCropByIdProps) => (
  <Get<Crop, void, void, GetCropByIdPathParams>
    path={`/api/Crops/${id}`}
    
    {...props}
  />
);

export type UseGetCropByIdProps = Omit<UseGetProps<Crop, void, void, GetCropByIdPathParams>, "path"> & GetCropByIdPathParams;

export const useGetCropById = ({id, ...props}: UseGetCropByIdProps) => useGet<Crop, void, void, GetCropByIdPathParams>((paramsInPath: GetCropByIdPathParams) => `/api/Crops/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCropByIdPathParams {
  id: number
}

export type PutCropByIdProps = Omit<MutateProps<Crop, void, void, Crop, PutCropByIdPathParams>, "path" | "verb"> & PutCropByIdPathParams;

export const PutCropById = ({id, ...props}: PutCropByIdProps) => (
  <Mutate<Crop, void, void, Crop, PutCropByIdPathParams>
    verb="PUT"
    path={`/api/Crops/${id}`}
    
    {...props}
  />
);

export type UsePutCropByIdProps = Omit<UseMutateProps<Crop, void, void, Crop, PutCropByIdPathParams>, "path" | "verb"> & PutCropByIdPathParams;

export const usePutCropById = ({id, ...props}: UsePutCropByIdProps) => useMutate<Crop, void, void, Crop, PutCropByIdPathParams>("PUT", (paramsInPath: PutCropByIdPathParams) => `/api/Crops/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteCropProps = Omit<MutateProps<Crop, void, void, number, void>, "path" | "verb">;

export const DeleteCrop = (props: DeleteCropProps) => (
  <Mutate<Crop, void, void, number, void>
    verb="DELETE"
    path={`/api/Crops`}
    
    {...props}
  />
);

export type UseDeleteCropProps = Omit<UseMutateProps<Crop, void, void, number, void>, "path" | "verb">;

export const useDeleteCrop = (props: UseDeleteCropProps) => useMutate<Crop, void, void, number, void>("DELETE", `/api/Crops`, {   ...props });


export interface GetCropImagePathPathParams {
  collectionId: number
}

export type GetCropImagePathProps = Omit<GetProps<Crop, void, void, GetCropImagePathPathParams>, "path"> & GetCropImagePathPathParams;

export const GetCropImagePath = ({collectionId, ...props}: GetCropImagePathProps) => (
  <Get<Crop, void, void, GetCropImagePathPathParams>
    path={`/api/Crops/Collections/${collectionId}`}
    
    {...props}
  />
);

export type UseGetCropImagePathProps = Omit<UseGetProps<Crop, void, void, GetCropImagePathPathParams>, "path"> & GetCropImagePathPathParams;

export const useGetCropImagePath = ({collectionId, ...props}: UseGetCropImagePathProps) => useGet<Crop, void, void, GetCropImagePathPathParams>((paramsInPath: GetCropImagePathPathParams) => `/api/Crops/Collections/${paramsInPath.collectionId}`, {  pathParams: { collectionId }, ...props });


export interface GetCropWithVaritiesPathParams {
  id: number
}

export type GetCropWithVaritiesProps = Omit<GetProps<Crop[], void, void, GetCropWithVaritiesPathParams>, "path"> & GetCropWithVaritiesPathParams;

export const GetCropWithVarities = ({id, ...props}: GetCropWithVaritiesProps) => (
  <Get<Crop[], void, void, GetCropWithVaritiesPathParams>
    path={`/api/Crops/${id}/Varieties`}
    
    {...props}
  />
);

export type UseGetCropWithVaritiesProps = Omit<UseGetProps<Crop[], void, void, GetCropWithVaritiesPathParams>, "path"> & GetCropWithVaritiesPathParams;

export const useGetCropWithVarities = ({id, ...props}: UseGetCropWithVaritiesProps) => useGet<Crop[], void, void, GetCropWithVaritiesPathParams>((paramsInPath: GetCropWithVaritiesPathParams) => `/api/Crops/${paramsInPath.id}/Varieties`, {  pathParams: { id }, ...props });


export type GetCustomAttributeUnitsProps = Omit<GetProps<CustomAttributeUnit[], void, void, void>, "path">;

export const GetCustomAttributeUnits = (props: GetCustomAttributeUnitsProps) => (
  <Get<CustomAttributeUnit[], void, void, void>
    path={`/api/CustomAttributeUnit`}
    
    {...props}
  />
);

export type UseGetCustomAttributeUnitsProps = Omit<UseGetProps<CustomAttributeUnit[], void, void, void>, "path">;

export const useGetCustomAttributeUnits = (props: UseGetCustomAttributeUnitsProps) => useGet<CustomAttributeUnit[], void, void, void>(`/api/CustomAttributeUnit`, props);


export interface GetCustomAttributeUnitByIdPathParams {
  id: number
}

export type GetCustomAttributeUnitByIdProps = Omit<GetProps<CustomAttributeUnit, void, void, GetCustomAttributeUnitByIdPathParams>, "path"> & GetCustomAttributeUnitByIdPathParams;

export const GetCustomAttributeUnitById = ({id, ...props}: GetCustomAttributeUnitByIdProps) => (
  <Get<CustomAttributeUnit, void, void, GetCustomAttributeUnitByIdPathParams>
    path={`/api/CustomAttributeUnit/${id}`}
    
    {...props}
  />
);

export type UseGetCustomAttributeUnitByIdProps = Omit<UseGetProps<CustomAttributeUnit, void, void, GetCustomAttributeUnitByIdPathParams>, "path"> & GetCustomAttributeUnitByIdPathParams;

export const useGetCustomAttributeUnitById = ({id, ...props}: UseGetCustomAttributeUnitByIdProps) => useGet<CustomAttributeUnit, void, void, GetCustomAttributeUnitByIdPathParams>((paramsInPath: GetCustomAttributeUnitByIdPathParams) => `/api/CustomAttributeUnit/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetCustomAttributeUnitsByNamePathParams {
  name: string
}

export type GetCustomAttributeUnitsByNameProps = Omit<GetProps<CustomAttributeUnit[], void, void, GetCustomAttributeUnitsByNamePathParams>, "path"> & GetCustomAttributeUnitsByNamePathParams;

export const GetCustomAttributeUnitsByName = ({name, ...props}: GetCustomAttributeUnitsByNameProps) => (
  <Get<CustomAttributeUnit[], void, void, GetCustomAttributeUnitsByNamePathParams>
    path={`/api/CustomAttributeUnit/SearchByName/${name}`}
    
    {...props}
  />
);

export type UseGetCustomAttributeUnitsByNameProps = Omit<UseGetProps<CustomAttributeUnit[], void, void, GetCustomAttributeUnitsByNamePathParams>, "path"> & GetCustomAttributeUnitsByNamePathParams;

export const useGetCustomAttributeUnitsByName = ({name, ...props}: UseGetCustomAttributeUnitsByNameProps) => useGet<CustomAttributeUnit[], void, void, GetCustomAttributeUnitsByNamePathParams>((paramsInPath: GetCustomAttributeUnitsByNamePathParams) => `/api/CustomAttributeUnit/SearchByName/${paramsInPath.name}`, {  pathParams: { name }, ...props });


export type GetCustomerAnalyticProps = Omit<GetProps<CustomerAnalytic[], void, void, void>, "path">;

export const GetCustomerAnalytic = (props: GetCustomerAnalyticProps) => (
  <Get<CustomerAnalytic[], void, void, void>
    path={`/api/CustomerAnalytics`}
    
    {...props}
  />
);

export type UseGetCustomerAnalyticProps = Omit<UseGetProps<CustomerAnalytic[], void, void, void>, "path">;

export const useGetCustomerAnalytic = (props: UseGetCustomerAnalyticProps) => useGet<CustomerAnalytic[], void, void, void>(`/api/CustomerAnalytics`, props);


export type PostCustomerAnalyticProps = Omit<MutateProps<CustomerAnalytic, void, void, CustomerAnalytic, void>, "path" | "verb">;

export const PostCustomerAnalytic = (props: PostCustomerAnalyticProps) => (
  <Mutate<CustomerAnalytic, void, void, CustomerAnalytic, void>
    verb="POST"
    path={`/api/CustomerAnalytics`}
    
    {...props}
  />
);

export type UsePostCustomerAnalyticProps = Omit<UseMutateProps<CustomerAnalytic, void, void, CustomerAnalytic, void>, "path" | "verb">;

export const usePostCustomerAnalytic = (props: UsePostCustomerAnalyticProps) => useMutate<CustomerAnalytic, void, void, CustomerAnalytic, void>("POST", `/api/CustomerAnalytics`, props);


export interface GetCustomerAnalyticByIdPathParams {
  id: number
}

export type GetCustomerAnalyticByIdProps = Omit<GetProps<CustomerAnalytic, void, void, GetCustomerAnalyticByIdPathParams>, "path"> & GetCustomerAnalyticByIdPathParams;

export const GetCustomerAnalyticById = ({id, ...props}: GetCustomerAnalyticByIdProps) => (
  <Get<CustomerAnalytic, void, void, GetCustomerAnalyticByIdPathParams>
    path={`/api/CustomerAnalytics/${id}`}
    
    {...props}
  />
);

export type UseGetCustomerAnalyticByIdProps = Omit<UseGetProps<CustomerAnalytic, void, void, GetCustomerAnalyticByIdPathParams>, "path"> & GetCustomerAnalyticByIdPathParams;

export const useGetCustomerAnalyticById = ({id, ...props}: UseGetCustomerAnalyticByIdProps) => useGet<CustomerAnalytic, void, void, GetCustomerAnalyticByIdPathParams>((paramsInPath: GetCustomerAnalyticByIdPathParams) => `/api/CustomerAnalytics/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCustomerAnalyticByIdPathParams {
  id: number
}

export type PutCustomerAnalyticByIdProps = Omit<MutateProps<CustomerAnalytic, void, void, CustomerAnalytic, PutCustomerAnalyticByIdPathParams>, "path" | "verb"> & PutCustomerAnalyticByIdPathParams;

export const PutCustomerAnalyticById = ({id, ...props}: PutCustomerAnalyticByIdProps) => (
  <Mutate<CustomerAnalytic, void, void, CustomerAnalytic, PutCustomerAnalyticByIdPathParams>
    verb="PUT"
    path={`/api/CustomerAnalytics/${id}`}
    
    {...props}
  />
);

export type UsePutCustomerAnalyticByIdProps = Omit<UseMutateProps<CustomerAnalytic, void, void, CustomerAnalytic, PutCustomerAnalyticByIdPathParams>, "path" | "verb"> & PutCustomerAnalyticByIdPathParams;

export const usePutCustomerAnalyticById = ({id, ...props}: UsePutCustomerAnalyticByIdProps) => useMutate<CustomerAnalytic, void, void, CustomerAnalytic, PutCustomerAnalyticByIdPathParams>("PUT", (paramsInPath: PutCustomerAnalyticByIdPathParams) => `/api/CustomerAnalytics/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetCustomerAnalyticsByCustomerIdPathParams {
  customerId: number
}

export type GetCustomerAnalyticsByCustomerIdProps = Omit<GetProps<CustomerAnalytic[], void, void, GetCustomerAnalyticsByCustomerIdPathParams>, "path"> & GetCustomerAnalyticsByCustomerIdPathParams;

export const GetCustomerAnalyticsByCustomerId = ({customerId, ...props}: GetCustomerAnalyticsByCustomerIdProps) => (
  <Get<CustomerAnalytic[], void, void, GetCustomerAnalyticsByCustomerIdPathParams>
    path={`/api/CustomerAnalytics/Customer/${customerId}`}
    
    {...props}
  />
);

export type UseGetCustomerAnalyticsByCustomerIdProps = Omit<UseGetProps<CustomerAnalytic[], void, void, GetCustomerAnalyticsByCustomerIdPathParams>, "path"> & GetCustomerAnalyticsByCustomerIdPathParams;

export const useGetCustomerAnalyticsByCustomerId = ({customerId, ...props}: UseGetCustomerAnalyticsByCustomerIdProps) => useGet<CustomerAnalytic[], void, void, GetCustomerAnalyticsByCustomerIdPathParams>((paramsInPath: GetCustomerAnalyticsByCustomerIdPathParams) => `/api/CustomerAnalytics/Customer/${paramsInPath.customerId}`, {  pathParams: { customerId }, ...props });


export interface GetCustomerAnalyticsByAnalyticIdPathParams {
  analyticId: number
}

export type GetCustomerAnalyticsByAnalyticIdProps = Omit<GetProps<CustomerAnalytic[], void, void, GetCustomerAnalyticsByAnalyticIdPathParams>, "path"> & GetCustomerAnalyticsByAnalyticIdPathParams;

export const GetCustomerAnalyticsByAnalyticId = ({analyticId, ...props}: GetCustomerAnalyticsByAnalyticIdProps) => (
  <Get<CustomerAnalytic[], void, void, GetCustomerAnalyticsByAnalyticIdPathParams>
    path={`/api/CustomerAnalytics/Analytic/${analyticId}`}
    
    {...props}
  />
);

export type UseGetCustomerAnalyticsByAnalyticIdProps = Omit<UseGetProps<CustomerAnalytic[], void, void, GetCustomerAnalyticsByAnalyticIdPathParams>, "path"> & GetCustomerAnalyticsByAnalyticIdPathParams;

export const useGetCustomerAnalyticsByAnalyticId = ({analyticId, ...props}: UseGetCustomerAnalyticsByAnalyticIdProps) => useGet<CustomerAnalytic[], void, void, GetCustomerAnalyticsByAnalyticIdPathParams>((paramsInPath: GetCustomerAnalyticsByAnalyticIdPathParams) => `/api/CustomerAnalytics/Analytic/${paramsInPath.analyticId}`, {  pathParams: { analyticId }, ...props });


export interface GetCustomerLicenseByCustomerIdPathParams {
  customerId: number
}

export type GetCustomerLicenseByCustomerIdProps = Omit<GetProps<CustomerLicense, void, void, GetCustomerLicenseByCustomerIdPathParams>, "path"> & GetCustomerLicenseByCustomerIdPathParams;

export const GetCustomerLicenseByCustomerId = ({customerId, ...props}: GetCustomerLicenseByCustomerIdProps) => (
  <Get<CustomerLicense, void, void, GetCustomerLicenseByCustomerIdPathParams>
    path={`/api/CustomerLicenses/Customer/${customerId}`}
    
    {...props}
  />
);

export type UseGetCustomerLicenseByCustomerIdProps = Omit<UseGetProps<CustomerLicense, void, void, GetCustomerLicenseByCustomerIdPathParams>, "path"> & GetCustomerLicenseByCustomerIdPathParams;

export const useGetCustomerLicenseByCustomerId = ({customerId, ...props}: UseGetCustomerLicenseByCustomerIdProps) => useGet<CustomerLicense, void, void, GetCustomerLicenseByCustomerIdPathParams>((paramsInPath: GetCustomerLicenseByCustomerIdPathParams) => `/api/CustomerLicenses/Customer/${paramsInPath.customerId}`, {  pathParams: { customerId }, ...props });


export type PostCustomerLicenseProps = Omit<MutateProps<CustomerLicense, void, void, CustomerLicense, void>, "path" | "verb">;

export const PostCustomerLicense = (props: PostCustomerLicenseProps) => (
  <Mutate<CustomerLicense, void, void, CustomerLicense, void>
    verb="POST"
    path={`/api/CustomerLicenses`}
    
    {...props}
  />
);

export type UsePostCustomerLicenseProps = Omit<UseMutateProps<CustomerLicense, void, void, CustomerLicense, void>, "path" | "verb">;

export const usePostCustomerLicense = (props: UsePostCustomerLicenseProps) => useMutate<CustomerLicense, void, void, CustomerLicense, void>("POST", `/api/CustomerLicenses`, props);


export interface PutCustomerLicenseByIdPathParams {
  id: number
}

export type PutCustomerLicenseByIdProps = Omit<MutateProps<CustomerLicense, void, void, CustomerLicense, PutCustomerLicenseByIdPathParams>, "path" | "verb"> & PutCustomerLicenseByIdPathParams;

export const PutCustomerLicenseById = ({id, ...props}: PutCustomerLicenseByIdProps) => (
  <Mutate<CustomerLicense, void, void, CustomerLicense, PutCustomerLicenseByIdPathParams>
    verb="PUT"
    path={`/api/CustomerLicenses/${id}`}
    
    {...props}
  />
);

export type UsePutCustomerLicenseByIdProps = Omit<UseMutateProps<CustomerLicense, void, void, CustomerLicense, PutCustomerLicenseByIdPathParams>, "path" | "verb"> & PutCustomerLicenseByIdPathParams;

export const usePutCustomerLicenseById = ({id, ...props}: UsePutCustomerLicenseByIdProps) => useMutate<CustomerLicense, void, void, CustomerLicense, PutCustomerLicenseByIdPathParams>("PUT", (paramsInPath: PutCustomerLicenseByIdPathParams) => `/api/CustomerLicenses/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetCustomerProps = Omit<GetProps<Customer[], void, void, void>, "path">;

export const GetCustomer = (props: GetCustomerProps) => (
  <Get<Customer[], void, void, void>
    path={`/api/Customers`}
    
    {...props}
  />
);

export type UseGetCustomerProps = Omit<UseGetProps<Customer[], void, void, void>, "path">;

export const useGetCustomer = (props: UseGetCustomerProps) => useGet<Customer[], void, void, void>(`/api/Customers`, props);


export type PostCustomerProps = Omit<MutateProps<Customer, void, void, Customer, void>, "path" | "verb">;

export const PostCustomer = (props: PostCustomerProps) => (
  <Mutate<Customer, void, void, Customer, void>
    verb="POST"
    path={`/api/Customers`}
    
    {...props}
  />
);

export type UsePostCustomerProps = Omit<UseMutateProps<Customer, void, void, Customer, void>, "path" | "verb">;

export const usePostCustomer = (props: UsePostCustomerProps) => useMutate<Customer, void, void, Customer, void>("POST", `/api/Customers`, props);


export interface GetCustomerByIdPathParams {
  id: number
}

export type GetCustomerByIdProps = Omit<GetProps<Customer, void, void, GetCustomerByIdPathParams>, "path"> & GetCustomerByIdPathParams;

export const GetCustomerById = ({id, ...props}: GetCustomerByIdProps) => (
  <Get<Customer, void, void, GetCustomerByIdPathParams>
    path={`/api/Customers/${id}`}
    
    {...props}
  />
);

export type UseGetCustomerByIdProps = Omit<UseGetProps<Customer, void, void, GetCustomerByIdPathParams>, "path"> & GetCustomerByIdPathParams;

export const useGetCustomerById = ({id, ...props}: UseGetCustomerByIdProps) => useGet<Customer, void, void, GetCustomerByIdPathParams>((paramsInPath: GetCustomerByIdPathParams) => `/api/Customers/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutCustomerPathParams {
  id: number
}

export type PutCustomerProps = Omit<MutateProps<Customer, void, void, Customer, PutCustomerPathParams>, "path" | "verb"> & PutCustomerPathParams;

export const PutCustomer = ({id, ...props}: PutCustomerProps) => (
  <Mutate<Customer, void, void, Customer, PutCustomerPathParams>
    verb="PUT"
    path={`/api/Customers/${id}`}
    
    {...props}
  />
);

export type UsePutCustomerProps = Omit<UseMutateProps<Customer, void, void, Customer, PutCustomerPathParams>, "path" | "verb"> & PutCustomerPathParams;

export const usePutCustomer = ({id, ...props}: UsePutCustomerProps) => useMutate<Customer, void, void, Customer, PutCustomerPathParams>("PUT", (paramsInPath: PutCustomerPathParams) => `/api/Customers/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteCustomerProps = Omit<MutateProps<Customer, void, void, number, void>, "path" | "verb">;

export const DeleteCustomer = (props: DeleteCustomerProps) => (
  <Mutate<Customer, void, void, number, void>
    verb="DELETE"
    path={`/api/Customers`}
    
    {...props}
  />
);

export type UseDeleteCustomerProps = Omit<UseMutateProps<Customer, void, void, number, void>, "path" | "verb">;

export const useDeleteCustomer = (props: UseDeleteCustomerProps) => useMutate<Customer, void, void, number, void>("DELETE", `/api/Customers`, {   ...props });


export interface GetCustomerByCollectionIdPathParams {
  collectionId: number
}

export type GetCustomerByCollectionIdProps = Omit<GetProps<Customer, void, void, GetCustomerByCollectionIdPathParams>, "path"> & GetCustomerByCollectionIdPathParams;

export const GetCustomerByCollectionId = ({collectionId, ...props}: GetCustomerByCollectionIdProps) => (
  <Get<Customer, void, void, GetCustomerByCollectionIdPathParams>
    path={`/api/Customers/Collections/${collectionId}`}
    
    {...props}
  />
);

export type UseGetCustomerByCollectionIdProps = Omit<UseGetProps<Customer, void, void, GetCustomerByCollectionIdPathParams>, "path"> & GetCustomerByCollectionIdPathParams;

export const useGetCustomerByCollectionId = ({collectionId, ...props}: UseGetCustomerByCollectionIdProps) => useGet<Customer, void, void, GetCustomerByCollectionIdPathParams>((paramsInPath: GetCustomerByCollectionIdPathParams) => `/api/Customers/Collections/${paramsInPath.collectionId}`, {  pathParams: { collectionId }, ...props });


export interface GetCustomersByCustomerAnalyticIdPathParams {
  customerAnalyticId: number
}

export type GetCustomersByCustomerAnalyticIdProps = Omit<GetProps<Customer[], void, void, GetCustomersByCustomerAnalyticIdPathParams>, "path"> & GetCustomersByCustomerAnalyticIdPathParams;

export const GetCustomersByCustomerAnalyticId = ({customerAnalyticId, ...props}: GetCustomersByCustomerAnalyticIdProps) => (
  <Get<Customer[], void, void, GetCustomersByCustomerAnalyticIdPathParams>
    path={`/api/Customers/CustomerAnalytics/${customerAnalyticId}`}
    
    {...props}
  />
);

export type UseGetCustomersByCustomerAnalyticIdProps = Omit<UseGetProps<Customer[], void, void, GetCustomersByCustomerAnalyticIdPathParams>, "path"> & GetCustomersByCustomerAnalyticIdPathParams;

export const useGetCustomersByCustomerAnalyticId = ({customerAnalyticId, ...props}: UseGetCustomersByCustomerAnalyticIdProps) => useGet<Customer[], void, void, GetCustomersByCustomerAnalyticIdPathParams>((paramsInPath: GetCustomersByCustomerAnalyticIdPathParams) => `/api/Customers/CustomerAnalytics/${paramsInPath.customerAnalyticId}`, {  pathParams: { customerAnalyticId }, ...props });


export interface GetCustomersForUserPathParams {
  userId: string
}

export type GetCustomersForUserProps = Omit<GetProps<Customer[], void, void, GetCustomersForUserPathParams>, "path"> & GetCustomersForUserPathParams;

export const GetCustomersForUser = ({userId, ...props}: GetCustomersForUserProps) => (
  <Get<Customer[], void, void, GetCustomersForUserPathParams>
    path={`/api/Customers/Users/${userId}`}
    
    {...props}
  />
);

export type UseGetCustomersForUserProps = Omit<UseGetProps<Customer[], void, void, GetCustomersForUserPathParams>, "path"> & GetCustomersForUserPathParams;

export const useGetCustomersForUser = ({userId, ...props}: UseGetCustomersForUserProps) => useGet<Customer[], void, void, GetCustomersForUserPathParams>((paramsInPath: GetCustomersForUserPathParams) => `/api/Customers/Users/${paramsInPath.userId}`, {  pathParams: { userId }, ...props });


export interface GetCustomerByCollectionUuidPathParams {
  collectionUuid: string
}

export type GetCustomerByCollectionUuidProps = Omit<GetProps<Customer, void, void, GetCustomerByCollectionUuidPathParams>, "path"> & GetCustomerByCollectionUuidPathParams;

export const GetCustomerByCollectionUuid = ({collectionUuid, ...props}: GetCustomerByCollectionUuidProps) => (
  <Get<Customer, void, void, GetCustomerByCollectionUuidPathParams>
    path={`/api/Customers/Collections/Uuid/${collectionUuid}`}
    
    {...props}
  />
);

export type UseGetCustomerByCollectionUuidProps = Omit<UseGetProps<Customer, void, void, GetCustomerByCollectionUuidPathParams>, "path"> & GetCustomerByCollectionUuidPathParams;

export const useGetCustomerByCollectionUuid = ({collectionUuid, ...props}: UseGetCustomerByCollectionUuidProps) => useGet<Customer, void, void, GetCustomerByCollectionUuidPathParams>((paramsInPath: GetCustomerByCollectionUuidPathParams) => `/api/Customers/Collections/Uuid/${paramsInPath.collectionUuid}`, {  pathParams: { collectionUuid }, ...props });


export type GetCustomerByCollectionListProps = Omit<MutateProps<Customer[], void, void, string[], void>, "path" | "verb">;

export const GetCustomerByCollectionList = (props: GetCustomerByCollectionListProps) => (
  <Mutate<Customer[], void, void, string[], void>
    verb="POST"
    path={`/api/Customers/Collections/Uuid`}
    
    {...props}
  />
);

export type UseGetCustomerByCollectionListProps = Omit<UseMutateProps<Customer[], void, void, string[], void>, "path" | "verb">;

export const useGetCustomerByCollectionList = (props: UseGetCustomerByCollectionListProps) => useMutate<Customer[], void, void, string[], void>("POST", `/api/Customers/Collections/Uuid`, props);


export type GetCustomerByCollectionIdListProps = Omit<MutateProps<Customer[], void, void, number[], void>, "path" | "verb">;

export const GetCustomerByCollectionIdList = (props: GetCustomerByCollectionIdListProps) => (
  <Mutate<Customer[], void, void, number[], void>
    verb="POST"
    path={`/api/Customers/Collections`}
    
    {...props}
  />
);

export type UseGetCustomerByCollectionIdListProps = Omit<UseMutateProps<Customer[], void, void, number[], void>, "path" | "verb">;

export const useGetCustomerByCollectionIdList = (props: UseGetCustomerByCollectionIdListProps) => useMutate<Customer[], void, void, number[], void>("POST", `/api/Customers/Collections`, props);


export interface CustomerSearchPathParams {
  customerName: string
}

export type CustomerSearchProps = Omit<GetProps<Customer[], void, void, CustomerSearchPathParams>, "path"> & CustomerSearchPathParams;

export const CustomerSearch = ({customerName, ...props}: CustomerSearchProps) => (
  <Get<Customer[], void, void, CustomerSearchPathParams>
    path={`/api/Customers/Names/${customerName}`}
    
    {...props}
  />
);

export type UseCustomerSearchProps = Omit<UseGetProps<Customer[], void, void, CustomerSearchPathParams>, "path"> & CustomerSearchPathParams;

export const useCustomerSearch = ({customerName, ...props}: UseCustomerSearchProps) => useGet<Customer[], void, void, CustomerSearchPathParams>((paramsInPath: CustomerSearchPathParams) => `/api/Customers/Names/${paramsInPath.customerName}`, {  pathParams: { customerName }, ...props });


export type GetEptPointcloudProps = Omit<GetProps<EptPointcloud[], void, void, void>, "path">;

export const GetEptPointcloud = (props: GetEptPointcloudProps) => (
  <Get<EptPointcloud[], void, void, void>
    path={`/api/EptPointcloud`}
    
    {...props}
  />
);

export type UseGetEptPointcloudProps = Omit<UseGetProps<EptPointcloud[], void, void, void>, "path">;

export const useGetEptPointcloud = (props: UseGetEptPointcloudProps) => useGet<EptPointcloud[], void, void, void>(`/api/EptPointcloud`, props);


export type PostEptPointcloudProps = Omit<MutateProps<EptPointcloud, void, void, EptPointcloud, void>, "path" | "verb">;

export const PostEptPointcloud = (props: PostEptPointcloudProps) => (
  <Mutate<EptPointcloud, void, void, EptPointcloud, void>
    verb="POST"
    path={`/api/EptPointcloud`}
    
    {...props}
  />
);

export type UsePostEptPointcloudProps = Omit<UseMutateProps<EptPointcloud, void, void, EptPointcloud, void>, "path" | "verb">;

export const usePostEptPointcloud = (props: UsePostEptPointcloudProps) => useMutate<EptPointcloud, void, void, EptPointcloud, void>("POST", `/api/EptPointcloud`, props);


export interface GetPagedEptPointcloudsPathParams {
  skip: number;
  take: number
}

export type GetPagedEptPointcloudsProps = Omit<GetProps<EptPointcloud[], void, void, GetPagedEptPointcloudsPathParams>, "path"> & GetPagedEptPointcloudsPathParams;

export const GetPagedEptPointclouds = ({skip, take, ...props}: GetPagedEptPointcloudsProps) => (
  <Get<EptPointcloud[], void, void, GetPagedEptPointcloudsPathParams>
    path={`/api/EptPointcloud/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetPagedEptPointcloudsProps = Omit<UseGetProps<EptPointcloud[], void, void, GetPagedEptPointcloudsPathParams>, "path"> & GetPagedEptPointcloudsPathParams;

export const useGetPagedEptPointclouds = ({skip, take, ...props}: UseGetPagedEptPointcloudsProps) => useGet<EptPointcloud[], void, void, GetPagedEptPointcloudsPathParams>((paramsInPath: GetPagedEptPointcloudsPathParams) => `/api/EptPointcloud/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetFilteredPagedEptPointcloudsPathParams {
  skip: number;
  take: number
}

export type GetFilteredPagedEptPointcloudsProps = Omit<MutateProps<EptPointcloud[], void, void, EptPointcloudFilter, GetFilteredPagedEptPointcloudsPathParams>, "path" | "verb"> & GetFilteredPagedEptPointcloudsPathParams;

export const GetFilteredPagedEptPointclouds = ({skip, take, ...props}: GetFilteredPagedEptPointcloudsProps) => (
  <Mutate<EptPointcloud[], void, void, EptPointcloudFilter, GetFilteredPagedEptPointcloudsPathParams>
    verb="POST"
    path={`/api/EptPointcloud/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredPagedEptPointcloudsProps = Omit<UseMutateProps<EptPointcloud[], void, void, EptPointcloudFilter, GetFilteredPagedEptPointcloudsPathParams>, "path" | "verb"> & GetFilteredPagedEptPointcloudsPathParams;

export const useGetFilteredPagedEptPointclouds = ({skip, take, ...props}: UseGetFilteredPagedEptPointcloudsProps) => useMutate<EptPointcloud[], void, void, EptPointcloudFilter, GetFilteredPagedEptPointcloudsPathParams>("POST", (paramsInPath: GetFilteredPagedEptPointcloudsPathParams) => `/api/EptPointcloud/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetEptPointcloudByIdPathParams {
  id: number
}

export type GetEptPointcloudByIdProps = Omit<GetProps<EptPointcloud, void, void, GetEptPointcloudByIdPathParams>, "path"> & GetEptPointcloudByIdPathParams;

export const GetEptPointcloudById = ({id, ...props}: GetEptPointcloudByIdProps) => (
  <Get<EptPointcloud, void, void, GetEptPointcloudByIdPathParams>
    path={`/api/EptPointcloud/${id}`}
    
    {...props}
  />
);

export type UseGetEptPointcloudByIdProps = Omit<UseGetProps<EptPointcloud, void, void, GetEptPointcloudByIdPathParams>, "path"> & GetEptPointcloudByIdPathParams;

export const useGetEptPointcloudById = ({id, ...props}: UseGetEptPointcloudByIdProps) => useGet<EptPointcloud, void, void, GetEptPointcloudByIdPathParams>((paramsInPath: GetEptPointcloudByIdPathParams) => `/api/EptPointcloud/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteEptPointcloudProps = Omit<MutateProps<EptPointcloud, void, void, number, void>, "path" | "verb">;

export const DeleteEptPointcloud = (props: DeleteEptPointcloudProps) => (
  <Mutate<EptPointcloud, void, void, number, void>
    verb="DELETE"
    path={`/api/EptPointcloud`}
    
    {...props}
  />
);

export type UseDeleteEptPointcloudProps = Omit<UseMutateProps<EptPointcloud, void, void, number, void>, "path" | "verb">;

export const useDeleteEptPointcloud = (props: UseDeleteEptPointcloudProps) => useMutate<EptPointcloud, void, void, number, void>("DELETE", `/api/EptPointcloud`, {   ...props });


export interface GetEptJsonByIdPathParams {
  id: number
}

export type GetEptJsonByIdProps = Omit<GetProps<string, void, void, GetEptJsonByIdPathParams>, "path"> & GetEptJsonByIdPathParams;

export const GetEptJsonById = ({id, ...props}: GetEptJsonByIdProps) => (
  <Get<string, void, void, GetEptJsonByIdPathParams>
    path={`/api/EptPointcloud/${id}/ept.json`}
    
    {...props}
  />
);

export type UseGetEptJsonByIdProps = Omit<UseGetProps<string, void, void, GetEptJsonByIdPathParams>, "path"> & GetEptJsonByIdPathParams;

export const useGetEptJsonById = ({id, ...props}: UseGetEptJsonByIdProps) => useGet<string, void, void, GetEptJsonByIdPathParams>((paramsInPath: GetEptJsonByIdPathParams) => `/api/EptPointcloud/${paramsInPath.id}/ept.json`, {  pathParams: { id }, ...props });


export interface GetEptBuildJsonByIdPathParams {
  id: number
}

export type GetEptBuildJsonByIdProps = Omit<GetProps<string, void, void, GetEptBuildJsonByIdPathParams>, "path"> & GetEptBuildJsonByIdPathParams;

export const GetEptBuildJsonById = ({id, ...props}: GetEptBuildJsonByIdProps) => (
  <Get<string, void, void, GetEptBuildJsonByIdPathParams>
    path={`/api/EptPointcloud/${id}/ept-build.json`}
    
    {...props}
  />
);

export type UseGetEptBuildJsonByIdProps = Omit<UseGetProps<string, void, void, GetEptBuildJsonByIdPathParams>, "path"> & GetEptBuildJsonByIdPathParams;

export const useGetEptBuildJsonById = ({id, ...props}: UseGetEptBuildJsonByIdProps) => useGet<string, void, void, GetEptBuildJsonByIdPathParams>((paramsInPath: GetEptBuildJsonByIdPathParams) => `/api/EptPointcloud/${paramsInPath.id}/ept-build.json`, {  pathParams: { id }, ...props });


export interface GetEptDataByIdPathParams {
  id: number;
  folderName: string;
  fileName: string
}

export type GetEptDataByIdProps = Omit<GetProps<string, void, void, GetEptDataByIdPathParams>, "path"> & GetEptDataByIdPathParams;

export const GetEptDataById = ({id, folderName, fileName, ...props}: GetEptDataByIdProps) => (
  <Get<string, void, void, GetEptDataByIdPathParams>
    path={`/api/EptPointcloud/${id}/${folderName}/${fileName}`}
    
    {...props}
  />
);

export type UseGetEptDataByIdProps = Omit<UseGetProps<string, void, void, GetEptDataByIdPathParams>, "path"> & GetEptDataByIdPathParams;

export const useGetEptDataById = ({id, folderName, fileName, ...props}: UseGetEptDataByIdProps) => useGet<string, void, void, GetEptDataByIdPathParams>((paramsInPath: GetEptDataByIdPathParams) => `/api/EptPointcloud/${paramsInPath.id}/${paramsInPath.folderName}/${paramsInPath.fileName}`, {  pathParams: { id, folderName, fileName }, ...props });


export type GetFarmProps = Omit<GetProps<Farm[], void, void, void>, "path">;

export const GetFarm = (props: GetFarmProps) => (
  <Get<Farm[], void, void, void>
    path={`/api/Farms`}
    
    {...props}
  />
);

export type UseGetFarmProps = Omit<UseGetProps<Farm[], void, void, void>, "path">;

export const useGetFarm = (props: UseGetFarmProps) => useGet<Farm[], void, void, void>(`/api/Farms`, props);


export type PostFarmProps = Omit<MutateProps<Farm, void, void, Farm, void>, "path" | "verb">;

export const PostFarm = (props: PostFarmProps) => (
  <Mutate<Farm, void, void, Farm, void>
    verb="POST"
    path={`/api/Farms`}
    
    {...props}
  />
);

export type UsePostFarmProps = Omit<UseMutateProps<Farm, void, void, Farm, void>, "path" | "verb">;

export const usePostFarm = (props: UsePostFarmProps) => useMutate<Farm, void, void, Farm, void>("POST", `/api/Farms`, props);


export interface GetPagedUserFarmSummaryPathParams {
  pageNumber: number;
  pageSize: number
}

export type GetPagedUserFarmSummaryProps = Omit<GetProps<FarmSummaryPage[], void, void, GetPagedUserFarmSummaryPathParams>, "path"> & GetPagedUserFarmSummaryPathParams;

export const GetPagedUserFarmSummary = ({pageNumber, pageSize, ...props}: GetPagedUserFarmSummaryProps) => (
  <Get<FarmSummaryPage[], void, void, GetPagedUserFarmSummaryPathParams>
    path={`/api/Farms/GetPagedUserFarmSummary/${pageNumber}/${pageSize}`}
    
    {...props}
  />
);

export type UseGetPagedUserFarmSummaryProps = Omit<UseGetProps<FarmSummaryPage[], void, void, GetPagedUserFarmSummaryPathParams>, "path"> & GetPagedUserFarmSummaryPathParams;

export const useGetPagedUserFarmSummary = ({pageNumber, pageSize, ...props}: UseGetPagedUserFarmSummaryProps) => useGet<FarmSummaryPage[], void, void, GetPagedUserFarmSummaryPathParams>((paramsInPath: GetPagedUserFarmSummaryPathParams) => `/api/Farms/GetPagedUserFarmSummary/${paramsInPath.pageNumber}/${paramsInPath.pageSize}`, {  pathParams: { pageNumber, pageSize }, ...props });


export interface GetUserFarmSummaryPathParams {
  id: number
}

export type GetUserFarmSummaryProps = Omit<GetProps<FarmSummaryPage[], void, void, GetUserFarmSummaryPathParams>, "path"> & GetUserFarmSummaryPathParams;

export const GetUserFarmSummary = ({id, ...props}: GetUserFarmSummaryProps) => (
  <Get<FarmSummaryPage[], void, void, GetUserFarmSummaryPathParams>
    path={`/api/Farms/GetUserFarmSummary/${id}`}
    
    {...props}
  />
);

export type UseGetUserFarmSummaryProps = Omit<UseGetProps<FarmSummaryPage[], void, void, GetUserFarmSummaryPathParams>, "path"> & GetUserFarmSummaryPathParams;

export const useGetUserFarmSummary = ({id, ...props}: UseGetUserFarmSummaryProps) => useGet<FarmSummaryPage[], void, void, GetUserFarmSummaryPathParams>((paramsInPath: GetUserFarmSummaryPathParams) => `/api/Farms/GetUserFarmSummary/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetUserFarmSummaryPlantCountsPathParams {
  id: number
}

export type GetUserFarmSummaryPlantCountsProps = Omit<GetProps<FarmSummaryPage, void, void, GetUserFarmSummaryPlantCountsPathParams>, "path"> & GetUserFarmSummaryPlantCountsPathParams;

export const GetUserFarmSummaryPlantCounts = ({id, ...props}: GetUserFarmSummaryPlantCountsProps) => (
  <Get<FarmSummaryPage, void, void, GetUserFarmSummaryPlantCountsPathParams>
    path={`/api/Farms/GetUserFarmSummaryPlantCounts/${id}`}
    
    {...props}
  />
);

export type UseGetUserFarmSummaryPlantCountsProps = Omit<UseGetProps<FarmSummaryPage, void, void, GetUserFarmSummaryPlantCountsPathParams>, "path"> & GetUserFarmSummaryPlantCountsPathParams;

export const useGetUserFarmSummaryPlantCounts = ({id, ...props}: UseGetUserFarmSummaryPlantCountsProps) => useGet<FarmSummaryPage, void, void, GetUserFarmSummaryPlantCountsPathParams>((paramsInPath: GetUserFarmSummaryPlantCountsPathParams) => `/api/Farms/GetUserFarmSummaryPlantCounts/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface FarmSearchPathParams {
  farmName: string
}

export type FarmSearchProps = Omit<GetProps<FarmSummary[], void, void, FarmSearchPathParams>, "path"> & FarmSearchPathParams;

export const FarmSearch = ({farmName, ...props}: FarmSearchProps) => (
  <Get<FarmSummary[], void, void, FarmSearchPathParams>
    path={`/api/Farms/Search/${farmName}`}
    
    {...props}
  />
);

export type UseFarmSearchProps = Omit<UseGetProps<FarmSummary[], void, void, FarmSearchPathParams>, "path"> & FarmSearchPathParams;

export const useFarmSearch = ({farmName, ...props}: UseFarmSearchProps) => useGet<FarmSummary[], void, void, FarmSearchPathParams>((paramsInPath: FarmSearchPathParams) => `/api/Farms/Search/${paramsInPath.farmName}`, {  pathParams: { farmName }, ...props });


export interface GetFarmByIdPathParams {
  id: number
}

export type GetFarmByIdProps = Omit<GetProps<Farm, void, void, GetFarmByIdPathParams>, "path"> & GetFarmByIdPathParams;

export const GetFarmById = ({id, ...props}: GetFarmByIdProps) => (
  <Get<Farm, void, void, GetFarmByIdPathParams>
    path={`/api/Farms/${id}`}
    
    {...props}
  />
);

export type UseGetFarmByIdProps = Omit<UseGetProps<Farm, void, void, GetFarmByIdPathParams>, "path"> & GetFarmByIdPathParams;

export const useGetFarmById = ({id, ...props}: UseGetFarmByIdProps) => useGet<Farm, void, void, GetFarmByIdPathParams>((paramsInPath: GetFarmByIdPathParams) => `/api/Farms/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutFarmPathParams {
  id: number
}

export type PutFarmProps = Omit<MutateProps<Farm, void, void, Farm, PutFarmPathParams>, "path" | "verb"> & PutFarmPathParams;

export const PutFarm = ({id, ...props}: PutFarmProps) => (
  <Mutate<Farm, void, void, Farm, PutFarmPathParams>
    verb="PUT"
    path={`/api/Farms/${id}`}
    
    {...props}
  />
);

export type UsePutFarmProps = Omit<UseMutateProps<Farm, void, void, Farm, PutFarmPathParams>, "path" | "verb"> & PutFarmPathParams;

export const usePutFarm = ({id, ...props}: UsePutFarmProps) => useMutate<Farm, void, void, Farm, PutFarmPathParams>("PUT", (paramsInPath: PutFarmPathParams) => `/api/Farms/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteFarmProps = Omit<MutateProps<Farm, void, void, number, void>, "path" | "verb">;

export const DeleteFarm = (props: DeleteFarmProps) => (
  <Mutate<Farm, void, void, number, void>
    verb="DELETE"
    path={`/api/Farms`}
    
    {...props}
  />
);

export type UseDeleteFarmProps = Omit<UseMutateProps<Farm, void, void, number, void>, "path" | "verb">;

export const useDeleteFarm = (props: UseDeleteFarmProps) => useMutate<Farm, void, void, number, void>("DELETE", `/api/Farms`, {   ...props });


export interface GetUserFarmsByUserIdPathParams {
  userId: string
}

export type GetUserFarmsByUserIdProps = Omit<GetProps<Farm[], void, void, GetUserFarmsByUserIdPathParams>, "path"> & GetUserFarmsByUserIdPathParams;

export const GetUserFarmsByUserId = ({userId, ...props}: GetUserFarmsByUserIdProps) => (
  <Get<Farm[], void, void, GetUserFarmsByUserIdPathParams>
    path={`/api/Farms/GetUserFarms/${userId}`}
    
    {...props}
  />
);

export type UseGetUserFarmsByUserIdProps = Omit<UseGetProps<Farm[], void, void, GetUserFarmsByUserIdPathParams>, "path"> & GetUserFarmsByUserIdPathParams;

export const useGetUserFarmsByUserId = ({userId, ...props}: UseGetUserFarmsByUserIdProps) => useGet<Farm[], void, void, GetUserFarmsByUserIdPathParams>((paramsInPath: GetUserFarmsByUserIdPathParams) => `/api/Farms/GetUserFarms/${paramsInPath.userId}`, {  pathParams: { userId }, ...props });


export type GetUserFarmsProps = Omit<GetProps<Farm[], void, void, void>, "path">;

export const GetUserFarms = (props: GetUserFarmsProps) => (
  <Get<Farm[], void, void, void>
    path={`/api/Farms/GetUserFarms`}
    
    {...props}
  />
);

export type UseGetUserFarmsProps = Omit<UseGetProps<Farm[], void, void, void>, "path">;

export const useGetUserFarms = (props: UseGetUserFarmsProps) => useGet<Farm[], void, void, void>(`/api/Farms/GetUserFarms`, props);


export interface GetCustomerFarmsPathParams {
  customerId: number
}

export type GetCustomerFarmsProps = Omit<GetProps<Farm[], void, void, GetCustomerFarmsPathParams>, "path"> & GetCustomerFarmsPathParams;

export const GetCustomerFarms = ({customerId, ...props}: GetCustomerFarmsProps) => (
  <Get<Farm[], void, void, GetCustomerFarmsPathParams>
    path={`/api/Farms/GetCustomerFarms/${customerId}`}
    
    {...props}
  />
);

export type UseGetCustomerFarmsProps = Omit<UseGetProps<Farm[], void, void, GetCustomerFarmsPathParams>, "path"> & GetCustomerFarmsPathParams;

export const useGetCustomerFarms = ({customerId, ...props}: UseGetCustomerFarmsProps) => useGet<Farm[], void, void, GetCustomerFarmsPathParams>((paramsInPath: GetCustomerFarmsPathParams) => `/api/Farms/GetCustomerFarms/${paramsInPath.customerId}`, {  pathParams: { customerId }, ...props });


export interface GetFileReportByIdPathParams {
  id: number
}

export type GetFileReportByIdProps = Omit<GetProps<FarmReport, void, void, GetFileReportByIdPathParams>, "path"> & GetFileReportByIdPathParams;

export const GetFileReportById = ({id, ...props}: GetFileReportByIdProps) => (
  <Get<FarmReport, void, void, GetFileReportByIdPathParams>
    path={`/api/FileReport/${id}`}
    
    {...props}
  />
);

export type UseGetFileReportByIdProps = Omit<UseGetProps<FarmReport, void, void, GetFileReportByIdPathParams>, "path"> & GetFileReportByIdPathParams;

export const useGetFileReportById = ({id, ...props}: UseGetFileReportByIdProps) => useGet<FarmReport, void, void, GetFileReportByIdPathParams>((paramsInPath: GetFileReportByIdPathParams) => `/api/FileReport/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetFileReportDataByIdPathParams {
  id: number
}

export type GetFileReportDataByIdProps = Omit<GetProps<string, void, void, GetFileReportDataByIdPathParams>, "path"> & GetFileReportDataByIdPathParams;

export const GetFileReportDataById = ({id, ...props}: GetFileReportDataByIdProps) => (
  <Get<string, void, void, GetFileReportDataByIdPathParams>
    path={`/api/FileReport/${id}/Data`}
    
    {...props}
  />
);

export type UseGetFileReportDataByIdProps = Omit<UseGetProps<string, void, void, GetFileReportDataByIdPathParams>, "path"> & GetFileReportDataByIdPathParams;

export const useGetFileReportDataById = ({id, ...props}: UseGetFileReportDataByIdProps) => useGet<string, void, void, GetFileReportDataByIdPathParams>((paramsInPath: GetFileReportDataByIdPathParams) => `/api/FileReport/${paramsInPath.id}/Data`, {  pathParams: { id }, ...props });


export interface GetGeometryCollectionPathParams {
  id: number
}

export type GetGeometryCollectionProps = Omit<GetProps<ApGeometryCollection, void, void, GetGeometryCollectionPathParams>, "path"> & GetGeometryCollectionPathParams;

export const GetGeometryCollection = ({id, ...props}: GetGeometryCollectionProps) => (
  <Get<ApGeometryCollection, void, void, GetGeometryCollectionPathParams>
    path={`/api/GeometryCollections/${id}`}
    
    {...props}
  />
);

export type UseGetGeometryCollectionProps = Omit<UseGetProps<ApGeometryCollection, void, void, GetGeometryCollectionPathParams>, "path"> & GetGeometryCollectionPathParams;

export const useGetGeometryCollection = ({id, ...props}: UseGetGeometryCollectionProps) => useGet<ApGeometryCollection, void, void, GetGeometryCollectionPathParams>((paramsInPath: GetGeometryCollectionPathParams) => `/api/GeometryCollections/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutGeometryCollectionPathParams {
  id: number
}

export type PutGeometryCollectionProps = Omit<MutateProps<ApGeometryCollection, void, void, ApGeometryCollection, PutGeometryCollectionPathParams>, "path" | "verb"> & PutGeometryCollectionPathParams;

export const PutGeometryCollection = ({id, ...props}: PutGeometryCollectionProps) => (
  <Mutate<ApGeometryCollection, void, void, ApGeometryCollection, PutGeometryCollectionPathParams>
    verb="PUT"
    path={`/api/GeometryCollections/${id}`}
    
    {...props}
  />
);

export type UsePutGeometryCollectionProps = Omit<UseMutateProps<ApGeometryCollection, void, void, ApGeometryCollection, PutGeometryCollectionPathParams>, "path" | "verb"> & PutGeometryCollectionPathParams;

export const usePutGeometryCollection = ({id, ...props}: UsePutGeometryCollectionProps) => useMutate<ApGeometryCollection, void, void, ApGeometryCollection, PutGeometryCollectionPathParams>("PUT", (paramsInPath: PutGeometryCollectionPathParams) => `/api/GeometryCollections/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetDownloadGeometryCollectionPathParams {
  id: number
}

export type GetDownloadGeometryCollectionProps = Omit<GetProps<Download, void, void, GetDownloadGeometryCollectionPathParams>, "path"> & GetDownloadGeometryCollectionPathParams;

export const GetDownloadGeometryCollection = ({id, ...props}: GetDownloadGeometryCollectionProps) => (
  <Get<Download, void, void, GetDownloadGeometryCollectionPathParams>
    path={`/api/GeometryCollections/${id}/download`}
    
    {...props}
  />
);

export type UseGetDownloadGeometryCollectionProps = Omit<UseGetProps<Download, void, void, GetDownloadGeometryCollectionPathParams>, "path"> & GetDownloadGeometryCollectionPathParams;

export const useGetDownloadGeometryCollection = ({id, ...props}: UseGetDownloadGeometryCollectionProps) => useGet<Download, void, void, GetDownloadGeometryCollectionPathParams>((paramsInPath: GetDownloadGeometryCollectionPathParams) => `/api/GeometryCollections/${paramsInPath.id}/download`, {  pathParams: { id }, ...props });


export type GetGeometryCollectionsProps = Omit<GetProps<ApGeometryCollection[], void, void, void>, "path">;

export const GetGeometryCollections = (props: GetGeometryCollectionsProps) => (
  <Get<ApGeometryCollection[], void, void, void>
    path={`/api/GeometryCollections`}
    
    {...props}
  />
);

export type UseGetGeometryCollectionsProps = Omit<UseGetProps<ApGeometryCollection[], void, void, void>, "path">;

export const useGetGeometryCollections = (props: UseGetGeometryCollectionsProps) => useGet<ApGeometryCollection[], void, void, void>(`/api/GeometryCollections`, props);


export type PostGeometryCollectionProps = Omit<MutateProps<ApGeometryCollection, void, void, ApGeometryCollection, void>, "path" | "verb">;

export const PostGeometryCollection = (props: PostGeometryCollectionProps) => (
  <Mutate<ApGeometryCollection, void, void, ApGeometryCollection, void>
    verb="POST"
    path={`/api/GeometryCollections`}
    
    {...props}
  />
);

export type UsePostGeometryCollectionProps = Omit<UseMutateProps<ApGeometryCollection, void, void, ApGeometryCollection, void>, "path" | "verb">;

export const usePostGeometryCollection = (props: UsePostGeometryCollectionProps) => useMutate<ApGeometryCollection, void, void, ApGeometryCollection, void>("POST", `/api/GeometryCollections`, props);


export interface GetPagedGeometryCollectionsPathParams {
  skip: number;
  take: number
}

export type GetPagedGeometryCollectionsProps = Omit<GetProps<ApGeometryCollection[], void, void, GetPagedGeometryCollectionsPathParams>, "path"> & GetPagedGeometryCollectionsPathParams;

export const GetPagedGeometryCollections = ({skip, take, ...props}: GetPagedGeometryCollectionsProps) => (
  <Get<ApGeometryCollection[], void, void, GetPagedGeometryCollectionsPathParams>
    path={`/api/GeometryCollections/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetPagedGeometryCollectionsProps = Omit<UseGetProps<ApGeometryCollection[], void, void, GetPagedGeometryCollectionsPathParams>, "path"> & GetPagedGeometryCollectionsPathParams;

export const useGetPagedGeometryCollections = ({skip, take, ...props}: UseGetPagedGeometryCollectionsProps) => useGet<ApGeometryCollection[], void, void, GetPagedGeometryCollectionsPathParams>((paramsInPath: GetPagedGeometryCollectionsPathParams) => `/api/GeometryCollections/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetFilteredPagedGeometryCollectionsPathParams {
  skip: number;
  take: number
}

export type GetFilteredPagedGeometryCollectionsProps = Omit<MutateProps<ApGeometryCollection[], void, void, ApGeometryCollectionFilter, GetFilteredPagedGeometryCollectionsPathParams>, "path" | "verb"> & GetFilteredPagedGeometryCollectionsPathParams;

export const GetFilteredPagedGeometryCollections = ({skip, take, ...props}: GetFilteredPagedGeometryCollectionsProps) => (
  <Mutate<ApGeometryCollection[], void, void, ApGeometryCollectionFilter, GetFilteredPagedGeometryCollectionsPathParams>
    verb="POST"
    path={`/api/GeometryCollections/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredPagedGeometryCollectionsProps = Omit<UseMutateProps<ApGeometryCollection[], void, void, ApGeometryCollectionFilter, GetFilteredPagedGeometryCollectionsPathParams>, "path" | "verb"> & GetFilteredPagedGeometryCollectionsPathParams;

export const useGetFilteredPagedGeometryCollections = ({skip, take, ...props}: UseGetFilteredPagedGeometryCollectionsProps) => useMutate<ApGeometryCollection[], void, void, ApGeometryCollectionFilter, GetFilteredPagedGeometryCollectionsPathParams>("POST", (paramsInPath: GetFilteredPagedGeometryCollectionsPathParams) => `/api/GeometryCollections/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetGeometriesPaginatedByGeometryCollectionIdPathParams {
  geometryCollectionId: number;
  pageNumber: number;
  pageSize: number
}

export type GetGeometriesPaginatedByGeometryCollectionIdProps = Omit<GetProps<ApGeometry[], void, void, GetGeometriesPaginatedByGeometryCollectionIdPathParams>, "path"> & GetGeometriesPaginatedByGeometryCollectionIdPathParams;

export const GetGeometriesPaginatedByGeometryCollectionId = ({geometryCollectionId, pageNumber, pageSize, ...props}: GetGeometriesPaginatedByGeometryCollectionIdProps) => (
  <Get<ApGeometry[], void, void, GetGeometriesPaginatedByGeometryCollectionIdPathParams>
    path={`/api/GeometryCollections/${geometryCollectionId}/Geometries/${pageNumber}/${pageSize}`}
    
    {...props}
  />
);

export type UseGetGeometriesPaginatedByGeometryCollectionIdProps = Omit<UseGetProps<ApGeometry[], void, void, GetGeometriesPaginatedByGeometryCollectionIdPathParams>, "path"> & GetGeometriesPaginatedByGeometryCollectionIdPathParams;

export const useGetGeometriesPaginatedByGeometryCollectionId = ({geometryCollectionId, pageNumber, pageSize, ...props}: UseGetGeometriesPaginatedByGeometryCollectionIdProps) => useGet<ApGeometry[], void, void, GetGeometriesPaginatedByGeometryCollectionIdPathParams>((paramsInPath: GetGeometriesPaginatedByGeometryCollectionIdPathParams) => `/api/GeometryCollections/${paramsInPath.geometryCollectionId}/Geometries/${paramsInPath.pageNumber}/${paramsInPath.pageSize}`, {  pathParams: { geometryCollectionId, pageNumber, pageSize }, ...props });


export interface GetGeometryCollectionDetailsPathParams {
  geometryCollectionId: number
}

export type GetGeometryCollectionDetailsProps = Omit<GetProps<ApGeometryCollectionDetails, void, void, GetGeometryCollectionDetailsPathParams>, "path"> & GetGeometryCollectionDetailsPathParams;

export const GetGeometryCollectionDetails = ({geometryCollectionId, ...props}: GetGeometryCollectionDetailsProps) => (
  <Get<ApGeometryCollectionDetails, void, void, GetGeometryCollectionDetailsPathParams>
    path={`/api/GeometryCollections/${geometryCollectionId}/Details`}
    
    {...props}
  />
);

export type UseGetGeometryCollectionDetailsProps = Omit<UseGetProps<ApGeometryCollectionDetails, void, void, GetGeometryCollectionDetailsPathParams>, "path"> & GetGeometryCollectionDetailsPathParams;

export const useGetGeometryCollectionDetails = ({geometryCollectionId, ...props}: UseGetGeometryCollectionDetailsProps) => useGet<ApGeometryCollectionDetails, void, void, GetGeometryCollectionDetailsPathParams>((paramsInPath: GetGeometryCollectionDetailsPathParams) => `/api/GeometryCollections/${paramsInPath.geometryCollectionId}/Details`, {  pathParams: { geometryCollectionId }, ...props });


export interface GetGeometryHistogramByGeometryCollectionIdPathParams {
  geometryCollectionId: number;
  attributeName: string;
  binCount: number;
  classificationMethod: string
}

export type GetGeometryHistogramByGeometryCollectionIdProps = Omit<GetProps<Histogram, void, void, GetGeometryHistogramByGeometryCollectionIdPathParams>, "path"> & GetGeometryHistogramByGeometryCollectionIdPathParams;

export const GetGeometryHistogramByGeometryCollectionId = ({geometryCollectionId, attributeName, binCount, classificationMethod, ...props}: GetGeometryHistogramByGeometryCollectionIdProps) => (
  <Get<Histogram, void, void, GetGeometryHistogramByGeometryCollectionIdPathParams>
    path={`/api/GeometryCollections/${geometryCollectionId}/Geometries/Histogram/${attributeName}/${binCount}/${classificationMethod}`}
    
    {...props}
  />
);

export type UseGetGeometryHistogramByGeometryCollectionIdProps = Omit<UseGetProps<Histogram, void, void, GetGeometryHistogramByGeometryCollectionIdPathParams>, "path"> & GetGeometryHistogramByGeometryCollectionIdPathParams;

export const useGetGeometryHistogramByGeometryCollectionId = ({geometryCollectionId, attributeName, binCount, classificationMethod, ...props}: UseGetGeometryHistogramByGeometryCollectionIdProps) => useGet<Histogram, void, void, GetGeometryHistogramByGeometryCollectionIdPathParams>((paramsInPath: GetGeometryHistogramByGeometryCollectionIdPathParams) => `/api/GeometryCollections/${paramsInPath.geometryCollectionId}/Geometries/Histogram/${paramsInPath.attributeName}/${paramsInPath.binCount}/${paramsInPath.classificationMethod}`, {  pathParams: { geometryCollectionId, attributeName, binCount, classificationMethod }, ...props });


export interface PostGeometryHistogramByGeometryCollectionIdPathParams {
  geometryCollectionId: number
}

export type PostGeometryHistogramByGeometryCollectionIdProps = Omit<MutateProps<Histogram, void, void, HistogramParameters, PostGeometryHistogramByGeometryCollectionIdPathParams>, "path" | "verb"> & PostGeometryHistogramByGeometryCollectionIdPathParams;

export const PostGeometryHistogramByGeometryCollectionId = ({geometryCollectionId, ...props}: PostGeometryHistogramByGeometryCollectionIdProps) => (
  <Mutate<Histogram, void, void, HistogramParameters, PostGeometryHistogramByGeometryCollectionIdPathParams>
    verb="POST"
    path={`/api/GeometryCollections/${geometryCollectionId}/Geometries/Histogram`}
    
    {...props}
  />
);

export type UsePostGeometryHistogramByGeometryCollectionIdProps = Omit<UseMutateProps<Histogram, void, void, HistogramParameters, PostGeometryHistogramByGeometryCollectionIdPathParams>, "path" | "verb"> & PostGeometryHistogramByGeometryCollectionIdPathParams;

export const usePostGeometryHistogramByGeometryCollectionId = ({geometryCollectionId, ...props}: UsePostGeometryHistogramByGeometryCollectionIdProps) => useMutate<Histogram, void, void, HistogramParameters, PostGeometryHistogramByGeometryCollectionIdPathParams>("POST", (paramsInPath: PostGeometryHistogramByGeometryCollectionIdPathParams) => `/api/GeometryCollections/${paramsInPath.geometryCollectionId}/Geometries/Histogram`, {  pathParams: { geometryCollectionId }, ...props });


export interface GetGeometryCategorizedBinsByGeometryCollectionIdPathParams {
  geometryCollectionId: number;
  attributeName: string
}

export type GetGeometryCategorizedBinsByGeometryCollectionIdProps = Omit<GetProps<HistogramCategorized, void, void, GetGeometryCategorizedBinsByGeometryCollectionIdPathParams>, "path"> & GetGeometryCategorizedBinsByGeometryCollectionIdPathParams;

export const GetGeometryCategorizedBinsByGeometryCollectionId = ({geometryCollectionId, attributeName, ...props}: GetGeometryCategorizedBinsByGeometryCollectionIdProps) => (
  <Get<HistogramCategorized, void, void, GetGeometryCategorizedBinsByGeometryCollectionIdPathParams>
    path={`/api/GeometryCollections/${geometryCollectionId}/Geometries/CategorizedHistogram/${attributeName}`}
    
    {...props}
  />
);

export type UseGetGeometryCategorizedBinsByGeometryCollectionIdProps = Omit<UseGetProps<HistogramCategorized, void, void, GetGeometryCategorizedBinsByGeometryCollectionIdPathParams>, "path"> & GetGeometryCategorizedBinsByGeometryCollectionIdPathParams;

export const useGetGeometryCategorizedBinsByGeometryCollectionId = ({geometryCollectionId, attributeName, ...props}: UseGetGeometryCategorizedBinsByGeometryCollectionIdProps) => useGet<HistogramCategorized, void, void, GetGeometryCategorizedBinsByGeometryCollectionIdPathParams>((paramsInPath: GetGeometryCategorizedBinsByGeometryCollectionIdPathParams) => `/api/GeometryCollections/${paramsInPath.geometryCollectionId}/Geometries/CategorizedHistogram/${paramsInPath.attributeName}`, {  pathParams: { geometryCollectionId, attributeName }, ...props });


export interface PostGeometryCategorizedBinsByGeometryCollectionIdPathParams {
  geometryCollectionId: number
}

export type PostGeometryCategorizedBinsByGeometryCollectionIdProps = Omit<MutateProps<HistogramCategorized, void, void, string, PostGeometryCategorizedBinsByGeometryCollectionIdPathParams>, "path" | "verb"> & PostGeometryCategorizedBinsByGeometryCollectionIdPathParams;

export const PostGeometryCategorizedBinsByGeometryCollectionId = ({geometryCollectionId, ...props}: PostGeometryCategorizedBinsByGeometryCollectionIdProps) => (
  <Mutate<HistogramCategorized, void, void, string, PostGeometryCategorizedBinsByGeometryCollectionIdPathParams>
    verb="POST"
    path={`/api/GeometryCollections/${geometryCollectionId}/Geometries/CategorizedHistogram`}
    
    {...props}
  />
);

export type UsePostGeometryCategorizedBinsByGeometryCollectionIdProps = Omit<UseMutateProps<HistogramCategorized, void, void, string, PostGeometryCategorizedBinsByGeometryCollectionIdPathParams>, "path" | "verb"> & PostGeometryCategorizedBinsByGeometryCollectionIdPathParams;

export const usePostGeometryCategorizedBinsByGeometryCollectionId = ({geometryCollectionId, ...props}: UsePostGeometryCategorizedBinsByGeometryCollectionIdProps) => useMutate<HistogramCategorized, void, void, string, PostGeometryCategorizedBinsByGeometryCollectionIdPathParams>("POST", (paramsInPath: PostGeometryCategorizedBinsByGeometryCollectionIdPathParams) => `/api/GeometryCollections/${paramsInPath.geometryCollectionId}/Geometries/CategorizedHistogram`, {  pathParams: { geometryCollectionId }, ...props });


export type CloneGeometryCollectionProps = Omit<MutateProps<void, void, void, GeometryCollectionCloneRequest, void>, "path" | "verb">;

export const CloneGeometryCollection = (props: CloneGeometryCollectionProps) => (
  <Mutate<void, void, void, GeometryCollectionCloneRequest, void>
    verb="POST"
    path={`/api/GeometryCollections/Clone`}
    
    {...props}
  />
);

export type UseCloneGeometryCollectionProps = Omit<UseMutateProps<void, void, void, GeometryCollectionCloneRequest, void>, "path" | "verb">;

export const useCloneGeometryCollection = (props: UseCloneGeometryCollectionProps) => useMutate<void, void, void, GeometryCollectionCloneRequest, void>("POST", `/api/GeometryCollections/Clone`, props);


export interface QueueGeometryCollectionsImportPathParams {
  uploadId: number
}

export type QueueGeometryCollectionsImportProps = Omit<MutateProps<void, void, void, void, QueueGeometryCollectionsImportPathParams>, "path" | "verb"> & QueueGeometryCollectionsImportPathParams;

export const QueueGeometryCollectionsImport = ({uploadId, ...props}: QueueGeometryCollectionsImportProps) => (
  <Mutate<void, void, void, void, QueueGeometryCollectionsImportPathParams>
    verb="POST"
    path={`/api/GeometryCollections/UploadId/${uploadId}`}
    
    {...props}
  />
);

export type UseQueueGeometryCollectionsImportProps = Omit<UseMutateProps<void, void, void, void, QueueGeometryCollectionsImportPathParams>, "path" | "verb"> & QueueGeometryCollectionsImportPathParams;

export const useQueueGeometryCollectionsImport = ({uploadId, ...props}: UseQueueGeometryCollectionsImportProps) => useMutate<void, void, void, void, QueueGeometryCollectionsImportPathParams>("POST", (paramsInPath: QueueGeometryCollectionsImportPathParams) => `/api/GeometryCollections/UploadId/${paramsInPath.uploadId}`, {  pathParams: { uploadId }, ...props });


export interface UploadGeojsonToGeometryCollectionQueryParams {
  Name?: string;
  CustomerId?: number;
}

export type UploadGeojsonToGeometryCollectionProps = Omit<MutateProps<void, void, UploadGeojsonToGeometryCollectionQueryParams, void, void>, "path" | "verb">;

export const UploadGeojsonToGeometryCollection = (props: UploadGeojsonToGeometryCollectionProps) => (
  <Mutate<void, void, UploadGeojsonToGeometryCollectionQueryParams, void, void>
    verb="POST"
    path={`/api/GeometryCollections/Upload`}
    
    {...props}
  />
);

export type UseUploadGeojsonToGeometryCollectionProps = Omit<UseMutateProps<void, void, UploadGeojsonToGeometryCollectionQueryParams, void, void>, "path" | "verb">;

export const useUploadGeojsonToGeometryCollection = (props: UseUploadGeojsonToGeometryCollectionProps) => useMutate<void, void, UploadGeojsonToGeometryCollectionQueryParams, void, void>("POST", `/api/GeometryCollections/Upload`, props);


export interface CreateGeojsonToGeometryCollectionPathParams {
  customerId: number;
  name: string
}

export type CreateGeojsonToGeometryCollectionProps = Omit<MutateProps<void, void, void, void, CreateGeojsonToGeometryCollectionPathParams>, "path" | "verb"> & CreateGeojsonToGeometryCollectionPathParams;

export const CreateGeojsonToGeometryCollection = ({customerId, name, ...props}: CreateGeojsonToGeometryCollectionProps) => (
  <Mutate<void, void, void, void, CreateGeojsonToGeometryCollectionPathParams>
    verb="POST"
    path={`/api/GeometryCollections/Upload/Customer/${customerId}/Name/${name}`}
    
    {...props}
  />
);

export type UseCreateGeojsonToGeometryCollectionProps = Omit<UseMutateProps<void, void, void, void, CreateGeojsonToGeometryCollectionPathParams>, "path" | "verb"> & CreateGeojsonToGeometryCollectionPathParams;

export const useCreateGeojsonToGeometryCollection = ({customerId, name, ...props}: UseCreateGeojsonToGeometryCollectionProps) => useMutate<void, void, void, void, CreateGeojsonToGeometryCollectionPathParams>("POST", (paramsInPath: CreateGeojsonToGeometryCollectionPathParams) => `/api/GeometryCollections/Upload/Customer/${paramsInPath.customerId}/Name/${paramsInPath.name}`, {  pathParams: { customerId, name }, ...props });


export type HealthCheckProps = Omit<GetProps<string, unknown, void, void>, "path">;

export const HealthCheck = (props: HealthCheckProps) => (
  <Get<string, unknown, void, void>
    path={`/api/healthz`}
    
    {...props}
  />
);

export type UseHealthCheckProps = Omit<UseGetProps<string, unknown, void, void>, "path">;

export const useHealthCheck = (props: UseHealthCheckProps) => useGet<string, unknown, void, void>(`/api/healthz`, props);


export type ServicesHealthCheckProps = Omit<GetProps<ServicesHealthCheck, void, void, void>, "path">;

export const ServicesHealthCheck = (props: ServicesHealthCheckProps) => (
  <Get<ServicesHealthCheck, void, void, void>
    path={`/api/healthz/services`}
    
    {...props}
  />
);

export type UseServicesHealthCheckProps = Omit<UseGetProps<ServicesHealthCheck, void, void, void>, "path">;

export const useServicesHealthCheck = (props: UseServicesHealthCheckProps) => useGet<ServicesHealthCheck, void, void, void>(`/api/healthz/services`, props);


export type GetImageMosaicsProps = Omit<GetProps<ImageMosaic[], void, void, void>, "path">;

export const GetImageMosaics = (props: GetImageMosaicsProps) => (
  <Get<ImageMosaic[], void, void, void>
    path={`/api/ImageMosaics`}
    
    {...props}
  />
);

export type UseGetImageMosaicsProps = Omit<UseGetProps<ImageMosaic[], void, void, void>, "path">;

export const useGetImageMosaics = (props: UseGetImageMosaicsProps) => useGet<ImageMosaic[], void, void, void>(`/api/ImageMosaics`, props);


export type PostImageMosaicProps = Omit<MutateProps<ImageMosaic, void, void, ImageMosaic, void>, "path" | "verb">;

export const PostImageMosaic = (props: PostImageMosaicProps) => (
  <Mutate<ImageMosaic, void, void, ImageMosaic, void>
    verb="POST"
    path={`/api/ImageMosaics`}
    
    {...props}
  />
);

export type UsePostImageMosaicProps = Omit<UseMutateProps<ImageMosaic, void, void, ImageMosaic, void>, "path" | "verb">;

export const usePostImageMosaic = (props: UsePostImageMosaicProps) => useMutate<ImageMosaic, void, void, ImageMosaic, void>("POST", `/api/ImageMosaics`, props);


export interface GetImageMosaicsByIdPathParams {
  id: number
}

export type GetImageMosaicsByIdProps = Omit<GetProps<ImageMosaic, void, void, GetImageMosaicsByIdPathParams>, "path"> & GetImageMosaicsByIdPathParams;

export const GetImageMosaicsById = ({id, ...props}: GetImageMosaicsByIdProps) => (
  <Get<ImageMosaic, void, void, GetImageMosaicsByIdPathParams>
    path={`/api/ImageMosaics/${id}`}
    
    {...props}
  />
);

export type UseGetImageMosaicsByIdProps = Omit<UseGetProps<ImageMosaic, void, void, GetImageMosaicsByIdPathParams>, "path"> & GetImageMosaicsByIdPathParams;

export const useGetImageMosaicsById = ({id, ...props}: UseGetImageMosaicsByIdProps) => useGet<ImageMosaic, void, void, GetImageMosaicsByIdPathParams>((paramsInPath: GetImageMosaicsByIdPathParams) => `/api/ImageMosaics/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteImageMosaicProps = Omit<MutateProps<void, void, void, number, void>, "path" | "verb">;

export const DeleteImageMosaic = (props: DeleteImageMosaicProps) => (
  <Mutate<void, void, void, number, void>
    verb="DELETE"
    path={`/api/ImageMosaics`}
    
    {...props}
  />
);

export type UseDeleteImageMosaicProps = Omit<UseMutateProps<void, void, void, number, void>, "path" | "verb">;

export const useDeleteImageMosaic = (props: UseDeleteImageMosaicProps) => useMutate<void, void, void, number, void>("DELETE", `/api/ImageMosaics`, {   ...props });


export interface PutImageMosaicPathParams {
  id: number
}

export type PutImageMosaicProps = Omit<MutateProps<void, void, void, ImageMosaic, PutImageMosaicPathParams>, "path" | "verb"> & PutImageMosaicPathParams;

export const PutImageMosaic = ({id, ...props}: PutImageMosaicProps) => (
  <Mutate<void, void, void, ImageMosaic, PutImageMosaicPathParams>
    verb="PUT"
    path={`/api/ImageMosaics/${id}`}
    
    {...props}
  />
);

export type UsePutImageMosaicProps = Omit<UseMutateProps<void, void, void, ImageMosaic, PutImageMosaicPathParams>, "path" | "verb"> & PutImageMosaicPathParams;

export const usePutImageMosaic = ({id, ...props}: UsePutImageMosaicProps) => useMutate<void, void, void, ImageMosaic, PutImageMosaicPathParams>("PUT", (paramsInPath: PutImageMosaicPathParams) => `/api/ImageMosaics/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetPagedImageMosaicsPathParams {
  skip: number;
  take: number
}

export type GetPagedImageMosaicsProps = Omit<GetProps<ImageMosaic[], void, void, GetPagedImageMosaicsPathParams>, "path"> & GetPagedImageMosaicsPathParams;

export const GetPagedImageMosaics = ({skip, take, ...props}: GetPagedImageMosaicsProps) => (
  <Get<ImageMosaic[], void, void, GetPagedImageMosaicsPathParams>
    path={`/api/ImageMosaics/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetPagedImageMosaicsProps = Omit<UseGetProps<ImageMosaic[], void, void, GetPagedImageMosaicsPathParams>, "path"> & GetPagedImageMosaicsPathParams;

export const useGetPagedImageMosaics = ({skip, take, ...props}: UseGetPagedImageMosaicsProps) => useGet<ImageMosaic[], void, void, GetPagedImageMosaicsPathParams>((paramsInPath: GetPagedImageMosaicsPathParams) => `/api/ImageMosaics/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetFilteredPagedImageMosaicsPathParams {
  skip: number;
  take: number
}

export type GetFilteredPagedImageMosaicsProps = Omit<MutateProps<ImageMosaic[], void, void, ImageMosaicFilter, GetFilteredPagedImageMosaicsPathParams>, "path" | "verb"> & GetFilteredPagedImageMosaicsPathParams;

export const GetFilteredPagedImageMosaics = ({skip, take, ...props}: GetFilteredPagedImageMosaicsProps) => (
  <Mutate<ImageMosaic[], void, void, ImageMosaicFilter, GetFilteredPagedImageMosaicsPathParams>
    verb="POST"
    path={`/api/ImageMosaics/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredPagedImageMosaicsProps = Omit<UseMutateProps<ImageMosaic[], void, void, ImageMosaicFilter, GetFilteredPagedImageMosaicsPathParams>, "path" | "verb"> & GetFilteredPagedImageMosaicsPathParams;

export const useGetFilteredPagedImageMosaics = ({skip, take, ...props}: UseGetFilteredPagedImageMosaicsProps) => useMutate<ImageMosaic[], void, void, ImageMosaicFilter, GetFilteredPagedImageMosaicsPathParams>("POST", (paramsInPath: GetFilteredPagedImageMosaicsPathParams) => `/api/ImageMosaics/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export type GetJobTypeCategoryProps = Omit<GetProps<JobTypeCategory[], void, void, void>, "path">;

export const GetJobTypeCategory = (props: GetJobTypeCategoryProps) => (
  <Get<JobTypeCategory[], void, void, void>
    path={`/api/JobTypeCategories`}
    
    {...props}
  />
);

export type UseGetJobTypeCategoryProps = Omit<UseGetProps<JobTypeCategory[], void, void, void>, "path">;

export const useGetJobTypeCategory = (props: UseGetJobTypeCategoryProps) => useGet<JobTypeCategory[], void, void, void>(`/api/JobTypeCategories`, props);


export type PostJobCategoryTypeProps = Omit<MutateProps<JobTypeCategory, void, void, JobTypeCategory, void>, "path" | "verb">;

export const PostJobCategoryType = (props: PostJobCategoryTypeProps) => (
  <Mutate<JobTypeCategory, void, void, JobTypeCategory, void>
    verb="POST"
    path={`/api/JobTypeCategories`}
    
    {...props}
  />
);

export type UsePostJobCategoryTypeProps = Omit<UseMutateProps<JobTypeCategory, void, void, JobTypeCategory, void>, "path" | "verb">;

export const usePostJobCategoryType = (props: UsePostJobCategoryTypeProps) => useMutate<JobTypeCategory, void, void, JobTypeCategory, void>("POST", `/api/JobTypeCategories`, props);


export interface GetJobTypeCategoryByIdPathParams {
  id: number
}

export type GetJobTypeCategoryByIdProps = Omit<GetProps<JobTypeCategory, void, void, GetJobTypeCategoryByIdPathParams>, "path"> & GetJobTypeCategoryByIdPathParams;

export const GetJobTypeCategoryById = ({id, ...props}: GetJobTypeCategoryByIdProps) => (
  <Get<JobTypeCategory, void, void, GetJobTypeCategoryByIdPathParams>
    path={`/api/JobTypeCategories/${id}`}
    
    {...props}
  />
);

export type UseGetJobTypeCategoryByIdProps = Omit<UseGetProps<JobTypeCategory, void, void, GetJobTypeCategoryByIdPathParams>, "path"> & GetJobTypeCategoryByIdPathParams;

export const useGetJobTypeCategoryById = ({id, ...props}: UseGetJobTypeCategoryByIdProps) => useGet<JobTypeCategory, void, void, GetJobTypeCategoryByIdPathParams>((paramsInPath: GetJobTypeCategoryByIdPathParams) => `/api/JobTypeCategories/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutJobTypeCategoryByIdPathParams {
  id: number
}

export type PutJobTypeCategoryByIdProps = Omit<MutateProps<JobTypeCategory, void, void, JobTypeCategory, PutJobTypeCategoryByIdPathParams>, "path" | "verb"> & PutJobTypeCategoryByIdPathParams;

export const PutJobTypeCategoryById = ({id, ...props}: PutJobTypeCategoryByIdProps) => (
  <Mutate<JobTypeCategory, void, void, JobTypeCategory, PutJobTypeCategoryByIdPathParams>
    verb="PUT"
    path={`/api/JobTypeCategories/${id}`}
    
    {...props}
  />
);

export type UsePutJobTypeCategoryByIdProps = Omit<UseMutateProps<JobTypeCategory, void, void, JobTypeCategory, PutJobTypeCategoryByIdPathParams>, "path" | "verb"> & PutJobTypeCategoryByIdPathParams;

export const usePutJobTypeCategoryById = ({id, ...props}: UsePutJobTypeCategoryByIdProps) => useMutate<JobTypeCategory, void, void, JobTypeCategory, PutJobTypeCategoryByIdPathParams>("PUT", (paramsInPath: PutJobTypeCategoryByIdPathParams) => `/api/JobTypeCategories/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetJobTypeProps = Omit<GetProps<JobType[], void, void, void>, "path">;

export const GetJobType = (props: GetJobTypeProps) => (
  <Get<JobType[], void, void, void>
    path={`/api/JobTypes`}
    
    {...props}
  />
);

export type UseGetJobTypeProps = Omit<UseGetProps<JobType[], void, void, void>, "path">;

export const useGetJobType = (props: UseGetJobTypeProps) => useGet<JobType[], void, void, void>(`/api/JobTypes`, props);


export type PostJobTypeProps = Omit<MutateProps<JobType, void, void, JobType, void>, "path" | "verb">;

export const PostJobType = (props: PostJobTypeProps) => (
  <Mutate<JobType, void, void, JobType, void>
    verb="POST"
    path={`/api/JobTypes`}
    
    {...props}
  />
);

export type UsePostJobTypeProps = Omit<UseMutateProps<JobType, void, void, JobType, void>, "path" | "verb">;

export const usePostJobType = (props: UsePostJobTypeProps) => useMutate<JobType, void, void, JobType, void>("POST", `/api/JobTypes`, props);


export interface GetJobTypeByIdPathParams {
  id: number
}

export type GetJobTypeByIdProps = Omit<GetProps<JobType, void, void, GetJobTypeByIdPathParams>, "path"> & GetJobTypeByIdPathParams;

export const GetJobTypeById = ({id, ...props}: GetJobTypeByIdProps) => (
  <Get<JobType, void, void, GetJobTypeByIdPathParams>
    path={`/api/JobTypes/${id}`}
    
    {...props}
  />
);

export type UseGetJobTypeByIdProps = Omit<UseGetProps<JobType, void, void, GetJobTypeByIdPathParams>, "path"> & GetJobTypeByIdPathParams;

export const useGetJobTypeById = ({id, ...props}: UseGetJobTypeByIdProps) => useGet<JobType, void, void, GetJobTypeByIdPathParams>((paramsInPath: GetJobTypeByIdPathParams) => `/api/JobTypes/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutJobTypeByIdPathParams {
  id: number
}

export type PutJobTypeByIdProps = Omit<MutateProps<JobType, void, void, JobType, PutJobTypeByIdPathParams>, "path" | "verb"> & PutJobTypeByIdPathParams;

export const PutJobTypeById = ({id, ...props}: PutJobTypeByIdProps) => (
  <Mutate<JobType, void, void, JobType, PutJobTypeByIdPathParams>
    verb="PUT"
    path={`/api/JobTypes/${id}`}
    
    {...props}
  />
);

export type UsePutJobTypeByIdProps = Omit<UseMutateProps<JobType, void, void, JobType, PutJobTypeByIdPathParams>, "path" | "verb"> & PutJobTypeByIdPathParams;

export const usePutJobTypeById = ({id, ...props}: UsePutJobTypeByIdProps) => useMutate<JobType, void, void, JobType, PutJobTypeByIdPathParams>("PUT", (paramsInPath: PutJobTypeByIdPathParams) => `/api/JobTypes/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetLayerGroupProps = Omit<GetProps<LayerGroup[], void, void, void>, "path">;

export const GetLayerGroup = (props: GetLayerGroupProps) => (
  <Get<LayerGroup[], void, void, void>
    path={`/api/LayerGroups`}
    
    {...props}
  />
);

export type UseGetLayerGroupProps = Omit<UseGetProps<LayerGroup[], void, void, void>, "path">;

export const useGetLayerGroup = (props: UseGetLayerGroupProps) => useGet<LayerGroup[], void, void, void>(`/api/LayerGroups`, props);


export type PostLayerGroupProps = Omit<MutateProps<LayerGroup, void, void, LayerGroup, void>, "path" | "verb">;

export const PostLayerGroup = (props: PostLayerGroupProps) => (
  <Mutate<LayerGroup, void, void, LayerGroup, void>
    verb="POST"
    path={`/api/LayerGroups`}
    
    {...props}
  />
);

export type UsePostLayerGroupProps = Omit<UseMutateProps<LayerGroup, void, void, LayerGroup, void>, "path" | "verb">;

export const usePostLayerGroup = (props: UsePostLayerGroupProps) => useMutate<LayerGroup, void, void, LayerGroup, void>("POST", `/api/LayerGroups`, props);


export interface GetLayerGroupByIdPathParams {
  id: number
}

export type GetLayerGroupByIdProps = Omit<GetProps<LayerGroup, void, void, GetLayerGroupByIdPathParams>, "path"> & GetLayerGroupByIdPathParams;

export const GetLayerGroupById = ({id, ...props}: GetLayerGroupByIdProps) => (
  <Get<LayerGroup, void, void, GetLayerGroupByIdPathParams>
    path={`/api/LayerGroups/${id}`}
    
    {...props}
  />
);

export type UseGetLayerGroupByIdProps = Omit<UseGetProps<LayerGroup, void, void, GetLayerGroupByIdPathParams>, "path"> & GetLayerGroupByIdPathParams;

export const useGetLayerGroupById = ({id, ...props}: UseGetLayerGroupByIdProps) => useGet<LayerGroup, void, void, GetLayerGroupByIdPathParams>((paramsInPath: GetLayerGroupByIdPathParams) => `/api/LayerGroups/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutLayerGroupPathParams {
  id: number
}

export type PutLayerGroupProps = Omit<MutateProps<LayerGroup, void, void, LayerGroup, PutLayerGroupPathParams>, "path" | "verb"> & PutLayerGroupPathParams;

export const PutLayerGroup = ({id, ...props}: PutLayerGroupProps) => (
  <Mutate<LayerGroup, void, void, LayerGroup, PutLayerGroupPathParams>
    verb="PUT"
    path={`/api/LayerGroups/${id}`}
    
    {...props}
  />
);

export type UsePutLayerGroupProps = Omit<UseMutateProps<LayerGroup, void, void, LayerGroup, PutLayerGroupPathParams>, "path" | "verb"> & PutLayerGroupPathParams;

export const usePutLayerGroup = ({id, ...props}: UsePutLayerGroupProps) => useMutate<LayerGroup, void, void, LayerGroup, PutLayerGroupPathParams>("PUT", (paramsInPath: PutLayerGroupPathParams) => `/api/LayerGroups/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteLayerGroupProps = Omit<MutateProps<void, void, void, number, void>, "path" | "verb">;

export const DeleteLayerGroup = (props: DeleteLayerGroupProps) => (
  <Mutate<void, void, void, number, void>
    verb="DELETE"
    path={`/api/LayerGroups`}
    
    {...props}
  />
);

export type UseDeleteLayerGroupProps = Omit<UseMutateProps<void, void, void, number, void>, "path" | "verb">;

export const useDeleteLayerGroup = (props: UseDeleteLayerGroupProps) => useMutate<void, void, void, number, void>("DELETE", `/api/LayerGroups`, {   ...props });


export interface GetProjectLayerGroupsPathParams {
  projectUuid: string
}

export type GetProjectLayerGroupsProps = Omit<GetProps<LayerGroup[], void, void, GetProjectLayerGroupsPathParams>, "path"> & GetProjectLayerGroupsPathParams;

export const GetProjectLayerGroups = ({projectUuid, ...props}: GetProjectLayerGroupsProps) => (
  <Get<LayerGroup[], void, void, GetProjectLayerGroupsPathParams>
    path={`/api/LayerGroups/Projects/${projectUuid}`}
    
    {...props}
  />
);

export type UseGetProjectLayerGroupsProps = Omit<UseGetProps<LayerGroup[], void, void, GetProjectLayerGroupsPathParams>, "path"> & GetProjectLayerGroupsPathParams;

export const useGetProjectLayerGroups = ({projectUuid, ...props}: UseGetProjectLayerGroupsProps) => useGet<LayerGroup[], void, void, GetProjectLayerGroupsPathParams>((paramsInPath: GetProjectLayerGroupsPathParams) => `/api/LayerGroups/Projects/${paramsInPath.projectUuid}`, {  pathParams: { projectUuid }, ...props });


export interface CloneLayerGroupCapturesPathParams {
  originGroupCaptureId: number;
  destinationGroupCaptureId: number
}

export type CloneLayerGroupCapturesProps = Omit<MutateProps<void, void, void, CloneLayerGroupCapturesRequest, CloneLayerGroupCapturesPathParams>, "path" | "verb"> & CloneLayerGroupCapturesPathParams;

export const CloneLayerGroupCaptures = ({originGroupCaptureId, destinationGroupCaptureId, ...props}: CloneLayerGroupCapturesProps) => (
  <Mutate<void, void, void, CloneLayerGroupCapturesRequest, CloneLayerGroupCapturesPathParams>
    verb="POST"
    path={`/api/LayerGroups/${originGroupCaptureId}/to/${destinationGroupCaptureId}`}
    
    {...props}
  />
);

export type UseCloneLayerGroupCapturesProps = Omit<UseMutateProps<void, void, void, CloneLayerGroupCapturesRequest, CloneLayerGroupCapturesPathParams>, "path" | "verb"> & CloneLayerGroupCapturesPathParams;

export const useCloneLayerGroupCaptures = ({originGroupCaptureId, destinationGroupCaptureId, ...props}: UseCloneLayerGroupCapturesProps) => useMutate<void, void, void, CloneLayerGroupCapturesRequest, CloneLayerGroupCapturesPathParams>("POST", (paramsInPath: CloneLayerGroupCapturesPathParams) => `/api/LayerGroups/${paramsInPath.originGroupCaptureId}/to/${paramsInPath.destinationGroupCaptureId}`, {  pathParams: { originGroupCaptureId, destinationGroupCaptureId }, ...props });


export type GetLayerProps = Omit<GetProps<Layer[], void, void, void>, "path">;

export const GetLayer = (props: GetLayerProps) => (
  <Get<Layer[], void, void, void>
    path={`/api/Layers`}
    
    {...props}
  />
);

export type UseGetLayerProps = Omit<UseGetProps<Layer[], void, void, void>, "path">;

export const useGetLayer = (props: UseGetLayerProps) => useGet<Layer[], void, void, void>(`/api/Layers`, props);


export type PostLayerProps = Omit<MutateProps<Layer, void, void, Layer, void>, "path" | "verb">;

export const PostLayer = (props: PostLayerProps) => (
  <Mutate<Layer, void, void, Layer, void>
    verb="POST"
    path={`/api/Layers`}
    
    {...props}
  />
);

export type UsePostLayerProps = Omit<UseMutateProps<Layer, void, void, Layer, void>, "path" | "verb">;

export const usePostLayer = (props: UsePostLayerProps) => useMutate<Layer, void, void, Layer, void>("POST", `/api/Layers`, props);


export interface GetLayerByIdPathParams {
  id: number
}

export type GetLayerByIdProps = Omit<GetProps<Layer, void, void, GetLayerByIdPathParams>, "path"> & GetLayerByIdPathParams;

export const GetLayerById = ({id, ...props}: GetLayerByIdProps) => (
  <Get<Layer, void, void, GetLayerByIdPathParams>
    path={`/api/Layers/${id}`}
    
    {...props}
  />
);

export type UseGetLayerByIdProps = Omit<UseGetProps<Layer, void, void, GetLayerByIdPathParams>, "path"> & GetLayerByIdPathParams;

export const useGetLayerById = ({id, ...props}: UseGetLayerByIdProps) => useGet<Layer, void, void, GetLayerByIdPathParams>((paramsInPath: GetLayerByIdPathParams) => `/api/Layers/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutLayerByIdPathParams {
  id: number
}

export type PutLayerByIdProps = Omit<MutateProps<Layer, void, void, Layer, PutLayerByIdPathParams>, "path" | "verb"> & PutLayerByIdPathParams;

export const PutLayerById = ({id, ...props}: PutLayerByIdProps) => (
  <Mutate<Layer, void, void, Layer, PutLayerByIdPathParams>
    verb="PUT"
    path={`/api/Layers/${id}`}
    
    {...props}
  />
);

export type UsePutLayerByIdProps = Omit<UseMutateProps<Layer, void, void, Layer, PutLayerByIdPathParams>, "path" | "verb"> & PutLayerByIdPathParams;

export const usePutLayerById = ({id, ...props}: UsePutLayerByIdProps) => useMutate<Layer, void, void, Layer, PutLayerByIdPathParams>("PUT", (paramsInPath: PutLayerByIdPathParams) => `/api/Layers/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteLayerByIdProps = Omit<MutateProps<Layer, void, void, number, void>, "path" | "verb">;

export const DeleteLayerById = (props: DeleteLayerByIdProps) => (
  <Mutate<Layer, void, void, number, void>
    verb="DELETE"
    path={`/api/Layers`}
    
    {...props}
  />
);

export type UseDeleteLayerByIdProps = Omit<UseMutateProps<Layer, void, void, number, void>, "path" | "verb">;

export const useDeleteLayerById = (props: UseDeleteLayerByIdProps) => useMutate<Layer, void, void, number, void>("DELETE", `/api/Layers`, {   ...props });


export interface GetLayersByLayerGroupPathParams {
  id: number
}

export type GetLayersByLayerGroupProps = Omit<GetProps<Layer[], void, void, GetLayersByLayerGroupPathParams>, "path"> & GetLayersByLayerGroupPathParams;

export const GetLayersByLayerGroup = ({id, ...props}: GetLayersByLayerGroupProps) => (
  <Get<Layer[], void, void, GetLayersByLayerGroupPathParams>
    path={`/api/Layers/GroupLayers/${id}`}
    
    {...props}
  />
);

export type UseGetLayersByLayerGroupProps = Omit<UseGetProps<Layer[], void, void, GetLayersByLayerGroupPathParams>, "path"> & GetLayersByLayerGroupPathParams;

export const useGetLayersByLayerGroup = ({id, ...props}: UseGetLayersByLayerGroupProps) => useGet<Layer[], void, void, GetLayersByLayerGroupPathParams>((paramsInPath: GetLayersByLayerGroupPathParams) => `/api/Layers/GroupLayers/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetLibraryProps = Omit<GetProps<LibraryItem[], void, void, void>, "path">;

export const GetLibrary = (props: GetLibraryProps) => (
  <Get<LibraryItem[], void, void, void>
    path={`/api/Layers/Library`}
    
    {...props}
  />
);

export type UseGetLibraryProps = Omit<UseGetProps<LibraryItem[], void, void, void>, "path">;

export const useGetLibrary = (props: UseGetLibraryProps) => useGet<LibraryItem[], void, void, void>(`/api/Layers/Library`, props);


export interface GetLibraryFilteredPathParams {
  skip: number;
  take: number
}

export type GetLibraryFilteredProps = Omit<MutateProps<LibraryItem[], void, void, LibraryFilter, GetLibraryFilteredPathParams>, "path" | "verb"> & GetLibraryFilteredPathParams;

export const GetLibraryFiltered = ({skip, take, ...props}: GetLibraryFilteredProps) => (
  <Mutate<LibraryItem[], void, void, LibraryFilter, GetLibraryFilteredPathParams>
    verb="POST"
    path={`/api/Layers/Library/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetLibraryFilteredProps = Omit<UseMutateProps<LibraryItem[], void, void, LibraryFilter, GetLibraryFilteredPathParams>, "path" | "verb"> & GetLibraryFilteredPathParams;

export const useGetLibraryFiltered = ({skip, take, ...props}: UseGetLibraryFilteredProps) => useMutate<LibraryItem[], void, void, LibraryFilter, GetLibraryFilteredPathParams>("POST", (paramsInPath: GetLibraryFilteredPathParams) => `/api/Layers/Library/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface DownloadLayerByIdPathParams {
  id: number
}

export type DownloadLayerByIdProps = Omit<GetProps<string, void, void, DownloadLayerByIdPathParams>, "path"> & DownloadLayerByIdPathParams;

export const DownloadLayerById = ({id, ...props}: DownloadLayerByIdProps) => (
  <Get<string, void, void, DownloadLayerByIdPathParams>
    path={`/api/Layers/${id}/Download`}
    
    {...props}
  />
);

export type UseDownloadLayerByIdProps = Omit<UseGetProps<string, void, void, DownloadLayerByIdPathParams>, "path"> & DownloadLayerByIdPathParams;

export const useDownloadLayerById = ({id, ...props}: UseDownloadLayerByIdProps) => useGet<string, void, void, DownloadLayerByIdPathParams>((paramsInPath: DownloadLayerByIdPathParams) => `/api/Layers/${paramsInPath.id}/Download`, {  pathParams: { id }, ...props });


export type GetLayerTypeProps = Omit<GetProps<LayerType[], void, void, void>, "path">;

export const GetLayerType = (props: GetLayerTypeProps) => (
  <Get<LayerType[], void, void, void>
    path={`/api/LayerTypes`}
    
    {...props}
  />
);

export type UseGetLayerTypeProps = Omit<UseGetProps<LayerType[], void, void, void>, "path">;

export const useGetLayerType = (props: UseGetLayerTypeProps) => useGet<LayerType[], void, void, void>(`/api/LayerTypes`, props);


export type PostLayerTypeProps = Omit<MutateProps<LayerType, void, void, LayerType, void>, "path" | "verb">;

export const PostLayerType = (props: PostLayerTypeProps) => (
  <Mutate<LayerType, void, void, LayerType, void>
    verb="POST"
    path={`/api/LayerTypes`}
    
    {...props}
  />
);

export type UsePostLayerTypeProps = Omit<UseMutateProps<LayerType, void, void, LayerType, void>, "path" | "verb">;

export const usePostLayerType = (props: UsePostLayerTypeProps) => useMutate<LayerType, void, void, LayerType, void>("POST", `/api/LayerTypes`, props);


export interface GetLayerTypeByIdPathParams {
  id: number
}

export type GetLayerTypeByIdProps = Omit<GetProps<LayerType, void, void, GetLayerTypeByIdPathParams>, "path"> & GetLayerTypeByIdPathParams;

export const GetLayerTypeById = ({id, ...props}: GetLayerTypeByIdProps) => (
  <Get<LayerType, void, void, GetLayerTypeByIdPathParams>
    path={`/api/LayerTypes/${id}`}
    
    {...props}
  />
);

export type UseGetLayerTypeByIdProps = Omit<UseGetProps<LayerType, void, void, GetLayerTypeByIdPathParams>, "path"> & GetLayerTypeByIdPathParams;

export const useGetLayerTypeById = ({id, ...props}: UseGetLayerTypeByIdProps) => useGet<LayerType, void, void, GetLayerTypeByIdPathParams>((paramsInPath: GetLayerTypeByIdPathParams) => `/api/LayerTypes/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutLayerTypeByIdPathParams {
  id: number
}

export type PutLayerTypeByIdProps = Omit<MutateProps<LayerType, void, void, LayerType, PutLayerTypeByIdPathParams>, "path" | "verb"> & PutLayerTypeByIdPathParams;

export const PutLayerTypeById = ({id, ...props}: PutLayerTypeByIdProps) => (
  <Mutate<LayerType, void, void, LayerType, PutLayerTypeByIdPathParams>
    verb="PUT"
    path={`/api/LayerTypes/${id}`}
    
    {...props}
  />
);

export type UsePutLayerTypeByIdProps = Omit<UseMutateProps<LayerType, void, void, LayerType, PutLayerTypeByIdPathParams>, "path" | "verb"> & PutLayerTypeByIdPathParams;

export const usePutLayerTypeById = ({id, ...props}: UsePutLayerTypeByIdProps) => useMutate<LayerType, void, void, LayerType, PutLayerTypeByIdPathParams>("PUT", (paramsInPath: PutLayerTypeByIdPathParams) => `/api/LayerTypes/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteLayerTypeByIdProps = Omit<MutateProps<LayerType, void, void, number, void>, "path" | "verb">;

export const DeleteLayerTypeById = (props: DeleteLayerTypeByIdProps) => (
  <Mutate<LayerType, void, void, number, void>
    verb="DELETE"
    path={`/api/LayerTypes`}
    
    {...props}
  />
);

export type UseDeleteLayerTypeByIdProps = Omit<UseMutateProps<LayerType, void, void, number, void>, "path" | "verb">;

export const useDeleteLayerTypeById = (props: UseDeleteLayerTypeByIdProps) => useMutate<LayerType, void, void, number, void>("DELETE", `/api/LayerTypes`, {   ...props });


export interface GetMapQueryParams {
  Service?: string;
  Version?: string;
  Request?: string;
  Format?: string;
  Transparent?: string;
  Query_layers?: string;
  Layers?: string;
  ViewParams?: string;
  Dim_File_Name?: string;
  Info_Format?: string;
  X?: string;
  Y?: string;
  I?: string;
  J?: string;
  SRS?: string;
  CRS?: string;
  Styles?: string;
  Width?: string;
  Height?: string;
  Bbox?: string;
  Feature_Count?: string;
  Bgcolor?: string;
  Sld?: string;
  Sld_Body?: string;
  Time?: string;
  Cql_Filter?: string;
  Env?: string;
}

export type GetMapProps = Omit<GetProps<void, void, GetMapQueryParams, void>, "path">;

export const GetMap = (props: GetMapProps) => (
  <Get<void, void, GetMapQueryParams, void>
    path={`/api/maps/wms`}
    
    {...props}
  />
);

export type UseGetMapProps = Omit<UseGetProps<void, void, GetMapQueryParams, void>, "path">;

export const useGetMap = (props: UseGetMapProps) => useGet<void, void, GetMapQueryParams, void>(`/api/maps/wms`, props);


export type GetMlModelsProps = Omit<GetProps<MlModel[], void, void, void>, "path">;

export const GetMlModels = (props: GetMlModelsProps) => (
  <Get<MlModel[], void, void, void>
    path={`/api/mlmodels`}
    
    {...props}
  />
);

export type UseGetMlModelsProps = Omit<UseGetProps<MlModel[], void, void, void>, "path">;

export const useGetMlModels = (props: UseGetMlModelsProps) => useGet<MlModel[], void, void, void>(`/api/mlmodels`, props);


export type PutMlModelByIdProps = Omit<MutateProps<MlModel, void, void, MlModel, void>, "path" | "verb">;

export const PutMlModelById = (props: PutMlModelByIdProps) => (
  <Mutate<MlModel, void, void, MlModel, void>
    verb="PUT"
    path={`/api/mlmodels`}
    
    {...props}
  />
);

export type UsePutMlModelByIdProps = Omit<UseMutateProps<MlModel, void, void, MlModel, void>, "path" | "verb">;

export const usePutMlModelById = (props: UsePutMlModelByIdProps) => useMutate<MlModel, void, void, MlModel, void>("PUT", `/api/mlmodels`, props);


export type PostMlModelProps = Omit<MutateProps<MlModel, void, void, MlModel, void>, "path" | "verb">;

export const PostMlModel = (props: PostMlModelProps) => (
  <Mutate<MlModel, void, void, MlModel, void>
    verb="POST"
    path={`/api/mlmodels`}
    
    {...props}
  />
);

export type UsePostMlModelProps = Omit<UseMutateProps<MlModel, void, void, MlModel, void>, "path" | "verb">;

export const usePostMlModel = (props: UsePostMlModelProps) => useMutate<MlModel, void, void, MlModel, void>("POST", `/api/mlmodels`, props);


export interface GetMlModelByIdPathParams {
  id: number
}

export type GetMlModelByIdProps = Omit<GetProps<MlModel, void, void, GetMlModelByIdPathParams>, "path"> & GetMlModelByIdPathParams;

export const GetMlModelById = ({id, ...props}: GetMlModelByIdProps) => (
  <Get<MlModel, void, void, GetMlModelByIdPathParams>
    path={`/api/mlmodels/${id}`}
    
    {...props}
  />
);

export type UseGetMlModelByIdProps = Omit<UseGetProps<MlModel, void, void, GetMlModelByIdPathParams>, "path"> & GetMlModelByIdPathParams;

export const useGetMlModelById = ({id, ...props}: UseGetMlModelByIdProps) => useGet<MlModel, void, void, GetMlModelByIdPathParams>((paramsInPath: GetMlModelByIdPathParams) => `/api/mlmodels/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetMosaicEnginesProps = Omit<GetProps<MosaicEngine[], void, void, void>, "path">;

export const GetMosaicEngines = (props: GetMosaicEnginesProps) => (
  <Get<MosaicEngine[], void, void, void>
    path={`/api/mosaicengines`}
    
    {...props}
  />
);

export type UseGetMosaicEnginesProps = Omit<UseGetProps<MosaicEngine[], void, void, void>, "path">;

export const useGetMosaicEngines = (props: UseGetMosaicEnginesProps) => useGet<MosaicEngine[], void, void, void>(`/api/mosaicengines`, props);


export type PutMosaicEngineByIdProps = Omit<MutateProps<MosaicEngine, void, void, MosaicEngine, void>, "path" | "verb">;

export const PutMosaicEngineById = (props: PutMosaicEngineByIdProps) => (
  <Mutate<MosaicEngine, void, void, MosaicEngine, void>
    verb="PUT"
    path={`/api/mosaicengines`}
    
    {...props}
  />
);

export type UsePutMosaicEngineByIdProps = Omit<UseMutateProps<MosaicEngine, void, void, MosaicEngine, void>, "path" | "verb">;

export const usePutMosaicEngineById = (props: UsePutMosaicEngineByIdProps) => useMutate<MosaicEngine, void, void, MosaicEngine, void>("PUT", `/api/mosaicengines`, props);


export type PostMosaicEngineProps = Omit<MutateProps<MosaicEngine, void, void, MosaicEngine, void>, "path" | "verb">;

export const PostMosaicEngine = (props: PostMosaicEngineProps) => (
  <Mutate<MosaicEngine, void, void, MosaicEngine, void>
    verb="POST"
    path={`/api/mosaicengines`}
    
    {...props}
  />
);

export type UsePostMosaicEngineProps = Omit<UseMutateProps<MosaicEngine, void, void, MosaicEngine, void>, "path" | "verb">;

export const usePostMosaicEngine = (props: UsePostMosaicEngineProps) => useMutate<MosaicEngine, void, void, MosaicEngine, void>("POST", `/api/mosaicengines`, props);


export interface GetMosaicEngineByIdPathParams {
  id: number
}

export type GetMosaicEngineByIdProps = Omit<GetProps<MosaicEngine, void, void, GetMosaicEngineByIdPathParams>, "path"> & GetMosaicEngineByIdPathParams;

export const GetMosaicEngineById = ({id, ...props}: GetMosaicEngineByIdProps) => (
  <Get<MosaicEngine, void, void, GetMosaicEngineByIdPathParams>
    path={`/api/mosaicengines/${id}`}
    
    {...props}
  />
);

export type UseGetMosaicEngineByIdProps = Omit<UseGetProps<MosaicEngine, void, void, GetMosaicEngineByIdPathParams>, "path"> & GetMosaicEngineByIdPathParams;

export const useGetMosaicEngineById = ({id, ...props}: UseGetMosaicEngineByIdProps) => useGet<MosaicEngine, void, void, GetMosaicEngineByIdPathParams>((paramsInPath: GetMosaicEngineByIdPathParams) => `/api/mosaicengines/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetOperationJobProps = Omit<GetProps<OperationJob[], void, void, void>, "path">;

export const GetOperationJob = (props: GetOperationJobProps) => (
  <Get<OperationJob[], void, void, void>
    path={`/api/OperationJobs`}
    
    {...props}
  />
);

export type UseGetOperationJobProps = Omit<UseGetProps<OperationJob[], void, void, void>, "path">;

export const useGetOperationJob = (props: UseGetOperationJobProps) => useGet<OperationJob[], void, void, void>(`/api/OperationJobs`, props);


export type PostOperationJobProps = Omit<MutateProps<OperationJob, void, void, OperationJob, void>, "path" | "verb">;

export const PostOperationJob = (props: PostOperationJobProps) => (
  <Mutate<OperationJob, void, void, OperationJob, void>
    verb="POST"
    path={`/api/OperationJobs`}
    
    {...props}
  />
);

export type UsePostOperationJobProps = Omit<UseMutateProps<OperationJob, void, void, OperationJob, void>, "path" | "verb">;

export const usePostOperationJob = (props: UsePostOperationJobProps) => useMutate<OperationJob, void, void, OperationJob, void>("POST", `/api/OperationJobs`, props);


export interface GetOperationJobByIdPathParams {
  id: number
}

export type GetOperationJobByIdProps = Omit<GetProps<OperationJob, void, void, GetOperationJobByIdPathParams>, "path"> & GetOperationJobByIdPathParams;

export const GetOperationJobById = ({id, ...props}: GetOperationJobByIdProps) => (
  <Get<OperationJob, void, void, GetOperationJobByIdPathParams>
    path={`/api/OperationJobs/${id}`}
    
    {...props}
  />
);

export type UseGetOperationJobByIdProps = Omit<UseGetProps<OperationJob, void, void, GetOperationJobByIdPathParams>, "path"> & GetOperationJobByIdPathParams;

export const useGetOperationJobById = ({id, ...props}: UseGetOperationJobByIdProps) => useGet<OperationJob, void, void, GetOperationJobByIdPathParams>((paramsInPath: GetOperationJobByIdPathParams) => `/api/OperationJobs/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutOperationJobByIdPathParams {
  id: number
}

export type PutOperationJobByIdProps = Omit<MutateProps<OperationJob, void, void, OperationJob, PutOperationJobByIdPathParams>, "path" | "verb"> & PutOperationJobByIdPathParams;

export const PutOperationJobById = ({id, ...props}: PutOperationJobByIdProps) => (
  <Mutate<OperationJob, void, void, OperationJob, PutOperationJobByIdPathParams>
    verb="PUT"
    path={`/api/OperationJobs/${id}`}
    
    {...props}
  />
);

export type UsePutOperationJobByIdProps = Omit<UseMutateProps<OperationJob, void, void, OperationJob, PutOperationJobByIdPathParams>, "path" | "verb"> & PutOperationJobByIdPathParams;

export const usePutOperationJobById = ({id, ...props}: UsePutOperationJobByIdProps) => useMutate<OperationJob, void, void, OperationJob, PutOperationJobByIdPathParams>("PUT", (paramsInPath: PutOperationJobByIdPathParams) => `/api/OperationJobs/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteOperationJobByIdProps = Omit<MutateProps<OperationJob, void, void, number, void>, "path" | "verb">;

export const DeleteOperationJobById = (props: DeleteOperationJobByIdProps) => (
  <Mutate<OperationJob, void, void, number, void>
    verb="DELETE"
    path={`/api/OperationJobs`}
    
    {...props}
  />
);

export type UseDeleteOperationJobByIdProps = Omit<UseMutateProps<OperationJob, void, void, number, void>, "path" | "verb">;

export const useDeleteOperationJobById = (props: UseDeleteOperationJobByIdProps) => useMutate<OperationJob, void, void, number, void>("DELETE", `/api/OperationJobs`, {   ...props });


export type GetPipelineJobProps = Omit<GetProps<PipelineJob[], void, void, void>, "path">;

export const GetPipelineJob = (props: GetPipelineJobProps) => (
  <Get<PipelineJob[], void, void, void>
    path={`/api/PipelineJobs`}
    
    {...props}
  />
);

export type UseGetPipelineJobProps = Omit<UseGetProps<PipelineJob[], void, void, void>, "path">;

export const useGetPipelineJob = (props: UseGetPipelineJobProps) => useGet<PipelineJob[], void, void, void>(`/api/PipelineJobs`, props);


export type PostPipelineJobProps = Omit<MutateProps<PipelineJob, void, void, PipelineJob, void>, "path" | "verb">;

export const PostPipelineJob = (props: PostPipelineJobProps) => (
  <Mutate<PipelineJob, void, void, PipelineJob, void>
    verb="POST"
    path={`/api/PipelineJobs`}
    
    {...props}
  />
);

export type UsePostPipelineJobProps = Omit<UseMutateProps<PipelineJob, void, void, PipelineJob, void>, "path" | "verb">;

export const usePostPipelineJob = (props: UsePostPipelineJobProps) => useMutate<PipelineJob, void, void, PipelineJob, void>("POST", `/api/PipelineJobs`, props);


export interface GetPipelineJobByIdPathParams {
  id: number
}

export type GetPipelineJobByIdProps = Omit<GetProps<PipelineJob, void, void, GetPipelineJobByIdPathParams>, "path"> & GetPipelineJobByIdPathParams;

export const GetPipelineJobById = ({id, ...props}: GetPipelineJobByIdProps) => (
  <Get<PipelineJob, void, void, GetPipelineJobByIdPathParams>
    path={`/api/PipelineJobs/${id}`}
    
    {...props}
  />
);

export type UseGetPipelineJobByIdProps = Omit<UseGetProps<PipelineJob, void, void, GetPipelineJobByIdPathParams>, "path"> & GetPipelineJobByIdPathParams;

export const useGetPipelineJobById = ({id, ...props}: UseGetPipelineJobByIdProps) => useGet<PipelineJob, void, void, GetPipelineJobByIdPathParams>((paramsInPath: GetPipelineJobByIdPathParams) => `/api/PipelineJobs/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutPipelineJobByIdPathParams {
  id: number
}

export type PutPipelineJobByIdProps = Omit<MutateProps<PipelineJob, void, void, PipelineJob, PutPipelineJobByIdPathParams>, "path" | "verb"> & PutPipelineJobByIdPathParams;

export const PutPipelineJobById = ({id, ...props}: PutPipelineJobByIdProps) => (
  <Mutate<PipelineJob, void, void, PipelineJob, PutPipelineJobByIdPathParams>
    verb="PUT"
    path={`/api/PipelineJobs/${id}`}
    
    {...props}
  />
);

export type UsePutPipelineJobByIdProps = Omit<UseMutateProps<PipelineJob, void, void, PipelineJob, PutPipelineJobByIdPathParams>, "path" | "verb"> & PutPipelineJobByIdPathParams;

export const usePutPipelineJobById = ({id, ...props}: UsePutPipelineJobByIdProps) => useMutate<PipelineJob, void, void, PipelineJob, PutPipelineJobByIdPathParams>("PUT", (paramsInPath: PutPipelineJobByIdPathParams) => `/api/PipelineJobs/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetPagedPipelineJobsPathParams {
  skip: number;
  take: number
}

export type GetPagedPipelineJobsProps = Omit<GetProps<PipelineJob[], void, void, GetPagedPipelineJobsPathParams>, "path"> & GetPagedPipelineJobsPathParams;

export const GetPagedPipelineJobs = ({skip, take, ...props}: GetPagedPipelineJobsProps) => (
  <Get<PipelineJob[], void, void, GetPagedPipelineJobsPathParams>
    path={`/api/PipelineJobs/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetPagedPipelineJobsProps = Omit<UseGetProps<PipelineJob[], void, void, GetPagedPipelineJobsPathParams>, "path"> & GetPagedPipelineJobsPathParams;

export const useGetPagedPipelineJobs = ({skip, take, ...props}: UseGetPagedPipelineJobsProps) => useGet<PipelineJob[], void, void, GetPagedPipelineJobsPathParams>((paramsInPath: GetPagedPipelineJobsPathParams) => `/api/PipelineJobs/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetFilteredPagedPipelineJobsPathParams {
  skip: number;
  take: number
}

export type GetFilteredPagedPipelineJobsProps = Omit<MutateProps<PipelineJob[], void, void, PipelineJobFilter, GetFilteredPagedPipelineJobsPathParams>, "path" | "verb"> & GetFilteredPagedPipelineJobsPathParams;

export const GetFilteredPagedPipelineJobs = ({skip, take, ...props}: GetFilteredPagedPipelineJobsProps) => (
  <Mutate<PipelineJob[], void, void, PipelineJobFilter, GetFilteredPagedPipelineJobsPathParams>
    verb="POST"
    path={`/api/PipelineJobs/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredPagedPipelineJobsProps = Omit<UseMutateProps<PipelineJob[], void, void, PipelineJobFilter, GetFilteredPagedPipelineJobsPathParams>, "path" | "verb"> & GetFilteredPagedPipelineJobsPathParams;

export const useGetFilteredPagedPipelineJobs = ({skip, take, ...props}: UseGetFilteredPagedPipelineJobsProps) => useMutate<PipelineJob[], void, void, PipelineJobFilter, GetFilteredPagedPipelineJobsPathParams>("POST", (paramsInPath: GetFilteredPagedPipelineJobsPathParams) => `/api/PipelineJobs/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export type SubmitPipelineJobProps = Omit<MutateProps<PipelineJob, void, void, PipelineJob, void>, "path" | "verb">;

export const SubmitPipelineJob = (props: SubmitPipelineJobProps) => (
  <Mutate<PipelineJob, void, void, PipelineJob, void>
    verb="POST"
    path={`/api/PipelineJobs/Submit`}
    
    {...props}
  />
);

export type UseSubmitPipelineJobProps = Omit<UseMutateProps<PipelineJob, void, void, PipelineJob, void>, "path" | "verb">;

export const useSubmitPipelineJob = (props: UseSubmitPipelineJobProps) => useMutate<PipelineJob, void, void, PipelineJob, void>("POST", `/api/PipelineJobs/Submit`, props);


export interface UpdatePipelineJobStatusByJobUuidPathParams {
  jobUuid: string
}

export type UpdatePipelineJobStatusByJobUuidProps = Omit<MutateProps<PipelineJob, void, void, PipelineJob, UpdatePipelineJobStatusByJobUuidPathParams>, "path" | "verb"> & UpdatePipelineJobStatusByJobUuidPathParams;

export const UpdatePipelineJobStatusByJobUuid = ({jobUuid, ...props}: UpdatePipelineJobStatusByJobUuidProps) => (
  <Mutate<PipelineJob, void, void, PipelineJob, UpdatePipelineJobStatusByJobUuidPathParams>
    verb="PUT"
    path={`/api/PipelineJobs/Uuid/${jobUuid}/Status`}
    
    {...props}
  />
);

export type UseUpdatePipelineJobStatusByJobUuidProps = Omit<UseMutateProps<PipelineJob, void, void, PipelineJob, UpdatePipelineJobStatusByJobUuidPathParams>, "path" | "verb"> & UpdatePipelineJobStatusByJobUuidPathParams;

export const useUpdatePipelineJobStatusByJobUuid = ({jobUuid, ...props}: UseUpdatePipelineJobStatusByJobUuidProps) => useMutate<PipelineJob, void, void, PipelineJob, UpdatePipelineJobStatusByJobUuidPathParams>("PUT", (paramsInPath: UpdatePipelineJobStatusByJobUuidPathParams) => `/api/PipelineJobs/Uuid/${paramsInPath.jobUuid}/Status`, {  pathParams: { jobUuid }, ...props });


export type GetPipelinesProps = Omit<GetProps<Pipeline[], void, void, void>, "path">;

export const GetPipelines = (props: GetPipelinesProps) => (
  <Get<Pipeline[], void, void, void>
    path={`/api/Pipelines`}
    
    {...props}
  />
);

export type UseGetPipelinesProps = Omit<UseGetProps<Pipeline[], void, void, void>, "path">;

export const useGetPipelines = (props: UseGetPipelinesProps) => useGet<Pipeline[], void, void, void>(`/api/Pipelines`, props);


export type PostPipelineProps = Omit<MutateProps<Pipeline, void, void, Pipeline, void>, "path" | "verb">;

export const PostPipeline = (props: PostPipelineProps) => (
  <Mutate<Pipeline, void, void, Pipeline, void>
    verb="POST"
    path={`/api/Pipelines`}
    
    {...props}
  />
);

export type UsePostPipelineProps = Omit<UseMutateProps<Pipeline, void, void, Pipeline, void>, "path" | "verb">;

export const usePostPipeline = (props: UsePostPipelineProps) => useMutate<Pipeline, void, void, Pipeline, void>("POST", `/api/Pipelines`, props);


export interface GetPipelineByIdPathParams {
  id: number
}

export type GetPipelineByIdProps = Omit<GetProps<Pipeline, void, void, GetPipelineByIdPathParams>, "path"> & GetPipelineByIdPathParams;

export const GetPipelineById = ({id, ...props}: GetPipelineByIdProps) => (
  <Get<Pipeline, void, void, GetPipelineByIdPathParams>
    path={`/api/Pipelines/${id}`}
    
    {...props}
  />
);

export type UseGetPipelineByIdProps = Omit<UseGetProps<Pipeline, void, void, GetPipelineByIdPathParams>, "path"> & GetPipelineByIdPathParams;

export const useGetPipelineById = ({id, ...props}: UseGetPipelineByIdProps) => useGet<Pipeline, void, void, GetPipelineByIdPathParams>((paramsInPath: GetPipelineByIdPathParams) => `/api/Pipelines/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutPipelineByIdPathParams {
  id: number
}

export type PutPipelineByIdProps = Omit<MutateProps<Pipeline, void, void, Pipeline, PutPipelineByIdPathParams>, "path" | "verb"> & PutPipelineByIdPathParams;

export const PutPipelineById = ({id, ...props}: PutPipelineByIdProps) => (
  <Mutate<Pipeline, void, void, Pipeline, PutPipelineByIdPathParams>
    verb="PUT"
    path={`/api/Pipelines/${id}`}
    
    {...props}
  />
);

export type UsePutPipelineByIdProps = Omit<UseMutateProps<Pipeline, void, void, Pipeline, PutPipelineByIdPathParams>, "path" | "verb"> & PutPipelineByIdPathParams;

export const usePutPipelineById = ({id, ...props}: UsePutPipelineByIdProps) => useMutate<Pipeline, void, void, Pipeline, PutPipelineByIdPathParams>("PUT", (paramsInPath: PutPipelineByIdPathParams) => `/api/Pipelines/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetProjectProps = Omit<GetProps<Project[], void, void, void>, "path">;

export const GetProject = (props: GetProjectProps) => (
  <Get<Project[], void, void, void>
    path={`/api/Projects`}
    
    {...props}
  />
);

export type UseGetProjectProps = Omit<UseGetProps<Project[], void, void, void>, "path">;

export const useGetProject = (props: UseGetProjectProps) => useGet<Project[], void, void, void>(`/api/Projects`, props);


export type PostProjectProps = Omit<MutateProps<Project, void, void, Project, void>, "path" | "verb">;

export const PostProject = (props: PostProjectProps) => (
  <Mutate<Project, void, void, Project, void>
    verb="POST"
    path={`/api/Projects`}
    
    {...props}
  />
);

export type UsePostProjectProps = Omit<UseMutateProps<Project, void, void, Project, void>, "path" | "verb">;

export const usePostProject = (props: UsePostProjectProps) => useMutate<Project, void, void, Project, void>("POST", `/api/Projects`, props);


export interface GetFilteredPageProjectsPathParams {
  skip: number;
  take: number
}

export type GetFilteredPageProjectsProps = Omit<MutateProps<Project[], void, void, ProjectFilter, GetFilteredPageProjectsPathParams>, "path" | "verb"> & GetFilteredPageProjectsPathParams;

export const GetFilteredPageProjects = ({skip, take, ...props}: GetFilteredPageProjectsProps) => (
  <Mutate<Project[], void, void, ProjectFilter, GetFilteredPageProjectsPathParams>
    verb="POST"
    path={`/api/Projects/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredPageProjectsProps = Omit<UseMutateProps<Project[], void, void, ProjectFilter, GetFilteredPageProjectsPathParams>, "path" | "verb"> & GetFilteredPageProjectsPathParams;

export const useGetFilteredPageProjects = ({skip, take, ...props}: UseGetFilteredPageProjectsProps) => useMutate<Project[], void, void, ProjectFilter, GetFilteredPageProjectsPathParams>("POST", (paramsInPath: GetFilteredPageProjectsPathParams) => `/api/Projects/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetProjectByUuidPathParams {
  uuid: string
}

export type GetProjectByUuidProps = Omit<GetProps<Project, void, void, GetProjectByUuidPathParams>, "path"> & GetProjectByUuidPathParams;

export const GetProjectByUuid = ({uuid, ...props}: GetProjectByUuidProps) => (
  <Get<Project, void, void, GetProjectByUuidPathParams>
    path={`/api/Projects/${uuid}`}
    
    {...props}
  />
);

export type UseGetProjectByUuidProps = Omit<UseGetProps<Project, void, void, GetProjectByUuidPathParams>, "path"> & GetProjectByUuidPathParams;

export const useGetProjectByUuid = ({uuid, ...props}: UseGetProjectByUuidProps) => useGet<Project, void, void, GetProjectByUuidPathParams>((paramsInPath: GetProjectByUuidPathParams) => `/api/Projects/${paramsInPath.uuid}`, {  pathParams: { uuid }, ...props });


export interface PutProjectPathParams {
  uuid: string
}

export type PutProjectProps = Omit<MutateProps<Project, void, void, Project, PutProjectPathParams>, "path" | "verb"> & PutProjectPathParams;

export const PutProject = ({uuid, ...props}: PutProjectProps) => (
  <Mutate<Project, void, void, Project, PutProjectPathParams>
    verb="PUT"
    path={`/api/Projects/${uuid}`}
    
    {...props}
  />
);

export type UsePutProjectProps = Omit<UseMutateProps<Project, void, void, Project, PutProjectPathParams>, "path" | "verb"> & PutProjectPathParams;

export const usePutProject = ({uuid, ...props}: UsePutProjectProps) => useMutate<Project, void, void, Project, PutProjectPathParams>("PUT", (paramsInPath: PutProjectPathParams) => `/api/Projects/${paramsInPath.uuid}`, {  pathParams: { uuid }, ...props });


export type DeleteProjectProps = Omit<MutateProps<void, void, void, string, void>, "path" | "verb">;

export const DeleteProject = (props: DeleteProjectProps) => (
  <Mutate<void, void, void, string, void>
    verb="DELETE"
    path={`/api/Projects`}
    
    {...props}
  />
);

export type UseDeleteProjectProps = Omit<UseMutateProps<void, void, void, string, void>, "path" | "verb">;

export const useDeleteProject = (props: UseDeleteProjectProps) => useMutate<void, void, void, string, void>("DELETE", `/api/Projects`, {   ...props });


export interface GetProjectsByCapturePathParams {
  captureId: number
}

export type GetProjectsByCaptureProps = Omit<GetProps<Project[], void, void, GetProjectsByCapturePathParams>, "path"> & GetProjectsByCapturePathParams;

export const GetProjectsByCapture = ({captureId, ...props}: GetProjectsByCaptureProps) => (
  <Get<Project[], void, void, GetProjectsByCapturePathParams>
    path={`/api/Projects/Capture/${captureId}`}
    
    {...props}
  />
);

export type UseGetProjectsByCaptureProps = Omit<UseGetProps<Project[], void, void, GetProjectsByCapturePathParams>, "path"> & GetProjectsByCapturePathParams;

export const useGetProjectsByCapture = ({captureId, ...props}: UseGetProjectsByCaptureProps) => useGet<Project[], void, void, GetProjectsByCapturePathParams>((paramsInPath: GetProjectsByCapturePathParams) => `/api/Projects/Capture/${paramsInPath.captureId}`, {  pathParams: { captureId }, ...props });


export interface AddCaptureToProjectPathParams {
  uuid: string;
  captureId: number
}

export type AddCaptureToProjectProps = Omit<MutateProps<void, void, void, void, AddCaptureToProjectPathParams>, "path" | "verb"> & AddCaptureToProjectPathParams;

export const AddCaptureToProject = ({uuid, captureId, ...props}: AddCaptureToProjectProps) => (
  <Mutate<void, void, void, void, AddCaptureToProjectPathParams>
    verb="POST"
    path={`/api/Projects/${uuid}/Capture/${captureId}`}
    
    {...props}
  />
);

export type UseAddCaptureToProjectProps = Omit<UseMutateProps<void, void, void, void, AddCaptureToProjectPathParams>, "path" | "verb"> & AddCaptureToProjectPathParams;

export const useAddCaptureToProject = ({uuid, captureId, ...props}: UseAddCaptureToProjectProps) => useMutate<void, void, void, void, AddCaptureToProjectPathParams>("POST", (paramsInPath: AddCaptureToProjectPathParams) => `/api/Projects/${paramsInPath.uuid}/Capture/${paramsInPath.captureId}`, {  pathParams: { uuid, captureId }, ...props });


export interface DeleteCaptureFromProjectPathParams {
  uuid: string
}

export type DeleteCaptureFromProjectProps = Omit<MutateProps<void, void, void, number, DeleteCaptureFromProjectPathParams>, "path" | "verb"> & DeleteCaptureFromProjectPathParams;

export const DeleteCaptureFromProject = ({uuid, ...props}: DeleteCaptureFromProjectProps) => (
  <Mutate<void, void, void, number, DeleteCaptureFromProjectPathParams>
    verb="DELETE"
    path={`/api/Projects/${uuid}/Capture`}
    
    {...props}
  />
);

export type UseDeleteCaptureFromProjectProps = Omit<UseMutateProps<void, void, void, number, DeleteCaptureFromProjectPathParams>, "path" | "verb"> & DeleteCaptureFromProjectPathParams;

export const useDeleteCaptureFromProject = ({uuid, ...props}: UseDeleteCaptureFromProjectProps) => useMutate<void, void, void, number, DeleteCaptureFromProjectPathParams>("DELETE", (paramsInPath: DeleteCaptureFromProjectPathParams) => `/api/Projects/${paramsInPath.uuid}/Capture`, {  pathParams: { uuid }, ...props });


export interface GetProjectTagByIdPathParams {
  id: number
}

export type GetProjectTagByIdProps = Omit<GetProps<ProjectTag, void, void, GetProjectTagByIdPathParams>, "path"> & GetProjectTagByIdPathParams;

export const GetProjectTagById = ({id, ...props}: GetProjectTagByIdProps) => (
  <Get<ProjectTag, void, void, GetProjectTagByIdPathParams>
    path={`/api/ProjectTags/${id}`}
    
    {...props}
  />
);

export type UseGetProjectTagByIdProps = Omit<UseGetProps<ProjectTag, void, void, GetProjectTagByIdPathParams>, "path"> & GetProjectTagByIdPathParams;

export const useGetProjectTagById = ({id, ...props}: UseGetProjectTagByIdProps) => useGet<ProjectTag, void, void, GetProjectTagByIdPathParams>((paramsInPath: GetProjectTagByIdPathParams) => `/api/ProjectTags/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteProjectTagProps = Omit<MutateProps<ProjectTag, void, void, number, void>, "path" | "verb">;

export const DeleteProjectTag = (props: DeleteProjectTagProps) => (
  <Mutate<ProjectTag, void, void, number, void>
    verb="DELETE"
    path={`/api/ProjectTags`}
    
    {...props}
  />
);

export type UseDeleteProjectTagProps = Omit<UseMutateProps<ProjectTag, void, void, number, void>, "path" | "verb">;

export const useDeleteProjectTag = (props: UseDeleteProjectTagProps) => useMutate<ProjectTag, void, void, number, void>("DELETE", `/api/ProjectTags`, {   ...props });


export interface GetProjectTagByNamePathParams {
  tagName: string
}

export type GetProjectTagByNameProps = Omit<GetProps<ProjectTag[], void, void, GetProjectTagByNamePathParams>, "path"> & GetProjectTagByNamePathParams;

export const GetProjectTagByName = ({tagName, ...props}: GetProjectTagByNameProps) => (
  <Get<ProjectTag[], void, void, GetProjectTagByNamePathParams>
    path={`/api/ProjectTags/Search/${tagName}`}
    
    {...props}
  />
);

export type UseGetProjectTagByNameProps = Omit<UseGetProps<ProjectTag[], void, void, GetProjectTagByNamePathParams>, "path"> & GetProjectTagByNamePathParams;

export const useGetProjectTagByName = ({tagName, ...props}: UseGetProjectTagByNameProps) => useGet<ProjectTag[], void, void, GetProjectTagByNamePathParams>((paramsInPath: GetProjectTagByNamePathParams) => `/api/ProjectTags/Search/${paramsInPath.tagName}`, {  pathParams: { tagName }, ...props });


export interface GetProjectTagsByProjectIdPathParams {
  projectId: number
}

export type GetProjectTagsByProjectIdProps = Omit<GetProps<ProjectTag[], void, void, GetProjectTagsByProjectIdPathParams>, "path"> & GetProjectTagsByProjectIdPathParams;

export const GetProjectTagsByProjectId = ({projectId, ...props}: GetProjectTagsByProjectIdProps) => (
  <Get<ProjectTag[], void, void, GetProjectTagsByProjectIdPathParams>
    path={`/api/ProjectTags/Projects/${projectId}`}
    
    {...props}
  />
);

export type UseGetProjectTagsByProjectIdProps = Omit<UseGetProps<ProjectTag[], void, void, GetProjectTagsByProjectIdPathParams>, "path"> & GetProjectTagsByProjectIdPathParams;

export const useGetProjectTagsByProjectId = ({projectId, ...props}: UseGetProjectTagsByProjectIdProps) => useGet<ProjectTag[], void, void, GetProjectTagsByProjectIdPathParams>((paramsInPath: GetProjectTagsByProjectIdPathParams) => `/api/ProjectTags/Projects/${paramsInPath.projectId}`, {  pathParams: { projectId }, ...props });


export interface PutProjectTagPathParams {
  projectTagId: number
}

export type PutProjectTagProps = Omit<MutateProps<ProjectTag, void, void, ProjectTag, PutProjectTagPathParams>, "path" | "verb"> & PutProjectTagPathParams;

export const PutProjectTag = ({projectTagId, ...props}: PutProjectTagProps) => (
  <Mutate<ProjectTag, void, void, ProjectTag, PutProjectTagPathParams>
    verb="PUT"
    path={`/api/ProjectTags/${projectTagId}`}
    
    {...props}
  />
);

export type UsePutProjectTagProps = Omit<UseMutateProps<ProjectTag, void, void, ProjectTag, PutProjectTagPathParams>, "path" | "verb"> & PutProjectTagPathParams;

export const usePutProjectTag = ({projectTagId, ...props}: UsePutProjectTagProps) => useMutate<ProjectTag, void, void, ProjectTag, PutProjectTagPathParams>("PUT", (paramsInPath: PutProjectTagPathParams) => `/api/ProjectTags/${paramsInPath.projectTagId}`, {  pathParams: { projectTagId }, ...props });


export type PostProjectTagProps = Omit<MutateProps<ProjectTag, void, void, ProjectTag, void>, "path" | "verb">;

export const PostProjectTag = (props: PostProjectTagProps) => (
  <Mutate<ProjectTag, void, void, ProjectTag, void>
    verb="POST"
    path={`/api/ProjectTags`}
    
    {...props}
  />
);

export type UsePostProjectTagProps = Omit<UseMutateProps<ProjectTag, void, void, ProjectTag, void>, "path" | "verb">;

export const usePostProjectTag = (props: UsePostProjectTagProps) => useMutate<ProjectTag, void, void, ProjectTag, void>("POST", `/api/ProjectTags`, props);


export type GetRegionsProps = Omit<GetProps<Region[], void, void, void>, "path">;

export const GetRegions = (props: GetRegionsProps) => (
  <Get<Region[], void, void, void>
    path={`/api/Regions`}
    
    {...props}
  />
);

export type UseGetRegionsProps = Omit<UseGetProps<Region[], void, void, void>, "path">;

export const useGetRegions = (props: UseGetRegionsProps) => useGet<Region[], void, void, void>(`/api/Regions`, props);


export type PostRegionProps = Omit<MutateProps<Region, void, void, Region, void>, "path" | "verb">;

export const PostRegion = (props: PostRegionProps) => (
  <Mutate<Region, void, void, Region, void>
    verb="POST"
    path={`/api/Regions`}
    
    {...props}
  />
);

export type UsePostRegionProps = Omit<UseMutateProps<Region, void, void, Region, void>, "path" | "verb">;

export const usePostRegion = (props: UsePostRegionProps) => useMutate<Region, void, void, Region, void>("POST", `/api/Regions`, props);


export interface GetRegionPathParams {
  id: number
}

export type GetRegionProps = Omit<GetProps<Region, void, void, GetRegionPathParams>, "path"> & GetRegionPathParams;

export const GetRegion = ({id, ...props}: GetRegionProps) => (
  <Get<Region, void, void, GetRegionPathParams>
    path={`/api/Regions/${id}`}
    
    {...props}
  />
);

export type UseGetRegionProps = Omit<UseGetProps<Region, void, void, GetRegionPathParams>, "path"> & GetRegionPathParams;

export const useGetRegion = ({id, ...props}: UseGetRegionProps) => useGet<Region, void, void, GetRegionPathParams>((paramsInPath: GetRegionPathParams) => `/api/Regions/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutRegionPathParams {
  id: number
}

export type PutRegionProps = Omit<MutateProps<Region, void, void, Region, PutRegionPathParams>, "path" | "verb"> & PutRegionPathParams;

export const PutRegion = ({id, ...props}: PutRegionProps) => (
  <Mutate<Region, void, void, Region, PutRegionPathParams>
    verb="PUT"
    path={`/api/Regions/${id}`}
    
    {...props}
  />
);

export type UsePutRegionProps = Omit<UseMutateProps<Region, void, void, Region, PutRegionPathParams>, "path" | "verb"> & PutRegionPathParams;

export const usePutRegion = ({id, ...props}: UsePutRegionProps) => useMutate<Region, void, void, Region, PutRegionPathParams>("PUT", (paramsInPath: PutRegionPathParams) => `/api/Regions/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteRegionProps = Omit<MutateProps<Region, void, void, number, void>, "path" | "verb">;

export const DeleteRegion = (props: DeleteRegionProps) => (
  <Mutate<Region, void, void, number, void>
    verb="DELETE"
    path={`/api/Regions`}
    
    {...props}
  />
);

export type UseDeleteRegionProps = Omit<UseMutateProps<Region, void, void, number, void>, "path" | "verb">;

export const useDeleteRegion = (props: UseDeleteRegionProps) => useMutate<Region, void, void, number, void>("DELETE", `/api/Regions`, {   ...props });


export interface GetRegionsByCountryIdPathParams {
  countryId: number
}

export type GetRegionsByCountryIdProps = Omit<GetProps<Region[], void, void, GetRegionsByCountryIdPathParams>, "path"> & GetRegionsByCountryIdPathParams;

export const GetRegionsByCountryId = ({countryId, ...props}: GetRegionsByCountryIdProps) => (
  <Get<Region[], void, void, GetRegionsByCountryIdPathParams>
    path={`/api/Regions/Country/${countryId}`}
    
    {...props}
  />
);

export type UseGetRegionsByCountryIdProps = Omit<UseGetProps<Region[], void, void, GetRegionsByCountryIdPathParams>, "path"> & GetRegionsByCountryIdPathParams;

export const useGetRegionsByCountryId = ({countryId, ...props}: UseGetRegionsByCountryIdProps) => useGet<Region[], void, void, GetRegionsByCountryIdPathParams>((paramsInPath: GetRegionsByCountryIdPathParams) => `/api/Regions/Country/${paramsInPath.countryId}`, {  pathParams: { countryId }, ...props });


export type PostRequestAnalyticsProps = Omit<MutateProps<void, void, void, RequestAnalytics, void>, "path" | "verb">;

export const PostRequestAnalytics = (props: PostRequestAnalyticsProps) => (
  <Mutate<void, void, void, RequestAnalytics, void>
    verb="POST"
    path={`/api/RequestAnalytics`}
    
    {...props}
  />
);

export type UsePostRequestAnalyticsProps = Omit<UseMutateProps<void, void, void, RequestAnalytics, void>, "path" | "verb">;

export const usePostRequestAnalytics = (props: UsePostRequestAnalyticsProps) => useMutate<void, void, void, RequestAnalytics, void>("POST", `/api/RequestAnalytics`, props);


export interface GetUploadByIdPathParams {
  id: number
}

export type GetUploadByIdProps = Omit<GetProps<Upload, void, void, GetUploadByIdPathParams>, "path"> & GetUploadByIdPathParams;

export const GetUploadById = ({id, ...props}: GetUploadByIdProps) => (
  <Get<Upload, void, void, GetUploadByIdPathParams>
    path={`/api/Upload/${id}`}
    
    {...props}
  />
);

export type UseGetUploadByIdProps = Omit<UseGetProps<Upload, void, void, GetUploadByIdPathParams>, "path"> & GetUploadByIdPathParams;

export const useGetUploadById = ({id, ...props}: UseGetUploadByIdProps) => useGet<Upload, void, void, GetUploadByIdPathParams>((paramsInPath: GetUploadByIdPathParams) => `/api/Upload/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutUploadByIdPathParams {
  id: number
}

export type PutUploadByIdProps = Omit<MutateProps<Upload, void, void, Upload, PutUploadByIdPathParams>, "path" | "verb"> & PutUploadByIdPathParams;

export const PutUploadById = ({id, ...props}: PutUploadByIdProps) => (
  <Mutate<Upload, void, void, Upload, PutUploadByIdPathParams>
    verb="PUT"
    path={`/api/Upload/${id}`}
    
    {...props}
  />
);

export type UsePutUploadByIdProps = Omit<UseMutateProps<Upload, void, void, Upload, PutUploadByIdPathParams>, "path" | "verb"> & PutUploadByIdPathParams;

export const usePutUploadById = ({id, ...props}: UsePutUploadByIdProps) => useMutate<Upload, void, void, Upload, PutUploadByIdPathParams>("PUT", (paramsInPath: PutUploadByIdPathParams) => `/api/Upload/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetPagedUploadsPathParams {
  skip: number;
  take: number
}

export type GetPagedUploadsProps = Omit<GetProps<Upload[], void, void, GetPagedUploadsPathParams>, "path"> & GetPagedUploadsPathParams;

export const GetPagedUploads = ({skip, take, ...props}: GetPagedUploadsProps) => (
  <Get<Upload[], void, void, GetPagedUploadsPathParams>
    path={`/api/Upload/Completed/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetPagedUploadsProps = Omit<UseGetProps<Upload[], void, void, GetPagedUploadsPathParams>, "path"> & GetPagedUploadsPathParams;

export const useGetPagedUploads = ({skip, take, ...props}: UseGetPagedUploadsProps) => useGet<Upload[], void, void, GetPagedUploadsPathParams>((paramsInPath: GetPagedUploadsPathParams) => `/api/Upload/Completed/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export interface GetFilteredPagedUploadsPathParams {
  skip: number;
  take: number
}

export type GetFilteredPagedUploadsProps = Omit<MutateProps<Upload[], void, void, UploadFilter, GetFilteredPagedUploadsPathParams>, "path" | "verb"> & GetFilteredPagedUploadsPathParams;

export const GetFilteredPagedUploads = ({skip, take, ...props}: GetFilteredPagedUploadsProps) => (
  <Mutate<Upload[], void, void, UploadFilter, GetFilteredPagedUploadsPathParams>
    verb="POST"
    path={`/api/Upload/Completed/skip/${skip}/take/${take}`}
    
    {...props}
  />
);

export type UseGetFilteredPagedUploadsProps = Omit<UseMutateProps<Upload[], void, void, UploadFilter, GetFilteredPagedUploadsPathParams>, "path" | "verb"> & GetFilteredPagedUploadsPathParams;

export const useGetFilteredPagedUploads = ({skip, take, ...props}: UseGetFilteredPagedUploadsProps) => useMutate<Upload[], void, void, UploadFilter, GetFilteredPagedUploadsPathParams>("POST", (paramsInPath: GetFilteredPagedUploadsPathParams) => `/api/Upload/Completed/skip/${paramsInPath.skip}/take/${paramsInPath.take}`, {  pathParams: { skip, take }, ...props });


export type PostUploadProps = Omit<MutateProps<Upload, void, void, Upload, void>, "path" | "verb">;

export const PostUpload = (props: PostUploadProps) => (
  <Mutate<Upload, void, void, Upload, void>
    verb="POST"
    path={`/api/Upload`}
    
    {...props}
  />
);

export type UsePostUploadProps = Omit<UseMutateProps<Upload, void, void, Upload, void>, "path" | "verb">;

export const usePostUpload = (props: UsePostUploadProps) => useMutate<Upload, void, void, Upload, void>("POST", `/api/Upload`, props);


export interface GetUploadFileByIdPathParams {
  id: number
}

export type GetUploadFileByIdProps = Omit<GetProps<UploadFile, void, void, GetUploadFileByIdPathParams>, "path"> & GetUploadFileByIdPathParams;

export const GetUploadFileById = ({id, ...props}: GetUploadFileByIdProps) => (
  <Get<UploadFile, void, void, GetUploadFileByIdPathParams>
    path={`/api/UploadFile/${id}`}
    
    {...props}
  />
);

export type UseGetUploadFileByIdProps = Omit<UseGetProps<UploadFile, void, void, GetUploadFileByIdPathParams>, "path"> & GetUploadFileByIdPathParams;

export const useGetUploadFileById = ({id, ...props}: UseGetUploadFileByIdProps) => useGet<UploadFile, void, void, GetUploadFileByIdPathParams>((paramsInPath: GetUploadFileByIdPathParams) => `/api/UploadFile/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PutUploadFileByIdPathParams {
  id: number
}

export type PutUploadFileByIdProps = Omit<MutateProps<UploadFile, void, void, UploadFile, PutUploadFileByIdPathParams>, "path" | "verb"> & PutUploadFileByIdPathParams;

export const PutUploadFileById = ({id, ...props}: PutUploadFileByIdProps) => (
  <Mutate<UploadFile, void, void, UploadFile, PutUploadFileByIdPathParams>
    verb="PUT"
    path={`/api/UploadFile/${id}`}
    
    {...props}
  />
);

export type UsePutUploadFileByIdProps = Omit<UseMutateProps<UploadFile, void, void, UploadFile, PutUploadFileByIdPathParams>, "path" | "verb"> & PutUploadFileByIdPathParams;

export const usePutUploadFileById = ({id, ...props}: UsePutUploadFileByIdProps) => useMutate<UploadFile, void, void, UploadFile, PutUploadFileByIdPathParams>("PUT", (paramsInPath: PutUploadFileByIdPathParams) => `/api/UploadFile/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type DeleteUploadFileProps = Omit<MutateProps<UploadFile, void, void, number, void>, "path" | "verb">;

export const DeleteUploadFile = (props: DeleteUploadFileProps) => (
  <Mutate<UploadFile, void, void, number, void>
    verb="DELETE"
    path={`/api/UploadFile`}
    
    {...props}
  />
);

export type UseDeleteUploadFileProps = Omit<UseMutateProps<UploadFile, void, void, number, void>, "path" | "verb">;

export const useDeleteUploadFile = (props: UseDeleteUploadFileProps) => useMutate<UploadFile, void, void, number, void>("DELETE", `/api/UploadFile`, {   ...props });


export interface GetUploadFilesByUploadIdPathParams {
  uploadId: number
}

export type GetUploadFilesByUploadIdProps = Omit<GetProps<UploadFile[], void, void, GetUploadFilesByUploadIdPathParams>, "path"> & GetUploadFilesByUploadIdPathParams;

export const GetUploadFilesByUploadId = ({uploadId, ...props}: GetUploadFilesByUploadIdProps) => (
  <Get<UploadFile[], void, void, GetUploadFilesByUploadIdPathParams>
    path={`/api/UploadFile/Upload/${uploadId}`}
    
    {...props}
  />
);

export type UseGetUploadFilesByUploadIdProps = Omit<UseGetProps<UploadFile[], void, void, GetUploadFilesByUploadIdPathParams>, "path"> & GetUploadFilesByUploadIdPathParams;

export const useGetUploadFilesByUploadId = ({uploadId, ...props}: UseGetUploadFilesByUploadIdProps) => useGet<UploadFile[], void, void, GetUploadFilesByUploadIdPathParams>((paramsInPath: GetUploadFilesByUploadIdPathParams) => `/api/UploadFile/Upload/${paramsInPath.uploadId}`, {  pathParams: { uploadId }, ...props });


export type PostUploadFileProps = Omit<MutateProps<UploadFile, void, void, UploadFile, void>, "path" | "verb">;

export const PostUploadFile = (props: PostUploadFileProps) => (
  <Mutate<UploadFile, void, void, UploadFile, void>
    verb="POST"
    path={`/api/UploadFile`}
    
    {...props}
  />
);

export type UsePostUploadFileProps = Omit<UseMutateProps<UploadFile, void, void, UploadFile, void>, "path" | "verb">;

export const usePostUploadFile = (props: UsePostUploadFileProps) => useMutate<UploadFile, void, void, UploadFile, void>("POST", `/api/UploadFile`, props);


export type GetUploadTypeProps = Omit<GetProps<UploadType[], void, void, void>, "path">;

export const GetUploadType = (props: GetUploadTypeProps) => (
  <Get<UploadType[], void, void, void>
    path={`/api/UploadTypes`}
    
    {...props}
  />
);

export type UseGetUploadTypeProps = Omit<UseGetProps<UploadType[], void, void, void>, "path">;

export const useGetUploadType = (props: UseGetUploadTypeProps) => useGet<UploadType[], void, void, void>(`/api/UploadTypes`, props);


export type GetUserProps = Omit<GetProps<User, void, void, void>, "path">;

export const GetUser = (props: GetUserProps) => (
  <Get<User, void, void, void>
    path={`/api/Users`}
    
    {...props}
  />
);

export type UseGetUserProps = Omit<UseGetProps<User, void, void, void>, "path">;

export const useGetUser = (props: UseGetUserProps) => useGet<User, void, void, void>(`/api/Users`, props);


export type GetUsersAvailibleToAssignByUserProps = Omit<GetProps<User[], void, void, void>, "path">;

export const GetUsersAvailibleToAssignByUser = (props: GetUsersAvailibleToAssignByUserProps) => (
  <Get<User[], void, void, void>
    path={`/api/Users/Customers`}
    
    {...props}
  />
);

export type UseGetUsersAvailibleToAssignByUserProps = Omit<UseGetProps<User[], void, void, void>, "path">;

export const useGetUsersAvailibleToAssignByUser = (props: UseGetUsersAvailibleToAssignByUserProps) => useGet<User[], void, void, void>(`/api/Users/Customers`, props);


export interface GetUsersByCustomerPathParams {
  customerId: number
}

export type GetUsersByCustomerProps = Omit<GetProps<User[], void, void, GetUsersByCustomerPathParams>, "path"> & GetUsersByCustomerPathParams;

export const GetUsersByCustomer = ({customerId, ...props}: GetUsersByCustomerProps) => (
  <Get<User[], void, void, GetUsersByCustomerPathParams>
    path={`/api/Users/Customers/${customerId}`}
    
    {...props}
  />
);

export type UseGetUsersByCustomerProps = Omit<UseGetProps<User[], void, void, GetUsersByCustomerPathParams>, "path"> & GetUsersByCustomerPathParams;

export const useGetUsersByCustomer = ({customerId, ...props}: UseGetUsersByCustomerProps) => useGet<User[], void, void, GetUsersByCustomerPathParams>((paramsInPath: GetUsersByCustomerPathParams) => `/api/Users/Customers/${paramsInPath.customerId}`, {  pathParams: { customerId }, ...props });


export type GetUsersAvailibleFromCapturesProps = Omit<GetProps<User[], void, void, void>, "path">;

export const GetUsersAvailibleFromCaptures = (props: GetUsersAvailibleFromCapturesProps) => (
  <Get<User[], void, void, void>
    path={`/api/Users/Captures`}
    
    {...props}
  />
);

export type UseGetUsersAvailibleFromCapturesProps = Omit<UseGetProps<User[], void, void, void>, "path">;

export const useGetUsersAvailibleFromCaptures = (props: UseGetUsersAvailibleFromCapturesProps) => useGet<User[], void, void, void>(`/api/Users/Captures`, props);


export type GetQAQCUsersProps = Omit<GetProps<User[], void, void, void>, "path">;

export const GetQAQCUsers = (props: GetQAQCUsersProps) => (
  <Get<User[], void, void, void>
    path={`/api/Users/QAQC`}
    
    {...props}
  />
);

export type UseGetQAQCUsersProps = Omit<UseGetProps<User[], void, void, void>, "path">;

export const useGetQAQCUsers = (props: UseGetQAQCUsersProps) => useGet<User[], void, void, void>(`/api/Users/QAQC`, props);

