import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
interface SidebarToggleButtonProps {
  open: boolean;
  onClick: () => void;
  show?: boolean;
}

export function SidebarToggleButton({
  show = true,
  open,
  onClick,
}: SidebarToggleButtonProps) {
  return (
    <button
      className={`
      w-6
      h-8
      p-1
      mt-2
      rounded-r-full
      text-gray-800
      bg-gray-50
      text-center
      transition-all
      duration-300
      hover:bg-white
      focus:outline-none
      ${show ? 'opacity-90' : 'opacity-0 invisible'}`}
      onClick={onClick}
    >
      <div className="">
        {open ? (
          <FontAwesomeIcon className="text-sm mr-2" icon={faChevronLeft} />
        ) : (
          <FontAwesomeIcon className="text-sm mr-2" icon={faChevronRight} />
        )}
      </div>
    </button>
  );
}
