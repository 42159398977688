import { useEffect, useState } from 'react';

import {
  UploadFile,
  useGetUploadById,
  useGetUploadFilesByUploadId,
} from '@agerpoint/api';
import {
  ExpansionContentContainer,
  ExpansionContentTabContentContainer,
  ExpansionContentTabsContainer,
} from '@agerpoint/component';
import { AttachmentMapLayer } from '@agerpoint/types';

import { StyleProperty } from './style-property';

interface AttachmentLayerStyle {
  layer: AttachmentMapLayer;
}

export function AttachmentLayerStyle({ layer }: AttachmentLayerStyle) {
  const [mode, setMode] = useState('attachment');
  const {
    loading: loadingUpload,
    error: errorLoadingUpload,
    data: uploadData,
  } = useGetUploadById({
    id: layer.entityId,
    resolve: (data) => {
      if (layer.type === 'Attachment') {
        layer.upload = data;
      }
      return data;
    },
  });

  const {
    refetch,
    loading: loadingUploadFile,
    error: errorLoadingUploadFile,
  } = useGetUploadFilesByUploadId({
    uploadId: uploadData?.id as number,
    resolve: (data: UploadFile[]) => {
      if (layer.type === 'Attachment') {
        const first = data[0];
        layer.fileName = first?.originalName ?? 'Unknown';
        if (first.createDatetime) {
          layer.fileDate = new Date(
            Date.parse(first.createDatetime)
          ).toDateString();
        } else {
          layer.fileDate = 'Unknown';
        }
        layer.uploadFile = first;
      }
      return data;
    },
    lazy: true,
  });

  useEffect(() => {
    if (uploadData?.id) {
      refetch();
    }
  }, [uploadData]);

  const loading = loadingUpload || loadingUploadFile;

  const error = errorLoadingUpload || errorLoadingUploadFile;

  return (
    <ExpansionContentContainer>
      <ExpansionContentTabsContainer>
        <StyleProperty
          name="Attachment"
          value={layer.name}
          selected={mode === 'attachment'}
          onSelect={() => setMode('attachment')}
        />
      </ExpansionContentTabsContainer>
      {!loading && (
        <ExpansionContentTabContentContainer>
          {mode === 'attachment' ? (
            <div className={`px-4 py-3 text-gray-800 text-sm`}>
              <h3 className="font-bold mb-2 leading-3">File:</h3>
              <span className="text-system-gray-600" title={layer.fileName}>
                <div className="break-all flex flex-nowrap">
                  {layer.fileName}
                </div>
              </span>
              <h3 className="font-bold mb-2 mt-2 leading-3">Date:</h3>
              <div className="text-system-gray-600">{layer.fileDate}</div>
            </div>
          ) : null}
        </ExpansionContentTabContentContainer>
      )}
      {error && (
        <div className="text-system-gray-600">Error getting file info</div>
      )}
    </ExpansionContentContainer>
  );
}
