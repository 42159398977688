import Feature from 'ol/Feature';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { FitOptions } from 'ol/View';
import { Extent } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import TileLayer from 'ol/layer/Tile';
import VectorTileLayer from 'ol/layer/VectorTile';
import { BingMaps } from 'ol/source';
import { Fill, RegularShape, Stroke, Style } from 'ol/style';
import { StyleLike } from 'ol/style/Style';

export interface OpenLayerGeneratedFeature {
  id: string | number;
  latitude: number;
  longitude: number;
  name?: string;
  style: string;
}

export enum OpenMapLayer {
  RoadMap = 'CanvasLight',
  Aerial = 'Aerial',
  Hybrid = 'AerialWithLabelsOnDemand',
}

export type OpenMapLayersObject = {
  [key in OpenMapLayer]?: TileLayer<BingMaps>;
};
export interface OpenLayerMapProps<T> {
  id: string;
  controller?: (controller: OpenLayerMapController) => void;
  bingKey: string;
  mapLayers: {
    used: OpenMapLayer[];
    initial: OpenMapLayer;
    onChange?: (layer: OpenMapLayer) => void;
  };
  featureLayer?: {
    data: T[];
    styles?: { [key: string]: StyleLike };
    featureGenerator: (data: T) => OpenLayerGeneratedFeature | null;
    popupGenerator?: (id: number | string) => React.ReactNode;
    cluster?: {
      distance: number;
      minDistance: number;
    };
  };
  geoJsonLayer?: {
    data: Feature<Geometry>[];
  };
  vectorTileLayers?: VectorTileLayer[] | undefined;
  callbacks?: {
    onDragBoxSelect?: (ids: (number | string)[]) => void;
    onFeatureClick?: (
      id: number | string,
      event: MapBrowserEvent<PointerEvent>
    ) => void;
    onBaseMapClick?: (event: MapBrowserEvent<PointerEvent>) => void;
    onClusterClick?: (
      ids: (number | string)[],
      event: MapBrowserEvent<PointerEvent>
    ) => void;
  };
  dependencies?: React.DependencyList;
  disableInteraction?: boolean;
  loading?: boolean;
  useTools?: boolean;
}

export interface OpenLayerMapController {
  zoomMapToLonLat?: (lonlat: [number, number], zoom?: number) => void;
  zoomMapToExtent?: (extent: Extent, options?: FitOptions | undefined) => void;
  refreshView?: () => void;
}

export const unSelectedUploadFileDotStyle = new Style({
  image: new RegularShape({
    fill: new Fill({
      color: [255, 255, 255, 1],
    }),
    stroke: new Stroke({
      color: [51, 155, 192, 1],
      width: 1.5,
    }),
    points: 300,
    radius: 5,
  }),
  zIndex: 199,
});

export const selectedUploadFileDotStyle = new Style({
  image: new RegularShape({
    fill: new Fill({
      color: [51, 155, 192, 1],
    }),
    stroke: new Stroke({
      color: [22, 6, 54, 1],
      width: 1.2,
    }),
    points: 300,
    radius: 5.5,
  }),
  zIndex: 200,
});

export const highlightedUploadFileDotStyle = new Style({
  image: new RegularShape({
    fill: new Fill({
      color: [208, 208, 208, 1],
    }),
    stroke: new Stroke({
      color: [51, 155, 192, 1],
      width: 1.5,
    }),
    points: 300,
    radius: 6,
  }),
  zIndex: 200,
});

export const successfulUploadFileDotStyle = new Style({
  image: new RegularShape({
    fill: new Fill({
      color: [46, 156, 115, 1],
    }),
    stroke: new Stroke({
      color: [0, 129, 90, 1],
      width: 1.2,
    }),
    points: 300,
    radius: 5.5,
  }),
  zIndex: 199,
});

export const failedUploadFileDotStyle = new Style({
  image: new RegularShape({
    fill: new Fill({
      color: [255, 104, 113, 1],
    }),
    stroke: new Stroke({
      color: [224, 86, 80, 1],
      width: 1.2,
    }),
    points: 300,
    radius: 5.5,
  }),
  zIndex: 199,
});
