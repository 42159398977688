import { ReactNode } from 'react';

import { DialogModal, PrimaryButton } from '@agerpoint/component';

export interface ConfirmAction {
  label: string;
  callback: () => void | Promise<void>;
}
interface ConfirmModalProps {
  title: string;
  message: ReactNode;
  isOpen: boolean;
  confirm?: ConfirmAction;
  close: ConfirmAction;
  canConfirm?: boolean;
  theme?: 'red' | 'darkBlue' | 'solidDarkBlue' | 'primary';
  visibleOverflow?: boolean;
}

export function ConfirmModal({
  title,
  message,
  isOpen,
  confirm,
  close,
  canConfirm,
  theme = 'red',
  visibleOverflow,
}: ConfirmModalProps) {
  return (
    <DialogModal
      open={isOpen}
      title={title}
      handleCloseDialog={close.callback}
      size={'small'}
      visibleOverflow={visibleOverflow}
    >
      <div className="pt-2 w-full p-1">
        <div className="text-md break-words">{message}</div>
        <div className="flex flex-row pt-8 justify-end gap-3">
          {confirm && (
            <PrimaryButton
              onClicked={confirm.callback}
              label={confirm.label}
              theme={theme}
              className="h-12 px-10"
              disabled={!canConfirm}
            />
          )}

          <PrimaryButton
            onClicked={close.callback}
            label={close.label}
            theme="white"
            className="h-12 px-10"
            disabled={canConfirm !== undefined ? !canConfirm : false}
          />
        </div>
      </div>
    </DialogModal>
  );
}
