import { faFilterSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ButtonClearFilterProps {
  visible?: boolean;
  onClick?: () => void;
}

export const ButtonClearFilter = ({
  visible,
  onClick,
}: ButtonClearFilterProps) => {
  if (!visible) {
    return null;
  }

  return (
    <div
      className={`z-10 bg-white rounded border text-gray-900
      border-gray-500 cursor-pointer flex justify-center items-center hover:border-green`}
      onClick={onClick}
      style={{
        height: '38px',
        aspectRatio: '1 / 1',
      }}
    >
      <FontAwesomeIcon icon={faFilterSlash} />
    </div>
  );
};
