import compare from 'trivial-compare';

import { APIModels } from '../..';

export const users = (data?: APIModels.User[]) => {
  if (!data) {
    return data;
  }

  const sorted = [...data];
  sorted.sort((a, b) => {
    const aName =
      `${a?.userProfiles?.[0]?.firstName} ${a?.userProfiles?.[0]?.lastName}`
        .trim()
        .toLowerCase();
    const bName =
      `${b?.userProfiles?.[0]?.firstName} ${b?.userProfiles?.[0]?.lastName}`
        .trim()
        .toLowerCase();
    return compare(aName, bName);
  });
  return sorted;
};
