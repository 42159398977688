import {
  faArrows,
  faCircleNotch,
  faDownload,
} from '@fortawesome/pro-light-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
  faClose,
  faSpinner,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@headlessui/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { createFilename } from '@agerpoint/utilities';

interface ImageViewerProps {
  open: boolean;
  handleCloseViewer: () => void;
  handleNavigation?: {
    next: () => void;
    previous: () => void;
  };
  children: JSX.Element;
  loading?: boolean;
  showDownloadButton?: boolean;
  downloadBlob?: Blob;
}
export const ImageViewer = ({
  open,
  handleCloseViewer,
  children,
  loading = false,
  showDownloadButton = false,
  downloadBlob,
  handleNavigation,
}: ImageViewerProps) => {
  const zoomContainerRef = useRef<HTMLDivElement>(null);
  const [viewerContainerRef, setViewerContainerRef] =
    useState<HTMLDivElement | null>(null);

  const [pos, setPos] = useState({ x: 0, y: 0, scale: 0.4 });
  const [panning, setPanning] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });
  const [origin, setOrigin] = useState({ top: 0, left: 0 });
  const [initialPos, setInitialPos] = useState({ x: 0, y: 0, scale: 0.4 });

  const onMouseLeave = () => {
    if (panning) {
      setPanning(false);
    }
  };

  const calcInitialXPos = () => {
    const imgW = zoomContainerRef.current?.getBoundingClientRect().width;
    const containerW = viewerContainerRef?.getBoundingClientRect().width;

    if (!imgW || !containerW) {
      return 0;
    }

    return containerW / 2 - imgW / 2;
  };

  const resetOrigin = useCallback(() => {
    if (viewerContainerRef) {
      const top = viewerContainerRef?.getBoundingClientRect().top;
      const left = viewerContainerRef?.getBoundingClientRect().left;
      setOrigin({ top, left });
    }
  }, [viewerContainerRef]);

  const handleArrowKeys = useCallback(
    (e: KeyboardEvent) => {
      if (!open || !handleNavigation) {
        return;
      }

      if (e.key === 'ArrowRight') {
        e.preventDefault();
        e.stopPropagation();
        handleNavigation.next();
        resetImagePosition();
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault();
        e.stopPropagation();
        handleNavigation.previous();
        resetImagePosition();
      }
    },
    [handleNavigation, open]
  );

  useEffect(() => {
    if (!handleNavigation || !open) {
      return;
    }

    window.addEventListener('keydown', handleArrowKeys);

    return () => {
      window.removeEventListener('keydown', handleArrowKeys);
    };
  }, [handleNavigation, handleArrowKeys, open]);

  useEffect(() => {
    if (open) {
      const init = {
        x: calcInitialXPos(),
        y: 25,
        scale: 0.4,
      };
      setInitialPos(init);
      setPos(init);
    }
  }, [open, viewerContainerRef]);

  useEffect(() => {
    if (zoomContainerRef && zoomContainerRef.current) {
      const ref = zoomContainerRef.current;

      ref.addEventListener('wheel', onScroll, {
        passive: false,
      });

      return () => {
        ref.removeEventListener('wheel', onScroll);
      };
    }

    return;
  });

  const onScroll = (e: any) => {
    e.preventDefault();
    resetOrigin();
    const res = zoomCallback(e, pos);
    setPos({
      scale: res.scale,
      x: res.x,
      y: res.y,
    });
  };

  const zoomCallback = (
    event: any,
    position: { x: number; y: number; scale: number }
  ) => {
    const { deltaY, clientX, clientY } = event;
    const { x, y } = position;
    let { scale } = position;
    const xs = (clientX - x) / scale;
    const ys = (clientY - y) / scale;
    const delta = -deltaY;
    delta > 0 ? (scale *= 1.05) : (scale /= 1.05);
    const pointX = clientX - xs * scale;
    const pointY = clientY - ys * scale;
    if (scale < 0.3) {
      return { x: x, y: y, scale: 0.3 };
    }
    return {
      x: pointX,
      y: pointY,
      scale,
    };
  };

  const onMouseMove = (e: any) => {
    e.preventDefault();
    if (!panning) {
      return;
    }
    const pointX = e.clientX - start.x;
    const pointY = e.clientY - start.y;
    setPos({
      scale: pos.scale,
      x: pointX,
      y: pointY,
    });
  };

  const onMouseDown = (e: any) => {
    e.preventDefault();

    setStart({ x: e.clientX - pos.x, y: e.clientY - pos.y });
    setPanning(true);
  };

  useEffect(() => {
    window.addEventListener('resize', resetOrigin);

    return () => {
      window.removeEventListener('resize', resetOrigin);
    };
  });

  const handleDownload = () => {
    if (!downloadBlob) {
      return;
    }
    const url = URL.createObjectURL(downloadBlob);
    const link = document.createElement('a');
    link.download = `${createFilename('capture_image', 'png')}`;
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  };

  const resetImagePosition = () => {
    setPos({
      x: initialPos.x,
      y: initialPos.y,
      scale: 0.4,
    });
  };

  const closeFn = useCallback(() => {
    resetImagePosition();
    handleCloseViewer();
  }, [handleCloseViewer]);

  return (
    <Dialog
      as="div"
      className="fixed inset-0 bg-gray-900 bg-opacity-75"
      style={{ zIndex: 99999999 }}
      open={open}
      onClose={closeFn}
      ref={(newRef) => setViewerContainerRef(newRef)}
    >
      <div className="relative w-full h-full">
        <div
          className="absolute w-12 h-12 flex justify-center items-center
        rounded-full bg-black bg-opacity-50 hover:bg-opacity-100 top-8 right-8 text-white text-4xl
        hover:cursor-pointer"
          style={{ zIndex: 9999 }}
          onClick={closeFn}
        >
          <FontAwesomeIcon icon={faClose} />
        </div>
        <div
          className="absolute w-12 h-12 flex justify-center items-center
                rounded-full bg-black bg-opacity-50 hover:bg-opacity-100 top-28 right-8 text-white text-3xl
                hover:cursor-pointer"
          style={{ zIndex: 9999 }}
          onClick={resetImagePosition}
        >
          <FontAwesomeIcon icon={faArrows} flip="horizontal" />
        </div>
        {handleNavigation && (
          <div
            className="absolute bottom-8 flex flex-row gap-4 left-1/2 -translate-x-1/2"
            style={{ zIndex: 9999 }}
          >
            <div
              className="w-12 h-12 flex justify-center items-center
        rounded-full bg-black bg-opacity-50 hover:bg-opacity-100 text-white text-4xl
        hover:cursor-pointer"
              onClick={async () => {
                handleNavigation.previous();
                resetImagePosition();
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div
              className="w-12 h-12 flex justify-center items-center
        rounded-full bg-black bg-opacity-50 hover:bg-opacity-100 text-white text-4xl
        hover:cursor-pointer"
              onClick={async () => {
                handleNavigation.next();
                resetImagePosition();
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        )}
        {showDownloadButton &&
          (downloadBlob ? (
            <div
              className="absolute w-12 h-12 flex justify-center items-center
                rounded-full bg-black bg-opacity-50 hover:bg-opacity-100 top-48 right-8 text-white text-3xl
                hover:cursor-pointer"
              style={{ zIndex: 9999 }}
              onClick={handleDownload}
            >
              <FontAwesomeIcon icon={faDownload} />
            </div>
          ) : (
            <div
              className="absolute w-12 h-12 flex justify-center items-center
                rounded-full bg-black bg-opacity-50 hover:bg-opacity-100 top-48 right-8 text-white text-3xl
                hover:cursor-pointer"
              style={{ zIndex: 9999 }}
            >
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ))}
        {loading ? (
          <div className="w-full h-full flex justify-center items-center">
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              className="text-white w-20 h-20"
            />
          </div>
        ) : null}
        <div className="qaqc-chart-viewer-container">
          <div
            className={`chart-container cursor-grab ${
              panning ? 'chart-container--panning' : ' '
            }`}
            onMouseLeave={onMouseLeave}
            ref={zoomContainerRef}
            onMouseDown={onMouseDown}
            onMouseUp={() => setPanning(false)}
            onMouseMove={onMouseMove}
            style={{
              transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`,
              transformOrigin: `-${origin.left}px -${origin.top}px`,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
