import { useCallback, useEffect, useState } from 'react';

interface useFormValidationErrors {
  [key: string]: string | undefined;
}

export interface useFormValidationState {
  errors: useFormValidationErrors;
  hasErrors: () => Promise<boolean>;
  advancedCallbacks: {
    /**
     * Instead, use hasErrors, which negates this function for you, to make the code cleaner.
     */
    validate: () => Promise<boolean>;
    reset: () => void;
    subscribe: <T>(validators: useFormValidationValidators<T>) => void;
    unsubscribe: (key: string) => void;
    update: (key: string, value: any) => void;
  };
}

interface useFormValidationValidators<T> {
  key: string;
  value: T;
  validators: ((value: T) => Promise<string | undefined>)[];
}

export const useFormValidation = (): useFormValidationState => {
  const [errors, setErrors] = useState<useFormValidationErrors>({});
  const [autovalidate, setAutovalidate] = useState<boolean>(false);
  const [validators, setValidators] = useState<
    useFormValidationValidators<any>[]
  >([]);

  const reset = useCallback(() => {
    setErrors({});
    setAutovalidate(false);
  }, []);

  const validate = useCallback(
    async (auto = false): Promise<boolean> => {
      const newErrors = await Promise.all(
        validators.map(async ({ key, value, validators }) => {
          const error = await validators.reduce(async (acc, validator) => {
            const prev = await acc;
            if (prev) {
              return prev;
            }
            return validator(value);
          }, Promise.resolve<string | undefined>(undefined));

          return { key, error };
        })
      );

      const errorsObject = newErrors.reduce((acc, { key, error }) => {
        if (error) {
          acc[key] = error;
        }
        return acc;
      }, {} as useFormValidationErrors);

      setErrors(errorsObject);
      setAutovalidate(true);

      const isValid = Object.keys(errorsObject).length === 0;

      if (!auto && isValid) {
        reset();
      }

      return isValid;
    },
    [validators, reset]
  );

  const hasErrors = useCallback(async () => !(await validate()), [validate]);

  useEffect(() => {
    if (autovalidate) {
      validate(true);
    }
  }, [validate]);

  const subscribe = useCallback(
    <T,>(validators: useFormValidationValidators<T>) => {
      setValidators((prevValidators) => [...prevValidators, validators]);
    },
    []
  );

  const unsubscribe = useCallback((key: string) => {
    setValidators((prevValidators) =>
      prevValidators.filter((validator) => validator.key !== key)
    );
  }, []);

  const update = useCallback((key: string, value: any) => {
    setValidators((prevValidators) => {
      return prevValidators.map((validator) => {
        if (validator.key === key) {
          return { ...validator, value };
        }
        return validator;
      });
    });
  }, []);

  return {
    errors,
    hasErrors,
    advancedCallbacks: {
      validate,
      reset,
      subscribe,
      unsubscribe,
      update,
    },
  };
};
