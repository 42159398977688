import React, { ReactNode } from 'react';

interface ExpansionContainerProps {
  children: ReactNode;
}

export function ExpansionContainerOuter({ children }: ExpansionContainerProps) {
  return (
    <div className="flex flex-col items-start overflow-x-hidden absolute inset-0 top-12">
      {children}
    </div>
  );
}

export function ExpansionContainerInner({ children }: ExpansionContainerProps) {
  return (
    <div className="flex flex-col h-full border-r border-gray-300 py-3 px-4 w-40">
      {children}
    </div>
  );
}
