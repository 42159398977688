import {
  IconName,
  IconPrefix,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useCallback, useMemo, useRef } from 'react';

interface IButtonText {
  id: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  label: string;
  loading?: boolean;
  leadingIcon?: IconName;
  leadingIconPrefix?: IconPrefix;
  leadingIconSpin?: boolean;
  leadingIconColor?: string;
  trailingIcon?: IconName;
  trailingIconPrefix?: IconPrefix;
  trailingIconSpin?: boolean;
  trailingIconColor?: string;
  textSize?: string;
}

// TODO: Implement disabled and loading states
const ButtonText = ({
  id,
  buttonRef: buttonRefProp,
  onClick,
  disabled,
  label,
  loading,
  leadingIcon,
  leadingIconPrefix = 'far',
  leadingIconSpin,
  leadingIconColor,
  trailingIcon,
  trailingIconPrefix = 'far',
  trailingIconSpin,
  trailingIconColor,
  textSize = 'text-sm',
}: IButtonText) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const labelClassName = useMemo(() => {
    let classes = '';
    if (disabled) {
      classes += ' text-gray-textSecondary';
    } else {
      classes += ' text-primary';
    }

    return classes.trim();
  }, [disabled]);

  const className = useMemo(() => {
    let classes = ` transition-all focus:underline
    duration-200 focus:outline-none outline-none gap-1
    flex flex-row items-center justify-center ${textSize} text-left `;

    if (loading) {
      classes += ' cursor-progress ';
    } else if (disabled) {
      classes += ' cursor-not-allowed ';
    } else {
      classes += ' cursor-pointer hover:underline decoration-primary';
    }

    return classes.trim();
  }, [disabled, loading, textSize]);

  const buildIcon = useCallback(
    (
      prefix: IconPrefix,
      icon?: IconName,
      spin?: boolean,
      color = 'text-gray-iconSecondary'
    ) => {
      if (!icon) return null;

      if (loading) {
        return (
          <div className="size-4 flex-center">
            <FontAwesomeIcon
              spin
              icon={faCircleNotch}
              className={`${textSize} text-gray-iconSecondary`}
            />
          </div>
        );
      }

      if (disabled) {
        return (
          <div className="size-4 flex-center">
            <FontAwesomeIcon
              icon={[prefix, icon]}
              spin={spin}
              className={`${textSize} text-gray-iconSecondary`}
            />
          </div>
        );
      }

      const isHex = color?.startsWith('#');
      return (
        <div className="size-4 flex-center">
          <FontAwesomeIcon
            icon={[prefix, icon]}
            spin={spin}
            className={`${textSize} ${color}`}
            style={{
              color: isHex ? color : undefined,
            }}
          />
        </div>
      );
    },
    [loading, disabled, textSize]
  );

  return (
    <button
      data-test-id={id}
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled}
      ref={buttonRefProp ?? buttonRef}
    >
      {buildIcon(
        leadingIconPrefix,
        leadingIcon,
        leadingIconSpin,
        leadingIconColor
      )}
      {label && <div className={labelClassName}>{label}</div>}
      {buildIcon(
        trailingIconPrefix,
        trailingIcon,
        trailingIconSpin,
        trailingIconColor
      )}
    </button>
  );
};

export { ButtonText };
