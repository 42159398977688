import { faDisplay } from '@fortawesome/pro-duotone-svg-icons';
import {
  faAngle,
  faArrowsToCircle,
  faArrowsUpDownLeftRight,
  faCameraViewfinder,
  faChevronDown,
  faChevronUp,
  faCircleDashed,
  faCircleDot,
  faEye,
  faEyeSlash,
  faFloppyDisk,
  faRulerHorizontal,
  faRulerVertical,
  faSquareDashed,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import { APIClient, APIUtils } from '@agerpoint/api';
import { Button } from '@agerpoint/component';
import {
  BackgroundOptionsValues,
  IPotreeViewerController,
  MeasurementOptionsValues,
  MixpanelNames,
} from '@agerpoint/types';
import { useGlobalStore, useToasts } from '@agerpoint/utilities';

import './potree-tools.scss';

interface PotreeToolsProps {
  viewerController?: IPotreeViewerController;
}

export const PotreeTools = ({ viewerController }: PotreeToolsProps) => {
  const toasts = useToasts();

  const {
    actions: { sendEvent },
  } = useGlobalStore();

  const queryClient = useQueryClient();

  const [expanded, setExpanded] = useState(false);

  const selectMeasurementTool = useCallback(
    (tool: MeasurementOptionsValues) => {
      sendEvent(MixpanelNames.MetricViewerToolSelected, {
        tool: 'Tool',
        type: tool,
      });

      viewerController?.setSelectedTool?.(tool);
    },
    [sendEvent, viewerController]
  );

  const showHideOnChange = useCallback(() => {
    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Label Toggle',
      type: '',
    });

    viewerController?.setMeasurementsVisible?.(
      !viewerController.info.measurementsVisible
    );
  }, [sendEvent, viewerController]);

  const removeAllMeasurements = useCallback(() => {
    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Remove All',
      type: '',
    });

    viewerController?.removeMeasurements?.();
  }, [sendEvent, viewerController]);

  const resetView = useCallback(() => {
    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Reset',
      type: '',
    });

    viewerController?.resetView?.();
  }, [sendEvent, viewerController]);

  const selectBackgroundOnChange = useCallback(
    (backgroundType: BackgroundOptionsValues) => {
      sendEvent(MixpanelNames.MetricViewerToolSelected, {
        tool: 'Background',
        type: backgroundType,
      });
      viewerController?.setBackground?.(backgroundType);
    },
    [sendEvent, viewerController]
  );

  const saveView = useCallback(() => {
    if (
      !viewerController?.info.viewerReady ||
      !viewerController?.info.captureJobMetadata?.captureId
    ) {
      return;
    }

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Save View',
      type: '',
    });

    const cameraState = viewerController?.getCameraSettings?.();

    if (!cameraState) return;

    const cameraStateJSON = JSON.stringify(cameraState);

    const data = {
      ...viewerController?.info.captureJobMetadata,
      cameraSettings: cameraStateJSON,
    };

    APIClient.putCaptureJobById(
      viewerController?.info.captureJobMetadata?.captureId.toString(),
      Number(viewerController?.info.captureJobMetadata?.id),
      data
    )
      .then(() => {
        toasts.add(toasts.prepare.entityUpdated('capture job', 'View saved.'));

        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            {
              captureId: Number(
                viewerController?.info.captureJobMetadata?.captureId
              ),
            },
          ],
        });

        viewerController?.setCaptureJobMetadata?.({
          ...viewerController?.info.captureJobMetadata,
          cameraSettings: cameraStateJSON,
        });
      })
      .catch(() => {
        toasts.add(toasts.prepare.error('Failed to save view!'));
      });
  }, [viewerController, queryClient, sendEvent, toasts]);

  const resetSavedView = useCallback(() => {
    if (!viewerController) return;
    if (!viewerController?.info.captureJobMetadata?.cameraSettings) return;

    sendEvent(MixpanelNames.MetricViewerToolSelected, {
      tool: 'Reset View',
      type: '',
    });

    viewerController?.setCameraSettings?.();
  }, [viewerController, sendEvent]);

  return (
    <div className="absolute top-0 bottom-0 right-0 z-30 m-0 flex text-black pointer-events-none">
      <div className="flex flex-col justify-center w-8 mr-4">
        <div className="bg-white flex flex-col items-center rounded py-2 pointer-events-auto relative gap-2">
          <Button.Icon
            id="angle-tool"
            icon={faAngle}
            onClick={() =>
              selectMeasurementTool(MeasurementOptionsValues.Angle)
            }
            title="Angle Measurement"
          />
          <Button.Icon
            id="area-tool"
            icon={faSquareDashed}
            onClick={() => selectMeasurementTool(MeasurementOptionsValues.Area)}
            title="Area Measurement"
          />
          <Button.Icon
            id="radius-tool"
            icon={faCircleDashed}
            onClick={() =>
              selectMeasurementTool(MeasurementOptionsValues.Circle)
            }
            title="Radius Measurement"
          />
          <Button.Icon
            id="distance-tool"
            icon={faRulerHorizontal}
            onClick={() =>
              selectMeasurementTool(MeasurementOptionsValues.Distance)
            }
            title="Distance Measurement"
          />
          <Button.Icon
            id="height-tool"
            icon={faRulerVertical}
            onClick={() =>
              selectMeasurementTool(MeasurementOptionsValues.Height)
            }
            title="Height Measurement"
          />
          <Button.Icon
            id="point-tool"
            icon={faCircleDot}
            onClick={() =>
              selectMeasurementTool(MeasurementOptionsValues.Point)
            }
            title="Point Location"
          />
          <Button.Icon
            id="show-hide-measurements"
            icon={
              viewerController?.info.measurementsVisible ? faEyeSlash : faEye
            }
            onClick={showHideOnChange}
            title={
              viewerController?.info.measurementsVisible
                ? 'Hide Labels'
                : 'Show Labels'
            }
          />
          <Button.Icon
            id="remove-all-measurements"
            icon={faTrash}
            onClick={removeAllMeasurements}
            title="Remove All Measurements"
          />
          <Button.Icon
            id="expand-collapse"
            icon={expanded ? faChevronUp : faChevronDown}
            onClick={() => {
              setExpanded(!expanded);
            }}
            title={expanded ? 'Show Less' : 'Show More'}
          />
          {expanded && (
            <>
              {viewerController?.info.captureJobMetadata && (
                <Button.Icon
                  id="save-view"
                  icon={faFloppyDisk}
                  onClick={saveView}
                  title="Save View"
                />
              )}
              <Button.Icon
                id="reset-saved-view"
                icon={faArrowsToCircle}
                onClick={resetSavedView}
                title="Reset Saved View"
              />
              <Button.Icon
                id="take-screenshot"
                icon={faCameraViewfinder}
                onClick={viewerController?.takeScreenshot}
                title="Take Screenshot"
                internalPadding="p-0.5"
              />
              <Button.Icon
                id="reset-view"
                icon={faArrowsUpDownLeftRight}
                onClick={resetView}
                title="Reset View"
              />
              <Button.Icon
                id="background-toggle"
                icon={faDisplay}
                iconColor="black-display"
                onClick={() =>
                  selectBackgroundOnChange(BackgroundOptionsValues.Black)
                }
                title="Black Background"
              />
              <Button.Icon
                id="grey-background"
                icon={faDisplay}
                iconColor="grey-display"
                onClick={() =>
                  selectBackgroundOnChange(BackgroundOptionsValues.Gradient)
                }
                title="Grey Background"
              />
              <Button.Icon
                id="white-background"
                icon={faDisplay}
                iconColor="white-display"
                onClick={() =>
                  selectBackgroundOnChange(BackgroundOptionsValues.White)
                }
                title="White Background"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
