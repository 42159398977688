import { faCircleNotch, faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';

import { PrimaryButton } from '@agerpoint/component';

import { TileViewLayout, TileViewProps } from './tile-view-types';

export function TileView<T>({
  data,
  tileGenerator,
  layout,
  pagination,
  error,
  noResults,
  loading,
}: TileViewProps<T>) {
  const layoutMapping: Map<TileViewLayout, string> = new Map([
    [TileViewLayout.LargeGrid, 'lg:w-1/2 md:w-1/2 sm:w-full w-full'],
    [TileViewLayout.SmallGrid, 'lg:w-1/4 md:w-1/3 sm:w-1/2 w-1/2'],
    [TileViewLayout.List, 'w-full'],
    [TileViewLayout.Cloud, 'md:w-full lg:w-1/2 xl:w-1/3 2xl:w-1/4'],
  ]);

  const lastRowRef = useRef<HTMLTableRowElement>(null);
  const processingPage = useRef<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        const target = entries[0];

        if (
          target.isIntersecting &&
          processingPage.current === false &&
          !loading
        ) {
          processingPage.current = true;
          await pagination?.loadNextPage();
          processingPage.current = false;
        }
      },
      {
        root: null,
        rootMargin: '1px',
        threshold: 0,
      }
    );
    if (lastRowRef.current) {
      observer.observe(lastRowRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [pagination, loading]);

  return (
    <div
      className={`overflow-y-auto overflow-x-hidden flex flex-row flex-wrap
        w-full content-start relative h-full ${
          layout === TileViewLayout.Cloud ? 'p-4' : 'mt-4 px-4'
        }`}
    >
      {data.map((d, id) => {
        return (
          <div key={id} className={layoutMapping.get(layout)}>
            {tileGenerator(d, layout, id)}
          </div>
        );
      })}
      <div className="w-full h-1" ref={lastRowRef} />
      {loading && (
        <div className="w-full">
          <div className="py-4 flex items-center justify-center">
            <FontAwesomeIcon
              icon={faCircleNotch}
              spin
              className="fa-spin text-4xl text-green-600"
            />
          </div>
        </div>
      )}
      {error && !loading && data.length !== 0 && (
        <div className="flex flex-row w-full items-center justify-center gap-2">
          {error?.title && (
            <span className="font-bold text-base text-center leading-none">
              {error.title}
            </span>
          )}
          {error?.action && (
            <PrimaryButton
              icon={<FontAwesomeIcon icon={faRefresh} />}
              label="Retry"
              size="x-small"
              onClicked={error.action}
              theme="white"
            />
          )}
        </div>
      )}

      {data.length === 0 && !loading && error !== undefined && (
        <div
          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 text-center`}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            {error.title && (
              <span className="font-bold text-2xl">{error.title}</span>
            )}
            {error.message && (
              <span className="text-base text-gray-500 font-light">
                {error.message}
              </span>
            )}
            {error.action && (
              <div className="pt-4">
                <PrimaryButton
                  label="Refresh"
                  icon={<FontAwesomeIcon icon={faRefresh} />}
                  onClicked={error.action}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {data.length === 0 &&
        !loading &&
        noResults !== undefined &&
        error === undefined && (
          <div
            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 text-center`}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              {noResults.icon && (
                <div className="text-6xl text-gray-iconSecondary pb-8">
                  {noResults.icon}
                </div>
              )}
              {noResults.title && (
                <span className="font-bold text-2xl">{noResults.title}</span>
              )}
              {noResults.message && (
                <span className="text-base text-gray-500 font-light">
                  {noResults.message}
                </span>
              )}
              {noResults.action && (
                <div className="pt-4">
                  <PrimaryButton
                    icon={noResults.actionIcon}
                    label={noResults.actionLabel}
                    onClicked={noResults.action}
                  />
                </div>
              )}
            </div>
          </div>
        )}
    </div>
  );
}
