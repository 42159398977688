import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Input } from '@agerpoint/component';
import {
  IPotreeViewerController,
  MaterialType,
  PotreeViewer2ControllerInfoClassificationProps,
} from '@agerpoint/types';
import { cvtRgbaArrToRgbaStr, cvtRgbaPctToStdRgba } from '@agerpoint/utilities';

import { CapturesViewerContext } from '../../captures-viewer/captures-viewer-context';
import { useCapturesViewerQueries } from '../../captures-viewer/captures-viewer-queries';
import { ColorPicker2 } from '../../color-picker2';

export const ClassificationColorPicker = ({
  viewerController,
  classificationClass,
}: {
  viewerController?: IPotreeViewerController;
  classificationClass: PotreeViewer2ControllerInfoClassificationProps;
}) => {
  const [expanded, setExpanded] = useState(false);

  const portal = useRef(document.createElement('div'));

  useEffect(() => {
    const p = portal.current;
    document.body.appendChild(p);
    return () => {
      document.body.removeChild(p);
    };
  }, []);

  const [colorTileRef, setColorTileRef] = useState<HTMLDivElement | null>(null);
  const [pickerRef, setPickerRef] = useState<HTMLDivElement | null>(null);

  const portalPos = useMemo(() => {
    if (!colorTileRef || !pickerRef) {
      return { x: 0, y: 0 };
    }

    const rect = colorTileRef.getBoundingClientRect();
    const pickRect = pickerRef.getBoundingClientRect();
    const whatQuad = (x: number, y: number) => {
      if (x < window.innerWidth / 2) {
        return y < window.innerHeight / 2 ? 1 : 3;
      }
      return y < window.innerHeight / 2 ? 2 : 4;
    };

    const quad = whatQuad(rect.left, rect.top);
    let position = { x: '', y: '' };

    if (quad === 1) {
      position = { x: `${rect.left + 10}px`, y: `${rect.top + 10}px` };
    } else if (quad === 2) {
      position = {
        x: `${rect.right - pickRect.width + 10}px`,
        y: `${rect.top + 10}px`,
      };
    } else if (quad === 3) {
      position = {
        x: `${rect.left + 10}px`,
        y: `${rect.top - pickRect.height}px`,
      };
    } else {
      // quad 4
      position = {
        x: `${rect.right - pickRect.width - 10}px`,
        y: `${rect.top - pickRect.height}px`,
      };
    }

    return position;
  }, [colorTileRef, pickerRef]);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    const clickHandler = (e: MouseEvent) => {
      if (pickerRef?.contains(e.target as Node)) {
        return;
      }

      if (colorTileRef?.contains(e.target as Node)) {
        return;
      }

      setExpanded(false);
    };

    document.addEventListener('mousedown', clickHandler);
    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  }, [expanded, pickerRef, colorTileRef]);

  return (
    <div
      className="w-5 h-5 rounded relative overflow-visible cursor-pointer ring-1 ring-gray-500"
      style={{
        background: `repeating-conic-gradient(#d3d3d3 0% 25%, transparent 0% 50%) 50% / 20px 20px`,
      }}
      tabIndex={0}
      onClick={() => {
        if (colorTileRef) {
          setExpanded(true);
        }
      }}
      ref={setColorTileRef}
    >
      <div
        className="absolute inset-0 rounded"
        style={{
          backgroundColor: cvtRgbaArrToRgbaStr(
            cvtRgbaPctToStdRgba(classificationClass.color)
          ),
        }}
      />
      {expanded &&
        createPortal(
          <div
            className="absolute z-200 bg-white shadow rounded-t-md rounded-b p-1 border border-gray-light"
            style={{
              top: portalPos.y,
              left: portalPos.x,
            }}
            ref={setPickerRef}
          >
            <ColorPicker2.RGB
              initialPercentRgbColor={classificationClass.color.slice(0, -1)}
              onChange={(color) => {
                viewerController?.updateClassificationClassByLabel(
                  classificationClass.label,
                  { color: [...color, 1] }
                );
              }}
              debounceTime={300}
            />
          </div>,
          portal.current
        )}
    </div>
  );
};

export const QaqcCaptureClassification = () => {
  const { viewerController, selectedCaptureJob } = useContext(
    CapturesViewerContext
  );
  const { analyticRequestQuery } = useCapturesViewerQueries({
    selectedCaptureJob,
  });

  return (
    <div className="w-full h-full overflow-auto bg-gray-700 rounded text-xs p-2">
      <Input.Select.Inline
        disabled={
          analyticRequestQuery.isLoading ||
          viewerController?.potreeController?.info.classificationClasses ===
            undefined ||
          selectedCaptureJob?.mlModel === undefined
        }
        id="3d-model-style-select"
        options={[MaterialType.RGBA, MaterialType.CLASSIFICATION]}
        value={viewerController?.potreeController?.info.pointStyle}
        setValue={(value) => {
          viewerController?.potreeController?.setPointStyle(
            value ?? MaterialType.RGBA
          );
        }}
        style={Input.style.mini}
        optionBuilder={(option) => {
          if (option === MaterialType.RGBA) {
            return 'RGB';
          }
          if (option === MaterialType.CLASSIFICATION) {
            return 'Classification';
          } else {
            return 'Unknown';
          }
        }}
      />
      {viewerController?.potreeController?.info.pointStyle ===
        MaterialType.CLASSIFICATION &&
        viewerController?.potreeController?.info.classificationClasses && (
          <div className="flex flex-col gap-1 text-sm pt-2">
            {viewerController?.potreeController?.info.classificationClasses.map(
              (classificationClass, index) => (
                <div
                  key={index}
                  className="flex flex-row w-full items-center justify-between"
                >
                  <Input.Checkbox
                    id={`classification-class-checkbox-${index}`}
                    value={classificationClass.visible}
                    setValue={(value) => {
                      viewerController?.potreeController?.updateClassificationClassByLabel(
                        classificationClass.label,
                        { visible: value }
                      );
                    }}
                    label={
                      <Input.Label label={classificationClass.labelString} />
                    }
                  />
                  <ClassificationColorPicker
                    viewerController={viewerController?.potreeController}
                    classificationClass={classificationClass}
                  />
                </div>
              )
            )}
          </div>
        )}
    </div>
  );
};
