import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useMemo, useRef, useState } from 'react';

import { useAgerStore } from '@agerpoint/utilities';

import { CloudButton } from '../../button';
import { CloudInput } from '../input';
import { InputIdContext } from '../input-id-context';
import {
  InputValidation,
  useInputValidationIntegration,
} from '../input-validation';

interface IInputTextArea {
  /**
   * The unique identifier for the input element.
   */
  id: string;

  /**
   * The error message to display below the input element.
   */
  error?: string;

  /**
   * The label to display above the input element.
   */
  label?: string;

  /**
   *  Determines whether the input element is required.
   */
  required?: boolean;

  /**
   * The placeholder text to display when the input is empty.
   */
  placeholder?: string;

  /**
   * The current value of the input element.
   */
  value: string;

  /**
   * The callback function to update the value of the input element.
   */
  setValue: (value: string) => void;

  /**
   * Determines whether to use the raw value without trimming leading whitespace.
   * Default value: false
   */
  useRawValue?: boolean;

  /**
   * The event handler for the blur event of the input element.
   */
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;

  /**
   * The event handler for the focus event of the input element.
   */
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;

  /**
   * The event handler for the click event of the input element.
   */
  onClick?: React.MouseEventHandler<HTMLTextAreaElement>;

  /**
   * The event handler for the keydown event of the input element.
   */
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;

  /**
   * The ref object or callback function to get a reference to the input element.
   */
  inputRef?: React.RefObject<HTMLTextAreaElement>;

  /**
   * Determines whether the input should use the compact style.
   */
  compact?: boolean;

  /**
   * Determines whether the input element is disabled.
   * Default value: false
   */
  disabled?: boolean;

  /**
   * Determines whether the input element is read-only.
   * Default value: false
   */
  readOnly?: boolean;

  /**
   * The validation configuration for the input element.
   */
  validation?: InputValidation<string>;

  /**
   * Determines whether the input element is focused.
   */
  autoFocus?: boolean;

  /**
   * Determines whether the input element is resizable.
   */
  resize?: boolean;

  /**
   * The number of visible text lines for the input element.
   */
  rows?: number | 'dynamic';

  /**
   * The confirmation configuration for the input element.
   */
  confirmation?: {
    confirm?: {
      label: string;
      onClick: () => void;
    };
    cancel?: {
      label: string;
      onClick: () => void;
    };
    loading: boolean;
  };
}

const InputTextArea = ({
  id,
  value,
  setValue,
  error,
  label,
  required,
  compact,
  placeholder,
  useRawValue = false,
  onBlur,
  onFocus,
  onClick,
  onKeyDown,
  disabled,
  inputRef: inputRefProp,
  readOnly,
  validation,
  autoFocus,
  resize = false,
  rows: rowsProp = 3,
  confirmation,
}: IInputTextArea) => {
  const [hasFocus, setHasFocus] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useAgerStore();

  const rows = useMemo(() => {
    if (rowsProp !== 'dynamic') {
      return rowsProp;
    }

    if (!inputRef.current) {
      return 3;
    }

    // Get the computed style of the element to determine the font
    const computedStyle = window.getComputedStyle(inputRef.current);
    const font =
      computedStyle.font ||
      `${computedStyle.fontSize} ${computedStyle.fontFamily}`;

    // Create a canvas to measure text width
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      context.font = font;

      // Split the text by newlines to handle '\n' characters
      const lines = value.split('\n');
      let totalLines = 0;

      // Get the width of the container element
      const elementWidth = inputRef.current.offsetWidth;

      lines.forEach((line) => {
        if (line === '') {
          totalLines += 1;
          return;
        }
        // Measure the width of each segment of text between newlines
        const lineWidth = context.measureText(line).width;
        // Calculate how many lines this segment will take
        const approxLineCount = Math.ceil(lineWidth / elementWidth);
        totalLines += approxLineCount; // Add this segment's lines to the total
      });

      return Math.min(Math.max(totalLines, 3), isMobile ? 10 : 20);
    }

    return 3;
  }, [value, rowsProp, hasFocus, isMobile]);

  const inputClassName = useMemo(() => {
    let className =
      'p-0 m-0 outline-none border-0 ring-0 bg-transparent w-full transition-colors';

    if (compact) {
      className += ` text-sm`;
    } else {
      className += ` text-base`;
    }

    if (disabled) {
      className += ` text-gray-textSecondary placeholder-gray-border cursor-not-allowed`;
    } else if (error) {
      className += ` text-gray-textPrimary placeholder-gray-textSecondary`;
    }

    return className;
  }, [disabled, error, compact]);

  const wrapperClassName = useMemo(() => {
    let className = 'rounded-lg bg-white transition-colors flex flex-col gap-2';

    if (compact) {
      className += ` px-1`;
    } else {
      className += ` p-4`;
    }

    if (disabled) {
      className += ` ring-1 ring-gray-border cursor-not-allowed`;
    } else if (error) {
      className += ` ring-1 ring-status-error focus:ring-status-error focus:ring-2 hover-overlay-5`;
    } else {
      className += ` ${
        hasFocus
          ? ' ring-2 ring-primary hover-overlay-5'
          : ' ring-1 ring-gray-border hover-overlay-5'
      }`;
    }

    return className;
  }, [disabled, error, hasFocus, compact]);

  const confirmationComponent = useMemo(() => {
    if (!confirmation) return null;

    if (compact) {
      return (
        <div className="flex flex-row justify-end gap-1">
          <CloudButton.Icon
            id={`${id}-cancel-button`}
            leadingIcon="xmark"
            leadingIconColor="text-status-error"
            label={confirmation.cancel?.label}
            onClick={confirmation.cancel?.onClick}
            disabled={confirmation.loading}
            compact
          />
          <CloudButton.Icon
            id={`${id}-confirm-button`}
            leadingIcon="check"
            leadingIconColor="text-primary"
            label={confirmation.confirm?.label}
            onClick={confirmation.confirm?.onClick}
            loading={confirmation.loading}
            compact
          />
        </div>
      );
    }

    return (
      <div className="flex flex-row justify-end gap-2">
        <CloudButton.Secondary
          id={`${id}-cancel-button`}
          label={confirmation.cancel?.label}
          disabled={confirmation.loading}
          leadingIcon={faXmark}
          danger
          onClick={confirmation.cancel?.onClick}
        />
        <CloudButton.Primary
          id={`${id}-confirm-button`}
          loading={confirmation.loading}
          label={confirmation.confirm?.label}
          leadingIcon={faCheck}
          onClick={confirmation.confirm?.onClick}
        />
      </div>
    );
  }, [confirmation, id]);

  useInputValidationIntegration({
    id,
    value,
    validation,
  });

  return (
    <InputIdContext.Provider value={id}>
      <div className="flex flex-col w-full">
        {label && <CloudInput.Label label={label} required={required} />}
        <div className={wrapperClassName} ref={wrapperRef}>
          <textarea
            readOnly={readOnly}
            ref={inputRefProp ?? inputRef}
            data-test-id={id}
            disabled={disabled}
            className={inputClassName}
            autoFocus={autoFocus}
            placeholder={placeholder}
            rows={rows}
            style={{
              boxShadow: 'none',
              resize: resize ? 'vertical' : 'none',
            }}
            onBlur={(e) => {
              if (wrapperRef.current?.contains(e.relatedTarget as Node)) {
                return;
              }
              setHasFocus(false);
              onBlur?.(e);
            }}
            onFocus={(e) => {
              setHasFocus(true);
              onFocus?.(e);
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              );
            }}
            onClick={onClick}
            onKeyDown={(e) => {
              onKeyDown?.(e);
            }}
            value={value}
            onChange={(e) => {
              let v = e.target.value;
              if (useRawValue) {
                setValue(v);
                return;
              }

              v = v.trimStart();
              setValue(v);
            }}
          />
          {confirmationComponent}
        </div>
        {error && <CloudInput.Error error={error} />}
      </div>
    </InputIdContext.Provider>
  );
};

export { InputTextArea };
