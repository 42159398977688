import { useState } from 'react';

import { useGetFileReportById } from '@agerpoint/api';
import {
  ExpansionContentContainer,
  ExpansionContentTabContentContainer,
  ExpansionContentTabsContainer,
} from '@agerpoint/component';
import { FileMapLayer, Layer } from '@agerpoint/types';

import { StyleProperty } from './style-property';

interface FileLayerStyle {
  layer: FileMapLayer;
  onChange: (layer: Layer) => Promise<void>;
}

export function FileLayerStyle({ layer, onChange }: FileLayerStyle) {
  const [mode, setMode] = useState('attribute');
  const { loading: loadingAttributes, error: errorLoadingAttributes } =
    useGetFileReportById({
      id: layer.entityId,
      resolve: (data) => {
        layer.fileName = data.fileName;
        layer.description = data.description;
        layer.fileDate = new Date(
          Date.parse(data.createDatetime)
        ).toDateString();
        layer.reportName = data.reportName;
        return data;
      },
    });
  return (
    <ExpansionContentContainer>
      <ExpansionContentTabsContainer>
        <StyleProperty
          name="Report"
          value={layer.reportName}
          selected={mode === 'attribute'}
          onSelect={() => setMode('attribute')}
        />
      </ExpansionContentTabsContainer>
      {!loadingAttributes && !errorLoadingAttributes && (
        <ExpansionContentTabContentContainer>
          {mode === 'attribute' ? (
            <div className={`px-4 py-3 text-gray-800 text-sm`}>
              <h3 className="font-bold mb-2 leading-3">File:</h3>
              <span className="text-system-gray-600" title={layer.fileName}>
                <div className="break-all flex flex-nowrap">
                  {layer.fileName}
                </div>
              </span>
              <h3 className="font-bold mb-2 mt-2 leading-3">Description:</h3>
              <div className="break-words text-system-gray-600">
                {layer.description}
              </div>
              <h3 className="font-bold mb-2 mt-2 leading-3">Date:</h3>
              <div className="text-system-gray-600">{layer.fileDate}</div>
            </div>
          ) : null}
        </ExpansionContentTabContentContainer>
      )}
      {errorLoadingAttributes && (
        <div className="text-system-gray-600">Error getting file info</div>
      )}
    </ExpansionContentContainer>
  );
}
