import { UploadFileWithExif } from '@agerpoint/types';

export const renameUploadFileNameDuplicates = (
  files: UploadFileWithExif[]
): UploadFileWithExif[] => {
  const groupedByName = files.reduce(function (acc, f) {
    (acc[f.data.name] = acc[f.data.name] || []).push(f);
    return acc;
  }, {} as { [key: string]: UploadFileWithExif[] });

  const noNameDuplicates: UploadFileWithExif[] = [];
  for (const name of Object.keys(groupedByName)) {
    const l = groupedByName[name].length;
    if (l === 1) {
      noNameDuplicates.push(groupedByName[name][0]);
    } else if (l > 1) {
      let index = 0;
      for (const file of groupedByName[name]) {
        if (index > 0) {
          const fileExtension = file.data.name.split('.').pop() as string;
          const extensionLength = fileExtension.length + 1; // Add 1 for the dot
          // Cut out last {extensionLength} characters + 1 for the dot
          file.name = `${file.data.name.slice(
            0,
            -extensionLength
          )} (${index}).${fileExtension}`;
        }
        index += 1;
        noNameDuplicates.push(file);
      }
    }
  }

  return noNameDuplicates;
};

export const removeUploadFileHashDuplicates = (
  files: UploadFileWithExif[]
): { files: UploadFileWithExif[]; duplicatesDetected: boolean } => {
  const groupedByHash = files.reduce(function (acc, f) {
    (acc[f.hash] = acc[f.hash] || []).push(f);
    return acc;
  }, {} as { [key: string]: UploadFileWithExif[] });

  const noFileDuplicates: UploadFileWithExif[] = [];
  let duplicatesDetected = false;
  for (const hash of Object.keys(groupedByHash)) {
    const l = groupedByHash[hash].length;
    if (l > 0) {
      noFileDuplicates.push(groupedByHash[hash][0]);
      if (l > 1) {
        duplicatesDetected = true;
      }
    }
  }

  return { files: noFileDuplicates, duplicatesDetected };
};
