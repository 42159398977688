/**
 * Generated by orval 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';
import type { CustomAttributeUnit } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCustomAttributeUnits = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CustomAttributeUnit[]>(
    { url: `/api/CustomAttributeUnit`, method: 'GET', signal },
    options
  );
};

export const getGetCustomAttributeUnitsQueryKey = () => {
  return [`/api/CustomAttributeUnit`] as const;
};

export const getGetCustomAttributeUnitsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomAttributeUnits>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCustomAttributeUnits>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCustomAttributeUnitsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomAttributeUnits>>
  > = ({ signal }) => getCustomAttributeUnits(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomAttributeUnits>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomAttributeUnitsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomAttributeUnits>>
>;
export type GetCustomAttributeUnitsQueryError = ErrorType<void>;

export const useGetCustomAttributeUnits = <
  TData = Awaited<ReturnType<typeof getCustomAttributeUnits>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCustomAttributeUnits>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomAttributeUnitsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomAttributeUnitById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CustomAttributeUnit>(
    {
      url: `/api/CustomAttributeUnit/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomAttributeUnitByIdQueryKey = (id: number) => {
  return [`/api/CustomAttributeUnit/${id}`] as const;
};

export const getGetCustomAttributeUnitByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomAttributeUnitById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomAttributeUnitById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCustomAttributeUnitByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomAttributeUnitById>>
  > = ({ signal }) => getCustomAttributeUnitById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomAttributeUnitById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomAttributeUnitByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomAttributeUnitById>>
>;
export type GetCustomAttributeUnitByIdQueryError = ErrorType<void>;

export const useGetCustomAttributeUnitById = <
  TData = Awaited<ReturnType<typeof getCustomAttributeUnitById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomAttributeUnitById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomAttributeUnitByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomAttributeUnitsByName = (
  name: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CustomAttributeUnit[]>(
    {
      url: `/api/CustomAttributeUnit/SearchByName/${encodeURIComponent(
        String(name)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomAttributeUnitsByNameQueryKey = (name: string) => {
  return [`/api/CustomAttributeUnit/SearchByName/${name}`] as const;
};

export const getGetCustomAttributeUnitsByNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomAttributeUnitsByName>>,
  TError = ErrorType<void>
>(
  name: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomAttributeUnitsByName>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCustomAttributeUnitsByNameQueryKey(name);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomAttributeUnitsByName>>
  > = ({ signal }) =>
    getCustomAttributeUnitsByName(name, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!name,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomAttributeUnitsByName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomAttributeUnitsByNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomAttributeUnitsByName>>
>;
export type GetCustomAttributeUnitsByNameQueryError = ErrorType<void>;

export const useGetCustomAttributeUnitsByName = <
  TData = Awaited<ReturnType<typeof getCustomAttributeUnitsByName>>,
  TError = ErrorType<void>
>(
  name: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomAttributeUnitsByName>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomAttributeUnitsByNameQueryOptions(
    name,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
