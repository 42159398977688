import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';

interface ButtonIconProps {
  id: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  label?: string;
  icon: IconDefinition;
  iconColor?: string;
  internalPadding?: string;
  loading?: boolean;
  title?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

export const ButtonIcon = ({
  id,
  onClick,
  disabled,
  label,
  icon,
  iconColor,
  internalPadding,
  loading,
  title,
  buttonRef,
}: ButtonIconProps) => {
  const className = useMemo(() => {
    let classes = ` transition-all rounded-full ring-gray-dark
    duration-200 focus:outline-none outline-none focus:ring-2 ring-offset-1
    flex flex-row items-center justify-center text-xs `;

    if (disabled) {
      classes += 'cursor-not-allowed opacity-50 text-disabled ';
    } else {
      classes += 'text-gray-dark hover-overlay-5';
    }

    return classes.trim();
  }, [disabled]);

  return (
    <button
      data-test-id={id}
      type="button"
      ref={buttonRef}
      onClick={loading ? undefined : onClick}
      className={className}
      disabled={loading || disabled}
      title={title}
      style={{
        height: label ? '28px' : '20px',
        width: label ? '28px' : '20px',
        minWidth: label ? '28px' : '20px',
        minHeight: label ? '28px' : '20px',
      }}
    >
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div
          className={`flex items-center justify-center ${
            internalPadding ?? ''
          }`}
          style={{
            width: label ? '14px' : '20px',
            height: label ? '14px' : '20px',
          }}
        >
          <FontAwesomeIcon
            icon={loading ? faCircleNotch : icon}
            className={`w-full h-full ${iconColor ?? ''}`.trim()}
            spin={loading}
          />
        </div>
        {label && (
          <div className="leading-none w-full h-full text-center">{label}</div>
        )}
      </div>
    </button>
  );
};
