import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminPointcloudsQueries } from './admin-pointclouds-queries';

export const AdminPointcloudsNew = () => {
  usePageTitle(() => 'Platform - Pointclouds', []);

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const [pointcloud, setPointcloud] = useState<
    Partial<APIModels.EptPointcloud>
  >({});

  const formValidation = useFormValidation();

  const { pointcloudPostMutation, organizationsQuery } =
    useAdminPointcloudsQueries();

  const [selectedOrg, setSelectedOrg] = useState<
    APIModels.Customer | undefined
  >();

  const createPointcloud = useCallback(async () => {
    if (pointcloudPostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    pointcloudPostMutation.mutate({
      data: pointcloud,
    });
  }, [formValidation, pointcloudPostMutation, pointcloud]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Pointclouds',
              path: isViteApp
                ? '/app/admin/platform/pointclouds' + params
                : '/admin/pointclouds',
              params,
            },
          ]}
        />
      </div>
      <div className="px-4 py-2 flex flex-row gap-2 justify-start items-center">
        <Button.Back
          id="new-pointcloud-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/pointclouds' + params);
            } else {
              navigate('/admin/pointclouds' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Pointcloud</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="pointcloud-name-input"
          value={pointcloud?.name || ''}
          setValue={(name) => {
            setPointcloud({ ...pointcloud, name });
          }}
          label={<Input.Label label="Name" required />}
          error={
            <Input.Error
              error={formValidation.errors['pointcloud-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
        <Input.Text.Single
          id="pointcloud-file-name-input"
          value={pointcloud?.uuid || ''}
          setValue={(uuid) => {
            setPointcloud({ ...pointcloud, uuid });
          }}
          label={<Input.Label label="File Name" required />}
          error={
            <Input.Error
              error={formValidation.errors['pointcloud-file-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('File Name')],
          }}
        />
        <Input.Select.Single
          id="organization-select"
          title="Organization"
          label={<Input.Label label="Organization" required />}
          error={
            <Input.Error error={formValidation.errors['organization-select']} />
          }
          value={selectedOrg}
          setValue={(o) => {
            setSelectedOrg(o);
            setPointcloud({ ...pointcloud, customerId: o?.id });
          }}
          options={organizationsQuery.data ?? []}
          optionBuilder={(o) =>
            o.customerDisplayName ?? o.customerName ?? 'Unknown'
          }
          loading={organizationsQuery.isLoading}
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Organization')],
          }}
        />
        <div className="flex flex-row justify-end items-center py-4">
          <Button.Primary
            id="new-pointcloud-create-button"
            label={'Create'}
            onClick={createPointcloud}
            loading={pointcloudPostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
