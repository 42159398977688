import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import {
  BreadCrumbs,
  Button,
  IconTypes,
  Input,
  SvgElement,
} from '@agerpoint/component';
import { availableAnalyticsIcons } from '@agerpoint/types';
import {
  convertNameToCamelCase,
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminAnalyticsQueries } from './admin-analytics-queries';

export const AdminAnalyticsNew = () => {
  usePageTitle(() => 'Platform - Analytics', []);

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const isViteApp = useIsViteApp();

  const formValidation = useFormValidation();
  const [analytic, setAnalytic] = useState<APIModels.Analytic>({});

  const { analyticPostMutation } = useAdminAnalyticsQueries();

  const createAnalytic = useCallback(async () => {
    if (analyticPostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    analyticPostMutation.mutate({
      data: analytic,
    });
  }, [analytic, formValidation, analyticPostMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Analytics',
              path: isViteApp
                ? '/app/admin/platform/analytics'
                : '/admin/analytics',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-analytics-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/analytics' + params);
            } else {
              navigate('/admin/analytics' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Analytic</h1>
      </div>
      <div className="h-full w-full pt-4">
        <div className="px-4 w-full flex flex-col max-w-lg gap-2">
          <Input.Text.Single
            id="analytic-name-input"
            value={analytic?.analyticName || ''}
            setValue={(analyticName) => {
              setAnalytic({ ...analytic, analyticName });
            }}
            label={<Input.Label label="Name" required />}
            error={
              <Input.Error
                error={formValidation.errors['analytic-name-input']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Name')],
            }}
          />
          <Input.Text.Single
            id="analytic-desc-input"
            value={analytic?.analyticDescription || ''}
            setValue={(analyticDescription) => {
              setAnalytic({ ...analytic, analyticDescription });
            }}
            label={<Input.Label label="Description" />}
          />
          <Input.Number.Integer
            id="analytic-cost-input"
            value={analytic?.dc}
            setValue={(dc) => {
              setAnalytic({
                ...analytic,
                dc,
              });
            }}
            label={<Input.Label label="Default Cost" required />}
            error={
              <Input.Error
                error={formValidation.errors['analytic-cost-input']}
              />
            }
            validation={{
              validationState: formValidation,
              validators: [Input.validators.required('Default Cost')],
            }}
            onlyPositive
          />
          <Input.Select.Single
            id="analytic-icon-input"
            options={availableAnalyticsIcons}
            title="Icon"
            optionBuilder={(i) => ({
              searchString: i ?? '',
              element: (
                <div
                  className="flex flex-row gap-2 items-center"
                  style={{
                    height: '20px',
                  }}
                >
                  <SvgElement
                    className="h-full"
                    type={convertNameToCamelCase(`fa-${i}`) as IconTypes}
                  />
                  <span>{i}</span>
                </div>
              ),
            })}
            value={analytic?.icon}
            setValue={(icon) => {
              setAnalytic({
                ...analytic,
                icon,
              });
            }}
            label={<Input.Label label="Icon" />}
          />
          <div className="w-full flex flex-row justify-end py-4">
            <Button.Primary
              id="create-analytic-button"
              label="Create"
              onClick={createAnalytic}
              disabled={analyticPostMutation.isPending}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
