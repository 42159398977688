import {
  faCircleNotch,
  faFilterSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncyEffect } from 'use-debouncy';

import { APIModels } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { usePageTitle, useQueryState } from '@agerpoint/utilities';

import { useAdminBlocksQueries } from './admin-blocks-queries';

export const AdminBlocksList = () => {
  usePageTitle(() => 'Platform - Blocks', []);

  const navigate = useNavigate();

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v.trim(),
    toUrlParam: (v) => v.trim(),
  });
  const [filter, setFilter] = useState<APIModels.BlockFilter>();

  const { blocksQuery, farmsLookupTable } = useAdminBlocksQueries(filter);

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        blockName: nameFilter.trim(),
      }));
    },
    500,
    [nameFilter]
  );

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      blockName: nameFilter.trim(),
    }));
  }, []);

  const hasFiltersApplied = useMemo(
    () => !!((filter?.blockName?.length ?? 0) > 0),
    [filter]
  );

  const clearFilters = useCallback(() => {
    setNameFilter('');
  }, []);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="flex flex-row justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">Blocks</h1>
        <Button.Primary
          id="new-block-button"
          label="New Block"
          icon={faPlus}
          onClick={() => {
            navigate('new', {
              state: {
                params: window.location.search,
              },
            });
          }}
        />
      </div>
      <div className="px-4 flex flex-row gap-2 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="admin-blocks-name-filter"
            setValue={setNameFilter}
            value={nameFilter}
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="p-4 h-full">
        <Datatable
          id="admin-blocks-list-datatable"
          data={blocksQuery.data?.pages.flatMap((p) => p) ?? []}
          cellOnClick={() => {
            return (row) => {
              navigate(`${row.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching blocks',
                  message: 'Adjust your filters and try again',
                  action: clearFilters,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No blocks yet',
                  message: 'Create block to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Block',
                }
          }
          error={
            blocksQuery.isError
              ? {
                  title: 'There was a problem loading blocks',
                  message: 'Try refreshing the page',
                  action: () => blocksQuery.refetch(),
                }
              : undefined
          }
          columns={[
            {
              label: 'ID',
              value: (row) => row.id,
              name: 'id',
            },
            {
              label: 'Name',
              value: (row) => row.blockName,
              flex: 3,
              name: 'name',
            },
            {
              label: 'Display Name',
              value: (row) => row.blockDisplayName,
              flex: 3,
              name: 'displayName',
            },
            {
              label: 'Farm',
              value: function FarmDisplayName(row) {
                if (!row.farmId) {
                  return null;
                }
                if (!farmsLookupTable) {
                  return <FontAwesomeIcon spin icon={faCircleNotch} />;
                }

                const f = farmsLookupTable[row.farmId];

                return f?.farmDisplayName ?? f?.farmName ?? null;
              },
              flex: 3,
              name: 'farm',
            },
          ]}
          rowHeight={50}
          style={{ ...dataTableAgerStyle, tableMinWidth: 800 }}
          loading={
            blocksQuery.isLoading ||
            blocksQuery.isFetchingNextPage ||
            filter === undefined
          }
          pagination={{
            threshold: 10,
            loadNextPage: () => {
              if (
                blocksQuery.isLoading ||
                blocksQuery.isFetchingNextPage ||
                !blocksQuery.hasNextPage ||
                filter === undefined
              ) {
                return;
              }

              blocksQuery.fetchNextPage();
            },
          }}
        />
      </div>
    </div>
  );
};
