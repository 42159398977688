import { useLocation, useNavigate } from 'react-router-dom';

import { useIsViteApp } from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminOrganizationsQueries } from './admin-organizations-queries';

export const AdminOrganizationsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';

  const { organizationQuery, regionsQuery } = useAdminOrganizationsQueries();

  const isViteApp = useIsViteApp();

  if (organizationQuery.isLoading) {
    return <PageLoadingState />;
  }

  if (organizationQuery.isError) {
    return (
      <PageErrorState
        entityName="organization"
        pluralEntityName="organizations"
        statusCode={organizationQuery.error.response?.status ?? 500}
        tryAgainCallback={() => organizationQuery.refetch()}
        tryAgainLoading={organizationQuery.isFetching}
        navigateBackCallback={() =>
          navigate(
            isViteApp
              ? '/app/admin/platform/organizations' + params
              : '/admin/organizations' + params
          )
        }
      />
    );
  }

  return (
    <div className="flex w-full h-full flex-col overflow-auto pt-4 max-w-lg px-4">
      <EntityDetailsSection
        items={[
          { label: 'ID', value: organizationQuery.data?.id },
          {
            label: 'UUID',
            value: organizationQuery.data?.customerUuid,
          },
          {
            label: 'Name',
            value: organizationQuery.data?.customerName,
          },
          {
            label: 'Display Name',
            value: organizationQuery.data?.customerDisplayName,
          },
          {
            label: 'Region',
            value: regionsQuery?.data?.find(
              (r) => r.id === organizationQuery?.data?.regionId
            )?.regionName,
          },
        ]}
      />
    </div>
  );
};
