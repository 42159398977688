import {
  faClose,
  faCubes,
  faMagnifyingGlass,
} from '@fortawesome/pro-light-svg-icons';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { APIClient } from '@agerpoint/api';
import { Button } from '@agerpoint/component';
import {
  CaptureJobTypes,
  EffectNames,
  JobTypeCategory,
  LdFlags,
  MaterialType,
} from '@agerpoint/types';
import {
  hasPermission,
  isGaussianJob,
  useActionListener,
  useGlobalStore,
  useIsViteApp,
} from '@agerpoint/utilities';

import { CapturesViewerContext } from '../../captures-viewer/captures-viewer-context';
import { useCapturesViewerQueries } from '../../captures-viewer/captures-viewer-queries';
import { Datatable, dataTableAgerSmallStyle } from '../../datatable/datatable';

interface IQaqcJobPickerItem {
  id: number;
  name: string;
  jobType: JobTypeCategory;
  status?: string;
}
interface CaptureJobAndExtractionJobTableRow extends IQaqcJobPickerItem {
  extractionJob: IQaqcJobPickerItem;
}

export const QaqcJobPicker = () => {
  const navigate = useNavigate();
  const { captureId, captureJobId, extractionJobId } = useParams();

  const isViteApp = useIsViteApp();

  const [showPicker, setShowPicker] = useState(false);
  const [lowResJobId, setLowResJobId] = useState<number>();
  const [specialCaptureJobId, setSpecialCaptureJobId] = useState<number>();
  const {
    selectedCaptureJob,
    setSelectedCaptureJob,
    viewerController,
    setSelectedCaptureExtractionJobId,
    selectedCaptureExtractionJobId,
  } = useContext(CapturesViewerContext);
  const { permissions } = useGlobalStore();

  const {
    captureQuery,
    captureJobsWithPlyInfoQueries,
    captureJobImagesQuery,
    captureJobImagesForLowResJobIdQuery,
    analyticRequestsQuery,
    specialCaptureJobByIdQuery,
  } = useCapturesViewerQueries({
    selectedCaptureJob,
    lowResJobId,
    specialCaptureJobId,
  });

  const [tableData, setTableData] = useState<
    CaptureJobAndExtractionJobTableRow[]
  >([]);
  const [completedJobs, setCompletedJobs] = useState<IQaqcJobPickerItem[]>([]);
  const [analyticCaptureJobs, setAnalyticCaptureJobs] = useState<
    IQaqcJobPickerItem[]
  >([]);
  const [analyticExtractionJobs, setAnalyticExtractionJobs] = useState<
    IQaqcJobPickerItem[]
  >([]);

  const hasPrismPermission = useMemo(
    () => hasPermission(LdFlags.PotreeImagePrism, permissions),
    [permissions]
  );

  useEffect(() => {
    const doAsync = async () => {
      if (!analyticRequestsQuery.data) return;

      const ar = analyticRequestsQuery.data;

      const acj: IQaqcJobPickerItem[] = [];
      const aej: IQaqcJobPickerItem[] = [];

      const res = await Promise.all(
        ar.map(async (request) => {
          if (!request.id) return [];
          const analyticJobs =
            await APIClient.getAnalyticRequestJobsByAnalyticRequestId(
              request.id
            );
          if (!analyticJobs) return [];
          return analyticJobs;
        })
      );
      res.flat().forEach((job: APIClient.AnalyticJob) => {
        if (job.entityName === JobTypeCategory.CaptureJob) {
          acj.push({
            id: job?.entityId || NaN,
            name: job?.name || '',
            jobType: JobTypeCategory.CaptureJob,
          });
        }
        if (job.entityName === JobTypeCategory.CaptureExtractionJob) {
          aej.push({
            id: job?.entityId || NaN,
            name: job?.name || '',
            jobType: JobTypeCategory.CaptureExtractionJob,
            status: job?.status || '',
          });
        }
      });
      setAnalyticCaptureJobs(acj);
      setAnalyticExtractionJobs(aej);
    };

    doAsync();
  }, [analyticRequestsQuery.data]);

  useEffect(() => {
    if (!captureQuery.data || !captureQuery?.data?.completedJobs?.length)
      return;
    const completedJobs = captureQuery.data?.completedJobs.map((job) => {
      return {
        id: job?.id || NaN,
        name: job?.name || '',
        jobType: JobTypeCategory.CaptureJob,
      };
    });
    setCompletedJobs(completedJobs);
  }, [captureQuery.data]);

  useEffect(() => {
    if (!analyticCaptureJobs || !analyticExtractionJobs || !completedJobs)
      return;

    const jobs = [
      ...completedJobs,
      ...analyticCaptureJobs,
      // ...analyticExtractionJobs,
    ];
    // get unique jobs (we combined capture jobs from completed jobs and analytic jobs)
    const uniqueCaptureJobs = jobs.filter(
      (job, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.id === job.id && t.name === job.name && t.jobType === job.jobType
        )
    );

    const tableData: CaptureJobAndExtractionJobTableRow[] =
      uniqueCaptureJobs.flatMap((captureJob) => {
        return analyticExtractionJobs.map((extractionJob) => ({
          ...captureJob,
          extractionJob,
        }));
      });

    const justCaptureJobs: CaptureJobAndExtractionJobTableRow[] =
      uniqueCaptureJobs.map((captureJob) => ({
        ...captureJob,
        extractionJob: {
          id: NaN,
          name: '',
          jobType: JobTypeCategory.CaptureExtractionJob,
        },
      }));

    setTableData([...tableData, ...justCaptureJobs]);
  }, [completedJobs, analyticCaptureJobs, analyticExtractionJobs]);

  useEffect(() => {
    if (!captureJobImagesForLowResJobIdQuery?.data) return;
    viewerController?.threeController?.removeCameraPositions();
    viewerController?.threeController?.loadCameraPositions(
      captureJobImagesForLowResJobIdQuery?.data ?? []
    );
  }, [captureJobImagesForLowResJobIdQuery?.data, viewerController]);

  const captureJobWithPlyInfo = useMemo(
    () =>
      captureJobsWithPlyInfoQueries?.find(
        (q) => q.data?.id === selectedCaptureJob?.id
      )?.data,
    [captureJobsWithPlyInfoQueries, selectedCaptureJob]
  );

  const drawCaptureImagesInPotree = useCallback(() => {
    if ((captureJobImagesQuery.data?.length ?? 0) === 0) {
      viewerController?.potreeController?.removeCameraPositions();
      return;
    }

    viewerController?.potreeController?.removeCameraPositions();
    viewerController?.potreeController?.loadCameraPositions(
      captureJobImagesQuery.data ?? [],
      hasPrismPermission
    );
  }, [viewerController, captureJobImagesQuery?.data]);

  const drawCaptureImagesInGs3d = useCallback(async () => {
    // special case for this one right now
    // we need to find the low res job and use its images

    const lowResJob = captureQuery?.data?.completedJobs?.find((job) => {
      return job.captureJobTypeId === CaptureJobTypes['Low Resolution'];
    });
    if (!lowResJob || !lowResJob?.id || !captureQuery?.data?.id) return;
    const lowResJobId = lowResJob.id;
    viewerController?.threeController?.setCaptureMetadata(captureQuery?.data);
    setLowResJobId(lowResJobId);
  }, [
    captureQuery?.data,
    captureJobImagesForLowResJobIdQuery,
    viewerController,
  ]);

  const pointCloudLoadedFn = useCallback(() => {
    if (!captureQuery.data?.id) return;

    // camera settings
    if (!selectedCaptureJob?.cameraSettings) {
      viewerController?.potreeController?.setCameraFrontView();
    } else {
      viewerController?.potreeController?.setCameraSettings();
    }

    // capture images
    if (selectedCaptureJob && isGaussianJob(selectedCaptureJob)) {
      drawCaptureImagesInGs3d();
    } else {
      drawCaptureImagesInPotree();
    }
  }, [
    selectedCaptureJob,
    viewerController,
    captureJobImagesQuery?.data,
    captureQuery?.data,
  ]);

  useActionListener(EffectNames.POTREE_POINT_CLOUD_LOADED, pointCloudLoadedFn);
  useActionListener(EffectNames.GS_3D_POINT_CLOUD_LOADED, pointCloudLoadedFn);

  useEffect(() => {
    // Only for Gaussian jobs
    if (!selectedCaptureJob) {
      return;
    }
    if (!isGaussianJob(selectedCaptureJob)) {
      return;
    }
    if (!captureJobWithPlyInfo) {
      return;
    }
    if (!captureJobWithPlyInfo.plyDownloadUrl) {
      return;
    }

    viewerController?.threeController?.setCaptureJobMetadata(
      captureJobWithPlyInfo
    );
    viewerController?.threeController?.loadPlyModel(
      captureJobWithPlyInfo.plyDownloadUrl
    );
  }, [
    selectedCaptureJob?.id,
    viewerController?.threeController?.info.viewerReady,
    captureJobWithPlyInfo?.id,
  ]);

  useEffect(() => {
    // Only for pointclouds

    if (!selectedCaptureJob) {
      return;
    }
    if (isGaussianJob(selectedCaptureJob)) {
      return;
    }
    if (!selectedCaptureJob?.eptPointcloudId) {
      return;
    }

    viewerController?.potreeController?.setCaptureJobMetadata(
      selectedCaptureJob
    );
    viewerController?.potreeController?.removePointcloud();
    viewerController?.potreeController?.removeMeasurements();
    viewerController?.potreeController?.removeObjects();
    viewerController?.potreeController?.clearAllTrunkLines();
    viewerController?.potreeController?.loadPointcloud(
      selectedCaptureJob.eptPointcloudId
    );
    viewerController?.potreeController?.setPointStyle(MaterialType.RGBA);
  }, [
    selectedCaptureJob?.id,
    selectedCaptureExtractionJobId,
    viewerController?.potreeController?.info.viewerReady,
  ]);

  useEffect(() => {
    if (specialCaptureJobByIdQuery.data) {
      const specialCaptureJob = specialCaptureJobByIdQuery.data;
      setSelectedCaptureJob?.(specialCaptureJob);
      if (extractionJobId) {
        setSelectedCaptureExtractionJobId?.(Number(extractionJobId));
      }
    }
  }, [specialCaptureJobByIdQuery.data, extractionJobId]);

  useEffect(() => {
    if (captureId && captureJobId && tableData?.length) {
      const findCaptureJob = captureQuery?.data?.completedJobs?.find(
        (job) => job.id === Number(captureJobId)
      );
      if (!findCaptureJob) {
        setSpecialCaptureJobId(Number(captureJobId));
        // we dont have the actual capture job object in memory
        console.error(`Capture job ${captureJobId} not found in table data`);
        return;
      }
      setSelectedCaptureJob?.(findCaptureJob);
      if (extractionJobId) {
        setSelectedCaptureExtractionJobId?.(Number(extractionJobId));
      }
    }
  }, [
    selectedCaptureExtractionJobId,
    selectedCaptureJob,
    captureJobId,
    extractionJobId,
    captureId,
    tableData,
    setSelectedCaptureExtractionJobId,
    setSelectedCaptureJob,
    navigate,
  ]);

  const openDifferentJob = useCallback(
    (row: CaptureJobAndExtractionJobTableRow) => {
      if (!row.extractionJob?.id) {
        if (isViteApp) {
          navigate(`/app/admin/operations/qaqc/${captureId}/${row.id}`);
        } else {
          navigate(`/ops/qaqc/${captureId}/${row.id}`);
        }
        return;
      }
      if (isViteApp) {
        navigate(
          `/app/admin/operations/qaqc/${captureId}/${row.id}/${row.extractionJob?.id}`
        );
      } else {
        navigate(`/ops/qaqc/${captureId}/${row.id}/${row.extractionJob?.id}`);
      }

      setShowPicker(false);
    },
    [selectedCaptureJob]
  );

  const NameInfo = () => {
    return (
      <div className="text-white truncate text-sm">
        <span className="text-gray text-xs pl-2">CaptureJobId:</span>
        {selectedCaptureJob?.id || '-'}
        <span className="text-gray text-xs pl-2">CaptureJobName:</span>
        {selectedCaptureJob?.name || '-'}
        <span className="text-gray text-xs pl-2">ExtractionJobId:</span>
        {selectedCaptureExtractionJobId || '-'}
      </div>
    );
  };

  return (
    <div
      className={`flex  items-center bg-gray-900 rounded-b-lg shadow p-2 pointer-events-auto ${
        showPicker ? 'flex-grow' : ''
      } `}
      style={{ height: showPicker ? 400 : 'unset' }}
    >
      {!showPicker ? (
        <div
          className="flex flex-row cursor-pointer"
          onClick={() => setShowPicker(true)}
        >
          <Button.Icon
            icon={faMagnifyingGlass}
            id="qaqc-job-picker"
            iconColor="text-white"
          />
          <NameInfo />
        </div>
      ) : (
        <div className="flex flex-col flex-grow h-full">
          <div className="flex flex-row w-full  pb-1">
            <Button.Icon
              icon={faClose}
              id="qaqc-job-picker"
              iconColor="text-white"
              onClick={() => setShowPicker(false)}
            />
            <NameInfo />
          </div>
          <Datatable
            data={tableData ?? []}
            style={dataTableAgerSmallStyle}
            rowHeight={40}
            noResults={{
              title: 'No jobs available',
              message: 'Check back later for updates or contact an admin',
            }}
            loading={false}
            columns={[
              {
                //id
                label: 'Capture Job ID',
                value: (row) => row.id,
              },
              {
                label: 'Job Name',
                value: (row) => row.name,
              },
              {
                label: 'Extraction Job ID',
                value: (row) => row.extractionJob?.id || '-',
              },
              {
                label: 'Ext Job Status',
                value: (row) => {
                  return row.extractionJob?.status || '-';
                },
              },
              {
                label: 'View',
                value: (row) => {
                  return (
                    <Button.Icon
                      id="qaqc-job-picker-view-button"
                      icon={faCubes}
                      onClick={() => openDifferentJob(row)}
                    />
                  );
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
