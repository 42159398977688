import { useCallback } from 'react';
import { MutateMethod } from 'restful-react';

import { Layer, PutLayerByIdPathParams, usePutLayerById } from '@agerpoint/api';
import { Group } from '@agerpoint/types';
import { toApiLayer } from '@agerpoint/utilities';

import { useProject } from '../state/use-project';

export function useSaveAndPersistLayerZIndex(
  setLayerZIndex: (groupId: number, layerId: number, zIndex: number) => void
) {
  const { mutate: putLayerById } = usePutLayerById({ id: NaN });

  const saveAndPersistLayerZIndex = useCallback(persistLayersZIndex, [
    putLayerById,
    setLayerZIndex,
  ]);

  async function persistLayersZIndex(
    groupId: number,
    layerId: number,
    zIndex: number
  ) {
    setLayerZIndex(groupId, layerId, zIndex);

    const { groups } = useProject.getState();
    await persistGroupLayers(
      groups,
      groupId,
      putLayerById as unknown as MutateMethod<
        void,
        Layer,
        void,
        PutLayerByIdPathParams
      >
    );
  }

  return saveAndPersistLayerZIndex;
}

export async function persistGroupLayers(
  groups: Group[],
  groupId: number,
  putLayerById: MutateMethod<void, Layer, void, PutLayerByIdPathParams>
) {
  const group = groups.find((group) => group.id === groupId);

  if (group) {
    const layers = group.layers;
    await Promise.all(
      layers.map((layer) =>
        putLayerById(toApiLayer(layer), {
          pathParams: { id: layer.id },
        })
      )
    );
  }
}
