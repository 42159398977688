import * as LDClient from 'launchdarkly-js-client-sdk';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Intermediate } from '@agerpoint/feature';
import { LDFlagChangeset, LDFlagSet } from '@agerpoint/types';
import {
  environment,
  useAgerStore,
  useGlobalStore,
} from '@agerpoint/utilities';

interface LaunchDarklySetupProps {
  children: JSX.Element;
}

export const LaunchDarklySetup = ({ children }: LaunchDarklySetupProps) => {
  const {
    user,
    permissions,
    actions: { setPermissions },
  } = useGlobalStore();

  const [launchDarklyInitialized, setLaunchDarklyInitialized] = useState(false);
  const [launchDarklyReady, setLaunchDarklyReady] = useState(false);
  const [launchDarklyChange, setLaunchDarklyChange] = useState<LDFlagChangeset>(
    {}
  );

  const client = useRef<LDClient.LDClient>();

  useEffect(() => {
    if (launchDarklyReady) {
      if (!client?.current) return;
      const flags = client.current.allFlags();
      setPermissions(flags);
    }
  }, [launchDarklyReady, launchDarklyChange, client, setPermissions]);

  const readyFn = useCallback(() => {
    setLaunchDarklyReady(true);
  }, []);

  const changeFn = useCallback((changeSet: LDFlagChangeset) => {
    setLaunchDarklyChange(changeSet);
  }, []);

  useEffect(() => {
    if (!user || launchDarklyInitialized || client.current) {
      return;
    }

    const context = {
      kind: 'user',
      key: user.id as string,
      email: user.userName,
      anonymous: false,
    };

    const ldClient = LDClient.initialize(environment.ld_client_key, context);
    client.current = ldClient;

    ldClient.on('ready', readyFn);
    ldClient.on('error', (err: Error) => {
      console.error(err);
    });
    ldClient.on('change', changeFn);
    setLaunchDarklyInitialized(true);

    return () => {
      ldClient.close();
    };
  }, [user, launchDarklyInitialized]);

  if (!Object.keys(permissions).length) {
    return <Intermediate message="Checking permissions ..." />;
  }

  return children;
};

export const LaunchDarklyCloudSetup = ({
  children,
}: LaunchDarklySetupProps) => {
  const {
    launchDarkly: { setFeatureFlags },
    user: { userInfo },
  } = useAgerStore();

  const [launchDarklyInitialized, setLaunchDarklyInitialized] = useState(false);
  const [launchDarklyReady, setLaunchDarklyReady] = useState(false);
  const [launchDarklyChange, setLaunchDarklyChange] = useState<LDFlagChangeset>(
    {}
  );
  const [flags, setFlags] = useState<LDFlagSet>({});

  useEffect(() => {
    setFeatureFlags?.(flags);
  }, [flags, setFeatureFlags]);

  const client = useRef<LDClient.LDClient>();
  useEffect(() => {
    if (launchDarklyReady) {
      if (!client?.current) return;
      const flags = client.current.allFlags();
      setFlags(flags);
    }
  }, [launchDarklyReady, launchDarklyChange, client]);

  const readyFn = useCallback(() => {
    setLaunchDarklyReady(true);
  }, []);

  const changeFn = useCallback((changeSet: LDFlagChangeset) => {
    setLaunchDarklyChange(changeSet);
  }, []);

  useEffect(() => {
    if (!userInfo || launchDarklyInitialized || client.current) {
      return;
    }

    const context = {
      kind: 'user',
      key: userInfo.id as string,
      email: userInfo.userName,
      anonymous: false,
    };

    const ldClient = LDClient.initialize(environment.ld_client_key, context);
    client.current = ldClient;

    ldClient.on('ready', readyFn);
    ldClient.on('error', (err: Error) => {
      console.error(err);
    });
    ldClient.on('change', changeFn);
    setLaunchDarklyInitialized(true);

    return () => {
      ldClient.close();
    };
  }, [userInfo, launchDarklyInitialized]);

  if (!Object.keys(flags).length) {
    return <Intermediate message="Checking permissions ..." />;
  }

  return children;
};
