import { SetState, create } from 'zustand';

import { PanelsState } from '@agerpoint/types';
import { devtools } from 'zustand/middleware';

export function createInitialPanelState(
  set: SetState<PanelsState>
): PanelsState {
  return {
    selectedLayerId: undefined,
    selectedFeatureIds: [],
    isDraggingFromLibrary: false,
    expansionInfo: { type: 'none' },
    currentExpansionId: undefined,
    isSidebarOpen: true,
    mapMode: '2d',
    toolMode: undefined,
    layerIdUpdateCount: 0,
    selectedOlUid: undefined,
    setIsDraggingFromLibrary: (isDragging) => {
      set((state) => {
        return { isDraggingFromLibrary: isDragging };
      });
    },
    setMapMode: (mapMode) =>
      set((state) => ({
        mapMode: mapMode,
      })),
    setToolMode: (toolMode) =>
      set(() => ({
        toolMode,
      })),
    toggleSidebar: () => {
      set((state) => ({
        isSidebarOpen: !state.isSidebarOpen,
      }));
    },
    toggleExpansion: (expansion) =>
      set((state) => {
        if (expansion.type === 'none') {
          return {
            expansionInfo: expansion,
            currentExpansionId: undefined,
          };
        } else {
          const isOpen = expansion.key === state.currentExpansionId;
          return {
            expansionInfo: !isOpen ? expansion : { type: 'none' },
            currentExpansionId: !isOpen ? expansion.key : undefined,
          };
        }
      }),
    setSelectedLayerId: (layerId, count = false) =>
      set((state) => ({
        selectedLayerId: layerId,
        selectedFeatureIds: [],
        layerIdUpdateCount: count
          ? state.layerIdUpdateCount + 1
          : state.layerIdUpdateCount,
      })),
    setSelectedFeatureIds: (featureIds) =>
      set(() => ({ selectedFeatureIds: [...featureIds] })),
    setSelectedOlUid: (olUid) => set(() => ({ selectedOlUid: olUid })),
  };
}

export const usePanels = create<PanelsState>()(
  devtools((set) => ({
    ...createInitialPanelState(set),
  }))
);
