import { useEffect, useState } from 'react';

import { PointSizeType } from '@agerpoint/types';
import { useGlobalStore } from '@agerpoint/utilities';

export const StandAloneViewerPointSizeType = () => {
  const {
    standAloneViewer: {
      pointSizeType,
      actions: { setPointSizeType },
    },
  } = useGlobalStore();

  const [allowedPointSizeTypes, setAllowedPointSizeTypes] = useState<
    { value: number; name: string }[]
  >([]);

  useEffect(() => {
    const pointSizes = Object.values(PointSizeType)
      .filter((a) => {
        return typeof a === 'number';
      })
      .map((a: any) => {
        return {
          value: a,
          name: PointSizeType[a],
        };
      });
    setAllowedPointSizeTypes(pointSizes);
  }, []);

  const selectedPointSize = (pointSize: number) => {
    setPointSizeType(pointSize);
  };

  return (
    <>
      {allowedPointSizeTypes.map((pntSizeType) => {
        return (
          <div className="flex items-center pb-1" key={pntSizeType.value}>
            <div className="flex items-center justify-between min-h-[25px] flex-row w-full">
              <div className="mx-1">
                <input
                  type="radio"
                  id={pntSizeType.name}
                  checked={pointSizeType === pntSizeType.value}
                  value={pntSizeType.value}
                  onChange={() => {
                    selectedPointSize(pntSizeType.value);
                  }}
                  className={`w-4 h-4 text-green bg-gray-100 border-gray-300 hover:ring-green hover:ring-2 cursor-pointer`}
                />
              </div>

              <div className={`ml-2 px-1 w-full truncate`}>
                <label
                  className="py-0 px-1 text-sm rounded border border-transparent truncate cursor-pointer"
                  htmlFor={pntSizeType.name}
                >
                  {pntSizeType.name}
                </label>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
