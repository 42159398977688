import {
  faCheck,
  faCircleCheck,
  faCircleNotch,
  faExclamationTriangle,
  faFilterSlash,
  faQuestionCircle,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as faSolidCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import compare from 'trivial-compare';
import { useDebouncyEffect } from 'use-debouncy';

import { APIModels, formatDate } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { CaptureJobTypes } from '@agerpoint/types';
import {
  convertDateToUtcBeginningOfDay,
  convertDateToUtcEndOfDay,
  getCapturesUnique3DModelList,
  useIsViteApp,
  useItemSelection,
  useQueryState,
} from '@agerpoint/utilities';

import { useOpsPipelineCapturesQueries } from './pipeline-captures-queries';

export const PipelineCapturesList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isViteApp = useIsViteApp();

  const preselectedCaptures = location.state?.preselectedCaptureIds ?? [];

  const [filter, setFilter] = useState<APIModels.CaptureFilter>();

  const { capturesQuery, projectsQuery, usersQuery, organizationsQuery } =
    useOpsPipelineCapturesQueries({ filter });

  const [nameFilter, setNameFilter] = useQueryState<string>({
    paramName: 'name',
    initialValue: '',
    fromUrlParam: (v) => v.trim(),
    toUrlParam: (v) => v.trim(),
  });

  const [selectedFilterProjects, setSelectedFilterProjects] = useQueryState<
    APIModels.Project[]
  >({
    paramName: 'projects',
    initialValue: [],
    fromUrlParam: (a) => {
      const splitted = a?.split(',');
      return [...(projectsQuery.data || [])]
        .sort((a, b) => compare(a.id, b.id))
        .filter((x) => x.id && splitted?.includes(x.id.toString() ?? ''));
    },
    toUrlParam: (a) => {
      return a.map((x) => x.id).join(',');
    },
    retryInitWhen: projectsQuery.isSuccess,
  });

  const [selectedFilterUsers, setSelectedFilterUsers] = useQueryState<
    APIModels.User[]
  >({
    paramName: 'users',
    initialValue: [],
    fromUrlParam: (a) => {
      return (
        usersQuery.data?.filter((x) => a?.includes(x.id as string) ?? false) ??
        []
      );
    },
    toUrlParam: (a) => {
      return a.map((x) => x.id).join(',');
    },
    retryInitWhen: usersQuery.isSuccess,
  });

  const [selectedFilterOrganizations, setSelectedFilterOrganizations] =
    useQueryState<APIModels.Customer[]>({
      paramName: 'organizations',
      initialValue: [],
      fromUrlParam: (a) => {
        const splitted = a?.split(',').map((x) => Number(x));
        const filtered =
          organizationsQuery.data?.filter((x) => {
            return splitted?.includes(x.id as number) ?? false;
          }) ?? [];
        return filtered;
      },
      toUrlParam: (a) => {
        return a.map((x) => x.id).join(',');
      },
      retryInitWhen: organizationsQuery.isSuccess,
    });

  const [selectedFilterCaptureDate, setSelectedFilterCaptureDate] =
    useQueryState<[Date | undefined, Date | undefined]>({
      paramName: 'capture_date',
      initialValue: [undefined, undefined],
      toUrlParam: (v) => {
        if (v?.[0] && v?.[1]) {
          const start = formatDate(v[0].toString());
          const end = formatDate(v[1].toString());

          return `${start}-${end}`;
        }
        return '';
      },
      fromUrlParam: (v) => {
        const dates = v.split('-');
        if (dates.length !== 2) {
          return [undefined, undefined];
        }

        const [start, end] = dates;

        const startDate = new Date(start);
        const endDate = new Date(end);

        return [startDate, endDate];
      },
    });

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      archived: false,
      captureName: nameFilter.trim(),
      orderBy: 'scanDatetime',
      orderAscending: false,
    }));
  }, []);

  useEffect(() => {
    setFilter((prev) => {
      let adjustedStartScanDate = convertDateToUtcBeginningOfDay(
        selectedFilterCaptureDate?.[0]
      );

      const adjustedEndScanDate = convertDateToUtcEndOfDay(
        selectedFilterCaptureDate?.[1]
      );

      if (adjustedEndScanDate === undefined) {
        adjustedStartScanDate = undefined;
      }

      return {
        ...prev,
        projectUuids: selectedFilterProjects.map((x) => x.uuid as string),
        userUuids: selectedFilterUsers.map((x) => x.id as string),
        customerId: selectedFilterOrganizations.map((x) => x.id as number),
        startScanDatetime: adjustedStartScanDate?.toISOString(),
        endScanDatetime: adjustedEndScanDate?.toISOString(),
      };
    });
  }, [
    selectedFilterProjects,
    selectedFilterUsers,
    selectedFilterOrganizations,
    selectedFilterCaptureDate,
  ]);

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        captureName: nameFilter.trim(),
      }));
    },
    500,
    [nameFilter]
  );

  const captures = useMemo(
    () => capturesQuery.data?.pages.flatMap((page) => page) ?? [],
    [capturesQuery.data]
  );

  const captureSelection = useItemSelection<number, APIModels.Capture>({
    items: captures,
    dependencies: [captures.length],
    idField: 'id',
  });

  useEffect(() => {
    // Preselecting data from state
    if (
      preselectedCaptures.length > 1 &&
      !captureSelection.hasSelectedItems &&
      captures.length > 0
    ) {
      const selectedCaptures = captures.filter((capture) =>
        preselectedCaptures.includes(capture.id)
      );

      if (selectedCaptures.length > 0) {
        captureSelection.addSelectionList(
          selectedCaptures.map((x) => ({
            key: x.id as number,
            value: x,
          }))
        );
      }

      // clear navigation state so we're not reselecting data on every data change from now on
      navigate('', {
        state: undefined,
      });
    }
  }, [captures.length]);

  const hasFiltersApplied = useMemo(
    () =>
      !!(
        (filter?.captureName?.length ?? 0) > 0 ||
        (filter?.projectUuids ?? []).length > 0 ||
        (filter?.userUuids ?? []).length > 0 ||
        (filter?.customerId ?? []).length > 0 ||
        (filter?.startScanDatetime && filter?.endScanDatetime)
      ),
    [filter]
  );

  const clearFilter = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setNameFilter('');
    setSelectedFilterProjects([]);
    setSelectedFilterUsers([]);
    setSelectedFilterOrganizations([]);
    setSelectedFilterCaptureDate([undefined, undefined]);

    searchParams.delete('organizations');
    searchParams.delete('users');
    searchParams.delete('name');
    searchParams.delete('capture_date');
    searchParams.delete('projects');
    navigate(
      {
        pathname: isViteApp
          ? '/app/admin/pipelines/captures'
          : '/ops/pipeline/captures',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, []);

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <div className="px-4">
        {!isViteApp && (
          <BreadCrumbs
            items={[
              {
                label: 'Operations',
                path: '/ops',
              },
              {
                label: 'Pipelines',
                path: '/ops/pipeline',
              },
            ]}
          />
        )}
      </div>
      <div className="flex flex-row justify-between items-center px-4 py-2">
        <h1 className="text-3xl font-bold">
          {isViteApp ? 'Pipelines - Captures' : 'Captures'}
        </h1>
        {captureSelection.hasSelectedItems && (
          <div className="flex flex-row gap-1">
            <Button.Primary
              id="pipeline-captures-hd-ml-pipelines"
              label="HD and ML Pipeline"
              onClick={() => {
                let captureIds = captureSelection
                  .getSelectionArray()
                  .map((x) => x.id?.toString() ?? '')
                  .filter((x) => x.length > 0)
                  .join(',');

                captureIds = encodeURIComponent(captureIds);

                navigate(
                  isViteApp
                    ? `/app/admin/pipelines/captures/hd-ml-pipeline?captures=${captureIds}`
                    : `/ops/pipeline/captures/hd-ml-pipeline?captures=${captureIds}`,
                  {
                    state: {
                      params: window.location.search,
                    },
                  }
                );
              }}
            />
            <Button.Secondary
              id="clear-all-selected-captures"
              label={`Clear Selection (${captureSelection.selectionSize})`}
              onClick={() => {
                captureSelection.clearSelection();
              }}
              icon={faXmark}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2 px-4 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="pipeline-captures-name-search"
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
            value={nameFilter}
            setValue={setNameFilter}
          />
        </div>
        <Input.Select.Multi
          id="project-filter-select"
          placeholder="Projects"
          title="Projects"
          options={projectsQuery.data ?? []}
          value={selectedFilterProjects}
          loading={projectsQuery.isLoading}
          setValue={setSelectedFilterProjects}
          optionBuilder={(o) => o.name}
        />

        <Input.Select.Multi
          id="user-filter-select"
          placeholder="Users"
          title="Users"
          options={usersQuery.data ?? []}
          value={selectedFilterUsers}
          loading={usersQuery.isLoading}
          setValue={setSelectedFilterUsers}
          optionBuilder={(o) =>
            `${o?.userProfiles?.[0]?.firstName} ${o?.userProfiles?.[0]?.lastName}`.trim()
          }
        />
        <Input.Select.Multi
          id="organization-filter-select"
          placeholder="Organizations"
          title="Organizations"
          options={organizationsQuery.data ?? []}
          value={selectedFilterOrganizations}
          loading={organizationsQuery.isLoading}
          setValue={setSelectedFilterOrganizations}
          optionBuilder={(o) => o.customerDisplayName ?? 'Unknown'}
        />
        <div className="w-48">
          <Input.Date.Range
            id="capture-date-filter"
            placeholder="Capture Date Range"
            value={selectedFilterCaptureDate}
            setValue={setSelectedFilterCaptureDate}
          />
        </div>
        <Button.ClearFilter visible={hasFiltersApplied} onClick={clearFilter} />
      </div>
      <div className="p-4 h-full">
        <Datatable
          id="pipeline-captures-datatable"
          data={captures ?? []}
          cellOnClick={() => {
            return (row) => {
              navigate(
                isViteApp
                  ? `/app/admin/pipelines/captures/hd-ml-pipeline?captures=${row?.id}`
                  : `/ops/pipeline/captures/hd-ml-pipeline?captures=${row?.id}`,
                {
                  state: {
                    params: window.location.search,
                  },
                }
              );
            };
          }}
          columns={[
            {
              label: '',
              value: (row) => {
                if (row.completedJobs?.[0]?.eptPointcloudId) {
                  return <FontAwesomeIcon icon={faCheck} />;
                }
                if (!row.scanDatetime) {
                  return null;
                }
                const scanDate = Date.parse(row?.scanDatetime);
                const now: number = new Date().getTime();
                const hours = Math.abs(now - scanDate) / 36e5;

                if (hours > 24) {
                  return <FontAwesomeIcon icon={faExclamationTriangle} />;
                }

                return <FontAwesomeIcon icon={faCircleNotch} spin />;
              },
              flex: 0.25,
              style: {
                bodyStyle: 'flex justify-center',
              },
              name: 'status',
            },
            {
              label: 'Name',
              value: (row) => {
                return (
                  <span title={row.captureName || ''}>{row.captureName}</span>
                );
              },
              flex: 2,
              name: 'captureName',
              sortKey: 'captureName',
            },

            {
              label: 'Capture Date',
              value: (row) =>
                row.scanDatetime ? formatDate(row.scanDatetime) : null,
              sortKey: 'scanDatetime',
              name: 'scanDatetime',
            },
            {
              label: 'Create Date',
              value: (row) =>
                row.createDatetime ? formatDate(row.createDatetime) : null,
              sortKey: 'createDatetime',
              name: 'createDatetime',
            },
            {
              label: '3D Model',
              value: (row) => {
                return getCapturesUnique3DModelList(row)?.join(', ');
              },
              name: 'resolution',
            },
            {
              label: 'HD Jobs',
              flex: 0.5,
              value: (row, index) => {
                const hdJobs =
                  row.completedJobs?.filter(
                    (job) =>
                      job.captureJobTypeId ===
                      CaptureJobTypes['High Resolution']
                  ) ?? [];

                const mlModelsAndEngineStrings = hdJobs.map(
                  (j) => `${j.mosaicEngine} - ${j.mlModel}`
                );

                return (
                  <div className="relative flex flex-row gap-2 p-1">
                    <span>{hdJobs.length}</span>
                    {mlModelsAndEngineStrings.length > 0 && (
                      <Button.Icon
                        id={`pipeline-captures-list-hd-jobs-${index}`}
                        icon={faQuestionCircle}
                        onClick={() => {
                          navigate(
                            isViteApp
                              ? `/app/admin/pipelines/captures/${row.id}/hd-jobs`
                              : `/ops/pipeline/captures/${row.id}/hd-jobs`,
                            {
                              state: {
                                params: window.location.search,
                              },
                            }
                          );
                        }}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              label: captureSelection.isEverythingSelected ? (
                <Button.Small
                  id="captures-list-deselect-all"
                  label={'Deselect All'}
                  onClick={() => {
                    captureSelection.toggleSelectionEverything();
                  }}
                  icon={faSolidCircleCheck}
                  iconColor={'text-accent'}
                />
              ) : (
                <Button.Small
                  id="captures-list-select-all"
                  label={'Select All'}
                  onClick={() => {
                    captureSelection.toggleSelectionEverything();
                  }}
                  icon={faCircleCheck}
                />
              ),
              value: (row, index) => {
                const isSelected = row.id
                  ? captureSelection.isSelected(row.id)
                  : false;
                return (
                  <div className="p-1">
                    <Button.Icon
                      id={`captures-list-select-one-${index}`}
                      icon={isSelected ? faSolidCircleCheck : faCircleCheck}
                      onClick={(e) => {
                        if (!row.id) {
                          return;
                        }
                        if (e.shiftKey) {
                          captureSelection.addBulkSelectionUntilItem(
                            row.id,
                            row
                          );
                        } else {
                          captureSelection.toggleSelection(row.id, row);
                        }
                      }}
                      iconColor={isSelected ? 'text-accent' : undefined}
                    />
                  </div>
                );
              },
              flex: 0.75,
              style: { columnWrapperStyle: 'flex justify-center' },
            },
          ]}
          loading={
            capturesQuery.isLoading ||
            capturesQuery.isFetchingNextPage ||
            filter === undefined
          }
          noResults={
            hasFiltersApplied
              ? {
                  title: 'No matching captures',
                  message: 'Adjust your filters and try again',
                  action: clearFilter,
                  actionIcon: <FontAwesomeIcon icon={faFilterSlash} />,
                  actionLabel: 'Clear Filters',
                }
              : {
                  title: 'No captures yet',
                }
          }
          error={
            capturesQuery.isError
              ? {
                  title: 'There was a problem loading captures',
                  message: 'Try refreshing the page',
                  action: () => capturesQuery.refetch(),
                }
              : undefined
          }
          pagination={{
            threshold: 10,
            loadNextPage: () => {
              if (
                !capturesQuery.hasNextPage ||
                capturesQuery.isFetchingNextPage ||
                capturesQuery.isLoading ||
                filter === undefined
              ) {
                return;
              }

              capturesQuery.fetchNextPage();
            },
          }}
          rowHeight={50}
          style={{
            ...dataTableAgerStyle,
            tableMinWidth: 950,
          }}
          sort={{
            key: filter?.orderBy,
            order: filter?.orderAscending ? 'asc' : 'desc',
          }}
          setSort={(options) => {
            setFilter((prev) => ({
              ...prev,
              orderBy: options.key,
              orderAscending: options.order === 'asc',
            }));
          }}
        />
      </div>
    </div>
  );
};
