import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colord } from 'colord';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import {
  HexColorInput,
  HexColorPicker,
  RgbaStringColorPicker,
} from 'react-colorful';
import { useDebouncyFn } from 'use-debouncy';

import { Label, PrimaryButton } from '@agerpoint/component';

interface CustomPickerProps {
  color: string;

  /**
   * To debounce `onSave` by number of milliseconds.
   *
   * If value is above 0, "Save" and "Reset" buttons are not rendered, as this is intended
   * to be used to debounce frequent `onChange` invocations, especially when
   * dragging.
   */
  debounce?: number;

  /**
   * When `true`, opacity UI section is not rendered.
   */
  disableAlpha?: boolean;
  onChange: (color: string) => void;
  onClear?: () => void;
  onSave?: (color: string) => Promise<void>;
}

const presets: string[] = [];

export function CustomPicker({
  color,
  debounce,
  disableAlpha,
  onChange,
  onClear,
  onSave,
}: CustomPickerProps) {
  const [isSaving, setIsSaving] = useState<boolean>();
  const debounceSave = debounce && debounce > 0;
  const onDebouncedSave = useDebouncyFn(onSaveColor, debounce);
  const colorModel = colord(color);
  const alpha = colorModel.alpha();

  const presetIndex = useMemo(() => {
    const hexColor = colorModel.alpha(1).toHex();
    let index = -1;
    while (index < 0) {
      index = presets.findIndex((preset) => preset === hexColor);
      if (index === -1) {
        presets.push(hexColor);
        while (presets.length > 9) {
          presets.shift();
        }
      }
    }
    return index;
  }, [colorModel]);

  const [internalColorState, setInternalColorState] = useState(color);

  useEffect(() => {
    internalOnChange(internalColorState);
  }, [internalColorState]);

  return (
    <div className="flex flex-col">
      <div className="custom-picker">
        {!disableAlpha ? (
          <>
            <RgbaStringColorPicker
              color={internalColorState}
              onChange={setInternalColorState}
            />
            <div className="flex items-center mt-2">
              <span className="w-full text-sm">
                Opacity: {colord(color).alpha()}
              </span>
              <PrimaryButton
                className="w-1/2"
                theme={'white'}
                size={'small'}
                disabled={false}
                label={'Transparent'}
                onClicked={() =>
                  internalOnChange(colord(color).alpha(0).toRgbString())
                }
              />
            </div>
          </>
        ) : (
          <HexColorPicker color={color} onChange={internalOnChange} />
        )}
        <div className="flex flex-row items-center">
          <Label htmlFor="hexInput" className="text-sm mr-4">
            Hex
          </Label>
          <HexColorInput
            id="hexInput"
            className={`focus:ring-green
            focus:border-green
            block
            w-full
            shadow-sm
            sm:text-sm
            border-gray-500
            rounded
            placeholder-gray
            relative mt-6`}
            color={!disableAlpha ? colord(color).toHex() : color}
            onChange={(color) => {
              color = disableAlpha
                ? color
                : colord(color).alpha(alpha).toRgbString();

              internalOnChange(color);
            }}
          />
        </div>
        <div className="flex flex-row">
          {Array.from({ length: Math.max(9, presets.length) }).map((_, i) => (
            <button
              key={i}
              className="inline-block w-5 h-5 rounded-sm border border-gray-100"
              style={{ backgroundColor: presets[i] || 'white' }}
              disabled={!presets[i]}
              onClick={() =>
                presets[i] &&
                internalOnChange(
                  disableAlpha
                    ? presets[i]
                    : colord(presets[i]).alpha(alpha).toRgbString(),
                  true
                )
              }
            />
          ))}
        </div>
      </div>

      {!debounceSave && onSave && (
        <div className="flex full space-x-2 justify-end mt-4">
          <PrimaryButton
            className="w-1/2"
            theme={'white'}
            size={'small'}
            disabled={!onClear}
            label={'Reset'}
            onClicked={onClear ? onClear : undefined}
          />
          <PrimaryButton
            className="w-1/2"
            theme={'primary'}
            size={'small'}
            disabled={isSaving}
            label={
              isSaving ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className="fa-spin text-white"
                />
              ) : (
                'Save'
              )
            }
            onClicked={onSaveColor}
          />
        </div>
      )}
    </div>
  );

  function internalOnChange(color: string, disablePreset = false) {
    if (!disablePreset) {
      presets[presetIndex] = colord(color).alpha(1).toHex();
    }

    if (!debounceSave) {
      onChange(color);
    } else {
      onChangeFork(color);
    }
  }

  function onSaveColor(newColor?: string) {
    setIsSaving(true);
    onSave?.(newColor || color).finally(() => setIsSaving(false));
  }

  /**
   * Immediately calls `onChange` and debounces call to `onSave`.
   *
   * @param color new color from onChange
   */
  function onChangeFork(color: string) {
    onChange(color);
    onDebouncedSave(color);
  }
}
