import { faCheck, faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconButton } from '@agerpoint/component';

interface SaveCheckmarkProps {
  isSaving: boolean;
  onSave: () => void;
  title: string;
  disabled?: boolean;
}

export function SaveCheckmark({
  isSaving,
  onSave,
  title,
  disabled,
}: SaveCheckmarkProps) {
  return (
    <IconButton
      className={`flex flex-shrink-0 items-center ml-auto justify-center w-7 h-7 ${
        isSaving ? 'opacity-40' : ''
      } ${
        !disabled ? 'bg-green-300 hover:bg-green' : 'bg-green-100'
      } rounded-sm text-white mr-1`}
      show
      icon={
        !isSaving ? (
          <FontAwesomeIcon className="text-xl text-center" icon={faCheck} />
        ) : (
          <FontAwesomeIcon
            className="text-xl text-center fa-spin"
            icon={faCircleNotch}
          />
        )
      }
      disabled={isSaving || disabled}
      onClick={onSave}
      title={title}
    />
  );
}
