import { faCircleNotch, faXmark } from '@fortawesome/pro-light-svg-icons';
import { faPen, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { UseGetReturn } from 'restful-react';

import {
  Capture,
  CaptureExtractionJob,
  Customer,
  PostProject,
  Project,
  User,
  useDeleteCaptureFromProject,
  useGetCustomer,
  useGetProjectsByCapture,
  useGetUsersAvailibleFromCaptures,
} from '@agerpoint/api';
import { QaqcCaptureNotes } from '@agerpoint/feature';
import { UserClaims } from '@agerpoint/types';
import {
  getDateString,
  hasClaims,
  useGlobalStore,
  useToasts,
} from '@agerpoint/utilities';

import { AddCapturesToProjectsModal } from '../../add-captures-to-projects-modal/add-captures-to-projects-modal';
import { EditCapturesOrgModal } from '../../edit-captures-org-modal/edit-captures-org-modal';

interface QaqcCaptureDetailsSubsectionProps {
  capture: Capture;
  extractionJob?: CaptureExtractionJob;
  setExtractionJob: React.Dispatch<
    React.SetStateAction<CaptureExtractionJob | undefined>
  >;
  isExpanded: boolean;
}

export const QaqcCaptureDetailsSubsection = ({
  capture,
  extractionJob,
  setExtractionJob,
  isExpanded,
}: QaqcCaptureDetailsSubsectionProps) => {
  const { user: storeUser } = useGlobalStore();

  const toasts = useToasts();

  const { data: customers } = useGetCustomer({});
  const { data: users } = useGetUsersAvailibleFromCaptures(
    {}
  ) as unknown as UseGetReturn<User[], void, void, unknown>;

  const [customer, setCustomer] = useState<Customer>({});
  const [user, setUser] = useState<User>();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [showAddToProjectsModal, setShowAddToProjectsModal] = useState(false);

  const { data: captureProjectsData, refetch: refetchCaptureProjects } =
    useGetProjectsByCapture({
      captureId: capture?.id ?? NaN,
    }) as unknown as UseGetReturn<Project[], void, void, unknown>;

  const [captureProjects, setCaptureProjects] = useState<Project[]>();

  useEffect(() => {
    if (captureProjectsData) {
      setCaptureProjects(captureProjectsData);
    }
  }, [captureProjectsData]);

  useEffect(() => {
    if (
      storeUser &&
      storeUser?.cloudClaims &&
      hasClaims([UserClaims.AgerAdmin], storeUser?.cloudClaims as UserClaims[])
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [storeUser]);

  useEffect(() => {
    if (customers) {
      const customer = customers.find((c) => c.id === capture.customerId);
      if (customer) {
        setCustomer(customer);
      }
    }
  }, [customers, capture]);

  useEffect(() => {
    if (users) {
      const userList = users as User[];
      const captureUser =
        userList.find((u: User) => u.id === capture.createdById) || {};
      setUser(captureUser);
      return;
    }
    setUser(undefined);
  }, [users, capture]);

  const buildRecord = (title?: React.ReactNode, value?: React.ReactNode) => {
    return (
      <div className="flex flex-col text-xs">
        <div className="leading-4 text-gray-400 whitespace-nowrap">
          {title ?? ''}
        </div>
        <div className="text-white">{value ?? ''}</div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col pt-1 gap-1">
        <div className="flex flex-row gap-y-1 gap-x-2 flex-wrap">
          {buildRecord(
            'Created By:',
            user?.userName ?? <FontAwesomeIcon icon={faCircleNotch} spin />
          )}
          {buildRecord(
            <>
              <span className="pr-1">Organization:</span>
              {isAdmin && (
                <FontAwesomeIcon
                  icon={faPen}
                  className="hover:text-gray-400 cursor-pointer"
                  onClick={() => {
                    setShowEditOrgModal(true);
                  }}
                />
              )}
            </>,
            customer.customerDisplayName ?? 'No Organization'
          )}
          {buildRecord('Create Date:', getDateString(capture.createDatetime))}
          {buildRecord('Upload Date:', getDateString(capture.updateDatetime))}
          {buildRecord('Size:', `${capture.fileSize}MB`)}
          {buildRecord('Images:', `${capture.numberImages}`)}
        </div>
        {buildRecord(
          <>
            <span className="pr-1">Project(s):</span>
            <FontAwesomeIcon
              icon={faPlus}
              className="hover:text-gray-400 cursor-pointer"
              onClick={() => {
                setShowAddToProjectsModal(true);
              }}
            />
          </>,
          <>
            {(captureProjects?.length ?? 0) > 0
              ? captureProjects?.map((project, key) => (
                  <QaqcCaptureProjectRecord
                    key={key}
                    project={project}
                    capture={capture}
                    setCaptureProjects={setCaptureProjects}
                  />
                ))
              : 'No Projects'}
          </>
        )}
        <QaqcCaptureNotes
          extractionJob={extractionJob}
          setExtractionJob={setExtractionJob}
          isExpanded={isExpanded}
        />
      </div>

      <EditCapturesOrgModal
        captures={[capture]}
        customers={customers || []}
        open={showEditOrgModal}
        handleCloseDialog={() => {
          setShowEditOrgModal(false);
        }}
        changedCaptures={(captures) => {
          captures.forEach((c) => {
            if (customers) {
              const customer = customers.find(
                (cust) => cust.id === c.customerId
              );
              capture.customerId = customer?.id || undefined;
              if (customer) {
                setCustomer(customer);
              } else {
                setCustomer({});
              }
            }
          });
        }}
      />
      <AddCapturesToProjectsModal
        captures={[capture]}
        open={showAddToProjectsModal}
        handleCloseDialog={() => {
          setShowAddToProjectsModal(false);
        }}
        updatedCallback={() => {
          refetchCaptureProjects();
        }}
        customers={customers || []}
        users={users ?? undefined}
      />
    </>
  );
};

interface QaqcCaptureProjectRecordProps {
  project: Project;
  capture: Capture;
  setCaptureProjects: React.Dispatch<
    React.SetStateAction<Project[] | undefined>
  >;
}

const QaqcCaptureProjectRecord = ({
  project,
  capture,
  setCaptureProjects,
}: QaqcCaptureProjectRecordProps) => {
  const {
    mutate: deleteCaptureFromProject,
    loading: loadingDeleteCaptureFromProject,
  } = useDeleteCaptureFromProject({
    uuid: '',
  });

  const toasts = useToasts();

  return (
    <div className="flex flex-row gap-1 items-center text-xs text-white">
      <span>{project.name}</span>
      <FontAwesomeIcon
        icon={loadingDeleteCaptureFromProject ? faCircleNotch : faXmark}
        className={
          !loadingDeleteCaptureFromProject
            ? 'hover:text-gray-400 cursor-pointer'
            : ''
        }
        spin={loadingDeleteCaptureFromProject}
        onClick={async () => {
          if (loadingDeleteCaptureFromProject) {
            return;
          }

          if (!capture.id || !project.uuid) {
            return;
          }

          try {
            await deleteCaptureFromProject(capture.id, {
              pathParams: {
                uuid: project.uuid,
              },
            });
            toasts.add(
              toasts.prepare.entityUpdated(
                'project',
                'Removed capture from project.'
              )
            );

            setCaptureProjects((prev) => {
              if (!prev) {
                return prev;
              }

              const next = [...prev];
              const index = next.findIndex((p) => p.id === project.id);
              next.splice(index, 1);
              return next;
            });
          } catch (e) {
            toasts.add(
              toasts.prepare.error('Failed to remove capture from project!')
            );

            console.error(e);
          }
        }}
      />
    </div>
  );
};
