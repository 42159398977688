import {
  IconName,
  IconPrefix,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useCallback, useMemo, useRef } from 'react';

import { Tooltip } from '../tooltip';

interface IButtonIconToggleBase {
  id: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  leadingIcon?: IconName;
  leadingIconPrefix?: IconPrefix;
  leadingIconSpin?: boolean;
  leadingIconColor?: string;
  trailingIcon?: IconName;
  trailingIconPrefix?: IconPrefix;
  trailingIconSpin?: boolean;
  trailingIconColor?: string;
  toggled?: boolean;
}

interface IButtonIconToggleWithLabel extends IButtonIconToggleBase {
  label: string;
  tooltip?: never;
  tooltipPosition?: never;
}

interface IButtonIconToggleWithToolTip extends IButtonIconToggleBase {
  tooltip: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  label?: never;
}

type IButtonIconToggle =
  | IButtonIconToggleWithLabel
  | IButtonIconToggleWithToolTip;

export const ButtonIconToggle = ({
  id,
  buttonRef: buttonRefProp,
  onClick,
  disabled,
  loading,
  leadingIcon,
  leadingIconPrefix = 'far',
  leadingIconSpin,
  leadingIconColor,
  trailingIcon,
  trailingIconPrefix = 'far',
  trailingIconSpin,
  trailingIconColor,
  label,
  tooltip,
  tooltipPosition = 'bottom',
  toggled,
}: IButtonIconToggle) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const className = useMemo(() => {
    let classes = `transition-colors duration-200 px-1 h-10 shrink-0
    flex flex-row items-center justify-center relative ring-1 ring-gray-border`;

    if (toggled) {
      classes += ' rounded-lg bg-primary bg-opacity-10 text-primary  ';
    } else {
      classes += ' rounded-lg ';
    }

    if (loading) {
      classes += ' cursor-progress ';
    } else if (disabled) {
      classes += ' cursor-not-allowed ';
    } else {
      classes += ' cursor-pointer hover-overlay-5 ';
    }

    return classes.trim();
  }, [disabled, toggled, loading]);

  const buildIcon = useCallback(
    (
      prefix: IconPrefix,
      icon?: IconName,
      spin?: boolean,
      color = 'text-gray-iconPrimary'
    ) => {
      if (!icon) return null;

      if (loading) {
        return (
          <div className="w-8 flex-center">
            <FontAwesomeIcon
              spin
              icon={faCircleNotch}
              className="text-base text-gray-iconSecondary"
            />
          </div>
        );
      }

      if (disabled) {
        return (
          <div className="w-8 flex-center">
            <FontAwesomeIcon
              icon={[toggled ? 'fas' : prefix, icon]}
              spin={spin}
              className="text-base text-gray-iconSecondary"
            />
          </div>
        );
      }

      return (
        <div className="w-8 flex-center">
          <FontAwesomeIcon
            icon={[toggled ? 'fas' : prefix, icon]}
            spin={spin}
            className={`text-base ${toggled ? 'text-primary' : color}`}
          />
        </div>
      );
    },
    [loading, disabled, toggled]
  );

  return (
    <>
      <button
        data-test-id={id}
        type="button"
        onClick={onClick}
        className={className}
        disabled={disabled}
        style={{
          height: '40px',
        }}
        ref={buttonRefProp ?? buttonRef}
      >
        {buildIcon(
          leadingIconPrefix,
          leadingIcon,
          leadingIconSpin,
          leadingIconColor
        )}
        {label && <div className="px-1 text-gray-textPrimary">{label}</div>}
        {buildIcon(
          trailingIconPrefix,
          trailingIcon,
          trailingIconSpin,
          trailingIconColor
        )}
      </button>
      {tooltip && tooltipPosition && (buttonRefProp ?? buttonRef) && (
        <Tooltip
          childRef={buttonRefProp ?? buttonRef}
          position={tooltipPosition}
        >
          {tooltip}
        </Tooltip>
      )}
    </>
  );
};
