import React, { ReactNode } from 'react';
interface SidebarExpansionProps {
  open: boolean;
  sidebarOpen: boolean;
  children?: ReactNode;
}

export function SidebarExpansion({
  open,
  sidebarOpen,
  children,
}: SidebarExpansionProps) {
  return (
    <div
      className={`absolute bg-white h-full top-0 transition-all duration-300 ease-linear overflow-auto ${
        open
          ? 'left-sidebar w-sidebar-expansion'
          : sidebarOpen
          ? 'left-sidebar-expansion-closed w-sidebar-expansion invisible'
          : '-left-full w-0'
      }`}
    >
      {open && children}
    </div>
  );
}
